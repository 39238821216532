import * as React from 'react';
import { createContext, useContextSelector } from '@fluentui/react-context-selector';
export const MenuListContext = createContext(undefined);
const menuListContextDefaultValue = {
    checkedValues: {},
    setFocusByFirstCharacter: ()=>null,
    toggleCheckbox: ()=>null,
    selectRadio: ()=>null,
    hasIcons: false,
    hasCheckmarks: false
};
export const MenuListProvider = MenuListContext.Provider;
export const useMenuListContext_unstable = (selector)=>useContextSelector(MenuListContext, (ctx = menuListContextDefaultValue)=>selector(ctx));
