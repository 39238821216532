import React from 'react';
import { Spinner, SpinnerProps } from '@fluentui/react-components';

import styles from './Loader.module.scss';

export type LoaderProps = SpinnerProps;

/** Loader component that display a spinner with centered style. */
export const Loader: React.FunctionComponent<LoaderProps> = (props) => {
    const style = props.style || {};

    if (props.label === '') {
        // remove space between spinner and label if label is empty
        style.rowGap = 0;
        style.columnGap = 0;
    }

    return (
        <div className={styles.spinnerContainer}>
            <Spinner size="large" labelPosition="below" {...props} style={style} />
        </div>
    );
};
