import React from 'react';
import {
    Button,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerHeaderTitle,
    OverlayDrawer,
    OverlayDrawerProps,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';

import styles from './Drawer.module.scss';

export type DrawerProps = (
    | { title: string; renderTitle?: never }
    | { title?: never; renderTitle: () => React.ReactNode }
) & {
    open: boolean;
    onOpenChange: OverlayDrawerProps['onOpenChange'];
    onDismiss: () => void;
    ariaLabel?: string;
    footer?: React.ReactNode;
    size?: 'small' | 'medium' | 'large';
    /**
     * Custom class name for the drawer body
     */
    className?: string;
    headerStyle?: React.CSSProperties;
    onError?: React.ReactNode;
};

/**
 * Creates UI component for a panel that slides in from the right.
 *
 * ## How
 * Using Fluent 9 `<OverlayDrawer>` component
 *
 * ## Why
 * Provide a consistent implementation for a sliding panel across the application.
 * It improves UX by managing overlays, focus and accessibility.
 *
 * @example Minimal
 * import { Drawer } from '@kusto/ui-components';
 *
 *       <Drawer
 *          open={open}
 *          onOpenChange={(_, { open }) => setOpen(open)}
 *          onDismiss={onDismiss}
 *          title={t.dashboards.forms.baseQueries.title}
 *       >
 *           <childComponent/>
 *       </Drawer>
 *
 */

export const Drawer: React.FC<DrawerProps> = ({
    open,
    onOpenChange,
    onDismiss,
    title,
    ariaLabel,
    footer,
    size,
    className,
    headerStyle,
    onError,
    renderTitle,
    ...props
}) => {
    const headerTitle = renderTitle ? renderTitle() : title;
    return (
        <OverlayDrawer open={open} size={size} position="end" onOpenChange={onOpenChange}>
            <DrawerHeader>
                <DrawerHeaderTitle
                    style={headerStyle}
                    className={styles.drawerHeaderTitle}
                    action={
                        <Button
                            appearance="subtle"
                            aria-label={ariaLabel}
                            icon={<Dismiss24Regular />}
                            onClick={onDismiss}
                        />
                    }
                >
                    {headerTitle}
                </DrawerHeaderTitle>
                {onError && <div>{onError}</div>}
            </DrawerHeader>
            <DrawerBody className={className}>{props.children}</DrawerBody>
            {footer && <DrawerFooter>{footer}</DrawerFooter>}
        </OverlayDrawer>
    );
};
