.panelFix :global(.ms-Panel-main) {
    max-width: 100%; // Default for this is 100vw, which causes the panel to not respect the width of the host container
}

.panelFix :global(.ms-Panel-footer) {
    background-color: var(--white);
}

.fluent8DialogFix {
    display: flex !important;
    width: 100% !important;
    height: 100% !important;
}
