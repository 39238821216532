export const VISUAL_OPTIONS__TEXT_DEBOUNCE = 1000;

export const VISUALS__SLOTS_MAX_WIDTH = 5;
export const VISUALS__SLOTS_MAX_HEIGHT = 5;

/**
 * When a user does not add a visual when creating a new query and it is
 * added to the dashboard this visual type will be used
 */
export const DEFAULT_VISUAL_TYPE = 'table';

/**
 * Display strings for dashboard values that shouldn't be localized
 */
export const DASHBOARD_VALUE_DISPLAY_STRINGS = {
    null: 'null',
    json: 'dynamic',
    kustoArray: 'dynamic',
    timespan: 'timespan',
};
