import React from 'react';
import { SelectionEvents } from '@fluentui/react-combobox';
import {
    Combobox,
    ComboboxProps,
    Field,
    FieldProps,
    mergeClasses,
    Popover,
    PopoverSurface,
} from '@fluentui/react-components';

import { useStrings } from '../../hooks/useStrings.tsx';
import { DropdownButton } from './components/DropdownButton';
import { DropdownMenu } from './components/DropdownMenu.tsx';
import { useDropdown } from './hooks/useDropdown';
import {
    DropdownValue,
    DropdownWithSearchOption,
    OverrideInputProps,
    SelectedOption,
    TitleRender,
    TooltipRender,
} from './types';

import styles from './DropdownWithSearch.module.scss';

export type DropdownWithSearchProps = Omit<ComboboxProps, 'onChange' | 'onOptionSelect' | 'placeholder'> &
    Pick<
        FieldProps,
        | 'validationMessage'
        | 'validationMessageIcon'
        | 'validationState'
        | 'required'
        | 'hint'
        | 'label'
        | 'orientation'
    > & {
        /** Array of options to be displayed in the dropdown */
        options: DropdownWithSearchOption[];
        /** Keys of the selected options (controlled) */
        selectedKeys?: DropdownValue;
        /** Keys of the selected options (uncontrolled) */
        defaultSelectedKeys?: DropdownValue;
        /** Placeholder text displayed in the dropdown */
        placeholder?: string | React.ReactElement;
        /** Placeholder text displayed in the search input */
        searchInputPlaceHolder?: string;
        /** Callback function invoked when the selection changes */
        onChange?: (ev: SelectionEvents, data: SelectedOption) => void;
        /** Custom title renderer function */
        renderTitle?: TitleRender;
        /** Custom header renderer */
        renderHeader?: () => React.ReactElement | null;

        /** Alternate content renderer */
        renderAlternateContent?: () => React.ReactElement | undefined;
        /** Custom option renderer function */
        renderOption?: (option: DropdownWithSearchOption) => React.ReactElement;
        /** Custom label renderer function */
        renderLabel?: () => React.ReactElement;
        /** On dropdown dismissed callback */
        onDismiss?: () => void;
        /** Content to render inside a tooltip */
        renderTooltipContent?: TooltipRender;
        /** Props to override the search input field. If passed, the input will always be visible, and the sorting mechanism will be disabled. */
        overrideInputProps?: OverrideInputProps;
        /** Enable horizontal scrolling inside the dropdown if necessary (disable ellipsis for option items) */
        enableHorizontalScroll?: boolean;
        /** Test identifier for testing */
        ['data-testid']?: string;
    };

export interface DropdownWithSearchRef {
    setOpen: (open: boolean) => void;
}

export const DropdownWithSearch = React.forwardRef<DropdownWithSearchRef, DropdownWithSearchProps>(
    (
        {
            id,
            options,
            onChange,
            placeholder,
            searchInputPlaceHolder,
            selectedKeys,
            defaultSelectedKeys,
            disabled,
            className,
            multiselect,
            renderTitle,
            renderOption,
            renderHeader,
            renderAlternateContent,
            label,
            orientation,
            hint,
            required,
            validationMessage,
            validationMessageIcon,
            validationState,
            expandIcon,
            onDismiss,
            renderTooltipContent,
            overrideInputProps,
            enableHorizontalScroll,
            ['data-testid']: dataTestId,
            ...props
        },
        ref
    ) => {
        const {
            open,
            query,
            filteredOptions,
            controlledSelectedKeys,
            uncontrolledSelectedKeys,
            buttonRef,
            popoverRef,
            comboboxRef,
            listBoxRef,
            inputRef,
            setQuery,
            handleOpenChanged,
            handleOptionSelected,
            showSearch,
            inputAccessibilityHandleKeyDown,
            fieldAccessibilityHandleKeyDown,
            tabHandleKeyUp,
        } = useDropdown(
            options,
            selectedKeys,
            defaultSelectedKeys,
            onChange,
            multiselect,
            onDismiss,
            overrideInputProps
        );
        const strings = useStrings();

        React.useImperativeHandle(
            ref,
            () => ({
                setOpen: handleOpenChanged,
            }),
            [handleOpenChanged]
        );

        const handleInputChanged = React.useCallback(
            (ev: React.ChangeEvent<HTMLInputElement>) => {
                setQuery(ev.target.value);
                overrideInputProps?.onInputChange(ev);
            },
            [setQuery, overrideInputProps]
        );
        return (
            <Field
                className={className}
                label={label}
                orientation={orientation}
                hint={hint}
                required={required}
                validationMessage={validationMessage}
                validationMessageIcon={validationMessageIcon}
                validationState={validationState}
                onKeyDown={fieldAccessibilityHandleKeyDown}
            >
                <Popover
                    closeOnScroll
                    open={open}
                    onOpenChange={(_e, data) => handleOpenChanged(data.open)}
                    positioning="below-start"
                    trapFocus={true}
                >
                    <DropdownButton
                        ref={buttonRef}
                        id={id}
                        data-testid={dataTestId}
                        options={options}
                        selectedKeys={controlledSelectedKeys || uncontrolledSelectedKeys}
                        placeholder={placeholder}
                        disabled={disabled}
                        renderTitle={renderTitle}
                        expandIcon={expandIcon}
                        renderTooltipContent={renderTooltipContent}
                    />

                    <PopoverSurface
                        ref={popoverRef}
                        style={{ padding: 0 }}
                        className={mergeClasses(
                            styles.popoverContainer,
                            !showSearch && styles.withoutSearch,
                            enableHorizontalScroll ? styles.horizontalScroll : styles.optionEllipsis
                        )}
                        tabIndex={-1}
                    >
                        <Combobox
                            {...props}
                            ref={comboboxRef}
                            inlinePopup
                            autoComplete="off"
                            multiselect={multiselect}
                            open={true}
                            input={{ ref: inputRef }}
                            placeholder={
                                searchInputPlaceHolder ?? strings.uiComponents.dropdownWithSearch.searchOptions
                            }
                            listbox={{ ref: listBoxRef }}
                            onChange={(e) => showSearch && handleInputChanged(e)}
                            onKeyDownCapture={inputAccessibilityHandleKeyDown}
                            onKeyUp={tabHandleKeyUp}
                            value={query}
                            onOptionSelect={handleOptionSelected}
                            expandIcon={<></>}
                            {...(controlledSelectedKeys
                                ? { selectedOptions: controlledSelectedKeys }
                                : uncontrolledSelectedKeys
                                ? { defaultSelectedOptions: uncontrolledSelectedKeys }
                                : undefined)}
                        >
                            {renderAlternateContent?.() ?? (
                                <>
                                    {renderHeader?.()}
                                    <DropdownMenu
                                        options={options}
                                        filteredOptions={filteredOptions}
                                        renderOption={renderOption}
                                    />
                                </>
                            )}
                        </Combobox>
                    </PopoverSurface>
                </Popover>
            </Field>
        );
    }
);
