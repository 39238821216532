/**
 * @see {@link DynamicObject}
 */
export type DynamicValue = string | number | boolean | DynamicArray | DynamicObject;
/**
 * @see {@link DynamicObject}
 */
export type DynamicArray = readonly (null | DynamicValue)[];
/**
 * https://learn.microsoft.com/en-us/azure/data-explorer/kusto/query/scalar-data-types/dynamic
 *
 * FIXME: We currently don't represent many kusto values in our dynamic
 * representation correctly, because we're passing it as json, and it's not a
 * subset of json. See the kusto docs for more information.
 */
export interface DynamicObject {
    readonly [key: string]: null | DynamicValue;
}

/**
 * Kwe type tags to type in-memory representations
 *
 * https://learn.microsoft.com/en-us/azure/data-explorer/kusto/query/scalar-data-types
 */
export interface DataFrameTypes {
    readonly string: string;
    readonly int: number;
    /**
     * TODO: Represent long values as bigint's
     */
    readonly long: string;
    readonly real: number;
    /**
     * TODO: Update to use some other representation that we can do math with,
     * like this library: https://github.com/munrocket/double.js
     */
    readonly decimal: string;
    readonly bool: boolean;
    /**
     * Milliseconds from epoc. Can be used to construct the `Date` class
     *
     * TODO: Kusto timespan is has "tick" level precision. Converting to a
     * javascript number will be lossy when sub-millisecond values are parsed.
     * https://learn.microsoft.com/en-us/azure/data-explorer/kusto/query/scalar-data-types/datetime
     *
     * @see
     * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/valueOf#return_value}
     * @see {@link Date.valueOf }
     */
    readonly datetime: number;
    /**
     * Leaving is string form for now, because kusto timespans measure in ticks, so converting to a number would be lossy. TODO: Maybe use bigint to support these?
     */
    readonly timespan: string;
    readonly guid: string;
    /**
     * @see {@link DynamicObject}
     */
    readonly dynamic: DynamicValue;
}

export type UnknownScalarValue = string | number | boolean;
export type UnknownDataFrameValue = null | DataFrameTypes[KustoDataType];

export type KustoNumericType = 'int' | 'long' | 'real' | 'decimal' | 'datetime' | 'timespan';
/**
 * https://learn.microsoft.com/en-us/azure/data-explorer/kusto/query/scalar-data-types/
 */
export type KustoScalarType = KustoNumericType | 'string' | 'bool' | 'guid';
export type KustoDataType = keyof DataFrameTypes;

export const KUSTO_NUMERIC_TYPES: readonly KustoNumericType[] = [
    'int',
    'long',
    'real',
    'decimal',
    'datetime',
    'timespan',
];

export const KUSTO_TIME_TYPES: readonly KustoNumericType[] = ['datetime', 'timespan'];

export const KUSTO_SCALAR_TYPES: readonly KustoScalarType[] = [...KUSTO_NUMERIC_TYPES, 'string', 'bool', 'guid'];

export const KUSTO_DATA_TYPES: readonly KustoDataType[] = [...KUSTO_SCALAR_TYPES, 'dynamic'];
