import { assertNever } from '@kusto/utils';

import { ColorRule, colorRulesThemes } from '../../../visualOptions';

export type ColorRulePanelActions =
    | { type: 'setColor'; color: ColorRule.Color | null }
    | { type: 'setIcon'; icon: ColorRule.Icon | null }
    | {
          type: 'setConditionOperatorAtIndex';
          index: 0 | 1;
          operator: ColorRule.Operator;
      }
    | { type: 'setConditionValuesAtIndex'; index: 0 | 1; value: string; valueIndex: 0 | 1 }
    | { type: 'setConditionColumnAtIndex'; index: 0 | 1; column: string | null }
    | { type: 'addCondition'; condition: ColorRule.Condition }
    | { type: 'deleteCondition'; index: 0 | 1 }
    | { type: 'setApplyToColumn'; column: string | null }
    | { type: 'setApplyTo'; applyTo: 'rows' | 'cells' }
    | { type: 'setHideText'; hideText: boolean }
    | { type: 'setReverseTheme'; reverse: boolean }
    | { type: 'setChainingOperator'; chainingOperator: 'and' | 'or' }
    | { type: 'setColorStyle'; colorStyle: 'light' | 'bold' }
    | { type: 'setTag'; tag: string }
    | { type: 'setRuleName'; ruleName: string }
    | { type: 'setRuleType'; ruleType: 'colorByCondition' | 'colorByValue' }
    | { type: 'setTheme'; theme: ColorRule.Theme | null }
    | { type: 'setThemeMinValue'; value: string }
    | { type: 'setThemeMaxValue'; value: string }
    | { type: 'setColorByValueColumn'; column: string | null; isNumeric: boolean };

export const colorRulePanelReducer = (
    state: ColorRule.UColorRule,
    action: ColorRulePanelActions
): ColorRule.UColorRule => {
    switch (action.type) {
        case 'setColor':
            return state.ruleType === 'colorByCondition' ? { ...state, color: action.color } : state;
        case 'setIcon':
            return state.ruleType === 'colorByCondition' ? { ...state, icon: action.icon } : state;
        case 'setConditionOperatorAtIndex':
            if (state.ruleType !== 'colorByCondition') {
                return state;
            }
            return {
                ...state,
                conditions: [
                    ...state.conditions.slice(0, action.index),
                    {
                        values: state.conditions[action.index]?.values ?? [''],
                        operator: action.operator,
                        column: state.conditions[action.index]?.column ?? null,
                    },
                    ...state.conditions.slice(action.index + 1),
                ],
            };
        case 'setConditionValuesAtIndex': {
            if (state.ruleType !== 'colorByCondition') {
                return state;
            }
            const operator = state.conditions[action.index]?.operator;
            if (!operator) {
                return state;
            }
            let values = [...state.conditions[action.index].values];
            if (operator === 'between') {
                values[action.valueIndex] = action.value;
            } else {
                values = [action.value];
            }
            return {
                ...state,
                conditions: [
                    ...state.conditions.slice(0, action.index),
                    { operator, column: state.conditions[action.index]?.column ?? null, values: values },
                    ...state.conditions.slice(action.index + 1),
                ],
            };
        }
        case 'setThemeMinValue': {
            return state.ruleType !== 'colorByValue' ? state : { ...state, minValue: action.value };
        }
        case 'setThemeMaxValue': {
            return state.ruleType !== 'colorByValue' ? state : { ...state, maxValue: action.value };
        }
        case 'setConditionColumnAtIndex':
            if (state.ruleType !== 'colorByCondition') {
                return state;
            }
            const values = state.conditions[action.index]?.values;
            const operator = state.conditions[action.index]?.operator;

            if (!values || !operator) {
                return state;
            }
            return {
                ...state,
                conditions: [
                    ...state.conditions.slice(0, action.index),
                    { operator, column: action.column, values },
                    ...state.conditions.slice(action.index + 1),
                ],
            };
        case 'addCondition': {
            return state.ruleType === 'colorByCondition' && state.conditions.length < 2
                ? { ...state, conditions: [...state.conditions, action.condition] }
                : state;
        }
        case 'deleteCondition': {
            return state.ruleType === 'colorByCondition' && state.conditions.length > 1
                ? {
                      ...state,
                      conditions: [
                          ...state.conditions.slice(0, action.index),
                          ...state.conditions.slice(action.index + 1),
                      ],
                  }
                : state;
        }
        case 'setApplyToColumn': {
            return state.ruleType === 'colorByCondition' ? { ...state, applyToColumn: action.column } : state;
        }
        case 'setTheme': {
            return state.ruleType === 'colorByValue' ? { ...state, themeName: action.theme } : state;
        }
        case 'setColorByValueColumn': {
            return state.ruleType === 'colorByValue'
                ? {
                      ...state,
                      column: action.column,
                      themeName: action.isNumeric ? state.themeName ?? colorRulesThemes[0] : null,
                  }
                : state;
        }
        case 'setApplyTo': {
            return { ...state, applyTo: action.applyTo };
        }
        case 'setHideText': {
            return state.ruleType === 'colorByCondition' ? { ...state, hideText: action.hideText } : state;
        }
        case 'setReverseTheme': {
            return state.ruleType === 'colorByValue' ? { ...state, reverseTheme: action.reverse } : state;
        }
        case 'setChainingOperator': {
            return state.ruleType === 'colorByCondition'
                ? { ...state, chainingOperator: action.chainingOperator }
                : state;
        }
        case 'setColorStyle': {
            return state.ruleType === 'colorByCondition' ? { ...state, colorStyle: action.colorStyle } : state;
        }
        case 'setTag': {
            return state.ruleType === 'colorByCondition' ? { ...state, tag: action.tag } : state;
        }
        case 'setRuleName': {
            return { ...state, ruleName: action.ruleName };
        }
        case 'setRuleType': {
            if (action.ruleType === state.ruleType) {
                return state;
            }
            if (action.ruleType === 'colorByCondition') {
                return {
                    id: state.id,
                    ruleType: action.ruleType,
                    ruleName: state.ruleName,
                    applyTo: state.applyTo,
                    conditions: [{ column: null, operator: '>', values: [''] }],
                    chainingOperator: 'and',
                    colorStyle: 'bold',
                    applyToColumn: null,
                    hideText: false,
                    color: null,
                    tag: '',
                    icon: null,
                };
            }
            if (action.ruleType === 'colorByValue') {
                return {
                    id: state.id,
                    ruleType: action.ruleType,
                    ruleName: state.ruleName,
                    applyTo: state.applyTo,
                    column: null,
                    themeName: 'traffic-lights',
                    maxValue: null,
                    minValue: null,
                    reverseTheme: false,
                };
            }
            return state;
        }
    }

    assertNever(action);
};
