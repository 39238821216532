import React from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
    CellClickedEvent,
    CellDoubleClickedEvent,
    CellKeyDownEvent,
    CellPosition,
    ColDef,
    GetRowIdParams,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IRowNode,
    NavigateToNextCellParams,
    ProcessCellForExportParams,
    RowDataUpdatedEvent,
    RowGroupOpenedEvent,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';

import './Light.scss';
import './Dark.scss';
import './CustomIcons.scss';
import './TreeView.scss';

import { ContextualMenu, IContextualMenuItem, Point } from '@fluentui/react';
import debounce from 'lodash/debounce';

import { Theme } from '@kusto/utils';

import { Keys } from '../../common';
import type { QueryLocale } from '../../core/strings';
import { Action, Icon, ITreeEntityMapper } from './actions';
import { ReactTreeViewCellRenderer } from './AgGridCellRenderers';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';

/**
 * Data structure for Tree view rows.
 */
export interface TreeViewRow {
    /**
     * A unique ID for the row in this tree
     */
    id: string;
    /**
     * An array of strings that indicates the hierarchical path from root on the tree view.
     */
    pathFromRoot: string[];
    /**
     * displayed Label of the row.
     */
    label: string;
    /**
     * Type of entity. Each entity has it's own icon.
     */
    icon: Icon;
    /**
     * Text that appears beside the main text (main text would be the last entry in pathFromRoot).
     * Text would be a bit smaller and grayed out. Used by ADX to display the types for columns for example.
     */
    details?: string;
    /**
     * A list of actions that will be displayed on the right side upon hover. Each action type is associated with its
     * own icon.
     */
    actions?: Action[];
}

export interface TreeViewProps {
    strings: QueryLocale;

    getRowData?: (expanded: { [id: string]: boolean }, treeEntityMapper: ITreeEntityMapper) => TreeViewRow[];
    theme?: Theme;
    onCellClicked: (event: CellClickedEvent) => void;
    onCellDoubleClicked: (event: CellDoubleClickedEvent) => void;
    onCellKeyDown?: (event: CellKeyDownEvent) => void;
    onRowDataUpdated: (event: RowDataUpdatedEvent) => void;
    onRowGroupOpened: (event: RowGroupOpenedEvent) => void;
    getContextMenuItems: (params?: IRowNode) => IContextualMenuItem[];
    onGridReady: (params: GridReadyEvent) => void;
    isExternalFilterPresent?: () => boolean;
    doesExternalFilterPass?: (node: IRowNode) => boolean;
    rowData?: TreeViewRow[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    comparator?: (valueA: any, valueB: any, nodeA: IRowNode, nodeB: IRowNode, isInverted: boolean) => number;
    noRowsText?: string;
    highlightRegExp?: string; // a regex that determines what to highlight in every cell.
    visibility?: boolean;
    treeEntityMapper: ITreeEntityMapper;
    gridDebugMode?: boolean;
}

interface State {
    target?: Point;
    items?: IContextualMenuItem[];
}

export const MainColId = 'ag-Grid-AutoColumn';
export const columnDefs: ColDef[] = [];

/**
 * A presentational component based on ag-grid that's optimized for large trees. Specifically handles dynamic expansion
 * of nodes.
 */
export class TreeView extends React.Component<TreeViewProps, State> {
    private gridApi: GridApi | undefined;
    private debouncedOnResize = debounce(
        () => {
            setTimeout(() => {
                this.gridApi?.sizeColumnsToFit();
            }, 0);
        },
        500,
        { trailing: true, leading: true }
    );
    // Keep tracking of the focus in order to reset the focus after renders/updates
    private treeHasFocus = false;
    private openedGroup: {
        [key: string]: boolean;
    } = {};

    constructor(props: TreeViewProps) {
        super(props);
        this.state = {};
    }
    componentDidUpdate(prevProps: TreeViewProps) {
        // after re-render of element the real document "focus" is moved (original element was removed)
        // - getFocusedCell returns the internal ag-grid state and not the actual focus
        // check if the tree had the focus before the render (treeHasFocus) and reset the focus on the same
        // cell as before
        if (this.treeHasFocus && this.state.target === undefined) {
            this.focusOnTreeFocusedCell();
        }

        // Refresh - re-render the cells
        if (prevProps.theme !== this.props.theme && this.gridApi) {
            this.gridApi.refreshCells({ force: true });
        }
    }
    render() {
        const rowDataToRender =
            this.props.rowData ?? this.props.getRowData?.(this.openedGroup, this.props.treeEntityMapper) ?? [];
        const gridOptions: GridOptions = {
            overlayNoRowsTemplate: this.props.noRowsText,
            suppressNoRowsOverlay: !this.props.noRowsText,
            suppressRowClickSelection: true,
            suppressScrollOnNewData: true,
            suppressContextMenu: true,
            animateRows: true,
            columnDefs: columnDefs,
            treeData: true,
            getDataPath: (data: TreeViewRow) => {
                return data.pathFromRoot;
            },
            onRowDataUpdated: this.props.onRowDataUpdated,
            onCellKeyDown: this.onCellKeyDown,
            onCellDoubleClicked: this.props.onCellDoubleClicked,
            onRowGroupOpened: this.onRowGroupOpened,
            onViewportChanged: this.debouncedFixAccessibilitySetup,
            onFirstDataRendered: this.debouncedFixAccessibilitySetup,
            isExternalFilterPresent: this.props.isExternalFilterPresent,
            doesExternalFilterPass: this.props.doesExternalFilterPass,
            processRowPostCreate: this.debouncedFixAccessibilitySetup,
            navigateToNextCell: this.accessibilityKeyboardNavigation,
            localeText: this.props.strings.agGrid.builtIn,
            ensureDomOrder: true,
            context: { highlightRegExp: this.props.highlightRegExp },
            onModelUpdated: () => {
                this.debouncedOnResize();
            },
            // This is needed because otherwise grid tries to copy all cells in the row to the clipboard.
            // In our case, the first column has no text, so ag grid prints an empty string, followed by \t
            // which is the delimiter, and only then the entity name.
            // so when copying a value we get "\tClusterName" copied to the clipboard.
            // following flag makes ag-grid to only copy the cell with actual text.
            suppressCopyRowsToClipboard: true,
            processCellForClipboard: this.processCellForClipboard,
            debug: this.props.gridDebugMode,
        };
        return (
            // Disabled while enabling eslint rule
            // eslint-disable-next-line jsx-a11y/interactive-supports-focus
            <div
                className={
                    this.props.theme === Theme.Dark
                        ? 'connection-pane ag-theme-balham-dark'
                        : 'connection-pane ag-theme-balham'
                }
                onContextMenu={this.openContextMenu}
                onFocus={() => {
                    this.treeHasFocus = true;
                }}
                onBlur={this.clearFocus}
                style={{
                    visibility:
                        this.props.visibility === true || this.props.visibility === undefined ? 'visible' : 'hidden',
                }}
            >
                {/* Disabled while enabling eslint rule */}
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                <div tabIndex={0} onFocus={this.focusOnSelected} />
                {this.state.target && (
                    <ContextualMenu
                        target={this.state.target}
                        shouldFocusOnMount={true}
                        shouldFocusOnContainer={true}
                        onDismiss={this.dismissContextMenu}
                        items={this.state.items!}
                    />
                )}
                <AgGridReact
                    {...gridOptions}
                    modules={[ClientSideRowModelModule, MenuModule, RowGroupingModule]}
                    rowData={rowDataToRender}
                    headerHeight={0}
                    rowSelection="single"
                    onCellClicked={this.props.onCellClicked}
                    onGridReady={this.onGridReady}
                    getRowId={this.getRowId}
                    rowBuffer={50}
                    autoGroupColumnDef={
                        {
                            headerName: 'Name',
                            filterParams: { caseSensitive: false },
                            filter: 'text',
                            colId: MainColId,
                            minWidth: 0,
                            comparator: this.props.comparator,
                            sortable: !!this.props.comparator,
                            valueGetter: 'data.id + data.details + data.icon.iconName',
                            cellRendererParams: {
                                suppressCount: true,
                                suppressDoubleClickExpand: true,
                                suppressEnterExpand: true,
                                innerRenderer: ReactTreeViewCellRenderer,
                            },
                        } as ColDef
                    }
                    onCellDoubleClicked={this.props.onCellDoubleClicked}
                />
            </div>
        );
    }

    private onGridReady = (params: GridReadyEvent): void => {
        if (!params.api) {
            return;
        }
        this.gridApi = params.api;

        params.columnApi!.autoSizeAllColumns();
        this.gridApi.sizeColumnsToFit();
        if (this.props.onGridReady) {
            this.props.onGridReady(params);
        }

        this.debouncedFixAccessibilitySetup();
    };

    // Keep track of open groups, so we will be able to get minimal amount of rows (scrubbing closed groups)
    private onRowGroupOpened = (event: RowGroupOpenedEvent) => {
        if (this.props.onRowGroupOpened) {
            this.props.onRowGroupOpened(event);
        }
        const rowData = event.data as TreeViewRow;
        if (rowData && rowData.id) {
            if (event.node.expanded) {
                this.openedGroup[rowData.id] = true;
            } else {
                delete this.openedGroup[rowData.id];
            }
        }
    };
    private getRowId({ data }: GetRowIdParams) {
        return data.id;
    }
    private onCellKeyDown = (e: CellKeyDownEvent) => {
        const keyboardEvent = e.event as KeyboardEvent;
        if (!keyboardEvent) {
            return;
        }
        if (this.props.onCellKeyDown) {
            this.props.onCellKeyDown(e);
        }

        if (keyboardEvent.key === Keys.enter) {
            const target = e.event?.target as Element;
            if (target && !target.classList.contains('actionIconOnHover') && e.node.isExpandable()) {
                e.node.setExpanded(!e.node.expanded);
            }
        }
    };
    private fixAccessibilitySetup = () => {
        if (!this.gridApi) {
            return;
        }
        const rowsContainer = document.querySelector('.ag-center-cols-container');
        rowsContainer?.setAttribute('role', 'tree');
        const cells = document.querySelectorAll('.connection-pane .ag-root .ag-cell');
        cells.forEach((cellWrapperElement) => {
            switch (cellWrapperElement.getAttribute('col-id')) {
                case MainColId:
                    const rowIndexStr = cellWrapperElement.parentElement?.getAttribute('row-index');
                    const rowIndex = rowIndexStr ? Number.parseInt(rowIndexStr) : undefined;
                    const row = rowIndex !== undefined ? this.gridApi?.getDisplayedRowAtIndex(rowIndex) : undefined;
                    if (!row) {
                        return;
                    }

                    const parentElement = cellWrapperElement.parentElement!;
                    parentElement.setAttribute('role', 'treeitem');
                    parentElement.removeAttribute('aria-rowindex');
                    parentElement.setAttribute('aria-level', (row.level + 1).toString());
                    parentElement.setAttribute('aria-expanded', row.expanded.toString());
                    parentElement.setAttribute('aria-posinset', (row.childIndex + 1).toString());
                    if (row.parent) {
                        const size = (row.parent.childrenAfterGroup ?? []).length.toString();
                        parentElement.setAttribute('aria-setsize', size);
                    }
                    cellWrapperElement.setAttribute('role', 'presentation');
                    cellWrapperElement.removeAttribute('aria-colindex');
                    cellWrapperElement.removeAttribute('aria-expanded');
                    const icons = cellWrapperElement.querySelectorAll('.ag-icon');
                    icons.forEach((elem) => {
                        const parentClasses = elem.parentElement?.className;
                        if (!parentClasses) {
                            return;
                        }
                        elem.setAttribute('aria-label', row.expanded ? 'Collapse' : 'Expand');
                        elem.setAttribute('role', 'button');

                        elem.parentElement!.setAttribute('role', 'presentation');
                    });

                    break;
                default:
                    cellWrapperElement.removeAttribute('tabIndex');
                    break;
            }
        });
    };
    private debouncedFixAccessibilitySetup = debounce(this.fixAccessibilitySetup, 10);

    private debouncedClearGridFocus = debounce(() => {
        if (this.gridApi && !this.treeHasFocus) {
            this.gridApi.clearFocusedCell();
        }
    }, 100);
    private clearFocus = () => {
        // Focus is tricky - blur event fires even when moving between rows between elements in the tree
        // So, marked tree as "unfocused" but wait with the actual action - clear focus mark.
        // if the focus is in the tree (move to different row/cell),
        // a following onFocus fires which will set the treeHasFocus to true
        if (this.treeHasFocus) {
            this.debouncedClearGridFocus();
        }
        this.treeHasFocus = false;
    };
    private focusOnSelected = () => {
        if (!this.gridApi) {
            return;
        }
        const selected = this.gridApi.getSelectedNodes();
        if (!selected || selected.length === 0) {
            return;
        }
        if (selected[0].rowIndex !== null) {
            this.gridApi.setFocusedCell(selected[0].rowIndex, MainColId);
            this.gridApi.ensureNodeVisible(selected[0]);
        } else if (selected[0].parent) {
            // If rowIndex is null, it probably means the group is collapsed. Expand the group and refocus on the cell.
            selected[0].parent.setExpanded(true);
            setTimeout(() => {
                if (selected[0].rowIndex !== null) {
                    this.gridApi?.setFocusedCell(selected[0].rowIndex, MainColId);
                    this.gridApi?.ensureNodeVisible(selected[0]);
                }
            }, 0);
        }
    };
    private focusOnTreeFocusedCell = () => {
        if (!this.gridApi) {
            return;
        }
        const focus = this.gridApi.getFocusedCell();
        if (focus) {
            this.gridApi.setFocusedCell(focus.rowIndex, focus.column);
        }
    };
    private processCellForClipboard = (params: ProcessCellForExportParams) => {
        const path = (params.node?.data as TreeViewRow).pathFromRoot as string[];
        if (!path || path.length === 0) {
            return '';
        }

        const entityName = path[path.length - 1];
        return entityName;
    };
    private accessibilityKeyboardNavigation = (event: NavigateToNextCellParams): CellPosition => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            return event.nextCellPosition ?? event.previousCellPosition;
        }

        const currentRow = this.gridApi!.getDisplayedRowAtIndex(event.previousCellPosition.rowIndex);
        if (!currentRow) {
            return event.previousCellPosition;
        }
        if (event.key === 'ArrowLeft') {
            if (currentRow.expanded) {
                currentRow.setExpanded(false);
            } else if (currentRow.parent && Number.isInteger(currentRow.parent.rowIndex)) {
                return {
                    ...event.previousCellPosition,
                    rowIndex: currentRow.parent.rowIndex ?? event.previousCellPosition.rowIndex,
                };
            } else if (currentRow.rowIndex !== null && currentRow.rowIndex > 0) {
                return {
                    ...event.previousCellPosition,
                    rowIndex: currentRow.rowIndex - 1,
                };
            }
        } else if (event.key === 'ArrowRight') {
            if (currentRow.childrenAfterGroup && currentRow.childrenAfterGroup.length > 0) {
                if (!currentRow.expanded && currentRow.isExpandable()) {
                    currentRow.setExpanded(true);
                } else {
                    return {
                        ...event.previousCellPosition,
                        rowIndex: currentRow.childrenAfterGroup[0].rowIndex ?? event.previousCellPosition.rowIndex,
                    };
                }
            } else if (currentRow.rowIndex !== null && currentRow.rowIndex + 1 < this.gridApi!.getDisplayedRowCount()) {
                return {
                    ...event.previousCellPosition,
                    rowIndex: currentRow.rowIndex + 1,
                };
            }
        }
        return event.previousCellPosition;
    };
    private findRowIndex(elm: HTMLElement | null): number | undefined {
        let cur: HTMLElement | null = elm;
        while (cur) {
            const index = cur.getAttribute('row-index');
            if (index !== null) {
                return parseInt(index, 10);
            }
            cur = cur.parentElement;
        }
        return undefined;
    }
    private openContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!this.gridApi) {
            return;
        }
        const rowIndex = this.findRowIndex(e.target as HTMLElement);

        const rowData = rowIndex !== undefined ? this.gridApi!.getDisplayedRowAtIndex(rowIndex) : undefined;
        this.setState({
            target: { x: e.clientX, y: e.clientY },
            items: this.props.getContextMenuItems(rowData),
        });
    };
    private dismissContextMenu = () => {
        this.setState({ target: undefined });
    };
}
