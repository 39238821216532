import { usePrevious } from '@fluentui/react-hooks';
import { Options } from 'highcharts';

export function useShouldRecreateChartObject(isDarkTheme: boolean, yAxis?: Options['yAxis']): boolean {
    // highcharts does not know how to switch themes when updating the chart object, only when recreating it.
    // Thus whenever we're changing themes, we're signaling highcharts to recreate the object by (ab)using the immutable flag.
    // also: highcharts doesn't handle switching between chart types very well (for example - it might switch the axes).
    // Thus recreate the chart whenever we're changing chart type
    const prevDarkTheme = usePrevious(isDarkTheme);
    const prevYAxis = usePrevious(yAxis);
    return (prevDarkTheme !== undefined && prevDarkTheme !== isDarkTheme) || prevYAxis !== yAxis;
}
