import { createContext, useContext } from 'react';

import { TabsBarProps, TabsBarState } from '../Tabs.interfaces';

export const TabsBarContext = createContext<(TabsBarProps & TabsBarState) | null>(null);

export function useTabsBarContext() {
    const context = useContext(TabsBarContext);
    if (context === null) {
        throw new Error('TabsBarContext cannot be null');
    }

    return context;
}
