@use '@kusto/style/tokens';

.panel {
    background-color: tokens.$colorNeutralBackground2 !important;
    height: 100%;
}

.pinned {
    background-color: tokens.$colorNeutralBackground2 !important;
    width: 400px !important;
    border-right: tokens.$strokeWidthThin solid tokens.$colorNeutralStroke1;
}
