import React from 'react';
import { Button, Subtitle1 } from '@fluentui/react-components';
import { ArrowShuffle20Regular } from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';

import { MonacoEditorJSON } from '@kusto/ui-components';
import { Theme } from '@kusto/utils';

import { useDataExplorationContext } from '../../context/DataExplorationContext';
import { useSampleValue, type DataProfileTimeRange } from '../../DataExplorationApi';
import { ColumnInfoStatusWrapper } from './ColumnInfoStatusWrapper';

import styles from './SchemaColumnInfo.module.scss';

interface SchemaSampleValueProps {
    selectedColumnName: string;
    title: React.ReactNode;
    queryText: string;
    timeRange: DataProfileTimeRange;
    wordWrap?: boolean;
}

export const SchemaSampleValue: React.FC<SchemaSampleValueProps> = observer(function SchemaSampleValue({
    selectedColumnName,
    queryText,
    title,
    timeRange,
    wordWrap,
}) {
    const { t, theme } = useDataExplorationContext();
    const { data: sampleValue, mutate, isLoading, error } = useSampleValue(queryText, selectedColumnName, timeRange);

    return (
        <>
            <div className={styles.columnInfoHeader}>
                {title}
                <div className={styles.sampleValueHeader}>
                    <Subtitle1>{t.dataExploration.schemaInsights.sampledValue}</Subtitle1>
                    <Button
                        size="small"
                        appearance="transparent"
                        icon={<ArrowShuffle20Regular />}
                        onClick={() => mutate()}
                    >
                        {t.dataExploration.schemaInsights.sampleAgain}
                    </Button>
                </div>
            </div>
            <ColumnInfoStatusWrapper isLoading={isLoading} errorMessage={error?.message}>
                <div className={styles.sampleValueContentArea}>
                    <MonacoEditorJSON
                        json={JSON.stringify(sampleValue)}
                        isDark={theme === Theme.Dark}
                        editorOptions={{
                            lineNumbers: 'on',
                            lineNumbersMinChars: 3,
                            wordWrap: wordWrap ? 'on' : 'off',
                        }}
                    />
                </div>
            </ColumnInfoStatusWrapper>
        </>
    );
});
