import { usePrevious } from '../utils/hooks.ts';
import type { ExtendedVisualizationOptions } from '../utils/visualization.ts';

export function useShouldRecreateChartObject(props: {
    visualizationOptions: ExtendedVisualizationOptions;
    isDarkTheme?: boolean;
}): boolean {
    // highcharts does not know how to switch themes when updating the chart object, only when recreating it.
    // Thus whenever we're changing themes, we're signaling highcharts to recreate the object by (ab)using the immutable flag.
    // also: highcharts doesn't handle switching between chart types very well (for example - it might switch the axes).
    // Thus recreate the chart whenever we're changing chart type

    const prevDarkTheme = usePrevious(props.isDarkTheme);
    const prevVisualizationOptions = usePrevious(props.visualizationOptions);
    return (
        (prevDarkTheme !== undefined && prevDarkTheme !== props.isDarkTheme) ||
        prevVisualizationOptions?.Visualization !== props.visualizationOptions.Visualization ||
        prevVisualizationOptions?.YAxis !== props.visualizationOptions.YAxis
    );
}
