import React from 'react';
import { mergeClasses } from '@fluentui/react-components';

import { MonacoEditorKQL } from '@kusto/ui-components';
import { Theme, useAbortSignal } from '@kusto/utils';

import { useDataExplorationContext } from '../../../context/DataExplorationContext';

import styles from './KQLViewer.module.scss';

export interface KQLViewerProps {
    kqlText: string;
    hideLineNumbers?: boolean;
    className?: string;
}

export const KQLViewer: React.FunctionComponent<KQLViewerProps> = ({ kqlText, hideLineNumbers, className }) => {
    const { clusterUrl, databaseName, theme } = useDataExplorationContext();

    const abortSignal = useAbortSignal();

    const schema = {
        Databases: {
            [databaseName]: {
                Name: databaseName,
                Tables: {
                    // $G$ TODO - load schema + and support CDN syntax highlighting
                    // [schemaTableName]: {
                    //     Name: schemaTableName,
                    //     DocString: '',
                    //     OrderedColumns: (tableSchema.value?.columns ?? []).map((column) => {
                    //         return {
                    //             Name: column.field,
                    //             Type: column.columnType,
                    //             CslType: column.dataType,
                    //         };
                    //     }),
                    // },
                },
            },
        },
    };

    return (
        <div className={mergeClasses(styles.kqlViewer, className)}>
            <MonacoEditorKQL
                kqlText={kqlText}
                context={{ clusterUrl, databaseName, schema }}
                isDark={theme === Theme.Dark}
                abortSignal={abortSignal}
                editorOptions={{
                    lineNumbers: hideLineNumbers ? 'off' : 'on',
                }}
            />
        </div>
    );
};
