import React from 'react';
import { Body1, Button } from '@fluentui/react-components';

import styles from './ColumnList.module.scss';

export interface ColumnDetails {
    icon: React.ReactElement;
    title: string;
    suffixIcon?: React.ReactElement;
    id: string;
    columnInfo?: React.ReactNode;
    type: 'column';
}

interface SectionHeaderProps {
    title: string;
}

export interface ColumnListSectionHeader extends SectionHeaderProps {
    type: 'sectionHeader';
    reactKey: React.Key;
}

export interface ColumnListProps {
    columns: (ColumnDetails | ColumnListSectionHeader)[];
    selectedId?: string;
    onSelect(c: string): void;
    appearance?: 'subtle' | 'pretty';
}

interface ColumnProps extends Omit<ColumnDetails, 'id' | 'type'> {
    selected?: boolean;
    onSelect(): void;
}

const Column: React.FC<ColumnProps> = ({ icon, title, suffixIcon, selected, onSelect, columnInfo }) => {
    return (
        <div className={`${styles.listItemContainer} ${selected ? styles.selected : ''}`}>
            <Button onClick={onSelect} appearance="transparent" icon={icon} className={styles.listItemButton}>
                <Body1>{title}</Body1>
                <div className={styles.suffixIcon}>{suffixIcon}</div>
                <div className={styles.columnInfoContainer}>{columnInfo}</div>
            </Button>
        </div>
    );
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ title }) => (
    <div className={styles.listSectionHeader}>{title}</div>
);

export const ColumnList: React.FC<ColumnListProps> = ({ columns, selectedId, onSelect, appearance = 'subtle' }) => {
    return (
        <div className={`${styles.listContainer} ${appearance == 'pretty' ? styles.pretty : ''}`}>
            {columns.map((colOrHeader) =>
                colOrHeader.type === 'sectionHeader' ? (
                    <SectionHeader title={colOrHeader.title} key={colOrHeader.reactKey} />
                ) : (
                    <Column
                        key={colOrHeader.id}
                        title={colOrHeader.title}
                        icon={colOrHeader.icon}
                        suffixIcon={colOrHeader.suffixIcon}
                        columnInfo={colOrHeader.columnInfo}
                        selected={colOrHeader.id === selectedId}
                        onSelect={() => onSelect(colOrHeader.id)}
                    />
                )
            )}
        </div>
    );
};
