import * as React from 'react';
import { assertNever, MessageBar, MessageBarType } from '@fluentui/react';
import { observer } from 'mobx-react-lite';

import { Slideout } from '@kusto/ui-components';

import { KweVisualFwkLocale } from '../../types';
import type { VisualOptionsModel } from '../model/model';

import * as styles from './SchemaErrorBanner.module.scss';

interface Message {
    level: MessageBarType;
    text: React.ReactNode;
}

const fillerMessage: Message = { level: MessageBarType.info, text: null };

export interface SchemaErrorBannerProps {
    t: KweVisualFwkLocale;
    model: VisualOptionsModel;
}

export const SchemaErrorBanner: React.FC<SchemaErrorBannerProps> = observer(function SchemaErrorBanner({ t, model }) {
    const schema = model.schema.get();

    const [lastMessage, setLastMessage] = React.useState<Message>(fillerMessage);

    const incomingMessage = React.useMemo((): Message | undefined => {
        switch (schema.kind) {
            case 'available':
                if (schema.errorMessage) {
                    return { level: MessageBarType.warning, text: schema.errorMessage };
                }
                return undefined;
            case 'unavailable':
                return { level: MessageBarType.error, text: schema.errorMessage };
            case 'uninitialized':
                return undefined;
            default:
                assertNever(schema);
        }
    }, [schema]);

    React.useEffect(() => {
        if (incomingMessage) {
            setLastMessage(incomingMessage);
        }
    }, [incomingMessage]);

    const message = incomingMessage ?? lastMessage;

    const open = incomingMessage !== undefined;

    return (
        <Slideout className={styles.errorBannerSlideout} open={open}>
            <MessageBar
                messageBarType={message.level}
                truncated={true}
                isMultiline={false}
                overflowButtonAriaLabel={t.visualFwk.visualConfig.errorBannerExpandButtonTitle}
            >
                {message.text}
            </MessageBar>
        </Slideout>
    );
});
