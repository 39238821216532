import * as React from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { ITextField, TextField } from '@fluentui/react/lib/TextField';
import { IRefObject, KeyCodes } from '@fluentui/react/lib/Utilities';

import { CustomListDropdown, CustomListDropdownProps } from './components/customListDropdown/CustomListDropdown';
import {
    CustomListDropdownMenu,
    CustomListDropdownMenuProps,
} from './components/customListDropdown/CustomListDropdownMenu';
import { useCustomListDropdownDispatch } from './components/customListDropdown/CustomListDropdownMenuContext';
import { CustomListDropdownHeaderProps, RTDDropdownOption } from './components/customListDropdown/types';
import { RTD_DROPDOWN } from './constants';
import { NoData } from './NoData';
import { useRtdProviderStrings } from './stringsContext';

import * as standardStyles from './components/customListDropdown/CustomListDropdownMenu.module.scss';
import * as styles from './InputDropdown.module.scss';

export interface InputDropdownAdditionalProps {
    inputType?: string;

    placeholder?: string;
    validationError?: string;
    onInputChange:
        | ((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void)
        | undefined;
    onEnter: ((event: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
    onEscape: ((event: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
}

const onRenderMenu = (props: InputDropdownProps & CustomListDropdownMenuProps) => <InputDropdownMenu {...props} />;

export interface InputDropdownProps extends CustomListDropdownProps, InputDropdownAdditionalProps {}

export const InputDropdown: React.FC<InputDropdownProps> = ({ selectedKey, ...props }) => {
    const strings = useRtdProviderStrings();
    // Options cannot be apart of the above spread because it is used in onRenderMenu
    const externalOptions = props.options;

    const activeSelectionKey = selectedKey ? `${selectedKey}-selected` : undefined;

    const options = React.useMemo(
        (): RTDDropdownOption[] => [
            {
                key: activeSelectionKey ? activeSelectionKey : RTD_DROPDOWN.emptySelection,
                text:
                    selectedKey === RTD_DROPDOWN.parameterAllSelection
                        ? strings.utils.components.rtdDropdown.allTitle
                        : selectedKey ?? RTD_DROPDOWN.emptySelection,
                hidden: true,
            },
            ...externalOptions,
        ],
        [activeSelectionKey, selectedKey, externalOptions, strings]
    );

    return (
        <CustomListDropdown
            {...props}
            selectedKey={activeSelectionKey}
            options={options}
            // Unfortunately, there doesn't seem to be a nice way to arrange the types so that this cast is unnecessary
            onRenderMenu={onRenderMenu as (p: CustomListDropdownMenuProps) => JSX.Element | null}
        />
    );
};

interface InputDropdownMenuProps extends InputDropdownProps, CustomListDropdownMenuProps {}

const InputDropdownMenu: React.FC<InputDropdownMenuProps> = (props) => {
    const {
        inputType,
        selectedKey,
        options,
        ariaLabel,
        placeholder,
        validationError,
        onEnter,
        onEscape,
        onInputChange,
        onRenderHeaderPrefix,
        telemetry,
    } = props;
    const strings = useRtdProviderStrings();

    const dropdownTelemetry = React.useMemo(() => telemetry.bind({ component: 'InputDropdownMenu' }), [telemetry]);

    const defaultOption = React.useMemo(
        () => (selectedKey ? options.find((o) => o.key === selectedKey) : undefined),
        [selectedKey, options]
    );

    const onRenderHeader = (innerProps: CustomListDropdownHeaderProps) => (
        <InputDropdownMenuHeader
            {...innerProps}
            inputType={inputType}
            ariaLabel={ariaLabel}
            placeholder={placeholder}
            defaultValue={
                defaultOption?.key === `${RTD_DROPDOWN.parameterAllSelection}-selected` ? '' : defaultOption?.text
            }
            validationError={validationError}
            onInputChange={onInputChange}
            onEnter={onEnter}
            onEscape={onEscape}
        />
    );

    return (
        <CustomListDropdownMenu
            {...props}
            onRenderHeaderPrefix={onRenderHeaderPrefix}
            onRenderHeader={onRenderHeader}
            noData={
                <NoData className={standardStyles.noResults} message={strings.utils.components.rtdDropdown.noRecents} />
            }
            // The first element is fake, so we can have 1 item and still have no data
            noDataCount={1}
            telemetry={dropdownTelemetry}
        />
    );
};

interface InputDropdownMenuHeaderProps extends CustomListDropdownHeaderProps {
    inputType: string | undefined;

    ariaLabel: string | undefined;
    placeholder: string | undefined;
    defaultValue: string | undefined;
    validationError: string | undefined;

    onInputChange:
        | ((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void)
        | undefined;
    onEnter: ((event: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
    onEscape: ((event: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
}

const InputDropdownMenuHeader: React.FC<InputDropdownMenuHeaderProps> = ({
    inputType,
    ariaLabel,
    placeholder,
    defaultValue,
    validationError,
    onInputKeyDown,
    onInputChange: externalOnInputChange,
    onEnter,
    onEscape,
    inputRef,
}) => {
    const strings = useRtdProviderStrings();
    const [dispatch, getState] = useCustomListDropdownDispatch();

    const onInputChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
            externalOnInputChange?.(event, newValue);

            // Typed into textbox, clear activeIndex
            dispatch({
                type: 'setActiveIndex',
                index: undefined,
            });
        },
        [externalOnInputChange, dispatch]
    );

    const onKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            switch (event.keyCode) {
                case KeyCodes.enter: {
                    if (onEnter && getState().activeIndex === undefined) {
                        onEnter(event);
                        return;
                    }

                    break;
                }
                case KeyCodes.escape: {
                    if (onEscape) {
                        onEscape(event);
                        return;
                    }

                    break;
                }
                default: {
                    break;
                }
            }

            // If the event wasn't handled above (by returning), continue running the event execution chain
            onInputKeyDown(event);
        },
        [onEnter, onEscape, onInputKeyDown, getState]
    );

    return (
        <>
            <TextField
                componentRef={inputRef as IRefObject<ITextField>}
                className={standardStyles.search}
                type={inputType}
                autoComplete="off"
                onChange={onInputChange}
                onKeyDown={onKeyDown}
                ariaLabel={ariaLabel}
                placeholder={placeholder}
                defaultValue={defaultValue}
                errorMessage={validationError}
            />
            <Text className={styles.recentHeader}>{strings.utils.components.rtdDropdown.recent}</Text>
        </>
    );
};
