.background {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .container {
        text-align: center;
        color: var(--colorNeutralForeground1);

        .learnMore {
            display: flex;
            justify-content: center;
            color: var(--colorNeutralForeground4);
        }
    }
}
