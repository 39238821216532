import { getScrollParent } from './getScrollParent';
/**
 * Allows to mimic a behavior from V1 of Popper and accept `window` and `scrollParent` as strings.
 */ export function getBoundary(element, boundary) {
    if (boundary === 'window') {
        return element === null || element === void 0 ? void 0 : element.ownerDocument.documentElement;
    }
    if (boundary === 'clippingParents') {
        return 'clippingAncestors';
    }
    if (boundary === 'scrollParent') {
        let boundariesNode = getScrollParent(element);
        if (boundariesNode.nodeName === 'BODY') {
            boundariesNode = element === null || element === void 0 ? void 0 : element.ownerDocument.documentElement;
        }
        return boundariesNode;
    }
    return boundary;
}
