/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { EntityType } from '../../../common';
import { Cluster, QueryStoreEnv } from '../../../stores';
import { Action, ITreeEntityMapper } from '../actions';
import { iconMapper } from './IconMapper';
import { RowDataTypeBase } from './RowDataTypeBase';

export class ClusterRowDataType extends RowDataTypeBase {
    get cluster(): Cluster {
        return this.baseData as Cluster;
    }

    constructor(private readonly env: QueryStoreEnv, cluster: Cluster, treeEntityMapper: ITreeEntityMapper) {
        const alias = decodeURIComponent(cluster.getAlias());

        // Affiliated cluster icon handling
        const { username, tenantId } = env.authProvider.getAccount() || {};
        const { username: affiliatedUsername, tenantId: affiliatedTenantId } = cluster.basicAccountInfo || {};
        const isAffiliated =
            !env.featureFlags.IFrameAuth &&
            env.featureFlags.ClusterAccount &&
            ((affiliatedUsername && affiliatedUsername !== username) ||
                (affiliatedTenantId && affiliatedTenantId !== tenantId));
        const iconName = `${iconMapper[cluster.serviceOffering] ? cluster.serviceOffering : cluster.entityType}${
            isAffiliated ? ' Affiliated' : ''
        }`;

        super(
            cluster.id,
            [cluster.name],
            cluster.fetchState === 'failed' ? `Error: ${alias}` : alias,
            EntityType.Cluster,
            cluster,
            iconMapper[iconName] ?? iconMapper[cluster.entityType],
            treeEntityMapper
        );
        this.isFavorite = cluster.isFavorite;
        this.actions = this.getActions();
        this.details = this.cluster.isFetching && !this.cluster.isBackgroundFetch ? 'Loading' : undefined;
    }

    setIsFavorite(isFavorite: boolean) {
        this.isFavorite = isFavorite;
        this.refreshActions();
    }

    private refreshActions() {
        this.actions = this.getActions();
    }

    private getActions(): Action[] {
        const isFavorite = !!this.isFavorite;
        const cluster = this.cluster;
        if (this.cluster.isFetching && !this.cluster.isBackgroundFetch) {
            return [this.treeEntityMapper.getAction('favorite', isFavorite, undefined)!];
        }

        let actions = [];
        switch (cluster.fetchState) {
            case 'notStarted':
                actions = this.env.featureFlags.ShowConnectionButtons
                    ? [
                          this.treeEntityMapper.getAction('refresh', isFavorite, undefined)!,
                          this.treeEntityMapper.getAction('delete', isFavorite, undefined)!,
                          this.treeEntityMapper.getAction('edit', isFavorite, undefined)!,
                          this.treeEntityMapper.getAction('favorite', isFavorite, undefined)!,
                      ]
                    : [
                          this.treeEntityMapper.getAction('refresh', isFavorite, undefined)!,
                          this.treeEntityMapper.getAction('favorite', isFavorite, undefined)!,
                      ];
                break;
            case 'failed':
                actions = this.env.featureFlags.ShowConnectionButtons
                    ? [
                          this.treeEntityMapper.getAction('refresh', isFavorite, undefined)!,
                          this.treeEntityMapper.getAction('delete', isFavorite, undefined)!,
                          this.treeEntityMapper.getAction('edit', isFavorite, undefined)!,
                          this.treeEntityMapper.getAction(
                              'info',
                              isFavorite,
                              this.cluster.fetchStateError.errorMessage
                          )!,
                          this.treeEntityMapper.getAction('favorite', isFavorite, undefined)!,
                      ]
                    : [
                          this.treeEntityMapper.getAction('refresh', isFavorite, undefined)!,
                          this.treeEntityMapper.getAction(
                              'info',
                              isFavorite,
                              this.cluster.fetchStateError.errorMessage
                          )!,
                          this.treeEntityMapper.getAction('favorite', isFavorite, undefined)!,
                      ];
                break;
            case 'done':
                actions = this.treeEntityMapper.getActions(this.entityType, isFavorite, undefined);
                break;
            default:
                throw new Error('unexpected fetchState ' + this.cluster.fetchState);
        }

        return actions.filter((action) => action);
    }
}
