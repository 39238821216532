import React from 'react';
import { observer } from 'mobx-react-lite';

import * as client from '@kusto/client';
import * as Fwk from '@kusto/visual-fwk';
import { ExtendedVisualizationOptions, inferMapColumns, KustoAzureMap } from '@kusto/visualizations';

import { defaultVisualOptions } from '../charting';
import { standardSizes } from '../constants';
import { KweRtdVisualContext } from '../context';

interface HeuristicsSuccess
    extends Pick<
        ExtendedVisualizationOptions,
        'GeoPointColumn' | 'Latitude' | 'Longitude' | 'LabelColumn' | 'SizeColumn'
    > {
    columns: readonly client.KustoColumn[];
    rows: readonly client.KustoQueryResultRowObject[];
}

type Heuristics = null | HeuristicsSuccess;

type MapModelDef = keyof typeof mapModel;

const heuristicsFnc: Fwk.VisualConfigHeuristicsFnc<MapModelDef, Heuristics> = ({ queryResult, visualOptions }) => {
    if (queryResult === undefined) {
        return null;
    }

    const columns = client.clientColumnsFromKweColumns(queryResult.dataFrame.fields);
    const rows = client.queryAreaRowObjectsFromDataFrame(queryResult.dataFrame);

    const firstElement = rows[0];

    const labelColumn = visualOptions.map__labelColumn;
    const sizeColumn = visualOptions.map__sizeColumn;
    const longitudeColumn = visualOptions.map__longitudeColumn;
    const latitudeColumn = visualOptions.map__latitudeColumn;
    const geoPointColumn = visualOptions.map__geoPointColumn;
    const geoType = visualOptions.map__geoType;

    const { Latitude, Longitude, GeoPointColumn, LabelColumn, SizeColumn } = inferMapColumns(
        {
            Longitude: longitudeColumn,
            Latitude: latitudeColumn,
            GeoPointColumn: geoPointColumn,
            LabelColumn: labelColumn,
            SizeColumn: sizeColumn,
            GeoType: geoType,
        },
        columns,
        firstElement
    );

    return { Latitude, Longitude, GeoPointColumn, LabelColumn, SizeColumn, columns, rows, firstElement };
};

function createComponent(ctx: KweRtdVisualContext): React.FC<Fwk.IDataVisualProps<MapModelDef, Heuristics>> {
    return observer(function RtdMapVisual(props) {
        const visualOptions: ExtendedVisualizationOptions = {
            ...defaultVisualOptions,
            Visualization: 'map',
            Kind: props.visualOptions.map__type,
            Latitude: props.heuristics?.Latitude ?? null,
            Longitude: props.heuristics?.Longitude ?? null,
            LabelColumn: props.heuristics?.LabelColumn ?? null,
            SizeColumn: props.heuristics?.SizeColumn ?? null,
            DisableSize: props.visualOptions.map__sizeDisabled,
            GeoType: props.visualOptions.map__geoType,
            GeoPointColumn: props.heuristics?.GeoPointColumn ?? null,
            /**
             * We want to specify XColum and yColumns to deal with a bug we currently have with maps:
             * Having both geo point and numeric columns causes a crush,
             * The current Logic doesn't know which columns to use, so we have to specify it
             */
            XColumn: props.heuristics?.Latitude ?? props.heuristics?.GeoPointColumn ?? null,
            YColumns:
                props.heuristics?.Longitude !== undefined && props.heuristics?.Longitude !== null
                    ? [props.heuristics?.Longitude]
                    : null,
        };

        return (
            <KustoAzureMap
                visualizationOptions={visualOptions}
                azureMapSubscriptionKey={ctx.azureMapSubscriptionKey}
                rows={props.heuristics?.rows}
                isDarkTheme={props.isDarkTheme}
                strings={ctx.strings.visualizations}
                formatMessage={props.formatMessage}
            />
        );
    });
}

function geoPointInput(ctx: KweRtdVisualContext) {
    return Fwk.createTileInput.column<'map__geoPointColumn', Heuristics>(
        'map__geoPointColumn',
        ctx.strings.rtdProvider.visuals.map$map__geoPointColumnLabel,
        {
            selectInferColumn: (options) => {
                if (options.get('map__geoPointColumn') !== null) {
                    return undefined;
                }
                return options.getHeuristics()?.GeoPointColumn ?? undefined;
            },
        }
    );
}

function latitudeInput(ctx: KweRtdVisualContext) {
    return Fwk.createTileInput.column<'map__latitudeColumn', Heuristics>(
        'map__latitudeColumn',
        ctx.strings.rtdProvider.visuals.map$map__latitudeColumn$label,
        {
            selectInferColumn: (options) => {
                if (options.get('map__latitudeColumn') !== null) {
                    return undefined;
                }
                return options.getHeuristics()?.Latitude ?? undefined;
            },
        }
    );
}

function longitudeInput(ctx: KweRtdVisualContext) {
    return Fwk.createTileInput.column<'map__longitudeColumn', Heuristics>(
        'map__longitudeColumn',
        ctx.strings.rtdProvider.visuals.map$map__longitudeColumn$label,
        {
            selectInferColumn: (options) => {
                if (options.get('map__longitudeColumn') !== null) {
                    return undefined;
                }
                return options.getHeuristics()?.Longitude ?? undefined;
            },
        }
    );
}

function labelInput(ctx: KweRtdVisualContext) {
    return Fwk.createTileInput.column<'map__labelColumn', Heuristics>(
        'map__labelColumn',
        ctx.strings.rtdProvider.visuals.map$map__labelColumnLabel,
        {
            selectInferColumn: (options) => {
                if (options.get('map__labelColumn') !== null) {
                    return undefined;
                }
                return options.getHeuristics()?.LabelColumn ?? undefined;
            },
        }
    );
}

function geoTypeInput(ctx: KweRtdVisualContext) {
    return Fwk.createTileInput.staticDropdown(
        ['map__geoType'],
        ctx.strings.rtdProvider.visuals.map$map__geoType$label,
        [
            { key: 'infer', text: ctx.strings.rtdProvider.visuals.map$map__geoType$infer },
            { key: 'numeric', text: ctx.strings.rtdProvider.visuals.map$map__geoType$numeric },
            { key: 'geoPoint', text: ctx.strings.rtdProvider.visuals.map$map__geoType$geoPoint },
        ],
        (o) => o.get('map__geoType'),
        (v, m) => m.set('map__geoType', v)
    );
}

function sizeInput(ctx: KweRtdVisualContext) {
    return Fwk.createTileInput.column<'map__sizeColumn', Heuristics>(
        'map__sizeColumn',
        ctx.strings.rtdProvider.visuals.map$map__sizeColumn$label,
        {
            selectInferColumn: (options) => {
                if (options.get('map__sizeColumn') !== null) {
                    return undefined;
                }
                return options.getHeuristics()?.SizeColumn ?? undefined;
            },
        }
    );
}

const mapModel = {
    map__type: 'bubble',
    map__latitudeColumn: null,
    map__longitudeColumn: null,
    map__labelColumn: null,
    map__sizeColumn: null,
    map__sizeDisabled: true,
    map__geoType: 'infer',
    map__geoPointColumn: null,
} as const;

export function mapVisualConfig(ctx: KweRtdVisualContext): Fwk.VisualTypeConfig<MapModelDef, Heuristics> {
    return {
        label: ctx.strings.rtdProvider.visuals.map.visualTitle,
        iconName: 'MapPin',
        config: {
            model: mapModel,
            Component: createComponent(ctx),
            heuristics: heuristicsFnc,
            ...standardSizes,
            inputLayout: (options) => {
                const geoType = options.get('map__geoType');
                const geoTypeToInput: Record<typeof geoType, Fwk.VisualInput<MapModelDef, Heuristics>[] | undefined> = {
                    numeric: [latitudeInput(ctx), longitudeInput(ctx)],
                    geoPoint: [geoPointInput(ctx)],
                    infer: [],
                };
                const dataSegmentInputs = [];
                if (geoTypeToInput[geoType] !== undefined) {
                    dataSegmentInputs.push(...(geoTypeToInput[geoType] as Fwk.VisualInput<MapModelDef>[]));
                }
                return {
                    visual: {
                        segments: [
                            Fwk.tileInputSegment(ctx.strings.rtdProvider.visuals.input.segment$generalTitle, {}),
                            Fwk.tileInputSegment(
                                ctx.strings.rtdProvider.visuals.input.segment$dataTitle,
                                {},
                                geoTypeInput(ctx),
                                ...dataSegmentInputs,
                                labelInput(ctx)
                            ),
                            Fwk.tileInputSegment<'map__sizeDisabled' | 'map__sizeColumn', Heuristics>(
                                ctx.strings.rtdProvider.visuals.input.segment$sizeTitle,
                                {
                                    toggle: {
                                        optionKey: 'map__sizeDisabled',
                                        titleText: ctx.strings.rtdProvider.visuals.input.segment$mapSizeToggleTitleText,
                                        invert: true,
                                        labels: {
                                            enabled:
                                                ctx.strings.rtdProvider.visuals.input
                                                    .segment$toggleShowHideLabel$enabled,
                                            disabled:
                                                ctx.strings.rtdProvider.visuals.input
                                                    .segment$toggleShowHideLabel$disabled,
                                        },
                                    },
                                },
                                sizeInput(ctx)
                            ),
                        ],
                    },
                };
            },
        },
    };
}
