import React, { useMemo, useState } from 'react';
import type {
    Column as AgGridColumn,
    BaseExportParams,
    CellClickedEvent,
    CellContextMenuEvent,
    CellRange,
    ColumnApi,
    ColumnPinnedEvent,
    ColumnPivotModeChangedEvent,
    ColumnVisibleEvent,
    FilterModifiedEvent,
    GetMainMenuItemsParams,
    GridApi,
    GridOptions,
    GridReadyEvent,
    SortChangedEvent,
    ToolPanelVisibleChangedEvent,
} from '@ag-grid-community/core';
import { ContextualMenuItemType, IconButton, IContextualMenuItem } from '@fluentui/react';
import * as clipboard from 'clipboard-polyfill';
import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import * as mobx from 'mobx';
import { inject, observer } from 'mobx-react';
import { isMacOs } from 'react-device-detect';
import { GlobalHotKeys as ReactGlobalHotKeys } from 'react-hotkeys';
import ReactMonacoEditor from 'react-monaco-editor';

import { GridWithExpand, GridWithExpandHandle, GridWithExpandProps } from '@kusto/ag-grid';
import * as kusto from '@kusto/client';
import { useRender } from '@kusto/ui-components';
import { IKweTelemetry, KweException, Mutable, Theme, useAbortSignal } from '@kusto/utils';
import {
    DetailsViewType,
    EXPAND_ROW_COL_ID,
    GridExpandView,
    GridState,
    GridWithExpandOld,
    GridWithExpandOldProps,
    KustoDataProps,
    withAgGridKustoData,
} from '@kusto/visualizations';

import { Column as ConnectionPaneColumn, EntityType } from '../../common/types';
import { useQueryCore } from '../../core/core';
import { GridStateCache } from '../../stores/gridStateCache';
import {
    MultiTableResult,
    QueryCompletionColumn,
    QueryCompletionColumns,
    QueryCompletionRows,
} from '../../stores/queryCompletionInfo';
import { IRootStore } from '../../stores/rootStore';
import { copyTableResultToClipboard } from '../../utils';
import { generateDatatableStatement, generateFilterStatement } from '../../utils/cslCommandGenerator';
import { ExpandBarItems } from './ExpandBarItems';
import { ExternalContent } from './ExternalResizablePanelProvider';
import { GridStatusPanel } from './GridStatusPanel';
import { openGridLink } from './openGridLink';
import { getRangeSelection } from './utils';

const LevelColumnNames = ['Level', 'Severity'];

enum TraceLevel {
    Verbose = '5',
    Information = '4',
    Warning = '3',
    Error = '2',
    Critical = '1',
}

// Since Information has no coloring class - we skip its prefixes for performance considerations.
const PrefixToTraceLevel: { [prefix: string]: TraceLevel } = {
    verb: TraceLevel.Verbose,
    d: TraceLevel.Verbose,
    // 'i': TraceLevel.Information,
    // 'medium': TraceLevel.Information,
    w: TraceLevel.Warning,
    monitor: TraceLevel.Warning,
    e: TraceLevel.Error,
    u: TraceLevel.Error,
    crit: TraceLevel.Critical,
    fatal: TraceLevel.Critical,
    assert: TraceLevel.Critical,
    high: TraceLevel.Critical,
};

const TraceLevelPrefixes: string[] = Object.keys(PrefixToTraceLevel);

const TraceLevelToClass: { [level: string]: string } = {
    [TraceLevel.Verbose]: 'verbose-color',
    [TraceLevel.Information]: '',
    [TraceLevel.Warning]: 'warning-color',
    [TraceLevel.Error]: 'error-color',
    [TraceLevel.Critical]: 'critical-color',
};

export const verbosityRegex = new RegExp(
    '^(info|err|warn|crit|verb)|' + // begin of the words
        '^(fatal|noise|medium|unexpected|monitor|exception|high|assert' +
        '|e|w|d|dd|i|v|c|trace|normal|usage|correlation|stats|debug)$',
    'i'
);

const ExpandPanel: React.FC<{
    isOpen: boolean;
    setExternalViewContainer: (container: HTMLElement | null) => void;
    onDismissed: () => void;
}> = ({ isOpen, setExternalViewContainer, onDismissed }) => {
    return (
        <ExternalContent
            visible={isOpen}
            allowResize
            width="30%"
            styles={{
                root: {
                    height: '100%',
                    marginLeft: -10,
                    paddingLeft: 10,
                },
                content: {
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                },
            }}
            className="rightExpand"
        >
            <IconButton
                iconProps={{
                    iconName: 'ChromeClose',
                    styles: { root: { fontSize: 12 } },
                }}
                className="expand-close"
                onClick={onDismissed}
            />
            <div style={{ height: '100%' }} ref={setExternalViewContainer} />
        </ExternalContent>
    );
};

const ExpandPanelOld = React.memo(function ExpandPanel({ expandView }: { expandView?: GridExpandView }) {
    const divRef = React.useRef<HTMLElement | null>(null);
    const onReady = React.useCallback(() => {
        if (divRef.current?.parentElement && expandView) {
            expandView.onReady(divRef.current.parentElement);
        }
    }, [expandView, divRef]);
    return (
        <ExternalContent
            visible={expandView !== undefined}
            allowResize
            width="30%"
            styles={{
                root: {
                    height: '100%',
                    marginLeft: -10,
                    paddingLeft: 10,
                },
                content: {
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                },
            }}
            onLayerMounted={onReady}
            className="rightExpand"
        >
            <IconButton
                iconProps={{
                    iconName: 'ChromeClose',
                    styles: { root: { fontSize: 12 } },
                }}
                className="expand-close"
                onClick={expandView?.onDismissed}
            />
            <div
                ref={(ref) => {
                    divRef.current = ref;
                    onReady();
                }}
            />
        </ExternalContent>
    );
});

const KustoDataGridOld = withAgGridKustoData(GridWithExpandOld);

const KustoDataGrid = withAgGridKustoData(GridWithExpand, true);

function onFilterModified(column: AgGridColumn, api: GridApi, telemetry: IKweTelemetry) {
    // using private APIs
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filter = api.getFilterInstance(column.getColId()) as any;
    if (filter) {
        const filter1 = {
            stringValue: `${filter.eValue1?.value ?? ''}`.trim(),
            noneStringValue: filter.eValueFrom1?.value,
            operation: filter.eType1.value, // 'equals', 'contains', ...
        };

        const filter2 = {
            stringValue: `${filter.eValue2?.value ?? ''}`.trim(),
            noneStringValue: filter.eValueFrom2?.value,
            operation: filter.eType2.value,
        };

        const operation1 = filter1.stringValue || filter1.noneStringValue ? filter1.operation : undefined;
        const operation2 = filter2.stringValue || filter2.noneStringValue ? filter2.operation : undefined;

        telemetry.event('grid-column-filtered', {
            operation1: operation1,
            operation2: !!operation1 ? operation2 : undefined,
        }); // ignore operation if there is no operation 1
    }
}

let onSortChangedTriggerCount = 0;

function onSortChanged(event: SortChangedEvent, telemetry: IKweTelemetry) {
    // ignore first event that is sent by the grid after the grid is mounted.
    if (onSortChangedTriggerCount > 0) {
        const columnsState = event.columnApi.getColumnState();
        let countAscColumns = 0;
        let countDescColumns = 0;
        columnsState.forEach((colModel) => (colModel.sort === 'asc' ? countAscColumns++ : countDescColumns++));
        telemetry.event('grid-column-sorted', {
            numberOfAscColumns: `${countAscColumns}`,
            numberOfDescColumns: `${countDescColumns}`,
        });
    }
    onSortChangedTriggerCount++;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function kustoDataGridRenderEditor(editorDidMount: any, options: any, theme?: Theme) {
    const themeName = theme ? (theme === Theme.Light ? 'kusto-light' : 'kusto-dark') : undefined;
    return <ReactMonacoEditor language="json" options={options} editorDidMount={editorDidMount} theme={themeName} />;
}

function createDataGridTelemetryPropsOld(
    telemetry: IKweTelemetry,
    signal: AbortSignal
): Partial<GridWithExpandOldProps> {
    const events = {
        onColumnMoved: debounce(() => {
            telemetry.event('grid-column-moved');
        }, 2000),
        onColumnPinned: (e: ColumnPinnedEvent) => telemetry.event('grid-column-pinned', { pinned: `${e.pinned}` }),
        onColumnPivotModeChanged: (e: ColumnPivotModeChangedEvent) =>
            telemetry.event('grid-pivot-mode', { pivotModeEnabled: `${e.columnApi.isPivotMode()}` }),
        onToolPanelVisibleChanged: (e: ToolPanelVisibleChangedEvent) =>
            telemetry.event('columns-panel-visible', { visible: e.api.isToolPanelShowing() }),
        onColumnRowGroupChanged: () => telemetry.event('grid-column-grouped-changed'),
        onColumnVisible: (e: ColumnVisibleEvent) => telemetry.event('grid-column-visible', { visible: `${e.visible}` }),
        onEditorOpen: () => telemetry.event('grid-cell-expanded'),
        onFilterModified: debounce((e: FilterModifiedEvent) => onFilterModified(e.column, e.api, telemetry), 2000),
        onSortChanged: (e: SortChangedEvent) => onSortChanged(e, telemetry),
        renderEditor: kustoDataGridRenderEditor,
    };

    signal.addEventListener('abort', () => {
        events.onColumnMoved.flush();
        events.onFilterModified.flush();
    });

    return events;
}

function createDataGridTelemetryProps(telemetry: IKweTelemetry, signal: AbortSignal): Partial<GridWithExpandProps> {
    const events = {
        onColumnMoved: debounce(() => {
            telemetry.event('grid-column-moved');
        }, 2000),
        onColumnPinned: (e: ColumnPinnedEvent) => telemetry.event('grid-column-pinned', { pinned: `${e.pinned}` }),
        onColumnPivotModeChanged: (e: ColumnPivotModeChangedEvent) =>
            telemetry.event('grid-pivot-mode', { pivotModeEnabled: `${e.columnApi.isPivotMode()}` }),
        onToolPanelVisibleChanged: (e: ToolPanelVisibleChangedEvent) =>
            telemetry.event('columns-panel-visible', { visible: e.api.isToolPanelShowing() }),
        onColumnRowGroupChanged: () => telemetry.event('grid-column-grouped-changed'),
        onColumnVisible: (e: ColumnVisibleEvent) => telemetry.event('grid-column-visible', { visible: `${e.visible}` }),
        onEditorOpen: () => telemetry.event('grid-cell-expanded'),
        onFilterModified: debounce((e: FilterModifiedEvent) => onFilterModified(e.column, e.api, telemetry), 2000),
        onSortChanged: (e: SortChangedEvent) => onSortChanged(e, telemetry),
    };

    signal.addEventListener('abort', () => {
        events.onColumnMoved.flush();
        events.onFilterModified.flush();
    });

    return events;
}

interface KustoGridWithExpandOldProps
    extends Omit<KustoDataProps, 'theme'>,
        Omit<GridWithExpandOldProps, 'strings' | 'renderEditor' | 'openExternalView' | 'closeExternalView'> {}

const KustoGridWithExpandOld: React.FC<KustoGridWithExpandOldProps> = observer(function KustoGridWithExpand(props) {
    const core = useQueryCore();
    const abortSignal = useAbortSignal();

    const { closeExternalView, eventProps } = React.useMemo(() => {
        const telemetry = core.telemetry.bind({ component: 'QueryResultGrid' });
        return {
            closeExternalView: () => setExpandView(undefined),
            eventProps: createDataGridTelemetryPropsOld(telemetry, abortSignal),
        };
    }, [abortSignal, core]);

    const [expandView, setExpandView] = useState<GridExpandView>();

    const theme = core.store.settings.theme;
    const locale = core.strings;

    const Grid = useMemo(
        () => (
            <KustoDataGridOld
                {...props}
                theme={theme}
                locale={locale}
                openExternalView={setExpandView}
                closeExternalView={closeExternalView}
                {...eventProps}
                renderEditor={kustoDataGridRenderEditor}
                timezone={props.timezone}
            />
        ),
        [closeExternalView, theme, locale, eventProps, props]
    );

    return (
        <>
            {Grid}
            <ExpandPanelOld expandView={expandView} />
        </>
    );
});

interface KustoGridWithExpandProps
    extends Omit<KustoDataProps, 'theme' | 'locale'>,
        Omit<GridWithExpandProps, 'theme' | 'strings' | 'openExternalView' | 'closeExternalView'> {}

const KustoGridWithExpand: React.FC<KustoGridWithExpandProps> = observer(function KustoGridWithExpand(props) {
    const core = useQueryCore();
    const abortSignal = useAbortSignal();
    const [isExternalViewOpen, setIsExternalViewOpen] = useState(false);
    const [externalViewContainer, setExternalViewContainer] = useState<HTMLElement | null>(null);
    const gridWithExpandRef = React.useRef<GridWithExpandHandle>(null);

    const { closeExternalView, eventProps, openExternalView } = React.useMemo(() => {
        const telemetry = core.telemetry.bind({ component: 'QueryResultGrid' });
        return {
            closeExternalView: () => setIsExternalViewOpen(false),
            openExternalView: () => setIsExternalViewOpen(true),
            eventProps: createDataGridTelemetryProps(telemetry, abortSignal),
        };
    }, [abortSignal, core]);

    const theme = core.store.settings.theme;
    const locale = core.strings;

    const Grid = useMemo(
        () => (
            <KustoDataGrid
                {...props}
                theme={theme}
                openExternalView={openExternalView}
                closeExternalView={closeExternalView}
                externalViewContainer={externalViewContainer}
                {...eventProps}
                locale={locale}
                timezone={props.timezone}
                debug={core.featureFlags.GridDebugMode}
                gridWithExpandRef={gridWithExpandRef}
            />
        ),
        [
            props,
            theme,
            openExternalView,
            closeExternalView,
            externalViewContainer,
            eventProps,
            locale,
            core.featureFlags.GridDebugMode,
        ]
    );

    return (
        <>
            {Grid}
            <ExpandPanel
                isOpen={isExternalViewOpen}
                setExternalViewContainer={setExternalViewContainer}
                onDismissed={() => gridWithExpandRef.current?.closeAllExpandedRows()}
            />
        </>
    );
});

// The row coloring heuristics is taken from KE code (found in KustoDataGridControl)
function getRowClassBasedOnTraceLevel(
    column: QueryCompletionColumn,
    telemetry: IKweTelemetry,
    ref: React.MutableRefObject<{
        trackedTraceLevels: Record<string, boolean>;
    }>
): GridOptions['getRowClass'] {
    return (params) => {
        let traceLevel: string | undefined;
        if (params && params.data) {
            const rowValue = params.data[column.field];

            if (!rowValue) {
                return '';
            }

            const lowerCaseRowValue = rowValue.toString().toLowerCase();

            if (LevelColumnNames.includes(column.field)) {
                traceLevel = TraceLevelToClass[lowerCaseRowValue];
            }

            const traceLevelPrefix = TraceLevelPrefixes.find((prefix) => lowerCaseRowValue.startsWith(prefix));
            if (!traceLevel && traceLevelPrefix) {
                traceLevel = TraceLevelToClass[PrefixToTraceLevel[traceLevelPrefix]];
            }
        }

        if (traceLevel) {
            if (!ref.current.trackedTraceLevels[traceLevel]) {
                ref.current.trackedTraceLevels[traceLevel] = true;
                telemetry.event('traceLevel', { traceLevel: traceLevel });
            }
            return traceLevel;
        }

        return '';
    };
}

function getRowClassBasedOnValue(
    column: QueryCompletionColumn,
    valuesToHighlightColor: Record<string, string>
): GridOptions['getRowClass'] {
    return (params) => {
        if (params && params.data) {
            const rowValue = params.data[column.field];

            if (!rowValue) {
                return '';
            }
            return valuesToHighlightColor[rowValue];
        }
        return '';
    };
}

function detectVerboseColumn(
    rows: QueryCompletionRows,
    columns: QueryCompletionColumns
): undefined | QueryCompletionColumn {
    if (!columns) {
        return;
    }

    const levelColumn = columns.find((c) => LevelColumnNames.includes(c.field));

    if (
        levelColumn &&
        (levelColumn.columnType === 'long' || levelColumn.columnType === 'int' || levelColumn.columnType === 'string')
    ) {
        const areAllValuesValid = rows!.every((r) => {
            const numberValue = Number(r[levelColumn.field]);
            return numberValue >= 0 && numberValue <= 5;
        });

        if (areAllValuesValid) {
            return levelColumn;
        }
    }

    const verbosityCol = columns.find(
        (col) =>
            col.columnType === 'string' &&
            rows!.every((r) => !!r[col.field] && verbosityRegex.test(r[col.field] as string))
    );

    return verbosityCol;
}

function createAgGridToConnectionPaneColumnMapping(
    agGridColumns: AgGridColumn[],
    columns: { field: string; columnType: string | undefined }[]
) {
    const agGridColumnToPojoColumn: Map<AgGridColumn, ConnectionPaneColumn> = new Map();
    agGridColumns.forEach((col) => {
        // no need to process columns that were already added.
        if (agGridColumnToPojoColumn.has(col)) {
            return;
        }
        const id = col.getId();

        const { columnType, field } = columns!.filter((storeCol) => storeCol.field === id)[0];
        const pojoCol: ConnectionPaneColumn = {
            entityType: EntityType.Column,
            id: id,
            name: field,
            type: columnType as kusto.ColumnType,
        };
        agGridColumnToPojoColumn.set(col, pojoCol);
    });
    return agGridColumnToPojoColumn;
}

export interface QueryResultGridProps extends Omit<KustoDataProps, 'theme' | 'resultToDisplay' | 'onLinkClicked'> {
    gridStateCache?: GridStateCache;
    resultToDisplay: MultiTableResult;
    hideAddAsFilterContextItem?: boolean;
    disableExpandTypes?: boolean;
    disableJPath?: boolean;
    showTotalBar?: boolean; //TODO remove in the future if not not used in both query and dashboard
}

/**
 * get data in cell ranges as a kusto.KustoResult object.
 * Since a result object is rectangular (i.e all rows have the same number of columns), and since range selection don't have to be,
 * the result will be as follows:
 * 1. number of columns will be the union of columns across all the ranges
 * 2. number of rows will be the union of rows across all the ranges
 * 3. let the union of column indexes in the selected ranges be  [I0, I1, I2, ...] where I1 < I2 < I3 ...
 *    the corresponding column indexes in the result will be [0, 1, 2, ...]
 * 4. let the union of row indexes in the selected ranges be [J0, J1, J2 ...] where J1 < J2 < J3 ...
 *    the corresponding row indexes in the result will be [0, 1, 2, ...]
 */
function getResultDataFromCellRange(
    gridApi: GridApi,
    gridSelection: CellRange[],
    queryResult: kusto.KustoResult
): kusto.KustoResult {
    const { columns: kustoQueryResultColumns, tableName, visualizationOptions } = queryResult;

    if (!kustoQueryResultColumns) {
        throw new KweException('getResultDataFromCellRange: no columns or rows in result');
    }

    // #region handle columns

    // note: set preserves order, so we have the list of columns in the order they are on the grid.
    const gridColumnsInSelection = Array.from(new Set(gridSelection.flatMap((range) => range.columns)));

    if (gridColumnsInSelection.length === 0) {
        throw new KweException('getResultDataFromCellRange: no columns in grid selection');
    }

    // convert grid columns to kusto columns (which is what we need to return).
    const kustoColumnsInSelection = gridColumnsInSelection.map((gridColumn) => {
        const gridField = gridColumn.getColDef().field;
        const col = kustoQueryResultColumns.find((resultColumn) => gridField === resultColumn.field);
        if (col === undefined) {
            throw new KweException(
                `getResultDataFromCellRange: did not find column "${gridField}" in query result when mapping grid selection to query result. This is probably a bug.`
            );
        }
        return col;
    });

    // #endregion handle columns

    // #region handle rows

    // now we're building result rows.
    // potentially a single row can exist in multiple ranges.
    // thus we use a map to easily add more data to the same row.
    const outputRowByOriginalRowNumber: Map<number, Mutable<kusto.KustoQueryResultRowObject>> = new Map();

    const model = gridApi.getModel();
    // Go through each range and populate the relevant rows in the rowByIndex map.
    for (const cellRange of gridSelection) {
        if (!cellRange.startRow || !cellRange.endRow) {
            throw new KweException('getResultDataFromCellRange: no start or end row in grid selection');
        }

        // depending on the selection gesture, the start can be before the end.
        const startIndex = Math.min(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex);
        const endIndex = Math.max(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex);

        // go through each row in the range and populate query result row object.
        for (let i = startIndex; i <= endIndex; i++) {
            const row = model.getRow(i);
            if (row === undefined) {
                throw new KweException(`getResultDataFromCellRange: row ${i} is undefined`);
            }

            // create a row object in the Map if this is the first time we've seen this row.

            const rowObject = outputRowByOriginalRowNumber.get(i) ?? outputRowByOriginalRowNumber.set(i, {}).get(i)!;

            // go through each column in this range and populate all values for the row.
            for (const col of cellRange.columns) {
                const field = col.getColDef().field;
                if (field === undefined) {
                    throw new KweException(`getResultDataFromCellRange: column field is undefined`);
                }

                rowObject[field] = gridApi.getValue(col, row);
            }
        }
    }

    // we don't need the map anymore. get the values and sort them by the original row number.
    const resultRows = [...outputRowByOriginalRowNumber].sort((a, b) => a[0] - b[0]).map((entry) => entry[1]);

    // #endregion handle rows

    return { rows: resultRows, columns: kustoColumnsInSelection, tableName, visualizationOptions };
}

export const QueryResultGrid: React.FC<QueryResultGridProps> = observer(function QueryResultGrid({
    gridStateCache,
    resultToDisplay,
    hideAddAsFilterContextItem,
    disableExpandTypes,
    disableJPath,
    showTotalBar,
    getContextMenuItemsKwe,
    ...gridProps
}) {
    const render = useRender();
    const abortSignal = useAbortSignal();
    const core = useQueryCore();
    const [columnToHighlightRowsBy, setColumnToHighlightRowsBy] = useState<string>();

    const telemetry = React.useMemo(() => core.telemetry.bind({ component: 'QueryResultGrid' }), [core]);

    const ref = React.useRef<{
        gridApi?: GridApi;
        columnApi?: ColumnApi;
        trackedTraceLevels: Record<string, boolean>;
    }>({ trackedTraceLevels: {} });

    /**
     * Generate a mapping from connection-pane column names to selected cell values.
     * @param rangeSelections ag grid cell selection
     * @param agGridColumnToConnectionsColumn mapping from ag grid column to connections column
     * @returns a mapping from connections column to selected cell values
     */
    const createColumnToCellsMap = React.useCallback(
        (rangeSelections: CellRange[], agGridColumnToConnectionsColumn: Map<AgGridColumn, ConnectionPaneColumn>) => {
            const model = ref.current.gridApi!.getModel();
            const result: Map<ConnectionPaneColumn, Set<string>> = new Map();
            rangeSelections.forEach((selection) => {
                const cols = selection.columns;

                if (!cols) {
                    return;
                }

                // We won't do anything if you've selected all rows...
                if (!selection.startRow || !selection.endRow) {
                    return;
                }

                const startIndex = selection.startRow.rowIndex;
                const endIndex = selection.endRow.rowIndex;
                // Produce all selected cells in all ranges by col.
                for (let i = startIndex; i <= endIndex; ++i) {
                    for (const col of cols) {
                        const agGridRow = model.getRow(i);
                        if (!agGridRow) {
                            continue;
                        }

                        const cellValue = ref.current.gridApi!.getValue(col, agGridRow);

                        const pojoColumn = agGridColumnToConnectionsColumn.get(col)!;
                        if (!result.has(pojoColumn)) {
                            result.set(pojoColumn, new Set<string>());
                        }

                        result.get(pojoColumn)!.add(cellValue);
                    }
                }
            });
            return result;
        },
        []
    );

    const onLinkClicked = React.useCallback(
        (url: string, ctrlPressed: boolean) => {
            openGridLink(url, ctrlPressed, core, render, abortSignal);
        },
        [core, abortSignal, render]
    );

    const onCellClicked = React.useCallback(
        (params: CellClickedEvent) => {
            params.event?.stopPropagation();

            if (params.node.detail) {
                return;
            }

            const mEvent = params.event as MouseEvent;
            const target = mEvent.target as HTMLElement;

            const targetClasses = mEvent && target && target.classList;
            if (mEvent instanceof MouseEvent && target && targetClasses && targetClasses.contains('highlighted-url')) {
                const columnId = params.column.getColId();
                // data-url is used for pretty table links so
                // it has a higher precedence then the others.
                const url: undefined | string = target.getAttribute('data-url') || params.data?.[columnId];

                if (!url) {
                    return;
                }
                return openGridLink(url, isMacOs ? mEvent.metaKey : mEvent.ctrlKey, core, render, abortSignal);
            }
        },
        [core, render, abortSignal]
    );

    const onGridReady = React.useCallback((e: GridReadyEvent): void => {
        if (!e.api || !e.columnApi) {
            return;
        }

        // Change all columns to be visible in case all the columns were hidden
        const columns = e.columnApi.getAllColumns()?.slice(1);
        if (columns && columns.every((column) => !column.isVisible())) {
            e.columnApi.setColumnsVisible(columns, true);
        }

        ref.current.gridApi = e.api;
        ref.current.columnApi = e.columnApi;
    }, []);

    // Tab pulled off so getGridState and updateGridState close over a specific tab
    const tab = core.store.tabs.tabInContext;

    const getGridState = React.useMemo(
        () =>
            mobx.action(() => {
                // get full grid state from that tab if latest view query or only column state from cache
                return gridStateCache && resultToDisplay && tab
                    ? tab.getVisualState<GridState>() ||
                          gridStateCache.retrieveGridState<GridState>(resultToDisplay.columnsHash)
                    : undefined;
            }),
        [gridStateCache, resultToDisplay, tab]
    );

    React.useEffect(() => {
        setColumnToHighlightRowsBy(getGridState()?.columnToHighlightRowsBy);
    }, [getGridState]);

    const updateGridState = React.useMemo(
        () =>
            mobx.action((newGridState: GridState) => {
                if (tab && resultToDisplay) {
                    tab.setVisualState(newGridState, resultToDisplay);
                }
                // Don't store column state if it too big
                if (gridStateCache && resultToDisplay && newGridState.columns && newGridState.columns.length < 200) {
                    gridStateCache.storeGridState<GridState>(resultToDisplay.columnsHash, {
                        columns: newGridState.columns,
                        columnToHighlightRowsBy: newGridState.columnToHighlightRowsBy,
                    });
                }
            }),
        [gridStateCache, resultToDisplay, tab]
    );

    const updateHighlightByColumn = (colId: string) => {
        setColumnToHighlightRowsBy((oldColId) => (colId === oldColId ? undefined : colId));
    };

    const getExportParams = React.useCallback((): BaseExportParams => {
        return ref.current.columnApi && ref.current.gridApi
            ? {
                  columnKeys: ref.current.columnApi
                      .getAllDisplayedColumns()
                      .filter((col) => col.getColDef().colId !== EXPAND_ROW_COL_ID),
                  shouldRowBeSkipped: (params) => params.node.detail,
              }
            : {};
    }, []);

    const addSelectionAsFilter = React.useCallback(() => {
        telemetry.event('KustoGridWithExpand.ContextMenu.AddAsFilter');

        if (!resultToDisplay) {
            return;
        }

        const { columns } = resultToDisplay;
        if (!columns) {
            return;
        }

        if (!ref.current.gridApi) {
            return;
        }

        const rangeSelections = ref.current.gridApi.getCellRanges();

        if (!rangeSelections) {
            return;
        }

        const agGridColumns = rangeSelections.flatMap((selection) => selection.columns);

        const agGridColumnToConnectionsColumn: Map<AgGridColumn, ConnectionPaneColumn> =
            createAgGridToConnectionPaneColumnMapping(
                agGridColumns,
                columns.map((col) => col)
            );

        const additionalFilters = createColumnToCellsMap(rangeSelections, agGridColumnToConnectionsColumn);

        const additionalFilterString = generateFilterStatement(additionalFilters, false);

        // Set the cursor to the end of the line and paste the filter string
        // In case setPositionAtEndOfLine fails, we'll just paste the filter string at the current cursor position.
        core.kustoEditor.ref?.setPositionAtEndOfLine();
        core.kustoEditor.ref?.pasteText('\n' + additionalFilterString, 'cursorPosition');
    }, [resultToDisplay, core, createColumnToCellsMap, telemetry]);

    React.useEffect(
        () =>
            mobx.autorun(() => {
                if (core.store.tabs.tabInContext.isAddValueAsFilterRequested) {
                    addSelectionAsFilter();
                }
                core.store.tabs.tabInContext.requestAddValueAsFilters(false);
            }),
        [addSelectionAsFilter, core.store.tabs.tabInContext, core.store.tabs.tabInContext.isAddValueAsFilterRequested]
    );

    const getResultGridContextMenuItems = React.useMemo(
        () =>
            mobx.action((defaultMenu: IContextualMenuItem[], event?: CellContextMenuEvent): IContextualMenuItem[] => {
                const items: IContextualMenuItem[] = [
                    ...defaultMenu,
                    {
                        key: 'copy',
                        name: core.strings.query.copy,
                        shortCut: '',
                        onClick: () => {
                            telemetry.event('KustoGridWithExpand.ContextMenu.Copy');
                            if (ref.current.gridApi) {
                                ref.current.gridApi.copySelectedRangeToClipboard({ includeHeaders: false });
                                const focusCell = ref.current.gridApi.getFocusedCell();
                                if (focusCell) {
                                    const focusRow = focusCell.rowIndex;
                                    const focusCol = focusCell.column;
                                    ref.current.gridApi.ensureIndexVisible(focusRow);
                                    ref.current.gridApi.ensureColumnVisible(focusCol);
                                    setTimeout(() => ref.current.gridApi?.setFocusedCell(focusRow, focusCol), 10);
                                }
                            }
                        },
                    },
                    {
                        key: 'copy with header',
                        name: core.strings.query.copyHeaders,
                        onClick: () => {
                            telemetry.event('KustoGridWithExpand.ContextMenu.CopyWithHeader');
                            if (ref.current.gridApi) {
                                ref.current.gridApi.copySelectedRangeToClipboard({ includeHeaders: true });
                            }
                        },
                    },
                    {
                        key: 'copy as HTML',
                        name: core.strings.query.copyAsHtml,
                        onClick: () => {
                            telemetry.event('KustoGridWithExpand.ContextMenu.CopyAsHtml');
                            const gridApi = ref.current.gridApi;
                            if (gridApi === undefined) {
                                telemetry.error('copy as HTML: gridApi is undefined');
                                return;
                            }

                            const rangeSelection = getRangeSelection(gridApi);

                            const resultSubset = getResultDataFromCellRange(
                                ref.current.gridApi!,
                                rangeSelection,
                                resultToDisplay
                            );

                            copyTableResultToClipboard(core, resultSubset);
                            return;
                        },
                    },
                    {
                        key: 'copy as datatable',
                        name: core.strings.query.grid$copyAsDataTable,

                        onClick: () => {
                            telemetry.event('KustoGridWithExpand.ContextMenu.CopyAsDataTable');
                            const gridApi = ref.current.gridApi;
                            if (gridApi === undefined) {
                                telemetry.error('copy as datatable: gridApi is undefined');
                                return;
                            }

                            const rangeSelection = getRangeSelection(gridApi);

                            const resultData = getResultDataFromCellRange(
                                ref.current.gridApi!,
                                rangeSelection,
                                resultToDisplay
                            );

                            const { columns: resultColumns, rows: resultRows } = resultData;
                            if (resultRows === null || resultColumns === null) {
                                return;
                            }

                            const rowsForDataTable = resultRows.flatMap((row) =>
                                resultColumns.map((col) => row[col.field]?.toString() ?? '')
                            );

                            if (!resultToDisplay) {
                                return;
                            }

                            const { columns } = resultToDisplay;
                            if (!columns) {
                                return;
                            }

                            const agGridColumns: AgGridColumn[] = Array.from(
                                new Set(rangeSelection.flatMap((x) => x.columns))
                            );

                            const agGridColumnToConnectionsColumn: Map<AgGridColumn, ConnectionPaneColumn> =
                                createAgGridToConnectionPaneColumnMapping(
                                    agGridColumns,
                                    columns.map((col) => col)
                                );

                            const connectionPaneColumns = Array.from(agGridColumnToConnectionsColumn.values());

                            const statement = generateDatatableStatement(
                                {
                                    columns: connectionPaneColumns,
                                    rows: rowsForDataTable,
                                },
                                true
                            );

                            clipboard.writeText(statement);
                        },
                    },
                    {
                        key: 'ContextMenuSeparator1',
                        itemType: ContextualMenuItemType.Divider,
                    },
                    {
                        key: 'export to csv',
                        name: core.strings.query.exportCSV,
                        onClick: () => {
                            telemetry.event('KustoGridWithExpand.ContextMenu.ExportToCsv');
                            if (ref.current.gridApi) {
                                ref.current.gridApi.exportDataAsCsv(getExportParams());
                            }
                        },
                    },
                    {
                        key: 'export to excel',
                        name: core.strings.query.exportExcel,
                        onClick: () => {
                            telemetry.event('KustoGridWithExpand.ContextMenu.ExportToExcel');
                            if (ref.current.gridApi) {
                                ref.current.gridApi.exportDataAsExcel(getExportParams());
                            }
                        },
                    },
                    {
                        key: 'ContextMenuSeparator2',
                        itemType: ContextualMenuItemType.Divider,
                    },
                    {
                        key: 'show / hide all columns',
                        name: core.strings.query.grid$selectAllColumn,
                        onClick: () => {
                            telemetry.event('KustoGridWithExpand.ContextMenu.ShowOrHideAllColumns');
                            if (ref.current.columnApi) {
                                const columns = ref.current.columnApi.getAllColumns()?.slice(1);
                                if (columns) {
                                    ref.current.columnApi.setColumnsVisible(
                                        columns,
                                        columns.length > 0 && !columns[0].isVisible()
                                    );
                                }
                            }
                        },
                    },
                    {
                        key: 'explore`results',
                        name: core.strings.query.grid$exploreResults,
                        subMenuProps: {
                            items: [
                                {
                                    key: 'highlight`bycolumn',
                                    name: core.strings.query.grid$colorByValue,
                                    onClick: () => {
                                        telemetry.event('KustoGridWithExpand.ContextMenu.HighlightByColumn');
                                        if (ref.current.gridApi) {
                                            const focusCell = ref.current.gridApi.getFocusedCell();
                                            if (focusCell) {
                                                updateHighlightByColumn(focusCell.column.getColId());
                                            }
                                        }
                                    },
                                },
                            ],
                        },
                    },
                ];

                if (!hideAddAsFilterContextItem) {
                    items.push({
                        key: 'add as filters',
                        name: core.strings.query.grid$addAsFilters,
                        secondaryText: `${isMacOs ? '⌘' : 'Ctrl'}+Shift+Space`,
                        onClick: addSelectionAsFilter,
                    });
                }

                if (getContextMenuItemsKwe) {
                    return getContextMenuItemsKwe(items, event);
                } else {
                    return items;
                }
            }),
        [
            hideAddAsFilterContextItem,
            getContextMenuItemsKwe,
            resultToDisplay,
            telemetry,
            getExportParams,
            core,
            addSelectionAsFilter,
        ]
    );

    const getAdditionalMenuItems = (params: GetMainMenuItemsParams) => {
        // ag grid requires an HTML element or string. fluent exports icons either as React components or
        // font icons, but the second option requires loading ALL icons. this is a uinique place where
        // copying the SVG code from fluent to here is the lesser evil
        const colorIcon = `<svg xmlns="http://www.w3.org/2000/svg" class="highlight-by-icon" viewBox="0 0 2048 2048" focusable="false">
<path d="M1024 0q141 0 272 36t245 103 207 160 160 208 103 245 37 272q0 141-36 272t-103 245-160 207-208 160-245 103-272 37q-53 0-99-20t-81-55-55-81-21-100q0-49 9-85t24-67 31-56 31-52 23-56 10-68q0-52-20-99t-55-81-82-55-99-21q-38 0-67 9t-56 24-53 31-56 31-67 23-85 10q-53 0-99-20t-81-55-55-81-21-100q0-141 36-272t103-245 160-207 208-160T751 37t273-37zm0 1920q123 0 237-32t214-90 182-141 140-181 91-214 32-238q0-123-32-237t-90-214-141-182-181-140-214-91-238-32q-123 0-237 32t-214 90-182 141-140 181-91 214-32 238q0 27 10 50t27 40 41 28 50 10q38 0 67-9t56-24 52-31 55-31 67-23 87-10q80 0 150 30t122 82 82 122 30 150q0 49-9 86t-24 67-31 55-31 52-23 56-10 68q0 27 10 50t27 40 41 28 50 10zM512 640q27 0 50 10t40 27 28 41 10 50q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50q0-27 10-50t27-40 41-28 50-10zm384-256q27 0 50 10t40 27 28 41 10 50q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50q0-27 10-50t27-40 41-28 50-10zm512 384q-27 0-50-10t-40-27-28-41-10-50q0-27 10-50t27-40 41-28 50-10q27 0 50 10t40 27 28 41 10 50q0 27-10 50t-27 40-41 28-50 10zm128 256q27 0 50 10t40 27 28 41 10 50q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50q0-27 10-50t27-40 41-28 50-10zm-256 384q27 0 50 10t40 27 28 41 10 50q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50q0-27 10-50t27-40 41-28 50-10z" />
</svg>`;
        return [
            {
                name: core.strings.query.grid$colorByValue,
                icon: colorIcon,
                action: () => {
                    telemetry.event('KustoGridWithExpand.ColumnMenu.HighlightByColumn');
                    updateHighlightByColumn(params.column.getId());
                },
            },
        ];
    };

    React.useEffect(
        () =>
            mobx.autorun(() => {
                onSortChangedTriggerCount = 0;
                if (core.store.tabs.tabInContext.csvExportRequested && ref.current.gridApi) {
                    ref.current.gridApi.exportDataAsCsv(getExportParams());
                }
                if (core.store.tabs.tabInContext.excelExportRequested && ref.current.gridApi) {
                    ref.current.gridApi.exportDataAsExcel(getExportParams());
                }
                core.store.tabs.tabInContext.requestExcelExport(false);
                core.store.tabs.tabInContext.requestCsvExport(false);
            }),
        [core, getExportParams]
    );

    const { columns, rows } = resultToDisplay;
    const { displayGridLevelColoring, hideEmptyCellInRowExpand, closeExpandViewOnClick, alignRightResultNumbers } =
        core.store.settings;
    const { setExpandViewLayout, displayedExpandViewLayout, contentViewMode } = core.store.layout;

    const getRowClass = React.useMemo(() => {
        if (!columns) {
            return undefined;
        }
        if (columnToHighlightRowsBy) {
            const NUMBER_OF_HIGHLIGHT_COLORS = 32;
            const highlightByColumn = columns.find((c) => c.field === columnToHighlightRowsBy);
            if (rows && highlightByColumn) {
                const uniqColValues = uniq(rows.map((r) => r[columnToHighlightRowsBy]));
                const valueToHighlightMap = uniqColValues.reduce<Record<string | number, string>>(
                    (valToColor, curr, idx) => {
                        valToColor[curr as string] = `highlight-color-${idx % NUMBER_OF_HIGHLIGHT_COLORS}`;
                        return valToColor;
                    },
                    {}
                );
                return getRowClassBasedOnValue(highlightByColumn, valueToHighlightMap);
            }
        }
        if (displayGridLevelColoring) {
            const verbosityColumn = detectVerboseColumn(rows, columns);
            if (!verbosityColumn) {
                return undefined;
            }
            return getRowClassBasedOnTraceLevel(verbosityColumn, telemetry, ref);
        }
        return undefined;
    }, [columns, displayGridLevelColoring, columnToHighlightRowsBy, rows, telemetry]);

    React.useEffect(() => {
        setTimeout(() => {
            ref.current.gridApi?.redrawRows();
        }, 0);
        updateGridState({
            ...getGridState(),
            columnToHighlightRowsBy,
        });
    }, [columnToHighlightRowsBy, getGridState, getRowClass, updateGridState]);

    React.useEffect(() => {
        ref.current.trackedTraceLevels = {};
    }, []);

    const statusPanels = showTotalBar ? [{ statusPanel: 'agTotalRowCountComponent', align: 'left' }] : [];

    const gridOptionsStatusBar: GridOptions['statusBar'] = {
        statusPanels: [
            ...statusPanels,
            { statusPanel: 'agAggregationComponent', align: 'left' },
            { statusPanel: 'gridStatusPanel', statusPanelParams: { columns } },
        ],
    };

    return (
        <>
            <ReactGlobalHotKeys
                keyMap={{
                    addSelectionAsFilter: `${isMacOs ? 'Command' : 'Control'}+Shift+Space`,
                }}
                handlers={{
                    addSelectionAsFilter: () => {
                        addSelectionAsFilter();
                    },
                }}
            />
            {core.featureFlags.GridWithExpandRewrite ? (
                <KustoGridWithExpand
                    {...gridProps}
                    resultToDisplay={resultToDisplay}
                    mouseWheelZoom={core.store.settings.mouseWheelZoom}
                    formatResultData={core.store.settings.formatResultData}
                    getContextMenuItemsKwe={getResultGridContextMenuItems}
                    getAdditionalMenuItems={getAdditionalMenuItems}
                    hideEmptyCells={hideEmptyCellInRowExpand}
                    closeExpandViewOnClick={closeExpandViewOnClick}
                    expandType={disableExpandTypes ? DetailsViewType.InGrid : displayedExpandViewLayout}
                    disableJPath={disableJPath}
                    onExpandTypeChange={setExpandViewLayout}
                    onRequestPaste={(content: string) => core.kustoEditor.ref?.pasteText(content)}
                    contentViewModeType={contentViewMode}
                    renderExpandBarItems={(isCompactMode?: boolean) => <ExpandBarItems isCompactMode={isCompactMode} />}
                    gridOptions={{
                        statusBar: gridOptionsStatusBar,
                        getRowClass,
                        onGridReady,
                        components: {
                            gridStatusPanel: GridStatusPanel,
                        },
                    }}
                    numbersAlignRight={alignRightResultNumbers}
                    initialGridState={gridStateCache ? getGridState : undefined}
                    onStoreGridState={gridStateCache ? updateGridState : undefined}
                    onCellClicked={onCellClicked}
                    debug={core.featureFlags.GridDebugMode}
                />
            ) : (
                <KustoGridWithExpandOld
                    {...gridProps}
                    resultToDisplay={resultToDisplay}
                    mouseWheelZoom={core.store.settings.mouseWheelZoom}
                    formatResultData={core.store.settings.formatResultData}
                    getContextMenuItemsKwe={getResultGridContextMenuItems}
                    getAdditionalMenuItems={getAdditionalMenuItems}
                    hideEmptyCells={hideEmptyCellInRowExpand}
                    closeExpandViewOnClick={closeExpandViewOnClick}
                    expandType={disableExpandTypes ? DetailsViewType.InGrid : displayedExpandViewLayout}
                    disableJPath={disableJPath}
                    onExpandTypeChange={setExpandViewLayout}
                    onRequestPaste={(content: string) => core.kustoEditor.ref?.pasteText(content)}
                    contentViewModeType={contentViewMode}
                    renderExpandBarItems={(isCompactMode?: boolean) => <ExpandBarItems isCompactMode={isCompactMode} />}
                    gridOptions={{
                        statusBar: gridOptionsStatusBar,
                        getRowClass,
                        onGridReady,
                        components: {
                            gridStatusPanel: GridStatusPanel,
                        },
                    }}
                    numbersAlignRight={alignRightResultNumbers}
                    initialGridState={gridStateCache ? getGridState : undefined}
                    onStoreGridState={gridStateCache ? updateGridState : undefined}
                    onLinkClicked={onLinkClicked}
                    debug={core.featureFlags.GridDebugMode}
                    suppressReactUi
                />
            )}
        </>
    );
});

export const QueryResultGridWithStateCache = inject((allStores: { store: IRootStore }) => ({
    gridStateCache: allStores.store.gridStateCache,
}))(QueryResultGrid);
