import { useMemo } from 'react';

import { toKQL } from '../kqlParser/kqlParser';
import { pillToKql } from '../kqlParser/pillToKql';
import { useDataExplorationStore } from '../store/DataExplorationStore';

/**
 * Returns the KQL query string that represents the current state of the data exploration.
 * The query is composed of the source KQL and all the pills created by the user.
 * @param pillsToDrop The number of user pills to drop from the query. The pills are removed from the end. Default is `0`.
 */
export function useKql(pillsToDrop = 0): string {
    const { sourceKql, pills } = useDataExplorationStore().state;

    const originalQuery = useMemo(() => toKQL(sourceKql), [sourceKql]);
    const userPills = pills.slice(0, pills.length - pillsToDrop);

    return [originalQuery, ...userPills.map((pill) => `| ${pillToKql(pill)}`)].join('\n');
}
