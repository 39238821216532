import React from 'react';
import { Spinner } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';

import { ColumnWithInsights, type DataProfileTimeRange } from '../../DataExplorationApi';
import { useStrings } from '../../DataExplorationModal/hooks/useStrings';
import { HighCardinalityInfo } from './HighCardinalityInfo';
import { SchemaDistributionList } from './SchemaDistributionList';
import { SchemaSampleValue } from './SchemaSampleView';

import styles from './SchemaColumnInfo.module.scss';

interface SchemaColumnInfoProps {
    selectedColumn: ColumnWithInsights;
    title: React.ReactNode;
    queryText: string;
    timeRange: DataProfileTimeRange;
    timezone: string;
}

export const SchemaColumnInfo: React.FC<SchemaColumnInfoProps> = observer(function SchemaColumnInfo({
    selectedColumn,
    ...props
}) {
    const { strings } = useStrings();

    if (selectedColumn.isHighCardinality === undefined) {
        return (
            <div className={styles.loadingIndicator}>
                <Spinner size="huge" label={strings.schemaInsights.loading} labelPosition="below" />
            </div>
        );
    }

    if (selectedColumn.isHighCardinality) {
        if (selectedColumn.ColumnType === 'string') {
            return <SchemaSampleValue selectedColumnName={selectedColumn.ColumnName} wordWrap {...props} />;
        }

        return <HighCardinalityInfo title={props.title} />;
    }

    return selectedColumn.ColumnType === 'dynamic' ? (
        <SchemaSampleValue selectedColumnName={selectedColumn.ColumnName} {...props} />
    ) : (
        <SchemaDistributionList selectedColumn={selectedColumn} {...props} />
    );
});
