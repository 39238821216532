import { KustoColumn } from '@kusto/client';
import { Result } from '@kusto/utils';
import { KustoHeuristicsErr, KustoHeuristicsOk } from '@kusto/visualizations';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Interaction {
    export interface HeuristicsOk<DataItem = unknown> {
        readonly xColumn: undefined | string;
        readonly yColumns: undefined | readonly string[];
        readonly seriesColumns: undefined | readonly string[];
        readonly kustoHeuristics: KustoHeuristicsOk<DataItem>;
    }

    export type HeuristicsErr = string | KustoHeuristicsErr;

    export type HeuristicsResult<
        OkValue extends HeuristicsOk = Interaction.HeuristicsOk,
        ErrValue extends HeuristicsErr = Interaction.HeuristicsErr
    > = Result<OkValue, ErrValue>;

    export interface HeuristicsSuccess<
        HeuristicsOk extends Interaction.HeuristicsOk = Interaction.HeuristicsOk,
        HeuristicsErr extends Interaction.HeuristicsErr = Interaction.HeuristicsErr
    > {
        readonly columns: readonly KustoColumn[];
        readonly result: HeuristicsResult<HeuristicsOk, HeuristicsErr>;
    }

    export type Heuristics<
        HeuristicsOk extends Interaction.HeuristicsOk = Interaction.HeuristicsOk,
        HeuristicsErr extends Interaction.HeuristicsErr = Interaction.HeuristicsErr
    > = null | HeuristicsSuccess<HeuristicsOk, HeuristicsErr>;
}
