$dropdownItemMinHeight: 36px;

.gridWrapper {
    display: flex;
    flex-direction: column;

    max-height: 100%;
}

.search {
    margin: 8px;
    min-height: $dropdownItemMinHeight;
}

.itemsWrapper {
    overflow-y: auto;
    flex: 1 1;
    min-height: 0;
}

.activeItem {
    button {
        border: none;
    }

    > ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        outline: 1px solid;
        outline-offset: -1px;
        box-sizing: border-box;

        // Always on top
        z-index: 1;
    }
}

.item {
    button {
        border: none;
    }
}

.noResults {
    // Keep same margins as Fabric dropdown items
    margin-top: 9px;
    margin-bottom: 8px;
}
