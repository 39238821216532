import React from 'react';
import { Body1, DrawerBody, Link, Spinner, Tooltip } from '@fluentui/react-components';
import { ErrorCircle20Regular, GlobeClock20Regular, Textbox20Filled } from '@fluentui/react-icons';
import { EmptyState } from '@trident/ux-react';
import { ColorString } from 'highcharts';
import { observer } from 'mobx-react-lite';

import { CollapsibleMessageBar, ErrorBoundary } from '@kusto/ui-components';
import { useResizeObserver } from '@kusto/utils';

import { useDataExplorationContext } from './context/DataExplorationContext';
import { DataProfileTimeRange, useIsIngestionTimePolicyEnabled, useScopedRowCount } from './DataExplorationApi';
import { DataProfileTab, DataProfileTabType } from './DataProfileTab/DataProfileTab';
import { DataProfileTabList } from './DataProfileTab/DataProfileTabList';
import { DataProfileErrorBoundary } from './ErrorBoundary/ErrorBoundary';
import { DataProfileHistogram } from './Histogram/Histogram';
import { useKustoClient } from './kustoClientWrapper';
import { ColumnWithDescription } from './SchemaInsights/SchemaInsights';
import { TimeRangePicker } from './TimeRangePicker/TimeRangePicker';

import styles from './DataProfileContent.module.scss';

interface DataProfileContentProps {
    databaseName: string;
    clusterUrl: string;
    selectedTableName: string;
    selectedTableDocString?: string;
    columns: ColumnWithDescription[];
    selectedTimeFilter: DataProfileTimeRange;
    setSelectedTimeFilter: (filter: DataProfileTimeRange) => void;
    isDarkTheme: boolean;
    graphMainColor?: ColorString;
    graphFillColor?: ColorString;
    timezone: string;
    showDataGridTab: boolean;
    showKqlExamplesTab: boolean;
}

function useShowEmptyStates(tableName: string, timeRange: DataProfileTimeRange) {
    const {
        data: maxCount,
        isLoading: isLoadingMax,
        error: totalCountError,
    } = useScopedRowCount(tableName, { type: 'filterDisabled' });
    const {
        data: filteredCount,
        isLoading: isLoadingFiltered,
        error: scopedCountError,
    } = useScopedRowCount(tableName, timeRange);

    return {
        error: totalCountError?.message || scopedCountError?.message,
        isLoading: isLoadingFiltered || isLoadingMax,
        isColdOrEmptyTable: maxCount === 0,
        noDataForTimeRange: filteredCount === 0,
    };
}

export const DataProfileContent: React.FC<DataProfileContentProps> = observer(function DataProfileContent({
    selectedTableName,
    selectedTableDocString,
    columns,
    clusterUrl,
    databaseName,
    selectedTimeFilter,
    setSelectedTimeFilter,
    isDarkTheme,
    graphMainColor,
    graphFillColor,
    timezone,
    showDataGridTab,
    showKqlExamplesTab,
}) {
    const dbCacheToken = clusterUrl + databaseName;
    const { t } = useDataExplorationContext();
    const [selectedTab, setSelectedTab] = React.useState<DataProfileTabType>('Schema');
    const { ref: panelContentRef, width: panelWidth } = useResizeObserver<HTMLDivElement>();

    const { isAppInsightsDomain, executeV1Query, executeV2Query } = useKustoClient({
        clusterUrl,
        databaseName,
        t,
    });

    const { isColdOrEmptyTable, noDataForTimeRange, isLoading, error } = useShowEmptyStates(
        selectedTableName,
        selectedTimeFilter
    );
    const { data: isIngestionTimePolicyEnabled } = useIsIngestionTimePolicyEnabled(
        dbCacheToken,
        selectedTableName,
        executeV1Query,
        isAppInsightsDomain
    );

    const viewMode = panelWidth && panelWidth < 700 ? 'narrow' : 'wide';

    if (isLoading) {
        return (
            <DrawerBody className={styles.panelCenter} ref={panelContentRef}>
                <Spinner size="huge" label={t.dataExploration.schemaInsights.loading} labelPosition="below" />
            </DrawerBody>
        );
    }

    if (isColdOrEmptyTable || error) {
        return (
            <DrawerBody className={styles.panelCenter} ref={panelContentRef}>
                <EmptyState
                    size={viewMode === 'wide' ? 'large' : 'medium'}
                    emptyStateType={error ? 'error' : 'noItem'}
                    emptyStateTitle={
                        error ? t.dataExploration.errorStateTitle : t.dataExploration.noDataToDisplay.title
                    }
                >
                    {viewMode === 'wide' ? (
                        <Body1 align="center">{error || t.dataExploration.noDataToDisplay.content}</Body1>
                    ) : null}
                </EmptyState>
            </DrawerBody>
        );
    }

    const activeTimeFilter: DataProfileTimeRange = isIngestionTimePolicyEnabled
        ? selectedTimeFilter
        : { type: 'filterDisabled' };

    const showTabSelector = viewMode === 'wide' && (showDataGridTab || showKqlExamplesTab);

    return (
        <DrawerBody className={styles.panelBody} ref={panelContentRef}>
            {selectedTableDocString ? (
                <CollapsibleMessageBar
                    className={styles.docstring}
                    icon={<Textbox20Filled className={styles.docstringIcon} />}
                    title={t.dataExploration.docstringTitle}
                    text={selectedTableDocString}
                />
            ) : null}
            <div className={styles.filtersWrapper}>
                {isIngestionTimePolicyEnabled ? (
                    <TimeRangePicker
                        allowedRanges={['1d', '7d', '30d', '365d']}
                        selectedRange={activeTimeFilter}
                        setSelectedRange={(val) => {
                            setSelectedTimeFilter(val);
                        }}
                    />
                ) : (
                    <Tooltip
                        relationship="description"
                        content={
                            <>
                                {t.dataExploration.filters.timeFilterDisabled.text}{' '}
                                <Link href="https://go.microsoft.com/fwlink/?linkid=2256720" target="_blank">
                                    {t.dataExploration.filters.timeFilterDisabled.learnMore}
                                </Link>
                            </>
                        }
                    >
                        {/* extra div required for tooltip to work. wrapping a disabled button directly doesn't work */}
                        <div>
                            <TimeRangePicker
                                allowedRanges={['1d', '7d', '30d', '365d']}
                                selectedRange={activeTimeFilter}
                                setSelectedRange={() => {}}
                                disabled
                            />
                        </div>
                    </Tooltip>
                )}

                <div className={styles.timezoneIndicator}>
                    <GlobeClock20Regular />
                    <Body1>{timezone}</Body1>
                </div>
            </div>
            {noDataForTimeRange ? (
                <div className={styles.noDataForTimeRange}>
                    <EmptyState
                        emptyStateType="noItem"
                        size={viewMode === 'wide' ? 'large' : 'medium'}
                        emptyStateTitle={t.dataExploration.noDataIngestedForTimeRange.title}
                    />
                </div>
            ) : (
                <>
                    <div className={styles.histogramWrapper}>
                        <ErrorBoundary
                            key={selectedTableName}
                            fallback={
                                <div className={styles.messageBarError}>
                                    <ErrorCircle20Regular />
                                    <Body1>{t.dataExploration.errorStateTitle}</Body1>
                                </div>
                            }
                        >
                            <DataProfileHistogram
                                dbCacheToken={dbCacheToken}
                                queryText={selectedTableName}
                                timeRange={activeTimeFilter}
                                columns={columns}
                                isIngestionTimePolicyEnabled={isIngestionTimePolicyEnabled}
                                isDarkTheme={isDarkTheme}
                                seriesColor={graphMainColor}
                                seriesFillColor={graphFillColor}
                                timezone={timezone}
                                executeV2Query={executeV2Query}
                            />
                        </ErrorBoundary>
                    </div>
                    {showTabSelector ? (
                        <DataProfileTabList
                            selectedTab={selectedTab}
                            onTabSelect={setSelectedTab}
                            showDataGridOption={showDataGridTab}
                            showKqlExamplesOption={showKqlExamplesTab}
                        />
                    ) : null}
                    <DataProfileErrorBoundary
                        key={selectedTableName}
                        errorStateTitle={t.dataExploration.errorStateTitle}
                    >
                        <DataProfileTab
                            activeTab={viewMode === 'wide' ? selectedTab : 'Schema'}
                            isDarkTheme={isDarkTheme}
                            clusterUrl={clusterUrl}
                            databaseName={databaseName}
                            dbCacheToken={dbCacheToken}
                            viewMode={viewMode}
                            queryText={selectedTableName}
                            columns={columns}
                            timeRange={activeTimeFilter}
                            timezone={timezone}
                            executeV2Query={executeV2Query}
                        />
                    </DataProfileErrorBoundary>
                </>
            )}
        </DrawerBody>
    );
});
