export function useToolbarContextValues_unstable(state) {
    const { size, handleToggleButton, vertical, checkedValues, handleRadio } = state;
    // This context is created with "@fluentui/react-context-selector", these is no sense to memoize it
    const toolbar = {
        size,
        vertical,
        handleToggleButton,
        handleRadio,
        checkedValues
    };
    return {
        toolbar
    };
}
