import * as React from 'react';
import { Chart } from 'highcharts';

import { InternalChartProps } from '../types.ts';
import { ExtendedKind, ExtendedVisualizationOptions } from '../utils/visualization.ts';
import { Highchart, HighchartProps } from './Highchart.tsx';
import { HighChartsLayout } from './HighChartsLayout.tsx';
import { ChartSeries, PieChartSeries } from './InteractiveLegend/types.ts';
import { useShouldRecreateChartObject } from './utils.ts';

interface HighChartWithInteractiveLegendProps
    extends Omit<HighchartProps, 'shouldRecreateChartObject'>,
        Pick<InternalChartProps, 'legendPosition'> {
    visualizationOptions: ExtendedVisualizationOptions;
}

const PIE_CHART_TYPES: ExtendedKind[] = ['pie', 'donut'];

export const HighChartWithInteractiveLegend: React.FC<HighChartWithInteractiveLegendProps> = React.forwardRef(
    function HighchartWithShouldRecreate(props, ref) {
        const shouldRecreateChartObject = useShouldRecreateChartObject(props);

        const [chartRef, setChart] = React.useState<{ chart: Chart }>();
        const [seriesList, setSeriesList] = React.useState<ChartSeries[] | undefined>();
        React.useImperativeHandle(ref, () => chartRef, [chartRef]);

        // Series value should be checked after render
        React.useEffect(() => {
            setSeriesList(
                PIE_CHART_TYPES.includes(props.visualizationOptions.Kind)
                    ? (chartRef?.chart?.series[0].data as PieChartSeries[])
                    : chartRef?.chart?.series
            );
        }, [chartRef, props.visualizationOptions]);

        return (
            <HighChartsLayout
                chart={chartRef?.chart}
                seriesList={seriesList}
                seriesOptions={props.options.series}
                strings={props.strings}
                legendPosition={props.legendPosition}
                legendVisibility={props.visualizationOptions.Legend}
            >
                <Highchart {...props} ref={setChart} shouldRecreateChartObject={shouldRecreateChartObject} />
            </HighChartsLayout>
        );
    }
);
