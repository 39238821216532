import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';

import { RenderHelperProvider } from '@kusto/ui-components';
import { KweException, RtdDropdownStringsProvider, RtdThemeProvider, TelemetryProvider } from '@kusto/utils';

import { RenderConfigurationsOptions, RenderVisualOptions } from '../pub';
import type { VisualOptionsModel } from './model';

export const VisualOptionsContextsProvider: React.FC<{
    options: RenderVisualOptions | RenderConfigurationsOptions;
    model: VisualOptionsModel;
}> = observer(function VisualOptionsContextsProvider({ options, model, children }) {
    return (
        <RenderHelperProvider>
            <RtdThemeProvider isDark={options.isDarkTheme}>
                <TelemetryProvider telemetry={model.args.telemetry}>
                    <VisualOptionsModelContext.Provider value={model}>
                        <RtdDropdownStringsProvider value={model.args.locale()}>{children}</RtdDropdownStringsProvider>
                    </VisualOptionsModelContext.Provider>
                </TelemetryProvider>
            </RtdThemeProvider>
        </RenderHelperProvider>
    );
});

const VisualOptionsModelContext = React.createContext<VisualOptionsModel | undefined>(undefined);

export function useVisualOptionsModel(): VisualOptionsModel {
    const model = useContext(VisualOptionsModelContext);

    if (model === undefined) {
        throw new KweException('useVisualOptionsModel is only meant to be used inside VisualOptionsModel');
    }

    return model;
}
