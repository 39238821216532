import * as React from 'react';
import { IStyle, ITheme, TooltipDelay, TooltipHost } from '@fluentui/react';
import { classNamesFunction, IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import { observer } from 'mobx-react-lite';

import { useQueryCore } from '../../core/core';
import { CopyTextActionButton } from '../CopyTextActionButton';

const getCardItemClassNames = classNamesFunction<CardItemStyleProps, CardItemStyles>();

type CardItemStyleProps = Required<Pick<CardItemProps, 'theme'>>;

interface CardItemProps {
    value: number | string;
    label: string;
    shouldLocalize?: boolean;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<CardItemStyleProps, CardItemStyles>;
    showTooltip?: boolean;
    showCopyToClipboardButton?: boolean;
}

interface CardItemStyles {
    item: IStyle;
    value: IStyle;
    label: IStyle;
}

const getCardItemStyles = (props: CardItemStyleProps): CardItemStyles => {
    return {
        item: {
            borderLeftColor: props.theme!.palette.themePrimary, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            borderLeftWidth: '4px',
            borderLeftStyle: 'solid',
            paddingLeft: '8px',
            marginRight: '16px',
        },
        value: {
            lineHeight: '22px',
            fontSize: '16px',
            fontWeight: 600,
            color: props.theme!.palette.themePrimary, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        },
        label: {
            whiteSpace: 'nowrap',
            fontSize: '12px',
            lineHeight: '16px',
            color: props.theme!.palette.neutralSecondary, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        },
    };
};

const getBoldValueCardItemStyles = (props: CardItemStyleProps): CardItemStyles => {
    return {
        item: {
            paddingLeft: '8px',
            marginRight: '16px',
            marginBottom: '16px',
        },
        value: {
            lineHeight: '22px',
            fontSize: '16px',
            fontWeight: 700,

            color: props.theme!.palette.neutralSecondary,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '240px',
            textOverflow: 'ellipsis',
        },
        label: {
            whiteSpace: 'nowrap',
            fontSize: '12px',
            lineHeight: '16px',

            color: props.theme!.palette.neutralSecondary,
        },
    };
};

const NumericCardItemBase = observer(function NumericCardItemBase({
    value,
    label,
    styles,
    showTooltip,
    showCopyToClipboardButton,
    theme,
    shouldLocalize,
}: CardItemProps) {
    const core = useQueryCore();

    const classNames = getCardItemClassNames(styles, { theme: theme! });

    // localization is on by default.
    const val = value && (shouldLocalize ?? true) ? value.toLocaleString(core.strings.locale) : value;
    return (
        <div className={classNames.item}>
            <div className={classNames.label}>
                <span>{label}</span>
                {showCopyToClipboardButton && (
                    <CopyTextActionButton
                        tooltipHostStyles={{ root: { display: 'inline-block' } }}
                        key="copyActivityId"
                        messageBeforeCopy={core.strings.query.copyActivityId}
                        messageAfterCopy={core.strings.query.copyActivityIdDone}
                        textToCopy={`${val}`}
                        iconProps={{ iconName: 'Copy' }}
                    />
                )}
            </div>
            <TooltipHost content={showTooltip ? `${val}` : ''} delay={TooltipDelay.long}>
                <div className={classNames.value}>{val}</div>
            </TooltipHost>
        </div>
    );
});

/**
 * Shows a value and a title. The value is in bold blue. Both the value and the title has a left-4px-blue-border.
 */
export const PrimaryColorValueCardItem = styled(NumericCardItemBase, getCardItemStyles);

/**
 * Shows a value and a title, where the value is in black bold.
 */
export const BoldValueCardItem = styled(NumericCardItemBase, getBoldValueCardItemStyles);

const getCardClassNames = classNamesFunction<CardStyleProps, CardStyles>();

type CardStyleProps = Required<Pick<CardProps, 'theme'>>;

interface CardProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<CardStyleProps, CardStyles>;
    children: JSX.Element | JSX.Element[];
}

interface CardStyles {
    card: IStyle;
}

const getVerticalCardStyles = (): CardStyles => {
    return {
        card: [
            'card',
            {
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 0px 10px 16px',
            },
        ],
    };
};

const getCardStyles = (): CardStyles => {
    return {
        card: [
            'card',
            {
                display: 'flex',
                flexDirection: 'row',
                padding: '0px 0px 10px 16px',
            },
        ],
    };
};

const NumericCardBase = (props: CardProps) => {
    const classNames = getCardClassNames(props.styles, { theme: props.theme! });
    return <div className={classNames.card}>{props.children}</div>;
};

// @ts-expect-error: Added lint that requires a comment here. TODO: Make this comment more specific rule
export const NumericCard: React.FC<CardProps> = styled(NumericCardBase, getCardStyles);

// @ts-expect-error: Added lint that requires a comment here. TODO: Make this comment more specific rule
export const VerticalNumericCard: React.FC<CardProps> = styled(NumericCardBase, getVerticalCardStyles);

const getTileClassNames = classNamesFunction<TileStyleProps, TileStyles>();

type TileStyleProps = Required<Pick<TileProps, 'theme'>> & Partial<Pick<TileProps, 'centered'>>;

interface TileProps {
    header: string;
    children?: JSX.Element | JSX.Element[];
    centered?: boolean;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<TileStyleProps, TileStyles>;
    headerRight?: JSX.Element | JSX.Element[] | string;
}

interface TileStyles {
    header: IStyle;
    headerRight: IStyle;
    headerContainer: IStyle;
    tile: IStyle;
}

const getTileStyles = (props: TileStyleProps): TileStyles => {
    return {
        tile: {
            padding: 3,
            backgroundClip: 'content-box',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 0px',
            backgroundColor: props.theme.palette.white,
        },
        header: [
            {
                fontSize: '12px',
                fontWeight: '500',
                padding: '10px 10px 10px 10px',
                color: props.theme!.palette.themePrimary, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                flexGrow: 1,
            },
            props.centered
                ? {
                      display: 'flex',
                      alignSelf: 'center',
                  }
                : {},
        ],
        headerContainer: {
            display: 'flex',
        },
        headerRight: {
            fontSize: '12px',
            color: props.theme!.palette.themePrimary, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            position: 'relative',
            right: '0px',
        },
    };
};

const BaseTile = (props: TileProps) => {
    const classNames = getTileClassNames(props.styles, {
        theme: props.theme!,
        centered: props.centered,
    });
    return (
        <div className={classNames.tile}>
            <div className={classNames.headerContainer}>
                <div className={classNames.header}>{props.header}</div>
                <div className={classNames.headerRight}>{props.headerRight}</div>
            </div>
            {props.children}
        </div>
    );
};
export const Tile = styled(BaseTile, getTileStyles);

const rowStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 0 0px',
};

export const FlexRow = ({
    children,
    style,
    className,
}: { children: JSX.Element | JSX.Element[] } & Pick<React.HTMLAttributes<HTMLDivElement>, 'style' | 'className'>) => (
    <div style={{ ...rowStyle, ...style }} className={className}>
        {children}
    </div>
);

const getDashboardContainerClassNames = classNamesFunction<DashboardContainerStyleProps, DashboardContainerStyles>();

type DashboardContainerStyleProps = Required<Pick<DashboardContainerProps, 'theme'>>;

interface DashboardContainerProps {
    children?: JSX.Element | JSX.Element[];
    theme?: ITheme;
    id?: string;
    styles?: IStyleFunctionOrObject<DashboardContainerStyleProps, DashboardContainerStyles>;
}

interface DashboardContainerStyles {
    root: IStyle;
}

const getDashboardContainerStyles = (props: DashboardContainerStyleProps): DashboardContainerStyles => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 0px',
            justifyContent: 'flex-start',
            backgroundColor: props.theme.palette.neutralLight,
            paddingTop: 6,
            overflow: 'auto',
        },
    };
};

const DashboardContainerBase = ({ children, theme, styles, id }: DashboardContainerProps) => {
    const classNames = getDashboardContainerClassNames(styles, {
        theme: theme!,
    });
    return (
        <div className={classNames.root} id={id}>
            {children}
        </div>
    );
};
export const DashboardContainer = styled(DashboardContainerBase, getDashboardContainerStyles);
