import { fromFloatingUIPlacement } from './fromFloatingUIPlacement';
/**
 * Shim to transform offset values from this library to Floating UI
 * @param rawOffset Offset from this library
 * @returns An offset value compatible with Floating UI
 */ export function getFloatingUIOffset(rawOffset) {
    if (!rawOffset) {
        return rawOffset;
    }
    if (typeof rawOffset === 'number' || typeof rawOffset === 'object') {
        return rawOffset;
    }
    return ({ rects: { floating, reference }, placement })=>{
        const { position, alignment } = fromFloatingUIPlacement(placement);
        return rawOffset({
            positionedRect: floating,
            targetRect: reference,
            position,
            alignment
        });
    };
}
