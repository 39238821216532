import * as React from 'react';

import { formatLiterals } from '@kusto/utils';

import type { KweVisualFwkLocale } from '../../types';
import type { Slot } from '../../visualOptions';

export interface SlotLabel {
    text: string;
    node: React.ReactNode;
}

export function createSlotLabel(slot: Slot, locale: KweVisualFwkLocale): SlotLabel {
    const strings = locale.visualFwk.visualConfig.multiStat__slot.formatSlot;

    const dimensionsText = formatLiterals(strings.size, {
        columnCount: slot.width.toLocaleString(locale.locale),
        rowCount: slot.height.toLocaleString(locale.locale),
    });

    const area = slot.width * slot.height;
    const areaText =
        area === 1
            ? strings.areaTextSingular
            : formatLiterals(strings.areaTextPlural, { count: area.toLocaleString(locale.locale) });

    return {
        text: `${dimensionsText} ${areaText}`,
        node: (
            <>
                {dimensionsText}
                <br />
                {areaText}
            </>
        ),
    };
}
