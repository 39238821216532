const emptyImmutableSet = createImmutableSet();
/**
 * Avoid using *dangerouslyCreateImmutableSet*, since this method will expose internally used set, use  createImmutableSet instead,
 * @param internalSet - a set that is used internally to store values.
 */ function dangerouslyCreateImmutableSet(internalSet) {
    return {
        size: internalSet.size,
        add (value) {
            const nextSet = new Set(internalSet);
            nextSet.add(value);
            return dangerouslyCreateImmutableSet(nextSet);
        },
        clear () {
            return emptyImmutableSet;
        },
        delete (value) {
            const nextSet = new Set(internalSet);
            nextSet.delete(value);
            return dangerouslyCreateImmutableSet(nextSet);
        },
        has (value) {
            return internalSet.has(value);
        },
        [Symbol.iterator] () {
            return internalSet[Symbol.iterator]();
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dangerouslyGetInternalSet_unstable: ()=>internalSet
    };
}
function isImmutableSet(value) {
    return typeof value === 'object' && value !== null && 'dangerouslyGetInternalSet_unstable' in value;
}
/**
 * properly creates an ImmutableSet instance from an iterable
 */ function createImmutableSet(iterable) {
    const internalSet = new Set(iterable);
    return dangerouslyCreateImmutableSet(internalSet);
}
export const ImmutableSet = {
    empty: emptyImmutableSet,
    create: createImmutableSet,
    isImmutableSet,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    dangerouslyCreate_unstable: dangerouslyCreateImmutableSet
};
