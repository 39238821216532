import React from 'react';
import { Dropdown, DropdownProps, mergeClasses, PopoverTrigger, Tooltip } from '@fluentui/react-components';

import { useStrings } from '../../../hooks/useStrings.tsx';
import { UiComponentsLocale } from '../../../types.ts';
import { DropdownWithSearchOption, TitleRender, TooltipRender } from '../types.ts';

import styles from '../DropdownWithSearch.module.scss';

export function buildTitle(options: DropdownWithSearchOption[], strings: UiComponentsLocale) {
    const separator = new Intl.ListFormat(strings.locale, { style: 'narrow' });
    return separator.format(options.map((option) => option.text).filter(Boolean));
}

export interface DropdownButtonProps
    extends Pick<DropdownProps, 'button' | 'disabled' | 'id' | 'className' | 'expandIcon'> {
    selectedKeys?: string[];
    options: DropdownWithSearchOption[];
    placeholder?: string | React.ReactElement;
    /** Custom title renderer function */
    renderTitle?: TitleRender;
    renderTooltipContent: TooltipRender | undefined;
    ['data-testid']?: string;
}

export const DropdownButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps>(
    (
        {
            id,
            options,
            selectedKeys = [],
            className,
            placeholder = '',
            disabled,
            renderTitle,
            expandIcon,
            renderTooltipContent,
            ['data-testid']: dataTestId,
        },
        ref
    ) => {
        const strings = useStrings();
        const defaultRender = (options: DropdownWithSearchOption[]) => buildTitle(options, strings);
        const { text, content, selectedOptions } = React.useMemo(() => {
            const selectedOptions = options.filter((option) => selectedKeys.includes(option.key));
            const text = buildTitle(selectedOptions, strings);
            const content = selectedOptions.length === 1 ? selectedOptions[0].content : undefined;
            return { text, content, selectedOptions };
        }, [options, selectedKeys, strings]);

        const [isTooltipActivated, setisTooltipActivated] = React.useState(false);
        const tooltipContent =
            isTooltipActivated && renderTooltipContent ? renderTooltipContent(selectedOptions) : undefined;

        return (
            <Tooltip
                withArrow
                showDelay={500}
                relationship="description"
                content={tooltipContent ? tooltipContent : <></>}
                visible={!!tooltipContent}
                onVisibleChange={(_, data) => setisTooltipActivated(data.visible)}
            >
                <PopoverTrigger disableButtonEnhancement>
                    <Dropdown
                        ref={ref}
                        role="combobox"
                        id={id}
                        data-testid={dataTestId}
                        button={
                            selectedOptions.length ? (
                                <div className={(content || renderTitle) && styles.customElem}>
                                    {content ?? renderTitle?.(selectedOptions, defaultRender) ?? text}
                                </div>
                            ) : (
                                <div className={styles.placeholder}>{placeholder}</div>
                            )
                        }
                        expandIcon={expandIcon}
                        disabled={disabled}
                        className={mergeClasses(styles.dropdown, className)}
                        // Prevent the listbox from showing on click while keeping the other dropdown button functionality
                        listbox={null}
                    />
                </PopoverTrigger>
            </Tooltip>
        );
    }
);
