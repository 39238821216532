import React, { useMemo, useState } from 'react';
import { Button, Divider, Dropdown, Option, Subtitle1 } from '@fluentui/react-components';
import { Add20Filled, Add20Regular, bundleIcon, Delete20Filled, Delete20Regular } from '@fluentui/react-icons';

import { useStrings } from '../../../hooks/useStrings';
import {
    AGGREGATION_OPERATORS,
    AggregationFunction,
    AggregationGroup,
    AggregationOperator,
    AggregationPill,
} from '../../../types/Pills.interfaces';
import { ColumnDropdown } from './helpers/ColumnDropdown';
import { KqlPreview } from './helpers/KqlPreview/KqlPreview';
import { PopoverButtons } from './helpers/PopoverButtons';
import { DisplayNameField, PopoverField } from './helpers/PopoverField';
import { TimespanDropdown } from './helpers/TimespanDropdown';

import styles from './Popovers.module.scss';

export const Add20Icon = bundleIcon(Add20Filled, Add20Regular);
export const Delete20Icon = bundleIcon(Delete20Filled, Delete20Regular);

const OPERATORS_WITH_COLUMN: AggregationOperator[] = AGGREGATION_OPERATORS.filter((x) => x !== 'count'); // some operators don't need a column
const DEFAULT_FUNCTION: AggregationOperator = 'count';
const MAX_GROUPS = 2;

interface PartialAggregationPillData {
    functions: Partial<AggregationFunction>[];
    groups: Partial<AggregationGroup>[];
}

/** Validate the pill data; return valid pill or undefined */
function validatePill(pillData: PartialAggregationPillData): AggregationPill | undefined {
    const { functions, groups } = pillData;
    const pillFunc = functions[0];

    if (pillFunc.operator && (pillFunc.column || !OPERATORS_WITH_COLUMN.includes(pillFunc.operator))) {
        return {
            type: 'aggregation',
            data: {
                functions: [
                    {
                        operator: pillFunc.operator,
                        ...pillFunc,
                    },
                ],
                groups: (groups as AggregationGroup[]).filter((group) => group.column), // remove empty groups
            },
        };
    }

    return undefined;
}

export interface AggregationPillPopoverProps {
    defaultValues?: PartialAggregationPillData;
    onApply: (newPill: AggregationPill) => void;
    onCancel: () => void;
    /** whether it's a new pill or editing an existing pill */
    newPill?: boolean;
}

export const AggregationPillPopover: React.FunctionComponent<AggregationPillPopoverProps> = ({
    defaultValues,
    onApply,
    onCancel,
    newPill,
}) => {
    const { strings } = useStrings();

    const [pillFunc, setPillFunc] = useState<AggregationFunction>({
        operator: DEFAULT_FUNCTION,
        ...defaultValues?.functions?.[0],
    });

    const [groups, setGroups] = useState<Partial<AggregationGroup>[]>(defaultValues?.groups ?? []);

    const onFuncChange = (newState: Partial<AggregationFunction>) => {
        setPillFunc((prevState) => ({ ...prevState, ...newState }));
    };

    const onGroupChange = (newState: Partial<AggregationGroup>, groupIndex: number) => {
        const newGroups = [...groups];

        // can safely mutate, local value
        newGroups[groupIndex] = {
            ...newGroups[groupIndex],
            ...newState,
        };

        setGroups(newGroups);
    };

    const removeGroup = (groupIndex: number) => {
        setGroups(groups.filter((_group, i) => i !== groupIndex));
    };

    const addGroup = () => {
        setGroups([...groups, {}]);
    };

    const validPill = useMemo(() => validatePill({ functions: [pillFunc], groups }), [pillFunc, groups]);

    const submitForm = () => {
        if (!validPill) {
            return; // should not get here, button disabled
        }

        onApply(validPill);
    };

    return (
        <>
            <div data-testid="aggregation-pill-popover" className={styles.aggregationPillPopover}>
                <Subtitle1>{strings.dataExplorationModal.pillsBar.aggregation}</Subtitle1>
                <div className={styles.aggregationFormContainer}>
                    {/* Summarize */}
                    <div className={styles.aggregationFormRow}>
                        {/* Summarize: Operator */}
                        <PopoverField
                            label={strings.dataExplorationModal.pillsBar.operator}
                            labelId="operator-dropdown"
                        >
                            <Dropdown
                                placeholder={strings.dataExplorationModal.pillsBar.operatorDropdownPlaceholder}
                                onOptionSelect={(e, { optionValue }) => {
                                    onFuncChange({ operator: optionValue as AggregationOperator, column: undefined });
                                }}
                                defaultValue={pillFunc.operator ?? DEFAULT_FUNCTION} // default label
                                defaultSelectedOptions={pillFunc.operator ? [pillFunc.operator] : [DEFAULT_FUNCTION]} // default value
                                aria-labelledby="operator-dropdown"
                            >
                                {AGGREGATION_OPERATORS.map((operator, i) => (
                                    <Option value={operator} key={i} data-testid={`aggregation-operator-option-${i}`}>
                                        {operator}
                                    </Option>
                                ))}
                            </Dropdown>
                        </PopoverField>
                        {/* Summarize Column (hidden for some operators) */}
                        {pillFunc.operator && OPERATORS_WITH_COLUMN.includes(pillFunc.operator) ? (
                            <ColumnDropdown
                                selectedColumn={pillFunc.column}
                                onOptionSelect={(column) => onFuncChange({ column })}
                                isEditMode={!newPill}
                            />
                        ) : null}
                        {/* Summarize: Display Name */}
                        <DisplayNameField
                            labelId="display-name-input"
                            placeholder={pillFunc.operator}
                            defaultValue={pillFunc.displayName}
                            onChange={(value) => onFuncChange({ displayName: value })}
                        />
                    </div>
                    <Divider />
                    {/* Group by */}
                    <div className={styles.aggregationGroups}>
                        {groups.map((group, i) => {
                            const hideLabel = i != 0;
                            return (
                                <div
                                    className={styles.aggregationFormRow}
                                    // key:
                                    // can't only use index, we are deleting item from the middle
                                    // can't only use column-name, may be undefined
                                    key={i + (group.column?.ColumnName ?? 'new')}
                                    data-testid={`aggregation-group-${i}`}
                                >
                                    {/* Group by: Column */}
                                    <ColumnDropdown
                                        label={hideLabel ? '' : strings.dataExplorationModal.pillsBar.groupBy}
                                        selectedColumn={group?.column}
                                        onOptionSelect={(column) =>
                                            onGroupChange({ column, displayName: undefined }, i)
                                        }
                                        isEditMode={!newPill}
                                    />
                                    {/* Group by: Display Name */}
                                    <DisplayNameField
                                        hideLabel={hideLabel}
                                        labelId={'group-display-name-input-' + i}
                                        placeholder={group?.column?.ColumnName}
                                        defaultValue={group?.displayName}
                                        onChange={(value) => onGroupChange({ displayName: value }, i)}
                                    />
                                    {/* Group by: TimeRange */}
                                    {group.column?.ColumnType === 'datetime' ? (
                                        <TimespanDropdown
                                            selectedTimespan={group.timespan}
                                            onOptionSelect={(timespan) => onGroupChange({ timespan }, i)}
                                            hideLabel={hideLabel}
                                        />
                                    ) : null}
                                    {/* Remove group */}
                                    <Button
                                        icon={<Delete20Icon />}
                                        onClick={() => removeGroup(i)}
                                        className={styles.removeButton}
                                        appearance="subtle"
                                    />
                                </div>
                            );
                        })}
                        {/* Add group */}
                        {groups.length < MAX_GROUPS ? (
                            <Button
                                icon={<Add20Icon />}
                                onClick={addGroup}
                                className={styles.addButton}
                                appearance="subtle"
                            >
                                {strings.dataExplorationModal.pillsBar.addGroup}
                            </Button>
                        ) : null}
                    </div>
                </div>
                <KqlPreview pill={validPill} />
            </div>
            <PopoverButtons onApply={submitForm} onCancel={onCancel} disabled={!validPill} />
        </>
    );
};
