import type { INotification } from '@kusto/utils';

import type { QueryResultBarConfig } from '../components/QueryResults/QueryResultsBar';
import type { CommandType, FetchState, QueryCompletionInfo, QueryContext, QueryResultStore } from '../stores';

export type SettingElement = 'timezone'; // Probably will be more ...

export interface QueryConfiguration {
    readonly appName: undefined | string;
    readonly azureMapToken: undefined | string;
    readonly queryResultStore: undefined | QueryResultStore;
    readonly defaultLanguage: undefined | string;
    /**
     * Used when rending @kusto/query's ExceptionBoundary
     */
    readonly supportEmail: string;
    readonly query?: {
        executionDone?: (
            commandType: CommandType,
            resultState: FetchState,
            readonly: boolean,
            queryCompletionInfo: QueryCompletionInfo | undefined
        ) => void;
        // Consider adding additional config points
        // executionResult?: () => void
        // beforeExecution?: () => boolean;
    };
    readonly actions?: {
        readonly onCopyLink?: (context: QueryContext) => void;
        readonly onCopyResults?: (resultsAsHtml: string) => void;
        readonly onCopyQuery?: (context: QueryContext, queryAsHTML: string, queryAsText: string) => void;
        readonly onOpenSetting?: (propName: SettingElement) => void;
        // probably will need to add
        userNotification?: (notification: INotification) => void;
        openPinToDashboard?(): void;
    };
    // query bar configuration follow react hook, it will allow trigger re-render of the bar from config
    // either by useObserver or any other hook
    readonly useQueryResultBar?: () => QueryResultBarConfig;
    readonly useClusterScope: undefined | boolean;
    /**
     * Open link contained in query result.
     */
    openQueryLink(url: string, newTab: boolean): void;
}
