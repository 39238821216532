/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import size from 'lodash/size';
import { flow, getEnv, getRoot, Instance, types } from 'mobx-state-tree';
import pLimit from 'p-limit';

import { castToError, NotificationType } from '@kusto/utils';

import { EntityType } from '../../common';
import { getTelemetryClient, testConnection, toArray } from '../../utils';
import { Cluster, Database, getClusterFromDatabase, getClusterUrl } from '../cluster';
import {
    BasicAccountInfo,
    ENTITY_GROUP_MEMBER_PREFIX,
    ENTITY_GROUP_MEMBER_PREFIX_OLD,
    ENTITY_GROUP_PREFIX,
    ENTITY_GROUP_PREFIX_OLD,
    FOLDER_PREFIX,
    FUNCTION_PREFIX,
    FUNCTION_PREFIX_OLD,
    inactiveFetchStates,
} from '../common';
import { Group } from '../group';
import type { IRootStore } from '../rootStore';
import { getQueryStoreEnv } from '../storeEnv';
import { checkClusterHealthEvents } from './healthCheckHandler';

export interface DeepLinkProperties {
    readonly deepLink: URL;
    readonly clusterName?: string;
    readonly databaseName?: string;
    readonly tableName?: string;
    readonly subPath?: string;
    readonly connectionString?: string;
    readonly query?: string;
    readonly querySrc?: string;
    readonly autorun?: boolean;
    readonly forget: boolean;
    readonly storeName?: string;
    readonly workspaceName?: string;
    readonly origin?: string;
    readonly pathname?: string;
}

const { trackEvent, trackTrace, trackException } = getTelemetryClient({
    component: 'connectionPane',
    flow: '',
});

const ClusterOrDatabase = types.union(Cluster, Database);

// eslint-disable-next-line no-redeclare
type ClusterOrDatabase = typeof ClusterOrDatabase.Type;
interface EntityRefreshResult {
    name: string;
    entityType: string;
    fetchState: string;
    prevFetchState: string;
    prevMajorVersion: string;
    prevMinorVersion: string;
    majorVersion: string;
    minorVersion: string;
}
export const ClusterOrDatabaseSafeReference = types.safeReference(ClusterOrDatabase);
// eslint-disable-next-line no-redeclare
export type ClusterOrDatabaseSafeReference = typeof ClusterOrDatabaseSafeReference.Type;

const ClusterWithSnapshot = types.snapshotProcessor(Cluster, {
    postProcessor: (snapshot): unknown => {
        if (!snapshot.tooBigToCache) {
            return snapshot;
        }
        // remove tables and functions from DB cache if the cluster is too big for caching
        const updatedDBs = Object.values(snapshot.databases)
            .map((db) => ({
                ...db,
                // Reset data
                tables: {},
                functions: {},
                fetchStateError: '',
                fetchState: 'notStarted',
            }))
            .reduce((dbs, db) => {
                dbs[db.id] = db;
                return dbs;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }, {} as { [id: string]: any });
        return {
            ...snapshot,
            databases: updatedDBs,
        };
    },
});

export const ConnectionPane = types
    .model('ConnectionPane', {
        groups: types.optional(types.map(Group), {}),
        favorites: types.optional(types.map(ClusterOrDatabaseSafeReference), {}),
        connections: types.map(ClusterWithSnapshot),
        entityInContext: types.maybe(ClusterOrDatabaseSafeReference),
        showOnlyFavorites: types.optional(types.boolean, false),
        expandedEntities: types.optional(types.map(ClusterOrDatabaseSafeReference), {}),
        expandedTreeEntities: types.optional(types.map(types.string), {}),
        healthCheckPerformed: types.optional(types.boolean, false),
        scrollIntoViewItem: types.maybe(types.string),
    })
    .volatile(() => ({
        clusterNotInListDialog: undefined as DeepLinkProperties | undefined,
    }))
    .actions((self) => ({
        // Old tree actions
        updateExpanded(entity: Cluster | Database, expanded: boolean) {
            if (expanded) {
                self.expandedEntities.put(entity);
            } else {
                self.expandedEntities.delete(entity.id);
            }
        },
        clearExpanded() {
            self.expandedEntities.replace({});
        },
        // New Connections tree actions
        updateExpandedTreeEntity(id: string, expanded: boolean) {
            if (expanded) {
                self.expandedTreeEntities.set(id, id);
            } else {
                self.expandedTreeEntities.delete(id);
            }
        },
        toggleExpandedTreeEntity(id: string) {
            if (self.expandedTreeEntities.has(id)) {
                self.expandedTreeEntities.delete(id);
            } else {
                self.expandedTreeEntities.set(id, id);
            }
        },
        clearExpandedTreeEntities() {
            self.expandedTreeEntities.replace({});
        },
        focusOnEntity(parentValue?: string) {
            if (parentValue) {
                const expandedEntities: string[] = Array.from(self.expandedTreeEntities.values());
                expandedEntities.forEach((value) => {
                    if (value.indexOf(parentValue) === -1) {
                        self.expandedTreeEntities.delete(value);
                    }
                });
                this.updateExpandedTreeEntity(parentValue, true);
                // for all parents of this parent, expand it
                const parentValueParts = parentValue.split('/');
                for (let i = 0; i < parentValueParts.length - 1; i++) {
                    const parent = parentValueParts.slice(0, i + 1).join('/');
                    this.updateExpandedTreeEntity(parent, true);
                }
            }
        },
        scrollIntoView(value: string | undefined) {
            self.scrollIntoViewItem = value;
        },
        addGroup(id: string) {
            if (self.groups.has(id)) {
                return;
            }
            const newGroup: Group = Group.create({ id, entities: [], timestamp: Date.now() }, getEnv(self));
            self.groups.put(newGroup);
        },
        editGroup(id: string, newId: string) {
            const group: Group | undefined = self.groups.get(id);
            if (!group) {
                return;
            }
            const newGroup: Group = Group.create({ id: newId, entities: [], timestamp: group.timestamp }, getEnv(self));
            group.entities.forEach((entity) => newGroup.addEntity(entity));
            self.groups.delete(id);
            self.groups.put(newGroup);
        },
        removeGroup(id: string) {
            self.groups.delete(id);
        },
        getEntityInContextByName(clusterName: string, databaseName?: string): Database | Cluster | undefined {
            let entity: Cluster | Database | undefined = self.connections.get(clusterName);

            if (!entity) {
                return;
            }

            if (databaseName && entity.databases) {
                entity = entity.databases.get(`${clusterName}/${databaseName}`);
            }

            return entity;
        },
        /**
         * id: entity's ID. e.g. help/samples
         * clusterName: if empty the clusterName will be extracted from the ID.
         */
        getEntityFromId(id: string, clusterName?: string) {
            if (!id) {
                return;
            }

            const isFolder = id.startsWith(FOLDER_PREFIX);
            if (isFolder) {
                return;
            }
            const isEntityGroup = id.startsWith(ENTITY_GROUP_PREFIX);
            const isEntityGroupOld = id.startsWith(ENTITY_GROUP_PREFIX_OLD);
            const entityGroupPrefix = isEntityGroup
                ? ENTITY_GROUP_PREFIX
                : isEntityGroupOld
                ? ENTITY_GROUP_PREFIX_OLD
                : undefined;
            const isEntityGroupMember = id.startsWith(ENTITY_GROUP_MEMBER_PREFIX);
            const isEntityGroupMemberOld = id.startsWith(ENTITY_GROUP_MEMBER_PREFIX_OLD);
            const entityGroupMemberPrefix = isEntityGroupMember
                ? ENTITY_GROUP_MEMBER_PREFIX
                : isEntityGroupMemberOld
                ? ENTITY_GROUP_MEMBER_PREFIX_OLD
                : undefined;
            const isFunction = id.startsWith(FUNCTION_PREFIX);
            const isFunctionOld = id.startsWith(FUNCTION_PREFIX_OLD);
            const functionPrefix = isFunction ? FUNCTION_PREFIX : isFunctionOld ? FUNCTION_PREFIX_OLD : undefined;
            let path = id.split('/');
            if (functionPrefix) {
                path = id.substring(functionPrefix.length).split('/');
            }
            if (entityGroupPrefix) {
                path = id.substring(entityGroupPrefix.length).split('/');
            }
            if (entityGroupMemberPrefix) {
                path = id.substring(entityGroupMemberPrefix.length).split('/');
            }
            const _clusterName = clusterName ?? path[0];

            const cluster = self.connections.get(_clusterName);

            if (path[1] === undefined) {
                return cluster;
            }
            const databaseName = path[1];

            const database = cluster?.databases.get(`${_clusterName}/${databaseName}`);
            if (!database) {
                return;
            }

            if (path[2] === undefined) {
                return database;
            }

            if (functionPrefix) {
                const functionId = path[2];
                return database!.functions.get(`${functionPrefix}${_clusterName}/${databaseName}/${functionId}`);
            }

            if (entityGroupPrefix) {
                const entityGroupId = path[2];
                return database!.entityGroups.get(
                    `${entityGroupPrefix}${_clusterName}/${databaseName}/${entityGroupId}`
                );
            }

            if (entityGroupMemberPrefix) {
                const entityGroupMemberId = path[3];
                const entityGroupId = path[2];
                const entityGroup = database!.entityGroups.get(
                    `${
                        isEntityGroupMember ? ENTITY_GROUP_PREFIX : ENTITY_GROUP_PREFIX_OLD
                    }${_clusterName}/${databaseName}/${entityGroupId}`
                );
                return entityGroupMemberId === undefined
                    ? entityGroup
                    : entityGroup!.entities[
                          `${entityGroupMemberPrefix}${_clusterName}/${databaseName}/${entityGroupMemberId}`
                      ];
            }

            const tableName = path[2];
            const columnName = path[3];
            const table = database!.tables.get(`${_clusterName}/${databaseName}/${tableName}`);

            return columnName === undefined
                ? table
                : table!.columns[`${_clusterName}/${databaseName}/${tableName}/${columnName}`];
        },
        openInWebUI(relativePath?: string) {
            trackEvent('openInWebUi', {
                flow: 'openInWebUi',
                relativePath: relativePath ?? '',
            });
            const authProvider = getQueryStoreEnv(self).authProvider;

            // Best effort try to extract the user from our token (if we have one).
            // If we do - inject the tenant name as a query param so that external portal will know what's the preferred
            // tenant to provide to AAD when requesting its own token.
            const account = authProvider.getAccount();
            const tenant = account?.tenantId;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const username = account?.username;
            trackTrace('openInWebUi:profile:', SeverityLevel.Verbose, {
                flow: 'openInWebUi',
                username: `${username}`,
                tenant: `${tenant}`,
            });
            const queryParams = new URLSearchParams();
            if (tenant) {
                queryParams.append('tenant', tenant);
            }
            if (username) {
                queryParams.append('login_hint', username);
            }
            let query = queryParams.toString();
            if (query) {
                query = `?${query}`;
            }
            const path = relativePath ?? window.location.pathname;
            const url = `${window.location.protocol}//${window.location.host}${path}${query}`;
            trackTrace('openInWebUi:url:', SeverityLevel.Verbose, { flow: 'openInWebUi', url: url });
            window.open(url);
        },
        /**
         * Refetch schema for the database in context.
         * If the entity in context is a cluster or if the cluster's schema is already cached, do nothing.
         */
        refetchSchemaForDatabaseInContextIfNeeded() {
            const entity = self.entityInContext;
            if (!entity) {
                return;
            }

            if (entity && entity.entityType === EntityType.Database && entity.fetchState === 'notStarted') {
                entity.fetchCurrentSchema(true, false);
            }
        },
        refreshEntity(clusterOrDatabase?: Cluster | Database) {
            const entity = clusterOrDatabase ?? self.entityInContext;
            if (!entity) {
                return;
            }
            entity.fetchCurrentSchema(false, false);
        },
        addToFavorites(entity: Cluster | Database) {
            trackEvent('addToFavorites', {
                flow: 'addToFavorites',
                id: entity.id,
                name: entity.name,
            });
            entity.setIsFavorite(true);
            self.favorites.put(entity);
        },
        removeFromFavorites(clusterOrDatabase: Cluster | Database | undefined) {
            trackEvent('removeFromFavorites', {
                flow: 'removeFromFavorites',
            });
            if (clusterOrDatabase) {
                clusterOrDatabase.setIsFavorite(false);
                self.favorites.delete(clusterOrDatabase.id);
            }
        },
        refreshSchemas() {
            const expandedEntitiesToRefresh = toArray(self.expandedEntities)
                .filter((entity) => entity?.entityType === EntityType.Cluster)
                .slice(-4); // newest are at end;

            const failedConnectionToRefresh = toArray(self.connections)
                .filter((entity) => inactiveFetchStates.includes(entity.fetchState))
                .slice(4); // newest are at start

            const limit = pLimit(3);
            Promise.allSettled<EntityRefreshResult>(
                Array.from(new Set([self.entityInContext, ...expandedEntitiesToRefresh, ...failedConnectionToRefresh]))
                    // Filter out empty self.entityInContext - new user flow (empty store)
                    .filter((entity) => !!entity)
                    .map((entity) =>
                        limit(() => {
                            const {
                                majorVersion: prevMajorVersion,
                                minorVersion: prevMinorVersion,
                                fetchState: prevFetchState,
                            } = entity;

                            return entity?.fetchCurrentSchema(true, false).then(() => {
                                const { name, entityType, fetchState, majorVersion, minorVersion } = entity;
                                return {
                                    name,
                                    entityType,
                                    fetchState,
                                    prevMajorVersion,
                                    prevMinorVersion,
                                    prevFetchState,
                                    majorVersion,
                                    minorVersion,
                                };
                            });
                        })
                    )
            ).then((results) => {
                const updates = results
                    .filter((result) => result.status === 'fulfilled')
                    .map((result) => (result as PromiseFulfilledResult<EntityRefreshResult>).value);

                const schemaChangeUpdates = updates.filter(
                    (result) =>
                        result.majorVersion !== result.prevMajorVersion ||
                        result.minorVersion !== result.prevMinorVersion
                );
                const activatedConnectionUpdates = updates.filter(
                    (result) => result.prevFetchState !== result.fetchState
                );

                if (schemaChangeUpdates.length || activatedConnectionUpdates.length) {
                    trackEvent('ConnectionPane.RefreshSchemas', {
                        activatedConnectionUpdates,
                        schemaChangeUpdates,
                    });
                }
            });
        },
        setClusterNotInList: (queryPageLinkProperties: DeepLinkProperties) => {
            self.clusterNotInListDialog = queryPageLinkProperties;
        },
        removeClusterNotInList: () => {
            self.clusterNotInListDialog = undefined;
        },
    }))
    .actions((self) => {
        const queryStoreEnv = getQueryStoreEnv(self);

        /**
         * Set the connection pane entity in context and
         * follow up integrity updates:
         *      - fetch database schema if needed
         *      - more ?
         */
        function setEntityInContextInternal(entity: Cluster | Database | undefined) {
            self.entityInContext = entity;
            self.refetchSchemaForDatabaseInContextIfNeeded();
        }

        function removeOrReplaceFavoritesByClusterId(clusterIdToRemove: string, replaceWithCluster?: Cluster) {
            const favorites = self.favorites;
            // Find all references to the cluster in favorites
            const referencesToClusterInFavorites = Array.from(favorites.values()).filter((fav) => {
                // This is a weak entity reference so can actually be undefined
                if (!fav) {
                    return false;
                }
                const idParts = fav.id.split('/');
                return idParts[0] === clusterIdToRemove;
            });

            // Remove/replace the cluster in Favorites
            referencesToClusterInFavorites.forEach((entity) => {
                if (!entity?.id) {
                    return;
                }
                favorites.delete(entity.id);

                if (replaceWithCluster) {
                    const replaceEntity = self.getEntityFromId(entity.id, replaceWithCluster.name);
                    if (replaceEntity) {
                        self.addToFavorites(replaceEntity as Database | Cluster);
                    }
                }
            });
        }

        /**
         * Remove cluster from connection list and remove/replace all the reference to the cluster
         *
         * - replace reference to the cluster in connection pane and tabs entity in context
         *   to default cluster or provided replaceWithCluster
         * - replace (or remove when replaceWithCluster not provided) references to the cluster in favorites
         * - remove the cluster from connection
         *
         * @param clusterId
         * @param replaceWithCluster - optional - if not provided will replace with random cluster where applicable
         */
        function removeConnectionAndReplaceReferences(clusterId: string, replaceWithCluster?: Cluster) {
            trackEvent('removeAndReplaceConnection', {
                flow: 'removeAndReplaceConnection',
                clusterId,
            });

            // if entity in context is in cluster to be removed - change it otherwise
            // we'll have a dangling reference.

            // Find the first cluster that isn't the one we're going to remove (or undefined if that's the only one).
            const defaultEntityInContext: Cluster | undefined =
                replaceWithCluster ?? Array.from(self.connections.values()).find((c) => c.id !== clusterId);

            // Update ConnectionPane entityInContext
            if (self.entityInContext && self.entityInContext.id.split('/')[0] === clusterId) {
                const replaceEntity = replaceWithCluster
                    ? (self.getEntityFromId(self.entityInContext.id, replaceWithCluster.name) as
                          | ClusterOrDatabase
                          | undefined)
                    : undefined;
                setEntityInContextInternal(replaceEntity ?? defaultEntityInContext);
            }

            // Foreach Tab, update entityInContext
            const { tabs } = (getRoot(self) as IRootStore).tabs;
            tabs.forEach((tab) => {
                if (tab.entityInContext && tab.entityInContext.id.split('/')[0] === clusterId) {
                    const replaceEntity = replaceWithCluster
                        ? (self.getEntityFromId(tab.entityInContext.id, replaceWithCluster.name) as
                              | ClusterOrDatabase
                              | undefined)
                        : undefined;
                    tab.setEntityInContext(replaceEntity ?? defaultEntityInContext);

                    tab.setTitlePlaceholder(undefined); // used by trident only
                }
            });

            removeOrReplaceFavoritesByClusterId(clusterId, replaceWithCluster);

            self.connections.delete(clusterId);
        }

        // Remove the cluster from all groups
        function removeFromGroups(clusterId: string) {
            const groups: Group[] = Array.from(self.groups.values());
            groups.forEach((group: Group) => {
                const index = group.entities.findIndex((entity) => entity.id === clusterId);
                if (index !== -1) {
                    self.groups.get(group.id)?.removeEntity(clusterId);
                }
            });
        }

        function updateContextIfNeeded(cluster: Cluster, shouldUpdateContext: boolean) {
            if (shouldUpdateContext) {
                // set the cluster to be the entity in context both for the connection tree and for the current tab.
                setEntityInContextInternal(cluster);
                const tabs = (getRoot(self) as IRootStore).tabs;
                if (tabs.tabInContext) {
                    tabs.tabInContext.setEntityInContext(cluster);
                }
            }
        }

        return {
            setEntityInContextByObject(entity?: Cluster | Database) {
                trackEvent('setEntityInContextByObject', {
                    flow: 'setEntityInContextByObject',
                    id: entity ? entity.id : 'none',
                });

                setEntityInContextInternal(entity);
            },
            /**
             * entity in context entity can be either a cluster or a database.
             * if second parameter is not undefined - it's a database that's in context.
             */
            setEntityInContextByName(clusterName: string, databaseName?: string): Database | Cluster | undefined {
                trackEvent('setEntityInContextByName', {
                    flow: 'setEntityInContextByName',
                    clusterName,
                    databaseName: databaseName ? databaseName : '',
                });

                const entity = self.getEntityInContextByName(clusterName, databaseName);

                if (entity && entity !== self.entityInContext) {
                    setEntityInContextInternal(entity);
                }

                return entity;
            },
            addConnection: flow(function* addConnection(
                clusterName: string,
                connectionString: string,
                initialCatalog?: string,
                alias?: string,
                basicAccountInfo?: BasicAccountInfo,
                updateContext = true
            ) {
                const env = getQueryStoreEnv(self);
                trackEvent('addConnection', { flow: 'addConnection', clusterName });
                const clusters = Array.from(self.connections.values());
                const existingCluster: Cluster | undefined = clusters.find((cluster: Cluster) => {
                    return cluster.connectionString === connectionString;
                });
                if (existingCluster) {
                    trackTrace('addConnection: connection already exist. Will refresh connection instead of adding.');
                    existingCluster.basicAccountInfo = basicAccountInfo;

                    updateContextIfNeeded(existingCluster, updateContext);
                    // set the new cluster to be the entity in context both for the connection tree and for the current tab.

                    yield existingCluster.fetchCurrentSchema(false);
                    return existingCluster;
                }

                try {
                    // KEEP both ignore lines
                    // force the evaluation of new URL
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const test = new URL(connectionString);
                } catch (e: unknown) {
                    trackException(castToError(e), 'addConnection:invalid-url', { connectionString });
                    return undefined;
                }
                const newCluster: Cluster = Cluster.create(
                    {
                        alias: alias,
                        name: clusterName,
                        connectionString: connectionString,
                        id: clusterName,
                        initialCatalog,
                        basicAccountInfo,
                    },
                    getEnv(self)
                );
                self.connections.put(newCluster);

                updateContextIfNeeded(newCluster, updateContext);

                yield newCluster!.fetchCurrentSchema(false);
                if (!env.featureFlags.cheackClusterHealth && queryStoreEnv.armClient) {
                    checkClusterHealthEvents(
                        env,
                        queryStoreEnv.kustoClient,
                        queryStoreEnv.armClient,
                        newCluster.clusterUrl,
                        newCluster.getAccount_UNSAFE()
                    );
                }
                return newCluster;
            }),
            replaceConnection(clusterId: string, replaceWithCluster: Cluster) {
                trackEvent('replaceConnection', {
                    flow: 'replaceConnection',
                    clusterId,
                    newClusterId: replaceWithCluster.id,
                });
                removeConnectionAndReplaceReferences(clusterId, replaceWithCluster);
            },
            removeConnection(clusterId: string) {
                trackEvent('removeConnection', {
                    flow: 'removeConnection',
                    clusterId,
                });
                removeFromGroups(clusterId);
                removeConnectionAndReplaceReferences(clusterId);
            },
            removeExpandedConnection(clusterValue: string) {
                trackEvent('removeExpandedConnection', {
                    flow: 'removeExpandedConnection',
                    clusterValue,
                });
                const expandedEntities = Array.from(self.expandedTreeEntities.values()).filter((value) =>
                    value.startsWith(clusterValue)
                );

                expandedEntities.forEach((value) => {
                    self.expandedTreeEntities.delete(value);
                });
            },
            toggleFavorites() {
                trackEvent('toggleFavorites', { flow: 'toggleFavorites' });
                self.showOnlyFavorites = !self.showOnlyFavorites;
            },
        };
    })
    .actions((self) => {
        const storeEnv = getQueryStoreEnv(self);
        const getClusterByUrl = (url: string): Cluster | undefined => {
            const normalizedUrl = (url: string) => {
                const lowerUrl = getClusterUrl(url.toLowerCase());
                return lowerUrl.endsWith('/') ? lowerUrl : lowerUrl + '/';
            };
            const requestedUrl = normalizedUrl(url);
            // Perform a case-insensitive search for the cluster.
            const cluster = Array.from(self.connections.entries()).find(([clusterId, clusterInfo]) => {
                if (!clusterInfo?.connectionString) {
                    trackTrace('getClusterByUrl: cluster is null', SeverityLevel.Error, {
                        clusterId: clusterId ?? 'NA',
                    });
                    return false;
                }
                // Kusto connection strings support authentication parameters like "fed", that come after the character ";".
                // Removing these for now because our clusters in the connection list do not contain them.
                return normalizedUrl(clusterInfo.connectionString) === requestedUrl;
            })?.[1];
            return cluster;
        };
        return {
            getClusterByUrl,
            afterCreate: () => {
                const armClient = storeEnv.armClient;
                if (!self.healthCheckPerformed && armClient) {
                    self.connections.forEach((connection) => {
                        checkClusterHealthEvents(
                            storeEnv,
                            storeEnv.kustoClient,
                            armClient,
                            connection.clusterUrl,
                            connection.getAccount_UNSAFE()
                        );
                    });
                    self.healthCheckPerformed = true;
                }
            },
            findOrAddCluster: flow(function* findOrAddCluster(
                clusterName: string,
                connectionString: string,
                shouldRefetchSchema?: boolean,
                alias?: string
            ) {
                const env = getQueryStoreEnv(self);
                const existingCluster = getClusterByUrl(connectionString);
                if (existingCluster) {
                    self.setEntityInContextByObject(existingCluster);
                    if (shouldRefetchSchema) {
                        yield existingCluster.fetchCurrentSchema(env.featureFlags.RefreshConnection);
                    }
                    return existingCluster;
                }

                const testResult = yield testConnection(env, clusterName, connectionString);
                if (testResult.kind === 'err') {
                    env.config.actions?.userNotification?.({
                        title: env.strings.query.deepLinkHandler$connectionTestFailed,
                        content: testResult.err?.errorMessage + '\n' + connectionString,
                        type: NotificationType.Error,
                    });
                    return undefined;
                }
                const cluster: Cluster | undefined = yield self.addConnection(
                    clusterName,
                    testResult.value ?? connectionString,
                    undefined,
                    alias
                );

                return cluster;
            }),
            isDatabaseSchemaFetchInProgress(database?: Database): boolean | undefined {
                if (!database) {
                    return;
                }

                const cluster = getClusterFromDatabase(database);
                return (cluster.isFetching && database.fetchState !== 'failed') || database.isFetching;
            },
            isDatabaseSchemaFetchFailed(database?: Database) {
                if (!database) {
                    return false;
                }

                const cluster = getClusterFromDatabase(database);
                return (
                    database.fetchState === 'failed' ||
                    (cluster.fetchState === 'failed' && !database.isFetching && database.fetchState !== 'done')
                );
            },
        };
    })
    .views((self) => ({
        /** returns a mapping between clusters' aliases and their name*/
        aliasesToNameMapping: (): { [alias: string]: string } => {
            const mapping = {} as { [alias: string]: string };
            self.connections.forEach((connection) => {
                if (connection.alias) {
                    mapping[connection.alias] = connection.name;
                }
            });

            return mapping;
        },
        countClustersWithDescendant: (): {
            clusters: number;
            databases: number;
            functions: number;
            tables: number;
            columns: number;
        } => {
            let [clusters, databases, tables, functions, columns] = [0, 0, 0, 0, 0];
            self.connections.forEach((cluster) => {
                clusters++;
                databases += cluster.databases.size;
                cluster.databases.forEach((database) => {
                    functions += database.functions.size;
                    tables += database.tables.size;
                    database.tables.forEach((table) => {
                        columns += size(table.columns);
                    });
                });
            });
            return { clusters, databases, functions, tables, columns };
        },
    }));
// eslint-disable-next-line no-redeclare
export type ConnectionPane = Instance<typeof ConnectionPane>;
