@use '@kusto/style/tokens';

$label-line-height: 20px;
$label-font-size: 14px;
$line-height-correction: calc(($label-line-height - $label-font-size) / 2);

.dialogContent {
    width: 310px;
    display: flex;
    flex-direction: column;

    $font-size: 14px;
    $line-height: 20px;
    grid-row-gap: 16px;

    grid-column-gap: 8px - $line-height-correction;

    label {
        line-height: $label-line-height;
        font-size: $label-font-size;
        padding: 0;
        margin-bottom: 8 - $line-height-correction;
    }
}

.fullWidth {
    grid-column: span 2;
}

.footer {
    display: flex;
    justify-content: flex-end;
    gap: tokens.$spacingHorizontalS;
    grid-column: span 2;
}

// Callout adds a span where it's added in the DOM, which causes styling issues.
// Couldn't find a way to add styles to the span directly.
.wrapper {
    display: none;
}
