import * as React from 'react';
import { useKeyborgRef } from './useKeyborgRef';
/**
 */ export function useSetKeyboardNavigation() {
    const keyborgRef = useKeyborgRef();
    return React.useCallback((isNavigatingWithKeyboard)=>{
        var _keyborgRef_current;
        (_keyborgRef_current = keyborgRef.current) === null || _keyborgRef_current === void 0 ? void 0 : _keyborgRef_current.setVal(isNavigatingWithKeyboard);
    }, [
        keyborgRef
    ]);
}
