import { registerIcons } from '@fluentui/style-utilities';

import type { ReadonlyRecord } from '../types';

export interface RegisterFontIconsOptions<T extends ReadonlyRecord<string, number>> {
    characterCodes: T;
    namespace: string;
}

/**
 * For use with `Scripts/build-icon-fonts.mjs`
 *
 * TODO: Add support for Fluent 9. Make Fluent 8 support optional.
 */
export function registerFontIcons<T extends ReadonlyRecord<string, number>>(
    options: RegisterFontIconsOptions<T>
): Record<keyof typeof options.characterCodes, string> {
    const namespaceIconName = (name: string) => `${options.namespace}--${name}`;

    // **IMPORTANT:** All SVGs must have either equal width/height to the
    // viewbox, or only viewbox specified
    //
    // Calling this inside of the same function that creates the
    // `dashboardIconNames` object so that the icons will be registered if the
    // icons names aren't tree shaken.
    registerIcons({
        fontFace: {
            fontFamily: `"${options.namespace}-icons"`,
        },
        icons: Object.fromEntries(
            Object.entries(options.characterCodes).map(([key, icon]) => [
                namespaceIconName(key),
                String.fromCharCode(icon),
            ])
        ),
    });

    return Object.fromEntries(
        Object.keys(options.characterCodes).map((key) => [key, namespaceIconName(key)])
    ) as Record<keyof typeof options.characterCodes, string>;
}
