import type { VisualOptionKey, VisualOptionsKeysFor } from '../visualOptions/model';
import type { VisualInput } from './input';

export const VISUAL_SECTION_KEY = 'visual' as const;
export const INTERACTIONS_SECTION_KEY = 'interactions' as const;
export const visualConfigSectionsKeys = [VISUAL_SECTION_KEY, INTERACTIONS_SECTION_KEY] as const;

export type Empty = false | null | undefined;

export declare namespace VisualConfigLayout {
    export type SectionKey = 'visual' | 'interactions';

    export interface SegmentToggle<C extends VisualOptionKey> {
        readonly optionKey: VisualOptionsKeysFor<C, boolean>;
        readonly labels: { readonly enabled: string; readonly disabled: string };
        readonly titleText: string;
        /**
         * Section toggle state will be inverted compared to the visual options
         * property state
         */
        readonly invert?: boolean;
    }

    export interface Segment<C extends VisualOptionKey = VisualOptionKey, H = unknown> {
        readonly displayName: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        readonly inputs?: ReadonlyArray<Empty | VisualInput<C, H, any>>;
        readonly toggle?: SegmentToggle<C>;
        readonly hideReset?: boolean;
    }

    export interface Section<C extends VisualOptionKey, H> {
        readonly head?: Empty | ReadonlyArray<VisualInput<C, H>>;
        readonly segments?: Empty | ReadonlyArray<Segment<C, H>>;
    }

    export type Layout<C extends VisualOptionKey = VisualOptionKey, H = unknown> = {
        readonly [K in SectionKey]?: Empty | Section<C, H>;
    };
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type VisualConfigLayout<C extends VisualOptionKey = VisualOptionKey, H = unknown> = VisualConfigLayout.Layout<
    C,
    H
>;

export type SegmentBuilderOptions<C extends VisualOptionKey, H> = Pick<
    VisualConfigLayout.Segment<C, H>,
    'toggle' | 'hideReset'
>;

export function tileInputSegment<C extends VisualOptionKey, H = unknown>(
    displayName: string,
    { toggle: segmentToggleInfo, hideReset: hideResetButton }: SegmentBuilderOptions<C, H> = {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...configKeys: ReadonlyArray<Empty | VisualInput<C, H, any>>
): VisualConfigLayout.Segment<C, H> {
    return { displayName, toggle: segmentToggleInfo, hideReset: hideResetButton, inputs: configKeys };
}
