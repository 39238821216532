import * as React from 'react';
/**
 * Provides a mount node for portals to render into.
 *
 * @internal
 */ const PortalMountNodeContext = React.createContext(undefined);
/**
 * @internal
 */ export const PortalMountNodeProvider = PortalMountNodeContext.Provider;
export function usePortalMountNode() {
    return React.useContext(PortalMountNodeContext);
}
