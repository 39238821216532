import React from 'react';
import { Tree, TreeOpenChangeData, TreeOpenChangeEvent } from '@fluentui/react-components';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { TreeItemWrapper } from './TreeEntity';
import { ConnectionTreeProps, NestedTreeEntity } from './types';

// Function to create the ID-to-entity map
const createIdEntityMap = (list: NestedTreeEntity[]) => {
    const idEntityMap: { [key: string]: NestedTreeEntity } = {};

    const traverseEntities = (list: NestedTreeEntity[]) => {
        for (const entity of list) {
            idEntityMap[entity.id] = entity;
            traverseEntities(entity.subtree);
        }
    };

    traverseEntities(list);
    return idEntityMap;
};

function caseInsensitiveIncludes(str: string, substr: string): boolean {
    const regex = new RegExp(`(${substr})`, 'gi');
    return regex.test(str);
}

function getFilteredTreeArray(subtree: NestedTreeEntity[], searchString: string): NestedTreeEntity[] {
    return subtree
        .map((treeEntity) => getFilteredTree(treeEntity, searchString))
        .filter((treeEntity) => treeEntity.subtree.length || caseInsensitiveIncludes(treeEntity.name, searchString));
}

function getFilteredTree(tree: NestedTreeEntity, searchString: string): NestedTreeEntity {
    return {
        ...tree,
        subtree: getFilteredTreeArray(tree.subtree, searchString),
    };
}

export const ConnectionTree: React.FC<ConnectionTreeProps> = ({
    list,
    searchString,
    onEntityClick,
    logErrorToService,
    ...props
}) => {
    const idEntityMap = React.useMemo(() => createIdEntityMap(list), [list]);

    const handleOpenChange = (_: TreeOpenChangeEvent, data: TreeOpenChangeData) => {
        const entity = idEntityMap[data.value];
        if (entity) {
            onEntityClick?.(entity, data);
        }
    };
    // SearchTree is always controlled, FullTree can be controlled or uncontrolled based on lib client's usage.
    // moving from controlled to uncontrolled is forbidden, so we need two Tree elements and react needs either 2 different components or a key prop to tell them apart
    return (
        <ErrorBoundary fallback={<>Ops something went wrong...</>} logErrorToService={logErrorToService}>
            {searchString ? (
                <SearchTree list={list} onOpenChange={handleOpenChange} searchString={searchString!} {...props} />
            ) : (
                <FullTree list={list} onOpenChange={handleOpenChange} {...props} />
            )}
        </ErrorBoundary>
    );
};

const SearchTree: React.FC<ConnectionTreeProps & Required<Pick<ConnectionTreeProps, 'searchString'>>> = ({
    list,
    entityMap,
    icons,
    searchString,
    entityActionsMap,
    ...props
}) => {
    const { filteredList, filteredIds } = React.useMemo(() => {
        const filteredList = getFilteredTreeArray(list, searchString);

        const filteredIds: string[] = filteredList.map((item: { id?: string }) => item.id ?? '');

        return { filteredList, filteredIds: filteredIds.length ? new Set(filteredIds) : undefined };
    }, [list, searchString]);

    return (
        <Tree {...props} aria-label="Tree" defaultOpenItems={filteredIds}>
            {filteredList.map((entity: NestedTreeEntity) => {
                return (
                    <TreeItemWrapper
                        key={`${entity.id}-searchTree`}
                        icons={icons}
                        entity={entity}
                        entityMap={entityMap}
                        highlightString={searchString}
                        entityActionsMap={entityActionsMap}
                    />
                );
            })}
        </Tree>
    );
};

const FullTree: React.FC<ConnectionTreeProps> = ({
    list,
    entityMap,
    entityActionsMap,
    icons,
    selectedItems,
    scrollIntoViewItem,
    ...props
}) => {
    const wrapperProps = {
        entityMap,
        entityActionsMap,
        icons,
        selectedItems,
        scrollIntoViewItem,
    };
    return (
        <Tree {...props} aria-label="Tree">
            {list.map((entity: NestedTreeEntity) => {
                return <TreeItemWrapper key={`${entity.id}-fullTree`} entity={entity} {...wrapperProps} />;
            })}
        </Tree>
    );
};
