import { cast, ISimpleType, types } from 'mobx-state-tree';

import * as kusto from '@kusto/client';
import { Theme } from '@kusto/utils';

import { configureSubDomains } from '../../utils/configureSubDomains';
import { getQueryStoreEnv } from '../storeEnv';
import { OriginAllowlist } from './originAllowlist';

export const DEFAULT_TIME_ZONE = 'UTC';

export const themes = Object.keys(Theme).map((k) => Theme[k as keyof typeof Theme]);

/**
 * Resolved once on browser init so we don't get tearing if it changes during
 * the session. No way to subscribe to this.
 */
export const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const Settings = types
    .model('Settings', {
        enableCodelensOverride: types.optional(types.boolean, false),
        trustedHosts: types.optional(types.string, ''),
        originAllowlist: types.optional(OriginAllowlist, () => OriginAllowlist.create({ allowlist: '' })),
        includeExtendedSyntax: types.optional(types.maybe(types.boolean), false),
        formatResultData: types.optional(types.boolean, true),
        theme: types.optional(types.enumeration(themes), Theme.Light),
        /**
         * Use resolvedTimeZone instead for most things
         *
         * `null` represents the browser time-zone setting. `undefined` is
         * defaulted to `UTC`.
         *
         * Ideally, we'd use something other than `null` to represent browser
         * time, but migrations here are hard. Something like `{ kind: 'browser'
         * } | { kind: 'value', timeZone: string }` would be better.
         */
        timezone: types.optional(types.maybeNull(types.string), DEFAULT_TIME_ZONE),
        /**
         * Use resolvedTimeZoneName instead for most things
         *
         * `null` represents the browser time-zone setting. `undefined` is
         * defaulted to `UTC`.
         *
         * @deprecated Can be removed after a few releases
         */
        timezoneDisplayName: types.maybe(types.maybeNull(types.string)),
        hideEmptyCellInRowExpand: types.optional(types.boolean, false),
        displayGridLevelColoring: types.optional(types.boolean, true),
        language: types.optional(types.maybe(types.string), undefined),
        timeoutInMinutes: types.optional(types.integer, 4),
        adminCommandTimeoutInMinutes: types.optional(types.integer, 60),
        weakConsistency: types.optional(types.boolean, false),
        lazySchemaExploration: types.optional(types.boolean, false),
        liteSchemaExplorationOverride: types.optional(types.boolean, true),
        mouseWheelZoom: types.optional(types.boolean, true),
        emptyWorkspaceOnDeepLinkQuery: types.optional(types.boolean, false),
        closeExpandViewOnClick: types.optional(types.boolean, false),
        alignRightResultNumbers: types.optional(types.boolean, true),
        useBoost: types.optional(types.boolean, false),
        showAllSeriesOnHover: types.optional(types.boolean, true),
        clickableLinks: types.optional(types.boolean, true),
        // TODO: Delete when safe + remove via migration. Stopped using in version 2.88.0
        showUnknownLinkConfirmationDialog: types.optional(types.boolean, true),
        openSuggestionDialogAfterPreviousSuggestionAcceptedOverride: types.optional(types.boolean, true),
        enableWarnings: types.optional(types.boolean, true),
        enableSuggestions: types.optional(types.boolean, true),
        disabledRecommendationCodes: types.optional(types.array(types.string), []),
        ignoreChartLimits: types.optional(types.boolean, false),
        engineParser: types.optional(types.maybe(types.string) as ISimpleType<kusto.EngineParserType>, undefined),
        showQueriesOperationDocumentation: types.optional(types.maybe(types.boolean), undefined),
        crossTenantSharingEnabled: types.optional(types.maybe(types.boolean), false),
    })
    .volatile((_self) => ({
        readonly: false,
        allowSettingCrossTenantSharingFeatureFlag: false,
    }))
    .actions((self) => ({
        setAllowSettingCrossTenantSharingFeatureFlag(allow: boolean) {
            self.allowSettingCrossTenantSharingFeatureFlag = allow;
        },
        setCrossTenantSharingEnabled: (enabled: boolean) => {
            self.crossTenantSharingEnabled = enabled;
        },
        setReadonly: (readonly: boolean) => {
            self.readonly = readonly;
        },
        setEnableCodeLens: (enable: boolean) => {
            self.enableCodelensOverride = enable;
        },
        setTrustedHosts: (trustedHosts: string) => {
            self.trustedHosts = trustedHosts;
            configureSubDomains(getQueryStoreEnv(self).kustoDomains, trustedHosts);
        },
        addTrustedHost: (trustedHost: string) => {
            const updatedTrustedHosts = self.trustedHosts ? `${self.trustedHosts};${trustedHost}` : trustedHost;
            self.trustedHosts = updatedTrustedHosts;
            configureSubDomains(getQueryStoreEnv(self).kustoDomains, updatedTrustedHosts);
        },
        setIncludeExtendedSyntax: (includeExtendedSyntax: boolean) => {
            self.includeExtendedSyntax = includeExtendedSyntax;
        },
        setFormatResultData: (val: boolean) => {
            self.formatResultData = val;
        },
        setTheme: (val: Theme) => {
            self.theme = val;
        },
        setTimezone: (timezone: null | string, displayName?: null | string) => {
            self.timezoneDisplayName = timezone;
            self.timezone = timezone;
            self.timezoneDisplayName = displayName;
            getQueryStoreEnv(self).telemetry.event('TimeZoneChanged', { timezone });
        },
        setHideEmptyCell: (hide: boolean) => (self.hideEmptyCellInRowExpand = hide),
        setLevelColoring: (show: boolean) => (self.displayGridLevelColoring = show),
        setLanguage: (language?: string) => (self.language = language),
        setTimeoutInMinutes: (timeout: number) => (self.timeoutInMinutes = timeout),
        setAdminCommandTimeoutInMinutes: (timeout: number) => (self.adminCommandTimeoutInMinutes = timeout),
        setWeakConsistency: (weak: boolean) => (self.weakConsistency = weak),
        setLazySchemaExploration: (lazySchemaExploration: boolean) =>
            (self.lazySchemaExploration = lazySchemaExploration),
        setLiteSchemaExploration: (liteSchemaExploration: boolean) =>
            (self.liteSchemaExplorationOverride = liteSchemaExploration),
        setMouseWheelZoom: (wheel: boolean) => (self.mouseWheelZoom = wheel),
        setEmptyWorkspaceOnDeepLinkQuery: (empty: boolean) => (self.emptyWorkspaceOnDeepLinkQuery = empty),
        setCloseExpandViewOnClick: (val: boolean) => (self.closeExpandViewOnClick = val),
        setAlignRightResultNumbers: (val: boolean) => (self.alignRightResultNumbers = val),
        setUseBoost: (val: boolean) => (self.useBoost = val),
        setShowAllSeriesOnHover: (val: boolean) => (self.showAllSeriesOnHover = val),
        setClickableLinks: (val: boolean) => (self.clickableLinks = val),
        setOpenSuggestionDialogAfterPreviousSuggestionAccepted: (val: boolean) => {
            self.openSuggestionDialogAfterPreviousSuggestionAcceptedOverride = val;
        },
        setEnableWarnings: (val: boolean) => (self.enableWarnings = val),
        setEnableSuggestions: (val: boolean) => (self.enableSuggestions = val),
        setDisabledRecommendationCodes: (codes: string[]) => (self.disabledRecommendationCodes = cast(codes)),
        setIgnoreChartLimits(val: boolean) {
            self.ignoreChartLimits = val;
        },
        setEngineParser(val: kusto.EngineParserType) {
            self.engineParser = val;
        },
        setShowQueriesOperationDocumentation: (showQueriesOperationDocumentation: boolean | undefined) => {
            self.showQueriesOperationDocumentation = showQueriesOperationDocumentation;
        },
    }))
    .views((self) => ({
        /**
         * get a Kusto @see ClientRequestProperties object from user settings
         */
        clientRequestProperties(isQuery: boolean) {
            // user settings apply to queries only.
            if (!isQuery) {
                return null;
            }

            const timeoutAsTimestampString =
                self.timeoutInMinutes === 60
                    ? '01:00:00'
                    : `00:${self.timeoutInMinutes.toString().padStart(2, '0')}:00`;
            const queryConsistency = self.weakConsistency ? 'weakconsistency' : 'strongconsistency';

            const clientRequestProperties: kusto.ClientRequestProperties = {
                Options: {
                    servertimeout: timeoutAsTimestampString,
                    queryconsistency: queryConsistency,
                },
            };
            return clientRequestProperties;
        },
        get computedLanguage(): string {
            return self.language ?? getQueryStoreEnv(self).config.defaultLanguage ?? navigator.language;
        },
        get monacoEditorTheme() {
            return self.theme === Theme.Dark ? 'kusto-dark' : 'kusto-light';
        },
        /**
         * timeZoneDisplayName with `null` resolved to the browser time
         */
        get resolvedTimeZone() {
            switch (self.timezone) {
                case null:
                    return browserTimezone;
                // An older version of the client would save 'UTC' in this format
                case 'Etc/UTC':
                    return 'UTC';
            }

            // If browser does not support selected time zone, use default
            // value. Until we have roaming profile this should be unlikely.
            try {
                Intl.DateTimeFormat(undefined, { timeZone: self.timezone });
            } catch {
                return DEFAULT_TIME_ZONE;
            }

            return self.timezone;
        },
    }));

export type ISettings = typeof Settings.Type;
