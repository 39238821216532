import * as mobx from 'mobx';

import { renderQueryError } from '../../error';
import type { VisualFwkQueryResult } from '../../queryResult';
import type { KweVisualFwkLocale } from '../../types';
import { SCHEMA_UNINITIALIZED, type SchemaState } from '../../visualConfig';
import { i18nElement } from './i18nElement';

function schemaReducer(
    state: SchemaState,
    action: VisualFwkQueryResult,
    getLocale: () => KweVisualFwkLocale
): SchemaState {
    switch (action.kind) {
        case 'ok':
            return {
                kind: 'available',
                schema: action.value.dataFrame.fields,
            };
        case 'err': {
            if (state.kind === 'uninitialized' || state.kind === 'unavailable') {
                const errorMessage = i18nElement(getLocale, (t) =>
                    renderQueryError(action.err, t.visualFwk.visualConfig.schemaContext.errorInitializingSchema.normal)
                );
                return { kind: 'unavailable', errorMessage };
            }

            const updateErrorMessage = i18nElement(getLocale, (t) =>
                renderQueryError(action.err, t.visualFwk.visualConfig.schemaContext.errorUpdatingSchema.normal)
            );
            return { ...state, errorMessage: updateErrorMessage };
        }
        case 'loading': {
            // Do nothing
            return state;
        }
    }
}

export function createSchemaObservable(
    signal: AbortSignal,
    getQueryResult: () => VisualFwkQueryResult,
    getLocale: () => KweVisualFwkLocale
): mobx.IObservableValue<SchemaState> {
    const schema = mobx.observable.box<SchemaState>(SCHEMA_UNINITIALIZED, { deep: false, name: 'Schema State' });

    signal.addEventListener(
        'abort',
        mobx.reaction(
            getQueryResult,
            (value) => {
                schema.set(schemaReducer(schema.get(), value, getLocale));
            },
            { fireImmediately: true }
        )
    );

    return schema;
}
