import type { SeverityLevel as AppInsightsSeverityLevel, IAppInsights } from '@microsoft/applicationinsights-web';

import type { MaybeRedactedResult } from '../pii';
import type { Dispose } from '../types';
import { assertNever, castToError } from '../utils/errors';
import type {
    BoundProperties,
    BoundPropertiesList,
    CustomProperties,
    IExceptionTelemetry,
    IKweTelemetry,
    IMetricTelemetry,
    IPageViewTelemetry,
    IStopTrackEvent,
    ITraceTelemetry,
    SeverityLevel,
} from './interface';

function assignBoundProperties(existing: undefined | BoundProperties, properties: Iterable<BoundProperties>) {
    const result: BoundProperties = {};
    for (const staticProps of properties) {
        const resolved = typeof staticProps === 'function' ? staticProps() : staticProps;
        // Object.assign so we're not creating copies
        Object.assign(result, resolved);
    }
    Object.assign(result, existing);
    return result;
}

export interface KweTelemetrySettings {
    readonly logLevelTelemetry: () => 'none' | SeverityLevel;
    readonly logLevelConsole: () => 'none' | SeverityLevel;
    readonly redactPii: <Data = unknown>(data: Data) => MaybeRedactedResult<Data>;
    readonly isMicrosoftInternalAccount: boolean;
}

function translateSeverityLevel(level: SeverityLevel): AppInsightsSeverityLevel {
    switch (level) {
        case 'verbose':
            return 0;
        case 'information':
            return 1;
        case 'warning':
            return 2;
        case 'error':
            return 3;
        case 'critical':
            return 4;
        default:
            assertNever(level);
    }
}

type TelemetryMethodName = Exclude<keyof IKweTelemetry, 'bind' | 'info' | 'verbose'>;

/**
 * Parts of the app insights interface that we use
 */
export type KweTelemetryAppInsights = Omit<IAppInsights, '_onerror' | 'getCookieMgr'>;

/**
 * # KweTelemetry
 *
 * ## Goals
 * - Provide a stable telemetry interface for our entire repo to depend on
 * - Map that interface to `IAppInsights`
 * - Implement widely used convenience methods
 *
 * ## Non-goals
 * - Configure app insights
 * - Replicate functionality that can be achieved by configuring app-insights
 * - Implement package-specific functionality that could be achieved with code
 *   locally defined
 *
 * app-insights initialization code may come to @kusto/utils if/when we have
 * multiple client apps, but it shouldn't be mixed in with this code.
 *
 * Docs: https://docs.microsoft.com/en-us/azure/azure-monitor/app/data-model
 */
export class KweTelemetry implements IKweTelemetry {
    private constructor(
        private readonly appInsights: KweTelemetryAppInsights,
        private readonly config: KweTelemetrySettings,
        private readonly globalProperties: Map<symbol, BoundProperties>,
        private readonly properties: BoundPropertiesList
    ) {}

    /**
     * @param properties Properties here are added through
     * {@link IKweTelemetry.bindGlobal}, so they don't run through the PII
     * redactor, and are added to every telemetry event created by appInsights,
     * including things like uncaught errors, or page views.
     */
    public static fromAppInsights(
        appInsights: KweTelemetryAppInsights,
        config: KweTelemetrySettings,
        ...properties: BoundPropertiesList
    ): IKweTelemetry {
        const globalProperties = new Map<symbol, BoundProperties>();

        appInsights.addTelemetryInitializer((envelope) => {
            envelope.data = assignBoundProperties(envelope.data, globalProperties.values());
        });

        for (const property of properties) {
            globalProperties.set(Symbol('constructor'), property);
        }

        return new KweTelemetry(appInsights, config, globalProperties, []);
    }

    private applyBoundProperties(properties: undefined | CustomProperties): CustomProperties {
        return assignBoundProperties(properties, this.properties);
    }

    private logConsole(methodName: TelemetryMethodName, severityLevel: SeverityLevel, ...data: unknown[]) {
        if (!this.checkSeverityLevel(severityLevel, 'logLevelConsole')) {
            return;
        }

        let fnc: 'error' | 'warn' | 'info';
        switch (severityLevel) {
            case 'error':
            case 'critical':
                fnc = 'error';
                break;
            case 'warning':
                fnc = 'warn';
                break;
            case 'information':
            case 'verbose':
                fnc = 'info';
                break;
            default:
                assertNever(severityLevel);
        }

        // eslint-disable-next-line no-console
        console[fnc](
            methodName,
            severityLevel,
            ...data,
            // Adding global properties so devs don't get confused as to why
            // they aren't showing up. It's an additional item because only the
            // caller know which data value would be the right one to mix these
            // into.
            //
            // This does mean we're resolving global properties twice per
            // telemetry call when console logging is turned on, which is pretty
            // inefficient. This is fine because we don't expect it to be turned
            // on in production.
            assignBoundProperties(undefined, this.globalProperties.values())
        );
    }

    private logTelemetry<T extends keyof IAppInsights>(
        /**
         * What index should we mixin the timeToRedact metrics.
         * Pass in <= -1 to skip mixing in metrics.
         */
        mixinRedactMetricsIndex: number,
        method: T,
        severityLevel: SeverityLevel,
        disablePiiRedactor: undefined | boolean,
        name: string,
        ...args: Parameters<IAppInsights[T]>
    ) {
        if (this.checkSeverityLevel(severityLevel, 'logLevelTelemetry')) {
            if (disablePiiRedactor) {
                // Not possible to type correctly
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.appInsights[method](...(args as [any]));
                return;
            }

            const startTime = window.performance.now();
            const maybeRedacted = this.config.redactPii(args);
            const endTime = window.performance.now();
            const timeToRedact = endTime - startTime;

            if (maybeRedacted.kind === 'ok') {
                if (mixinRedactMetricsIndex >= 0) {
                    // We're directly adding the `timeToRedact` into
                    // the targeted properties for perf tracking.
                    // This should get removed eventually as we wouldn't
                    // need to track this once we are okay with the perf.
                    const redactedProps = maybeRedacted.value[mixinRedactMetricsIndex] as Record<string, unknown>;
                    redactedProps['timeToRedact'] = timeToRedact;
                    redactedProps['wasRedacted'] = !disablePiiRedactor;
                }

                // Not possible to type correctly
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.appInsights[method](...(maybeRedacted.value as [any]));
            } else {
                /**
                 * When redacting hits an error state. Let's still
                 * fire off an appInsights call with the original
                 * event name and method + error reason
                 * so that we can easily identify
                 * what events are failing PII redaction.
                 */
                const errorProperties: Record<string, unknown> = {
                    name: 'unexpected-redact-error',
                    originalMethod: method,
                    originalName: name,
                    reason: maybeRedacted.reason,
                };

                let exception: undefined | Error;
                if (this.config.isMicrosoftInternalAccount) {
                    /**
                     * This is temporary. The exception is being messed up when we go to redact
                     * for the second time so unfortunately we need to expose the original exception
                     * for microsoft internal customers only so we can investigate this further.
                     *
                     * @see https://msazure.visualstudio.com/DefaultCollection/One/_workitems/edit/23633013
                     * @see https://dev.azure.com/msazure/One/_workitems/edit/26820431
                     */
                    exception = maybeRedacted.exception;
                    // Adding a "tag" so we can differentiate these events later for investigation
                    errorProperties.tag = 'bug_23633013';
                } else {
                    // Let's redact the exception error because there's
                    // a chance the error _could_ contain PII in the message
                    // (e.g. "Failed to convert SSN-SSN-SSN to number")
                    const maybeErrorRedacted = this.config.redactPii(maybeRedacted.exception);

                    if (maybeErrorRedacted.kind === 'ok') {
                        exception = maybeErrorRedacted.value;
                    } else {
                        errorProperties.exceptionFailedToRedact = true;
                    }
                }

                this.appInsights.trackException(
                    { severityLevel: translateSeverityLevel('critical'), exception },
                    errorProperties
                );
            }
        }
    }

    private checkSeverityLevel(level: SeverityLevel, type: 'logLevelConsole' | 'logLevelTelemetry') {
        const configured = this.config[type]();
        if (configured === 'none') {
            return false;
        }
        return translateSeverityLevel(configured) <= translateSeverityLevel(level);
    }

    bind(...properties: BoundPropertiesList): IKweTelemetry {
        return new KweTelemetry(this.appInsights, this.config, this.globalProperties, [
            ...this.properties,
            ...properties,
        ]);
    }

    globalBind(
        properties: Record<string, unknown> | (() => undefined | Record<string, unknown>),
        signal?: AbortSignal
    ): Dispose {
        const key = Symbol('global-bind');
        this.globalProperties.set(key, properties);

        const dispose = () => {
            this.globalProperties.delete(key);
            signal?.removeEventListener('abort', dispose);
        };

        signal?.addEventListener('abort', dispose);

        return dispose;
    }

    event(name: string, properties?: CustomProperties): void {
        properties = this.applyBoundProperties(properties);
        const severityLevel = 'information';

        this.logConsole('event', severityLevel, name, properties);
        this.logTelemetry(1, 'trackEvent', severityLevel, properties.disablePiiRedactor, name, { name }, properties);
    }

    pageView({ name, uri, refUri, pageType, isLoggedIn, duration, ...properties }: IPageViewTelemetry = {}): void {
        properties = this.applyBoundProperties(properties);
        const severityLevel = 'information';

        this.logConsole('pageView', severityLevel, {
            name,
            uri,
            refUri,
            pageType,
            isLoggedIn,
            duration,
            ...properties,
        });
        this.logTelemetry(
            1,
            'trackPageView',
            severityLevel,
            properties.disablePiiRedactor,
            name ?? 'trackPageView',
            { name, uri, refUri, pageType, isLoggedIn, properties: { duration } },
            properties
        );
    }

    exception(
        name: string,
        { exception: unknownException, severityLevel = 'error', ...properties }: IExceptionTelemetry = {}
    ): void {
        properties = this.applyBoundProperties(properties);

        const exception = unknownException ? castToError(unknownException) : undefined;

        this.logConsole('exception', severityLevel, name, exception, properties);

        this.logTelemetry(
            1,
            'trackException',
            severityLevel,
            properties.disablePiiRedactor,
            name,
            {
                // It's ok to leave `exception` undefined because App Insights will add
                // a stack trace for us
                exception,
                severityLevel: translateSeverityLevel(severityLevel),
            },
            { ...properties, name }
        );
    }

    trace(name: string, { severityLevel = 'information', ...properties }: ITraceTelemetry = {}): void {
        properties = this.applyBoundProperties({
            // By default, we can disable the redactor for traces since
            // a huge majority of calls don't log any PII. But, because
            // we're spreading below, devs can manually enable it for certain
            // traces that need it
            disablePiiRedactor: true,
            ...properties,
        });

        this.logConsole('trace', severityLevel, name, properties);
        this.logTelemetry(
            1,
            'trackTrace',
            severityLevel,
            properties.disablePiiRedactor,
            name,
            { message: name, severityLevel: translateSeverityLevel(severityLevel) },
            properties
        );
    }

    error(name: string, properties?: ITraceTelemetry): void {
        this.trace(name, { severityLevel: 'error', ...properties });
    }

    info(name: string, properties?: ITraceTelemetry): void {
        this.trace(name, { severityLevel: 'information', ...properties });
    }

    warn(name: string, properties?: ITraceTelemetry): void {
        this.trace(name, { severityLevel: 'warning', ...properties });
    }

    verbose(name: string, properties?: ITraceTelemetry): void {
        this.trace(name, { severityLevel: 'verbose', ...properties });
    }

    metric(name: string, value: number, { sampleCount, min, max, ...properties }: IMetricTelemetry = {}): void {
        properties = this.applyBoundProperties(properties);
        const severityLevel = 'information';

        this.logConsole('metric', severityLevel, name, value, {
            sampleCount,
            min,
            max,
            ...properties,
        });
        this.logTelemetry(
            1,
            'trackMetric',
            severityLevel,
            properties.disablePiiRedactor,
            name,
            { name, average: value, sampleCount, min, max },
            properties
        );
    }

    startTrackEvent(name: string): void {
        const severityLevel = 'information';

        this.logConsole('startTrackEvent', severityLevel, name);
        this.logTelemetry(-1, 'startTrackEvent', severityLevel, undefined, name, name);
    }

    stopTrackEvent(name: string, options: IStopTrackEvent): void {
        const severityLevel = 'information';
        const properties = this.applyBoundProperties(options?.properties);

        this.logConsole('stopTrackEvent', severityLevel, name, {
            measurements: options?.measurements,
            properties,
        });
        this.logTelemetry(
            1,
            'stopTrackEvent',
            severityLevel,
            options?.disablePiiRedactor,
            name,
            name,
            properties,
            options?.measurements
        );
    }
}
