import { KustoQueryResultRowObject } from '@kusto/client';

import { TableResult } from '../agGrid/AgGridWithKustoData';

/**
 * Sorts the table results by the first date-time column (if such exists).
 * Only if the table result doesn't already sorted by the server.
 *
 * @param table The table results to sort.
 * @returns list of sorted rows.
 */
export function sortRowsByDate({
    columns,
    rows,
    visualizationOptions,
}: TableResult): KustoQueryResultRowObject[] | null {
    if (!rows) {
        return null;
    }

    /* Clone rows because:
    - Ag-grid adds properties to the rows and the rows are frozen in our model.
    - TS interface not match `Rows` is readonly while `KustoQueryResultRowObject` is not.
    - `Array.sort()` mutates the array and we don't want to mutate the original rows.
    */
    const clonedRows = [...rows];

    if (!columns) {
        return clonedRows;
    }

    if (visualizationOptions?.IsQuerySorted) {
        // Table result already sorted by the server. The query has `sort by` statement.
        return clonedRows;
    }

    const dateTimeColumn = columns.find((col) => col.columnType === 'datetime');
    if (!dateTimeColumn) {
        // No date-time column
        return clonedRows;
    }

    clonedRows.sort(
        (rowA, rowB) =>
            new Date(rowA[dateTimeColumn.field] as number).getTime() -
            new Date(rowB[dateTimeColumn.field] as number).getTime()
    );

    return clonedRows;
}

/**
 * Sorts the table results by the first date-time column (if such exists).
 * Only if the table result doesn't already sorted by the server.
 *
 * @param resultTable The table results to sort.
 * @returns sorted table results.
 */
export function sortTableByDate(resultTable: TableResult): TableResult {
    const sortedRows = sortRowsByDate(resultTable);

    return {
        ...resultTable,
        rows: sortedRows,
    };
}
