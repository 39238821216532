import React, { CSSProperties, isValidElement } from 'react';

export interface IconProps {
    color?: string;
    height?: number | string;
    width?: number | string;
    style?: CSSProperties;
    className?: string;
    /** Determine whether to use the 'Filled' or 'Regular' version of the icon generated by `bundleIcon()`  */
    filled?: boolean;
}

export type Icon = React.FunctionComponent<IconProps>;

export const EmptyIcon: Icon = () => <></>;

export const renderIcon = (Icon: Icon = EmptyIcon) => <Icon />;

export const renderIconIfDefined = (icon?: Icon | JSX.Element) => {
    if (isValidElement(icon)) {
        // If props.icon is a JSX.Element, use it directly
        return icon;
    }

    return icon ? renderIcon(icon as Icon) : undefined;
};
