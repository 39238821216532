import React from 'react';
import { Subtitle1, Text } from '@fluentui/react-components';

import { LinkUrl } from '../LinkUrl';
import { WelcomeTile, WelcomeTileProps } from './WelcomeTile';

import styles from './WelcomeState.module.scss';

export interface WelcomeStateScope {
    /** Localized strings */
    strings: {
        /** "Not sure yet" text */
        notSureYet: string;
        /** "Learn more" text */
        learnMore: string;
    };
    /** Links */
    links: {
        /** Link to learn more */
        learnMore: string;
    };
}

export interface WelcomeStateProps extends React.HTMLAttributes<HTMLElement> {
    /** Title of the welcome state */
    title: string;
    /** List of tiles (buttons) to display */
    itemsList: WelcomeTileProps[];
    /** Scope parameters like local and links */
    scope: WelcomeStateScope;
}

/** Welcome state component, that display a main title and a list of buttons */
export const WelcomeState: React.FunctionComponent<WelcomeStateProps> = ({
    title,
    itemsList,
    scope,
    ...elementProps
}) => (
    <div className={styles.background} {...elementProps}>
        <div className={styles.container}>
            <Subtitle1>{title}</Subtitle1>
            <div>
                {itemsList.map((item, index) => (
                    <WelcomeTile key={index} {...item} />
                ))}
            </div>
            <Text size={300} className={styles.learnMore}>
                {scope.strings.notSureYet}&nbsp;
                <LinkUrl text={scope.strings.learnMore} url={scope.links.learnMore} />
            </Text>
        </div>
    </div>
);
