import { err, IKweTelemetry, ok, Result } from '@kusto/utils';

import { KWE_ENV } from '../../common/constants';
import { dependencies } from '../../dependencies';
import {
    OutgoingIFrameMessage,
    OutgoingIFrameMessageBase,
    ShareQueryContextMessage,
    ShareTabsSnapshotMessage,
    TabsPayload,
    UIncomingIframeMessage,
} from './IFrameMessage';

/**
 * List of origins that are trusted for privileged iframe communication
 */
const trustedIframeOrigins: readonly string[] = [
    'https://local.microsoftgeneva.com:44301',
    'https://portal.microsoftgeneva.com',
    'https://portal-east.microsoftgeneva.com',
    'https://portal-int.microsoftgeneva.com',
    'https://portal-east-int.microsoftgeneva.com',
    'https://portal-east-test.microsoftgeneva.com',
    'https://portal-eu.microsoftgeneva.com',
    'https://metrics-uxob.dc.ad.msft.net:44301',
    'https://jarvis-west.dc.ad.msft.net',
    'https://jarvis-east.dc.ad.msft.net',
    'https://jarvis-west-int.cloudapp.net',
    'https://jarvis-east-int.cloudapp.net',
    'https://jarvis-int-west.microsoftgeneva.com',
    'https://jarvis-int-east.microsoftgeneva.com',
    'https://jarvis-east-test.microsoftgeneva.com',
    'https://jarvis-eu.microsoftgeneva.com',
    'https://jarvis.core.eaglex.ic.gov',
    'https://jarvis-west.core.eaglex.ic.gov',
    'https://jarvis-east.core.eaglex.ic.gov',
    'https://jarvis-west.cloudapp.eaglex.ic.gov',
    'https://jarvis-east.cloudapp.eaglex.ic.gov',
    'https://jarvis.core.microsoft.scloud',
    'https://jarvis-west.core.microsoft.scloud',
    'https://jarvis-east.core.microsoft.scloud',
    'https://jarvis-west.cloudapp.microsoft.scloud',
    'https://jarvis-east.cloudapp.microsoft.scloud',
    'https://portal-east.microsoftgeneva.microsoft.scloud',
    'https://portal-west.microsoftgeneva.microsoft.scloud',
    'https://portal-east.microsoftgeneva.eaglex.ic.gov',
    'https://portal-west.microsoftgeneva.eaglex.ic.gov',
    // for development
    'http://localhost:4202',
    'http://localhost:3000',
];

const escapeDomainPostfix = (str: string): string => {
    return str.replace(/\./g, '\\.');
};

// keep or in case or empty string
const portalIframeOriginRegEx = KWE_ENV.azurePortalIframeOriginDomainPostfix;

export interface IIframeTrustedDomains {
    // provided origin by query url params
    readonly iframeOrigin?: string;
    // is the provided origin is trusted
    isTrustedIframeOrigin(): boolean;

    isTrustedEventOrigin(event: Partial<MessageEvent>): boolean;
    isTrustedOrigin(origin: string): boolean;
}

export class IFrameTrustedDomains implements IIframeTrustedDomains {
    private readonly regExDomains: RegExp;
    private readonly staticDomains: readonly string[];
    public readonly iframeOrigin: string | undefined;

    constructor(staticDomains: readonly string[], additionalDomainRegExString: string[], iframeOrigin?: string) {
        const joinPattern = additionalDomainRegExString
            .filter((domain) => !!domain)
            .map((domain) =>
                domain.startsWith('http') ? escapeDomainPostfix(domain) : `https://.*${escapeDomainPostfix(domain)}`
            )
            .join('|');
        this.regExDomains = new RegExp(`^(${joinPattern})$`, 'i');
        this.staticDomains = staticDomains;
        this.iframeOrigin = iframeOrigin;
    }

    isTrustedEventOrigin(event: Partial<MessageEvent>): boolean {
        return this.isTrustedOrigin(event.origin ?? '');
    }
    isTrustedOrigin(origin: string): boolean {
        if (!origin) {
            return false;
        }
        return this.staticDomains.indexOf(origin) > -1 || (origin.match(this.regExDomains)?.length ?? 0) > 0;
    }

    isTrustedIframeOrigin() {
        if (!this.iframeOrigin) {
            return false;
        }
        return this.isTrustedOrigin(this.iframeOrigin);
    }
}

export function createIframeTrustedDomain(iframeOrigin?: string): IIframeTrustedDomains {
    return new IFrameTrustedDomains(
        trustedIframeOrigins,
        portalIframeOriginRegEx ? [portalIframeOriginRegEx] : [],
        iframeOrigin
    );
}

/**
 * Pass a message to a trusted iframe origin
 * @param message Message data to pass to parent window
 */
export function postIfTrustedOrigin(message: OutgoingIFrameMessageBase): Result<undefined> {
    const origin = dependencies.queryDeepLinkProperties.origin || '';
    if (!isTrustedOrigin({ origin } as Partial<MessageEvent>)) {
        return err(`postIfTrustedOrigin: '${origin}' is not trusted. type: ${message.type}`);
    }

    if (!window.parent) {
        return err('postIfTrustedOrigin: window.parent is not defined');
    }

    if (window.parent === window) {
        return err('postIfTrustedOrigin: window.parent === window which means this is not running in an iframe');
    }

    window.parent.postMessage(message, origin);
    return ok();
}

export function postShareTabsSnapshot(message: ShareTabsSnapshotMessage): Result<undefined> {
    return postIfTrustedOrigin(message);
}

export function postShareQueryContext(message: ShareQueryContextMessage): Result<undefined> {
    return postIfTrustedOrigin(message);
}

/**
 * Request an authentication token from the parent window
 */
export const postGetToken = (scope?: string): void => {
    window.parent?.postMessage(
        {
            signature: 'queryExplorer',
            type: 'getToken',
            scope,
        },
        '*'
    );
};

/**
 * Verify a passed message came from a whitelisted origin.
 * @param event The message received from the origin window
 * @returns true if the origin is whitelisted
 *
 * @deprecated use kweCore.IframeTrustedDomain.isTrustedOrigin
 */
export const isTrustedOrigin = (event: Partial<MessageEvent>): boolean => {
    return trustedIframeOrigins.indexOf(event.origin ?? '') > -1;
};

/**
 * post a message to the parent window that requests a snapshot of the current tabs.
 * Then listen for an appropriate response from the parent window
 * @param telemetry telemetry client
 * @returns the tab snapshot as a promise
 */
export function getTabSnapshotFromHost(telemetry: IKweTelemetry): Promise<Result<TabsPayload>> {
    const message: OutgoingIFrameMessage = {
        signature: 'queryExplorer',
        type: 'requestTabsSnapshot',
    };

    telemetry.trace('getTabSnapshotFromHost: posting requestTabsSnapshot');
    const result = postIfTrustedOrigin(message);
    if (result.kind === 'err') {
        telemetry.exception(`getTabSnapshotFromHost: requestTabsSnapshot failed: ${result.err}`);
        return Promise.resolve(result);
    }

    return new Promise<Result<TabsPayload>>((resolve) => {
        const handler = (event: MessageEvent<UIncomingIframeMessage>) => {
            const eventData = event.data;
            if (eventData.type === 'loadTabsSnapshot') {
                telemetry.trace('getTabSnapshotFromHost: received loadTabsSnapshot message');
                window.removeEventListener('message', handler);
                resolve(ok(eventData.payload));
            }
        };
        window.addEventListener('message', handler);
    });
}
