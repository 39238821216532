import * as React from 'react';
import { Spinner, SpinnerProps } from '@fluentui/react-components';

import * as styles from './SpinnerText.module.scss';

export interface SpinnerTextProps extends SpinnerProps {
    /**
     * When `true` spinner is shown, and text is hidden
     */
    on?: boolean;
}

/**
 * Used to add a spinner to a fluent ui button, without resizing the button.
 *
 * @example
 * ```
 * <Button><SpinnerText on={loading}>Button Text</SpinnerText></Button>
 * ```
 */
export const SpinnerText: React.FC<SpinnerTextProps> = ({ on, children, ...spinnerProps }) => (
    <>
        <span className={on ? styles.hideText : undefined}>{children}</span>
        {on && <Spinner size="tiny" {...spinnerProps} className={styles.spinner} />}
    </>
);
