import * as React from 'react';
import * as Highcharts from 'highcharts';
import type HighchartsReact from 'highcharts-react-official';
import cloneDeep from 'lodash/cloneDeep';

import { InternalChartProps, YAxisSeriesMap } from '../types';
import type { ExtendedVisualizationOptions } from '../utils/visualization';
import { Highchart, HighchartProps } from './Highchart';
import { HighChartsLayout } from './HighChartsLayout.tsx';
import { ChartSeries } from './InteractiveLegend/types.ts';
import { useShouldRecreateChartObject } from './utils.ts';

import * as styles from './styles.module.scss';

export const CHART_WITH_PANELS_RESULT_CONTAINER_ID = 'chart-with-panels-result-container';
const maxNumberOfPanelsInRow = 3; // For multiple panels in pie chart

interface PanelsData {
    seriesList: ChartSeries[] | undefined;
    charts: Highcharts.Chart[];
}

export interface HighChartPanelsProps
    extends Omit<HighchartProps, 'shouldRecreateChartObject'>,
        Pick<InternalChartProps, 'legendPosition'> {
    yAxesSeriesMap: YAxisSeriesMap;
    horizontalView?: boolean;
    visualizationOptions: ExtendedVisualizationOptions;
}

export const HighChartPanelsWithInteractiveLegend: React.FC<HighChartPanelsProps> = (props) => {
    const chartsRefs = React.useRef<{ [k: string]: HighchartsReact.RefObject }>({});
    const [panelsData, setPanelsData] = React.useState<PanelsData>();

    const { yAxesSeriesMap, horizontalView } = props;
    const title = props.options.title?.text;
    const shouldRecreateChartObject = useShouldRecreateChartObject(props);

    // Build an option object for each chart (panel)
    const chartsData = React.useMemo(() => {
        const ySeriesMap = props.yAxesSeriesMap;
        const yAxes = Object.keys(ySeriesMap);

        return yAxes.map((yAxis, yAxisIndex) => {
            const chartOptions = cloneDeep(props.options);
            chartOptions.series?.forEach((item) => {
                if (!ySeriesMap[yAxis].includes(item.name as string)) {
                    (item as unknown as Highcharts.Series).data = [];
                }
            });
            chartOptions.legend = { enabled: false };
            chartOptions.title = undefined;

            if (props.visualizationOptions.MultipleYAxes && Array.isArray(chartOptions.yAxis)) {
                chartOptions.yAxis.forEach((yAxisOptions, yAxisOptionsIndex) => {
                    yAxisOptions.opposite = false;
                    if (yAxisOptions.title?.text) {
                        yAxisOptions.title.text = '';
                    }
                    /*
                    In a standard chart, users can define multiple Y-axes with specific min and max values, saved under the visual options object.
                    When splitting the chart into panels, each panel renders independently but shares the same visual options.
                    This shared configuration can incorrectly affect panels that do not use the same Y-axes.
                     */
                    if (yAxisOptionsIndex !== yAxisIndex) {
                        yAxisOptions.max = null;
                        yAxisOptions.min = null;
                    }
                });
            }

            return { key: yAxis, data: chartOptions };
        });
    }, [props.options, props.visualizationOptions, props.yAxesSeriesMap]);

    // Init series list
    React.useEffect(() => {
        const chartsRefsKeys = Object.keys(chartsRefs.current ?? {});
        if (chartsRefsKeys.length) {
            chartsRefsKeys.forEach((key) => {
                if (!chartsRefs.current[key]) {
                    delete chartsRefs.current[key];
                }
            });

            const yAxes = Object.keys(yAxesSeriesMap);
            const chartsRefsValues = Object.values(chartsRefs.current);
            const seriesList = chartsRefsValues[0]?.chart.series
                .map((item: Highcharts.Series) => {
                    const yAxis = yAxes.filter((key) => yAxesSeriesMap[key].includes(item.name))[0];
                    const chart = chartsRefs.current[yAxis as string]?.chart;
                    if (chart) {
                        return chart.series.find((series) => series.name === item.name);
                    }
                })
                .filter((item) => item !== undefined);

            const charts = chartsRefsValues.map((data) => data.chart);
            setPanelsData({ seriesList, charts });
        }
    }, [chartsRefs, props.isDarkTheme, yAxesSeriesMap]);

    // Sync crosshair & tooltips between panels
    const syncPanels = React.useCallback(
        (e: React.MouseEvent, chartId: string) => {
            Object.keys(chartsRefs.current).forEach((chartKey) => {
                const chartsRef = chartsRefs.current[chartKey];

                if (!chartsRef.chart) {
                    return;
                }

                // Refresh tooltip (must come before updating the crosshair)
                const normalizedEvent = chartsRef.chart.pointer.normalize(e.nativeEvent);
                const seriesItem = chartsRef.chart.series.find((item) => item.data.length && item.visible);
                const point = seriesItem?.searchPoint(normalizedEvent, true);
                if (point) {
                    if (chartKey !== chartId) {
                        point.series.chart.tooltip.refresh(point); // No need to refresh the hovered chart
                    }
                    point.series.chart.xAxis[0].drawCrosshair(normalizedEvent, point);
                }
            });
        },
        [chartsRefs]
    );

    const clearSyncedView = () => {
        Object.values(chartsRefs.current).forEach((chartsRef) => {
            // Clear highlighted points, tooltip and crosshairs
            chartsRef.chart.series.forEach((series) => series.points.forEach((point) => point.setState?.()));
            chartsRef.chart.tooltip.hide(0);
            chartsRef.chart.xAxis[0].hideCrosshair();
        });
    };

    return (
        <HighChartsLayout
            id={CHART_WITH_PANELS_RESULT_CONTAINER_ID}
            seriesList={panelsData?.seriesList}
            seriesOptions={props.options.series}
            chart={panelsData?.charts}
            strings={props.strings}
            legendPosition={props.legendPosition}
            legendVisibility={props.visualizationOptions.Legend}
            title={title}
            onMouseLeave={clearSyncedView}
            className={styles.panelsChartWithLegend}
            style={{
                flexWrap:
                    horizontalView && Object.keys(chartsRefs.current).length > maxNumberOfPanelsInRow
                        ? 'wrap'
                        : 'nowrap',
            }}
        >
            {chartsData.map((chartData) => (
                <div
                    key={chartData.key}
                    className={horizontalView ? styles.highcharts__chartsBody_hItem : styles.highcharts__chartsBody}
                    onMouseMove={(e) => syncPanels(e, chartData.key)}
                >
                    {horizontalView && <div className={styles.highcharts__horizontalLabel}>{chartData.key}</div>}
                    <Highchart
                        ref={(el: HighchartsReact.RefObject) => (chartsRefs.current[chartData.key] = el)}
                        key={chartData.key}
                        {...{ ...props, options: chartData.data }}
                        shouldRecreateChartObject={shouldRecreateChartObject}
                    />
                </div>
            ))}
        </HighChartsLayout>
    );
};
