import React, { useMemo } from 'react';
import { Body1Strong, Caption1, Caption1Strong } from '@fluentui/react-components';
import { isMac } from '@fluentui/utilities';
import { action as mobxAction } from 'mobx';
import { observer } from 'mobx-react-lite';

import { Icon, TabItem, TabsBar, TabsBarEvents, TabsBarFlags, TabsBarStrings } from '@kusto/ui-components';

import { useQueryCore } from '../core/core';
import { QueryStrings } from '../core/strings';
import { getClusterAndDatabaseFromEntity, isTridentCluster, Tab } from '../stores';

const flags: TabsBarFlags = {
    hideSaveDiscardButtons: true,
};

const TabTooltip: React.FunctionComponent<{ tab: Tab; strings: QueryStrings['tabsHeader']['tabTooltip'] }> = observer(
    function TabTooltip({ tab, strings }) {
        let tooltipBubble: JSX.Element;
        const tabTitle = tab.calcTitle;

        const { entityInContext } = tab;
        if (!entityInContext) {
            tooltipBubble = <Caption1>{tabTitle}</Caption1>;
        } else {
            const { database, cluster } = getClusterAndDatabaseFromEntity(entityInContext);
            const databaseName = database && (database.prettyName ?? database.name);
            const clusterName = cluster.alias ?? decodeURIComponent(cluster.name);

            tooltipBubble = (
                <>
                    {tabTitle != databaseName && tabTitle != clusterName && (
                        <div>
                            <Body1Strong>{tabTitle}</Body1Strong>
                        </div>
                    )}
                    {databaseName && (
                        <div>
                            <Caption1>{strings.database}: </Caption1>
                            <Caption1Strong>{databaseName}</Caption1Strong>
                        </div>
                    )}
                    {clusterName && !isTridentCluster(cluster) && (
                        <div>
                            <Caption1>{strings.cluster}: </Caption1>
                            <Caption1Strong>{clusterName}</Caption1Strong>
                        </div>
                    )}
                </>
            );
        }

        return <span data-testid={`tab-tooltip-${tab.id}`}>{tooltipBubble}</span>;
    }
);

export interface TabsHeaderProps {
    getTabIcon?: (tab: Tab) => Icon | undefined;
}

export const TabsHeader: React.FunctionComponent<TabsHeaderProps> = observer(function TabHeaders(
    props: TabsHeaderProps
) {
    const {
        telemetry,
        store: { tabs: tabsHandler },
        strings: { query: queryStrings },
    } = useQueryCore();

    const selectedTabId = tabsHandler.tabInContext.id;

    const tabsList = tabsHandler.tabs.map<TabItem>((tab) => ({
        icon: props.getTabIcon?.(tab),
        tabId: tab.id,
        label: tab.calcTitle,
        tooltip: <TabTooltip tab={tab} strings={queryStrings.tabsHeader.tabTooltip} />,
    }));

    const events = useMemo<TabsBarEvents>(() => {
        const _withTab = mobxAction((tabId: string, action: (tab: Tab) => void) => {
            const tab = tabsHandler.tabs.find((tab) => tab.id === tabId);
            if (tab) {
                action(tab);
            } else {
                telemetry.exception(`TabsHeader.Error: Tab with id '${tabId}' not found in store`);
            }
        });

        return {
            onAddTab: () => {
                telemetry.event('TabsHeader.onAddTab');
                tabsHandler.addTab({ origin: 'TabsHeader' });
            },
            onTabSelect: (tabId: string) => {
                telemetry.event('TabsHeader.onTabSelect', { tabId });
                _withTab(tabId, (tab) => tabsHandler.setTabInContext(tab));
            },
            onTabRename: (tabId: string, newLabel: string) => {
                telemetry.event('TabsHeader.onTabRename', { tabId, newLabel });
                _withTab(tabId, (tab) => tab.setTitle(newLabel));
            },
            onTabClose: (tabId: string) => {
                telemetry.event('TabsHeader.onTabClose', { tabId });
                _withTab(tabId, (tab) => tabsHandler.removeTab(tab));
            },
            onTabsReorder: (tabIds: string[]) => {
                telemetry.event('TabsHeader.onTabsReorder', { tabIds });
                tabsHandler.setTabs(tabIds);
            },
            onClickReopenClosedTab: () => {
                telemetry.event('TabsHeader.onClickReopenClosedTab');
                tabsHandler.undoTabRemoval();
            },
            onClickCloseAllTabs: () => {
                telemetry.event('TabsHeader.onClickCloseAllTabs');
                tabsHandler.removeAllTabs();
            },
            onClickCloseOtherTabs: () => {
                telemetry.event('TabsHeader.onClickCloseOtherTabs');
                tabsHandler.removeAllTabsExceptCurrent();
            },
        };
    }, [tabsHandler, telemetry]);

    const strings = useMemo<TabsBarStrings>(() => {
        return {
            addTab: queryStrings.tabsHeader.addTab,
            closeTab: queryStrings.tabsHeader.closeTab,
            editTab: queryStrings.tabsHeader.editTab,
            rename: queryStrings.tabsHeader.rename,
            discard: queryStrings.tabsHeader.discard,
            tabMenu: queryStrings.tabsHeader.tabMenu,
            reopenClosedTab: queryStrings.tabsHeader.reopenClosedTab + ` (${isMac() ? 'Command' : 'Ctrl'}+Shift+Alt+T)`,
            closeAllTabs: queryStrings.tabsHeader.closeAllTabs,
            closeOtherTabs: queryStrings.tabsHeader.closeOtherTabs,
            scrollLeft: queryStrings.tabsHeader.scrollLeft,
            scrollRight: queryStrings.tabsHeader.scrollRight,
        };
    }, [queryStrings]);

    return (
        <TabsBar
            tabsList={tabsList}
            selectedTabId={selectedTabId}
            events={events}
            strings={strings}
            flags={flags}
            testId="query-tabs"
        />
    );
});
