import {
    assertNever,
    DataFrame,
    DataFrameSchema,
    Field,
    FieldSchema,
    formatKustoDatetime,
    KustoDataType,
    KustoScalarType,
    Mutable,
    UField,
    UnknownDataFrameValue,
} from '@kusto/utils';

import type { DataTable, DataTableColumn, ValueRow } from './client';
import type {
    KustoColumn,
    KustoDataTypeExtended,
    KustoQueryResultRowObject,
    KustoScalarTypeExtended,
    KustoScalarUnsupportedTypeExtended,
} from './types';

export function kweScalarTypeFromExtended(
    dataType: KustoScalarTypeExtended | KustoScalarUnsupportedTypeExtended
): KustoScalarType {
    switch (dataType) {
        case 'bool':
        case 'string':
        case 'datetime':
        case 'guid':
        case 'int':
        case 'long':
        case 'real':
        case 'decimal':
        case 'timespan':
            return dataType;

        case 'date':
            return 'datetime';
        case 'boolean':
            return 'bool';
        case 'time':
            return 'timespan';
        case 'double':
            return 'real';

        // Extended types
        case 'float':
            return 'real';
        case 'ulong': // Not ideal, but ulong doesn't fit inside any other numeric value
        case 'uint64':
            return 'decimal';
        case 'uint32':
            return 'long';
        case 'int16':
        case 'uint16':
        case 'uint':
        case 'uint8':
        case 'byte':
            return 'int';

        default:
            assertNever(dataType);
    }
}

export function kweDataTypeFromExtended(dataType: KustoDataTypeExtended): KustoDataType {
    if (dataType === 'dynamic') {
        return dataType;
    }
    return kweScalarTypeFromExtended(dataType);
}

// File contains code for converting to and from @kusto/client types

export function kweColumnsFromClientColumns(columns: readonly FieldSchema[]): FieldSchema[] {
    return columns.map((c) => ({
        name: c.name,
        type: kweDataTypeFromExtended(c.type),
    }));
}

export function clientV2ResultToDataFrame(
    table: DataTable,
    applyApplicationInsightsHack: undefined | { onAppInsightsFixed: () => void }
): DataFrame {
    const fields: UField[] = table.Columns.map((c, i) => {
        const type = kweDataTypeFromExtended(c.ColumnType);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new Field<any, any>(
            c.ColumnName,
            type,
            table.Rows.map((r) => dataFrameValueFromClientValue((r as ValueRow)[i], type, applyApplicationInsightsHack))
        );
    });

    return { fields, size: table.Rows.length };
}

/**
 * Application insights proxy incorrectly stringifies dynamic values. If the
 * stringified value is a object or array, deserialize it. We have no way to
 * know if the object/array was meant to be a string, so, string form json gets
 * converted to json here in error.
 *
 * We've also got no way of telling the difference between a json string, and a
 * regular one, so, we'll try to parse it, and suppress errors if it fails
 *
 * Boolean values are the only ones that are serialized correctly
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function tryRepairAppInsightsDynamic(
    value: UnknownDataFrameValue,
    onAppInsightsFixed: () => void
): UnknownDataFrameValue {
    if (value === null || typeof value === 'boolean') {
        return value;
    }
    // As of writing, all dynamic app insights values are passed in string form. If this isn't true, log an exception so we can look into it
    if (typeof value !== 'string') {
        onAppInsightsFixed();
        return value;
    }
    // Only try to `JSON.parse` array's and objects. boolean and string values
    // don't need to be parsed, and numeric values could loose accuracy if we
    // parse them
    if (value[0] === '[' || value[0] === '{') {
        try {
            // This slightly breaks the results if applied to something
            // that is already serialized, for example a string that in
            // json format will result as an object. Also JSON.parse
            // might fail in case it's not an app-insights result, for
            // example: a string with double quotes
            //
            // If app insights is still broken the way it was as of
            // writing, JSON.parse should never fail. try/catch is
            // present so that if it get fixed, we'll still kinda work,
            // but with the issue described above.
            return JSON.parse(value);
        } catch {
            // No way to tell if this occurred because app-insights is fixed, or what we tried to parse wasn't json
            return value;
        }
    }
    return value;
}

/**
 * Converts a value from out kusto client to our kwe table value format
 */
export function dataFrameValueFromClientValue(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    dataType: KustoDataTypeExtended,
    applyApplicationInsightsHack: undefined | { onAppInsightsFixed: () => void }
): UnknownDataFrameValue {
    if (dataType === 'dynamic') {
        if (applyApplicationInsightsHack) {
            return tryRepairAppInsightsDynamic(value, applyApplicationInsightsHack.onAppInsightsFixed);
        }
        return value;
    }
    return kweScalarValueFromClientScalarValue(value, dataType);
}

export function kweScalarValueFromClientScalarValue(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    dataType: KustoScalarTypeExtended
): UnknownDataFrameValue {
    switch (dataType) {
        case 'date':
        case 'datetime':
            // Kusto stores dates as iso 8601 strings (1993-05-19T00:00:00Z)
            //
            // NOTE: Precision is lost because kusto is accurate to the "tick"
            // (10,000,000 ticks per second), and JavaScript Date is only
            // accurate to the millisecond
            const date = Date.parse(value);

            if (isNaN(date)) {
                return null;
            }

            return date;
        case 'long':
        case 'decimal':
            if (value === null) {
                return null;
            }
            // kusto client returns long and decimal values in string form only
            // if they aren't representable as a javascript number. Normalize as
            // strings
            return value.toString();
        default:
            return value;
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function queryAreaValueFromKweValue(type: KustoDataType, value: any): any {
    switch (type) {
        case 'dynamic':
            // Doing this causes dynamic("{ "foo": 2 }") to be
            // handled the same as dynamic({ "foo": 2 })). This is
            // constant with how it's working on the query
            // experience
            if (typeof value === 'object') {
                return JSON.stringify(value);
            }
            return value;
        case 'datetime':
            return formatKustoDatetime(value);
        case 'long':
        case 'decimal':
        case 'bool':
        case 'string':
        case 'int':
        case 'real':
        case 'timespan':
        case 'guid':
            return value;
        default:
            assertNever(type);
    }
}

export function clientColumnsFromKweColumns(columns: DataFrameSchema): readonly KustoColumn[] {
    return columns.map((c) => ({
        columnType: c.type,
        field: c.name,
        headerName: c.name,
    }));
}

export function clientColumnsToKweColumns(columns: readonly DataTableColumn[]): FieldSchema[] {
    return columns.map((c) => ({
        name: c.ColumnName,
        type: kweDataTypeFromExtended(c.ColumnType),
    }));
}

export function queryAreaRowObjectFromDataFrame(dataFrame: DataFrame, rowIndex: number): KustoQueryResultRowObject {
    const row: Mutable<KustoQueryResultRowObject> = {};

    for (const field of dataFrame.fields) {
        const value = field.values[rowIndex];
        row[field.name] = value === null ? value : queryAreaValueFromKweValue(field.type, value);
    }

    return row;
}

export function queryAreaRowObjectsFromDataFrame(dataFrame: DataFrame): KustoQueryResultRowObject[] {
    const rows: KustoQueryResultRowObject[] = [];

    for (let i = 0; i < dataFrame.size; i++) {
        rows.push(queryAreaRowObjectFromDataFrame(dataFrame, i));
    }

    return rows;
}
