import type { IDropdownStyleProps, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import type { IStyleFunction } from '@fluentui/react/lib/Utilities';

export const getStyles: IStyleFunction<IDropdownStyleProps, IDropdownStyles> = ({ theme }) => {
    // Not sure in what scenario theme would be undefined
    const semanticColors = theme?.semanticColors;

    // Taken from https://github.com/microsoft/fluentui/blob/master/packages/fluent-theme/src/fluent/styles/Dropdown.styles.ts#L30
    const itemSelectors = (isSelected: boolean) => {
        return {
            border: 'none',
            selectors: {
                '&:hover': {
                    color: semanticColors?.menuItemTextHovered,
                    backgroundColor: !isSelected
                        ? semanticColors?.menuItemBackgroundHovered
                        : semanticColors?.menuItemBackgroundPressed,
                },
                '&:focus': {
                    backgroundColor: !isSelected ? 'transparent' : semanticColors?.menuItemBackgroundPressed,
                },
                '&:focus::after': {
                    outline: 'none',
                },
                '&:active': {
                    color: semanticColors?.menuItemTextHovered,
                    backgroundColor: !isSelected
                        ? semanticColors?.menuItemBackgroundPressed
                        : semanticColors?.menuItemBackgroundHovered,
                },
                'input:focus + label::before': {
                    // Disable remaining outline appearing on focus
                    outline: 'none !important',
                },
            },
        };
    };

    return {
        dropdownItemsWrapper: {
            maxHeight: '100%',
            display: 'grid',
            // force child to fill all available space, and no more
            gridTemplate: 'minmax(0, 1fr) / minmax(0, 1fr)',
        },
        dropdownItems: {
            display: 'grid',
            // force child to fill all available space, and no more
            gridTemplate: 'minmax(0, 1fr) / minmax(0, 1fr)',
        },
        dropdownItem: itemSelectors(false),
        dropdownItemSelected: itemSelectors(true),
        subComponentStyles: {
            panel: {
                scrollableContent: {
                    display: 'flex',
                    flexDirection: 'column',
                },
                content: {
                    flex: '1 1',
                    minHeight: 0,
                    display: 'grid',
                    // force child to fill all available space, and no more
                    gridTemplate: 'minmax(0, 1fr) / minmax(0, 1fr)',
                },
            },
        },
    };
};
