import * as React from 'react';
import { Input, InputOnChangeData, Toolbar } from '@fluentui/react-components';
import { ArrowRepeatAllRegular, ArrowSquareDownRegular, DismissRegular, SearchRegular } from '@fluentui/react-icons';
import debounce from 'lodash/debounce';

import { VisualizationsStrings } from '../../types.ts';
import { ActionItem } from './ActionItem';

import * as styles from './InteractiveLegend.module.scss';

export interface InteractiveActionsRef {
    clearSearch: () => void;
}

interface InteractiveActionsProps {
    onInvertClick: () => void;
    onArrowClick: (isUp: boolean) => void;
    onSearch: (query: string) => void;
    strings: VisualizationsStrings;
}

export const InteractiveActions = React.forwardRef<InteractiveActionsRef, InteractiveActionsProps>(
    ({ onInvertClick, onArrowClick, onSearch, strings }, ref) => {
        const [showSearch, setShowSearch] = React.useState<boolean | null>();
        const [inputWidth, setInputWidth] = React.useState<number>();
        const toolbarRef = React.useRef<HTMLDivElement>(null);
        const searchInputRef = React.useRef<HTMLInputElement>(null);

        React.useEffect(() => {
            /* When searching, filtering out the longest items can cause the legend area to shrink and the UI to "jump".
               Determining the search area width based on the initial size ensures the width is consistent and optimal */
            if (toolbarRef.current?.clientWidth) {
                setInputWidth(toolbarRef.current.clientWidth);
            }

            // Accessibility: Ensure focus on the search element during transitions.
            if (showSearch === null || showSearch === undefined) {
                return;
            }
            showSearch
                ? searchInputRef.current?.focus()
                : (toolbarRef.current?.children[0] as HTMLElement | null)?.focus();
        }, [showSearch, searchInputRef, toolbarRef]);

        const toggleShowSearch = (show: boolean | null) => {
            setShowSearch(show);
            if (!show) {
                onSearch('');
            }
        };

        React.useImperativeHandle(ref, () => ({
            clearSearch: () => toggleShowSearch(null),
        }));

        const handleInputChanged = debounce((_e: unknown, data: InputOnChangeData) => onSearch(data.value), 400);

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Escape') {
                toggleShowSearch(false);
            }
        };

        return showSearch ? (
            <Input
                ref={searchInputRef}
                size="small"
                className={styles.search}
                style={{ width: inputWidth }}
                onChange={handleInputChanged}
                onKeyDown={handleKeyDown}
                contentBefore={<SearchRegular />}
                contentAfter={<DismissRegular onClick={() => toggleShowSearch(false)} data-testid="search-dismiss" />}
                data-testid="search-input"
            />
        ) : (
            <Toolbar className={styles.actions} ref={toolbarRef}>
                <ActionItem
                    appearance="subtle"
                    icon={<SearchRegular />}
                    onClick={() => toggleShowSearch(true)}
                    content={strings.interactiveLegend.searchLegend}
                    data-testid="search-legend"
                />
                <ActionItem
                    appearance="subtle"
                    icon={<ArrowRepeatAllRegular />}
                    onClick={onInvertClick}
                    content={strings.interactiveLegend.invertSelection}
                    data-testid="invert-selection"
                />
                <ActionItem
                    appearance="subtle"
                    icon={<ArrowSquareDownRegular />}
                    className={styles.arrowUp}
                    onClick={() => onArrowClick(true)}
                    content={strings.interactiveLegend.navigateUp}
                    data-testid="navigate-up"
                />
                <ActionItem
                    appearance="subtle"
                    icon={<ArrowSquareDownRegular />}
                    onClick={() => onArrowClick(false)}
                    content={strings.interactiveLegend.navigateDown}
                    data-testid="navigate-down"
                />
            </Toolbar>
        );
    }
);
