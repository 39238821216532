import React from 'react';
import { Switch } from '@fluentui/react-components';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import type { VisualConfigLayout } from '../../visualConfig';
import type { VisualOptionKey } from '../../visualOptions';
import type { VisualOptionsModel, VisualPluginModel } from '../model/model';

import * as styles from './SegmentToggle.module.scss';

export interface SegmentProps<C extends VisualOptionKey, H> extends VisualConfigLayout.SegmentToggle<C> {
    paneModel: VisualOptionsModel;
    inputModel: VisualPluginModel<C, H>;
}

export const SegmentToggle = observer(function SegmentToggle<C extends VisualOptionKey, H>({
    paneModel,
    inputModel,
    optionKey,
    labels,
    invert,
    ...props
}: SegmentProps<C, H>) {
    const onChange = React.useMemo(
        () =>
            mobx.action(() => {
                paneModel.unknown_options[optionKey] = !inputModel.narrowedOptions[optionKey];
            }),
        [paneModel, optionKey, inputModel]
    );

    let checked = inputModel.narrowedOptions[optionKey];

    if (invert) {
        checked = !checked;
    }

    const id = `visual-options--${optionKey}-toggle`;

    return (
        <Switch
            label={checked ? labels.enabled : labels.disabled}
            labelPosition="before"
            className={styles.farRightToggle}
            id={id}
            checked={checked}
            onChange={onChange}
            {...props}
        />
    );
});
