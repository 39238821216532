import * as React from 'react';
const defaultContextValue = {
    scrollState: 'none',
    setScrollState: ()=>({})
};
export const drawerContext = React.createContext(undefined);
export const DrawerProvider = drawerContext.Provider;
export const useDrawerContext_unstable = ()=>{
    var _React_useContext;
    return (_React_useContext = React.useContext(drawerContext)) !== null && _React_useContext !== void 0 ? _React_useContext : defaultContextValue;
};
export const useDrawerContextValue = ()=>{
    const [scrollState, setScrollState] = React.useState('none');
    return {
        setScrollState,
        scrollState
    };
};
