import { EntityType, TableEntityTypes } from '../../common';

export type ActionKind = 'refresh' | 'edit' | 'delete' | 'favorite' | 'info' | 'keepFocus';

export interface Icon {
    iconName: string;
    iconType: 'fluent' | 'custom';
    isBold?: boolean;
    ariaLabel?: string;
}

/**
 * Actions are displayed when hovering over a row.
 * Typical actions are favorite / unfavorite, info icon, edit, delete ETC.
 * Even though Actions are usually only displayed on hover (in order to reduce mental strain),
 * Occasionally some indication needs to be shown even when not hovering.
 */
export interface Action {
    /**
     * Icon to be displayed when hovering over a row
     */
    mainIcon: Icon;

    /**
     * Icon to be displayed when _not_ hovering on the row.
     * Example scenario is a favorite icon that will be displayed even when not hovering.
     * If not provided, nothing will be displayed when not hovering.
     */
    nonHoverIcon?: Icon;

    /**
     * Text to display when hovering over the icon. This is useful for info icon for example.
     */
    textOnHover?: string;
}

export interface ITreeEntityMapper {
    getNodeName(entityType: TableEntityTypes): string;
    getActions(entityType: EntityType, isFavorite?: boolean, info?: string): Action[];
    getAction(actionKind: ActionKind, isFavorite: boolean, info: string | undefined): Action | undefined;
}

export const keepFocusIconName = 'Flashlight';
