import mapValues from 'lodash/mapValues';

import { DotNetType, KustoType } from './schemaTypes.ts';

export const getDefaultDotNetType = (kustoType: KustoType): DotNetType => kustoTypeToDefaultDotNetType[kustoType];

const kustoTypeToDotNetType: Record<KustoType, DotNetType[]> = {
    bool: ['System.SByte', 'System.Boolean'],
    datetime: ['System.DateTime'],
    decimal: ['System.Data.SqlTypes.SqlDecimal'],
    dynamic: [
        'System.Object',
        'Newtonsoft.Json.Linq.JArray',
        'Newtonsoft.Json.Linq.JObject',
        'Newtonsoft.Json.Linq.JToken',
    ],
    float: ['System.Single'],
    guid: ['System.Guid'],
    int: ['System.Int32'],
    int16: ['System.Int16'],
    long: ['System.Int64'],
    real: ['System.Double'],
    string: ['System.String'],
    timespan: ['System.TimeSpan'],
    uint: ['System.UInt32'],
    uint8: ['System.Byte'],
    uint16: ['System.UInt16'],
    ulong: ['System.UInt64'],
};

const kustoTypeToDefaultDotNetType: Record<KustoType, DotNetType> = mapValues(
    kustoTypeToDotNetType,
    (types) => types[0]
);
