.tabsList {
    display: flex;
    width: 100%;
    height: 32px;
    overflow: hidden;

    .tabItem {
        display: flex;
    }
}
.tabsList:hover {
    overflow-x: auto;
}
.tabsList.draggingOver {
    background: var(--colorNeutralBackground1Hover);
}
