.chartArea {
    padding: var(--spacingHorizontalL) var(--spacingVerticalL);
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;

    .typePickerContainer {
        margin-bottom: var(--spacingVerticalXL);
        display: flex;
        flex: 0 0 auto;
        align-items: baseline;
        gap: var(--spacingHorizontalM);
    }
}

.loader {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
