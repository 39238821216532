import React from 'react';
import * as Highcharts from 'highcharts';
import { observer } from 'mobx-react-lite';

import { KweException, Theme } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';
import { getChartColors, Highchart } from '@kusto/visualizations';

import { standardSizes } from '../../constants';
import { KweRtdVisualContext } from '../../context';
import { createBaseHighchartOptions } from '../createBaseHighchartOptions';
import { useShouldRecreateChartObject } from '../useShouldRecreateHighchart';
import { colorPaletteInput } from './colorPaletteInput';
import { COLOR_STOPS, ColorPaletteKey, colorPaletteThemeMap, ColorPaletteThemes } from './colors';
import { createHeuristics, Heuristics, HeuristicsOk } from './heuristics';
import { heatmapModel, HeatmapModel, HeatmapModelDef } from './model';

function inputLayout(
    ctx: KweRtdVisualContext
): Fwk.VisualSelector<HeatmapModelDef, Fwk.VisualConfigLayout<HeatmapModelDef, Heuristics>> {
    const tileInput = Fwk.tileInput(ctx.flags);

    return () => {
        return {
            visual: {
                segments: [
                    Fwk.tileInputSegment(
                        ctx.strings.rtdProvider.visuals.input.segment$dataTitle,
                        {},
                        Fwk.createTileInput.columnsNullable<'yColumns', Heuristics>(
                            'yColumns',
                            ctx.strings.rtdProvider.visuals.heatmap.yColumnTitle,
                            {
                                selectInferColumns: (props) => {
                                    if (props.get('yColumns') !== null) {
                                        return;
                                    }
                                    const h = props.getHeuristics();

                                    if (!h) {
                                        return;
                                    }

                                    if (h.kind === 'ok') {
                                        return [h.value.yField.name];
                                    }

                                    if (h.err.selections.yField.kind === 'ok') {
                                        return [h.err.selections.yField.value.name];
                                    }
                                },
                                selectErrorMessage: (props) => {
                                    const h = props.getHeuristics();
                                    return h?.err?.errMsgs.yColumn;
                                },
                            }
                        ),
                        Fwk.createTileInput.column<'xColumn', Heuristics>(
                            'xColumn',
                            ctx.strings.rtdProvider.visuals.heatmap.xColumnTitle,
                            {
                                selectInferColumn: (props) => {
                                    if (props.get('xColumn') !== null) {
                                        return;
                                    }

                                    const h = props.getHeuristics();

                                    if (!h) {
                                        return;
                                    }

                                    if (h.kind === 'ok') {
                                        return h.value.xField.name;
                                    }

                                    if (h.err.selections.xField.kind === 'ok') {
                                        return h.err.selections.xField.value.name;
                                    }
                                },
                                selectErrorMessage: (props) => {
                                    const h = props.getHeuristics();
                                    return h?.err?.errMsgs.xColumn;
                                },
                            }
                        ),
                        Fwk.createTileInput.column<'heatMap__dataColumn', Heuristics>(
                            'heatMap__dataColumn',
                            ctx.strings.rtdProvider.visuals.heatmap.valueColumnTitle,
                            {
                                selectInferColumn: (props) => {
                                    if (props.get('heatMap__dataColumn') !== null) {
                                        return;
                                    }
                                    const h = props.getHeuristics();

                                    if (!h) {
                                        return;
                                    }

                                    if (h.kind === 'ok') {
                                        return h.value.dataField.name;
                                    }

                                    if (h.err.selections.dataField.kind === 'ok') {
                                        return h.err.selections.dataField.value.name;
                                    }
                                },
                                selectErrorMessage: (props) => {
                                    const h = props.getHeuristics();
                                    return h?.err?.errMsgs.dataColumn;
                                },
                            }
                        )
                    ),
                    Fwk.tileInputSegment<'xColumnTitle', Heuristics>(
                        ctx.strings.rtdProvider.visuals.input.segment$xAxisTitle,
                        {},
                        tileInput.xColumnTitle(ctx.strings)
                    ),
                    Fwk.tileInputSegment<'yColumnTitle', Heuristics>(
                        ctx.strings.rtdProvider.visuals.input.segment$yAxisTitle,
                        {},
                        tileInput.yColumnTitle(ctx.strings)
                    ),
                    Fwk.tileInputSegment<'heatMap__colorPaletteKey', Heuristics>(
                        ctx.strings.rtdProvider.visuals.heatmap.segmentTitle,
                        {},
                        colorPaletteInput(
                            'heatMap__colorPaletteKey',
                            ctx.strings.rtdProvider.visuals.heatmap.colorPaletteInputTitle,
                            Object.entries(colorPaletteThemeMap).map(([key, palette]) => ({
                                key,
                                text: ctx.strings.rtdProvider.visuals.heatmap.colors[key as ColorPaletteKey],
                                data: {
                                    previewColors: palette.preview,
                                },
                            }))
                        )
                    ),
                ],
            },
        };
    };
}

/**
 * This is hard-coded options. Will be updated in the next PR
 */
function createHighchartOptions(
    ctx: KweRtdVisualContext,
    heuristics: HeuristicsOk,
    visualOptions: Fwk.VisualOptions<keyof HeatmapModel>,
    timeZone: string,
    baseChartColors: string[]
) {
    const { xColumnTitle, yColumnTitle } = visualOptions;
    const colorPalette = ColorPaletteThemes[visualOptions.heatMap__colorPaletteKey];
    const options = createBaseHighchartOptions(ctx, undefined, baseChartColors, timeZone);

    let colorStops: Highcharts.ColorAxisOptions['stops'];
    if (colorPalette) {
        // converting the colors and the stops into Highchart schema
        // Example: [[0, "#DJJ3J4"], [0.1, '#JFK45K'], ...]
        colorStops = COLOR_STOPS.map((colorStop, index) => [colorStop, colorPalette[index]]);
    }

    // first pass of options
    // with core settings
    options.chart = {
        ...options.chart,
        type: 'heatmap',
        marginTop: 40,
        marginBottom: 80,
        plotBorderWidth: 1,
    };
    options.colorAxis = {
        stops: colorStops,
    };
    options.xAxis = {
        title: {
            text: xColumnTitle,
        },
    };
    options.yAxis = {
        title: {
            text: yColumnTitle,
        },
    };

    if (heuristics.xAxisType === 'datetime') {
        options.xAxis.type = heuristics.xAxisType;
        options.xAxis.tickWidth = 1;
        options.yAxis.categories = heuristics.maybeYCategories;
        options.series = [
            {
                type: 'heatmap',
                borderWidth: 0,
                nullColor: '#EFEFEF',
                colsize: heuristics.colSize,
                tooltip: {
                    headerFormat: heuristics.dataField.name + '<br/>',
                    pointFormat: '{point.x:%e %b, %Y} {point.y}: <b>{point.value}</b>',
                },
                data: heuristics.dataItems,
            },
        ];
        options.plotOptions = {
            ...options.plotOptions,
            series: {
                ...options.plotOptions?.series,
                // remove initial animation for Heatmap
                // because on average this should be a
                // very intensive visual to render with
                // the expectation of bigger datasets
                animation: false,
            },
        };
    } else {
        const titles = [xColumnTitle, yColumnTitle].filter(Boolean);
        // e.g. "Sales x Employees" or if one titles is missing then "Sales"
        const seriesName = titles.join(' x ');

        function getPointCategoryName(point: Highcharts.Point, dimension: 'x' | 'y') {
            const axis = point.series[dimension === 'y' ? 'yAxis' : 'xAxis'];
            // point.y will always be defined within Heatmap

            const dimensionIdx = dimension === 'y' ? point.y! : point.x;
            return axis.categories[dimensionIdx];
        }

        options.legend = {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'middle',
        };
        options.xAxis.type = heuristics.xAxisType;
        options.xAxis.categories = heuristics.xCategories;
        options.yAxis.categories = heuristics.yCategories;
        options.tooltip = {
            formatter: function () {
                return (
                    getPointCategoryName(this.point, 'x') +
                    '<br>' +
                    getPointCategoryName(this.point, 'y') +
                    '<br>' +
                    // For some reason `this.point.value` isn't
                    // defined on the type but from Highchart's
                    // Heatmap demo it exists...
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    `<b>${(this.point as any).value}</b>`
                );
            },
        };
        options.series = [
            {
                type: 'heatmap',
                name: seriesName,
                borderWidth: 1,
                borderColor: colorPalette[0], // 0-index is usually the darkest color which should look fine
                dataLabels: {
                    enabled: true,
                },
                data: heuristics.dataItems,
            },
        ];
    }
    return options;
}

interface InnerHeatmapVisualProps
    extends Pick<
        Fwk.IDataVisualProps<HeatmapModelDef, Heuristics>,
        'formatMessage' | 'isDarkTheme' | 'timeZone' | 'visualOptions' | 'locale'
    > {
    ctx: KweRtdVisualContext;
    heuristicsValue: HeuristicsOk;
}

const InnerHeatmapVisual: React.FC<InnerHeatmapVisualProps> = observer(function InnerHeatmapVisual({
    ctx,
    heuristicsValue,
    isDarkTheme,
    timeZone,
    visualOptions,
    formatMessage,
    locale,
}) {
    const theme = isDarkTheme ? Theme.Dark : Theme.Light;
    const options = createHighchartOptions(ctx, heuristicsValue, visualOptions, timeZone, getChartColors(theme).colors);
    const shouldRecreateChartObject = useShouldRecreateChartObject(isDarkTheme, options.yAxis);

    return (
        <Highchart
            disableChartLimits={ctx.chartEvents.disableChartLimits}
            ignoreChartLimits={ctx.chartProps.ignoreChartLimits}
            isDarkTheme={isDarkTheme}
            shouldRecreateChartObject={shouldRecreateChartObject}
            formatMessage={formatMessage}
            strings={ctx.strings.visualizations}
            options={options}
            dataItemsLength={heuristicsValue.dataItems.length}
            locale={locale}
        />
    );
});

export function createComponent(ctx: KweRtdVisualContext): React.FC<Fwk.IDataVisualProps<HeatmapModelDef, Heuristics>> {
    return function HeatmapVisual(props: Fwk.IDataVisualProps<HeatmapModelDef, Heuristics>) {
        const { formatMessage, isDarkTheme, visualOptions, timeZone, heuristics, locale } = props;

        if (!heuristics) {
            ctx.telemetry.exception('Heatmap heuristics should only be null if there is no query result.', {
                isQueryResultEmpty: !props.queryResult,
            });
            return null;
        }

        if (heuristics.kind === 'err') {
            const errorMessages = Object.values(heuristics.err.errMsgs).filter(Boolean);

            if (errorMessages.length === 0) {
                throw new KweException('Heatmap has no error messages found when in kind="err" state');
            }

            return formatMessage({ message: errorMessages[0], level: 'error' });
        }

        return (
            <InnerHeatmapVisual
                ctx={ctx}
                heuristicsValue={heuristics.value}
                formatMessage={formatMessage}
                isDarkTheme={isDarkTheme}
                visualOptions={visualOptions}
                timeZone={timeZone}
                locale={locale}
            />
        );
    };
}

export function heatmapVisualConfig(ctx: KweRtdVisualContext): Fwk.VisualTypeConfig<HeatmapModelDef, Heuristics> {
    return {
        label: ctx.strings.rtdProvider.visuals.heatmap.visualTitle,
        iconName: 'Nav2DMapView',
        config: {
            model: heatmapModel,
            Component: createComponent(ctx),
            inputLayout: inputLayout(ctx),
            heuristics: createHeuristics(ctx),
            ...standardSizes,
        },
    };
}
