import React, { CSSProperties } from 'react';
import { Menu, MenuItem, MenuItemProps, MenuList, MenuPopover, MenuTrigger, tokens } from '@fluentui/react-components';

import { Icon, renderIcon } from '../Icon';
import { MenuItemContent } from './DropDownMenuItemContent';
import { DropDownOption } from './types';

import styles from './DropDownWithActions.module.scss';

// Because the Menu rendered outside normal DOM (overlay), the SCSS Modules styles are not applied. Using inline styles instead.
const menuItemStyle: CSSProperties = {
    maxWidth: 'unset',
    whiteSpace: 'nowrap',
    border: '1px solid', // add border to all, but color only active
    borderColor: 'transparent',
};
const selectedStyle: CSSProperties = {
    color: tokens.colorNeutralForegroundInverted,
    backgroundColor: tokens.colorBrandForegroundInverted,
};
const activeStyle: CSSProperties = {
    borderColor: tokens.colorNeutralForeground2BrandSelected,
};
const menuItemContentStyle: CSSProperties = {
    overflowX: 'hidden',
};

function itemStyle({ selected, active }: { selected?: boolean; active?: boolean } = {}): CSSProperties {
    return {
        ...menuItemStyle,
        ...(selected ? selectedStyle : {}),
        ...(active ? activeStyle : {}),
    };
}

function renderMenuIcon(hasIcons?: boolean, icon?: Icon) {
    // If one of the siblings has an icon (hasIcons is true), use the renderIcon - which adds empty element if no icon
    return hasIcons ? renderIcon(icon) : undefined;
}

type selectHandler<T> = (event: React.MouseEvent<HTMLElement>, option: DropDownOption<T>) => void;

export interface DropDownMenuItemProps<T> extends DropDownOption<T> {
    /** The id of the selected item. If option id matches the selectedId, it will be highlighted.*/
    selectedId?: string;
    /**
     * Whether the menu has icons container. If at least one item in a menu has an icon, the whole menu will have icons container.
     * Used to align the text of the menu items.
     */
    hasIcons?: boolean;
    onSelect: selectHandler<T>;
}

/** MenuItem or SubMenu according to `subMenu` prop */
export const DropDownMenuItem = <T,>({ ...props }: DropDownMenuItemProps<T>) =>
    props.subMenu?.length ? (
        // This syntax `subMenu=...` is a bit redundant - and doesn't adds any value - but makes TS happy
        <DropDownSubMenu {...props} subMenu={props.subMenu} />
    ) : (
        <DropDownMenuLeaf {...props} />
    );

type DropDownMenuLeafProps<T> = Omit<DropDownMenuItemProps<T>, 'subMenu'>;

/** MenuItem, without sub-menu */
const DropDownMenuLeaf = <T,>({ selectedId, hasIcons, onSelect, ...option }: DropDownMenuLeafProps<T>) => (
    <MenuItem
        icon={renderMenuIcon(hasIcons, option.icon)}
        disabled={option.disabled}
        onClick={(e) => {
            option.onClick?.(option);
            onSelect(e, option);
        }}
        style={itemStyle({ selected: option.id === selectedId })}
        // This is a known Bug in Fluent! The code is working correctly. However, there is a TS error here, therefore we use casting.
        // TODO: Remove casting when bug fixed; see: https://github.com/microsoft/fluentui/issues/29596
        content={{ style: menuItemContentStyle } as MenuItemProps['content']}
    >
        <MenuItemContent {...option} />
    </MenuItem>
);

type DropDownSubMenuProps<T> = Omit<DropDownMenuItemProps<T>, 'subMenu'> & {
    subMenu: NonNullable<DropDownOption<T>['subMenu']>;
};

/** SubMenu with sub-items */
const DropDownSubMenu = <T,>({ selectedId, hasIcons, onSelect, ...option }: DropDownSubMenuProps<T>) => {
    let hasSelectedChild = false;
    if (selectedId) {
        // This is an optimization. To target is to highlight all the sub-menus that has selected child.
        // To prevent calculation for each node, we start with calculation only for the first level.
        // Then continue to next level only if the previous level passed. By not passing `selectedId` to next level.
        hasSelectedChild = option.subMenu
            .flatMap((option) => option.subMenu ?? option)
            .some((subOption) => subOption.id === selectedId);
    }

    return (
        <Menu>
            {/* parent node, with '>' */}
            <MenuTrigger disableButtonEnhancement>
                <MenuItem
                    icon={renderMenuIcon(hasIcons, option.icon)}
                    disabled={option.disabled}
                    style={itemStyle({ active: hasSelectedChild })}
                    content={{ style: menuItemContentStyle } as MenuItemProps['content']}
                >
                    <MenuItemContent {...option} />
                </MenuItem>
            </MenuTrigger>
            {/* sub menu */}
            <MenuPopover className={styles.menuPopover}>
                <MenuList hasIcons={hasIcons}>
                    {option.subMenu.map((subOption, i) => (
                        <DropDownMenuItem
                            key={i}
                            {...subOption}
                            selectedId={hasSelectedChild ? selectedId : undefined}
                            hasIcons={option.subMenu.some((subOption) => subOption.icon)}
                            onSelect={onSelect}
                        />
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};
