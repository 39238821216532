import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { caseInsensitiveComparer } from '@kusto/utils';

import { EntityGroup, EntityType, EntityWithFolder, Function, Table } from '../../../common';
import { FolderEntityType, isFunction, isTable, sortedDatabaseFolders } from '../../../common/entityTypeUtils';
import { Cluster, Database, QueryStoreEnv } from '../../../stores';
import { getTelemetryClient } from '../../../utils';
import { ITreeEntityMapper } from '../actions';
import { RowDataType } from '../RowDataType';
import { ClusterRowDataType } from './ClusterRowDataType';
import { ColumnRowDataType } from './ColumnRowDataType';
import { DatabaseFolderRowDataType, entityGroupFolderName, functionsFolderName } from './DatabaseFolderRowDataType';
import { DatabaseLoadingRowDataType } from './DatabaseLoadingRowDataType';
import { databaseDescendantsComparer, DatabaseRowDataType } from './DatabaseRowDataType';
import { EntityGroupMemberRowDataType } from './EntityGroupMemberRowDataType';
import { EntityGroupRowDataType } from './EntityGroupRowDataType';
import { FunctionRowDataType } from './FunctionRowDataType';
import { getFromCacheOrCreateList } from './RowDataTypeCache';
import { TableRowDataType } from './TableRowDataType';

const { trackTrace } = getTelemetryClient({
    component: 'LazyLoadingFlow',
    flow: '',
});

/**
 * Creates a list of RowDataType from a list of clusters.
 * If an entity is passed in the `expanded` parameter, create a RowDataType list for its children,
 * otherwise add a lazy loading method that will be invoked when the entity is expanded.
 * When `showOnlyFavorites` is true add RowDataTypes only for clusters and databases that has `isFavorite` equals to true
 * or clusters and databases that are in the `includes` array.
 * entityActionMapper maps for every entity what actions it supports. For example, cluster type supports actions refresh, delete, rename and favorite.
 */
export const clustersToRowData = (
    env: QueryStoreEnv,
    clusters: Cluster[],
    showOnlyFavorites: boolean,
    includes: (Cluster | Database)[],
    expanded: { [id: string]: boolean },
    treeEntityMapper: ITreeEntityMapper
): RowDataType[] => {
    // clusters and databases into a RowDataType array
    const rowData = clusters
        .filter((cluster) => {
            if (!cluster) {
                trackTrace('cluster is null', SeverityLevel.Error);
                return false;
            }
            return true;
        })
        .map((conn) => clusterToRowData(env, conn, showOnlyFavorites, includes, treeEntityMapper))
        .reduce((prev, curr) => prev.concat(curr), []);

    // trigger childrenLoader for items in the expanded list.
    return expandRowDataTypes(env, rowData, expanded);
};

export const expandRowDataTypes = (
    env: QueryStoreEnv,
    rowData: RowDataType[],
    expanded: { [id: string]: boolean }
): RowDataType[] => {
    // trigger childrenLoader for items in the expanded list.
    let index = 0;
    while (index < rowData.length) {
        const item = rowData[index];
        if (expanded[item.id] && item.childrenLoader) {
            rowData = rowData.concat(item.childrenLoader(env, item).slice(1));
            item.childrenLoader = undefined;
        }
        index++;
    }
    return rowData;
};

const clusterToRowData = (
    env: QueryStoreEnv,
    cluster: Cluster,
    showOnlyFavorites: boolean,
    includes: (Cluster | Database)[],
    treeEntityMapper: ITreeEntityMapper
): RowDataType[] => {
    const isClusterMarkedAsFavorite = cluster.isFavorite || includes.indexOf(cluster) >= 0;
    const showCluster = !showOnlyFavorites || isClusterMarkedAsFavorite;
    if (
        !showCluster &&
        !Array.from(cluster.databases.values()).find((db) => db.isFavorite || includes.indexOf(db) >= 0)
    ) {
        return [];
    }

    const clusterRowData = new ClusterRowDataType(env, cluster, treeEntityMapper) as RowDataType;
    if (cluster.fetchState !== 'done') {
        return [clusterRowData];
    }

    return [clusterRowData].concat(
        databasesToRowData(env, cluster, showOnlyFavorites, isClusterMarkedAsFavorite, includes, treeEntityMapper)
    );
};

const databasesToRowData = (
    env: QueryStoreEnv,
    cluster: Cluster,
    showOnlyFavorites: boolean,
    isClusterMarkedAsFavorite: boolean,
    includes: (Database | Cluster)[],
    treeEntityMapper: ITreeEntityMapper
): RowDataType[] => {
    const databases = Array.from(cluster.databases.values());
    return databases
        .filter((db) => {
            if (!db) {
                trackTrace('database is undefined', SeverityLevel.Error, { cluserName: cluster.name });
                return false;
            }
            return (
                !showOnlyFavorites ||
                db.isFavorite ||
                isClusterMarkedAsFavorite ||
                includes.find((entity) => db === entity)
            );
        })
        .sort((a, b) => caseInsensitiveComparer(a.prettyName || a.name, b.prettyName || b.name))
        .reduce<RowDataType[]>((accumulatedDatabases, db) => {
            return accumulatedDatabases.concat(getDatabaseRowDataWithPlaceholderChild(env, db, treeEntityMapper));
        }, []);
};

const getDatabaseRowDataWithPlaceholderChild = (
    env: QueryStoreEnv,
    db: Database,
    entityActionsMapper: ITreeEntityMapper
) => {
    const isLoading = DatabaseRowDataType.isLoading(db);
    const details = DatabaseRowDataType.details(env, db, isLoading);
    const shouldRefreshCache = (old: RowDataType) => old.isFavorite !== db.isFavorite || details !== old.details;
    const rowData = DatabaseRowDataType.fromCacheOrCreate(
        env,
        db,
        entityActionsMapper,
        isLoading,
        details,
        shouldRefreshCache
    );
    const accumulatedDatabases: RowDataType[] = [];
    accumulatedDatabases.push(rowData);
    if (isLoading || db.fetchState === 'notStarted') {
        accumulatedDatabases.push(DatabaseLoadingRowDataType.fromCacheOrCreate(env, db, entityActionsMapper));
    } else {
        const placeHolder = createPlaceholderChild(env, db, entityActionsMapper);
        if (placeHolder) {
            accumulatedDatabases.push(placeHolder);
            rowData.childrenLoader = databaseChildrenToRowData;
        }
    }
    return accumulatedDatabases;
};

/**
 * Lazy loading for a database. Will be called when a database is expanded.
 */
export const databaseChildrenToRowData = (env: QueryStoreEnv, dbRowData: RowDataType) => {
    const db = dbRowData.baseData as Database;
    const entityActionsMapper = dbRowData.treeEntityMapper;
    // Functions
    const functions = tableOrFunctionListToRowData({
        env,
        database: db,
        entities: Array.from(db.functions.values()),
        folderRootName: functionsFolderName,
        folderRootEntity: EntityType.FunctionsFolder,
        appendToRootFolder: (f) => f.entityType === EntityType.Function,
        entityActionsMapper,
    });
    // Entity Groups
    const entityGroups = entityGroupListToRowData({
        env,
        database: db,
        entities: Array.from(db.entityGroups.values()),
        folderRootName: entityGroupFolderName,
        folderRootEntity: EntityType.EntityGroupFolder,
        entityActionsMapper,
        childrenLoader: entityGroupsMembersToRowData,
    }).map((item) => {
        item.childrenLoader = entityGroupsMembersToRowData;
        return item;
    });
    // Tables
    const tables = tableOrFunctionListToRowData({
        env,
        database: db,
        entities: Array.from(db.regularTables.values()),
        folderRootName: entityActionsMapper.getNodeName(EntityType.Table),
        folderRootEntity: EntityType.TablesFolder,
        appendToRootFolder: () => false,
        entityActionsMapper,
        childrenLoader: columnsToRowData,
    }).map((item) => {
        // When getting the list from cache, childrenLoader might been used and removed by previous expand
        if (isTable(item.entityType)) {
            item.childrenLoader = columnsToRowData;
        }
        return item;
    });
    // External Tables
    const externalTables = tableOrFunctionListToRowData({
        env,
        database: db,
        entities: Array.from(db.externalTables.values()),
        folderRootName: entityActionsMapper.getNodeName(EntityType.ExternalTable),
        folderRootEntity: EntityType.ExternalTableFolder,
        appendToRootFolder: () => true,
        entityActionsMapper,
        childrenLoader: columnsToRowData,
    }).map((item) => {
        // When getting the list from cache, childrenLoader might been used and removed by previous expand
        if (isTable(item.entityType)) {
            item.childrenLoader = columnsToRowData;
        }
        return item;
    });
    // Materialized Views
    const materializedViewTables = tableOrFunctionListToRowData({
        env,
        database: db,
        entities: Array.from(db.materializedViewTables.values()),
        folderRootName: entityActionsMapper.getNodeName(EntityType.MaterializedViewTable),
        folderRootEntity: EntityType.MaterializedViewTableFolder,
        appendToRootFolder: () => true,
        entityActionsMapper,
        childrenLoader: columnsToRowData,
    }).map((item) => {
        // When getting the list from cache, childrenLoader might been used and removed by previous expand
        if (isTable(item.entityType)) {
            item.childrenLoader = columnsToRowData;
        }
        return item;
    });

    let rows = functions.concat(materializedViewTables).concat(tables).concat(externalTables).concat(entityGroups);
    rows = rows.sort(databaseDescendantsComparer);

    return rows;
};

const entityGroupListToRowData = ({
    env,
    database,
    entities,
    folderRootName,
    folderRootEntity,
    entityActionsMapper,
    childrenLoader,
}: {
    env: QueryStoreEnv;
    database: Database;
    // eslint-disable-next-line @typescript-eslint/ban-types
    entities: EntityGroup[];
    folderRootName: string;
    folderRootEntity: FolderEntityType;
    // eslint-disable-next-line @typescript-eslint/ban-types
    entityActionsMapper: ITreeEntityMapper;
    childrenLoader?: (env: QueryStoreEnv, base: RowDataType, first?: boolean) => RowDataType[];
}) =>
    getFromCacheOrCreateList(database, '$' + folderRootName + database.id, () => {
        const folderSet = new Set<string>();
        const entityRowDataList = entities
            .sort((a, b) => caseInsensitiveComparer(a.name, b.name))
            .map((entity) => {
                // non-empty paths begin from a folder named "Tables"
                const path = [folderRootName];

                // Collect all folders we see on the way.
                // when we bump into A\B\C we'll collect A, A\B, A\B\C
                for (let i = 1; i <= path.length; ++i) {
                    const slice = path.slice(0, i);
                    const folder = slice.join('/');
                    folderSet.add(folder);
                }

                const entityGroupRowDataType = EntityGroupRowDataType.fromCacheOrCreate(
                    env,
                    entity as EntityGroup,
                    database,
                    entityActionsMapper
                );
                entityGroupRowDataType.childrenLoader = entityGroupsMembersToRowData;
                return entityGroupRowDataType;
            });

        const folders = Array.from(folderSet)
            .sort(caseInsensitiveComparer)
            .map((folderString: string) => {
                const entityType = folderString === folderRootName ? folderRootEntity : EntityType.Folder;
                return DatabaseFolderRowDataType.fromCacheOrCreate(
                    env,
                    database,
                    folderString,
                    entityType,
                    entityActionsMapper
                );
            });

        const childrenPlaceHolder = childrenLoader
            ? entityRowDataList
                  .map((entityRowData) => childrenLoader(env, entityRowData, true)[0])
                  .filter((column) => column)
            : [];
        return folders.concat(entityRowDataList).concat(childrenPlaceHolder);
    });

const tableOrFunctionListToRowData = ({
    env,
    database,
    entities,
    folderRootName,
    folderRootEntity,
    appendToRootFolder,
    entityActionsMapper,
    childrenLoader,
}: {
    env: QueryStoreEnv;
    database: Database;
    // eslint-disable-next-line @typescript-eslint/ban-types
    entities: (Table | Function)[];
    folderRootName: string;
    folderRootEntity: FolderEntityType;
    // eslint-disable-next-line @typescript-eslint/ban-types
    appendToRootFolder(entity: Table | Function): boolean;
    entityActionsMapper: ITreeEntityMapper;
    childrenLoader?: (env: QueryStoreEnv, base: RowDataType, first?: boolean) => RowDataType[];
}) =>
    getFromCacheOrCreateList(database, '$' + folderRootName + database.id, () => {
        const folderSet = new Set<string>();
        const entityRowDataList = entities
            .sort((a, b) => caseInsensitiveComparer(a.name, b.name))
            .map((entity) => {
                // non-empty paths begin from a folder named "Tables"
                const path = entity.folder
                    ? [folderRootName].concat(entity.folder.split(/[\\/]/g)).filter((f) => f)
                    : appendToRootFolder(entity)
                    ? [folderRootName]
                    : [];

                // Collect all folders we see on the way.
                // when we bump into A\B\C we'll collect A, A\B, A\B\C
                for (let i = 1; i <= path.length; ++i) {
                    const slice = path.slice(0, i);
                    const folder = slice.join('/');
                    folderSet.add(folder);
                }

                if (isTable(entity.entityType)) {
                    const tableRowDataType = TableRowDataType.fromCacheOrCreate(
                        env,
                        entity as Table,
                        database,
                        entityActionsMapper
                    );
                    tableRowDataType.childrenLoader = columnsToRowData;
                    return tableRowDataType;
                } else if (isFunction(entity.entityType)) {
                    return FunctionRowDataType.fromCacheOrCreate(
                        env,
                        // eslint-disable-next-line @typescript-eslint/ban-types
                        entity as Function,
                        database,
                        entityActionsMapper
                    );
                } else {
                    // should never happen. Raise alert.
                    throw Error(`Unexpected entity type: ${entity.entityType}`);
                }
            });

        const folders = Array.from(folderSet)
            .sort(caseInsensitiveComparer)
            .map((folderString: string) => {
                const entityType = folderString === folderRootName ? folderRootEntity : EntityType.Folder;
                return DatabaseFolderRowDataType.fromCacheOrCreate(
                    env,
                    database,
                    folderString,
                    entityType,
                    entityActionsMapper
                );
            });

        const childrenPlaceHolder = childrenLoader
            ? entityRowDataList
                  .map((entityRowData) => childrenLoader(env, entityRowData, true)[0])
                  .filter((column) => column)
            : [];
        return folders.concat(entityRowDataList).concat(childrenPlaceHolder);
    });

/**
 * Lazy loading for entity group members. Will be called when aan entity group is expanded.
 */
const entityGroupsMembersToRowData = (env: QueryStoreEnv, entityGroupRowData: RowDataType, filterFirst?: boolean) => {
    const db = entityGroupRowData.baseData as Database;
    const entityGroup = db.entityGroups.get(entityGroupRowData.id);
    const entityActionsMapper = entityGroupRowData.treeEntityMapper;

    if (!entityGroup) {
        return [];
    }

    return getFromCacheOrCreateList(db, '$entityGroup_members/' + (filterFirst === true) + entityGroup.id, () =>
        Object.values(entityGroup.entities)
            .filter((_entity, index) => filterFirst !== true || index <= 0)
            .map((entity) =>
                EntityGroupMemberRowDataType.fromCacheOrCreate(env, entity, entityGroup, db, entityActionsMapper)
            )
    );
};

/**
 * Lazy loading for columns. Will be called when a table is expanded.
 */
const columnsToRowData = (env: QueryStoreEnv, tableRowData: RowDataType, filterFirst?: boolean) => {
    const db = tableRowData.baseData as Database;
    const table = db.tables.get(tableRowData.id);
    const entityActionsMapper = tableRowData.treeEntityMapper;

    if (!table) {
        return [];
    }

    return getFromCacheOrCreateList(db, '$table_columns/' + (filterFirst === true) + table.id, () =>
        Object.values(table.columns)
            .filter((_col, index) => filterFirst !== true || index <= 0)
            .map((col) => ColumnRowDataType.fromCacheOrCreate(env, col, table, db, entityActionsMapper))
    );
};

/**
 * Create a placeholder child for a database.
 * Why placeholder? This will allow the database to be expandable because a node is expandable only if it has at least one child.
 *
 * The placeholder child can be a root folder (Functions, External Table, Materialized Views and so on).
 * or if there are no entities that belongs to a root folder, it will return the first table with no folders.
 */
const createPlaceholderChild = (
    env: QueryStoreEnv,
    database: Database,
    treeEntityMapper: ITreeEntityMapper
): RowDataType | undefined => {
    // Check if there are Functions, External Tables, Materialize Views or tables with folders.
    let placeHolder: RowDataType | undefined;
    const hasFunctionsWithFolder = !!Array.from(database.functions.values()).find(
        (func) => !!func.folder || func.entityType === EntityType.Function
    );
    const hasEntityGroups = Array.from(database.entityGroups.values()).length > 0;
    const tables = Array.from(database.tables.values());
    const functions = Array.from(database.functions.values());
    let [hasExternalTableWithFolder, hasMaterializeViewWithFolder, hasTableWithFolder] = [false, false, false];
    tables.forEach((table) => {
        if (table.entityType === EntityType.ExternalTable) {
            hasExternalTableWithFolder = true;
        } else if (table.entityType === EntityType.MaterializedViewTable) {
            hasMaterializeViewWithFolder = true;
        } else if (!!table.folder) {
            hasTableWithFolder = true;
        }
    });

    // create a root folder, keeping the order in sortedRootFolders.
    for (const rootFolder of sortedDatabaseFolders) {
        // Functions
        if (rootFolder === EntityType.FunctionsFolder && hasFunctionsWithFolder) {
            return DatabaseFolderRowDataType.fromCacheOrCreate(
                env,
                database,
                functionsFolderName,
                EntityType.FunctionsFolder,
                treeEntityMapper
            );
        }
        // MaterializedViews
        if (rootFolder === EntityType.MaterializedViewTableFolder && hasMaterializeViewWithFolder) {
            return DatabaseFolderRowDataType.fromCacheOrCreate(
                env,
                database,
                treeEntityMapper.getNodeName(EntityType.MaterializedViewTable),
                EntityType.MaterializedViewTableFolder,
                treeEntityMapper
            );
        }
        // ExternalTables
        if (rootFolder === EntityType.ExternalTableFolder && hasExternalTableWithFolder) {
            return DatabaseFolderRowDataType.fromCacheOrCreate(
                env,
                database,
                treeEntityMapper.getNodeName(EntityType.ExternalTable),
                EntityType.ExternalTableFolder,
                treeEntityMapper
            );
        }
        // Tables
        if (rootFolder === EntityType.TablesFolder && hasTableWithFolder) {
            return DatabaseFolderRowDataType.fromCacheOrCreate(
                env,
                database,
                treeEntityMapper.getNodeName(EntityType.Table),
                EntityType.TablesFolder,
                treeEntityMapper
            );
        }
        // EntityGroups
        if (rootFolder === EntityType.EntityGroupFolder && hasEntityGroups) {
            return DatabaseFolderRowDataType.fromCacheOrCreate(
                env,
                database,
                entityGroupFolderName,
                EntityType.EntityGroupFolder,
                treeEntityMapper
            );
        }
    }

    // if there are no functions or folders, show the first table.
    if (tables.length > 0) {
        placeHolder = TableRowDataType.fromCacheOrCreate(env, firstTableOrFunction(tables), database, treeEntityMapper);
    } else if (functions.length > 0) {
        placeHolder = FunctionRowDataType.fromCacheOrCreate(
            env,
            firstTableOrFunction(functions),
            database,
            treeEntityMapper
        );
    }

    return placeHolder;
};

/**
 * A helper method that returns the first table or function in tablesOrFunctions using case-insensitive comparison.
 * (For performance reasons avoid calling sort.)
 */
export const firstTableOrFunction = <T extends EntityWithFolder>(tablesOrFunctions: T[]): T => {
    let first = tablesOrFunctions[0];
    for (let i = 1; i < tablesOrFunctions.length; i++) {
        const tableOrFunction = tablesOrFunctions[i];
        if (tableOrFunction.name.localeCompare(first.name, undefined, { sensitivity: 'base' }) === -1) {
            first = tableOrFunction;
        }
    }
    return first;
};
