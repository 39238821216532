import * as React from 'react';
import { DialogContent, Target } from '@fluentui/react';
import { Text } from '@fluentui/react-components';

import { RTDCallout, useThemeState } from '@kusto/utils';

import { VISUALS__SLOTS_MAX_HEIGHT, VISUALS__SLOTS_MAX_WIDTH } from '../../constants';
import type { KweVisualFwkLocale } from '../../types';
import { Slot } from '../../visualOptions';
import { createSlotLabel, SlotLabel } from './lib';

import * as styles from './SlotCallout.module.scss';

interface ExtendedSlot extends Slot {
    index: number;
    label: SlotLabel;
}

// Slot grid code is somewhat hot. Do this work outside of it so that we don't
// need to create any slot objects and we create less labels
function createCells(strings: KweVisualFwkLocale): ExtendedSlot[] {
    const cells: ExtendedSlot[] = [];
    let index = 0;
    for (let height = 1; height <= VISUALS__SLOTS_MAX_WIDTH; height++) {
        for (let width = 1; width <= VISUALS__SLOTS_MAX_HEIGHT; width++) {
            const slot = { width, height };
            cells.push({ index, ...slot, label: createSlotLabel(slot, strings) });
            index++;
        }
    }
    return cells;
}

interface CellProps {
    selected: Slot;
    slot: ExtendedSlot;
    hovered?: Slot;
    onSave: (slot: Slot) => void;
    setHovered: (slot: ExtendedSlot) => void;
}

const Cell: React.FC<CellProps> = ({ selected, hovered, slot, onSave, setHovered }) => {
    const onClick = React.useCallback(() => onSave({ width: slot.width, height: slot.height }), [slot, onSave]);

    const isSelected = slot.width <= selected.width && slot.height <= selected.height;
    const applyHoverHighlight = !!hovered && slot.width <= hovered.width && slot.height <= hovered.height;
    const onMouseEnter = () => setHovered(slot);

    return (
        <button
            onMouseEnter={onMouseEnter}
            aria-label={slot.label.text}
            className={`${styles.cell} ${isSelected ? styles.selected : ''} ${
                applyHoverHighlight ? styles.hovered : ''
            }`}
            onClick={onClick}
        />
    );
};

interface SlotGridProps {
    t: KweVisualFwkLocale;
    selected: Slot;
    onSave: (slot: Slot) => void;
}

const SlotGrid: React.FC<SlotGridProps> = ({ t, selected, onSave }) => {
    const cells = React.useMemo(() => createCells(t), [t]);
    const [hovered, setHovered] = React.useState<ExtendedSlot | undefined>();

    return (
        <>
            <div onMouseLeave={() => setHovered(undefined)} className={styles.grid}>
                {cells.map((slot) => (
                    <Cell
                        key={slot.index}
                        hovered={hovered}
                        selected={selected}
                        slot={slot}
                        onSave={onSave}
                        setHovered={setHovered}
                    />
                ))}
            </div>
            <Text className={styles.selectedText}>
                {hovered ? hovered.label.node : createSlotLabel(selected, t).node}
            </Text>
        </>
    );
};

export interface SlotCalloutProps {
    t: KweVisualFwkLocale;
    target: Target;
    slot: Slot;
    onClose: () => void;
    onSave: (slot: Slot) => void;
}

export const SlotCallout: React.FC<SlotCalloutProps> = ({ t, target, slot, onClose, onSave }) => {
    const themeState = useThemeState();

    return (
        <RTDCallout target={target} onDismiss={onClose} className={themeState.classNames}>
            <DialogContent title={t.visualFwk.visualConfig.multiStat__slot.calloutTitle} onDismiss={onClose}>
                <SlotGrid t={t} onSave={onSave} selected={slot} />
            </DialogContent>
        </RTDCallout>
    );
};
