import { EntityType } from '../../common';
import { Cluster, Database, QueryStoreEnv } from '../../stores';
import { ITreeEntityMapper } from './actions';
import { TreeViewRow } from './TreeView';

export interface RowDataType extends TreeViewRow {
    info?: string;
    entityType: EntityType;
    isFavorite?: boolean;
    baseData: Cluster | Database;
    treeEntityMapper: ITreeEntityMapper;
    childrenLoader?: (env: QueryStoreEnv, base: RowDataType) => RowDataType[];
    setIsFavorite?: (isFavorite: boolean) => void;
}
