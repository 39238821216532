import React from 'react';
import * as Highcharts from 'highcharts';
import orderBy from 'lodash/orderBy';
import { observer } from 'mobx-react-lite';

import { ArgumentColumnType } from '@kusto/charting';
import * as client from '@kusto/client';
import { err, formatLiterals, ok, Result, Theme } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';
import {
    ChartColors,
    getChartColors,
    getDataFromDataItems,
    getFirstColumnOfTypeVFormat,
    Highchart,
    HighchartProps,
    KustoHeuristicsOk,
} from '@kusto/visualizations';

import { NUMERIC_DATA_TYPE, standardSizes, STRING_DATA_TYPE } from '../../constants';
import { KweRtdVisualContext } from '../../context';
import { createBaseHighchartOptions } from '../createBaseHighchartOptions';
import { crossFilterSegment, drillthroughSegment, useCrossFilterEvents, useDrillthroughEvents } from '../interaction';
import { useShouldRecreateChartObject } from '../useShouldRecreateHighchart';
import { mergeChartEvents } from '../util';
import type { FunnelDataItemWithRow, FunnelHeuristics, FunnelHeuristicsOk } from './types';
import { addPercentageToData, isDataAscending } from './util';

const funnelModel = {
    xColumn: null,
    yColumns: null,
    crossFilter: [],
    crossFilterDisabled: false,
    drillthrough: [],
    drillthroughDisabled: false,
} as const;

type FunnelModelDef = keyof typeof funnelModel;

/**
 * We are creating the data items in the same shape
 * as Visualizations.DataItemWithRow because our
 * current Highchart interactive event handlers depends on it's
 * structure in order to work right when
 * Highchart's click event is triggered.
 * See line 64 in `createBaseHighchartOptions`.
 */
function createFunnelDataItems(
    ctx: KweRtdVisualContext,
    rows: client.KustoQueryResultRowObject[],
    xColumn: string,
    yColumns: readonly string[]
): Result<FunnelDataItemWithRow[]> {
    const res: FunnelDataItemWithRow[] = [];

    for (const row of rows) {
        const rowData = row[xColumn];

        if (rowData === undefined) {
            // In this case, the xColumn has become "unavailable" (e.g. the column
            // is no longer in the query result) so we should
            // not return anything.
            return err(
                formatLiterals(ctx.strings.rtdProvider.visuals.sharedMessages.missingColumn, {
                    columnName: ctx.strings.rtdProvider.visuals.funnel.inputLabels.xColumn,
                })
            );
        }

        const rowName: null | string = rowData === null ? null : rowData.toString();
        const columnName = yColumns[0];
        const value = Number(row[columnName]);

        if (Number.isNaN(value)) {
            return err(ctx.strings.rtdProvider.visuals.funnel.noNumberConvertYColumnError);
        }

        res.push({
            row,
            SeriesName: columnName,
            ArgumentData: rowName,
            ValueData: value,
            ValueName: columnName,
            ArgumentNumeric: value,
            /**
             * This property is only needed for
             * `getDataFromDataItems` but for Funnel,
             * we don't make use of this so setting
             * it as an empty object to make TS happy
             */
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ArgumentDateTime: {} as any,
        });
    }

    return ok(res);
}

interface Selections {
    xColumn: string;
    yColumns: readonly string[];
}

function getSelections(props: Fwk.HeuristicsProps<FunnelModelDef>, columns: readonly client.KustoColumn[]): Selections {
    let yColumns: readonly string[] = [];
    const { xColumn: xColumnProp, yColumns: yColumnsProp } = props.visualOptions;

    // X should be a string if this exists
    const xColumn: undefined | string =
        xColumnProp ?? getFirstColumnOfTypeVFormat(columns, [STRING_DATA_TYPE], [])?.field ?? columns[0].field;

    if (yColumnsProp !== null && yColumnsProp.length !== 0) {
        yColumns = yColumnsProp;
    } else {
        // YColumns be numeric
        const numericColumns: string | undefined = getFirstColumnOfTypeVFormat(
            columns,
            [NUMERIC_DATA_TYPE],
            [xColumn]
        )?.field;
        yColumns = numericColumns ? [numericColumns] : [columns[1].field];
    }

    return {
        xColumn,
        yColumns,
    };
}

function createHeuristics(ctx: KweRtdVisualContext): Fwk.VisualConfigHeuristicsFnc<FunnelModelDef, FunnelHeuristics> {
    return function innerHeuristics(props) {
        if (!props.queryResult) {
            return null;
        }

        const columns = client.clientColumnsFromKweColumns(props.queryResult.dataFrame.fields);

        if (columns.length <= 1) {
            return {
                columns,
                result: err(ctx.strings.rtdProvider.visuals.funnel.numberOfColumnsTooSmall),
            };
        }

        const rows = client.queryAreaRowObjectsFromDataFrame(props.queryResult.dataFrame);
        const selections = getSelections(props, columns);

        let result: Result<FunnelHeuristicsOk>;

        if (selections.yColumns.length >= 2) {
            result = err(ctx.strings.rtdProvider.visuals.funnel.yColumnsMultipleSelectionError);
        } else {
            const dataItemsResult = createFunnelDataItems(ctx, rows, selections.xColumn, selections.yColumns);

            if (dataItemsResult.kind === 'err') {
                result = dataItemsResult;
            } else {
                result = ok({
                    kustoHeuristics: {
                        argumentType: ArgumentColumnType.AllExceptGeospatial,
                        dataItems: dataItemsResult.value,
                        metaData: undefined,
                    },
                    xColumn: selections.xColumn,
                    yColumns: selections.yColumns,
                    seriesColumns: undefined,
                });
            }
        }

        return {
            columns,
            result,
        };
    };
}

function createSeries(
    ctx: KweRtdVisualContext,
    kustoHeuristics: KustoHeuristicsOk<FunnelDataItemWithRow>
): Highcharts.SeriesOptionsType[] {
    const { groupedBySeries, seriesNames } = getDataFromDataItems<FunnelDataItemWithRow>(
        // This is defined in `createHeuristics`

        kustoHeuristics.argumentType!,
        kustoHeuristics.dataItems
    );

    return seriesNames.map((seriesName: string) => {
        const extraConfig: Record<string, unknown> = {};
        let data = groupedBySeries[seriesName];

        const isOriginalDataAscending = isDataAscending(data);

        if (isOriginalDataAscending) {
            extraConfig.reversed = true;
        }

        // Highcharts _always_ expects the input data to
        // be ordered in descending fashion regardless if the
        // visual is reversed or not.
        data = orderBy(data, (item) => item.y, 'desc');
        data = addPercentageToData(ctx, data, isOriginalDataAscending);

        return {
            ...extraConfig,
            type: 'funnel',
            name: seriesName,
            data,
        };
    });
}

function createFunnelHighcartOptions(
    ctx: KweRtdVisualContext,
    kustoHeuristics: KustoHeuristicsOk<FunnelDataItemWithRow>,
    chartColors: ChartColors
): Highcharts.Options {
    return {
        plotOptions: {
            funnel: {
                neckHeight: '30%',
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: <b>{point.y:,.0f} ({point.custom.initialPercentageValue})</b>',
                    style: { textOutline: 'none', color: chartColors.tickStyle.fill },
                },
            },
        },
        series: createSeries(ctx, kustoHeuristics),
        tooltip: {
            enabled: true,
            pointFormat: `
                    <span><b>{series.name}: {point.y:,.0f}</b></span><br/>
                    <span><b>{point.custom.initialPercentage}</b></span><br/>
                    <span><b>{point.custom.previousPercentage}</b></span><br/>
                    <span><b>{point.custom.totalPercentage}</b></span><br/>
                `,
        },
    };
}

interface InnerFunnelVisualProps
    extends Pick<HighchartProps, 'isDarkTheme' | 'formatMessage' | 'onHighchartsPointMenuItems' | 'locale'> {
    ctx: KweRtdVisualContext;
    dataItemsLength: number;
    options: Highcharts.Options;
}

const InnerFunnelVisual: React.FC<InnerFunnelVisualProps> = observer(function InnerFunnelVisual({
    ctx,
    isDarkTheme,
    formatMessage,
    dataItemsLength,
    options,
    onHighchartsPointMenuItems,
    locale,
}) {
    const shouldRecreateChartObject = useShouldRecreateChartObject(Boolean(isDarkTheme), options.yAxis);
    return (
        <Highchart
            disableChartLimits={ctx.chartEvents.disableChartLimits}
            ignoreChartLimits={ctx.chartProps.ignoreChartLimits}
            isDarkTheme={isDarkTheme}
            formatMessage={formatMessage}
            strings={ctx.strings.visualizations}
            dataItemsLength={dataItemsLength}
            options={options}
            onHighchartsPointMenuItems={onHighchartsPointMenuItems}
            shouldRecreateChartObject={shouldRecreateChartObject}
            locale={locale}
        />
    );
});

export function createComponent(
    ctx: KweRtdVisualContext
): React.FC<Fwk.IDataVisualProps<FunnelModelDef, FunnelHeuristics>> {
    return observer(function FunnelVisual(props: Fwk.IDataVisualProps<FunnelModelDef, FunnelHeuristics>) {
        const crossFilterEvents = useCrossFilterEvents(
            ctx,
            props.visualOptions,
            props.heuristics,
            props.dashboard,
            props.queryResult
        );

        const drillthroughEvents = useDrillthroughEvents(ctx, props.visualOptions, props.heuristics, props.dashboard);

        const interactiveEvents = React.useMemo(
            () => mergeChartEvents(crossFilterEvents, drillthroughEvents),
            [crossFilterEvents, drillthroughEvents]
        );

        const chartColors = React.useMemo(
            () => getChartColors(props.isDarkTheme ? Theme.Dark : Theme.Light),
            [props.isDarkTheme]
        );

        const baseOptions = React.useMemo(() => {
            return createBaseHighchartOptions(ctx, interactiveEvents, chartColors.colors, props.timeZone);
        }, [props, interactiveEvents, chartColors]);

        if (!props.heuristics) {
            ctx.telemetry.exception('Funnel heuristics should only be null if there is no query result.', {
                isQueryResultEmpty: !props.queryResult,
            });
            return null;
        }

        const heuristics = props.heuristics.result;

        if (heuristics.kind === 'err') {
            return props.formatMessage({ message: heuristics.err, level: 'error' });
        }

        const { kustoHeuristics } = heuristics.value;
        const funnelHighcartOptions = createFunnelHighcartOptions(ctx, kustoHeuristics, chartColors);
        const options = Highcharts.merge(baseOptions, funnelHighcartOptions);

        return (
            <InnerFunnelVisual
                ctx={ctx}
                isDarkTheme={props.isDarkTheme}
                formatMessage={props.formatMessage}
                dataItemsLength={kustoHeuristics.dataItems.length}
                options={options}
                onHighchartsPointMenuItems={interactiveEvents?.onHighchartsPointMenuItems}
                locale={props.locale}
            />
        );
    });
}

function createInputLayout(
    ctx: KweRtdVisualContext
): Fwk.VisualSelector<FunnelModelDef, Fwk.VisualConfigLayout<FunnelModelDef, FunnelHeuristics>> {
    return {
        visual: {
            segments: [
                Fwk.tileInputSegment(
                    ctx.strings.rtdProvider.visuals.input.segment$dataTitle,
                    {},
                    Fwk.createTileInput.column<'xColumn', FunnelHeuristics>(
                        'xColumn',
                        ctx.strings.rtdProvider.visuals.funnel.inputLabels.xColumn,
                        {
                            selectInferColumn: (props) => {
                                if (props.get('xColumn') !== null) {
                                    return undefined;
                                }
                                return props.getHeuristics()?.result.value?.xColumn;
                            },
                        }
                    ),
                    Fwk.createTileInput.columnsNullable<'yColumns', FunnelHeuristics>(
                        'yColumns',
                        ctx.strings.rtdProvider.visuals.funnel.inputLabels.yColumns,
                        {
                            selectInferColumns: (props) => {
                                if (props.get('yColumns') !== null) {
                                    return undefined;
                                }
                                return props.getHeuristics()?.result.value?.yColumns;
                            },
                        }
                    )
                ),
            ],
        },
        interactions: {
            segments: [crossFilterSegment<FunnelHeuristics>(ctx), drillthroughSegment<FunnelHeuristics>(ctx)],
        },
    };
}

export function funnelVisualConfig(ctx: KweRtdVisualContext): Fwk.VisualTypeConfig<FunnelModelDef, FunnelHeuristics> {
    return {
        label: ctx.strings.rtdProvider.visuals.funnel.label,
        iconName: 'FunnelChart',
        config: {
            model: funnelModel,
            Component: createComponent(ctx),
            inputLayout: createInputLayout(ctx),
            heuristics: createHeuristics(ctx),
            ...standardSizes,
        },
    };
}
