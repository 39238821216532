import React, { useMemo, useState } from 'react';
import { Tab, TabList } from '@fluentui/react-components';

import { useFullRowCount } from '../../../DataExplorationApi';
import { useKql } from '../../hooks/useKql';
import { useQueryResults } from '../../hooks/useResults';
import { useStrings } from '../../hooks/useStrings';
import { KQLViewer } from '../KQLViewer/KQLViewer';
import { ResultsGrid } from './ResultsGrid';

import styles from './Results.module.scss';

type ResultsTab = 'grid' | 'query';

export const Results: React.FunctionComponent = () => {
    const { strings } = useStrings();
    const resultsStr = strings.dataExplorationModal.results;

    const { data: computedResults } = useQueryResults();

    const computedKql = useKql();
    const { data: fullCount, isLoading: isLoadingFullCount } = useFullRowCount(computedKql);

    const [selectedTab, setSelectedTab] = useState<ResultsTab>('grid');

    const countLabel = useMemo(() => {
        if (computedResults === undefined || isLoadingFullCount) {
            return '';
        }
        const rowsShown = computedResults.dataFrame.size;

        return `${resultsStr.count.showing} ${
            rowsShown < fullCount! ? `${rowsShown} ${resultsStr.count.outOf} ` : ''
        }${fullCount} ${resultsStr.count.rows}`;
    }, [
        computedResults,
        isLoadingFullCount,
        resultsStr.count.showing,
        resultsStr.count.outOf,
        resultsStr.count.rows,
        fullCount,
    ]);

    return (
        <div className={styles.results}>
            <div className={styles.tabsBar}>
                <TabList
                    selectedValue={selectedTab}
                    onTabSelect={(e, { value }) => setSelectedTab(value as ResultsTab)}
                    className={styles.tabsList}
                >
                    <Tab value="grid">{resultsStr.tabs.resultsGrid}</Tab>
                    <Tab value="query">{resultsStr.tabs.resultsQuery}</Tab>
                </TabList>
                <div className={styles.resultsCount}>{countLabel}</div>
            </div>
            <div className={styles.resultsContact}>
                {selectedTab === 'grid' && <ResultsGrid />}
                {selectedTab === 'query' && <KQLViewer kqlText={computedKql} />}
            </div>
        </div>
    );
};
