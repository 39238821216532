import * as React from 'react';
/**
 * @internal
 */ export const OverridesContext = React.createContext(undefined);
/**
 * @internal
 */ export const OverridesProvider = OverridesContext.Provider;
export function useOverrides() {
    var _React_useContext;
    return (_React_useContext = React.useContext(OverridesContext)) !== null && _React_useContext !== void 0 ? _React_useContext : {};
}
