.kqlPreview {
    margin-top: var(--spacingVerticalM);

    .kqlPreviewTitle {
        margin-bottom: var(--spacingVerticalXS);
        display: block;
    }

    .kqlPreviewViewer {
        height: 50px;
        padding: var(--spacingVerticalXS) var(--spacingHorizontalXXS);
        border: 1px solid var(--colorNeutralStroke1);
        border-radius: var(--borderRadiusMedium);
    }
}
