import React from 'react';
import { Body1, Caption1 } from '@fluentui/react-components';

import styles from './DistributionList.module.scss';

export interface DistributionListProps {
    totalValues: number;
    items: { title: string; itemCount: number; itemKey?: React.Key }[];
    locale: undefined | string;
}

interface ValueDetailsProps {
    title: string;
    count: string;
    percentage: string;
}

const ValueDetails: React.FC<ValueDetailsProps> = ({ title, count, percentage }) => {
    return (
        <li className={styles.itemContainer}>
            <div className={styles.textLine}>
                <Body1 className={styles.title}>{title}</Body1>
                <Body1>{count}</Body1>
                <Caption1 className={styles.percentage}>({percentage})</Caption1>
            </div>
            <div className={styles.percentageBar}>
                <div className={styles.percentageBarFill} style={{ width: percentage }} />
            </div>
        </li>
    );
};

export const DistributionList: React.FC<DistributionListProps> = ({ items, totalValues, locale }) => {
    const percentFormatter = new Intl.NumberFormat(locale, {
        style: 'percent',
        maximumFractionDigits: 2,
        maximumSignificantDigits: 2,
    });
    const decimalFormatter = new Intl.NumberFormat(locale, { style: 'decimal' });

    return (
        <ol className={styles.distributionList}>
            {items
                .sort((a, b) => b.itemCount - a.itemCount)
                .map((item) => (
                    <ValueDetails
                        key={item.itemKey ?? item.title + item.itemCount}
                        title={item.title}
                        count={decimalFormatter.format(item.itemCount)}
                        percentage={percentFormatter.format(item.itemCount / totalValues)}
                    />
                ))}
        </ol>
    );
};
