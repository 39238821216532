import * as React from 'react';
import { applyTriggerPropsToChildren, getTriggerChild, useEventCallback } from '@fluentui/react-utilities';
import { useDialogContext_unstable, useDialogSurfaceContext_unstable } from '../../contexts';
import { useARIAButtonProps } from '@fluentui/react-aria';
import { useModalAttributes } from '@fluentui/react-tabster';
/**
 * Create the state required to render DialogTrigger.
 * Clones the only child component and adds necessary event handling behaviours to open a popup Dialog
 *
 * @param props - props from this instance of DialogTrigger
 */ export const useDialogTrigger_unstable = (props)=>{
    const isInsideSurfaceDialog = useDialogSurfaceContext_unstable();
    const { children, disableButtonEnhancement = false, action = isInsideSurfaceDialog ? 'close' : 'open' } = props;
    const child = getTriggerChild(children);
    const requestOpenChange = useDialogContext_unstable((ctx)=>ctx.requestOpenChange);
    const { triggerAttributes } = useModalAttributes();
    const handleClick = useEventCallback((event)=>{
        var _child_props_onClick, _child_props;
        child === null || child === void 0 ? void 0 : (_child_props_onClick = (_child_props = child.props).onClick) === null || _child_props_onClick === void 0 ? void 0 : _child_props_onClick.call(_child_props, event);
        if (!event.isDefaultPrevented()) {
            requestOpenChange({
                event,
                type: 'triggerClick',
                open: action === 'open'
            });
        }
    });
    const triggerChildProps = {
        ...child === null || child === void 0 ? void 0 : child.props,
        ref: child === null || child === void 0 ? void 0 : child.ref,
        onClick: handleClick,
        ...triggerAttributes
    };
    const ariaButtonTriggerChildProps = useARIAButtonProps((child === null || child === void 0 ? void 0 : child.type) === 'button' || (child === null || child === void 0 ? void 0 : child.type) === 'a' ? child.type : 'div', {
        ...triggerChildProps,
        type: 'button'
    });
    return {
        children: applyTriggerPropsToChildren(children, disableButtonEnhancement ? triggerChildProps : ariaButtonTriggerChildProps)
    };
};
