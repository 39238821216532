import * as mobx from 'mobx';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { isMacOs } from 'react-device-detect';

import { RenderHelper } from '@kusto/ui-components';
import type { IKweTelemetry } from '@kusto/utils';

import type { QueryCore } from '../../core/core';
import { copyLinkToClipboard, copyQueryToClipboard, copyResultsToClipboard } from '../../utils';
import { KustoEditorHandle } from './handle';
import { getSelectionDetails, unescapeText } from './lib';
import { openTextEditor } from './openTextEditor';

export function escapeText(text: string) {
    const escapedStr = text.replace(/\\/g, '\\\\').replace(/'/g, "\\'");
    const lines = escapedStr
        .replace(/\r\n?/g, '\n') // normalize \r or \r\n to \n newline
        .split('\n');
    const multilineEscapedStr = lines
        .map((line, index) =>
            // ignore empty last line
            index === lines.length - 1 && line === '' ? '' : `'${line}\\n'`
        )
        .join('\n');

    return multilineEscapedStr;
}

function escapeSelectedString(editor: monaco.editor.IStandaloneCodeEditor) {
    const { value, range } = getSelectionDetails(editor);
    if (!value || !range) {
        return;
    }

    editor.executeEdits('escape', [
        {
            range: range,
            text: escapeText(value),
        },
    ]);
}

function unescapeSelectedString(editor: monaco.editor.IStandaloneCodeEditor) {
    const { value, range } = getSelectionDetails(editor);
    if (!value || !range) {
        return;
    }
    editor.executeEdits('escape', [
        {
            range: range,
            text: unescapeText(value),
        },
    ]);
}

function addPipeLine(editor: monaco.editor.IStandaloneCodeEditor) {
    editor.trigger('keyboard', 'type', {
        text: `
|`,
    });
}

export type ActionDescriptorType =
    | 'LinkToClipboard'
    | 'TextAndLinkToClipboard'
    | 'TextAndLinkAndResultToClipboard'
    | 'DuplicateQuery'
    | 'NewTab'
    | 'CloseTab'
    | 'SwitchRight'
    | 'SwitchLeft'
    | 'ExportToCsv'
    | 'EscapeString'
    | 'UnescapeString'
    | 'OpenTextEditor'
    | 'AddSelectionAsFilter';

export type CustomActionDescriptorType = Partial<Record<ActionDescriptorType, monaco.editor.IActionDescriptor>>;

export function addEditorActions(
    core: QueryCore,
    editor: monaco.editor.IStandaloneCodeEditor,
    handle: KustoEditorHandle,
    abortSignal: AbortSignal,
    telemetry: IKweTelemetry,
    render: RenderHelper,
    customActions?: CustomActionDescriptorType
) {
    const editorActions: Record<ActionDescriptorType, monaco.editor.IActionDescriptor> = {
        DuplicateQuery: {
            label: core.strings.query.duplicateQuery,
            keybindings: [
                (isMacOs ? monaco.KeyMod.WinCtrl : monaco.KeyMod.CtrlCmd) | monaco.KeyMod.Alt | monaco.KeyCode.KeyD,
            ],
            id: 'editor.action.kusto.duplicateQuery',
            run: () => handle.duplicateQuery(),
        },
        NewTab: {
            label: core.strings.query.newTab,
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyJ],
            id: 'editor.action.kusto.newTab',
            run: () => {
                core.store.tabs.addTab({ origin: 'editor.action.kusto.newTab' });
            },
        },
        CloseTab: {
            label: core.strings.query.closeTab,
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyK],
            id: 'editor.action.kusto.closeTab',
            run: mobx.action(() => {
                core.store.tabs.removeTab(core.store.tabs.tabInContext);
            }),
        },
        SwitchRight: {
            label: core.strings.query.switchTabRight,
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyMod.Alt | monaco.KeyCode.BracketRight],
            id: 'editor.action.kusto.switchRight',
            run: () => {
                core.store.tabs.switchTab('right');
            },
        },
        SwitchLeft: {
            label: core.strings.query.switchTabLeft,
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyMod.Alt | monaco.KeyCode.BracketLeft],
            id: 'editor.action.kusto.switchLeft',
            run: () => {
                core.store.tabs.switchTab('left');
            },
        },
        ExportToCsv: {
            label: core.strings.query.exportResultsCSV,
            id: 'editor.action.kusto.exportToCsv',
            run: mobx.action(() => {
                core.store.tabs.tabInContext.requestCsvExport(true);
            }),
        },
        LinkToClipboard: {
            label: core.strings.query.copyLinkClipboard,
            keybindings: [monaco.KeyMod.Shift | monaco.KeyMod.Alt | monaco.KeyCode.KeyL],
            id: 'editor.action.kusto.linkToClipboard',
            run: mobx.action(() => {
                copyLinkToClipboard(core, core.store.tabs.tabInContext);
            }),
        },
        TextAndLinkToClipboard: {
            label: core.strings.query.copyQueryLinkClipboard,
            keybindings: [monaco.KeyMod.Shift | monaco.KeyMod.Alt | monaco.KeyCode.KeyC],
            id: 'editor.action.kusto.textAndLinkToClipboard',
            run: mobx.action(() => {
                copyQueryToClipboard(core, core.store.tabs.tabInContext.queryContext);
            }),
        },
        TextAndLinkAndResultToClipboard: {
            label: core.strings.query.linkQueryResultsToClipboard,
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyC],
            id: 'editor.action.kusto.textAndLinkAndResultToClipboard',
            run: mobx.action(() => {
                copyResultsToClipboard(core);
            }),
        },
        EscapeString: {
            label: core.strings.query.editor$escapeString,
            keybindings: [
                monaco.KeyMod.chord(
                    monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyK,
                    monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS
                ),
            ],
            id: 'editor.action.kusto.escapeString',
            run: escapeSelectedString,
        },
        UnescapeString: {
            label: core.strings.query.editor$unescapeString,
            keybindings: [
                monaco.KeyMod.chord(
                    monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyK,
                    monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyM
                ),
            ],
            id: 'editor.action.kusto.unescapeString',
            run: unescapeSelectedString,
        },
        OpenTextEditor: {
            label: core.strings.query.editor$textEditor,
            keybindings: [monaco.KeyMod.Alt | monaco.KeyCode.F2],
            id: 'editor.action.kusto.openTextEditor',
            run: () => openTextEditor(render, editor, abortSignal),
        },
        AddSelectionAsFilter: {
            label: core.strings.query.grid$addAsFilters,
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.Space],
            id: 'editor.action.kusto.AddSelectionAsFilter',
            run: mobx.action(() => {
                core.store.tabs.tabInContext.requestAddValueAsFilters(true);
            }),
        },
    };

    const actions = Object.values({ ...editorActions, ...customActions });
    for (const action of actions) {
        editor.addAction(action);
    }

    const actionId = 'editor.action.kusto.recallCommandExecutionResult';

    // Why is this conditional??
    if (!editor.getAction(actionId)) {
        editor.addAction({
            id: actionId,
            label: core.strings.query.recallExecutionResult,
            keybindings: [monaco.KeyCode.F8],
            run: mobx.action(() => {
                core.store.tabs.tabInContext.recall();
            }),
            contextMenuGroupId: 'navigation',
        });
    }

    const commands: Parameters<typeof editor.addCommand>[] = [
        [
            monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
            () => {
                addPipeLine(editor);
            },
        ],
        [
            monaco.KeyMod.Shift | monaco.KeyCode.Enter,
            mobx.action(() => {
                core.store.tabs.tabInContext.run('Yes');
            }),
        ],
        [
            monaco.KeyMod.Alt | monaco.KeyMod.Shift | monaco.KeyCode.Enter,
            mobx.action(() => {
                core.store.tabs.tabInContext.run('Yes', 'Preview');
            }),
            '',
        ],
        [
            monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyF,
            mobx.action(() => {
                telemetry.event('onSearch', { trigger: 'shortcut', flow: 'search' });
                core.store.tabs.tabInContext.enableSearch(true);
            }),
            '',
        ],
        [
            monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyMod.Alt | monaco.KeyCode.KeyT,
            () => {
                telemetry.event('onUndoTabRemoval', { trigger: 'shortcut', shortcut: 'command+ctrl+shift+t' });
                core.store.tabs.undoTabRemoval();
            },
            '',
        ],
    ];

    for (const cmd of commands) {
        editor.addCommand(...cmd);
    }
}
