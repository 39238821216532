import React from 'react';
import { Button } from '@fluentui/react-components';
import { ChevronLeftRegular, ChevronRightRegular } from '@fluentui/react-icons';

import { useTabsBarContext } from '../TabsContext/TabsBar.context';

import styles from './ScrollButton.module.scss';

export interface ScrollButtonProps {
    side: 'left' | 'right';
    onClick: () => void;
}

export const ScrollButton: React.FunctionComponent<ScrollButtonProps> = ({ side, onClick }) => {
    const context = useTabsBarContext();
    if (context.flags?.hideScrollTabsButtons) {
        return null;
    }

    const { icon, label } =
        side === 'left'
            ? { icon: <ChevronLeftRegular />, label: context.strings.scrollLeft }
            : { icon: <ChevronRightRegular />, label: context.strings.scrollRight };

    return (
        <Button
            icon={icon}
            aria-label={label}
            className={styles.scrollButton}
            onClick={onClick}
            appearance="transparent"
        />
    );
};
