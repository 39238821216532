import { Map, data as mapData } from 'azure-maps-control';

import * as kusto from '@kusto/client';
import { err, formatLiterals, ok, Result } from '@kusto/utils';

import { Columns, DataItemWithRow, Rows } from '../types';
import { getFirstColumnOfTypeVFormat } from '../utils/heuristics';
import { ExtendedVisualizationOptions } from '../utils/visualization';

/**
 * Some of this code was adapted from azure maps doc site.
 * https://github.com/Azure-Samples/AzureMapsCodeSamples/blob/master/AzureMapsCodeSamples/Custom%20Modules/Bring%20Data%20Into%20View%20Control/BringDataIntoViewControl.ts
 * @param map the Map object
 */
export const bringDataIntoView = (featureCollection: mapData.FeatureCollection, map: Map) => {
    const bounds = mapData.BoundingBox.fromData(featureCollection);

    const w = mapData.BoundingBox.getWidth(bounds);
    const h = mapData.BoundingBox.getHeight(bounds);
    //If the bounding box is really small, likely a single point, use center/zoom.
    if (w < 0.000001 || h < 0.000001) {
        map.setCamera({
            center: mapData.BoundingBox.getCenter(bounds),
            zoom: 6,
        });
    } else {
        map.setCamera({
            bounds: bounds,
            padding: 80,
        });
    }
};

// TODO: datetime and timespan are also numeric types but this can't be changed
// because it would change visualization behavior
const SUPPORTED_NUMERIC_DATA_TYPE: kusto.NumericColumnType[] = ['int', 'real', 'long', 'decimal'];
const LABEL_DATA_TYPE: kusto.ColumnType[] = [
    ...SUPPORTED_NUMERIC_DATA_TYPE,
    'bool',
    'datetime',
    'dynamic',
    'guid',
    'string',
    'timespan',
];

export function extractCoordinatesFromGeoPoint(point: string) {
    // Geo point should be json with field of type which needs to equal to 'Point', and a field of coordinates which is an array
    let valueAsJson;
    try {
        valueAsJson = JSON.parse(point);
    } catch (ex) {}
    if (
        valueAsJson &&
        valueAsJson.type === 'Point' &&
        valueAsJson.coordinates !== undefined &&
        valueAsJson.coordinates.length === 2
    ) {
        const longitudeAsNumber = Number(valueAsJson.coordinates[0]);
        const latitudeAsNumber = Number(valueAsJson.coordinates[1]);
        if (isNaN(longitudeAsNumber) || isNaN(latitudeAsNumber)) {
            return null;
        }
        return {
            longitude: longitudeAsNumber,
            latitude: latitudeAsNumber,
        };
    }
    return null;
}

export function getFirstGeoPointColumn(
    allColumns: Columns,
    excludedColumnNames: string[],
    firstElement?: kusto.KustoQueryResultRowObject
) {
    if (!allColumns) {
        return null;
    }

    const excluded = new Set(excludedColumnNames);
    const relevantColumns = allColumns.filter((col) => {
        if (!col.columnType || !firstElement) {
            return false;
        }
        if (excluded.has(col.field)) {
            return false;
        }

        const dataType = col.columnType as kusto.ColumnType;
        if (dataType !== 'dynamic') {
            return false;
        }
        const firstElementValue = firstElement[col.field];
        if (typeof firstElementValue !== 'string') {
            return false;
        }
        return extractCoordinatesFromGeoPoint(firstElementValue) !== null;
    });
    if (relevantColumns.length > 0) {
        return relevantColumns[0];
    }
    return null;
}

export function inferMapColumns(
    visualizationOptions: Pick<
        ExtendedVisualizationOptions,
        'Longitude' | 'Latitude' | 'GeoPointColumn' | 'LabelColumn' | 'SizeColumn' | 'GeoType'
    >,
    columns: Columns,
    firstElement?: kusto.KustoQueryResultRowObject
): Pick<ExtendedVisualizationOptions, 'Longitude' | 'Latitude' | 'GeoPointColumn' | 'LabelColumn' | 'SizeColumn'> {
    let takenColumns: string[] = [];
    const geoType = visualizationOptions.GeoType;
    let Longitude = visualizationOptions.Longitude;
    let Latitude = visualizationOptions.Latitude;
    let GeoPointColumn = visualizationOptions.GeoPointColumn;
    let LabelColumn = visualizationOptions.LabelColumn;
    let SizeColumn = visualizationOptions.SizeColumn;

    if (geoType === 'infer') {
        const longitudeColumnName = getFirstColumnOfTypeVFormat(
            columns,
            [SUPPORTED_NUMERIC_DATA_TYPE],
            takenColumns
        )?.field;
        if (longitudeColumnName) {
            takenColumns.push(longitudeColumnName);
        }

        const latitudeColumnName = getFirstColumnOfTypeVFormat(
            columns,
            [SUPPORTED_NUMERIC_DATA_TYPE],
            takenColumns
        )?.field;
        if (latitudeColumnName) {
            takenColumns.push(latitudeColumnName);
        }

        Longitude = longitudeColumnName ?? null;
        Latitude = latitudeColumnName ?? null;

        if (Longitude === null || Latitude === null) {
            // If any of Lat or Long are null, we reset what we just tried to infer
            takenColumns = [];
            Longitude = null;
            Latitude = null;
            const geoPointColumnName = getFirstGeoPointColumn(columns, takenColumns, firstElement)?.field;
            if (geoPointColumnName) {
                takenColumns.push(geoPointColumnName);
            }
            GeoPointColumn = geoPointColumnName ?? null;
        }
    } else if (geoType === 'geoPoint') {
        if (GeoPointColumn === null) {
            const columnName = getFirstGeoPointColumn(columns, takenColumns, firstElement)?.field;
            if (columnName) {
                GeoPointColumn = columnName;
                takenColumns.push(columnName);
            }
        }
    } else {
        // 'numeric'. meaning we need to get Latitude and Longitude
        if (Longitude) {
            takenColumns.push(Longitude);
        }
        if (Latitude) {
            takenColumns.push(Latitude);
        }

        if (Longitude === null) {
            const columnName = getFirstColumnOfTypeVFormat(columns, [SUPPORTED_NUMERIC_DATA_TYPE], takenColumns)?.field;
            if (columnName) {
                Longitude = columnName;
                takenColumns.push(columnName);
            }
        }

        if (Latitude === null) {
            const columnName = getFirstColumnOfTypeVFormat(columns, [SUPPORTED_NUMERIC_DATA_TYPE], takenColumns)?.field;
            if (columnName) {
                Latitude = columnName;
                takenColumns.push(columnName);
            }
        }
    }

    if (SizeColumn === null) {
        const columnName = getFirstColumnOfTypeVFormat(columns, [SUPPORTED_NUMERIC_DATA_TYPE], takenColumns)?.field;
        if (columnName) {
            SizeColumn = columnName;
            takenColumns.push(columnName);
        }
    }

    if (LabelColumn === null) {
        const columnName = getFirstColumnOfTypeVFormat(columns, [LABEL_DATA_TYPE], takenColumns)?.field;
        if (columnName) {
            LabelColumn = columnName;
            takenColumns.push(columnName);
        } else if (SizeColumn !== null) {
            // It makes sense to have the Label column the same as the size column if couldn't any available column
            LabelColumn = SizeColumn;
        }
    }

    if (GeoPointColumn === null && (Latitude === null || Longitude === null)) {
        // If we couldn't infer geo location or size, we reset to the default values
        Longitude = visualizationOptions.Longitude;
        Latitude = visualizationOptions.Latitude;
        GeoPointColumn = visualizationOptions.GeoPointColumn;
        LabelColumn = visualizationOptions.LabelColumn;
        SizeColumn = visualizationOptions.SizeColumn;
    }

    return { Longitude, Latitude, GeoPointColumn, LabelColumn, SizeColumn };
}

// position is taken from the point inside the feature,
// so it's not part of feature properties
export interface FeatureProperties {
    value?: number | string | null;
    argument?: string;
    size?: number;
    /** Color hex code used for the Circle Icon in the popup */
    color?: string;
}

export type MapFeature = mapData.Feature<mapData.Point, FeatureProperties>;

export function getCoordinates(
    visualizationOptions: Pick<ExtendedVisualizationOptions, 'Longitude' | 'Latitude' | 'GeoPointColumn' | 'GeoType'>,
    row?: kusto.KustoQueryResultRowObject
) {
    const { Longitude = null, Latitude = null, GeoPointColumn = null, GeoType } = visualizationOptions;
    let coordinates: { latitude: number; longitude: number } | null = null;
    if (GeoType === 'geoPoint' || (GeoType === 'infer' && GeoPointColumn !== null)) {
        if (GeoPointColumn === null) {
            return null;
        }
        const point = row?.[GeoPointColumn];
        if (typeof point !== 'string') {
            return null;
        }
        const coordinatesFromPoint = extractCoordinatesFromGeoPoint(point);
        if (coordinatesFromPoint === null) {
            return null;
        }
        coordinates = {
            latitude: coordinatesFromPoint.latitude,
            longitude: coordinatesFromPoint.longitude,
        };
    } else if (GeoType === 'numeric' || GeoType === 'infer') {
        if (Latitude === null || Longitude === null) {
            return null;
        }
        const latitudeValue = row?.[Latitude];
        const longitudeValue = row?.[Longitude];
        const latitudeValueAsNumber = Number(latitudeValue);
        const longitudeValueAsNumber = Number(longitudeValue);
        if (isNaN(latitudeValueAsNumber) || isNaN(longitudeValueAsNumber)) {
            // Make sure we can use Lat and Long as numbers
            return null;
        }
        coordinates = {
            latitude: latitudeValueAsNumber,
            longitude: longitudeValueAsNumber,
        };
    }
    return coordinates;
}

export function getBubbleMapFeatureCollection(
    rows: Rows,
    visualizationOptions: Pick<
        ExtendedVisualizationOptions,
        'Longitude' | 'Latitude' | 'GeoPointColumn' | 'GeoType' | 'LabelColumn' | 'SizeColumn' | 'DisableSize'
    >,
    sizeColumnTypeError: string,
    colors: string[]
): Result<mapData.FeatureCollection> {
    const colorBySeriesName: Record<string, string> = {};
    const labelColumn = visualizationOptions.LabelColumn ?? null;
    const sizeColumn = visualizationOptions.SizeColumn ?? null;
    const disableSize = Boolean(visualizationOptions.DisableSize);

    if (labelColumn) {
        let colorIndex = 0;

        for (const row of rows) {
            const value = row[labelColumn];

            // when the value is a string
            // we can expect it be the series name
            if (typeof value === 'string' && !colorBySeriesName[value]) {
                colorBySeriesName[value] = colors[colorIndex % colors.length];
                colorIndex++;
            }
        }
    }

    const featureArray: MapFeature[] = [];
    for (const row of rows) {
        const coordinates = getCoordinates(visualizationOptions, row);
        if (coordinates === null) {
            // we purposefully skip empty coordinates so that
            // this UX is the same as the desktop app
            continue;
        }

        const { longitude, latitude } = coordinates;
        const value = labelColumn !== null ? row[labelColumn] : '';
        const argument = labelColumn ?? '';
        let sizeValue;

        if (!disableSize) {
            sizeValue = sizeColumn !== null ? row[sizeColumn] : 0;

            sizeValue = Number(sizeValue);
            // Verify we can convert Size to number
            if (isNaN(sizeValue)) {
                return err(formatLiterals(sizeColumnTypeError, { columnName: 'Size', columnType: 'numeric' }));
            }
        }

        const point = new mapData.Point([longitude, latitude]);
        const color = typeof value !== 'string' ? colors[0] : colorBySeriesName[value] ?? colors[0];

        featureArray.push(
            new mapData.Feature<mapData.Point, FeatureProperties>(point, {
                value: value as number | string | null,
                argument,
                size: sizeValue,
                color,
            })
        );
    }

    const featureCollection = new mapData.FeatureCollection(featureArray);

    return ok(featureCollection);
}

export function getDefaultFeatureCollection(
    dataItems: readonly DataItemWithRow[],
    visualizationOptions: Pick<ExtendedVisualizationOptions, 'Visualization'>,
    colors: string[]
): mapData.FeatureCollection {
    const colorBySeriesName: Record<string, string> = {};
    let colorIndex = 0;

    for (const dataItem of dataItems) {
        const argument =
            visualizationOptions.Visualization === 'piechart' ? dataItem.ArgumentData! : dataItem.SeriesName!;

        if (!colorBySeriesName[argument]) {
            colorBySeriesName[argument] = colors[colorIndex % colors.length];
            colorIndex++;
        }
    }

    const featureArray: MapFeature[] = [];
    for (const dataItem of dataItems) {
        const coordinates = {
            latitude: dataItem.GeoCoordinates?.Latitude,
            longitude: dataItem.GeoCoordinates?.Longitude,
        };

        if (coordinates.latitude === undefined || coordinates.longitude === undefined) {
            // we purposefully skip empty coordinates so that
            // this UX is the same as the desktop app
            continue;
        }

        const value = visualizationOptions.Visualization === 'piechart' ? dataItem.ValueData : undefined;
        const argument =
            visualizationOptions.Visualization === 'piechart' ? dataItem.ArgumentData! : dataItem.SeriesName!;
        const { longitude, latitude } = coordinates;

        const point = new mapData.Point([longitude, latitude]);

        featureArray.push(
            new mapData.Feature<mapData.Point, FeatureProperties>(point, {
                value,
                argument,
                size: value,
                color: colorBySeriesName[argument] ?? colors[0],
            })
        );
    }
    return new mapData.FeatureCollection(featureArray);
}
