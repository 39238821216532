import * as React from 'react';
/**
 * @internal
 * Context shared by all of the tooltips in the app
 */ const TooltipVisibilityContext = React.createContext(undefined);
const tooltipVisibilityContextDefaultValue = {};
/**
 * @internal
 */ export const TooltipVisibilityProvider = TooltipVisibilityContext.Provider;
export function useTooltipVisibility() {
    var _React_useContext;
    return (_React_useContext = React.useContext(TooltipVisibilityContext)) !== null && _React_useContext !== void 0 ? _React_useContext : tooltipVisibilityContextDefaultValue;
}
