import { KustoDomains, parseClusterConnectionString } from '@kusto/client';
import { EntityType } from '@kusto/query';
import {
    decodedQueryParam,
    formatLiterals,
    IKweTelemetry,
    Mutable,
    URL_SEARCH_FALSE_VALUE,
    URL_SEARCH_TRUE_VALUES,
} from '@kusto/utils';

import { AppPage, appPages, deepLinkDisabledAppPages, deepLinkEnabledAppPages } from '../common/AppPages';
import { KWE_ENV } from '../common/constants';
import type { AppStrings } from '../locale';

export interface QueryDeepLinkProperties {
    /**
     * @deprecated use react-router instead
     */
    appPage?: AppPage;
    readonly deepLink: URL;
    readonly clusterName?: string;
    readonly databaseName?: string;
    readonly tableName?: string;
    readonly subPath?: string;
    readonly connectionString?: string;
    readonly query?: string;
    readonly querySrc?: string;
    readonly autorun?: boolean;
    readonly forget: boolean;
    readonly storeName?: string;
    readonly workspaceName?: string;
    readonly origin?: string;
    readonly pathname?: string;
    /**
     * Query parameter to open one of the dataset tutorial in explorer, feature
     * in use for the homepage
     */
    readonly tutorial?: string;
    /**
     * True if user clicked on the explore query from the dashboard tile using new browser tab/window.
     */
    readonly exploreQuery?: boolean;

    /**
     * If exceptions are created, they will be added to this array so caller can
     * log them if they didn't pass telemetry as an argument.
     *
     * Not user facing.
     */
    readonly exceptions?: unknown[];
    /**
     * User facing messages created while parsing the URL
     */
    readonly warnings?: ReadonlyArray<(strings: AppStrings) => string>;
}

const ignoredPathPrefixes = (KWE_ENV.publicUrl || '').split('/');
const enabledPathPrefixes: string[] = Object.values(deepLinkEnabledAppPages);
const disabledPathPrefixes: string[] = Object.values(deepLinkDisabledAppPages);

/**
 * Parse a deep link and extract useful information such as db and cluster in
 * context. Supports the following formats:
 * https://kusto.azure.com/clusters/${clusterName}/databases/${databaseName}/?query=${query}
 * https://kusto.azure.com/${clusterName}/${databaseName}/?query=${query}
 * https://kusto.azure.com/?cluster${clusterName}/${databaseName}/?query=${query}
 * https://kusto.azure.com/?cluster=${clusterName}&database=${databaseName}&q=${query}
 *
 * TODO: Add warnings for unrecognized parameters when in query area path
 */
export function queryPageLinkParser(
    telemetry: undefined | IKweTelemetry,
    kustoDomains: KustoDomains,
    deepLink: string
): QueryDeepLinkProperties {
    const warnings: Array<(strings: AppStrings) => string> = [];

    const deepLinkUrl = new URL(deepLink);
    const params = deepLinkUrl.searchParams;

    const getBoolParam = (key: string) => {
        const value = params.get(key);

        switch (value) {
            case URL_SEARCH_TRUE_VALUES[0]:
            case URL_SEARCH_TRUE_VALUES[1]:
                return true;
            case URL_SEARCH_FALSE_VALUE:
                return false;
            case null:
                return undefined;
            default:
                warnings.push((strings) =>
                    formatLiterals(strings.kwe.init.urlWarnings.failedToParse, {
                        urlPartial: new URLSearchParams([[key, value]]).toString(),
                    })
                );
                return undefined;
        }
    };

    const storeName = params.get('storeName') ?? undefined;
    const workspaceName = params.get('workspace') ?? undefined;
    const origin = params.get('origin') ?? undefined;
    const tutorial = params.get('tutorial') ?? undefined;
    const exploreQuery = getBoolParam('exploreQuery');

    const forget = getBoolParam('forget') ?? false;

    const pathname = deepLinkUrl.pathname;

    const baseLinkData: Mutable<QueryDeepLinkProperties> = {
        deepLink: deepLinkUrl,
        forget,
        storeName,
        workspaceName,
        origin,
        pathname,
        tutorial,
        exploreQuery,
        warnings,
    };

    // If we're not in the query or root path, shouldn't we return nothing?
    if (!pathname) {
        return baseLinkData;
    }

    let pathFragments = pathname.split('/');

    let appName: string | undefined = undefined;
    if (pathFragments) {
        // Cycle through ignored paths
        while (pathFragments.length > 0 && ignoredPathPrefixes.indexOf(pathFragments[0]) !== -1) {
            pathFragments.shift();
        }

        // To work with oneclick - remove last empty fragment
        if (pathFragments.length > 0 && pathFragments[pathFragments.length - 1] === '') {
            pathFragments.unshift();
        }

        if (pathFragments.length > 0 && disabledPathPrefixes.indexOf(pathFragments[0]) !== -1) {
            // Matches disabled path, return deep link defaults
            const appPage = getAppPage(pathFragments[0]);
            baseLinkData.appPage = appPage;
            return baseLinkData;
        }

        // Cycle through enabled paths (capture the last one)
        while (pathFragments.length > 0 && enabledPathPrefixes.indexOf(pathFragments[0]) !== -1) {
            appName = pathFragments.shift();
        }
    }

    const appPage = getAppPage(appName);
    baseLinkData.appPage = appPage;
    if (appPage === appPages.OneClick || appPage === appPages.VirtualCluster || appPage === appPages.Trident) {
        if (pathFragments.length === 0) {
            return baseLinkData;
        }
        baseLinkData.subPath = pathFragments[0];
        pathFragments = pathFragments.slice(1);
    }

    const isNewUriScheme = pathFragments[0] === 'clusters';

    // We're supporting both the new more standard REST api schema:
    // https://kusto.azure.com/clusters/${clusterName}/databases/${databaseName}
    // but also the old weird uri scheme
    // https://kusto.azure.com/${clusterName}/${databaseName}

    // entitiesPrefixes are relevant only for the new uri scheme
    const entitiesPrefixes = {
        [EntityType.Cluster]: 'clusters',
        [EntityType.Database]: 'databases',
        [EntityType.Table]: 'tables',
    };
    // will populate with the entity values parsed from the URL
    const entityValues: { [entity in EntityType]?: string } = {};

    // Parse the entities from the path fragments
    for (const [entity, prefix] of Object.entries(entitiesPrefixes)) {
        if (isNewUriScheme) {
            if (pathFragments[0] === prefix) {
                entityValues[entity as EntityType] = pathFragments[1];
            } else {
                // If it's new uri scheme but the next fragment isn't an entity prefix, then stop parsing entities.
                break;
            }
        } else {
            entityValues[entity as EntityType] = pathFragments[0];
        }
        // remove the parsed entity from the path fragments
        pathFragments = pathFragments.slice(isNewUriScheme ? 2 : 1);
    }

    const rawCluster: string | undefined = params.get('cluster') || entityValues[EntityType.Cluster];

    const databaseName: string | undefined = decodeURIComponent(
        params.get('database') || entityValues[EntityType.Database] || ''
    );

    const tableName = entityValues[EntityType.Table];
    if (tableName) {
        baseLinkData.tableName = decodeURIComponent(tableName);
    }
    if (!baseLinkData.subPath) {
        baseLinkData.subPath = pathFragments.join('/');
    }

    // looks like there's no deep link here if we don't have a cluster name
    if (!rawCluster) {
        return baseLinkData;
    }

    // Normalize different supported references to a cluster to its alias.
    const maybeConnectionStringInfo = parseClusterConnectionString(telemetry, kustoDomains, rawCluster);
    if (maybeConnectionStringInfo.kind === 'err') {
        if (maybeConnectionStringInfo.err.code === 'unexpectedCrash') {
            baseLinkData.exceptions ??= [];
            baseLinkData.exceptions.push(maybeConnectionStringInfo.err.exception);
        }
        return baseLinkData;
    }
    const { clusterName, connectionString } = maybeConnectionStringInfo.value;

    // We support both 'q' and 'query' as parameters. q is for encoded gzipped
    // queries. query is for both.
    let query = (params.get('query') || params.get('q')) ?? undefined;
    if (query) {
        // If this is a base64 gzipped query, do necessary work to decode and unzip it
        try {
            const replaced = query.replace(/ /g, '+');

            query = decodedQueryParam(replaced);
        } catch {
            // Error intentionally ignored: Treat queries that fail to unzip as plaintext
        }
    }

    const querySrc = params.get('querysrc') ?? undefined;

    const autorun = getBoolParam('autorun');

    if (!query && autorun !== undefined) {
        warnings.push((strings) => formatLiterals(strings.kwe.init.urlWarnings.query.autorunRequiresQuery));
    }

    return {
        appPage,
        clusterName,
        connectionString,
        databaseName,
        tableName: tableName ? decodeURIComponent(tableName) : undefined,
        query,
        querySrc,
        autorun,
        ...baseLinkData,
    };
}

/**
 * get the app page from url fragment.
 * @param appName the app name in url
 */
function getAppPage(appName: string | undefined) {
    if (appName === undefined) {
        return appPages.Explorer;
    }

    // turns out string enums don't have reverse lookups in typescript.
    return Object.values(appPages).indexOf(appName as AppPage) !== -1 ? (appName as AppPage) : appPages.Explorer;
}
