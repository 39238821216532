import { assertNever } from '@kusto/utils';

import type { HorizontalLine, YAxisConfig } from '../../visualOptions';
import { convertStringToNumber } from './util';

interface UpdateYAxisLimit {
    type: 'updateYAxisLimit';
    property: 'yAxisMinimumValue' | 'yAxisMaximumValue';
    value: string;
}

export type MultipleYAxesActions =
    | { type: 'setColumn'; column: string }
    | { type: 'setLabel'; label: string }
    | UpdateYAxisLimit
    | { type: 'setYAxisScale'; yAxisScale: 'linear' | 'log' }
    | { type: 'updateHorizontalLine'; value: string; id: string }
    | { type: 'setHorizontalLines'; lines: readonly HorizontalLine[] };

export const multipleYAxesReducer = (state: YAxisConfig, action: MultipleYAxesActions): YAxisConfig => {
    switch (action.type) {
        case 'setColumn':
            return { ...state, columns: [action.column] };
        case 'setLabel': {
            return { ...state, label: action.label };
        }
        case 'updateYAxisLimit': {
            return { ...updateYAxisLimit(state, action.property, action.value) };
        }
        case 'setYAxisScale': {
            return { ...state, yAxisScale: action.yAxisScale };
        }
        case 'updateHorizontalLine': {
            const updatedHorizontalLines = [...state.horizontalLines];
            const index = updatedHorizontalLines.findIndex((line) => line.id === action.id);
            updatedHorizontalLines[index] = {
                ...updatedHorizontalLines[index],
                value: convertStringToNumber(action.value),
            };
            return { ...state, horizontalLines: updatedHorizontalLines };
        }
        case 'setHorizontalLines': {
            return { ...state, horizontalLines: action.lines };
        }
    }
    assertNever(action);
};

export const updateYAxisLimit = (
    state: YAxisConfig,
    property: 'yAxisMinimumValue' | 'yAxisMaximumValue',
    value: string
): YAxisConfig => {
    let { yAxisMinimumValue, yAxisMaximumValue } = state;
    switch (property) {
        case 'yAxisMaximumValue':
            yAxisMaximumValue = convertStringToNumber(value);
            break;
        case 'yAxisMinimumValue':
            yAxisMinimumValue = convertStringToNumber(value);
            break;
    }

    if (yAxisMinimumValue !== null && yAxisMaximumValue !== null) {
        switch (property) {
            case 'yAxisMinimumValue':
                if (yAxisMaximumValue < yAxisMinimumValue) {
                    yAxisMaximumValue = yAxisMinimumValue;
                }
                break;
            case 'yAxisMaximumValue':
                if (yAxisMinimumValue > yAxisMaximumValue) {
                    yAxisMinimumValue = yAxisMaximumValue;
                }
                break;
        }
    }
    return { ...state, yAxisMaximumValue, yAxisMinimumValue };
};
