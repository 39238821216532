/// Generated by /Scripts/build-icon-fonts.mjs, do not edit manually

import { registerFontIcons } from '@kusto/utils';

import characterCodes from './icons.json';

// Required for registerIcons
import './icons.css';

export const visualFwkIconNames = registerFontIcons({
    characterCodes,
    namespace: 'visual-fwk',
});
