import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import {
    Divider,
    Input,
    makeStyles,
    Menu,
    MenuDivider,
    MenuGroup,
    MenuGroupHeader,
    MenuList,
    MenuPopover,
    MenuProps,
    mergeClasses,
    PositioningImperativeRef,
    useId,
} from '@fluentui/react-components';
import { ChevronDownRegular } from '@fluentui/react-icons';

import { renderIcon } from '../Icon';
import { OptionalTooltip } from '../OptionalTooltip/OptionalTooltip';
import { ActionButton } from './ActionButton';
import { DropDownMenuItem } from './DropDownMenu';
import { ActionItem, DropDownOption, DropDownWithActionsProps } from './types';
import { getSelectedOption, keyboardExecute } from './utils';

import styles from './DropDownWithActions.module.scss';

// Apply CSS to internal html input element (not to Fluent's Input component)
const useStyles = makeStyles({
    inputStyle: {
        '> input': {
            pointerEvents: 'none', // don't act as textbox
            textOverflow: 'ellipsis',
        },
    },
});

/** DropDown with additional actions (icons). Clicking the menu button will open the drop-down, while clicking an icon will trigger callback. */
export const DropDownWithActions = <T,>({
    selectedOptionId,
    placeholder,
    onSelect,
    optionsGroups,
    actions = [],
    disabled,
    disabledTooltip,
    style = {},
}: DropDownWithActionsProps<T>) => {
    const className = useStyles();
    const dropDownWithActionsMenuListId = useId('DropDownWithActionsMenuList');

    // Position the Menu below (relative) the Input
    const buttonRef = useRef<HTMLDivElement>(null);
    const positioningRef = useRef<PositioningImperativeRef>(null);

    useEffect(() => {
        if (buttonRef.current) {
            positioningRef.current?.setTarget(buttonRef.current);
        }
    }, [buttonRef, positioningRef]);

    const [open, setOpen] = useState(false);

    const toggleOpen = useCallback(() => {
        if (disabled) {
            return;
        }

        setOpen((prev) => !prev);
    }, [disabled]);

    const handleOpenChange = useCallback<NonNullable<MenuProps['onOpenChange']>>((event, data) => {
        setOpen(data.open);
        // prevent bubbling to onClick event - otherwise closing the menu will also trigger the onClick event - which will open the menu again
        event.stopPropagation();
    }, []);

    const openMenuAction: ActionItem = {
        icon: ChevronDownRegular,
        tooltip: 'Open menu', // a11y
        disabled,
        onClick: toggleOpen,
    };

    const selectedOption = getSelectedOption(optionsGroups, selectedOptionId);
    const tooltip = (disabled ? disabledTooltip : undefined) ?? selectedOption?.selectedTooltip;

    return (
        <div>
            <OptionalTooltip content={tooltip}>
                <div
                    ref={buttonRef}
                    onClick={toggleOpen}
                    onKeyDown={(e) => keyboardExecute(e, toggleOpen)}
                    className={styles.container}
                    // a11y
                    role="combobox"
                    aria-controls="DropDownWithActionsMenuList"
                    aria-expanded={open}
                    tabIndex={0}
                >
                    {/*
                    // Using Input and not Button/DropDown from Fluent - because:
                    // 1. Unlike Button that has Icon and IconPosition (before/after), Input has contentBefore and contentAfter, which allows us to have both iconBefore and actions (after).
                    // 2. Unlike DropDown, custom MenuList can have sub-menus, which default DropDown-Option has not.
                    */}
                    <Input
                        aria-label="dropdown"
                        value={selectedOption?.text ?? placeholder?.text}
                        contentBefore={renderIcon(selectedOption?.icon ?? placeholder?.icon)}
                        contentAfter={
                            <>
                                {[openMenuAction, ...actions].map((action, index) => (
                                    <Fragment key={index}>
                                        <ActionButton {...action} />
                                        {index != actions.length && (
                                            <Divider vertical className={styles.actionsDivider} />
                                        )}
                                    </Fragment>
                                ))}
                            </>
                        }
                        disabled={disabled}
                        className={mergeClasses(styles.mainButton, className.inputStyle)}
                        style={style}
                        autoComplete="off"
                    />
                </div>
            </OptionalTooltip>
            <Menu open={open} onOpenChange={handleOpenChange} positioning={{ positioningRef }}>
                <MenuPopover
                    className={styles.menuPopover}
                    style={{
                        // menu width same as the button
                        width: buttonRef.current?.firstElementChild?.clientWidth,
                        maxWidth: 'unset',
                    }}
                >
                    <MenuList id={dropDownWithActionsMenuListId}>
                        {optionsGroups.map((group, i) => (
                            <Fragment key={i}>
                                <MenuGroup>
                                    {group.label && <MenuGroupHeader>{group.label}</MenuGroupHeader>}
                                    {group.options.map((option, j) => (
                                        <DropDownMenuItem
                                            key={j}
                                            {...option}
                                            hasIcons={group.hasIcons || group.options.some((option) => option.icon)}
                                            selectedId={selectedOptionId}
                                            onSelect={(_event, option: DropDownOption<T>) => onSelect?.(option)}
                                        />
                                    ))}
                                </MenuGroup>
                                {i != optionsGroups.length - 1 && <MenuDivider />}
                            </Fragment>
                        ))}
                    </MenuList>
                </MenuPopover>
            </Menu>
        </div>
    );
};
