.addPillPopover {
    display: grid;
    grid-template:
        'filter aggregation close' auto
        'filter-content aggregation-content aggregation-content' auto / minmax(230px, 1fr) 1fr auto;
    gap: var(--spacingVerticalL);
}

.filterContainer {
    grid-area: filter;
}
.filterContent {
    grid-area: filter-content;
}

.aggregationContainer {
    grid-area: aggregation;
}
.aggregationContent {
    grid-area: aggregation-content;
    border-left: 1px solid var(--colorNeutralStroke1);
    padding-left: var(--spacingHorizontalL);
}

.closeButtonContainer {
    grid-area: close;
}
