import { createContext, useContextSelector } from '@fluentui/react-context-selector';
export const OverflowContext = createContext(undefined);
const overflowContextDefaultValue = {
    itemVisibility: {},
    groupVisibility: {},
    hasOverflow: false,
    registerItem: ()=>()=>null,
    updateOverflow: ()=>null,
    registerOverflowMenu: ()=>()=>null,
    registerDivider: ()=>()=>null
};
/**
 * @internal
 */ export const useOverflowContext = (selector)=>useContextSelector(OverflowContext, (ctx = overflowContextDefaultValue)=>selector(ctx));
