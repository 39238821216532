import React, { useState } from 'react';
import { Button, OverflowItem, Popover, PopoverSurface, PopoverTrigger } from '@fluentui/react-components';
import { Add20Filled, Add20Regular, bundleIcon } from '@fluentui/react-icons';

import { useStrings } from '../../../hooks/useStrings';
import { AddPillPopover } from './AddPillPopover';

const Add20Icon = bundleIcon(Add20Filled, Add20Regular);

export const AddPillButton: React.FunctionComponent = () => {
    const { strings } = useStrings();

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    return (
        <Popover
            withArrow
            positioning="below"
            open={isPopoverOpen}
            onOpenChange={(e, { open }) => setIsPopoverOpen(open)} // auto close on click outside
        >
            <PopoverTrigger disableButtonEnhancement>
                <OverflowItem id="button-add" priority={2000}>
                    <Button icon={<Add20Icon />} onClick={() => setIsPopoverOpen(true)} appearance="transparent">
                        {strings.dataExplorationModal.pillsBar.addPillLabel}
                    </Button>
                </OverflowItem>
            </PopoverTrigger>
            <PopoverSurface tabIndex={-1}>
                <AddPillPopover onClose={() => setIsPopoverOpen(false)} />
            </PopoverSurface>
        </Popover>
    );
};
