import * as React from 'react';
import { mergeClasses } from '@griffel/core';
import * as Highcharts from 'highcharts';
import type HighchartsReact from 'highcharts-react-official';
import cloneDeep from 'lodash/cloneDeep';

import { assertNever } from '@kusto/utils';

import type { InternalChartProps, YAxisSeriesMap } from '../types';
import type { ExtendedVisualizationOptions } from '../utils/visualization';
import { Highchart, HighchartProps } from './Highchart';
import { useShouldRecreateChartObject } from './utils';

import * as styles from './styles.module.scss';

interface CustomLegendItem {
    name: string;
    color: string;
    symbol?: string;
    disabled?: boolean;
    chartRef: HighchartsReact.RefObject;
}

interface CustomLegendProps {
    legendItems: CustomLegendItem[];
}

export const CHART_WITH_PANELS_RESULT_CONTAINER_ID = 'chart-with-panels-result-container';

const neutralDisabledColor = '#cccccc'; // todo: have this themed
const maxNumberOfPanelsInRow = 3;

const CustomLegend: React.FC<CustomLegendProps> = (props) => {
    const { legendItems } = props;
    const [items, setItems] = React.useState<CustomLegendItem[]>(legendItems);

    React.useEffect(() => {
        setItems(legendItems);
    }, [props, legendItems]);

    const onItemClicked = (item: CustomLegendItem) => {
        const series = item.chartRef.chart.series.find((seriesItem) => seriesItem.name === item.name);
        if (series) {
            const isVisible = !series.visible;
            series.setVisible(isVisible);
            item.disabled = !isVisible;
            setItems(items.slice());
        }
    };

    const onItemHovered = (item: CustomLegendItem, mouseIn: boolean) => {
        legendItems.forEach((legendItem) =>
            legendItem.chartRef.chart.series.forEach((seriesItem) => seriesItem.setState('normal'))
        );
        const series = item.chartRef.chart.series.find((seriesItem) => seriesItem.name === item.name);
        if (mouseIn && series?.visible) {
            legendItems.forEach((legendItem) =>
                legendItem.chartRef.chart.series.forEach((seriesItem) => seriesItem.setState('inactive'))
            );
            series.setState('normal');
        }
        setItems(items.slice());
    };

    return (
        <div className={styles.highcharts__customLegend}>
            {items.map((item) => (
                <button
                    key={item.name}
                    aria-label={`Show ${item.name}`}
                    onClick={() => onItemClicked(item)}
                    onMouseEnter={() => onItemHovered(item, true)}
                    onMouseLeave={() => onItemHovered(item, false)}
                    className={styles.highcharts__legendItem}
                    style={item.disabled ? { color: neutralDisabledColor } : {}}
                >
                    <div
                        className={styles.highcharts__legendSymbol}
                        style={{ border: `1px solid ${item.disabled ? neutralDisabledColor : item.color}` }}
                    />
                    <span>{item.name}</span>
                </button>
            ))}
        </div>
    );
};

export interface HighChartWithPanelsProps
    extends Omit<HighchartProps, 'shouldRecreateChartObject'>,
        Pick<InternalChartProps, 'legendPosition'> {
    yAxesSeriesMap: YAxisSeriesMap;
    horizontalView?: boolean;
    visualizationOptions: ExtendedVisualizationOptions;
}

export const HighChartWithPanels: React.FC<HighChartWithPanelsProps> = (props) => {
    const chartsRefs = React.useRef<{ [k: string]: HighchartsReact.RefObject }>({});
    const [legendItems, setLegendItems] = React.useState<CustomLegendItem[]>([]);
    const { yAxesSeriesMap, horizontalView } = props;
    const title = props.options.title?.text;

    const shouldRecreateChartObject = useShouldRecreateChartObject(props);

    // Build an option object for each chart (panel)
    const chartsData = React.useMemo(() => {
        const yAxes = Object.keys(yAxesSeriesMap);

        return yAxes.map((yAxis) => {
            const chartOptions = cloneDeep(props);
            chartOptions.options.series?.forEach((item) => {
                if (!yAxesSeriesMap[yAxis].includes(item.name as string)) {
                    (item as unknown as Highcharts.Series).data = [];
                }
            });
            chartOptions.options.legend = { enabled: false };
            chartOptions.options.title = undefined;

            if (chartOptions.visualizationOptions.MultipleYAxes && Array.isArray(chartOptions.options.yAxis)) {
                chartOptions.options.yAxis.forEach((yAxis) => {
                    yAxis.opposite = false;
                    if (yAxis.title?.text) {
                        yAxis.title.text = '';
                    }
                });
            }

            return { key: yAxis, data: chartOptions };
        });
    }, [props, yAxesSeriesMap]);

    // Init legend items
    React.useEffect(() => {
        if (chartsRefs.current && Object.values(chartsRefs.current).length) {
            Object.keys(chartsRefs.current).forEach((key) => {
                if (!chartsRefs.current[key]) {
                    delete chartsRefs.current[key];
                }
            });

            const yAxes = Object.keys(yAxesSeriesMap);
            const legendItems = Object.values(chartsRefs.current)[0].chart.series.map((item: Highcharts.Series) => {
                const yAxis = yAxes.filter((key) => yAxesSeriesMap[key].includes(item.name))[0];
                return {
                    name: item.name,
                    color: (item as unknown as Highcharts.Point).color as string,
                    chartRef: chartsRefs.current[yAxis as string],
                };
            });
            setLegendItems(legendItems);
        }
    }, [chartsRefs, setLegendItems, props.isDarkTheme, yAxesSeriesMap]);

    // Sync crosshair & tooltips between panels
    const syncPanels = React.useCallback(
        (e: MouseEvent, chartId: string) => {
            Object.keys(chartsRefs.current).forEach((chartKey) => {
                const chartsRef = chartsRefs.current[chartKey];

                // Refresh tooltip (must come before updating the crosshair)
                const normalizedEvent = chartsRef.chart.pointer.normalize(e);
                const seriesItem = chartsRef.chart.series.find((item) => item.data.length && item.visible);
                const point = seriesItem?.searchPoint(normalizedEvent, true);
                if (point) {
                    if (chartKey !== chartId) {
                        point.series.chart.tooltip.refresh(point); // No need to refresh the hovered chart
                    }
                    point.series.chart.xAxis[0].drawCrosshair(normalizedEvent, point);
                }
            });
        },
        [chartsRefs]
    );

    const clearSyncedView = () => {
        Object.values(chartsRefs.current).forEach((chartsRef) => {
            // Clear highlighted points, tooltip and crosshairs
            chartsRef.chart.series.forEach((series) => series.points.forEach((point) => point.setState()));
            chartsRef.chart.tooltip.hide(0);
            chartsRef.chart.xAxis[0].hideCrosshair();
        });
    };

    let legendClass: string;

    switch (props.legendPosition) {
        case 'bottom':
        case undefined:
            legendClass = styles.legend_bottom;
            break;
        case 'left':
            legendClass = styles.legend_left;
            break;
        case 'right':
            legendClass = styles.legend_right;
            break;
        default:
            assertNever(props.legendPosition);
    }

    return (
        <div
            id={CHART_WITH_PANELS_RESULT_CONTAINER_ID}
            className={mergeClasses(styles.highcharts__resultContainer, legendClass)}
        >
            {title && <div className={styles.highcharts__title}>{title}</div>}
            <div
                className={mergeClasses(
                    styles.highcharts__chart,
                    horizontalView ? styles.highcharts__chartsBody_h : styles.highcharts__chartsBody
                )}
                style={{
                    flexWrap:
                        horizontalView && Object.keys(chartsRefs.current).length > maxNumberOfPanelsInRow
                            ? 'wrap'
                            : 'nowrap',
                }}
                onMouseLeave={clearSyncedView}
            >
                {chartsData.map((chartData) => (
                    <div
                        key={chartData.key}
                        className={horizontalView ? styles.highcharts__chartsBody_hItem : styles.highcharts__chartsBody}
                        onMouseMove={(e) => syncPanels(e as unknown as MouseEvent, chartData.key)}
                    >
                        {horizontalView && <div className={styles.highcharts__horizontalLabel}>{chartData.key}</div>}
                        <Highchart
                            ref={(el: HighchartsReact.RefObject) => (chartsRefs.current[chartData.key] = el)}
                            key={chartData.key}
                            {...chartData.data}
                            shouldRecreateChartObject={shouldRecreateChartObject}
                        />
                    </div>
                ))}
            </div>
            {props.visualizationOptions.Legend !== 'hidden' && <CustomLegend legendItems={legendItems} />}
        </div>
    );
};
