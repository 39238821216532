import * as React from 'react';
import { ToolbarButton, Tooltip } from '@fluentui/react-components';
import { ToolbarButtonProps } from '@fluentui/react-toolbar';

type ActionItemProps = ToolbarButtonProps & {
    content: string;
};

export const ActionItem: React.FC<ActionItemProps> = ({ content, ...props }) => {
    return (
        <Tooltip content={content} showDelay={600} positioning="below" relationship="description" withArrow>
            <ToolbarButton {...props} />
        </Tooltip>
    );
};
