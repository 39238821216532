import React from 'react';
import {
    Button,
    createFocusOutlineStyle,
    Divider,
    makeStyles,
    shorthands,
    tokens,
    Tooltip,
} from '@fluentui/react-components';
import { Copy20Regular } from '@fluentui/react-icons';
import * as clipboard from 'clipboard-polyfill';

import { KweAgGridLocale } from '../locale';
import { JPathArrayRepresentation, JsonModelData } from './types';
import { useJPath } from './useJPath';
import { convertJPathArrayRepresentationToString } from './utils';

import styles from './JPathView.module.scss';

const JPATH_LABEL = 'JPath:';
const SEPARATOR = '/';

export interface JPathViewProps extends JsonModelData {
    locale: KweAgGridLocale;
}

//TODO move into scss module
const useStyles = makeStyles({
    container: {
        ...createFocusOutlineStyle(),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        ...shorthands.flex(1),
    },
    divider: {
        ...shorthands.flex(0),
    },
    copyButton: {
        ...shorthands.padding('5px'),
    },
});

export const JPathView: React.FC<JPathViewProps> = ({ rawString, lineNumber, locale, children }) => {
    const jsonPath = useJPath(rawString, lineNumber);
    const JPathStyles = useStyles();

    const handleCopyClick = () => {
        const dt = new clipboard.DT();
        dt.setData('text/plain', convertJPathArrayRepresentationToString(jsonPath));
        clipboard.write(dt);
    };

    return (
        <div
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            className={JPathStyles.container}
            role="toolbar"
        >
            <span className={styles.jpViewLabel}>{JPATH_LABEL}</span>
            {jsonPath.length ? (
                <>
                    <Tooltip withArrow content={<PrettyString jpath={jsonPath} />} relationship="label">
                        <Button
                            className={JPathStyles.copyButton}
                            onClick={handleCopyClick}
                            icon={<Copy20Regular primaryFill={tokens.colorPaletteBlueBorderActive} />}
                            appearance="subtle"
                            iconPosition="before"
                            aria-label={locale?.agGrid.jpath.copyJPath}
                        >
                            <PrettyString jpath={jsonPath} />
                        </Button>
                    </Tooltip>
                    <Divider vertical className={JPathStyles.divider} />
                    {children}
                </>
            ) : (
                <span>{locale?.agGrid.jpath.invalidValue}</span>
            )}
        </div>
    );
};

const PrettyString: React.FC<{ jpath: JPathArrayRepresentation }> = ({ jpath }) => {
    return (
        <div className={styles.jpViewValue}>
            {jpath.map((nodeStr, index) => {
                if (index === 0) {
                    return; // Editor lines starts from 1
                }
                const isNumber = +nodeStr >= 0;
                return (
                    <div key={`${nodeStr}_${index}`}>
                        {!isNumber && <span className={styles.jpViewSeparator}>{SEPARATOR}</span>}
                        <span className={index === 1 ? '' : styles.colorFragment}>
                            {isNumber ? `[${nodeStr}]` : nodeStr}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};
