import React from 'react';
import {
    ContextualMenu,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton,
    Separator,
} from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import ReactMonacoEditor from 'react-monaco-editor';

import type { RenderHelper } from '@kusto/ui-components';

import { useQueryCore } from '../../core/core';
import { escapeText, getSelectionDetails, unescapeText } from './lib';

import * as styles from './openTextEditor.module.scss';

interface TextEditorDialogProps {
    defaultValue: string;
    onClose: () => void;
    onSave: (value: string) => void;
}

const TextEditorDialog: React.FunctionComponent<TextEditorDialogProps> = observer(function TextEditorDialog({
    defaultValue,
    onClose,
    onSave,
}) {
    const core = useQueryCore();
    const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor>();

    return (
        <Dialog
            modalProps={{
                containerClassName: styles.noWidthLimit,
                dragOptions: {
                    moveMenuItemText: 'Move',
                    closeMenuItemText: core.strings.query.close,
                    menu: ContextualMenu,
                },
            }}
            hidden={false}
            dialogContentProps={{
                title: core.strings.query.editor$textEditor,
                type: DialogType.largeHeader,
            }}
        >
            <Separator />
            <ReactMonacoEditor
                language="text"
                width="700"
                height="300"
                defaultValue={defaultValue}
                editorDidMount={(editor) => {
                    editorRef.current = editor;
                    editor.addAction({
                        label: core.strings.query.close,
                        keybindings: [monaco.KeyCode.Escape],
                        id: 'editor.action.kusto.closeTextEditor',
                        run: onClose,
                    });
                }}
            />
            <Separator />
            <DialogFooter>
                <PrimaryButton
                    onClick={() => {
                        const value = editorRef.current?.getValue() ?? '';
                        onSave(value);
                    }}
                    text={core.strings.query.apply}
                />
                <DefaultButton onClick={onClose} text={core.strings.query.cancel} />
            </DialogFooter>
        </Dialog>
    );
});

export function openTextEditor(
    render: RenderHelper,
    editor: monaco.editor.IStandaloneCodeEditor,
    abortSignal: AbortSignal
) {
    const selectionDetails = getSelectionDetails(editor);

    render((resolve) => {
        const onClose = () => {
            editor.focus();
            resolve();
        };

        return (
            <TextEditorDialog
                defaultValue={unescapeText(selectionDetails.value || '')}
                onClose={onClose}
                onSave={(val: string) => {
                    if (selectionDetails.range) {
                        editor.executeEdits('escape', [
                            {
                                range: selectionDetails.range,
                                text: escapeText(val),
                            },
                        ]);
                    }
                    onClose();
                }}
            />
        );
    }, abortSignal);
}
