import * as React from 'react';
import { Icon, MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import { Button } from '@fluentui/react-components';

import { QueryMessageLevel } from '../error';
import { VisualHtmlErrorId, VisualMessageData, VisualMessageOptions } from '../visualConfig';

import * as styles from './VisualMessage.module.scss';

interface MessageLayoutProps extends Omit<VisualMessageProps, 'showIcon' | 'level' | 'options'>, VisualMessageOptions {}

const InfoMessageLayout: React.FC<MessageLayoutProps> = ({ htmlErrorId, title, message, actions, icon, children }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    return (
        <div ref={ref} className={styles.infoLayout} id={htmlErrorId}>
            {icon && <Icon className={styles.icon} iconName={icon} />}
            {title && <div className={styles.title}>{title}</div>}
            {message && <div className={styles.body}>{message}</div>}
            {children}
            {actions && (
                <>
                    {actions?.map((action) => (
                        <Button appearance="primary" key={action.key} onClick={action.onClick}>
                            {action.text}
                        </Button>
                    ))}
                </>
            )}
        </div>
    );
};

const ErrorMessageLayout: React.FC<MessageLayoutProps> = ({
    htmlErrorId,
    title,
    message,
    actions,
    headerLevel,
    children,
}) => {
    return (
        <MessageBar
            id={htmlErrorId}
            className={styles.visualMessage}
            messageBarType={MessageBarType.error}
            isMultiline
            actions={
                actions && (
                    <>
                        {actions?.map((action) => (
                            <MessageBarButton key={action.key} onClick={action.onClick}>
                                {action.text}
                            </MessageBarButton>
                        ))}
                    </>
                )
            }
        >
            {title && React.createElement('h' + headerLevel, {}, title)}
            {message && <p>{message}</p>}
            {children}
        </MessageBar>
    );
};

export interface VisualMessageProps extends Pick<VisualMessageData, 'title' | 'options'> {
    headerLevel: 1 | 2 | 3 | 4 | 5;
    htmlErrorId?: VisualHtmlErrorId;
    message?: React.ReactNode;
    level?: QueryMessageLevel;
}

export const VisualMessage: React.FC<VisualMessageProps> = ({
    headerLevel,
    htmlErrorId,
    message,
    title,
    options,
    level = 'error',
    children,
}) => {
    const MessageLayout = level === 'info' ? InfoMessageLayout : ErrorMessageLayout;

    return (
        <MessageLayout
            headerLevel={headerLevel}
            htmlErrorId={htmlErrorId}
            message={message}
            title={title}
            actions={options?.actions}
            icon={options?.icon}
        >
            {children}
        </MessageLayout>
    );
};
