import { EntityGroup } from '../../../common';
import { Database, QueryStoreEnv } from '../../../stores';
import { ITreeEntityMapper } from '../actions';
import { RowDataType } from '../RowDataType';
import { getBaseRootPath } from '../utils';
import { entityGroupFolderName } from './DatabaseFolderRowDataType';
import { iconMapper } from './IconMapper';
import { RowDataTypeBase } from './RowDataTypeBase';
import { getFromCacheOrCreate } from './RowDataTypeCache';

export class EntityGroupRowDataType extends RowDataTypeBase {
    private constructor(
        entityGroup: EntityGroup,
        database: Database,
        baseRootPath: string[],
        treeEntityMapper: ITreeEntityMapper
    ) {
        super(
            entityGroup.id,
            EntityGroupRowDataType.buildEntityGroupFolderPath(baseRootPath, entityGroup),
            entityGroup.name,
            entityGroup.entityType,
            database,
            iconMapper[entityGroup.entityType],
            treeEntityMapper
        );

        this.actions = treeEntityMapper.getActions(this.entityType, false, this.info);
    }

    public static buildEntityGroupFolderPath(baseRootPath: string[], entityGroup: EntityGroup): string[] {
        const pathFromRoot: string[] = [];
        // Regular tables without a folder will be shown under the database.
        pathFromRoot.push(...baseRootPath, entityGroupFolderName, entityGroup.id);

        return pathFromRoot;
    }

    public static fromCacheOrCreate(
        env: QueryStoreEnv,
        entityGroup: EntityGroup,
        database: Database,
        entityActionsMapper: ITreeEntityMapper
    ): RowDataType {
        return getFromCacheOrCreate(
            database,
            entityGroup.id,
            () => new EntityGroupRowDataType(entityGroup, database, getBaseRootPath(env, database), entityActionsMapper)
        );
    }
}
