import React from 'react';
import {
    Button,
    createCustomFocusIndicatorStyle,
    HeadlessFlatTreeItem,
    makeStyles,
    Menu,
    MenuList,
    MenuPopover,
    MenuTrigger,
    mergeClasses,
    Slot,
    Spinner,
    tokens,
    Tooltip,
    TreeItem,
    TreeItemLayout,
    treeItemLayoutClassNames,
    treeItemPersonaLayoutClassNames,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {
    ArrowCounterclockwiseDashes20Regular,
    ErrorCircleFilled,
    Info24Regular,
    MoreHorizontal20Regular,
} from '@fluentui/react-icons';

import { OverflowTooltip } from '../OverflowTooltip';
import { getMarkedText, TextWithLink } from './TreeEntity';
import { ActionMenuItemType, EntityActionsMapType, FlatTreeEntity, statusRequiresSpinner, TreeNode } from './types';

import * as styles from './TreeEntity.module.scss';

interface ITreeProps {
    value: string;
    'aria-setsize': number;
    'aria-level': number;
    'aria-posinset': number;
    itemType: 'branch' | 'leaf';
    parentValue?: string | undefined;
}

const useStyles = makeStyles({
    // If the consumer wants a focus indicator and uses the itemInFocus prop, set the outline to transparent.
    // If itemInFocus is not used (undefined), set the outline to the brand color for a focus-on-click experience.
    treeItemWithFocus: {
        display: 'flex',
        ...createCustomFocusIndicatorStyle(
            {
                outlineColor: 'transparent',
            },
            {
                customizeSelector: (selector) =>
                    `${selector} > .${treeItemLayoutClassNames.root}, ${selector} > .${treeItemPersonaLayoutClassNames.root}`,
            }
        ),
    },
    treeItemWithoutFocus: {
        display: 'flex',
        ...createCustomFocusIndicatorStyle(
            {
                outlineColor: tokens.colorBrandForegroundInverted,
            },
            {
                customizeSelector: (selector) =>
                    `${selector} > .${treeItemLayoutClassNames.root}, ${selector} > .${treeItemPersonaLayoutClassNames.root}`,
            }
        ),
    },
});

const getAsideItems = (entity: TreeNode, actions?: ActionMenuItemType[]): JSX.Element[] => {
    return (
        actions?.map((ActionComponent, index) => (
            <ActionComponent key={`${index}-aside-${ActionComponent.name}`} {...entity} />
        )) ?? []
    );
};
const getMenuItems = (entity: TreeNode, actions?: ActionMenuItemType[]): JSX.Element[] => {
    return (
        actions?.map((ActionComponent, index) => (
            <ActionComponent key={`${index}-${ActionComponent.name}`} {...entity} />
        )) ?? []
    );
};

const Actions: React.FC<{ docstring?: string; aside?: JSX.Element[]; menuItems?: JSX.Element[] }> = ({
    docstring,
    aside,
    menuItems,
}) => {
    return (
        <>
            {docstring && (
                <Tooltip
                    content={<TextWithLink text={docstring} />}
                    relationship="label"
                    hideDelay={500}
                    withArrow
                    positioning={'after-bottom'}
                >
                    <Button icon={<Info24Regular />} appearance="transparent" />
                </Tooltip>
            )}
            {aside?.length && <>{aside}</>}
            {menuItems?.length && (
                <Menu positioning={'below-start'}>
                    <MenuTrigger disableButtonEnhancement>
                        <Tooltip content="More actions" relationship="label">
                            <Button
                                // TODO: add aria-label into i18n when localization is ready in ui-components pkg
                                aria-label="More actions"
                                appearance="subtle"
                                icon={<MoreHorizontal20Regular />}
                            />
                        </Tooltip>
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>{menuItems}</MenuList>{' '}
                        {/**TODO : should change element array into react node with key  */}
                    </MenuPopover>
                </Menu>
            )}
        </>
    );
};

export const FlatTreeItemComponent: React.FC<{
    flatTreeItem: HeadlessFlatTreeItem<FlatTreeEntity>;
    icons: { [key: string]: JSX.Element } | undefined;
    entityActionsMap?: EntityActionsMapType | undefined;
    selectedItems?: Iterable<string> | undefined;
    searchString?: string;
    style?: React.CSSProperties;
    itemInFocus?: string;
    setItemInFocus?: (item: string) => void;
}> = ({ flatTreeItem, entityActionsMap, selectedItems, icons, searchString, style, itemInFocus, setItemInFocus }) => {
    const focusTargetAttribute = useRestoreFocusTarget();

    const { content, ...treeItemProps } = flatTreeItem.getTreeItemProps();
    const { id, status, error, entityType, docstring, root, hasDivider, ...treeProps } = treeItemProps;
    const entity: FlatTreeEntity = {
        id,
        status,
        error,
        entityType,
        docstring,
        content,
        root,
        hasDivider,
        ...treeProps,
    };
    const actions: ActionMenuItemType[] = entityActionsMap?.[entityType]?.menuItems ?? [];
    const asideItems: ActionMenuItemType[] = entityActionsMap?.[entityType]?.asideItems ?? [];
    const hasActions = actions.length > 0 || asideItems.length > 0;
    const aside: JSX.Element[] | undefined = asideItems.length ? getAsideItems(entity, asideItems) : undefined;
    const menuItems: JSX.Element[] | undefined = actions.length ? getMenuItems(entity, actions) : undefined;
    const isSelected = Array.from(selectedItems ?? []).includes(entity.value);

    const icon: JSX.Element | null | undefined = icons?.[entityType];
    const expandIcon: Slot<'div'> | undefined = entity.error ? (
        <Tooltip content={entity.error} withArrow relationship="label">
            <ErrorCircleFilled primaryFill={tokens.colorPaletteRedForeground1} />
        </Tooltip>
    ) : entity.status === 'FetchNotStarted' ? (
        <ArrowCounterclockwiseDashes20Regular />
    ) : undefined;

    const treeItemStyles = useStyles();

    const description = treeProps.description && (
        <div role="contentinfo" className={styles.description}>
            {treeProps.description}
        </div>
    );

    const RenderTreeItem = (
        <TreeItem
            onFocus={() => setItemInFocus?.(entity.value)}
            aria-describedby={isSelected ? 'selected tree item' : undefined}
            aria-label={entity.content}
            className={mergeClasses(
                hasDivider && styles.treeItem__dividerAbove,
                !!itemInFocus ? treeItemStyles.treeItemWithFocus : treeItemStyles.treeItemWithoutFocus,
                itemInFocus === entity.value ? styles.selectedItem : styles.notSelectedTreeItem
            )}
            {...focusTargetAttribute}
            {...(treeProps as ITreeProps)}
            // This is crucial for the tree virtualization to work properly
            style={style}
        >
            <TreeItemLayout
                main={{ className: styles.treeItemLayoutMain }}
                style={
                    expandIcon
                        ? {
                              paddingLeft: (Number(treeProps['aria-level']) - 1) * 24,
                          }
                        : entity.entityType === 'Label'
                        ? { paddingLeft: '0px' }
                        : {}
                }
                className={mergeClasses(
                    styles.treeItemLayout,
                    isSelected ? styles.selectedTreeItem : styles.hoverTreeItem,
                    styles.treeLayoutCursor,
                    entity.entityType === 'Label' && styles.unHoverable
                )}
                expandIcon={expandIcon}
                iconBefore={
                    icon ? (
                        <div className={styles.treeItemIconBefore} title={entity.entityType}>
                            {statusRequiresSpinner.includes(entity.status) ? <Spinner size="tiny" /> : icon}
                        </div>
                    ) : null
                }
                actions={hasActions ? <Actions docstring={docstring} menuItems={menuItems} aside={aside} /> : undefined}
            >
                <OverflowTooltip content={content} withArrow>
                    {/* To make the text un-selectable */}
                    <span className={styles.unSelectable} style={treeProps?.treeItemStyle}>
                        {searchString && entity.content && !entity.notSearchable
                            ? getMarkedText(entity.content, searchString)
                            : content}
                    </span>
                </OverflowTooltip>
                {description}
            </TreeItemLayout>
        </TreeItem>
    );

    return menuItems?.length ? (
        <Menu key={`${flatTreeItem.value}Menu`} openOnContext>
            <MenuTrigger disableButtonEnhancement>{RenderTreeItem}</MenuTrigger>
            <MenuPopover>
                <MenuList>{menuItems}</MenuList>
            </MenuPopover>
        </Menu>
    ) : (
        RenderTreeItem
    );
};
