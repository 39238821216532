import React from 'react';
import {
    MenuButton as FluentMenuButton,
    MenuButtonProps as FluentMenuButtonProps,
    MenuItemProps as FluentMenuItemProps,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuProps,
    MenuTrigger,
} from '@fluentui/react-components';

export type MenuItemProps = Omit<FluentMenuItemProps, 'hasSubmenu'> & {
    text?: string;
    subItems?: MenuItemProps[];
    renderContent?: (menuItem: MenuItemProps) => React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
};
export type MenuButtonProps = Omit<MenuProps, 'children'> & {
    key?: string;
    items?: MenuItemProps[];
    text?: string;
    buttonProps?: FluentMenuButtonProps;
    title?: string;
    className?: string;
    ['data-testid']?: string;
};
function renderMenuItem(props: MenuItemProps, key: string | number) {
    const { text = '', subItems = [], renderContent, ...fluentMenuItemProps } = props;
    const id = `menu-item-${key}`;
    return subItems.length ? (
        <Menu key={id}>
            <MenuTrigger disableButtonEnhancement>
                <MenuItem {...fluentMenuItemProps}>{renderContent?.(props) ?? text}</MenuItem>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {subItems.map((props: MenuItemProps, key: number) => {
                        return renderMenuItem(props, `${id}-${key}`);
                    })}
                </MenuList>
            </MenuPopover>
        </Menu>
    ) : (
        <MenuItem key={id} {...fluentMenuItemProps}>
            {renderContent?.(props) ?? text}
        </MenuItem>
    );
}
export const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(
    (
        { text = '', items = [], buttonProps = {}, className, ['data-testid']: dataTestId, title, ...menuProps },
        forwardRef
    ) => {
        return (
            <Menu {...menuProps}>
                <MenuTrigger disableButtonEnhancement>
                    <FluentMenuButton
                        className={className}
                        ref={forwardRef}
                        data-testid={dataTestId}
                        title={title}
                        {...buttonProps}
                    >
                        {text ?? ''}
                    </FluentMenuButton>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>{items.map(renderMenuItem)}</MenuList>
                </MenuPopover>
            </Menu>
        );
    }
);
