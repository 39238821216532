import type { Initialization as AppInsightsInitialization } from '@microsoft/applicationinsights-web/types/Initialization';
import type * as H from 'history';

import type { IFeatureFlagsService } from '@kusto/app-common';
import type { KustoDomains } from '@kusto/client';
import type { QueryResultStore, QueryStrings } from '@kusto/query';
import { Account, IKweTelemetry, KweException } from '@kusto/utils';

import type { IAuthProvider } from './AuthenticationProviders/IAuthProvider';
import type { AppQueryParams } from './core/appQueryParams';
import type { FeatureFlag } from './core/FeatureFlags';
import type { AppStrings, KweStrings } from './locale';
import type { LocalStorageKeys } from './stores/localStorageHelper';
import type { IIframeTrustedDomains } from './utils/IFrame/IFrameCommunication';
import type { LoginManager } from './utils/Login/loginManager';
import type { QueryDeepLinkProperties } from './utils/queryPageLinkParser';

/**
 * Initialization code is kept elsewhere to avoid circular dependencies
 */

export interface BootstrapDependencies {
    readonly kustoDomains: KustoDomains;
    readonly appInsights: AppInsightsInitialization;
    readonly telemetry: IKweTelemetry;
    readonly authProvider: IAuthProvider;
    readonly queryDeepLinkProperties: QueryDeepLinkProperties;
    readonly queryResultStore: QueryResultStore;
    readonly sessionId: string;
    readonly localStorageKey: string;
    readonly history: H.History;
    /**
     * @deprecated Use KweCore.strings instead
     */
    strings: KweStrings;
    /**
     * @deprecated Use KweCore.strings instead
     */
    queryStrings: QueryStrings;
    readonly featureFlags: IFeatureFlagsService<FeatureFlag>;
    readonly urlTheme: undefined | string;
    readonly urlLanguage: undefined | string;
    readonly loginManager?: LoginManager;
    readonly setUserSpecificDependencies: (account: Account) => void;
    readonly appSearch: AppQueryParams;
    readonly iframeTrustedDomains: IIframeTrustedDomains;
    readonly localStorageKeys: LocalStorageKeys;

    /**
     * Warnings created while initializing the app. Saved here so they can be
     * displayed all-at-once, once the UX is ready.
     */
    readonly warnings: ReadonlyArray<(strings: AppStrings) => string>;
}

export function setDependencies(d: BootstrapDependencies) {
    if (dependencies !== undefined) {
        throw new KweException('dependencies already initialized');
    }
    dependencies = d;
}

/**
 * @deprecated Avoid in favor of `useKweCore()` in React or `getKweEnv(self)` in
 * mobx-state-tree
 */
export let dependencies: BootstrapDependencies = undefined as unknown as BootstrapDependencies;
