import React from 'react';
import { Button, Caption1, Caption1Strong, Tooltip } from '@fluentui/react-components';
import { Add16Regular, CircleFilled, Copy16Regular } from '@fluentui/react-icons';
import * as clipboard from 'clipboard-polyfill';

import { useStrings } from '../../../../hooks/useStrings';

import styles from './AddFilterTooltip.module.scss';

export const AddFilterTooltipContent: React.FC<{
    value?: number;
    color: string;
    label: string;
    addAsFilter: () => void;
}> = ({ color, label, value, addAsFilter }) => {
    const { strings } = useStrings();

    const copyValue = React.useCallback(() => {
        const dt = new clipboard.DT();
        dt.setData('text/plain', label);
        clipboard.write(dt);
    }, [label]);

    return (
        <div className={styles.container}>
            <div className={styles.pointInfo}>
                <div className={styles.pointName}>
                    <CircleFilled style={{ color: color }} />
                    <Caption1>{label}</Caption1>
                </div>
                {value !== undefined ? <Caption1Strong>{value}</Caption1Strong> : null}
            </div>
            <div className={styles.pointActions}>
                <Button appearance="subtle" onClick={() => addAsFilter()} icon={<Add16Regular />}>
                    {strings.dataExplorationModal.schemaColumns.columnInfo.addAsFilter}
                </Button>
                <Tooltip
                    content={strings.dataExplorationModal.schemaColumns.columnInfo.copyToClipboard}
                    relationship="label"
                >
                    <Button onClick={copyValue} appearance="subtle" icon={<Copy16Regular />} />
                </Tooltip>
            </div>
        </div>
    );
};
