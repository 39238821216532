import React from 'react';
import { Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';

import { dependencies } from '../../dependencies';

interface Props {
    message: string;
    title: string;
    onClicked: () => void;
}

export const LoginErrorDialog: React.FC<Props> = (props: Props) => {
    const modalProps = {
        titleAriaId: props.message,
        subtitleAriaId: props.title,
        isBlocking: true,
    };

    const dialogContentProps = {
        title: props.title,
        subText: props.message,
    };

    return (
        <Dialog hidden={false} modalProps={modalProps} dialogContentProps={dialogContentProps}>
            <DialogFooter>
                <PrimaryButton onClick={props.onClicked} text={dependencies.strings.kwe$loginErrorDialog$buttonText} />
            </DialogFooter>
        </Dialog>
    );
};
