import * as React from 'react';
import { mergeCallbacks, useId, useEventCallback, slot, isResolvedShorthand, useMergedRefs } from '@fluentui/react-utilities';
import { Listbox } from '../Listbox';
/**
 * @internal
 * @returns  listbox slot with desired behaviour and props
 */ export function useListboxSlot(listboxSlotFromProp, ref, options) {
    const { state: { multiselect }, triggerRef, defaultProps } = options;
    const listboxId = useId('fluent-listbox', isResolvedShorthand(listboxSlotFromProp) ? listboxSlotFromProp.id : undefined);
    const listboxSlot = slot.optional(listboxSlotFromProp, {
        renderByDefault: true,
        elementType: Listbox,
        defaultProps: {
            id: listboxId,
            multiselect,
            tabIndex: undefined,
            ...defaultProps
        }
    });
    /**
   * Clicking on the listbox should never blur the trigger
   * in a combobox
   */ const onMouseDown = useEventCallback(mergeCallbacks((event)=>{
        event.preventDefault();
    }, listboxSlot === null || listboxSlot === void 0 ? void 0 : listboxSlot.onMouseDown));
    const onClick = useEventCallback(mergeCallbacks((event)=>{
        var _triggerRef_current;
        event.preventDefault();
        (_triggerRef_current = triggerRef.current) === null || _triggerRef_current === void 0 ? void 0 : _triggerRef_current.focus();
    }, listboxSlot === null || listboxSlot === void 0 ? void 0 : listboxSlot.onClick));
    const listboxRef = useMergedRefs(listboxSlot === null || listboxSlot === void 0 ? void 0 : listboxSlot.ref, ref);
    if (listboxSlot) {
        listboxSlot.ref = listboxRef;
        listboxSlot.onMouseDown = onMouseDown;
        listboxSlot.onClick = onClick;
    }
    return listboxSlot;
}
