import { SetStateAction, useRef, useState } from 'react';

export type PullStateReturn<T> = readonly [T, (state: SetStateAction<T>) => void, () => T];

/**
 * Like `useState`, but with an additional `getState` function returned.
 *
 * Having a `getState` function is important to avoid the stale closure problem.
 * That's to say, a callback shouldn't rely on state on the `state` returned
 * from `useState` most of the time, because it may not be the latest state
 * value.
 *
 * Important: You should only use this IF you need to access state inside
 * an async callback. You don't need this if you're inside a sync callback.
 *
 * @example
 * Bad:
 *
 * function onClick() {
 *   const state = getState(); // This isn't helpful so just access the state directly
 *   showNotification(`${state.numOfUnread} messages unread`);
 * }
 *
 * Bad (fixed):
 *
 * function onClick() {
 *   showNotification(`${state.numOfUnread} messages unread`);
 * }
 *
 *
 * Good:
 *
 * async function onSubmit() {
 *   const config = await api.get('/config');
 *   // This is only a problem if it occurs after an await point. Even if the function is async, it won't pause until we use the await keyword.
 *   const state = getState();
 *   const data = config.isInternalTenant ? state.internalData : state.externalData;
 *   await api.post('/submit', { data });
 * }
 *
 */
export function usePullState<T>(initialState: T | (() => T)): [T, (state: SetStateAction<T>) => void, () => T] {
    const [reactiveState, setReactiveState] = useState(initialState);
    const ref = useRef<{
        state: T;
        getState: () => T;
        setState: (state: SetStateAction<T>) => void;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }>(undefined as any);

    if (ref.current === undefined) {
        ref.current = {
            state: reactiveState,
            getState: () => ref.current.state,
            setState: (state) => {
                if (state instanceof Function) {
                    state = state(ref.current.state);
                }

                ref.current.state = state;
                setReactiveState(state);
            },
        };
    }

    return [reactiveState, ref.current.setState, ref.current.getState];
}
