import type { Locale as DateFnsLocale } from 'date-fns';

// Suppress error related to importing outside of root dir.
// https://stackoverflow.com/questions/55753163/package-json-is-not-under-rootdir
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type KweUtilsStrings = typeof import('../strings.json');

/**
 * Locale base for the repo. Other packages Locale interfaces should extend
 * this.
 */
export interface KweUtilsLocale {
    readonly utils: KweUtilsStrings;
    readonly dateFns: undefined | DateFnsLocale;
    readonly locale: undefined | Locale;
}

export type KweUtilsStringSelector<T = string> = (strings: KweUtilsLocale) => T;

/**
 * Enumerates the supported themes in KustoWeb
 */
export enum Theme {
    Light = 'Light',
    Dark = 'Dark',
}

export type MonacoEditorTheme = 'kusto-dark' | 'kusto-light';

/**
 * Locale tag like "en" or "fr" that's used for
 * i18n within dashboards. Mostly used for dates
 * by way of Intl.DateTimeFormat. Can be
 * `undefined` which means it will resort
 * to the browser default locale tag.
 *
 * @see http://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
 */
export type Locale = undefined | string;

export type Mutable<T> = {
    -readonly [P in keyof T]: T[P];
};

/**
 * The Account type is the same as AccountInfo from msal-browser library (omitting idTokenClaims and environment):
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/src/account/AccountInfo.ts
 *
 * Q: Why not using `Omit<AccountInfo, "idTokenClaims" | "environment">`?
 * A: To skip the need to reference msal-common from common library just for this type.
 */
export interface Account {
    readonly homeAccountId: string;
    readonly tenantId: string;
    /** preferred_username claim of the id_token that represents this account. Might be an email or even a phone number. */
    readonly username: string;
    readonly localAccountId: string;
    /** Full name for the account, including given name and family name */
    readonly name?: string;
}

/**
 * Extracts public interface from class. Using interfaces instead of classes
 * allows for easier mocking.
 */
export type InterfaceFor<C> = {
    [Member in keyof C]: C[Member];
};

export type ReadonlyRecord<K extends string | number | symbol, T> = {
    readonly [P in K]: T;
};

export type Dispose = () => void;
