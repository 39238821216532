import React from 'react';
import { OptionOnSelectData, SelectionEvents } from '@fluentui/react-combobox';

import { DropdownValue, DropdownWithSearchOption, OverrideInputProps, SelectedOption } from '../types.ts';
import { useListBoxResize } from './useListBoxResize.ts';
import { useSearchOptions } from './useSearchOptions.ts';

export function updateValues(value: DropdownValue) {
    if (!value) {
        return [''];
    }
    return Array.isArray(value) ? value : [value];
}

export function updateDefaultValues(value: DropdownValue) {
    const updateValue = updateValues(value);
    return updateValue.length === 1 && updateValue[0] === '' ? undefined : updateValue;
}

export function useDropdown(
    options: DropdownWithSearchOption[],
    selectedKeys: DropdownValue,
    defaultSelectedKeys: DropdownValue,
    onChange?: (ev: SelectionEvents, data: SelectedOption) => void,
    multiselect?: boolean,
    onDismiss?: () => void,
    overrideInputProps?: OverrideInputProps
) {
    const [open, setOpen] = React.useState<boolean | null>(null);
    const [query, setQuery] = React.useState<string>('');
    const [uncontrolledSelectedKeys, setUncontrolledSelectedKeys] = React.useState<string[] | undefined>(
        updateDefaultValues(defaultSelectedKeys)
    );

    const controlledSelectedKeys = React.useMemo(() => {
        // The component considered to be controlled only if "selectedKeys" were passed and "defaultSelectedKeys" is not defined
        return !defaultSelectedKeys && selectedKeys !== undefined ? updateValues(selectedKeys) : undefined;
    }, [defaultSelectedKeys, selectedKeys]);

    const comboboxRef = React.useRef<HTMLInputElement>(null);
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const listBoxRef = React.useRef<HTMLInputElement>(null);
    const popoverRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const filteredOptions = useSearchOptions(options, query, !!overrideInputProps);

    const showSearch = useListBoxResize(
        options,
        filteredOptions,
        listBoxRef,
        popoverRef,
        buttonRef,
        inputRef,
        open ?? false,
        query,
        !!overrideInputProps
    );

    /* Accessibility - on open the focus should be on the input, on close on the button.
     The null check condition is to prevent taking the focus on render */
    React.useEffect(() => {
        if (open === null) {
            return;
        }

        if (open) {
            listBoxRef.current?.click();
        } else {
            buttonRef.current?.focus();
        }
    }, [open]);

    const handleOpenChanged = (isOpen: boolean) => {
        setOpen(isOpen);
        if (!isOpen) {
            setQuery('');
            onDismiss?.();
        } else if (overrideInputProps?.value) {
            // Show override input default value on open (if exists)
            setQuery(overrideInputProps.value);
        }
    };

    const handleOptionSelected = (e: SelectionEvents, data: OptionOnSelectData) => {
        if (data.optionValue) {
            const optionData = options.filter((option) => option.key === data.optionValue)[0]?.data;
            const isSelected = data.selectedOptions.includes(data.optionValue);
            setUncontrolledSelectedKeys(data.selectedOptions);
            if (!multiselect) {
                setOpen(false);
                setQuery('');
            }
            onChange?.(e, {
                ...data,
                key: data.optionValue,
                text: data.optionText,
                data: optionData,
                selected: isSelected,
            });
            if (!multiselect) {
                // Should occur after onChange
                onDismiss?.();
            }
        }
    };

    /** Closes the dropdown on ESC and prevents selection with SPACE.
        We're getting all the accessibility keys functionality for "free" from Fleunt's Combobox.
        The edge cases where the popover-surface and the combobox collide are these two keys, necessitating intervention:
        Trigger close on ESC and allow the user to enter spaces within the input without triggering a selection. */
    const inputAccessibilityHandleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (overrideInputProps) {
            overrideInputProps.onKeyDownCapture(ev);
        }

        if (ev.key === 'Escape') {
            handleOpenChanged(false);
            comboboxRef.current?.blur();
        } else if (ev.key === ' ') {
            ev.stopPropagation();
        }
    };

    /** Given that the dropdown menu is a popover, this adjustment is necessary
     * to open the dropdown using the Enter/Up/Down arrow keys. Space key is already handled. */
    const fieldAccessibilityHandleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (!open && (ev.key === 'ArrowUp' || ev.key === 'ArrowDown' || ev.key === 'Enter')) {
            handleOpenChanged(true);
        }
    };

    /** Close the dropdown on tab out of the component */
    const tabHandleKeyUp = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === 'Tab') {
            handleOpenChanged(false);
        }
    };

    return {
        open: open ?? false,
        query,
        controlledSelectedKeys,
        uncontrolledSelectedKeys,
        buttonRef,
        popoverRef,
        comboboxRef,
        listBoxRef,
        inputRef,
        setQuery,
        handleOpenChanged,
        handleOptionSelected,
        inputAccessibilityHandleKeyDown,
        fieldAccessibilityHandleKeyDown,
        tabHandleKeyUp,
        showSearch,
        filteredOptions,
    };
}
