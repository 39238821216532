import type { CellClickedEvent } from '@ag-grid-community/core';

import type * as client from '@kusto/client';
import { ok } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';

import type { RtdProviderLocale } from '../../i18n';
import type { RtdDataVisualProps } from './model';
import { ErrorStrings, extractRtdValue } from './util';

/**
 * column => click handler
 */
type QueryResultGridCrossFilter = Record<string, ((event: CellClickedEvent) => void)[]>;

export function createCrossFilterConfig(
    columns: readonly client.KustoColumn[],
    visualOptions: RtdDataVisualProps['visualOptions'],
    dashboardsApi: undefined | Fwk.DashboardVisualApi,
    strings: RtdProviderLocale
): undefined | QueryResultGridCrossFilter {
    const crossFilterConfigs = visualOptions.crossFilterDisabled ? null : visualOptions.crossFilter;
    const crossFilter = dashboardsApi?.crossFilter;
    if (!crossFilterConfigs || !crossFilter) {
        return;
    }
    const config: QueryResultGridCrossFilter = {};

    const errorStrings: ErrorStrings = {
        noColumn: strings.rtdProvider.visuals.crossFilter$errors$noColumn,
        missingColumn: strings.rtdProvider.visuals.crossFilter$errors$missingColumn,
    };

    for (const c of crossFilterConfigs) {
        if (Fwk.crossFilterIsReady(c) && c.interaction === 'column') {
            let callbacks = config[c.property];
            if (callbacks === undefined) {
                callbacks = [];
                config[c.property] = callbacks;
            }
            const parameterId = c.parameterId;
            callbacks.push((event: CellClickedEvent) => {
                const value = extractRtdValue(columns, event, errorStrings);
                const res = ok([ok({ parameterId, value })]);
                crossFilter?.(res);
            });
        }
    }
    if (Object.keys(config).length === 0) {
        return undefined;
    }

    return config;
}
