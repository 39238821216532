import { extractErrorDescription, type DataSetCompletion, type DataTable, type ExecutionResult } from '@kusto/client';
import { KweException } from '@kusto/utils';

export function getV2PrimaryResult(res: ExecutionResult<'v2'>): DataTable {
    const dataTable = res.apiCallResult.find(
        (frame): frame is DataTable => frame.FrameType === 'DataTable' && frame.TableKind === 'PrimaryResult'
    );

    if (!dataTable) {
        throw new KweException('Unable to locate datatable from kusto results');
    }

    return dataTable;
}

export function throwOnV2Error(res: ExecutionResult<'v2'>): void {
    const dataSetCompletion = res.apiCallResult.find((frame) => frame.FrameType === 'DataSetCompletion');

    if (dataSetCompletion && (dataSetCompletion as DataSetCompletion).HasErrors) {
        const oneApiErrors = (dataSetCompletion as DataSetCompletion).OneApiErrors;
        if (oneApiErrors !== undefined && oneApiErrors.length !== 0 && oneApiErrors[0].error !== undefined) {
            const errorDescription = extractErrorDescription(oneApiErrors[0].error);
            throw new Error(errorDescription.errorMessage);
        }
    }
}
