import * as mobx from 'mobx';

import {
    ABORTED,
    assertNever,
    AsyncResult,
    err,
    IKweTelemetry,
    isAbortError,
    LOADING,
    Loading,
    Mutable,
    ok,
    ReadonlyRecord,
    Result,
} from '@kusto/utils';

import type { KweVisualFwkStringSelector } from './types';
import type { ResolvedVisualTypeConfig, TileSize, VisualConfig, VisualTypeConfig } from './visualConfig';
import type { VisualOptionKey, VisualOptionProperties } from './visualOptions';

export class InternalParsedVisual<C extends VisualOptionKey = VisualOptionKey, H = unknown> {
    constructor(private readonly _config: ResolvedVisualTypeConfig<C, H>) {}

    minimumSize(options: Pick<VisualOptionProperties, C>): TileSize {
        return typeof this._config.minimumSize === 'function'
            ? this._config.minimumSize(options)
            : this._config.minimumSize;
    }
    defaultSize(options: Pick<VisualOptionProperties, C>): TileSize {
        const defaultSize = this._config.defaultSize;
        switch (typeof defaultSize) {
            case 'undefined':
                return this.minimumSize(options);
            case 'function':
                return defaultSize(options);
            case 'object':
                return defaultSize;
            default:
                assertNever(defaultSize);
        }
    }

    get model() {
        return this._config.model;
    }

    get inputLayout() {
        return this._config.inputLayout;
    }

    get heuristics() {
        return this._config.heuristics;
    }

    get Component() {
        return this._config.Component;
    }
}

export interface IParsedVisualType<C extends VisualOptionKey = VisualOptionKey, H = unknown> {
    readonly label: string;
    readonly iconName?: string;
    readonly config: Loading | Result<InternalParsedVisual<C, H>, KweVisualFwkStringSelector>;
    readonly promise: AsyncResult<InternalParsedVisual<C, H>, KweVisualFwkStringSelector>;
}

// TODO: Figure out what's going on with type errors that occur if we remove
// the generics
//  eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ParsedVisuals = Partial<ReadonlyRecord<string, IParsedVisualType<any, any>>>;

interface Data<C extends VisualOptionKey = VisualOptionKey, H = unknown> {
    readonly config: Loading | Result<InternalParsedVisual<C, H>, KweVisualFwkStringSelector>;
    readonly promise: AsyncResult<InternalParsedVisual<C, H>, KweVisualFwkStringSelector>;
}

class ParsedVisualType<C extends VisualOptionKey = VisualOptionKey, H = unknown> implements IParsedVisualType<C, H> {
    private _data: undefined | Data<C, H>;

    readonly label: string;
    readonly iconName: undefined | string;

    constructor(
        readonly key: string,
        private readonly hostConfig: VisualTypeConfig<C, H>,
        private readonly telemetry: IKweTelemetry
    ) {
        this.iconName = this.hostConfig.iconName;
        this.label = hostConfig.label;
    }

    private init() {
        let data: Mutable<Data<C, H>>;
        if (typeof this.hostConfig.config === 'object') {
            const config: Data<C, H>['config'] = ok(new InternalParsedVisual(this.hostConfig.config));
            data = { config, promise: Promise.resolve(config) };
        } else {
            const config: Data<C, H>['config'] = LOADING;
            const promise: Data<C, H>['promise'] = this.hostConfig.config().then(
                (v) => ok(new InternalParsedVisual(v)),
                (e) => {
                    if (isAbortError(e)) {
                        return ABORTED;
                    }

                    this.telemetry.exception('Failed to fetch visual config', { exception: e });

                    return err<KweVisualFwkStringSelector>((t) => t.utils.util.error.unidentifiedErrorMessage);
                }
            );
            data = { config, promise };
            mobx.makeObservable(data, {
                config: mobx.observable.ref,
            });
            promise.then(
                mobx.action((v) => {
                    if (v.kind !== 'abort') {
                        data.config = v;
                    }
                })
            );
        }

        this._data = data;

        return this._data;
    }

    get config() {
        return (this._data ?? this.init()).config;
    }

    get promise() {
        return (this._data ?? this.init()).promise;
    }
}

export function parseVisuals(visuals: VisualConfig['visuals'], telemetry: IKweTelemetry): ParsedVisuals {
    const parsed: Mutable<ParsedVisuals> = {};

    for (const [key, value] of Object.entries(visuals)) {
        parsed[key] = new ParsedVisualType(key, value, telemetry);
    }

    return parsed;
}
