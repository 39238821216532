/* eslint-disable @typescript-eslint/no-redeclare */
import { Instance, types } from 'mobx-state-tree';

import * as kusto from '@kusto/client';
import { normalizeSpaces } from '@kusto/utils';
import {
    ColumnFormatting,
    ExtendedVisualizationOptions,
    TableVisualizationOptionsSnapshot,
    VisualizationOptions,
} from '@kusto/visualizations';

import { isClickable, timespanInMilliseconds } from '../utils';
import { abbreviateAndLocalizeNumber } from '../utils/numbers';
import { assess, LoadAssessment, localizeLoadAssessment } from '../utils/queryStatisticsUtils';
import { getQueryStoreEnv } from './storeEnv';

/**
 * An ok string hashing function.
 * @param str input string
 * @param enable continues hashing by passing previous sub string hash
 */
const stringHashCode = function (str: string, progressive = 0): number {
    let hash = progressive;
    if (str.length === 0) {
        return hash;
    }
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
};

/**
 * Contains all relevant data for a kusto query / command execution.
 */
export const RequestInfo = types
    .model('RequestInfo', {
        url: types.string,
        dbName: types.string,
        queryText: types.maybeNull(types.string),
        timeStarted: types.Date,
    })
    .actions((self) => ({
        setTimeStarted(time: Date) {
            self.timeStarted = time;
        },
    }))
    .views((self) => ({
        get normalizedQueryText() {
            return self.queryText ? normalizeSpaces(self.queryText) : null;
        },
    }))
    .views((self) => ({
        get hashCode() {
            return stringHashCode(self.url.concat(self.dbName).concat(self.normalizedQueryText || ''));
        },
    }));
// eslint-disable-next-line no-redeclare
export type RequestInfo = typeof RequestInfo.Type;

export const TableResult = types
    .model('TableResult', {
        rows: types.frozen<null | readonly kusto.KustoQueryResultRowObject[]>(),
        columns: types.frozen<null | readonly kusto.KustoColumn[]>(),
        visualizationOptions: types.maybeNull(types.frozen<VisualizationOptions>()),
        tableName: types.maybeNull(types.string),
    })
    .views((self) => ({
        get isChart() {
            return (
                self.visualizationOptions &&
                self.visualizationOptions.Visualization &&
                self.visualizationOptions.Visualization !== 'table'
            );
        },
    }))
    .views((self) => {
        let _columnHash: number | undefined;
        return {
            // Lazy calculation of column hash and cache it
            get columnsHash() {
                if (_columnHash === undefined) {
                    _columnHash =
                        self.columns && self.columns.length > 0
                            ? self.columns
                                  .slice(0, 100)
                                  .reduce(
                                      (hash, col) =>
                                          stringHashCode(
                                              col.headerName,
                                              stringHashCode(col.columnType || col.dataType || '', hash)
                                          ),
                                      0
                                  )
                            : -1;
                }

                return _columnHash!;
            },
        };
    });

// eslint-disable-next-line no-redeclare
// eslint-disable-next-line @typescript-eslint/no-empty-interface -- typescript recommends using interfaces rather than types.
export interface TableResult extends Instance<typeof TableResult> {}
export type QueryCompletionRows = typeof TableResult.Type.rows;
export type QueryCompletionColumns = typeof TableResult.Type.columns;
export type QueryCompletionColumn = kusto.KustoColumn;
export interface MultiTableResult extends TableResult {
    queryIndex: number;
}

/**
 * Represents a response from kusto in a way that's easily digestible for display.
 * This contains the metadata but not the response data itself.
 */
export const QueryCompletionInfo = types
    .model('QueryCompletionInfo', {
        id: types.maybe(types.identifierNumber),
        request: RequestInfo,
        timeEnded: types.Date,
        isSuccess: types.boolean,
        haveCachedResults: types.optional(types.boolean, true),
        failureReason: types.frozen(),
        errorDescription: types.maybeNull(types.frozen<kusto.KustoClientErrorDescription>()),
        clientActivityId: types.string,
        queryResourceConsumption: types.maybeNull(types.frozen<kusto.QueryResourceConsumptionData>()),
        resultInContext: types.optional(types.string, '0'),
    })
    .views((self) => ({
        get executionTimeInMilliseconds() {
            return (
                (self.request &&
                    self.request.timeStarted &&
                    self.timeEnded.getTime() - self.request.timeStarted.getTime()) ||
                null
            );
        },
        /**
         * the index of the tab, in the action bar, that is currently selected (Table <x>, Graph, Stats)
         */
        get resultIndex() {
            return parseInt(self.resultInContext, 10);
        },
        /**
         * Gets total cpu time in the format HH:mm:ss. Default: 00:00:00
         */
        get totalCPU(): string {
            return self.queryResourceConsumption?.resource_usage?.cpu?.['total cpu'] ?? '00:00:00';
        },
    }))
    .actions((self) => ({
        setResultInContext(itemKey: string) {
            self.resultInContext = itemKey;
        },
        noCachedResults: () => (self.haveCachedResults = false),
    }))
    .views((self) => ({
        /**
         * Gets the total cpu time in milliseconds. Total time took to all CPUs to run the query.
         */
        get totalCPUInMilliseconds(): number {
            return timespanInMilliseconds(self.totalCPU);
        },
        /**
         * Gets peak per node memory, with an abbreviated unit. e.g. 12 B, 1.06 MB
         */
        get peakPerNodeMemory(): string {
            if (!self.queryResourceConsumption) return '0';
            return abbreviateAndLocalizeNumber(
                self.queryResourceConsumption.resource_usage?.memory?.peak_per_node ?? 0,
                getQueryStoreEnv(self).strings.locale
            );
        },
        /**
         * Gets a sum of rows from all the datasets.
         */
        get numberOfRowsInDatasets(): number {
            if (!self.queryResourceConsumption) return 0;
            return (
                self.queryResourceConsumption.dataset_statistics?.reduce(
                    (sum: number, stat: kusto.DatasetStatistic) => (sum += stat.table_row_count ?? 0),
                    0
                ) ?? 0
            );
        },
        /**
         * Gets the size in byes of all the datasets returned.
         */
        get sizeOfDatasets(): number {
            if (!self.queryResourceConsumption) return 0;
            return (
                self.queryResourceConsumption.dataset_statistics?.reduce(
                    (sum: number, stat: kusto.DatasetStatistic) => (sum += stat.table_size ?? 0),
                    0
                ) ?? 0
            );
        },
        /**
         * Gets the bytes that were returned from both the cold and the hot cache.
         */
        get shardDiskHitsInBytes(): number {
            if (!self.queryResourceConsumption) return 0;
            const diskHits: number = self.queryResourceConsumption.resource_usage?.cache?.disk?.hits ?? 0;
            const coldHitBytes: number =
                self.queryResourceConsumption.resource_usage?.cache?.shards?.cold?.hitbytes ?? 0;
            const hotHitBytes: number = self.queryResourceConsumption.resource_usage?.cache?.shards?.hot?.hitbytes ?? 0;
            return diskHits * (64 << 10) + coldHitBytes + hotHitBytes;
        },
        /**
         * Gets the bytes that couldn't return from the cache.
         */
        get shardDiskMissesInBytes(): number {
            if (!self.queryResourceConsumption) return 0;
            const diskMisses: number = self.queryResourceConsumption.resource_usage?.cache?.disk?.misses ?? 0;
            const coldMissBytes: number =
                self.queryResourceConsumption.resource_usage?.cache?.shards?.cold?.missbytes ?? 0;
            const hotMissBytes: number =
                self.queryResourceConsumption.resource_usage?.cache?.shards?.hot?.missbytes ?? 0;

            return diskMisses * (64 << 10) + coldMissBytes + hotMissBytes;
        },
        /**
         * Gets the bytes that were returned from the memory cache (only for v2 engines, for v3 will always be 0)
         */
        get cacheMemoryHits(): number {
            if (!self.queryResourceConsumption) return 0;
            return self.queryResourceConsumption.resource_usage?.cache?.memory?.hits ?? 0;
        },
        get shardDiskBypassBytes(): number {
            return self.queryResourceConsumption?.resource_usage?.cache?.shards?.bypassbytes ?? 0;
        },
        /**
         * Gets the bytes that were not returned from the memory cache (only for v2 engines, for v3 will always be 0)
         */
        get cacheMemoryMisses(): number {
            return self.queryResourceConsumption?.resource_usage?.cache?.memory?.misses ?? 0;
        },
        get externalDownloadedSize(): number {
            return self.queryResourceConsumption?.input_dataset_statistics?.external_data?.downloaded_bytes ?? 0;
        },
        get externalDownloadedFiles(): number {
            return self.queryResourceConsumption?.input_dataset_statistics?.external_data?.downloaded_items ?? 0;
        },
        get metadataAsJson(): string {
            return JSON.stringify(self.queryResourceConsumption);
        },
    }))
    .views((self) => {
        const env = getQueryStoreEnv(self);
        return {
            /**
             * Gets cpu load load assessment. Return localized "Low", "Medium" (total CPU time > 1 minute),  "High" (total CPU time > 1 hour)
             */
            get cpuLoadLoadAssessment() {
                const medium = 60 * 1000; // > 1 minute is assessed as medium load;
                const high = 60 * 60 * 1000; // > 1 hour is assessed as high load
                const totalCpuTimeInSeconds = self.totalCPUInMilliseconds;
                return assess(env.strings.query, totalCpuTimeInSeconds, medium, high);
            },

            /**
             * Gets the level of parallelism. Return a localized string - "Low", "Medium" (#CPUs > 10) or "High" (#CPUs > 1000)
             */
            get parallelismLevel() {
                const executionTimeInMilliseconds = self.executionTimeInMilliseconds;
                if (executionTimeInMilliseconds && executionTimeInMilliseconds > 0) {
                    const medium = 10; // > 10 CPUs are assessed as medium
                    const high = 1000; // > 1000 CPUs are assessed as high
                    const numberOfCPUs = self.totalCPUInMilliseconds / executionTimeInMilliseconds;
                    return assess(env.strings.query, numberOfCPUs, medium, high);
                } else {
                    return localizeLoadAssessment(env.strings.query, LoadAssessment.Low);
                }
            },
            /**
             * Gets total data scanned with an abbreviated unit. e.g. 12 B, 1.06 MB
             */
            get totalDataScanned(): string {
                if (!self.queryResourceConsumption) return '0';
                const memoryBlocksTotalIn16BytesBlock =
                    self.queryResourceConsumption?.resource_usage?.cache?.memory?.total ?? 0;
                const totalBytesScanned =
                    memoryBlocksTotalIn16BytesBlock * (16 << 10) +
                    self.shardDiskHitsInBytes +
                    self.shardDiskMissesInBytes +
                    self.shardDiskBypassBytes;
                return abbreviateAndLocalizeNumber(totalBytesScanned, env.strings.locale);
            },
            /**
             * Gets total data scanned with an abbreviated unit. e.g. 12 B, 1.06 MB
             */
            get coldDataScanned(): string {
                if (!self.queryResourceConsumption) return '0';
                const diskBlocksTotalIn64BytesBlock =
                    self.queryResourceConsumption?.resource_usage?.cache?.disk?.total ?? 0;
                const diskBlocksHitsIn64BytesBlock =
                    self.queryResourceConsumption?.resource_usage?.cache?.disk?.hits ?? 0;
                const hotRetrieveBytes =
                    self.queryResourceConsumption?.resource_usage?.cache?.shards?.hot?.retrievebytes ?? 0;
                const coldRetrieveBytes =
                    self.queryResourceConsumption?.resource_usage?.cache?.shards?.cold?.retrievebytes ?? 0;
                const bypassBytes = self.queryResourceConsumption?.resource_usage?.cache?.shards?.bypassbytes ?? 0;
                const shardDiskRetrieveBytes = hotRetrieveBytes + coldRetrieveBytes + bypassBytes;

                const scannedDateInBytes =
                    (diskBlocksTotalIn64BytesBlock - diskBlocksHitsIn64BytesBlock) * (64 << 10) +
                    shardDiskRetrieveBytes;
                return abbreviateAndLocalizeNumber(scannedDateInBytes, env.strings.locale);
            },
            get externalSkippedFiles(): number {
                const iteratedArtifacts =
                    self.queryResourceConsumption?.input_dataset_statistics?.external_data?.iterated_artifacts ?? 0;
                if (iteratedArtifacts > 0) {
                    return iteratedArtifacts - self.externalDownloadedFiles;
                } else {
                    return 0;
                }
            },
        };
    });
// eslint-disable-next-line no-redeclare
export type QueryCompletionInfo = typeof QueryCompletionInfo.Type;

/**
 * Represents the results of a query. basically an array of tables with some convenience methods.
 */
export const QueryResults = types
    .model('QueryResults', {
        results: types.array(TableResult),
    })
    .volatile((_self) => ({
        visualAdded: false as boolean,
    }))
    .actions((self) => ({
        addVisual() {
            self.visualAdded = true;
        },
    }))
    .views((self) => ({
        /**
         * Add Column Formatting configuration into resultToDisplay.visualizationOptions and
         * return resultToDisplay as a POJO (plain old JavaScript object - instead of a mobx model).
         * Why POJO? As long as it is a mobx model visualizationOptions in resultToDisplay can't be changed into type ExtendedVisualizationOptions.
         */
        pojoResultWithColumnFormatting(
            resultToDisplay: MultiTableResult,
            enableClickableLinks: boolean
        ): MultiTableResult {
            const columnFormatting: ColumnFormatting = {
                LinkConfig: {
                    renderAsLink: (column, rowData): undefined | string => {
                        if (!enableClickableLinks) {
                            return;
                        }

                        const maybeUrl = rowData[column];
                        if (typeof maybeUrl !== 'string') {
                            return;
                        }

                        const maybeUrlTrimmed = maybeUrl.trim();

                        // This blindly enables all columns
                        // as clickable IF the `maybeUrl`
                        // will validate as a clickable link
                        if (isClickable(maybeUrlTrimmed)) {
                            return maybeUrlTrimmed;
                        }
                    },
                },
                ConditionalFormattingConfig: undefined /* currently only used in dashboards */,
            };

            const visualizationOptions: ExtendedVisualizationOptions = Object.assign(
                {},
                resultToDisplay.visualizationOptions,
                { ColumnFormatting: columnFormatting }
            );

            const resultToDisplayAsPlainObject = Object.assign({}, resultToDisplay, {
                visualizationOptions,
            }) as MultiTableResult;

            return resultToDisplayAsPlainObject;
        },

        get resultsToDisplay(): Array<MultiTableResult> {
            const env = getQueryStoreEnv(self);
            // Each result with a chart visualization gets duplicated into 2 results
            // - one with table visualization and one with chart visualizations,
            // since we want to have both in separate tabs.
            if (env.featureFlags.QueryVisualOptions && env.featureFlags.AddVisual) {
                return self.results
                    .map((result, i) =>
                        result.isChart || self.visualAdded
                            ? [
                                  {
                                      ...result,
                                      queryIndex: i,
                                      visualizationOptions: {
                                          ...TableVisualizationOptionsSnapshot,
                                          IsQuerySorted: result.visualizationOptions?.IsQuerySorted,
                                      } as VisualizationOptions,
                                      isChart: false,
                                      columnsHash: result.columnsHash,
                                  },
                                  {
                                      ...result,
                                      queryIndex: i,
                                      isChart: true,
                                      columnsHash: result.columnsHash,
                                  },
                              ]
                            : [
                                  {
                                      ...result,
                                      queryIndex: i,
                                      isChart: result.isChart,
                                      columnsHash: result.columnsHash,
                                  },
                              ]
                    )
                    .reduce((prev: Array<MultiTableResult>, curr) => prev.concat(curr), []);
            }
            return self.results
                .map((result, i) =>
                    result.isChart
                        ? [
                              {
                                  ...result,
                                  queryIndex: i,
                                  isChart: true,
                                  columnsHash: result.columnsHash,
                              },
                              {
                                  ...result,
                                  queryIndex: i,
                                  visualizationOptions: {
                                      ...TableVisualizationOptionsSnapshot,
                                      IsQuerySorted: result.visualizationOptions?.IsQuerySorted,
                                  } as VisualizationOptions,
                                  isChart: false,
                                  columnsHash: result.columnsHash,
                              },
                          ]
                        : [
                              {
                                  ...result,
                                  queryIndex: i,
                                  isChart: result.isChart,
                                  columnsHash: result.columnsHash,
                              },
                          ]
                )
                .reduce((prev: Array<MultiTableResult>, curr) => prev.concat(curr), []);
        },
    }));
// eslint-disable-next-line no-redeclare
export type QueryResults = typeof QueryResults.Type;
