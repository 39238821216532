import { saveAs } from 'file-saver';

import { QueryResults } from '../stores/queryCompletionInfo';

interface pythonParamData {
    name: string;
    value: string;
    fileName: string;
}
const pythonReadFile = ({ name, fileName }: pythonParamData) => `
with open(os.path.join(os.path.dirname(__file__), '${fileName}'), 'r') as file:
    kusto_${name} = file.read()
    
binary_${name} = binascii.unhexlify(kusto_${name})
${name} = pickle.loads(binary_${name})
`;

const saveParamToFile = ({ value, fileName }: pythonParamData) =>
    new Promise((resolve) => {
        const blob = new Blob([value], {
            type: 'text/plain',
        });
        saveAs(blob, fileName);
        // due to issue in safari, need to add delay between downloads
        setTimeout(resolve, 50);
    });

export const pythonDebugResult = async (queryResults: QueryResults) => {
    const debugResults = queryResults.resultsToDisplay.find((result) => result.tableName === 'PythonDebugResult');
    if (debugResults && debugResults.rows) {
        const dataFilePrefix = new Date().getTime();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { code, df, kargs } = (debugResults.rows as any[]).reduce(
            (data, row: { param: string; value: string }) => {
                data[row.param] = {
                    value: row.value,
                    fileName: `${row.param}_${dataFilePrefix}.txt`,
                    name: row.param,
                } as pythonParamData;
                return data;
            },
            {} as { [param: string]: string }
        );
        const pyhtonCode = `import numpy
import pandas as pd
import pickle
import binascii
import tempfile
import os

${df ? pythonReadFile(df) : ''}${kargs ? pythonReadFile(kargs) : ''}

breakpoint()

# Inline python code starts here
${code.value}

# End of inline python, the 'result' variable should contain the tabular results of the operator evaluation

breakpoint()
pass
`;
        await saveParamToFile(df);
        if (kargs) {
            await saveParamToFile(kargs);
        }
        await saveParamToFile({
            value: pyhtonCode,
            fileName: 'debug_python.py',
            name: 'code',
        });
    }
};
