import { ArgumentColumnType } from '@kusto/charting';
import { YAxisConfig } from '@kusto/visual-fwk';

import { isArgumentNumeric } from '../utils/charting';
import { ExtendedVisualizationOptions } from '../utils/visualization';

export const referenceLinesColor = '#57A300';
export const sharedTooltipMaxNumOfSeries = 6;

export function isAnomalySeries(seriesName: string) {
    return seriesName.includes('(anomaly)');
}

export function getYAxisChartingConfig(
    visualizationOptions: ExtendedVisualizationOptions,
    yAxis?: YAxisConfig
): Highcharts.YAxisOptions {
    return yAxis
        ? {
              min: yAxis.yAxisMinimumValue,
              max: yAxis.yAxisMaximumValue,
              type: yAxis.yAxisScale === 'log' ? 'logarithmic' : undefined,
              // If user specified a specific min and max value, we want to respect it and not start on ticks.
              startOnTick: yAxis.yAxisMinimumValue === null,
              endOnTick: yAxis.yAxisMaximumValue === null,
          }
        : {
              min: visualizationOptions.Ymin !== 'NaN' ? visualizationOptions.Ymin : null,
              max: visualizationOptions.Ymax !== 'NaN' ? visualizationOptions.Ymax : null,
              type: visualizationOptions.YAxis === 'log' ? 'logarithmic' : undefined,
              // If user specified a specific min and max value, we want to respect it and not start on ticks.
              startOnTick: visualizationOptions.Ymin === 'NaN',
              endOnTick: visualizationOptions.Ymax === 'NaN',
          };
}

export function getVerticalLineValue(value?: string, type?: ArgumentColumnType) {
    if (value === undefined || type === undefined) {
        return undefined;
    }
    if (type === ArgumentColumnType.DateTime) {
        const valueAsDate = new Date(value);
        return valueAsDate instanceof Date && !isNaN(valueAsDate.getTime()) ? valueAsDate.getTime() : undefined;
    }
    if (isArgumentNumeric(type)) {
        const valueAsNumber = parseFloat(value);
        return !isNaN(valueAsNumber) ? valueAsNumber : undefined;
    }
    return undefined;
}

export function buildYSplitAxesConfig(
    seriesNames: string[],
    visualizationOptions: ExtendedVisualizationOptions
): Highcharts.YAxisOptions[] {
    return (
        seriesNames
            // anomaly series do not get their own axis, since their data overlays on the existing columns.
            .filter((name) => visualizationOptions.Visualization !== 'anomalychart' || !isAnomalySeries(name))
            .map<Highcharts.YAxisOptions>((_name, index) => ({
                min: visualizationOptions.Ymin !== 'NaN' ? visualizationOptions.Ymin : null,
                max: visualizationOptions.Ymax !== 'NaN' ? visualizationOptions.Ymax : null,
                type: visualizationOptions.YAxis === 'log' ? 'logarithmic' : undefined,
                // If user specified a specific min and max value, we want to respect it and not start on ticks.
                startOnTick: visualizationOptions.Ymin === 'NaN',
                endOnTick: visualizationOptions.Ymax === 'NaN',
                title: { text: undefined },
                opposite: index % 2 !== 0,
                // Showing more than 4 axes is not useful for humans. if there are more than 4 we show them all.
                // This aligns with the behavior of Kusto.Explorer.
                visible: seriesNames.length <= 4,
            }))
    );
}

export function getYAxisPlotLinesConfig(lines: (number | null)[], color?: string): Highcharts.YAxisPlotLinesOptions[] {
    const lineOptions: Highcharts.YAxisPlotLinesOptions[] = [];

    for (const line of lines) {
        if (line === null) continue;
        const num = Number(line);
        if (isNaN(num)) continue;

        lineOptions.push({
            value: Number(line),
            width: 1.5,
            color: color ?? referenceLinesColor,
            dashStyle: 'ShortDash',
            label: {
                style: { display: 'none', color: color ?? referenceLinesColor },
                text: line.toString(),
                align: 'right',
                x: 0,
            },
            zIndex: 3,
            events: {
                mouseover: function () {
                    this.label.element.style.display = 'block';
                },
                mouseout: function () {
                    this.label.element.style.display = 'none';
                },
            },
        });
    }

    return lineOptions;
}

/**
 * Check if results should be split into multiple panels
 * @param visualizationOptions Chart props
 */
export function shouldShowMultiplePanels(visualizationOptions: ExtendedVisualizationOptions) {
    const { MultipleYAxes } = visualizationOptions;
    return (
        visualizationOptions.YSplit === 'panels' ||
        // If the toggle is on and there are valid additional Y-axes or only one Y-column
        (MultipleYAxes?.showMultiplePanels &&
            ((MultipleYAxes.additional.length > 0 && !!visualizationOptions.YColumns?.length) ||
                visualizationOptions.YColumns?.length === 1))
    );
}
