.panelBody {
    padding: var(--spacingVerticalL) var(--spacingHorizontalL) !important;
    background-color: var(--colorNeutralBackground2) !important;
    background-size: auto 0 !important;
    display: flex;
    flex-flow: column nowrap;
    gap: var(--spacingVerticalL);
    flex-grow: 1;
}

.panelCenter {
    background-color: var(--colorNeutralBackground2) !important;
    background-size: auto 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

%containerDesign {
    padding: var(--spacingVerticalL) var(--spacingHorizontalL);
    border-radius: var(--borderRadiusXLarge);
    background: var(--colorNeutralBackground1);
    box-shadow: var(--shadow4);
}

.filtersWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: var(--spacingHorizontalL);
}

.timezoneIndicator {
    display: flex;
    align-items: center;
    gap: var(--spacingHorizontalSNudge);
    padding: 0 var(--spacingHorizontalM);
}

.histogramWrapper {
    display: flex;
    align-self: stretch;
    max-height: 170px;
    flex-grow: 0;
    flex-shrink: 0;
    @extend %containerDesign;
    padding: 0;
    transition: height 0.5s ease-in-out;
}

.messageBarError {
    padding: var(--spacingVerticalL) var(--spacingHorizontalL);
    display: flex;
    align-items: center;
    gap: var(--spacingHorizontalXS);
}

.noDataForTimeRange {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.docstringIcon {
    color: var(--colorBrandForeground1);
}

.docstring {
    margin-top: calc(-1 * var(--spacingVerticalM));
}
