$inputWidth: 256px;

.colorIcon {
    width: 16px;
    height: 16px;
}

.fullWidth {
    grid-column: span 2;
}

.themeDropdownOption {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.themeDropdownOptionText {
    margin-left: 8px;
}

.themeCanvas {
    grid-column: span 3;
    height: 28px;
    width: 100%;
}

.themeDropdownOptionColors {
    display: grid;
    grid-template-columns: repeat(3, 20px);
    height: 20px;
    column-gap: 2px;
}

.themeColorIcon {
    width: 20px;
    height: 20px;
}

.dialogContent {
    :global(.ms-Dialog-content) {
        grid-template-columns: 1fr 1fr;
    }
}

.sectionTitle {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 12px;
    margin-top: 20px;
}

.operatorInput {
    width: 100px;
}

.inputWidth {
    width: $inputWidth;
}

.chainingOperator {
    grid-column: 1;
}

.condition {
    grid-column: span 3;
}

.deleteButton {
    width: 32px;
    height: 32px;
    &:hover {
        background-color: var(--neutralQuaternaryAlt);
    }
}

.section {
    display: grid;
    grid-template-columns: repeat(auto-fill, $inputWidth);
    column-gap: 12px;
    row-gap: 16px;
}

.colorRulePanel {
    :global(.ms-Panel-footerInner) {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }
    :global(.ms-Panel-main) {
        @media (min-width: 1024px) {
            width: 976px;
        }
    }
    :global(.ms-Panel-commands) {
        background-color: var(--bodyBackgroundColor);
    }
    :global(.ms-Panel-footer) {
        background-color: var(--bodyBackgroundColor);
    }
}

// Need to align this toggle with the other input fields in the same row
.hideTextToggle {
    :global(.ms-Toggle-innerContainer) {
        height: 32px;
        align-items: center;
    }
    margin-bottom: 0;
}

.betweenAnd {
    line-height: 32px;
    align-self: flex-end;
}

.conditionContainer {
    display: grid;
    grid-template-columns: minmax(110px, max-content) 1fr 24px;
    grid-template-rows: 80px;
    padding: 0 12px;
    align-items: center;
    column-gap: 8px;
    background-color: var(--gray20);
    &.withChaining {
        .condition {
            grid-column: 2;
        }
    }
}
