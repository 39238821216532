import React from 'react';
import { useConst, useId } from '@fluentui/react-hooks';
import { IconButton } from '@fluentui/react/lib/Button';

import { TooltipWrapper, TooltipWrapperProps } from './TooltipWrapper';

export interface IconButtonWithTooltipProps extends React.ComponentProps<typeof IconButton> {
    tooltipProps?: TooltipWrapperProps;
}

/**
 * @deprecated Fluent 8
 */
export const IconButtonWithTooltip = ({ title, tooltipProps, ...iconButtonProps }: IconButtonWithTooltipProps) => {
    const iconButtonId = useId();
    const calloutProps = useConst({
        target: `#${iconButtonId}`,
    });
    return (
        <TooltipWrapper content={title} calloutProps={calloutProps} {...tooltipProps}>
            <IconButton id={iconButtonId} {...iconButtonProps}></IconButton>
        </TooltipWrapper>
    );
};
