import React from 'react';
import { mergeClasses, Subtitle1 } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';

import { DistributionList } from '@kusto/ui-components';
import { formatLiterals } from '@kusto/utils';

import {
    useColumnDistribution,
    useScopedRowCount,
    type ColumnWithInsights,
    type DataProfileTimeRange,
} from '../../DataExplorationApi';
import { useStrings } from '../../DataExplorationModal/hooks/useStrings';
import { ColumnInfoStatusWrapper } from './ColumnInfoStatusWrapper';

import scrollableStyles from '../ScrollableContent.module.scss';
import styles from './SchemaColumnInfo.module.scss';

interface SchemaDistributionListProps {
    selectedColumn: ColumnWithInsights;
    title: React.ReactNode;
    queryText: string;
    timeRange: DataProfileTimeRange;
    timezone: string;
}

export const SchemaDistributionList: React.FC<SchemaDistributionListProps> = observer(function SchemaDistributionList({
    selectedColumn,
    queryText,
    title,
    timeRange,
    timezone,
}) {
    const { strings, locale } = useStrings();

    const {
        data: scopedRowCount,
        isLoading: isLoadingRowCount,
        error: rowCountError,
    } = useScopedRowCount(queryText, timeRange);
    const {
        data: distribution,
        isLoading: isLoadingDistribution,
        error: distributionError,
    } = useColumnDistribution(queryText, selectedColumn.ColumnName, timeRange);

    const formattedDistribution = React.useMemo(() => {
        switch (selectedColumn.ColumnType) {
            case 'int':
            case 'long':
            case 'real':
            case 'decimal':
                const numberFormatter = new Intl.NumberFormat(locale, { style: 'decimal' });
                return distribution?.map(({ title, itemCount }) => ({
                    title: title !== null && title !== undefined ? numberFormatter.format(title as number) : '-',
                    itemCount,
                }));
            case 'datetime':
                const dateFormatter = new Intl.DateTimeFormat(locale, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    fractionalSecondDigits: 3,
                    timeZone: timezone,
                });
                return distribution?.map(({ title, itemCount }) => ({
                    title:
                        title !== null && title !== undefined
                            ? dateFormatter.format(new Date(title as string))
                            : 'null',
                    itemCount,
                }));
            case 'bool':
                return [
                    {
                        title: strings.schemaInsights.columnInfo.true,
                        itemCount: distribution?.find((row) => row.title === true)?.itemCount ?? 0,
                    },
                    {
                        title: strings.schemaInsights.columnInfo.false,
                        itemCount: distribution?.find((row) => row.title === false)?.itemCount ?? 0,
                    },
                    {
                        title: '-',
                        itemCount: distribution?.find((row) => row.title === null)?.itemCount ?? 0,
                    },
                ];
            case 'string':
                return distribution?.map(({ title, itemCount }) => ({
                    title: title === '' ? strings.schemaInsights.columnInfo.emptyString : (title as string),
                    itemCount,
                }));
            case 'dynamic':
            case 'guid':
            case 'timespan':
            default:
                return distribution as { title: string; itemCount: number }[];
        }
    }, [distribution, selectedColumn.ColumnType, strings, locale, timezone]);

    return (
        <>
            <div className={styles.columnInfoHeader}>
                {title}
                {isLoadingDistribution || !formattedDistribution ? null : (
                    <Subtitle1>
                        {formatLiterals(strings.schemaInsights.distributionTopTitle, {
                            topCount: formattedDistribution.length.toLocaleString(),
                        })}
                    </Subtitle1>
                )}
            </div>
            <ColumnInfoStatusWrapper
                isLoading={
                    isLoadingRowCount || scopedRowCount === undefined || isLoadingDistribution || !formattedDistribution
                }
                errorMessage={rowCountError?.message || distributionError?.message}
            >
                <div
                    className={mergeClasses(scrollableStyles.scrollableContent, scrollableStyles.scrollableSpacing)}
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    tabIndex={0}
                >
                    <DistributionList
                        totalValues={scopedRowCount ?? 0}
                        items={formattedDistribution ?? []}
                        locale={locale}
                    />
                </div>
            </ColumnInfoStatusWrapper>
        </>
    );
});
