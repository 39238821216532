import React, { useState } from 'react';
import {
    Body1,
    Button,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Subtitle1,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';

import { DataExploration, DataExplorationProps } from './DataExploration';

import styles from './DataExplorationDialog.module.scss';

export interface DataExplorationDialogProps extends DataExplorationProps {
    header: {
        title: string;
        subtitle?: string;
    };
}

export const DataExplorationModal: React.FunctionComponent<DataExplorationDialogProps> = ({ header, ...props }) => {
    const [open, setOpen] = useState(true);

    return (
        <Dialog open={open}>
            <DialogSurface className={styles.dialog}>
                <DialogBody className={styles.dialogContent}>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                    onClick={() => setOpen(false)}
                                    className={styles.closeButton}
                                />
                            </DialogTrigger>
                        }
                    >
                        <div className={styles.dialogHeader} data-testid="data-expo-title">
                            <Subtitle1>{header.title}</Subtitle1>
                            <Body1 className={styles.subTitle}>{header.subtitle}</Body1>
                        </div>
                    </DialogTitle>
                    <DialogContent className={styles.dialogBody}>
                        <DataExploration {...props} />
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
