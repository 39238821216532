import React from 'react';
import { Divider, Option } from '@fluentui/react-components';

import type { DropdownWithSearchOption } from '../types.ts';

import styles from '../DropdownWithSearch.module.scss';

interface DropdownItemProps {
    /* The option object */
    option: DropdownWithSearchOption;
    /** Custom option renderer function */
    renderOption?: (option: DropdownWithSearchOption) => React.ReactElement;
}

export const DropdownItem: React.FC<DropdownItemProps> = ({ option, renderOption }) => {
    const { key, text, content, ...props } = option;

    switch (option.type) {
        case 'hidden':
            return null;
        case 'divider':
            return <Divider />;
        default:
            return (
                <Option
                    key={key}
                    value={key}
                    text={text}
                    className={(content || renderOption) && styles.customElem}
                    {...props}
                >
                    <div className={styles.optionContent}>{content ?? renderOption?.(option) ?? text}</div>
                </Option>
            );
    }
};
