// TODO: Expand support for this to others (e.g. mailto: or file://)
// @see https://msazure.visualstudio.com/DefaultCollection/One/_workitems/edit/8814814/
const CLICKABLE_SCHEMES = ['https://'] as const;

function startsWithClickableScheme(input: string) {
    return CLICKABLE_SCHEMES.some((scheme) => input.startsWith(scheme));
}

/**
 * Checks if the input is a clickable URL. This function will not handle
 * extra white-space that's at the start of it. It's up to the caller to trim.
 */
export function isClickable(input: string): boolean {
    // Proceeding white-space will never give us a clickable url
    // so bail early here
    if (input.startsWith(' ')) {
        return false;
    }

    const parts = input.split(' ').filter(Boolean);

    if (!parts.length) {
        return startsWithClickableScheme(input);
    }

    let numOfClickable = 0;
    for (const part of parts) {
        if (startsWithClickableScheme(part)) {
            numOfClickable++;
        }
    }

    /**
     * This means there's 2 or more "clickable" urls inside the
     * input string which is an issue because a customer cannot
     * visit "https://microsoft.com%20https://microsoft.com" as
     * that doesn't exist and the page would fail to load. So,
     * we return false so that ultimately the customer won't see
     * the malformed link as being clickable.
     */
    if (numOfClickable !== 1) {
        return false;
    }

    return true;
}
