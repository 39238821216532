import React, { useCallback } from 'react';
import { mergeClasses } from '@fluentui/react-components';
import { bundleIcon, EditFilled, EditRegular } from '@fluentui/react-icons';

import { CloseTabButton } from '../CloseTabButton/CloseTabButton';
import { TabItem } from '../Tabs.interfaces';
import { useTabsBarContext } from '../TabsContext/TabsBar.context';
import { ButtonTooltip } from '../TabsTooltip/TabsTooltip';
import { TabText } from '../TabText/TabText';

import styles from './Tab.module.scss';

const Edit = bundleIcon(EditFilled, EditRegular);

export const Tab: React.FunctionComponent<TabItem> = (tabItem) => {
    const { tabId, icon: Icon } = tabItem;
    const context = useTabsBarContext();

    const isSelected = tabId === context.selectedTabId;
    const isEditing = tabId === context.editingTab.tabId;
    const isSingleTab = context.tabsList.length <= 1;

    const selectTab = useCallback(() => {
        context.events.onTabSelect(tabId);
    }, [tabId, context]);

    const closeTab = useCallback(() => {
        context.events.onTabClose(tabId);
    }, [tabId, context]);

    const onMouseDown = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            switch (event.button) {
                case 0: // left click
                    selectTab();
                    break;
                case 1: // middle click
                    closeTab();
                    break;
            }
        },
        [closeTab, selectTab]
    );

    const onRename = useCallback(() => {
        context.editingTab.setTabId(tabId);
    }, [tabId, context]);

    return (
        <div
            role="tab"
            aria-selected={isSelected}
            className={mergeClasses(
                styles.tab,
                isSelected ? styles.selectedTab : undefined,
                isEditing ? styles.editingTab : undefined,
                isSingleTab ? styles.singleTab : undefined,
                !Icon ? styles.withoutIcon : undefined
            )}
            tabIndex={0}
            onMouseDown={onMouseDown}
            onKeyDown={selectTab}
        >
            <div className={styles.tabTextContainer}>
                {Icon && <Icon filled={isSelected} className={styles.tabIcon} />}
                <TabText {...tabItem} />
            </div>
            {isEditing ? null : (
                <div className={styles.tabButtons}>
                    <ButtonTooltip
                        tooltip={context.strings.editTab}
                        onClick={onRename}
                        icon={<Edit />}
                        appearance="transparent"
                        size="small"
                        className={styles.tabButtonEdit}
                    />
                    <CloseTabButton {...tabItem} />
                </div>
            )}
        </div>
    );
};
