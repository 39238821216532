import React from 'react';
import { MessageBar, MessageBarType, Toggle } from '@fluentui/react';
import { observer } from 'mobx-react-lite';

import {
    DropdownWithSearch,
    DropdownWithSearchOption,
    DropdownWithSearchProps,
    SelectedOption,
} from '@kusto/ui-components';
import { formatLiterals, KweException, useComputed } from '@kusto/utils';

import { interactionErrorMessage, InteractionErrorMessage, InteractionTarget } from '../../interactions/target';
import type { KweVisualFwkLocale } from '../../types';
import type { CrossFilterConfig } from '../../visualOptions';
import { DropdownOptionText, InteractiveDropdownOption } from '../interactive';
import type { InteractionsIndex } from './CrossFilter';
import { crossFilterTypeWarning, ResolvedSelections, useResolveSelections } from './lib';
import type { CrossFilterVisual } from './VisualInteraction';

import * as inputLibStyle from '../styles.module.scss';

interface CrossFilterDropdownOption<T = unknown> extends DropdownWithSearchOption<T> {
    typeText?: string;
    warning?: InteractionErrorMessage;
}

type CrossFilterPropertyOption = CrossFilterDropdownOption<{
    readonly interaction: CrossFilterVisual.Available;
    readonly property: CrossFilterVisual.Property;
}>;

type CrossFilterInteractionOption = CrossFilterDropdownOption<CrossFilterVisual>;

const NOT_FOUND_OPTION_KEY = 'not_found';

// Separate from property option stuff so we can apply it to interactions if need be
function propertyOptionText(
    strings: KweVisualFwkLocale,
    displayName: string,
    property: CrossFilterVisual.Property,
    selectedParameter: undefined | InteractionTarget
) {
    let warning: undefined | InteractionErrorMessage;
    let warningTitle: undefined | string;
    if (property.kind === 'available') {
        if (selectedParameter) {
            const res = selectedParameter.canApply(property.dataTypes);
            if (res.kind !== 'ok') {
                // Details are omitted to save space
                warning = interactionErrorMessage(
                    () =>
                        strings.visualFwk.visualConfig.interactions.crossFilter.property
                            .propertyParameterTypeMismatchMessage,
                    res.kind
                );
                warningTitle = res.errors.map((e) => e.text()).join(', ');
            }
        }
    } else {
        warning = interactionErrorMessage(() => property.unavailableReason);
        warningTitle = property.unavailableReason;
    }

    let typeText: string;
    if (property.dataTypes === undefined) {
        typeText = strings.visualFwk.value.unavailable;
    } else if (property.dataTypes.length === 0) {
        typeText = strings.visualFwk.value.any;
    } else {
        typeText = property.dataTypes.join(', ');
    }

    const warningText = warningTitle ? ' \n\n' + warningTitle : '';

    return { title: `${displayName} (${typeText})${warningText}`, warning, typeText };
}

/**
 * Note: no support for multi select dropdowns
 */
const onRenderTitle: DropdownWithSearchProps['renderTitle'] = (selected) => {
    if (selected === undefined || selected.length === 0) {
        throw new KweException('Unable to determine visual selected dropdown selection');
    }
    const option = selected[0] as InteractiveDropdownOption;
    return <DropdownOptionText option={option} />;
};

const onDropdownRenderOption: DropdownWithSearchProps['renderOption'] = (option) => {
    if (option === undefined) {
        throw new KweException();
    }
    const casted = option as InteractiveDropdownOption;
    return <DropdownOptionText option={casted} />;
};

function interactionToOptionKey(id: string) {
    return `d--${id}`;
}

interface InteractionDropdownProps extends CrossFilterFormProps {
    selections: ResolvedSelections;
}

const InteractionDropdown: React.FC<InteractionDropdownProps> = ({
    t,
    disabled,
    getValue,
    onChange,
    id,
    interactions,
    value,
    selections: { interaction, parameter, property },
}) => {
    const interactionOptions = React.useMemo(
        (): CrossFilterDropdownOption[] => [
            {
                key: NOT_FOUND_OPTION_KEY,
                text: t.visualFwk.visualConfig.interactions.crossFilter.interaction.notFoundError,
                type: 'hidden',
            },
            ...interactions.map((d) => {
                const unavailable = d.unavailable;
                return {
                    key: interactionToOptionKey(d.id),
                    text: d.displayName ?? d.id,
                    data: d,
                    disabled: unavailable !== undefined,
                    warning: unavailable === undefined ? undefined : interactionErrorMessage(() => unavailable),
                    // If there is only 1 property available, then it will be
                    // selected when this option is selected, so show that
                    // properties info here
                    ...(d.properties?.length === 1
                        ? propertyOptionText(t, d.displayName ?? d.id, d.properties[0], parameter)
                        : undefined),
                };
            }),
        ],
        [interactions, parameter, t]
    );

    let dropdownErrorText: undefined | string;

    if (value.interaction !== undefined) {
        if (interaction === undefined) {
            dropdownErrorText = t.visualFwk.visualConfig.interactions.crossFilter.interaction.notFoundError;
        } else if (interaction.unavailable !== undefined) {
            dropdownErrorText = formatLiterals(
                t.visualFwk.visualConfig.interactions.crossFilter.interaction.unavailablePrefix,
                {
                    message: interaction.unavailable,
                }
            );
        } else if (interaction.kind === 'available' && interaction.properties.length === 1) {
            dropdownErrorText = getPropertyErrorMessage(t, value, interaction, property);
        }
    }

    const onInteractionChange = React.useCallback(
        (_event: unknown, option?: SelectedOption) => {
            const d = (option as CrossFilterInteractionOption).data!;
            const prev = getValue(id);
            if (!prev) {
                return;
            }
            onChange(id, {
                ...prev,
                interaction: d.id,
                // If there is only 1 available property, select it, otherwise clear the selected property.
                property: d.properties && d.properties.length === 1 ? d.properties[0].id : undefined,
            });
        },
        [getValue, id, onChange]
    );

    return (
        <DropdownWithSearch
            className={inputLibStyle.basicInput}
            label={'Interaction'}
            disabled={disabled}
            selectedKeys={
                interaction ? interactionToOptionKey(interaction.id) : value.property ? NOT_FOUND_OPTION_KEY : null
            }
            options={interactionOptions}
            onChange={onInteractionChange}
            validationMessage={dropdownErrorText}
            renderTitle={onRenderTitle}
            renderOption={onDropdownRenderOption}
        />
    );
};

function propertyToOptionKey(id: string) {
    return `s--${id}`;
}

function newPropertyOption(
    strings: KweVisualFwkLocale,
    interaction: CrossFilterVisual.Available,
    property: CrossFilterVisual.Property,
    selectedParameter: undefined | InteractionTarget
): CrossFilterPropertyOption {
    return {
        key: propertyToOptionKey(property.id),
        text: property.displayName ?? property.id,
        data: { interaction, property },
        ...propertyOptionText(strings, property.displayName ?? property.id, property, selectedParameter),
    };
}

function getPropertyErrorMessage(
    strings: KweVisualFwkLocale,
    value: CrossFilterConfig,
    selectedInteraction: CrossFilterVisual.Available,
    selectedProperty: undefined | CrossFilterVisual.Property
): undefined | string {
    const propertiesName =
        selectedInteraction.propertiesDisplayName ??
        strings.visualFwk.visualConfig.interactions.crossFilter.property.defaultDropdownLabel;
    if (value.property === undefined) {
        return undefined;
    }
    if (selectedProperty === undefined) {
        return formatLiterals(strings.visualFwk.visualConfig.interactions.crossFilter.property.notFoundError, {
            propertiesName,
        });
    }
    if (selectedProperty.kind === 'unavailable') {
        return formatLiterals(strings.visualFwk.visualConfig.interactions.crossFilter.property.unavailableErrorPrefix, {
            message: selectedProperty.unavailableReason,
        });
    }
    return undefined;
}

interface PropertyDropdownProps extends CrossFilterFormProps {
    selectedInteraction: CrossFilterVisual.Available;
    selectedProperty: CrossFilterVisual.Property | undefined;
    selectedParameter: InteractionTarget | undefined;
}

const PropertyDropdown: React.FC<PropertyDropdownProps> = observer(function PropertyDropdown({
    t,
    value,
    onChange,
    getValue,
    disabled,
    id,
    selectedInteraction,
    selectedProperty,
    selectedParameter,
}) {
    const propertyOptions = useComputed((): CrossFilterDropdownOption[] => {
        const unavailableOption: CrossFilterDropdownOption = {
            key: NOT_FOUND_OPTION_KEY,
            text: t.visualFwk.visualConfig.interactions.crossFilter.notFoundOptionText,
            type: 'hidden',
        };

        if (!selectedInteraction?.properties) {
            return [unavailableOption];
        }
        return [
            unavailableOption,
            ...selectedInteraction.properties.map((p) =>
                newPropertyOption(t, selectedInteraction, p, selectedParameter)
            ),
        ];
    }, [selectedInteraction, selectedParameter, t]);

    const onPropertyChange = React.useCallback(
        (_event: unknown, option?: SelectedOption) => {
            const data = (option as CrossFilterPropertyOption).data!;
            const prev = getValue(id);
            if (!prev) {
                return;
            }
            onChange(id, {
                ...prev,
                interaction: data.interaction.id,
                property: data.property.id,
            });
        },
        [getValue, id, onChange]
    );

    return (
        <DropdownWithSearch
            className={inputLibStyle.basicInput}
            label={
                selectedInteraction.propertiesDisplayName ??
                t.visualFwk.visualConfig.interactions.crossFilter.property.defaultDropdownLabel
            }
            disabled={disabled}
            selectedKeys={
                selectedProperty
                    ? propertyToOptionKey(selectedProperty.id)
                    : value.property
                    ? NOT_FOUND_OPTION_KEY
                    : null
            }
            options={propertyOptions}
            onChange={onPropertyChange}
            validationMessage={getPropertyErrorMessage(t, value, selectedInteraction, selectedProperty)}
            renderTitle={onRenderTitle}
            renderOption={onDropdownRenderOption}
        />
    );
});

function parameterIdToOptionKey(id: string) {
    return `p--${id}`;
}

interface ParameterDropdownProps extends CrossFilterFormProps {
    selections: ResolvedSelections;
}

const ParameterDropdown: React.FC<ParameterDropdownProps> = observer(function ParameterDropdown({
    t,
    value,
    parameters,
    disabled,
    onChange,
    getValue,
    id,
    selections,
}) {
    const parameterUnavailable = value.parameterId !== undefined && selections.parameter === undefined;

    const parameterOptions = useComputed(
        (): CrossFilterDropdownOption[] => [
            {
                key: NOT_FOUND_OPTION_KEY,
                text: t.visualFwk.visualConfig.interactions.crossFilter.parameter.configuredParameterDeletedText,
                type: 'hidden',
            },
            ...parameters.map((p) => {
                const warning = crossFilterTypeWarning(t, { ...selections, parameter: p });

                const typeText = p.typeDisplayName(t);
                const warningText = warning ? ' \n\n' + warning.text() : '';

                return {
                    key: parameterIdToOptionKey(p.id),
                    text: p.displayName,
                    warning,
                    data: p.id,
                    typeText,
                    title: `${p.displayName} (${typeText})${warningText}`,
                };
            }),
        ],
        [parameters, selections, t]
    );

    const onParameterChange = React.useCallback(
        (_event: unknown, option?: SelectedOption) => {
            if (!option) {
                return;
            }
            const prev = getValue(id);
            if (!prev) {
                return;
            }
            const parameterId = option.data as string;
            onChange(id, { ...prev, parameterId });
        },
        [getValue, id, onChange]
    );

    return (
        <DropdownWithSearch
            className={inputLibStyle.basicInput}
            label={t.visualFwk.visualConfig.interactions.crossFilter.parameter.dropdownLabel}
            disabled={disabled}
            // Right now we have no way to format unavailable parameters, so don't select anything
            selectedKeys={
                parameterUnavailable
                    ? NOT_FOUND_OPTION_KEY
                    : value.parameterId
                    ? parameterIdToOptionKey(value.parameterId)
                    : null
            }
            options={parameterOptions}
            onChange={onParameterChange}
            validationMessage={
                parameterUnavailable
                    ? t.visualFwk.visualConfig.interactions.crossFilter.parameter.unavailableError
                    : undefined
            }
            renderTitle={onRenderTitle}
            renderOption={onDropdownRenderOption}
        />
    );
});

interface TypeWarningProps {
    t: KweVisualFwkLocale;
    selections: ResolvedSelections;
}

const TypeWarning: React.FC<TypeWarningProps> = observer(function TypeWarning({ t, selections }) {
    const warning = crossFilterTypeWarning(t, selections);

    if (warning) {
        return (
            <MessageBar
                isMultiline
                messageBarType={warning.level === 'error' ? MessageBarType.error : MessageBarType.warning}
            >
                {warning.text()}
            </MessageBar>
        );
    }

    return null;
});

interface DisableToggleProps {
    t: KweVisualFwkLocale;
    id: number;
    value: CrossFilterConfig;
    onChange(id: number, value: CrossFilterConfig): void;
    getValue(id: number): undefined | CrossFilterConfig;
}

const DisableToggle: React.FC<DisableToggleProps> = ({ t, id, value, onChange, getValue }) => {
    const onDisabledToggle = React.useCallback(() => {
        const prev = getValue(id);
        if (!prev) {
            return;
        }
        onChange(id, {
            ...prev,
            disabled: !prev.disabled,
        });
    }, [getValue, id, onChange]);

    return (
        <Toggle
            className={inputLibStyle.basicInput}
            checked={value.disabled}
            label={t.utils.util.buttons.disable}
            onClick={onDisabledToggle}
        />
    );
};

export interface CrossFilterFormProps {
    t: KweVisualFwkLocale;
    id: number;
    value: CrossFilterConfig;
    onChange(id: number, value: CrossFilterConfig): void;
    getValue(id: number): undefined | CrossFilterConfig;
    disabled: boolean;
    showDisable: boolean;
    interactions: readonly CrossFilterVisual[];
    interactionsIndex: InteractionsIndex;
    parameters: readonly InteractionTarget[];
    parametersRecord: undefined | ReadonlyMap<string, InteractionTarget>;
}

export const CrossFilterForm: React.FC<CrossFilterFormProps> = (props) => {
    const { value, interactions } = props;
    const disabled = props.disabled || value.disabled;

    let selections = useResolveSelections(props.value, props.interactionsIndex, props.parametersRecord);

    // Selecting a property will also select an interaction, so we can
    // aggressively default this. Should not be defaulted if an invalid
    // interaction is selected, so we can show an error.
    if (selections.interaction === undefined) {
        selections = { ...selections, interaction: interactions[0] };
    }

    return (
        <>
            {/* If there is a single available interaction  that is selected with multiple properties, don't show this dropdown */}
            {(interactions.length !== 1 ||
                selections.interaction?.kind !== 'available' ||
                selections.interaction.properties.length === 1) && (
                <InteractionDropdown {...props} disabled={disabled} selections={selections} />
            )}
            {/* If there is a single available property that is selected, don't show this dropdown. */}
            {selections.interaction?.kind === 'available' &&
                !(selections.interaction.properties.length === 1 && selections.property !== undefined) && (
                    <PropertyDropdown
                        {...props}
                        disabled={disabled}
                        selectedInteraction={selections.interaction}
                        selectedProperty={selections.property}
                        selectedParameter={selections.parameter}
                    />
                )}
            <ParameterDropdown {...props} disabled={disabled} selections={selections} />
            {props.showDisable && <DisableToggle {...props} />}
            <TypeWarning t={props.t} selections={selections} />
        </>
    );
};
