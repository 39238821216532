import { createPortal } from 'react-dom';

interface Props {
    container: HTMLDivElement;
    children: React.ReactNode;
}

export const QueryResultsSearchPortal = (props: Props): JSX.Element => {
    return createPortal(props.children, props.container);
};
