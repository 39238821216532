import * as Fwk from '@kusto/visual-fwk';

export const model = {
    table__enableRenderLinks: true,
    table__renderLinks: [],
    colorRules: [],
    colorRulesDisabled: true,
    colorStyle: 'light',
    crossFilter: [],
    crossFilterDisabled: false,
    drillthrough: [],
    drillthroughDisabled: false,
} as const;

export type TableModelDef = keyof typeof model;

export type RtdDataVisualProps = Fwk.IDataVisualProps<TableModelDef>;
export type RtdVisualSelector = Fwk.VisualSelector<TableModelDef, Fwk.VisualConfigLayout<TableModelDef>>;
