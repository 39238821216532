import type { FeatureFlags } from '@kusto/app-common';

import type { VisualFwkFeatureFlag } from '../featureFlags';
import type { KweVisualFwkLocale } from '../types';
import { LEGEND_LOCATION_OPTIONS } from '../visualOptions';
import { createTileInput } from './createTileInput';
import { multipleYAxesManagedConfig } from './multipleYAxes/MultipleYAxes';

export * from './CrossFilter/VisualInteraction';
export * from './Drillthrough/VisualInteraction';

export function axisScaleOptions(t: KweVisualFwkLocale) {
    return [
        {
            key: 'linear' as const,
            text: t.visualFwk.visualConfig.axisScale.linear,
        },
        {
            key: 'log' as const,
            text: t.visualFwk.visualConfig.axisScale.log,
        },
    ];
}

/** @deprecated Most of these should be moved to kustoweb */
export function tileInput(_flags: FeatureFlags<VisualFwkFeatureFlag>) {
    return {
        xColumn(t: KweVisualFwkLocale) {
            return createTileInput.column('xColumn', t.visualFwk.visualConfig.xColumnDropdownLabel);
        },
        yColumn(t: KweVisualFwkLocale) {
            return createTileInput.column('yColumn', t.visualFwk.visualConfig.yColumnDropdownLabel);
        },
        yColumns(t: KweVisualFwkLocale) {
            return createTileInput.columnsNullable('yColumns', t.visualFwk.visualConfig.yColumnsDropdownLabel);
        },
        seriesColumns(t: KweVisualFwkLocale) {
            return createTileInput.columnsNullable(
                'seriesColumns',
                t.visualFwk.visualConfig.seriesColumnsDropdownLabel
            );
        },
        horizontalLine(t: KweVisualFwkLocale) {
            return createTileInput.text('horizontalLine', t.visualFwk.visualConfig.horizontalLineInputLabel);
        },
        verticalLine(t: KweVisualFwkLocale) {
            return createTileInput.text('verticalLine', t.visualFwk.visualConfig.verticalLineInputLabel);
        },
        hideDataLabels(t: KweVisualFwkLocale) {
            return createTileInput.toggle('hideDataLabels', t.visualFwk.visualConfig.hideDataLabelsCheckboxLabel);
        },
        // TODO: Not clear if this is staying or not
        // hideTitle(t: KweVisualFwkLocale) {
        //     return createTileInput.toggle('hideTitle', t.visualFwk.visualConfig.hideTileTitleCheckboxLabel);
        // },
        yAxisRight(t: KweVisualFwkLocale) {
            return createTileInput.staticDropdown(
                ['yAxisRight'],
                t.visualFwk.visualConfig.yAxisPosition.label,
                [
                    { key: 'right', text: 'Right' },
                    { key: 'left', text: 'Left' },
                ],
                (options) => (options.get('yAxisRight') ? 'right' : 'left'),
                (value, model) => model.set('yAxisRight', value === 'right')
            );
        },
        multipleYAxis: multipleYAxesManagedConfig,

        xColumnTitle(t: KweVisualFwkLocale) {
            return createTileInput.text<'xColumnTitle'>(
                'xColumnTitle',
                t.visualFwk.visualConfig.xColumnTitleInputLabel
            );
        },
        yColumnTitle(t: KweVisualFwkLocale) {
            return createTileInput.text<'yColumnTitle'>(
                'yColumnTitle',
                t.visualFwk.visualConfig.yColumnTitleInputLabel
            );
        },
        xAxisScale(t: KweVisualFwkLocale) {
            return createTileInput.staticDropdown(
                ['xAxisScale'],
                t.visualFwk.visualConfig.axisScale.label.xAxisScale,
                axisScaleOptions(t),
                (o) => o.get('xAxisScale'),
                (v, m) => m.set('xAxisScale', v)
            );
        },
        yAxisScale(t: KweVisualFwkLocale) {
            return createTileInput.staticDropdown(
                ['yAxisScale'],
                t.visualFwk.visualConfig.axisScale.label.yAxisScale,
                axisScaleOptions(t),
                (o) => o.get('yAxisScale'),
                (v, m) => m.set('yAxisScale', v)
            );
        },
        legendLocation(t: KweVisualFwkLocale) {
            return createTileInput.staticDropdown(
                ['legendLocation'],
                t.visualFwk.visualConfig.legendLocationDropdown.label,
                LEGEND_LOCATION_OPTIONS.map((key) => ({
                    key,
                    text: t.visualFwk.visualConfig.legendLocationDropdown.options[key],
                })),
                (o) => o.get('legendLocation'),
                (v, m) => m.set('legendLocation', v)
            );
        },
        line__hidePinpointTooltips(t: KweVisualFwkLocale) {
            return createTileInput.toggle(
                'line__hidePinpointTooltips',
                t.visualFwk.visualConfig.line__hidePinpointTooltips
            );
        },
        map__bubbleFormat(t: KweVisualFwkLocale) {
            return createTileInput.staticDropdown(
                ['map__bubbleFormat'],
                t.visualFwk.visualConfig.map__bubbleFormatDropdown.label,
                [
                    { key: 'dot', text: t.visualFwk.visualConfig.map__bubbleFormatDropdown.dotOption },
                    {
                        key: 'bubble',
                        text: t.visualFwk.visualConfig.map__bubbleFormatDropdown.bubbleOption,
                    },
                    {
                        key: 'heatmap',
                        text: t.visualFwk.visualConfig.map__bubbleFormatDropdown.heatmapOption,
                    },
                    {
                        key: 'pieChart',
                        text: t.visualFwk.visualConfig.map__bubbleFormatDropdown.pieChartOption,
                    },
                ],
                (o) => o.get('map__bubbleFormat'),
                (v, m) => m.set('map__bubbleFormat', v)
            );
        },
        map__minBubbleSizeColumn(t: KweVisualFwkLocale) {
            return createTileInput.column(
                'map__minBubbleSizeColumn',
                t.visualFwk.visualConfig.map__minBubbleSizeColumnDropdownLabel
            );
        },
        multiStat__displayOrientation(t: KweVisualFwkLocale) {
            return createTileInput.staticDropdown(
                ['multiStat__displayOrientation'],
                t.visualFwk.visualConfig.multiStat__displayOrientation.label,
                [
                    {
                        key: 'vertical',
                        text: t.visualFwk.visualConfig.multiStat__displayOrientation.vertical,
                    },
                    {
                        key: 'horizontal',
                        text: t.visualFwk.visualConfig.multiStat__displayOrientation.horizontal,
                    },
                ],
                (o) => o.get('multiStat__displayOrientation'),
                (v, m) => m.set('multiStat__displayOrientation', v)
            );
        },
        multiStat__textSize(t: KweVisualFwkLocale) {
            return createTileInput.staticDropdown(
                ['multiStat__textSize'],
                t.visualFwk.visualConfig.multiStat__textSize.label,
                [
                    { key: 'auto', text: t.visualFwk.visualConfig.multiStat__textSize.auto },
                    { key: 'small', text: t.visualFwk.visualConfig.multiStat__textSize.small },
                    { key: 'large', text: t.visualFwk.visualConfig.multiStat__textSize.large },
                ],
                (o) => o.get('multiStat__textSize'),
                (v, m) => m.set('multiStat__textSize', v)
            );
        },

        heatMap__dataColumn(t: KweVisualFwkLocale) {
            return createTileInput.column<'heatMap__dataColumn'>(
                'heatMap__dataColumn',
                t.visualFwk.visualConfig.heatMap__dataColumnLabel
            );
        },

        // TODO: ADD BACK
        // Only input that does not operate on the model
        // visualTypeVariant: visualTypeVariantInput,
    };
}
