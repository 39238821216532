import * as React from 'react';
import { createContext, useContextSelector } from '@fluentui/react-context-selector';
const listboxContextDefaultValue = {
    activeOption: undefined,
    focusVisible: false,
    multiselect: false,
    registerOption () {
        return ()=>undefined;
    },
    selectedOptions: [],
    onOptionClick () {
    // noop
    },
    onActiveDescendantChange () {
    // noop
    },
    selectOption () {
    // noop
    },
    setActiveOption () {
    // noop
    }
};
export const ListboxContext = createContext(undefined);
export const useListboxContext_unstable = (selector)=>useContextSelector(ListboxContext, (ctx = listboxContextDefaultValue)=>selector(ctx));
export const ListboxProvider = ListboxContext.Provider;
