/*** Not ideal to keep this in a library, but as long as the client library is used internally only and in the same repo, it is a good place to hold this data to enforce consistency.  */
export type ClientRequestSource = 'VirtualCluster' | 'Query' | 'Dashboards' | 'QuickQuery' | 'QuerySet' | 'Ingestion';

/*** Not ideal to keep this in a library, but as long as the client library is used internally only and in the same repo, it is a good place to hold this data to enforce consistency  */
export type AppId = 'RTA' | 'KWE';

export interface XMsClientRequestIdParams {
    /**
     * The request's source
     */
    source: ClientRequestSource;

    /**
     * A session Id.
     * For KWE it is an ID that is persisted for the lifetime of the app.
     * It is visible in Settings -> About and sent with each telemetry.
     */
    sessionId: string;

    /**
     * An identification for the App.
     */
    appId: string;

    /**
     * additional data to add after the session-id. Should be in order of volatility.
     */
    additionalData?: string[];
}

export interface XMsAppNameParams {
    /**
     * The app's version e.g. 2.113.0-2
     */
    appVersion: string;

    /**
     * An identification for the App.
     */
    appId: string;

    /**
     * Top frame domain
     */
    topFrameDomain?: string;
}

/**
 * Generate value for x-ms-app.
 * Format: Kusto.Web.<app-name>:<version>| [ | topFrameDomain:<top-frame-domain-value>]
 */
export function buildAppName(appNameParams: XMsAppNameParams): string {
    const array: string[] = [`Kusto.Web.${appNameParams.appId}:${appNameParams.appVersion}`];
    if (appNameParams.topFrameDomain) {
        array.push(`embeddedIn:${appNameParams.topFrameDomain}`);
    }
    return array.join('|');
}

/**
 * Generate value for x-ms-client-request-id.
 * Format: <ShortPrefix/Source>; <SessionId>; [additional-data]; <RequestGuid>
 * Format: Kusto.web.<app-name>.<source>; <SessionId>; [additional-data]; <RequestGuid>
 */
export function buildClientRequestId(clientRequestIdParams: XMsClientRequestIdParams): string {
    const executionId = process.env.NODE_ENV === 'test' ? 'TEST-EXECUTION-ID' : crypto.randomUUID();
    const additionalData = clientRequestIdParams.additionalData ?? [];
    const prefix = `Kusto.Web.${clientRequestIdParams.appId}.${clientRequestIdParams.source}`;
    return [prefix, clientRequestIdParams.sessionId, ...additionalData, executionId].join(';');
}
