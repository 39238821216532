import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { getTelemetryClient } from '@kusto/query';
import { castToError } from '@kusto/utils';

const { trackTrace, trackException } = getTelemetryClient({
    component: 'interactionInProgressWorkaround',
    flow: '',
});

/**
 * Workaround for bug https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4295
 * "Login fails with interaction_in_progress error"
 * This method should be called after handleRedirectPromise
 */
export const interactionInProgressWorkaround = () => {
    try {
        deleteFromCookieStorage();
        deleteFromLocalStorage();
    } catch (ex: unknown) {
        trackException(castToError(ex), 'interactionInProgressWorkaround');
    }
};

const deleteFromLocalStorage = () => {
    // Iterate over localStorage and delete msal.<guid>.interaction.status
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (
            key &&
            key.startsWith('msal.') &&
            key.endsWith('.interaction.status') &&
            localStorage.getItem(key) === 'interaction_in_progress'
        ) {
            localStorage.removeItem(key);
            return;
        }
    }
};

const deleteFromCookieStorage = () => {
    const cookieName = 'msal.interaction.status';
    deleteCookie(cookieName, '/');
};

const deleteCookie = (name: string, path?: string, domain?: string) => {
    if (isCookieExist(name)) {
        const deleteCookieVal =
            `${name}=` +
            (path ? `;path=${path}` : '') +
            (domain ? `;domain=${domain}` : '') +
            ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
        document.cookie = deleteCookieVal;
        if (isCookieExist(name)) {
            trackTrace(`deleteCookie: failed to delete cookie`, SeverityLevel.Warning, { cookie: name });
        } else {
            trackTrace(`deleteCookie: cookie deleted successfully`, SeverityLevel.Verbose, { cookie: name });
        }
    }
};

const isCookieExist = (name: string) => {
    const cookies = document.cookie;
    return cookies.split(';').some((cookie) => cookie.trim().startsWith(`${name}=`));
};
