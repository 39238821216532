import React from 'react';
import { observer } from 'mobx-react-lite';

import * as Fwk from '@kusto/visual-fwk';

import { KweRtdVisualContext } from '../../context';
import { markdown_getInferredTableValue, Markdown_Heuristics } from '../MarkdownVisual/heuristics';
import { PlotlyIframe } from './iframe';
import { SupportedPlotlyVersion, versions } from './versions';

import * as styles from './styles.module.scss';

const PLOTLY_DOCS_LINK = 'https://plotly.com/chart-studio-help/json-chart-schema/';

const plotlyVersionOptions = Object.entries(versions.version).map(([majorVersion, exactVersion]) => ({
    key: majorVersion,
    text: `${majorVersion}: (v${exactVersion})`,
})) as ReadonlyArray<{ key: SupportedPlotlyVersion; text: string }>;

const model = {
    xColumn: null,
    plotly__version: versions.defaultVersion,
} as const;

type ModelDef = keyof typeof model;

export function plotlyVisualConfig(
    ctx: KweRtdVisualContext
): Fwk.VisualTypeConfig<ModelDef, undefined | Markdown_Heuristics> {
    return {
        label: ctx.strings.rtdProvider.visuals.plotly.name,
        iconName: 'PieDouble', // TODO(plotly): plotly icon
        config: {
            model,
            defaultSize: { width: 9, height: 7 },
            minimumSize: { width: 4, height: 4 },
            inputLayout: {
                visual: {
                    head: [
                        Fwk.createTileInput.column(
                            'xColumn',
                            ctx.strings.rtdProvider.visuals.plotly.columnDropdownLabel,
                            {
                                selectInferColumn: (options) => options.getHeuristics()?.inferColumnName,
                                selectOptionDisabled: (column) => column.type !== 'string',
                                selectErrorMessage: (options) => {
                                    const e = options.getHeuristics()?.value.err;
                                    return !e?.columnError ? undefined : e.value;
                                },
                            }
                        ),
                        Fwk.createTileInput.staticDropdown(
                            ['plotly__version'],
                            ctx.strings.rtdProvider.visuals.plotly.versionDropdownLabel,
                            plotlyVersionOptions,
                            (o) => o.get('plotly__version'),
                            (v, m) => m.set('plotly__version', v)
                        ),
                        {
                            id: 'plotly-visual--description',
                            keys: [],
                            Component: () => (
                                <p className={styles.description}>
                                    {ctx.strings.rtdProvider.visuals.plotly.description}{' '}
                                    <a href={PLOTLY_DOCS_LINK} target="_blank" rel="noreferrer">
                                        {ctx.strings.rtdProvider.visuals.plotly.docsLink}
                                    </a>
                                </p>
                            ),
                        },
                    ],
                },
            },
            heuristics: (props) => {
                const result = props.queryResult;

                if (!result) {
                    return undefined;
                }

                return markdown_getInferredTableValue(
                    ctx.strings.rtdProvider.visuals.plotly,
                    result.dataFrame,
                    props.visualOptions.xColumn
                );
            },
            Component: observer(function PlotlyVisual(props) {
                const { heuristics: heuristicsProp, queryResult, visualOptions, formatMessage } = props;

                const heuristics = React.useMemo(
                    () =>
                        heuristicsProp ??
                        markdown_getInferredTableValue(
                            ctx.strings.rtdProvider.visuals.plotly,
                            queryResult.dataFrame,
                            visualOptions.xColumn
                        ),
                    [heuristicsProp, queryResult, visualOptions]
                );

                if (heuristics.value.kind === 'err') {
                    return formatMessage({ message: heuristics.value.err.value, level: 'error' });
                }

                const sdlc = heuristics.value.value;

                if (!sdlc) {
                    return null;
                }

                // TODO: Investigate if we can run out of webgl contexts using lots of Plotly scattergl tiles.
                // There's a fix for this in v2.28.0 https://github.com/plotly/plotly.js/pull/6784
                // where we'd need to install virtual-webgl and Plotly will use that instead of the real webgl
                // contexts when rendering visuals. However, would that even work considering each Plotly tile
                // is in its own iframe?
                //
                // Work item: https://msazure.visualstudio.com/DefaultCollection/One/_workitems/edit/28249729
                //
                // References:
                // https://stackoverflow.com/a/59148816/24948672
                // https://stackoverflow.com/a/61278833/24948672
                //
                // Curious here because each Plotly tile is iframe-d so they're isolated BUT would they still
                // be restricted by the global number of webgl contexts the Browser can hand out for that browser tab?
                return (
                    <PlotlyIframe
                        ctx={ctx}
                        formatMessage={formatMessage}
                        sdlc={sdlc}
                        isDarkTheme={props.isDarkTheme}
                        version={visualOptions.plotly__version}
                    />
                );
            }),
        },
    };
}
