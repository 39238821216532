import { ColorPaletteKey, COLORS_FOR_PALETTE } from './colors';

export interface HeatmapModel {
    xColumn: null;
    yColumns: null;
    xColumnTitle: string;
    yColumnTitle: string;
    heatMap__dataColumn: null;
    heatMap__colorPaletteKey: ColorPaletteKey;
}

export const heatmapModel: HeatmapModel = {
    xColumn: null,
    yColumns: null,
    xColumnTitle: '',
    yColumnTitle: '',
    heatMap__dataColumn: null,
    heatMap__colorPaletteKey: COLORS_FOR_PALETTE.BLUE,
};

export type HeatmapModelDef = keyof HeatmapModel;
