import * as React from 'react';
import { usePopover_unstable } from './usePopover';
import { renderPopover_unstable } from './renderPopover';
/**
 * Wrapper component that manages state for a PopoverTrigger and a PopoverSurface components.
 */ export const Popover = (props)=>{
    const state = usePopover_unstable(props);
    return renderPopover_unstable(state);
};
Popover.displayName = 'Popover';
