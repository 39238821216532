import React from 'react';
import { Dropdown, Option } from '@fluentui/react-components';

import { useStrings } from '../../../../hooks/useStrings';
import { AggregationGroup, TimespanUnit } from '../../../../types/Pills.interfaces';
import { PopoverField } from './PopoverField';

const TimeSpanValues: NonNullable<AggregationGroup['timespan']>[] = [
    { value: 5, unit: 's' },
    { value: 30, unit: 's' },
    { value: 1, unit: 'm' },
    { value: 5, unit: 'm' },
    { value: 30, unit: 'm' },
    { value: 60, unit: 'm' },
    { value: 2, unit: 'h' },
    { value: 1, unit: 'd' },
];

const EMPTY_VALUE = '$$EMPTY$$';
const VALUE_DIVIDER = '||';

export interface TimespanDropdownProps {
    selectedTimespan?: AggregationGroup['timespan'];
    onOptionSelect: (timespan: AggregationGroup['timespan']) => void;
    hideLabel?: boolean;
}

export const TimespanDropdown: React.FunctionComponent<TimespanDropdownProps> = ({
    hideLabel,
    selectedTimespan,
    onOptionSelect,
}) => {
    const { strings } = useStrings();

    const getTimespanString = (timespan: NonNullable<AggregationGroup['timespan']>) =>
        `${timespan.value} ${strings.dataExplorationModal.shared.timeUnits[timespan.unit]}`;

    return (
        <PopoverField
            label={strings.dataExplorationModal.pillsBar.timespanDropdownLabel}
            labelId="timespan-dropdown"
            hideLabel={hideLabel}
            optional
        >
            <Dropdown
                aria-labelledby="timespan-dropdown"
                placeholder={strings.dataExplorationModal.pillsBar.timespanDropdownPlaceholder}
                defaultValue={selectedTimespan ? getTimespanString(selectedTimespan) : undefined}
                defaultSelectedOptions={
                    selectedTimespan ? [`${selectedTimespan.value}${selectedTimespan.unit}`] : undefined
                }
                onOptionSelect={(e, { optionValue }) => {
                    if (!optionValue) {
                        return;
                    }

                    if (optionValue === EMPTY_VALUE) {
                        onOptionSelect(undefined);
                        return;
                    }

                    const [value, unit] = optionValue.split(VALUE_DIVIDER);
                    onOptionSelect({
                        value: parseInt(value),
                        unit: unit as TimespanUnit,
                    });
                }}
            >
                <Option value={EMPTY_VALUE}>{strings.dataExplorationModal.pillsBar.timespanDropdownEmpty}</Option>
                {TimeSpanValues.map((timespan, i) => (
                    <Option key={i} value={`${timespan.value}${VALUE_DIVIDER}${timespan.unit}`}>
                        {getTimespanString(timespan)}
                    </Option>
                ))}
            </Dropdown>
        </PopoverField>
    );
};
