import { getMover, MoverDirections } from 'tabster';
import { useTabsterAttributes } from './useTabsterAttributes';
import { useTabster } from './useTabster';
/**
 * A hook that returns the necessary tabster attributes to support arrow key navigation
 * @param options - Options to configure keyboard navigation
 */ export const useArrowNavigationGroup = (options = {})=>{
    const { circular, axis, memorizeCurrent = true, tabbable, ignoreDefaultKeydown, // eslint-disable-next-line @typescript-eslint/naming-convention
    unstable_hasDefault } = options;
    const tabster = useTabster();
    if (tabster) {
        getMover(tabster);
    }
    return useTabsterAttributes({
        mover: {
            cyclic: !!circular,
            direction: axisToMoverDirection(axis !== null && axis !== void 0 ? axis : 'vertical'),
            memorizeCurrent,
            tabbable,
            hasDefault: unstable_hasDefault
        },
        ...ignoreDefaultKeydown && {
            focusable: {
                ignoreKeydown: ignoreDefaultKeydown
            }
        }
    });
};
function axisToMoverDirection(axis) {
    switch(axis){
        case 'horizontal':
            return MoverDirections.Horizontal;
        case 'grid':
            return MoverDirections.Grid;
        case 'grid-linear':
            return MoverDirections.GridLinear;
        case 'both':
            return MoverDirections.Both;
        case 'vertical':
        default:
            return MoverDirections.Vertical;
    }
}
