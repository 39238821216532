@use '../variables.scss' as *;

$left-right-content-padding: 16px;
$borderColor: var(--neutralLight);

.optionsListBody {
    flex: 0 1 auto;
    min-height: 0;
    padding: 0 $left-right-content-padding 8px $left-right-content-padding;

    overflow: auto;

    display: grid;
    // grid-auto-flow: column;
    grid-template-columns: minmax(0, 1fr);
    // Important that we use gap here, so individual elements can apply there
    // own margins to adjust spacing optionally.
    gap: $space-between-options;

    // Variables for visual options
    --border-color: var(--neutralLight);
    --left-right-padding: #{$left-right-content-padding};
    --option-spacing: #{$space-between-options};
}

.sectionDivider {
    // At 150% monitor scaling (1 browser pixels = 1.5 actual pixels) & and
    // a 1px height, Chrome sometimes makes this 2px and sometimes makes this 1px
    // https://stackoverflow.com/questions/42710882/css-border-1px-appear-as-0-667px-or-1px-depending-on-the-computer-display-res
    height: 1px;
    background-color: var(--neutralTertiary);

    // Remove <hr /> border and margin styles
    border: none;
    margin: 0;
}

.collapseButton {
    padding: 0 $left-right-content-padding;
    margin-bottom: 12px !important;
    flex: 0 0 auto;
    font-weight: 400 !important;
    width: max-content;
    justify-content: flex-start !important;
}
