.configItem {
    display: grid;
    grid-template-columns: [color] auto [content] 1fr [edit] auto [delete] auto [extra-content] auto;
}

.color {
    grid-area: color;
}

.content {
    grid-area: content;
}

.extra-content {
    grid-area: extra-content;
}

.edit {
    grid-area: edit;
    margin-left: auto;
}

.remove {
    grid-area: delete;
    margin-left: auto;
}
