import React from 'react';
import { concatStyleSets, IPanelProps, IPanelStyles, Panel } from '@fluentui/react';
import { observer } from 'mobx-react-lite';

import { useQueryCore } from '../core/core';

// clipPath: 'polygon(-30px 0, 100% 0, 100% 100%, -30px 100%);'
const fabricStyle = {
    root: {
        top: 48,
        // clip-path is not supported by office fabric typings, thus using the string value.
        'clip-path': 'polygon(-30px 0, 100% 0, 100% 100%, -30px 100%)',
    },
} as Partial<IPanelStyles>;

/**
 * A right side panel that takes into account web explorer specific UX such as whether we're
 * displaying a header. if we are - the panel will not be displayed over the header, but start below.
 * @param props Same as fabric panel props
 */
export const RightSidePanel = observer(function RightSidePanel(props: IPanelProps) {
    const core = useQueryCore();
    if (core.featureFlags.ShowPageHeader) {
        const styles = concatStyleSets(props.styles, fabricStyle);
        return <Panel {...props} styles={styles} />;
    } else {
        return <Panel {...props} />;
    }
});
