import {
    ArrowSortFilled,
    ArrowSortRegular,
    bundleIcon,
    CalendarFilled,
    CalendarRegular,
    CodeFilled,
    CodeRegular,
    ColumnArrowRightFilled,
    ColumnArrowRightRegular,
    ColumnFilled,
    ColumnRegular,
    FilterFilled,
    FilterRegular,
    GroupListFilled,
    GroupListRegular,
    TableArrowUpFilled,
    TableArrowUpRegular,
    TableFilled,
    TableRegular,
    TextNumberListLtrFilled,
    TextNumberListLtrRegular,
} from '@fluentui/react-icons';
import ColumnAddIcon from 'jsx:./OperatorsIcons/ColumnAdd.svg'; // extend
import ColumnSingleIcon from 'jsx:./OperatorsIcons/ColumnSingle.svg'; // distinct

import { Icon } from '@kusto/ui-components';

import { Pill } from '../types/Pills.interfaces';
import { pipeOperator } from './kqlParser';

export const TableIcon = bundleIcon(TableFilled, TableRegular); // Source (Table1)
export const CodeIcon = bundleIcon(CodeFilled, CodeRegular); // other/default

const FilterIcon = bundleIcon(FilterFilled, FilterRegular); // where
const GroupListIcon = bundleIcon(GroupListFilled, GroupListRegular); // summarize
const CalendarIcon = bundleIcon(CalendarFilled, CalendarRegular); // where Time between
const ColumnIcon = bundleIcon(ColumnFilled, ColumnRegular); // project
const TextNumberListLtrIcon = bundleIcon(TextNumberListLtrFilled, TextNumberListLtrRegular); // count
const ArrowSortIcon = bundleIcon(ArrowSortFilled, ArrowSortRegular); // sort
const ColumnArrowRightIcon = bundleIcon(ColumnArrowRightFilled, ColumnArrowRightRegular); // join
const TableArrowUpIcon = bundleIcon(TableArrowUpFilled, TableArrowUpRegular); // make-series

export function getUserPillIcon(pill: Pill): undefined | Icon {
    switch (pill.type) {
        case 'filter':
            return FilterIcon;
        case 'aggregation':
            return GroupListIcon;
        case 'limit':
            return undefined; // 'limit' not supported yet!
        case 'datetime':
            return CalendarIcon;
    }
}

export function getSystemPillIcon(pipe: string): Icon {
    const operator = pipeOperator(pipe);
    switch (operator) {
        case 'where':
            return FilterIcon;
        case 'summarize':
            return GroupListIcon;
        case 'extend':
            return ColumnAddIcon;
        case 'project':
            return ColumnIcon;
        case 'count':
            return TextNumberListLtrIcon;
        case 'distinct':
            return ColumnSingleIcon;
        case 'sort':
            return ArrowSortIcon;
        case 'join':
            return ColumnArrowRightIcon;
        case 'make-series':
            return TableArrowUpIcon;
        case 'take':
        case 'top':
        case 'union':
        case 'range':
        case 'lookup':
        case 'mv-expand':
        case 'parse':
        default:
            return CodeIcon;
    }
}
