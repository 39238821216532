import React from 'react';
// lodash import is restricted to avoid importing the entire library, so
// type-only imports are safe
// eslint-disable-next-line no-restricted-imports
import type { DebouncedFunc } from 'lodash';

import type { Dispose } from '../types';

/**
 * @param listener If a promise is returned, listener blocks with that
 * promise
 */
export type RegisterFlush = (listener: () => unknown) => Dispose;

/**
 * Register lodash.debounce or lodash.throttle to a FlushController
 *
 * @example
 * import React from 'react';
 * import debounce from 'lodash/debounce';
 *
 * import { FlushController } from '@kusto/utils';
 *
 * const MyComponent: React.FC = () => {
 *   const flushController = React.useState(() => new FlushController())[0];
 *
 *   const myCallback = React.useMemo(() => debounce((event) => {}, 1000), []);
 *
 *   useRegisterFlush(flushController.register, myCallback);
 *
 *   return null;
 * }
 */
export function useRegisterDebounce(register: RegisterFlush, debounced: DebouncedFunc<() => unknown>) {
    React.useEffect(() => {
        const dispose = register(debounced.flush);
        return () => {
            debounced.cancel();
            dispose();
        };
    }, [debounced, register]);
}
