import * as React from 'react';
export function useComboboxContextValues(state) {
    const { appearance, open, registerOption, selectedOptions, selectOption, setOpen, size, activeDescendantController, onOptionClick, onActiveDescendantChange } = state;
    const combobox = {
        activeOption: undefined,
        appearance,
        focusVisible: false,
        open,
        registerOption,
        selectedOptions,
        selectOption,
        setActiveOption: ()=>null,
        setOpen,
        size
    };
    const listbox = {
        activeOption: undefined,
        focusVisible: false,
        registerOption,
        selectedOptions,
        selectOption,
        setActiveOption: ()=>null,
        onOptionClick,
        onActiveDescendantChange
    };
    const activeDescendant = React.useMemo(()=>({
            controller: activeDescendantController
        }), [
        activeDescendantController
    ]);
    return {
        combobox,
        activeDescendant,
        listbox
    };
}
