import React from 'react';

import { PillBarActions } from './PillBarActions/PillBarActions';
import { PillsList } from './PillsList';

import styles from './PillsBar.module.scss';

export const PillsBar: React.FunctionComponent = () => {
    return (
        <div className={styles.pillsBar}>
            <PillsList />
            <PillBarActions />
        </div>
    );
};
