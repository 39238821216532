/**
 *  App Main Pages Explorer (default), Ingestion
 *  Keeping "ingest" as an AppPage for backward compatibility. In practice it's the app page "oneClick" with "ingest" flow.
 */
export const deepLinkEnabledAppPages = {
    Explorer: '',
    Ingest: 'ingest',
    OneClick: 'oneclick',
    Trident: 'trident',
    VirtualCluster: 'freecluster',
    HomePage: 'home',
    OneClickIbiza: 'ingestIbiza',
} as const;

/**
 * The app pages that do not support Deep Linking
 */
export const deepLinkDisabledAppPages = {
    Dashboards: 'dashboards',
    GetStarted: 'getstarted',
    PublicVirtualCluster: 'publicfreecluster',
} as const;

/**
 *  App Main Pages Explorer (default), Ingestion, Dashboard, GetStarted
 */
export const appPages = {
    ...deepLinkDisabledAppPages,
    ...deepLinkEnabledAppPages,
};

// TypeScript will export this type at the same time as the object above, keeping consistency with normal
// enum usage
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AppPage = (typeof appPages)[keyof typeof appPages];
