import * as React from 'react';
import {
    Button,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Overflow,
    OverflowItem,
    tokens,
    useIsOverflowItemVisible,
    useOverflowMenu,
} from '@fluentui/react-components';
import { MoreHorizontal20Regular } from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';

import { useQueryCore } from '../../core/core';
import { ContentViewSelectionMenu } from './ContentViewMenu';
import { ExpandSelectionMenu } from './ExpandViewMenu';
import { JPathCommandBarProps } from './QueryResultsBar';

import * as styles from './QueryResultsBar.module.scss';

/*
 * An item inside the overflow menu
 * Renders the menu-item, only if the actual item is not visible
 */
const OverflowMenuItem: React.FC<{ item: JPathCommandBarProps }> = ({ item }) => {
    const isVisible = useIsOverflowItemVisible(item.id);

    if (isVisible) {
        return null;
    }

    return (
        <MenuItem>
            <item.component key={item.id} {...item.props} isMenuItem showLabel />
        </MenuItem>
    );
};
/*
 * The actual overflow menu
 * Being rendered only if there is an overflow
 */
const OverflowMenu: React.FC<{ items: JPathCommandBarProps[] }> = ({ items }) => {
    const { ref, isOverflowing } = useOverflowMenu<HTMLButtonElement>();

    if (!isOverflowing) {
        return null;
    }

    return (
        <Menu persistOnItemClick>
            <MenuTrigger disableButtonEnhancement>
                <Button
                    ref={ref}
                    icon={<MoreHorizontal20Regular primaryFill={tokens.colorPaletteBlueBorderActive} />}
                    appearance="subtle"
                />
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {items.map((item) => (
                        <OverflowMenuItem key={item.id} item={item} />
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

export const ExpandBarItems: React.FC<{ isCompactMode?: boolean }> = observer(function ExpandBarItems() {
    const core = useQueryCore();
    const items: JPathCommandBarProps[] = [
        {
            component: ContentViewSelectionMenu,
            id: 'ContentViewSelectionMenu',
            props: { showLabel: true },
        },
    ];

    if (core.store.tabs.tabInContext.isTableShown) {
        items.unshift({
            component: ExpandSelectionMenu,
            id: 'ExpandSelectionMenu',
            props: { showLabel: true },
        });
    }
    return (
        <Overflow>
            <div className={styles.overflowContainer}>
                {items.map((item) => (
                    <OverflowItem key={item.id} id={item.id}>
                        <div>
                            <item.component key={item.id} {...item.props} />
                        </div>
                    </OverflowItem>
                ))}
                <OverflowMenu items={items} />
            </div>
        </Overflow>
    );
});
