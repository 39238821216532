import React from 'react';
import {
    Button,
    makeStyles,
    Menu,
    MenuList,
    MenuPopover,
    MenuTrigger,
    mergeClasses,
    Spinner,
    tokens,
    Tooltip,
    Tree,
    TreeItem,
    TreeItemLayout,
} from '@fluentui/react-components';
import { ErrorCircleFilled, Info24Regular, MoreHorizontal20Regular } from '@fluentui/react-icons';

import { OverflowTooltip } from '../OverflowTooltip';
import { NestedTreeEntity, TreeItemEntityProps } from './types';

import styles from './TreeEntity.module.scss';

export const selectedTreeItemClass = styles.selectedTreeItem;

export const TreeItemWrapper: React.FC<TreeItemEntityProps> = React.memo(({ entity, entityMap, ...props }) => {
    const Component = entityMap?.[entity.entityType] ?? TreeEntity;
    return <Component key={entity.id + '_TreeItemWrapper'} entity={entity} entityMap={entityMap} {...props} />;
});

const useStyle = makeStyles({
    tooltipContent: {
        wordBreak: 'break-all',
        flexWrap: 'wrap',
        maxWidth: '300px',
    },
});

export const TextWithLink: React.FC<{ text: string }> = ({ text }): JSX.Element => {
    const styles = useStyle();
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Split the text into an array of segments based on the URL regex
    const segments = text.split(urlRegex);

    // Map each segment to either a text node or a link element
    const content = segments.map((segment: string, index: number) => {
        if (segment.match(urlRegex)) {
            // If the segment is a URL, create a link element
            return (
                <a key={index} href={segment} target="_blank" rel="noopener noreferrer">
                    {segment}
                </a>
            );
        } else {
            // If the segment is not a URL, render it as plain text
            return <span key={index}>{segment}</span>;
        }
    });

    return <div className={styles.tooltipContent}>{content}</div>;
};

export const getMarkedText = (text: string, highlightString: string) => {
    // add capture group to the regex since it causes split to add the matches to the array
    const regex = new RegExp(`(${highlightString})`, 'gi');
    const splitText = text.split(regex);
    const matches = text.match(regex);

    return splitText.map((part, i) => (
        <React.Fragment key={i}>{matches?.includes(part) ? <mark>{part}</mark> : <>{part}</>}</React.Fragment>
    ));
};

export const TreeEntity: React.FC<TreeItemEntityProps> = React.memo(
    ({ entity, icons, entityActionsMap, itemType, selectedItems, scrollIntoViewItem, highlightString, ...props }) => {
        const icon = icons?.[entity.entityType];
        const actions = entityActionsMap?.[entity.entityType]?.menuItems;
        const markedName = highlightString ? <>{getMarkedText(entity.name, highlightString)}</> : entity.name;
        const isSelected = Array.from(selectedItems ?? []).includes(entity.id);
        const ref = React.useRef<HTMLDivElement>(null);
        React.useEffect(() => {
            if (scrollIntoViewItem && entity.id === scrollIntoViewItem && ref?.current) {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }
        }, [entity.id, ref, scrollIntoViewItem]);

        const entityMenuItems = React.useMemo(() => {
            return (
                <>
                    {actions?.map((actionName, i) => {
                        const ActionComponent = actionName;
                        return <ActionComponent key={`[${i}.${actionName}`} {...entity} />;
                    })}
                </>
            );
        }, [actions, entity]);

        const actionsComponent = (
            <>
                {entity.docstring && (
                    <Tooltip
                        content={<TextWithLink text={entity.docstring} />}
                        relationship="label"
                        withArrow
                        hideDelay={500}
                    >
                        <Button icon={<Info24Regular />} appearance="transparent" />
                    </Tooltip>
                )}
                {actions?.length && (
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <Button
                                // TODO: add aria-label into i18n when localization is ready in ui-components pkg
                                aria-label="More options"
                                appearance="subtle"
                                icon={<MoreHorizontal20Regular />}
                            />
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>{entityMenuItems}</MenuList>
                        </MenuPopover>
                    </Menu>
                )}
            </>
        );
        const treeItemLayout = (
            <TreeItemLayout
                ref={ref}
                key={`${entity.id}-layout`}
                main={{ className: styles.treeItemLayoutMain }}
                className={mergeClasses(
                    styles.treeItemLayout,
                    isSelected ? styles.selectedTreeItem : styles.hoverTreeItem
                )}
                iconBefore={
                    <Button className={styles.treeItemIconBefore} appearance="transparent">
                        {['Validating', 'Loading'].includes(entity.status) ? <Spinner size="tiny" /> : icon}
                    </Button>
                }
                expandIcon={
                    entity.error ? (
                        <Tooltip content={entity.error} withArrow relationship="label">
                            <ErrorCircleFilled primaryFill={tokens.colorPaletteRedForeground1} />
                        </Tooltip>
                    ) : undefined
                }
                actions={actionsComponent}
            >
                <OverflowTooltip content={entity.name} withArrow>
                    <>{markedName}</>
                </OverflowTooltip>
            </TreeItemLayout>
        );
        return (
            <TreeItem
                key={`${entity.id}-treeItem`}
                itemType={itemType ?? entity.itemType ?? 'branch'}
                value={entity.id}
            >
                {actions?.length ? (
                    <Menu positioning="below-end" openOnContext>
                        <MenuTrigger disableButtonEnhancement>{treeItemLayout}</MenuTrigger>
                        <MenuPopover>
                            <MenuList>{entityMenuItems}</MenuList>
                        </MenuPopover>
                    </Menu>
                ) : (
                    treeItemLayout
                )}
                {!entity.error && (
                    <Tree key={`${entity.id}-treeEntityNestedTree`} aria-label="Tree">
                        {entity.subtree.map((child: NestedTreeEntity) => (
                            <TreeItemWrapper
                                key={`${child.id}-treeItemWrapper`}
                                entity={child}
                                icons={icons}
                                entityActionsMap={entityActionsMap}
                                selectedItems={selectedItems}
                                highlightString={highlightString}
                                scrollIntoViewItem={scrollIntoViewItem}
                                {...props}
                            />
                        ))}
                    </Tree>
                )}
            </TreeItem>
        );
    }
);
