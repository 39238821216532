// Styling for a single label and input wrapped in an element
.basicInput {
    // Avoids extra whitespace
    display: flex;
    flex-direction: column;
    // Avoid top level margins by default, container is already applying a gap
    margin: 0;
    label {
        font-weight: 600;
        padding: 0;
        // Top and bottom have 3 subtracted to compensate for light height
        margin: -3px 0 5px 0;
        font-size: 14px;
        line-height: 20px;
    }
}
