import type { CrossFilterProperty, DrillthroughConfig, DrillthroughPair } from '../../visualOptions';

export interface ReadyDrillthrough extends DrillthroughConfig {
    readonly disabled: false;
}

/**
 * Important: This check does NOT guarantee that ALL pairs are ready.
 * If at least one of the pairs is ready then we will consider the entire config
 * ready for consumption.
 *
 * If you absolutely need all of the pairs to be ready then use
 * `drillthroughPairIsReady` like so:
 *
 * @example
 * ```typescript
 * const readyPairs = drillthroughConfig.pairs.filter(Rtd.drillthroughPairIsReady);
 * ```
 */
export function drillthroughIsReady(drillthrough: DrillthroughConfig): drillthrough is ReadyDrillthrough {
    const isPairsReady = drillthrough.pairs.length !== 0 && drillthrough.pairs.some((p) => drillthroughPairIsReady(p));

    return !drillthrough.disabled && isPairsReady && drillthrough.destinationPages.size > 0;
}

export interface ReadyDrillthroughPair extends DrillthroughPair {
    readonly property: CrossFilterProperty;
    readonly parameterId: string;
}

export function drillthroughPairIsReady(pair: DrillthroughPair): pair is ReadyDrillthroughPair {
    return pair.parameterId !== undefined && pair.property !== undefined;
}
