import * as React from 'react';
import PerfectScrollbar, { ScrollBarProps as PerfectScrollbarProps } from 'react-perfect-scrollbar';

import { Theme } from '@kusto/utils';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './Scroll.scss';

export interface ScrollbarProps extends PerfectScrollbarProps {
    theme: Theme;
}

export const Scrollbar: React.FC<ScrollbarProps> = ({ theme, children, ...perfectScrollbarProps }) => {
    return (
        <PerfectScrollbar {...perfectScrollbarProps} className={theme.toLocaleLowerCase()}>
            {children}
        </PerfectScrollbar>
    );
};
