import React from 'react';
import { Text } from '@fluentui/react-components';

import { DataExplorationStrings } from '../../context/DataExplorationContext';
import {
    AggregationOperator,
    BinaryFilterPillData,
    BinaryOperator,
    isBinaryOperator,
    Pill,
    UnaryOperator,
} from '../types/Pills.interfaces';
import { pipeOperator } from './kqlParser';

/** Translate filter operators (like `<=`, `startswith`) to a user readable text (like: "Greater than or equals") */
export function translateFilterOperator(
    operator: BinaryOperator | UnaryOperator,
    strings: DataExplorationStrings
): string {
    const filterOperators = strings.dataExplorationModal.shared.filterOperators;
    switch (operator) {
        case '==':
            return filterOperators.equals;
        case '!=':
            return filterOperators.notEquals;
        case '>':
            return filterOperators.greaterThan;
        case '>=':
            return filterOperators.greaterThanOrEquals;
        case '<':
            return filterOperators.lessThan;
        case '<=':
            return filterOperators.lessThanOrEquals;
        case 'contains':
            return filterOperators.contains;
        case '!contains':
            return filterOperators.notContains;
        case 'has':
            return filterOperators.has;
        case '!has':
            return filterOperators.notHas;
        case 'startswith':
            return filterOperators.startsWith;
        case '!startswith':
            return filterOperators.notStartsWith;
        case 'endswith':
            return filterOperators.endsWith;
        case '!endswith':
            return filterOperators.notEndsWith;
        case 'isnull':
            return filterOperators.isnull;
        case 'isnotnull':
            return filterOperators.isnotnull;
        case 'isempty':
            return filterOperators.isempty;
        case 'isnotempty':
            return filterOperators.isnotempty;
        default:
            return operator;
    }
}

/** Translate aggregation operators (like `count`, `sum`) to a user readable text (like: "Count number of rows") */
export function translateAggregationOperator(operator: AggregationOperator, strings: DataExplorationStrings): string {
    const aggregationOperators = strings.dataExplorationModal.shared.aggregationOperators;
    switch (operator) {
        case 'count':
            return aggregationOperators.count;
        case 'dcount':
            return aggregationOperators.count;
        case 'max':
            return aggregationOperators.max;
        case 'min':
            return aggregationOperators.min;
        case 'sum':
            return aggregationOperators.sum;
        case 'avg':
            return aggregationOperators.avg;
        default:
            return operator;
    }
}

/** Returns the text that appear on the pill */
export function getPillText(pill: Pill, strings: DataExplorationStrings): string | JSX.Element {
    switch (pill.type) {
        case 'filter': {
            const { column, operator } = pill.data;

            return (
                // State isnull / State == 'TEXAS'
                <>
                    {`${column.ColumnName} ${operator} `}
                    {isBinaryOperator(operator) ? (
                        <Text weight="semibold">{(pill.data as BinaryFilterPillData).value}</Text>
                    ) : null}
                </>
            );
        }

        case 'aggregation': {
            const groupByString = strings.dataExplorationModal.shared.groupBy.by;

            const { functions, groups } = pill.data;

            const firstFunc = functions[0];
            const operatorLabel = `${firstFunc.operator}(${firstFunc.column?.ColumnName ?? ''})`;
            const groupByLabel = groups.length
                ? `${groupByString} ${groups.map((group) => group.column.ColumnName).join(', ')}`
                : '';

            return (
                // sum(Source) by Product, Region / count() by Product
                <Text weight="semibold">
                    {operatorLabel} {groupByLabel}
                </Text>
            );
        }

        case 'datetime': {
            const { start, end } = pill.data;

            const startString = start.toLocaleString('default', { month: 'short' }) + ' ' + start.getFullYear();
            const endString = end.toLocaleString('default', { month: 'short' }) + ' ' + end.getFullYear();

            // Jan 2021 - Feb 2021
            return `${startString} - ${endString}`;
        }

        case 'limit': {
            throw new Error('PillText: Limit pill is not supported yet');

            // const { max } = pill.data;
            // return `Limit to ${max} rows`;
        }
    }
}

/** Create a user readable text that describe the pill */
export function getPillDescription(pill: Pill, strings: DataExplorationStrings): string {
    switch (pill.type) {
        case 'filter': {
            const { column, operator } = pill.data;

            const operatorLabel = `${column.ColumnName} ${translateFilterOperator(operator, strings).toLowerCase()}`;
            const valueLabel = isBinaryOperator(operator) ? (pill.data as BinaryFilterPillData).value : '';

            return (
                // State Is null / State equals 'TEXAS'
                `${operatorLabel} ${valueLabel}`
            );
        }

        case 'aggregation': {
            const groupByString = strings.dataExplorationModal.shared.groupBy.groupBy;

            const { functions, groups } = pill.data;

            const firstFunc = functions[0];
            const operatorLabel = `${translateAggregationOperator(firstFunc.operator, strings)}${
                firstFunc.column?.ColumnName ? ` '${firstFunc.column?.ColumnName}'` : ''
            }`;
            const groupByLabel = groups.length
                ? `${groupByString} ${groups.map((group) => `'${group.column.ColumnName}'`).join(', ')}`
                : '';

            return (
                // Sum of 'Source' group by 'Product', 'Region' / Count number of rows group by 'Product'
                `${operatorLabel} ${groupByLabel}`
            );
        }

        case 'datetime': {
            const { start, end } = pill.data;

            const startString = start.toLocaleString('default', { month: 'short' }) + ' ' + start.getFullYear();
            const endString = end.toLocaleString('default', { month: 'short' }) + ' ' + end.getFullYear();

            // Jan 2021 - Feb 2021
            return `${startString} - ${endString}`;
        }

        case 'limit': {
            throw new Error('PillDescription: Limit pill is not supported yet');

            // const { max } = pill.data;
            // return `Limit to ${max} rows`;
        }
    }
}

/** Returns text message that describe the pipe's operator. */
export function getPipeDescription(pipe: string, strings: DataExplorationStrings): string {
    const kqlOperatorsStr = strings.dataExplorationModal.shared.kqlOperators;

    const operator = pipeOperator(pipe);
    switch (operator) {
        case 'where':
            return kqlOperatorsStr.where;
        case 'summarize':
            return kqlOperatorsStr.summarize;
        case 'extend':
            return kqlOperatorsStr.extend;
        case 'project':
            return kqlOperatorsStr.project;
        case 'count':
            return kqlOperatorsStr.count;
        case 'distinct':
            return kqlOperatorsStr.distinct;
        case 'sort':
            return kqlOperatorsStr.sort;
        case 'take':
            return kqlOperatorsStr.take;
        case 'top':
            return kqlOperatorsStr.top;
        case 'join':
            return kqlOperatorsStr.join;
        case 'union':
            return kqlOperatorsStr.union;
        case 'range':
            return kqlOperatorsStr.range;
        case 'lookup':
            return kqlOperatorsStr.lookup;
        case 'mv-expand':
            return kqlOperatorsStr.mvExpand;
        case 'parse':
            return kqlOperatorsStr.parse;
        case 'make-series':
            return kqlOperatorsStr.makeSeries;
        default:
            return kqlOperatorsStr.default;
    }
}
