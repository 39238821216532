import React from 'react';
import { Spinner } from '@fluentui/react';
import noop from 'lodash/noop';
import { observer } from 'mobx-react-lite';

import { assertNever } from '@kusto/utils';

import { narrowVisualOptions } from '../narrowVisualOptions';
import type { InternalParsedVisual, ParsedVisuals } from '../parseVisuals';
import type { VisualFwkQueryResult } from '../queryResult';
import type { KweVisualFwkLocale } from '../types';
import type {
    DataVisualPropsQueryResult,
    IDataVisualProps,
    VisualHtmlErrorId,
    VisualMessageFormatter,
} from '../visualConfig';
import type { UnknownVisualOptions, VisualOptionKey } from '../visualOptions';
import { UnsupportedVisualType } from './UnsupportedVisualType';
import { VisualMessage } from './VisualMessage';

import * as styles from './KweVisual.module.scss';

export const DataVisualSpinner: React.FC<{ t: KweVisualFwkLocale }> = ({ t }) => {
    return (
        <Spinner
            className={styles.loadingSpinner}
            label={t.utils.util.status.loading}
            data-testid="visual-spinner"
            ariaLive="assertive"
        />
    );
};

export interface QueryResultErrorsProps {
    t: KweVisualFwkLocale;
    queryResult: VisualFwkQueryResult;
    renderSuccess(result: DataVisualPropsQueryResult): React.ReactElement;
    /**
     * TODO: Consider making this not customizable
     */
    renderLoading?(): React.ReactElement;
    htmlErrorId?: VisualHtmlErrorId;
    messageHeaderLevel?: 1 | 2 | 3 | 4 | 5;
    /**
     * This callback allows this component to avoid knowing about props passed to the data visual
     */
    renderErrorBoundary: (wrappedComponent: React.ReactChild) => React.ReactElement;
}

export function QueryResultErrors({
    t,
    queryResult,
    htmlErrorId,
    messageHeaderLevel = 2,
    renderLoading = () => <DataVisualSpinner t={t} />,
    renderErrorBoundary,
    renderSuccess,
}: QueryResultErrorsProps) {
    switch (queryResult.kind) {
        case 'err':
            // TODO(multiple errors)
            const err = 'length' in queryResult.err ? queryResult.err[0] : queryResult.err;
            return (
                <VisualMessage
                    headerLevel={messageHeaderLevel}
                    htmlErrorId={htmlErrorId}
                    title={err.title}
                    message={err.body}
                    level={err.level}
                />
            );
        case 'loading':
            return renderLoading();
            return <DataVisualSpinner t={t} />;
        case 'ok':
            // Error boundary is applied here so it catches heuristics crashes
            return renderErrorBoundary(renderSuccess(queryResult.value));
        default:
            assertNever(queryResult);
    }
}

function formatMessageDefault(
    headerLevel: 1 | 2 | 3 | 4 | 5 = 2,
    htmlErrorId?: VisualHtmlErrorId
): VisualMessageFormatter {
    return (message) => (
        <VisualMessage
            level={message.level}
            message={message.message}
            title={message.title}
            options={message.options}
            headerLevel={headerLevel}
            htmlErrorId={htmlErrorId}
        />
    );
}

export interface KweResolvedVisualProps<C extends VisualOptionKey = VisualOptionKey, H = unknown>
    extends Omit<
        IDataVisualProps<C, H>,
        'heuristics' | 'visualType' | 'visualOptions' | 'formatMessage' | 'setResultCounts'
    > {
    visualType: string;
    options?: UnknownVisualOptions;
    htmlErrorId?: VisualHtmlErrorId;
    headerLevel?: 1 | 2 | 3 | 4 | 5;
    setResultCounts?: IDataVisualProps['setResultCounts'];
    formatMessage?: VisualMessageFormatter;
    config: InternalParsedVisual<C, H>;
    heuristics?: H;
}

/**
 * Compared to calling the visual directly, this component will:
 * - Narrow visuals options
 * - Resolve heuristics
 * - Persist heuristics results across renders
 */
export function KweResolvedVisual<C extends VisualOptionKey = VisualOptionKey, H = unknown>({
    visualType,
    options,
    queryResult,
    setResultCounts = noop,
    heuristics: maybeHeuristics,
    config,
    htmlErrorId,
    headerLevel,
    formatMessage = formatMessageDefault(headerLevel, htmlErrorId),
    ...passthrough
}: KweResolvedVisualProps<C, H>) {
    const narrowedOptions = React.useMemo(
        () => (options ? narrowVisualOptions(config, options) : config.model),
        [config, options]
    );

    const ref = React.useRef<undefined | H>(undefined);

    const heuristics = React.useMemo(
        () =>
            maybeHeuristics ??
            config.heuristics(
                {
                    visualType: visualType,
                    visualOptions: narrowedOptions,
                    queryResult,
                },
                // Unsafe
                ref.current
            ),
        [config, maybeHeuristics, narrowedOptions, queryResult, visualType]
    );

    React.useEffect(() => {
        ref.current = heuristics;
    }, [heuristics]);

    return (
        <config.Component
            {...passthrough}
            setResultCounts={setResultCounts}
            formatMessage={formatMessage}
            heuristics={heuristics}
            queryResult={queryResult}
            visualType={visualType}
            visualOptions={narrowedOptions}
        />
    );
}

export interface KweVisualProps
    extends Omit<
        IDataVisualProps,
        'heuristics' | 'visualOptions' | 'locale' | 'formatMessage' | 'setResultCounts' | 'queryResult'
    > {
    queryResult: VisualFwkQueryResult;
    visualType: string;
    options?: UnknownVisualOptions;
    setResultCounts?: IDataVisualProps['setResultCounts'];
    /**
     * TODO: Consider making this not customizable
     */
    formatMessage?: VisualMessageFormatter;
    htmlErrorId?: VisualHtmlErrorId;
    headerLevel?: 1 | 2 | 3 | 4 | 5;
    visuals: ParsedVisuals;
    t: KweVisualFwkLocale;
    /**
     * TODO: Consider making this not customizable
     */
    renderLoading?(): React.ReactElement;
    /**
     * TODO: Consider making this not customizable
     */
    renderErrorBoundary?(wrappedComponent: React.ReactChild): React.ReactElement;
}

/**
 * Used for rendering a chart without any configuration UX
 */
export const KweVisual = observer(function KweVisual({
    queryResult,
    visualType,
    visuals,
    t,
    renderLoading = () => <DataVisualSpinner t={t} />,
    htmlErrorId,
    headerLevel,
    formatMessage = formatMessageDefault(headerLevel, htmlErrorId),
    renderErrorBoundary = (e) => <>{e}</>,
    ...passthrough
}: KweVisualProps) {
    const config = visuals[visualType];

    if (!config) {
        return <UnsupportedVisualType t={t} id={htmlErrorId} type={visualType} />;
    }

    const maybeConfig = config.config;

    switch (maybeConfig.kind) {
        case 'err':
            return formatMessage({ level: 'error', message: maybeConfig.err(t) });
        case 'loading':
            return renderLoading();
        case 'ok':
            return (
                <QueryResultErrors
                    t={t}
                    messageHeaderLevel={headerLevel}
                    queryResult={queryResult}
                    htmlErrorId={htmlErrorId}
                    renderLoading={renderLoading}
                    renderSuccess={(resultSuccess) => (
                        <KweResolvedVisual
                            {...passthrough}
                            queryResult={resultSuccess}
                            formatMessage={formatMessage}
                            locale={t.locale}
                            config={maybeConfig.value}
                            visualType={visualType}
                        />
                    )}
                    renderErrorBoundary={renderErrorBoundary}
                />
            );
    }
});
