import React from 'react';
import {
    Button,
    Caption1,
    Caption1Strong,
    Popover,
    PopoverSurface,
    PopoverSurfaceProps,
    PopoverTrigger,
} from '@fluentui/react-components';

import { OverflowTooltip } from '../OverflowTooltip';

import styles from './InteractiveDistributionList.module.scss';

interface ValueDetailsProps {
    title: string;
    count: string;
    percentage: string;
    relativeWidth: string;
    popoverContent: React.JSX.Element;
    popoverSurfaceProps?: PopoverSurfaceProps;
}

interface DistributionItem extends Pick<ValueDetailsProps, 'title' | 'popoverContent' | 'popoverSurfaceProps'> {
    itemCount: number;
    itemKey?: React.Key;
}

export interface InteractiveDistributionListProps {
    totalValues: number;
    items: DistributionItem[];
    locale: undefined | string;
}

const ValueDetails: React.FC<ValueDetailsProps> = ({
    title,
    count,
    percentage,
    relativeWidth,
    popoverContent,
    popoverSurfaceProps,
}) => {
    return (
        <li className={styles.itemContainer}>
            <div className={styles.title}>
                <Caption1>
                    <OverflowTooltip>{title}</OverflowTooltip>
                </Caption1>
            </div>
            <div className={styles.percentageBar}>
                <Popover withArrow>
                    <PopoverTrigger>
                        <Button className={styles.percentageBarFill} style={{ width: relativeWidth }} shape="square" />
                    </PopoverTrigger>
                    <PopoverSurface {...(popoverSurfaceProps ?? {})}>{popoverContent}</PopoverSurface>
                </Popover>
            </div>
            <div className={styles.countContainer}>
                <Caption1Strong>{count}</Caption1Strong>
                <Caption1 className={styles.percentage}>({percentage})</Caption1>
            </div>
        </li>
    );
};

export const InteractiveDistributionList: React.FC<InteractiveDistributionListProps> = ({
    items,
    totalValues,
    locale,
}) => {
    const percentFormatter = new Intl.NumberFormat(locale, {
        style: 'percent',
        maximumFractionDigits: 2,
        maximumSignificantDigits: 2,
    });
    const countFormatter = new Intl.NumberFormat(locale, { notation: 'compact' });

    const highestValue = items.reduce((max, item) => Math.max(max, item.itemCount), 0);

    return (
        <ol className={styles.distributionList}>
            {items
                .sort((a, b) => b.itemCount - a.itemCount)
                .map((item) => (
                    <ValueDetails
                        key={item.itemKey ?? item.title + item.itemCount}
                        title={item.title}
                        count={countFormatter.format(item.itemCount)}
                        percentage={percentFormatter.format(item.itemCount / totalValues)}
                        relativeWidth={percentFormatter.format(item.itemCount / highestValue)}
                        popoverContent={item.popoverContent}
                        popoverSurfaceProps={item.popoverSurfaceProps}
                    />
                ))}
        </ol>
    );
};
