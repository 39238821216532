import * as React from 'react';
import { useEventCallback } from './useEventCallback';
/**
 * @internal
 * Utility to perform checks where a click/touch event was made outside a component
 */ export const useOnScrollOutside = (options)=>{
    const { refs, callback, element, disabled, contains: containsProp } = options;
    const listener = useEventCallback((ev)=>{
        const contains = containsProp || ((parent, child)=>!!(parent === null || parent === void 0 ? void 0 : parent.contains(child)));
        const target = ev.composedPath()[0];
        const isOutside = refs.every((ref)=>!contains(ref.current || null, target));
        if (isOutside && !disabled) {
            callback(ev);
        }
    });
    React.useEffect(()=>{
        if (disabled) {
            return;
        }
        element === null || element === void 0 ? void 0 : element.addEventListener('wheel', listener);
        element === null || element === void 0 ? void 0 : element.addEventListener('touchmove', listener);
        return ()=>{
            element === null || element === void 0 ? void 0 : element.removeEventListener('wheel', listener);
            element === null || element === void 0 ? void 0 : element.removeEventListener('touchmove', listener);
        };
    }, [
        listener,
        element,
        disabled
    ]);
};
