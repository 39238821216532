import { Escape } from '@fluentui/keyboard-keys';
import { presenceMotionSlot } from '@fluentui/react-motion';
import { useEventCallback, useMergedRefs, isResolvedShorthand, slot, getIntrinsicElementProps, useIsomorphicLayoutEffect } from '@fluentui/react-utilities';
import * as React from 'react';
import { useDialogContext_unstable } from '../../contexts';
import { useDisableBodyScroll } from '../../utils/useDisableBodyScroll';
import { DialogBackdropMotion } from '../DialogBackdropMotion';
import { useMotionForwardedRef } from '../MotionRefForwarder';
/**
 * Create the state required to render DialogSurface.
 *
 * The returned state can be modified with hooks such as useDialogSurfaceStyles_unstable,
 * before being passed to renderDialogSurface_unstable.
 *
 * @param props - props from this instance of DialogSurface
 * @param ref - reference to root HTMLElement of DialogSurface
 */ export const useDialogSurface_unstable = (props, ref)=>{
    const contextRef = useMotionForwardedRef();
    const modalType = useDialogContext_unstable((ctx)=>ctx.modalType);
    const isNestedDialog = useDialogContext_unstable((ctx)=>ctx.isNestedDialog);
    const modalAttributes = useDialogContext_unstable((ctx)=>ctx.modalAttributes);
    const dialogRef = useDialogContext_unstable((ctx)=>ctx.dialogRef);
    const requestOpenChange = useDialogContext_unstable((ctx)=>ctx.requestOpenChange);
    const dialogTitleID = useDialogContext_unstable((ctx)=>ctx.dialogTitleId);
    const open = useDialogContext_unstable((ctx)=>ctx.open);
    const handledBackdropClick = useEventCallback((event)=>{
        if (isResolvedShorthand(props.backdrop)) {
            var _props_backdrop_onClick, _props_backdrop;
            (_props_backdrop_onClick = (_props_backdrop = props.backdrop).onClick) === null || _props_backdrop_onClick === void 0 ? void 0 : _props_backdrop_onClick.call(_props_backdrop, event);
        }
        if (modalType === 'modal' && !event.isDefaultPrevented()) {
            requestOpenChange({
                event,
                open: false,
                type: 'backdropClick'
            });
        }
    });
    const handleKeyDown = useEventCallback((event)=>{
        var _props_onKeyDown;
        (_props_onKeyDown = props.onKeyDown) === null || _props_onKeyDown === void 0 ? void 0 : _props_onKeyDown.call(props, event);
        if (event.key === Escape && !event.isDefaultPrevented()) {
            requestOpenChange({
                event,
                open: false,
                type: 'escapeKeyDown'
            });
            // stop propagation to avoid conflicting with other elements that listen for `Escape`
            // e,g: nested Dialog, Popover, Menu and Tooltip
            event.preventDefault();
        }
    });
    const backdrop = slot.optional(props.backdrop, {
        renderByDefault: modalType !== 'non-modal',
        defaultProps: {
            'aria-hidden': 'true'
        },
        elementType: 'div'
    });
    if (backdrop) {
        backdrop.onClick = handledBackdropClick;
    }
    const { disableBodyScroll, enableBodyScroll } = useDisableBodyScroll();
    useIsomorphicLayoutEffect(()=>{
        if (isNestedDialog || modalType === 'non-modal') {
            return;
        }
        disableBodyScroll();
        return ()=>{
            enableBodyScroll();
        };
    }, [
        enableBodyScroll,
        isNestedDialog,
        disableBodyScroll,
        modalType
    ]);
    return {
        components: {
            backdrop: 'div',
            root: 'div',
            // TODO: remove once React v18 slot API is modified
            // This is a problem at the moment due to UnknownSlotProps assumption
            // that `children` property is `ReactNode`, which in this case is not valid
            // as PresenceComponentProps['children'] is `ReactElement`
            backdropMotion: DialogBackdropMotion
        },
        open,
        backdrop,
        isNestedDialog,
        mountNode: props.mountNode,
        root: slot.always(getIntrinsicElementProps('div', {
            tabIndex: -1,
            'aria-modal': modalType !== 'non-modal',
            role: modalType === 'alert' ? 'alertdialog' : 'dialog',
            'aria-labelledby': props['aria-label'] ? undefined : dialogTitleID,
            ...props,
            ...modalAttributes,
            onKeyDown: handleKeyDown,
            // FIXME:
            // `DialogSurfaceElement` is wrongly assigned to be `HTMLElement` instead of `HTMLDivElement`
            // but since it would be a breaking change to fix it, we are casting ref to it's proper type
            ref: useMergedRefs(ref, contextRef, dialogRef)
        }), {
            elementType: 'div'
        }),
        backdropMotion: presenceMotionSlot(props.backdropMotion, {
            elementType: DialogBackdropMotion,
            defaultProps: {
                appear: true,
                visible: open
            }
        }),
        // Deprecated properties
        transitionStatus: undefined
    };
};
