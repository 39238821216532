import { formatLiterals } from '@kusto/utils';

import type { KustoClientErrorDescription } from './client';
import type { KustoClientLocale } from './types';

export function formatClientErrorMessage(
    info: KustoClientErrorDescription,
    t: KustoClientLocale
): { title: string; body: string } {
    const title = t.client.errorCodes[info.code! as keyof typeof t.client.errorCodes] ?? t.client.unknownErrorType;

    let body: string;

    if (!info.token) {
        body = info.errorMessage;
    } else if (info.line && info.pos) {
        body = formatLiterals(t.client.kustoErrorMessageLong, {
            errorMessage: info.errorMessage,
            token: info.token,
            line: info.line.toLocaleString(t.locale),
            pos: info.pos.toLocaleString(t.locale),
        });
    } else {
        body = formatLiterals(t.client.kustoErrorMessageShort, {
            errorMessage: info.errorMessage,
            token: info.token,
        });
    }

    return { title, body };
}

export function formatClientErrorString(description: KustoClientErrorDescription, t: KustoClientLocale): string {
    const { title, body } = formatClientErrorMessage(description, t);
    return `${title}\n\n${body}`;
}
