import React from 'react';
import { TooltipHost, TooltipOverflowMode } from '@fluentui/react';
import { Button, Text } from '@fluentui/react-components';
import { Add20Regular } from '@fluentui/react-icons';
import classNames from 'classnames';
import * as mobx from 'mobx';
import { observer, Observer } from 'mobx-react-lite';

import { useThemeState } from '@kusto/utils';

import type { KweVisualFwkLocale } from '../../types';
import type { SchemaState, VisualInput } from '../../visualConfig/input';
import type { ColorResolutionSet, ColorRuleThemeColorValues } from '../../visualConfig/visualConfig';
import type { ColorRule, VisualOptionKey, VisualOptionsKeysFor } from '../../visualOptions';
import { ConfigurationItemRow } from '../configurationList/ConfigurationItemRow';
import { ColorRulePanel } from './ColorRulesPanel/ColorRulePanel';
import {
    calculateDefaultRuleName,
    ColorRulePreviewColor,
    formatColorRuleColumn,
    isRuleColumnInferrable,
} from './ColorRulesPanel/utils';
import { conditionalFormattingColors, themeValues } from './constants';
import { ColorRulesVisualType, filterColorRules } from './filterRules';

import * as gridStyles from '../configurationList/grid.module.scss';
import * as configurationListStyles from '../configurationList/styles.module.scss';

type ApplyColorRuleChange = (items: readonly ColorRule.UColorRule[]) => readonly ColorRule.UColorRule[] | undefined;

const replaceColorRule =
    (rule: ColorRule.UColorRule): ApplyColorRuleChange =>
    (rules) => {
        const index = rules.findIndex((r) => r.id === rule.id);

        if (index === -1) {
            return undefined;
        }
        return [...rules.slice(0, index), rule, ...rules.slice(index + 1)];
    };

const addColorRule: ApplyColorRuleChange = (rules) => {
    const newRule: ColorRule.ColorRuleByCondition = {
        id: crypto.randomUUID(),
        ruleType: 'colorByCondition',
        applyToColumn: null,
        hideText: false,
        applyTo: 'cells',
        conditions: [{ column: null, operator: '>', values: [''] }],
        chainingOperator: 'and',
        colorStyle: 'bold',
        color: null,
        tag: '',
        icon: null,
        ruleName: '',
    };

    return [...rules, newRule];
};

const deleteColorRule: (ruleId: string) => ApplyColorRuleChange = (ruleId) => {
    return (rules) => {
        const index = rules.findIndex((rule) => rule.id === ruleId);

        if (index === -1) {
            return undefined;
        }
        return [...rules.slice(0, index), ...rules.slice(index + 1)];
    };
};

function rowId(id: string): string {
    return `color-rule--row--${id}`;
}

export interface ColorRulesProps {
    t: KweVisualFwkLocale;
    visualType: ColorRulesVisualType;
    rules: readonly ColorRule.UColorRule[];
    onChange: (applyChange: ApplyColorRuleChange) => void;
    disabled?: boolean;
    lightColors: ColorResolutionSet;
    boldColors: ColorResolutionSet;
    themeColors: ColorRuleThemeColorValues;
    getSchema: () => SchemaState;
}

export const ColorRules: React.FC<ColorRulesProps> = ({
    t,
    visualType,
    getSchema,
    rules,
    onChange,
    disabled,
    lightColors,
    boldColors,
    themeColors,
}) => {
    const [panelRuleId, setPanelRuleId] = React.useState<undefined | string>();

    // Need to show the rules that match the visual type
    const filteredRules = React.useMemo(() => filterColorRules(rules, visualType), [visualType, rules]);

    const onAddColorRule = React.useCallback(() => {
        onChange(addColorRule);
    }, [onChange]);
    const onDeleteColorRule = React.useCallback(
        (item: ColorRule.UColorRule) => {
            onChange(deleteColorRule(item.id));
            setPanelRuleId((id) => {
                if (id === item.id) {
                    return undefined;
                }
                return id;
            });
        },
        [onChange]
    );
    const onPanelDismiss = React.useCallback(() => setPanelRuleId(undefined), []);
    const onCalloutSave = React.useCallback(
        (newRule: ColorRule.UColorRule) => {
            onChange(replaceColorRule(newRule));
            setPanelRuleId((id) => {
                if (id === newRule.id) {
                    return undefined;
                }
                return id;
            });
        },
        [onChange]
    );
    const onEditClicked = React.useCallback((item) => setPanelRuleId(item.id), []);

    const panelRule = panelRuleId === undefined ? undefined : filteredRules.find((r) => r.id === panelRuleId);

    return (
        <>
            <div className={configurationListStyles.configurationList}>
                {filteredRules.map((rule) => (
                    <ConfigurationItemRow
                        id={rowId(rule.id)}
                        key={rule.id}
                        onDelete={onDeleteColorRule}
                        item={rule}
                        disabled={disabled}
                        onEdit={onEditClicked}
                        editButtonTitle={t.utils.util.buttons.edit}
                        deleteButtonTitle={t.utils.util.buttons.delete}
                    >
                        <ItemComponent
                            boldColors={boldColors}
                            lightColors={lightColors}
                            colorsByTheme={themeColors}
                            t={t}
                            item={rule}
                            visualType={visualType}
                        />
                    </ConfigurationItemRow>
                ))}
                <div className={configurationListStyles.addConfigItemButton}>
                    <Button
                        appearance="transparent"
                        icon={<Add20Regular />}
                        onClick={onAddColorRule}
                        disabled={disabled}
                    >
                        {t.visualFwk.visualConfig.colorRules.addRuleButtonText}
                    </Button>
                </div>
            </div>
            {panelRule && (
                <Observer>
                    {() => (
                        <ColorRulePanel
                            t={t}
                            visualType={visualType}
                            schema={getSchema()}
                            onClose={onPanelDismiss}
                            onSave={onCalloutSave}
                            rule={panelRule}
                            lightColors={lightColors}
                            boldColors={boldColors}
                            themeColors={themeColors}
                        />
                    )}
                </Observer>
            )}
        </>
    );
};

interface ItemComponentProps {
    boldColors: ColorResolutionSet;
    lightColors: ColorResolutionSet;
    colorsByTheme: ColorRuleThemeColorValues;
    t: KweVisualFwkLocale;

    item: ColorRule.UColorRule;
    visualType: ColorRulesVisualType;
}

const ItemComponent: React.FC<ItemComponentProps> = ({
    boldColors,
    lightColors,
    colorsByTheme,
    t,
    item,
    visualType,
}) => {
    let applyToText;
    if (item.ruleType === 'colorByCondition') {
        if (item.applyTo === 'cells') {
            applyToText = formatColorRuleColumn(
                t,
                item.applyToColumn ?? item.conditions[0].column,
                isRuleColumnInferrable(visualType)
            );
        } else {
            applyToText = formatColorRuleColumn(t, item.conditions[0].column, isRuleColumnInferrable(visualType));
        }
    } else {
        applyToText = formatColorRuleColumn(t, item.column);
    }

    return (
        <>
            <ColorRulePreviewColor
                boldColors={boldColors}
                lightColors={lightColors}
                colorsByTheme={colorsByTheme}
                rule={item}
            />
            <div className={classNames(gridStyles.content, configurationListStyles.configItemText)}>
                <TooltipHost
                    hostClassName={configurationListStyles.configItemTooltip}
                    content={calculateDefaultRuleName(t, item, visualType)}
                    overflowMode={TooltipOverflowMode.Self}
                >
                    <Text>{calculateDefaultRuleName(t, item, visualType)}</Text>
                </TooltipHost>
                <Text size={200}>{applyToText}</Text>
            </div>
        </>
    );
};

/**
 * Connects `ColorRules` to the edit tile page. Before here all the color rules
 * code is (mostly) not context dependent
 *
 * Uses temp state to keep unfiltered rules so switching between visuals types
 * doesn't cause state loss.
 */
export function createColorRulesInput<C extends VisualOptionKey>(
    t: KweVisualFwkLocale,
    key: VisualOptionsKeysFor<C, readonly ColorRule.UColorRule[]>,
    visualType: ColorRulesVisualType
): VisualInput<C, unknown, readonly ColorRule.UColorRule[]> {
    return {
        id: `colorRules--${key}`,
        keys: [key],
        init: (model, rules) => {
            // If rules exist from a different visual type, update the model
            // state with rules filtered for the new visual type, and preserve
            // the rules.
            if (rules) {
                model.set(key, filterColorRules(rules, visualType));
                return rules;
            }

            return model.get(key);
        },
        Component: observer(function ColorRulesManagedConfig({ disabled, model }) {
            const { isDark } = useThemeState();

            const { bold: boldColors, light: lightColors } = conditionalFormattingColors[isDark ? 'dark' : 'light'];
            const themeColors = themeValues[isDark ? 'dark' : 'light'];

            const rules = model.get(key);

            const onChange = React.useMemo(
                () =>
                    mobx.action((applyChange: ApplyColorRuleChange) => {
                        const colorRules = applyChange(model.getTemp());
                        if (colorRules) {
                            model.setTemp(colorRules);
                            model.set(key, filterColorRules(colorRules, visualType));
                        }
                    }),
                [model]
            );

            return (
                <ColorRules
                    t={t}
                    visualType={visualType}
                    getSchema={() => model.getSchema()}
                    boldColors={boldColors}
                    lightColors={lightColors}
                    themeColors={themeColors}
                    rules={rules}
                    onChange={onChange}
                    disabled={disabled}
                />
            );
        }),
    };
}
