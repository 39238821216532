import React from 'react';
import { ActionButton, IButtonProps } from '@fluentui/react';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import { DataFrameSchema, usePullState } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';

import { RtdProviderLocale } from '../../../i18n';
import { TableRenderLinkForm } from './Form';
import { rowId, TableRenderLinkRow } from './Row';

interface AddRenderLinkButtonProps extends IButtonProps {
    t: RtdProviderLocale;
}

const addIconProps = {
    iconName: 'Add',
};

const AddRenderLinkButton: React.FC<AddRenderLinkButtonProps> = ({ t, ...props }) => {
    return (
        // Adding extra wrapping div for the sake of simple CSS and centering on tooltip
        <div
            className={`${Fwk.tileInputClassNames.addConfigItemButton} ${Fwk.tileInputClassNames.addConfigItemButtonMarginAdjust}`}
        >
            <ActionButton iconProps={addIconProps} {...props}>
                {t.rtdProvider.visuals.table.renderLinks.addConfigurationButtonText}
            </ActionButton>
        </div>
    );
};

interface TableRenderLinkDialogProps {
    t: RtdProviderLocale;
    id: number;
    config: Fwk.TableRenderLinkConfig;
    targetId: string;
    onDismiss: () => void;
    onApply: (config: Fwk.TableRenderLinkConfig) => void;
    tableSchemaResult: DataFrameSchema;
    disabled: boolean;
}

const TableRenderLinkDialog: React.FC<TableRenderLinkDialogProps> = ({
    t,
    id,
    config,
    targetId,
    onDismiss,
    onApply,
    tableSchemaResult,
    disabled,
}) => {
    const [state, setState, getState] = usePullState(config);

    const { onSave, onChange } = React.useMemo(
        () => ({
            onSave() {
                onApply(getState());
            },
            onChange(_id: number, value: Fwk.TableRenderLinkConfig) {
                setState(value);
            },
        }),
        [getState, onApply, setState]
    );

    return (
        <Fwk.ConfigurationItemCallout
            title={t.rtdProvider.visuals.table.renderLinks.calloutTitle}
            targetId={targetId}
            position="below"
            onClose={onDismiss}
            onSave={onSave}
            applyButtonLabel={t.utils.util.buttons.apply}
            cancelButtonLabel={t.utils.util.buttons.cancel}
        >
            <TableRenderLinkForm
                id={id}
                showDisable={true}
                value={state}
                onChange={onChange}
                getValue={getState}
                disabled={disabled}
                tableSchemaResult={tableSchemaResult}
                t={t}
            />
        </Fwk.ConfigurationItemCallout>
    );
};

let lastConfigId = 0;
function newConfigId() {
    return lastConfigId++;
}

function newConfig(): Fwk.TableRenderLinkConfig {
    return { urlColumn: undefined, displayColumn: undefined, disabled: false };
}

// No need to take in the key on the createConfig fn
// since this is always a static visual option
const KEY = 'table__renderLinks' as const;

export function createTableRenderLinksConfig(
    t: RtdProviderLocale
): Fwk.VisualInput<typeof KEY, unknown, Map<number, Fwk.TableRenderLinkConfig>> {
    return {
        id: KEY,
        keys: [KEY],
        init(model, prev) {
            if (prev) {
                return prev;
            }
            let initial = model.get(KEY);
            if (initial.length === 0) {
                initial = [newConfig()];
            }

            return new Map(initial.map((config: Fwk.TableRenderLinkConfig) => [newConfigId(), config]));
        },
        Component: observer(function TableRenderLinkManagedConfig({ model, disabled }) {
            const [dialogOpenId, setDialogOpenId] = React.useState<null | number>(null);

            const { tableSchemaResult, onChange, getValue, onAdd, onDelete } = React.useMemo(() => {
                const tableSchemaResult = mobx.computed(() => {
                    return model.getQueryResult()?.dataFrame.fields ?? [];
                });

                return {
                    tableSchemaResult,
                    onChange: mobx.action((id: number, tableRenderLink: Fwk.TableRenderLinkConfig) => {
                        const prev = model.getTemp();
                        if (!prev.has(id)) {
                            // Value has been deleted. Ignore action.
                            return;
                        }
                        const next = new Map(prev);
                        next.set(id, tableRenderLink);
                        model.setTemp(next);
                        model.set(KEY, [...next.values()]);
                    }),
                    getValue: mobx.action((id: number) => model.getTemp().get(id)),
                    onAdd: mobx.action(() => {
                        const next = new Map(model.getTemp());
                        const id = newConfigId();
                        next.set(id, newConfig());
                        model.setTemp(next);
                        model.set(KEY, [...next.values()]);
                        setDialogOpenId(id);
                    }),
                    onDelete: mobx.action((id: number) => {
                        const prev = model.getTemp();
                        if (!prev.has(id) || prev.size === 1) {
                            return;
                        }
                        const next = new Map(prev);
                        next.delete(id);
                        // If there is only 1 value left, and it's disabled, enable it.
                        if (next.size === 1 && next.values().next().value.disabled) {
                            const [lastId, value] = next.entries().next().value;
                            next.set(lastId, {
                                ...value,
                                disabled: false,
                            });
                        }
                        model.setTemp(next);
                        model.set(KEY, [...next.values()]);
                    }),
                };
            }, [model]);

            const closeDialog = () => setDialogOpenId(null);
            const dialogModel = dialogOpenId !== null && model.getTemp().get(dialogOpenId);
            const dialog = dialogModel && (
                <TableRenderLinkDialog
                    key={dialogOpenId}
                    t={t}
                    id={dialogOpenId}
                    targetId={`${rowId(dialogOpenId)}`}
                    config={dialogModel}
                    disabled={disabled}
                    onDismiss={closeDialog}
                    onApply={(next) => {
                        onChange(dialogOpenId, next);
                        closeDialog();
                    }}
                    tableSchemaResult={tableSchemaResult.get()}
                />
            );

            const tempValues = [...model.getTemp().entries()];
            if (tempValues.length === 1) {
                return (
                    <>
                        <TableRenderLinkForm
                            id={tempValues[0][0]}
                            value={tempValues[0][1]}
                            onChange={onChange}
                            getValue={getValue}
                            disabled={disabled}
                            showDisable={false}
                            tableSchemaResult={tableSchemaResult.get()}
                            t={t}
                        />
                        <AddRenderLinkButton t={t} onClick={onAdd} />
                        {dialog}
                    </>
                );
            }
            return (
                <>
                    <div>
                        {tempValues.map(([id, config]) => (
                            <TableRenderLinkRow
                                key={id}
                                id={id}
                                config={config}
                                onEdit={setDialogOpenId}
                                onDelete={onDelete}
                                tableSchemaResult={tableSchemaResult.get()}
                                t={t}
                            />
                        ))}
                    </div>
                    <AddRenderLinkButton t={t} onClick={onAdd} />
                    {dialog}
                </>
            );
        }),
    };
}
