import React from 'react';

import {
    DataTable,
    ExecutionResult,
    extractErrorDescription,
    KustoClientLocale,
    KustoClientResultV2,
    Row,
    ValueRow,
} from '@kusto/client';
import { KweException } from '@kusto/utils';

import { useDataExplorationContext } from './context/DataExplorationContext';
import { throwOnV2Error } from './lib';
import { QueryRunnerV1, QueryRunnerV2 } from './types';

function ignorePartialErrorRow(rows: Row[]): ValueRow[] {
    if (rows.length && !Array.isArray(rows[rows.length - 1])) {
        return rows.pop() as ValueRow[];
    }
    return rows as ValueRow[];
}

function handleAppInsightsDynamicCols(table: DataTable): DataTable {
    const parsedRows = ignorePartialErrorRow(table.Rows).map((row) =>
        row.map((col, colIndex) => {
            if (table.Columns[colIndex].ColumnType === 'dynamic') {
                try {
                    return JSON.parse(col as string);
                } catch {}
            }
            return col;
        })
    );
    return { ...table, Rows: parsedRows };
}

/**
 * Application insights and log analytics proxy double escapes dynamic values, and need to be handled differently.
 * Teams discussion: https://teams.microsoft.com/l/message/19:69fbea1362e04d5ab01974715f5d9b35@thread.tacv2/1644949296774?tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47&groupId=fd8dadc4-9cfb-45a6-8b9e-cf53c16016d3&parentMessageId=1644916798436&teamName=Kusto%20Web%20Explorer&channelName=Devs&createdTime=1644949296774
 */
function fixAppInsightsResponse(executionResult: ExecutionResult<'v2'>): ExecutionResult<'v2'> {
    return {
        ...executionResult,
        apiCallResult: executionResult.apiCallResult.map((table) =>
            table.FrameType === 'DataTable' && table.TableKind === 'PrimaryResult'
                ? handleAppInsightsDynamicCols(table)
                : table
        ) as KustoClientResultV2,
    };
}

export function useKustoClient({
    clusterUrl,
    databaseName,
    t,
}: {
    clusterUrl?: string;
    databaseName?: string;
    t: KustoClientLocale;
}) {
    const { telemetry, kustoDomains, kustoClient } = useDataExplorationContext();
    const isAppInsightsDomain = React.useMemo(() => {
        if (!clusterUrl) {
            return false;
        }
        try {
            const domain = new URL(clusterUrl).host;
            return kustoDomains.isAppInsightsDomain(domain);
        } catch (error) {
            telemetry.exception('DataProfile.failedParseUrl', { error, clusterUrl });
            return false;
        }
    }, [clusterUrl, kustoDomains, telemetry]);

    const executeQuery = React.useCallback(
        async (queryText) => {
            if (!clusterUrl || !databaseName) {
                throw new KweException(`Cannot query - ${clusterUrl ? 'database' : 'clusterUrl'} undefined`);
            }
            const request = kustoClient.createRequest(clusterUrl, { source: 'Query' });
            const result = await request.executeQuery(databaseName, queryText, t, {
                properties: { Options: { servertimeout: '00:01:00', query_take_max_records: 100_000 } },
            });
            //some errors return error codes (e.g. 400)
            if (result.kind == 'err') {
                throw new Error(result.err[0].errorMessage);
            }
            if (result.value?.errors) {
                throw new Error(result.value.errors[0].errorMessage);
            }
            if (result.kind == 'abort') {
                throw new Error('aborted');
            }
            return result.value;
        },
        [clusterUrl, databaseName, kustoClient, t]
    );

    /**
     * @deprecated in favor of executeQuery
     */
    const executeV2Query: QueryRunnerV2 = React.useCallback(
        async (queryText) => {
            if (!clusterUrl || !databaseName) {
                throw new KweException(`Cannot query - ${clusterUrl ? 'database' : 'clusterUrl'} undefined`);
            }

            const request = kustoClient.createRequest(clusterUrl, { source: 'Query' });
            let result;
            try {
                result = await request.executeQueryV2_deprecated(databaseName, queryText, {
                    properties: { Options: { servertimeout: '00:01:00', query_take_max_records: 100_000 } },
                });
                //some errors return error codes (e.g. 400)
            } catch (error) {
                throw new Error(extractErrorDescription(error).errorMessage);
            }

            //some errors return 200OK but have errors listed and sometimes a partial response
            throwOnV2Error(result);

            return isAppInsightsDomain ? fixAppInsightsResponse(result) : result;
        },
        [clusterUrl, databaseName, isAppInsightsDomain, kustoClient]
    );

    const executeV1Query: QueryRunnerV1 = React.useCallback(
        async (queryText) => {
            const request = kustoClient.createRequest(clusterUrl ?? '', { source: 'Query' });
            return request.executeQuery_deprecated(databaseName ?? '', queryText);
        },
        [clusterUrl, databaseName, kustoClient]
    );

    return { executeQuery, executeV1Query, executeV2Query, isAppInsightsDomain };
}
