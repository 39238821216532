import { iterFlagsList, TypeFromFlagList } from '@kusto/app-common';
import { formatLiterals, Mutable, URL_SEARCH_FALSE_VALUE, URL_SEARCH_TRUE_VALUES } from '@kusto/utils';

import { KWE_CONSTANTS } from '../common/constants';
import type { AppStrings } from '../locale';
import { FeatureFlag, FeatureFlagMap, featureFlags, legacyFeatureFlags, urlFeatureFlags } from './FeatureFlags';

const legacyFeatureFlagSet = new Set(legacyFeatureFlags);

const urlFeatureFlagSet = new Set(iterFlagsList(urlFeatureFlags));

const featureFlagSet = new Set(iterFlagsList(featureFlags));

function isUrlFeatureFlagKey(str: string): str is FeatureFlag {
    return urlFeatureFlagSet.has(str as TypeFromFlagList<typeof urlFeatureFlags>);
}

function isLegacyFeatureFlagKey(str: string): str is (typeof legacyFeatureFlags)[number] {
    return legacyFeatureFlagSet.has(str as (typeof legacyFeatureFlags)[number]);
}

const settingsParamSet = new Set(KWE_CONSTANTS.globalReservedSearchItems.settings);

function isSettingsParamKey(str: string): str is UrlAppSettingsKey {
    return settingsParamSet.has(str as UrlAppSettingsKey);
}

/**
 * Format url key ad value as they would appear in the url for user warnings.
 */
function formatUrlKeyValue(key: string, value: string) {
    return new URLSearchParams([[key, value]]).toString();
}

function addFeatureFlag(
    flags: Mutable<FeatureFlagMap>,
    warnings: Array<(strings: AppStrings) => string>,
    rawKey: string,
    key: string,
    value: string
) {
    if (!isUrlFeatureFlagKey(key)) {
        if (featureFlagSet.has(key as FeatureFlag)) {
            warnings.push((t) =>
                formatLiterals(t.kwe.init.urlWarnings.flags.cannotBeSet, {
                    flag: key,
                    urlPartial: formatUrlKeyValue(rawKey, value),
                })
            );
        } else {
            warnings.push((t) =>
                formatLiterals(t.kwe.init.urlWarnings.flags.notAFlag, {
                    text: key,
                    urlPartial: formatUrlKeyValue(rawKey, value),
                    flags: new Intl.ListFormat(t.kwe.pageLang).format(urlFeatureFlagSet.values()),
                })
            );
        }
        return;
    }

    if (key in flags) {
        warnings.push((t) =>
            formatLiterals(t.kwe.init.urlWarnings.flags.alreadySet, {
                flag: key,
                value: flags[key]?.toString() ?? 'unknown_value',
                urlPartial: formatUrlKeyValue(rawKey, value),
            })
        );
        return;
    }
    switch (value) {
        case URL_SEARCH_TRUE_VALUES[0]:
        case URL_SEARCH_TRUE_VALUES[1]:
            flags[key] = true;
            break;
        case URL_SEARCH_FALSE_VALUE:
            flags[key] = false;
            break;
        default:
            warnings.push((t) =>
                formatLiterals(t.kwe.init.urlWarnings.flags.invalidValue, {
                    flag: key,
                    value,
                    urlPartial: formatUrlKeyValue(rawKey, value),
                    trueValue: URL_SEARCH_TRUE_VALUES[0],
                    falseValue: URL_SEARCH_FALSE_VALUE,
                })
            );
    }
}

export type UrlAppSettingsKey = (typeof KWE_CONSTANTS)['globalReservedSearchItems']['settings'][number];

/**
 * Non feature-flag settings that can be configured in the url
 */
export type UrlAppSettings = {
    readonly [K in UrlAppSettingsKey]?: string;
};

export interface AppQueryParams {
    /**
     * Search strings with app params removed
     */
    readonly newSearch: string;
    readonly flags: Mutable<FeatureFlagMap>;
    readonly settings: UrlAppSettings;
    readonly flagsUsedWithoutPrefix: readonly FeatureFlag[];
    readonly warnings: ReadonlyArray<(strings: AppStrings) => string>;
}

/**
 * Removes feature flags from argument, and returns results
 */
export function appQueryParams(searchString: string): AppQueryParams {
    const search = new URLSearchParams(searchString);

    const warnings: Array<(strings: AppStrings) => string> = [];
    const flagsUsedWithoutPrefix: FeatureFlag[] = [];
    // Handle feature flag parsing
    // Each feature flag becomes a query param like: ShowShareMenu and ShowConnectionButtons
    // https://kusto.azure.com/?IFrameAuth=true&ShowFileMenu=true&ShowShareMenu=false
    const flags: Mutable<FeatureFlagMap> = {};
    const settings: Mutable<UrlAppSettings> = {};

    const filteredSearch = new URLSearchParams();

    for (const [rawKey, value] of search) {
        let key = rawKey;
        if (isLegacyFeatureFlagKey(key)) {
            flagsUsedWithoutPrefix.push(key);
            key = KWE_CONSTANTS.globalReservedSearchItems.featureFlagPrefix + key;
            // Uncomment this once the flag prefix has been out for a bit
            // warnings.push(() => `Setting feature flags without ${KWE_CONSTANTS.globalReservedSearchItems.featureFlagPrefix} is deprecated and will not work in the future. Update ${formatKeyValue(rawKey, value)} to be ${formatKeyValue(key, value)}`);
        }

        if (key.startsWith(KWE_CONSTANTS.globalReservedSearchItems.featureFlagPrefix)) {
            addFeatureFlag(
                flags,
                warnings,
                rawKey,
                key.slice(KWE_CONSTANTS.globalReservedSearchItems.featureFlagPrefix.length),
                value
            );
        } else if (isSettingsParamKey(rawKey)) {
            settings[rawKey] = value;
        } else {
            filteredSearch.append(rawKey, value);
        }
    }

    if (settings.language) {
        settings.language = settings.language.toLowerCase();
    }

    const newSearch = filteredSearch.toString();

    return { flags, newSearch, settings, flagsUsedWithoutPrefix, warnings: warnings };
}
