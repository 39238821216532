import React from 'react';
import { Body1, Body1Strong, Field, InfoLabel, Input, LabelProps } from '@fluentui/react-components';

import { useStrings } from '../../../../hooks/useStrings';

export interface PopoverFieldProps {
    labelId: string;
    /** Label that appear above the field. If a string is provided, it will appear as a bold text. */
    label: string | JSX.Element;
    /** If true, the label will not be displayed. */
    hideLabel?: boolean;
    /** If true, an 'optional' label will appear next to the label. Will be evaluated only if `label` is a string, */
    optional?: boolean;
    /** If `info` provided, an 'i' icon will appear next to the label. Clicking it will display the `info` text in a popup. */
    info?: string;
}

export const PopoverField: React.FunctionComponent<PopoverFieldProps> = ({
    labelId,
    label,
    hideLabel,
    optional,
    info,
    children,
}) => {
    const { strings } = useStrings();

    let fieldLabel: string | JSX.Element = '';

    if (!hideLabel) {
        if (typeof label === 'string') {
            fieldLabel = (
                <>
                    <Body1Strong>{label}</Body1Strong>
                    {optional && <Body1 italic> - {strings.dataExplorationModal.pillsBar.optional}</Body1>}
                </>
            );
        } else {
            fieldLabel = label;
        }
    }

    return (
        <div>
            <Field
                label={{
                    // Setting children to a render function allows you to replace the entire slot.
                    // The first param is the component for the slot (Label), which we're ignoring to use InfoLabel instead.
                    // The second param are the props for the slot, which need to be passed to the InfoLabel.
                    children: (_: unknown, slotProps: LabelProps) => (
                        <InfoLabel {...slotProps} info={info} id={labelId}>
                            {fieldLabel}
                        </InfoLabel>
                    ),
                }}
            >
                {children}
            </Field>
        </div>
    );
};

export interface DisplayNameFieldProps {
    labelId: string;
    hideLabel?: boolean;
    placeholder?: string;
    defaultValue?: string;
    onChange: (value: string) => void;
}

export const DisplayNameField: React.FunctionComponent<DisplayNameFieldProps> = (props) => {
    const { strings } = useStrings();

    return (
        <PopoverField
            labelId={props.labelId}
            label={strings.dataExplorationModal.pillsBar.displayName}
            hideLabel={props.hideLabel}
            optional
        >
            <Input
                aria-labelledby={props.labelId}
                placeholder={props.placeholder}
                defaultValue={props.defaultValue}
                onChange={(e, { value }) => props.onChange(value)}
            />
        </PopoverField>
    );
};
