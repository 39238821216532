import * as React from 'react';

import type { Dispose, ReadonlyRecord } from '@kusto/utils';

import type { QueryMessageLevel, QueryMessagesNormal } from './error';
import type { VisualMessageData, VisualMessages } from './visualConfig';

/* Extract and convert query warnings. We add a tag to avoid duplications while adding new warnings */
function buildVisualMessagesFromExecution(
    queryResultMessages: undefined | QueryMessagesNormal
): ReadonlyRecord<QueryMessageLevel, VisualMessageData[]> {
    const warn: VisualMessageData[] =
        queryResultMessages?.map((queryErr) => {
            const [title, message] = queryErr.body ? [queryErr.title, queryErr.body] : [undefined, queryErr.title];
            return { title, message, level: 'warn' };
        }) ?? [];
    return { info: [], error: [], warn }; // todo: Support query info and error messages
}

let nextId = 0;

export function useVisualMessages(
    queryResultMessages: undefined | QueryMessagesNormal
): [VisualMessages, (message: VisualMessageData) => Dispose] {
    const [messageMap, setMessageMap] = React.useState<Record<number, VisualMessageData>>({});

    const addMessage = React.useCallback((message: VisualMessageData): Dispose => {
        const id = nextId++;
        setMessageMap((prev) => ({ ...prev, [id]: message }));

        return () =>
            setMessageMap((prev) => {
                const next = { ...prev };
                delete next[id];
                return next;
            });
    }, []);

    const messages = React.useMemo(() => {
        const result = buildVisualMessagesFromExecution(queryResultMessages);
        Object.values<VisualMessageData>(messageMap).forEach((message) => result[message.level].push(message));
        return result;
    }, [messageMap, queryResultMessages]);

    return [messages, addMessage];
}
