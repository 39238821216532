import React, { useReducer } from 'react';

import { KweException } from '@kusto/utils';

import { parseKql } from '../kqlParser/kqlParser';
import { useDataExplorationActions } from './actions';
import { reducer } from './reducers';
import { DataExplorationModalState, VisualState } from './state';

export interface DataExplorationStore {
    state: DataExplorationModalState;
    actions: ReturnType<typeof useDataExplorationActions>;
}

export const dataExplorationStore = React.createContext<undefined | DataExplorationStore>(undefined);

export function useDataExplorationStore() {
    const store = React.useContext(dataExplorationStore);
    if (!store) {
        throw new KweException('useDataExplorationStore can only be called after the context is initialized');
    }

    return store;
}

export function DataExplorationStoreProvider({
    baseQuery,
    visual,
    children,
}: {
    baseQuery: string;
    visual: VisualState;
    children: React.ReactNode;
}) {
    const initState: DataExplorationModalState = {
        sourceKql: parseKql(baseQuery),
        pills: [],
        visual,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const actions = useDataExplorationActions(dispatch, initState);

    return <dataExplorationStore.Provider value={{ state, actions }}>{children}</dataExplorationStore.Provider>;
}
