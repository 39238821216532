import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { assertNever } from '@kusto/utils';

import { EntityType, TableEntityTypes } from '../../../common';
import { FolderEntityType } from '../../../common/entityTypeUtils';
import { Database, FOLDER_PREFIX, QueryStoreEnv } from '../../../stores';
import { getTelemetryClient } from '../../../utils';
import { ITreeEntityMapper } from '../actions';
import { RowDataType } from '../RowDataType';
import { getBaseRootPath } from '../utils';
import { iconMapper } from './IconMapper';
import { RowDataTypeBase } from './RowDataTypeBase';
import { getFromCacheOrCreate } from './RowDataTypeCache';

const { trackTrace } = getTelemetryClient({ component: 'DatabaseFolderRowDataType', flow: '' });

// Don't export, only access these via tablesRootFolderName(). Trident uses different strings.
const regularTablesFolderName = 'Tables';
const externalTablesFolderName = 'External Tables';
const materializedViewFolderName = 'Materialized Views';

export const functionsFolderName = 'Functions';
export const entityGroupFolderName = 'Entity Groups';

/**
 * For each entity type return its root folder.
 * @example External tables will be grouped under the "External Table" folder, so for entity ExternalTable this method will return "External Table".
 */
export const tablesRootFolderName = (entityType?: TableEntityTypes): string => {
    if (!entityType) {
        // A user complained that after opening Kusto Web Explorer for the first time in a while, the site didn't load for him.
        // Investigation showed up, that it was failing in this line because entityType was undefined.
        // Fix: instead of assert, if entityType is undefined, return Table as the root folder name.
        trackTrace('entity type: ' + entityType, SeverityLevel.Error);
        return regularTablesFolderName;
    }
    switch (entityType) {
        case EntityType.Table:
            return regularTablesFolderName;
        case EntityType.ExternalTable:
            return externalTablesFolderName;
        case EntityType.MaterializedViewTable:
            return materializedViewFolderName;
        default:
            trackTrace('entity type: ' + entityType, SeverityLevel.Critical);
            assertNever(entityType);
    }
};

export class DatabaseFolderRowDataType extends RowDataTypeBase {
    /**
     * Creates an instance of DatabaseFolderRowDataType. A RowDataType that represents a database's folder.
     * @param database The database the contains the folder.
     * @param path The full path of the folder from the database and not including the database name. E.g. Tables/tmp
     * @param folderEntityType
     */
    private constructor(
        database: Database,
        path: string,
        folderEntityType: FolderEntityType,
        baseRootPath: string[],
        treeEntityMapper: ITreeEntityMapper
    ) {
        const subFolders = path.split('/');
        super(
            DatabaseFolderRowDataType.buildId(database.id, path),
            [...baseRootPath, ...subFolders],
            subFolders[subFolders.length - 1],
            folderEntityType,
            database,
            iconMapper[folderEntityType],
            treeEntityMapper
        );

        this.actions = this.treeEntityMapper.getActions(folderEntityType);
    }

    public static buildId(databaseId: string, path: string): string {
        return `${FOLDER_PREFIX}${databaseId}/${path}`;
    }

    public static fromCacheOrCreate(
        env: QueryStoreEnv,
        database: Database,
        path: string,
        folderEntityType: FolderEntityType,
        treeEntityMapper: ITreeEntityMapper
    ): RowDataType {
        const id = DatabaseFolderRowDataType.buildId(database.id, path);
        return getFromCacheOrCreate(
            database,
            id,
            () =>
                new DatabaseFolderRowDataType(
                    database,
                    path,
                    folderEntityType,
                    getBaseRootPath(env, database),
                    treeEntityMapper
                )
        );
    }
}
