import * as React from 'react';
import { IRawStyle } from '@fluentui/merge-styles';
import { Icon, IStyle, ITheme, TooltipHost } from '@fluentui/react';
import { classNamesFunction, IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';

import { assertNever, Locale, UField } from '@kusto/utils';
import type { ColorRule } from '@kusto/visual-fwk';

import { getConditionalFormattingColors } from '../../utils/conditionalFormatting/conditionalFormatting';
import type { ConditionalFormattingOptions } from '../../utils/conditionalFormatting/types';

// re-use canvas object for better performance
let canvas: HTMLCanvasElement | undefined;
function getCanvas() {
    canvas ||= document.createElement('canvas');
    return canvas;
}

const defaultHorizontalWidth = 144;
/**
 * Since Recharts isn't smart enough to do this on its own, we're measuring how much pixels we need for ticks.
 * This function is using the canvas capability to measure text size in pixels.
 * @param text The text to measure width for
 */
function getTextWidth(text: string, fontSize: number): number {
    const canvas = getCanvas();

    const context = canvas.getContext('2d');
    const font = `${fontSize}px "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`;
    if (!context) {
        return defaultHorizontalWidth;
    }
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}

const getClassNames = classNamesFunction<StatCardStyleProps, StatCardStyles>();

export type TextSize = 'small' | 'auto' | 'large';

/*
 * Stuff that could be improved:
 * - Verify logic that measures to see if we need to abbreviate actually works. Pretty sure it has bugs.
 * - Support abbreviating timespan and datetime
 * - Special-case `null` and empty-string values with special css + text
 */

export type StatCardStyleProps = Required<Pick<StatCardProps, 'theme' | 'textSize' | 'header'>> &
    Pick<StatCardProps, 'colorStyle' | 'color'>;

export interface StatCardProps {
    /**
     * The value to be displayed in the stat
     */
    valueField: UField;
    rowIndex: number;

    /**
     * Size of text to display
     */
    textSize: TextSize;

    /**
     * Name of locale (to localize numeric data)
     */
    locale: undefined | Locale;

    timeZone: string;

    /**
     * Fluent UI theme for the component.
     */
    theme?: ITheme;

    /**
     * optional style customizations.
     */
    styles?: IStyleFunctionOrObject<StatCardStyleProps, StatCardStyles>;
    header?: string;
    iconName?: ConditionalFormattingOptions['iconName'];
    color?: ConditionalFormattingOptions['color'];
    subLabel?: string;
    colorStyle?: ColorRule.ColorStyle;
}

export interface StatCardStyles {
    root: IStyle;
    value: IStyle;
    valueHost: IStyle;
    label: IStyle;
    subLabel: IStyle;
    header: IStyle;
    footer: IStyle;
    icon: IStyle;
    valueContainer: IStyle;
    invisibleCounterBalance: IStyle;
}

const textSizeToStyle: { [index in TextSize]: IRawStyle } = {
    small: { fontSize: 22, lineHeight: 30 },
    auto: { fontSize: 30, lineHeight: 40 },
    large: { fontSize: 42, lineHeight: 54 },
};

const textSizeToIconSpacing: Record<TextSize, number> = {
    small: 4,
    auto: 6,
    large: 8,
};

const getFontSize = (textSize: TextSize): number => textSizeToStyle[textSize].fontSize as number;

const getCardStyles = (props: StatCardStyleProps): StatCardStyles => {
    const style = textSizeToStyle[props.textSize];
    const fontSize = style.fontSize as number;
    const lineHeight = style.lineHeight as number;
    const spaceBelow = lineHeight - fontSize;
    const {
        // Added while enabling lints

        textColor = props.theme!.palette.neutralPrimary,
        // Added while enabling lints

        headerColor = props.theme!.palette.neutralPrimary,
        // Added while enabling lints

        labelColor = props.theme!.palette.neutralPrimary,
        // Added while enabling lints

        backgroundColor = props.theme!.palette.white,
    } = getConditionalFormattingColors(props, props.theme.isInverted ? 'dark' : 'light');
    // We want 12 pixel diff between value and label. if let's say font size is 30, line height is 40, we have a space of 10 so we need 2 more.
    const marginTop = 12 - spaceBelow;
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: 'auto',
            boxSizing: 'border-box',
            // so that grid item won't exceed container length (in multistat)
            minWidth: 0,
            backgroundColor,
        },
        valueHost: {
            alignSelf: 'center',
            minWidth: 0,
        },
        value: {
            ...textSizeToStyle[props.textSize],
            color: textColor,
            // Without maxWidth long text is allowed to exceed container size and ellipsis is not shown.
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            flexShrink: 1,
        },
        label: {
            fontSize: 10,
            lineHeight: '12px',
            color: labelColor,
            marginTop,
            flexShrink: 1,
        },
        header: {
            alignSelf: 'start',
            color: headerColor,
            paddingLeft: '12px',
            paddingTop: '4px',
            // That's the way to make sure header stays on top (marginBottom will consume all space downwards)
            marginBottom: 'auto',
            maxWidth: '100%',
            overflow: 'hidden',
            boxSizing: 'border-box',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        // since header is using marginBottom to stay on top, in order for the label to stay in center,
        // we need a 'counter-balance' on the other side of label that will eat up the margin from bottom to top.
        invisibleCounterBalance: {
            marginTop: 'auto',
            visibility: 'hidden',
        },
        subLabel: {
            lineHeight: 12,
            border: `1px ${textColor} solid`,
            color: textColor,
            padding: '0 4px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            fontSize: 10,
        },
        footer: {
            display: 'flex',
            alignSelf: 'flex-end',
            boxSizing: 'border-box',
            height: 22,
            padding: 4,
            maxWidth: '100%',
        },
        icon: {
            ...textSizeToStyle[props.textSize],
            color: textColor,
            marginRight: textSizeToIconSpacing[props.textSize],
            selectors: {
                // Sometimes we use SVGs if the icon is not in the Fabric icons library. we need to alter their CSS specificity
                svg: {
                    height: textSizeToStyle[props.textSize].lineHeight,
                    width: textSizeToStyle[props.textSize].fontSize,
                    verticalAlign: 'bottom',
                },
                path: {
                    fill: textColor,
                },
            },
        },
        valueContainer: {
            display: 'flex',
            alignItems: 'center',
            maxWidth: '100%',
            flexGrow: 1,
        },
    };
};

const StatCardBase = ({
    valueField,
    rowIndex,
    styles,
    theme,
    textSize,
    header,
    color,
    colorStyle,
    subLabel,
    iconName,
    locale,
    timeZone,
}: StatCardProps) => {
    const classNames = getClassNames(styles, {
        theme: theme!,
        textSize: textSize ?? 'auto',
        header: header ?? '',
        color,
        colorStyle,
    });
    const ref = React.useRef(null);

    // `shouldAbbreviate` is ignored and not reset if value cannot be abbreviated
    const [shouldAbbreviate, setShouldAbbreviate] = React.useState<boolean>(false);

    const localizedValue = valueField.toLocaleString(rowIndex, timeZone, locale) ?? '';

    // A label is displayed below the abbreviated number in smaller grayer font.
    // It displays the full number for reference (which should be localized as well).
    const label = shouldAbbreviate ? localizedValue : '';

    let displayValue = localizedValue;
    if (valueField.values[rowIndex] !== null && shouldAbbreviate) {
        let rawValue: undefined | string | number;
        switch (valueField.type) {
            case 'bool':
            case 'datetime': // TODO: Abbreviate timespan
            case 'string':
            case 'timespan': // TODO: Abbreviate timespan
            case 'guid':
                break;
            case 'decimal':
            case 'long':
            case 'real':
            case 'int':
                rawValue = valueField.values[rowIndex] ?? undefined;
                break;
            case 'dynamic': {
                const value = valueField.values[rowIndex];
                if (typeof value === 'number') {
                    rawValue = value;
                }
                break;
            }
            default:
                assertNever(valueField);
        }

        if (rawValue !== undefined) {
            displayValue = new Intl.NumberFormat(locale, {
                notation: 'compact',
                compactDisplay: 'short',
            }).format(rawValue as number);
        }
    }

    React.useLayoutEffect(() => {
        switch (valueField.type) {
            case 'bool':
            case 'datetime':
            case 'string':
            case 'timespan':
            case 'guid':
                return;
            case 'dynamic':
                if (typeof valueField.values[rowIndex] !== 'number') {
                    return;
                }
                break;
            case 'decimal':
            case 'int':
            case 'long':
            case 'real':
                break;
            default:
                assertNever(valueField);
        }

        // Abbreviation is shortening a number for display purposes. example:  1,000,000 => 1M
        const fontSize = getFontSize(textSize);
        const textWidth = getTextWidth(localizedValue, fontSize);
        const iconMargins = 4;
        const iconWidth = iconName ? (textSizeToStyle[textSize ?? 'auto'].fontSize as number) + iconMargins : 0;

        const _setShouldAbbreviate = throttle(setShouldAbbreviate, 300);

        let lastWidth: undefined | number;
        const ob = new ResizeObserver((entries) => {
            // entries[0].contentBoxSize[0].inlineSize not available in jest
            // eslint-disable-next-line turbo/no-undeclared-env-vars
            if (process.env.NODE_ENV === 'test' && process.env.JEST_WORKER_ID !== undefined) {
                _setShouldAbbreviate(false);
                return;
            }

            const width = entries[0].contentBoxSize[0].inlineSize;
            if (lastWidth === width) {
                return;
            }
            lastWidth = width;
            _setShouldAbbreviate(width < textWidth + iconWidth);
        });

        ob.observe(ref.current!);
        return () => {
            _setShouldAbbreviate.cancel();
            ob.disconnect();
        };
    }, [textSize, localizedValue, iconName, valueField, rowIndex]);

    return (
        <div ref={ref} className={classNames.root}>
            {header && <div className={classNames.header}>{header}</div>}
            <div className={classNames.valueContainer}>
                <Icon className={classNames.icon} iconName={iconName} />
                <TooltipHost content={localizedValue} hostClassName={classNames.valueHost}>
                    <div className={classNames.value}>{displayValue}</div>
                </TooltipHost>
            </div>
            <div className={classNames.label}>{label}</div>
            {header && <div className={classNames.invisibleCounterBalance}></div>}
            <div className={classNames.footer}>
                {!isEmpty(subLabel?.trim()) && (
                    <TooltipHost content={subLabel} styles={{ root: { minWidth: 0 } }}>
                        <div data-testid="subLabel" className={classNames.subLabel}>
                            {subLabel}
                        </div>
                    </TooltipHost>
                )}
            </div>
        </div>
    );
};

/**
 * A component that displays a single stat (usually numeric, but can be textual).
 * Usually displayed on small rectangular surfaces as part of a dashboard.
 *
 * figma: https://www.figma.com/file/oqmKKJVwc4rZgivntf568F/ADX-Dashboards-BUILD-Handoff?node-id=1993%3A23494&viewport=-6696%2C-11587%2C1.3175917863845825
 */
export const StatCard = styled(StatCardBase, getCardStyles, undefined, { scope: 'statCard' });
