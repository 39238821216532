.typeText {
    margin-left: 4px;
    &:global(.available) {
        color: var(--neutralSecondary);
    }
    &:global(.unavailable) {
        color: var(--errorTextColor);
    }
}

:global(.ms-Dropdown-item) {
    white-space: nowrap;
    &[aria-selected='true'],
    &:hover {
        .typeText:global(.available) {
            color: var(--neutralPrimaryAlt);
        }
    }
}

// This is for non-infer dropdown options.
// These are always a single column name + type format.
.specificItemContainer {
    display: grid;
    grid-template-columns: minmax(0, auto) auto;
}

.truncateText {
    text-overflow: ellipsis;
    overflow-x: hidden;
}
