import * as React from 'react';
import {
    Body1,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemRadio,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tooltip,
} from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';

import { ContentViewModeType, DetailsViewType } from '@kusto/visualizations';

export interface IMenuOption {
    key: string;
    data: DetailsViewType | ContentViewModeType;
    label?: string;
    tooltipProps: {
        content: string;
    };
    icon: React.JSX.Element;
    isDisabled?: boolean | undefined;
}

export interface IJPathMenuProps {
    showLabel?: boolean;
    isMenuItem?: boolean;
}
export type IMenuComponentProps = {
    selectedItem: IMenuOption;
    tooltipContent: string;
    labelWithSelection: string;
    isDisabled?: boolean | undefined;
    menuOptions: IMenuOption[];
    onItemSelected: (item: IMenuOption['data']) => void;
} & IJPathMenuProps;

export const JPathMenuComponent = observer(function JPathMenuComponent({
    selectedItem,
    tooltipContent,
    showLabel,
    isMenuItem,
    labelWithSelection,
    isDisabled,
    menuOptions,
    onItemSelected,
}: IMenuComponentProps) {
    const { icon, label, key } = selectedItem;

    return (
        <Menu key={key} defaultCheckedValues={{ mode: [key] }} aria-label={labelWithSelection}>
            <MenuTrigger disableButtonEnhancement>
                <Tooltip withArrow content={tooltipContent} relationship={'label'}>
                    {isMenuItem ? (
                        <MenuItem hasSubmenu icon={icon} aria-label={labelWithSelection}>
                            {labelWithSelection}
                        </MenuItem>
                    ) : (
                        <MenuButton
                            aria-label={labelWithSelection}
                            icon={icon}
                            appearance="subtle"
                            disabled={isDisabled}
                        >
                            {showLabel && <Body1>{label}</Body1>}
                        </MenuButton>
                    )}
                </Tooltip>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {menuOptions.map((item: IMenuOption) => (
                        <Tooltip
                            key={item.key}
                            withArrow
                            showDelay={500}
                            content={item.tooltipProps.content}
                            positioning={'before'}
                            relationship={'label'}
                        >
                            <MenuItemRadio
                                icon={item.icon}
                                value={item.key}
                                name="mode"
                                onClick={() => onItemSelected(item.data)}
                            >
                                {item.label}
                            </MenuItemRadio>
                        </Tooltip>
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
});
