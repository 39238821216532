import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { armUtils, IArmClient, IKustoClient, ValueRow } from '@kusto/client';
import { Account, castToError } from '@kusto/utils';

import { getTelemetryClient } from '../../utils';
import { QueryStoreEnv } from '../storeEnv';

const tracer = getTelemetryClient({ component: 'healthCheckHandler', flow: '' });

export async function checkClusterHealthEvents(
    env: QueryStoreEnv,
    client: IKustoClient,
    armClient: IArmClient,
    clusterUrl: string,
    account: Account | undefined
) {
    if (!env.featureFlags.cheackClusterHealth) {
        return null;
    }

    const { resourceId, tenantId } = await getClusterResourceId(client, clusterUrl, account);
    if (resourceId === null) {
        return null;
    }
    const { subscriptionId = '', resourceGroup = '', clusterName = '' } = armUtils.parseClusterResourceId(resourceId);

    return armClient
        .sendRequest(`${resourceId}/providers/Microsoft.ResourceHealth/events`, '2018-07-01', account, 'get')
        .then(() => {
            tracer.trackTrace('healthCheckHandler.getClusterHealthEvents success', SeverityLevel.Information, {
                tenantId: tenantId ?? '',
                resourceGroup,
                subscriptionId,
                clusterName,
                resourceId,
            });
        })
        .catch((ex) => {
            tracer.trackTrace('healthCheckHandler.getClusterHealthEvents error', SeverityLevel.Information, {
                status: `${ex?.response?.status}`,
                tenantId: tenantId ?? '',
                resourceGroup,
                subscriptionId,
                clusterName,
                resourceId,
            });
        });
}

async function getClusterResourceId(client: IKustoClient, clusterUrl: string, account: Account | undefined) {
    let resourceId: string | null = null;
    let tenantId: string | null = null;
    try {
        const kustoRequest = client.createRequest(clusterUrl, { source: 'Query' });
        const result = await kustoRequest.executeControlCommand_deprecated(
            undefined,
            '.show cluster monitoring | project CloudResourceId, substring(KustoAccount,5)',
            { account }
        );
        const rows = result.apiCallResult.Tables[0].Rows as ValueRow[];
        resourceId = rows[0][0] as string;
        tenantId = rows[0][1] as string;
    } catch (ex) {
        tracer.trackException(castToError(ex), 'healthCheckHandler.getClusterResourceId');
    }
    return { resourceId, tenantId };
}
