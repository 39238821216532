import { SeverityLevel } from '@microsoft/applicationinsights-web';
import * as mobx from 'mobx';

import { IKustoClientAuthProvider, KustoClient, KustoClientEvents, KustoDomains } from '@kusto/client';
import type { IKweTelemetry } from '@kusto/utils';

import type { IQueryRootStore } from '../stores';
import { getTelemetryClient } from './telemetryClient';

const { trackTrace } = getTelemetryClient({
    component: 'kustoClient',
    flow: 'execute',
});

class QueryKustoEvents implements KustoClientEvents {
    nonKustoDomain(targetDomain: string) {
        trackTrace('Blocked a non-whitelisted domain', SeverityLevel.Warning, { targetDomain });
    }

    tokenExpired(message: string) {
        trackTrace('token expired', SeverityLevel.Information, { message });
    }

    tokenFetched(
        clientActivityId: string,
        applicationUrl: string,
        url: string,
        dbName: string,
        queryType: 'query' | 'command'
        // queryText: string
    ) {
        trackTrace('token fetched', SeverityLevel.Information, {
            clientActivityId,
            applicationUrl,
            url,
            dbName: dbName || 'N/A',
            queryType,
        });
    }

    onUnauthorizedError(
        clientActivityId: string,
        retrying: boolean,
        refreshTokenSupported: boolean,
        retryCount: number
        // error: Error
    ) {
        trackTrace('Unauthorized Error (401)', SeverityLevel.Information, {
            clientActivityId: clientActivityId,
            retryCount: `${retryCount}`,
            refreshTokenSupported: `${refreshTokenSupported}`,
            retrying: `${retrying}`,
        });

        if (retrying && !refreshTokenSupported) {
            // TODO(pii): Need to sanitize error before we can log again
            trackTrace('unauthorized error', SeverityLevel.Error, { clientActivityId });
        }
    }

    responseParsed(type: string, duration: number, length?: number) {
        trackTrace('response parsed', SeverityLevel.Information, {
            type,
            length: `${length}`,
            duration: `${duration}`,
        });
    }
}

export class QueryKustoClient extends KustoClient {
    /**
     * @param store Getter to avoid circular dependency
     */
    constructor(
        kustoDomains: KustoDomains,
        authenticationProvider: IKustoClientAuthProvider,
        telemetry: IKweTelemetry,
        sessionId: string,
        appVersion: string,
        store: () => IQueryRootStore
    ) {
        const appId = 'KWE';

        super(
            kustoDomains,
            authenticationProvider,
            telemetry,
            sessionId,
            { appId, appVersion },
            new QueryKustoEvents(),
            mobx.action(async (isQuery) => store().settings.clientRequestProperties(isQuery))
        );
    }
}
