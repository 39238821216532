export const DefaultColumnTypes = {
    numericColumn: {
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell'
    },
    rightAligned: {
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell'
    }
};
