/* eslint-disable @typescript-eslint/no-redeclare */
import { types } from 'mobx-state-tree';

import { ContentViewModeType, DetailsViewType } from '@kusto/visualizations';

import { getTelemetryClient, SeverityLevels } from '../utils/telemetryClient';

const { trackTrace } = getTelemetryClient({
    component: 'layout',
    flow: '',
});

const WindowSizeType = types.enumeration('WindowSizeType', ['XXSmall', 'XSmall', 'Small', 'Medium', 'Large']);

// eslint-disable-next-line no-redeclare
export type WindowSizeType = typeof WindowSizeType.Type;

const WINDOW_WIDTH_TYPE_TO_RANGE: Record<WindowSizeType, [number, number]> = {
    XXSmall: [0, 320],
    XSmall: [320, 480],
    Small: [480, 624],
    Medium: [624, 860],
    Large: [860, Number.MAX_SAFE_INTEGER],
};

const WINDOW_HEIGHT_TYPE_TO_RANGE: Partial<Record<WindowSizeType, [number, number]>> = {
    Small: [0, 256],
    Medium: [256, 640],
    Large: [640, Number.MAX_SAFE_INTEGER],
};

const calcWindowSizeType: (dimension: 'height' | 'width') => WindowSizeType = (dimension) => {
    const WINDOW_SIZE_TO_RANGE = dimension === 'width' ? WINDOW_WIDTH_TYPE_TO_RANGE : WINDOW_HEIGHT_TYPE_TO_RANGE;
    const windowSizePixels = dimension === 'width' ? window.innerWidth : window.innerHeight;
    for (const [key, value] of Object.entries(WINDOW_SIZE_TO_RANGE)) {
        if (value && windowSizePixels > value[0] && windowSizePixels <= value[1]) {
            return key as WindowSizeType;
        }
    }
    return 'Large';
};

export const connectionPaneMinWidthInPixels = 100;
export const connectionPaneDefaultWidth = '228px';

export const Layout = types
    .model('Layout', {
        windowWidthSizeType: types.maybe(WindowSizeType),
        expandViewLayout: types.optional(types.integer, DetailsViewType.InGrid),
        contentViewMode: types.optional(types.integer, ContentViewModeType.Full),
        windowHeightSizeType: types.maybe(WindowSizeType),
        /// left pane here is the connection pane - and not navigation pane.
        // couldn't change the nomenclature because of backward compat.
        leftPaneSize: types.maybe(types.string),
        isLeftPaneCollapsed: types.optional(types.boolean, false),
        showNavigationPane: types.optional(types.boolean, true),
        isVisualConfigOpen: types.optional(types.boolean, true),
        enableNewConnectionPane: types.optional(types.boolean, true),
    })
    .views((self) => ({
        get displayedExpandViewLayout() {
            return self.windowHeightSizeType === 'Small' ? DetailsViewType.InGrid : self.expandViewLayout;
        },
    }))
    .volatile(() => ({
        isMonacoEditorCollapsed: false,
    }))
    .actions((self) => ({
        setExpandViewLayout(layout: DetailsViewType) {
            const layoutName = DetailsViewType[layout];
            trackTrace('Expand View Layout Changed', SeverityLevels.Information, {
                layout: layoutName,
            });
            self.expandViewLayout = layout;
        },
        setContentViewMode(contentViewMode: ContentViewModeType) {
            const contentViewModeName = ContentViewModeType[contentViewMode];
            trackTrace('Content View Mode Changed', SeverityLevels.Information, {
                contentViewMode: contentViewModeName,
            });
            self.contentViewMode = contentViewMode;
        },
        setWindowSizeType(dimension: 'width' | 'height', sizeType: WindowSizeType) {
            if (dimension === 'width') {
                self.windowWidthSizeType = sizeType;
            } else {
                self.windowHeightSizeType = sizeType;
            }
        },
        setIsMonacoEditorCollapsed(isCollapsed: boolean) {
            self.isMonacoEditorCollapsed = isCollapsed;
        },
        handleWindowResize() {
            const windowWidthSizeType = calcWindowSizeType('width');
            const windowHeightSizeType = calcWindowSizeType('height');
            if (self.windowWidthSizeType !== windowWidthSizeType) {
                this.setWindowSizeType('width', windowWidthSizeType);
            }
            if (self.windowHeightSizeType !== windowHeightSizeType) {
                this.setWindowSizeType('height', windowHeightSizeType);
            }
        },
        afterCreate() {
            this.handleWindowResize();
            if (self.leftPaneSize === undefined || self.leftPaneSize.includes('%')) {
                // move away from percentage to pixels;
                self.leftPaneSize = connectionPaneDefaultWidth;
            }
            window.addEventListener('resize', this.handleWindowResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleWindowResize);
        },
        saveLeftPaneSize(size: string) {
            self.leftPaneSize = size;
        },
        toggleIsLeftPaneCollapse(isCollapsed?: boolean) {
            const newValue = isCollapsed ?? !self.isLeftPaneCollapsed;
            self.isLeftPaneCollapsed = newValue;

            if (!self.isLeftPaneCollapsed) {
                if (self.leftPaneSize?.includes('px')) {
                    const pixels = parseFloat(self.leftPaneSize);
                    if (!isNaN(pixels) && pixels < connectionPaneMinWidthInPixels) {
                        self.leftPaneSize = connectionPaneDefaultWidth;
                    }
                }
            }
        },
        toggleShowHideNavigation() {
            self.showNavigationPane = !self.showNavigationPane;
        },
        toggleVisualConfigOpen() {
            self.isVisualConfigOpen = !self.isVisualConfigOpen;
        },
        toggleNewConnectionPane() {
            self.enableNewConnectionPane = !self.enableNewConnectionPane;
        },
        setVisualConfigOpen(isOpen: boolean) {
            self.isVisualConfigOpen = isOpen;
        },
    }));
// eslint-disable-next-line no-redeclare
export type Layout = typeof Layout.Type;
