import * as React from 'react';
import { Toolbar } from '@fluentui/react-components';
import { ChevronLeftRegular, ChevronRightRegular } from '@fluentui/react-icons';
import debounce from 'lodash/debounce';

import { VisualizationsStrings } from '../../types.ts';
import { ActionItem } from './ActionItem';

import * as styles from './InteractiveLegend.module.scss';

const SCROLL_PERCENT = 0.6;
const EFFECTIVE_START = 4;

interface ScrollArrowsProps {
    containerRef: React.RefObject<HTMLDivElement>;
    visibleItems: number;
    strings: VisualizationsStrings;
}

export const ScrollControls: React.FC<ScrollArrowsProps> = ({ containerRef, visibleItems, strings }) => {
    const [disableScrollLeft, setDisableScrollLeft] = React.useState<boolean>();
    const [disableScrollRight, setDisableScrollRight] = React.useState<boolean>();

    const checkScrollability = React.useMemo(
        () =>
            debounce(() => {
                if (containerRef.current && visibleItems) {
                    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
                    setDisableScrollLeft(scrollLeft <= EFFECTIVE_START);
                    setDisableScrollRight(scrollLeft + clientWidth >= scrollWidth);
                }
            }, 300),
        [containerRef, visibleItems]
    );

    React.useEffect(() => {
        const ref = containerRef.current;
        if (containerRef.current) {
            containerRef.current.addEventListener('scroll', checkScrollability);
            checkScrollability();
        }

        return () => {
            ref?.removeEventListener('scroll', checkScrollability);
        };
    }, [containerRef, checkScrollability]);

    const scroll = (isNext: boolean) => {
        if (!containerRef.current) {
            return;
        }
        const viewportWidth = containerRef.current.clientWidth;
        const scrollDelta = isNext ? viewportWidth * SCROLL_PERCENT : -viewportWidth * SCROLL_PERCENT;
        const targetScroll = containerRef.current.scrollLeft + scrollDelta;
        containerRef.current?.scrollTo({ left: targetScroll, behavior: 'smooth' });
    };

    const noScroll = disableScrollLeft && disableScrollRight;

    return (
        <Toolbar className={styles.scrollButtons}>
            {!noScroll && (
                <>
                    <ActionItem
                        appearance="subtle"
                        icon={<ChevronLeftRegular />}
                        onClick={() => scroll(false)}
                        content={strings.interactiveLegend.scrollLeft}
                        disabled={disableScrollLeft}
                        data-testid="scroll-left"
                    />
                    <ActionItem
                        appearance="subtle"
                        icon={<ChevronRightRegular />}
                        onClick={() => scroll(true)}
                        content={strings.interactiveLegend.scrollRight}
                        disabled={disableScrollRight}
                        data-testid="scroll-right"
                    />
                </>
            )}
        </Toolbar>
    );
};
