import * as dateFns from 'date-fns';
import * as Highcharts from 'highcharts';

import * as client from '@kusto/client';
import {
    assertNever,
    err,
    formatLiterals,
    isOneOfType,
    KUSTO_TIME_TYPES,
    KweException,
    ok,
    type DataFrame,
    type KustoDataType,
    type KustoNumericType,
    type Ok,
    type Result,
    type StringField,
    type UField,
    type UnknownDataFrameValue,
} from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';
import { getFirstFieldOfType } from '@kusto/visualizations';

import { KweRtdVisualContext } from '../../context';
import { HeatmapModelDef } from './model';

// TODO(perf): Skip creating data items and convert to highcharts values directly

type Datum = number | string | boolean | null;
/**
 * For Heatmap within Highcharts,
 * it seems like it always expects the data items
 * to be in a Tuple of length 3.
 * Where each index in the tuple can represent
 * something different.
 *
 * @see https://www.highcharts.com/demo/heatmap
 * @see https://www.highcharts.com/demo/heatmap-canvas
 *
 * For example, a dataset for number of sales for employees by day (by category)
 * ```
 *  ['Alexander',   'Monday',     120],
 *  ['Alexander',   'Tuesday',    80],
 *  ['Marie',       'Monday',     65],
 *  ['Marie',       'Tuesday',    90],
 *  ['Sophia',      'Monday',     87],
 *  ['Sophia',      'Tuesday',    93],
 * ```
 * Where index-0 is the employee name [x = string]
 * Where index-1 is the category [y = string]
 * Where index-2 is the data value of the stock value ($) [value = numeric]
 *
 * For example, a dataset for temperature changes by dates per hour (by datetime)
 * ```
 *  [2023-01-05, 1100, 45],
 *  [2023-01-05, 1200, 50],
 *  [2023-01-05, 1300, 55],
 *  [2023-01-06, 1100, 38],
 *  [2023-01-06, 1200, 42],
 *  [2023-01-06, 1300, 44],
 * ```
 * Where index-0 is the date the recording took place [x = datetime]
 * Where index-1 is the 24-hour stamp when the recording occurred [y = numeric]
 * Where index-2 is the data value of the temperature (in Celsius) [value = numeric]
 */
export type DataItem = [Datum, Datum, Datum];

interface BaseSuccess<AxisType extends Highcharts.AxisTypeValue> {
    /**
     * This is our main discriminator
     */
    xAxisType: AxisType;
    yField: UField;
    xField: UField;
    dataField: UField;
    dataItems: DataItem[];
}

interface SuccessByCategory extends BaseSuccess<'category'> {
    yCategories: string[];
    xCategories: string[];
}

interface SuccessByDatetime extends BaseSuccess<'datetime'> {
    /**
     * Only defined IF the yField is a string type
     */
    maybeYCategories: undefined | string[];
    colSize: undefined | number;
}

export type HeuristicsOk = SuccessByCategory | SuccessByDatetime;

interface HeuristicErr {
    errMsgs: {
        xColumn?: string;
        yColumn?: string;
        dataColumn?: string;
    };
    /**
     * The current selected column names when we errored out.
     */
    selections: Selections;
}

export type Heuristics = null | Result<HeuristicsOk, HeuristicErr>;

function getValue(rawValue: UnknownDataFrameValue, columnType: KustoDataType) {
    if (columnType === 'datetime') {
        const valueAsDate = new Date(rawValue as number | string);
        return dateFns.isValid(valueAsDate) ? dateFns.getTime(valueAsDate) : null;
    }

    return client.queryAreaValueFromKweValue(columnType, rawValue);
}

function manualSelection(
    ctx: KweRtdVisualContext,
    fields: DataFrame['fields'],
    selectedColumn: string,
    columnTitle: string
): Result<UField, string> {
    /**
     * The user selected column could be missing in the query results
     * so we need to verify it exists.
     *
     * Example: First run of query results includes their user-defined
     * column A. Second run of query results is missing column A so we
     * need to show them an error message that it's missing.
     */
    const selectedField = fields.find((field) => field.name === selectedColumn);

    if (selectedField) {
        return ok(selectedField);
    }

    return err(
        formatLiterals(ctx.strings.rtdProvider.visuals.sharedMessages.missingColumn, {
            columnName: columnTitle,
        })
    );
}

export interface Selections {
    xField: Result<UField, string>;
    yField: Result<UField, string>;
    dataField: Result<UField, string>;
}

/**
 * Representation of {@link Selections} where each
 * field is in it's Ok state
 */
export interface SelectionsOk<
    XField extends UField = UField,
    YField extends UField = UField,
    DataField extends UField = UField
> {
    xField: Ok<XField>;
    yField: Ok<YField>;
    dataField: Ok<DataField>;
}

// TODO(data-accuracy): datetime and timespan are also numeric types but this can't be changed
// because it would change visualization behavior
// @see https://msazure.visualstudio.com/DefaultCollection/One/_git/Azure-Kusto-WebUX/pullRequest/10106217#1715978126
const SUPPORTED_NUMERIC_DATA_TYPES = ['int', 'real', 'long', 'decimal'] satisfies KustoNumericType[];

const SUPPORTED_YFIELD_BY_DATETIME_TYPES = [...SUPPORTED_NUMERIC_DATA_TYPES, 'string'] satisfies KustoDataType[];

type SupportedYFields = Extract<UField, { type: (typeof SUPPORTED_YFIELD_BY_DATETIME_TYPES)[number] }>;

export function getSelections(
    ctx: KweRtdVisualContext,
    { visualOptions }: Fwk.HeuristicsProps<HeatmapModelDef>,
    dataFrame: DataFrame
): Selections {
    /**
     * Note: For inferring, we always try to satisfy inference "by datetime"
     * See the jsdoc for {@link DataItem} to understand what "by datetime" means.
     */
    const { xColumn, yColumns, heatMap__dataColumn: dataColumn } = visualOptions;
    const heatmapStrings = ctx.strings.rtdProvider.visuals.heatmap;
    // As an easy out, we can default to the same field for
    // all the selections since we support that behavior
    const defaultField = dataFrame.fields[0];

    let xField: Selections['xField'];
    if (xColumn !== null) {
        xField = manualSelection(ctx, dataFrame.fields, xColumn, heatmapStrings.xColumnTitle);
    } else {
        // Assume the user wants a datetime for xColumn
        const tempXColumn = getFirstFieldOfType(dataFrame, [KUSTO_TIME_TYPES], []) ?? defaultField;

        if (tempXColumn) {
            xField = ok(tempXColumn);
        } else {
            xField = err(
                formatLiterals(ctx.strings.rtdProvider.visuals.sharedMessages.unableToInferColumn, {
                    columnName: heatmapStrings.xColumnTitle,
                })
            );
        }
    }

    let dataField: Selections['dataField'];
    if (dataColumn !== null) {
        dataField = manualSelection(ctx, dataFrame.fields, dataColumn, heatmapStrings.valueColumnTitle);
    } else {
        const columnsToIgnore = [];

        if (xField.kind === 'ok') {
            columnsToIgnore.push(xField.value.name);
        }

        // dataColumn should always be numeric as Infer
        const tempDataColumn =
            getFirstFieldOfType(dataFrame, [SUPPORTED_NUMERIC_DATA_TYPES], columnsToIgnore) ?? defaultField;

        if (tempDataColumn) {
            dataField = ok(tempDataColumn);
        } else {
            dataField = err(
                formatLiterals(ctx.strings.rtdProvider.visuals.sharedMessages.unableToInferColumn, {
                    columnName: heatmapStrings.valueColumnTitle,
                })
            );
        }
    }

    let yField: Selections['yField'];
    if (yColumns !== null && yColumns.length !== 0) {
        // Heatmap specific: We will always just care
        // about the first yColumn in the yColumns array.
        // Essentially treating it as a single-selection.
        yField = manualSelection(ctx, dataFrame.fields, yColumns[0], heatmapStrings.yColumnTitle);
    } else {
        const columnsToIgnore = [];

        if (xField.kind === 'ok') {
            columnsToIgnore.push(xField.value.name);
        }

        if (dataField.kind === 'ok') {
            columnsToIgnore.push(dataField.value.name);
        }

        const firstNumericOrStringColumn =
            getFirstFieldOfType(
                dataFrame,

                [SUPPORTED_YFIELD_BY_DATETIME_TYPES],
                columnsToIgnore
            ) ?? defaultField;

        if (firstNumericOrStringColumn) {
            yField = ok(firstNumericOrStringColumn);
        } else {
            yField = err(
                formatLiterals(ctx.strings.rtdProvider.visuals.sharedMessages.unableToInferColumn, {
                    columnName: heatmapStrings.yColumnTitle,
                })
            );
        }
    }

    return {
        xField,
        yField,
        dataField,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CreateDataItemCallback<XValue = any, YValue = any, DataValue = any> = (
    xValue: XValue,
    yValue: YValue,
    dataValue: DataValue
) => null | DataItem;

function createDataItems(
    { xField, dataField, yField }: SelectionsOk,
    createDataItem: CreateDataItemCallback
): DataItem[] {
    const dataItems: DataItem[] = [];

    for (let i = 0; i < xField.value.values.length; i++) {
        // get all values - x,y and data
        const rawXValue = xField.value.values[i];
        const rawYValue = yField.value.values[i];
        const rawDataValue = dataField.value.values[i];

        const xValue = getValue(rawXValue, xField.value.type);
        const yValue = getValue(rawYValue, yField.value.type);
        const dataValue = getValue(rawDataValue, dataField.value.type);

        const item = createDataItem(xValue, yValue, dataValue);

        // We can skip null items because adding them to this
        // array would still result in the same Highcharts
        // behavior of the item not being rendered in the visual
        if (item) {
            dataItems.push(item);
        }
    }

    return dataItems;
}

export function tryConvertToNumber(value: ReturnType<typeof getValue>): null | number {
    if (typeof value === 'number' || value === null) {
        /**
         * Customer's can have `int(null)` or null values
         * @see https://learn.microsoft.com/en-us/azure/data-explorer/kusto/query/scalar-data-types/null-values?pivots=azuredataexplorer
         */
        return value;
    }

    /**
     * For "long" or "decimal" KWE types (e.g. floating-point types) they come to us
     * as stringified numbers so we need to check for them
     */
    if (typeof value === 'string') {
        /**
         * We're going to lose some precision for BigInts.
         *
         * TODO: Once Highcharts supports BigInts we should
         * be converting over to it here to avoid precision loss
         * @see https://github.com/highcharts/highcharts/issues/14819#issuecomment-747469458
         */
        const valueAsNum = Number(value);

        if (!isNaN(valueAsNum)) {
            return valueAsNum;
        }
    }

    /**
     * For rest of the cases, we throw because this fn is only called in code paths
     * where it's impossible to get to here because of the types.
     */
    throw new KweException('Unexpected value received while trying to convert to number for Heatmap');
}

export function createDataItemByDatetime(
    ctx: KweRtdVisualContext,
    selectionsOk: SelectionsOk,
    maybeYCategories?: string[]
) {
    const { xField, yField, dataField } = selectionsOk;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function maybeConvertToRawNumber(columnKind: 'x' | 'y' | 'data', value: any) {
        let currentField: UField;

        switch (columnKind) {
            case 'x':
                currentField = xField.value;
                break;
            case 'y':
                currentField = yField.value;
                break;
            case 'data':
                currentField = dataField.value;
                break;
            default:
                assertNever(columnKind);
        }

        if (isOneOfType(currentField, SUPPORTED_NUMERIC_DATA_TYPES)) {
            // Highcharts gracefully accepts any kind of value for the `dataValue`
            // but we should try our best to convert numeric types to raw JS
            // numbers so it gets used correctly in the Heatmap.
            // Going to lose precision here but numeric values should always be
            // raw numbers. Without this, we'd get stringified numbers like "4.0999999999999996"
            return tryConvertToNumber(value);
        }

        return value;
    }

    const createDataItemCallback: CreateDataItemCallback<null | string | number, null | string | number> = (
        xValue,
        yValue,
        dataValue
    ) => {
        const innerXValue = maybeConvertToRawNumber('x', xValue);

        /**
         * We are returning null here because if we just passed
         * it along then Highcharts for some reason renders
         * the visual in a terrible way that isn't helpful at all.
         * So it's visually better to just omit this data item all
         * together.
         *
         * @see https://msazure.visualstudio.com/DefaultCollection/One/_workitems/edit/17275538
         *
         * TODO: Warn user we are omitting data because of x-column nulls in their
         * data set. A good candidate for visual warnings for the new Tile Warning UX.
         */
        if (innerXValue === null) {
            return null;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let innerYValue: any;
        if (maybeYCategories) {
            if (typeof yValue !== 'string') {
                throw new KweException('Y value should always be a string when maybeYCategories is defined');
            }

            const yCategoryIdx = maybeYCategories.indexOf(yValue);
            // We should never get into this bad state because
            // the categories are computed off the query result so it
            // should always resolve correctly.
            // However, in the unknown-unknown case that the index is missing
            // we can assign `null` because Highcharts gracefully handles
            // this case by rendering an empty cell thankfully.
            if (yCategoryIdx === -1) {
                ctx.telemetry.error('Failed to get yCategoryIdx in Heatmap', { disablePiiRedactor: true });
                innerYValue = null;
            } else {
                // Use the category index because yField is a string column type in this scenario
                innerYValue = yCategoryIdx;
            }
        } else {
            innerYValue = maybeConvertToRawNumber('y', yValue);
        }

        const innerDataValue = maybeConvertToRawNumber('data', dataValue);

        // Should always be in [x, y, value] order inside Tuple
        const datum: DataItem = [innerXValue, innerYValue, innerDataValue];
        return datum;
    };

    return createDataItemCallback;
}

function getDatetimeErrors(
    ctx: KweRtdVisualContext,
    selectionsOk: SelectionsOk
): Result<SelectionsOk<never, SupportedYFields>, HeuristicErr> {
    const yField = selectionsOk.yField.value;
    // implicitly the xColumn has to be a datetime type in order
    // for this code path to get called so we don't need to
    // check for the xColumn type here again.
    const errRecord: HeuristicErr = {
        errMsgs: {},
        selections: selectionsOk,
    };

    // yValue must be a numerical value (e.g. number that represents KWE `int` type)
    // when it's a Heatmap by Datetime.
    //
    // numeric data types is too narrow for the generic column type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!isOneOfType(yField, SUPPORTED_YFIELD_BY_DATETIME_TYPES)) {
        errRecord.errMsgs.yColumn =
            formatLiterals(ctx.strings.rtdProvider.visuals.heatmap.errors.columnBadType, {
                columnName: yField.name,
                columnType: yField.type,
            }) + ctx.strings.rtdProvider.visuals.heatmap.errors.suggestion.useNumericalOrString;
        return err(errRecord);
    }

    // Need to cast because TS can't infer YField is one of the SupportedYFieldTypes
    return ok(selectionsOk as unknown as SelectionsOk<never, SupportedYFields>);
}

/**
 * This function finds the number of milliseconds between two unique smallest timestamps.
 *
 * In heatmap, when passing X as datetime, Highcharts does not know if there is an aggregation in the query.
 * To make sure we display the visualization with as little as empty spots as possible we need to calculate col size.
 */
export function calculateColSize(dataItems: DataItem[]): undefined | number {
    if (dataItems.length <= 1) {
        return;
    }

    let smallestTimeStamp = Number.MAX_SAFE_INTEGER;
    // casting because we are guaranteed it's a number
    // based off our error check before this fn is called
    let secondSmallestTimeStamp = dataItems[1][0] as number;

    for (const dataItem of dataItems) {
        // We always use index-0 because that's where
        // the xColumn (datetime) will be
        const currentTimeStamp = dataItem[0] as number;
        if (currentTimeStamp < smallestTimeStamp) {
            secondSmallestTimeStamp = smallestTimeStamp;
            smallestTimeStamp = currentTimeStamp;
            // when user aggregate the data, there are duplicated dates -- we needs to avoid those
        } else if (currentTimeStamp < secondSmallestTimeStamp && currentTimeStamp !== smallestTimeStamp) {
            secondSmallestTimeStamp = currentTimeStamp;
        }
    }
    return secondSmallestTimeStamp - smallestTimeStamp;
}

function dataByDatetime(ctx: KweRtdVisualContext, selectionsOk: SelectionsOk): Result<SuccessByDatetime, HeuristicErr> {
    const res = getDatetimeErrors(ctx, selectionsOk);

    if (res.kind === 'err') {
        return res;
    }

    let maybeYCategories: undefined | string[];
    /**
     * We only need to compute the yCategories when yField is a string
     * because Highchart's heatmap by datetime can support this scenario.
     * However, we need to pass in the index of the yCategory like how we do
     * it in {@link createDataItemByCategory} for the yField.
     */
    if (res.value.yField.value.type === 'string') {
        maybeYCategories = getCategories(res.value.yField.value);
    }

    const dataItems = createDataItems(selectionsOk, createDataItemByDatetime(ctx, selectionsOk, maybeYCategories));
    const colSize = calculateColSize(dataItems);

    const data: SuccessByDatetime = {
        xAxisType: 'datetime',
        dataItems,
        dataField: selectionsOk.dataField.value,
        xField: selectionsOk.xField.value,
        yField: selectionsOk.yField.value,
        colSize,
        maybeYCategories,
    };

    return ok(data);
}

export function createDataItemByCategory(
    ctx: KweRtdVisualContext,
    xCategories: string[],
    yCategories: string[],
    convertDataValueToRawNumber: boolean
) {
    const createDataItemCallback: CreateDataItemCallback<string, string> = (xValue, yValue, dataValue) => {
        // Note: When it's a Heatmap by Category
        // the xValue and yValue should always
        // be the number index from the respective categories.
        // Highcharts uses this to figure out how to group the data
        // on their side for the visual.
        //
        // For example, if we have the following x categories: ["Alexander", "Marie", "Sophia"]
        // and the following y categories: ["Monday", "Tuesday"]
        // means we can build out our Tuples for Highcharts like so:
        // ["Alexander", "Monday", 120] -> [0, 0, 120]
        // ["Alexander", "Tuesday", 80] -> [0, 1, 80]
        // ["Sophia", "Tuesday", 93] -> [2, 1, 93]
        let xCategoryIdx: null | number = xCategories.indexOf(xValue);
        let yCategoryIdx: null | number = yCategories.indexOf(yValue);

        // We should never get into this bad state because
        // the categories are computed off the query result so it
        // should always resolve correctly.
        // However, in the unknown-unknown case that the index is missing
        // we can assign `null` because Highcharts gracefully handles
        // this case by rendering an empty cell thankfully.
        if (xCategoryIdx === -1) {
            ctx.telemetry.error('Failed to get xCategoryIdx in Heatmap', { disablePiiRedactor: true });
            xCategoryIdx = null;
        }

        if (yCategoryIdx === -1) {
            ctx.telemetry.error('Failed to get yCategoryIdx in Heatmap', { disablePiiRedactor: true });
            yCategoryIdx = null;
        }

        const innerDataValue = convertDataValueToRawNumber ? tryConvertToNumber(dataValue) : dataValue;

        const datum: DataItem = [xCategoryIdx, yCategoryIdx, innerDataValue];
        return datum;
    };

    return createDataItemCallback;
}

function getCategoryErrors(
    ctx: KweRtdVisualContext,
    selectionsOk: SelectionsOk
): Result<SelectionsOk<StringField, StringField>, HeuristicErr> {
    let hasError = false;
    const errRecord: HeuristicErr = {
        errMsgs: {},
        selections: selectionsOk,
    };
    const heatmapStrings = ctx.strings.rtdProvider.visuals.heatmap;
    const xField = selectionsOk.xField.value;
    const yField = selectionsOk.yField.value;

    if (xField.type !== 'string') {
        errRecord.errMsgs.xColumn =
            formatLiterals(heatmapStrings.errors.columnBadType, {
                columnName: xField.name,
                columnType: xField.type,
            }) + heatmapStrings.errors.suggestion.useString;
        hasError = true;
    }

    if (yField.type !== 'string') {
        errRecord.errMsgs.yColumn =
            formatLiterals(heatmapStrings.errors.columnBadType, {
                columnName: yField.name,
                columnType: yField.type,
            }) + heatmapStrings.errors.suggestion.useString;
        hasError = true;
    }

    if (hasError) {
        return err(errRecord);
    }

    // Casting because TS doesn't infer xField and yField as `StringField`
    // by this point :/
    return ok(selectionsOk as unknown as SelectionsOk<StringField, StringField>);
}

/**
 * Gets a unique set of column values (categories) from the query results.
 * Should only be called when it's guaranteed the column name is of
 * type "string".
 *
 * An example return
 * @example
 * ["Seattle", "Redmond", "Sammamish", "Bellevue"],
 *
 * Note: NOT to be used with `dataColumn` as that can be any type
 */
function getCategories(field: StringField): string[] {
    // StringField should really have string[] as it's values, but we don't
    // bother to exclude `null` from it's types at this time
    return Array.from(new Set(field.values as string[]));
}

function dataByCategory(ctx: KweRtdVisualContext, selectionsOk: SelectionsOk): Result<SuccessByCategory, HeuristicErr> {
    const res = getCategoryErrors(ctx, selectionsOk);

    if (res.kind === 'err') {
        return res;
    }

    const xCategories: string[] = getCategories(res.value.xField.value);
    const yCategories: string[] = getCategories(res.value.yField.value);

    const dataItems = createDataItems(
        selectionsOk,
        createDataItemByCategory(
            ctx,
            xCategories,
            yCategories,
            isOneOfType(selectionsOk.dataField.value, SUPPORTED_NUMERIC_DATA_TYPES)
        )
    );

    const data: SuccessByCategory = {
        xAxisType: 'category',
        dataItems,
        dataField: selectionsOk.dataField.value,
        xField: selectionsOk.xField.value,
        yField: selectionsOk.yField.value,
        xCategories,
        yCategories,
    };

    return ok(data);
}

export function createHeatmapHeuristicsResult(ctx: KweRtdVisualContext, selections: Selections): Heuristics {
    const { xField: selectedXField, yField: selectedYField, dataField: selectedDataField } = selections;

    if (selectedXField.kind === 'err' || selectedYField.kind === 'err' || selectedDataField.kind === 'err') {
        // We cannot render a heatmap in any way if one of the column selections
        // is in an error state. There is no way that can happen so let's bail early here.
        return err({
            selections,
            errMsgs: {
                xColumn: selectedXField.err,
                yColumn: selectedYField.err,
                dataColumn: selectedDataField.err,
            },
        });
    }

    /**
     * TS can't infer all the fields in {@link selections} are in their Ok state
     * by here so we have to cast
     */
    const castedSelections = selections as unknown as SelectionsOk;

    // If the X field is a time type, we need to treat it as a Heatmap by datetime
    if (isOneOfType(castedSelections.xField.value, KUSTO_TIME_TYPES)) {
        return dataByDatetime(ctx, castedSelections);
    }

    return dataByCategory(ctx, castedSelections);
}

export function createHeuristics(ctx: KweRtdVisualContext): Fwk.VisualConfigHeuristicsFnc<HeatmapModelDef, Heuristics> {
    return function innerHeuristics(props) {
        const { queryResult } = props;

        if (queryResult === undefined) {
            return null;
        }

        const selections = getSelections(ctx, props, queryResult.dataFrame);
        return createHeatmapHeuristicsResult(ctx, selections);
    };
}
