import React from 'react';

import { DataFrameSchema, formatLiterals, KweException } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';
import { inferStatColumns, StatCardChart, StatHeuristicsResult } from '@kusto/visualizations';

import { useCreateConditionalFormatting } from '../colorRules';
import { KweRtdVisualContext } from '../context';
import { RtdProviderLocale, RtdProviderStrings } from '../i18n';
import { colorRulesSegment } from '../inputLib';

export interface HeuristicsSuccess extends StatHeuristicsResult {
    columns: DataFrameSchema;
}

type Heuristics = null | HeuristicsSuccess;

export function createHeuristicsFnc(
    Visualization: 'card' | 'multistat'
): Fwk.VisualConfigHeuristicsFnc<MultiStatModelDef, Heuristics> {
    return ({ queryResult, visualOptions }): Heuristics => {
        if (queryResult === undefined) {
            return null;
        }

        const valueColumn = visualOptions.multiStat__valueColumn;
        // If this is for 'card', then we can't access multistat props. Would be more type-safe to write 2 selectors.
        const labelColumn = Visualization === 'multistat' ? visualOptions.multiStat__labelColumn : null;
        const result = inferStatColumns(
            {
                YColumns: valueColumn !== null ? [valueColumn] : null,
                XColumn: labelColumn,
                Series: null,
                Visualization,
            },
            queryResult.dataFrame
        );

        return { columns: queryResult.dataFrame.fields, ...result };
    };
}

function useVisualMessages(strings: RtdProviderStrings, props: Fwk.IDataVisualProps<StatModelDef, Heuristics>) {
    // Have to pull everything off to make the react hooks lint happy
    const labelColumnMissing = props.heuristics?.labelColumnMissing;
    const valueColumnMissing = props.heuristics?.valueColumnMissing;
    const addMessage = props.addMessage;

    React.useLayoutEffect(() => {
        if (labelColumnMissing) {
            return addMessage({
                message: formatLiterals(strings.visuals.sharedMessages.missingColumn, {
                    columnName: labelColumnMissing,
                }),
                level: 'warn',
            });
        }
    }, [addMessage, labelColumnMissing, strings]);

    React.useLayoutEffect(() => {
        if (valueColumnMissing) {
            return addMessage({
                message: formatLiterals(strings.visuals.sharedMessages.missingColumn, {
                    columnName: valueColumnMissing,
                }),
                level: 'warn',
            });
        }
    }, [addMessage, valueColumnMissing, strings]);
}

function labelColumnInput(t: RtdProviderLocale) {
    return Fwk.createTileInput.column<'multiStat__labelColumn', Heuristics>(
        'multiStat__labelColumn',
        t.visualFwk.visualConfig.multiStat__labelColumnLabel,
        {
            selectInferColumn: (options) => {
                if (options.get('multiStat__labelColumn') !== null) {
                    return undefined;
                }
                const h = options.getHeuristics();
                return h?.labelField?.name;
            },
        }
    );
}

function valueColumnInput(t: RtdProviderLocale) {
    return Fwk.createTileInput.column<'multiStat__valueColumn', Heuristics>(
        'multiStat__valueColumn',
        t.visualFwk.visualConfig.multiStat__valueColumnLabel,
        {
            selectInferColumn: (options) => {
                if (options.get('multiStat__valueColumn') !== null) {
                    return undefined;
                }
                const h = options.getHeuristics();
                return h?.valueField?.name ?? undefined;
            },
        }
    );
}

export const statModel = {
    multiStat__textSize: 'auto',
    multiStat__valueColumn: null,
    colorRulesDisabled: false,
    colorRules: [],
    colorStyle: 'light',
} as const;

type StatModelDef = keyof typeof statModel;

function createStatComponent(ctx: KweRtdVisualContext): React.FC<Fwk.IDataVisualProps<StatModelDef, Heuristics>> {
    return function RtdStatVisual(props) {
        if (!props.heuristics) {
            throw new KweException('stat heuristics should only be null if there is no query result');
        }

        const dataFrame = props.queryResult.dataFrame;

        // Intentionally running after every render
        React.useEffect(() => ctx.chartEvents.presentingChart?.(dataFrame.size, props.visualType));

        useVisualMessages(ctx.strings.rtdProvider, props);
        const ConditionalFormattingConfig = useCreateConditionalFormatting(ctx.strings, props, 'stat');

        return (
            <StatCardChart
                visualizationOptions={{
                    Visualization: 'card',
                    XColumn: props.heuristics.labelField?.name ?? null,
                    YColumns: props.heuristics.valueField ? [props.heuristics.valueField.name] : null,
                    Series: null,
                    TextSize: props.visualOptions.multiStat__textSize,
                    ColumnFormatting: { ConditionalFormattingConfig },
                    // Defaulted
                    Width: 0,
                    // Defaulted
                    Height: 0,
                }}
                strings={ctx.strings.visualizations}
                timezone={props.timeZone}
                locale={props.locale}
                dataFrame={dataFrame}
                setResultCounts={props.setResultCounts}
                formatMessage={props.formatMessage}
            />
        );
    };
}

export function statHeuristicsConfig(
    _strings: RtdProviderLocale
): Readonly<Fwk.ResolvedHeuristicsTypeConfig<StatModelDef, Heuristics>> {
    return {
        model: statModel,
        heuristics: createHeuristicsFnc('card') as Fwk.VisualConfigHeuristicsFnc<StatModelDef, Heuristics>,
    };
}

function statConfig(ctx: KweRtdVisualContext): Fwk.VisualTypeConfig<StatModelDef, Heuristics> {
    const tileInput = Fwk.tileInput(ctx.flags);

    return {
        label: ctx.strings.rtdProvider.visuals.stat.visualTitle,
        iconName: 'TextCallout',
        config: {
            ...statHeuristicsConfig(ctx.strings),
            minimumSize: { width: 3, height: 3 },
            inputLayout: {
                visual: {
                    segments: [
                        Fwk.tileInputSegment<StatModelDef, Heuristics>(
                            ctx.strings.rtdProvider.visuals.input.segment$generalTitle,
                            {},
                            tileInput.multiStat__textSize(ctx.strings)
                        ),
                        Fwk.tileInputSegment(
                            ctx.strings.rtdProvider.visuals.input.segment$dataTitle,
                            {},
                            valueColumnInput(ctx.strings)
                        ),
                        colorRulesSegment(ctx, 'stat'),
                    ],
                },
            },
            Component: createStatComponent(ctx),
        },
    };
}

export const multiStatModel = {
    ...statModel,
    multiStat__displayOrientation: 'horizontal',
    multiStat__labelColumn: null,
    multiStat__valueColumn: null,
    multiStat__slot: { width: 4, height: 2 },
} as const;

type MultiStatModelDef = keyof typeof multiStatModel;

function createMultiStatComponent(
    ctx: KweRtdVisualContext
): React.FC<Fwk.IDataVisualProps<MultiStatModelDef, Heuristics>> {
    return (props) => {
        if (!props.heuristics) {
            throw new KweException('stat heuristics should only be null if there is no query result');
        }

        const visualOptions = props.visualOptions;
        const dataFrame = props.queryResult.dataFrame;

        // Intentionally running after every render
        React.useEffect(() => ctx.chartEvents.presentingChart?.(dataFrame.size, props.visualType));

        const ConditionalFormattingConfig = useCreateConditionalFormatting(ctx.strings, props, 'stat');

        return (
            <StatCardChart
                visualizationOptions={{
                    Visualization: 'multistat',
                    XColumn: props.heuristics.labelField?.name ?? null,
                    YColumns: props.heuristics.valueField ? [props.heuristics.valueField.name] : null,
                    Series: null,
                    TextSize: props.visualOptions.multiStat__textSize,
                    ColumnFormatting: { ConditionalFormattingConfig },
                    Width: visualOptions.multiStat__slot.width,
                    Height: visualOptions.multiStat__slot.height,
                }}
                strings={ctx.strings.visualizations}
                timezone={props.timeZone}
                locale={props.locale}
                dataFrame={dataFrame}
                setResultCounts={props.setResultCounts}
                formatMessage={props.formatMessage}
            />
        );
    };
}
export function multiStatHeuristicsConfig(
    _strings: RtdProviderLocale
): Readonly<Fwk.ResolvedHeuristicsTypeConfig<MultiStatModelDef, Heuristics>> {
    return {
        model: multiStatModel,
        heuristics: createHeuristicsFnc('multistat'),
    };
}

function multiStatConfig(ctx: KweRtdVisualContext): Fwk.VisualTypeConfig<MultiStatModelDef, Heuristics> {
    const tileInput = Fwk.tileInput(ctx.flags);

    return {
        label: ctx.strings.rtdProvider.visuals.multiStat.visualTitle,
        iconName: 'TextCallout',
        config: {
            ...multiStatHeuristicsConfig(ctx.strings),
            Component: createMultiStatComponent(ctx),
            inputLayout: {
                visual: {
                    segments: [
                        Fwk.tileInputSegment(
                            ctx.strings.rtdProvider.visuals.input.segment$generalTitle,
                            {},
                            tileInput.multiStat__displayOrientation(ctx.strings),
                            tileInput.multiStat__textSize(ctx.strings)
                        ),
                        Fwk.tileInputSegment(
                            ctx.strings.rtdProvider.visuals.input.segment$layoutTitle,
                            { hideReset: true },
                            Fwk.createTileInput.slot('multiStat__slot')
                        ),
                        Fwk.tileInputSegment(
                            ctx.strings.rtdProvider.visuals.input.segment$dataTitle,
                            {},
                            labelColumnInput(ctx.strings),
                            valueColumnInput(ctx.strings)
                        ),
                        colorRulesSegment(ctx, 'stat'),
                    ],
                },
            },
            minimumSize: (options): Fwk.TileSize => {
                return { height: 3 * options.multiStat__slot.height, width: 3 * options.multiStat__slot.width };
            },
        },
    };
}

export function statConfigs(ctx: KweRtdVisualContext) {
    return {
        card: statConfig(ctx),
        multistat: multiStatConfig(ctx),
    };
}
