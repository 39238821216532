import * as React from 'react';
import { mergeClasses } from '@griffel/core';
import { Chart, SeriesOptionsType } from 'highcharts';

import { LegendVisibility } from '@kusto/charting';
import { assertNever } from '@kusto/utils';

import { InternalChartProps, LegendPosition, VisualizationsStrings } from '../types.ts';
import { InteractiveLegend } from './InteractiveLegend/InteractiveLegend.tsx';
import { ChartSeries } from './InteractiveLegend/types.ts';

import * as styles from './styles.module.scss';

interface HighChartsLayoutProps {
    chart: Chart | Chart[] | undefined;
    seriesList: ChartSeries[] | undefined;
    seriesOptions: SeriesOptionsType[] | undefined;
    legendPosition: InternalChartProps['legendPosition'];
    legendVisibility: LegendVisibility;
    strings: VisualizationsStrings;
    id?: string;
    title?: string;
    className?: string;
    style?: React.CSSProperties;
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

export const HighChartsLayout: React.FC<HighChartsLayoutProps> = ({
    chart,
    seriesList,
    seriesOptions,
    legendPosition,
    legendVisibility,
    strings,
    id,
    title,
    className,
    style,
    onMouseLeave,
    children,
}) => {
    const isLeftSideScroll = legendPosition === 'left';
    const layoutStyle = getLayoutStyle(legendPosition);
    const isHorizontalLegend = !legendPosition || legendPosition === 'bottom';

    return (
        <div id={id} className={mergeClasses(className ?? styles.chartWithLegend, layoutStyle)}>
            {title && <div className={styles.highcharts__title}>{title}</div>}
            <div className={styles.chartContainer} style={style} onMouseLeave={onMouseLeave}>
                {children}
            </div>
            {legendVisibility !== 'hidden' && chart && seriesList && (
                <div className={mergeClasses(styles.interactiveLegend, isLeftSideScroll ? styles.leftSideScroll : '')}>
                    <InteractiveLegend
                        chart={chart}
                        seriesList={seriesList}
                        seriesOptions={seriesOptions}
                        strings={strings}
                        horizontal={isHorizontalLegend}
                    />
                </div>
            )}
        </div>
    );
};

export function getLayoutStyle(legendPosition: LegendPosition | undefined) {
    switch (legendPosition) {
        case 'bottom':
        case undefined:
            return styles.layout_legendBottom;
        case 'left':
            return styles.layout_legendLeft;
        case 'right':
            return styles.layout_legendRight;
        default:
            assertNever(legendPosition);
    }
}
