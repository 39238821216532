import * as React from 'react';
import { getIntrinsicElementProps, useEventCallback, useMergedRefs, slot } from '@fluentui/react-utilities';
import { useArrowNavigationGroup, useFocusFinders } from '@fluentui/react-tabster';
import { useFluent_unstable as useFluent } from '@fluentui/react-shared-contexts';
import { interactionTagSecondaryClassNames } from '../InteractionTagSecondary/useInteractionTagSecondaryStyles.styles';
/**
 * Create the state required to render TagGroup.
 *
 * The returned state can be modified with hooks such as useTagGroupStyles_unstable,
 * before being passed to renderTagGroup_unstable.
 *
 * @param props - props from this instance of TagGroup
 * @param ref - reference to root HTMLDivElement of TagGroup
 */ export const useTagGroup_unstable = (props, ref)=>{
    const { onDismiss, size = 'medium', appearance = 'filled', dismissible = false, role = 'toolbar' } = props;
    const innerRef = React.useRef();
    const { targetDocument } = useFluent();
    const { findNextFocusable, findPrevFocusable } = useFocusFinders();
    const handleTagDismiss = useEventCallback((e, data)=>{
        var _innerRef_current;
        onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss(e, data);
        // set focus after tag dismiss
        const activeElement = targetDocument === null || targetDocument === void 0 ? void 0 : targetDocument.activeElement;
        if ((_innerRef_current = innerRef.current) === null || _innerRef_current === void 0 ? void 0 : _innerRef_current.contains(activeElement)) {
            // focus on next tag only if the active element is within the current tag group
            const next = findNextFocusable(activeElement, {
                container: innerRef.current
            });
            if (next) {
                next.focus();
                return;
            }
            // if there is no next focusable, focus on the previous focusable
            if (activeElement === null || activeElement === void 0 ? void 0 : activeElement.className.includes(interactionTagSecondaryClassNames.root)) {
                const prev = findPrevFocusable(activeElement.parentElement, {
                    container: innerRef.current
                });
                prev === null || prev === void 0 ? void 0 : prev.focus();
            } else {
                const prev = findPrevFocusable(activeElement, {
                    container: innerRef.current
                });
                prev === null || prev === void 0 ? void 0 : prev.focus();
            }
        }
    });
    const arrowNavigationProps = useArrowNavigationGroup({
        circular: true,
        axis: 'both',
        memorizeCurrent: true
    });
    return {
        handleTagDismiss,
        role,
        size,
        appearance,
        dismissible,
        components: {
            root: 'div'
        },
        root: slot.always(getIntrinsicElementProps('div', {
            // FIXME:
            // `ref` is wrongly assigned to be `HTMLElement` instead of `HTMLDivElement`
            // but since it would be a breaking change to fix it, we are casting ref to it's proper type
            ref: useMergedRefs(ref, innerRef),
            role,
            ...arrowNavigationProps,
            ...props
        }), {
            elementType: 'div'
        })
    };
};
