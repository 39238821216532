import type { Account } from '../types';
import { MaybeRedactedResult, PiiRedactor } from './PiiRedactor';

const ACCOUNT_USERNAME_MASK = 'ACCOUNT_USERNAME';
const ACCOUNT_NAME_MASK = 'ACCOUNT_NAME';

export function createRedactPiiFn(
    /**
     * This will get used by the Redactor to specifically target the
     * current signed in accounts information.
     * E.g. username, email, etc.
     */
    getAllAccounts?: () => Account[]
) {
    const redactor = new PiiRedactor();

    function redactFn<Data = unknown>(data: Data): MaybeRedactedResult<Data> {
        let knownValuesToRedact: undefined | Record<string, string>;
        const accounts = getAllAccounts?.();

        if (accounts) {
            knownValuesToRedact = {};

            for (const account of accounts) {
                knownValuesToRedact[account.username] = ACCOUNT_USERNAME_MASK;

                if (account.name) {
                    knownValuesToRedact[account.name] = ACCOUNT_NAME_MASK;
                }
            }
        }

        return redactor.redact(data, {
            knownValuesToRedact,
        });
    }

    return redactFn;
}
