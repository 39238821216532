import React from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

import { TooltipWrapper, TooltipWrapperProps } from './TooltipWrapper';

export interface IconWithTooltipProps extends React.ComponentProps<typeof Icon> {
    tooltipProps?: TooltipWrapperProps;
}

/**
 * @deprecated Fluent 8
 */
export const IconWithTooltip = ({ title, tooltipProps, ...iconProps }: IconWithTooltipProps) => {
    return (
        <TooltipWrapper content={title} {...tooltipProps}>
            <Icon {...iconProps}></Icon>
        </TooltipWrapper>
    );
};
