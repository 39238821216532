/* eslint-disable @typescript-eslint/no-redeclare */
import { types } from 'mobx-state-tree';
import * as mst from 'mobx-state-tree';

import { ColumnWithDescription } from '@kusto/data-exploration';

import { Table } from '../common/types';
import { Cluster, Database } from './cluster';

export type DataProfileStoreModel = mst.IModelType<
    // eslint-disable-next-line @typescript-eslint/ban-types
    {},
    {
        isOpen: boolean;
        cluster: null | Cluster;
        database: null | Database;
        selectedTableId: string;
        viewMode: 'inline' | 'overlay';

        openDataProfile(): void;
        closeDataProfile(): void;
        setSelectedDatabase(cluster: Cluster, database: Database | null): void;
        setSelectedTable(tableId: string): void;
        setViewMode(viewMode: 'inline' | 'overlay'): void;
        readonly databaseTables: Table[];
        readonly selectedTable: undefined | Table;
        readonly selectedTableColumns: ColumnWithDescription[];
    }
>;

export const DataProfileStore: DataProfileStoreModel = types
    .model('DataProfileStore', {})
    .volatile(() => ({
        isOpen: false as boolean,
        cluster: null as Cluster | null,
        database: null as Database | null,
        selectedTableId: '' as string,
        viewMode: 'overlay' as 'inline' | 'overlay',
    }))
    .actions((self) => ({
        openDataProfile() {
            self.isOpen = true;
        },
        closeDataProfile() {
            self.isOpen = false;
        },
        setSelectedDatabase(cluster: Cluster, database: Database | null) {
            self.cluster = cluster;
            self.database = database;
        },
        setSelectedTable(tableId: string) {
            self.selectedTableId = tableId;
        },
        setViewMode(viewMode: 'inline' | 'overlay') {
            self.viewMode = viewMode;
        },
    }))
    .views((self) => ({
        get databaseTables() {
            return Array.from(self.database?.regularTables.values() ?? []);
        },
    }))
    .views((self) => ({
        get selectedTable() {
            return self.databaseTables.find((table) => table.id === self.selectedTableId);
        },
    }))
    .views((self) => ({
        get selectedTableColumns(): ColumnWithDescription[] {
            return self.selectedTable
                ? Object.values(self.selectedTable.columns).map((col) => ({
                      ColumnName: col.name,
                      ColumnType: col.type,
                      description: col.docstring,
                  }))
                : [];
        },
    }));

// eslint-disable-next-line no-redeclare
export type DataProfileStore = mst.Instance<DataProfileStoreModel>;
