import { GridApi, IRowNode } from '@ag-grid-community/core';
import debounce from 'lodash/debounce';

import { EntityType } from '../../common';
import { QueryCore } from '../../core/core';
import {
    Cluster,
    Database,
    getClusterAndDatabaseFromEntity,
    getClusterFromDatabase,
    QueryStoreEnv,
    Tabs,
} from '../../stores';
import { ConnectionPane } from '../../stores/connectionPane';
import { quoteIfNeeded } from '../../utils';
import { RowDataType } from './RowDataType';

export class ConnectionPaneActions {
    private connectionPane: ConnectionPane;

    constructor(connectionPane: ConnectionPane) {
        this.connectionPane = connectionPane;
    }

    public selectNodeAndExpand(gridApi?: GridApi, entityInContext?: Database | Cluster): void {
        if (!gridApi) {
            return;
        }
        this.debounceSelectNodeInContext(false, gridApi, entityInContext);

        // Every time data is loaded (expand for the first time) sync expand state
        Array.from(this.connectionPane?.expandedEntities.keys()).forEach((id) => {
            const node = gridApi?.getRowNode(id);
            if (node && !node.expanded) {
                node.setExpanded(true);
            }
        });
    }

    // Expand selected row (database | cluster)
    public expandRowGroup(
        env: QueryStoreEnv,
        node: IRowNode,
        gridApi?: GridApi,
        entityInContext?: Database | Cluster
    ): void {
        // If we're on a database entity that's currently not loaded and not loading, load it.
        const item = node.data as RowDataType;
        if (!item) {
            return;
        }

        if (item.entityType === EntityType.Database || item.entityType === EntityType.Cluster) {
            this.connectionPane?.updateExpanded(item.baseData, node.expanded);
        }

        this.debounceSelectNodeInContext(false, gridApi, entityInContext);

        if (item.entityType === EntityType.Database) {
            // This is a database. let's get the database entity in our store
            const database = item.baseData as Database;
            if (database?.fetchState === 'notStarted') {
                this.refreshEntity(database);
                return;
            }
        }

        this.loadChildren(env, node, gridApi);
    }

    /**
     * select the right ag-table node according to connection in context.
     */
    public selectNodeInContext(changeVisibility = false, gridApi?: GridApi, entityInContext?: Database | Cluster) {
        if (!gridApi) {
            return;
        }
        const entity = entityInContext;
        const oldSelectedNode = gridApi.getSelectedNodes()?.[0];
        if (entity && (entity.entityType === EntityType.Database || entity.entityType === EntityType.Cluster)) {
            const newSelectedNode = gridApi.getRowNode(entity.id);

            if (!newSelectedNode) {
                return;
            }

            if (oldSelectedNode && oldSelectedNode.id === newSelectedNode.id) {
                if (changeVisibility) {
                    gridApi.ensureNodeVisible(newSelectedNode, null);
                }
                return;
            }

            // Auto open connection pane if DB selected and cluster is collapsed
            let node = newSelectedNode;
            while (node.parent && node.level > 0) {
                node = node.parent;
                node.setExpanded(true);
            }
            newSelectedNode.setSelected(true, true);

            // Ag grid typing is buggy here - null is indeed the value to provide to move the least amount of distance
            // to make node visible
            if (changeVisibility) {
                gridApi.ensureNodeVisible(newSelectedNode, null);
            }
            // api.redrawRows ({rowNodes: [newSelectedNode, oldSelectedNode]});
            if (newSelectedNode.parent && !newSelectedNode.parent.expanded) {
                newSelectedNode.parent.setExpanded(true);
            }
        } else if (oldSelectedNode) {
            oldSelectedNode.setSelected(false, true);
        }
    }

    /**
     * Load children from the model to AGGrid.
     */
    public loadChildren(env: QueryStoreEnv, node: IRowNode, gridApi?: GridApi) {
        if (node.data && node.data.childrenLoader) {
            const rowToLoad = node.data
                .childrenLoader(env, node.data)
                .filter((row: RowDataType) => row.id !== node.data.id);
            node.data.childrenLoader = undefined;
            if (rowToLoad && node.childrenAfterGroup) {
                setTimeout(() => {
                    gridApi?.applyTransaction({ add: rowToLoad });
                }, 0);
            }
        }
    }

    // selectNodeInContext might be call multiple times for single change
    public debounceSelectNodeInContext = debounce(this.selectNodeInContext, 50, {
        leading: true,
        maxWait: 50,
        trailing: false,
    });

    /**
     * You can click refresh on cluster or databases.
     * @param id the Cluster or database ID
     */
    public refreshEntity(clusterOrDatabase?: Cluster | Database) {
        this.connectionPane?.refreshEntity(clusterOrDatabase);
    }

    public requestPasteForSelectedNode(core: QueryCore, node: IRowNode): void {
        const handle = core.kustoEditor.ref;
        if (!handle) {
            return;
        }
        const rowDataType = node.data as RowDataType;
        if (!rowDataType || !rowDataType.baseData) {
            return;
        }
        const { cluster, database } = getClusterAndDatabaseFromEntity(rowDataType.baseData);
        if (!cluster || !database) {
            return;
        }

        const { label: entityName, entityType } = node.data;

        // If this is a table, we want to past a newline and a pipe character.
        if (entityType === EntityType.Table) {
            handle.pasteText(quoteIfNeeded(entityName) + '\n| ', 'cursorPosition');
        } else if (entityType === EntityType.ExternalTable) {
            handle.pasteText(`external_table("${entityName}")\n| `, 'cursorPosition');
        } else {
            handle.pasteText(entityName, 'cursorPosition');
        }
    }

    public changeSelection(
        node: IRowNode,
        tabs?: Tabs
    ): [clusterName: string | undefined, databaseName: string | undefined] {
        let oldSelected = null;
        let entity = null;
        const { clusterName, databaseName: dbName } = this.getClusterNameAndDatabaseName(node.data as RowDataType);
        let databaseName = dbName;
        if (clusterName) {
            // connection pane
            oldSelected = this.connectionPane?.entityInContext;

            const entityInContext: Cluster | Database | undefined = this.connectionPane?.connections.get(clusterName);

            if (!databaseName && entityInContext?.databases?.size) {
                // If the cluster wasn't already in context - auto-select first db, otherwise - keep the database in the current entity
                const [firstDatabase] = entityInContext.databases.values();
                databaseName =
                    oldSelected?.id.split('/')[0] === clusterName && oldSelected.entityType === EntityType.Database
                        ? oldSelected.name
                        : firstDatabase?.name;
            }

            entity = this.connectionPane?.setEntityInContextByName(clusterName, databaseName);
            // current tab
            if (entity) {
                tabs?.tabInContext.setEntityInContext(entity);
            }
        }
        if (oldSelected !== null && entity !== null && oldSelected !== entity) {
            return [clusterName, databaseName];
        }
        return [undefined, undefined];
    }

    private getClusterNameAndDatabaseName = (
        rowDataType: RowDataType
    ): { clusterName?: string; databaseName?: string } => {
        if (rowDataType.baseData.entityType === EntityType.Cluster) {
            const cluster = rowDataType.baseData as Cluster;
            return { clusterName: cluster.name, databaseName: undefined };
        }

        if (rowDataType.baseData.entityType === EntityType.Database) {
            const database = rowDataType.baseData as Database;
            const cluster = getClusterFromDatabase(database);
            return {
                clusterName: cluster.name,
                databaseName: database.name,
            };
        }

        return { clusterName: undefined, databaseName: undefined };
    };
}
