export var Token;
(function (Token) {
    Token["PlainText"] = "plainText";
    Token["Comment"] = "comment";
    Token["Punctuation"] = "punctuation";
    Token["Directive"] = "directive";
    Token["Literal"] = "literal";
    Token["StringLiteral"] = "stringLiteral";
    Token["Type"] = "type";
    Token["Column"] = "column";
    Token["Table"] = "table";
    Token["Database"] = "database";
    Token["Function"] = "function";
    Token["Parameter"] = "parameter";
    Token["Variable"] = "variable";
    Token["Identifier"] = "identifier";
    Token["ClientParameter"] = "clientParameter";
    Token["QueryParameter"] = "queryParameter";
    Token["ScalarParameter"] = "scalarParameter";
    Token["MathOperator"] = "mathOperator";
    Token["QueryOperator"] = "queryOperator";
    Token["Command"] = "command";
    Token["Keyword"] = "keyword";
    Token["MaterializedView"] = "materializedView";
    Token["SchemaMember"] = "schemaMember";
    Token["SignatureParameter"] = "signatureParameter";
    Token["Option"] = "option";
})(Token || (Token = {}));
export var tokenTypes = [
    Token.PlainText,
    Token.Comment,
    Token.Punctuation,
    Token.Directive,
    Token.Literal,
    Token.StringLiteral,
    Token.Type,
    Token.Column,
    Token.Table,
    Token.Database,
    Token.Function,
    Token.Parameter,
    Token.Variable,
    Token.Identifier,
    Token.ClientParameter,
    Token.QueryParameter,
    Token.ScalarParameter,
    Token.MathOperator,
    Token.QueryOperator,
    Token.Command,
    Token.Keyword,
    Token.MaterializedView,
    Token.SchemaMember,
    Token.SignatureParameter,
    Token.Option,
];
