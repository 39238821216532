import * as React from 'react';
import { Button } from '@fluentui/react-components';
import { Delete16Regular, Edit16Regular } from '@fluentui/react-icons';
import classNames from 'classnames';

import * as gridStyles from './grid.module.scss';
import * as styles from './styles.module.scss';

export interface ConfigurationItemRowProps<T> extends React.HTMLAttributes<HTMLDivElement> {
    item: T;
    onDelete: (item: T) => void;
    disabled?: boolean;
    onEdit?: (item: T) => void;
    hideEditButton?: boolean;
    disableDelete?: boolean;
    editButtonTitle: string;
    deleteButtonTitle: string;
}

export const ConfigurationItemRow = <T,>({
    item,
    onDelete,
    disabled,
    onEdit,
    hideEditButton,
    disableDelete,
    children,
    className,
    editButtonTitle,
    deleteButtonTitle,
    ...divProps
}: React.PropsWithChildren<ConfigurationItemRowProps<T>>) => {
    const onEditClicked = React.useCallback(() => onEdit?.(item), [item, onEdit]);
    const innerOnDelete = React.useCallback(() => onDelete(item), [item, onDelete]);

    return (
        <div {...divProps} className={classNames(gridStyles.configItem, styles.configItem)}>
            {children}
            {!hideEditButton && (
                <Button
                    appearance="transparent"
                    className={gridStyles.edit}
                    icon={<Edit16Regular />}
                    onClick={onEditClicked}
                    title={editButtonTitle}
                    disabled={disabled}
                />
            )}
            <Button
                appearance="transparent"
                className={gridStyles.remove}
                icon={<Delete16Regular />}
                onClick={innerOnDelete}
                title={deleteButtonTitle}
                disabled={disabled || disableDelete}
            />
        </div>
    );
};

export interface ConfigurationItemRowContentProps {
    title: string;
    disabled: boolean;
}

export const ConfigurationItemRowContent: React.FC<ConfigurationItemRowContentProps> = ({
    title,
    disabled,
    children,
}) => (
    <div
        title={title}
        className={classNames(gridStyles.content, styles.itemRowContent, { [styles.disabled]: disabled })}
    >
        {children}
    </div>
);
