import * as React from 'react';
import { getNativeElementProps } from '../utils/getNativeElementProps';
/**
 * Given an element tagname and user props, filters the props to only allowed props for the given
 * element type.
 *
 * Equivalent to {@link getNativeElementProps}, but more type-safe.
 */ export const getIntrinsicElementProps = (/** The slot's default element type (e.g. 'div') */ tagName, /** The component's props object */ props, /** List of native props to exclude from the returned value */ excludedPropNames)=>{
    var _props_as;
    // eslint-disable-next-line deprecation/deprecation
    return getNativeElementProps((_props_as = props.as) !== null && _props_as !== void 0 ? _props_as : tagName, props, excludedPropNames);
};
