import React from 'react';
import { Caption1Strong } from '@fluentui/react-components';

import { ColumnTypeIcon } from '@kusto/ui-components';

import { useDataExplorationContext } from '../../../context/DataExplorationContext';
import { ColumnWithInsights } from '../../../DataExplorationApi';
import { SchemaColumnList } from '../../../SchemaInsights/SchemaColumnList/SchemaColumnList';
import { useKql } from '../../hooks/useKql';
import { SelectedColumnInfo } from './SelectedColumnInfo/SelectedColumnInfo';

import styles from './SchemaColumns.module.scss';

interface SchemaColumnsContentProps {
    columns: ColumnWithInsights[];
    isLoading: boolean;
}

export const SchemaColumnsContent: React.FunctionComponent<SchemaColumnsContentProps> = ({ columns, isLoading }) => {
    const [selectedColumnName, setSelectedColumnName] = React.useState<string>(columns[0].ColumnName);
    const computedKql = useKql();
    const { featureFlags } = useDataExplorationContext();

    const selectedColumn = React.useMemo(
        () => columns.find((col) => col.ColumnName === selectedColumnName) ?? columns[0],
        [selectedColumnName, columns]
    );

    return (
        <div className={styles.schemaColumns}>
            <div className={styles.columnList}>
                <SchemaColumnList
                    columns={columns}
                    selected={selectedColumn?.ColumnName}
                    onSelectedChange={(col) => setSelectedColumnName(col)}
                    timezone={'UTC'} //TODO
                    appearance="subtle"
                    isLoadingInsights={isLoading}
                />
            </div>
            {featureFlags?.enableSelectedColumnInfo ? (
                <div className={styles.columnInfo}>
                    <SelectedColumnInfo
                        selectedColumn={selectedColumn}
                        title={
                            <div className={styles.selectedColumn}>
                                <ColumnTypeIcon size="small" type={selectedColumn.ColumnType} />
                                <Caption1Strong>{selectedColumn.ColumnName}</Caption1Strong>
                            </div>
                        }
                        queryText={computedKql}
                        timeRange={{ type: 'filterDisabled' }}
                        timezone={'UTC'}
                    />
                </div>
            ) : null}
        </div>
    );
};
