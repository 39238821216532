import { createContext } from '@fluentui/react-context-selector';
/**
 * @deprecated - use ListboxContext instead
 * @see ListboxContext
 */ // eslint-disable-next-line @fluentui/no-context-default-value
export const ComboboxContext = createContext({
    activeOption: undefined,
    appearance: 'outline',
    focusVisible: false,
    open: false,
    registerOption () {
        return ()=>undefined;
    },
    selectedOptions: [],
    selectOption () {
    // noop
    },
    setActiveOption () {
    // noop
    },
    setOpen () {
    // noop
    },
    size: 'medium'
});
/**
 * @deprecated - render ListboxProvider instead
 * @see ListboxProvider
 * @see useListboxContext_unstable
 */ // eslint-disable-next-line deprecation/deprecation
export const ComboboxProvider = ComboboxContext.Provider;
