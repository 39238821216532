import React from 'react';
import { observer } from 'mobx-react-lite';

import type { DropdownWithSearchOption } from '@kusto/ui-components';

import type { InteractionErrorMessage } from '../../../interactions/target';

import * as styles from './styles.module.scss';

export interface InteractiveDropdownOption<Data = unknown> extends DropdownWithSearchOption<Data> {
    typeText?: string;
    warning?: InteractionErrorMessage;
}

export interface DropdownOptionTextProps<DropdownData = unknown> {
    option: InteractiveDropdownOption<DropdownData>;
}

export const DropdownOptionText: React.FC<DropdownOptionTextProps> = observer(function DropdownOptionText({ option }) {
    return (
        <>
            <span>{option.text}</span>
            {option.typeText && <span className={styles.type}>({option.typeText})</span>}
            {option.warning && <span className={styles.warning}>{option.warning.text()}</span>}
        </>
    );
});
