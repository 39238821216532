export enum kustoEntityTypes {
    // No icon type
    noIconType = 'NO_ICON_TYPE',
    label = 'Label',
    // Entity
    group = 'Group',
    favorites = 'Favorites',
    cluster = 'Cluster',
    clusterDM = 'ClusterDm',
    clusterCM = 'ClusterCm',
    database = 'Database',
    databaseSuspended = 'DatabaseSuspended',
    databaseFollower = 'DatabaseFollower',
    entityGroupFolder = 'EntityGroupFolder',
    entityGroup = 'EntityGroup',
    entityGroupMember = 'EntityGroupMember',
    table = 'Table',
    folder = 'Folder',
    tableFolder = 'TableFolder',
    externalTable = 'ExternalTable',
    shortcuts = 'Shortcuts',
    externalTableFolder = 'ExternalTableFolder',
    shortcutsFolder = 'ShortcutsFolder',
    function = 'Function',
    functionFolder = 'FunctionFolder',
    virtualCluster = 'VirtualCluster',
    materializedView = 'MaterializedViewTable',
    materializedViewTableFolder = 'MaterializedViewTableFolder',
    column = 'Column',

    // Column types
    dynamic = 'dynamic',
    decimal = 'decimal',
    datetime = 'datetime',
    timespan = 'timespan',
    guid = 'guid',
    long = 'long',
    string = 'string',
    real = 'real',
    boolean = 'boolean',
    bool = 'bool',
    int = 'int',
}

export function caseInsensitiveIncludes(str: string[], searchString: string): boolean {
    const lowerCaseSearchString = searchString.toLowerCase();
    return str.some((s) => s?.toLowerCase().includes(lowerCaseSearchString));
}

export type SearchTreeDisplayMode = 'AllItems' | 'collapsed';
