import type { DataExplorationLocale } from '@kusto/data-exploration';
import type { RtdProviderLocale } from '@kusto/rtd-provider';
import type { KweUtilsLocale } from '@kusto/utils';
import type { KweVisualFwkLocale } from '@kusto/visual-fwk';
import type { VisualizationsLocale } from '@kusto/visualizations';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type QueryStrings = typeof import('../../strings.json');

export interface QueryLocale
    extends RtdProviderLocale,
        KweUtilsLocale,
        VisualizationsLocale,
        DataExplorationLocale,
        KweVisualFwkLocale {
    readonly query: QueryStrings;
}
