import React from 'react';
import { ToolbarButton, Tooltip } from '@fluentui/react-components';
import { ArrowSync20Regular } from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';

import { useRefreshDataProfile } from '@kusto/data-exploration';

import { useQueryCore } from '../../../core/core';

export const DataProfileRefreshButton: React.FC = observer(function DataProfileCloseButton() {
    const core = useQueryCore();
    const refresh = useRefreshDataProfile();

    return (
        <Tooltip content={core.strings.query.dataProfile.refreshToolTip} relationship="label">
            <ToolbarButton appearance="subtle" icon={<ArrowSync20Regular />} onClick={() => refresh()} />
        </Tooltip>
    );
});
