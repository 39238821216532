// The following import fixes an infinite loop in later versions of mobx-react.
// further info here: https://github.com/mobxjs/mobx-react-lite/#observer-batching
import * as mobx from 'mobx';
import { getEnv, getRoot, getSnapshot, IAnyModelType, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import * as mst from 'mobx-state-tree';

import { EntityType } from '../common/types';
import { ITreeEntityMapper, RowDataType } from '../components/connectionExplorer';
import { databaseChildrenToRawDataTypes } from '../components/connectionExplorer/RowDataTypes/DatabaseChildrenRawDataTypesFlow';
import { clustersToRowData } from '../components/connectionExplorer/RowDataTypes/LazyLoadingFlow';
import { tabClassName } from '../components/tabs/TabItem';
import { getTelemetryClient } from '../utils/telemetryClient';
import { Cluster, Database } from './cluster';
import { ConnectionPane } from './connectionPane';
import { DataProfileStore } from './dataProfilePanel';
import { GridStateCache } from './gridStateCache';
import { Layout } from './layout';
import { ResultCache } from './resultCache';
import { ISettings, Settings } from './settings/settings';
import { getQueryStoreEnv } from './storeEnv';
import { CursorPosition, Tab, TabModel } from './tab';

const id = crypto.randomUUID();

const { trackEvent } = getTelemetryClient({
    component: 'rootStore',
    flow: '',
});

interface CloseAction {
    closeType: 'single' | 'multiple';
    tabs: Tab[];
}

interface AddTypePropsType {
    entityInContext?: Cluster | Database;
    text?: string;
    cursorPosition?: CursorPosition;
    /** Free text. Invoker of method, used for logging. */
    origin: string;
    id?: string;
    title?: string;
    doNotSetNewTabInContext?: boolean;
}
const MAX_UNDO_OPERATIONS = 5;

export const Tabs: TabsModel = types
    .model('Tabs', {
        tabs: types.optional(types.array(Tab), [{ text: '', id }]),
        tabInContext: types.optional(types.reference(Tab), id),
    })
    .volatile(() => ({
        recentlyCloseTabs: mobx.observable([] as CloseAction[]),
    }))
    .views((self) => ({
        get tabInContextIndex() {
            // TODO: o(n) might be too much when there are lots of tabs.
            return self.tabs
                .map((tab, index) => ({ tab, index }))
                .filter((tabAndIndex) => tabAndIndex.tab.id === self.tabInContext.id)[0].index;
        },
    }))
    .actions((self) => ({
        setTabInContext(tab: Tab) {
            trackEvent('Tabs.SetTabInContext', {
                flow: 'Tabs.setTabInContext',
                tabId: tab.id,
                entityInContextId: tab.entityInContext ? tab.entityInContext.id : 'undefined',
                openTabCount: self.tabs.length.toString(),
            });

            self.tabInContext = tab;
            const root = getRoot(self) as IRootStore;
            root.connectionPane.setEntityInContextByObject(tab.entityInContext || undefined);
        },
    }))
    .actions((self) => ({
        undoTabRemoval() {
            // TODO(RoamingProfile) - handle undo delete tabs
            const latestCloseTabSnapshot = self.recentlyCloseTabs.pop();
            if (latestCloseTabSnapshot) {
                latestCloseTabSnapshot.tabs.forEach((tab) => {
                    // Build a new tab from the tab's snapshot.
                    const newTab = Tab.create({ id: crypto.randomUUID() }, getEnv(self));

                    // set text
                    newTab.setText(tab.text ?? '');

                    // set title
                    newTab.setTitle(tab.title ?? '');

                    // set entityInContext
                    if (typeof tab.entityInContext === 'string') {
                        const entityInContextId = tab.entityInContext as string;
                        const root = getRoot(self) as IRootStore;
                        const entityInContext = root.connectionPane.getEntityFromId(entityInContextId) as
                            | Cluster
                            | Database
                            | undefined;
                        if (entityInContext) {
                            newTab.setEntityInContext(entityInContext);
                            if (latestCloseTabSnapshot.closeType === 'single') {
                                root.connectionPane.setEntityInContextByObject(entityInContext);
                            }
                        }
                    }

                    // add restored tab to the tabs list.
                    self.tabs.push(newTab);
                    if (latestCloseTabSnapshot.closeType === 'single') {
                        self.setTabInContext(newTab);
                    }
                });
            }
        },
    }))
    .actions((self) => ({
        addTabFromSnapshot(tab: SnapshotIn<Tab>) {
            self.tabs.push(tab);
        },
        addTab(addTabProps: AddTypePropsType) {
            const { text, cursorPosition, origin, id, title, doNotSetNewTabInContext } = addTabProps;
            let { entityInContext } = addTabProps;
            trackEvent('Tabs.AddTab', {
                flow: 'Tabs.addTab',
                entityInContextId: entityInContext ? entityInContext.id : 'undefined',
                textLength: text ? text.length.toString() : 'undefined',
                openTabCount: self.tabs.length.toString(),
                origin,
            });

            const newId = id ?? crypto.randomUUID();
            if (!entityInContext) {
                entityInContext = (getRoot(self) as IRootStore).connectionPane.entityInContext;
            }

            const newTab: Tab = Tab.create(
                {
                    id: newId,
                    title,
                    // can't set the reference before the newTab is added to rootStore -
                    // the reference is invalid until added
                    // entityInContext: castToReferenceSnapshot(entityInContext)
                },
                getEnv(self)
            );

            if (text) {
                newTab.setText(text);
            }

            if (cursorPosition) {
                newTab.setCommandInContext({ command: '', commandType: 'Unknown', cursorPosition });
            }

            self.tabs.push(newTab);
            newTab.setEntityInContext(entityInContext || null);
            if (doNotSetNewTabInContext !== true) {
                self.setTabInContext(newTab);
            }
            return newTab;
        },
        setTabs(tabIds: string[]) {
            // Used to set the tabs order
            const order: Record<string, number> = {};

            tabIds.forEach((tabId: string, index: number) => (order[tabId] = index));

            self.tabs.replace(self.tabs.slice().sort((a, b) => order[a.id] - order[b.id]));
        },
        overrideTabs(tabIds: string[]) {
            // Sets tabs order but removes tabs that are not in the new order
            const tabsToKeep: { [key: string]: boolean } = {};
            tabIds.forEach((tabId: string) => (tabsToKeep[tabId] = true));
            const tabsToRemove = self.tabs.filter((tab) => !tabsToKeep[tab.id]);
            tabsToRemove.forEach((t) => this.removeTab(t));

            this.setTabs(tabIds);

            if (self.tabInContext?.id && self.tabs.find((tab) => tab.id === self.tabInContext.id) === undefined) {
                self.setTabInContext(self.tabs[0]);
            }
        },
        removeTab(tab: Tab) {
            trackEvent('Tabs.RemoveTab', {
                flow: 'Tabs.removeTab',
                tabId: tab ? tab.id : 'EmptyProbablyFF',
                tabEntityInContext: tab && tab.entityInContext ? tab.entityInContext.id : 'undefined',
                openTabCount: self.tabs.length.toString(),
            });

            // Don't close if this is the only tab.
            if (!tab || self.tabs.length === 1) {
                return;
            }

            // if we're removing current tab - change current to next tab before removing.
            if (tab.id === self.tabInContext.id) {
                const tabIndex: number = self.tabInContextIndex;
                const lastIndex = self.tabs.length - 1;

                const newTabInContextIndex = self.tabInContextIndex === lastIndex ? tabIndex - 1 : tabIndex + 1;
                self.setTabInContext(self.tabs[newTabInContextIndex]);
            }

            if (self.recentlyCloseTabs.length >= MAX_UNDO_OPERATIONS) {
                self.recentlyCloseTabs.shift();
            }

            self.recentlyCloseTabs.push({ closeType: 'single', tabs: [getSnapshot<Tab>(tab)] });

            self.tabs.remove(tab);
        },
        removeAllTabs() {
            const closingTabs = [...self.tabs];
            if (self.recentlyCloseTabs.length >= MAX_UNDO_OPERATIONS) {
                self.recentlyCloseTabs.shift();
            }
            self.recentlyCloseTabs.push({ closeType: 'multiple', tabs: closingTabs });

            const newTab: Tab = Tab.create({ id: crypto.randomUUID() }, getEnv(self));
            self.tabs.push(newTab);
            self.setTabInContext(newTab);
            self.tabs.splice(0, self.tabs.length - 1);
        },
        removeAllTabsExceptCurrent() {
            const allTabsExceptCurrent = self.tabs.filter((tab) => tab.id === self.tabInContext.id);
            if (allTabsExceptCurrent.length > 0) {
                const closingTabs = self.tabs
                    .filter((tab) => tab.id !== self.tabInContext.id)
                    .map((tab) => getSnapshot<Tab>(tab));
                if (self.recentlyCloseTabs.length >= MAX_UNDO_OPERATIONS) {
                    self.recentlyCloseTabs.shift();
                }
                self.recentlyCloseTabs.push({ closeType: 'multiple', tabs: closingTabs });
                self.tabs.replace(allTabsExceptCurrent);
            }
        },
    }))
    .actions((self) => ({
        switchTab(direction: 'left' | 'right') {
            const tabCount = self.tabs.length;
            const newIndex = (self.tabInContextIndex + tabCount + (direction === 'left' ? -1 : 1)) % tabCount;
            const tab = self.tabs[newIndex];
            self.setTabInContext(tab);
            (
                document.querySelector(`.${tabClassName}[data-rbd-draggable-id="${tab.id}"]`) as HTMLElement
            ).scrollIntoView();
        },
        removeAllTabsWithRecentlyCloseTabs() {
            self.removeAllTabs();
            self.recentlyCloseTabs.clear();
        },
    }));

export type TabsModel = mst.IModelType<
    {
        tabs: mst.IOptionalIType<mst.IArrayType<TabModel>, [undefined]>;
        tabInContext: mst.IOptionalIType<mst.IReferenceType<TabModel>, [undefined]>;
    },
    {
        recentlyCloseTabs: mobx.IObservableArray<CloseAction>;
        readonly tabInContextIndex: number;
        setTabInContext(tab: Tab): void;
        undoTabRemoval(): void;
        addTabFromSnapshot(tab: SnapshotIn<Tab>): void;
        addTab(addTabProps: AddTypePropsType): Tab;
        setTabs(tabIds: string[]): void;
        overrideTabs(tabIds: string[]): void;
        removeTab(tab: Tab): void;
        removeAllTabs(): void;
        removeAllTabsExceptCurrent(): void;
        switchTab(direction: 'left' | 'right'): void;
        removeAllTabsWithRecentlyCloseTabs(): void;
    },
    mst._NotCustomized
>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Tabs = Instance<TabsModel>;
export type TabsSnapshotOut = SnapshotOut<typeof Tabs>;

/**
 * Use this global state to save simple states.
 */
export const GlobalStateCache = types
    .model('GlobalStateCache', {
        ctrlNeededDialogShown: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        setCtrlNeededDialogShown(value: boolean) {
            self.ctrlNeededDialogShown = value;
        },
    }));

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type GlobalStateCache = Instance<typeof GlobalStateCache>;

export interface QueryRootStoreBase {
    connectionPane: ConnectionPane;
    dataProfile: DataProfileStore;
    tabs: Tabs;
    resultCache: ResultCache;
    settings: ISettings;
    gridStateCache: GridStateCache;
    globalStateCache: GlobalStateCache;
    layout: Layout;
}

const rootStoreActionsAndView = (self: QueryRootStoreBase) => {
    const env = getQueryStoreEnv(self);
    return {
        views: {
            getRowDataForCluster(expanded: { [id: string]: boolean }, treeEntityMapper: ITreeEntityMapper) {
                return clustersToRowData(
                    env,
                    Array.from(self.connectionPane.connections.values()),
                    self.connectionPane.showOnlyFavorites,
                    self.connectionPane.entityInContext ? [self.connectionPane.entityInContext] : [],
                    expanded,
                    treeEntityMapper
                );
            },
            getRowDataForDatabase(expanded: { [id: string]: boolean }, treeEntityMapper: ITreeEntityMapper) {
                if (self.connectionPane.entityInContext?.entityType === EntityType.Database) {
                    const db = self.connectionPane.entityInContext as Database;
                    return databaseChildrenToRawDataTypes(env, db, expanded, treeEntityMapper);
                } else {
                    return [];
                }
            },
        },
    };
};

//
// Note: new Required rootStore props should be add to QueryRootStoreInRequiredProps
//
type QueryRootStoreInRequiredProps = 'connectionPane' | '';
export const RootStore: RootStoreModel = types
    .model('QueryRootStore', {
        connectionPane: ConnectionPane,
        tabs: types.optional(Tabs, {}),
        resultCache: types.optional(ResultCache, { executions: {} }),
        settings: types.optional(Settings, {}),
        gridStateCache: types.optional(GridStateCache, {}),
        globalStateCache: types.optional(GlobalStateCache, {}),
        layout: types.optional(Layout, {}),
        dataProfile: types.optional(DataProfileStore, {}),
    })
    .actions((self) => ({
        afterCreate() {
            self.resultCache.pruneAll();
        },
    }))
    .extend((self) => {
        const extend = rootStoreActionsAndView(self);
        return extend;
    });

export type RootStoreModel = mst.IModelType<
    {
        connectionPane: typeof ConnectionPane;
        tabs: mst.IOptionalIType<TabsModel, [undefined]>;
        resultCache: mst.IOptionalIType<typeof ResultCache, [undefined]>;
        settings: mst.IOptionalIType<typeof Settings, [undefined]>;
        gridStateCache: mst.IOptionalIType<typeof GridStateCache, [undefined]>;
        globalStateCache: mst.IOptionalIType<typeof GlobalStateCache, [undefined]>;
        layout: mst.IOptionalIType<typeof Layout, [undefined]>;
        dataProfile: mst.IOptionalIType<typeof DataProfileStore, [undefined]>;
    },
    {
        afterCreate(): void;
        getRowDataForCluster(
            expanded: {
                [id: string]: boolean;
            },
            treeEntityMapper: ITreeEntityMapper
        ): RowDataType[];
        getRowDataForDatabase(
            expanded: {
                [id: string]: boolean;
            },
            treeEntityMapper: ITreeEntityMapper
        ): RowDataType[];
    },
    mst._NotCustomized
>;

export type IRootStore = Instance<RootStoreModel>;

// Export the RootStore as IAnyModelType - to avoid MST issue - "Type instantiation is excessively deep and possibly infinite"
//
export const QueryRootStore = RootStore as IAnyModelType;

export type IQueryRootStore = QueryRootStoreBase & ReturnType<typeof rootStoreActionsAndView>['views'];

export type QueryRootStoreSnapshotOut = {
    [P in keyof IQueryRootStore]: SnapshotOut<IQueryRootStore[P]>;
};
export type QueryRootStoreSnapshotIn = {
    // Required props of Query root store
    [P in Extract<keyof IQueryRootStore, QueryRootStoreInRequiredProps>]: SnapshotIn<IQueryRootStore[P]>;
} & {
    // Optional props of query root store
    [P in Exclude<keyof IQueryRootStore, QueryRootStoreInRequiredProps>]?: SnapshotIn<IQueryRootStore[P]>;
};
