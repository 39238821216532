import type { VisualMessageData } from '@kusto/visual-fwk';
import type { ChartEvents, KustoHeuristicsErr } from '@kusto/visualizations';

type ChartEventFn = ChartEvents[keyof ChartEvents];

const mergeFns = (key: keyof ChartEvents, a: ChartEventFn, b: ChartEventFn) => {
    const mergedFn = (...args: Parameters<ChartEventFn>) => {
        // @ts-expect-error TS isn't inferring the tuple type from Parameters<ChartEventFn>
        const resultA = a(...args);
        // @ts-expect-error TS isn't inferring the tuple type from Parameters<ChartEventFn>
        const resultB = b(...args);

        /**
         * Mainly for combining return type for
         * ChartEvents['onHighchartsPointMenuItems'].
         * All the other chart events return nothing so
         * no need to worry about them.
         */
        if (key === 'onHighchartsPointMenuItems') {
            let r: ReturnType<ChartEvents['onHighchartsPointMenuItems']> = [];

            if (resultA) {
                r = resultA;
            }
            if (resultB) {
                r = [...r, ...resultB];
            }

            return r;
        }
        return;
    };

    return mergedFn as ChartEventFn;
};

export function mergeChartEvents(
    ...eventsToMerge: Array<undefined | Partial<ChartEvents>>
): undefined | Partial<ChartEvents> {
    // casting because TS can't infer that eventsToMerge.filter(Boolean)
    // results in only Partial<ChartEvents>[]
    const events = eventsToMerge.filter(Boolean) as Partial<ChartEvents>[];

    if (events.length === 0) return undefined;

    const finalEvents: Record<string, ChartEventFn> = {};

    for (const e of events) {
        for (const entry of Object.entries(e)) {
            const key = entry[0] as keyof ChartEvents;
            const eventFn = entry[1];
            const sourceFn = finalEvents[key];

            if (sourceFn) {
                finalEvents[key] = mergeFns(key, sourceFn, eventFn);
            } else {
                finalEvents[key] = eventFn;
            }
        }
    }

    return finalEvents as Partial<ChartEvents>;
}

export function isPositiveInteger(value: string | number | null): boolean {
    if (value && +value > 0) {
        return Number.isInteger(+value);
    }
    return false;
}

export function getHeuristicsErrMessage(err: KustoHeuristicsErr): VisualMessageData {
    const defaultMsg: VisualMessageData = { level: 'error', message: err.message, title: err.title };
    switch (err.type) {
        case 'NoRows':
            return { ...defaultMsg, level: 'info', options: { icon: 'FolderList' } };
        default:
            return defaultMsg;
    }
}
