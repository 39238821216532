const toObjectMap = (...items)=>{
    const result = {};
    for (const item of items){
        const keys = Array.isArray(item) ? item : Object.keys(item);
        for (const key of keys){
            result[key] = 1;
        }
    }
    return result;
};
/**
 * An array of events that are allowed on every html element type.
 *
 * @public
 */ export const baseElementEvents = toObjectMap([
    'onAuxClick',
    'onAnimationEnd',
    'onAnimationStart',
    'onCopy',
    'onCut',
    'onPaste',
    'onCompositionEnd',
    'onCompositionStart',
    'onCompositionUpdate',
    'onFocus',
    'onFocusCapture',
    'onBlur',
    'onBlurCapture',
    'onChange',
    'onInput',
    'onSubmit',
    'onLoad',
    'onError',
    'onKeyDown',
    'onKeyDownCapture',
    'onKeyPress',
    'onKeyUp',
    'onAbort',
    'onCanPlay',
    'onCanPlayThrough',
    'onDurationChange',
    'onEmptied',
    'onEncrypted',
    'onEnded',
    'onLoadedData',
    'onLoadedMetadata',
    'onLoadStart',
    'onPause',
    'onPlay',
    'onPlaying',
    'onProgress',
    'onRateChange',
    'onSeeked',
    'onSeeking',
    'onStalled',
    'onSuspend',
    'onTimeUpdate',
    'onVolumeChange',
    'onWaiting',
    'onClick',
    'onClickCapture',
    'onContextMenu',
    'onDoubleClick',
    'onDrag',
    'onDragEnd',
    'onDragEnter',
    'onDragExit',
    'onDragLeave',
    'onDragOver',
    'onDragStart',
    'onDrop',
    'onMouseDown',
    'onMouseDownCapture',
    'onMouseEnter',
    'onMouseLeave',
    'onMouseMove',
    'onMouseOut',
    'onMouseOver',
    'onMouseUp',
    'onMouseUpCapture',
    'onSelect',
    'onTouchCancel',
    'onTouchEnd',
    'onTouchMove',
    'onTouchStart',
    'onScroll',
    'onWheel',
    'onPointerCancel',
    'onPointerDown',
    'onPointerEnter',
    'onPointerLeave',
    'onPointerMove',
    'onPointerOut',
    'onPointerOver',
    'onPointerUp',
    'onGotPointerCapture',
    'onLostPointerCapture'
]);
/**
 * An array of element attributes which are allowed on every html element type.
 *
 * @public
 */ export const baseElementProperties = toObjectMap([
    'accessKey',
    'children',
    'className',
    'contentEditable',
    'dir',
    'draggable',
    'hidden',
    'htmlFor',
    'id',
    'lang',
    'ref',
    'role',
    'style',
    'tabIndex',
    'title',
    'translate',
    'spellCheck',
    'name'
]);
/**
 * An array of microdata attributes that are allowed on every html element type.
 *
 * @public
 */ export const microdataProperties = toObjectMap([
    'itemID',
    'itemProp',
    'itemRef',
    'itemScope',
    'itemType'
]);
/**
 * An array of HTML element properties and events.
 *
 * @public
 */ export const htmlElementProperties = toObjectMap(baseElementProperties, baseElementEvents, microdataProperties);
/**
 * An array of LABEL tag properties and events.
 *
 * @public
 */ export const labelProperties = toObjectMap(htmlElementProperties, [
    'form'
]);
/**
 * An array of AUDIO tag properties and events.

 * @public
 */ export const audioProperties = toObjectMap(htmlElementProperties, [
    'height',
    'loop',
    'muted',
    'preload',
    'src',
    'width'
]);
/**
 * An array of VIDEO tag properties and events.
 *
 * @public
 */ export const videoProperties = toObjectMap(audioProperties, [
    'poster'
]);
/**
 * An array of OL tag properties and events.
 *
 * @public
 */ export const olProperties = toObjectMap(htmlElementProperties, [
    'start'
]);
/**
 * An array of LI tag properties and events.
 *
 * @public
 */ export const liProperties = toObjectMap(htmlElementProperties, [
    'value'
]);
/**
 * An array of A tag properties and events.
 *
 * @public
 */ export const anchorProperties = toObjectMap(htmlElementProperties, [
    'download',
    'href',
    'hrefLang',
    'media',
    'rel',
    'target',
    'type'
]);
/**
 * An array of TIME tag properties and events.
 *
 * @public
 */ export const timeProperties = toObjectMap(htmlElementProperties, [
    'dateTime'
]);
/**
 * An array of BUTTON tag properties and events.
 *
 * @public
 */ export const buttonProperties = toObjectMap(htmlElementProperties, [
    'autoFocus',
    'disabled',
    'form',
    'formAction',
    'formEncType',
    'formMethod',
    'formNoValidate',
    'formTarget',
    'type',
    'value'
]);
/**
 * An array of INPUT tag properties and events.
 *
 * @public
 */ export const inputProperties = toObjectMap(buttonProperties, [
    'accept',
    'alt',
    'autoCapitalize',
    'autoComplete',
    'checked',
    'dirname',
    'form',
    'height',
    'inputMode',
    'list',
    'max',
    'maxLength',
    'min',
    'multiple',
    'pattern',
    'placeholder',
    'readOnly',
    'required',
    'src',
    'step',
    'size',
    'type',
    'value',
    'width'
]);
/**
 * An array of TEXTAREA tag properties and events.
 *
 * @public
 */ export const textAreaProperties = toObjectMap(buttonProperties, [
    'autoCapitalize',
    'cols',
    'dirname',
    'form',
    'maxLength',
    'placeholder',
    'readOnly',
    'required',
    'rows',
    'wrap'
]);
/**
 * An array of SELECT tag properties and events.
 *
 * @public
 */ export const selectProperties = toObjectMap(buttonProperties, [
    'form',
    'multiple',
    'required'
]);
export const optionProperties = toObjectMap(htmlElementProperties, [
    'selected',
    'value'
]);
/**
 * An array of TABLE tag properties and events.
 *
 * @public
 */ export const tableProperties = toObjectMap(htmlElementProperties, [
    'cellPadding',
    'cellSpacing'
]);
/**
 * An array of TR tag properties and events.
 *
 * @public
 */ export const trProperties = htmlElementProperties;
/**
 * An array of TH tag properties and events.
 *
 * @public
 */ export const thProperties = toObjectMap(htmlElementProperties, [
    'colSpan',
    'rowSpan',
    'scope'
]);
/**
 * An array of TD tag properties and events.
 *
 * @public
 */ export const tdProperties = toObjectMap(htmlElementProperties, [
    'colSpan',
    'headers',
    'rowSpan',
    'scope'
]);
export const colGroupProperties = toObjectMap(htmlElementProperties, [
    'span'
]);
export const colProperties = toObjectMap(htmlElementProperties, [
    'span'
]);
/**
 * An array of FIELDSET tag properties and events.
 *
 * @public
 */ export const fieldsetProperties = toObjectMap(htmlElementProperties, [
    'disabled',
    'form'
]);
/**
 * An array of FORM tag properties and events.
 *
 * @public
 */ export const formProperties = toObjectMap(htmlElementProperties, [
    'acceptCharset',
    'action',
    'encType',
    'encType',
    'method',
    'noValidate',
    'target'
]);
/**
 * An array of IFRAME tag properties and events.
 *
 * @public
 */ export const iframeProperties = toObjectMap(htmlElementProperties, [
    'allow',
    'allowFullScreen',
    'allowPaymentRequest',
    'allowTransparency',
    'csp',
    'height',
    'importance',
    'referrerPolicy',
    'sandbox',
    'src',
    'srcDoc',
    'width'
]);
/**
 * An array of IMAGE tag properties and events.
 *
 * @public
 */ export const imgProperties = toObjectMap(htmlElementProperties, [
    'alt',
    'crossOrigin',
    'height',
    'src',
    'srcSet',
    'useMap',
    'width'
]);
/**
 * An array of DIALOG tag properties and events.
 *
 * @public
 */ export const dialogProperties = toObjectMap(htmlElementProperties, [
    'open',
    'onCancel',
    'onClose'
]);
/**
 * An array of DIV tag properties and events.
 *
 * @public
 */ export const divProperties = htmlElementProperties;
/**
 * Gets native supported props for an html element provided the allowance set. Use one of the property
 * sets defined (divProperties, buttonPropertes, etc) to filter out supported properties from a given
 * props set. Note that all data- and aria- prefixed attributes will be allowed.
 * NOTE: getNativeProps should always be applied first when adding props to a react component. The
 * non-native props should be applied second. This will prevent getNativeProps from overriding your custom props.
 * For example, if props passed to getNativeProps has an onClick function and getNativeProps is added to
 * the component after an onClick function is added, then the getNativeProps onClick will override it.
 *
 * @public
 * @param props - The unfiltered input props
 * @param allowedPropNames - The array or record of allowed prop names.
 * @param excludedPropNames
 * @returns The filtered props
 */ // eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getNativeProps(// eslint-disable-next-line @typescript-eslint/no-explicit-any
props, allowedPropNames, excludedPropNames) {
    // It'd be great to properly type this while allowing 'aria-` and 'data-' attributes like TypeScript does for
    // JSX attributes, but that ability is hardcoded into the TS compiler with no analog in TypeScript typings.
    // Then we'd be able to enforce props extends native props (including aria- and data- attributes), and then
    // return native props.
    // We should be able to do this once this PR is merged: https://github.com/microsoft/TypeScript/pull/26797
    const isArray = Array.isArray(allowedPropNames);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = {};
    const keys = Object.keys(props);
    for (const key of keys){
        const isNativeProp = !isArray && allowedPropNames[key] || isArray && allowedPropNames.indexOf(key) >= 0 || key.indexOf('data-') === 0 || key.indexOf('aria-') === 0;
        if (isNativeProp && (!excludedPropNames || (excludedPropNames === null || excludedPropNames === void 0 ? void 0 : excludedPropNames.indexOf(key)) === -1)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            result[key] = props[key];
        }
    }
    return result;
}
