.rulesList {
    width: 100%;
}

.colorRuleRow {
    padding: 4px 0 10px 0;
    border-bottom: 1px solid var(--gray50);
}

.colorRuleRow:last-child {
    border-bottom: none;
}

.indicatorsContainer {
    height: 20px;
    margin-top: 16px;
    display: flex;
    align-items: center;
}

.ruleConfigText {
    margin-left: 4px;
    color: var(--neutralSecondary);
}
