import React, { useMemo } from 'react';
import { usePrevious } from '@fluentui/react-hooks';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Accessibility from 'highcharts/modules/accessibility';
import Boost from 'highcharts/modules/boost';
import { observer } from 'mobx-react-lite';

import { useStrings } from '../DataExplorationModal/hooks/useStrings';
import { createHistogramOptions } from './createHistogramOptions';
import type { HistogramOkValue } from './types';

import styles from './styles.module.scss';

Boost(Highcharts);
Accessibility(Highcharts);

export interface HistogramVisualProps {
    data: HistogramOkValue;
    isDarkTheme: boolean;
    timezone: string;
    seriesColor?: Highcharts.ColorString;
    seriesFillColor?: Highcharts.ColorString;
}

/**
 * Histogram (Line Chart) Visual from Highcharts
 */
export const HistogramVisual = observer<HistogramVisualProps>(function HistogramVisual({
    data,
    isDarkTheme,
    timezone,
    seriesColor,
    seriesFillColor,
}) {
    const { strings, locale } = useStrings();

    const options = React.useMemo(
        () =>
            createHistogramOptions({
                data,
                isDarkTheme,
                timezone,
                strings,
                locale,
                seriesColor,
                seriesFillColor,
            }),
        [data, isDarkTheme, timezone, strings, locale, seriesColor, seriesFillColor]
    );
    const prevIsDarkTheme = usePrevious(isDarkTheme);

    // highcharts does not know how to switch themes when updating the chart object, only when recreating it.
    // Thus whenever we're changing themes, we're signaling highcharts to recreate the object by (ab)using the immutable flag.
    const shouldRecreate = useMemo(() => {
        return prevIsDarkTheme !== undefined && prevIsDarkTheme !== isDarkTheme;
    }, [prevIsDarkTheme, isDarkTheme]);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            immutable={shouldRecreate}
            containerProps={{ className: styles.highchartsContainer }}
        />
    );
});
