import React from 'react';
import { Body1Strong, Spinner } from '@fluentui/react-components';

import { useStrings } from '../DataExplorationModal/hooks/useStrings';
import { HistogramVisual, HistogramVisualProps } from './HistogramVisual';

import styles from './styles.module.scss';

interface HistogramOKViewProps extends HistogramVisualProps {
    isLoading: boolean;
}
export const HistogramOKView: React.FC<HistogramOKViewProps> = ({ isLoading, ...props }) => {
    const { locale } = useStrings();
    if (isLoading) {
        return <Spinner />;
    }

    const formatter = new Intl.DateTimeFormat(locale, { dateStyle: 'medium', timeZone: props.timezone });
    const firstBinStart = props.data.at(0)?.binStart;
    const lastBinEnd = props.data.at(-1)?.binEnd;

    return (
        <>
            <HistogramVisual {...props} />
            {firstBinStart ? (
                <Body1Strong block className={styles.firstBin}>
                    {formatter.format(firstBinStart)}
                </Body1Strong>
            ) : null}
            {lastBinEnd ? (
                <Body1Strong block className={styles.lastBin}>
                    {formatter.format(lastBinEnd)}
                </Body1Strong>
            ) : null}
        </>
    );
};
