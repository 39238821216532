import { useMemo } from 'react';

import { UnknownVisualOptions } from '@kusto/visual-fwk';

import { Pill } from '../types/Pills.interfaces';
import { DataExplorationModalState } from './state';

interface ADD_PIPE {
    type: 'ADD_PIPE';
    payload: {
        pipe: string;
    };
}

interface REMOVE_PIPE {
    type: 'REMOVE_PIPE';
}

interface ADD_PILL {
    type: 'ADD_PILL';
    payload: {
        pill: Pill;
    };
}

interface UPDATE_PILL {
    type: 'UPDATE_PILL';
    payload: {
        pillIndex: number;
        pill: Pill;
    };
}

interface REMOVE_PILL {
    type: 'REMOVE_PILL';
}

interface CHANGE_VISUAL_TYPE {
    type: 'CHANGE_VISUAL_TYPE';
    payload: {
        type: string;
        options: UnknownVisualOptions;
    };
}

interface OVERRIDE_STATE {
    type: 'OVERRIDE_STATE';
    payload: DataExplorationModalState;
}

export type DataExplorationModalActions =
    | ADD_PIPE
    | REMOVE_PIPE
    | ADD_PILL
    | UPDATE_PILL
    | REMOVE_PILL
    | CHANGE_VISUAL_TYPE
    | OVERRIDE_STATE;

export function useDataExplorationActions(
    dispatch: React.Dispatch<DataExplorationModalActions>,
    defaultState: DataExplorationModalState
) {
    const actions = useMemo(
        () => ({
            /** Add new pipe */
            addPipe: (pipe: string) => {
                dispatch({ type: 'ADD_PIPE', payload: { pipe } });
            },
            /** Remove the last pipe (auto generated from source query) */
            removePipe: () => {
                dispatch({ type: 'REMOVE_PIPE' });
            },
            /** Add new pill at the end of the pills array */
            addPill: (pill: Pill) => {
                dispatch({ type: 'ADD_PILL', payload: { pill } });
            },
            /** Replace the item in the pills array at `index` with the provided `pill` */
            updatePill: (pill: Pill, pillIndex: number) => {
                dispatch({ type: 'UPDATE_PILL', payload: { pill, pillIndex } });
            },
            /** Remove the last pill (user defined) */
            removePill: () => {
                dispatch({ type: 'REMOVE_PILL' });
            },
            changeVisualType: (visualType: string) => {
                dispatch({
                    type: 'CHANGE_VISUAL_TYPE',
                    payload: {
                        type: visualType,
                        options: {
                            /* placeholder for sensible defaults */
                        },
                    },
                });
            },
            /** reset modal to initial state */
            resetToInitialState: () => {
                dispatch({ type: 'OVERRIDE_STATE', payload: defaultState });
            },
        }),
        [defaultState, dispatch]
    );
    return actions;
}
