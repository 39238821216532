import React from 'react';
import { Caption1 } from '@fluentui/react-components';

import { ErrorBoundary as _ErrorBoundary } from '@kusto/ui-components';

import { useStrings } from '../../hooks/useStrings';

/** ErrorBoundary for DataExpo dialog components */
export const ErrorBoundary: React.FunctionComponent = ({ children }) => {
    const { strings } = useStrings();

    return (
        <_ErrorBoundary fallback={<Caption1>{strings.dataExplorationModal.shared.errorBoundaryMessage}</Caption1>}>
            {children}
        </_ErrorBoundary>
    );
};
