.expandBarContainer {
    padding: var(--spacingVerticalMNudge) var(--spacingHorizontalMNudge);
    background-color: var(--colorNeutralBackground1);
}
.expandBarBody {
    align-items: center;
    border-radius: var(--borderRadiusXLarge);
    box-shadow: var(--shadow4);
    display: flex;
    height: 36px;
    justify-content: space-between;
    overflow: hidden;
    padding: 0 var(--spacingHorizontalMNudge);
    flex: 1;
}
