/**
 * This file contains functions for getting raw data types for database children (functions, tables etc...)
 * mostly used by Trident case (Trident shows only database children in tree view)
 */

import { Database, QueryStoreEnv } from '../../../stores';
import { ITreeEntityMapper } from '../actions';
import { RowDataType } from '../RowDataType';
import { DatabaseLoadingRowDataType } from './DatabaseLoadingRowDataType';
import { DatabaseRowDataType } from './DatabaseRowDataType';
import { databaseChildrenToRowData, expandRowDataTypes } from './LazyLoadingFlow';

/**
 * Creates a list of RowDataType from a database children (functions, tables etc...).
 * @param database : Database entity used for getting raw data types
 * @param expanded : List of IDs. Each ID represent an entity that needs to be expanded.
 * @param treeEntityMapper : Maps for every entity what actions it supports.
 * @returns List of raw data types for database children
 */
export const databaseChildrenToRawDataTypes = (
    env: QueryStoreEnv,
    database: Database | undefined,
    expanded: { [id: string]: boolean },
    treeEntityMapper: ITreeEntityMapper
): RowDataType[] => {
    const rowData = database ? getDatabaseRowDataWithPlaceholderChild(env, database, treeEntityMapper) : [];
    return expandRowDataTypes(env, rowData, expanded);
};

const getDatabaseRowDataWithPlaceholderChild = (
    env: QueryStoreEnv,
    db: Database,
    treeEntityMapper: ITreeEntityMapper
) => {
    const accumulatedRawDataTypes: RowDataType[] = [];
    const isLoading = DatabaseRowDataType.isLoading(db);
    if (isLoading || db.fetchState === 'notStarted') {
        accumulatedRawDataTypes.push(DatabaseLoadingRowDataType.fromCacheOrCreate(env, db, treeEntityMapper));
    } else {
        const details = DatabaseRowDataType.details(env, db, isLoading);
        const shouldRefreshCache = (old: RowDataType) => details !== old.details;
        const rowData = DatabaseRowDataType.fromCacheOrCreate(
            env,
            db,
            treeEntityMapper,
            isLoading,
            details,
            shouldRefreshCache
        );
        const databaseChildrenRawDataTypes = databaseChildrenToRowData(env, rowData);
        if (databaseChildrenRawDataTypes) {
            accumulatedRawDataTypes.push(...databaseChildrenRawDataTypes);
        }
    }
    return accumulatedRawDataTypes;
};
