import type { KustoWorker } from '@kusto/monaco-kusto';
import debounce from 'lodash/debounce';
import * as mobx from 'mobx';
import * as mst from 'mobx-state-tree';
import { editor, Selection, Uri } from 'monaco-editor/esm/vs/editor/editor.api';
import type { IPosition } from 'monaco-editor/esm/vs/editor/editor.api';

import { initWorkerFromModel } from '@kusto/kusto-schema';
import { IKweTelemetry, isWhitespace } from '@kusto/utils';

import { QueryCore } from '../../core/core';
import { CommandType, MonacoRange, Tab } from '../../stores/tab';
import { makeOptimisticLock, OptimisticLock } from './optimisticLock.ts';

export interface ModelAndView {
    model: editor.ITextModel;
    viewState?: editor.ICodeEditorViewState;
}

export interface MonacoModels {
    [modelId: string]: ModelAndView;
}

export function getOrCreateModel(models: MonacoModels, modelId: string, value?: string) {
    let modelAndView: ModelAndView | undefined;

    // Check component cache
    const modelWrapper = models[modelId];

    if (modelWrapper && modelWrapper.model) {
        // Use component cache
        modelAndView = modelWrapper;
    } else {
        // Check global monaco instance
        const existingModels = editor.getModels();
        const model = existingModels.find((m: editor.ITextModel) => m.uri.path === modelId);
        if (model) {
            // Found in global monaco instance, update component cache
            modelAndView = { model };
            models[modelId] = modelAndView;
        }
    }

    if (modelAndView) {
        if (value !== undefined) {
            modelAndView.model.setValue(value);
        }
        return modelAndView;
    } else {
        // Create a new model and update cache
        const modelUri = Uri.from({
            scheme: 'monaco',
            path: modelId,
        });
        const model = editor.createModel(value || '', 'kusto', modelUri);

        modelAndView = { model: model! };
    }

    models[modelId] = modelAndView;

    return modelAndView;
}

interface CommandDetailsResult {
    command: string;
    commandType: CommandType;
    commandWithoutLeadingComments?: string;
    range?: MonacoRange;
}

export async function getCommandDetails(
    worker: KustoWorker,
    model: editor.IModel,
    offset: number,
    selection: Selection | null,
    telemetry: IKweTelemetry
): Promise<CommandDetailsResult> {
    const selectedText = selection ? model.getValueInRange(selection) : undefined;
    telemetry.verbose(`syncEditorContextToStore: selected text length - ${selectedText?.length ?? 0}`);

    const commandAndLocation = await worker.getCommandAndLocationInContext(model.uri.toString(), offset);
    const command = selectedText ? selectedText : commandAndLocation?.text;
    const range = commandAndLocation?.range;

    telemetry.verbose(`getCommandDetails: command length - ${command?.length ?? 0}`);

    if (!command || isWhitespace(command)) {
        telemetry.verbose('getCommandDetails: no command to run: clear command-in-context');
        return { command: '', commandType: 'Unknown', range };
    }

    // TODO: Because these rely on first getting the command, we have to ping
    // the worker twice here, slowing everything down. We should create a new
    // command that does everything we need, so we can avoid the extra round
    // trip
    const adminCommandPromise = worker.getAdminCommand(command);
    const clientDirective = await worker.getClientDirective(command);
    if (clientDirective.isClientDirective) {
        telemetry.verbose('getCommandDetails: Setting a client directive command in context');
        return {
            command,
            commandType: 'ClientDirective',
            commandWithoutLeadingComments: clientDirective.directiveWithoutLeadingComments,
            range,
        };
    }

    const adminCommand = await adminCommandPromise;
    if (adminCommand.isAdminCommand) {
        telemetry.verbose('getCommandDetails: Setting an admin command in context');
        return {
            command,
            commandType: 'AdminCommand',
            commandWithoutLeadingComments: adminCommand.adminCommandWithoutLeadingComments,
            range,
        };
    }

    telemetry.verbose('getCommandDetails: Setting command in context');
    return { command, commandType: 'Query', range };
}

async function syncEditorContextToStore(
    tab: Tab,
    model: editor.IModel,
    offset: number,
    selection: Selection | null,
    cursorPosition: IPosition,
    telemetry: IKweTelemetry,
    optimisticLock: OptimisticLock
): Promise<void> {
    const currentVersion = optimisticLock.createVersion();
    const worker = await initWorkerFromModel(model);

    // TODO: When does this occur?
    if (!worker) {
        telemetry.trace('syncEditorContextToStore: Kusto worker is empty', { severityLevel: 'warning' });
        return;
    }

    // If tab is removed from mobx-state-tree, then it's model should also be disposed
    if (model.isDisposed()) {
        telemetry.info('syncEditorContextToStore: model is disposed');
        return;
    }

    const tabText = model.getValue(editor.EndOfLinePreference.LF);
    tab.setText(tabText);

    const commandDetails = await getCommandDetails(worker, model, offset, selection, telemetry);

    const wasTabDeletedDuringCommandRetrieval = !mobx.runInAction(() => mst.isAlive(tab));
    if (wasTabDeletedDuringCommandRetrieval) return;

    // Optimistic locking is used here to prevent race conditions when syncing the editor context to the store.
    // Multiple calls to the web worker can return results out of order. Without optimistic locking, an earlier
    // call's result could incorrectly overwrite a more recent and accurate result in the store, leading to outdated
    // or incorrect data in the current query context. By using optimistic locking, we ensure that only the most
    // recent and relevant data is saved, maintaining the integrity of the current query in context.
    if (optimisticLock.shouldUpdate(currentVersion)) {
        tab.setCommandInContext({ ...commandDetails, cursorPosition });
        optimisticLock.setNewVersion(currentVersion);
    }
}

/**
 * Since we want to preserve view state between tab switches (cursor position, ETC), we cache the models and
 * viewstates and retrieve them when needed.
 * @param prevProps previous props
 */
function handleModelChange(
    core: QueryCore,
    editorInstance: editor.IStandaloneCodeEditor,
    models: MonacoModels,
    prevId: string,
    nextId: string
) {
    const prevModel = editorInstance.getModel();
    const prevViewState = editorInstance.saveViewState();

    // store prev model and viewstate if we're switching to another
    // model id, or switching to a model managed by monaco (hence
    // model info is empty).
    if (prevModel && prevViewState) {
        models[prevId] = {
            model: prevModel,
            viewState: prevViewState,
        };
    }

    // When roaming profile is enable, the text will be loaded by the monaco binding
    const text = core.featureFlags.EnableRoamingProfile ? undefined : core.store.tabs.tabInContext.text;

    const { model: nextModel, viewState: nextViewState } = getOrCreateModel(models, nextId, text);

    editorInstance.setModel(nextModel);

    if (nextViewState) {
        editorInstance.restoreViewState(nextViewState);
    }

    // When switching models, it usually means we're switching tabs. this causes monaco to lose focus.
    // Let's bring it back.
    editorInstance.focus();

    // Delete cached models for deleted tabs.
    const cachedModelIds = Object.keys(models);
    const activeModels = new Set(core.store.tabs.tabs.map((t) => t.id));
    const modelsToDelete = cachedModelIds.filter((m) => !activeModels.has(m));
    modelsToDelete.forEach((id) => {
        models[id].model.dispose();
        delete models[id];
    });
}

/**
 * Setup syncing tab text, cursor position, selected query between the store,
 * monaco, and the language service
 *
 * @returns Function to flush debounced changes to the store
 */
export function setupTabContentSync(
    core: QueryCore,
    editorInstance: editor.IStandaloneCodeEditor,
    models: { [modelId: string]: ModelAndView },
    abortSignal: AbortSignal,
    telemetry: IKweTelemetry
): () => undefined | Promise<void> {
    let preventTriggerChangeEvent = false;

    const debouncedSyncEditorContextToStore = debounce(
        syncEditorContextToStore,
        // Slow typing
        200,
        {
            // even if typing a lot we need to sync from time to time
            // Shouldn't cause performance issue
            maxWait: 1000,
        }
    );

    const optimisticLock = makeOptimisticLock();

    const localSyncEditorContextToStore = async () => {
        const model = editorInstance.getModel();
        if (!model) {
            return;
        }

        const position = editorInstance.getPosition();
        if (!position) {
            return;
        }

        // Important that we read the offset, selection, and tab _before_ we
        // debounce because the focused tab might change.

        const offset = model.getOffsetAt(position);
        const selection = editorInstance.getSelection();
        const tabInContext = mobx.runInAction(() => core.store.tabs.tabInContext);

        await debouncedSyncEditorContextToStore(
            tabInContext,
            model,
            offset,
            selection,
            {
                column: position.column,
                lineNumber: position.lineNumber,
            },
            telemetry,
            optimisticLock
        );
    };

    function flushTextSync() {
        return debouncedSyncEditorContextToStore.flush();
    }

    // TODO: Shouldn't sync trigger by text change as well? user typing in Insert Mode (cursor doesn't move)
    editorInstance.onDidChangeCursorPosition(() => {
        telemetry.verbose('onDidChangeCursorPosition');
        if (preventTriggerChangeEvent) {
            telemetry.verbose('onDidChangeCursorPosition: preventTriggerChangeEvent');
            return;
        }
        localSyncEditorContextToStore();
    });

    abortSignal.addEventListener('abort', flushTextSync);

    abortSignal.addEventListener(
        'abort',
        mobx.reaction(
            () => {
                const tabInContext = core.store.tabs.tabInContext;
                const { text, cursorPosition, id } = tabInContext;
                return { text, cursorPosition, id, tabInContext };
            },
            ({ id, text, cursorPosition }, prev) => {
                preventTriggerChangeEvent = true;
                let updateCommandInContextDetails = false;

                if (!prev) {
                    telemetry.trace('syncWithStoreTabs - tab store change - no previous data');
                } else if (prev.id !== id) {
                    // Handle tab change - flush previous tab changes to store,
                    // load new/re-used model to editor
                    // sync commandInContext** to store

                    // Flush previous tab changes
                    flushTextSync();

                    handleModelChange(core, editorInstance, models, prev.id, id);

                    if (cursorPosition) {
                        editorInstance.setPosition(cursorPosition);
                        editorInstance.revealPositionInCenterIfOutsideViewport(cursorPosition);
                    }

                    updateCommandInContextDetails = true;
                } else {
                    // Handle content change while in the same tab

                    const shouldUpdateText =
                        text !== prev.text &&
                        text !== editorInstance.getModel()?.getValue(editor.EndOfLinePreference.LF) &&
                        !core.featureFlags.EnableRoamingProfile;
                    const updatePosition =
                        cursorPosition &&
                        (cursorPosition.lineNumber !== prev.cursorPosition?.lineNumber ||
                            cursorPosition.column !== prev.cursorPosition?.column)
                            ? cursorPosition
                            : // In case we change the text let keep at least the current editor position
                            shouldUpdateText
                            ? editorInstance.getPosition()
                            : undefined;

                    if (shouldUpdateText) {
                        editorInstance.setValue(text);
                    }
                    if (updatePosition) {
                        editorInstance.setPosition(updatePosition);
                        editorInstance.revealPositionInCenterIfOutsideViewport(updatePosition);

                        updateCommandInContextDetails = true;
                    }
                }

                if (updateCommandInContextDetails) {
                    // Monaco model is initialized with things the store isn't
                    // (intellisense, cursor position, etc.), so we need to
                    // update the store
                    localSyncEditorContextToStore();
                    debouncedSyncEditorContextToStore.flush();
                }
                preventTriggerChangeEvent = false;
            },
            { fireImmediately: true, name: 'applyTabChangeToMonaco' }
        )
    );

    // Trigger initial sync of command in context to support deeplink autorun
    // run query/command (tab.ts) will wait until on debounce flush is completed
    localSyncEditorContextToStore();

    return flushTextSync;
}
