import React from 'react';
import {
    Link,
    MessageBar,
    TextField,
    Toggle,
    type ILayerStyleProps,
    type ILayerStyles,
    type ISearchBox,
    type IStyleFunctionOrObject,
} from '@fluentui/react';
import { Button, makeStyles, Tab, TabList, tokens, Tooltip, useArrowNavigationGroup } from '@fluentui/react-components';
import { ChevronDoubleLeft20Regular, ChevronDoubleRight20Regular, Pin20Regular } from '@fluentui/react-icons';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import { ExceptionBoundary } from '@kusto/app-common';
import { defaultLayout, RtdVisualTypes } from '@kusto/rtd-provider';
import { KweException, Theme, useComputed } from '@kusto/utils';
import {
    initVisualModel,
    IVisualOptionsModel,
    RenderConfigurationsOptions,
    RenderVisualOptions,
    tileInputClassNames,
    useVisualMessages,
} from '@kusto/visual-fwk';
import { defaultMessageFormatter } from '@kusto/visualizations';

import { QUERY_CONSTANTS } from '../../constants';
import { useQueryCore } from '../../core/core';
import { Tile } from '../charting/Tile';
import { ExternalContent } from './ExternalResizablePanelProvider';
import { QueryResourceConsumption } from './QueryResourceConsumption';
import { QueryResultGridWithStateCache } from './QueryResultGrid';

import * as styles from './QueryResultVisualization.module.scss';

const noop = () => {};

export const chartResultContainerId = styles.chartResultContainer;

export interface QueryResultVisualizationProps {
    searchEnabled?: boolean;
    searchBoxRef?: React.RefObject<ISearchBox>;
    searchPlaceholderRef?: React.RefObject<HTMLDivElement>;
    onSearchClear?: () => void;
}

const useStyle = makeStyles({
    visualOptionsPanelHeader: {
        justifyContent: 'space-between',
        display: 'flex',
        height: '39px',
        backgroundColor: tokens.colorNeutralForegroundInverted,
    },
    tabList: {
        backgroundColor: tokens.colorNeutralForegroundInverted,
    },
});

const ChartWrapper: React.FC<{
    isCard: boolean;
    title?: string | null;
    hideTitle: boolean;
    children: React.ReactElement | React.ReactElement[];
}> = ({ isCard, title, hideTitle, children }) => {
    return title && !hideTitle && isCard ? (
        <Tile
            header={title}
            centered={false}
            styles={{
                header: {
                    fontWeight: 600,
                    padding: '0 0 0 8px',
                    lineHeight: 40,
                },
                tile: { padding: 0 },
            }}
        >
            {children}
        </Tile>
    ) : (
        <>{children}</>
    );
};

const visualOptionsPanelStyles: IStyleFunctionOrObject<ILayerStyleProps, ILayerStyles> = () => ({
    root: {
        height: '100%',
        overflow: 'hidden',
    },
    content: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
});

const VisualTitleField: React.FC = observer(function VisualTitleField() {
    const queryCore = useQueryCore();
    const tabInContext = queryCore.store.tabs.tabInContext;

    return (
        <>
            <TextField
                className={tileInputClassNames.basicInput}
                onChange={(_, newTitle = '') => tabInContext.setVisualTitle(newTitle)}
                // Default to `''` to ensure this is a controlled component
                value={tabInContext.visualModelState.title ?? ''}
                label={queryCore.strings.query.visualOptions.titleInputLabel}
                autoComplete="off"
            />
            <Toggle
                className={tileInputClassNames.basicInput}
                onChange={(_, checked = false) => tabInContext.setVisualHideTitle(checked)}
                checked={tabInContext.visualModelState.hideTitle}
                label={queryCore.strings.query.visualOptions.hideTitleCheckboxLabel}
            />
        </>
    );
});

const VisualModelOptionsPanel: React.FC<{
    model: IVisualOptionsModel;
    showPinWarning: boolean;
}> = observer(function VisualModelOptionsPanel({ model, showPinWarning }) {
    const core = useQueryCore();
    const { layout } = core.store;
    const isOpen = layout.isVisualConfigOpen;

    const shouldShowPnMessage = core.config.actions?.openPinToDashboard && showPinWarning;

    const renderConfigurationOptions: RenderConfigurationsOptions = {
        headAdditions: !QUERY_CONSTANTS.tileUnsupportedVisuals.has(model.pluginKey.get()) && <VisualTitleField />,
        isDarkTheme: core.store.settings.theme === Theme.Dark,
    };

    const handlePanelToggle = () => {
        core.telemetry.event('VisualOptions.togglePanelOpen', {
            action: isOpen ? 'close' : 'open',
        });
        layout.toggleVisualConfigOpen();
    };

    const attributes = useArrowNavigationGroup({ axis: 'horizontal', circular: true });
    const visualizationsStyles = useStyle();
    return (
        <ExternalContent
            visible={true}
            styles={visualOptionsPanelStyles}
            width={isOpen ? '356px' : '32px'}
            allowResize={false}
        >
            <div {...attributes} className={visualizationsStyles.visualOptionsPanelHeader}>
                <TabList className={visualizationsStyles.tabList} selectedValue="visualFormatting">
                    <Tooltip
                        content={
                            isOpen
                                ? core.strings.query.visualOptions.closeVisualFormattingButtonAria
                                : core.strings.query.visualOptions.openVisualFormattingButtonAria
                        }
                        relationship="label"
                    >
                        <Button
                            appearance="subtle"
                            icon={isOpen ? <ChevronDoubleRight20Regular /> : <ChevronDoubleLeft20Regular />}
                            onClick={handlePanelToggle}
                        />
                    </Tooltip>
                    {isOpen && (
                        <>
                            <Tab value="visualFormatting">{core.strings.query.visualOptions.visualFormatting}</Tab>
                            {core.config.actions?.openPinToDashboard && (
                                <Tooltip
                                    content={core.strings.query.visualOptions.pinToDashboard.button}
                                    relationship="label"
                                >
                                    <Button
                                        icon={<Pin20Regular />}
                                        onClick={core.config.actions?.openPinToDashboard}
                                        appearance="transparent"
                                    >
                                        {core.strings.query.visualOptions.pinToDashboard.button}
                                    </Button>
                                </Tooltip>
                            )}
                        </>
                    )}
                </TabList>
            </div>
            {isOpen && shouldShowPnMessage ? (
                <div className={styles.pinToDashboardWarning}>
                    <MessageBar styles={{ root: { height: '64px' } }}>
                        {core.strings.query.visualOptions.pinToDashboard.warningMessage.text}
                        <Link onClick={core.config.actions?.openPinToDashboard}>
                            {core.strings.query.visualOptions.pinToDashboard.warningMessage.link}
                        </Link>
                    </MessageBar>
                </div>
            ) : null}
            {isOpen && model.renderConfiguration(renderConfigurationOptions)}
        </ExternalContent>
    );
});

function useObserveVisualType(model: IVisualOptionsModel) {
    const [isDirty, setDirty] = React.useState(false);
    const { tabInContext } = useQueryCore().store.tabs;
    React.useEffect(() => {
        return mobx.reaction(
            () => model.pluginKey.get(),
            (newKey) => {
                if (newKey) {
                    if (tabInContext.visualModelState) {
                        if (tabInContext.visualModelState.visualType !== newKey) {
                            setDirty(true);
                            tabInContext.visualModelState.visualType = newKey as RtdVisualTypes;
                        }
                    }
                }
            }
        );
    }, [model.pluginKey, tabInContext.visualModelState]);
    return isDirty;
}

function useObserveVisualOptionsChanges(model: IVisualOptionsModel) {
    const [isDirty, setDirty] = React.useState(false);
    const { tabInContext } = useQueryCore().store.tabs;
    React.useEffect(() => {
        return mobx.reaction(
            () => model.optionsCopy,
            (newOptions) => {
                setDirty(true);
                tabInContext.visualModelState.options = newOptions;
            }
        );
    }, [model, tabInContext]);
    return isDirty;
}

function useVisOptionsModel() {
    const core = useQueryCore();
    const parsedVisuals = core.parsedVisuals;
    const tab = core.store.tabs.tabInContext;

    const [model] = React.useState<IVisualOptionsModel>(() =>
        initVisualModel(
            {
                featureFlags: core.featureFlags,
                telemetry: core.telemetry,
                parsedVisuals,
                layout: defaultLayout,
                get queryResult() {
                    return tab.visualResult;
                },
                headerLevel: 2,
                locale() {
                    return core.strings;
                },
            },
            mobx.runInAction(() => tab.visualModelState)
        )
    );

    const isTypeDirty = useObserveVisualType(model);
    const isOptionsDirty = useObserveVisualOptionsChanges(model);

    React.useEffect(() => {
        return () => {
            model.dispose();
        };
    }, [model]);
    return { model, isDirty: isTypeDirty || isOptionsDirty };
}

const VisualModelVisualization: React.FC<{
    visualOptionsModel: IVisualOptionsModel;
    showPinWarning: boolean;
}> = observer(function VisualModelVisualization({ visualOptionsModel, showPinWarning }) {
    const core = useQueryCore();
    const [, addMessage] = useVisualMessages(undefined); // todo: pass query results + show messages

    const renderVisualOptions = useComputed(
        (): RenderVisualOptions => ({
            onLinkClick: core.config.openQueryLink,
            isDarkTheme: core.store.settings.theme === Theme.Dark,
            timeZone: core.store.settings.resolvedTimeZone,
            formatMessage: defaultMessageFormatter,
            renderErrorBoundary: (wrappedElement) => (
                <ExceptionBoundary
                    wrapError={(error) => defaultMessageFormatter({ level: 'error', message: error })}
                    cidPrefix="QAV" // Query area visual
                    area="Query"
                    headerLevel={2}
                    supportEmail={core.config.supportEmail}
                    t={core.strings.utils.components.crashText}
                >
                    {wrappedElement}
                </ExceptionBoundary>
            ),
            featureFlags: core.featureFlags,
            setResultCounts: noop,
            addMessage,
        }),
        [addMessage, core]
    );

    const tabInContext = core.store.tabs.tabInContext;

    return (
        <>
            <div id={chartResultContainerId}>
                <ChartWrapper
                    isCard={!QUERY_CONSTANTS.tileUnsupportedVisuals.has(visualOptionsModel.pluginKey.get())}
                    title={tabInContext.visualModelState.title}
                    hideTitle={tabInContext.visualModelState.hideTitle}
                >
                    {visualOptionsModel.renderVisual(renderVisualOptions)}
                </ChartWrapper>
            </div>
            {/* Doesn't matter where VisualModelOptionsPanel goes, because it renders stuff through a portal  */}
            {core.featureFlags.QueryVisualOptions && (
                <VisualModelOptionsPanel model={visualOptionsModel} showPinWarning={showPinWarning} />
            )}
        </>
    );
});

/**
 * Visualize the query results (not including the Action Bar). The view can show either one of the following:
 * 1. A Table Grid with all the results
 * 2. A chart, if query was rendered
 * 3. Stats (queryResourceConsumption) to show query statistics.
 *
 * The decision what needs to be shown is based on resultIndex.
 * resultIndex is the index of the selected tab (Graph, Table <number> or Stats) in the action bar.
 */
export const QueryResultVisualization: React.FC<QueryResultVisualizationProps> = observer(
    function QueryResultVisualization(props) {
        const core = useQueryCore();
        const { model: visualOptionsModel, isDirty: isVisualOptionsModelDirty } = useVisOptionsModel();
        const tab = core.store.tabs.tabInContext;
        const timeZone = core.store.settings.resolvedTimeZone;
        const completionInfo = tab?.completionInfo;
        const { resultIndex = undefined, id: completionInfoId = undefined } = completionInfo || {};
        const resultsToDisplay = tab?.results?.resultsToDisplay;
        const resultToDisplay = resultIndex !== undefined ? resultsToDisplay?.[resultIndex] : undefined;
        const addClickableLinks =
            core.store.settings?.clickableLinks === true && core.featureFlags.HighlightUrlColumns === true;
        const results = React.useMemo(() => {
            return resultToDisplay && tab?.results
                ? tab.results.pojoResultWithColumnFormatting(resultToDisplay, addClickableLinks)
                : undefined;
        }, [resultToDisplay, addClickableLinks, tab]);

        if (
            !tab ||
            tab.isFetching ||
            !tab.results ||
            !resultsToDisplay ||
            resultIndex === undefined ||
            completionInfoId === undefined
        ) {
            return null;
        }

        const firstResult = tab.results.results[0];

        // sanity check
        if (firstResult?.rows === null || firstResult?.columns === null) {
            throw new KweException('query is reported as done but rows and cols are null');
        }

        if (resultIndex >= resultsToDisplay.length) {
            return <QueryResourceConsumption completionInfo={completionInfo!} />;
        }

        // TODO: When does this happen
        if (!resultToDisplay || !results) {
            return null;
        }

        // we always have a tab for the table visualization (even if we show a graph)
        if (resultToDisplay.isChart) {
            if (core.store.tabs.tabInContext!.renderingException) {
                return <div />;
            }

            return (
                <VisualModelVisualization
                    visualOptionsModel={visualOptionsModel}
                    showPinWarning={isVisualOptionsModelDirty}
                />
            );
        }

        return (
            <QueryResultGridWithStateCache
                key={completionInfoId + '_' + resultIndex + '_' + tab.id}
                resultToDisplay={results}
                searchBoxRef={props.searchBoxRef}
                searchEnabled={props.searchEnabled}
                searchPlaceholderRef={props.searchPlaceholderRef}
                onSearchClear={props.onSearchClear}
                locale={core.strings}
                timezone={timeZone}
                hideEmptyColumns={core.store.tabs.tabInContext?.hideEmptyColumns ?? false}
                searchOptions={{
                    styles: { searchBox: { width: 'unset', minWidth: 100, flexGrow: 1, maxWidth: 300 } },
                }}
            />
        );
    }
);
