export const Alt = 'Alt';
export const AltGraph = 'AltGraph';
export const CapsLock = 'CapsLock';
export const Control = 'Control';
export const Fn = 'Fn';
export const FnLock = 'FnLock';
export const Meta = 'Meta';
export const NumLock = 'NumLock';
export const ScrollLock = 'ScrollLock';
export const Shift = 'Shift';
export const Symbol = 'Symbol';
export const SymbolLock = 'SymbolLock';
export const Hyper = 'Hyper';
export const Super = 'Super';
export const Enter = 'Enter';
export const Space = ' ';
export const Tab = 'Tab';
export const ArrowDown = 'ArrowDown';
export const ArrowLeft = 'ArrowLeft';
export const ArrowRight = 'ArrowRight';
export const ArrowUp = 'ArrowUp';
export const End = 'End';
export const Home = 'Home';
export const PageDown = 'PageDown';
export const PageUp = 'PageUp';
export const Backspace = 'Backspace';
export const Clear = 'Clear';
export const Copy = 'Copy';
export const CrSel = 'CrSel';
export const Cut = 'Cut';
export const Delete = 'Delete';
export const EraseEof = 'EraseEof';
export const ExSel = 'ExSel';
export const Insert = 'Insert';
export const Paste = 'Paste';
export const Redo = 'Redo';
export const Undo = 'Undo';
export const Accept = 'Accept';
export const Again = 'Again';
export const Attn = 'Attn';
export const Cancel = 'Cancel';
export const ContextMenu = 'ContextMenu';
export const Escape = 'Escape';
export const Execute = 'Execute';
export const Find = 'Find';
export const Help = 'Help';
export const Pause = 'Pause';
export const Play = 'Play';
export const Props = 'Props';
export const Select = 'Select';
export const ZoomIn = 'ZoomIn';
export const ZoomOut = 'ZoomOut';
export const BrightnessDown = 'BrightnessDown';
export const BrightnessUp = 'BrightnessUp';
export const Eject = 'Eject';
export const LogOff = 'LogOff';
export const Power = 'Power';
export const PowerOff = 'PowerOff';
export const PrintScreen = 'PrintScreen';
export const Hibernate = 'Hibernate';
export const Standby = 'Standby';
export const WakeUp = 'WakeUp';
export const AllCandidates = 'AllCandidates';
export const Alphanumeric = 'Alphanumeric';
export const CodeInput = 'CodeInput';
export const Compose = 'Compose';
export const Convert = 'Convert';
export const Dead = 'Dead';
export const FinalMode = 'FinalMode';
export const GroupFirst = 'GroupFirst';
export const GroupLast = 'GroupLast';
export const GroupNext = 'GroupNext';
export const GroupPrevious = 'GroupPrevious';
export const ModeChange = 'ModeChange';
export const NextCandidate = 'NextCandidate';
export const NonConvert = 'NonConvert';
export const PreviousCandidate = 'PreviousCandidate';
export const Process = 'Process';
export const SingleCandidate = 'SingleCandidate';
export const HangulMode = 'HangulMode';
export const HanjaMode = 'HanjaMode';
export const JunjaMode = 'JunjaMode';
export const Eisu = 'Eisu';
export const Hankaku = 'Hankaku';
export const Hiragana = 'Hiragana';
export const HiraganaKatakana = 'HiraganaKatakana';
export const KanaMode = 'KanaMode';
export const KanjiMode = 'KanjiMode';
export const Katakana = 'Katakana';
export const Romaji = 'Romaji';
export const Zenkaku = 'Zenkaku';
export const ZenkakuHankaku = 'ZenkakuHankaku';
export const F1 = 'F1';
export const F2 = 'F2';
export const F3 = 'F3';
export const F4 = 'F4';
export const F5 = 'F5';
export const F6 = 'F6';
export const F7 = 'F7';
export const F8 = 'F8';
export const F9 = 'F9';
export const F10 = 'F10';
export const F11 = 'F11';
export const F12 = 'F12';
export const Soft1 = 'Soft1';
export const Soft2 = 'Soft2';
export const Soft3 = 'Soft3';
export const Soft4 = 'Soft4';
export const ChannelDown = 'ChannelDown';
export const ChannelUp = 'ChannelUp';
export const Close = 'Close';
export const MailForward = 'MailForward';
export const MailReply = 'MailReply';
export const MailSend = 'MailSend';
export const MediaClose = 'MediaClose';
export const MediaFastForward = 'MediaFastForward';
export const MediaPause = 'MediaPause';
export const MediaPlay = 'MediaPlay';
export const MediaPlayPause = 'MediaPlayPause';
export const MediaRecord = 'MediaRecord';
export const MediaRewind = 'MediaRewind';
export const MediaStop = 'MediaStop';
export const MediaTrackNext = 'MediaTrackNext';
export const MediaTrackPrevious = 'MediaTrackPrevious';
export const New = 'New';
export const Open = 'Open';
export const Print = 'Print';
export const Save = 'Save';
export const SpellCheck = 'SpellCheck';
export const Key11 = 'Key11';
export const Key12 = 'Key12';
export const AudioBalanceLeft = 'AudioBalanceLeft';
export const AudioBalanceRight = 'AudioBalanceRight';
export const AudioBassBoostDown = 'AudioBassBoostDown';
export const AudioBassBoostToggle = 'AudioBassBoostToggle';
export const AudioBassBoostUp = 'AudioBassBoostUp';
export const AudioFaderFront = 'AudioFaderFront';
export const AudioFaderRear = 'AudioFaderRear';
export const AudioSurroundModeNext = 'AudioSurroundModeNext';
export const AudioTrebleDown = 'AudioTrebleDown';
export const AudioTrebleUp = 'AudioTrebleUp';
export const AudioVolumeDown = 'AudioVolumeDown';
export const AudioVolumeUp = 'AudioVolumeUp';
export const AudioVolumeMute = 'AudioVolumeMute';
export const MicrophoneToggle = 'MicrophoneToggle';
export const MicrophoneVolumeDown = 'MicrophoneVolumeDown';
export const MicrophoneVolumeUp = 'MicrophoneVolumeUp';
export const MicrophoneVolumeMute = 'MicrophoneVolumeMute';
export const SpeechCorrectionList = 'SpeechCorrectionList';
export const SpeechInputToggle = 'SpeechInputToggle';
export const LaunchApplication1 = 'LaunchApplication1';
export const LaunchApplication2 = 'LaunchApplication2';
export const LaunchCalendar = 'LaunchCalendar';
export const LaunchContacts = 'LaunchContacts';
export const LaunchMail = 'LaunchMail';
export const LaunchMediaPlayer = 'LaunchMediaPlayer';
export const LaunchMusicPlayer = 'LaunchMusicPlayer';
export const LaunchPhone = 'LaunchPhone';
export const LaunchScreenSaver = 'LaunchScreenSaver';
export const LaunchSpreadsheet = 'LaunchSpreadsheet';
export const LaunchWebBrowser = 'LaunchWebBrowser';
export const LaunchWebCam = 'LaunchWebCam';
export const LaunchWordProcessor = 'LaunchWordProcessor';
export const BrowserBack = 'BrowserBack';
export const BrowserFavorites = 'BrowserFavorites';
export const BrowserForward = 'BrowserForward';
export const BrowserHome = 'BrowserHome';
export const BrowserRefresh = 'BrowserRefresh';
export const BrowserSearch = 'BrowserSearch';
export const BrowserStop = 'BrowserStop';
export const AppSwitch = 'AppSwitch';
export const Call = 'Call';
export const Camera = 'Camera';
export const CameraFocus = 'CameraFocus';
export const EndCall = 'EndCall';
export const GoBack = 'GoBack';
export const GoHome = 'GoHome';
export const HeadsetHook = 'HeadsetHook';
export const LastNumberRedial = 'LastNumberRedial';
export const Notification = 'Notification';
export const MannerMode = 'MannerMode';
export const VoiceDial = 'VoiceDial';
export const TV = 'TV';
export const TV3DMode = 'TV3DMode';
export const TVAntennaCable = 'TVAntennaCable';
export const TVAudioDescription = 'TVAudioDescription';
export const TVAudioDescriptionMixDown = 'TVAudioDescriptionMixDown';
export const TVAudioDescriptionMixUp = 'TVAudioDescriptionMixUp';
export const TVContentsMenu = 'TVContentsMenu';
export const TVDataService = 'TVDataService';
export const TVInput = 'TVInput';
export const TVInputComponent1 = 'TVInputComponent1';
export const TVInputComponent2 = 'TVInputComponent2';
export const TVInputComposite1 = 'TVInputComposite1';
export const TVInputComposite2 = 'TVInputComposite2';
export const TVInputHDMI1 = 'TVInputHDMI1';
export const TVInputHDMI2 = 'TVInputHDMI2';
export const TVInputHDMI3 = 'TVInputHDMI3';
export const TVInputHDMI4 = 'TVInputHDMI4';
export const TVInputVGA1 = 'TVInputVGA1';
export const TVMediaContext = 'TVMediaContext';
export const TVNetwork = 'TVNetwork';
export const TVNumberEntry = 'TVNumberEntry';
export const TVPower = 'TVPower';
export const TVRadioService = 'TVRadioService';
export const TVSatellite = 'TVSatellite';
export const TVSatelliteBS = 'TVSatelliteBS';
export const TVSatelliteCS = 'TVSatelliteCS';
export const TVSatelliteToggle = 'TVSatelliteToggle';
export const TVTerrestrialAnalog = 'TVTerrestrialAnalog';
export const TVTerrestrialDigital = 'TVTerrestrialDigital';
export const TVTimer = 'TVTimer';
export const AVRInput = 'AVRInput';
export const AVRPower = 'AVRPower';
export const ColorF0Red = 'ColorF0Red';
export const ColorF1Green = 'ColorF1Green';
export const ColorF2Yellow = 'ColorF2Yellow';
export const ColorF3Blue = 'ColorF3Blue';
export const ColorF4Grey = 'ColorF4Grey';
export const ColorF5Brown = 'ColorF5Brown';
export const ClosedCaptionToggle = 'ClosedCaptionToggle';
export const Dimmer = 'Dimmer';
export const DisplaySwap = 'DisplaySwap';
export const DVR = 'DVR';
export const Exit = 'Exit';
export const FavoriteClear0 = 'FavoriteClear0';
export const FavoriteClear1 = 'FavoriteClear1';
export const FavoriteClear2 = 'FavoriteClear2';
export const FavoriteClear3 = 'FavoriteClear3';
export const FavoriteRecall0 = 'FavoriteRecall0';
export const FavoriteRecall1 = 'FavoriteRecall1';
export const FavoriteRecall2 = 'FavoriteRecall2';
export const FavoriteRecall3 = 'FavoriteRecall3';
export const FavoriteStore0 = 'FavoriteStore0';
export const FavoriteStore1 = 'FavoriteStore1';
export const FavoriteStore2 = 'FavoriteStore2';
export const FavoriteStore3 = 'FavoriteStore3';
export const Guide = 'Guide';
export const GuideNextDay = 'GuideNextDay';
export const GuidePreviousDay = 'GuidePreviousDay';
export const Info = 'Info';
export const InstantReplay = 'InstantReplay';
export const Link = 'Link';
export const ListProgram = 'ListProgram';
export const LiveContent = 'LiveContent';
export const Lock = 'Lock';
export const MediaApps = 'MediaApps';
export const MediaAudioTrack = 'MediaAudioTrack';
export const MediaLast = 'MediaLast';
export const MediaSkipBackward = 'MediaSkipBackward';
export const MediaSkipForward = 'MediaSkipForward';
export const MediaStepBackward = 'MediaStepBackward';
export const MediaStepForward = 'MediaStepForward';
export const MediaTopMenu = 'MediaTopMenu';
export const NavigateIn = 'NavigateIn';
export const NavigateNext = 'NavigateNext';
export const NavigateOut = 'NavigateOut';
export const NavigatePrevious = 'NavigatePrevious';
export const NextFavoriteChannel = 'NextFavoriteChannel';
export const NextUserProfile = 'NextUserProfile';
export const OnDemand = 'OnDemand';
export const Pairing = 'Pairing';
export const PinPDown = 'PinPDown';
export const PinPMove = 'PinPMove';
export const PinPToggle = 'PinPToggle';
export const PinPUp = 'PinPUp';
export const PlaySpeedDown = 'PlaySpeedDown';
export const PlaySpeedReset = 'PlaySpeedReset';
export const PlaySpeedUp = 'PlaySpeedUp';
export const RandomToggle = 'RandomToggle';
export const RcLowBattery = 'RcLowBattery';
export const RecordSpeedNext = 'RecordSpeedNext';
export const RfBypass = 'RfBypass';
export const ScanChannelsToggle = 'ScanChannelsToggle';
export const ScreenModeNext = 'ScreenModeNext';
export const Settings = 'Settings';
export const SplitScreenToggle = 'SplitScreenToggle';
export const STBInput = 'STBInput';
export const STBPower = 'STBPower';
export const Subtitle = 'Subtitle';
export const Teletext = 'Teletext';
export const VideoModeNext = 'VideoModeNext';
export const Wink = 'Wink';
export const ZoomToggle = 'ZoomToggle';
export const MediaNextTrack = 'MediaNextTrack';
export const MediaPreviousTrack = 'MediaPreviousTrack';
export const Unidentified = 'Unidentified';
