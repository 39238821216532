.container {
    height: 100%;
    width: 100%;
    min-height: 220px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    container-type: size;
    container-name: svgLoader;
}

@container svgLoader (height > 420px) {
    .svgDivContainer {
        height: 200px;
        width: 200px;
        background-image: url('./crashLargeIcon.svg');
        margin-bottom: var(--spacingVerticalXXL);
    }
}

@container svgLoader (height <= 420px) and (height > 353px) {
    .svgDivContainer {
        height: 100px;
        width: 100px;
        background-image: url('./crashMediumIcon.svg');
        margin-bottom: var(--spacingVerticalXXL);
    }
}

@container svgLoader (height <= 350px) {
    .svgDivContainer {
        display: none;
    }
}

.title {
    padding-bottom: var(--spacingVerticalS);
}

.errorCode {
    padding-bottom: var(--spacingVerticalS);
}

.description {
    max-width: 420px;
    text-align: center !important;
    padding-bottom: var(--spacingVerticalS);
}

.reportButton {
    margin-top: var(--spacingVerticalXXL) !important;
}
