import React from 'react';

// Error boundary Props
interface ErrorBoundaryProps {
    fallback: React.ReactNode;
    onError?: () => void;
    logErrorToService?: (error: Error, componentStack: string) => void;
}
// Error boundary state
interface ErrorBoundaryState {
    hasError: boolean;
}

// Error boundary component
/**
 * Consumers may consider using @kusto/utils's ExceptionBoundary, which is more opinionated.
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        // Example "componentStack":
        //   in ComponentThatThrows (created by App)
        //   in ErrorBoundary (created by App)
        //   in div (created by App)
        //   in App
        this.props.logErrorToService?.(error, info.componentStack);
    }

    render() {
        if (this.state.hasError) {
            this.props.onError?.();

            // You can render any custom fallback UI
            return this.props.fallback;
        }

        return this.props.children;
    }
}
