.pillTooltip {
    width: 320px;
    padding: var(--spacingVerticalM) var(--spacingHorizontalSNudge);
    display: flex;
    flex-direction: column;
    gap: var(--spacingHorizontalL);

    .descriptionSection {
        display: flex;
        gap: var(--spacingHorizontalL);

        .descriptionIcon {
            align-self: center;
            font-size: 24px; // icon size
        }
        .descriptionText {
            flex: 1;
        }
    }

    .codeSection {
        display: flex;
        flex-direction: column;
        padding: var(--spacingVerticalM) var(--spacingHorizontalS);
        border: 1px solid var(--colorNeutralStroke2);
        border-radius: var(--borderRadiusXLarge);
        gap: var(--spacingHorizontalL);

        .codeSectionTitle {
            display: flex;

            .codeIcon {
                font-size: 16px; // icon size
                margin-right: var(--spacingHorizontalXS);
            }
        }
        .kqlCodeWrapper {
            height: 60px;
        }
    }
}
