import React from 'react';
import {
    Button,
    ButtonProps,
    Dialog,
    DialogActions,
    DialogBody,
    DialogBodyProps,
    DialogContent,
    DialogContentProps,
    DialogSurface,
    DialogTitle,
} from '@fluentui/react-components';

export interface DialogButton extends Omit<ButtonProps, 'onClick'> {
    /** Button text */
    text: string;
    /** Button click handler */
    onClick: () => void;
}

export interface DialogComponentProps {
    /** Dialog title */
    title: string;
    /** Dialog content */
    content: string | JSX.Element;
    /** Optional props for the DialogContent sub-component  */
    contentProps?: DialogContentProps;
    /** Optional props for the DialogBody  */
    bodyProps?: DialogBodyProps;
    /** Dialog buttons */
    buttons: DialogButton[];
}

export const DialogComponent: React.FunctionComponent<DialogComponentProps> = (props) => {
    return (
        <Dialog open>
            <DialogSurface>
                <DialogBody {...props.bodyProps}>
                    <DialogTitle>{props.title}</DialogTitle>
                    <DialogContent {...props.contentProps}>{props.content}</DialogContent>
                    <DialogActions>
                        {props.buttons?.map((button, index) => (
                            // @ts-expect-error - TS doesn't like `button.onClick` signature, but it's correct
                            <Button key={index} {...button}>
                                {button.text}
                            </Button>
                        ))}
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
