import isPlainObject from 'lodash/isPlainObject';

import { err, ok, Result, Theme } from '@kusto/utils';
import { getChartColors } from '@kusto/visualizations';

import type { RtdProviderLocale } from '../../../i18n';
import { darkThemeTemplate } from '../darkTheme';

/**
 * @see "self" https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy/Sources#self
 */
const DEFAULT_ALLOWLIST: string[] = [`'self'`, 'data:'];

function createCSPMetaTag(allowlist: readonly string[]): { nonce: string; cspMetaTag: string } {
    const whitelist: string[] = [...DEFAULT_ALLOWLIST, ...allowlist];
    const nonce = crypto.randomUUID();
    const nonceStr = `'nonce-${nonce}'`;
    const cspContent = `default-src 'self'; script-src ${nonceStr}; style-src 'unsafe-inline'; img-src ${whitelist.join(
        ' '
    )}`;

    return {
        nonce,
        cspMetaTag: `<meta http-equiv="Content-Security-Policy" content="${cspContent}" />`,
    };
}

type ObjectLike = Record<string, unknown>;

interface DataLayoutConfig<
    Data extends ObjectLike = ObjectLike,
    Layout extends ObjectLike = ObjectLike,
    Config extends ObjectLike = ObjectLike
> {
    data: Data[];
    layout?: Layout;
    config?: Config;
}

export function getFormattedDLC(
    sdlc: string,
    isDarkTheme: boolean
): Result<DataLayoutConfig, (t: RtdProviderLocale) => string> {
    let maybeDLC: unknown;

    try {
        maybeDLC = JSON.parse(sdlc);
    } catch (e) {
        const error = e;
        /**
         * JSON Parse errors should always be SyntaxError
         * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/JSON_bad_parse
         */
        if (!(error instanceof SyntaxError)) {
            throw error;
        }

        // This will always be a JSON parse error that's 100% the fault
        // of the customer because they didn't pass in a valid JSON string
        // since `sdlc` is 100% user input and not controlled by us.
        // Trying to log telemetry on this error would just create a lot
        // of noise. The best thing is just to inform the customer that
        // they should try again with proper format.
        return err((t: RtdProviderLocale) => `${t.rtdProvider.visuals.plotly.errors.jsonParse}\n\n${error.message}`);
    }

    if (!isPlainObject(maybeDLC)) {
        return err((t: RtdProviderLocale) => t.rtdProvider.visuals.plotly.errors.notJsonParsedAsObject);
    }

    const plotlyDLC = maybeDLC as DataLayoutConfig;
    plotlyDLC.layout = {
        ...plotlyDLC.layout,
        template: isDarkTheme ? darkThemeTemplate : undefined,
        colorway: getChartColors(isDarkTheme ? Theme.Dark : Theme.Light).colors,
    };

    return ok(plotlyDLC);
}

export function createSrcDoc(
    /**
     * e.g. "${PUBLIC_URL}/plotly/v1.js"
     */
    publicRtdPlotlyUrl: string,
    allowlist: readonly string[],
    securePlotlyIframe: boolean
): string {
    const { nonce, cspMetaTag } = securePlotlyIframe ? createCSPMetaTag(allowlist) : { nonce: '', cspMetaTag: '' };

    return `
        <!DOCTYPE html>
        <html>
            <head>
                ${cspMetaTag}
                <style type="text/css" nonce="${nonce}">
                    html, body {
                        width: 100%;
                        height: 100%;
                        padding: 0;
                        margin: 0;
                        box-sizing: border-box;
                    }

                    #plotly-root {
                        height: 100%
                    }
                </style>
            </head>
            <body>
                <div id="plotly-root"></div>
                <script src="${publicRtdPlotlyUrl}" nonce="${nonce}" type="module"></script>
            </body>
        </html>
    `;
}
