import React from 'react';
import { CompoundButton } from '@fluentui/react-components';

import { Icon } from '../Icon';

import styles from './WelcomeTile.module.scss';

export interface WelcomeTileProps {
    /** Icon to display */
    icon: Icon;
    /** Label to display */
    label: string;
    /** Whether the tile is disabled */
    disabled?: boolean;
    /** Callback to call when the tile is clicked */
    onClick: () => void;
}

/** A tile in the welcome state */
export const WelcomeTile: React.FunctionComponent<WelcomeTileProps> = ({ icon: Icon, label, disabled, onClick }) => (
    <CompoundButton className={styles.card} disabled={disabled} onClick={onClick}>
        <div className={styles.iconContainer}>
            <Icon className={styles.icon} />
        </div>
        <div className={styles.buttonText}>{label}</div>
    </CompoundButton>
);
