import { Instance, types } from 'mobx-state-tree';

//
// URL spec: https://url.spec.whatwg.org/
//

const FILE_ALLOWLIST_MAGIC_STRING = 'file://';

/**
 * @see {@link https://url.spec.whatwg.org/#special-scheme}
 */
const SPECIAL_PROTOCOLS = { 'ftp:': 21, 'http:': 80, 'https:': 443, 'ws:': 80, 'wss:': 443 } as const;

/**
 * Special scheme Url to a normalized origin string.
 *
 * @example https://example.com/test becomes https:://example.com:443
 */
export function urlToAllowlistString(url: string | URL | Location): undefined | string {
    if (typeof url === 'string') {
        url = new URL(url, window.location.href);
    }

    if (url.protocol === 'file:') {
        return FILE_ALLOWLIST_MAGIC_STRING;
    }

    if (url.protocol in SPECIAL_PROTOCOLS) {
        return (
            url.protocol +
            '//' +
            url.hostname +
            ':' +
            (url.port || SPECIAL_PROTOCOLS[url.protocol as keyof typeof SPECIAL_PROTOCOLS])
        );
    }

    return undefined;
}

function isQueryLinkTrusted(url: string | URL | Location, originAllowlist: Set<string>): boolean {
    const allowlistString = urlToAllowlistString(url);
    if (allowlistString) {
        return originAllowlist.has(allowlistString);
    }
    return true;
}

/**
 * https://www.ietf.org/rfc/rfc2396.txt
 *
 * > 3.1. Scheme Component
 * > ...
 * > scheme = alpha *( alpha | digit | "+" | "-" | "." )
 */
const HAS_SCHEME_REGEX = /^[a-zA-Z][a-zA-Z\d+\-.]*:/;

export type IOriginAllowlist = Instance<typeof OriginAllowlist>;

/**
 *  Contains origin allowlist for query content. Currently it applies to images
 *  and links. If a link in a query result is in this allow list, then users
 *  won't be prompted before opening it. Images in dashboards query derived
 *  markdown are not rendered if they are not in this.
 */
export const OriginAllowlist = types
    .model('OriginAllowlist', {
        allowlist: types.string,
    })
    .views((self) => ({
        get allowlistParsed() {
            const valid = new Set<string>([urlToAllowlistString(window.location)!]);
            const invalid = new Set<string>();
            for (const text of self.allowlist.split(';')) {
                if (text.trim() === FILE_ALLOWLIST_MAGIC_STRING) {
                    valid.add(FILE_ALLOWLIST_MAGIC_STRING);
                    continue;
                }
                // If user doesn't include a protocol default to https
                const withProtocol = HAS_SCHEME_REGEX.test(text) ? text : 'https://' + text;
                let url: URL;
                try {
                    url = new URL(withProtocol, window.location.href);
                } catch (e) {
                    invalid.add(text);
                    continue;
                }

                if (url.pathname !== '/') {
                    invalid.add(text);
                    continue;
                }

                const parsed = urlToAllowlistString(url);
                if (parsed) {
                    valid.add(parsed);
                } else {
                    invalid.add(text);
                }
            }
            return { valid, invalid };
        },
    }))
    .actions((self) => ({
        set(text: string) {
            self.allowlist = text;
        },
        add(...domains: Array<string | URL | Location>) {
            // Access `self.parsed` outside here so the
            // Sets for `valid` and `invalid` only need
            // to be created just once
            const validDomains = self.allowlistParsed.valid;

            const nextAllowlist = domains
                .map(urlToAllowlistString)
                .filter((item): item is string => !!item && !validDomains.has(item));

            if (nextAllowlist.length) {
                self.allowlist = self.allowlist.trim()
                    ? `${self.allowlist};${nextAllowlist.join(';')}`
                    : nextAllowlist.join(';');
            }
        },
        isAllowlisted(url: string | URL | Location) {
            return isQueryLinkTrusted(url, self.allowlistParsed.valid);
        },
    }));
