import { ImmutableSet } from './ImmutableSet';
export function createOpenItems(iterable) {
    if (iterable === undefined) {
        return ImmutableSet.empty;
    }
    if (ImmutableSet.isImmutableSet(iterable)) {
        return iterable;
    }
    return ImmutableSet.create(iterable);
}
