import type { AppPage } from '../common/AppPages';

export function getPath(page: AppPage) {
    return `/${page}`;
}

/**
 * Appends a trailing slash to the end of the provided string if none is present
 */
export function appendSlashIfNecessary(input: string): string {
    if (input.length < 1) {
        return input;
    }

    return input[input.length - 1] !== '/' ? `${input}/` : input;
}
