import React from 'react';

import { useComputed } from '@kusto/utils';

import type { KweRtdVisualContext } from '../../../context';

export type AddKind = 'permanent' | 'temporary';
export type AddToOriginAllowlist = (kind: AddKind, valuesToAdd: string[]) => void;

export function useOriginAllowlist(ctx: KweRtdVisualContext): {
    originAllowlist: readonly string[];
    addToOriginAllowlist: AddToOriginAllowlist;
} {
    const [tempOriginAllowlist, setTempOriginAllowlist] = React.useState<string[]>([]);

    const addToOriginAllowlist = React.useCallback<AddToOriginAllowlist>(
        (kind, valuesToAdd) => {
            switch (kind) {
                case 'permanent': {
                    ctx.originAllowlist.add(...valuesToAdd);
                    break;
                }
                case 'temporary': {
                    setTempOriginAllowlist((prevValues) => {
                        const nextValues = Array.from(new Set([...prevValues, ...valuesToAdd]));
                        return nextValues;
                    });
                    break;
                }
            }
        },
        [ctx.originAllowlist]
    );

    const originAllowlist = useComputed<readonly string[]>(() => {
        const userProfileAllowlist = ctx.originAllowlist.allowlistParsed.valid;
        return Array.from(new Set([...userProfileAllowlist, ...tempOriginAllowlist]));
    }, [ctx.originAllowlist.allowlistParsed, tempOriginAllowlist]);

    return { originAllowlist: originAllowlist, addToOriginAllowlist };
}
