import React from 'react';

import type { KweUtilsLocale } from '../../types';
import { KweException } from '../../utils/errors';

const rtdDropdownCtx = React.createContext<KweUtilsLocale | undefined>(undefined);

export const RtdDropdownStringsProvider: React.FC<{ value: KweUtilsLocale }> = ({ value, children }) => {
    return <rtdDropdownCtx.Provider value={value}>{children}</rtdDropdownCtx.Provider>;
};

export function useRtdProviderStrings(): KweUtilsLocale {
    const strings = React.useContext(rtdDropdownCtx);
    if (!strings) {
        throw new KweException('useRtdProviderStrings must be used within a RtdDropdownStringsProvider');
    }
    return strings;
}
