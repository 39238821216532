import { createContext, useContextSelector } from '@fluentui/react-context-selector';
export const ToolbarContext = createContext(undefined);
const toolbarContextDefaultValue = {
    size: 'medium',
    handleToggleButton: ()=>null,
    handleRadio: ()=>null,
    vertical: false,
    checkedValues: {}
};
export const useToolbarContext_unstable = (selector)=>useContextSelector(ToolbarContext, (ctx = toolbarContextDefaultValue)=>selector(ctx));
