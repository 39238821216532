import { editor } from 'monaco-editor/esm/vs/editor/editor.api';
export function getRangeHtml(model, range) {
    var startLineNumber = range.startLineNumber, endLineNumber = range.endLineNumber, endColumn = range.endColumn;
    var isLastLineEmpty = endColumn === 1;
    var actualLastLine = isLastLineEmpty ? endLineNumber - 1 : endLineNumber;
    var totalLines = actualLastLine - startLineNumber + 1;
    var colorizedLines = new Array(totalLines)
        .fill(undefined)
        .map(function (_, index) { return editor.colorizeModelLine(model, startLineNumber + index); });
    return colorizedLines.join('<br/>');
}
