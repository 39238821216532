.tabsBarContainer {
    display: flex;
    padding: var(--spacingVerticalSNudge) var(--spacingHorizontalS);
    border-bottom: 1px solid var(--colorNeutralStroke2);
    justify-content: space-between;
    box-sizing: border-box;

    .tabsListContainer {
        display: flex;
        overflow: hidden;

        .addButton {
            display: flex;
            width: 32px;
        }
    }

    // scroll-bar - webkit Chrome/Edge
    ::-webkit-scrollbar {
        height: 4px;
    }
    ::-webkit-scrollbar-track {
        background: var(--colorSubtleBackgroundInvertedHover);
    }
    ::-webkit-scrollbar-thumb {
        background: var(--colorNeutralForeground2BrandHover);
        border-radius: 2px;
    }

    // This will apply to Firefox only
    @-moz-document url-prefix() {
        // scroll-bar Firefox
        scrollbar-color: var(--colorNeutralForeground2BrandHover) var(--colorSubtleBackgroundInvertedHover);
        scrollbar-width: thin;
    }
}
