.parent {
    width: 100%;
    height: 100%;
}

.iframe {
    width: 100%;
    height: 100%;
    border: none;
    display: block;
    box-sizing: border-box;
    background-color: transparent;
}

.invisible {
    visibility: hidden;
}

.grayText {
    color: var(--neutralSecondary);
}

.cspUrl {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--green);
    text-decoration: underline;
}

.leading {
    margin-bottom: 8px;
}

.leadingText {
    margin-left: 8px;
}

.promptContainer {
    max-width: 500px;
    height: 100%;
    margin: auto;
}

.buttonRow {
    button:not(:first-child) {
        margin-left: 16px;
    }
}

.helperText {
    margin-top: 8px;
}
