import React from 'react';

import * as kusto from '@kusto/client';
import { DataFrame, formatLiterals, KustoDataType, KustoNumericType, KweException, UField } from '@kusto/utils';
import { VisualMessageFormatter } from '@kusto/visual-fwk';

import { VisualizationsStrings } from '../../types';
import { getConditionalFormattingOptions } from '../../utils/conditionalFormatting/conditionalFormatting';
import { getFirstFieldOfType } from '../../utils/heuristics';
import { ExtendedVisualizationOptions } from '../../utils/visualization';
import { ConditionalFormattingProps, MultiStatCard } from './MultiStatCard';
import { StatCard } from './SingleStatCard';

/** "Series" is for heuristics only */
export type StatVisualOptions = Pick<
    ExtendedVisualizationOptions,
    'Visualization' | 'XColumn' | 'YColumns' | 'TextSize' | 'ColumnFormatting' | 'Width' | 'Height' | 'Series'
>;

export interface StatCardChartProps {
    /**
     * Kusto visualization options as supported by Kusto render command
     */
    visualizationOptions: StatVisualOptions;
    dataFrame: DataFrame;

    strings: VisualizationsStrings;
    locale: undefined | string;
    timezone: string;

    setResultCounts?: (info: { displayedResults: number; totalResults: number } | null) => void;

    formatMessage: VisualMessageFormatter;
}

const STRING_DATA_TYPE: KustoDataType[] = ['string'];

// TODO: datetime and timespan are also numeric types but this can't be changed
// because it would change visualization behavior
const SUPPORTED_NUMERIC_DATA_TYPES: KustoNumericType[] = ['int', 'real', 'long', 'decimal'];

export interface StatHeuristicsResult {
    valueField: undefined | UField;
    labelField: undefined | UField;

    // These should really break hard errors, but doing so would be a breaking change
    /**
     * If missing, set to the name of the column
     */
    labelColumnMissing: undefined | string;
    /**
     * If missing, set to the name of the column
     */
    valueColumnMissing: undefined | string;
}

/**
 * Enrich visualization option with heuristic axis and series column selection.
 * This method updates visualization options in-place for perf reasons.
 */
export function inferStatColumns(
    vo: Pick<ExtendedVisualizationOptions, 'XColumn' | 'YColumns' | 'Series' | 'Visualization'>,
    dataFrame: DataFrame
): StatHeuristicsResult {
    const takenColumns: string[] = [];
    let valueColumnMissing: undefined | string;
    let labelColumnMissing: undefined | string;

    // In the edge case where user specified the series column in visualization
    // options (via render command), previous behavior was - this column can't
    // be used for heuristics. new behavior - series column can be used for
    // heuristics.
    //
    // The real solution is to prevent this query from even compiling (language service should be tighter).
    if (vo.Series) {
        takenColumns.push(...vo.Series);
    }

    let valueField: undefined | UField;

    if (vo.YColumns !== null && vo.YColumns.length !== 0) {
        const name = vo.YColumns[0];
        valueField = dataFrame.fields.find((column) => column.name === name);
        if (!valueField) {
            valueColumnMissing = name;
        } else {
            takenColumns.push(valueField.name);
        }
    }

    let labelField: undefined | UField;

    if (vo.XColumn !== null) {
        labelField = dataFrame.fields.find((column) => column.name === vo.XColumn);

        if (!labelField) {
            labelColumnMissing = vo.XColumn;
        } else {
            takenColumns.push(labelField.name);
        }
    }

    if (!valueField) {
        valueField = getFirstFieldOfType(dataFrame, [SUPPORTED_NUMERIC_DATA_TYPES, STRING_DATA_TYPE], takenColumns);
        if (valueField) {
            takenColumns.push(valueField.name);
        }
    }

    // Card visual doesn't have a label
    if (!labelField && vo.Visualization === 'multistat') {
        labelField = getFirstFieldOfType(dataFrame, [STRING_DATA_TYPE], takenColumns);
    }

    return { valueField, labelField, valueColumnMissing, labelColumnMissing };
}

/**
 * Renders all components that don't have native support in desktop explorer.
 * @param props rows, columns and options needed to render the visualization
 *
 * figma: https://www.figma.com/file/oqmKKJVwc4rZgivntf568F/ADX-Dashboards-BUILD-Handoff?node-id=1993%3A24129&viewport=-6696%2C-11587%2C1.3175917863845825
 */
export const StatCardChart = ({
    visualizationOptions,
    dataFrame,
    setResultCounts,
    strings,
    timezone,
    formatMessage,
    locale,
}: StatCardChartProps) => {
    const numberOfColumns = visualizationOptions.Width;
    const numberOfRows = visualizationOptions.Height;

    React.useLayoutEffect(() => {
        if (numberOfColumns && numberOfRows && setResultCounts) {
            setResultCounts(
                numberOfColumns * numberOfRows < dataFrame.size
                    ? { displayedResults: numberOfColumns * numberOfRows, totalResults: dataFrame.size }
                    : null
            );
            return () => setResultCounts(null);
        }
        return;
    }, [
        dataFrame.size,
        visualizationOptions.Width,
        visualizationOptions.Height,
        numberOfColumns,
        numberOfRows,
        setResultCounts,
    ]);

    const columnTypeByName: Record<string, KustoDataType> = React.useMemo(() => {
        const map: Record<string, KustoDataType> = {};
        dataFrame.fields.forEach((field) => {
            map[field.name] = field.type;
        });
        return map;
    }, [dataFrame]);
    const { valueField, labelField } = inferStatColumns(visualizationOptions, dataFrame);

    const vis = visualizationOptions.Visualization;

    if (vis === null) {
        throw new KweException('Missing visual type');
    }

    if (!valueField) {
        return formatMessage({
            message: formatLiterals(strings.errors.noDraw, {
                visualizationType: vis,
            }),
            level: 'error',
        });
    }
    if (dataFrame.size === 0) {
        return formatMessage({
            title: strings.errors.noResult.title,
            message: strings.errors.noResult.message,
            level: 'info',
            options: { icon: 'FolderList' },
        });
    }

    switch (vis) {
        case 'card':
            const rowIndex = dataFrame.size - 1;
            const conditionalFormattingOptions = getConditionalFormattingOptions({
                row: kusto.queryAreaRowObjectFromDataFrame(dataFrame, rowIndex),
                columnsToType: columnTypeByName,
                applyTo: 'cells',
                conditionalFormattingOptions: visualizationOptions.ColumnFormatting?.ConditionalFormattingConfig,
                inferredColumnName: valueField.name,
            });
            return (
                <StatCard
                    locale={locale}
                    timeZone={timezone}
                    valueField={valueField}
                    rowIndex={rowIndex}
                    textSize={visualizationOptions.TextSize ?? 'auto'}
                    {...conditionalFormattingOptions}
                />
            );

        case 'multistat': {
            const conditionalFormattingProps: ConditionalFormattingProps[] = [];

            for (let i = 0; i < Math.min(numberOfColumns! * numberOfRows!, dataFrame.size); i++) {
                const formattingOptions = getConditionalFormattingOptions({
                    row: kusto.queryAreaRowObjectFromDataFrame(dataFrame, i),
                    columnsToType: columnTypeByName,
                    applyTo: 'cells',
                    conditionalFormattingOptions: visualizationOptions.ColumnFormatting?.ConditionalFormattingConfig,
                    inferredColumnName: valueField.name,
                });
                conditionalFormattingProps.push(formattingOptions);
            }

            return (
                <MultiStatCard
                    textSize={visualizationOptions.TextSize ?? 'auto'}
                    numberOfColumns={numberOfColumns!}
                    numberOfRows={numberOfRows!}
                    valueField={valueField}
                    labelField={labelField}
                    conditionalFormattingProps={conditionalFormattingProps}
                    locale={locale}
                    timeZone={timezone}
                />
            );
        }
        default:
            throw new KweException(`Unsupported visual type ${vis}`);
    }
};
