import React from 'react';
import { Body1 } from '@fluentui/react-components';
import { DatabaseWarning20Regular, ErrorCircle20Regular } from '@fluentui/react-icons';

import * as styles from './styles.module.scss';

interface HistogramErrViewProps {
    msg: string;
    level: 'warn' | 'error';
}

//TODO replace with message bar component when available
export const HistogramErrView: React.FC<HistogramErrViewProps> = ({ msg, level }) => {
    return (
        <div className={styles.errMsg}>
            {level === 'error' ? <ErrorCircle20Regular /> : <DatabaseWarning20Regular />}
            <Body1>{msg}</Body1>
        </div>
    );
};
