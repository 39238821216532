import { JPathArrayRepresentation } from './types';

type lineJsonPathMap = JPathArrayRepresentation[];

/**
 * map each line to its json path (array representation)
 * @param modelValue json object string representation
 * @returns object where the keys are line numbers and the values are the equivalent json path as an array representation
 */
export function buildJsonPathForEachLine(modelValue: string): lineJsonPathMap {
    const result: lineJsonPathMap = [];
    let object;

    try {
        object = JSON.parse(modelValue);
    } catch (_e) {
        object = null;
    }

    if (object) {
        const rootPath = ['']; // Editor lines starts from 1
        result[0] = rootPath; // Init result object
        buildJsonPathForEachLineHelper(object, result, rootPath);
    }

    return result;
}

/**
 * convert json path array representation to a valid JPath string
 * @param jpath array representation
 * @returns a valid JPath string
 */
export function convertJPathArrayRepresentationToString(jpath: JPathArrayRepresentation): string {
    return jpath?.length > 0
        ? jpath
              .map((item, index) => {
                  if (index === 0) {
                      return item;
                  } else if (typeof item === 'number') {
                      return `[${item}]`;
                  } else {
                      return `['${item}']`;
                  }
              })
              .join('')
        : '';
}

function buildJsonPathForEachLineHelper(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    object: any,
    result: lineJsonPathMap,
    path: JPathArrayRepresentation
): void {
    if (Array.isArray(object)) {
        object.forEach((item, index) => {
            const pathToLine = [...path, index];
            result[getNextFreeLineNumber(result)] = pathToLine;
            buildJsonPathForEachLineHelper(item, result, pathToLine);
        });
        if (object.length) {
            result[getNextFreeLineNumber(result)] = path; // Array's parent closing clause
        }
    } else if (object && typeof object === 'object') {
        Object.keys(object).forEach((key) => {
            const pathToLine = [...path, key];
            result[getNextFreeLineNumber(result)] = pathToLine;
            buildJsonPathForEachLineHelper(object[key], result, pathToLine);
        });
        if (Object.keys(object).length) {
            result[getNextFreeLineNumber(result)] = path; // Object's parent closing clause
        }
    }
}

function getNextFreeLineNumber(object: lineJsonPathMap): number {
    return Object.keys(object).length;
}
