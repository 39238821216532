export const RTD_DROPDOWN = {
    /**
     * Default Fabric dropdown row height. Always used unless changed via CSS
     */
    rowHeight: 36,
    minFilterSize: 10,

    rtdInternalPrefix: '__rtd_internal',
    emptySelection: '__rtd_internal_empty_selection',
    addSelection: '__rtd_internal_add_selection',
    divider: '__rtd_internal_divider',
    parameterAllSelection: '__rtd_internal_all_selection' as const,
};
