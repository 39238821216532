import React from 'react';

interface ResizeObserverOptions<T extends Element> {
    ref?: React.MutableRefObject<T | null>;
}

/*
API inspired by https://github.com/ZeeCoder/use-resize-observer#readme
 but with much less functionality. we can choose to install it in the future for the full set of features
*/
export function useResizeObserver<T extends Element>(options?: ResizeObserverOptions<T>) {
    const defaultRef = React.useRef<T>(null);
    const [width, setWidth] = React.useState<number>();
    const [height, setHeight] = React.useState<number>();

    const refToUse = options?.ref ?? defaultRef;

    React.useLayoutEffect(() => {
        if (!refToUse?.current) {
            return;
        }

        const ob = new ResizeObserver(([ref]) => {
            setWidth(ref.contentRect.width);
            setHeight(ref.contentRect.height);
        });

        ob.observe(refToUse.current);

        return () => ob.disconnect();
    }, [refToUse]);

    return { ref: refToUse, height, width };
}
