import React, { useState } from 'react';
import { MessageBar, MessageBarType, Toggle } from '@fluentui/react';
import { Button, ButtonProps, Field, Input, InputOnChangeData } from '@fluentui/react-components';
import { Add20Regular } from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';

import {
    DropdownWithSearch,
    DropdownWithSearchOption,
    DropdownWithSearchProps,
    SelectedOption,
} from '@kusto/ui-components';
import { formatLiterals, KweException, useComputed } from '@kusto/utils';

import type { RtdPage } from '../../dashboardApi';
import type { InteractionTarget } from '../../interactions/target';
import { interactionErrorMessage, InteractionErrorMessage } from '../../interactions/target';
import type { KweVisualFwkLocale } from '../../types';
import type { DrillthroughConfig, DrillthroughPair } from '../../visualOptions';
import { crossFilterTypeWarning } from '../CrossFilter/lib';
import { DropdownOptionText, InteractiveDropdownOption } from '../interactive';
import { PropertiesIndex } from './Drillthrough';
import { getResolvedSelections, ResolvedSelections, useResolvedSelectionsMap } from './lib';
import { DrillthroughVisual } from './VisualInteraction';

import * as configurationListStyles from '../configurationList/styles.module.scss';
import * as inputLibStyle from '../styles.module.scss';
import * as styles from './styles.module.scss';

// #region Shared drillthrough code

const NOT_FOUND_OPTION_KEY = 'not_found';

type DrillthroughPropertyOption = InteractiveDropdownOption<{
    readonly interaction: DrillthroughVisual.Available;
    readonly property: DrillthroughVisual.Property;
}>;

/**
 * Note: no support for multi select dropdowns
 */
const onDropdownRenderTitle: DropdownWithSearchProps['renderTitle'] = (selected) => {
    if (selected === undefined || selected.length === 0) {
        throw new KweException('Unable to determine visual selected dropdown selection');
    }

    const option = selected[0] as InteractiveDropdownOption;
    return <DropdownOptionText option={option} />;
};

const onDropdownRenderOption: DropdownWithSearchProps['renderOption'] = (option) => {
    if (option === undefined) {
        throw new KweException();
    }
    const casted = option as InteractiveDropdownOption;
    return <DropdownOptionText option={casted} />;
};
// #endregion

// #region PropertyDropdown

function propertyOptionText(
    t: KweVisualFwkLocale,
    displayName: string,
    property: DrillthroughVisual.Property,
    selectedParameter: undefined | InteractionTarget
): Partial<DrillthroughPropertyOption> {
    let warning: undefined | InteractionErrorMessage;
    let warningTitle: undefined | string;
    if (property.kind === 'available') {
        if (selectedParameter) {
            const res = selectedParameter.canApply(property.dataTypes);
            if (res.kind !== 'ok') {
                // Details are omitted to save space
                warning = interactionErrorMessage(
                    () =>
                        t.visualFwk.visualConfig.interactions.drillthrough.property
                            .propertyParameterTypeMismatchMessage,
                    res.kind
                );
                warningTitle = res.errors.map((e) => e.text()).join(', ');
            }
        }
    } else {
        warning = interactionErrorMessage(() => property.unavailableReason);
        warningTitle = property.unavailableReason;
    }

    let typeText: string;
    if (property.dataTypes === undefined) {
        typeText = t.visualFwk.value.unavailable;
    } else if (property.dataTypes.length === 0) {
        typeText = t.visualFwk.value.any;
    } else {
        typeText = property.dataTypes.join(', ');
    }

    const warningText = warningTitle ? ' \n\n' + warningTitle : '';

    return { title: `${displayName} (${typeText})${warningText}`, warning, typeText };
}

function propertyToOptionKey(id: string) {
    return `s--${id}`;
}

function newPropertyOption(
    t: KweVisualFwkLocale,
    interaction: DrillthroughVisual.Available,
    property: DrillthroughVisual.Property,
    selectedParameter: undefined | InteractionTarget
): DrillthroughPropertyOption {
    return {
        key: propertyToOptionKey(property.id),
        text: property.displayName ?? property.id,
        data: { interaction, property },
        ...propertyOptionText(t, property.displayName ?? property.id, property, selectedParameter),
    };
}

function getPropertyErrorMessage(
    t: KweVisualFwkLocale,
    pair: DrillthroughPair,
    selectedInteraction: DrillthroughVisual.Available,
    selectedProperty: undefined | DrillthroughVisual.Property
): undefined | string {
    const propertiesName =
        selectedInteraction.propertiesDisplayName ??
        t.visualFwk.visualConfig.interactions.drillthrough.property.defaultDropdownLabel;
    if (pair.property === undefined) {
        return undefined;
    }
    if (selectedProperty === undefined) {
        return formatLiterals(t.visualFwk.visualConfig.interactions.drillthrough.property.notFoundError, {
            propertiesName,
        });
    }
    if (selectedProperty.kind === 'unavailable') {
        return formatLiterals(t.visualFwk.visualConfig.interactions.drillthrough.property.unavailableErrorPrefix, {
            message: selectedProperty.unavailableReason,
        });
    }
    return undefined;
}

interface PropertyDropdownProps extends DrillthroughFormProps {
    selectedInteraction: DrillthroughVisual.Available;
    selectedProperty: DrillthroughVisual.Property | undefined;
    selectedParameter: InteractionTarget | undefined;
    pair: DrillthroughPair;
    onPairChange: (nextPair: DrillthroughPair) => void;
}

const PropertyDropdown: React.FC<PropertyDropdownProps> = observer(function PropertyDropdown({
    t,
    pair,
    onPairChange,
    disabled,
    selectedInteraction,
    selectedProperty,
    selectedParameter,
}) {
    const propertyOptions = useComputed((): InteractiveDropdownOption[] => {
        const unavailableOption: InteractiveDropdownOption = {
            key: NOT_FOUND_OPTION_KEY,
            text: t.visualFwk.visualConfig.interactions.drillthrough.notFoundOptionText,
            type: 'hidden',
        };

        if (!selectedInteraction?.properties) {
            return [unavailableOption];
        }
        return [
            unavailableOption,
            ...selectedInteraction.properties.map((p) =>
                newPropertyOption(t, selectedInteraction, p, selectedParameter)
            ),
        ];
    }, [t, selectedInteraction, selectedParameter]);

    const onPropertyChange = React.useCallback(
        (_event: unknown, option: SelectedOption) => {
            const data = (option as DrillthroughPropertyOption).data!;
            onPairChange({ ...pair, property: data.property.id });
        },
        [pair, onPairChange]
    );

    return (
        <DropdownWithSearch
            className={inputLibStyle.basicInput}
            label={selectedInteraction.propertiesDisplayName}
            disabled={disabled}
            selectedKeys={
                selectedProperty
                    ? propertyToOptionKey(selectedProperty.id)
                    : pair.property
                    ? NOT_FOUND_OPTION_KEY
                    : null
            }
            options={propertyOptions}
            onChange={onPropertyChange}
            validationMessage={getPropertyErrorMessage(t, pair, selectedInteraction, selectedProperty)}
            renderTitle={onDropdownRenderTitle}
            renderOption={onDropdownRenderOption}
        />
    );
});
// #endregion

// #region ParameterDropdown

function parameterIdToOptionKey(id: string) {
    return `p--${id}`;
}

interface ParameterDropdownProps extends DrillthroughFormProps {
    selections: ResolvedSelections;
    pair: DrillthroughPair;
    onPairChange: (nextPair: DrillthroughPair) => void;
}

const ParameterDropdown: React.FC<ParameterDropdownProps> = observer(function ParameterDropdown({
    t,
    interaction,
    parameters,
    disabled,
    selections,
    pair,
    onPairChange,
}) {
    const parameterUnavailable = pair.parameterId !== undefined && selections.parameter === undefined;

    const parameterOptions = useComputed(
        (): InteractiveDropdownOption[] => [
            {
                key: NOT_FOUND_OPTION_KEY,
                text: t.visualFwk.visualConfig.interactions.drillthrough.parameter.configuredParameterDeletedText,
                type: 'hidden',
            },
            ...parameters.map((p) => {
                const warning = crossFilterTypeWarning(t, {
                    property: selections.property,
                    interaction,
                    parameter: p,
                });

                const typeText = p.typeDisplayName(t);
                const warningText = warning ? ' \n\n' + warning.text() : '';

                return {
                    key: parameterIdToOptionKey(p.id),
                    text: p.displayName,
                    warning,
                    data: p.id,
                    typeText,
                    title: `${p.displayName} (${typeText})${warningText}`,
                };
            }),
        ],
        [t, parameters, selections, interaction]
    );

    const onParameterChange = React.useCallback(
        (_event: unknown, option: SelectedOption) => {
            const parameterId = option.data as string;
            onPairChange({ ...pair, parameterId });
        },
        [pair, onPairChange]
    );

    return (
        <DropdownWithSearch
            className={inputLibStyle.basicInput}
            label={t.visualFwk.visualConfig.interactions.drillthrough.parameter.dropdownLabel}
            disabled={disabled}
            // Right now we have no way to format unavailable parameters, so don't select anything
            selectedKeys={
                parameterUnavailable
                    ? NOT_FOUND_OPTION_KEY
                    : pair.parameterId
                    ? parameterIdToOptionKey(pair.parameterId)
                    : null
            }
            options={parameterOptions}
            onChange={onParameterChange}
            validationMessage={
                parameterUnavailable
                    ? t.visualFwk.visualConfig.interactions.drillthrough.parameter.unavailableError
                    : undefined
            }
            renderTitle={onDropdownRenderTitle}
            renderOption={onDropdownRenderOption}
        />
    );
});
// #endregion

// #region DestinationPages
const selectAllOptionKey = 'all' as const;

const onDestinationPagesDropdownRenderTitle: DropdownWithSearchProps['renderTitle'] = (options, defaultRender) => {
    const newOptions = options.filter((o) => o.key !== selectAllOptionKey);
    return defaultRender(newOptions);
};

function pageIdToOptionKey(id: string) {
    return `page--${id}`;
}

function optionKeyToPageId(optionKey: string) {
    return optionKey.replace('page--', '');
}

export const DestinationPages: React.FC<DrillthroughFormProps> = function DestinationPages({
    t,
    id,
    value,
    disabled,
    onChange: onChangeProp,
    getValue,
    pages,
    pagesRecord,
    disabledPageIds,
}) {
    const [pageSelectionRecord, setPageSelectionRecord] = useState<Record<string, boolean>>(() => {
        /**
         * key is pageId; value is `selected` state
         */
        const initialPageSelectionRecord: Record<string, boolean> = {};

        let totalSelectedPages = 0;
        for (const page of pages) {
            const isSelected = value.destinationPages.has(page.id);
            if (isSelected) {
                totalSelectedPages += 1;
            }
            initialPageSelectionRecord[page.id] = isSelected;
        }

        initialPageSelectionRecord[selectAllOptionKey] = totalSelectedPages === pages.length - disabledPageIds.size;

        return initialPageSelectionRecord;
    });

    const options = React.useMemo(() => {
        const innerOptions: DropdownWithSearchOption[] = [
            {
                key: selectAllOptionKey,
                text: t.visualFwk.parameter.selectAll,
            },
        ];

        pages.forEach((page) => {
            // If this drillthrough config has the page id
            // inside destinationPages, then it "owns" that
            // selection so it should never be disabled
            const doesConfigOwnPageSelection = value.destinationPages.has(page.id);
            innerOptions.push({
                key: pageIdToOptionKey(page.id),
                text: page.name,
                data: page.id,
                disabled: doesConfigOwnPageSelection ? false : disabledPageIds.has(page.id),
            });
        });

        return innerOptions;
    }, [t, pages, value, disabledPageIds]);

    const selectedKeys = React.useMemo(() => {
        const innerSelectedKeys: string[] = [];

        for (const [pageId, selected] of Object.entries(pageSelectionRecord)) {
            if (selected) {
                const isSelectAll = pageId === selectAllOptionKey;

                // we handle this down below
                if (isSelectAll) {
                    continue;
                }

                innerSelectedKeys.push(pageIdToOptionKey(pageId));
            }
        }

        if (innerSelectedKeys.length === pages.length - disabledPageIds.size) {
            innerSelectedKeys.push(selectAllOptionKey);
        }

        return innerSelectedKeys;
    }, [pageSelectionRecord, disabledPageIds, pages]);

    const onChange = React.useCallback(
        (_event: unknown, option: SelectedOption) => {
            setPageSelectionRecord((prevSelectionRecord) => {
                const isSelectAllOption = option.key === selectAllOptionKey;
                const selectedValue = Boolean(option.selected);

                delete prevSelectionRecord[selectAllOptionKey];

                const nextRecord: Record<string, boolean> = {
                    ...prevSelectionRecord,
                };

                if (isSelectAllOption) {
                    for (const pageId of Object.keys(prevSelectionRecord)) {
                        if (!disabledPageIds.has(pageId)) {
                            nextRecord[pageId] = selectedValue;
                        }
                    }
                    nextRecord[selectAllOptionKey] = selectedValue;

                    return nextRecord;
                }

                nextRecord[option.data as string] = selectedValue;
                let selectAllValue = true;

                for (const [pageId, pageSelected] of Object.entries(nextRecord)) {
                    if (!disabledPageIds.has(pageId)) {
                        // if any of the available pages are not selected
                        // then "select all" should be false
                        if (pageSelected === false) {
                            selectAllValue = false;
                        }
                    }
                }

                nextRecord[selectAllOptionKey] = selectAllValue;

                return nextRecord;
            });
        },
        [disabledPageIds]
    );

    const applyUpdate = React.useCallback(() => {
        if (!pagesRecord) {
            return;
        }

        const prev = getValue(id);
        if (!prev) {
            return;
        }

        const rawSelectedKeys: string[] = [];
        selectedKeys.forEach((key) => {
            if (key !== selectAllOptionKey) {
                rawSelectedKeys.push(optionKeyToPageId(key));
            }
        });
        const newDestinationPages: DrillthroughConfig['destinationPages'] = new Set(rawSelectedKeys);

        onChangeProp(id, {
            ...prev,
            destinationPages: newDestinationPages,
        });
    }, [id, getValue, onChangeProp, selectedKeys, pagesRecord]);

    return (
        <DropdownWithSearch
            disabled={disabled}
            renderTitle={onDestinationPagesDropdownRenderTitle}
            className={inputLibStyle.basicInput}
            label={t.visualFwk.visualConfig.interactions.drillthrough.destinationPages.dropdownLabel}
            options={options}
            multiselect={true}
            selectedKeys={selectedKeys}
            onChange={onChange}
            onBlur={applyUpdate}
        />
    );
};
// #endregion

// #region Notes

const NotesInput: React.FC<DrillthroughFormProps> = ({ t, id, value, onChange: onChangeProp, getValue, disabled }) => {
    // using a ref for performance so we can treat
    // the text field as an unmanaged input
    const notesRef = React.useRef<string>('');

    const onChange = React.useCallback((_: unknown, data: InputOnChangeData) => {
        notesRef.current = data.value;
    }, []);

    const applyUpdate = React.useCallback(() => {
        const prev = getValue(id);
        if (!prev) {
            return;
        }
        onChangeProp(id, {
            ...prev,
            notes: notesRef.current === '' ? undefined : notesRef.current,
        });
    }, [id, getValue, onChangeProp]);

    return (
        <Field
            label={t.visualFwk.visualConfig.interactions.drillthrough.inputNotesLabel}
            className={inputLibStyle.basicInput}
        >
            <Input disabled={disabled} defaultValue={value.notes} onChange={onChange} onBlur={applyUpdate} />
        </Field>
    );
};
// #endregion

// #region Buttons

type AddAnotherDrillthroughPairButtonProps = ButtonProps & {
    t: KweVisualFwkLocale;
};

const AddAnotherDrillthroughPairButton: React.FC<AddAnotherDrillthroughPairButtonProps> = ({ t, ...props }) => {
    const className = `${configurationListStyles.addConfigItemButton} ${styles.formAddDrillthrough}`;

    return (
        // Adding extra wrapping div for the sake of simple CSS and centering on tooltip
        <div className={className}>
            <Button appearance="transparent" icon={<Add20Regular />} {...props}>
                {t.visualFwk.visualConfig.interactions.drillthrough.addMoreConfigurationButtonText}
            </Button>
        </div>
    );
};

interface DisableToggleProps {
    t: KweVisualFwkLocale;
    id: number;
    value: DrillthroughConfig;
    onChange(id: number, value: DrillthroughConfig): void;
    getValue(id: number): undefined | DrillthroughConfig;
}

const DisableToggle: React.FC<DisableToggleProps> = ({ t, id, value, onChange, getValue }) => {
    const onDisabledToggle = React.useCallback(() => {
        const prev = getValue(id);
        if (!prev) {
            return;
        }
        onChange(id, {
            ...prev,
            disabled: !prev.disabled,
        });
    }, [getValue, id, onChange]);

    return (
        <Toggle
            className={inputLibStyle.basicInput}
            checked={value.disabled}
            label={t.utils.util.buttons.disable}
            onClick={onDisabledToggle}
        />
    );
};
// #endregion

// #region PairsSection
let lastPairKey = 0;
function newPairKey() {
    return lastPairKey++;
}

const PairsSection: React.FC<DrillthroughFormProps> = (props) => {
    const { value, onChange, getValue, id, interaction } = props;
    const selectionsMap = useResolvedSelectionsMap(
        value.pairs,
        interaction,
        props.propertiesIndex,
        props.parametersRecord
    );

    /**
     * We map from a unique id to the Pair reference
     * + index position for 2 reasons:
     *
     * 1. stable key
     * 2. saving the index position means we can guarantee the order
     * in the array for the config so on re-render they are rendered
     * in the exact same order
     */
    const pairsMap = React.useMemo(
        () => new Map(value.pairs.map((pair, indexPos) => [newPairKey(), { pair, indexPos }])),
        [value]
    );

    const createOnPairChange = React.useCallback(
        (pairKey) => {
            return (nextPair: DrillthroughPair) => {
                const prev = getValue(id);
                if (!prev) {
                    return;
                }

                if (nextPair.parameterId === undefined && nextPair.property === undefined) {
                    pairsMap.delete(pairKey);
                } else {
                    const prevPair = pairsMap.get(pairKey);

                    if (!prevPair) {
                        return;
                    }

                    pairsMap.set(pairKey, { pair: nextPair, indexPos: prevPair.indexPos });
                }

                const nextPairs = Array.from(pairsMap.values())
                    .sort((a, b) => {
                        return a.indexPos - b.indexPos;
                    })
                    .map((p) => p.pair);

                onChange(id, { ...prev, pairs: nextPairs });
            };
        },
        [id, onChange, getValue, pairsMap]
    );

    const sections = [];

    for (const [pairKey, p] of pairsMap.entries()) {
        const onPairChange = createOnPairChange(pairKey);
        const selections = selectionsMap.get(p.pair);

        if (!selections) {
            continue;
        }

        sections.push(
            <div key={pairKey} className={styles.pair}>
                {/* If there is a single available property that is selected, don't show this dropdown. */}
                {interaction?.kind === 'available' && (
                    <PropertyDropdown
                        {...props}
                        selectedInteraction={interaction}
                        selectedProperty={selections.property}
                        selectedParameter={selections.parameter}
                        pair={p.pair}
                        onPairChange={onPairChange}
                    />
                )}
                <ParameterDropdown {...props} selections={selections} pair={p.pair} onPairChange={onPairChange} />
            </div>
        );
    }

    return <div className={styles.pairsContainer}>{sections}</div>;
};
// #endregion

const TypeWarning: React.FC<DrillthroughFormProps> = observer(function TypeWarning(props) {
    const { value, interaction, propertiesIndex, parametersRecord, t } = props;

    const warnings = React.useMemo(() => {
        const arr: InteractionErrorMessage[] = [];

        for (const p of value.pairs) {
            const selections = getResolvedSelections(p, interaction, propertiesIndex, parametersRecord);
            const warning = crossFilterTypeWarning(t, { interaction, ...selections });

            if (warning) {
                arr.push(warning);
            }
        }

        return arr;
    }, [value.pairs, interaction, propertiesIndex, parametersRecord, t]);

    if (warnings.length > 0) {
        return (
            <>
                {warnings.map((warning, index) => (
                    <MessageBar
                        key={index}
                        isMultiline
                        messageBarType={warning.level === 'error' ? MessageBarType.error : MessageBarType.warning}
                    >
                        {warning.text()}
                    </MessageBar>
                ))}
            </>
        );
    }

    return null;
});

export function newPair() {
    return {
        property: undefined,
        parameterId: undefined,
    };
}
export interface DrillthroughFormProps {
    t: KweVisualFwkLocale;
    id: number;
    value: DrillthroughConfig;
    onChange(id: number, value: DrillthroughConfig): void;
    getValue(id: number): undefined | DrillthroughConfig;
    disabled: boolean;
    showDisable: boolean;
    interaction: DrillthroughVisual;
    propertiesIndex: PropertiesIndex;
    parameters: readonly InteractionTarget[];
    parametersRecord: undefined | ReadonlyMap<string, InteractionTarget>;
    pages: readonly RtdPage[];
    pagesRecord: undefined | ReadonlyMap<string, RtdPage>;
    disabledPageIds: Readonly<Set<string>>;
}

export const DrillthroughForm: React.FC<DrillthroughFormProps> = (props) => {
    const { onChange, id, getValue, value } = props;
    const disabled = props.disabled || value.disabled;

    const addAnotherDrillthroughPair = React.useCallback(() => {
        const prev = getValue(id);

        if (!prev) {
            return;
        }

        onChange(id, { ...prev, pairs: [...value.pairs, newPair()] });
    }, [getValue, onChange, id, value]);

    return (
        <>
            <div className={styles.mainInputsContainer}>
                <DestinationPages {...props} disabled={disabled} />
                <PairsSection {...props} disabled={disabled} />
                <NotesInput {...props} disabled={disabled} />
            </div>
            <AddAnotherDrillthroughPairButton t={props.t} onClick={addAnotherDrillthroughPair} />
            {props.showDisable && <DisableToggle {...props} />}
            <TypeWarning {...props} />
        </>
    );
};
