import { types } from 'mobx-state-tree';

import { Cluster } from './cluster';

export const Group = types
    .model('Group', {
        id: types.identifier,
        entities: types.array(types.reference(Cluster)),
        timestamp: types.number,
    })
    .actions((self) => ({
        addEntity(entity: Cluster) {
            self.entities.push(entity);
        },
        removeEntity(id: string) {
            const index = self.entities.findIndex((entity) => entity.id === id);
            if (index !== -1) {
                self.entities.splice(index, 1);
            }
        },
    }));

export type Group = typeof Group.Type;
