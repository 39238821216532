import React from 'react';
import { Body1, Caption1, Spinner } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';

import { formatLiterals } from '@kusto/utils';

import { useDataExplorationContext } from '../../../context/DataExplorationContext';
import { type ColumnWithInsights } from '../../../DataExplorationApi';

import * as styles from './ColumnSummary.module.scss';

interface ColumnSummaryProps extends Omit<ColumnWithInsights, 'ColumnName'> {
    timezone: string;
    isLoading?: boolean;
}

export const ColumnSummary: React.FC<ColumnSummaryProps> = observer(function ColumnSummary({
    ColumnType,
    maxValue,
    minValue,
    cardinality,
    timezone,
    isHighCardinality,
    isLoading,
}) {
    const { t } = useDataExplorationContext();

    const loadingComponent = (
        <Spinner size="tiny" label={t.dataExploration.schemaInsights.loading} labelPosition="after" />
    );

    switch (ColumnType) {
        case 'datetime':
            if (minValue && maxValue) {
                const dateTimeFormatter = Intl.DateTimeFormat(t.locale, { timeZone: timezone });
                return (
                    <Body1>
                        {/* TODO handle higher date resolution in kusto engine than JS Date allows */}
                        {dateTimeFormatter.formatRange(new Date(minValue), new Date(maxValue))}
                    </Body1>
                );
            } else if (isLoading) {
                return loadingComponent;
            }
            return null;
        case 'decimal':
        case 'int':
        case 'long':
        case 'real':
            if (minValue === undefined || minValue === null || maxValue === undefined || maxValue === null) {
                if (isLoading) {
                    return loadingComponent;
                }
                return null;
            }
            const numberFormatter = new Intl.NumberFormat(t.locale, { style: 'decimal' });
            return (
                <div className={styles.minMaxContainer}>
                    <div className={styles.minMax}>
                        <Caption1 className={styles.textCaption1}>
                            {t.dataExploration.schemaInsights.columnSummary.minimumValue}
                        </Caption1>
                        <Body1>
                            {/* handle locale based number format */}
                            {numberFormatter.format(minValue as number)}
                        </Body1>
                    </div>
                    <div className={styles.minMax}>
                        <Caption1 className={styles.textCaption1}>
                            {t.dataExploration.schemaInsights.columnSummary.maximumValue}
                        </Caption1>
                        <Body1>
                            {/* handle locale based number format */}
                            {numberFormatter.format(maxValue as number)}
                        </Body1>
                    </div>
                </div>
            );
        case 'string':
            if (cardinality === undefined || cardinality === null) {
                if (isLoading) {
                    return loadingComponent;
                }
                return null;
            }

            return (
                <div className={styles.cardinalityContainer}>
                    <Body1>
                        {isHighCardinality
                            ? formatLiterals(t.dataExploration.schemaInsights.columnSummary.highUniqueCount)
                            : formatLiterals(t.dataExploration.schemaInsights.columnSummary.uniqueValues, {
                                  count: cardinality.toString(),
                              })}
                    </Body1>
                </div>
            );
        case 'bool':
        case 'timespan':
        case 'dynamic':
        case 'guid':
        default:
            return null;
    }
});
