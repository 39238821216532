.filterPillPopover {
    .filterFormContainer {
        display: flex;
        gap: var(--spacingVerticalL);
        padding-top: var(--spacingVerticalXL);
    }
}

.aggregationPillPopover {
    // prevent panel from resizing when fields are added
    min-width: 770px;
    min-height: 300px;

    .aggregationFormContainer {
        display: flex;
        flex-direction: column;
        gap: var(--spacingVerticalL);
        padding-top: var(--spacingVerticalXL);
    }

    .aggregationGroups {
        display: flex;
        flex-direction: column;
        gap: var(--spacingHorizontalM);

        .removeButton {
            height: 32px;
            align-self: flex-end;
        }

        .addButton {
            align-self: flex-start;
        }
    }

    .aggregationFormRow {
        display: flex;
        gap: var(--spacingHorizontalL);
    }
}

.formButtons {
    display: flex;
    gap: var(--spacingHorizontalS);
    justify-content: flex-end;
    margin-top: var(--spacingVerticalXXL);
}
