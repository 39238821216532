import { safeStringify } from './json';

/**
 * AssertNever is a utility function that can be used for exhaustiveness checks
 * in switch statements.
 *
 * Use this instead of the `office-ui-fabric` version so code dependent on it is
 * usable in node and throws our own error class.
 */
export function assertNever(x: never): never {
    throw new KweException(`Unexpected value: ${x}`);
}

/**
 * Checks if a fetch exception was caused by an abort signal.
 *
 * From MDN:
 * > Note: When abort() is called, the fetch() promise rejects with an Error of
 * > type DOMException, with name AbortError.
 * https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
 */
export function isAbortError(e: unknown): e is DOMException {
    return e instanceof DOMException && e.name === 'AbortError';
}

/**
 * Returns true when err was thrown after accessing localStorage/cookies when
 * it's blocked via browsers settings.
 *
 * As of writing, this will occur by default if we're in an iframe in incognito
 * mode, or in Firefox if users change the "Tracking Protection" settings.
 * Dashboards and the query area need to run in this context.
 *
 * To reproduce outside of an iframe, open app in Firefox with "Enhanced
 * Tracking Protection" set to "Custom" and "Suspected fingerprinters" set to
 * "In all windows"
 *
 * Tested that it works with exceptions thrown on localStorage and indexedDB
 * access on Safari, Chrome, and Firefox.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/DOMException#error_names
 */
export function isSecurityError(e: unknown): e is DOMException {
    return e instanceof DOMException && e.name === 'SecurityError';
}

/**
 * Intended to be thrown
 *
 * Unexpected error case. This errors should always results in a logged
 * exception, and generally are caught at a React error boundary.
 */
export class KweException extends Error {
    name = 'KweException';
}

/**
 * # Control flow error
 *
 * Intended to be thrown
 *
 * Avoid using this and prefer return-ing error information when possible. If
 * this is used, some justification should be provided (existing pattern, perf,
 * etc).
 *
 * Extend or use this when `throw`-ing for control flow. Control-flow errors
 * should still inherit from `Error` so we'll have a stack-trace in the event
 * they aren't caught.
 */
export class KweCfError extends Error {
    name = 'KweCfError';
}

export class KweCastError extends KweException {
    name = 'KweCastError';
}

export function castToError(value: unknown): Error {
    if (value instanceof Error) {
        return value;
    } else {
        return new KweCastError(safeStringify(value));
    }
}

export function assertIsError(value: unknown): Error {
    if (value instanceof Error) {
        return value;
    }
    throw new KweException(safeStringify(value));
}
