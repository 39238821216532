import React from 'react';
import { Text } from '@fluentui/react';
import { Button } from '@fluentui/react-components';
import { ArrowClockwise16Regular } from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';

import { formatLiterals, KweException } from '@kusto/utils';

import type { RtdPage } from '../../dashboardApi';
import type { QueryMessageLevel } from '../../error';
import type { InteractionTarget } from '../../interactions/target';
import type { KweVisualFwkLocale } from '../../types';
import { DrillthroughConfig, DrillthroughPair } from '../../visualOptions';
import { ConfigurationItemRow, ConfigurationItemRowContent } from '../configurationList/ConfigurationItemRow';
import { ConfigurationItemRowErrors } from '../configurationList/ConfigurationItemRowErrors';
import { crossFilterTypeWarning } from '../CrossFilter/lib';
import { PropertiesIndex } from './Drillthrough';
import { formatUnavailable, useResolvedSelectionsMap } from './lib';
import type { DrillthroughVisual } from './VisualInteraction';

import * as styles from './styles.module.scss';

export function rowId(configId: number) {
    return `visualOptions--drillthroughRow--${configId}`;
}

function formatParameterLabel(
    t: KweVisualFwkLocale,
    parameterId: undefined | string,
    parametersRecord: undefined | ReadonlyMap<string, InteractionTarget>
) {
    if (parameterId === undefined) {
        return formatLiterals(t.visualFwk.visualConfig.interactions.drillthrough.notSelected, {
            category: t.visualFwk.parameter.displayName,
        });
    } else {
        const parameter = parametersRecord?.get(parameterId);
        if (parameter === undefined) {
            return formatUnavailable(t, t.visualFwk.parameter.displayName);
        } else {
            return parameter.displayName;
        }
    }
}

function formatPropertyLabel(
    t: KweVisualFwkLocale,
    propertyId: undefined | string,
    property: undefined | DrillthroughVisual.Property,
    displayName: undefined | string = t.visualFwk.visualConfig.interactions.drillthrough.property.defaultDisplayName
) {
    if (propertyId === undefined) {
        return formatLiterals(t.visualFwk.visualConfig.interactions.drillthrough.notSelected, {
            category: t.visualFwk.parameter.displayName,
        });
    }

    if (property === undefined) {
        return formatUnavailable(t, displayName);
    }
    return property.displayName ?? property.id;
}

function formatPairsLabel(
    t: KweVisualFwkLocale,
    interaction: DrillthroughVisual.Interaction,
    pairs: DrillthroughPair[],
    resolvedSelectionsMap: ReturnType<typeof useResolvedSelectionsMap>,
    parametersRecord: undefined | ReadonlyMap<string, InteractionTarget>,
    errors: string[]
) {
    if (parametersRecord === undefined) {
        throw new KweException('Can only be used while a dashboard is loaded');
    }

    let maxErrorLevel: QueryMessageLevel = 'warn';
    const labelStrings = [];

    for (const pair of pairs) {
        const selections = resolvedSelectionsMap.get(pair);

        if (selections === undefined) {
            throw new KweException(`Unable to find selection by existing pair property: ${pair.property}`);
        }

        const propertyLabel = formatPropertyLabel(t, pair.property, selections.property);
        const paramLabel = formatParameterLabel(t, pair.parameterId, parametersRecord);

        labelStrings.push(`${propertyLabel}: ${paramLabel}`);

        const typeWarning = crossFilterTypeWarning(t, { interaction, ...selections });

        if (typeWarning) {
            errors.push('\n\n\n' + typeWarning.text());

            if (typeWarning.level === 'error') {
                maxErrorLevel = 'error';
            }
        }
    }

    return { pairsLabel: labelStrings.join(', '), pairsLabelStrings: labelStrings, maxErrorLevel };
}

export interface DrillthroughRowProps {
    t: KweVisualFwkLocale;
    id: number;
    config: DrillthroughConfig;
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
    interaction: DrillthroughVisual.Interaction;
    propertiesIndex: PropertiesIndex;
    parametersRecord: undefined | ReadonlyMap<string, InteractionTarget>;
    pagesRecord: undefined | ReadonlyMap<string, RtdPage>;
    resetDestinationPages: (id: number) => void;
}

export const DrillthroughRow: React.FC<DrillthroughRowProps> = observer(function DrillthroughRow({
    t,
    id,
    onEdit,
    onDelete,
    config,
    interaction,
    propertiesIndex,
    parametersRecord,
    pagesRecord,
    resetDestinationPages,
}) {
    const resolvedSelectionsMap = useResolvedSelectionsMap(
        config.pairs,
        interaction,
        propertiesIndex,
        parametersRecord
    );

    const titleText: string[] = [];
    const errors: string[] = [];
    let maxErrorLevel: QueryMessageLevel = 'warn';
    let primaryLabel: string;
    let secondaryLabel: string;

    if (config.destinationPages.size > 0) {
        const pageNames = [];

        for (const pageId of config.destinationPages) {
            const page = pagesRecord?.get(pageId);

            if (page) {
                pageNames.push(page.name);
            }
        }

        primaryLabel = pageNames.join(', ');
    } else {
        primaryLabel = t.visualFwk.visualConfig.interactions.drillthrough.emptyPrimaryLabel;
    }
    titleText.push(primaryLabel);

    const formatPairsRes = formatPairsLabel(
        t,
        interaction,
        config.pairs,
        resolvedSelectionsMap,
        parametersRecord,
        errors
    );

    if (config.notes && config.notes.length > 0) {
        secondaryLabel = config.notes;
        titleText.push(config.notes);
    } else {
        secondaryLabel = formatPairsRes.pairsLabel;
        titleText.push(...formatPairsRes.pairsLabelStrings);
    }

    if (formatPairsRes.maxErrorLevel === 'error') {
        maxErrorLevel = 'error';
    }

    if (config.disabled) {
        titleText.push(`(${t.utils.util.status.disabled})`);
    }

    return (
        <ConfigurationItemRow<number>
            id={rowId(id)}
            item={id}
            onDelete={onDelete}
            onEdit={onEdit}
            editButtonTitle={t.utils.util.buttons.edit}
            deleteButtonTitle={t.utils.util.buttons.delete}
        >
            <>
                <ConfigurationItemRowContent title={titleText.join('\n')} disabled={config.disabled}>
                    <Text variant="mediumPlus">{primaryLabel}</Text>
                    <Text variant="small">{secondaryLabel}</Text>
                    {errors.length !== 0 && <ConfigurationItemRowErrors warnings={errors} level={maxErrorLevel} />}
                </ConfigurationItemRowContent>
                {config.destinationPages.size > 0 && (
                    <Button
                        appearance="transparent"
                        className={styles.resetButton}
                        icon={<ArrowClockwise16Regular />}
                        onClick={() => resetDestinationPages(id)}
                        title={t.visualFwk.visualConfig.interactions.drillthrough.resetDestinationPages}
                    />
                )}
            </>
        </ConfigurationItemRow>
    );
});
