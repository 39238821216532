import React, { useState } from 'react';
import { Field, Input, InputOnChangeData } from '@fluentui/react-components';
// Safe because it's a type import
// eslint-disable-next-line no-restricted-imports
import type { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react-lite';
import type { ConditionalKeys } from 'type-fest';

import { DropdownWithSearch, InputFieldProps, SelectedOption } from '@kusto/ui-components';
import { useRegisterDebounce } from '@kusto/utils';

import { VISUAL_OPTIONS__TEXT_DEBOUNCE } from '../../constants';
import type { KweVisualFwkLocale } from '../../types';
import type { YAxisConfig } from '../../visualOptions';
import { ApplyHorizontalLineChange, BaseYAxisHorizontalLineField, HorizontalLineList } from './HorizontalLines';
import { updateYAxisLimit } from './reducer';
import { ApplyYAxisChange, convertStringToNumber, createAxisScaleOptions, MultipleYAxesModel } from './util';

import * as inputsStyles from '../styles.module.scss';

interface BaseYAxisInputFieldProps {
    property: Exclude<ConditionalKeys<YAxisConfig, string>, undefined>;
    label: string;
    inputType?: string;
    placeholder?: string;
    onChange: (applyChange: ApplyYAxisChange) => void;
    model: MultipleYAxesModel;
}

export const BaseYAxisInputField: React.FC<BaseYAxisInputFieldProps> = observer(function BaseYAxisInputField({
    property,
    label,
    inputType,
    placeholder,
    onChange,
    model,
}) {
    // Local values shadow global values, and are cleared when debounce resolves
    const [localValue, setLocalValue] = React.useState<undefined | string>(undefined);
    const value = model.get('multipleYAxes').base[property];
    const onInputChange = React.useMemo(() => {
        const setGlobalValue = debounce((value: string) => {
            onChange((multipleYAxisConfig) => {
                const baseAxis = { ...multipleYAxisConfig.base, [property]: value };
                return { ...multipleYAxisConfig, base: baseAxis };
            });
            setLocalValue(undefined);
        }, VISUAL_OPTIONS__TEXT_DEBOUNCE);

        const innerOnChange = ((_: unknown, data: InputOnChangeData) => {
            if (data.value !== undefined) {
                setLocalValue(data.value);
                setGlobalValue(data.value);
            }
        }) as DebouncedFunc<(_: unknown, data: InputOnChangeData) => void>;
        innerOnChange.flush = setGlobalValue.flush;
        innerOnChange.cancel = setGlobalValue.cancel;
        return innerOnChange;
    }, [property, onChange]);

    useRegisterDebounce(model.registerFlush, onInputChange);

    return (
        <Field label={label}>
            <Input
                className={inputsStyles.basicInput}
                value={localValue ?? value}
                onChange={onInputChange}
                type={inputType as InputFieldProps['type']}
                placeholder={placeholder}
            />
        </Field>
    );
});

interface AxisLimitProps {
    t: KweVisualFwkLocale;
    model: MultipleYAxesModel;
    property: 'yAxisMinimumValue' | 'yAxisMaximumValue';
    onChange: (applyChange: ApplyYAxisChange) => void;
}

const AxisLimit: React.FC<AxisLimitProps> = observer(function AxisLimit({ t, property, model, onChange }) {
    const limit = model.get('multipleYAxes').base[property];
    const [text, setText] = useState(limit);

    React.useEffect(() => setText(limit), [limit]);

    const { onValueChange, dispatchValueChange } = React.useMemo(() => {
        const dispatchChange = debounce(
            (value: string) =>
                onChange((multipleYAxisConfig) => {
                    const baseAxis = updateYAxisLimit(multipleYAxisConfig.base, property, value);
                    return { ...multipleYAxisConfig, base: baseAxis };
                }),
            500
        );
        return {
            dispatchValueChange: dispatchChange,
            onValueChange: (_: unknown, data: InputOnChangeData) => {
                setText(convertStringToNumber(data.value));
                dispatchValueChange(data.value);
            },
        };
    }, [setText, property, onChange]);

    useRegisterDebounce(model.registerFlush, dispatchValueChange);

    return (
        <Field label={t.visualFwk.visualConfig[property].inputLabel}>
            <Input
                className={inputsStyles.basicInput}
                placeholder={t.visualFwk.visualConfig.textInputInferPlaceholder}
                value={text?.toString() ?? ''}
                type="number"
                onChange={onValueChange}
            />
        </Field>
    );
});

interface BaseYAxisScaleProps {
    t: KweVisualFwkLocale;
    onChange: (applyChange: ApplyYAxisChange) => void;
    model: MultipleYAxesModel;
}

const BaseYAxisScale: React.FC<BaseYAxisScaleProps> = observer(function BaseYAxisScale({ t, onChange, model }) {
    const selectedKey = model.get('multipleYAxes').base.yAxisScale;

    const { onScaleChange, options } = React.useMemo(
        () => ({
            onScaleChange(_: unknown, option?: SelectedOption) {
                onChange((multipleYAxisConfig) => {
                    const baseAxis = {
                        ...multipleYAxisConfig.base,
                        yAxisScale: option?.key as YAxisConfig['yAxisScale'],
                    };
                    return { ...multipleYAxisConfig, base: baseAxis };
                });
            },
            options: createAxisScaleOptions(t),
        }),
        [onChange, t]
    );

    return (
        <DropdownWithSearch
            className={inputsStyles.basicInput}
            selectedKeys={selectedKey}
            options={options}
            onChange={onScaleChange}
            label={t.visualFwk.visualConfig.axisScale.label.yAxisScale}
        />
    );
});

interface BaseYAxisHorizontalLineListProps {
    t: KweVisualFwkLocale;
    onChange: (applyChange: ApplyYAxisChange) => void;
    model: MultipleYAxesModel;
}

const BaseYAxisHorizontalLineList: React.FC<BaseYAxisHorizontalLineListProps> = observer(
    function BaseYAxisHorizontalLineList({ t, model, onChange }) {
        const multipleYAxes = model.get('multipleYAxes');

        const onChangeHorizontalLine = React.useCallback(
            (applyChange: ApplyHorizontalLineChange) => {
                onChange((multipleYAxisConfig) => {
                    const updatedLines = applyChange([...multipleYAxisConfig.base.horizontalLines]);
                    const baseAxis = { ...multipleYAxisConfig.base };
                    if (updatedLines) {
                        baseAxis.horizontalLines = updatedLines;
                    }
                    return { ...multipleYAxisConfig, base: baseAxis };
                });
            },
            [onChange]
        );
        const onHorizontalLineInputChange = React.useCallback(
            (id: string, newValue?: string) => {
                onChange((multipleYAxisConfig) => {
                    const horizontalLines = [...multipleYAxisConfig.base.horizontalLines];
                    const index = horizontalLines.findIndex((line) => line.id === id);
                    horizontalLines[index] = { ...horizontalLines[index], value: convertStringToNumber(newValue) };
                    const baseAxis = { ...multipleYAxisConfig.base, horizontalLines: horizontalLines };
                    return { ...multipleYAxisConfig, base: baseAxis };
                });
            },
            [onChange]
        );
        return (
            <HorizontalLineList
                t={t}
                registerFlush={model.registerFlush}
                yAxisConfig={multipleYAxes.base}
                onChangeHorizontalLine={onChangeHorizontalLine}
                ItemComponent={BaseYAxisHorizontalLineField}
                onHorizontalLineInputChange={onHorizontalLineInputChange}
            />
        );
    }
);

export interface BaseYAxisFormProps {
    t: KweVisualFwkLocale;
    onChange: (applyChange: ApplyYAxisChange) => void;
    model: MultipleYAxesModel;
}

export const BaseYAxisForm: React.FC<BaseYAxisFormProps> = ({ t, onChange, model }) => {
    return (
        <>
            <BaseYAxisInputField
                property="label"
                label={t.visualFwk.visualConfig.yColumnTitleInputLabel}
                onChange={onChange}
                model={model}
            />
            <AxisLimit t={t} property="yAxisMaximumValue" onChange={onChange} model={model} />
            <AxisLimit t={t} property="yAxisMinimumValue" onChange={onChange} model={model} />
            <BaseYAxisScale t={t} onChange={onChange} model={model} />
            <BaseYAxisHorizontalLineList t={t} onChange={onChange} model={model} />
        </>
    );
};
