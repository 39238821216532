import useSWR from 'swr';

import { useDataExplorationContext } from '../../context/DataExplorationContext';
import { useKustoClient } from '../../kustoClientWrapper';
import { useKql } from './useKql';

function useResult(query: string, keyPrefix = 'query_') {
    const { clusterUrl, databaseName, t } = useDataExplorationContext();
    const { executeQuery } = useKustoClient({ clusterUrl, databaseName, t });

    // TODO: Hash the key to SWR
    return useSWR([keyPrefix, query], async () => {
        const { frames, visualizationOptions, errors } = await executeQuery(query);

        return {
            dataFrame: frames[0].frame,
            visualizationOptions,
            errors,
        };
    });
}

/** Return the KQL results for the 'computed query' */
export function useQueryResults() {
    const computedKql = useKql();
    return useResult(computedKql, 'computedQuery_');
}

/** Return list of columns for the 'computed query' */
export function useQueryColumns(dropLastPill?: boolean) {
    const pillsToDrop = dropLastPill ? 1 : 0;
    const computedKql = useKql(pillsToDrop);

    return useResult(`${computedKql} | take 0`, 'computedColumns_');
}
