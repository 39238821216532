/**
 * Create a filtered list of RowDataType from a list of clusters.
 *
 * Logic:
 * Start with visiting the leafs (functions and columns),
 * if a leaf is passing the filter then RowDataType is created for the leaf and for all its ancestors (e.g. column->table->folder->database->cluster).
 * If a leaf is not passing the filter but one of its ancestors does, create RowDataType only for that ancestor and for its ancestors.
 * If a cluster and all its descendants don't pass the filter there will be no RowDataType created for this cluster.
 */

import { EntityGroup, EntityType, EntityWithFolder, Function, Table } from '../../../common';
import { FolderEntityType } from '../../../common/entityTypeUtils';
import { Cluster, ConnectionPane, Database, QueryStoreEnv } from '../../../stores';
import { ITreeEntityMapper, keepFocusIconName } from '../actions';
import { RowDataType } from '../RowDataType';
import { isTridentView } from '../utils';
import { ClusterRowDataType } from './ClusterRowDataType';
import { ColumnRowDataType } from './ColumnRowDataType';
import { DatabaseFolderRowDataType, entityGroupFolderName, functionsFolderName } from './DatabaseFolderRowDataType';
import { DatabaseRowDataType } from './DatabaseRowDataType';
import { EntityGroupMemberRowDataType } from './EntityGroupMemberRowDataType';
import { EntityGroupRowDataType } from './EntityGroupRowDataType';
import { FolderNode, FolderTree, TableFolderTree } from './FolderTree';
import { FunctionRowDataType } from './FunctionRowDataType';
import { TableRowDataType } from './TableRowDataType';

type FilterFunction = (id: string, details?: string) => boolean;
interface FilterFlowContext {
    connectionPane: ConnectionPane;
    filter: FilterFunction;
    countOnly: boolean;
}

export class FilterFlow {
    private treeEntityMapper: ITreeEntityMapper;
    constructor(private readonly env: QueryStoreEnv, treeEntityMapper: ITreeEntityMapper) {
        this.treeEntityMapper = treeEntityMapper;
    }

    public addFocusIcon(rows: RowDataType[]) {
        rows.forEach((item) => {
            const hasActions = item.actions && item.actions?.length > 0;
            const actions = item.actions?.filter((action) => action.mainIcon.iconName === keepFocusIconName);
            const hasFocusAction = hasActions && actions !== undefined && actions.length > 0;

            if (!hasFocusAction) {
                const keepFocusAction = this.treeEntityMapper.getAction('keepFocus', false, undefined);
                if (keepFocusAction) {
                    item.actions?.unshift(keepFocusAction);
                }
            }
        });
    }

    public removeFocusIcon(rows: RowDataType[]) {
        rows.forEach((item) => {
            item.actions?.forEach((action, i) => {
                if (action.mainIcon.iconName === keepFocusIconName) {
                    item.actions?.splice(i, 1);
                }
            });
        });
    }

    /**
     * Simulate how many rows getRowDataTypes will return.
     * The response from this method can be used to decide if search results should be shown.
     * If the number is too high an error message can be shown asking the user to try a different filter.
     * */
    public getRowDataTypesCount(connectionPane: ConnectionPane, filter: FilterFunction): number {
        const context: FilterFlowContext = { connectionPane, filter, countOnly: true };

        if (!isTridentView(this.env)) {
            return this.getRowDataTypesWithCount(context).length;
        } else {
            return this.getRowDataTypesWithCountFromEntityInContext(context).length;
        }
    }

    /**
     * Builds a list of RowDataTypes from the clusters in 'this.connectionPane'.
     */
    public getRowDataTypes(connectionPane: ConnectionPane, filter: FilterFunction): RowDataType[] {
        const context: FilterFlowContext = { connectionPane, filter, countOnly: false };
        if (!isTridentView(this.env)) {
            return this.getRowDataTypesWithCount(context).list;
        } else {
            return this.getRowDataTypesWithCountFromEntityInContext(context).list;
        }
    }

    /**
     * Builds a list of RowDataTypes from the current context database in 'this.connectionPane'. - used by Trident view
     * If 'this.countOnly' is true, only count the number of items (`list` will be empty).
     * */
    private getRowDataTypesWithCountFromEntityInContext(context: FilterFlowContext): {
        list: RowDataType[];
        length: number;
    } {
        if (context.connectionPane.entityInContext?.entityType !== EntityType.Database) {
            return { list: [], length: 0 };
        }
        const database = context.connectionPane.entityInContext as Database;
        return this.getDatabaseDescendantsResultsAndCount(database, context, true);
    }

    /**
     * Builds a list of RowDataTypes from the clusters in 'this.connectionPane'.
     * If 'this.countOnly' is true, only count the number of items (`list` will be empty).
     * */
    private getRowDataTypesWithCount(context: FilterFlowContext): { list: RowDataType[]; length: number } {
        let result: RowDataType[] = [];
        let totalCount = 0;
        const clusters = Array.from(context.connectionPane.connections.values());
        clusters.forEach((cluster) => {
            if (cluster) {
                const clusterDescendants = this.getClusterDescendants(cluster, context);
                if (clusterDescendants.length > 0 || context.filter(cluster.getAlias())) {
                    const clusterRowDataType = new ClusterRowDataType(this.env, cluster, this.treeEntityMapper);
                    totalCount = totalCount + clusterDescendants.length + 1;
                    if (!context.countOnly) {
                        result = result.concat(clusterRowDataType).concat(clusterDescendants.list);
                    }
                }
            }
        });

        return { list: result, length: totalCount };
    }

    private getClusterDescendants(
        cluster: Cluster,
        context: FilterFlowContext
    ): { list: RowDataType[]; length: number } {
        let result: RowDataType[] = [];
        let totalCount = 0;
        if (!cluster) {
            return { list: [], length: 0 };
        }

        cluster.databases.forEach((database) => {
            if (!database) {
                return;
            }
            if (context.connectionPane.showOnlyFavorites && !cluster.isFavorite && !database.isFavorite) {
                return;
            }
            const dbeDescendantsResult = this.getDatabaseDescendantsResultsAndCount(database, context, false);
            result = result.concat(dbeDescendantsResult.list);
            totalCount = totalCount + dbeDescendantsResult.length;
        });

        return { list: result, length: totalCount };
    }

    private getDatabaseDescendantsResultsAndCount(
        database: Database,
        context: FilterFlowContext,
        excludeDatabase: boolean
    ): { list: RowDataType[]; length: number } {
        let result: RowDataType[] = [];
        let totalCount = 0;
        const databaseDescendants = this.getDatabaseDescendants(database, context);
        if (
            !excludeDatabase &&
            (databaseDescendants.length > 0 || context.filter(database.prettyName ?? database.name))
        ) {
            if (!context.countOnly) {
                const shouldRefreshCache = (old: RowDataType) => old.isFavorite !== database.isFavorite;
                const databaseRowDataType = DatabaseRowDataType.fromCacheOrCreate(
                    this.env,
                    database,
                    this.treeEntityMapper,
                    undefined,
                    undefined,
                    shouldRefreshCache
                );
                result = result.concat(databaseRowDataType);
            }
            totalCount = 1;
        }

        if (!context.countOnly) {
            result = result.concat(databaseDescendants.list);
        }

        totalCount = totalCount + databaseDescendants.length;
        return { list: result, length: totalCount };
    }

    private getDatabaseDescendants(
        database: Database,
        context: FilterFlowContext
    ): { list: RowDataType[]; length: number } {
        const entityGroupsArray = Array.from(database.entityGroups.values());
        const functionsArray = Array.from(database.functions.values());
        const regularTablesArray = Array.from(database.regularTables.values());
        const externalTablesArray = Array.from(database.externalTables.values());
        const materializedViewArray = Array.from(database.materializedViewTables.values());

        const entityGroupFolderTree = new FolderTree<EntityGroup>(entityGroupsArray, entityGroupFolderName);

        // eslint-disable-next-line @typescript-eslint/ban-types
        const functionsFolderTree = new FolderTree<Function>(functionsArray, functionsFolderName);

        const regularTablesFolderTree = new TableFolderTree<Table>(
            regularTablesArray,
            this.treeEntityMapper.getNodeName(EntityType.Table)
        );

        const externalTablesFolderTree = new FolderTree<Table>(
            externalTablesArray,
            this.treeEntityMapper.getNodeName(EntityType.ExternalTable)
        );

        const materializedViewsFolderTree = new FolderTree<Table>(
            materializedViewArray,
            this.treeEntityMapper.getNodeName(EntityType.MaterializedViewTable)
        );

        const entityGroups = this.getFolderWithDescendants(
            entityGroupFolderTree.root,
            database,
            EntityType.EntityGroupFolder,
            context,
            (entity: EntityWithFolder) => this.geEntityGroupAndMembers(entity as EntityGroup, database, context)
        );

        const functions = this.getFolderWithDescendants(
            functionsFolderTree.root,
            database,
            EntityType.FunctionsFolder,
            context,
            // eslint-disable-next-line @typescript-eslint/ban-types
            (entity: EntityWithFolder) => this.getFunction(entity as Function, database, context)
        );

        const regularTables = this.getFolderWithDescendants(
            regularTablesFolderTree.root,
            database,
            EntityType.TablesFolder,
            context,
            (entity: EntityWithFolder) => this.getTableAndColumns(entity as Table, database, context)
        );

        const externalTables = this.getFolderWithDescendants(
            externalTablesFolderTree.root,
            database,
            EntityType.ExternalTableFolder,
            context,
            (entity: EntityWithFolder) => this.getTableAndColumns(entity as Table, database, context)
        );

        const materializedViews = this.getFolderWithDescendants(
            materializedViewsFolderTree.root,
            database,
            EntityType.MaterializedViewTableFolder,
            context,
            (entity: EntityWithFolder) => this.getTableAndColumns(entity as Table, database, context)
        );

        return {
            list: functions.list
                .concat(regularTables.list)
                .concat(externalTables.list)
                .concat(materializedViews.list)
                .concat(entityGroups.list),
            length:
                regularTables.length +
                functions.length +
                materializedViews.length +
                externalTables.length +
                entityGroups.length,
        };
    }

    private geEntityGroupAndMembers(
        // eslint-disable-next-line @typescript-eslint/ban-types
        entityGroup: EntityGroup,
        database: Database,
        context: FilterFlowContext
    ): { list: RowDataType[]; length: number } {
        const result: RowDataType[] = [];
        let totalCount = 0;

        Object.values(entityGroup.entities).forEach((entity) => {
            if (context.filter(entity.name)) {
                if (!context.countOnly) {
                    const columnRowDataType = EntityGroupMemberRowDataType.fromCacheOrCreate(
                        this.env,
                        entity,
                        entityGroup,
                        database,
                        this.treeEntityMapper
                    );
                    result.push(columnRowDataType);
                }
                totalCount++;
            }
        });

        if (totalCount > 0 || context.filter(entityGroup.name)) {
            if (!context.countOnly) {
                const entityGroupRowDataType = EntityGroupRowDataType.fromCacheOrCreate(
                    this.env,
                    entityGroup,
                    database,
                    this.treeEntityMapper
                );
                result.push(entityGroupRowDataType);
            }
            totalCount++;
        }

        return { list: result, length: totalCount };
    }

    private getFunction(
        // eslint-disable-next-line @typescript-eslint/ban-types
        func: Function,
        database: Database,
        context: FilterFlowContext
    ): { list: RowDataType[]; length: number } {
        if (context.filter(func.name)) {
            const functionRowData = !context.countOnly
                ? [FunctionRowDataType.fromCacheOrCreate(this.env, func, database, this.treeEntityMapper)]
                : [];
            return { list: functionRowData, length: 1 };
        }

        return { list: [], length: 0 };
    }

    private getTableAndColumns(
        table: Table,
        database: Database,
        context: FilterFlowContext
    ): { list: RowDataType[]; length: number } {
        const result: RowDataType[] = [];
        let totalCount = 0;

        Object.values(table.columns).forEach((column) => {
            if (context.filter(column.name, column.type)) {
                if (!context.countOnly) {
                    const columnRowDataType = ColumnRowDataType.fromCacheOrCreate(
                        this.env,
                        column,
                        table,
                        database,
                        this.treeEntityMapper
                    );
                    result.push(columnRowDataType);
                }
                totalCount++;
            }
        });

        if (totalCount > 0 || context.filter(table.name)) {
            if (!context.countOnly) {
                const tableRowDataType = TableRowDataType.fromCacheOrCreate(
                    this.env,
                    table,
                    database,
                    this.treeEntityMapper
                );
                result.push(tableRowDataType);
            }
            totalCount++;
        }

        return { list: result, length: totalCount };
    }

    private getFolderWithDescendants<T extends EntityWithFolder>(
        folder: FolderNode<T>,
        database: Database,
        entityType: FolderEntityType,
        context: FilterFlowContext,
        getEntityDescendants: (entity: T) => { list: RowDataType[]; length: number }
    ): { list: RowDataType[]; length: number } {
        const descendants: RowDataType[] = [];
        let totalCount = 0;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.values(folder.children).forEach((child: any) => {
            if (child instanceof FolderNode) {
                const folderWithDescendants = this.getFolderWithDescendants<T>(
                    child,
                    database,
                    entityType,
                    context,
                    getEntityDescendants
                );
                if (!context.countOnly) {
                    descendants.push(...folderWithDescendants.list);
                }
                totalCount += folderWithDescendants.length;
            } else {
                const entityDescendants = getEntityDescendants(child);
                if (!context.countOnly) {
                    descendants.push(...entityDescendants.list);
                }
                totalCount += entityDescendants.length;
            }
        });

        if (!folder.isRoot && (totalCount > 0 || context.filter(folder.name))) {
            if (!context.countOnly) {
                descendants.push(
                    DatabaseFolderRowDataType.fromCacheOrCreate(
                        this.env,
                        database,
                        folder.path,
                        entityType,
                        this.treeEntityMapper
                    )
                );
            }
            totalCount += 1;
        }

        return { list: descendants, length: totalCount };
    }
}
