import React, { useCallback, useMemo, useState } from 'react';
import { Button, MenuItem, MenuList, Subtitle1 } from '@fluentui/react-components';
import { bundleIcon, Dismiss24Regular, GroupList20Filled, GroupList20Regular } from '@fluentui/react-icons';

import { DataTableColumn } from '@kusto/client';

import { useStrings } from '../../../hooks/useStrings';
import { useDataExplorationStore } from '../../../store/DataExplorationStore';
import { Pill } from '../../../types/Pills.interfaces';
import { AggregationPillPopover } from '../Popovers/AggregationPillPopover';
import { FilterPillPopover } from '../Popovers/FilterPillPopover';
import { ColumnSelect } from './ColumnSelect';

import styles from './AddPill.module.scss';

const GroupListIcon = bundleIcon(GroupList20Filled, GroupList20Regular);

/** The default popover that appear when clicking 'Add'. Selecting between 'filter' and 'aggregation'. */
const DefaultPopover: React.FunctionComponent<{
    onFilterSelect: (item: DataTableColumn) => void;
    onAggregationSelect: () => void;
    onCancel: () => void;
}> = ({ onFilterSelect, onAggregationSelect, onCancel }) => {
    const { strings } = useStrings();

    return (
        <div data-testid="add-pill-popover" className={styles.addPillPopover}>
            <div className={styles.filterContainer}>
                <Subtitle1>{strings.dataExplorationModal.pillsBar.addFilterTitle}</Subtitle1>
            </div>
            <div className={styles.filterContent}>
                <ColumnSelect onSelect={onFilterSelect} />
            </div>
            <div className={styles.aggregationContainer}>
                <Subtitle1>{strings.dataExplorationModal.pillsBar.addAggregationTitle}</Subtitle1>
            </div>
            <div className={styles.aggregationContent}>
                <MenuList>
                    <MenuItem icon={<GroupListIcon />} onClick={onAggregationSelect}>
                        {strings.dataExplorationModal.pillsBar.aggregation}
                    </MenuItem>
                </MenuList>
            </div>
            <div className={styles.closeButtonContainer}>
                <Button
                    appearance="subtle"
                    aria-label={strings.dataExplorationModal.shared.close}
                    icon={<Dismiss24Regular />}
                    onClick={onCancel}
                />
            </div>
        </div>
    );
};

export interface AddPillPopoverProps {
    onClose: () => void;
}

export const AddPillPopover: React.FunctionComponent<AddPillPopoverProps> = ({ onClose }) => {
    const { actions } = useDataExplorationStore();

    const [popoverType, setPopoverType] = useState<'default' | 'filter' | 'aggregation'>('default');
    const [selectedColumn, setSelectedColumn] = useState<DataTableColumn | undefined>();

    const closePopover = useCallback(() => {
        onClose();
        setPopoverType('default');
    }, [onClose]);

    const onApply = useCallback(
        (newPill: Pill) => {
            actions.addPill(newPill);
            closePopover();
        },
        [actions, closePopover]
    );

    const popoverContent = useMemo(() => {
        switch (popoverType) {
            case 'filter':
                return (
                    <FilterPillPopover
                        defaultValues={{ column: selectedColumn }}
                        onApply={onApply}
                        onCancel={closePopover}
                        newPill
                    />
                );
            case 'aggregation':
                return <AggregationPillPopover onApply={onApply} onCancel={closePopover} newPill />;
            default:
                return (
                    <DefaultPopover
                        onFilterSelect={(item) => {
                            setPopoverType('filter');
                            setSelectedColumn(item);
                        }}
                        onAggregationSelect={() => {
                            setPopoverType('aggregation');
                        }}
                        onCancel={closePopover}
                    />
                );
        }
    }, [closePopover, onApply, popoverType, selectedColumn]);

    return popoverContent;
};
