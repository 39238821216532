.dropdownOption {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
}

.fullPreviewContainer {
    display: grid;
    // This ensures we'll always have
    // each row filled completely by each column
    // See: https://css-tricks.com/auto-sizing-columns-css-grid-auto-fill-vs-auto-fit/
    grid-template-columns: repeat(auto-fit, minmax(32px, 1fr));
    grid-template-rows: repeat(auto-fit, 32px);
    gap: 8px;
}

.partialPreviewContainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(16px, 1fr));
    grid-template-rows: repeat(auto-fit, 16px);
    gap: 4px;
    margin-right: 8px;
}
