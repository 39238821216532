import isEmpty from 'lodash/isEmpty';

import { EntityWithFolder } from '../../../common';

/**
 * Represents a list of folders with entities as leafs. The entities must implement EntityWithFolder.
 * FolderTree transform a list of entities with a folder member to a tree structure of folders and entities.
 */
export class FolderTree<T extends EntityWithFolder> {
    root: FolderNode<T>;
    entityRoot: FolderNode<T>;

    constructor(entities: T[], entityRootFolderName: string) {
        this.root = new FolderNode<T>('', '', true);
        this.entityRoot = this.root.addFolder(entityRootFolderName);
        entities.forEach((entity) => this.addEntity(entity));

        // if entityRoot has no children, delete it
        if (isEmpty(this.entityRoot.children)) {
            this.root.removeFolder(this.entityRoot.name);
        }
    }

    private addEntity(entity: T) {
        if (isEmpty(entity.folder)) {
            this.addFolderlessEntity(entity);
            return;
        }

        let currentFolder: FolderNode<T> = this.entityRoot;

        entity.folder!.split(/[\\/]/g).forEach((folderName) => {
            currentFolder = currentFolder.addFolder(folderName);
        });

        currentFolder!.addEntity(entity);
    }

    protected addFolderlessEntity(entity: T) {
        this.entityRoot.children[entity.id] = entity;
    }
}

/**
 * The 'Tables' folder is different in the sense that it shows every folder-less table (e.g. a table with an empty 'entity.folder')
 * directly under the database folder (the root folder) and not under the 'Tables' folder (the entityRoot folder).
 */
export class TableFolderTree<T extends EntityWithFolder> extends FolderTree<T> {
    /**
     * Any table entity with no folder (e.g. entity.folder is empty) should be under the root (e.g. the database) and not under the Tables folder.
     */
    protected addFolderlessEntity(entity: T) {
        this.root.children[entity.id] = entity;
    }
}

export class FolderNode<T extends EntityWithFolder> {
    name = '';
    children: { [id: string]: FolderNode<T> | T } = {};
    path: string;
    parent?: FolderNode<T>; // mainly for debugging
    isRoot: boolean;

    constructor(name: string, parentPath: string, isRoot = false) {
        this.name = name;
        this.path = isEmpty(parentPath) ? name : parentPath + '/' + name;
        this.isRoot = isRoot;
    }

    addFolder(folderName: string): FolderNode<T> {
        let folder = this.children[folderName];
        if (!folder) {
            folder = new FolderNode(folderName, this.path);
            folder.parent = this;
            this.children[folderName] = folder;
        }
        return folder as FolderNode<T>;
    }

    addEntity(entity: T) {
        this.children[entity.id] = entity;
    }

    removeFolder(folderName: string) {
        delete this.children[folderName];
    }
}
