{
    "queryResults": "Query results",
    "visualOptions": {
        "openVisualFormattingButtonAria": "Open visual options pane",
        "closeVisualFormattingButtonAria": "Close visual options pane",
        "visualFormatting": "Visual Formatting",
        "titleInputLabel": "Title",
        "hideTitleCheckboxLabel": "Hide title",
        "pinToDashboard": {
            "warningMessage": {
                "text": "Any visual formatting you do here does not change the query. To keep these changes, click",
                "link": "'Pin to dashboard'."
            },
            "button": "Pin to dashboard"
        }
    },
    "screenReader$QueryCompletedWithDatasets": "Query finished running. ${numberOfDatasets} datasets returned.",
    "screenReader$QueryCompletedNoDatasets": "Query Finished Running.",
    "screenReader$QueryCancelled": "Query Cancelled",
    "chartsErrorRenderingException": "Internal error: could not render chart",
    "showEmptyColumnsTooltip": "Show empty columns",
    "hideEmptyColumnsTooltip": "Hide empty columns",
    "button$hideEditor": "Hide editor",
    "button$showEditor": "Show editor",
    "stats": "Stats",
    "graph": "Graph",
    "visual": "Visual",
    "addVisual": "Add visual",
    "editVisual": "Edit visual",
    "editVisual.commnent": "Button text for editing the visual if added",
    "addVisualDisabled": "Add visual is only supported for a single result set",
    "query": "Query",
    "tableResultNoName": "Table ${tableNumber}",
    "copyActivityIdLabel": "Copy request ID",
    "copyActivityIdDone": "Copied",
    "copyActivityId": "Copy request ID to clipboard",
    "resultsCount": "${resultRowCount} records",
    "executionStatusCached": "Cached (${executionTimeInSeconds} s)",
    "executionStatusError": "Error (${executionTimeInSeconds} s)",
    "executionStatusCancelled": "Cancelled (${executionTimeInSeconds} s)",
    "executionStatusDone": "Done (${executionTimeInSeconds} s)",
    "executionStatusPreparing": "Preparing to run",
    "executionStatusRunning": "Running",
    "querySearchResults$buttonText": "Search",
    "statsTime": "Time",
    "statsTimeLabel": "Execution Time",
    "statsCPU": "CPU",
    "statsCPUTotal": "Total",
    "statsCPUKernel": "Kernel",
    "statsCPUUser": "User",
    "statsMemory": "Memory",
    "statsMemoryPeaks": "Peak per node",
    "statsDataSet": "Data set",
    "statsDataSetRows": "Rows",
    "statsDataSetSize": "Size",
    "statsDiskCache": "Disk cache",
    "statsMemoryCache": "Memory cache",
    "statsHits": "Hits",
    "statsMisses": "Misses",
    "statsClientRequestIDButtonTooltip": "Client request ID",
    "statsGeneralCardHeader": "General",
    "statsGeneralCpuLoadLabel": "CPU load",
    "statsGeneralParallelismLabel": "Parallelism",
    "statsPerformanceCardHeader": "Performance",
    "statsPerformanceDataScannedLabel": "Data scanned (estimated)",
    "statsPerformanceColdDataAccessedLabel": "Cold data accessed (estimated)",
    "statsExternalDataCardHeader": "External data",
    "statsExternalDataDownloadedSizeLabel": "Downloaded size",
    "statsExternalDataDownloadedFilesLabel": "Downloaded files",
    "statsExternalDataSkippedFilesLabel": "Skipped files",
    "statsMetadataJsonCardHeader": "Raw JSON Preview",
    "statsMetadataJsonCardViewJsonLink": "View full JSON",
    "statsMetadataJsonPanelTitle": "Raw JSON",
    "loadAssessmentHigh": "High",
    "loadAssessmentMedium": "Medium",
    "loadAssessmentLow": "Low",
    "ctrlNeededDialog$okButton": "Got it, thanks",
    "ctrlNeededDialog$text": "Hyperlinks in the query results table are opened with ${ctrlOrCommand}+Click.",
    "ctrlNeededDialog$title": "Press ${ctrlOrCommand} to navigate",
    "cancel": "Cancel",
    "apply": "Apply",
    "editor$textEditor": "Edit as multi-line text",
    "close": "Close",
    "tabs": "Tabs",
    "undoClosedTabButtonAria": "Reopen closed tab",
    "undoClosedTabButtonTooltip": "Reopen closed tab (${ctrlOrCommand}+Shift+Alt+T)",
    "closeAllTabs": "Close all tabs",
    "closeTabAriaLabel": "Press DELETE to remove.",
    "kwe$authorizationError": "You are not authorized to perform this operation. Please make sure you have permissions to access this cluster, and that you are logged in with the right user.",
    "kwe$authorizationErrorWithUserOnly": "You are not authorized to perform this operation. Please make sure you have permissions to access this cluster, and that you are logged in with the right user. \nLogged-in user: ${accountEmail}",
    "kwe$authorizationErrorWithUserAndTenant": "You are not authorized to perform this operation. Please make sure you have permissions to access this cluster, and that you are logged in with the right user. \nLogged-in user: ${accountEmail} \ntenant ID: ${tenantId}",
    "clientRequestIdNotNull": "self.clientRequestId should not be null",
    "selectDatabase": "Select a database to execute queries.",
    "unknownDirective": "Unknown client directive ${directive}",
    "cannotConnectClusters": "Cannot connect to other clusters from Azure Portal.\nclick \"Open in Web UI\" to connect to multiple clusters.",
    "newTab": "New tab",
    "grisStatusPanel$selected": "Selected",
    "grisStatusPanel$timeDiff": "Time diff",
    "grisStatusPanel$avg": "Average",
    "grisStatusPanel$count": "Count",
    "grisStatusPanel$min": "Min",
    "grisStatusPanel$max": "Max",
    "grisStatusPanel$sum": "Sum",
    "recallExecutionResult": "Recall execution result",
    "editor$unescapeString": "Decode multi-line string to text",
    "editor$escapeString": "Encode text to multi-line string",
    "linkQueryResultsToClipboard": "Link, query, results to clipboard",
    "copyQueryLinkClipboard": "Copy query and link To clipboard",
    "copyLinkClipboard": "Copy link to clipboard",
    "exportResultsCSV": "Export results to CSV",
    "switchTabLeft": "Switch to tab on the left",
    "switchTabRight": "Switch to tab on the right",
    "closeTab": "Close tab",
    "badConnection": "Failed to connect to cluster. Please verify the URI and check if the cluster is available.",
    "badConnection$privateConnection": "Failed to connect to cluster. It might be that you have'nt created a private endpoint in your Azure Data Explorer cluster (in your virtual network).",
    "deepLinkHandler$connectionTestFailed": "Could not connect to cluster",
    "connectionPane$search$tooManyResults": "Too many results, try refining your filter.",
    "connectionPane$search$noResults": "No results",
    "expndViewDisabledTooltip": "Only inline option is supported for smaller screens.",
    "copy": "Copy",
    "grid$copyAsDataTable": "Copy as datatable",
    "exportCSV": "Export to CSV",
    "exportExcel": "Export to Excel",
    "grid$selectAllColumn": "Show/Hide all columns",
    "grid$exploreResults": "Explore results",
    "grid$colorByValue": "Color by value",
    "grid$addAsFilters": "Add selection as filters",
    "copyHeaders": "Copy with headers",
    "copyAsHtml": "Copy as HTML",
    "queryEditor": "Query editor",
    "addNewTab": "Add new tab",
    "addNewTabDescription": "New tab",
    "openTabs": "Open tabs list",
    "renameTabButtonTooltip": "Rename tab",
    "connectionPaneMoreOptionAriaLabel": "Press Shift + F10 for more options",
    "connectionPane": "Connection pane",
    "expandLeftPane": "Expand left pane",
    "collapseLeftPane": "Collapse left pane",
    "grid$searchOoo": "Search...",
    "timeZoneIndicatorToolTip": "Query time is set to ${timeZone}. Click to change.",
    "queryDetailedViewInlineLayout": {
        "label": "Inline",
        "description": "Expand view in the table as inline row"
    },
    "queryDetailedViewBelowLayout": {
        "label": "Below",
        "description": "Expand view below the table"
    },
    "queryDetailedViewRightLayoutLabel": "Right",
    "queryDetailedViewRightLayoutDescription": "Expand view at the right part of the screen",
    "expandViewToolTip": "Expand view",
    "expandViewLabel": "Expand view",
    "expandViewAriaLabel": "Expand view: ${selected}",
    "queryContentViewMode": {
        "label": "Content view",
        "ariaLabel": "Content view: ${selected}",
        "toolTip": "Content view",
        "fullModeLabel": "Full",
        "fullModeDescription": "Show content in full mode",
        "compactModeLabel": "Compact",
        "compactModeDescription": "Show content in compact mode"
    },
    "resizePaneAria": "Resize pane",
    "duplicateQuery": "Duplicate query",
    "tabsHeader": {
        "tabTooltip": {
            "database": "Database",
            "cluster": "Cluster"
        },
        "addTab": "New tab",
        "closeTab": "Close tab",
        "editTab": "Edit tab name",
        "rename": "Rename",
        "discard": "Discard",
        "tabMenu": "Open tabs list",
        "reopenClosedTab": "Reopen closed tab",
        "closeAllTabs": "Close all tabs",
        "closeOtherTabs": "Close other tabs",
        "scrollRight": "Scroll right",
        "scrollLeft": "Scroll left"
    },
    "dataProfile": {
        "showInlineToolTip": "Pin",
        "refreshToolTip": "Refresh",
        "errorStateTitle": "Something went wrong...",
        "showOverlayToolTip": "Unpin"
    },
    "schema": {
        "schemaFetchFailed": "Failed to fetch schema: ${errorText}"
    }
}