import React from 'react';
import { Icon } from '@fluentui/react';
import classNames from 'classnames';

import type { KweVisualFwkLocale } from '../../../types';
import { SchemaState } from '../../../visualConfig/input';
import { ColorResolutionSet, ColorRuleThemeColorValues } from '../../../visualConfig/visualConfig';
import { ColorRule } from '../../../visualOptions/property';
import { ColorRulesVisualType } from '../filterRules';

import * as gridStyles from '../../configurationList/grid.module.scss';
import * as styles from '../styles.module.scss';

// TODO: Add support for datetime and timespan numeric types
const SUPPORTED_NUMERIC_TYPES = ['real', 'decimal', 'int', 'long'];

export function isColumnNumeric(columnName: string, schema: SchemaState) {
    const column = schema.kind === 'available' ? schema.schema.find((col) => col.name === columnName) : null;
    const columnType = column?.type;
    return !!columnType && SUPPORTED_NUMERIC_TYPES.includes(columnType);
}

export function useThemeCanvas(colors: string[]) {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    React.useLayoutEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const context = canvas.getContext('2d');
            if (context) {
                const grd = context.createLinearGradient(0, 0, 200, 0);
                colors.forEach((color, index) => grd.addColorStop(index / (colors.length - 1), color));
                context.fillStyle = grd;
                context.fillRect(0, 0, context.canvas.width, context.canvas.height);
            }
        }
    }, [colors]);
    return canvasRef;
}

interface ColorRulePreviewColorProps {
    boldColors: ColorResolutionSet;
    lightColors: ColorResolutionSet;
    colorsByTheme: ColorRuleThemeColorValues;
    rule: ColorRule.UColorRule;
}

const staticNoColors: string[] = [];

export function ColorRulePreviewColor({ boldColors, lightColors, colorsByTheme, rule }: ColorRulePreviewColorProps) {
    const themeColors =
        rule.ruleType === 'colorByValue' && rule.themeName ? colorsByTheme[rule.themeName].colors : staticNoColors;

    const canvasRef = useThemeCanvas(themeColors);

    const colorByValueIcon = React.useMemo(() => {
        if (rule.ruleType === 'colorByValue') {
            return themeColors.length > 0 ? (
                <canvas ref={canvasRef} className={classNames(styles.configItemColor, gridStyles.color)} />
            ) : (
                <Icon iconName="BucketColor" className={classNames(styles.configItemColor, gridStyles.color)} />
            );
        }
    }, [rule, canvasRef, themeColors]);

    const conditionColors: Record<ColorRule.ColorStyle, ColorResolutionSet> = {
        bold: boldColors,
        light: lightColors,
    };

    const colorByConditionColor =
        rule.ruleType === 'colorByCondition' && rule.color
            ? conditionColors[rule.colorStyle][rule.color].color
            : undefined;

    return (
        <>
            {rule.ruleType === 'colorByValue' && colorByValueIcon}
            {rule.ruleType === 'colorByCondition' && (
                <div
                    className={classNames(styles.configItemColor, gridStyles.color)}
                    style={{ backgroundColor: colorByConditionColor }}
                />
            )}
        </>
    );
}

export function isRuleColumnInferrable(visualType: ColorRulesVisualType) {
    return visualType === 'stat';
}

export function formatConditions(
    strings: KweVisualFwkLocale,
    rule: ColorRule.ColorRuleByCondition,
    visualType: ColorRulesVisualType
) {
    return rule.conditions
        .map((cond) => conditionText(strings, cond, rule.conditions.length > 1, isRuleColumnInferrable(visualType)))
        .join(` ${rule.chainingOperator} `);
}

export function calculateDefaultRuleName(
    strings: KweVisualFwkLocale,
    rule: ColorRule.UColorRule,
    visualType: ColorRulesVisualType
) {
    if (rule.ruleName !== '') {
        return rule.ruleName;
    }
    if (rule.ruleType === 'colorByCondition') {
        return formatConditions(strings, rule, visualType);
    } else {
        return rule.themeName
            ? strings.visualFwk.visualConfig.colorRules.themeDropdownOptionsText[rule.themeName]
            : strings.visualFwk.visualConfig.colorRules.colorByValueOption;
    }
}

function getNullColumnLabel(strings: KweVisualFwkLocale, inferrable?: boolean) {
    return inferrable
        ? strings.visualFwk.visualConfig.dropdownInputInferOption
        : strings.visualFwk.visualConfig.colorRules.noSelectionText;
}

export function formatColorRuleColumn(strings: KweVisualFwkLocale, column: string | null, inferrable?: boolean) {
    return column === null ? getNullColumnLabel(strings, inferrable) : column;
}

function conditionText(
    strings: KweVisualFwkLocale,
    condition: ColorRule.Condition,
    wrapWithBraces: boolean,
    inferrable: boolean
) {
    if (condition.column === null && !inferrable) {
        // In case the column is not inferrable (we show "no selection"), then we don't want to concat the operators as well
        return getNullColumnLabel(strings);
    }
    const columnName = formatColorRuleColumn(strings, condition.column, inferrable);
    let text = '';
    if (condition.operator === 'between') {
        text = `${condition.values[0] ?? ''} <= ${columnName} <= ${condition.values[1] ?? ''}`;
    } else {
        text = `${columnName} ${condition.operator} ${condition.values[0] ?? ''}`;
    }
    return wrapWithBraces ? `(${text})` : text;
}
