import * as React from 'react';
import { useFieldControlProps_unstable } from '@fluentui/react-field';
import { getPartitionedNativeProps, useControllableState, useEventCallback, slot } from '@fluentui/react-utilities';
import { useOverrides_unstable as useOverrides } from '@fluentui/react-shared-contexts';
/**
 * Create the state required to render Input.
 *
 * The returned state can be modified with hooks such as useInputStyles_unstable,
 * before being passed to renderInput_unstable.
 *
 * @param props - props from this instance of Input
 * @param ref - reference to `<input>` element of Input
 */ export const useInput_unstable = (props, ref)=>{
    props = useFieldControlProps_unstable(props, {
        supportsLabelFor: true,
        supportsRequired: true,
        supportsSize: true
    });
    const overrides = useOverrides();
    var _overrides_inputDefaultAppearance;
    const { size = 'medium', appearance = (_overrides_inputDefaultAppearance = overrides.inputDefaultAppearance) !== null && _overrides_inputDefaultAppearance !== void 0 ? _overrides_inputDefaultAppearance : 'outline', onChange } = props;
    if (process.env.NODE_ENV !== 'production' && (appearance === 'filled-darker-shadow' || appearance === 'filled-lighter-shadow')) {
        // eslint-disable-next-line no-console
        console.error("The 'filled-darker-shadow' and 'filled-lighter-shadow' appearances are deprecated and will be removed in the" + ' future.');
    }
    const [value, setValue] = useControllableState({
        state: props.value,
        defaultState: props.defaultValue,
        initialState: ''
    });
    const nativeProps = getPartitionedNativeProps({
        props,
        primarySlotTagName: 'input',
        excludedPropNames: [
            'size',
            'onChange',
            'value',
            'defaultValue'
        ]
    });
    const state = {
        size,
        appearance,
        components: {
            root: 'span',
            input: 'input',
            contentBefore: 'span',
            contentAfter: 'span'
        },
        input: slot.always(props.input, {
            defaultProps: {
                type: 'text',
                ref,
                ...nativeProps.primary
            },
            elementType: 'input'
        }),
        contentAfter: slot.optional(props.contentAfter, {
            elementType: 'span'
        }),
        contentBefore: slot.optional(props.contentBefore, {
            elementType: 'span'
        }),
        root: slot.always(props.root, {
            defaultProps: nativeProps.root,
            elementType: 'span'
        })
    };
    state.input.value = value;
    state.input.onChange = useEventCallback((ev)=>{
        const newValue = ev.target.value;
        onChange === null || onChange === void 0 ? void 0 : onChange(ev, {
            value: newValue
        });
        setValue(newValue);
    });
    return state;
};
