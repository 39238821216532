import React from 'react';
import { observer } from 'mobx-react-lite';

import { createTileInput, Markdown, markdownSizes, VisualTypeConfig } from '@kusto/visual-fwk';

import type { RtdProviderLocale } from '../../i18n';
import { markdown_getInferredTableValue, Markdown_Heuristics } from './heuristics';

import * as styles from './MarkdownVisual.module.scss';

const model = { xColumn: null } as const;

type ModelDef = keyof typeof model;

export function markdownVisualConfig(
    t: RtdProviderLocale
): VisualTypeConfig<ModelDef, undefined | Markdown_Heuristics> {
    return {
        label: t.rtdProvider.visuals.markdown.name,
        iconName: 'MarkDownLanguage',
        config: {
            model,
            ...markdownSizes,
            inputLayout: {
                visual: {
                    head: [
                        createTileInput.column('xColumn', t.rtdProvider.visuals.markdown.columnDropdownLabel, {
                            selectInferColumn: (options) => options.getHeuristics()?.inferColumnName,
                            selectOptionDisabled: (column) => column.type !== 'string',
                            selectErrorMessage: (options) => {
                                const e = options.getHeuristics()?.value.err;
                                return !e?.columnError ? undefined : e.value;
                            },
                        }),
                        {
                            id: 'markdown-visual--description',
                            keys: [],
                            Component: () => (
                                <p className={styles.description}>{t.rtdProvider.visuals.markdown.description}</p>
                            ),
                        },
                    ],
                },
            },
            heuristics: (props) => {
                const result = props.queryResult;

                if (!result) {
                    return undefined;
                }

                return markdown_getInferredTableValue(
                    t.rtdProvider.visuals.markdown,
                    result.dataFrame,
                    props.visualOptions.xColumn
                );
            },
            Component: observer(function MarkdownVisual(props) {
                const heuristics =
                    props.heuristics ??
                    markdown_getInferredTableValue(
                        t.rtdProvider.visuals.markdown,
                        props.queryResult.dataFrame,
                        props.visualOptions.xColumn
                    );

                if (heuristics.value.kind === 'err') {
                    return props.formatMessage({ message: heuristics.value.err.value, level: 'error' });
                }

                const value = heuristics.value.value;

                return (
                    <Markdown
                        onLinkClick={props.onLinkClick}
                        markdownText={value ? value : ''}
                        enableImageOverflow={props.featureFlags.EnableMarkdownImageOverflow}
                    />
                );
            }),
        },
    };
}
