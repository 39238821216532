@use '@kusto/style/typography';

// Styling for a single label and input wrapped in an element
.basicInput {
    // Avoids extra whitespace
    display: flex;
    flex-direction: column;
    // Avoid top level margins by default, container is already applying a gap
    margin: 0;
    label {
        @extend %typography--body1Strong;
        padding: 0;
        // Top and bottom have 3 subtracted to compensate for light height
        margin: -3px 0 5px 0;
    }
}
