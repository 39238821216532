import { createColorRulesInput } from './colorRules/ColorRules';
import { createInferableMultipleColumnOption, createSingleColumConfigOption } from './columnDropdowns';
import { createBoolToggle, createStaticDropdown, createStaticMultiSelect, createTextInput } from './configUtils';
import { createCrossFilterConfig } from './CrossFilter/CrossFilter';
import { createDrillthroughConfig } from './Drillthrough/Drillthrough';
import { createSlotInput } from './slot/createSlotInput';

import * as configurationListStyles from '../inputLib/configurationList/styles.module.scss';
import * as styles from './styles.module.scss';

export * from './configurationList/ConfigurationItemCallout';
export * from './configurationList/ConfigurationItemRow';
export * from './configurationList/ConfigurationItemRowErrors';

export * from './CrossFilter/host';
export * from './Drillthrough/host';

/**
 * Contains visual input library functions. Inputs only used by specific visuals should be kept alongside them, and not added here.
 */
export const createTileInput = {
    text: createTextInput,
    toggle: createBoolToggle,
    column: createSingleColumConfigOption,
    columnsNullable: createInferableMultipleColumnOption,
    staticDropdown: createStaticDropdown,
    staticMultiSelect: createStaticMultiSelect,
    colorRules: createColorRulesInput,
    crossFilter: createCrossFilterConfig,
    drillthrough: createDrillthroughConfig,
    slot: createSlotInput,
};

export const tileInputClassNames = {
    basicInput: styles.basicInput,
    addConfigItemButton: configurationListStyles.addConfigItemButton,
    addConfigItemButtonMarginAdjust: configurationListStyles.addConfigItemButtonMarginAdjust,
};
