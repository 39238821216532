import * as React from 'react';
import { Button, mergeClasses, OnOpenChangeData, Popover, PopoverSurface, Text } from '@fluentui/react-components';
import { Position } from '@fluentui/react-positioning';

import { useThemeState } from '@kusto/utils';

import * as styles from './ConfigurationItemCallout.module.scss';

export interface ConfigurationItemCalloutProps {
    onClose: () => void;
    onSave: () => void;
    /** The element ID next to which the callout should open */
    targetId: string;
    title: string;
    className?: string;
    position?: Position;
    applyButtonLabel: string;
    cancelButtonLabel: string;
}

export const ConfigurationItemCallout: React.FC<ConfigurationItemCalloutProps> = ({
    onClose,
    onSave,
    targetId,
    title,
    children,
    className,
    position = 'below',
    applyButtonLabel,
    cancelButtonLabel,
}) => {
    const theme = useThemeState();

    const [ref, setRef] = React.useState<HTMLElement>();
    React.useLayoutEffect(() => {
        const value = targetId ? document.getElementById(targetId) : undefined;
        if (value) {
            setRef(value);
        }
    }, [targetId]);

    const handleOpenChanged = (_e: unknown, data: OnOpenChangeData) => {
        if (!data.open) {
            onClose();
        }
    };

    return (
        <div className={styles.wrapper}>
            <Popover positioning={{ target: ref, position }} withArrow open={!!ref} onOpenChange={handleOpenChanged}>
                <PopoverSurface className={mergeClasses(styles.dialogContent, theme.classNames, className)}>
                    <Text size={500} weight="semibold">
                        {title}
                    </Text>
                    {children}
                    <div className={styles.footer}>
                        <Button appearance="primary" onClick={onSave}>
                            {applyButtonLabel}
                        </Button>
                        <Button appearance="secondary" onClick={onClose}>
                            {cancelButtonLabel}
                        </Button>
                    </div>
                </PopoverSurface>
            </Popover>
        </div>
    );
};
