import { ColumnType } from '@kusto/charting';
import { DataTableColumn } from '@kusto/client';

// -------------------------------------------- Filter Pill --------------------------------------------

export const UNARY_OPERATORS = ['isnull', 'isnotnull', 'isempty', 'isnotempty'] as const;

/** Operators that act on a single item, like: `isempty` */
export type UnaryOperator = (typeof UNARY_OPERATORS)[number];

export const BINARY_OPERATORS = [
    '==',
    '!=',
    '>',
    '<',
    '>=',
    '<=',
    'contains',
    '!contains',
    'has',
    '!has',
    'startswith',
    '!startswith',
    'endswith',
    '!endswith',
] as const;

export const isBinaryOperator = (operator = '') => BINARY_OPERATORS.includes(operator as BinaryOperator);

/** Operators that compare 2 items, like: `==`, `startswith` */
export type BinaryOperator = (typeof BINARY_OPERATORS)[number];

export const NUMERIC_OPERATORS: (BinaryOperator | UnaryOperator)[] = [
    '==',
    '!=',
    '>',
    '<',
    '>=',
    '<=',
    'isnull',
    'isnotnull',
    // TODO: add operator 'between(a .. b)'
];

export const STRING_OPERATORS: (BinaryOperator | UnaryOperator)[] = [
    '==',
    '!=',
    'contains',
    '!contains',
    'has',
    '!has',
    'startswith',
    '!startswith',
    'endswith',
    '!endswith',
    'isempty',
    'isnotempty',
];
export const BOOL_OPERATORS: (BinaryOperator | UnaryOperator)[] = ['==', '!=', 'isnull', 'isnotnull'];

export const STRING_COLUMN_TYPES: ColumnType[] = ['string', 'guid'];
export const NUMERIC_COLUMN_TYPES: ColumnType[] = ['int', 'long', 'real', 'decimal'];
export const BOOL_COLUMN_TYPES: ColumnType[] = ['bool'];

export const operatorsByType: Record<ColumnType, (BinaryOperator | UnaryOperator)[]> = {
    string: STRING_OPERATORS,
    guid: STRING_OPERATORS,
    int: NUMERIC_OPERATORS,
    long: NUMERIC_OPERATORS,
    real: NUMERIC_OPERATORS,
    decimal: NUMERIC_OPERATORS,
    datetime: NUMERIC_OPERATORS,
    timespan: NUMERIC_OPERATORS,
    dynamic: NUMERIC_OPERATORS,
    bool: BOOL_OPERATORS,
};

export interface BinaryFilterPillData {
    /** column to run filter on */
    column: DataTableColumn;
    /** filter operator; example: `==`, `startswith` */
    operator: BinaryOperator;
    /** value to compare to */
    value: string;
}

export interface UnaryFilterPillData {
    /** column to run filter on */
    column: DataTableColumn;
    /** filter operator; example: `isnull`, `isempty` */
    operator: UnaryOperator;
}

export type FilterPillData = BinaryFilterPillData | UnaryFilterPillData;

export interface FilterPill {
    type: 'filter';
    data: FilterPillData;
}

// -------------------------------------------- Aggregation Pill --------------------------------------------

export const AGGREGATION_OPERATORS = ['count', 'dcount', 'sum', 'min', 'max', 'avg'] as const;

/** Aggregation functions that act on 0 or 1 item, like: `count()`, `max(Num)` */
export type AggregationOperator = (typeof AGGREGATION_OPERATORS)[number];

export interface AggregationFunction {
    /** function to execute on the data; example: `count()`, `sum()` */
    operator: AggregationOperator;
    /** column to pass as a parameter of the function (not all function have column) */
    column?: DataTableColumn;
    /** alternative name for the new column */
    displayName?: string;
}

export type TimespanUnit = 'ms' | 's' | 'm' | 'h' | 'd' | 'w';

export interface AggregationGroup {
    /** column to group by */
    column: DataTableColumn;
    /** timespan for the `bin()` function */
    timespan?: {
        value: number;
        unit: TimespanUnit;
    };
    /** alternative name for the new column */
    displayName?: string;
}

export interface AggregationPillData {
    functions: AggregationFunction[]; // for now the ui have only one function, but the API support multiple
    groups: AggregationGroup[];
}

export interface AggregationPill {
    type: 'aggregation';
    data: AggregationPillData;
}

// -------------------------------------------- DateTime Pill --------------------------------------------

export interface DateTimePillData {
    column: DataTableColumn;
    start: Date;
    end: Date;
}

export interface DateTimePill {
    type: 'datetime';
    data: DateTimePillData;
}

// -------------------------------------------- Limit Pill --------------------------------------------

export interface LimitPillData {
    max: number;
}

export interface LimitPill {
    type: 'limit';
    data: LimitPillData;
}

// -------------------------------------------- Pill --------------------------------------------

export type Pill = FilterPill | AggregationPill | DateTimePill | LimitPill;
