import * as React from 'react';
import { PanelType, Stack } from '@fluentui/react';
import { Button } from '@fluentui/react-components';
import { Add20Regular } from '@fluentui/react-icons';

import { RTDPanel, useThemeState, useThunkReducer } from '@kusto/utils';

import type { KweVisualFwkLocale } from '../../../types';
import type { SchemaState } from '../../../visualConfig/input';
import type { ColorResolutionSet, ColorRuleThemeColorValues } from '../../../visualConfig/visualConfig';
import type { ColorRule } from '../../../visualOptions';
import type { ColorRulesVisualType } from '../filterRules';
import {
    ApplyOptionsInput,
    ApplyToColumnInput,
    ColorByValueColumn,
    ColorInput,
    ColorStyleInput,
    ConditionRow,
    HideTextToggle,
    IconInput,
    ReverseThemeToggle,
    RuleNameInput,
    RuleTypeInput,
    TagInput,
    ThemeInput,
    ThemeMaxValueInput,
    ThemeMinValueInput,
    ThemePreview,
} from './ColorRulesPanelInputs';
import { ColorRulePanelActions, colorRulePanelReducer } from './reducer';
import { isColumnNumeric, isRuleColumnInferrable } from './utils';

import * as styles from './colorRulePanel.module.scss';

export interface ColorRulePanelProps {
    t: KweVisualFwkLocale;
    visualType: ColorRulesVisualType;
    schema: SchemaState;
    rule: ColorRule.UColorRule;
    onClose: () => void;
    onSave: (rule: ColorRule.UColorRule) => void;
    boldColors: ColorResolutionSet;
    lightColors: ColorResolutionSet;
    themeColors: ColorRuleThemeColorValues;
}

export const ColorRulePanel: React.FC<ColorRulePanelProps> = ({
    t,
    visualType,
    schema,
    rule,
    onClose,
    onSave,
    boldColors,
    lightColors,
    themeColors,
}) => {
    const { classNames } = useThemeState();

    const [state, dispatch, getState] = useThunkReducer(colorRulePanelReducer, rule);

    const innerOnSave = React.useCallback(() => onSave(getState()), [onSave, getState]);

    const onRenderFooterContent = React.useCallback(
        () => (
            <>
                <Button appearance="primary" onClick={innerOnSave}>
                    {t.utils.util.buttons.save}
                </Button>
                <Button appearance="secondary" onClick={onClose}>
                    {t.utils.util.buttons.cancel}
                </Button>
            </>
        ),
        [onClose, innerOnSave, t]
    );

    return (
        <RTDPanel
            className={`${styles.colorRulePanel} ${classNames}`}
            isOpen={true}
            type={PanelType.custom}
            onDismiss={onClose}
            headerText={t.visualFwk.visualConfig.colorRules.dialogTitle}
            onRenderFooterContent={onRenderFooterContent}
            closeButtonAriaLabel={t.utils.util.buttons.close}
            isFooterAtBottom={true}
            withoutHostId
        >
            <>
                {state.ruleType === 'colorByCondition' && (
                    <RuleByCondition
                        t={t}
                        visualType={visualType}
                        schema={schema}
                        dispatch={dispatch}
                        rule={state}
                        boldColors={boldColors}
                        lightColors={lightColors}
                    />
                )}
                {state.ruleType === 'colorByValue' && (
                    <RuleByValue
                        t={t}
                        visualType={visualType}
                        schema={schema}
                        dispatch={dispatch}
                        rule={state}
                        themeColors={themeColors}
                    />
                )}
            </>
        </RTDPanel>
    );
};

interface RuleByConditionProps {
    t: KweVisualFwkLocale;
    visualType: ColorRulesVisualType;
    rule: ColorRule.ColorRuleByCondition;
    dispatch: React.Dispatch<ColorRulePanelActions>;
    schema: SchemaState;
    boldColors: ColorResolutionSet;
    lightColors: ColorResolutionSet;
}

const RuleByCondition: React.FC<RuleByConditionProps> = ({
    t,
    visualType,
    rule,
    dispatch,
    schema,
    boldColors,
    lightColors,
}) => {
    const addCondition = React.useCallback(() => {
        dispatch({ type: 'addCondition', condition: { column: null, operator: '>', values: [] } });
    }, [dispatch]);

    const colors = rule.colorStyle === 'bold' ? boldColors : lightColors;

    const hasMultipleConditions = rule.conditions.length > 1;

    return (
        <>
            <div className={styles.section}>
                <RuleTypeInput
                    rule={rule}
                    visualType={visualType}
                    dispatch={dispatch}
                    t={t}
                    disabled={visualType === 'stat'}
                />
                <RuleNameInput rule={rule} visualType={visualType} dispatch={dispatch} t={t} />
                <ColorStyleInput rule={rule} dispatch={dispatch} t={t} />
            </div>
            <div className={styles.sectionTitle} role="heading" aria-level={2}>
                {t.visualFwk.visualConfig.colorRules.conditionsSectionTitle}
            </div>
            <Stack tokens={{ childrenGap: 8 }}>
                {rule.conditions.map((condition, index) => {
                    if (index !== 0 && index !== 1) {
                        return false;
                    }
                    return (
                        <div
                            role="group"
                            key={index}
                            className={`${styles.conditionContainer} ${
                                hasMultipleConditions ? styles.withChaining : ''
                            }`}
                        >
                            <ConditionRow
                                chainingOperator={rule.chainingOperator}
                                showDelete={hasMultipleConditions}
                                schema={schema}
                                condition={condition}
                                dispatch={dispatch}
                                index={index}
                                enableInfer={isRuleColumnInferrable(visualType)}
                                t={t}
                            />
                        </div>
                    );
                })}
            </Stack>
            <Button
                style={{ padding: '0 8px', marginTop: 12 }}
                icon={<Add20Regular />}
                appearance="transparent"
                disabledFocusable={hasMultipleConditions}
                disabled={hasMultipleConditions}
                onClick={addCondition}
            >
                {t.visualFwk.visualConfig.colorRules.addConditionButtonText}
            </Button>
            <div className={styles.sectionTitle} role="heading" aria-level={2}>
                {t.visualFwk.visualConfig.colorRules.formattingSectionTitle}
            </div>
            <div className={styles.section}>
                {visualType !== 'stat' && (
                    <>
                        <ApplyOptionsInput rule={rule} visualType={visualType} dispatch={dispatch} t={t} />
                        <ApplyToColumnInput
                            dispatch={dispatch}
                            schema={schema}
                            rule={rule}
                            t={t}
                            disabled={rule.applyTo === 'rows'}
                        />
                        <HideTextToggle rule={rule} dispatch={dispatch} t={t} disabled={rule.applyTo === 'rows'} />
                    </>
                )}
                <ColorInput rule={rule} dispatch={dispatch} colors={colors} t={t} />
                <TagInput rule={rule} dispatch={dispatch} t={t} disabled={rule.applyTo === 'rows'} />
                <IconInput rule={rule} dispatch={dispatch} t={t} disabled={rule.applyTo === 'rows'} />
            </div>
        </>
    );
};

interface RuleByValueProps {
    t: KweVisualFwkLocale;
    visualType: ColorRulesVisualType;
    rule: ColorRule.ColorRuleByValue;
    dispatch: React.Dispatch<ColorRulePanelActions>;
    schema: SchemaState;
    themeColors: ColorRuleThemeColorValues;
}

const RuleByValue: React.FC<RuleByValueProps> = ({ t, visualType, rule, dispatch, schema, themeColors }) => {
    const isNumericColumn = React.useMemo(() => {
        return rule.column !== null && isColumnNumeric(rule.column, schema);
    }, [rule.column, schema]); // Will depend on the column. we will search that column in the schema, and get the type.
    return (
        <>
            <div className={styles.section}>
                <RuleTypeInput rule={rule} visualType={visualType} dispatch={dispatch} t={t} />
                <RuleNameInput rule={rule} visualType={visualType} dispatch={dispatch} t={t} />
                <ColorByValueColumn rule={rule} dispatch={dispatch} t={t} schema={schema} />
                <ThemeInput
                    rule={rule}
                    dispatch={dispatch}
                    t={t}
                    themeColors={themeColors}
                    disabled={!isNumericColumn}
                />
                <ThemeMinValueInput rule={rule} dispatch={dispatch} t={t} disabled={!isNumericColumn} />
                <ThemeMaxValueInput rule={rule} dispatch={dispatch} t={t} disabled={!isNumericColumn} />
                {isNumericColumn && rule.themeName && (
                    <>
                        <ReverseThemeToggle rule={rule} dispatch={dispatch} t={t} />
                        <ThemePreview
                            themeName={rule.themeName}
                            themeColors={themeColors}
                            reverseTheme={rule.reverseTheme}
                        />
                    </>
                )}
            </div>
            <div className={styles.sectionTitle} role="heading" aria-level={2}>
                {t.visualFwk.visualConfig.colorRules.formattingSectionTitle}
            </div>
            <div className={styles.section}>
                <ApplyOptionsInput rule={rule} dispatch={dispatch} t={t} visualType={visualType} />
            </div>
        </>
    );
};
