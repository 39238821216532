.menuTriggerButton {
    width: 60px;
    min-width: 60px;
    height: 32px;
}

.menuItemsList {
    max-height: 480px;
    overflow-x: hidden;
    overflow-y: auto;

    .menuItem {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    .menuItemSelected {
        background: var(--colorSubtleBackgroundSelected);

        .menuItemIcon {
            color: var(--colorNeutralForeground2BrandSelected);
        }
    }
}
