export const PLOTLY_MSG_TYPE = {
    INIT: 'plotly:init',
    VIOLATED_URLS: 'plotly:violatedUrls',
    /** Efficient way to update the visual without destroying and recreating */
    UPDATE: 'plotly:update',
    NEW_PLOT_ERR: 'plotly:newPlotErr',
    UPDATE_PLOT_ERR: 'plotly:updatePlotErr',
    MISSING_SETUP_ERR: 'plotly:missingSetupErr',
    UNEXPECTED_ERR: 'plotly:unexpectedErr',
} as const;

export type PlotlyMsgType = typeof PLOTLY_MSG_TYPE;

export type PlotlyPacket<DataLayoutConfig = unknown> =
    | { type: PlotlyMsgType['INIT']; dlc: DataLayoutConfig }
    | { type: PlotlyMsgType['VIOLATED_URLS']; value: string[] }
    | { type: PlotlyMsgType['UPDATE']; dlc: DataLayoutConfig }
    | { type: PlotlyMsgType['NEW_PLOT_ERR']; err: unknown }
    | { type: PlotlyMsgType['UPDATE_PLOT_ERR']; err: unknown }
    | { type: PlotlyMsgType['MISSING_SETUP_ERR'] }
    | { type: PlotlyMsgType['UNEXPECTED_ERR']; err: unknown };
