/* eslint-disable @typescript-eslint/no-explicit-any */
// Disabling `any` in here because we use it a lot since
// all of the inputs are unknown types

export const SKIPPABLE_OBJECTS = [
    BigInt,
    Date,
    RegExp,
    WeakMap,
    Promise,
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects#structured_data
    ArrayBuffer,
    DataView,
    // TypedArrays
    Int8Array,
    Uint8Array,
    Uint8ClampedArray,
    Int16Array,
    Uint16Array,
    Int32Array,
    Uint32Array,
    BigInt64Array,
    BigUint64Array,
    Float32Array,
    Float64Array,
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects#internationalization
    Intl.Collator,
    Intl.DateTimeFormat,
    Intl.DisplayNames,
    Intl.ListFormat,
    Intl.Locale,
    Intl.NumberFormat,
    Intl.PluralRules,
    Intl.RelativeTimeFormat,
];

export type TBuiltInSet = Set<any>;
export type TBuiltInIterable = TBuiltInSet | Array<any>;

// We only care about Set and Array because we know how to set values on those
// Iterables (e.g. Set.add(newValue) or Array[idx] = newValue). Anything else we should
// ignore. We can also ignore classes that implement the Symbol.iterator [1] because we don't
// know how to "set" a value on those custom classes.
//
// [1] https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Symbol/iterator
export function isRedactableIterable(x: unknown): x is TBuiltInIterable {
    if (x instanceof Set) {
        return true;
    }

    return Array.isArray(x);
}

export function isRedactableObject(x: unknown): boolean {
    /**
     * It's hard to know what kind of "object" we're working
     * with so a blanket typeof check covers everything since
     * all built-in object types work with it. Some of these
     * global objects when used in the typeof comparison will
     * return "object" while others won't:
     *
     * e.g.
     * typeof Infinity -> number
     * typeof RegExp -> object
     * typeof Promise -> object
     * typeof Symbol -> symbol
     *
     * Goal here is just to know if `x` is ObjectLike.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects
     */
    if (typeof x !== 'object') {
        return false;
    }

    /**
     * BigInt is its own case because inherently it works out of the box with `typeof`.
     * However, a developer could wrap the BigInt with an object and it would result
     * in `typeof BigInt(5) -> "object"` so the above if-check would hit a false-positive.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt#type_information
     */
    if (typeof x === 'object' && x instanceof BigInt) {
        return false;
    }

    /**
     * If `x` is not a skippable object then it's _probably_ redactable.
     * There's a chance we could be trying to redact objects that don't need to be
     * so some extra work _could_ occur. But, that would be on esoteric objects
     * that aren't skippable (e.g. not in the list) AND don't need to be redactable
     * which should be an edge case most of the time.
     */
    return SKIPPABLE_OBJECTS.findIndex((instance) => x instanceof instance) === -1;
}

export function isNonRedactablePrimitive(x: unknown): boolean {
    return x === undefined || x === null || typeof x === 'number' || typeof x === 'boolean';
}

export function isRedactable(x: unknown): boolean {
    // sanity check to bail early for primitives we know we can't redact
    if (isNonRedactablePrimitive(x)) {
        return false;
    }

    // we always need to redact strings
    if (typeof x === 'string') {
        return true;
    }

    // We need to recurse into these to redact their individual values if applicable
    return isRedactableIterable(x) || isRedactableObject(x);
}

/**
 * Generic setter on a redactable iterable
 */
export function setValueOnIterable(iterable: TBuiltInIterable, targetIndex: number, oldValue: any, newValue: any) {
    if (Array.isArray(iterable)) {
        iterable[targetIndex] = newValue;
        return;
    }

    (iterable as TBuiltInSet).delete(oldValue);
    (iterable as TBuiltInSet).add(newValue);
}

/**
 * Given the way {@link isRedactableObject} checks for redactable ObjectLike types
 * there is no guarantee we will be an object or Map. However, coding for the happy
 * path is the easiest and we can catch and log when we're not handling new Objects
 * properly
 */
export type TMaybeObjectLike = object | Map<any, any>;

export function getObjectEntries(obj: TMaybeObjectLike) {
    if (obj instanceof Map) {
        return obj.entries();
    }

    return Object.entries(obj);
}

/**
 * @throws This can throw if we try to call this on an `obj` that really isn't
 * an "object". For example, calling Object.defineProperty(null, key, ...)
 * will throw a TypeError because it's now allowed. In that example, we avoid
 * this by checking for {@link isNonRedactablePrimitive} before this is ever called.
 */
export function setValueOnObject(obj: TMaybeObjectLike, key: any, value: any) {
    if (obj instanceof Map) {
        obj.set(key, value);
        return;
    }

    Object.defineProperty(obj, key, {
        value,
        writable: true,
    });
}
