import { EntityGroup, EntityGroupMember, EntityType } from '../../../common';
import { Database, QueryStoreEnv } from '../../../stores';
import { ITreeEntityMapper } from '../actions';
import { RowDataType } from '../RowDataType';
import { getBaseRootPath } from '../utils';
import { EntityGroupRowDataType } from './EntityGroupRowDataType';
import { iconMapper } from './IconMapper';
import { RowDataTypeBase } from './RowDataTypeBase';
import { getFromCacheOrCreate } from './RowDataTypeCache';

export class EntityGroupMemberRowDataType extends RowDataTypeBase {
    private constructor(
        entity: EntityGroupMember,
        entityGroup: EntityGroup,
        database: Database,
        baseRootPath: string[],
        treeEntityMapper: ITreeEntityMapper
    ) {
        const pathFromRoot = EntityGroupRowDataType.buildEntityGroupFolderPath(baseRootPath, entityGroup);
        pathFromRoot.push(entity.name);
        super(
            entity.id,
            pathFromRoot,
            entity.name,
            EntityType.EntityGroupMember,
            database,
            iconMapper.EntityGroupMember,
            treeEntityMapper
        );

        this.actions = this.treeEntityMapper.getActions(this.entityType, false, undefined);
    }

    public static fromCacheOrCreate(
        env: QueryStoreEnv,
        entity: EntityGroupMember,
        entityGroup: EntityGroup,
        database: Database,
        treeEntityMapper: ITreeEntityMapper
    ): RowDataType {
        return getFromCacheOrCreate(
            database,
            entity.id,
            () =>
                new EntityGroupMemberRowDataType(
                    entity,
                    entityGroup,
                    database,
                    getBaseRootPath(env, database),
                    treeEntityMapper
                )
        );
    }
}
