import padStart from 'lodash/padStart';
import values from 'lodash/values';
import moment from 'moment';

import { Column, EntityGroup, Function, FunctionParameter, Table } from '../common/types';

export const isLetter = (letter: string): boolean => {
    return letter.length === 1 && !!letter.match(/[a-z]/i);
};

export const isLetterOrDigit = (letter: string): boolean => {
    return letter.length === 1 && !!letter.match(/[a-z0-9]/i);
};

/**
 * An identifier is a string that starts with a letter or underscore, and contains only letters, digits, and underscores.
 * @param name the name to validate
 * @returns
 */
export const isIdentifier = (name: string): boolean => {
    if (name.length === 0) {
        return false;
    }

    if (!isLetter(name.charAt(0)) && name.charAt(0) !== '_') {
        return false;
    }

    for (const c of name) {
        if (!isLetterOrDigit(c) && c !== '_') {
            return false;
        }
    }

    return true;
};

export const isKeyword = (name: string): boolean => {
    return Kusto.Data.Common.CslSyntaxGenerator.IsKeyword(name);
};

/**
 * Quotes a string if it needs quoting per the rules of the language as described here:
 * https://docs.microsoft.com/en-us/azure/data-explorer/kusto/query/schema-entities/entity-names#identifier-quoting
 * @param name The string to quote. Assumed to be a valid identifier (that's not already quoted).
 * @returns
 */
export function quoteIfNeeded(name: string): string {
    // no use in further processing if the name is empty
    if (name.length === 0) {
        return name;
    }

    // keywords must be quoted
    if (isIdentifier(name) && isKeyword(name)) {
        return `["${name}"]`;
    }

    // identifiers with dots, spaces, or dashes must be quoted (underscores are fine)
    for (let i = 0; i < name.length; i++) {
        const c = name[i];
        if (c === '.' || c === ' ' || c === '-') {
            return `["${name}"]`;
        }
    }

    return name;
}

export const normalizeName = (name: string): string => {
    if (isIdentifier(name) && !isKeyword(name)) {
        return name;
    }

    if (name.startsWith('[')) {
        return name;
    }

    if (name.indexOf("'") < 0) {
        return `['${name}']`;
    }

    return `["${name}"]`;
};

export const generateSchemaFromColumns = (columns: Column[]): string => {
    return columns
        .map((col) => {
            const normalizedName = normalizeName(col.name);
            return `${normalizedName}: ${col.type}`;
        })
        .join(', ');
};

/**
 * The javascript equivalent of the C# Char.GetUnicodeCategory(c) == System.Globalization.UnicodeCategory.Control
 * @param c Character
 */
const isControlCharacter = (c: string) => {
    if (!c || c.length !== 1) {
        return false;
    }

    // eslint-disable-next-line no-control-regex
    return /[\u0000-\u001F\u0080-\u009F\u007F]/.exec(c);
};

const getLiteral = (value: string | null | undefined) => {
    if (!value) {
        return null;
    }

    let literal = '"';
    for (const c of value) {
        switch (c) {
            case "'":
                literal += String.raw`\'`;
                break;
            case '"':
                literal += '\\"';
                break;
            case '\\':
                literal += String.raw`\\`;
                break;
            case '\0':
                literal += String.raw`\0`;
                break;
            case '\b':
                literal += String.raw`\b`;
                break;
            case '\f':
                literal += String.raw`\f`;
                break;
            case '\n':
                literal += String.raw`\n`;
                break;
            case '\r':
                literal += String.raw`\r`;
                break;
            case '\t':
                literal += String.raw`\t`;
                break;
            case '\v':
                literal += String.raw`\v`;
                break;
            default:
                if (!isControlCharacter(c)) {
                    literal += c;
                } else {
                    literal += '\\u';
                    literal += padStart(c.charCodeAt(0).toString(16), 4, '0');
                }
                break;
        }
    }
    literal += '"';
    return literal;
};

const generateWithClause = (properties: { [props: string]: string | undefined }) => {
    const keys = Object.keys(properties);
    const propertiesAsStrings = keys
        .filter((key) => properties[key])
        .map((key) => `${key} = ${getLiteral(properties[key])}`);

    return propertiesAsStrings.length > 0 ? `with (${propertiesAsStrings.join(',')})` : '';
};

export const generateTableCreateCommand = (table: Table) => {
    const schemaString = generateSchemaFromColumns(values(table.columns));
    const normalizedTableName = normalizeName(table.name);
    const { docstring, folder } = table;
    const withClause: string = generateWithClause({ docstring, folder });

    const command = `.create table ${normalizedTableName} (${schemaString}) ${withClause}`;

    return command;
};

const isTabular = (param: FunctionParameter) => {
    return !!param.columns;
};

const getInputParameterAsCslString = (param: FunctionParameter): string => {
    if (isTabular(param)) {
        let attributesAsString: string = param.columns!.map((col) => `${col.name}:${col.cslType}`).join(',');

        if (attributesAsString.length === 0) {
            attributesAsString = '*';
        }
        return `${param.name}:(${attributesAsString})`;
    }

    // Non-tabular param.
    if (param.cslDefaultValue) {
        return `${param.name}:${param.cslType}=${param.cslDefaultValue}`;
    } else {
        return `${param.name}:${param.cslType}`;
    }
};

const getInputParametersAsCslString = (parameters: FunctionParameter[]) => {
    if (parameters.length === 0) {
        return '()';
    }

    const parametersAsString = parameters.map((param) => getInputParameterAsCslString(param));

    return `(${parametersAsString.join(',')})`;
};

export const getGenerateMaterializedViewCommand = (tableName: string, sourceTableName: string, query: string) => {
    const normalizedTableName = normalizeName(tableName);
    const normalizedSourceTableName = normalizeName(sourceTableName);
    return `// [NOTE!] the materialized view will be created with no 'backfill'
.create materialized-view ${normalizedTableName} on table ${normalizedSourceTableName}
{
${query}
}`;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const generateFunctionCreateCommand = (fn: Function) => {
    const { docstring, folder, functionKind } = fn;
    const withClause: string = generateWithClause({
        docstring,
        folder,
        functionKind: functionKind === 'ViewFunction' ? functionKind : undefined,
    });
    const parametersClause: string = getInputParametersAsCslString(fn.inputParameters);
    const result = `.create-or-alter function ${withClause} ${normalizeName(fn.name)}${parametersClause} ${fn.body}`;
    return result;
};

export const generateEntityGroupCreateCommand = (entityGroup: EntityGroup) => {
    return `.create entity_group ${entityGroup.name} (
${Object.values(entityGroup.entities)
    .map((entity) => `\t${entity.name}`)
    .join(',\n')}
)`;
};

type DateTimeRange = [moment.Moment, moment.Moment];

const generateDateTimeRangeFilter = (column: string, range: DateTimeRange) => {
    return `${column} >= datetime(${range[0].toISOString()}) and ${column} <= datetime(${range[1].toISOString()})`;
};

const asCslString = (stringValue: string | null | undefined): string | null => {
    // Note that currently the Kusto engine doesn't support null strings,
    // so nulls are treated as empty string literals.
    if (!stringValue || stringValue === '') {
        return '""';
    }

    return getLiteral(stringValue);
};

const getCslLiteral = (cslTypeString: string, valueString: string | null): string | null => {
    if (!cslTypeString || cslTypeString === '') {
        // Non-recognized literals evaluate to themselves.
        return valueString;
    }

    if (cslTypeString === 'string') {
        return asCslString(valueString);
    }

    return `${cslTypeString}(${valueString === '' ? 'null' : valueString})`;
};

const convertToCslLiteral = (type: string, columnValues: (string | null)[]): (string | null)[] => {
    // TODO: This is less efficient than what we would like, because type is processed by
    //       GetCslLiteralFromClrValueString N times.
    return columnValues.map((cell) => getCslLiteral(type, cell));
};

const parseInexactUtc = (val: string | null) => {
    if (!val || val === 'now') {
        return moment.utc();
    }

    return moment.utc(val);
};

const convertFiltersByColumnType = (additionalFilters: Map<Column, Set<string | null>>) => {
    const resultFilters = new Map<string, (string | null)[]>();
    const dtRangesFilters = new Map<string, DateTimeRange>();

    for (const column of additionalFilters.keys()) {
        // Skip filter for null values
        if ((column.type as string) === 'DBNull') {
            continue;
        }

        const filterValuesSet = additionalFilters.get(column);

        if (!filterValuesSet || filterValuesSet.size === 0) {
            continue;
        }

        const filterValuesArray = Array.from(filterValuesSet);

        const cslType = column.type;
        if (cslType === 'datetime') {
            if (filterValuesSet.size === 1) {
                resultFilters.set(quoteIfNeeded(column.name), convertToCslLiteral(cslType, filterValuesArray));
            } else {
                const dtValues = filterValuesArray.map((v) => parseInexactUtc(v));
                const maxValue = moment.max(dtValues);
                const minValue = moment.min(dtValues);
                dtRangesFilters.set(quoteIfNeeded(column.name), [minValue, maxValue]);
            }
        } else if (cslType === 'dynamic') {
            const values = filterValuesArray.map((value) => `${JSON.stringify(value)}`);
            resultFilters.set(quoteIfNeeded(column.name), values);
        } else {
            resultFilters.set(quoteIfNeeded(column.name), convertToCslLiteral(cslType, filterValuesArray));
        }
    }
    return { resultFilters, dtRangesFilters };
};

const generateDateTimeRangeFilters = (dtRangeFilters: Map<string, DateTimeRange>) => {
    let text = '';
    for (const filter of dtRangeFilters) {
        text += '| where ';
        text += generateDateTimeRangeFilter(filter[0], filter[1]);
    }

    return text;
};

const generateEqualityFilters = (additionalFilters: Map<string, (string | null)[]>, useNewLines: boolean): string => {
    if (!additionalFilters || additionalFilters.size === 0) {
        return '';
    }

    let text = '';
    for (const filter of additionalFilters) {
        text += '| where ';
        let firstTime = true;
        const filterValues = filter[1];
        if (filterValues.length === 1) {
            text += `${filter[0]} == ${filterValues[0]}`;
        } else {
            for (const filterValue of filterValues) {
                text += `${firstTime ? filter[0] + ' in (' : ', '}${filterValue}`;
                firstTime = false;
            }
            text += ')';
        }

        if (useNewLines) {
            text += '\n';
        }
    }

    return text;
};

/**
 * Generate a filter statement from concrete values. Typical usage would be - user selects a bunch of cells in
 * the result table and now wants to filter on them. In case of datetime values, the generate statement
 * will filter for the minimal range that contains all selected values.
 * @param additionalFilters a list of values per column for which we want to generate filters.
 * @param useNewLines whether or not to use newlines between filters
 */
export const generateFilterStatement = (additionalFilters: Map<Column, Set<string>>, useNewLines = true) => {
    if (!additionalFilters || additionalFilters.size <= 0) {
        return '';
    }

    const { resultFilters, dtRangesFilters } = convertFiltersByColumnType(additionalFilters);
    let text = generateDateTimeRangeFilters(dtRangesFilters);
    text += generateEqualityFilters(resultFilters, useNewLines);
    return text;
};

/**
 * Generates a datatable statement from concrete values. Typical usage would be - user selects a bunch of cells in
 * the result table and now wants to create a datatable statement from them.
 * @param table: the table to convert to a datatable statement. note that the function assumes that the table is indeed a table (i.e rectangular, consistent types for columns ETC).
 */
export const generateDatatableStatement = (table: { columns: Column[]; rows: string[] }, useNewlines = false) => {
    const schemaString = generateSchemaFromColumns(table.columns);
    const valuesString = table.rows
        .map(
            (val, i) =>
                `${
                    // newlines after each full row
                    useNewlines && i !== 0 && i % table.columns.length === 0 ? '\n' : ''
                }${
                    // indentation in start of each new row
                    useNewlines && i % table.columns.length === 0 ? '    ' : ''
                }${
                    // actual cell value
                    getCslLiteral(table.columns[i % table.columns.length].type, val)
                }`
        )
        .join(',');
    return `datatable (${schemaString}) [\n${valuesString}\n]`;
};
