import React from 'react';
import { useUncontrolledFocus } from '@fluentui/react-components';
import { getKustoWorker } from '@kusto/monaco-kusto';
import type * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import ReactMonacoEditor from 'react-monaco-editor';

import { type DataTableColumn } from '@kusto/client';
import { useAbortSignal } from '@kusto/utils';

import * as styles from './KqlExamples.module.scss';

const monacoEditorOptions: monaco.editor.IEditorOptions = {
    selectOnLineNumbers: true,
    automaticLayout: true,
    minimap: {
        enabled: false,
    },
    wordWrap: 'on',
    mouseWheelZoom: true,
    readOnly: true,
    folding: false,
};

function getKql(selectedTableName: string) {
    return `//Selected Table
${selectedTableName}
| take 20

//Count of records in a table using KQL
${selectedTableName}
| count

//Count of records in a table using SQL
select count(*) from ${selectedTableName}

//For SQL exerts and KQL beginners, use “explain” operator to convert SQL to KQL
s
EXPLAIN
SELECT COUNT(*) AS LogsCount FROM ${selectedTableName}

${selectedTableName}
| summarize event_count=count() by EndLocation`;
}

interface KqlExamplesProps {
    isDarkTheme: boolean;
    databaseName: string;
    clusterUrl: string;
    tableName: string;
    columns: DataTableColumn[];
}

export const KqlExamples: React.FC<KqlExamplesProps> = ({
    isDarkTheme,
    clusterUrl,
    databaseName,
    tableName,
    columns,
}) => {
    const abortSignal = useAbortSignal();
    const uncontrolledFocusAttributes = useUncontrolledFocus();
    const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor>();
    const editor = editorRef.current;

    const updateMonacoSchema = React.useCallback(
        (editor: monaco.editor.IStandaloneCodeEditor | undefined) => {
            getKustoWorker().then((workerAccessor) => {
                if (!editor) {
                    return;
                }
                const model = editor.getModel();
                if (!model || abortSignal.aborted) {
                    return;
                }
                workerAccessor(model.uri).then((worker) => {
                    worker.setSchemaFromShowSchema(
                        {
                            Plugins: [],
                            Databases: {
                                [databaseName]: {
                                    Name: databaseName,
                                    Tables: {
                                        [tableName]: {
                                            Name: tableName,
                                            DocString: '',
                                            OrderedColumns: columns.map((col) => ({
                                                Name: col.ColumnName,
                                                Type: col.ColumnType,
                                                CslType: col.ColumnType,
                                            })),
                                        },
                                    },
                                    Functions: {},
                                },
                            },
                        },
                        clusterUrl,
                        databaseName
                    );
                });
            });
        },
        [abortSignal.aborted, clusterUrl, columns, databaseName, tableName]
    );

    React.useEffect(() => {
        updateMonacoSchema(editor);
    }, [editor, updateMonacoSchema]);

    return (
        <div className={styles.kqlExamplesContainer}>
            <ReactMonacoEditor
                {...uncontrolledFocusAttributes}
                language="kusto"
                value={getKql(tableName)}
                theme={isDarkTheme ? 'kusto-dark' : 'kusto-light'}
                options={monacoEditorOptions}
                editorDidMount={(editor) => {
                    editorRef.current = editor;
                    updateMonacoSchema(editor);
                }}
            />
        </div>
    );
};
