import isEmpty from 'lodash/isEmpty';

import { IAuthProvider } from '../AuthenticationProviders/IAuthProvider';
import { QueryDeepLinkProperties } from '../utils/queryPageLinkParser';

export class LocalStorageKeys {
    private workspace: string | undefined;
    isEmptyWorkspace = false;
    constructor(
        private queryDeepLinkProperties: QueryDeepLinkProperties,
        public localStorageKey: string,
        private enableRoamingProfile: boolean,
        private authProvider: IAuthProvider | undefined
    ) {
        if (this.enableRoamingProfile && !this.authProvider) {
            throw new Error('Roaming Profile is enabled but AuthProvider is undefined');
        }
        if (!this.enableRoamingProfile && this.authProvider) {
            this.authProvider = undefined;
        }

        this.workspace = this.queryDeepLinkProperties.workspaceName;

        if (this.workspace) {
            this.isEmptyWorkspace = new RegExp('volatile|empty', 'i').test(this.workspace);
            if (!this.isEmptyWorkspace) {
                this.localStorageKey = this.workspace;
            }
        }
    }

    localConnectionStorageKey(): string {
        return `${this.localStorageKey}_connections`;
    }

    localGridCacheStorageKey(): string {
        return `${this.localStorageKey}_gridCache`;
    }

    connectionStorageKey(): string {
        return `${this.currentUserStorageKey()}_connections`;
    }

    gridCacheStorageKey(): string {
        return `${this.currentUserStorageKey()}_gridCache`;
    }

    currentUserStorageKey(): string {
        const account = this.authProvider?.getAccount();
        return account ? `${account.homeAccountId}_${this.localStorageKey}` : this.localStorageKey;
    }

    isVolatileWorkspace(emptyWorkspaceOnDeepLinkQuery: boolean | undefined): boolean {
        if (this.isEmptyWorkspace) {
            return true;
        }

        if (
            emptyWorkspaceOnDeepLinkQuery &&
            (!isEmpty(this.queryDeepLinkProperties.query) || !isEmpty(this.queryDeepLinkProperties.querySrc))
        ) {
            return true;
        }

        return false;
    }
}
