import React from 'react';
import * as mobx from 'mobx';
import { Observer } from 'mobx-react-lite';

import { assertNever, err, FlushController, LOADING, Loading, Mutable, ok, RegisterFlush, Result } from '@kusto/utils';

import { UnsupportedVisualType } from '../../KweVisual/UnsupportedVisualType';
import { narrowVisualOptions } from '../../narrowVisualOptions';
import { InternalParsedVisual } from '../../parseVisuals';
import { OkQueryResult } from '../../queryResult';
import { KweVisualFwkLocale } from '../../types';
import type {
    Empty,
    HeuristicsProps,
    SchemaState,
    VisualConfigLayout,
    VisualInput,
    VisualInputModel,
    VisualSelector,
    VisualSelectorOptions,
} from '../../visualConfig';
import type { UnknownVisualOptions, VisualOptionKey, VisualOptionProperties, VisualOptions } from '../../visualOptions';
import { SectionsContainer } from '../components/Container';
import { VisualOptionsVisual } from '../components/Visual';
import { VisualConfigLayoutFiltered } from '../lib';
import type {
    IVisualOptionsModel,
    PluginStatus,
    RenderConfigurationsOptions,
    RenderVisualOptions,
    VisualModelArgs,
} from '../pub';
import { i18nElement } from './i18nElement';
import { VisualOptionsContextsProvider } from './ModelProvider';
import { createSchemaObservable } from './schemaReducer';

class SelectorOptions<C extends VisualOptionKey, H> implements VisualSelectorOptions<C> {
    constructor(readonly visualModel: VisualPluginModel<C, H>) {}

    getVisualType() {
        return this.visualModel.visualType;
    }

    get<K extends C>(key: K): VisualOptionProperties[K] {
        return this.visualModel.narrowedOptions[key];
    }

    set<K extends C>(key: K, value: VisualOptionProperties[K]): void {
        this.visualModel.pane.unknown_options[key] = value;
    }

    getSchema() {
        return this.visualModel.pane.schema.get();
    }

    getQueryResult() {
        return this.visualModel.pane.args.queryResult.value;
    }

    getHeuristics() {
        return this.visualModel.heuristics.get();
    }

    resolveSelector<T>(selector: VisualSelector<C, T>): { get: () => T } {
        if (typeof selector !== 'function') {
            return { get: () => selector };
        }
        // Cast needed because we can't exclude functions from T
        return mobx.computed(() => (selector as (options: VisualSelectorOptions<C>) => T)(this));
    }
}

/**
 * Each instance is specific to a input
 */
export class VisualOptionsInputInstance<C extends VisualOptionKey, H = undefined, Temp = unknown>
    extends SelectorOptions<C, H>
    implements VisualInputModel<C, H, Temp>
{
    registerFlush: RegisterFlush;

    constructor(source: VisualPluginModel<C, H>, readonly input: VisualInput<C, H, Temp>) {
        super(source);
        // Case where T !== undefined and `initTemp` === undefined is not handled right now.
        if (input.init) {
            this.setTemp(input.init(this, this.getTemp()));
        }

        this.registerFlush = source.pane.flushController.register;
    }

    getTemp(): Temp {
        // Inherently unsafe. We're trusting that all the inputs with the same
        // id are of the same type
        return this.visualModel.pane.inputTempState[this.input.id] as Temp;
    }
    setTemp(value: Temp): void {
        this.visualModel.pane.inputTempState[this.input.id] = value;
    }

    render(t: KweVisualFwkLocale, disabled: boolean) {
        return (
            <this.input.Component
                t={t}
                key={this.input.id}
                disabled={disabled}
                dashboard={this.visualModel.pane.args.dashboard}
                model={this}
            />
        );
    }

    reset() {
        if (this.input.init) {
            this.setTemp(this.input.init(this, this.getTemp()));
        } else {
            delete this.visualModel.pane.inputTempState[this.input.id];
        }
    }
}

class CHeuristicsProps<C extends VisualOptionKey = VisualOptionKey, H = undefined> implements HeuristicsProps<C> {
    visualOptions: VisualOptions<C>;

    constructor(private readonly visualModel: VisualPluginModel<C, H>) {
        this.visualOptions = visualModel.narrowedOptions;

        mobx.makeObservable(this, {
            queryResult: mobx.computed,
        });
    }

    get visualType() {
        return this.visualModel.visualType;
    }

    get queryResult(): undefined | OkQueryResult {
        return this.visualModel.pane.args.queryResult.value;
    }
}

function falsy(value: unknown) {
    return !value;
}

function normalizeInputLayout<C extends VisualOptionKey, H>(
    layout: VisualConfigLayout<C, H>
): VisualConfigLayoutFiltered<C, H> {
    function normalizeInputs(
        inputs: Empty | ReadonlyArray<Empty | VisualInput<C, H>>
    ): undefined | ReadonlyArray<VisualInput<C, H>> {
        if (!inputs || inputs.length === 0) {
            return undefined;
        }

        // Only make a copy if we need to
        if (inputs.some(falsy)) {
            return inputs.filter(Boolean) as ReadonlyArray<VisualInput<C, H>>;
        }

        return inputs as ReadonlyArray<VisualInput<C, H>>;
    }

    function filterSection(
        section: Empty | VisualConfigLayout.Section<C, H>
    ): undefined | VisualConfigLayoutFiltered.Section<C, H> {
        if (!section) {
            return undefined;
        }

        const filtered: VisualConfigLayoutFiltered.Section<C, H> = {
            head: normalizeInputs(section.head),
            segments: section.segments
                ? section.segments.map((seg) => ({
                      ...seg,
                      inputs: normalizeInputs(seg.inputs),
                  }))
                : undefined,
        };
        if (!filtered.head && !filtered.segments) {
            return undefined;
        }
        return filtered;
    }

    return {
        visual: filterSection(layout.visual),
        interactions: filterSection(layout.interactions),
    };
}

/**
 * Each instance is specific to a visual type. Get's discarded and re-built when
 * the type changes
 */
export class VisualPluginModel<C extends VisualOptionKey = VisualOptionKey, H = undefined> implements PluginStatus {
    readonly narrowedOptions: VisualOptions<C>;

    readonly dispose: () => void;

    readonly controlState: unknown;
    readonly heuristics: mobx.IObservableValue<H>;

    readonly heuristicsProps: HeuristicsProps<C>;

    readonly selectorOptions: VisualSelectorOptions<C, H> = new SelectorOptions(this);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly inputsModels: Map<string, VisualOptionsInputInstance<C, H, any>> = new Map();

    constructor(
        readonly visualType: string,
        readonly pane: VisualOptionsModel,
        readonly config: InternalParsedVisual<C, H>
    ) {
        mobx.makeObservable(this, {
            normalizedInputLayout: mobx.computed,
            supportedSections: mobx.computed,
            narrowedOptionsCopy: mobx.computed,
        });

        this.narrowedOptions = mobx.observable.object(
            narrowVisualOptions(this.config, this.pane.unknown_options),
            undefined,
            { deep: false }
        );

        this.heuristicsProps = new CHeuristicsProps(this);

        this.heuristics = mobx.observable.box(undefined, { deep: false }) as unknown as mobx.IObservableValue<H>;

        const dispose1 = mobx.autorun(() => {
            const prev = mobx.runInAction(() => this.heuristics.get());
            const next = this.config.heuristics(this.heuristicsProps, prev);
            mobx.runInAction(() => this.heuristics.set(next));
        });

        const dispose2 = mobx.observe(this.pane.unknown_options, (change) => {
            const key = change.name as C;
            if (!(key in this.config.model)) {
                return;
            }
            switch (change.type) {
                case 'add':
                case 'update':
                    // Safe, but mobx types aren't clever enough to make this work
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (this.narrowedOptions as any)[key] = change.newValue;
                    break;
                case 'remove':
                    // Safe, but mobx types aren't clever enough to make this work
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (this.narrowedOptions as any)[key] = this.config.model[key];
                    break;
                default:
                    assertNever(change);
            }
        });

        this.dispose = () => {
            dispose1();
            dispose2();
        };
    }

    get supportedSections(): readonly VisualConfigLayout.SectionKey[] {
        const layout = this.normalizedInputLayout;
        return layout ? (Object.keys(layout) as readonly VisualConfigLayout.SectionKey[]) : [];
    }

    /**
     * _not_ type safe. Burden of ensuring that models of the appropriate type
     * are passed to the right places is on the caller.
     */
    getInputInstance<Temp>(input: VisualInput<C, H, Temp>): VisualOptionsInputInstance<C, H, Temp> {
        let model = this.inputsModels.get(input.id) as undefined | VisualOptionsInputInstance<C, H, Temp>;

        if (!model) {
            model = new VisualOptionsInputInstance<C, H, Temp>(this, input);
            this.inputsModels.set(input.id, model);
        }

        return model;
    }

    get normalizedInputLayout(): undefined | VisualConfigLayoutFiltered<C, H> {
        const inputLayout = this.config?.inputLayout;
        return inputLayout && normalizeInputLayout(this.selectorOptions.resolveSelector(inputLayout).get());
    }

    get narrowedOptionsCopy() {
        return { ...this.narrowedOptions };
    }

    get minimumSize() {
        return this.config.minimumSize(this.narrowedOptionsCopy);
    }

    get defaultSize() {
        return this.config.defaultSize(this.narrowedOptionsCopy);
    }
}

export type MaybePluginModel<C extends keyof VisualOptionProperties = keyof VisualOptionProperties, H = undefined> =
    | Loading
    | Result<VisualPluginModel<C, H>, React.ReactElement>;

export class VisualOptionsModel implements IVisualOptionsModel {
    readonly unknown_options: Mutable<UnknownVisualOptions>;
    readonly schema: mobx.IObservableValue<SchemaState>;
    readonly pluginKey: mobx.IObservableValue<string>;
    readonly inputTempState: Record<string, unknown> = {};
    readonly flushController = new FlushController();
    readonly _pluginStatus: mobx.IObservableValue<MaybePluginModel> = mobx.observable.box(LOADING, { deep: false });

    private readonly abortController = new AbortController();

    constructor(readonly args: VisualModelArgs, options: UnknownVisualOptions, visualType: string) {
        this.unknown_options = { ...options };

        this.pluginKey = mobx.observable.box(visualType, { deep: false });

        this.schema = createSchemaObservable(this.abortController.signal, () => args.queryResult, args.locale);

        mobx.makeObservable(this, {
            unknown_options: mobx.observable.shallow,
            inputTempState: mobx.observable.shallow,
            optionsCopy: mobx.computed,
            dispose: mobx.action,
        });

        this.abortController.signal.addEventListener(
            'abort',
            mobx.reaction(
                () => {
                    const type = this.pluginKey.get();
                    return { type, config: this.args.parsedVisuals[type] };
                },
                ({ type, config }) => {
                    this._pluginStatus.get().value?.dispose();

                    let visualModel: MaybePluginModel;

                    switch (config?.config.kind) {
                        case undefined:
                            visualModel = err(
                                <Observer>
                                    {() => <UnsupportedVisualType t={this.args.locale()} type={type} />}
                                </Observer>
                            );
                            break;
                        case 'err':
                            visualModel = err(i18nElement(this.args.locale, config.config.err));
                            break;
                        case 'loading':
                            visualModel = LOADING;
                            break;
                        case 'ok':
                            visualModel = ok(new VisualPluginModel(type, this, config.config.value));
                            break;
                    }

                    this._pluginStatus.set(visualModel);
                },
                { fireImmediately: true, equals: (a, b) => a.type === b.type && a.config === b.config }
            )
        );

        this.abortController.signal.addEventListener('abort', () => this._pluginStatus.get().value?.dispose());
    }

    dispose() {
        this.abortController.abort();
    }

    get options(): UnknownVisualOptions {
        return this._pluginStatus.get().value?.narrowedOptions ?? this.unknown_options;
    }

    get optionsCopy(): UnknownVisualOptions {
        return this._pluginStatus.get().value?.narrowedOptionsCopy ?? { ...this.unknown_options };
    }

    get loadingVisualPlugin() {
        return this._pluginStatus.get().kind === 'loading';
    }

    get pluginStatus() {
        return this._pluginStatus.get();
    }

    async flush() {
        await this.flushController.flush();
    }

    renderConfiguration(options: RenderConfigurationsOptions) {
        return (
            <VisualOptionsContextsProvider options={options} model={this}>
                <SectionsContainer {...options} paneModel={this} />
            </VisualOptionsContextsProvider>
        );
    }
    renderVisual(options: RenderVisualOptions) {
        return (
            <VisualOptionsContextsProvider options={options} model={this}>
                <VisualOptionsVisual {...options} model={this} />
            </VisualOptionsContextsProvider>
        );
    }
}
