import React from 'react';
import { ITextFieldProps, TextField } from '@fluentui/react';

export interface TextFieldsWithKeyHandlerProps extends ITextFieldProps {
    onDoneEditing?: () => void;
    onChanged?: (value: string) => void;
}
export const TextFieldsWithKeyHandler: React.StatelessComponent<TextFieldsWithKeyHandlerProps> = ({
    onDoneEditing,
    onChanged,
    ...props
}) => {
    let doneCalled = false;
    const onDone = (e: React.SyntheticEvent) => {
        // On done might be called from tab or which will also trigger blur
        // avoid calling twice
        if (doneCalled) {
            return;
        }
        doneCalled = true;
        if (e.target) {
            onChanged!((e.target as HTMLTextAreaElement)!.value);
        }

        onDoneEditing!();
    };

    const keyHandler = (e: React.KeyboardEvent) => {
        e.nativeEvent.stopImmediatePropagation();
        switch (e.key) {
            case 'Enter':
            case 'Tab':
                onDone(e);
                break;
            case 'Escape':
                onDoneEditing!();
                break;
            default:
            // Nothing to do ...
        }
    };

    // Disabled while enabling lint rule
    // eslint-disable-next-line jsx-a11y/no-autofocus
    return <TextField onKeyDown={keyHandler} onBlur={onDone} autoFocus={true} {...props} />;
};
TextFieldsWithKeyHandler.defaultProps = {
    onDoneEditing: () => {
        /** */
    },
    onChanged: () => {
        /**  */
    },
};
