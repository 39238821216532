import React from 'react';
import { Field, FieldProps, Input, InputOnChangeData, InputProps, useId } from '@fluentui/react-components';
import { Dismiss20Regular } from '@fluentui/react-icons';
import debounce from 'lodash.debounce';

export type InputFieldProps = FieldProps &
    Omit<InputProps, 'onChange'> & {
        onInputChange: (value: string) => void;
        error?: string;
        showClearButton?: boolean;
    };
export const INPUT_DEBOUNCE_TIME = 400;
export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
    ({ showClearButton, error, onInputChange, defaultValue, value, ...props }, ref) => {
        const [inputValue, setInputValue] = React.useState(defaultValue || value);
        const inputId = useId();
        const propsWithError = {
            ...props,
            ...(error ? { validationMessage: error } : {}),
        };
        const debounceChange = React.useMemo(
            () =>
                debounce((dataValue) => {
                    onInputChange(dataValue);
                }, INPUT_DEBOUNCE_TIME),
            [onInputChange]
        );
        /* Updating the input text without waiting to the debounce to run */
        const handleOnChange = (data: InputOnChangeData) => {
            setInputValue(data.value);
            debounceChange(data.value);
        };
        return (
            <Field {...propsWithError}>
                <Input
                    id={inputId}
                    ref={ref}
                    onChange={(_ev, data) => handleOnChange(data)}
                    {...(defaultValue ? { defaultValue: inputValue } : { value: inputValue })}
                    {...(showClearButton && inputValue
                        ? {
                              contentAfter: (
                                  <Dismiss20Regular
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => handleOnChange({ value: '' })}
                                  />
                              ),
                          }
                        : {})}
                    {...props}
                />
            </Field>
        );
    }
);
