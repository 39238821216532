import type React from 'react';
import type * as mobx from 'mobx';

import type { FeatureFlags } from '@kusto/app-common';
import type { IKweTelemetry, Loading, Result } from '@kusto/utils';

import { DEFAULT_VISUAL_TYPE } from '../constants';
import type { RtdPluginApi } from '../dashboardApi';
import { VisualFwkFeatureFlag } from '../featureFlags';
import type { ParsedVisuals } from '../parseVisuals';
import type { VisualFwkQueryResult } from '../queryResult';
import type { KweVisualFwkLocale } from '../types';
import type { IDataVisualProps, TileSize, VisualConfig, VisualConfigLayout } from '../visualConfig';
import type { UnknownVisualOptions, VisualOptionProperties } from '../visualOptions';
import { VisualOptionsModel } from './model/model';

export interface RenderConfigurationsOptions {
    /**
     * Defaults to 'visual'
     */
    readonly section?: VisualConfigLayout.SectionKey;
    readonly headAdditions?: React.ReactNode;
    readonly isDarkTheme: boolean;
}

export interface RenderVisualOptions
    extends Omit<IDataVisualProps, 'heuristics' | 'queryResult' | 'visualType' | 'visualOptions' | 'locale'> {
    /**
     * Error boundary render prop to wrap the rendered visual
     * should handle fallback UI and error reporting.
     *
     * TODO(visual-fwk): Make optional and/or make not customizable
     */
    renderErrorBoundary: (wrappedElement: React.ReactChild) => React.ReactElement;
}

export interface PluginStatus {
    readonly supportedSections: readonly VisualConfigLayout.SectionKey[];
    readonly minimumSize: TileSize;
    readonly defaultSize: TileSize;
}

export interface IVisualOptionsModel {
    /**
     * If the visual plugin has loaded, this will match that plugins model,
     * otherwise it will be a union of all selected options.
     */
    readonly options: Partial<VisualOptionProperties>;
    /**
     * non-mobx copy of {@link IVisualOptionsModel.options}
     */
    readonly optionsCopy: Partial<VisualOptionProperties>;
    /**
     * Identifies what visual configuration to use. Can be changed from anywhere.
     */
    readonly pluginKey: mobx.IObservableValue<string>;
    /**
     * visualInfo
     */
    readonly pluginStatus: Loading | Result<PluginStatus, React.ReactElement>;
    /**
     * Flush ui changes
     */
    flush(): Promise<void>;
    dispose(): void;
    renderConfiguration(options: RenderConfigurationsOptions): React.ReactElement;
    renderVisual(options: RenderVisualOptions): React.ReactElement;
}

export interface VisualModelArgs {
    readonly featureFlags: FeatureFlags<VisualFwkFeatureFlag>;
    readonly telemetry: IKweTelemetry;
    readonly parsedVisuals: ParsedVisuals;
    readonly layout: VisualConfig<string>['layout'];
    readonly dashboard?: RtdPluginApi;
    readonly headerLevel: 1 | 2 | 3 | 4 | 5;
    /**
     * Should read query status from mobx state
     */
    readonly queryResult: VisualFwkQueryResult;
    /**
     * Should use MobX to subscribe to locale changes
     */
    locale(): KweVisualFwkLocale;
}

export interface VisualModelInitialState {
    readonly options: UnknownVisualOptions;
    readonly visualType: string;
}

export function initVisualModel(
    args: VisualModelArgs,
    state: VisualModelInitialState = {
        options: {},
        visualType: DEFAULT_VISUAL_TYPE,
    }
): IVisualOptionsModel {
    return new VisualOptionsModel(args, state.options, state.visualType);
}
