/* eslint-disable import/newline-after-import, import/first, import/order */
import * as mobx from 'mobx';

import { generateCid } from '@kusto/app-common';
import { mobxConfig, type IKweTelemetry } from '@kusto/utils';

import './monacoConfig';

import type { IFrameAuthenticationProvider } from './AuthenticationProviders/IFrameAuthProvider/IFrameAuthenticationProvider';
import { appPages } from './common/AppPages';
import type { BootstrapDependencies } from './dependencies';
import { IframeProtection } from './IframeProtection';
import { initBootstrapDependencies } from './utils/initDependencies';
import { getMarketingInfo } from './utils/marketingCampaign';
import { getPath } from './utils/url';

import './index.css';

// Default stack trace limit on Chrome is 10, which is too small when libraries
// like Ag-Grid and MobX can cause our to be pretty deep.
Error.stackTraceLimit = 100;

// Configure before other imports in case a warning occurs during imports
mobx.configure(mobxConfig);

/*
 * This is the entry point to the application.
 * Here we make the most high level decisions. For example:
 * 1. Do we redirect for authentication or not (not in Ibiza)
 * 2. Where to we try to get cached state from (in Ibiza we have state per cluster)
 */

async function loadWebsite(
    dependencies: BootstrapDependencies,
    telemetry: IKweTelemetry,
    isUserLoggedIn: boolean
): Promise<void> {
    await setUserIdInTelemetry(dependencies);
    telemetry.trace('Loading website after authentication', {
        severityLevel: 'information',
    });
    await import('./index.authenticated').then((d) => d.renderApp(dependencies, isUserLoggedIn));
}

async function setUserIdInTelemetry(dependencies: BootstrapDependencies): Promise<void> {
    const account = await dependencies.authProvider.getAccount();
    // userID in AI MUST be oid (and not hashing the user's email for example) since the Geneva Agent
    // that handles DSR Export (a GDPR Requirement) depends on it.
    if (account?.localAccountId) {
        dependencies.appInsights.setAuthenticatedUserContext(account.localAccountId);
    }
}

async function loginAndLoadSite(dependencies: BootstrapDependencies, telemetry: IKweTelemetry): Promise<void> {
    const marketingCampaign = JSON.stringify(getMarketingInfo());
    telemetry.info('pageLoad.preAuthentication', { marketingCampaign });

    let isUserLoggedIn = true;
    // // Ibiza handles tokens for us. do not redirect to login if we're in ibiza.
    if (dependencies.featureFlags.untracked.ibizaPortal || dependencies.featureFlags.untracked.IFrameAuth) {
        await (dependencies.authProvider as IFrameAuthenticationProvider).waitingForToken();
    } else {
        isUserLoggedIn = await dependencies.loginManager!.login(dependencies.appSearch);

        // Keep IframeProtection function after standaloneLogin
        // MSAL some times use iframe as part of silent token
        if (IframeProtection(telemetry)) {
            return;
        }

        if (!isUserLoggedIn) {
            dependencies.queryDeepLinkProperties.appPage = appPages.PublicVirtualCluster;
        } else if (dependencies.queryDeepLinkProperties.appPage === appPages.PublicVirtualCluster) {
            dependencies.history.replace(getPath(appPages.Explorer));
        }
    }
    telemetry.info('pageLoad.postAuthentication', { marketingCampaign, isUserLoggedIn });

    // load site
    await loadWebsite(dependencies, telemetry, isUserLoggedIn);
}

async function main() {
    let telemetry: undefined | IKweTelemetry;
    try {
        // TODO: Consider initializing telemetry before anything else so we can
        // log more errors
        const dependencies = await initBootstrapDependencies();

        telemetry = dependencies.telemetry.bind(() => ({
            flow: 'load',
            component: 'index',
            referrer: document.referrer,
            isIbiza: dependencies.featureFlags.untracked.ibizaPortal?.toString(),
            iFrameAuth: dependencies.featureFlags.untracked.IFrameAuth?.toString(),
        }));

        telemetry.pageView({ name: 'index' });

        await loginAndLoadSite(dependencies, telemetry);
    } catch (error) {
        let _cid: undefined | string;

        if (telemetry) {
            try {
                const cidPrefix = 'KBS'; // Kwe Bootstrap
                const cid = generateCid(cidPrefix);
                telemetry.exception('bootstrap failure', { exception: error, cid, cidPrefix });
                _cid = cid; // Assign last so it only occurs if everything went well
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            }
        }

        import('./index.authenticated')
            .then((ux) => ux.renderRootError(_cid))
            .catch(() => {
                // In case something failed while attempting to render the crash page, we have a plain html error page fallback
                const main = document.createElement('main');
                main.classList.add('plainError');
                main.appendChild(document.createTextNode('Something went wrong'));
                document.getElementById('root')?.appendChild(main);
            });

        // If we're unable to log the error, re-throw so if App Insights
        // successfully initialized it will log the error
        if (!_cid) {
            throw error;
        }
    }
}

main();
