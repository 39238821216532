// TODO: Use the browsers built-in support for gzip once the api is supported by our target browsers
// https://developer.mozilla.org/en-US/docs/Web/API/DecompressionStream
import pako from 'pako';

export const URL_SEARCH_TRUE_VALUES = ['true', ''] as const;
export const URL_SEARCH_FALSE_VALUE = 'false';

/**
 * Returns a string of the url protocol following by the url domain
 * If the protocol is empty, the returned string will contains the domain only
 */
export function getDomainPrefixedByProtocol(url: URL): string {
    return `${url.protocol}${url.protocol ? '//' : ''}${url.host}`;
}

// Add function signature definition, to tell TS the return type (string | undefined) is based of the input.
export function removeSecret(url: string, secret?: string): string;
export function removeSecret(url: undefined, secret?: string): undefined;
export function removeSecret(url: string | undefined, secret?: string): string | undefined;
/**
 * Remove any secret from the url.
 *
 * @param url The url to remove the secret from.
 * @param secret The secret to remove from the url. Default is `sig` (signature).
 *
 * @returns The url with the secret removed.
 *
 * @example
 * `http://www.example.com/abc?A=1&sig=123456789&B=2` => `http://www.example.com/abc?A=1&sig=*****&B=2`
 * `Error message 'http://test.com?sig=123456789' invalid uri` => `Error message 'http://test.com?sig=*****' invalid uri`
 */
export function removeSecret(url: string | undefined, secret = 'sig'): string | undefined {
    if (!url) {
        return url;
    }

    return url.replace(new RegExp(`${secret}=[^&']+`), `${secret}=*****`);
}

/**
 * Encode query or command text, to use in URL param. Encoding is done with gzip and Base64.
 *
 * @param query Query or command plain text to encode.
 * @returns Encoded query or command text.
 */
export function encodedQueryParam(query: string): string {
    return btoa(pako.gzip(query, { to: 'string' }));
}

/**
 * Decode query or command text, from URL param. Decoding is done with Base64 and gunzip.
 *
 * @param encodedQuery Query or command text to decode.
 * @returns Decoded query or command plain text.
 */
export function decodedQueryParam(encodedQuery: string): string {
    return pako.ungzip(atob(encodedQuery), { to: 'string' });
}

/** Create a URL param from the query, adding gzip and encode with Base64. */
export function createQueryParam(query: string): string {
    const encoded = encodedQueryParam(query);
    return `query=${encodeURIComponent(encoded)}`;
}
