import React from 'react';
import { Switch } from '@fluentui/react-components';
// lodash import is restricted to avoid importing the entire library, so
// type-only imports are safe
// eslint-disable-next-line no-restricted-imports
import type { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import { DropdownWithSearch, DropdownWithSearchOption, InputField, SelectedOption } from '@kusto/ui-components';
import { useRegisterDebounce } from '@kusto/utils';

import { VISUAL_OPTIONS__TEXT_DEBOUNCE } from '../constants';
import type { KweVisualFwkLocale } from '../types';
import { VisualInput, VisualInputModel, VisualSelector } from '../visualConfig/input';
import { VisualOptionKey, VisualOptionsKeysFor } from '../visualOptions';

import * as styles from './styles.module.scss';

export const optionLabelStyle = { label: { fontWeight: 'normal' as const } };

export function createBoolToggle<C extends VisualOptionKey>(
    key: VisualOptionsKeysFor<C, boolean>,
    label: string,
    invert?: boolean
): VisualInput<C> {
    return {
        id: `toggle--${key}`,
        keys: [key],
        Component: observer(function EtpVisualOptionsToggle({ disabled, model }) {
            let checked = model.get(key);
            if (invert) {
                checked = !checked;
            }
            const onChange = mobx.action(() => model.set(key, !model.get(key)));

            return (
                <Switch
                    className={styles.basicInput}
                    checked={checked}
                    onChange={onChange}
                    label={label}
                    disabled={disabled}
                />
            );
        }),
    };
}

export function createStaticMultiSelect<C extends VisualOptionKey, V extends string>(
    strings: KweVisualFwkLocale,
    key: VisualOptionsKeysFor<C, readonly V[]>,
    label: string,
    options: Array<{ key: V; text: string }>,
    valueSelector: VisualSelector<C, readonly V[]>,
    applyChange: (selection: V[], model: VisualInputModel<C>) => void,
    id = `staticMultiSelect--${key}`
): VisualInput<C> {
    return {
        id,
        keys: [key],
        Component: observer(function EtpVisualOptionsStaticDropdown({ disabled, model }) {
            const { onChange, selectedKeys } = React.useMemo(
                () => ({
                    onChange: mobx.action((_: unknown, option?: SelectedOption) => {
                        if (!option) {
                            return;
                        }
                        const selectedValues = model.resolveSelector(valueSelector).get();
                        const newSelected = option.selected;
                        let newKeys: V[] | undefined;
                        const newKey = option.key as V;
                        if (newSelected) {
                            if (!selectedValues.includes(newKey)) {
                                newKeys = [...selectedValues, newKey];
                            }
                        } else if (selectedValues.includes(newKey)) {
                            newKeys = selectedValues.filter((k) => k !== newKey);
                        }
                        if (newKeys) {
                            applyChange(newKeys, model);
                        }
                    }),
                    selectedKeys: mobx.computed(() => model.resolveSelector(valueSelector).get()),
                }),
                [model]
            );

            return (
                <DropdownWithSearch
                    multiselect={true}
                    className={styles.basicInput}
                    selectedKeys={Array.from(selectedKeys.get())}
                    options={options}
                    onChange={onChange}
                    label={label}
                    disabled={disabled}
                    placeholder={strings.visualFwk.visualConfig.multiSelectPlaceholder}
                />
            );
        }),
    };
}

export function createStaticDropdown<C extends VisualOptionKey, V extends string>(
    keys: readonly C[],
    label: string,
    options: ReadonlyArray<V | { readonly key: V; readonly text: string }>,
    valueSelector: VisualSelector<C, V>,
    applyChange: (selection: V, model: VisualInputModel<C>) => void,
    id = `staticDropdown--${keys.join()}`
): VisualInput<C> {
    const parsedOptions: Array<DropdownWithSearchOption<undefined, V>> = options.map((o) => {
        if (typeof o === 'string') {
            return { key: o, text: o };
        }
        return o;
    });

    return {
        id,
        keys,
        Component: observer(function EtpVisualOptionsStaticDropdown({ disabled, model }) {
            const onChange = React.useMemo(
                () =>
                    mobx.action((_: unknown, option?: SelectedOption) =>
                        applyChange((option as unknown as DropdownWithSearchOption<undefined, V>).key, model)
                    ),
                [model]
            );

            return (
                <DropdownWithSearch
                    className={styles.basicInput}
                    selectedKeys={model.resolveSelector(valueSelector).get()}
                    options={parsedOptions}
                    onChange={onChange}
                    label={label}
                    disabled={disabled}
                />
            );
        }),
    };
}

export function createTextInput<C extends VisualOptionKey>(
    key: VisualOptionsKeysFor<C, string>,
    label: string
): VisualInput<C> {
    return {
        id: `text--${key}`,
        keys: [key],
        Component: observer(function EtpVisualOptionsTextInput({ disabled, model }) {
            // Local values shadow global values, and are cleared when debounce resolves
            const [localValue, setLocalValue] = React.useState<undefined | string>(undefined);
            const globalValue = model.get(key);

            const onChange = React.useMemo(() => {
                const setGlobalValue = debounce(
                    mobx.action((newValue: string) => {
                        // Clear local state. Until this is done this input won't respond to
                        // changes to the global value.
                        setLocalValue(undefined);
                        model.set(key, newValue);
                    }),
                    VISUAL_OPTIONS__TEXT_DEBOUNCE
                );

                const innerOnChange = ((newValue: string) => {
                    if (newValue !== undefined) {
                        setLocalValue(newValue);
                        setGlobalValue(newValue);
                    }
                }) as DebouncedFunc<(newValue: string) => void>;
                innerOnChange.flush = setGlobalValue.flush;
                innerOnChange.cancel = setGlobalValue.cancel;
                return innerOnChange;
            }, [model]);

            useRegisterDebounce(model.registerFlush, onChange);

            return (
                <InputField
                    label={label}
                    className={styles.basicInput}
                    value={localValue ?? globalValue}
                    onInputChange={onChange}
                    disabled={disabled}
                />
            );
        }),
    };
}
