#chartResultContainer {
    flex: 1 1 0px;
    // Solves bug: when resizing horizontal bar for visualizations, to increase editor size, the chart doesn't rerender to fit the smaller real estate.
    min-height: 0;

    display: grid;
    grid-template: 1fr / 1fr;
    background: transparent;
    padding-top: 6;
    overflow: auto;
}

.pinToDashboardWarning {
    margin: 0 16px;
}
