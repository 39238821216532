button.card {
    display: inline-block;
    width: 184px;
    height: 148px;
    margin: var(--spacingHorizontalM);
    padding: 0;
    box-shadow: var(--shadow4);

    .iconContainer {
        background: var(--colorBrandBackground2);
        height: 96px;
        border-bottom: 1px solid var(--colorNeutralStroke2);
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            width: 34px;
            height: 34px;
        }
    }

    .buttonText {
        padding: var(--spacingHorizontalL);
        text-align: initial;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
