import { useFluent_unstable } from '@fluentui/react-shared-contexts';
import { useCallback } from 'react';
import { useBodyNoScrollStyles, useHTMLNoScrollStyles } from './useDisableBodyScroll.styles';
/**
 * @internal
 * A React *hook* that disables body scrolling through `overflowY: hidden` CSS property
 */ export function useDisableBodyScroll() {
    const htmlNoScrollStyles = useHTMLNoScrollStyles();
    const bodyNoScrollStyles = useBodyNoScrollStyles();
    const { targetDocument } = useFluent_unstable();
    const disableBodyScroll = useCallback(()=>{
        var _targetDocument_defaultView;
        if (!targetDocument) {
            return;
        }
        var _targetDocument_defaultView_innerHeight;
        const isHorizontalScrollbarVisible = targetDocument.body.clientHeight > ((_targetDocument_defaultView_innerHeight = (_targetDocument_defaultView = targetDocument.defaultView) === null || _targetDocument_defaultView === void 0 ? void 0 : _targetDocument_defaultView.innerHeight) !== null && _targetDocument_defaultView_innerHeight !== void 0 ? _targetDocument_defaultView_innerHeight : 0);
        if (!isHorizontalScrollbarVisible) {
            return;
        }
        targetDocument.documentElement.classList.add(htmlNoScrollStyles);
        targetDocument.body.classList.add(bodyNoScrollStyles);
        return;
    }, [
        targetDocument,
        htmlNoScrollStyles,
        bodyNoScrollStyles
    ]);
    const enableBodyScroll = useCallback(()=>{
        if (!targetDocument) {
            return;
        }
        targetDocument.documentElement.classList.remove(htmlNoScrollStyles);
        targetDocument.body.classList.remove(bodyNoScrollStyles);
    }, [
        targetDocument,
        htmlNoScrollStyles,
        bodyNoScrollStyles
    ]);
    return {
        disableBodyScroll,
        enableBodyScroll
    };
}
