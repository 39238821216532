import localforage from 'localforage';
import { getSnapshot } from 'mobx-state-tree';

import { QueryResults } from './queryCompletionInfo';
import type { QueryMstEnv } from './storeEnv';

export interface QueryResultStore {
    getResultsAsync(env: QueryMstEnv, id: number | undefined): Promise<QueryResults | undefined>;
    setResultsAsync(id: number, results: QueryResults): Promise<typeof QueryResults.SnapshotType>;
    removeResultsAsync(id: number): Promise<void>;
}

export class QueryResultPersistentStore implements QueryResultStore {
    constructor(private keyPrefix: string) {}
    public async getResultsAsync(env: QueryMstEnv, id: number | undefined): Promise<QueryResults | undefined> {
        if (!id) {
            return undefined;
        }

        const val = await localforage.getItem(this.getKey(id));
        if (!val) {
            return undefined;
        }

        return QueryResults.create(val as typeof QueryResults.SnapshotType, env);
    }

    public setResultsAsync(id: number, results: QueryResults) {
        const snapshot = getSnapshot(results);
        return localforage.setItem(this.getKey(id), snapshot);
    }

    public removeResultsAsync(id: number) {
        return localforage.removeItem(this.getKey(id));
    }

    private getKey(id: number): string {
        return `${this.keyPrefix}_${id.toString()}`;
    }
}
