import React, { CSSProperties } from 'react';

const Highlight = (props: { text: string }) => {
    return <span style={{ background: 'yellow' }}>{props.text}</span>;
};

export interface HighlightTextProps {
    /** Text to be displayed */
    text: string;
    /** Text to be highlighted (sub-string of the main `text`) */
    highlightText?: string;
    /** Custom style for the component */
    style?: CSSProperties;
}

/** Display text message, with highlighted sub-string. Used to highlight some parts of the main text */
export const HighlightText: React.FunctionComponent<HighlightTextProps> = ({ text, highlightText, style }) => {
    let retElement: string | (JSX.Element | string)[];

    if (highlightText) {
        const regEx = new RegExp(`(${highlightText})`, 'gi'); // RegEx inside parenthesis will cause the split method to return the separator as well; g: global; i: ignore-case;
        const parts = text.split(regEx);
        const lowerCaseSearch = highlightText.toLowerCase();

        retElement = parts.map((item, index) =>
            item.toLowerCase() === lowerCaseSearch ? <Highlight text={item} key={index} /> : item
        );
    } else {
        // no highlight
        retElement = text;
    }

    return <span style={style}>{retElement}</span>;
};
