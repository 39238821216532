import React from 'react';
import { Button, ButtonProps } from '@fluentui/react-components';
import { Add20Regular } from '@fluentui/react-icons';
import * as mobx from 'mobx';
import { Observer, observer } from 'mobx-react-lite';

import { useRender } from '@kusto/ui-components';
import { KweException, useAbortSignal, usePullState } from '@kusto/utils';

import type { InteractionTarget } from '../../interactions/target';
import type { KweVisualFwkLocale } from '../../types';
import { VisualInput, VisualInputSelector } from '../../visualConfig/input';
import { CrossFilterConfig, VisualOptionKey, VisualOptionsKeysFor } from '../../visualOptions';
import { ConfigurationItemCallout } from '../configurationList/ConfigurationItemCallout';
import { CrossFilterForm } from './Form';
import { CrossFilterRow, rowId } from './Row';
import type { CrossFilterVisual } from './VisualInteraction';

import * as configurationListStyles from '../configurationList/styles.module.scss';

type AddCrossFilterButtonProps = ButtonProps & {
    t: KweVisualFwkLocale;
};

const AddCrossFilterButton: React.FC<AddCrossFilterButtonProps> = ({ t, ...props }) => {
    return (
        // Adding extra wrapping div for the sake of simple CSS and centering on tooltip
        <div
            className={`${configurationListStyles.addConfigItemButton} ${configurationListStyles.addConfigItemButtonMarginAdjust}`}
        >
            <Button appearance="transparent" icon={<Add20Regular />} {...props}>
                {t.visualFwk.visualConfig.interactions.crossFilter.addConfigurationButtonText}
            </Button>
        </div>
    );
};

interface CrossFilterDialogProps {
    t: KweVisualFwkLocale;
    config: CrossFilterConfig;
    targetId: string;
    onDismiss: () => void;
    onApply: (config: CrossFilterConfig) => void;
    parameters: readonly InteractionTarget[];
    interactions: readonly CrossFilterVisual.Interaction[];
    interactionsIndex: InteractionsIndex;
    parametersRecord: undefined | ReadonlyMap<string, InteractionTarget>;
}

const CrossFilterDialog: React.FC<CrossFilterDialogProps> = ({
    t,
    config,
    targetId,
    onDismiss,
    onApply,
    parameters,
    interactions,
    interactionsIndex,
    parametersRecord,
}) => {
    const [state, setState, getState] = usePullState(config);

    const { onSave, onChange } = React.useMemo(
        () => ({
            onSave() {
                onApply(getState());
            },
            onChange(_id: number, value: CrossFilterConfig) {
                setState(value);
            },
        }),
        [getState, onApply, setState]
    );

    return (
        <ConfigurationItemCallout
            title={t.visualFwk.visualConfig.interactions.crossFilter.calloutTitle}
            targetId={targetId}
            position="below"
            className={configurationListStyles.withMargin}
            onClose={onDismiss}
            onSave={onSave}
            applyButtonLabel={t.utils.util.buttons.apply}
            cancelButtonLabel={t.utils.util.buttons.cancel}
        >
            <CrossFilterForm
                t={t}
                id={0}
                value={state}
                onChange={onChange}
                getValue={getState}
                disabled={false}
                parameters={parameters}
                showDisable
                interactions={interactions}
                interactionsIndex={interactionsIndex}
                parametersRecord={parametersRecord}
            />
        </ConfigurationItemCallout>
    );
};

let lastConfigId = 0;
function newConfigId() {
    return lastConfigId++;
}

function newConfig(): CrossFilterConfig {
    return { interaction: undefined, property: undefined, parameterId: undefined, disabled: false };
}

export type InteractionsIndex = ReadonlyMap<
    string,
    {
        readonly interaction: CrossFilterVisual;
        readonly properties?: ReadonlyMap<string, CrossFilterVisual.Property>;
    }
>;

function indexInteractions(interactions: readonly CrossFilterVisual[]): InteractionsIndex {
    const index = new Map<
        string,
        {
            readonly interaction: CrossFilterVisual;
            readonly properties?: ReadonlyMap<string, CrossFilterVisual.Property>;
        }
    >();

    for (const interaction of interactions) {
        let properties: undefined | Map<string, CrossFilterVisual.Property>;
        if (interaction.kind === 'available') {
            properties = new Map();
            for (const property of interaction.properties) {
                properties.set(property.id, property);
            }
        }
        index.set(interaction.id, { interaction, properties });
    }

    return index;
}

/**
 * @param interactionsConfig Returning `undefined` disables the input
 * @param idSuffix Right now we're overloading the `CrossFilterConfig` type and
 * saving in it that's different. Use this key to differentiate between them.
 */
export function createCrossFilterConfig<C extends VisualOptionKey, H = unknown>(
    key: VisualOptionsKeysFor<C, null | readonly CrossFilterConfig[]>,
    idSuffix: string,
    interactionsConfig: VisualInputSelector<C, CrossFilterVisual | readonly CrossFilterVisual[], H>
): VisualInput<C, H, Map<number, CrossFilterConfig>> {
    return {
        id: `crossFilter--${key}--${idSuffix}`,
        keys: [key],
        init(model, prev) {
            if (prev) {
                return prev;
            }
            let initial = model.get(key);
            if (initial.length === 0) {
                initial = [newConfig()];
            }
            return new Map(initial.map((config: CrossFilterConfig) => [newConfigId(), config]));
        },
        changed(model) {
            const config = model.getTemp();
            if (config.size !== 1) {
                return true;
            }
            const value = config.values().next().value;
            return (
                value.disabled ||
                value.interaction !== undefined ||
                value.parameterId !== undefined ||
                value.property !== undefined
            );
        },
        Component: observer(function CrossFilterManagedConfig({ t, model, disabled, dashboard }) {
            if (!dashboard) {
                throw new KweException('Drillthrough configuration is only support in dashboards');
            }
            const abortSignal = useAbortSignal();
            const render = useRender();

            // Removing mobx proxy because child components aren't observer components
            const parametersRecord = React.useMemo(
                () => mobx.computed(() => new Map(dashboard.state.interactionTargetsMap)),
                [dashboard]
            ).get();

            const { onChange, getValue, onAdd, onDelete, onEdit, interactionsObs } = React.useMemo(() => {
                const interactions = mobx.computed((): readonly CrossFilterVisual.Interaction[] => {
                    const result = model.resolveSelector(interactionsConfig).get();
                    if (Array.isArray(result)) {
                        return result;
                    }
                    return [result as CrossFilterVisual.Interaction];
                });
                const dialogState = mobx.observable<{ showingDialog: boolean; dispose: null | (() => void) }>({
                    showingDialog: false,
                    dispose: null,
                });

                return {
                    interactionsObs: interactions,
                    onChange: mobx.action((id: number, crossFilter: CrossFilterConfig) => {
                        const prev = model.getTemp();
                        if (!prev.has(id)) {
                            // Value has been deleted. Ignore action.
                            return;
                        }
                        const next = new Map(prev);
                        next.set(id, crossFilter);
                        model.setTemp(next);
                        model.set(key, [...next.values()]);
                    }),
                    getValue: mobx.action((id: number) => model.getTemp().get(id)),
                    onAdd: mobx.action(() => {
                        const next = new Map(model.getTemp());
                        const id = newConfigId();
                        next.set(id, newConfig());
                        model.setTemp(next);
                        model.set(key, [...next.values()]);
                        onEdit(id);
                    }),
                    onDelete: mobx.action((id: number) => {
                        const prev = model.getTemp();
                        if (!prev.has(id) || prev.size === 1) {
                            return;
                        }
                        const next = new Map(prev);
                        next.delete(id);
                        // If there is only 1 value left, and it's disabled, enable it.
                        if (next.size === 1 && next.values().next().value.disabled) {
                            const [lastId, value] = next.entries().next().value;
                            next.set(lastId, {
                                ...value,
                                disabled: false,
                            });
                        }
                        model.setTemp(next);
                        model.set(key, [...next.values()]);
                    }),
                    onEdit: mobx.action((id: number) => {
                        const prev = model.getTemp().get(id);
                        if (!prev) {
                            return;
                        }

                        render((dispose, { signal }) => {
                            signal.addEventListener(
                                'abort',
                                mobx.when(() => model.getTemp().get(id) === undefined, dispose)
                            );

                            // Enables UX where clicking on any
                            // edit button will close any opened
                            // dialog
                            if (dialogState.showingDialog) {
                                dialogState.dispose?.();
                                dialogState.showingDialog = false;
                                dialogState.dispose = null;
                            } else {
                                dialogState.dispose = dispose;
                                dialogState.showingDialog = true;
                            }

                            return (
                                <Observer>
                                    {() => (
                                        <CrossFilterDialog
                                            t={t}
                                            targetId={`${rowId(id)}`}
                                            config={prev}
                                            onDismiss={dispose}
                                            onApply={(next) => {
                                                onChange(id, next);
                                                dispose();
                                            }}
                                            parameters={dashboard.state.interactionTargets}
                                            interactions={interactions.get()}
                                            interactionsIndex={indexInteractions(interactions.get())}
                                            parametersRecord={new Map(dashboard.state.interactionTargetsMap)}
                                        />
                                    )}
                                </Observer>
                            );
                        }, abortSignal);
                    }),
                };
            }, [abortSignal, model, render, dashboard, t]);

            const resolvedInteractions = interactionsObs.get();

            const tempValues = [...model.getTemp().entries()];

            const interactionsIndex = React.useMemo(
                () => indexInteractions(resolvedInteractions),
                [resolvedInteractions]
            );

            if (tempValues.length === 1) {
                return (
                    <>
                        <CrossFilterForm
                            t={t}
                            id={tempValues[0][0]}
                            value={tempValues[0][1]}
                            onChange={onChange}
                            getValue={getValue}
                            disabled={disabled || interactionsConfig === undefined}
                            interactions={resolvedInteractions}
                            parameters={dashboard.state.interactionTargets}
                            showDisable={false}
                            interactionsIndex={interactionsIndex}
                            parametersRecord={parametersRecord}
                        />
                        <AddCrossFilterButton t={t} onClick={onAdd} />
                    </>
                );
            }
            return (
                <>
                    <div>
                        {tempValues.map(([id, config]) => (
                            <CrossFilterRow
                                t={t}
                                key={id}
                                id={id}
                                config={config}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                parametersRecord={parametersRecord}
                                interactionsIndex={interactionsIndex}
                            />
                        ))}
                    </div>
                    <AddCrossFilterButton t={t} onClick={onAdd} />
                </>
            );
        }),
    };
}
