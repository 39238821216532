import { Dispatch, MutableRefObject, RefObject, useCallback } from 'react';
import type { IList } from '@fluentui/react/lib/List';
import type { ISearchBox } from '@fluentui/react/lib/SearchBox';
import type { ITextField } from '@fluentui/react/lib/TextField';
import { KeyCodes } from '@fluentui/react/lib/Utilities';

import { CustomListDropdownMenuAction } from './reducer';
import { RTDDropdownOption } from './types';
import { buildRowHeightFunc } from './util';

/**
 * This hook's purpose is provide event handling
 * for a text input that is rendered inside the
 * dropdown's custom onRenderHeader function
 */
export function useOnInputKeyDown(
    filteredOptions: RTDDropdownOption[],
    currentActiveIndex: MutableRefObject<number | undefined>,
    listRef: IList | undefined,
    inputRef: RefObject<ITextField | ISearchBox>,
    dispatch: Dispatch<CustomListDropdownMenuAction>
) {
    return useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            switch (event.which) {
                case KeyCodes.up:
                case KeyCodes.down:
                    event.stopPropagation();
                    event.preventDefault();
                    return handleUpAndDownArrow(
                        event.which === KeyCodes.up ? 'up' : 'down',
                        filteredOptions,
                        currentActiveIndex,
                        listRef,
                        dispatch
                    );
                // Home and end are captured by the Callout, so we must manually handle them
                // so the input doesn't lose focus when these are pressed
                case KeyCodes.home:
                case KeyCodes.end: {
                    event.preventDefault();
                    event.stopPropagation();
                    const ref = inputRef.current;
                    if (ref) {
                        if ('setSelectionRange' in ref) {
                            // for normal text input component
                            const index = event.which === KeyCodes.home ? 0 : ref.value?.length ?? 0;
                            ref.setSelectionRange(index, index);
                        } else {
                            // for SearchBox component
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            const inputEl = (ref as any)['_inputElement'].current as undefined | HTMLInputElement;
                            if (inputEl) {
                                const index = event.which === KeyCodes.home ? 0 : inputEl.value.length;
                                inputEl.setSelectionRange(index, index);
                            }
                        }
                    }
                    return;
                }
            }
        },
        [filteredOptions, currentActiveIndex, listRef, inputRef, dispatch]
    );
}

/**
 * Key codes that when pressed means
 * we should auto focus the input.
 * This isn't an exhaustive list.
 * I think this hook could get removed
 * if we properly redo the focus
 * management for the ListBox
 * and its items.
 */
const whitelistedKeyCodes: number[] = [
    KeyCodes.zero,
    KeyCodes.one,
    KeyCodes.two,
    KeyCodes.three,
    KeyCodes.four,
    KeyCodes.five,
    KeyCodes.six,
    KeyCodes.seven,
    KeyCodes.eight,
    KeyCodes.nine,
    KeyCodes.multiply,
    KeyCodes.add,
    KeyCodes.subtract,
    KeyCodes.decimalPoint,
    KeyCodes.divide,
    KeyCodes.a,
    KeyCodes.b,
    KeyCodes.c,
    KeyCodes.d,
    KeyCodes.e,
    KeyCodes.f,
    KeyCodes.g,
    KeyCodes.h,
    KeyCodes.i,
    KeyCodes.j,
    KeyCodes.k,
    KeyCodes.l,
    KeyCodes.m,
    KeyCodes.n,
    KeyCodes.o,
    KeyCodes.p,
    KeyCodes.q,
    KeyCodes.r,
    KeyCodes.s,
    KeyCodes.t,
    KeyCodes.u,
    KeyCodes.v,
    KeyCodes.w,
    KeyCodes.x,
    KeyCodes.y,
    KeyCodes.z,
    KeyCodes.semicolon,
    KeyCodes.equalSign,
    KeyCodes.comma,
    KeyCodes.dash,
    KeyCodes.period,
    KeyCodes.forwardSlash,
    KeyCodes.graveAccent,
    KeyCodes.openBracket,
    KeyCodes.backSlash,
    KeyCodes.closeBracket,
    KeyCodes.singleQuote,
];

/**
 * This hook's goal is to
 * preserve the previous behavior
 * where the input would have the focus trapped
 * so a user could type at any point and
 * see the list results (e.g. filtering) without
 * needing to manually focus the input first.
 *
 * It does this by auto focusing when a key
 * from the whitelist is pressed down.
 * On Arrow Up/Down, we handle those separately
 * to update the `activeIndex` so that state
 * change wil get caught by the ListCell in
 * CustomListDropdownMenu and auto-focus
 * the button (single-select) or checkbox (multi-select)
 * if it needs to.
 */
export function useOnWrapperKeyDown(
    filteredOptions: RTDDropdownOption[],
    currentActiveIndex: MutableRefObject<number | undefined>,
    listRef: IList | undefined,
    inputRef: RefObject<ITextField | ISearchBox>,
    focusDropdown: () => void,
    dispatch: Dispatch<CustomListDropdownMenuAction>
) {
    return useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.which === KeyCodes.up || e.which === KeyCodes.down) {
                /**
                 * For up and down arrows
                 * we need to call the handler for this
                 * so it can increment/decrement the
                 * activeIndex state
                 */
                return handleUpAndDownArrow(
                    e.which === KeyCodes.up ? 'up' : 'down',
                    filteredOptions,
                    currentActiveIndex,
                    listRef,
                    dispatch
                );
            }

            if (e.which === KeyCodes.escape) {
                /**
                 * Manually focusing here because for SearchDropdownMenu
                 * Fluent loses the dropdown element it needs to re-focus when
                 * a customer hits the ESC key. My guess is because we're
                 * switching the focus onto the input search box.
                 *
                 * We shouldn't need to do this for the Input or regular
                 * dropdown but it doesn't change the ux. So it's safe to call.
                 */
                focusDropdown();
                return;
            }

            if (whitelistedKeyCodes.includes(e.which)) {
                /*
                 * We need to manually focus the searchbox
                 * when a user presses a whitelisted key.
                 * Ideally these are alphanumeric and symbol
                 * characters.
                 *
                 * This way a user can start typing a letter
                 * which the input will pick up and the
                 * input onChange will get triggered.
                 * For example, in the case of the
                 * SearchDropdown, this means a user
                 * typing an unreserved keyword means
                 * it will apply the value as a filter.
                 * Thus, they don't need to manually
                 * focus the input to start filtering the
                 * dropdown options and can just start
                 * immediately typing as long as their
                 * focus is within the wrapper div.
                 */
                inputRef.current?.focus();
            }
        },
        [filteredOptions, currentActiveIndex, listRef, inputRef, focusDropdown, dispatch]
    );
}

/**
 * Handles increment/decrement the
 * activeIndex state based on the direction
 */
function handleUpAndDownArrow(
    direction: 'up' | 'down',
    filteredOptions: RTDDropdownOption[],
    currentActiveIndex: MutableRefObject<number | undefined>,
    listRef: IList | undefined,
    dispatch: Dispatch<CustomListDropdownMenuAction>
) {
    if (filteredOptions.length === 0) {
        // Do nothing
        return;
    }

    let newFilteredIndex = currentActiveIndex.current ?? -1;
    const stepIndex = direction === 'up' ? -1 : 1;

    newFilteredIndex += stepIndex;

    if (newFilteredIndex >= filteredOptions.length) {
        newFilteredIndex = 0;
    } else if (newFilteredIndex < 0) {
        newFilteredIndex = filteredOptions.length - 1;
    }

    let extraSteps = 0;
    while (filteredOptions[newFilteredIndex].disabled || filteredOptions[newFilteredIndex].hidden) {
        // If current value is disabled or hidden, continue moving until a valid option is found
        if (extraSteps >= filteredOptions.length) {
            // Iterated through whole list. Give up
            dispatch({
                type: 'setActiveIndex',
                index: undefined,
            });

            return;
        }

        if (newFilteredIndex + stepIndex >= filteredOptions.length) {
            // Indexes are off by one, as they're about to be immediately incremented
            newFilteredIndex = -1;
        } else if (newFilteredIndex + stepIndex < 0) {
            newFilteredIndex = filteredOptions.length;
        }

        newFilteredIndex += stepIndex;

        extraSteps++;
    }

    dispatch({
        type: 'setActiveIndex',
        index: newFilteredIndex,
    });

    // Immediately scroll to this index, rather than waiting for the next render tick
    listRef?.scrollToIndex(newFilteredIndex, buildRowHeightFunc(filteredOptions));
}
