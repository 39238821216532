import type { KweVisualFwkLocale } from '../../types';
import type { VisualInputModel } from '../../visualConfig/input';
import type { MultipleYAxesConfig } from '../../visualOptions/property';

export function convertStringToNumber(value?: string): number | null {
    if (value === '' || value === undefined) {
        return null;
    }
    const num = parseFloat(value);

    if (Number.isNaN(num)) {
        return null;
    }

    return num;
}

export function createAxisScaleOptions(strings: KweVisualFwkLocale) {
    return [
        {
            key: 'linear' as const,
            text: strings.visualFwk.visualConfig.axisScale.linear,
        },
        {
            key: 'log' as const,
            text: strings.visualFwk.visualConfig.axisScale.log,
        },
    ];
}

export type MultipleYAxesModel = VisualInputModel<
    'multipleYAxes' | 'yColumns' | 'yAxisMinimumValue' | 'yAxisMaximumValue'
>;

export type ApplyYAxisChange = (items: MultipleYAxesConfig) => MultipleYAxesConfig | undefined;
