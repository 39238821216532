import * as React from 'react';

// `event` should always be object-like so blind checking
// for the properties metaKey, altKey, etc. is fine
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isModifiedEvent(event: any) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

/**
 * Let browser handle "target=_blank", "_top" and "_parent".
 */
function isInvalidTarget(event: React.SyntheticEvent<unknown>) {
    // @ts-expect-error React.SyntheticEvent doesn't include `target` but it will exist
    return event.currentTarget.target && event.currentTarget.target !== '_self';
}

export interface HrefCapturingOptions {
    /**
     * Default to false
     */
    ignoreExternalLinks?: boolean;
}

/**
 * Creates event handlers that prevent `<a/>` elements from causing navigation
 *
 * ## How
 * If first argument would trigger navigation, suppresses it and calls the
 * supplied function
 *
 * ## Why
 * If we're unable to use our router's `Link` component, use this to prevent navigation
 *
 * @example Minimal
 * import React from 'react';
 * import { hrefCapturing } from '@kusto/utils';
 *
 * const MyComponent: React.FC = () => {
 *     return <a href="https://www.youtube.com/watch?v=EtuDS0ntaJY" onClick={hrefCapturing(() => { // Navigation is suppressed })}>Click me</a>;
 * }
 *
 * @example Fluent 9 <Link /> + React Router
 * import React from 'react';
 * import { Link } from "@fluentui/react-components";
 * import { useHistory } from 'react-router-dom';
 *
 * import { hrefCapturing } from '@kusto/utils';
 *
 * const MyComponent: React.FC = () => {
 *   const history = useHistory();
 *
 *   const pathName = '/kusto-spaghetti-agency';
 *
 *   return <Link href={pathName} onClick={hrefCapturing((() => history.push(pathName))}>{pathName}</Link>;
 * }
 */
export function hrefCapturing<A extends [React.SyntheticEvent<unknown> | undefined]>(
    callback: (...args: A) => void,
    options?: HrefCapturingOptions
) {
    return function handler(...args: A) {
        const event = args[0];

        // Derived from react-router:
        // https://github.com/remix-run/react-router/blob/351a2a378880ad2a1704c2acf6c8f4f78b99d0b8/packages/react-router-dom/index.tsx#L400
        if (
            event === undefined ||
            event.defaultPrevented || // onClick prevented default
            isInvalidTarget(event) ||
            ('button' in event && event.button !== 0) || // ignore everything but left clicks
            isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
            return;
        }

        if (options?.ignoreExternalLinks && event.currentTarget instanceof HTMLAnchorElement) {
            const url = new URL(event.currentTarget.href, window.location.href);
            if (url.origin !== window.location.origin) {
                return;
            }
        }

        // Prevent navigating to the href
        event.preventDefault();

        return callback(...args);
    };
}
