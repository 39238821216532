import React, { CSSProperties, useLayoutEffect, useRef, useState } from 'react';
import { Tooltip, TooltipProps } from '@fluentui/react-components';

const overflowStyle: CSSProperties = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
};

export interface OverflowTooltipProps
    // omit for props override
    extends Omit<TooltipProps, 'children' | 'content' | 'relationship'> {
    /**
     * The text/element to display in the tooltip bubble.
     * If not provided the component children will appear in the tooltip.
     */
    content?: JSX.Element | string; // override `content` - Changing type to JSX.Element instead of Slot<T>.

    /**
     * Specifies whether the tooltip is acting as the `description` or `label`.
     * Defaults to `label`.
     *
     * For more info see `TooltipProps['relationship']` (base props) JS Docs
     */
    relationship?: TooltipProps['relationship']; // Override `relationship` - making optional.

    /**
     * Rendered children. Will act as the tooltip anchor (trigger).
     */
    children: JSX.Element | string; // Override `children` - Changing type to JSX.Element, removing nullable and undefined types.
}

/** Adds a tooltip over the children only if children content is overflowing (not all content fits the container). */
export const OverflowTooltip: React.FunctionComponent<OverflowTooltipProps> = (props) => {
    const { content, children } = props;

    const ref = useRef<HTMLDivElement>(null);

    const [isOverflown, setIsOverflown] = useState(false);

    // Fires synchronously after all DOM mutations.
    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const checkOverflow = (entry: any) => {
            if (entry.target) {
                setIsOverflown(entry.target.clientWidth < entry.target.scrollWidth);
            }
        };

        const resizeObserver = new ResizeObserver(([entry]) => checkOverflow(entry));
        resizeObserver.observe(ref.current);

        // Initial calling of the overflow check to set correct overflow status
        checkOverflow(ref.current);

        return () => resizeObserver.disconnect();
    });

    const render: JSX.Element = (
        <div ref={ref} style={overflowStyle}>
            {children}
        </div>
    );

    return isOverflown ? (
        <Tooltip relationship="label" {...props} content={content ?? children}>
            {render}
        </Tooltip>
    ) : (
        render
    );
};
