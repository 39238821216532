import { GridApi } from '@ag-grid-community/core';
import { elementToSVG } from 'dom-to-svg';
import * as mobx from 'mobx';

import { KweException } from '@kusto/utils';
import { CHART_WITH_PANELS_RESULT_CONTAINER_ID, EXPAND_ROW_COL_ID } from '@kusto/visualizations';

import { Tab } from '../../stores';

import * as resultStyles from './QueryResultVisualization.module.scss';

/**
 * get range selection from ag-grid, removing 'expand' column.
 */
export function getRangeSelection(gridApi: GridApi) {
    const rangeSelections = gridApi.getCellRanges();

    if (!rangeSelections || rangeSelections.length === 0) {
        throw new KweException('getRangeSelection: getCellRanges is null, undefined,  or zero length array');
    }

    // remove 'expand' column from selection (since it's only meant for UX interactions and not part of the data)
    rangeSelections.forEach((selection) => {
        selection.columns = selection.columns.filter((column) => column.getColId() !== EXPAND_ROW_COL_ID);
    });

    return rangeSelections;
}

export function captureResultImageIfNeeded(tab: Tab) {
    if (!tab || !mobx.runInAction(() => tab.isChartDisplayed)) {
        return;
    }

    const maybeInteractiveLegendChart = document.querySelectorAll('[class*="chartWithLegend"]');
    let domNode =
        maybeInteractiveLegendChart.length === 1
            ? maybeInteractiveLegendChart[0]
            : document.querySelector(`#${CHART_WITH_PANELS_RESULT_CONTAINER_ID}`);

    if (!domNode) {
        const canvasFromDom = document.querySelector(
            `#${resultStyles.chartResultContainer} canvas`
        ) as HTMLCanvasElement;
        if (canvasFromDom) {
            tab.setCanvasToPaste(canvasFromDom);
            return;
        }
    }

    let elemToSerialize: Element | XMLDocument;
    if (domNode) {
        elemToSerialize = elementToSVG(domNode);
    } else {
        domNode = document.querySelector(`#${resultStyles.chartResultContainer} svg`);
        if (domNode?.outerHTML == null) {
            return;
        }
        elemToSerialize = domNode;
    }

    // Serializing the SVG to solve bug 10964976. We are not sure why the fix works, but the img fires onerror if using the outerHTML for the src.
    const rawSvg = new XMLSerializer().serializeToString(elemToSerialize);
    const width = domNode.clientWidth;
    const height = domNode.clientHeight;

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    const img = document.createElement('img');
    img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(rawSvg))));
    img.onload = function () {
        ctx?.drawImage(img, 0, 0, width, height, 0, 0, width, height);

        tab.setCanvasToPaste(canvas);
    };
}
