/**
 * Helper function that creates a resize observer in the element's own window global
 * @param elementToObserve - Uses the element's window global to create the resize observer
 * @param callback
 * @returns function to cleanup the resize observer
 */ export function observeResize(elementToObserve, callback) {
    var _elementToObserve_ownerDocument_defaultView;
    const GlobalResizeObsever = (_elementToObserve_ownerDocument_defaultView = elementToObserve.ownerDocument.defaultView) === null || _elementToObserve_ownerDocument_defaultView === void 0 ? void 0 : _elementToObserve_ownerDocument_defaultView.ResizeObserver;
    if (!GlobalResizeObsever) {
        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.error('@fluentui/priority-overflow', 'ResizeObserver does not exist on container window');
        }
        return ()=>null;
    }
    let resizeObserver = new GlobalResizeObsever(callback);
    resizeObserver.observe(elementToObserve);
    return ()=>{
        resizeObserver === null || resizeObserver === void 0 ? void 0 : resizeObserver.disconnect();
        resizeObserver = undefined;
    };
}
