import * as React from 'react';
import { Icon } from '@fluentui/react/lib/Icon';

import { KweException } from '../../../utils/errors';
import { RTDDropdownProps } from '../RTDDropdown';
import { RTDDropdownOption } from '../types';

import * as styles from './dropdownIconRenderProps.module.scss';

export interface OptionOptionalData<T> extends RTDDropdownOption {
    data?: T;
    icon?: string | React.ComponentType<{ optionKey: string; data: T }>;
}

interface OptionRequiredData<T> extends RTDDropdownOption {
    data: T;
    icon: string | React.ComponentType<{ optionKey: string; data: T }>;
}

export type DropdownIconOption<T = undefined> = T extends undefined ? OptionOptionalData<T> : OptionRequiredData<T>;

const DefaultIconComponent: React.FC<{ iconName: string }> = ({ iconName }) => (
    <Icon iconName={iconName} aria-hidden="true" title={iconName} />
);

const DropdownItemText: React.FC<{ option: DropdownIconOption }> = ({ option: { data, text, key, icon } }) => {
    let iconNode: undefined | React.ReactNode;

    switch (typeof icon) {
        case 'string':
            iconNode = <DefaultIconComponent iconName={icon} />;
            break;
        case 'undefined':
            break;
        default:
            const DataComponent = icon;
            iconNode = <DataComponent optionKey={key} data={data} />;
            break;
    }

    return (
        <div className={styles.dropdownItem}>
            {iconNode}
            <span className={icon ? styles.itemText : `${styles.itemText} ${styles.noIcon}`}>{text}</span>
        </div>
    );
};

/**
 * Note: no support for multi select dropdowns
 */
const onRenderTitle: RTDDropdownProps['onRenderTitle'] = (selected) => {
    if (selected === undefined || selected.length === 0) {
        throw new KweException('Unable to determine visual selected dropdown selection');
    }
    return <DropdownItemText option={selected[0] as DropdownIconOption} />;
};

const onRenderOption: RTDDropdownProps['onRenderOption'] = (option) => {
    if (option === undefined) {
        throw new KweException();
    }
    return <DropdownItemText option={option as DropdownIconOption} />;
};

/**
 * Uses the data property on an dropdown option to render an icon on it
 *
 * Note: Does not have good support for multi select dropdowns
 * (Only renders the first selection when rendering the title)
 */
export const dropdownIconRenderProps: Pick<RTDDropdownProps, 'onRenderTitle' | 'onRenderOption'> = {
    onRenderOption,
    onRenderTitle,
};
