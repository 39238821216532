import React from 'react';
import fuzzysort from 'fuzzysort';

import { DropdownWithSearchOption } from '../types.ts';

export function useSearchOptions(options: DropdownWithSearchOption[], query: string, ignore?: boolean) {
    return React.useMemo(
        () =>
            !ignore && query
                ? fuzzysort
                      .go(query, options, {
                          key: 'text',
                          threshold: -10000,
                      })
                      .map((i) => i.obj)
                      .filter((i) => i.type !== 'divider')
                : options,
        [options, query, ignore]
    );
}
