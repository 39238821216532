:not(:global(.ag-cell-focus)).expandClose {
    display: none;
}
.expandClose:hover {
    color: red;
}

.expandInGrid :global(.ag-row-group-expanded) :global(.ag-cell-focus) .expandClose {
    display: inline-block;
    position: absolute;
    font-size: 20px;
    top: 0;
    right: 0;
    z-index: 1;

    &:hover {
        color: red;
    }
}

:global(.ag-row-group-expanded) :global(.ag-cell-focus):has(.expandClose) {
    padding-right: 20px;
}

.splitPane {
    & > div[data-type='Resizer'] {
        cursor: auto;
    }
}

.splitPane.resizeEnabled {
    & > div[data-type='Resizer'] {
        cursor: ns-resize;
    }
}
