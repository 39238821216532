import { getQueryParam } from '@kusto/utils';

export interface MarketingCampaign {
    utmCampaign: string | undefined;
    utmSource: string | undefined;
    utmMedium: string | undefined;
}

export function getMarketingInfo(): MarketingCampaign | undefined {
    const url = new URL(window.location.href);
    const utmCampaign = getQueryParam(url, 'utm_campaign');
    const utmSource = getQueryParam(url, 'utm_source');
    const utmMedium = getQueryParam(url, 'utm_medium');

    return utmCampaign || utmSource || utmMedium
        ? {
              utmCampaign,
              utmSource,
              utmMedium,
          }
        : undefined;
}
