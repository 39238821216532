import * as Fwk from '@kusto/visual-fwk';

import { KweRtdVisualContext } from './context';

export function colorRulesSegment(ctx: KweRtdVisualContext, visualType: Fwk.ColorRulesVisualType) {
    const inputStrings = ctx.strings.rtdProvider.visuals.input;
    return Fwk.tileInputSegment<'colorRules' | 'colorStyle' | 'colorRulesDisabled'>(
        inputStrings.segment$colorRulesTitle,
        {
            hideReset: true,
            toggle: {
                optionKey: 'colorRulesDisabled',
                titleText: inputStrings.segment$colorRulesToggleTitleText,
                invert: true,
                labels: {
                    enabled: inputStrings.segment$toggleShowHideLabel$enabled,
                    disabled: inputStrings.segment$toggleShowHideLabel$disabled,
                },
            },
        },
        Fwk.createTileInput.colorRules(ctx.strings, 'colorRules', visualType)
    );
}

export function crossFilterSegmentToggle(
    ctx: KweRtdVisualContext
): Fwk.VisualConfigLayout.SegmentToggle<'crossFilterDisabled'> {
    const inputStrings = ctx.strings.rtdProvider.visuals.input;
    return {
        optionKey: 'crossFilterDisabled',
        titleText: inputStrings.segment$crossFilterToggleTitleText,
        invert: true,
        labels: {
            disabled: inputStrings.segment$toggleOnOffLabels$off,
            enabled: inputStrings.segment$toggleOnOffLabels$on,
        },
    };
}

export function drillthroughSegmentToggle(
    ctx: KweRtdVisualContext
): Fwk.VisualConfigLayout.SegmentToggle<'drillthroughDisabled'> {
    const inputStrings = ctx.strings.rtdProvider.visuals.input;
    return {
        optionKey: 'drillthroughDisabled',
        titleText: inputStrings.segment$drillthroughToggleTitleText,
        invert: true,
        labels: {
            disabled: inputStrings.segment$toggleOnOffLabels$off,
            enabled: inputStrings.segment$toggleOnOffLabels$on,
        },
    };
}
