import React from 'react';
import { Body1, Subtitle1 } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';

import { useStrings } from '../../DataExplorationModal/hooks/useStrings';

import styles from './SchemaColumnInfo.module.scss';

interface HighCardinalityInfoProps {
    title: React.ReactNode;
}

export const HighCardinalityInfo: React.FC<HighCardinalityInfoProps> = observer(function HighCardinalityInfo({
    title,
}) {
    const { strings } = useStrings();

    return (
        <>
            <div className={styles.columnInfoHeader}>
                {title}
                <Subtitle1>{strings.schemaInsights.HighCardinalityTitle}</Subtitle1>
            </div>
            <div className={styles.simpleText}>
                <Body1>{strings.schemaInsights.HighCardinalityText}</Body1>
            </div>
        </>
    );
});
