import * as React from 'react';
/**
 * React hook to merge multiple React refs (either MutableRefObjects or ref callbacks) into a single ref callback that
 * updates all provided refs
 * @param refs - Refs to collectively update with one ref value.
 * @returns A function with an attached "current" prop, so that it can be treated like a RefObject.
 */ export function useMergedRefs(...refs) {
    'use no memo';
    const mergedCallback = React.useCallback((value)=>{
        // Update the "current" prop hanging on the function.
        mergedCallback.current = value;
        for (const ref of refs){
            if (typeof ref === 'function') {
                ref(value);
            } else if (ref) {
                // work around the immutability of the React.Ref type
                ref.current = value;
            }
        }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps -- already exhaustive
    [
        ...refs
    ]);
    return mergedCallback;
}
