import type { Locale as DateFnsStrings } from 'date-fns';
import * as mobx from 'mobx';

import type { KweAgGridStrings } from '@kusto/ag-grid';
import { ChartingLocale, ChartingStrings } from '@kusto/charting';
import type { KustoClientStrings } from '@kusto/client';
import type { RtdI18n, RtdStrings } from '@kusto/dashboard';
import type { DashboardsKustoStrings } from '@kusto/dashboards-kusto';
import type { DashboardsKweLocale, DashboardsKweStrings } from '@kusto/dashboards-kwe';
import type { DataExplorationStrings } from '@kusto/data-exploration';
import type { DataManagementStrings } from '@kusto/data-management';
import type { IngestHubStrings } from '@kusto/ingest-hub';
import type { QueryLocale, QueryStrings } from '@kusto/query';
import type { RtdProviderLocale, RtdProviderStrings } from '@kusto/rtd-provider';
import type { UiComponentsLocale, UiComponentsStrings } from '@kusto/ui-components';
import { KweException, KweUtilsLocale, KweUtilsStrings, Locale } from '@kusto/utils';
import type { KweVisualFwkStrings } from '@kusto/visual-fwk';
import type { VisualizationsStrings } from '@kusto/visualizations';

import type { FeatureFlagMap } from '../core/FeatureFlags';
import { dependencies } from '../dependencies';
import type { IRootStore } from '../stores/rootStore';
import { fetchStrings } from './fetchStrings';
import localEnglishStrings from './strings.json';

/**
 * Maps our normalized locale codes to date-fns locale codes when they don't match
 */
export const dateFnsLocales: Partial<Record<string, string>> = {
    en: 'en-US',
    'zh-hans': 'zh-HK',
    'zh-hant': 'zh-TW',
    'pt-pt': 'pt',
    'pt-br': 'pt-BR',
};

export function resolveDateFnsLocale(lang: string) {
    return dateFnsLocales[lang] ?? lang;
}

export type KweStrings = typeof import('./strings.json');

export function duckify<T>(copy: T): T {
    return Object.fromEntries(
        Object.entries(copy as Record<string, unknown>).map(([key, value]) => {
            if (typeof value === 'string') {
                if (key !== 'pageLang') {
                    value = '🐥'.repeat(value.length);
                }
            } else {
                value = duckify(value);
            }

            return [key, value];
        })
    ) as T;
}

export interface StringsBundle {
    readonly kustoweb: KweStrings;
    readonly rtdProvider: RtdProviderStrings;
    readonly visualizations: VisualizationsStrings;
    readonly dashboards: RtdStrings;
    readonly dashboardsKusto: DashboardsKustoStrings;
    readonly dashboardsKwe: DashboardsKweStrings;
    readonly query: QueryStrings;
    readonly utils: KweUtilsStrings;
    readonly client: KustoClientStrings;
    readonly dataExploration: DataExplorationStrings;
    readonly dataManagement: DataManagementStrings;
    readonly ingestHub: IngestHubStrings;
    readonly agGrid: KweAgGridStrings;
    readonly visualFwk: KweVisualFwkStrings;
    readonly uiComponents: UiComponentsStrings;
    readonly charting: ChartingStrings;
}

export interface AppStrings
    extends Omit<StringsBundle, 'kustoweb'>,
        // Extending these even though it should do nothing to ensure interface compatibility
        RtdProviderLocale,
        DashboardsKweLocale,
        RtdI18n,
        UiComponentsLocale,
        QueryLocale,
        KweUtilsLocale,
        ChartingLocale {
    readonly kwe: KweStrings;
    readonly dateFns: undefined | DateFnsStrings;
    readonly locale: undefined | Locale;
}

/**
 * @deprecated: Use core.strings instead. Using this forces us to bundle english copy with our app unnecessarily
 */
export const englishStrings = localEnglishStrings;

export interface LocalizationLanguage {
    readonly code: string;
    readonly localizedName: string;
    readonly alias: readonly string[];
}

// key: supported culture by Microsoft's localization team. must be lowercase
// value: array of cultures to be mapped to Microsoft's standard (like cultures from the browser)
// Values are copied from https://microsoft.sharepoint.com/:l:/r/sites/globalreadiness/Lists/Language?e=EE1gKc
// (same link with filter, might not work https://microsoft.sharepoint.com/sites/globalreadiness/Lists/Language/OfficialLanguageNames.aspx?viewid=5c7e77f2%2D71ff%2D4aed%2Db617%2Da77e20fcea2b&useFiltersInViewXml=1&FilterFields1=bcp47code&FilterValues1=hu%3B%23cs%3B%23de%3B%23es%3B%23fr%3B%23id%3B%23it%3B%23ja%3B%23ko%3B%23nl%3B%23pl%3B%23pt%2DBR%3B%23pt%2DPT%3B%23ru%3B%23sv%3B%23tr%3B%23zh%2DHans%3B%23zh%2DHant&FilterTypes1=Text&FilterOp1=In)
export const supportedCultures: LocalizationLanguage[] = [
    { code: 'cs', localizedName: 'Čeština', alias: [] }, // Czech
    { code: 'de', localizedName: 'Deutsch', alias: [] }, // German
    { code: 'en', localizedName: 'English', alias: [] }, // English
    { code: 'es', localizedName: 'Español', alias: [] }, // Spanish
    { code: 'fr', localizedName: 'Français', alias: [] }, // French
    { code: 'hu', localizedName: 'Magyar', alias: [] }, // Hungarian
    { code: 'it', localizedName: 'Italiano', alias: [] }, // Italian
    { code: 'nl', localizedName: 'Nederlands', alias: [] }, // Dutch
    { code: 'pl', localizedName: 'Polski', alias: [] }, // Polish
    { code: 'ru', localizedName: 'Pусский', alias: [] }, // Russian
    { code: 'sv', localizedName: 'Svenska', alias: [] }, // Swedish
    { code: 'tr', localizedName: 'Türkçe', alias: [] }, // Turkish
    { code: 'ja', localizedName: '日本語', alias: [] }, // Japanese
    { code: 'ko', localizedName: '한국어', alias: [] }, // Korean
    {
        code: 'pt-br',
        localizedName: 'Português (Brasil)',
        alias: [],
    }, // name: 'Portuguese (Brazil)'
    {
        code: 'pt-pt',
        localizedName: 'Português (Portugal)',
        alias: ['pt'],
    }, // 'Portuguese (Portugal)'
    {
        code: 'zh-hans',
        localizedName: '中文(简体)',
        alias: ['zh-cn'],
    }, // 'Chinese (Simplified)'
    {
        code: 'zh-hant',
        localizedName: '中文(繁體)',
        alias: ['zh-tw'],
    }, // 'Chinese (Traditional)'
    { code: 'id', localizedName: 'Indonesia', alias: [] }, // Indonesian
];

export function normalizeLanguage(requestedLang: undefined | string): string {
    if (requestedLang === undefined || requestedLang === '') {
        requestedLang = navigator.language;
    }
    requestedLang = requestedLang.toLowerCase();
    let appLang = '';
    const lang = requestedLang.split('-'); // requestedLang=en-us etc
    supportedCultures.forEach((supportedCulture) => {
        // checking to see if the entire culture is supported (example: pt-pt)
        if (requestedLang === supportedCulture.code) {
            appLang = requestedLang;
            return;
        }
        // if the culture is in alias, return the key (example: zh-cn -> return zh-hans)

        if (supportedCulture.alias.includes(requestedLang!)) {
            appLang = supportedCulture.code;
            return;
        }
        // only language is supported (en-US -> only [en] is supported)
        if (lang.length > 0 && supportedCulture.code === lang[0]) {
            appLang = lang[0];
            return;
        }
    });
    return appLang === '' ? 'en' : appLang; // default culture
}

function initPageLocale(strings: KweStrings) {
    const html: HTMLElement = document.getElementsByTagName('html')[0];
    if (html.lang !== strings.pageLang) {
        html.setAttribute('lang', strings.pageLang);
    }
    if (document.title !== strings.pageTitle) {
        document.title = strings.pageTitle;
    }
}

/**
 * Can't do a glob import (`import('date-fns/locale/* /index.js')`) because Parcel doesn't have a way to omit unwanted files like webpack does with "webpackInclude" magic string
 */
function importDateFnsLocale(lang: string) {
    lang = resolveDateFnsLocale(lang);

    switch (lang) {
        case 'en-US':
            return import('date-fns/locale/en-US');
        case 'zh-HK':
            return import('date-fns/locale/zh-HK');
        case 'zh-TW':
            return import('date-fns/locale/zh-TW');
        case 'pt':
            return import('date-fns/locale/pt');
        case 'cs':
            return import('date-fns/locale/cs');
        case 'de':
            return import('date-fns/locale/de');
        case 'es':
            return import('date-fns/locale/es');
        case 'fr':
            return import('date-fns/locale/fr');
        case 'hu':
            return import('date-fns/locale/hu');
        case 'it':
            return import('date-fns/locale/it');
        case 'nl':
            return import('date-fns/locale/nl');
        case 'pl':
            return import('date-fns/locale/pl');
        case 'ru':
            return import('date-fns/locale/ru');
        case 'sv':
            return import('date-fns/locale/sv');
        case 'tr':
            return import('date-fns/locale/tr');
        case 'ja':
            return import('date-fns/locale/ja');
        case 'ko':
            return import('date-fns/locale/ko');
        case 'pt-BR':
            return import('date-fns/locale/pt-BR');
        case 'id':
            return import('date-fns/locale/id');
        default:
            throw new KweException(`Unexpected lang: ${lang}`);
    }
}

async function localFetchStrings(langFromSettings: undefined | string, ducks: boolean): Promise<AppStrings> {
    const lang = normalizeLanguage(langFromSettings);

    const dateFnsStrings = importDateFnsLocale(lang);

    let bundleStrings = await fetchStrings(lang);

    if (ducks) {
        bundleStrings = duckify(bundleStrings);
    }

    const { kustoweb, dashboards, ...shared } = bundleStrings;
    const dateFns = (await dateFnsStrings)?.default;
    const locale = langFromSettings === undefined ? undefined : kustoweb.pageLang;

    const strings: AppStrings = {
        kwe: kustoweb,
        dateFns,
        dashboards,
        locale,
        ...shared,
    };

    initPageLocale(strings.kwe);

    return strings;
}

export async function initStrings(store: IRootStore, featureFlags: FeatureFlagMap): Promise<{ get(): AppStrings }> {
    const initialStrings = await await localFetchStrings(
        mobx.runInAction(() => store.settings.language),
        mobx.runInAction(() => featureFlags.ducks ?? false)
    );
    const boxStrings = mobx.observable.box(initialStrings, {
        deep: false,
    });

    // TODO: Once copy has been removed from "dependencies", comment this out, and uncomment the code below it this to
    // enable language hot-reloading
    dependencies.strings = initialStrings.kwe;

    let cancelFetch: undefined | (() => void);
    mobx.reaction(
        () => ({
            lang: store.settings.language,
            ducks: featureFlags.ducks ?? false,
            enabled: featureFlags.enableLanguageHotReloading,
        }),
        async ({ lang, ducks, enabled }) => {
            if (!enabled) {
                return;
            }
            let canceled = false;
            cancelFetch?.();
            cancelFetch = () => {
                canceled = true;
            };
            const strings = await localFetchStrings(lang, ducks);

            if (canceled) {
                return;
            }
            mobx.runInAction(() => {
                boxStrings.set(strings);
            });
        }
    );

    return boxStrings;
}
