// Suppress error related to importing outside of root dir.
// https://stackoverflow.com/questions/55753163/package-json-is-not-under-rootdir
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

export type UiComponentsStrings = typeof import('../strings.json');

export interface UiComponentsLocale {
    readonly uiComponents: UiComponentsStrings;
    readonly locale: undefined | string;
}
