import React from 'react';

import { type DataTableColumn } from '@kusto/client';

import { DataProfileTimeRange } from '../DataExplorationApi';
import { SchemaInsights } from '../SchemaInsights/SchemaInsights';
import { QueryRunnerV2 } from '../types';
import { KqlExamples } from './KqlExamples/KqlExamples';

export type DataProfileTabType = 'Schema' | 'DataGrid' | 'KqlExamples';

interface DataProfileTabProps {
    viewMode: 'narrow' | 'wide';
    isDarkTheme: boolean;
    clusterUrl: string;
    databaseName: string;
    dbCacheToken: string;
    queryText: string;
    columns: DataTableColumn[];
    timeRange: DataProfileTimeRange;
    activeTab: DataProfileTabType;
    timezone: string;
    executeV2Query: QueryRunnerV2;
}

export const DataProfileTab: React.FC<DataProfileTabProps> = ({
    viewMode,
    isDarkTheme,
    clusterUrl,
    databaseName,
    columns,
    queryText,
    timeRange,
    activeTab,
    timezone,
}) => {
    switch (activeTab) {
        case 'DataGrid':
            return <div>Data Grid placeholder</div>;
        case 'KqlExamples':
            return (
                <KqlExamples
                    isDarkTheme={isDarkTheme}
                    clusterUrl={clusterUrl}
                    databaseName={databaseName}
                    tableName={queryText}
                    columns={columns}
                />
            );
        case 'Schema':
            return (
                <SchemaInsights
                    viewMode={viewMode}
                    queryText={queryText}
                    columns={columns}
                    timeRange={timeRange}
                    timezone={timezone}
                />
            );
    }
};
