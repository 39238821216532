import React from 'react';
import {
    Body1Strong,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components';
import { CalendarClock20Regular, ChevronDown12Regular } from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';

import { formatLiterals } from '@kusto/utils';

import { DateTimeColumn, HistogramYColumn } from '../../DataExplorationApi';
import { useStrings } from '../../DataExplorationModal/hooks/useStrings';

interface DateTimeColumnPickerProps {
    dateTimeColumns: DateTimeColumn[];
    isIngestionTimePolicyEnabled?: boolean;
    selectedColumn: HistogramYColumn;
    onSelect: (option: HistogramYColumn) => void;
}

export const DateTimeColumnPicker: React.FC<DateTimeColumnPickerProps> = observer(function DateTimeColumnPicker({
    dateTimeColumns,
    isIngestionTimePolicyEnabled,
    selectedColumn,
    onSelect,
}) {
    const { strings } = useStrings();
    const selectedColumnName =
        selectedColumn.ColumnType === 'ingestionTime' ? strings.histogram.ingestionTime : selectedColumn.ColumnName;

    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <MenuButton
                    icon={<CalendarClock20Regular />}
                    appearance="transparent"
                    size="medium"
                    menuIcon={<ChevronDown12Regular />}
                >
                    <Body1Strong>
                        {formatLiterals(strings.histogram.dependantVariableDropdown, {
                            columnName: selectedColumnName,
                        })}
                    </Body1Strong>
                </MenuButton>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {isIngestionTimePolicyEnabled ? (
                        <MenuItem onClick={() => onSelect({ ColumnType: 'ingestionTime' })}>
                            {strings.histogram.ingestionTime}
                        </MenuItem>
                    ) : null}
                    {dateTimeColumns.map((col) => (
                        <MenuItem key={col.ColumnName} onClick={() => onSelect(col)}>
                            {formatLiterals(strings.histogram.dateTimeColumn, {
                                columnName: col.ColumnName,
                            })}
                        </MenuItem>
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
});
