import type { ColorResolutionSet, ThemeColorResolution } from '../../visualConfig';
import type { ColorRule } from '../../visualOptions';

/**
 * Colors are from:
 * https://www.figma.com/file/oqmKKJVwc4rZgivntf568F/ADX-Dashboards-BUILD-Handoff?node-id=2350%3A32748
 */
const bothLightAndBold = {
    light: {
        green: { color: '#107C10', invertText: true },
        blue: { color: '#005A9E', invertText: true },
        red: { color: '#A80000', invertText: true },
    },

    dark: {
        green: { color: '#92C353', invertText: true },
        blue: { color: '#6CB8F6', invertText: true },
        red: { color: '#FF8282', invertText: true },
        yellow: { color: '#FFC83E', invertText: true },
    },
};

export const conditionalFormattingColors: Record<'light' | 'dark', Record<ColorRule.ColorStyle, ColorResolutionSet>> = {
    light: {
        light: { ...bothLightAndBold.light, yellow: { color: '#EC952E' } },
        bold: { ...bothLightAndBold.light, yellow: { color: '#FFB900' } },
    },
    dark: { light: bothLightAndBold.dark, bold: bothLightAndBold.dark },
};

const baseThemeValues: Record<ColorRule.Theme, ThemeColorResolution> = {
    'traffic-lights': { colors: ['#9A1E13', '#E09846', '#387926'] },
    cold: { colors: ['#225B99', '#1F9A85', '#00DBBC'] },
    warm: { colors: ['#9A1E13', '#DE6C00', '#E09846'] },
    blue: { colors: ['#225B99', '#0078D4', '#4092FF'] },
    red: { colors: ['#9A1E13', '#C50F1F', '#E87979'] },
    yellow: { colors: ['#DE6C00', '#E09846', '#FDE300'] },
};

export const themeValues: Record<'light' | 'dark', Record<ColorRule.Theme, ThemeColorResolution>> = {
    light: baseThemeValues,
    dark: baseThemeValues,
};
