export interface Profile {
    aud?: string;
    iss?: string;
    name?: string;
    upn?: string;
    oid?: string;
    tid?: string;
    exp?: number;
    given_name?: string;
    family_name?: string;
    idp?: string;
    username?: string;
    email?: string;
}

export interface User {
    userName: string;
    profile?: Profile;
}

export function decodeJwtToken(token: string): Profile | undefined {
    try {
        const payload = token.split('.')[1];

        // Convert base 64 url to base 64 (regex needed because regular replace only replaces 1st occurrence).
        const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
        // atob() is a built in JS function that decodes a base-64 encoded string
        const utf8 = atob(base64);
        // Then parse that into JSON
        const object = JSON.parse(utf8);
        return object;
    } catch (err) {
        return undefined;
    }
}
