import * as React from 'react';
import { useMenuListContext_unstable } from '../../contexts/menuListContext';
export const useCharacterSearch = (state, ref)=>{
    const setFocusByFirstCharacter = useMenuListContext_unstable((context)=>context.setFocusByFirstCharacter);
    const { onKeyDown: originalOnKeyDown } = state.root;
    state.root.onKeyDown = (e)=>{
        var _e_key;
        originalOnKeyDown === null || originalOnKeyDown === void 0 ? void 0 : originalOnKeyDown(e);
        if (((_e_key = e.key) === null || _e_key === void 0 ? void 0 : _e_key.length) > 1) {
            return;
        }
        if (ref.current) {
            setFocusByFirstCharacter === null || setFocusByFirstCharacter === void 0 ? void 0 : setFocusByFirstCharacter(e, ref.current);
        }
    };
    return state;
};
