import { DataFrame, DataFrameTypes, KustoDataType, KweException, UField, UnknownScalarValue } from '@kusto/utils';

import type { BasicParamTypeTag } from '../target';
import type { Duration, TaggedValue } from './types';

export const rtdNullValue: TaggedValue.Null = { kind: 'null' };

export function tagArray<T = UnknownScalarValue, K = KustoDataType>(
    tag: K,
    values: readonly T[]
): TaggedValue.TArray<T, K> {
    return { kind: 'array', tag, values };
}

export function tagScalar<T = UnknownScalarValue, K = KustoDataType>(tag: K, value: T): TaggedValue.Scalar<T, K> {
    return { kind: 'scalar', tag, value };
}

export function fixedDuration(start: Date, end: Date): Duration.Fixed {
    return { kind: 'fixed', start, end };
}

export function tagFixedDuration(start: Date, end: Date): TaggedValue.Scalar<Duration.Fixed, 'duration'> {
    return tagScalar('duration', fixedDuration(start, end));
}

export function dynamicDuration(count: number, unit: Duration.TimeUnit): Duration.Dynamic {
    return { kind: 'dynamic', count, unit };
}

export function tagDynamicDuration(
    count: number,
    unit: Duration.TimeUnit
): TaggedValue.Scalar<Duration.Dynamic, 'duration'> {
    return tagScalar('duration', dynamicDuration(count, unit));
}

/**
 * All column types can be represented as basic param types as of writing
 */
export function basicParamTypeFromColumnType(type: KustoDataType): BasicParamTypeTag {
    switch (type) {
        case 'timespan':
        case 'dynamic':
        case 'guid':
            return 'string';
        default:
            return type;
    }
}

export function basicParamValueFromColumnValue(
    value: DataFrameTypes[KustoDataType],
    type: KustoDataType
): string | number | boolean {
    if (type === 'dynamic' && !(typeof value === 'string')) {
        return JSON.stringify(value);
    }
    return value as string | number | boolean;
}

export function taggedScalarValueFromTable(
    dataFrame: DataFrame,
    column: string | number,
    rowIndex: number
): TaggedValue.Null | TaggedValue.UBasicScalar {
    let field: undefined | UField;

    if (typeof column === 'number') {
        field = dataFrame.fields[column];
    } else {
        field = dataFrame.fields.find((c) => c.name === column);
    }

    if (process.env.NODE_ENV !== 'production' && !field) {
        throw new KweException('Field not found in data frame');
    }

    const columnType = field!.type;
    const tableValue = field!.values[rowIndex];

    if (tableValue === null) {
        return rtdNullValue;
    }

    return tagScalar(
        basicParamTypeFromColumnType(columnType),
        basicParamValueFromColumnValue(tableValue, columnType)
    ) as TaggedValue.UBasicScalar;
}
