import React, { useCallback, useState } from 'react';
import { Divider } from '@fluentui/react-components';
import { AddFilled, AddRegular, bundleIcon } from '@fluentui/react-icons';

import { DragState, TabsBarProps } from '../Tabs.interfaces';
import { TabsBarContext } from '../TabsContext/TabsBar.context';
import { TabsList } from '../TabsList/TabsList';
import { TabsMenu } from '../TabsMenu/TabsMenu';
import { ButtonTooltip } from '../TabsTooltip/TabsTooltip';

import styles from './TabsBar.module.scss';

const Add = bundleIcon(AddFilled, AddRegular);

/**
 * Components that render a command bar with list of tabs. Where each has label and icon (optional).
 * The tabs-bar also add controls like add, rename, close and reorder tab. Also a menu will be rendered with additional actions.
 */
export const TabsBar: React.FunctionComponent<TabsBarProps> = (props) => {
    // Tab in edit mode, keep on top level - only one tab can be edited at a time
    const [editingTab, setEditingTab] = useState<string | undefined>();
    const [dragging, setDragging] = useState<DragState | undefined>();

    const context = {
        ...props,
        editingTab: {
            tabId: editingTab,
            setTabId: setEditingTab,
        },
        dragging: {
            state: dragging,
            setState: setDragging,
        },
    };

    const onClickAddTab = useCallback(() => {
        setEditingTab(undefined);
        props.events.onAddTab();
    }, [props]);

    return (
        <TabsBarContext.Provider value={context}>
            <div className={styles.tabsBarContainer} data-testid={props.testId}>
                <div className={styles.tabsListContainer}>
                    <TabsList />
                    <div className={styles.addButton}>
                        <Divider vertical />
                        <ButtonTooltip
                            tooltip={props.strings.addTab}
                            icon={<Add />}
                            appearance="transparent"
                            onClick={onClickAddTab}
                        />
                    </div>
                </div>
                <TabsMenu />
            </div>
        </TabsBarContext.Provider>
    );
};
