import React from 'react';
import {
    Column as AgGridColumn,
    CellClassParams,
    CellContextMenuEvent,
    CellFocusedEvent,
    CellPosition,
    ColDef,
    ColumnApi,
    ColumnMovedEvent,
    ColumnPinnedEvent,
    ColumnRowGroupChangedEvent,
    ColumnVisibleEvent,
    Events,
    FilterChangedEvent,
    FirstDataRenderedEvent,
    GetMainMenuItems,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IFilterOptionDef,
    IRowNode,
    RowClassParams,
    RowPinnedType,
    SortChangedEvent,
    ValueFormatterParams,
} from '@ag-grid-community/core';
import { AgGridReactProps } from '@ag-grid-community/react';
import { ContextualMenu, IContextualMenuItem, ISearchBox, Point } from '@fluentui/react';
import { mergeClasses } from '@griffel/core';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import memoizeOne from 'memoize-one';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';

import type { GridWithSearchProps } from '@kusto/ag-grid';
import type * as kusto from '@kusto/client';
import { Locale, Theme } from '@kusto/utils';

import type { Rows, VisualizationsLocale } from '../types';
import {
    generateCFClassName,
    getConditionalFormattingOptions,
    wrapCellWithConditionalFormatting,
} from '../utils/conditionalFormatting/conditionalFormatting';
import type { VColorRuleByValue } from '../utils/conditionalFormatting/types';
import type { ColumnFormatting, ExtendedVisualizationOptions, VisualizationOptions } from '../utils/visualization';
import { AccessibleGrid } from './AccessibleGrid/AccessibleGrid';
import { getGridState, GridState, restoreGridState } from './AgGridState';
import { QueryResultsSearchPortal } from './QueryResultsSearchPortal';
import { Search, SearchProps } from './Search';

import './agGrid.scss';
import './agGridDark.scss';
import './agGridExpandView.scss';

import { useUncontrolledFocus } from '@fluentui/react-components';

import { sortRowsByDate } from '../utils/sortGridResults';
import { CellText } from './GridWithExpand';

import * as styles from './agGrid.module.scss';

/**
 * Filter for columns of type agTextColumnFilter with an additional custom "Not Empty" filter.
 * @see https://www.ag-grid.com/javascript-grid-filter-provided-simple/#default-filter-options
 */
const textFilters: Array<string | IFilterOptionDef> = [
    'contains',
    'notContains',
    'equals',
    'notEqual',
    'startsWith',
    'endsWith',
    {
        displayKey: 'notEmpty',
        displayName: 'Not empty', // will be localized in getLocaleText
        predicate: (_filterValues?: string[], cellValue?: string) => !!cellValue,
        numberOfInputs: 0,
    },
    {
        displayKey: 'empty',
        displayName: 'Empty only', // will be localized in getLocaleText
        predicate: (_filterValues?: string[], cellValue?: string) => !cellValue,
        numberOfInputs: 0,
    },
];

export interface TableResult {
    readonly columns: readonly kusto.KustoColumn[] | null;
    readonly rows: Rows | null;
    readonly visualizationOptions: VisualizationOptions | null;
    readonly tableName: string | null;
}

export interface KustoDataProps {
    theme: Theme;
    locale: VisualizationsLocale;
    formatResultData?: boolean;
    // "Kwe" suffix so it doesn't collide with the agGrid prop "getContextMenuItems"
    getContextMenuItemsKwe?: (
        defaultMenu: IContextualMenuItem[],
        event?: CellContextMenuEvent
    ) => IContextualMenuItem[];
    // items added at the end of the column menu
    getAdditionalMenuItems?: GetMainMenuItems;
    getColumnDef?: (base: ColDef, index: number) => ColDef;
    resultToDisplay?: TableResult;
    numbersAlignRight?: boolean;
    initialGridState?: () => undefined | GridState;
    onStoreGridState?: (state: GridState) => void;
    //Search box configuration
    searchEnabled?: boolean;
    searchPlaceholderRef?: React.RefObject<HTMLDivElement>;
    onSearchClear?: () => void;
    searchBoxRef?: React.RefObject<ISearchBox>;
    // Todo: move all search props to search options
    searchOptions?: Pick<SearchProps, 'styles' | 'hideCloseButton'>;
    /**
     * Wether to auto size all the columns or slice first several rows
     */
    autoSizeAllData?: boolean;
    timezone: string;
    hideEmptyColumns?: boolean;
}

export interface State {
    menu?: {
        target: Point;
        items: IContextualMenuItem[];
    };
    searchHitsCount: number;
    columnDefs: ColDef[];
}

class AgCell implements CellPosition {
    row: IRowNode;
    column: AgGridColumn;
    displayableColumnIndex: number;

    get rowIndex(): number {
        // Added while enabling lints

        return this.row.rowIndex!;
    }
    get rowPinned(): RowPinnedType {
        return this.row.rowPinned;
    }
    get colKey(): string {
        return this.column.getColId();
    }

    constructor(row: IRowNode, column: AgGridColumn, displayableColumnIndex: number) {
        this.row = row;
        this.column = column;
        this.displayableColumnIndex = displayableColumnIndex;
    }
}

class SearchHits {
    /** hold all the search hits in a sorted array. */
    all: AgCell[] = [];
    /**
     * hold all the search hits in a set. The string generic type represents a unique identifier for a cell.
     * The css class `ag-cell-search-hit` will be added to cells in this set.
     * */
    set: Set<string> = new Set();
    current?: AgCell = undefined;
    searchTerm?: string = undefined;
    position?: number = undefined;
    /** Mark the current search hit cell as focused. This will cause the same blue border as AgGrid's focused cell UX.
     * Q: Why not calling AgGrid.setFocus when search's position change?
     * A: When changing a search hit position, if gridApi.setFocus would have been called, it would move the focus from
     * the search box to AgGrid causing the shortcuts Enter and Shift+Enter to stop working, so instead it is marked
     * as focused and the marked-as-focused cell is passed to the WrappedComponent so it could show additional
     * information about it.
     */
    markAsFocused = false;

    addToSet(rowId?: string, colId?: string): void {
        this.set.add(`${rowId}__${colId}`);
    }

    hasInSet(rowId?: string, colId?: string): boolean {
        return this.set.has(`${rowId}__${colId}`);
    }
}

function getDisplayName(WrappedComponent: Partial<Record<'displayName' | 'name', string>>) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const autoSizeColumns = (columnApi: ColumnApi, visibleColumns: number) => {
    // Limit the number of column that will be handled
    const displayedColumns = columnApi.getAllDisplayedColumns() ?? [];
    const columnsToResize = displayedColumns.slice(0, visibleColumns);
    if (columnsToResize.length > 0) {
        // Ag Grid doesn't auto size column that aren't displayed
        columnApi.autoSizeColumns(columnsToResize);
    }
    return columnsToResize;
};

export const calcDefaultColWidth = (gridRef: React.RefObject<HTMLDivElement>, columnApi: ColumnApi) => {
    const maxColumnToCalc = 20;
    const defaultMaxSize = 350;
    const fitOneScreenMinSize = 130;
    const marginalFit = 2;
    if (!columnApi) {
        return [];
    }

    const columnsToFix = autoSizeColumns(columnApi, maxColumnToCalc);

    if (gridRef.current) {
        const containerViews = gridRef.current.getElementsByClassName('ag-body-viewport');
        const container = containerViews.item(0) as HTMLElement;
        if (container) {
            // find the sum of minimum required size and sum of overflow
            // eslint-disable-next-line prefer-const
            let [minSize, actualSize] = columnsToFix.reduce(
                ([min, over], col) => {
                    const actual = col.getActualWidth();
                    const colNeedSize = actual - fitOneScreenMinSize;
                    return actual > fitOneScreenMinSize
                        ? [min + fitOneScreenMinSize, over + colNeedSize]
                        : [min + actual, over];
                },
                [0, 0]
            );
            // Can we squeeze all column to single page
            let availableSize = container.offsetWidth - minSize - 20;
            if (availableSize > 0) {
                const columnsState = columnApi.getColumnState();
                // Filter the columns that need more space
                // sort from smaller with to wider
                // Allocate the relative available space
                //      * in case we need just a little bit more and it available allow it
                //
                // * future:
                //       improvements use logarithmic base allocation
                //       improve more than 1 screen width layout
                //
                columnsState
                    .filter((col) => col.width && col.width > fitOneScreenMinSize)
                    // Added while enabling lints

                    .sort((colA, colB) => colA.width! - colB.width!)
                    .forEach((col) => {
                        // Added while enabling lints

                        const colWidth = col.width!;
                        // fair split of extra space
                        let takenFromAvailable = Math.ceil(
                            (availableSize / actualSize) * (colWidth - fitOneScreenMinSize)
                        );
                        let newWidth = takenFromAvailable + fitOneScreenMinSize;

                        if (newWidth > colWidth) {
                            newWidth = colWidth;
                        } else {
                            // Skip resize if only minimal additional space needed
                            // and space is available
                            const marginalWidth = newWidth * marginalFit;
                            if (marginalWidth > colWidth && marginalWidth - fitOneScreenMinSize < availableSize) {
                                newWidth = colWidth;
                            }
                        }
                        takenFromAvailable = newWidth - fitOneScreenMinSize;

                        // Added while enabling lints

                        actualSize -= col.width! - fitOneScreenMinSize;
                        col.width = newWidth;
                        availableSize -= takenFromAvailable;
                    });
                return columnsState;
            }
        }
    }
    const colWidthUpdate = columnApi.getColumnState()?.map((col, index) => {
        if (index < maxColumnToCalc && col.width && col.width > defaultMaxSize) {
            col.width = defaultMaxSize;
        }
        return col;
    });
    return colWidthUpdate;
};

let latestEmptyColumns: string[] | undefined = undefined;

const ConditionalFormattingCellRenderer = wrapCellWithConditionalFormatting(CellText);

const UncontrolledFocusWrapper: React.FC<{
    className: string;
}> = ({ className, children }) => {
    const uncontrolledFocusAttributes = useUncontrolledFocus();
    return (
        <div className={className} {...uncontrolledFocusAttributes} data-is-focusable="true">
            {children}
        </div>
    );
};

/**
 * This is a react HOC (higher order component) that maintains:
 *    * Handling of kusto query results (column definition from resultSet)
 *    * support Accessability
 *    * Search
 *    * formatting (see xxxFormatter methods)
 *
 * It can wrap a simple AgGrid or GridWithExpand.
 *
 */
export function withAgGridKustoData<P extends GridWithSearchProps>(
    WrappedComponent: React.ComponentType<P>,
    gridWithExpandRewriteEnabled = false
): React.ComponentType<P & KustoDataProps> {
    return class BaseGrid extends React.Component<P & KustoDataProps, State> {
        static displayName = `WithHidableLines(${getDisplayName(WrappedComponent)})`;
        accessibleGrid?: AccessibleGrid;
        columnApi?: ColumnApi;
        gridApi?: GridApi;
        searchHits = new SearchHits();
        // Maps a column to it's type. Used for conditional formatting
        columnTypeByName: Record<string, kusto.ColumnType> = {};
        // Maps a column to it's minimum and maximum value. Used for conditional formatting
        edgeValuesByColumn: Record<string, { min: string; max: string }> = {};
        // Maps unique values in each column to an index number (used for coloring each row by value for conditional formatting)
        columnUniqueValues: Record<string, Record<string, number>> = {};

        accessibleGridOptions!: GridOptions;

        gridRef = React.createRef<HTMLDivElement>();
        locale: Locale = 'en';
        numberFormatter = (locale: Locale) =>
            Intl.NumberFormat(locale, {
                maximumFractionDigits: 20,
                minimumFractionDigits: 1, // Force single digit for decimal formatter
            });

        readonly gridOptions: GridOptions = {
            onGridReady: (e) => {
                this.onGridReady(e);
            },
            onCellFocused: (e) => {
                this.onCellFocused(e);
            },
            // We need to support fields that may contain dots
            // plus we don't need the deep reference features since our
            // column name isn't a json - it's a string.
            suppressFieldDotNotation: true,
            suppressColumnMoveAnimation: true,
            suppressContextMenu: true,
            suppressLoadingOverlay: true,
            enableRangeSelection: true,
            autoSizePadding: 5,
            defaultColDef: {
                enableRowGroup: true,
                enablePivot: true,
                enableValue: true,
                sortable: true,
                resizable: true,
                filter: true,
                cellDataType: false, // prevent AG grid built-in cell renderers implicitly being used (checkbox in particular)
                cellRenderer: gridWithExpandRewriteEnabled ? ConditionalFormattingCellRenderer : undefined,
            },
        };

        constructor(props: AgGridReactProps & P & KustoDataProps) {
            super(props);
            this.state = { menu: undefined, searchHitsCount: 0, columnDefs: [] };

            this.onFilterChanged = this.onFilterChanged.bind(this);
            this.onColumnMoved = this.onColumnMoved.bind(this);
            this.onColumnPinned = this.onColumnPinned.bind(this);
            this.onColumnRowGroupChanged = this.onColumnRowGroupChanged.bind(this);
            this.onColumnVisible = this.onColumnVisible.bind(this);
            this.onSortChanged = this.onSortChanged.bind(this);
        }

        UNSAFE_componentWillMount() {
            this.accessibleGrid = new AccessibleGrid(() => this.gridRef.current, this.props.getAdditionalMenuItems);
            setTimeout(() => this.hideEmptyColumns(), 200);
            const resultToDisplay = this.props.resultToDisplay as TableResult;
            const conditionalFormattingDisabled = (resultToDisplay.visualizationOptions as ExtendedVisualizationOptions)
                ?.ColumnFormatting?.ConditionalFormattingConfig?.colorRulesDisabled;
            if (!conditionalFormattingDisabled) {
                this.columnTypeByName = this.mapColumnTypeByName();
                this.columnUniqueValues = this.mapColumnsValuesToIndex();
                this.edgeValuesByColumn = this.mapColumnsToEdgeValues();
            }

            this.updateColDefs();

            this.accessibleGridOptions = this.accessibleGrid.gridOptions(this.props.locale);
        }
        componentWillUnmount() {
            // Make sure the latest state will be update in store
            // Unmount happens  when switching tabs, tables results with in same query, execute new query or recall
            //
            // On any change to column state call debouncedStoreGridState
            // But not when scrolling, expand collapse, focus, etc will not trigger update
            this.debouncedStoreGridState();
            this.debouncedStoreGridState.flush();

            // To be on the safe side
            if (!this.gridApi) {
                return;
            }
            this.gridApi.removeEventListener(Events.EVENT_COLUMN_MOVED, this.debouncedStoreGridState);
            this.gridApi.removeEventListener(Events.EVENT_COLUMN_RESIZED, this.debouncedStoreGridState);
            this.gridApi.removeEventListener(Events.EVENT_COLUMN_VISIBLE, this.debouncedStoreGridState);
            this.gridApi.removeEventListener(Events.EVENT_COLUMN_ROW_GROUP_CHANGED, this.debouncedStoreGridState);
            this.gridApi.removeEventListener(Events.EVENT_COLUMN_PIVOT_CHANGED, this.debouncedStoreGridState);
            this.gridApi.removeEventListener(Events.EVENT_FIRST_DATA_RENDERED, this.onFirstDataRendered);
        }
        componentDidUpdate(prevProps: KustoDataProps) {
            const resultToDisplay = this.props.resultToDisplay as TableResult;
            const conditionalFormattingDisabled = (resultToDisplay.visualizationOptions as ExtendedVisualizationOptions)
                .ColumnFormatting?.ConditionalFormattingConfig?.colorRulesDisabled;
            if (resultToDisplay && prevProps.resultToDisplay !== this.props.resultToDisplay && this.gridApi) {
                this.gridApi.setRowData(this.memoizeCloneRows(resultToDisplay));
            }
            if (
                !conditionalFormattingDisabled &&
                (!isEqual(resultToDisplay.visualizationOptions, prevProps.resultToDisplay?.visualizationOptions) ||
                    !isEqual(resultToDisplay.columns, prevProps.resultToDisplay?.columns))
            ) {
                this.columnTypeByName = this.mapColumnTypeByName();
            }
            if (
                !conditionalFormattingDisabled &&
                (!isEqual(resultToDisplay.visualizationOptions, prevProps.resultToDisplay?.visualizationOptions) ||
                    (resultToDisplay && prevProps.resultToDisplay !== this.props.resultToDisplay))
            ) {
                this.columnUniqueValues = this.mapColumnsValuesToIndex();
                this.edgeValuesByColumn = this.mapColumnsToEdgeValues();
                // Need to call updateColDefs in order for the cell renderer to get the updated values of columnUniqueValues and edgeValuesByColumn
                this.updateColDefs();
            } else if (
                !isEqual(resultToDisplay.visualizationOptions, prevProps.resultToDisplay?.visualizationOptions) ||
                !isEqual(resultToDisplay.columns, prevProps.resultToDisplay?.columns) ||
                this.props.numbersAlignRight !== prevProps.numbersAlignRight
            ) {
                this.updateColDefs();
            }
            if (prevProps.theme !== this.props.theme) {
                this.redrewSearchHitCells();
            }
            if (prevProps.timezone !== this.props.timezone) {
                this.gridApi?.redrawRows();
            }
            if (prevProps.hideEmptyColumns !== this.props.hideEmptyColumns || !this.props.hideEmptyColumns) {
                setTimeout(() => this.hideEmptyColumns(), 50);
            }

            if (prevProps.locale !== this.props.locale) {
                this.accessibleGridOptions = this.accessibleGrid!.gridOptions(this.props.locale);
            }
        }

        updateColDefs() {
            const resultToDisplay = this.props.resultToDisplay as TableResult;
            const { columns } = resultToDisplay;
            if (!columns) {
                return;
            }

            let gridColumnDefinitions = columns.map((col) => this.buildColumnDef(col));
            if (this.props.getColumnDef) {
                // Added while enabling lints

                gridColumnDefinitions = gridColumnDefinitions.map(this.props.getColumnDef!);
            }
            this.setState({ columnDefs: gridColumnDefinitions });
        }

        mapColumnTypeByName() {
            const resultToDisplay = this.props.resultToDisplay as TableResult;
            const { columns } = resultToDisplay;
            if (!columns) {
                return {};
            }
            const result = columns.reduce((res, column) => {
                if (column.headerName && column.columnType) {
                    res[column.headerName] = column.columnType;
                }
                return res;
            }, {} as Record<string, kusto.ColumnType>);
            return result;
        }

        /**
         * Map between a column name to the unique values in that column.
         * This is used to be able to map a unique value to a specific color.
         * This is being calculated only if there are "color by value" rules, and is calculated only for the columns in those rules.
         */
        mapColumnsValuesToIndex() {
            const { rows } = this.props.resultToDisplay as TableResult;
            const rules = (this.props.resultToDisplay?.visualizationOptions as ExtendedVisualizationOptions)
                .ColumnFormatting?.ConditionalFormattingConfig?.colorRules;
            const relevantRules = rules?.filter(
                (r) => r.ruleType === 'colorByValue' && r.column !== null && r.themeName === undefined
            ) as VColorRuleByValue[] | undefined;
            const relevantColumns = relevantRules?.map((r) => r.column);
            const columnUniqueValuesToIndex: Record<string, Record<string, number>> = {};
            relevantColumns?.forEach((col) => {
                if (rows && col !== null) {
                    columnUniqueValuesToIndex[col] = {};
                    let valueIndex = 0;
                    rows.forEach((r) => {
                        const val = r[col];
                        if (
                            val !== undefined &&
                            val !== null &&
                            columnUniqueValuesToIndex[col][val.toString()] === undefined
                        ) {
                            columnUniqueValuesToIndex[col][val.toString()] = valueIndex;
                            valueIndex++;
                        }
                    });
                }
            });
            return columnUniqueValuesToIndex;
        }

        mapColumnsToEdgeValues() {
            const resultToDisplay = this.props.resultToDisplay as TableResult;
            const rows = resultToDisplay.rows;
            const rules = (resultToDisplay?.visualizationOptions as ExtendedVisualizationOptions).ColumnFormatting
                ?.ConditionalFormattingConfig?.colorRules;
            const edgeValuesByColumn: Record<string, { min: string; max: string }> = {};

            // Filters only rules that are by value, and miss a maximum value or a minimum value (will need to calculate those ourselves.)
            const ruleByValueColumns = rules?.filter(
                (r) =>
                    r.ruleType === 'colorByValue' &&
                    (r.maxValue === undefined || r.minValue === undefined) &&
                    r.column !== null
            ) as VColorRuleByValue[];

            // Calculate min/max for each rule column, and save it in edgeValuesByColumn
            if (rows && ruleByValueColumns?.length) {
                // For each row, we go over the rules we filtered earlier, since these rules will require us to calculate min/max.
                rows.forEach((row) => {
                    ruleByValueColumns.forEach((rule) => {
                        const columnType = rule.column !== null ? this.columnTypeByName[rule.column] : null;
                        // Currently support only numbers
                        if (
                            rule.column !== null &&
                            columnType !== null &&
                            (columnType === 'int' ||
                                columnType === 'long' ||
                                columnType === 'real' ||
                                columnType === 'decimal')
                        ) {
                            const value = row[rule.column];
                            const numValue = value === null || value === undefined ? NaN : parseFloat(value.toString());
                            // Making sure it's actually a number to be safe
                            if (!isNaN(numValue)) {
                                const edgeValues = edgeValuesByColumn[rule.column];
                                if (!edgeValues) {
                                    edgeValuesByColumn[rule.column] = {
                                        min: numValue.toString(),
                                        max: numValue.toString(),
                                    };
                                } else {
                                    edgeValuesByColumn[rule.column] = {
                                        min: Math.min(numValue, parseFloat(edgeValues.min)).toString(),
                                        max: Math.max(numValue, parseFloat(edgeValues.max)).toString(),
                                    };
                                }
                            }
                        }
                    });
                });
            }
            return edgeValuesByColumn;
        }

        getRowClass = (params: RowClassParams) => {
            let classes: string[] = [];
            if (this.props.gridOptions?.getRowClass) {
                classes = classes.concat(this.props.gridOptions.getRowClass(params) ?? []);
            }
            const resultToDisplay = this.props.resultToDisplay as TableResult;
            const visualOptions = resultToDisplay?.visualizationOptions as ExtendedVisualizationOptions;
            if (!visualOptions.ColumnFormatting?.ConditionalFormattingConfig?.colorRulesDisabled) {
                const colOptions = getConditionalFormattingOptions({
                    row: params.data,
                    columnsToType: this.columnTypeByName,
                    edgeValues: this.edgeValuesByColumn,
                    columnsUniqueValues: this.columnUniqueValues,
                    applyTo: 'rows',
                    conditionalFormattingOptions: visualOptions.ColumnFormatting?.ConditionalFormattingConfig,
                });
                if (Object.keys(colOptions).length > 0) {
                    // Get the class name for the row (either using color, or theme)
                    const cfClassName = colOptions.color
                        ? generateCFClassName({
                              theme: this.props.theme,
                              colorStyle: colOptions.colorStyle,
                              colorName: colOptions.color,
                          })
                        : '';
                    const resultClassName = colOptions.colorByValueClassName ?? cfClassName;
                    if (resultClassName) {
                        classes.push(...resultClassName.split(' '));
                    }
                }
            }
            return classes;
        };

        // TODO:(izlisbon): after upgrading to AGGrid >24.0, this code can be replaced with
        // setting `hide: true|false` in baseColDef in buildColumnDef. Today it doesn't work.
        // From AGGrid v24.0 changelog (https://ag-grid.com/ag-grid-changelog/?fixVersion=24.0.0):
        // Column stateful items (width, flex, hide, sort, aggFunc, pivot, pivotIndex, rowGroup, rowGroupIndex, initialPinned) always get re-applied when Column Definitions are updated.
        hideEmptyColumns() {
            const resultToDisplay = this.props.resultToDisplay as TableResult;
            const { columns } = resultToDisplay as TableResult;
            const { rows } = resultToDisplay as TableResult;

            if (!columns) {
                return;
            }

            if (rows && columns) {
                const emptyColumns: string[] = [];

                for (const column of columns) {
                    const columnName = column.headerName;
                    if (!rows.some((row) => row[columnName] != null && row[columnName] !== '')) {
                        emptyColumns.push(columnName);
                    }
                }

                const notEmptyAnyMore =
                    latestEmptyColumns?.filter((prev) => !emptyColumns.some((cur) => cur === prev)) ?? [];

                const showEmptyColumns = !this.props.hideEmptyColumns;
                this.columnApi?.setColumnsVisible(emptyColumns, showEmptyColumns);
                if (notEmptyAnyMore && notEmptyAnyMore.length > 0) {
                    this.columnApi?.setColumnsVisible(notEmptyAnyMore, true);
                }
                latestEmptyColumns = emptyColumns;
            }
        }

        render() {
            const { resultToDisplay, theme, locale } = this.props;
            this.locale = locale.locale;
            const gridProps = this.props as AgGridReactProps & P;
            if (resultToDisplay == null) {
                return undefined;
            }
            const { columns } = resultToDisplay as TableResult;
            if (!columns) {
                return undefined;
            }

            const mergedGridOptions = merge(
                {
                    columnDefs: this.state.columnDefs,
                },
                this.gridOptions,
                this.accessibleGridOptions,
                this.props.gridOptions,
                {
                    onGridReady: this.onGridReady,
                    getRowClass: this.getRowClass,
                }
            );

            let searchComponent: JSX.Element | undefined = undefined;
            const showSearchBar = this.props.searchEnabled;
            if (showSearchBar && this.props.searchPlaceholderRef?.current) {
                searchComponent = (
                    <QueryResultsSearchPortal container={this.props.searchPlaceholderRef!.current}>
                        <Search
                            originalTotalResults={resultToDisplay.rows?.length}
                            searchBoxRef={this.props.searchBoxRef}
                            totalResultsCount={this.state.searchHitsCount}
                            onPositionChanged={this.delayedOnSearchPositionChanged}
                            onSearch={(str, completed, filterSearchResults) =>
                                this.debounceSearch(str, completed, filterSearchResults)
                            }
                            shouldNextIncreaseCount={true}
                            onNext={() => this.onNextSearchHit()}
                            onPrev={() => this.onPrevSearchHit()}
                            onClear={() => {
                                this.clearSearchHits(true);
                                if (this.props.onSearchClear) {
                                    this.props.onSearchClear();
                                }
                            }}
                            strings={this.props.locale.visualizations}
                            {...(this.props.searchOptions ?? {})}
                            locale={this.locale}
                            onFilterSearchResultsToggle={(searchString, val) => {
                                setTimeout(() => {
                                    this.gridApi?.setQuickFilter(val ? searchString.toUpperCase() : '');
                                }, 0);
                            }}
                            onClose={() => {
                                this.clearSearchHits(true);
                                if (this.props.onSearchClear) {
                                    this.props.onSearchClear();
                                }
                                setTimeout(() => {
                                    this.gridApi?.setQuickFilter('');
                                }, 0);
                            }}
                        />
                    </QueryResultsSearchPortal>
                );
            } else {
                this.clearSearchHits(true);
            }

            return (
                <div
                    id="table-result-container"
                    className={mergeClasses(
                        theme === Theme.Dark ? 'ag-theme-balham-dark' : 'ag-theme-balham',
                        'grid-with-kusto-data',
                        styles.agGridWithKustoData,
                        !this.gridApi && styles.hidden
                    )}
                    // Hide the grid on creation till grid state is restored
                    // measuring/ changing columns size and update groups state
                    // require more CPU/time when the grid is visible
                    ref={this.gridRef}
                    onContextMenu={this.onContextMenu}
                >
                    {this.state.menu && this.props.getContextMenuItemsKwe && (
                        <ContextualMenu
                            items={this.state.menu.items}
                            target={this.state.menu.target}
                            shouldFocusOnContainer={true}
                            shouldFocusOnMount={true}
                            onDismiss={() => this.setState({ menu: undefined })}
                        />
                    )}

                    {showSearchBar && searchComponent}

                    <UncontrolledFocusWrapper className={styles.agGridWithKustoData}>
                        <WrappedComponent
                            {...gridProps}
                            gridOptions={mergedGridOptions}
                            // AgGrid doesn't detect changes in the option.columnDef
                            columnDefs={mergedGridOptions.columnDefs}
                            onColumnMoved={this.onColumnMoved}
                            onColumnPinned={this.onColumnPinned}
                            onColumnRowGroupChanged={this.onColumnRowGroupChanged}
                            onColumnVisible={this.onColumnVisible}
                            onFilterChanged={this.onFilterChanged}
                            onSortChanged={this.onSortChanged}
                            searchFocusedCell={this.searchHits.current}
                            onCellContextMenu={this.onCellContextMenu}
                        />
                    </UncontrolledFocusWrapper>
                </div>
            );
        }

        private onFilterChanged = (event: FilterChangedEvent) => {
            if (this.props.onFilterChanged) {
                this.props.onFilterChanged(event);
            }
            this.refreshSearchResults('onFilterChanged');
        };

        private onColumnMoved = (event: ColumnMovedEvent) => {
            if (this.props.onColumnMoved) {
                this.props.onColumnMoved(event);
            }
            this.refreshSearchResults('onColumnMoved');
        };

        private onColumnPinned = (event: ColumnPinnedEvent) => {
            if (this.props.onColumnPinned) {
                this.props.onColumnPinned(event);
            }
            this.refreshSearchResults('onColumnPinned');
        };

        private onColumnRowGroupChanged = (event: ColumnRowGroupChangedEvent) => {
            if (this.props.onColumnRowGroupChanged) {
                this.props.onColumnRowGroupChanged(event);
            }
            this.refreshSearchResults('onColumnRowGroupChange');
        };

        private onColumnVisible = (event: ColumnVisibleEvent) => {
            if (this.props.onColumnVisible) {
                this.props.onColumnVisible(event);
            }
            this.refreshSearchResults('onColumnVisible');
        };

        private onSortChanged = (event: SortChangedEvent) => {
            if (this.props.onSortChanged) {
                this.props.onSortChanged(event);
            }
            this.refreshSearchResults('onSortChanged');
        };

        private onPrevSearchHit(): number | undefined {
            const nextSearchHitIndex = this.findFirstSearchHitIndexAfterFocusedCell();
            if (nextSearchHitIndex === undefined) {
                return undefined;
            }
            // returned position is 1-indexed
            return nextSearchHitIndex - 1 >= 0 ? nextSearchHitIndex : this.searchHits.all.length;
        }

        private onNextSearchHit(): number | undefined {
            const nextSearchHitIndex = this.findFirstSearchHitIndexAfterFocusedCell();
            if (nextSearchHitIndex === undefined) {
                return undefined;
            }
            if (nextSearchHitIndex >= this.searchHits.all.length) {
                return 1;
            }
            // returned position is 1-indexed
            return nextSearchHitIndex + 1;
        }

        /**
         * Find the index of the first search hit cell after the focused cell.
         */
        private findFirstSearchHitIndexAfterFocusedCell = (): number | undefined => {
            if (this.gridApi && this.searchHits.current && !this.searchHits.markAsFocused) {
                const focusedCell = this.gridApi.getFocusedCell();
                if (!focusedCell) {
                    return undefined;
                }
                const colIndex = this.getDisplayableColumnIndex(focusedCell.column.getColId());
                if (!colIndex) {
                    return undefined;
                }

                for (let i = 0; i < this.searchHits.all.length; i++) {
                    const searchHit = this.searchHits.all[i];
                    // Added while enabling lints

                    if (searchHit.rowIndex === focusedCell.rowIndex && searchHit.displayableColumnIndex > colIndex!) {
                        return i;
                    }
                    if (searchHit.rowIndex > focusedCell.rowIndex) {
                        return i;
                    }
                }

                return this.searchHits.all.length;
            }

            return undefined;
        };

        /**
         * Get the displayable index of a column.
         * Unfortunately, there is no API in AgGrid to get a column index, so the solution is to fetch
         * a sorted array with all the displayable columns and find the column's index in that array.
         */
        private getDisplayableColumnIndex = (colKey: string) => {
            const displayedColumns = this.columnApi?.getAllDisplayedColumns();
            if (!displayedColumns) {
                return undefined;
            }

            for (let i = 0; i < displayedColumns.length; i++) {
                const column = displayedColumns[i];
                if (column.getColId() === colKey) {
                    return i;
                }
            }

            return undefined;
        };

        onCellFocused(_e: CellFocusedEvent) {
            if (this.props.searchEnabled && this.searchHits.current && this.searchHits.markAsFocused) {
                this.searchHits.markAsFocused = false;
                this.gridApi?.refreshCells({
                    force: true,
                    rowNodes: [this.searchHits.current.row],
                    columns: [this.searchHits.current.colKey],
                });
            }
        }

        redrewSearchHitCells() {
            const searchHits = this.searchHits.current;
            if (searchHits) {
                this.gridApi?.refreshCells({
                    force: true,
                    rowNodes: [searchHits.row],
                    columns: [searchHits.colKey],
                });
            }
        }

        onSearchPositionChanged = (position: number) => {
            const rowsToRefresh: Set<IRowNode> = new Set();
            const colsToRefresh: Set<string> = new Set();

            // Previous search hit
            const oldHit = this.searchHits.current;
            if (oldHit) {
                rowsToRefresh.add(oldHit.row);
                colsToRefresh.add(oldHit.colKey);
            }

            // New search hit
            const newHit = this.searchHits.all[position - 1];
            this.searchHits.current = newHit;
            this.searchHits.position = position;
            if (newHit) {
                rowsToRefresh.add(newHit.row);
                colsToRefresh.add(newHit.colKey);

                // Ensure new hit is visible
                this.gridApi?.ensureNodeVisible(newHit.row, 'middle');
                let currentRow: IRowNode | null = newHit.row;
                while (currentRow != null) {
                    currentRow = currentRow.parent;
                    currentRow?.setExpanded(true);
                }
                this.gridApi?.ensureColumnVisible(newHit.colKey);
            }

            this.searchHits.markAsFocused = true;

            // refresh cells
            this.gridApi?.refreshCells({
                force: true,
                rowNodes: Array.from(rowsToRefresh),
                columns: Array.from(colsToRefresh),
            });

            // Since search hit changed, force update to allow the WrappedComponent to get the current search hit.
            // Q: Why not moving this.searchHits.current into a state?
            // A: state is updated asynchronously, but the refreshCells above happens immediately after current search is updated.
            //    to avoid the need to call refresh cells asynchronously it was easier to use
            //    this.searchHits.current and call forceUpdate just to update the WrappedComponent.
            this.forceUpdate();
        };

        //AG grid solution from error message. allows grid to finish drawing before trying to redraw again
        delayedOnSearchPositionChanged = (position: number) => {
            setTimeout(() => this.onSearchPositionChanged(position));
        };

        clearSearchHits = (resetFilter?: boolean) => {
            if (this.searchHits.all.length > 0) {
                this.searchHits = new SearchHits();
                this.gridApi?.refreshCells();
                this.setState({ searchHitsCount: 0 });
                if (resetFilter) {
                    setTimeout(() => {
                        this.gridApi?.setQuickFilter('');
                    }, 0);
                }
            }
        };

        private refreshSearchResults(_triggerNameForTelemetry: string) {
            if (this.props.searchEnabled && this.searchHits.searchTerm) {
                const oldSearchHits = this.searchHits;
                this.debounceSearch(this.searchHits.searchTerm, () => {
                    // keep the focused cell visible
                    if (oldSearchHits.position && oldSearchHits.all.length === this.searchHits.all.length) {
                        this.searchHits.current = this.searchHits.all[oldSearchHits.position - 1];
                        this.searchHits.position = oldSearchHits.position;
                        this.searchHits.markAsFocused = true;

                        this.gridApi?.refreshCells();
                    }
                });
            }
        }

        /**
         * Build searchHits and set `this.state.searchHitsCount`.
         */
        debounceSearch = debounce((searchStr: string, completed: () => void, filterResults?: boolean) => {
            // clear previous search hits
            this.clearSearchHits(searchStr === '');

            // if search term is empty, do nothing.
            if (isEmpty(searchStr)) {
                this.setState({ searchHitsCount: 0 });
                completed();
                return;
            }

            // Get displayed columns
            const displayedColumns = this.columnApi?.getAllDisplayedColumns();
            if (!displayedColumns) {
                this.setState({ searchHitsCount: 0 });
                completed();
                return;
            }

            // iterate over all visible rows and columns and create search hits mapping.
            const searchHits: SearchHits = new SearchHits();
            searchHits.searchTerm = searchStr;
            const strUpperCase = searchStr.toUpperCase();

            setTimeout(() => {
                if (filterResults) {
                    this.gridApi?.setQuickFilter(strUpperCase);
                }

                this.gridApi?.forEachNodeAfterFilterAndSort((row: IRowNode) => {
                    let columnIndex = 0;
                    for (const column of displayedColumns) {
                        const colKey = column.getColId();
                        const cellVal = this.gridApi?.getValue(colKey, row);
                        if (cellVal?.toString().toUpperCase().includes(strUpperCase) && row.rowIndex !== null) {
                            searchHits.all.push(new AgCell(row, column, columnIndex));
                            searchHits.addToSet(row.id, colKey);
                        }
                        columnIndex++;
                    }
                });

                this.searchHits = searchHits;
                this.gridApi?.refreshCells();

                // set hits count
                this.setState({ searchHitsCount: this.searchHits.all.length });

                completed();
            }, 0);
        }, 500);

        memoizeCloneRows = memoizeOne(
            (table: TableResult) => {
                const sortedRows = sortRowsByDate(table);
                return sortedRows ?? [];
            },
            ([prev]: TableResult[], [next]: TableResult[]) => prev.columns === next.columns && prev.rows === next.rows
        );

        shouldMarkAsSearchHit = (colId: string | undefined, rowId: string) => {
            const searchHit = this.searchHits.current;
            const isSameColumn = searchHit && searchHit.colKey === colId;
            const isSameRow = searchHit && searchHit.row.id === rowId;
            return isSameColumn && isSameRow;
        };

        buildColumnDef = (col: kusto.KustoColumn): ColDef => {
            const { numbersAlignRight } = this.props;
            const visOptions = this.props.resultToDisplay?.visualizationOptions as ExtendedVisualizationOptions;
            const columnFormatting = visOptions?.ColumnFormatting as ColumnFormatting;
            const baseColDef: ColDef = {
                colId: col.headerName,
                headerName: col.headerName,
                field: col.field,
                cellRendererParams: {
                    columnType: col.columnType ? col.columnType : undefined,
                    columnTypeByName: this.columnTypeByName,
                    edgeValues: this.edgeValuesByColumn,
                    columnUniqueValues: this.columnUniqueValues,
                    renderAsLink: columnFormatting?.LinkConfig?.renderAsLink,
                    visualizationOptions: this.props.resultToDisplay?.visualizationOptions,
                    theme: this.props.theme,
                    strings: this.props.locale.visualizations,
                },
                cellClassRules: {
                    'ag-cell-search-hit': (params: CellClassParams) => {
                        if (!params.colDef.colId) return false;
                        return this.searchHits.hasInSet(params.node.id, params.colDef.colId);
                    },
                    'ag-cell-search-hit-focus': (params: CellClassParams) => {
                        if (!this.searchHits.markAsFocused) {
                            return false;
                        }
                        const searchHit = this.searchHits.current;
                        const isSameColumn = searchHit && searchHit.colKey === params.colDef.colId;
                        const isSameRow = searchHit && searchHit.row.id === params.node.id;
                        return !!(isSameColumn && isSameRow);
                    },
                    'ag-cell-search-hit-current': (params: CellClassParams) => {
                        const searchHit = this.searchHits.current;
                        const isSameColumn = searchHit && searchHit.colKey === params.colDef.colId;
                        const isSameRow = searchHit && searchHit.row.id === params.node.id;
                        return !!(isSameColumn && isSameRow);
                    },
                    [styles.kustoAlignmentRight]: (params: CellClassParams) => {
                        const columnType = params.colDef.cellRendererParams.columnType;
                        return !!(
                            numbersAlignRight &&
                            (columnType === 'int' ||
                                columnType === 'long' ||
                                columnType === 'real' ||
                                columnType === 'double' ||
                                columnType === 'decimal')
                        );
                    },
                },
            };

            baseColDef.cellClass = (params: CellClassParams) => {
                // If conditional formatting is enabled, add the correct class name to apply formatting
                // Re-declaring the conditional formatting configuration to avoid using older values
                const visualOptions = this.props.resultToDisplay?.visualizationOptions as ExtendedVisualizationOptions;
                const columnFormattingConfig = visualOptions?.ColumnFormatting as ColumnFormatting;
                const conditionalFormattingConfig = columnFormattingConfig?.ConditionalFormattingConfig;
                const theme = this.props.theme;

                if (!conditionalFormattingConfig || conditionalFormattingConfig.colorRulesDisabled) {
                    return '';
                }
                const headerName = col.headerName || '';
                const colOptions = getConditionalFormattingOptions({
                    row: params.data,
                    columnsToType: this.columnTypeByName,
                    edgeValues: this.edgeValuesByColumn,
                    columnsUniqueValues: this.columnUniqueValues,
                    applyTo: 'cells',
                    targetColumn: headerName,
                    conditionalFormattingOptions: conditionalFormattingConfig,
                });
                const cfClass = generateCFClassName({
                    theme,
                    colorStyle: colOptions.colorStyle,
                    colorName: colOptions.color,
                });
                return `${cfClass} ${colOptions.colorByValueClassName ?? ''}`;
            };

            let typedColDef: ColDef;
            switch (col.columnType) {
                // TODO: decimal here isn't part of the party because it gets returned as a string from the server side,
                // and because it's usually to big to be represented as a javascript number.
                case 'int':
                case 'long':
                    typedColDef = {
                        valueFormatter: this.props.formatResultData ? this.integerValueFormatter : undefined,
                        filter: 'agNumberColumnFilter',
                        comparator: this.compareStringNumbers,
                    };
                    break;
                case 'real':
                    //
                    // let x = 0.9999999999999999;
                    // let x1 = x/100000;
                    // print x,x1, toString(x), toString(x1) , y=toString(x), todecimal(0.000000000000009999999) , todouble(0.000099999999999989), z1=199999
                    //
                    // expected result 0.99999999999999989,9.9999999999999991E-06,"0.9999999999999999","0.000009999999999999999","0.9999999999999999","0.0000000000000100000000000000000000000",9.9999999999989E-05,199999
                    //
                    typedColDef = {
                        valueFormatter: this.props.formatResultData ? this.floatValueFormatter : undefined,
                        filter: 'agNumberColumnFilter',
                        comparator: this.compareStringNumbers,
                    };
                    break;
                case 'decimal':
                    typedColDef = {
                        valueFormatter: this.props.formatResultData ? this.decimalValueFormatter : undefined,
                        filter: 'agNumberColumnFilter',
                        comparator: this.compareStringNumbers,
                    };
                    break;
                case 'datetime':
                    typedColDef = {
                        valueFormatter: this.datetimeValueFormatter,
                        filter: 'agTextColumnFilter',
                        filterParams: { filterOptions: textFilters },
                    };
                    break;
                default:
                    typedColDef = {
                        valueFormatter: this.clipValueFormatter,
                        filter: 'agTextColumnFilter',
                        filterParams: { filterOptions: textFilters },
                    };
                    break;
            }

            return { ...baseColDef, ...typedColDef };
        };

        onFirstDataRendered = (event: FirstDataRenderedEvent) => {
            setTimeout(() => {
                if (event.columnApi) {
                    event.columnApi.applyColumnState({ state: calcDefaultColWidth(this.gridRef, event.columnApi) });
                }
            }, 50);
        };

        onGridReady = (e: GridReadyEvent) => {
            if (!e.api || !e.columnApi) {
                return;
            }
            this.columnApi = e.columnApi;
            this.gridApi = e.api;

            // Added while enabling lints

            this.accessibleGrid!.init(e);
            // clone bcz ag-grid adds props to rows and rows are freezed in our model.
            const tableResult = this.props.resultToDisplay as TableResult;
            if (!tableResult) {
                return;
            }
            const data = this.memoizeCloneRows(tableResult);
            let restoredState = false;
            const initialGridState = this.props.initialGridState?.();
            if (initialGridState) {
                restoredState = restoreGridState(e.api, e.columnApi, initialGridState, data, this.gridRef.current);
            }
            if (!restoredState) {
                this.gridApi.addEventListener(Events.EVENT_FIRST_DATA_RENDERED, this.onFirstDataRendered);
                const sampleData = this.props.autoSizeAllData ? data : data.slice(0, 5);
                this.gridApi.setRowData(sampleData);

                // make the grid visible ....
                if (this.gridRef.current) {
                    this.gridRef.current.style.display = 'block';
                }

                setTimeout(() => {
                    if (e.columnApi) {
                        e.columnApi.applyColumnState({ state: calcDefaultColWidth(this.gridRef, e.columnApi) });
                    }
                }, 50);

                if (!this.props.autoSizeAllData) {
                    this.gridApi.setRowData(data);
                }
            } else {
                this.gridApi.removeEventListener(Events.EVENT_FIRST_DATA_RENDERED, this.onFirstDataRendered);
            }
            this.gridApi.addEventListener(Events.EVENT_COLUMN_MOVED, this.debouncedStoreGridState);
            this.gridApi.addEventListener(Events.EVENT_COLUMN_RESIZED, this.debouncedStoreGridState);
            this.gridApi.addEventListener(Events.EVENT_COLUMN_VISIBLE, this.debouncedStoreGridState);
            this.gridApi.addEventListener(Events.EVENT_COLUMN_ROW_GROUP_CHANGED, this.debouncedStoreGridState);
            this.gridApi.addEventListener(Events.EVENT_COLUMN_PIVOT_CHANGED, this.debouncedStoreGridState);
            if (this.props.gridOptions && this.props.gridOptions.onGridReady) {
                this.props.gridOptions.onGridReady(e);
            }
        };

        clipValueFormatter = (params: ValueFormatterParams) => {
            if (params.value === undefined || params.value === null) {
                return '';
            }
            const str = '' + params.value;
            return str.substring(0, 500);
        };

        floatValueFormatter = (params: ValueFormatterParams) => {
            if (params.value === undefined || params.value === null) {
                return '';
            }

            const numberValue = Number.parseFloat(params.value);

            // TODO: if this is too slow / doesn't work across browsers, replace with an ugly regex
            // return numberValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            return numberValue.toLocaleString(this.locale, {
                maximumFractionDigits: 20, // maximum allowed
                // keep the engine/result set notation (eg. 0.001 vs 1.0e-2)
                notation: this.numberStringHaveExponentialPart(params.value) ? 'scientific' : 'standard',
            } as Intl.NumberFormatOptions);
            // let x = 0.9999999999999999;
            // let x1 = x/100000;
            //
            // expected result 0.99999999999999989,9.9999999999999991E-06,"0.9999999999999999","0.000009999999999999999","0.9999999999999999","0.0000000000000100000000000000000000000",9.9999999999989E-05,199999
            //
        };

        integerValueFormatter = (params: ValueFormatterParams) => {
            if (params.value === undefined || params.value === null || params.value === '') {
                return '';
            }

            const strValue = params.value.toString() as string;
            if (typeof this.numberFormatter(this.locale).formatToParts !== 'function') {
                // In some browsers (Microsoft Edge less then 18 & IE) it's not supported
                // Fallback to un-formatted number
                return strValue;
            }

            return this.bigNumberFormatter(strValue);
        };

        bigNumberFormatter(integerStr: string, fractionStr?: string) {
            // The best case was to use this.numberFormatter.format(params.value.toString())
            // The problem - the formatter convert the string value to a number
            // which cause losing precision of large numbers

            // take the formatting from JS formatter
            // and change the actual value to with the relative part from the original string (keep the original precision)

            const numberFormattedParts = this.numberFormatter(this.locale).formatToParts(Number.parseFloat(integerStr));
            let intIndex = integerStr[0] === '-' ? 1 : 0;
            return numberFormattedParts
                .map((part) => {
                    if (part.type === 'integer') {
                        // replace with relative part from the original number
                        // the formatted number might lose precision (bigint issues)
                        const updatedPart = integerStr.slice(intIndex, intIndex + part.value.length);
                        intIndex += part.value.length;
                        return updatedPart;
                    }

                    // if the number have fraction replace it with the actual fraction (not manipulated)
                    if (part.type === 'decimal' && !fractionStr) {
                        return '';
                    }
                    if (part.type === 'fraction') {
                        return fractionStr ? fractionStr : '';
                    }
                    return part.value;
                })
                .join('');
        }
        decimalValueFormatter = (params: ValueFormatterParams) => {
            if (params.value === undefined || params.value === null) {
                return '';
            }

            const strValue = params.value.toString() as string;
            if (typeof this.numberFormatter(this.locale).formatToParts !== 'function') {
                // In some browsers (Microsoft Edge less then 18 & IE) it's not supported
                // Fallback to un-formatted number
                return strValue;
            }
            // Fall back to float formatter in case the number is represented by 1.23eXX
            // Assuming kusto decimals are full number strings
            if (this.numberStringHaveExponentialPart(strValue)) {
                return this.floatValueFormatter(params);
            }
            const dotIndex = strValue.indexOf('.');
            if (dotIndex === -1) {
                return this.bigNumberFormatter(strValue);
            } else {
                return this.bigNumberFormatter(
                    strValue.substring(0, dotIndex), // integer part (before the dot)
                    strValue.substring(dotIndex + 1) // fraction part after the dot
                );
            }
        };

        datetimeValueFormatter = (params: ValueFormatterParams) => {
            if (params.value === undefined || params.value === null || params.value === '') {
                return '';
            }

            try {
                const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss.SSSS';
                if (!momentTimeZone) {
                    return moment.utc(params.value).format(dateTimeFormat);
                } else {
                    const timezone = this.props.timezone ?? 'UTC';
                    return momentTimeZone.utc(params.value).tz(timezone).format(dateTimeFormat);
                }
            } catch {
                return params.value;
            }
        };
        numberStringHaveExponentialPart = (numberStr: unknown) => {
            if (typeof numberStr !== 'string') {
                return false;
            }

            return numberStr.indexOf('e') !== -1 || numberStr.indexOf('E') !== -1;
        };

        compareStringNumbers = (valueA: string, valueB: string): number => {
            if (valueA === valueB) {
                return 0;
            }
            const numA = parseFloat(valueA);
            const numB = parseFloat(valueB);
            if (isNaN(numA)) {
                return -1;
            }
            if (isNaN(numB)) {
                return 1;
            }
            return numA - numB;
        };

        onContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault();

            if (event.target instanceof HTMLElement && event.target.matches('.ag-header-cell.ag-header-active')) {
                /**
                 * In ag-grid v24, there is a weird bug where you can't
                 * activate the column header context menu even though you have
                 * a column header focused.
                 *
                 * This work around needs to dispatch the `contextmenu` event on the
                 * child element (`ag-cell-label-container`) because it seems
                 * like the event handler for `contextmenu` is
                 * registered against that child element instead of the
                 * parent (top-level) column header for some reason.
                 *
                 * For more context around this bug see the original PR
                 * @see https://msazure.visualstudio.com/DefaultCollection/One/_git/Azure-Kusto-WebUX/pullrequest/4953425
                 */
                event.target
                    .querySelector('.ag-cell-label-container')
                    ?.dispatchEvent(new Event('contextmenu', { bubbles: false }));
                return;
            }

            // If "event.target.matches" doesn't match the conditions to trigger
            // "onCellContextMenu", then menu will be buggy.
            //
            // Plan is to use `onCellContextMenu` if condition is true, and use
            // this callback if condition is false. This is a bit of a hack, and
            // I'm not certain how durable it is, or if it doesn't work in rare
            // cases.
            if (
                event.target instanceof HTMLElement &&
                (event.target.matches('.ag-cell *') || event.target.matches('.ag-virtual-list-item'))
            ) {
                // Handled by onCellContextMenu agGrid callback, or opens the accessible menu of row grouping
                return;
            }

            const getContextMenuItems = this.props.getContextMenuItemsKwe;
            if (getContextMenuItems) {
                this.setState({
                    menu: { target: { x: event.clientX, y: event.clientY }, items: getContextMenuItems([]) },
                });
            }
        };

        onCellContextMenu: GridOptions['onCellContextMenu'] = (event) => {
            const mouseEvent = event.event;
            if (!mouseEvent || !(mouseEvent instanceof MouseEvent)) {
                return;
            }

            mouseEvent.preventDefault();

            const getContextMenuItems = this.props.getContextMenuItemsKwe;
            if (getContextMenuItems) {
                this.setState({
                    menu: {
                        target: { x: mouseEvent.clientX, y: mouseEvent.clientY },
                        items: getContextMenuItems([], event),
                    },
                });
            }
        };

        debouncedStoreGridState = debounce(() => {
            if (this.columnApi && this.gridApi && this.props.onStoreGridState) {
                this.props.onStoreGridState(getGridState(this.gridApi, this.columnApi));
            }
        }, 2000);
    };
}
