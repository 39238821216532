import type { Locale } from '../types';
import type { DataFrameTypes, KustoDataType, UnknownDataFrameValue } from './dataTypes';
import { valueToDisplayStringImpl, type ValueToDisplayString } from './locale';

export interface IField<
    T extends KustoDataType = KustoDataType,
    V extends UnknownDataFrameValue = UnknownDataFrameValue
> extends FieldSchema {
    /**
     * Column name
     */
    readonly name: string;
    readonly type: T;
    readonly values: readonly (null | V)[];
    /**
     * `null` values propagated so callers can do location-specific formatting
     */
    toLocaleString(index: number, timeZone: string, locale: Locale): null | string;
}

export type StringField = IField<'string', DataFrameTypes['string']>;
export type IntField = IField<'int', DataFrameTypes['int']>;
export type LongField = IField<'long', DataFrameTypes['long']>;
export type RealField = IField<'real', DataFrameTypes['real']>;
export type DecimalField = IField<'decimal', DataFrameTypes['decimal']>;
export type BoolField = IField<'bool', DataFrameTypes['bool']>;
export type DatetimeField = IField<'datetime', DataFrameTypes['datetime']>;
export type TimespanField = IField<'timespan', DataFrameTypes['timespan']>;
export type GuidField = IField<'guid', DataFrameTypes['guid']>;
export type DynamicField = IField<'dynamic', DataFrameTypes['dynamic']>;

export type UField =
    | StringField
    | IntField
    | LongField
    | RealField
    | DecimalField
    | BoolField
    | DatetimeField
    | TimespanField
    | GuidField
    | DynamicField;

export interface DataFrame {
    readonly fields: readonly UField[];
    /**
     * Number of rows
     *
     * Must be saved separably so we can correctly round-trip with row-based
     * formats. Without this, we can't tell the number of rows when there are no
     * columns.
     */
    readonly size: number;
}

/// Utility

export interface FieldSchema {
    readonly name: string;
    readonly type: KustoDataType;
}

export type DataFrameSchema = readonly FieldSchema[];

/// Implementation

export class Field<
    T extends KustoDataType = KustoDataType,
    V extends DataFrameTypes[KustoDataType] = DataFrameTypes[KustoDataType]
> implements IField<T, V>
{
    constructor(readonly name: string, readonly type: T, readonly values: readonly (null | V)[]) {}

    toLocaleString(index: number, timeZone: string, locale: Locale): null | string {
        const value = this.values[index];
        return value === null
            ? null
            : (valueToDisplayStringImpl[this.type] as ValueToDisplayString<V>)(value, timeZone, locale);
    }
}
