.monacoContainer {
    width: 100%;
    height: 100%;
    min-height: 0;
    min-width: 0;
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: auto;

    // Class created by monaco-editor
    :global(.monaco-aria-container) {
        width: 0px;
        height: 0px;
        overflow: hidden;
    }
}
