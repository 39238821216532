export function useTreeItemContextValues_unstable(state) {
    const { value, itemType, layoutRef, subtreeRef, open, expandIconRef, actionsRef, treeItemRef, // eslint-disable-next-line deprecation/deprecation
    isActionsVisible, // eslint-disable-next-line deprecation/deprecation
    isAsideVisible, selectionRef, checked } = state;
    /**
   * This context is created with "@fluentui/react-context-selector",
   * there is no sense to memoize it
   */ const treeItem = {
        value,
        checked,
        itemType,
        layoutRef,
        subtreeRef,
        open,
        selectionRef,
        isActionsVisible,
        isAsideVisible,
        actionsRef,
        treeItemRef,
        expandIconRef
    };
    return {
        treeItem
    };
}
