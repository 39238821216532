import { SeverityLevel as AppInsightsSeverityLevel } from '@microsoft/applicationinsights-web';

import { IKweTelemetry, KweException, SeverityLevel } from '@kusto/utils';

let telemetryClient: undefined | IKweTelemetry;
let getVerboseFlag: undefined | (() => boolean);

export function initLegacyTelemetryClient(client: IKweTelemetry, _getVerboseFlag?: () => boolean) {
    if (telemetryClient) {
        throw new KweException('telemetry client already initialized');
    }
    telemetryClient = client;
    getVerboseFlag = _getVerboseFlag;
}

export const SeverityLevels = AppInsightsSeverityLevel;

export interface BaseProperties {
    disablePiiRedactor?: boolean;
    [name: string]: unknown;
}

// An event should contain at least the component and the flow.
export interface FlowProperties {
    component: string;
    flow: string;
}

function translateSeverityLevel(level: undefined | AppInsightsSeverityLevel): undefined | SeverityLevel {
    switch (level) {
        case undefined:
            return undefined;
        case AppInsightsSeverityLevel.Verbose:
            return 'verbose';
        case AppInsightsSeverityLevel.Information:
            return 'information';
        case AppInsightsSeverityLevel.Warning:
            return 'warning';
        case AppInsightsSeverityLevel.Error:
            return 'error';
        case AppInsightsSeverityLevel.Critical:
            return 'critical';
        default:
            throw new KweException(`Unexpected severity level: ${level}`);
    }
}

/**
 * @deprecated: Use KweTelemetry client directly. KweTelemetry can be accessed
 * with `useBindTelemetry` or `useQueryCore`.
 */
export function getTelemetryClient<propsType extends FlowProperties = FlowProperties>(constantProps: propsType) {
    // Binding to client lazily because `getTelemetryClient` may be called
    // before `telemetryClient` is available.
    const localClient = () => {
        if (!telemetryClient) {
            throw new KweException('telemetry client not initialized');
        }
        return telemetryClient.bind(constantProps as Record<string, unknown>);
    };
    const trackEvent = (
        name: string,
        properties?: (BaseProperties & Partial<propsType>) | undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        measurements?: { [name: string]: number } | undefined
    ) => {
        // appInsights.trackEvent can actually be null (before calling loadAppInsights)
        localClient().event(name, {
            name,
            ...properties,
            measurements,
        });
    };
    const trackEventWithContext = (
        name: string,
        context?: { [name: string]: string },
        properties?: (BaseProperties & Partial<propsType>) | undefined
    ) => {
        localClient().event(name, { ...context, ...properties });
    };

    /**
     * track a trace. Mainly used for developers. Verbose traces are left out unless feature flag verboseLogs is turned on.
     */
    const trackTrace = (
        message: string,
        severityLevel: AppInsightsSeverityLevel = AppInsightsSeverityLevel.Information,
        properties?: (BaseProperties & Partial<propsType>) | undefined,
        measurements?: { [name: string]: number } | undefined
    ) => {
        if (getVerboseFlag?.() || severityLevel !== AppInsightsSeverityLevel.Verbose) {
            localClient().trace(message, {
                ...properties,
                ...measurements,
                severityLevel: translateSeverityLevel(severityLevel),
            });
        }
    };

    const trackTraceWithContext = (
        message: string,
        severityLevel?: AppInsightsSeverityLevel,
        context?: { [name: string]: string },
        properties?: BaseProperties & Partial<propsType>,
        measurements?: { [name: string]: number }
    ) => {
        localClient().trace(message, {
            ...context,
            ...properties,
            ...measurements,
            severityLevel: translateSeverityLevel(severityLevel),
        });
    };

    const trackTraceAndException = (
        exception: Error,
        message: string,
        severityLevel?: AppInsightsSeverityLevel,
        handledAt?: string,
        properties?: BaseProperties,
        measurements?: { [name: string]: number }
    ) => {
        localClient().trace(message, {
            ...properties,
            ...measurements,
            severityLevel: translateSeverityLevel(severityLevel),
        });
        localClient().exception(message, {
            exception,
            ...properties,
            ...measurements,
            handledAt,
            severityLevel: translateSeverityLevel(severityLevel),
        });
    };

    const trackException = (
        exception: Error,
        handledAt?: string,
        properties?: BaseProperties,
        measurements?: { [name: string]: number },
        severityLevel?: AppInsightsSeverityLevel
    ) => {
        localClient().exception(exception.name, {
            ...properties,
            ...measurements,
            exception,
            handledAt,
            severityLevel: translateSeverityLevel(severityLevel),
        });
    };

    // This doesn't currently do anything (other than capture 'this').
    const startTrackEvent = (name: string) => {
        trackEvent(name);
        localClient().startTrackEvent(name);
    };

    const stopTrackEvent = (name: string, properties?: BaseProperties, measurements?: { [name: string]: number }) => {
        const combined = { ...properties, ...measurements };
        localClient().stopTrackEvent(name, combined);
    };

    const trackPageView = (name: string, properties?: BaseProperties) =>
        localClient().pageView({ name, ...properties });

    return {
        trackEvent,
        trackException,
        startTrackEvent,
        stopTrackEvent,
        trackEventWithContext,
        trackPageView,
        trackTraceWithContext,
        trackTrace,
        trackTraceAndException,
        localClient,
    };
}
