import * as React from 'react';
/**
 * @internal
 * Used to provide a CSS class that applies theme css variables
 *
 * Useful for elements in the React tree (can read context) but not in the DOM Tree. E.g. Portals
 */ const ThemeClassNameContext = React.createContext(undefined);
const themeClassNameContextDefaultVaue = '';
export const ThemeClassNameProvider = ThemeClassNameContext.Provider;
/**
 * @returns CSS class that applies css variables
 */ export function useThemeClassName() {
    var _React_useContext;
    return (_React_useContext = React.useContext(ThemeClassNameContext)) !== null && _React_useContext !== void 0 ? _React_useContext : themeClassNameContextDefaultVaue;
}
