import React from 'react';
import { Tab, TabList } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';

import type { DataProfileTimeRange } from '../DataExplorationApi';
import { useStrings } from '../DataExplorationModal/hooks/useStrings';

export interface TimeRangePickerProps {
    selectedRange: DataProfileTimeRange;
    setSelectedRange: (range: DataProfileTimeRange) => void;
    allowedRanges: string[];
    allowCustom?: boolean;
    disabled?: boolean;
}

function timeRangeToTabValue(range: DataProfileTimeRange) {
    if (range.type === 'filterDisabled') {
        return 'filterDisabled';
    }
    if (range.type === 'timespanLiteralAgo') {
        return range.timespanLiteral;
    }
    return 'custom';
}

export const TimeRangePicker: React.FC<TimeRangePickerProps> = observer(function TimeRangePicker({
    allowedRanges,
    selectedRange,
    disabled,
    setSelectedRange,
}) {
    const { strings } = useStrings();
    const selectedValue = timeRangeToTabValue(selectedRange);

    return (
        <TabList
            disabled={disabled}
            size="small"
            selectedValue={selectedValue}
            onTabSelect={(_, { value }) => {
                if (value === 'filterDisabled') {
                    setSelectedRange({ type: 'filterDisabled' });
                } else {
                    setSelectedRange({ type: 'timespanLiteralAgo', timespanLiteral: value as string });
                }
            }}
        >
            {allowedRanges.map((agoVal) => (
                <Tab key={agoVal} value={agoVal}>
                    {agoVal}
                </Tab>
            ))}
            <Tab key="maxTime" value="filterDisabled" style={{ pointerEvents: 'auto' }}>
                {strings.filters.filterValueMax}
            </Tab>
        </TabList>
    );
});
