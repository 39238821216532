import type { RenderInfo } from '@kusto/monaco-kusto';
import { saveAs } from 'file-saver';
import * as mobx from 'mobx';
import { editor, Range } from 'monaco-editor/esm/vs/editor/editor.api';

import { initWorker, initWorkerFromModel } from '@kusto/kusto-schema';
import { unifyWhiteSpaces } from '@kusto/utils';

import type { QueryCore } from '../../core/core';
import { getEndOfQuery } from './lib';

export type PasteLocation = 'cursorPosition' | 'appendAtEndOfCurrentCommand';

export class KustoEditorHandle {
    private cancelRecall?: () => void;
    private readonly telemetry;
    constructor(
        private readonly core: QueryCore,
        private readonly abortSignal: AbortSignal,
        public readonly monacoEditor: editor.IStandaloneCodeEditor,
        /**
         * Returns `undefined` if there's nothing to flush
         */
        public readonly flushEditorSync: () => undefined | Promise<void>
    ) {
        mobx.makeObservable(this, { duplicateQuery: mobx.action });
        this.telemetry = core.telemetry.bind({ component: 'KustoEditorHandle' });

        abortSignal.addEventListener('abort', () => this.cancelRecall?.());
    }

    saveContentAs(filename: string) {
        const currentModel = this.monacoEditor.getModel();
        if (!currentModel) {
            return;
        }
        const blob = new Blob([currentModel.getValue()], {
            type: 'text/plain;charset=utf-8',
        });
        saveAs(blob, filename);
    }
    async getRenderInfo(): Promise<RenderInfo | null> {
        const model = this.monacoEditor.getModel();
        if (!model) {
            return null;
        }

        const worker = await initWorker(this.monacoEditor);

        if (!worker) {
            return null;
        }

        const position = this.monacoEditor.getPosition();
        if (!position) {
            return null;
        }
        const offset = model.getOffsetAt(position);
        const renderInfo = await worker.getRenderInfo(model.uri.toString(), offset);

        if (!renderInfo) {
            return null;
        }

        return renderInfo;
    }
    triggerQuickCommand(): void {
        this.monacoEditor.focus();
        this.monacoEditor.trigger('triggerQuickCommand', 'editor.action.quickCommand', {});
    }
    setPositionAtEndOfLine(): undefined {
        const selection = this.monacoEditor.getSelection();
        const model = this.monacoEditor.getModel();
        if (!model || !selection) {
            return;
        }
        const startLineNumber = selection.startLineNumber;
        const column = model.getLineMaxColumn(startLineNumber) + 1;
        this.monacoEditor.setPosition({ lineNumber: startLineNumber, column });
    }

    async pasteText(textToPaste: string, pasteLocation: PasteLocation = 'cursorPosition'): Promise<void> {
        textToPaste = unifyWhiteSpaces(textToPaste);

        const currentCursorPosition = this.monacoEditor.getSelection();
        const endOfQueryPosition = await getEndOfQuery(this.monacoEditor);
        const range: Range | null = pasteLocation === 'cursorPosition' ? currentCursorPosition : endOfQueryPosition;

        if (!range) {
            return;
        }

        // Ensure the cursor is at the end of the current command before pasting if pasteLocation is set to 'appendAtEndOfCurrentCommand' and the current cursor position is not already at the end.
        if (endOfQueryPosition && pasteLocation === 'appendAtEndOfCurrentCommand') {
            const { endLineNumber: lineNumber, endColumn: column } = endOfQueryPosition;
            this.monacoEditor.setPosition({ lineNumber, column });
        }

        this.monacoEditor.executeEdits('pasteFromConnectionPane', [
            {
                text: textToPaste,
                forceMoveMarkers: true,
                range,
            },
        ]);

        // Ensure the editor's position is revealed in the center of the viewport after pasting text
        const position = this.monacoEditor.getPosition();
        if (position) {
            this.monacoEditor.revealPositionInCenterIfOutsideViewport(position);
        }

        this.monacoEditor.focus();
    }
    duplicateQuery() {
        this.core.telemetry.trace('ActionBar.DuplicateQuery');
        const tab = this.core.store.tabs.tabInContext;
        const command = tab.commandInContext;
        if (command && command.length > 0) {
            // command.trimEnd in order to avoid pasting also the double new line character at its end
            this.core.kustoEditor.ref?.pasteText('\n\n' + command.trimEnd(), 'appendAtEndOfCurrentCommand');
        }
    }
    async logDiagnostics() {
        const model = this.monacoEditor.getModel();
        if (!model) {
            return;
        }

        const position = this.monacoEditor.getPosition();
        if (!position) {
            return;
        }

        const offset = model.getOffsetAt(position);

        if (this.abortSignal.aborted) {
            return;
        }
        const worker = await initWorkerFromModel(model);
        if (this.abortSignal.aborted) {
            return;
        }
        // Logging query recommendations, regardless of the feature flag
        const diagnostics = await worker?.doValidation(
            model.uri.toString(),
            [{ start: offset, end: offset + 1 }],
            true,
            true
        );
        if (this.abortSignal.aborted) {
            return;
        }
        const queryRecommendationCodes = diagnostics
            ?.filter((dia) => dia.severity === 2 || dia.severity === 3)
            .map((dia) => dia.code);
        this.telemetry.info('Query recommendations', {
            diagnostics: JSON.stringify(queryRecommendationCodes),
            count: String(queryRecommendationCodes?.length),
        });
    }
}
