.scrollableContent {
    align-self: stretch;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: var(--colorNeutralBackground1);
    }
    &::-webkit-scrollbar-thumb {
        background: var(--colorNeutralForegroundDisabled);
        border-radius: var(--borderRadiusCircular);
    }
    // scroll-bar Firefox
    scrollbar-color: var(--colorNeutralForegroundDisabled) var(--colorNeutralBackground1);
    scrollbar-width: thin;
}

.scrollableSpacing {
    padding: 0 var(--spacingHorizontalXS) 0 var(--spacingHorizontalL);
    margin-right: var(--spacingHorizontalXS);
}
