import React from 'react';
import { Stack } from '@fluentui/react';
import { Text } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';

import { KweVisualFwkLocale } from '../../types';
import { IDataVisualProps } from '../../visualConfig';
import { ColorRule, VisualOptionProperties } from '../../visualOptions';
import { ColorRulePreviewColor, formatConditions } from './ColorRulesPanel/utils';
import { conditionalFormattingColors, themeValues } from './constants';
import { ColorRulesVisualType } from './filterRules';

import * as styles from './useColorRulesMessages.module.scss';

function formatRuleLabel(label: string, value: string) {
    return (
        <span>
            {label}: <strong>{value}</strong>
        </span>
    );
}

interface ColorRuleRowProps {
    t: KweVisualFwkLocale;
    isDarkTheme: boolean;
    rule: ColorRule.UColorRule;
    visualType: ColorRulesVisualType;
}

const ColorRuleRow: React.FC<ColorRuleRowProps> = observer(function ColorRuleRow({ t, visualType, isDarkTheme, rule }) {
    const ruleTypeValue =
        rule.ruleType === 'colorByCondition'
            ? t.visualFwk.visualConfig.colorRules.colorByConditionOption
            : t.visualFwk.visualConfig.colorRules.colorByValueOption;
    const { bold: boldColors, light: lightColors } = conditionalFormattingColors[isDarkTheme ? 'dark' : 'light'];
    const themeColors = themeValues[isDarkTheme ? 'dark' : 'light'];
    let colorName;
    if (rule.ruleType === 'colorByCondition') {
        colorName = rule.color
            ? t.visualFwk.visuals.colorRules.colorLabels[rule.color]
            : t.visualFwk.visualConfig.colorRules.noSelectionText;
    } else {
        colorName = rule.themeName
            ? t.visualFwk.visualConfig.colorRules.themeDropdownOptionsText[rule.themeName]
            : t.visualFwk.visualConfig.colorRules.infoTooltipConditionalFormattingRandomValue;
    }

    return (
        <div className={styles.colorRuleRow}>
            <Stack tokens={{ childrenGap: 4 }}>
                {rule.ruleName !== '' &&
                    rule.ruleName !== null &&
                    formatRuleLabel(t.visualFwk.visualConfig.colorRules.ruleNameLabel, rule.ruleName)}
                {formatRuleLabel(t.visualFwk.visualConfig.colorRules.ruleTypeLabel, ruleTypeValue)}
            </Stack>
            {rule.ruleType === 'colorByCondition' && (
                <div>
                    {formatRuleLabel(
                        t.visualFwk.visualConfig.colorRules.conditionsSectionTitle,
                        formatConditions(t, rule, visualType)
                    )}
                </div>
            )}
            <div className={styles.indicatorsContainer}>
                <ColorRulePreviewColor
                    boldColors={boldColors}
                    lightColors={lightColors}
                    colorsByTheme={themeColors}
                    rule={rule}
                />
                <Text size={200} className={styles.ruleConfigText}>
                    {colorName}
                </Text>
            </div>
        </div>
    );
});

interface ColorRulesProps {
    t: KweVisualFwkLocale;
    isDarkTheme: boolean;
    colorRules: VisualOptionProperties['colorRules'];
    visualType: ColorRulesVisualType;
}

const ColorRules: React.FC<ColorRulesProps> = observer(function ColorRules({ t, visualType, isDarkTheme, colorRules }) {
    return (
        <div className={styles.rulesList}>
            {colorRules.map((rule) => (
                <ColorRuleRow key={rule.id} t={t} isDarkTheme={isDarkTheme} rule={rule} visualType={visualType} />
            ))}
        </div>
    );
});

export function useColorRulesMessages(
    t: KweVisualFwkLocale,
    visualType: ColorRulesVisualType,
    addMessage: IDataVisualProps['addMessage'],
    colorRules: VisualOptionProperties['colorRules'],
    colorRulesDisabled: VisualOptionProperties['colorRulesDisabled'],
    isDarkTheme: boolean
) {
    React.useLayoutEffect(() => {
        if (colorRules.length !== 0 && !colorRulesDisabled) {
            return addMessage({
                level: 'info',
                title: t.visualFwk.visualConfig.colorRules.dialogTitle,
                message: <ColorRules isDarkTheme={isDarkTheme} t={t} colorRules={colorRules} visualType={visualType} />,
            });
        }
    }, [t, addMessage, colorRules, colorRulesDisabled, isDarkTheme, visualType]);
}
