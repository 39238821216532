/**
 * JSON.stringify without throwing.
 */
export const safeStringify = (value: unknown): string => {
    try {
        return JSON.stringify(value);
    } catch (e: unknown) {
        return '';
    }
};

/**
 * Stringify a value, similar to `JSON.stringify`, but including non-enumerable properties, so it can
 * stringify objects like `Error`. It supports a variety of other values, such as `null` or `undefined`, but
 * not weird things like `Map` or `Set` or `function`.
 *
 * @param value the value to stringify
 * @returns a string representation of the value, including non-enumerable properties
 */
export function safeStringifyIncludingNonEnumerables(value: unknown): string {
    try {
        if (value === undefined) {
            return 'undefined';
        } else if (value === null) {
            return 'null';
        } else if (typeof value === 'object') {
            const sameObjectButAllPropertiesAreEnumerable = Object.getOwnPropertyNames(value).reduce(
                (copyObjectWithEnumerables, n) => {
                    //@ts-expect-error not sure why this is an error
                    copyObjectWithEnumerables[n] = value[n];
                    return copyObjectWithEnumerables;
                },
                {} as Record<string, unknown>
            );
            return JSON.stringify(sameObjectButAllPropertiesAreEnumerable);
        } else {
            return safeStringify(value);
        }
    } catch {
        return '<couldnotstringify>';
    }
}
