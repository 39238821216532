import { useId } from '@fluentui/react-utilities';
import { useTabsterAttributes } from './useTabsterAttributes';
import { getModalizer, getRestorer, RestorerTypes } from 'tabster';
import { useTabster } from './useTabster';
/**
 * Applies modal dialog behaviour through DOM attributes
 * Modal element will focus trap and hide other content on the page
 * The trigger element will be focused if focus is lost after the modal element is removed
 *
 * @returns DOM attributes to apply to the modal element and its trigger
 */ export const useModalAttributes = (options = {})=>{
    const { trapFocus, alwaysFocusable, legacyTrapFocus } = options;
    const tabster = useTabster();
    // Initializes the modalizer and restorer APIs
    if (tabster) {
        getModalizer(tabster);
        getRestorer(tabster);
    }
    const id = useId('modal-', options.id);
    const modalAttributes = useTabsterAttributes({
        restorer: {
            type: RestorerTypes.Source
        },
        ...trapFocus && {
            modalizer: {
                id,
                isOthersAccessible: !trapFocus,
                isAlwaysAccessible: alwaysFocusable,
                isTrapped: legacyTrapFocus && trapFocus
            }
        }
    });
    const triggerAttributes = useTabsterAttributes({
        restorer: {
            type: RestorerTypes.Target
        }
    });
    return {
        modalAttributes,
        triggerAttributes
    };
};
