import range from 'lodash/range';

import * as client from '@kusto/client';
import { DataFrame, Field, FieldSchema, KustoDataType, UField, UnknownDataFrameValue } from '@kusto/utils';

import type { TableResult } from '../agGrid/AgGridWithKustoData';
import type { Columns, Rows } from '../types';

/**
 * Convert row from the kusto client to the @kusto/visualization legacy format
 *
 * @deprecated Prefer working in @kusto/utils's "DataFrame" format.
 */
function clientRowToVRow(
    row: client.ValueRow,
    columns: readonly { ColumnName: string; ColumnType: string; DataType?: string }[]
): client.KustoQueryResultRowObject {
    // Added while enabling lints
    // eslint-disable-next-line @typescript-eslint/ban-types
    return row.reduce((rowAsObject: { [key: string]: unknown }, val, index): {} => {
        let convertedVal: unknown = '';
        if (val === null || val === undefined) {
            convertedVal = val as null;
        } else if (typeof val === 'object') {
            convertedVal = JSON.stringify(val);
        } else {
            convertedVal =
                // in API v1 the value sent over the wire for a boolean is 1 with datatype SByte.
                // Here we convert it to a proper boolean.
                columns[index].DataType === 'SByte' && columns[index].ColumnType === 'bool' ? !!val : val;
        }
        rowAsObject[columns[index].ColumnName] = convertedVal;
        return rowAsObject;
    }, {});
}

/**
 * Convert rows from the kusto client to the @kusto/visualization legacy format
 *
 * @deprecated Prefer working in @kusto/utils's "DataFrame" format.
 */
function clientRowToVRows(rowsAsArrays: readonly client.ValueRow[], columns: readonly client.ColumnV1[]) {
    return rowsAsArrays.map((row) => clientRowToVRow(row, columns));
}

/**
 * Kusto result set can have names. Names can be provided by using the "as" operator.
 * for example, the following query:
 *     StormEvents | take 10 | as StormEventsSample;
 *     Github | take 10 | as GithubSample;
 * will produce two results sets, with the names StormEventsSample and GithubSample.
 * These names are shown in the UX.
 * This function returns the names of the primary results sets.
 * @param rawQueryResults the raw query result
 * @returns an array of pretty names, one for each result in the batch. null if no name is set.
 */
function getQueryResultPrettyNames(rawQueryResults: client.KustoClientResult): readonly (string | null)[] {
    const prettyNameIndex = 4;
    const kindIndex = 1; // the kind column in the TOC result table.

    // If there's only one table, this is probably a control command and it's the only primary result.
    // otherwise, the last table will be the TOC and we can extract the number of query results from it.
    if (rawQueryResults.Tables.length === 1) {
        return [null];
    }

    // last table is TOC
    const tocResultTable = rawQueryResults.Tables[rawQueryResults.Tables.length - 1].Rows as readonly client.ValueRow[];
    const queryResultsToc = tocResultTable.filter((row) => row[kindIndex] === 'QueryResult');
    return queryResultsToc.map((row) => row[prettyNameIndex] as string);
}

/**
 * Translate raw results from raw Kusto json to @kusto/visualization options legacy format.
 * @param rawQueryResults raw query results from kusto endpoint.
 *
 * @deprecated Prefer working in @kusto/utils's "DataFrame" format.
 */
export const kustoResultV1ToVTable = (rawQueryResults: client.KustoClientResult): TableResult[] => {
    const prettyNames = getQueryResultPrettyNames(rawQueryResults);

    const numberOfPrimaryResults = prettyNames?.length;

    const results: TableResult[] = range(0, numberOfPrimaryResults).map((i): TableResult => {
        const columnsAndTypes = rawQueryResults.Tables[i].Columns;
        const rowsAsArrays = rawQueryResults.Tables[i].Rows;
        const tableName = prettyNames[i];

        // convert an array of arrays to an array of objects
        const rows = clientRowToVRows(rowsAsArrays as client.ValueRow[], columnsAndTypes);

        const columns = columnsAndTypes.map((nameAndType) => ({
            headerName: nameAndType.ColumnName,
            field: nameAndType.ColumnName,
            dataType: nameAndType.DataType,
            columnType: nameAndType.ColumnType,
        }));

        const visualizationOptions = getVisualizationOptions(i, numberOfPrimaryResults, rawQueryResults);

        return {
            rows,
            columns,
            visualizationOptions,
            tableName,
        };
    });
    return results;
};

/**
 * Translate raw results from raw Kusto json to legacy @kusto/visualization format.
 * @param rawQueryResults raw query results from kusto endpoint.
 *
 * @deprecated Prefer working in @kusto/utils's "DataFrame" format.
 */
// TODO: this logic should be in the kusto client, otherwise,
// any part running queries won't parse them properly for the grid
export function kustoResultV2ToResultTable(rawQueryResults: client.KustoClientResultV2): {
    results: TableResult[];
    queryResourceConsumption?: client.QueryResourceConsumptionData;
    errorDescription?: client.KustoClientErrorDescription;
} {
    // type guard for a data table frame.
    const isDataTable = (x: client.Frame): x is client.DataTable => x.FrameType === 'DataTable';
    const isDataSetCompletion = (x: client.Frame): x is client.DataSetCompletion => x.FrameType === 'DataSetCompletion';
    const dataSetCompletion = rawQueryResults.filter(isDataSetCompletion)[0];
    let errorDescription: undefined | client.KustoClientErrorDescription = undefined;
    if (dataSetCompletion && dataSetCompletion.HasErrors) {
        const rawError = dataSetCompletion.OneApiErrors[0];
        errorDescription = client.extractErrorDescription(rawError);
    }

    // If there's only one table, this is probably a control command and it's the only primary result.
    // otherwise, the last table will be the TOC and we can extract the number of query results from it.
    const dataTableFrames = rawQueryResults.filter(isDataTable);
    const primaryResults = dataTableFrames.filter((dt) => dt.TableKind === 'PrimaryResult');
    const queryPropertiesFrame = dataTableFrames.filter((frame) => frame.TableKind === 'QueryProperties')[0];
    const queryResourceConsumption = client.getQueryResourceConsumption(dataTableFrames) ?? undefined;

    const visualizationOptionsMapping = parseVisualizationOptionsV2(queryPropertiesFrame);

    const results = primaryResults.map((primaryResult, _i): TableResult => {
        const tableId = primaryResult.TableId;
        const columnsAndTypes = primaryResult.Columns;
        const rowsAsArrays = primaryResult.Rows;
        const tableName = primaryResult.TableName;

        // convert an array of arrays to an array of objects
        const rows = rowsAsArrays
            .map((row) => {
                // if this isn't an array, it can be an error (such as query limits exceeded).
                if (!Array.isArray(row)) {
                    const rawError = row.OneApiErrors[0];
                    errorDescription = client.extractErrorDescription(rawError);
                    return undefined;
                }

                return clientRowToVRow(row, columnsAndTypes);
            })
            .filter((row) => row !== undefined) as client.KustoQueryResultRowObject[];

        const columns = columnsAndTypes.map((nameAndType) => ({
            headerName: nameAndType.ColumnName,
            field: nameAndType.ColumnName,
            columnType: nameAndType.ColumnType,
        }));

        const visualizationOptions = visualizationOptionsMapping[tableId] ?? client.EmptyVisualizationOptionsSnapshot;

        return {
            rows,
            columns,
            visualizationOptions,
            tableName,
        };
    });

    return { results, errorDescription, queryResourceConsumption };
}

/**
 * Get visualization options for result i in API v1
 * @param i table index
 * @param numberOfPrimaryResults number of primary results
 * @param queryResults kusto result
 *
 * @deprecated Prefer working in @kusto/utils's "DataFrame" format.
 */
function getVisualizationOptions(i: number, numberOfPrimaryResults: number, queryResults: client.KustoClientResult) {
    let visualizationOptions = client.EmptyVisualizationOptionsSnapshot;
    // currently we only get visualization data to for the last primary result.
    if (i === numberOfPrimaryResults - 1) {
        // Extract visualization data. it is the first table after primary results
        // if we got a visualization table as a second result
        if (queryResults.Tables.length > numberOfPrimaryResults) {
            // it might be a control command without any visualization info and we're just reading the wrong field.
            try {
                // only a single cell with json string
                const visualizationDataString: string = (
                    queryResults.Tables[numberOfPrimaryResults].Rows[0] as client.ValueRow
                )[0] as string;
                const visualizationDataObject = JSON.parse(visualizationDataString);
                visualizationOptions = visualizationDataObject;
            } catch {
                // do nothing
            }
        }
    }
    return visualizationOptions;
}

/**
 * parse visualization options for API v2
 * @param queryPropertiesFrame A Kusto DataTable. 
 * @example queryPropertiesFrame:
 * {
        "FrameType": "DataTable",
        "TableId": 0,
        "TableKind": "QueryProperties",
        "TableName": "@ExtendedProperties",
        "Columns": [
            {
                "ColumnName": "TableId",
                "ColumnType": "int"
            },
            {
                "ColumnName": "Key",
                "ColumnType": "string"
            },
            {
                "ColumnName": "Value",
                "ColumnType": "dynamic"
            }
        ],
        "Rows": [
            [
                1,
                "Visualization",
                "{\"Visualization\":\"barchart\",\"Title\":null,\"XColumn\":null,\"Series\":null,\"YColumns\":null,\"AnomalyColumns\":null,\"XTitle\":null,\"YTitle\":null,\"XAxis\":null,\"YAxis\":null,\"Legend\":null,\"YSplit\":null,\"Accumulate\":false,\"IsQuerySorted\":false,\"Kind\":null,\"Ymin\":\"NaN\",\"Ymax\":\"NaN\"}"
            ]
        ]
    },
 */
function parseVisualizationOptionsV2(queryPropertiesFrame: client.DataTable): {
    [tableId: string]: client.VisualizationOptions;
} {
    const tableIdIndex = 0;
    const keyIndex = 1;
    const valueIndex = 2;

    if (!queryPropertiesFrame || !queryPropertiesFrame.Rows) {
        return {};
    }

    // Added while enabling lints
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rows = queryPropertiesFrame.Rows as { [key: string]: any }[];
    return rows
        .filter((row) => row[keyIndex] === 'Visualization')
        .reduce((visualizations, row) => {
            const tableId = row[tableIdIndex];
            let value = row[valueIndex];
            if (typeof value === 'string') {
                value = JSON.parse(value) as client.VisualizationOptions;
            }
            visualizations[`${tableId}`] = value;
            return visualizations;
        }, {});
}

export function vColumnToKweColumn(column: client.KustoColumn): FieldSchema {
    return {
        name: column.headerName,
        type: client.kweDataTypeFromExtended(column.columnType),
    };
}

/**
 * Converts from kwe format (@kusto/utils) to query area (@kusto/query) package
 * format.
 *
 * NOTE: @kusto/query area table format keeps all dynamic values in string form,
 * so we can't tell what their original type was.
 *
 * We JSON.parse dynamic strings that are valid json object or arrays so dynamic
 * objects are preserved, but, this means if the string was always a string,
 * we've changed its type.
 *
 * This is a similar issue to the app insights bugs, so we don't need to worry
 * about those issues (see ./client.ts#tryRepairAppInsightsDynamic), I think?
 *
 *
 * NOTE: Does _not_ currently apply the app insights hack dashboards gets when going strait from a query result to a kwe table
 */
export function kweValueFromVValue(type: KustoDataType, value: UnknownDataFrameValue): UnknownDataFrameValue {
    if (type === 'dynamic') {
        // If it obviously wasn't a dynamic array or object, skip
        // `JSON.parse` so we don't pay the cost of throwing an error
        //
        // Strings containing valid numbers shouldn't be JSON.parse'd
        // because that will round "long" values
        if (typeof value !== 'string' || (value[0] !== '[' && value[0] !== '{')) {
            return value;
        }

        try {
            return JSON.parse(value);
        } catch {
            // If we're wrong about the dynamic being an object or
            // array, treat as if the heuristic returned false
            return value;
        }
    }
    return client.kweScalarValueFromClientScalarValue(value, type);
}

export function kweRowFromVRow(columns: readonly client.KustoColumn[], row: client.KustoQueryResultRowObject) {
    return columns.map((c) => {
        const val = row[c.field || c.headerName];
        return kweValueFromVValue(c.columnType, val);
    });
}

export function dataFrameFromVColumnsRows(columns: null | Columns, rows: null | Rows): DataFrame {
    if (!columns) {
        if (rows) {
            return { fields: [], size: rows.length };
        }
        return { fields: [], size: 0 };
    }

    const fields: UField[] = columns.map(
        (c) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            new Field<any, any>(
                c.field ?? c.headerName,
                c.columnType,
                rows?.map((r) => kweValueFromVValue(c.columnType, r[c.field || c.headerName])) ?? []
            )
    );

    return { fields, size: rows?.length ?? 0 };
}
