import { AxiosError, AxiosResponse } from 'axios';

export class KustoClientError extends Error implements Pick<AxiosError, 'isAxiosError' | 'response'> {
    exception: unknown;
    isAxiosError: boolean;
    isCancel: boolean;
    clientRequestId: string | undefined;
    response?: AxiosResponse;
    data?: unknown;
    constructor(
        exception: unknown,
        message: string | undefined,
        isAxiosError: boolean,
        isCancel: boolean,
        clientRequestId: string | undefined,
        response?: AxiosResponse,
        data?: unknown
    ) {
        super(message);
        this.isAxiosError = isAxiosError;
        this.isCancel = isCancel;
        this.clientRequestId = clientRequestId;
        this.exception = exception;
        this.response = response;
        this.data = data;
    }
}
