import React from 'react';

import { UiComponentsLocale } from '../types.ts';

const uiComponentsCtx = React.createContext<UiComponentsLocale | undefined>(undefined);

export const UiComponentsStringsProvider: React.FC<{ value: UiComponentsLocale }> = ({ value, children }) => {
    return <uiComponentsCtx.Provider value={value}>{children}</uiComponentsCtx.Provider>;
};

export function useStrings(): UiComponentsLocale {
    const strings = React.useContext(uiComponentsCtx);
    if (!strings) {
        throw new Error('ui-components useStrings must be used within a UiComponentsStringsProvider');
    }
    return strings;
}
