.tab {
    position: relative;
    width: 180px;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    border-radius: var(--borderRadiusMedium);
    padding: var(--spacingVerticalSNudge) var(--spacingHorizontalS);
    overflow: hidden;
    cursor: pointer;

    .tabTextContainer {
        display: flex;
        width: 100%;

        .tabIcon {
            min-width: 20px;
            height: 20px;
            margin-right: var(--spacingHorizontalXS);
        }
    }
    .tabButtons {
        display: none; // hide by default, visible on hover or selected
        align-items: center;

        .tabButtonEdit {
            width: 20px;
            min-width: 20px;
            margin-right: var(--spacingHorizontalXS);
        }
    }
}
.tab:hover,
.selectedTab {
    background: var(--colorNeutralBackground1Hover);

    .tabTextContainer {
        width: calc(100% - 44px);

        .tabIcon {
            color: var(--colorNeutralForeground2BrandSelected);
        }
    }
    .tabButtons {
        display: flex;
    }
}
.selectedTab,
.selectedTab:hover {
    background: var(--colorSubtleBackgroundSelected);
}
.singleTab {
    // single tab don't have close button
    .tabTextContainer {
        // override selected and hover styles
        width: calc(100% - 20px) !important;
    }
}
.editingTab {
    // editing tab edit don't have close button + edit button
    .tabTextContainer {
        // override selected and hover styles
        width: calc(100% - 24px) !important;
    }
}
.editingTab.withoutIcon {
    // editing tab without icon
    .tabTextContainer {
        // override selected and hover styles
        width: 100% !important;
    }
}
