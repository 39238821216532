import last from 'lodash/last';

export const armUtils = {
    resourceToScope: (resource: string) => `${resource}/.default`,

    getResourceGroupFromNamespaceId(namespaceId: string) {
        return namespaceId.split('/')[4];
    },

    getResourceGroupFromStorageAccountId(storageAccountId: string) {
        return storageAccountId.split('/')[4];
    },

    parseClusterResourceId(clusterResourceId?: string) {
        if (clusterResourceId === undefined) {
            return {};
        }
        const splitClusterResourceId = clusterResourceId.split('/');
        return {
            clusterName: last(splitClusterResourceId),
            subscriptionId: splitClusterResourceId[2],
            resourceGroup: splitClusterResourceId[4],
        };
    },

    extractResourceIdsFromEventHubId(eventHubId: string): {
        subscriptionId: string;
        resourceGroup: string;
        namespaceName: string;
        eventHubName: string;
    } {
        // Event Hub id includes subscription id, resource group and namespace
        const resourceFragments = eventHubId.split('/');
        const subscriptionId = resourceFragments[2];
        const resourceGroup = resourceFragments[4];
        const namespaceName = resourceFragments[8];
        const eventHubName = resourceFragments[resourceFragments.length - 1];
        return {
            subscriptionId,
            resourceGroup,
            namespaceName,
            eventHubName,
        };
    },
};
