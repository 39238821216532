import React from 'react';
import { EmptyState, EmptyStateContent } from '@trident/ux-react';

import { DataTableColumn } from '@kusto/client';

import { useSchemaWithInsights } from '../../../DataExplorationApi';
import { useKql } from '../../hooks/useKql';
import { useQueryResults } from '../../hooks/useResults';
import { useStrings } from '../../hooks/useStrings';
import { SchemaColumnsContent } from './SchemaColumnsContent';

import styles from './SchemaColumns.module.scss';

export const SchemaColumns: React.FunctionComponent = () => {
    const { strings } = useStrings();
    const { data } = useQueryResults();
    const computedKql = useKql();

    const columns: DataTableColumn[] | null = React.useMemo(
        () =>
            data?.dataFrame.size
                ? data?.dataFrame.fields.map((field) => ({ ColumnName: field.name, ColumnType: field.type }))
                : null,
        [data?.dataFrame]
    );

    const { data: columnsWithInsights, isLoading } = useSchemaWithInsights(computedKql, columns, {
        type: 'filterDisabled',
    });

    if (!columnsWithInsights?.length) {
        const noDataString = strings.dataExplorationModal.schemaColumns.emptyState.noData;
        return (
            <EmptyState
                emptyStateTitle={noDataString.title}
                className={styles.schemaColumnsEmpty}
                emptyStateType="noItem"
                size="small"
            >
                <EmptyStateContent description={noDataString.description} />
            </EmptyState>
        );
    }

    return <SchemaColumnsContent columns={columnsWithInsights} isLoading={isLoading} />;
};
