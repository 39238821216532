// copied from https://stackoverflow.com/a/46181
export const VALID_EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Create a RegExp object from a regular expression string.
 * @param regexString
 * @param ignoreCase
 * @returns
 */
export function tryCreateRegExp(regexString: string | undefined, ignoreCase: boolean): RegExp | undefined {
    if (!regexString) return undefined;
    try {
        return new RegExp(regexString, ignoreCase ? 'i' : '');
    } catch {
        return undefined;
    }
}
