import * as kusto from '@kusto/client';
import type { DataFrame, KustoDataType } from '@kusto/utils';

import { Columns } from '../types';

/**
 * The first column of the types specified by the dataTypes prioritized array.
 * @param allColumns
 * @param dataTypes an array of arrays data types. the outer array indicates priority, the 2nd array is a set of data types to look for.
 * for example, stat card tries to find a numeric data type as a first priority, and if it does not find one it looks for any numeric type.
 * this will look like [NUMERIC_DATA_TYPE][DataType.string].
 * @param excludedColumnNames columns that shouldn't be considered (for example, they're already selected to be some axis by the user)
 *
 * @deprecated Use {@link getFirstFieldOfType}
 */
export function getFirstColumnOfTypeVFormat(
    allColumns: Columns,
    dataTypes: kusto.ColumnType[][],
    excludedColumnNames: string[]
) {
    if (!allColumns) {
        return null;
    }

    const excluded = new Set(excludedColumnNames);
    // go in order of priority
    for (let index = 0; index < dataTypes.length; index++) {
        const types = dataTypes[index];
        const relevantColumns = allColumns.filter((col) => {
            if (!col.columnType) {
                return false;
            }
            if (excluded.has(col.field)) {
                return false;
            }

            const dataType = col.columnType as kusto.ColumnType;
            return types.includes(dataType);
        });

        if (relevantColumns.length > 0) {
            return relevantColumns[0];
        }
    }
    return null;
}

/**
 * @param dataTypes List of lists so some types can have equal priority
 */
export function getFirstFieldOfType(
    dataFrame: DataFrame,
    dataTypes: readonly (readonly KustoDataType[])[],
    excludedColumnNames: readonly string[]
) {
    // go in order of priority
    for (const types of dataTypes) {
        for (const field of dataFrame.fields) {
            if (excludedColumnNames.includes(field.name)) {
                continue;
            }
            if (types.includes(field.type)) {
                return field;
            }
        }
    }
    return undefined;
}
