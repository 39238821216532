import { DataExplorationModalActions } from './actions';
import { DataExplorationModalState } from './state';

function sourceKQLReducer(sourceKQL: DataExplorationModalState['sourceKql'], action: DataExplorationModalActions) {
    if (action.type === 'OVERRIDE_STATE') {
        return action.payload.sourceKql;
    }
    if (action.type === 'ADD_PIPE') {
        const newState = {
            ...sourceKQL,
            pipes: [...sourceKQL.pipes, action.payload.pipe],
        };
        return newState;
    }

    if (action.type === 'REMOVE_PIPE') {
        if (sourceKQL.pipes.length === 0) {
            return sourceKQL; // this should never happen (maybe we should log something here)
        }

        const newState = {
            ...sourceKQL,
            pipes: sourceKQL.pipes.slice(0, -1), // remove the last pipe
        };
        return newState;
    }

    return sourceKQL;
}

function pillsReducer(pills: DataExplorationModalState['pills'], action: DataExplorationModalActions) {
    if (action.type === 'OVERRIDE_STATE') {
        return action.payload.pills;
    }
    if (action.type === 'ADD_PILL') {
        const newState = [...pills, action.payload.pill];
        return newState;
    }

    if (action.type === 'UPDATE_PILL') {
        const index = action.payload.pillIndex;
        const newPill = action.payload.pill;

        const newState = [...pills.slice(0, index), newPill, ...pills.slice(index + 1)];
        return newState;
    }

    if (action.type === 'REMOVE_PILL') {
        if (pills.length === 0) {
            return pills; // this should never happen (maybe we should log something here)
        }

        const newState = pills.slice(0, -1); // remove the last pill
        return newState;
    }

    return pills;
}

function visualReducer(state: DataExplorationModalState['visual'], action: DataExplorationModalActions) {
    if (action.type === 'OVERRIDE_STATE') {
        return action.payload.visual;
    }
    if (action.type === 'CHANGE_VISUAL_TYPE' && state.type !== action.payload.type) {
        return action.payload;
    }

    return state;
}

export function reducer(srcState: DataExplorationModalState, action: DataExplorationModalActions) {
    const newState: DataExplorationModalState = {
        sourceKql: sourceKQLReducer(srcState.sourceKql, action),
        pills: pillsReducer(srcState.pills, action),
        visual: visualReducer(srcState.visual, action),
    };

    return newState;
}
