.defaultMessageFormatter {
    // Sizing if parent is display: grid
    place-self: stretch stretch;

    min-height: 48px;
    max-height: 100%;
    overflow: auto;
    white-space: pre-wrap;

    // Default message bar actions uses `flex-direction: row-reverse` to left
    // align actions, but, I don't think they tested it because the spacing and
    // order of buttons are messed up
    :global(.ms-MessageBar-actions) {
        flex-direction: row;
        justify-content: right;
    }
}
