import React from 'react';
import {
    Label,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    useId,
} from '@fluentui/react-components';

import { useDataExplorationContext } from '../../..';
import { useDataExplorationStore } from '../../store/DataExplorationStore';

import styles from './Chart.module.scss';

export const VisualTypeMenu: React.FC = () => {
    const { t, parsedVisuals, visualsLayout } = useDataExplorationContext();
    const {
        state: {
            visual: { type: selectedVisual },
        },
        actions,
    } = useDataExplorationStore();
    const pickerLabelId = useId('visual-type-picker');

    return (
        <div className={styles.typePickerContainer}>
            <Label id={pickerLabelId}>{t.dataExploration.dataExplorationModal.chart.visualTypePickerLabel}</Label>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <MenuButton aria-labelledby={pickerLabelId}>{parsedVisuals[selectedVisual]?.label}</MenuButton>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        {visualsLayout.map((option) => {
                            if (typeof option === 'string') {
                                return (
                                    <MenuItem key={option} onClick={() => actions.changeVisualType(option)}>
                                        {parsedVisuals[option]?.label}
                                    </MenuItem>
                                );
                            }
                            return (
                                <Menu key={option.default}>
                                    <MenuTrigger>
                                        <MenuItem>{parsedVisuals[option.default]?.label}</MenuItem>
                                    </MenuTrigger>
                                    <MenuPopover>
                                        <MenuList>
                                            {option.variants.map((variant) => (
                                                <MenuItem
                                                    key={variant}
                                                    onClick={() => actions.changeVisualType(variant)}
                                                >
                                                    {parsedVisuals[variant]?.label}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </MenuPopover>
                                </Menu>
                            );
                        })}
                    </MenuList>
                </MenuPopover>
            </Menu>
        </div>
    );
};
