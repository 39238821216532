@use '@kusto/style/tokens';

.messageBar {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-self: stretch;
    background-color: tokens.$colorNeutralBackground4;
    border: 1px solid tokens.$colorNeutralStroke2;
    align-items: start;
    box-sizing: border-box;
    position: relative;
    border-radius: tokens.$borderRadiusMedium;
}

.icon {
    height: 20px;
    flex: 0 0 20px;
    margin: 0;
    margin: tokens.$spacingVerticalSNudge 0 tokens.$spacingVerticalSNudge tokens.$spacingHorizontalMNudge;
}

.content {
    min-width: 0;
    flex: 1 1 auto;
    display: flex;
    gap: tokens.$spacingHorizontalS;
    padding: tokens.$spacingVerticalS tokens.$spacingHorizontalS;
}

.title {
    font-weight: tokens.$fontWeightSemibold;
}

.collapseExpand {
    flex: 0 0 24px;
    justify-self: flex-end;
    margin: tokens.$spacingVerticalXS tokens.$spacingHorizontalMNudge tokens.$spacingVerticalXS 0;
}
