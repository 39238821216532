import { EntityType, Table } from '../../../common';
import { Database, QueryStoreEnv } from '../../../stores';
import { ITreeEntityMapper } from '../actions';
import { RowDataType } from '../RowDataType';
import { getBaseRootPath } from '../utils';
import { iconMapper } from './IconMapper';
import { RowDataTypeBase } from './RowDataTypeBase';
import { getFromCacheOrCreate } from './RowDataTypeCache';

export class TableRowDataType extends RowDataTypeBase {
    private constructor(table: Table, database: Database, baseRootPath: string[], treeEntityMapper: ITreeEntityMapper) {
        super(
            table.id,
            TableRowDataType.buildTableFolderPath(baseRootPath, table, treeEntityMapper),
            table.name,
            table.entityType,
            database,
            iconMapper[table.entityType],
            treeEntityMapper
        );

        this.info = table.docstring;
        this.actions = treeEntityMapper.getActions(this.entityType, false, this.info);
    }

    public static buildTableFolderPath(rootPath: string[], table: Table, entityMapper: ITreeEntityMapper): string[] {
        const pathFromRoot: string[] = [];

        if (table.folder) {
            const folderPath = table.folder ? table.folder.split(/[\\/]/g).filter((f) => f) : [];
            pathFromRoot.push(...rootPath, entityMapper.getNodeName(table.entityType), ...folderPath, table.name);
        } else if (table.entityType !== EntityType.Table) {
            // External Tables or Materialized Views that has no folder will be grouped
            // under the root folders External Tables and Materialized Views respectively.
            pathFromRoot.push(...rootPath, entityMapper.getNodeName(table.entityType), table.name);
        } else {
            // Regular tables without a folder will be shown under the database.
            pathFromRoot.push(...rootPath, table.name);
        }

        return pathFromRoot;
    }

    public static fromCacheOrCreate(
        env: QueryStoreEnv,
        table: Table,
        database: Database,
        entityMapper: ITreeEntityMapper
    ): RowDataType {
        return getFromCacheOrCreate(
            database,
            table.id,
            () => new TableRowDataType(table, database, getBaseRootPath(env, database), entityMapper)
        );
    }
}
