import * as kusto from '@kusto/client';
import { sortTableByDate } from '@kusto/visualizations';

import { getQueryInContextHtml } from '../components/KustoEditor';
import { chartResultContainerId } from '../components/QueryResults/QueryResultVisualization.tsx';
import type { QueryCore } from '../core/core';
import type { QueryStoreEnv } from '../stores';
import { MultiTableResult } from '../stores/queryCompletionInfo';
import { QueryContext, Tab } from '../stores/tab';
import { generateChartHtml, generateErrorHtml, generateQueryResultsHtml } from './clipboardHelper';

const generateQueryAndResultsHtml = (
    core: QueryCore,
    tableResults?: MultiTableResult[],
    errorMessage?: string,
    isChart?: boolean
): string => {
    const { id, queryTextInContext, queryRange } = core.store.tabs.tabInContext;
    const queryHtmlContext = {
        editorModelPath: id,
        queryRange,
        queryText: queryTextInContext,
    };
    let html = getQueryInContextHtml(queryHtmlContext);
    if (errorMessage) {
        const errorHtml = generateErrorHtml(errorMessage);
        html += errorHtml;
    } else if (isChart) {
        // if we have a chart, only copy the chart
        let chartHtml = '<br/><br/>';
        chartHtml += generateChartHtml(core.store.tabs.tabInContext.canvasToPaste);
        html += chartHtml;
    } else if (tableResults && tableResults.length > 0) {
        const tablesHtml = generateResultHtml(tableResults);
        html += tablesHtml;
    }
    return html;
};

// This method called when the user open the "Copy-Menu" and select copy option - with results
function generateResultHtml(tableResults: MultiTableResult[]) {
    let tablesHtml = '';
    tableResults.forEach((tableResult, i) => {
        // isChart is null for tables / false for tables that are dataset for charts
        if (tableResult.isChart !== true) {
            const sortedTable = sortTableByDate(tableResult);
            const tableHtml = generateQueryResultsHtml(sortedTable, true, i);
            tablesHtml += tableHtml;
        }
    });
    return tablesHtml;
}

export function copyLinkToClipboard(env: QueryStoreEnv, tab: Tab) {
    if (tab && tab.commandInContext) {
        env.config.actions?.onCopyLink?.(tab.queryContext);
    }
}

export function copyQueryToClipboard(
    core: QueryCore,
    queryContext: QueryContext,
    tableResults?: MultiTableResult[],
    errorMessage?: string,
    isChart?: boolean
): void {
    const queryAsHTML = generateQueryAndResultsHtml(core, tableResults, errorMessage, isChart);
    const queryAsText = queryContext.query + (errorMessage ?? '');

    core.config.actions?.onCopyQuery?.(queryContext, queryAsHTML, queryAsText);
}

// This method called when the user "Right-clicks" on a results grid and selects "Copy as HTML"
export function copyTableResultToClipboard(env: QueryStoreEnv, tableResult: kusto.KustoResult) {
    const sortedTable = sortTableByDate(tableResult);
    const html = generateQueryResultsHtml(sortedTable);
    if (html === undefined) {
        return;
    }
    env.config.actions?.onCopyResults?.(html);
}

export function copyResultsToClipboard(core: QueryCore) {
    const tab = core.store.tabs.tabInContext;

    if (!tab.completionInfo) {
        return;
    }

    const { isSuccess } = tab.completionInfo;

    // If this is an error, copy the error to clipboard.
    const results = tab.results;
    if (!isSuccess && !results) {
        copyQueryToClipboard(core, tab.queryContext, undefined, tab.errorMessage);
        return;
    }

    if (!results) {
        return;
    }

    const resultsToDisplay = results.resultsToDisplay;
    if (tab.completionInfo.resultIndex >= resultsToDisplay.length) {
        return;
    }

    const { isChart } = resultsToDisplay[tab.completionInfo.resultIndex];
    const domNode = document.getElementById(chartResultContainerId);
    if (isChart) {
        if (!domNode) {
            // if we're trying to copy a chart and it's not in dom, copy link+query
            copyQueryToClipboard(core, tab.queryContext);
            return;
        }
    }

    // the chartImage is saved even after the user switched from chart to table
    // in this case we don't want to copy the chart image
    const isCopyChart = Boolean(domNode !== null && tab.canvasToPaste);

    copyQueryToClipboard(core, tab.queryContext, resultsToDisplay, undefined, isCopyChart);
}
