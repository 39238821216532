import React from 'react';
import { DropdownMenuItemType, IDropdownOption, Stack, TextField, Toggle } from '@fluentui/react';
import { Button } from '@fluentui/react-components';
import { Delete16Regular } from '@fluentui/react-icons';

import {
    DropdownIconOption,
    dropdownIconRenderProps,
    KweException,
    OptionOptionalData,
    RTDDropdown,
    RTDDropdownOption,
    RTDDropdownProps,
} from '@kusto/utils';

import { KweVisualFwkLocale } from '../../../types';
import { SchemaState } from '../../../visualConfig/input';
import { ColorResolutionSet, ColorRuleThemeColorValues } from '../../../visualConfig/visualConfig';
import {
    ColorRule,
    colorRulesColors,
    colorRulesIcons,
    colorRulesIconToIcon,
    colorRulesOperators,
    colorRulesThemes,
} from '../../../visualOptions';
import { SingleColumnDropdown } from '../../columnDropdowns/deprecated';
import type { ColorRulesVisualType } from '../filterRules';
import { ColorRulePanelActions } from './reducer';
import { calculateDefaultRuleName, isColumnNumeric, useThemeCanvas } from './utils';

import * as styles from './colorRulePanel.module.scss';

function createRuleTypeOptions(strings: KweVisualFwkLocale): RTDDropdownOption[] {
    return [
        {
            key: 'colorByCondition',
            text: strings.visualFwk.visualConfig.colorRules.colorByConditionOption,
        },
        { key: 'colorByValue', text: strings.visualFwk.visualConfig.colorRules.colorByValueOption },
    ];
}

function createColorStyleOptions(strings: KweVisualFwkLocale): RTDDropdownOption[] {
    return [
        { key: 'bold', text: strings.visualFwk.visualConfig.colorStyleDropdown.boldOption },
        { key: 'light', text: strings.visualFwk.visualConfig.colorStyleDropdown.lightOption },
    ];
}

function createIconOptions(strings: KweVisualFwkLocale): DropdownIconOption[] {
    const icons: DropdownIconOption[] = colorRulesIcons.map((icon) => {
        return {
            title: strings.visualFwk.visuals.colorRules.iconLabels[icon as ColorRule.Icon],
            text: '',
            key: icon,
            icon: colorRulesIconToIcon[icon as ColorRule.Icon],
        };
    });
    const noneOption: DropdownIconOption = {
        title: strings.visualFwk.visualConfig.colorRules.noIconOption,
        text: strings.visualFwk.visualConfig.colorRules.noIconOption,
        key: 'none',
    };
    const divider: DropdownIconOption = { key: 'divider', text: '-', itemType: DropdownMenuItemType.Divider };
    icons.unshift(divider);
    icons.unshift(noneOption);
    return icons;
}

const ColorIcon: React.FC<{ data: string }> = ({ data }) => (
    <span className={styles.colorIcon} style={{ backgroundColor: data }} />
);

function useColorOptions(strings: KweVisualFwkLocale, colors: ColorResolutionSet): OptionOptionalData<string>[] {
    return React.useMemo(() => {
        const colorOptions: OptionOptionalData<string>[] = colorRulesColors.map((c) => ({
            text: strings.visualFwk.visuals.colorRules.colorLabels[c],
            key: c,
            data: colors[c].color,
            icon: ColorIcon,
        }));
        colorOptions.unshift({ key: 'divider', text: '-', itemType: DropdownMenuItemType.Divider });
        colorOptions.unshift({
            text: strings.visualFwk.visualConfig.colorRules.noColorOption,
            key: 'none',
            data: strings.visualFwk.visualConfig.colorRules.noColorOption,
        });
        return colorOptions;
    }, [strings, colors]);
}

function createApplyToOptions(strings: KweVisualFwkLocale): RTDDropdownOption[] {
    return [
        { key: 'rows', text: strings.visualFwk.visualConfig.colorRules.applyToRowsOption },
        { key: 'cells', text: strings.visualFwk.visualConfig.colorRules.applyToCellsOption },
    ];
}

function createChainingOperatorOptions(strings: KweVisualFwkLocale): Array<DropdownIconOption<string>> {
    return [
        {
            key: 'and',
            text: strings.visualFwk.visualConfig.colorRules.chainingAndOption,
            icon: 'GroupObject',
            data: 'and',
        },
        {
            key: 'or',
            text: strings.visualFwk.visualConfig.colorRules.chainingOrOption,
            icon: 'Switch',
            data: 'or',
        },
    ];
}

const conditionOptions: RTDDropdownOption[] = colorRulesOperators.map((c) => ({
    text: c,
    key: c,
}));

/**
 * Interface of inputs that are used by both colorByCondition and colorByValue (rule is UColorRule)
 */
interface BaseRuleInputProps {
    t: KweVisualFwkLocale;
    visualType: ColorRulesVisualType;
    dispatch: React.Dispatch<ColorRulePanelActions>;
    rule: ColorRule.UColorRule;
}

/**
 * Interface of inputs that are used only by colorByCondition
 */
interface ColorByValueInputProps {
    t: KweVisualFwkLocale;
    dispatch: React.Dispatch<ColorRulePanelActions>;
    rule: ColorRule.ColorRuleByValue;
    disabled?: boolean;
}

/**
 * Interface of inputs that are used only by colorByCondition
 */
interface ColorByConditionInputProps {
    t: KweVisualFwkLocale;
    dispatch: React.Dispatch<ColorRulePanelActions>;
    rule: ColorRule.ColorRuleByCondition;
    disabled?: boolean;
}

interface ColorInputProps extends ColorByConditionInputProps {
    colors: ColorResolutionSet;
}

interface ColorByValueColumnProps extends ColorByValueInputProps {
    schema: SchemaState;
}

interface ApplyToColumnProps extends ColorByConditionInputProps {
    schema: SchemaState;
    disabled?: boolean;
}

interface ConditionRowProps {
    t: KweVisualFwkLocale;
    dispatch: React.Dispatch<ColorRulePanelActions>;
    condition: ColorRule.Condition;
    index: 0 | 1;
    schema: SchemaState;
    showDelete: boolean;
    chainingOperator: 'and' | 'or';
    enableInfer: boolean;
}

/**
 * Used for the inputs inside the condition row
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ConditionRowInputProps extends Pick<ConditionRowProps, 'dispatch' | 'condition' | 'index' | 't'> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ConditionsChainingOperatorInputProps extends Pick<ConditionRowProps, 'dispatch' | 'chainingOperator' | 't'> {}

interface RuleTypeInputProps extends BaseRuleInputProps {
    disabled?: boolean;
}

export const RuleTypeInput: React.FC<RuleTypeInputProps> = ({ rule, dispatch, t, disabled }) => {
    const onRuleTypeChange = React.useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (option) {
                dispatch({
                    type: 'setRuleType',
                    ruleType: option.key as 'colorByCondition' | 'colorByValue',
                });
            }
        },
        [dispatch]
    );

    const ruleTypeOptions = React.useMemo(() => {
        return createRuleTypeOptions(t);
    }, [t]);

    return (
        <RTDDropdown
            onChange={onRuleTypeChange}
            options={ruleTypeOptions}
            selectedKey={rule.ruleType}
            label={t.visualFwk.visualConfig.colorRules.ruleTypeLabel}
            disabled={disabled}
        />
    );
};

export const ColorStyleInput: React.FC<ColorByConditionInputProps> = ({ rule, dispatch, t }) => {
    const onColorStyleChange = React.useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (option) {
                dispatch({
                    type: 'setColorStyle',
                    colorStyle: option.key as 'light' | 'bold',
                });
            }
        },
        [dispatch]
    );

    const colorStyleOptions = createColorStyleOptions(t);

    return (
        <RTDDropdown
            onChange={onColorStyleChange}
            options={colorStyleOptions}
            selectedKey={rule.colorStyle}
            label={t.visualFwk.visualConfig.colorStyleDropdown.label}
        />
    );
};

export const RuleNameInput: React.FC<BaseRuleInputProps> = ({ rule, dispatch, t, visualType }) => {
    const onRuleNameChange = React.useCallback(
        (_: unknown, value?: string) => {
            if (value !== undefined) {
                dispatch({ type: 'setRuleName', ruleName: value });
            }
        },
        [dispatch]
    );
    return (
        <TextField
            value={rule.ruleName}
            label={t.visualFwk.visualConfig.colorRules.ruleNameLabel}
            onChange={onRuleNameChange}
            placeholder={calculateDefaultRuleName(t, rule, visualType)}
        />
    );
};

export const ConditionsChainingOperatorInput: React.FC<ConditionsChainingOperatorInputProps> = ({
    t,
    chainingOperator,
    dispatch,
}) => {
    const onChainingOperatorChange = React.useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (option) {
                dispatch({
                    type: 'setChainingOperator',
                    chainingOperator: option.key as 'or' | 'and',
                });
            }
        },
        [dispatch]
    );

    const chainingOperatorOptions = React.useMemo(() => {
        return createChainingOperatorOptions(t);
    }, [t]);

    return (
        <RTDDropdown
            {...dropdownIconRenderProps}
            onChange={onChainingOperatorChange}
            className={styles.chainingOperator}
            options={chainingOperatorOptions}
            selectedKey={chainingOperator}
            label={t.visualFwk.visualConfig.colorRules.chainingLabel}
        />
    );
};

const SingleValueOperatorInput: React.FC<ConditionRowInputProps> = ({ condition, dispatch, index, t }) => {
    const onValueChange = React.useCallback(
        (_: unknown, value?: string) => {
            if (value !== undefined) {
                dispatch({ type: 'setConditionValuesAtIndex', index, value, valueIndex: 0 });
            }
        },
        [index, dispatch]
    );
    return (
        <TextField
            value={condition.values[0] ?? ''}
            disabled={condition === undefined}
            label={t.visualFwk.visualConfig.colorRules.conditionValueLabel}
            onChange={onValueChange}
        />
    );
};

const MultipleValuesOperatorInput: React.FC<ConditionRowInputProps> = ({ condition, dispatch, index, t }) => {
    const onValueChange = React.useCallback(
        (valueIndex: 0 | 1, value?: string) => {
            if (value !== undefined) {
                dispatch({ type: 'setConditionValuesAtIndex', index, valueIndex, value });
            }
        },
        [index, dispatch]
    );

    const onFirstValueChange = React.useCallback(
        (_: unknown, value?: string) => {
            onValueChange(0, value);
        },
        [onValueChange]
    );
    const onSecondValueChange = React.useCallback(
        (_: unknown, value?: string) => {
            onValueChange(1, value);
        },
        [onValueChange]
    );
    return (
        <>
            <TextField
                value={condition.values[0] ?? ''}
                disabled={condition === undefined}
                label={t.visualFwk.visualConfig.colorRules.conditionValueLabel}
                onChange={onFirstValueChange}
            />
            <span className={styles.betweenAnd}>{t.visualFwk.visualConfig.colorRules.and}</span>
            <TextField
                value={condition.values[1] ?? ''}
                disabled={condition === undefined}
                label={t.visualFwk.visualConfig.colorRules.conditionValueLabel}
                onChange={onSecondValueChange}
            />
        </>
    );
};

export const ConditionRow: React.FC<ConditionRowProps> = ({
    t,
    condition,
    dispatch,
    index,
    schema,
    showDelete,
    chainingOperator,
    enableInfer,
}) => {
    const onOperatorChange = React.useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (!option) {
                return;
            }
            dispatch({
                type: 'setConditionOperatorAtIndex',
                index,
                operator: option.key as ColorRule.Operator,
            });
        },
        [index, dispatch]
    );

    const onColumnChange = React.useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (option?.data !== undefined) {
                dispatch({
                    type: 'setConditionColumnAtIndex',
                    column: option.data,
                    index,
                });
            }
        },
        [dispatch, index]
    );

    const deleteCondition = React.useCallback(() => {
        dispatch({ type: 'deleteCondition', index });
    }, [dispatch, index]);

    return (
        <>
            {index > 0 && (
                <ConditionsChainingOperatorInput chainingOperator={chainingOperator} dispatch={dispatch} t={t} />
            )}
            <Stack horizontal={true} className={styles.condition} tokens={{ childrenGap: 8 }}>
                <SingleColumnDropdown
                    t={t}
                    schema={schema}
                    className={styles.inputWidth}
                    label={t.visualFwk.visualConfig.colorRules.columnDropdownLabel}
                    id={`etp--color-rules-column-${index}`}
                    selectedColumn={condition.column}
                    onChange={onColumnChange}
                    inferLabel={enableInfer ? undefined : t.visualFwk.visualConfig.colorRules.noSelectionText}
                />
                <RTDDropdown
                    options={conditionOptions}
                    className={styles.operatorInput}
                    selectedKey={condition.operator ?? null}
                    placeholder={t.visualFwk.visualConfig.colorRules.noConditionOperatorText}
                    label={`${t.visualFwk.visualConfig.colorRules.conditionOperatorLabel}`}
                    onChange={onOperatorChange}
                />
                {condition.operator === 'between' ? (
                    <MultipleValuesOperatorInput condition={condition} dispatch={dispatch} index={index} t={t} />
                ) : (
                    <SingleValueOperatorInput condition={condition} dispatch={dispatch} index={index} t={t} />
                )}
            </Stack>
            {showDelete && (
                <Button
                    appearance="transparent"
                    className={styles.deleteButton}
                    icon={<Delete16Regular />}
                    onClick={deleteCondition}
                    title={t.utils.util.buttons.delete}
                />
            )}
        </>
    );
};

export const ApplyOptionsInput: React.FC<BaseRuleInputProps> = ({ dispatch, rule, t }) => {
    const applyToOptions = React.useMemo(() => {
        return createApplyToOptions(t);
    }, [t]);

    const onApplyToChange = React.useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (option) {
                dispatch({
                    type: 'setApplyTo',
                    applyTo: option.key as 'rows' | 'cells',
                });
            }
        },
        [dispatch]
    );

    return (
        <RTDDropdown
            onChange={onApplyToChange}
            options={applyToOptions}
            selectedKey={rule.applyTo}
            label={t.visualFwk.visualConfig.colorRules.applyOptionsLabel}
        />
    );
};

export const ApplyToColumnInput: React.FC<ApplyToColumnProps> = ({ dispatch, rule, schema, t, disabled }) => {
    const onColumnChange = React.useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (option?.data !== undefined) {
                dispatch({ type: 'setApplyToColumn', column: option.data });
            }
        },
        [dispatch]
    );
    const inferColumn = rule.conditions[0].column;
    const showNoneLabel = inferColumn === null;

    return (
        <SingleColumnDropdown
            t={t}
            schema={schema}
            label={t.visualFwk.visualConfig.colorRules.columnDropdownLabel}
            id={`etp--color-rules-apply-to-column`}
            selectedColumn={rule.applyToColumn}
            onChange={onColumnChange}
            disabled={disabled}
            inferColumn={inferColumn ?? undefined}
            inferLabel={showNoneLabel ? t.visualFwk.visualConfig.colorRules.noSelectionText : undefined}
        />
    );
};

export const ReverseThemeToggle: React.FC<ColorByValueInputProps> = ({ rule, dispatch, t }) => {
    const onToggleChange = React.useCallback(
        (_ev: React.MouseEvent, val?: boolean) => {
            dispatch({ type: 'setReverseTheme', reverse: !!val });
        },
        [dispatch]
    );

    return (
        <Toggle
            defaultChecked={false}
            label={t.visualFwk.visualConfig.colorRules.reverseThemeLabel}
            onText={t.visualFwk.visualConfig.colorRules.toggleOn}
            offText={t.visualFwk.visualConfig.colorRules.toggleOff}
            onChange={onToggleChange}
            className={styles.hideTextToggle}
            checked={rule.reverseTheme}
        />
    );
};

export const HideTextToggle: React.FC<ColorByConditionInputProps> = ({ rule, dispatch, t, disabled }) => {
    const onToggleChange = React.useCallback(
        (val: boolean) => {
            dispatch({ type: 'setHideText', hideText: val });
        },
        [dispatch]
    );

    return (
        <Toggle
            defaultChecked={false}
            label={t.visualFwk.visualConfig.colorRules.hideTextLabel}
            onText={t.visualFwk.visualConfig.colorRules.toggleOn}
            offText={t.visualFwk.visualConfig.colorRules.toggleOff}
            onChanged={onToggleChange}
            className={styles.hideTextToggle}
            checked={rule.hideText}
            disabled={disabled}
        />
    );
};

export const ColorInput: React.FC<ColorInputProps> = ({ dispatch, rule, colors, t }) => {
    const colorOptions = useColorOptions(t, colors);

    const onColorChange = React.useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (option) {
                dispatch({
                    type: 'setColor',
                    color: option.key === 'none' ? null : (option.key as ColorRule.Color),
                });
            }
        },
        [dispatch]
    );

    return (
        <RTDDropdown
            {...dropdownIconRenderProps}
            onChange={onColorChange}
            options={colorOptions}
            selectedKey={rule.color ?? 'none'}
            label={t.visualFwk.visualConfig.colorRules.highlightColorDropdownLabel}
        />
    );
};

export const TagInput: React.FC<ColorByConditionInputProps> = ({ rule, dispatch, t, disabled }) => {
    const onTagChange = React.useCallback(
        (_: unknown, value?: string) => {
            dispatch({ type: 'setTag', tag: value ?? '' });
        },
        [dispatch]
    );
    return (
        <TextField
            disabled={disabled}
            value={rule.tag}
            label={t.visualFwk.visualConfig.colorRules.tagLabel}
            onChange={onTagChange}
        />
    );
};

export const IconInput: React.FC<ColorByConditionInputProps> = ({ dispatch, rule, t, disabled }) => {
    const onIconChange = React.useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (option) {
                dispatch({ type: 'setIcon', icon: option.key === 'none' ? null : (option.key as ColorRule.Icon) });
            }
        },
        [dispatch]
    );

    const iconOptions = React.useMemo(() => createIconOptions(t), [t]);

    return (
        <RTDDropdown
            {...dropdownIconRenderProps}
            onChange={onIconChange}
            options={iconOptions}
            disabled={disabled}
            selectedKey={rule.icon ?? 'none'}
            placeholder={t.visualFwk.visualConfig.colorRules.noSelectionText}
            label={t.visualFwk.visualConfig.colorRules.iconDropdownLabel}
        />
    );
};

export const ColorByValueColumn: React.FC<ColorByValueColumnProps> = ({ rule, dispatch, t, schema }) => {
    const onColumnChange = React.useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (option?.data !== undefined) {
                const isNumeric = option.data !== null && isColumnNumeric(option.data, schema);
                dispatch({
                    type: 'setColorByValueColumn',
                    column: option.data,
                    isNumeric,
                });
            }
        },
        [dispatch, schema]
    );

    return (
        <SingleColumnDropdown
            t={t}
            schema={schema}
            label={t.visualFwk.visualConfig.colorRules.columnDropdownLabel}
            id={`etp--color-rules-color-by-value-column`}
            selectedColumn={rule.column}
            onChange={onColumnChange}
            inferLabel={t.visualFwk.visualConfig.colorRules.noSelectionText}
        />
    );
};

interface ThemeDropdownOption {
    key: ColorRule.Theme;
    text: string;
}

const ThemeDropdownOptionItem: React.FC<{ option: ThemeDropdownOption; themeColors: ColorRuleThemeColorValues }> = ({
    option,
    themeColors,
}) => {
    const { key, text } = option;
    const { colors } = themeColors[key];
    return (
        <div className={styles.themeDropdownOption}>
            <div className={styles.themeDropdownOptionColors}>
                {colors.map((color, index) => (
                    <span className={styles.themeColorIcon} key={index} style={{ backgroundColor: color }}></span>
                ))}
            </div>
            <span className={styles.themeDropdownOptionText}>{text}</span>
        </div>
    );
};

// TODO: Extract to shared component
// to re-use here and inside Heatmap theme colors
export const ThemeInput: React.FC<ColorByValueInputProps & { themeColors: ColorRuleThemeColorValues }> = ({
    rule,
    dispatch,
    t,
    themeColors,
    disabled,
}) => {
    const themeOptions = colorRulesThemes.map((theme) => ({
        key: theme,
        text: t.visualFwk.visualConfig.colorRules.themeDropdownOptionsText[theme],
    }));

    const onThemeChange = React.useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (option) {
                dispatch({ type: 'setTheme', theme: option.key as ColorRule.Theme });
            }
        },
        [dispatch]
    );

    const onRenderTitle: RTDDropdownProps['onRenderTitle'] = (selected) => {
        if (selected === undefined || selected.length === 0) {
            throw new KweException('Unable to determine visual selected dropdown selection');
        }
        return <ThemeDropdownOptionItem option={selected[0] as ThemeDropdownOption} themeColors={themeColors} />;
    };

    const onRenderOption: RTDDropdownProps['onRenderOption'] = (option) => {
        if (option === undefined) {
            throw new KweException();
        }
        return <ThemeDropdownOptionItem option={option as ThemeDropdownOption} themeColors={themeColors} />;
    };

    return (
        <RTDDropdown
            onRenderOption={onRenderOption}
            onRenderTitle={onRenderTitle}
            onChange={onThemeChange}
            options={themeOptions}
            selectedKey={rule.themeName ?? colorRulesThemes[0]}
            label={t.visualFwk.visualConfig.colorRules.themeDropdownLabel}
            disabled={disabled}
        />
    );
};

export const ThemePreview: React.FC<{
    themeName: ColorRule.Theme;
    themeColors: ColorRuleThemeColorValues;
    reverseTheme: boolean;
}> = ({ themeName, themeColors, reverseTheme }) => {
    let colors = themeColors[themeName].colors;
    if (reverseTheme) {
        colors = [...colors].reverse();
    }
    const canvasRef = useThemeCanvas(colors);

    return <canvas ref={canvasRef} className={styles.themeCanvas} />;
};

export const ThemeMinValueInput: React.FC<ColorByValueInputProps> = ({ rule, dispatch, disabled, t }) => {
    const onValueChange = React.useCallback(
        (_: unknown, value?: string) => {
            if (value !== undefined) {
                dispatch({ type: 'setThemeMinValue', value });
            }
        },
        [dispatch]
    );

    return (
        <TextField
            value={rule.minValue ?? ''}
            label={t.visualFwk.visualConfig.colorRules.themeMinValueLabel}
            onChange={onValueChange}
            disabled={disabled}
        />
    );
};

export const ThemeMaxValueInput: React.FC<ColorByValueInputProps> = ({ rule, dispatch, disabled, t }) => {
    const onValueChange = React.useCallback(
        (_: unknown, value?: string) => {
            if (value !== undefined) {
                dispatch({ type: 'setThemeMaxValue', value });
            }
        },
        [dispatch]
    );
    return (
        <TextField
            value={rule.maxValue ?? ''}
            label={t.visualFwk.visualConfig.colorRules.themeMaxValueLabel}
            onChange={onValueChange}
            disabled={disabled}
        />
    );
};
