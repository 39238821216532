import { Mutable } from '@kusto/utils';

import type { InternalParsedVisual } from './parseVisuals';
import type { UnknownVisualOptions, VisualOptionKey, VisualOptions } from './visualOptions';

/**
 * Removes extra model properties and adds missing properties
 */
export function narrowVisualOptions<C extends VisualOptionKey>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: InternalParsedVisual<C, any>,
    options: UnknownVisualOptions
): VisualOptions<C> {
    const result = {} as Mutable<VisualOptions<C>>;

    for (const key of Object.keys(config.model)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (result as any)[key] = key in options ? (options as any)[key] : (config.model as any)[key];
    }

    return result;
}
