import React from 'react';
import { Button, ButtonProps, Tooltip } from '@fluentui/react-components';
import { Slot } from '@fluentui/react-utilities';

export interface CustomTooltip {
    /** Used as disabled element tooltip text */
    disabledContent?: NonNullable<Slot<'div'>>;
    /** Used as enabled element tooltip text */
    enabledContent?: NonNullable<Slot<'div'>>;
}

export declare type CustomButtonType = React.FC<ButtonProps & { tooltip?: CustomTooltip }>;

/**
 * Custom Button - used as a workaround to the FluentUI9 Button bug, which set the primary color on over a disabled button
 * while the appearance is "subtle"
 */
export const CustomButton: CustomButtonType = ({ tooltip, ...props }) => {
    if (props.disabled) {
        const disabledButton = <Button disabled={true} appearance="transparent" {...props} />;
        return tooltip?.disabledContent ? (
            <Tooltip relationship="label" withArrow={true} positioning="above" content={tooltip.disabledContent}>
                {disabledButton}
            </Tooltip>
        ) : (
            disabledButton
        );
    }

    return tooltip?.enabledContent ? (
        <Tooltip relationship="label" withArrow={true} positioning="above" content={tooltip.enabledContent}>
            <Button appearance="subtle" {...props} />
        </Tooltip>
    ) : (
        <Button appearance="subtle" {...props} />
    );
};
