import { getRestorer, getTabsterAttribute, RestorerTypes } from 'tabster';
import { useTabster } from './useTabster';
/**
 * Focus will be restored to the most recent target element when it is lost from a source
 * @returns Attribute to apply to the target element where focus is restored
 */ export function useRestoreFocusTarget() {
    const tabster = useTabster();
    // Initializes the restorer API
    if (tabster) {
        getRestorer(tabster);
    }
    return getTabsterAttribute({
        restorer: {
            type: RestorerTypes.Target
        }
    });
}
/**
 * Focus will be restored to the most recent target element when it is lost from a source
 * @returns Attribute to apply to the element that might lose focus
 */ export function useRestoreFocusSource() {
    const tabster = useTabster();
    // Initializes the restorer API
    if (tabster) {
        getRestorer(tabster);
    }
    return getTabsterAttribute({
        restorer: {
            type: RestorerTypes.Source
        }
    });
}
