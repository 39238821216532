import * as React from 'react';
import { useMenu_unstable } from './useMenu';
import { useMenuContextValues_unstable } from './useMenuContextValues';
import { renderMenu_unstable } from './renderMenu';
/**
 * Wrapper component that manages state for a popup MenuList and a MenuTrigger
 */ export const Menu = (props)=>{
    const state = useMenu_unstable(props);
    const contextValues = useMenuContextValues_unstable(state);
    return renderMenu_unstable(state, contextValues);
};
Menu.displayName = 'Menu';
