import * as React from 'react';
const { useRef, useEffect } = React;
export const useMutationObserver = (target, callback, options)=>{
    const observer = useRef();
    useEffect(()=>{
        // Create an observer instance linked to the callback function
        observer.current = new MutationObserver(callback);
    }, [
        callback
    ]);
    useEffect(()=>{
        if (target) {
            var // Start observing the target node for configured mutations
            _observer_current;
            (_observer_current = observer.current) === null || _observer_current === void 0 ? void 0 : _observer_current.observe(target, options);
        }
        return ()=>{
            var _observer_current;
            (_observer_current = observer.current) === null || _observer_current === void 0 ? void 0 : _observer_current.disconnect();
        };
    }, [
        target,
        options
    ]);
    return {
        observer
    };
};
