import * as React from 'react';
import { useFluent_unstable as useFluent } from '@fluentui/react-shared-contexts';
import { useTabster } from './useTabster';
/**
 * Returns a set of helper functions that will traverse focusable elements in the context of a root DOM element
 */ export const useFocusFinders = ()=>{
    const tabster = useTabster();
    const { targetDocument } = useFluent();
    // Narrow props for now and let need dictate additional props in the future
    const findAllFocusable = React.useCallback((container, acceptCondition)=>(tabster === null || tabster === void 0 ? void 0 : tabster.focusable.findAll({
            container,
            acceptCondition
        })) || [], [
        tabster
    ]);
    const findFirstFocusable = React.useCallback((container)=>tabster === null || tabster === void 0 ? void 0 : tabster.focusable.findFirst({
            container
        }), [
        tabster
    ]);
    const findLastFocusable = React.useCallback((container)=>tabster === null || tabster === void 0 ? void 0 : tabster.focusable.findLast({
            container
        }), [
        tabster
    ]);
    const findNextFocusable = React.useCallback((currentElement, options = {})=>{
        if (!tabster || !targetDocument) {
            return null;
        }
        const { container = targetDocument.body } = options;
        return tabster.focusable.findNext({
            currentElement,
            container
        });
    }, [
        tabster,
        targetDocument
    ]);
    const findPrevFocusable = React.useCallback((currentElement, options = {})=>{
        if (!tabster || !targetDocument) {
            return null;
        }
        const { container = targetDocument.body } = options;
        return tabster.focusable.findPrev({
            currentElement,
            container
        });
    }, [
        tabster,
        targetDocument
    ]);
    return {
        findAllFocusable,
        findFirstFocusable,
        findLastFocusable,
        findNextFocusable,
        findPrevFocusable
    };
};
