import * as React from 'react';
import { FontIcon } from '@fluentui/react';
import { ILabelProps, Label } from '@fluentui/react/lib/Label';
import { ITooltipProps, TooltipHost } from '@fluentui/react/lib/Tooltip';

/**
 * @deprecated
 */
export interface LabelWithInfoProps extends ILabelProps {
    text: React.ReactNode;
    infoText: React.ReactNode;
    onRenderInfo?: () => React.ReactElement;
    tooltipProps?: ITooltipProps;
}

/**
 * @deprecated
 */
export const LabelWithInfo: React.FC<LabelWithInfoProps> = ({
    text,
    infoText,
    onRenderInfo,
    tooltipProps,
    ...props
}) => (
    <Label {...props}>
        {text}{' '}
        {!props.disabled ? (
            <TooltipHost
                calloutProps={{ gapSpace: 0 }}
                tooltipProps={{
                    ...tooltipProps,
                    onRenderContent: onRenderInfo ? onRenderInfo : () => <>{infoText}</>,
                }}
            >
                <FontIcon iconName="Info" />
            </TooltipHost>
        ) : (
            <FontIcon iconName="Info" />
        )}
    </Label>
);
