import * as React from 'react';
import { mergeClasses } from '@griffel/react';
import { renderText_unstable, useText_unstable, useTextStyles_unstable } from '../Text';
export function createPreset(options) {
    const { useStyles, className, displayName } = options;
    const Wrapper = React.forwardRef((props, ref)=>{
        const styles = useStyles();
        const state = useText_unstable(props, ref);
        useTextStyles_unstable(state);
        state.root.className = mergeClasses(className, state.root.className, styles.root, props.className);
        return renderText_unstable(state);
    });
    Wrapper.displayName = displayName;
    return Wrapper;
}
