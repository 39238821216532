import axios, { CancelToken, CancelTokenSource } from 'axios';

import { Account, IKweTelemetry, InterfaceFor } from '@kusto/utils';

import { KustoClientError } from '../KustoClientError';
import type { KustoDomains } from '../KustoDomains';
import { ColumnType, IKustoClientAuthProvider, VisualizationOptions } from '../types';
import { IKustoRequest, KustoRequest } from './kustoRequest';
import { buildAppName, buildClientRequestId, XMsAppNameParams, XMsClientRequestIdParams } from './xmsHeaders';

export interface KustoClientEvents {
    /** Triggers when a query is made to a non-whitelisted domain. */
    nonKustoDomain?: (targetDomain: string) => void;
    /** Triggers when the token is expired just before it is re-fetched. */
    tokenExpired?: (message: string) => void;
    /** Triggers when a valid token has fetched successfully */
    tokenFetched?: (
        clientActivityId: string,
        applicationUrl: string,
        url: string,
        dbName: string,
        queryType: 'query' | 'command',
        queryText: string
    ) => void;
    /**
     * Triggers when the backend returned an Unauthorized error (401).
     * @param clientActivityId The activity ID that was passed to execute
     * @param retrying true when retrying due to unauthorized error (401)
     * @param refreshTokenSupported retry will only work if refresh token is supported in the authentication provider.
     * @param retryCount the retry number
     * @param error the 401 error thrown by axios.
     */
    onUnauthorizedError?: (
        clientActivityId: string,
        retrying: boolean,
        refreshTokenSupported: boolean,
        retryCount: number,
        error: Error
    ) => void;
    /** Triggers after content is parsed. Can be used for parsing benchmark. */
    responseParsed?: (type: string, duration: number, length?: number) => void;
}

// Export axios's cancellation APIs, so callers won't need to import types from axios.
export type CancellationTokenSource = CancelTokenSource;
export type CancellationToken = CancelToken;
export const isCancelledError = (e: unknown) => axios.isCancel(e) || (e instanceof KustoClientError && e?.isCancel);

/**
 * https://learn.microsoft.com/en-us/azure/data-explorer/kusto/api/rest/response#json-encoding-of-a-sequence-of-tables
 */
export interface KustoClientResult {
    Tables: {
        Columns: ColumnV1[];
        Rows: RowV1[];
        TableName: string;
    }[];
    Exceptions?: string[];
}

/**
 * https://learn.microsoft.com/en-us/azure/data-explorer/kusto/api/rest/response#the-meaning-of-tables-in-the-response
 */
export type V1TableKind = 'QueryResult' | 'QueryProperties' | 'QueryStatus';

/**
 * https://learn.microsoft.com/en-us/azure/data-explorer/kusto/api/rest/response2#datasetheader
 */
export interface DataSetHeader {
    FrameType: 'DataSetHeader';
    IsProgressive: boolean;
    Version: string;
}

/**
 * @see {@link KustoClientResult}
 */
export interface ColumnV1 {
    ColumnName: string;
    /**
     * _Pretty_ sure this is optional because we treat it as such in at least
     * once place, but, kusto doesn't document it this way, and I'm unable to
     * reproduce it.
     *
     * Maybe it's only optional if the data type doesn't have a matching Kusto type?
     */
    ColumnType: ColumnType;
    /**
     * _Pretty_ sure this maps to the keys of v1TypeToKustoType, but unable to
     * find docs on this or a way to reproduce this
     */
    DataType: string;
}

export interface DataTableColumn {
    ColumnName: string;
    ColumnType: ColumnType;
}

export type ValueRow = unknown[];

export interface ErrorRowV1 {
    Exceptions: string[];
}

export interface ErrorRow {
    OneApiErrors: OneApiError[];
}

/**
 * @see {@link KustoClientResult}
 */
export type RowV1 = ValueRow | ErrorRowV1;
export type Row = ValueRow | ErrorRow;

/**
 * https://learn.microsoft.com/en-us/azure/data-explorer/kusto/api/rest/response2#the-meaning-of-tables-in-the-response
 */
export type TableKind =
    | 'PrimaryResult'
    | 'QueryCompletionInformation'
    | 'QueryTraceLog'
    | 'QueryPerfLog'
    | 'TableOfContents'
    | 'QueryProperties'
    | 'QueryPlan'
    | 'Unknown';

/**
 * https://learn.microsoft.com/en-us/azure/data-explorer/kusto/api/rest/response2#datatable
 */
export interface DataTable {
    FrameType: 'DataTable';
    TableId: number;
    TableKind: TableKind;
    TableName: string;
    Columns: DataTableColumn[];
    /**
     * If the query was a partial failure, then the last row will have an error object ({@link ErrorRow}) at the end of it
     */
    Rows: Row[];
}

/**
 * https://learn.microsoft.com/en-us/azure/data-explorer/kusto/api/rest/response2#datasetcompletion
 */
export type DataSetCompletion =
    | {
          FrameType: 'DataSetCompletion';
          HasErrors: false;
          Cancelled: boolean;
          /**
           * Not present if HasErrors is false
           */
          OneApiErrors?: undefined;
      }
    | {
          FrameType: 'DataSetCompletion';
          HasErrors: true;
          Cancelled: boolean;
          OneApiErrors: OneApiError[];
      };

export type Frame = DataSetHeader | DataTable | DataSetCompletion;

export type KustoClientResultV2 = [DataSetHeader, ...DataTable[], DataSetCompletion];

/**
 * 👇 Kusto docs link to this, but, actual responses contain (undocumented?) "@" properties
 * https://github.com/Microsoft/api-guidelines/blob/vNext/Guidelines.md#7102-error-condition-responses
 */
export interface OneApiError {
    error: {
        code: string;
        message: string;
        target?: string;
        '@type': string;
        '@message': string;
        '@context': OneApiErrorContext;
        '@permanent': boolean;
    };
}

export interface OneApiErrorContext {
    timestamp: string;
    serviceAlias: string;
    machineName: string;
    processName: string;
    processId: number;
    threadId: number;
    appDomainName: string;
    clientRequestId: string;
    activityId: string;
    subActivityId: string;
    activityType: string;
    parentActivityId: string;
    activityStack: string;
}

export type EngineParserType = 'v1' | 'v2' | undefined;

/**
 * This is a small subset of client request properties that are used in this app.
 * For a full list of client request options see:
 * {@link https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/netfx/request-properties#clientrequestproperties-options}
 */
export interface ClientRequestOptions {
    readonly query_language: 'kql' | 'sql' | 'csl';
    readonly queryconsistency: 'strongconsistency' | 'weakconsistency';
    readonly servertimeout: string;
    /** Request is read-only, however, may still have external side effects (http_requests or sql external commands). */
    readonly request_readonly: boolean;
    /**
     * Request is read-only, no external side effects. http_requests or sql
     * external commands are blocked.
     */
    readonly request_readonly_hardline: boolean;
    /**
     * Customer description string. mostly used for applying Request
     * classification policy on incoming requests
     */
    readonly request_description: string;
    /**
     * Query parameters that are part of the query will be logged and shown in
     * .show queries command.
     */
    readonly query_log_query_parameters: boolean;
    /**
     * If set, retrieves the schema of each tabular data in the results of the
     * query instead of the data itself.
     *
     * Not supported by app insights endpoints
     */
    readonly query_results_apply_getschema: boolean;
    /**
     * Max age for using cached results. Used in dashboards, defaults to unset.
     * (don't cache)
     */
    readonly query_results_cache_max_age: string;
    /**
     * Enables limiting query results to this number of records. [Long]
     *
     * Not supported by app insights endpoints
     */
    readonly query_take_max_records: number;
    /**
     * the parser the engine will use
     */
    readonly parser: EngineParserType;

    /**
     * Options that are used for signed queries
     */
    readonly signed_query_impersonation_manifest: string;
    readonly signed_query_properties: string;
    readonly signed_query_signature: string;
}

export interface ClientRequestProperties {
    Options?: Partial<ClientRequestOptions>;
    Parameters?: { [key: string]: string };
}

export interface RequestHeaders {
    /** pass as x-ms-app. Default: `KusWeb`. @see https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/netfx/request-properties#application-x-ms-app */
    appName?: string;

    /** passed as x-ms-user (a.k.a x-ms-user-id). Default: IKustoClientAuthProvider.getUser().userName ?? 'unknown'.  @see https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/netfx/request-properties#user-x-ms-user */
    userId?: string;

    /** passed as x-ms-client-request-id. Default: `KustoWebV2;<GUID>`. @see https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/netfx/request-properties#clientrequestid-x-ms-client-request-id */
    clientRequestId?: string;
}

export interface ExecutionResult<T extends ApiVersion> {
    apiCallResult: T extends 'v1' ? KustoClientResult : KustoClientResultV2;
    httpRequestStartTime: Date;
    clientRequestId: string;
}

export type ApiVersion = 'v1' | 'v2';

export const buildClientURL = (url: string, apiVersion: ApiVersion, isQuery: boolean): string =>
    `${url.replace(/\/$/, '')}/${apiVersion}/rest/${isQuery ? 'query' : 'mgmt'}`;

export type IKustoClient = InterfaceFor<KustoClient>;

/**
 * A class to query kusto clusters.
 */
export class KustoClient {
    /**
     * @deprecated will be removed once Trident moves to createRequest and pass appName in the constructor instead
     */
    protected defaultRequestHeaders = {
        /**
         * @deprecated will be removed once Trident moves to createRequest. Pass appName in the ctor instead
         */
        appName: 'KusWeb',
        /**
         * @deprecated will be removed once Trident moved to use createRequest
         */
        clientRequestId: (guid: string) => `KustoWebV2;${guid}`,
        /**
         * @deprecated will be removed once Trident moved to use createRequest
         */
        userId: 'unknown',
    };

    private readonly xMsAppHeader: string;

    /**
     * @param domains
     * @param authenticationProvider An auth provider that is used to get an auth token.
     * @param telemetry The telemetry service to log telemetry events.
     * @param sessionId The session ID of the user.
     * @param appNameParams The parameters to build the x-ms-app header.
     * @param events Optional. Listen to different events raised when executing a query.
     * @param getAmbientRequestProperties Optional. A function that retrieves Properties to be sent on every request to the Kusto cluster.
     */
    constructor(
        protected readonly domains: KustoDomains,
        private readonly authenticationProvider: IKustoClientAuthProvider,
        private readonly telemetry: IKweTelemetry,
        private readonly sessionId: string,
        private readonly appNameParams: Omit<XMsAppNameParams, 'topFrameDomain'>,
        private readonly events?: KustoClientEvents,
        private readonly getAmbientRequestProperties?: (
            isQuery: boolean
        ) => Promise<Partial<ClientRequestProperties> | null>
    ) {
        this.xMsAppHeader = buildAppName({
            ...appNameParams,
            topFrameDomain: topFrameDomain(),
        });
    }

    /**
     * Determines the `getToken` flow.
     * If this method returns true, `getToken` called with 'cluster' scope;
     * otherwise it will be called without scopes (undefined) - therefore uses the default scope (eg. help.kusto,windows.net).
     */
    protected isClusterScope(_url: string): boolean {
        return false;
    }

    createRequest(
        url: string,
        clientRequestIdParams: Omit<XMsClientRequestIdParams, 'sessionId' | 'appId'>,
        onQueryExecuted?: (result: ExecutionResult<'v1' | 'v2'> | KustoClientError) => void
    ): IKustoRequest {
        const xMsClientRequestId = buildClientRequestId({
            ...clientRequestIdParams,
            sessionId: this.sessionId,
            appId: this.appNameParams.appId,
        });

        return new KustoRequest(
            this.domains,
            this.authenticationProvider,
            this.isClusterScope(url),
            { appName: this.xMsAppHeader, clientRequestId: xMsClientRequestId },
            url,
            this.telemetry,
            this.events,
            this.getAmbientRequestProperties,
            onQueryExecuted
        );
    }

    /**
     * @deprecated will be removed once Trident moved to use createRequest
     */
    async cancelQuery(
        url: string,
        clientActivityId: string,
        requestTimeout?: number,
        cancelClientActivityId?: string,
        account?: Account
    ): Promise<ExecutionResult<'v1'>> {
        return this.executeControlCommand(url, undefined, `.cancel query '${clientActivityId}'`, {
            account,
            requestTimeout,
            requestHeaders: { clientRequestId: cancelClientActivityId },
        });
    }

    createCancelToken(): CancellationTokenSource {
        return axios.CancelToken.source();
    }

    /**
     * @deprecated will be removed once Trident moves to createRequest
     */
    async execute<T extends ApiVersion>(
        url: string,
        dbName: string | undefined,
        queryOrCommand: string,
        isQuery: boolean,
        apiVersion: T,
        options: {
            properties?: ClientRequestProperties;
            requestTimeout?: number;
            cancelToken?: CancellationToken;
            retryCount?: number;
            requestHeaders?: RequestHeaders;
            account?: Account;
            onStartHttpRequest?: () => void;
        }
    ): Promise<T extends 'v1' ? ExecutionResult<'v1'> : ExecutionResult<'v2'>> {
        return new KustoRequest(
            this.domains,
            this.authenticationProvider,
            this.isClusterScope(url),
            {
                appName: options.requestHeaders?.appName ?? this.defaultRequestHeaders.appName,
                clientRequestId:
                    options.requestHeaders?.clientRequestId ||
                    this.defaultRequestHeaders.clientRequestId(crypto.randomUUID()),
            },
            url,
            this.telemetry,
            this.events,
            this.getAmbientRequestProperties
        ).execute_deprecated(dbName, queryOrCommand, isQuery, apiVersion, options);
    }

    /**
     * @deprecated will be removed once Trident moves to createRequest
     */
    executeControlCommand(
        url: string,
        dbName: string | undefined,
        command: string,
        options: {
            account?: Account;
            properties?: ClientRequestProperties;
            requestTimeout?: number;
            requestHeaders?: RequestHeaders;
            cancelToken?: CancelToken;
        } = {}
    ): Promise<ExecutionResult<'v1'>> {
        return this.execute(url, dbName, command, false, 'v1', options);
    }

    /**
     * @deprecated will be removed once Trident moves to createRequest
     */
    executeQuery(
        url: string,
        dbName: string,
        query: string,
        options: {
            properties?: ClientRequestProperties;
            requestHeaders?: RequestHeaders;
            cancelToken?: CancelToken;
        } = {}
    ): Promise<ExecutionResult<'v1'>> {
        return this.execute(url, dbName, query, true, 'v1', options);
    }
}

export const getClientRequestIdFromError = (e: unknown): string => {
    return (e as KustoClientError)?.clientRequestId ?? '';
};

export const EmptyVisualizationOptionsSnapshot: VisualizationOptions = {
    Accumulate: false,
    IsQuerySorted: false,
    Kind: null,
    Legend: null,
    Series: null,
    Title: null,
    Visualization: null,
    XAxis: null,
    XColumn: null,
    XTitle: null,
    YAxis: null,
    YColumns: null,
    YSplit: null,
    YTitle: null,
    AnomalyColumns: null,
    Ymin: 'NaN',
    Ymax: 'NaN',
};

/**
 * Return the domain of the top frame (The URL in the browser). If KustoClient is not running in an iframe, return an empty string.
 * This is a best effort because in non-chromium browsers ancestorOrigins API doesn't exist and referrer isn't always accurate.
 */
function topFrameDomain(): string {
    try {
        const runInIframe = window.self !== window.top;
        if (runInIframe) {
            // ancestorOrigins is only supported in non-chromium based browsers
            if (Array.isArray(window.location.ancestorOrigins) && window.location.ancestorOrigins.length > 0) {
                const topOrigin = window.location.ancestorOrigins[window.location.ancestorOrigins.length - 1];
                return new URL(topOrigin).hostname;
            } else {
                // In non-chromium based browsers use referrer
                return document.referrer;
            }
        }
    } catch {}

    return '';
}
