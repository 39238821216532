@use '@kusto/style/tokens';

%containerDesign {
    padding: tokens.$spacingVerticalL tokens.$spacingHorizontalL;
    border-radius: tokens.$borderRadiusXLarge;
    background: tokens.$colorNeutralBackground1;
    box-shadow: tokens.$shadow4;
}

%errorDesign {
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.errorWrapper {
    @extend %containerDesign;
    @extend %errorDesign;
}

.fullSizeError {
    @extend %errorDesign;
}
