import React from 'react';
import { makeStyles } from '@fluentui/react-components';

import { InteractiveDistributionList } from '@kusto/ui-components';

import { AddFilterTooltipContent } from './AddFilterTooltip/AddFilterTooltip';

const useStyles = makeStyles({
    root: {
        padding: 0,
    },
});
export const TopValuesBarGraph: React.FC<{
    totalValues: number;
    items: { title: string; itemCount: number; addAsFilter(): void; itemKey?: React.Key }[];
    locale: undefined | string;
}> = ({ items, locale, totalValues }) => {
    const styles = useStyles();

    const itemsWithPopoverContent = items.map((item) => ({
        ...item,
        popoverContent: (
            <AddFilterTooltipContent
                addAsFilter={item.addAsFilter}
                value={item.itemCount}
                label={item.title}
                color="#118dff"
            />
        ),
        popoverSurfaceProps: { className: styles.root },
    }));

    return <InteractiveDistributionList items={itemsWithPopoverContent} totalValues={totalValues} locale={locale} />;
};
