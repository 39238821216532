@use '@kusto/style/tokens';

.navigationToolbar {
    justify-content: space-between;
}

.pinnedHeader {
    padding: tokens.$spacingVerticalSNudge tokens.$spacingHorizontalS !important;
    height: 40px;
    box-sizing: border-box;
    background-color: tokens.$colorNeutralBackground1;
    border-bottom: tokens.$strokeWidthThin solid var(--colorNeutralStroke1);
    margin-bottom: tokens.$strokeWidthThin;
}
.withExpandConnectionPane {
    padding-left: 0;
}

.pinnedHeaderText {
    display: flex;
    align-items: center;
    gap: tokens.$spacingHorizontalS;
    margin: 0;
}

.expandConnectionPane {
    border-right: tokens.$strokeWidthThin solid tokens.$colorNeutralStroke1;
}
