// Causes `./fetchStrings.dev'` and the localization files it references to not
// be present in the prod bundle.
//
// Webpack only documents this for package exports, but it appears to work for
// any file. Maybe I missed some docs.
// https://webpack.js.org/guides/package-exports/#with-nodejs-runtime-detection
if (process.env.NODE_ENV === 'production') {
    module.exports = require('./fetchStrings.prod');
} else {
    module.exports = require('./fetchStrings.dev');
}
