/* eslint-disable @typescript-eslint/no-redeclare */

import { types } from 'mobx-state-tree';

export const inactiveFetchStates = ['notStarted', 'failed', 'canceled'];
export const FUNCTION_PREFIX = '$fn_';
export const FUNCTION_PREFIX_OLD = '$function_';
export const ENTITY_GROUP_PREFIX = '$eg_';
export const ENTITY_GROUP_PREFIX_OLD = '$entityGroup_';
export const ENTITY_GROUP_MEMBER_PREFIX = '$egmember_';
export const ENTITY_GROUP_MEMBER_PREFIX_OLD = '$entityGroupMember_';
export const FOLDER_PREFIX = '$folder_';
export const FetchState = types.enumeration('FetchState', [...inactiveFetchStates, 'done', 'gotFromCache']);
export const ClusterType = types.enumeration('ClusterType', [
    'Engine',
    'DataManagement',
    'ClusterManager',
    'Bridge',
    'ResourceProvider',
    'HealthSuite',
    'Billing',
    'Gaia',
    'Proxy',
    'ServicesProcHost',
    'Flighting',
]);
export const CmSchema = types.model('CmSchema', {
    accounts: types.frozen<string[]>(),
    services: types.frozen<string[]>(),
});
export const BasicAccountInfo = types.model('BasicAccountInfo', {
    username: types.string,
    tenantId: types.string,
    tenantName: types.maybe(types.string),
});
export const ServiceOfferingType = types.enumeration('ServiceOfferingType', [
    'Azure Data Explorer',
    'Synapse Data Explorer',
    'Azure Data Explorer Personal',
    'Trident',
    'Global', //main CM
]);
export type FetchState = typeof FetchState.Type;
export type ClusterType = typeof ClusterType.Type;
export type CmSchema = typeof CmSchema.Type;
export type BasicAccountInfo = typeof BasicAccountInfo.Type;
export type ServiceOfferingType = typeof ServiceOfferingType.Type;
