import * as React from 'react';
import { useMemo, useState } from 'react';
const VirtualizerContext = React.createContext(undefined);
export const VirtualizerContextProvider = VirtualizerContext.Provider;
export const useVirtualizerContext_unstable = ()=>{
    return React.useContext(VirtualizerContext);
};
export const useVirtualizerContextState_unstable = (passedContext)=>{
    const virtualizerContext = useVirtualizerContext_unstable();
    const [_contextIndex, _setContextIndex] = useState(-1);
    /* We respect any wrapped providers while also ensuring defaults or passed through
   * Order of usage -> Passed Prop -> Provider Context -> Internal State default
   */ const _context = useMemo(()=>{
        var _ref;
        return (_ref = passedContext !== null && passedContext !== void 0 ? passedContext : virtualizerContext) !== null && _ref !== void 0 ? _ref : {
            contextIndex: _contextIndex,
            setContextIndex: _setContextIndex
        };
    }, [
        _contextIndex,
        passedContext,
        virtualizerContext
    ]);
    const context = useMemo(()=>{
        return {
            contextIndex: _context.contextIndex,
            setContextIndex: _context.setContextIndex
        };
    }, [
        _context
    ]);
    return context;
};
