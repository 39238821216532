import React, { useCallback, useEffect, useRef, useState } from 'react';
import { mergeClasses } from '@fluentui/react-components';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    DragStart,
    DragUpdate,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot,
    DropResult,
} from 'react-beautiful-dnd';

import { ScrollButton } from '../ScrollButton/ScrollButton';
import { Tab } from '../Tab/Tab';
import { TabDivider } from '../TabDivider/TabDivider';
import { useTabsBarContext } from '../TabsContext/TabsBar.context';

import styles from './TabsList.module.scss';

/**
 * Hook to detect overflow of the droppable container.
 * @param droppableRef - reference to the droppable container.
 */
function useOverflow(droppableRef: React.RefObject<HTMLDivElement>) {
    const [isOverflow, setIsOverflow] = useState(false);
    const resizeObserver = useRef<ResizeObserver | null>(null);
    useEffect(() => {
        const droppable = droppableRef.current;
        if (droppable) {
            resizeObserver.current = new ResizeObserver(() => {
                setIsOverflow(droppable.clientWidth < droppable.scrollWidth);
            });
            resizeObserver.current.observe(droppable);
        }
        return () => {
            if (resizeObserver.current) {
                resizeObserver.current.disconnect();
            }
        };
    }, [droppableRef]);

    return isOverflow;
}

/**
 * Hook to auto scroll to the selected tab, when it selected changed
 * @param droppableRef - reference to the droppable container.
 */
function useScrollToSelected(droppableRef: React.RefObject<HTMLDivElement>) {
    const context = useTabsBarContext();

    useEffect(() => {
        if (droppableRef.current) {
            const selectedIndex = context.tabsList.findIndex((tab) => tab.tabId === context.selectedTabId);
            if (-1 < selectedIndex) {
                // inside setTimeout for the first time the page load - auto-scroll the selected item
                setTimeout(() => {
                    droppableRef.current?.children // tabs
                        .item(selectedIndex)
                        ?.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'center' });
                });
            }
        }
        // Don't listen to `context.tabsList` change. We don't want to scroll to selected-tab on tab delete.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.selectedTabId]);
}

/** List of tabs component, responsible for the drag and drop of tabs inside the container. */
export const TabsList: React.FunctionComponent = () => {
    const context = useTabsBarContext();

    const onDragStart = useCallback(
        ({ draggableId, source }: DragStart) => {
            context.dragging.setState({ from: source.index });
            context.events.onTabSelect(draggableId);
        },
        [context]
    );
    const onDragUpdate = useCallback(
        ({ destination, source }: DragUpdate) => {
            context.dragging.setState({ from: source.index, to: destination?.index });
        },
        [context]
    );
    const onDragEnd = useCallback(
        ({ draggableId, source, destination }: DropResult) => {
            context.dragging.setState(undefined);

            if (!destination) {
                return; // dropped outside the list
            }

            const from = source.index;
            const to = destination.index;

            if (from === to) {
                return; // dropped in the same place
            }

            const tabsIds = context.tabsList.map((tabItem) => tabItem.tabId);

            tabsIds.splice(from, 1); // remove dragged item
            tabsIds.splice(to, 0, draggableId); // insert dragged item

            context.events.onTabsReorder(tabsIds);
        },
        [context]
    );

    // Keep reference to the droppable container for scrolling
    const droppableRef = useRef<HTMLDivElement>(null);

    // scroll the destination
    const scrollTo = (left: number) => {
        if (droppableRef.current) {
            droppableRef.current.scrollTo({ left, behavior: 'smooth' });
        }
    };

    // scroll by offset of pixels
    const scrollBy = (offset: number) => {
        if (droppableRef.current) {
            scrollTo(droppableRef.current.scrollLeft + offset);
        }
    };

    // scroll page left/right ('page' is the size of all tabs that fit in single view)
    const scrollPage = (side: 'left' | 'right') => {
        if (droppableRef.current) {
            const direction = side === 'left' ? -1 : +1;
            const offset = Math.max(droppableRef.current.clientWidth - 180 || 0, 300); // scroll offset is page width, at least 300px
            scrollBy(direction * offset);
        }
    };

    // show/hide scroll buttons when overflow (scroll-bar visible)
    const isOverflow = useOverflow(droppableRef);

    // Auto scroll to selected tab - when selected changes
    useScrollToSelected(droppableRef);

    return (
        <DragDropContext onDragStart={onDragStart} onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>
            {isOverflow && <ScrollButton side="left" onClick={() => scrollPage('left')} />}
            <Droppable droppableId="droppable" direction="horizontal">
                {(droppableProvided: DroppableProvided, droppableSnapshot: DroppableStateSnapshot) => {
                    droppableProvided.innerRef(droppableRef.current);
                    return (
                        <div
                            role="tablist"
                            className={mergeClasses(
                                styles.tabsList,
                                droppableSnapshot.isDraggingOver ? styles.draggingOver : undefined
                            )}
                            {...droppableProvided.droppableProps}
                            ref={droppableRef}
                            // horizontal scroll with vertical mouse wheel
                            onWheel={(e) => scrollBy(e.deltaY)}
                        >
                            {context.tabsList.map((tabItem, index) => (
                                <Draggable draggableId={tabItem.tabId} index={index} key={tabItem.tabId}>
                                    {(
                                        draggableProvided: DraggableProvided,
                                        _draggableSnapshot: DraggableStateSnapshot
                                    ) => (
                                        <div
                                            className={styles.tabItem}
                                            {...draggableProvided.draggableProps}
                                            {...draggableProvided.dragHandleProps}
                                            ref={draggableProvided.innerRef}
                                        >
                                            <Tab {...tabItem} />
                                            <TabDivider index={index} />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {/* reduce droppable size, if draggable element dragged outside the container */}
                            {droppableSnapshot.isDraggingOver ? droppableProvided.placeholder : null}
                        </div>
                    );
                }}
            </Droppable>
            {isOverflow && <ScrollButton side="right" onClick={() => scrollPage('right')} />}
        </DragDropContext>
    );
};
