import { PLOTLY_MSG_TYPE, PlotlyPacket } from './packet';

export type OnViolatedUrls = (violatedUrls: string[]) => void;

interface Callbacks {
    onViolatedUrls: OnViolatedUrls;
    onNewPlotErr: (err: unknown) => void;
    onUpdatePlotErr: (err: unknown) => void;
    onMissingSetupErr: () => void;
    onUnexpectedErr: (err: unknown) => void;
}

export function listenOnPort(port: MessagePort, callbacks: Callbacks) {
    port.onmessage = ({ data: plotlyPacket }: MessageEvent<PlotlyPacket>) => {
        switch (plotlyPacket.type) {
            case PLOTLY_MSG_TYPE.VIOLATED_URLS: {
                callbacks.onViolatedUrls(plotlyPacket.value);
                break;
            }
            case PLOTLY_MSG_TYPE.NEW_PLOT_ERR: {
                callbacks.onNewPlotErr(plotlyPacket.err);
                break;
            }
            case PLOTLY_MSG_TYPE.UPDATE_PLOT_ERR: {
                callbacks.onUpdatePlotErr(plotlyPacket.err);
                break;
            }
            case PLOTLY_MSG_TYPE.MISSING_SETUP_ERR: {
                callbacks.onMissingSetupErr();
                break;
            }
            case PLOTLY_MSG_TYPE.UNEXPECTED_ERR: {
                callbacks.onUnexpectedErr(plotlyPacket.err);
                break;
            }
        }
    };
}

export function sendUpdatePlotly<DataLayoutConfig>(port: MessagePort, dlc: DataLayoutConfig) {
    const packet: PlotlyPacket = {
        type: PLOTLY_MSG_TYPE.UPDATE,
        dlc,
    };

    port.postMessage(packet);
}
