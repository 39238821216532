import { assertNever } from '@kusto/utils';

import type { ColorRule } from '../../visualOptions';

export type ColorRulesVisualType = 'stat' | 'table';

export function filterColorRules(
    rules: readonly ColorRule.UColorRule[],
    visualType: ColorRulesVisualType
): readonly ColorRule.UColorRule[] {
    switch (visualType) {
        case 'table':
            return rules.map((r): ColorRule.UColorRule => {
                // Remove properties that only apply to cells
                if (
                    r.ruleType === 'colorByCondition' &&
                    r.applyTo !== 'cells' &&
                    (r.tag || r.icon || r.hideText || r.applyToColumn)
                ) {
                    return { ...r, tag: '', icon: null, hideText: false, applyToColumn: null };
                }
                return r;
            });
        case 'stat': {
            const _rules: ColorRule.UColorRule[] = [];

            for (const r of rules) {
                if (r.ruleType !== 'colorByCondition') {
                    continue;
                }

                // Stat visual only support applyTo === cells. Treat all rules
                // as this type, so more rules continue to exist after switching
                // from table to stat
                if (r.applyTo !== 'cells') {
                    _rules.push({ ...r, applyTo: 'cells' });
                } else {
                    _rules.push(r);
                }
            }

            return _rules;
        }
        default:
            assertNever(visualType);
    }
}
