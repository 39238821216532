import * as React from 'react';
import { useFluent_unstable as useFluent } from '@fluentui/react-shared-contexts';
import { applyFocusVisiblePolyfill } from '../focus/focusVisiblePolyfill';
export function useFocusVisible(options = {}) {
    const contextValue = useFluent();
    const scopeRef = React.useRef(null);
    var _options_targetDocument;
    const targetDocument = (_options_targetDocument = options.targetDocument) !== null && _options_targetDocument !== void 0 ? _options_targetDocument : contextValue.targetDocument;
    React.useEffect(()=>{
        if ((targetDocument === null || targetDocument === void 0 ? void 0 : targetDocument.defaultView) && scopeRef.current) {
            return applyFocusVisiblePolyfill(scopeRef.current, targetDocument.defaultView);
        }
    }, [
        scopeRef,
        targetDocument
    ]);
    return scopeRef;
}
