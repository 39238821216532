import React from 'react';
import { Body1Strong, Caption1Strong } from '@fluentui/react-components';

import { Icon, renderIcon, renderIconIfDefined } from '@kusto/ui-components';

import { useStrings } from '../../../hooks/useStrings';
import { CodeIcon } from '../../../kqlParser/pillToIcons';
import { KQLViewer } from '../../KQLViewer/KQLViewer';

import styles from './PillTooltip.module.scss';

export interface PillTooltipProps {
    /** Icon to display next to the readable text */
    icon?: Icon;
    /** Readable text description */
    readableText: JSX.Element | string;
    /** KQL command or query code */
    kqlText: string;
}

/** Tooltip that appear when hovering a pill with description and KQL code. */
export const PillTooltip: React.FunctionComponent<PillTooltipProps> = ({ icon, readableText, kqlText }) => {
    const { strings } = useStrings();

    return (
        <div className={styles.pillTooltip}>
            <div className={styles.descriptionSection}>
                <div className={styles.descriptionIcon}>{renderIconIfDefined(icon)}</div>
                <Body1Strong className={styles.descriptionText} role="heading">
                    {readableText}
                </Body1Strong>
            </div>
            <div className={styles.codeSection}>
                <div className={styles.codeSectionTitle}>
                    <div className={styles.codeIcon}>{renderIcon(CodeIcon)}</div>
                    <Caption1Strong>{strings.dataExplorationModal.pillsBar.kqlPreview}</Caption1Strong>
                </div>
                <KQLViewer kqlText={kqlText} hideLineNumbers className={styles.kqlCodeWrapper} />
            </div>
        </div>
    );
};
