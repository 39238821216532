import React from 'react';
import { makeStyles, shorthands, Switch, SwitchProps, tokens } from '@fluentui/react-components';

const useStyles = makeStyles({
    indicator: {
        backgroundColor: `transparent !important`,
        color: `${tokens.colorNeutralForegroundOnBrand} !important`,
        ...shorthands.borderColor(`${tokens.colorNeutralForegroundOnBrand} !important`),
    },
});

export type WhiteToggleProps = SwitchProps;

export const WhiteCustomToggle: React.FC<SwitchProps> = (props) => {
    const styles = useStyles();
    return <Switch {...props} indicator={{ className: styles.indicator }} />;
};
