import React from 'react';
import { Button, Tooltip } from '@fluentui/react-components';

import { OverflowTooltip } from '../../OverflowTooltip';
import { ButtonTooltipProps, TabItem } from '../Tabs.interfaces';

const DELAY = 500;

/** Fluent's Button wrapped inside Fluent's Tooltip, with default props and styles */
export const ButtonTooltip: React.FunctionComponent<ButtonTooltipProps> = ({ tooltip, Container, ...buttonProps }) => {
    const buttonRender = <Button aria-label={tooltip} {...buttonProps} />;

    return (
        <Tooltip content={tooltip} showDelay={DELAY} positioning="below" relationship="label">
            {Container ? <Container>{buttonRender}</Container> : <span>{buttonRender}</span>}
        </Tooltip>
    );
};

/** If `tooltip` defined - show tooltip on hover, otherwise show `tab.label` in tooltip only if content overflowing. */
export const TabTextTooltip: React.FunctionComponent<
    Pick<TabItem, 'label' | 'tooltip'> & { children: JSX.Element /* remove undefined */ }
> = ({ label, tooltip, children }) => {
    if (tooltip) {
        return (
            <Tooltip content={tooltip} showDelay={DELAY} relationship="label" withArrow>
                {children}
            </Tooltip>
        );
    } else {
        return (
            <OverflowTooltip content={label} showDelay={DELAY} withArrow>
                {children}
            </OverflowTooltip>
        );
    }
};
