import React from 'react';
import { Divider, Overflow, TagGroup } from '@fluentui/react-components';

import { useDataExplorationStore } from '../../store/DataExplorationStore';
import { AddPillButton } from './AddPillButton/AddPillButton';
import { PreviousPill, SourcePill, StatementsPill, SystemPills } from './Pills/SystemPills';
import { UserPills } from './Pills/UserPill';

import styles from './PillsBar.module.scss';

export const PillsList: React.FunctionComponent = () => {
    const { state, actions } = useDataExplorationStore();
    const { sourceKql, pills } = state;

    return (
        <TagGroup
            className={styles.pillsGroup}
            onDismiss={() => {
                if (pills.length) {
                    actions.removePill();
                } else {
                    actions.removePipe();
                }
            }}
        >
            <Overflow overflowDirection="start">
                <div className={styles.pillsList}>
                    <StatementsPill statements={sourceKql.prefixStatements} />
                    <SourcePill source={sourceKql.source} />
                    {sourceKql.pipes.length ? <Divider vertical className={styles.divider} /> : null}
                    <PreviousPill pipes={sourceKql.pipes} pills={pills} />
                    <SystemPills pipes={sourceKql.pipes} readonly={Boolean(pills.length)} />
                    <UserPills />
                    <Divider vertical className={styles.divider} />
                    <AddPillButton />
                </div>
            </Overflow>
        </TagGroup>
    );
};
