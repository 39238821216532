import React, { useMemo, useState } from 'react';
import { MenuItem, MenuList, Spinner } from '@fluentui/react-components';

import type { DataTableColumn } from '@kusto/client';
import { SearchBoxDebounce } from '@kusto/ui-components';

import { useQueryResults } from '../../../hooks/useResults';
import { useStrings } from '../../../hooks/useStrings';

export const ColumnSelect: React.FC<{ onSelect: (column: DataTableColumn) => void }> = ({ onSelect }) => {
    const computedResults = useQueryResults();
    const { strings } = useStrings();
    const [searchText, setSearchText] = useState('');

    const filteredFields = useMemo(() => {
        if (!computedResults.data) {
            return [];
        }
        const { fields } = computedResults.data.dataFrame;
        return fields.filter((f) => f.name.toLowerCase().includes(searchText.toLowerCase()));
    }, [computedResults.data, searchText]);

    if (computedResults.isLoading) {
        return <Spinner />;
    }
    if (computedResults.error) {
        return <>{strings.dataExplorationModal.pillsBar.errorLoadingData}</>;
    }

    return (
        <>
            <SearchBoxDebounce
                searchText={searchText}
                style={{ width: '100%' }}
                onChange={(string) => setSearchText(string)}
                placeholder={strings.dataExplorationModal.pillsBar.searchColumnsPlaceholder}
            />
            {filteredFields.length ? (
                <MenuList>
                    {filteredFields.map((field) => (
                        <MenuItem
                            key={field.name}
                            onClick={() => onSelect({ ColumnName: field.name, ColumnType: field.type })}
                        >
                            {field.name}
                        </MenuItem>
                    ))}
                </MenuList>
            ) : (
                <>{strings.dataExplorationModal.pillsBar.noColumnsFound}</>
            )}
        </>
    );
};
