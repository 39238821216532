import type { KweAgGridLocale } from '@kusto/ag-grid';
import type { ArgumentColumnType, ChartingLocale, DataItem } from '@kusto/charting';
import type * as kusto from '@kusto/client';
import type { IKweTelemetry, KweUtilsLocale, Locale, Result, Theme } from '@kusto/utils';
import type { VisualMessageFormatter } from '@kusto/visual-fwk';

import { ChartEvents } from './components/Chart';
import { ExtendedVisualizationOptions } from './utils/visualization';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/// @ts-ignore
export type VisualizationsStrings = typeof import('../locale/en.json');

export interface VisualizationsLocale extends KweAgGridLocale, KweUtilsLocale, ChartingLocale {
    readonly visualizations: VisualizationsStrings;
}

export type LegendPosition = 'bottom' | 'left' | 'right';

/**
 * Visualization properties for a chart. including the Kusto Visualization Options, and other presentational knobs.
 */
export interface ChartProps {
    /**
     * Kusto visualization options as supported by Kusto render command
     */
    readonly visualizationOptions: ExtendedVisualizationOptions;
    /**
     * A bunch of strings that the component displays upon errors
     */
    readonly strings: VisualizationsLocale;
    readonly telemetry: IKweTelemetry;
    readonly locale: undefined | Locale;
    readonly rows: Rows;
    readonly columns: Columns;
    readonly legendPosition?: LegendPosition;
    readonly enableInteractiveLegend?: boolean;
    readonly theme?: Theme;
    readonly useBoost?: boolean;
    readonly showAllSeriesOnHover?: boolean;
    readonly disableAnimation?: boolean;
    readonly events?: Partial<ChartEvents>;
    readonly azureMapSubscriptionKey?: string;
    readonly timezone: string;
    readonly formatMessage?: VisualMessageFormatter;
    readonly chartTitle?: string;
    /**
     * If true, chart is rendered even if series or point limits are exceeded
     */
    readonly ignoreChartLimits?: boolean;
}

/**
 * These are the properties that top-level charting components (BarChart, AreaChart) expose.
 * This means that these components expect a DataItem array, which means that these components are returned
 * only after that chart component ran the Rows and Columns through the Kusto.Charting library.
 */
export interface InternalChartProps
    extends Pick<
            ChartProps,
            | 'visualizationOptions'
            | 'locale'
            | 'showAllSeriesOnHover'
            | 'azureMapSubscriptionKey'
            | 'timezone'
            | 'ignoreChartLimits'
            | 'legendPosition'
        >,
        Partial<
            Pick<
                ChartEvents,
                'onPointClick' | 'onHighChartsDragXEnd' | 'onHighchartsPointMenuItems' | 'disableChartLimits'
            >
        > {
    readonly colors: readonly string[];
    readonly animationDuration: number;
    // Added while enabling lints
    // eslint-disable-next-line @typescript-eslint/ban-types
    readonly tooltipStyle?: Object;
    // Added while enabling lints
    // eslint-disable-next-line @typescript-eslint/ban-types
    readonly tickStyle?: Object;
    // Added while enabling lints
    // eslint-disable-next-line @typescript-eslint/ban-types
    readonly tooltipItemStyle?: Object;
    readonly hideLegend?: boolean;
    readonly fullWidthLegend?: boolean;
    readonly legendColor?: (defaultColor: string, seriesName: string) => string;
    readonly legendPosition?: LegendPosition;
    readonly enableInteractiveLegend?: boolean;
    readonly isDarkTheme?: boolean;
    readonly highPerformanceMode?: boolean;
    readonly chartTitle?: string;
    readonly dataItemsLength: number;
    readonly formatMessage: VisualMessageFormatter;
    readonly strings: VisualizationsStrings;
}

export type Columns = readonly kusto.KustoColumn[] | undefined;

export type Rows = readonly kusto.KustoQueryResultRowObject[];

export type ChartError =
    | 'NoRows'
    | 'ServerSideError'
    | 'LessThen2Columns'
    | 'DotNetLibraryHeuristicReturnedError'
    | 'DotNetLibraryHeuristicReturnedNoData'
    | 'UnknownVisualization'
    | 'MultiplePanelsLimitationExceeded';

export interface DataItemWithRow extends DataItem {
    /**
     * If each data items is associated with a specific row, a reference to the
     * appropriate row is added to each data item.
     */
    row?: kusto.KustoQueryResultRowObject;
}

/**
 * We accept the data item as an abstract type because
 * not all the times will the data item be `DataItemWithRow`.
 * For example, in rtd-provider, the data item is usually
 * customized for the specific visual.
 */
export interface KustoHeuristicsOk<D = DataItemWithRow> {
    readonly dataItems: readonly D[];
    readonly metaData: undefined | Kusto.Charting.IChartMetaData;
    readonly argumentType: undefined | ArgumentColumnType;
}
export interface KustoHeuristicsErr {
    readonly type: ChartError;
    readonly message: string;
    readonly title?: string;
}

export interface YAxisSeriesMap {
    [key: string]: string[];
}

export type KustoHeuristicsResult = Result<KustoHeuristicsOk, KustoHeuristicsErr>;
