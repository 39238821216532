import type { FeatureFlagList, FeatureFlags, FeatureFlagsSetting } from './types';

export function mergeFeatureFlagLists<Flag extends string>(...flags: FeatureFlagList<Flag>[]): FeatureFlagList<Flag> {
    const mergedFlags: Record<string, (readonly [Flag, string] | Flag)[]> = {};

    for (const obj of flags) {
        for (const [sectionName, flags] of Object.entries(obj)) {
            const section = mergedFlags[sectionName];
            if (section) {
                section.push(...flags);
            } else {
                mergedFlags[sectionName] = [...flags];
            }
        }
    }

    return mergedFlags;
}

export type FlagsConfigList<Flag extends string = string, Origin extends string = string> = {
    readonly [K in Origin]: undefined | FeatureFlagsSetting<Flag>;
};

export function mergeFlagsConfig<Flag extends string>(
    config: FlagsConfigList<Flag>
): Map<Flag, { readonly value: boolean; readonly source: string }> {
    const _flags = new Map<Flag, { readonly value: boolean; readonly source: string }>();

    for (const [source, flags] of Object.entries(config)) {
        if (!flags) {
            continue;
        }

        for (const [flag, value] of Object.entries(flags) as [Flag, undefined | boolean][]) {
            if (value !== undefined) {
                _flags.set(flag, { value, source });
            }
        }
    }

    return _flags;
}

export function createFlagsObj<Flag extends string = string>(getFlag: (flag: Flag) => boolean): FeatureFlags<Flag> {
    return new Proxy({} as FeatureFlags<Flag>, {
        get(_target, flag) {
            return getFlag(flag as Flag);
        },
    });
}

export function* iterFlagsList<Flag extends string>(flags: FeatureFlagList<Flag>): Generator<Flag, void> {
    for (const section of Object.values(flags)) {
        for (const flag of section) {
            if (typeof flag === 'string') {
                yield flag;
            } else {
                yield flag[0];
            }
        }
    }
}
