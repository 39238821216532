import * as React from 'react';
import { useArrowNavigationGroup } from '@fluentui/react-tabster';
import { getIntrinsicElementProps, useControllableState, useEventCallback, useMergedRefs, slot } from '@fluentui/react-utilities';
/**
 * Create the state required to render TabList.
 *
 * The returned state can be modified with hooks such as useTabListStyles_unstable,
 * before being passed to renderTabList_unstable.
 *
 * @param props - props from this instance of TabList
 * @param ref - reference to root HTMLElement of TabList
 */ export const useTabList_unstable = (props, ref)=>{
    const { appearance = 'transparent', reserveSelectedTabSpace = true, disabled = false, onTabSelect, selectTabOnFocus = false, size = 'medium', vertical = false } = props;
    const innerRef = React.useRef(null);
    const focusAttributes = useArrowNavigationGroup({
        circular: true,
        axis: vertical ? 'vertical' : 'horizontal',
        memorizeCurrent: true
    });
    const [selectedValue, setSelectedValue] = useControllableState({
        state: props.selectedValue,
        defaultState: props.defaultSelectedValue,
        initialState: undefined
    });
    // considered usePrevious, but it is sensitive to re-renders
    // this could cause the previous to move to current in the case where the tab list re-renders.
    // these refs avoid getRegisteredTabs changing when selectedValue changes and causing
    // renders for tabs that have not changed.
    const currentSelectedValue = React.useRef(undefined);
    const previousSelectedValue = React.useRef(undefined);
    React.useEffect(()=>{
        previousSelectedValue.current = currentSelectedValue.current;
        currentSelectedValue.current = selectedValue;
    }, [
        selectedValue
    ]);
    const onSelect = useEventCallback((event, data)=>{
        setSelectedValue(data.value);
        onTabSelect === null || onTabSelect === void 0 ? void 0 : onTabSelect(event, data);
    });
    const registeredTabs = React.useRef({});
    const onRegister = useEventCallback((data)=>{
        registeredTabs.current[JSON.stringify(data.value)] = data;
    });
    const onUnregister = useEventCallback((data)=>{
        delete registeredTabs.current[JSON.stringify(data.value)];
    });
    const getRegisteredTabs = React.useCallback(()=>{
        return {
            selectedValue: currentSelectedValue.current,
            previousSelectedValue: previousSelectedValue.current,
            registeredTabs: registeredTabs.current
        };
    }, []);
    return {
        components: {
            root: 'div'
        },
        root: slot.always(getIntrinsicElementProps('div', {
            // FIXME:
            // `ref` is wrongly assigned to be `HTMLElement` instead of `HTMLDivElement`
            // but since it would be a breaking change to fix it, we are casting ref to it's proper type
            ref: useMergedRefs(ref, innerRef),
            role: 'tablist',
            'aria-orientation': vertical ? 'vertical' : 'horizontal',
            ...focusAttributes,
            ...props
        }), {
            elementType: 'div'
        }),
        appearance,
        reserveSelectedTabSpace,
        disabled,
        selectTabOnFocus,
        selectedValue,
        size,
        vertical,
        onRegister,
        onUnregister,
        onSelect,
        getRegisteredTabs
    };
};
