import * as kusto from '@kusto/client';

/**
 * @deprecated in favor of {@kusto/kusto-schema.EntityType}
 */
export enum EntityType {
    Cluster = 'Cluster',
    Database = 'Database',
    Table = 'Table',
    ExternalTable = 'ExternalTable',
    MaterializedViewTable = 'MaterializedViewTable',
    Function = 'Function',
    ViewFunction = 'ViewFunction',
    Column = 'Column',
    Folder = 'Folder',
    FunctionsFolder = 'FunctionsFolder',
    TablesFolder = 'TablesFolder',
    ExternalTableFolder = 'ExternalTableFolder',
    MaterializedViewTableFolder = 'MaterializedViewTableFolder',
    LoadingIndicator = 'LoadingIndicator',
    EntityGroup = 'EntityGroup',
    EntityGroupFolder = 'EntityGroupFolder',
    EntityGroupMember = 'EntityGroupMember',
}

/**
 * Represents a kusto column in the connection pane.
 * This is not modeled as a mobx-state-tree entity since it's immutable thus we don't need change tracking for it
 * This results in a lot less memory consumption (since observable entities are much more memory intensive).
 * Originally this was an mst entity but we had out-of-memory crashes in clusters with lots of tables.
 */
export interface Column {
    id: string;
    name: string;
    type: kusto.ColumnType;
    entityType: EntityType.Column;
    docstring?: string;
}

export interface EntityWithFolder {
    id: string;
    name: string;
    folder?: string;
}

export type TableEntityTypes = EntityType.Table | EntityType.ExternalTable | EntityType.MaterializedViewTable;

/**
 * Represents a kusto table in the connection pane
 */
export interface Table extends EntityWithFolder {
    columns: { [id: string]: Column };
    docstring?: string;
    entityType: TableEntityTypes;
}
export interface ScalarParameter {
    name: string;
    type?: string;
    cslType?: string;
    cslDefaultValue?: string;
}

export type FunctionParameter = ScalarParameter & {
    columns?: ScalarParameter[];
};

export type FunctionEntityTypes = EntityType.Function | EntityType.ViewFunction;

/**
 * Represents a kusto function in the connection pane
 */
export interface Function extends EntityWithFolder {
    inputParameters: FunctionParameter[];
    body: string;
    docstring?: string;
    functionKind: string;
    outputColumns: string[];
    entityType: FunctionEntityTypes;
}

export interface EntityGroupMember {
    id: string;
    name: string;
    entityType: EntityType.EntityGroupMember;
}

export interface EntityGroup extends EntityWithFolder {
    entityType: EntityType.EntityGroup;
    entities: { [id: string]: EntityGroupMember };
}

export const Keys = {
    enter: 'Enter',
    tab: 'Tab',
    space: ' ',
};
