import * as React from 'react';
import { IStyle, ITheme } from '@fluentui/react';
import { classNamesFunction, IStyleFunctionOrObject, KeyCodes, styled } from '@fluentui/utilities';
import { mergeClasses } from '@griffel/core';

import { hexToRgb } from '@kusto/utils';

const getClassNames = classNamesFunction<SplitPaneStyleProps, SplitPaneStyles>();

export type SplitPaneStyleProps = Required<Pick<SplitPaneProps, 'theme' | 'disableResize'>>;

export interface SplitPaneStyles {
    root: IStyle;
    divider: IStyle;
}

const getStyles = (props: SplitPaneStyleProps): SplitPaneStyles => {
    const { theme, disableResize } = props;
    const { palette } = theme;

    const { r, g, b } = hexToRgb(palette.black)!;
    return {
        root: [
            'splitPane',
            {
                display: 'flex',
                flexDirection: 'column',
            },
            {
                // Fix height increasing issue
                height: '100%',
                width: '100%',
                position: 'absolute',
            } satisfies IStyle,
        ],
        divider: {
            cursor: disableResize ? 'default' : 'ns-resize', // resize icon rather than pointer
            borderWidth: '3px 0px', // we want the draggable surface to be larger than 1px
            borderColor: 'transparent',
            backgroundClip: 'content-box', // don't show gray background on the entire 5px
            boxSizing: 'border-box',
            height: 4,
            marginTop: -4,
            zIndex: 1,
            flexShrink: 0,

            background: `linear-gradient(rgba(${r}, ${g}, ${b}, 0.42), rgba(${r}, ${g}, ${b}, 0.42)) content-box no-repeat 0 2px / 100% 1px`,
            selectors: {
                ':hover': !disableResize && {
                    transition: 'all 2s ease',
                    borderLeftColor: 'transparent',
                    borderRightColor: 'transparent',
                    backgroundColor: ` rgba(${r}, ${g}, ${b}, 0.42)`,
                },
            },
        },
    };
};

interface State {
    firstFlexBasis: string;
    secondFlexBasis: string;
}

interface SplitPaneProps {
    firstPaneSize?: number;
    styles?: IStyleFunctionOrObject<SplitPaneStyleProps, SplitPaneStyles>;
    theme?: ITheme;
    children?: JSX.Element[];
    firstPaneMinHeight?: number;
    secondPaneMinHeight?: number;
    sliderAriaLabel?: string;
    disableResize?: boolean;
    hideDivider?: boolean;
    onMouseUp?: (firstComponentPercent?: number, secondComponentPercent?: number, containerHeight?: number) => void;

    topDivProps?: React.HTMLAttributes<HTMLDivElement>;
    bottomDivProps?: React.HTMLAttributes<HTMLDivElement>;
}

/**
 * Split pane implementation (yeah reinventing the wheel and all - but sometimes trying to find wheels that fit is
 * harder then just inventing one).
 */
export class BaseSplitPane extends React.Component<SplitPaneProps, State> {
    // true during resize operation
    private isResizing = false;

    // relevant DOM size measurements
    private sizes?: {
        containerHeight: number;
        firstComponentTop: number;
        secondComponentBottom: number;
    };

    // DOM element for the root div
    private splitPane: HTMLDivElement | null = null;

    // DOM element for first component
    private firstComponent: HTMLDivElement | null = null;

    // DOM element for first component
    private secondComponent: HTMLDivElement | null = null;

    constructor(props: SplitPaneProps) {
        super(props);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);

        this.state = {
            firstFlexBasis: props.firstPaneSize != null ? `${this.props.firstPaneSize}%` : '50%',

            secondFlexBasis: props.firstPaneSize != null ? `${100 - this.props.firstPaneSize!}%` : '50%',
        };
    }

    onMouseDown() {
        // Originally this call was done in componentDidMount, but turns out that the sizes aren't final then (since
        // parent component isn't mounted yet and thus certain sizes still need to be adjusted like 100% height that
        // depends on size of parent)
        this.updateSizes();
        this.isResizing = true;
    }

    private convertPercentageToNumber(percentage: string) {
        return parseInt(percentage.slice(0, -1), 10);
    }

    onMouseUp() {
        this.isResizing = false;
        this.props.onMouseUp?.(
            this.convertPercentageToNumber(this.state.firstFlexBasis),
            this.convertPercentageToNumber(this.state.secondFlexBasis),
            this.sizes?.containerHeight
        );
    }

    /**
     * Handles size calculations when resizing.
     * @param event mouse move event
     */
    onMouseMove(event: MouseEvent) {
        if (!this.isResizing) {
            return;
        }

        this.unFocus();
        if (
            !this.sizes ||
            event.clientY < this.sizes.firstComponentTop ||
            event.clientY > this.sizes.secondComponentBottom
        ) {
            return;
        }

        const newDividerCenterY = event.clientY;

        const newFirstComponentHeight = newDividerCenterY - this.sizes.firstComponentTop;
        const newSecondComponentHeight = this.sizes.secondComponentBottom - newDividerCenterY;

        const firstFlexBasis =
            (newFirstComponentHeight / (newFirstComponentHeight + newSecondComponentHeight)) * 100 + '%';
        const secondFlexBasis =
            (newSecondComponentHeight / (newFirstComponentHeight + newSecondComponentHeight)) * 100 + '%';

        this.setState({
            firstFlexBasis,
            secondFlexBasis,
        });
    }

    componentDidMount() {
        document.addEventListener('mouseup', this.onMouseUp);
        document.addEventListener('mousemove', this.onMouseMove);
    }

    UNSAFE_componentWillMount() {
        document.removeEventListener('mouseup', this.onMouseUp);
        document.addEventListener('mousemove', this.onMouseMove);
    }

    componentDidUpdate(prev: SplitPaneProps) {
        if (prev.firstPaneSize !== this.props.firstPaneSize) {
            if (this.props.firstPaneSize != null) {
                this.setState({
                    firstFlexBasis: `${this.props.firstPaneSize}%`,
                    secondFlexBasis: `${100 - this.props.firstPaneSize}%`,
                });
            }
        }
    }

    onKeyboardResize = (keyPressEvent: React.KeyboardEvent<HTMLDivElement>) => {
        const percentageChange = 10;
        // Take the number out of the string, for example: '90%' -> 90
        const firstFlexNumber = parseInt(this.state.firstFlexBasis.slice(0, -1), 10);
        if (isNaN(firstFlexNumber)) {
            return;
        }
        const secondFlexNumber = 100 - firstFlexNumber;
        switch (keyPressEvent.keyCode) {
            case KeyCodes.up: {
                this.setState({
                    firstFlexBasis: `${Math.max(0, firstFlexNumber - percentageChange)}%`,
                    secondFlexBasis: `${Math.min(100, secondFlexNumber + percentageChange)}%`,
                });
                break;
            }
            case KeyCodes.down: {
                this.setState({
                    firstFlexBasis: `${Math.min(100, firstFlexNumber + percentageChange)}%`,
                    secondFlexBasis: `${Math.max(0, secondFlexNumber - percentageChange)}%`,
                });
                break;
            }
            default:
                return;
        }
    };

    render() {
        const { firstFlexBasis, secondFlexBasis } = this.state;
        // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-non-null-assertion
        const first: React.ReactElement<{}> = this.props.children![0];
        // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-non-null-assertion
        const second: React.ReactElement<{}> = this.props.children![1];
        const firstPaneMinHeight = this.props.firstPaneMinHeight ?? 0;
        const secondPaneMinHeight = this.props.secondPaneMinHeight ?? 0;

        const firstComponentStyle: React.CSSProperties = {
            flex: `0 1 ${firstFlexBasis}`,
            height: firstFlexBasis,
            minHeight: `${firstPaneMinHeight}px`,
        };
        const secondComponentStyle: React.CSSProperties = {
            flex: `0 1 ${secondFlexBasis}`,
            minHeight: `${secondPaneMinHeight}px`,
        };

        const classNames = getClassNames(this.props.styles!, {
            theme: this.props.theme!,
            disableResize: this.props.disableResize ?? false,
        });
        const ariaValueNow = Number(firstFlexBasis.split('%')[0]);
        return (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <div className={classNames.root} ref={(domElement) => (this.splitPane = domElement)}>
                    <div
                        {...this.props.topDivProps}
                        className={mergeClasses('splitPaneFirstComponent', this.props.bottomDivProps?.className)}
                        style={firstComponentStyle}
                        ref={(domElement) => (this.firstComponent = domElement)}
                    >
                        {React.cloneElement(first)}
                    </div>
                    {!this.props.hideDivider && (
                        <div
                            className={classNames.divider}
                            onMouseDown={this.props.disableResize ? undefined : this.onMouseDown}
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            tabIndex={0}
                            role="slider"
                            aria-label={this.props.sliderAriaLabel}
                            onKeyDown={this.onKeyboardResize}
                            aria-valuenow={ariaValueNow}
                        />
                    )}
                    <div
                        {...this.props.bottomDivProps}
                        className={mergeClasses('splitPaneSecondComponent', this.props.bottomDivProps?.className)}
                        style={secondComponentStyle}
                        ref={(domElement) => (this.secondComponent = domElement)}
                    >
                        {React.cloneElement(second)}
                    </div>
                </div>
            </div>
        );
    }

    private updateSizes() {
        this.sizes = this.getComponentSizesFromDom();
    }

    private getComponentSizesFromDom() {
        // This should not happen since ref resolution happens before componentDidMount
        if (!this.firstComponent || !this.secondComponent || !this.splitPane) {
            throw new Error('either firstCompoennt secondComponent or splitPane dom refs are null');
        }

        const splitPaneRect = this.splitPane.getBoundingClientRect();
        const containerHeight = splitPaneRect.height;

        const firstComponentRect = this.firstComponent.getBoundingClientRect();
        const firstComponentTop = firstComponentRect.top;

        const secondComponentRect = this.secondComponent.getBoundingClientRect();
        const secondComponentBottom = secondComponentRect.bottom;

        return {
            containerHeight,
            firstComponentTop,
            secondComponentBottom,
        };
    }

    private unFocus() {
        try {
            const selection = window.getSelection();
            if (selection) {
                selection.removeAllRanges();
            }
        } catch (e) {
            // Do nothing
        }
    }
}

export const SplitPane = styled<SplitPaneProps, SplitPaneStyleProps, SplitPaneStyles>(BaseSplitPane, getStyles);
