import React from 'react';
import { DefaultButton, PrimaryButton, Stack, Text } from '@fluentui/react';
import { Link48Regular } from '@fluentui/react-icons';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react-lite';

import { formatLiterals } from '@kusto/utils';

import type { RtdProviderLocale } from '../../../i18n';
import type { ViolatedUrls } from './Iframe';
import type { AddKind } from './useOriginAllowlist';

import * as styles from './styles.module.scss';

interface InnerInlinePromptProps {
    t: RtdProviderLocale;
    origin: string;
    urls: string[];
    onTemporaryClick: (origin: string) => void;
    onPermanentClick: (origin: string) => void;
}

export const InnerInlinePrompt: React.FC<InnerInlinePromptProps> = observer(function InnerInlinePrompt({
    t,
    origin,
    urls,
    onTemporaryClick,
    onPermanentClick,
}) {
    const { onJustOnceClick, onAlwaysClick } = React.useMemo(
        () => ({
            onJustOnceClick: debounce(() => onTemporaryClick(origin), 200),
            onAlwaysClick: debounce(() => onPermanentClick(origin), 200),
        }),
        [origin, onTemporaryClick, onPermanentClick]
    );

    const plotlyStrings = t.rtdProvider.visuals.plotly;

    return (
        <Stack verticalAlign="center" className={styles.promptContainer}>
            <Stack horizontal verticalAlign="center" className={styles.leading}>
                <Link48Regular />
                <Text className={styles.leadingText} variant="xLarge">
                    {plotlyStrings.csp.prompt.title}
                </Text>
            </Stack>
            <Text variant="large" className={styles.grayText}>
                {formatLiterals(plotlyStrings.csp.prompt.description, { origin })}
            </Text>
            <ul>
                {urls.map((url) => (
                    <li key={url}>
                        <a title={url} className={styles.cspUrl} href={url} rel="noreferrer noopenner" target="_blank">
                            {url}
                        </a>
                    </li>
                ))}
            </ul>
            <Stack horizontal className={styles.buttonRow}>
                <PrimaryButton onClick={onJustOnceClick}>{plotlyStrings.csp.prompt.justOnceButtonLabel}</PrimaryButton>
                <DefaultButton onClick={onAlwaysClick}>{plotlyStrings.csp.prompt.alwaysButtonLabel}</DefaultButton>
            </Stack>
            <Text variant="medium" className={classNames(styles.grayText, styles.helperText)}>
                {plotlyStrings.csp.prompt.helperText}
            </Text>
        </Stack>
    );
});

export interface InlinePromptProps {
    t: RtdProviderLocale;
    violatedUrls: ViolatedUrls;
    onComplete: (results: Results) => void;
}

type Results = Record<AddKind, string[]>;

export const InlinePrompt: React.FC<InlinePromptProps> = ({ violatedUrls, onComplete, ...innerInlineProps }) => {
    const [currentIndex, setCurrentIndex] = React.useState(0);

    const [results, setResults] = React.useState<Results>({ permanent: [], temporary: [] });

    const update = (nextResults: Results) => {
        if (currentIndex + 1 > violatedUrls.length - 1) {
            onComplete(nextResults);
        } else {
            setResults(nextResults);
            setCurrentIndex(currentIndex + 1);
        }
    };

    const onTemporaryClick: InnerInlinePromptProps['onTemporaryClick'] = (origin) => {
        const nextResults: Results = { ...results, temporary: [...results.temporary, origin] };
        update(nextResults);
    };

    const onPermanentClick: InnerInlinePromptProps['onPermanentClick'] = (origin) => {
        const nextResults: Results = { ...results, permanent: [...results.permanent, origin] };
        update(nextResults);
    };

    const [origin, urls] = violatedUrls[currentIndex];

    return (
        <InnerInlinePrompt
            {...innerInlineProps}
            origin={origin}
            urls={urls}
            onTemporaryClick={onTemporaryClick}
            onPermanentClick={onPermanentClick}
        />
    );
};
