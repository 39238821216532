import React from 'react';
import { Button, Subtitle1 } from '@fluentui/react-components';

import { Icon } from '../Icon';
import { OverflowTooltip } from '../OverflowTooltip';

import styles from './BreadcrumbNavigation.module.scss';

export interface BreadcrumbNavigationProps {
    /** Icon to display at the beginning of the navigation */
    icon?: Icon;
    /** List of navigation items */
    navigationList: {
        /** Label to display for the navigation item */
        label: string;
        /** Callback to execute when the navigation item is clicked */
        onClick?: () => void;
    }[];
}

export const BreadcrumbNavigation: React.FunctionComponent<BreadcrumbNavigationProps> = ({
    icon: Icon,
    navigationList,
}) => {
    const getLabel = (label: string) => (
        <OverflowTooltip content={label}>
            <Subtitle1 truncate wrap={false}>
                {label}
            </Subtitle1>
        </OverflowTooltip>
    );

    return (
        <div className={styles.containerStyle} data-testid="breadcrumbs-navigation">
            {Icon && <Icon className={styles.iconStyle} />}
            {navigationList.map((item, index) => (
                <div key={index} className={styles.navItem}>
                    {item.onClick ? (
                        <Button appearance="subtle" className={styles.buttonStyle} onClick={item.onClick}>
                            {getLabel(item.label)}
                        </Button>
                    ) : (
                        <div className={styles.buttonStyle}>{getLabel(item.label)}</div>
                    )}
                    {index < navigationList.length - 1 ? <Subtitle1>/</Subtitle1> : undefined}
                </div>
            ))}
        </div>
    );
};
