import React from 'react';

/**
 * Creates an abort signal that aborts when the component un-mounts.
 *
 * Only returns the signal because the signal should always represent the
 * lifetime of the component. If you need a signal for a different lifetime, use
 * something else, like creating `new AbortController` and aborting it yourself.
 *
 * Things that should be disposed along with a component include callbacks, and
 * async operations as a result of callbacks.
 *
 * @example
 * ```typescript
 * const Component: React.FC = () => {
 *   const abortSignal = useAbortSignal();
 *   const dashboardService = useDashboardService();
 *
 *
 *   const onClick = async () => {
 *      // By passing the signal, the ajax request will be canceled if the component unmounts
 *      const res = await dashboardService.fetch({ signal: abortSignal });
 *      switch (res.kind) {
 *        case 'abort':
 *          return; // Do nothing, component is not mounted
 *        // Handle other cases
 *      }
 *   }
 *
 *   Return <button onClick>Foo</button>
 * };
 * ```
 *
 * Things called via `useEffect` should _not_ use this controller, and should
 * instead create a controller for each instance of the effect, aborting it in
 * the effect disposer.
 *
 * @example
 * `React.useEffect` + `AbortController` example:
 * ```typescript
 * React.useEffect(() => {
 *   const controller = new AbortController();
 *
 *   // Do async stuff
 *   fetch(`<url>${id}`, { signal: controller.signal });
 *
 *   // Controller is aborted along with each effect, so changing the id aborts
 *   // the previous async operation
 *   return () => controller.abort();
 * }, [id]);
 * ```
 */
export function useAbortSignal(): AbortSignal {
    const [controller] = React.useState(() => new AbortController());

    React.useEffect(() => () => controller.abort(), [controller]);

    return controller.signal;
}
