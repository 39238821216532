import React from 'react';

export type Thunk<S, A> = (dispatch: React.Dispatch<A | Thunk<S, A>>, getState: () => S) => void;

/**
 * React.useReducer with thunk support, and a 3rd "getState" function. Thunk
 * support similar to redux-thunk[^1].
 *
 * [^1] https://github.com/reduxjs/redux-thunk
 *
 * @example Can be called the same as `React.useReducer`
 * ```
 * import { useThunkReducer } from '@kusto/utils';
 *
 * const MyComponent = () => {
 *   const [state, dispatch, getState] = useThunkReducer(reducer, initialState);
 *
 *   // ...
 * }
 * ```
 *
 * @example Writing a thunk
 * ```
 * import { useThunkReducer, Thunk, assertNever } from '@kusto/utils';
 *
 * type Action = { readonly kind: 'increment' };
 * type State = number;
 *
 * const incrementIfOdd: Thunk<State, Action> = (dispatch, getState) => {
 *   if (getState() % 2 === 1) {
 *       dispatch({ kind: 'increment' })
 *   }
 * }
 *
 * // Regular React.useReducer reducer
 * function reducer(prevState: State, action: Action): State {
 *   switch (action.kind) {
 *     case 'increment:
 *       return state + 1;
 *     default:
 *       assertNever(action);
 *   }
 * }
 *
 * const initialState: State = 0;
 *
 * const MyComponent = () => {
 *   const [state, dispatch, getState] = useThunkReducer(reducer, initialState);
 *
 *   <button onClick={() => dispatch(incrementIfOdd)}>Value: {state.toLocalString()}</button>
 * }
 * ```
 */

export function useThunkReducer<S, A, I>(
    reducer: React.Reducer<S, A>,
    initialArg: I,
    init: (a: I) => S
): [S, React.Dispatch<A | Thunk<S, A>>, () => S];

export function useThunkReducer<S, A>(
    reducer: React.Reducer<S, A>,
    initialArg: S,
    init?: undefined
): [S, React.Dispatch<A | Thunk<S, A>>, () => S];

export function useThunkReducer<S, A, I>(
    reducer: React.Reducer<S, A>,
    initialArg: I,
    init = (a: I): S => a as unknown as S
): [S, React.Dispatch<A | Thunk<S, A>>, () => S] {
    const [hookState, setHookState] = React.useState(init(initialArg));

    const state = React.useRef(hookState);

    const actions = React.useMemo(() => {
        const setState = (newState: S) => {
            state.current = newState;
            setHookState(newState);
        };

        const reduce = (action: A) => reducer(getState(), action);
        const getState = () => state.current;
        const dispatch = (action: A | Thunk<S, A>) =>
            typeof action === 'function' ? (action as Thunk<S, A>)(dispatch, getState) : setState(reduce(action));

        return [dispatch, getState] as const;
    }, [reducer]);

    return [hookState, ...actions];
}
