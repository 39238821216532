import React from 'react';
import {
    Body2,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Subtitle1,
} from '@fluentui/react-components';
import { ChevronDown20Regular, Folder16Regular, Table16Regular, Table20Regular } from '@fluentui/react-icons';

import { Table } from '../../../../common';

import * as styles from './TablePicker.module.scss';

export const TablePickerDropdown: React.FC<{
    clusterAlias: string;
    databaseName: string;
    databaseTables: Table[];
    selectedTable: Table;
    setSelectedTableId: (id: string) => void;
}> = ({ clusterAlias, databaseName, databaseTables, selectedTable, setSelectedTableId }) => {
    const tableName = selectedTable?.name;

    //for empty strings
    const selectedTableFullPathParts = clusterAlias ? [clusterAlias, databaseName] : [databaseName];
    if (selectedTable?.folder) {
        selectedTableFullPathParts.push(selectedTable.folder);
    }

    const selectedTableFullPath = selectedTableFullPathParts.join(' / ');

    return (
        <div>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <MenuButton
                        icon={<Table20Regular />}
                        appearance="transparent"
                        size="large"
                        className={styles.tablePickerButton}
                        menuIcon={<ChevronDown20Regular />}
                    >
                        <Subtitle1>{tableName}</Subtitle1>
                    </MenuButton>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <TableList tables={databaseTables} setSelectedTableId={setSelectedTableId} />
                    </MenuList>
                </MenuPopover>
            </Menu>
            <Body2 block className={styles.tablePath}>
                {selectedTableFullPath}
            </Body2>
        </div>
    );
};

const TableList: React.FC<{ tables: Table[]; setSelectedTableId: (id: string) => void }> = ({
    tables,
    setSelectedTableId,
}) => {
    const { tablesWithFolders, noFolderTables } = React.useMemo(() => {
        const tablesWithFolders: Record<string, Table[]> = {};
        const noFolderTables: Table[] = [];
        tables.forEach((table) => {
            if (table.folder) {
                const [currentLevel, ...subLevels] = table.folder.split(/[\\/]/g);
                const tableWithFolderLevelRemoved = { ...table, folder: subLevels.join('/') };
                if (!tablesWithFolders[currentLevel]) {
                    tablesWithFolders[currentLevel] = [tableWithFolderLevelRemoved];
                } else {
                    tablesWithFolders[currentLevel].push(tableWithFolderLevelRemoved);
                }
            } else {
                noFolderTables.push(table);
            }
        });

        return { tablesWithFolders, noFolderTables };
    }, [tables]);

    return (
        <>
            {Object.entries(tablesWithFolders).map(([folderName, subMenuTables]) => (
                <Menu key={folderName}>
                    <MenuTrigger disableButtonEnhancement>
                        <MenuItem icon={<Folder16Regular />}>{folderName}</MenuItem>
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <TableList tables={subMenuTables} setSelectedTableId={setSelectedTableId} />
                        </MenuList>
                    </MenuPopover>
                </Menu>
            ))}
            {noFolderTables.map((table) => (
                <MenuItem icon={<Table16Regular />} key={table.id} onClick={() => setSelectedTableId(table.id)}>
                    {table.name}
                </MenuItem>
            ))}
        </>
    );
};
