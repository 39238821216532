import React from 'react';
import { Icon, ITooltipProps, TooltipHost } from '@fluentui/react';
import classNames from 'classnames';

import type { QueryMessageLevel } from '../../error';

import * as styles from './styles.module.scss';

const tooltipProps: ITooltipProps = {
    onRenderContent(props) {
        return props?.content ? <>{props.content}</> : null;
    },
};

export const ConfigurationItemRowErrors: React.FC<{ warnings: string[]; level: QueryMessageLevel }> = ({
    warnings,
    level,
}) => {
    return (
        <TooltipHost
            tooltipProps={tooltipProps}
            hostClassName={classNames(styles.tooltipHost, styles[level])}
            content={
                <ul className={styles.warningsList}>
                    {warnings.map((w, i) => (
                        <li key={i}>{w}</li>
                    ))}
                </ul>
            }
        >
            <Icon iconName={level === 'error' ? 'error' : 'warning'} />
        </TooltipHost>
    );
};
