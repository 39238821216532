import React from 'react';
import { Icon } from '@fluentui/react';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import { DropdownWithSearch, DropdownWithSearchProps, SelectedOption } from '@kusto/ui-components';
import { useTelemetry } from '@kusto/utils';

import type { VisualOptionsModel } from '../model/model';

export interface VisualizationOptionProps {
    label: string;
    iconName?: string;
}

export const VisualizationOption: React.FC<VisualizationOptionProps> = ({ label, iconName }) => {
    return (
        <>
            {iconName && <Icon iconName={iconName} aria-hidden="true" title={iconName} />}
            <span>{label}</span>
        </>
    );
};

export interface VisualTypePickerProps extends DropdownWithSearchProps {
    model: VisualOptionsModel;
}

/**
 * Includes icon and onChange code that is shared between visual type and visual
 * type variant pickers
 */
export const VisualTypePicker: React.FC<VisualTypePickerProps> = observer(function VisualTypePicker({
    model,
    options,
    ...props
}) {
    const { telemetry } = useTelemetry();

    const handleChangeVisualType = React.useMemo(
        () =>
            mobx.action((_: unknown, option?: SelectedOption) => {
                const key = option?.key as string;
                model.pluginKey.set(key);
                telemetry.event('VisualOptions.ChangeVisualType', { type: key });
            }),
        [model, telemetry]
    );

    return <DropdownWithSearch onChange={handleChangeVisualType} {...props} options={options} />;
});
