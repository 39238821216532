import React, { useState } from 'react';
import {
    classNamesFunction,
    ContextualMenu,
    getColorFromString,
    IContextualMenuItem,
    isDark,
    IStyleFunctionOrObject,
    styled,
    TooltipDelay,
    TooltipHost,
    TooltipOverflowMode,
} from '@fluentui/react';
import { IPalette, IStyle, ITheme } from '@fluentui/style-utilities';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

import { IconWithTooltip } from '@kusto/utils';

import { Keys } from '../../common/types';
import { useQueryCore } from '../../core/core';
import { getTelemetryClient } from '../../utils/telemetryClient';
import { TextFieldsWithKeyHandler } from '../commonComponents/TextFieldsWithKeyHandler';

export const tabClassName = 'tab-item';

const { trackEvent } = getTelemetryClient({
    component: 'TabItem',
    flow: '',
});

const getClassNames = classNamesFunction<TabItemStyleProps, TabItemStyles>();

const getSelectedTabBackground = (palette: IPalette) => palette.white;
const getNotSelectedTabBackground = (palette: IPalette, isDarkTheme: boolean) =>
    isDarkTheme ? palette.neutralQuaternaryAlt : palette.neutralLighter;
const getHoverTabBackground = (palette: IPalette, isDarkTheme: boolean) =>
    isDarkTheme ? palette.neutralLighter : palette.neutralQuaternaryAlt;

export const TAB_WIDTH = 180;

// Adding 2 pixels for the borders on the right and left
const TAB_WIDTH_INCLUDING_BORDERS = TAB_WIDTH + 2;

export type TabItemStyleProps = Required<Pick<TabItemProps, 'theme'>>;

export interface TabItemStyles {
    root: IStyle;
}

const getTabStyles = ({ theme }: { theme: ITheme }): TabItemStyles => {
    const isDarkTheme = isDark(getColorFromString(theme.palette.white)!);

    const selectedTabBorderColor = isDarkTheme ? theme.palette.neutralPrimary : theme.palette.neutralQuaternaryAlt;

    return {
        root: {
            height: 32,
            width: TAB_WIDTH,
            boxSizing: 'border-box',
            fontSize: 13,
            color: `${theme.palette.neutralPrimary} !important`,
            display: 'flex',
            backgroundColor: theme.palette.white,
            flexWrap: 'nowrap',
            justifyContent: 'flex-end',
            alignItems: 'center',
            cursor: 'pointer',
            ' .content': {
                width: '100%',
                boxSizing: 'border-box',
                padding: '0px 4px 0px 8px',
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'flex-end',
                alignItems: 'center',
                height: '100%',
                backgroundColor: getNotSelectedTabBackground(theme.palette, isDarkTheme),
            },
            '&.is-selected': {
                // the default background for selected link is the accent color.
                fontWeight: 600,
                '.tab-button': {
                    display: 'inline-block !important',
                },
                width: TAB_WIDTH_INCLUDING_BORDERS,
                borderRightColor: selectedTabBorderColor,
                borderLeftColor: selectedTabBorderColor,
                borderTopColor: selectedTabBorderColor,
                borderBottomColor: getSelectedTabBackground(theme.palette),
                borderStyle: 'solid',
                borderRightWidth: 1,
                borderLeftWidth: 1,
                borderTopWidth: isDarkTheme ? 1 : 0,
                borderBottomWidth: 0,
                paddingBottom: 1,
                height: 33,
                ' .content': {
                    background: `${getSelectedTabBackground(theme.palette)} !important`,
                },
            },
            '&.is-dragging': {
                borderTopWidth: 1,
                borderBottomWidth: 1,
                borderBottomColor: selectedTabBorderColor,
            },
            '&.is-focused, &:hover': {
                ' .content': {
                    backgroundColor: getHoverTabBackground(theme.palette, isDarkTheme),
                },
                ' .tab-button': {
                    display: 'inline-block !important',
                },
            },
            ' .tab-title': {
                flexGrow: 1,
            },
            ' .tab-title-readonly': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
            ' .tab-button': {
                display: 'none',
                flexGrow: 0,
                lineHeight: '16px',
                textAlign: 'center',
                width: '24px',
                position: 'relative',
            },
            ' .close-tab-button': {
                color: theme.palette.neutralSecondary,
                ':hover': {
                    color: 'red',
                },
            },
            ' .rename-tab-button': {
                color: theme.semanticColors.inputIcon,
                fontSize: 12,
                verticalAlign: 'text-top',
                ':hover': {
                    color: theme.semanticColors.inputIconHovered,
                },
            },
        },
    };
};

function getDraggableStyles(
    draggableProvided: DraggableProvided,
    isSelected: boolean,
    isDragging: boolean
): React.CSSProperties {
    const zIndex = isDragging ? 2 : isSelected ? 1 : 0;
    let marginBottom = 0;
    if (isSelected) {
        marginBottom = 1;
    }

    if (isDragging) {
        marginBottom = 0;
    }

    return {
        ...draggableProvided.draggableProps.style,
        marginBottom,
        height: 32,
        lineHeight: '32px',
        zIndex,
        boxSizing: 'border-box',
        userSelect: 'none',
    };
}

export interface TabItemPropsBase {
    tabKey: string;
    onClose: () => void;
    text: string;
}

interface TabItemProps extends TabItemPropsBase {
    tabKey: string;
    index: number;
    theme: ITheme;
    styles?: IStyleFunctionOrObject<TabItemStyleProps, TabItemStyles>;
    isSelected: boolean;
    onClick: (tabKey: string) => void;
}

interface TabItemInnerProps extends TabItemProps {
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
}

const TabItemInner: React.FC<TabItemInnerProps> = observer(function TabItemBase(props) {
    const core = useQueryCore();

    const [isRenaming, setIsRenaming] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [contextMenuTarget, setContextMenuTarget] = useState<{ x: number; y: number } | undefined>();
    const rootStore = core.store;
    const tab = rootStore.tabs.tabs.find((t) => t.id === props.tabKey);
    if (!tab) {
        return <></>;
    }

    let latestOnClickTime: number | undefined = undefined;

    const tabClassNames = getClassNames(props.styles, {
        theme: props.theme,
    });

    const tabButtonKeyHandler = (e: React.KeyboardEvent, buttonAction: () => void) => {
        switch (e.key) {
            case 'Enter':
            case 'Space':
                e.stopPropagation();
                buttonAction();
                break;
            default:
            // Nothing to do ...
        }
    };

    const onTabAuxClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        const middleButtonIndex = 1;
        if (e.button === middleButtonIndex) {
            props.onClose();
        }
    };

    const onDoneEditing = () => {
        setIsRenaming(false);
    };

    const onDoubleClicked = () => {
        setIsRenaming(true);
    };

    const fieldRender = (props: TabItemProps) => (
        <TextFieldsWithKeyHandler
            defaultValue={props.text}
            onChanged={(title: string) => tab.setTitle(title)}
            onDoneEditing={onDoneEditing}
            className="tab-title"
        />
    );

    const emulateDoubleClick = (onDoubleClicked: () => void) => {
        const clickTime = new Date().getTime();
        if (latestOnClickTime && clickTime - latestOnClickTime < 500) {
            onDoubleClicked();
        } else {
            latestOnClickTime = clickTime;
        }
    };

    // Add tabKey to classname for selected tab element identification by scrollSelectedTabToView function
    const classname = `${tabClassName} ${props.isSelected ? 'selected' : ''} ${props.tabKey}`;

    const onRenameClicked = (
        e?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement> | React.MouseEvent
    ) => {
        setIsRenaming(true);
        e?.stopPropagation();
    };

    const openContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setContextMenuTarget({ x: e.clientX, y: e.clientY });
    };

    const renameTabMenuItem: IContextualMenuItem = {
        key: 'RenameTab',
        name: core.strings.query.renameTabButtonTooltip,
        ariaLabel: core.strings.query.renameTabButtonTooltip,
        onClick: (e?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
            onRenameClicked(e);
            trackEvent('renaming-tab', { source: 'menuItem', trigger: 'contextMenu' });
        },
    };

    const closeTabMenuItem: IContextualMenuItem = {
        key: 'CloseTab',
        name: core.strings.query.closeTab,
        ariaLabel: core.strings.query.close,
        onClick: () => {
            props.onClose();
            trackEvent('close-tab', { source: 'menuItem', trigger: 'contextMenu' });
        },
    };

    const actions = [renameTabMenuItem, closeTabMenuItem];

    return (
        <div
            ref={props.provided.innerRef}
            {...props.provided.draggableProps}
            {...props.provided.dragHandleProps}
            className={`${classname} ${props.snapshot.isDragging ? 'dragging' : ''}`}
            style={getDraggableStyles(props.provided, props.isSelected, props.snapshot.isDragging)}
            role="tab"
            tabIndex={0}
            data-is-focusable="true"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onClick={() => {
                emulateDoubleClick(onDoubleClicked);
                trackEvent('renaming-tab', { source: 'button', trigger: 'onDoubleClick' });
                props.onClick(props.tabKey);
            }}
            onKeyDown={(ev: React.KeyboardEvent) => {
                if (ev.key === Keys.enter) {
                    props.onClick(props.tabKey);
                }
            }}
            onContextMenu={openContextMenu}
        >
            {contextMenuTarget && (
                <ContextualMenu
                    target={contextMenuTarget}
                    shouldFocusOnMount={true}
                    shouldFocusOnContainer={true}
                    items={actions}
                    onDismiss={() => setContextMenuTarget(undefined)}
                />
            )}
            <div
                className={classNames(tabClassNames.root, 'tab-item-container', {
                    'is-selected': props.isSelected,
                    'is-dragging': props.snapshot.isDragging,
                    'is-focused': isFocused,
                })}
                onAuxClick={onTabAuxClick}
            >
                <div className="content">
                    {isRenaming ? (
                        fieldRender(props)
                    ) : (
                        <TooltipHost
                            content={props.text}
                            hostClassName="tab-title tab-title-readonly"
                            overflowMode={TooltipOverflowMode.Self}
                            delay={TooltipDelay.long}
                        >
                            {props.text}
                        </TooltipHost>
                    )}
                    {!props.snapshot.isDragging && !isRenaming && (
                        <IconWithTooltip
                            className="tab-button rename-tab-button"
                            ariaLabel={core.strings.query.renameTabButtonTooltip}
                            tooltipProps={{ content: core.strings.query.renameTabButtonTooltip }}
                            onKeyDown={(e: React.KeyboardEvent) =>
                                tabButtonKeyHandler(e, () => {
                                    setIsRenaming(true);
                                    trackEvent('renaming-tab', {
                                        source: 'button',
                                        trigger: 'onKeyDown',
                                        key: e.key,
                                    });
                                })
                            }
                            iconName="Edit"
                            onClick={(e: React.MouseEvent) => {
                                onRenameClicked(e);
                                trackEvent('renaming-tab', { source: 'button', trigger: 'onClick' });
                            }}
                        />
                    )}
                    {!props.snapshot.isDragging && rootStore.tabs.tabs.length > 1 && (
                        <IconWithTooltip
                            className="tab-button close-tab-button"
                            ariaLabel={core.strings.query.close}
                            tooltipProps={{ content: core.strings.query.closeTab }}
                            onKeyDown={(e: React.KeyboardEvent) => tabButtonKeyHandler(e, props.onClose)}
                            iconName="Cancel"
                            onClick={props.onClose}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

const TabItemBase: React.FC<TabItemProps> = (props) => {
    return (
        <Draggable draggableId={props.tabKey} index={props.index}>
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                <TabItemInner provided={provided} snapshot={snapshot} {...props} />
            )}
        </Draggable>
    );
};

export const TabItem = styled(TabItemBase, getTabStyles);
