import React from 'react';

import { KweException } from '../utils';
import type { IKweTelemetry } from './interface';

const TelemetryContext = React.createContext<IKweTelemetry | undefined>(undefined);

/**
 * @see {@link IKweTelemetry}
 */
export const TelemetryProvider: React.FC<{ telemetry?: IKweTelemetry }> = ({ telemetry, children }) => {
    return <TelemetryContext.Provider value={telemetry}>{children}</TelemetryContext.Provider>;
};

/**
 * recommended way to access telemetry in function components
 */
export function useTelemetry(): { telemetry: IKweTelemetry } {
    const telemetry = React.useContext(TelemetryContext);

    if (telemetry === undefined) {
        throw new KweException('useTelemetry should only be used inside initialized TelemetryProvider');
    }

    return { telemetry };
}
