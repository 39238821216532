import * as React from 'react';
import { useFluent_unstable as useFluent } from '@fluentui/react-shared-contexts';
import { debounce } from '../utilities/debounce';
import { createResizeObserverFromDocument } from '../utilities/createResizeObserverFromDocument';
/**
 * useResizeObserverRef_unstable simplifies resize observer connection and ensures debounce/cleanup
 */ export const useResizeObserverRef_unstable = (resizeCallback)=>{
    const { targetDocument } = useFluent();
    const container = React.useRef(null);
    // the handler for resize observer
    const handleResize = debounce((entries, observer)=>{
        resizeCallback(entries, observer, container);
    });
    // Keep the reference of ResizeObserver in the state, as it should live through renders
    const [resizeObserver, setResizeObserver] = React.useState(()=>createResizeObserverFromDocument(targetDocument, handleResize));
    React.useEffect(()=>{
        // Update our state when resizeCallback changes
        container.current = null;
        resizeObserver === null || resizeObserver === void 0 ? void 0 : resizeObserver.disconnect();
        setResizeObserver(()=>createResizeObserverFromDocument(targetDocument, handleResize));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        resizeCallback,
        targetDocument
    ]);
    React.useEffect(()=>{
        return ()=>{
            container.current = null;
            resizeObserver === null || resizeObserver === void 0 ? void 0 : resizeObserver.disconnect();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const scrollRef = React.useCallback((instance)=>{
        if (container.current !== instance) {
            if (container.current) {
                resizeObserver === null || resizeObserver === void 0 ? void 0 : resizeObserver.unobserve(container.current);
            }
            container.current = instance;
            if (container.current) {
                resizeObserver === null || resizeObserver === void 0 ? void 0 : resizeObserver.observe(container.current);
            }
        }
    }, [
        resizeObserver
    ]);
    return scrollRef;
};
