import React from 'react';
import { useUncontrolledFocus } from '@fluentui/react-components';
import type { EngineSchema, LanguageSettings, Schema } from '@kusto/monaco-kusto';
import { kustoDefaults } from '@kusto/monaco-kusto';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { editor, Emitter, languages, MarkerSeverity, Range } from 'monaco-editor/esm/vs/editor/editor.api';

import { initWorker, initWorkerFromModel, loadSchemaForCrossClusterQueries } from '@kusto/kusto-schema';
import { RenderHelper, useRender } from '@kusto/ui-components';
import { formatToSeconds, IKweTelemetry, mobxEffect, normalizeSpaces } from '@kusto/utils';

import { QueryCore, useQueryCore } from '../../core/core';
import { convertDatabaseToDatabaseSymbol, Database, getClusterAndDatabaseFromEntity } from '../../stores/cluster';
import { KustoConnection } from '../../utils/platform';
import { addEditorActions, CustomActionDescriptorType } from './actions';
import { KustoEditorHandle } from './handle';
import { getOrCreateModel, MonacoModels, setupTabContentSync } from './tabContentSync';

import * as styles from './KustoEditor.module.scss';

export const kustoEditorContainerClassName = styles.monacoContainer;

function createKustoSettings(core: QueryCore, documentationSuffix: string | undefined): LanguageSettings {
    const settings = core.store.settings;
    const enableQuerySuggestions = core.featureFlags.queryRecommendations && settings.enableSuggestions;
    const disabledRecommendationCodesAsArray = Array.from(settings.disabledRecommendationCodes);

    return {
        ...kustoDefaults.languageSettings,
        disabledCompletionItems: ['treemap'],
        completionOptions: { includeExtendedSyntax: Boolean(settings.includeExtendedSyntax) },
        openSuggestionDialogAfterPreviousSuggestionAccepted:
            settings.openSuggestionDialogAfterPreviousSuggestionAcceptedOverride,
        disabledDiagnosticCodes: disabledRecommendationCodesAsArray,
        enableQueryWarnings: core.featureFlags.queryRecommendations && settings.enableWarnings,
        enableQuerySuggestions,
        enableQuickFixes: enableQuerySuggestions,
        documentationSuffix,
    };
}

function initAndSyncWorkerSettings(core: QueryCore, documentationSuffix: string | undefined, signal: AbortSignal) {
    const prevSettings = kustoDefaults.languageSettings;
    // Revert settings to default on editor close
    signal.addEventListener('abort', () => kustoDefaults.setLanguageSettings(prevSettings));

    const workerSettings = mobx.computed(() => createKustoSettings(core, documentationSuffix));
    //Initialize settings synchronously to grantee it's set before the worker is created
    kustoDefaults.setLanguageSettings(workerSettings.get());
    const setSettings = throttle((settings: LanguageSettings) => kustoDefaults.setLanguageSettings(settings));
    signal.addEventListener('abort', () => setSettings.cancel());
    mobx.reaction(() => workerSettings.get(), setSettings, {
        signal,
        // Deep memo settings changes because applying them is expensive: we restart the worker
        equals: mobx.comparer.structural,
    });
}

function setupMonacoSettingsSync(core: QueryCore, editor: editor.IStandaloneCodeEditor, signal: AbortSignal) {
    // Follow up setting changes added as reactions so they don't fire immediately

    mobxEffect(
        function updateReadonly() {
            editor.updateOptions({ readOnly: core.store.settings.readonly });
        },
        { signal }
    );

    mobxEffect(
        function updateMonacoMouseWheelZoom() {
            editor.updateOptions({ mouseWheelZoom: core.store.settings.mouseWheelZoom });
        },
        { signal }
    );

    mobxEffect(
        function updateMonacoTheme() {
            editor.updateOptions({ theme: core.store.settings.monacoEditorTheme });
        },
        { signal }
    );
}

// Set the details of a suggestion item open/close state
// And override it's functionally to support updating and persisting the state on click of cloe (x)
function setUpShowQueryOperationDocumentation(core: QueryCore, editor: editor.IStandaloneCodeEditor) {
    const {
        widget: { value: suggestWidget },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = editor.getContribution('editor.contrib.suggestController') as any;
    const { _setDetailsVisible } = suggestWidget;
    const { showQueriesOperationDocumentation, setShowQueriesOperationDocumentation } = core.store.settings;

    //TODO - hacky - try to introduce an official api + event into monaco #25827242
    suggestWidget._setDetailsVisible = (value: boolean) => {
        _setDetailsVisible.call(suggestWidget, value);
        setShowQueriesOperationDocumentation(value);
    };

    suggestWidget._setDetailsVisible(
        showQueriesOperationDocumentation && core.store.layout?.windowHeightSizeType === 'Large'
    );
}

function setupCodeLensSync(core: QueryCore, signal: AbortSignal) {
    /**
     * Create code lens symbols for a list of kusto commands.
     * Code lens will be displayed if the query was previously ran (possibly against a different cluster.)
     */
    const provideCodeLenses = (
        model: editor.IReadOnlyModel,
        commands: {
            absoluteStart: number;
            absoluteEnd: number;
            text: string;
        }[]
    ): languages.CodeLensList => {
        const noLens = { lenses: [], dispose: () => {} };
        if (!commands) {
            return noLens;
        }

        // See if we have our result cache. if not - return some dummy codelens that we will filter
        if (!core.store.resultCache.executions) {
            return noLens;
        }

        const latestByQuery = core.store.resultCache.latestExecutionByQuery;

        // Add normalized text to command since we'll be using it a few times.
        const commandsWithNormalizedText = commands.map((cmd) => ({
            ...cmd,
            normalizedText: normalizeSpaces(cmd.text),
        }));

        // Filter out all commands that were never executed.
        const executedCommands = commandsWithNormalizedText.filter((cmd) => latestByQuery[cmd.normalizedText]);

        const codeLenses = executedCommands.map((cmd) => {
            // translate absolute offsets to monaco data structures which expect line and column.
            const startPosition = model.getPositionAt(cmd.absoluteStart);
            const endPosition = model.getPositionAt(cmd.absoluteEnd);
            const range = new Range(
                startPosition.lineNumber,
                startPosition.column,
                endPosition.lineNumber,
                endPosition.column
            );

            const latestExecution = latestByQuery[cmd.normalizedText];
            const url = latestExecution.request.url;
            const connection = KustoConnection.fromConnectionString(core.kustoDomains, url);
            const clusterName = connection ? connection.cluster : 'N/A';
            const executionDate = moment.utc(latestExecution.request.timeStarted).format('YYYY-MM-DD HH:mm:ss');
            const duration = formatToSeconds(latestExecution.executionTimeInMilliseconds);
            const status = latestExecution.isSuccess ? 'success' : 'failure';
            const dbName = latestExecution.request.dbName;
            const command = {
                id: 'codelens',
                title: `${status} | ${executionDate} | ${clusterName} | ${dbName} | ${duration}`,
            };

            return { range, command };
        });

        return { lenses: codeLenses, dispose: () => {} };
    };

    const waitTimeForWorker = 1000;

    const codeLensEventEmitter = new Emitter() as Emitter<languages.CodeLensProvider>;

    const codeLensProvider: languages.CodeLensProvider = {
        provideCodeLenses: async (model: editor.IReadOnlyModel) => {
            const worker = await initWorkerFromModel(model);
            if (!worker) {
                return { lenses: [], dispose: () => {} };
            }
            const commands = await worker.getCommandsInDocument(model.uri.toString());
            const promise = provideCodeLenses(model, commands);
            return promise;
        },
        resolveCodeLens: (_model: editor.IReadOnlyModel, codeLens: languages.CodeLens) => {
            return codeLens;
        },
        onDidChange: codeLensEventEmitter.event,
    };

    mobxEffect(
        function registerCodelens() {
            if (!core.store.settings.enableCodelensOverride) {
                return;
            }
            const disposer1 = languages.registerCodeLensProvider('kusto', codeLensProvider);

            // MobX does not guarantee the order in which reactions will be run. Nest effects so this one doesn't fire until we've registered the code lens provider.
            const disposer2 = mobxEffect(function updateCodelensContent() {
                const executionStatus = core.store.tabs.tabInContext.executionStatus;
                const executions = core.store.resultCache.executions;
                if (executionStatus && executions) {
                    codeLensEventEmitter.fire(codeLensProvider);
                }
            });
            return () => {
                disposer1.dispose();
                disposer2();
            };
        },
        {
            // TODO: Why do we need this? Are we sure we need it?
            delay: waitTimeForWorker,
            signal,
        }
    );
}

function setupQueryErrorSync(core: QueryCore, editorInstance: editor.IStandaloneCodeEditor, signal: AbortSignal) {
    mobxEffect(
        function highlightSyntaxErrorFromServer() {
            const model = editorInstance.getModel();
            if (!model) {
                return;
            }

            const tab = core.store.tabs.tabInContext;
            if (
                tab.completionInfo &&
                tab.completionInfo.errorDescription &&
                tab.completionInfo.errorDescription.token &&
                tab.completionInfo.request &&
                tab.commandInContext
            ) {
                const desc = tab.completionInfo.errorDescription;

                editor.setModelMarkers(model, 'serverSideErrors', [
                    {
                        message: desc.errorMessage,
                        severity: MarkerSeverity.Error,
                        startLineNumber: desc.line!,
                        startColumn: desc.pos! + 1,
                        endLineNumber: desc.line!,
                        endColumn: desc.pos! + 1 + desc.token!.length,
                    },
                ]);
            } else {
                editor.setModelMarkers(model, 'serverSideErrors', []);
            }
        },
        { signal }
    );
}

async function setSchema(editor: editor.IStandaloneCodeEditor, schema: Schema) {
    const w = await initWorker(editor);
    if (w) {
        w.setSchema(schema);
    }
}

export function setupSchemaSync(core: QueryCore, editor: editor.IStandaloneCodeEditor, signal: AbortSignal) {
    mobxEffect(
        function updateWorkerSchema() {
            // Fire autorun when tab changes. This should _really_ fire when
            // the mobx model changes, but this is a good enough proxy for
            // now.
            //
            // This needs to happen because the the schema is lost when the
            // tab/model changes. If that's a bug, and it's fixed, then this
            // can be removed.
            void core.store.tabs.tabInContext;

            const entityInContext = core.store.connectionPane.entityInContext;

            // TODO - this isn't actually what we want -
            // We don't want to display any intellisense if there's no entity in context.
            // right now we'll just display intellisense for the latest entity that was in context.
            // Need to add support for an empty suggestion list in monaco-kusto.
            if (!entityInContext) {
                return;
            }

            const {
                databases: databasesMap,
                connectionString,
                clusterType,
                cmSchema,
            } = getClusterAndDatabaseFromEntity(entityInContext).cluster;

            // If this is a DM, show DM intellisense.
            if (clusterType === 'DataManagement') {
                setSchema(editor, {
                    clusterType: 'DataManagement',
                });
                return;
            }

            // If this is a CM show CM intellisense.
            if (clusterType === 'ClusterManager') {
                if (!cmSchema) {
                    return;
                }

                const { accounts, services } = cmSchema;
                setSchema(editor, {
                    clusterType: 'ClusterManager',
                    accounts,
                    services,
                    connectionString,
                });
                return;
            }

            // If this isn't a database, don't show any databases in intellisense
            if (entityInContext.entityType !== 'Database') {
                setSchema(editor, {
                    clusterType: 'Engine',
                    cluster: {
                        connectionString,
                        databases: [],
                    },
                    database: undefined,
                });
                return;
            }

            const entityInContextAsDB = entityInContext as Database;
            const dbInContextName = entityInContextAsDB.name;
            const dnInContextMvQueriesEnrichNotStarted = entityInContextAsDB.mvQueriesEnrichState === 'notStarted';
            const databases = Array.from(databasesMap.values()).map((db) =>
                convertDatabaseToDatabaseSymbol(
                    db,
                    entityInContextAsDB,
                    dbInContextName,
                    dnInContextMvQueriesEnrichNotStarted
                )
            );

            const databaseInContext = databases.find(({ name }) => name === dbInContextName);

            if (dnInContextMvQueriesEnrichNotStarted) {
                // Once enrichWithMVQueries finishes, this AutoRun function
                // will re-run, this time with mvQueriesEnrichState as
                // "done"
                entityInContextAsDB.enrichWithMVQueries();
            }

            const schema: EngineSchema = {
                clusterType: 'Engine',
                cluster: {
                    connectionString,
                    databases,
                },
                database: databaseInContext,
            };

            setSchema(editor, schema);
        },
        { signal }
    );
}

function setInitialCursorPosition(core: QueryCore, editor: editor.ICodeEditor): void {
    /**
     * When editor is mounted, the cursor's position is (1,1).
     * However, if there is a query that starts in (1,1) it doesn't auto select it.
     * This little "dummySelection" workaround, achieves exactly that.
     */
    const dummySelection = {
        selectionStartLineNumber: 100,
        selectionStartColumn: 1,
        positionLineNumber: 100,
        positionColumn: 5,
    };
    const position = {
        lineNumber: core.store.tabs.tabInContext?.cursorPosition?.lineNumber ?? 1,
        column: core.store.tabs.tabInContext?.cursorPosition?.column ?? 1,
    };
    editor.setSelection(dummySelection);
    editor.setPosition(position);
    if (core.store.tabs.tabInContext?.cursorPosition) {
        editor.revealPositionInCenter(position);
    }
}

function setupSchemaFetching(
    core: QueryCore,
    editor: editor.IStandaloneCodeEditor,
    signal: AbortSignal,
    telemetry: IKweTelemetry
) {
    const shortDebounceLoadSchema = debounce(loadSchemaForCrossClusterQueries, 200);
    const longDebounceLoadSchema = debounce(loadSchemaForCrossClusterQueries, 400);

    signal.addEventListener('abort', () => {
        shortDebounceLoadSchema.cancel();
        longDebounceLoadSchema.cancel();
    });

    // handle cross cluster queries
    mobxEffect(
        () => {
            const commandInContext = core.store.tabs.tabInContext.commandInContext;
            if (!commandInContext) {
                shortDebounceLoadSchema.cancel();
                longDebounceLoadSchema.cancel();
                // short commands (less or equal 10K chars) - debounce duration = 200ms
                // long commands (more than 10K chars) - debounce duration = 400ms
            } else if (commandInContext.length < 10000) {
                longDebounceLoadSchema.cancel();
                shortDebounceLoadSchema(core.kustoClient, editor, telemetry, core.kustoDomains, core.strings, signal);
            } else {
                shortDebounceLoadSchema.cancel();
                longDebounceLoadSchema(core.kustoClient, editor, telemetry, core.kustoDomains, core.strings, signal);
            }
        },
        { signal }
    );
}

function setupMonacoEditor(
    container: HTMLElement,
    core: QueryCore,
    signal: AbortSignal,
    render: RenderHelper,
    documentationSuffix: string | undefined,
    customActions?: CustomActionDescriptorType
): KustoEditorHandle {
    const telemetry = core.telemetry.bind({ component: 'Monaco' });

    initAndSyncWorkerSettings(core, documentationSuffix, signal);

    const models: MonacoModels = {};

    signal.addEventListener('abort', () => {
        for (const model of Object.values(models)) {
            model.model.dispose();
        }
    });

    // When roaming profile is enable, the text will be loaded by the monaco binding
    const text = core.featureFlags.EnableRoamingProfile ? undefined : core.store.tabs.tabInContext.text;

    const model = getOrCreateModel(models, core.store.tabs.tabInContext.id, text).model;

    const editorInstance = editor.create(container, {
        value: text,
        model,
        language: 'kusto',
        selectOnLineNumbers: true,
        automaticLayout: true,
        theme: core.store.settings.monacoEditorTheme,
        minimap: {
            enabled: false,
        },
        mouseWheelZoom: core.store.settings.mouseWheelZoom,
        fixedOverflowWidgets: true,
        suggest: {
            selectionMode: 'whenQuickSuggestion',
        },
        'semanticHighlighting.enabled': true,
    });

    if (core.featureFlags.SkipMonacoFocusOnInit !== true) {
        editorInstance.focus();
        setInitialCursorPosition(core, editorInstance);
    }

    setupMonacoSettingsSync(core, editorInstance, signal);

    const flushTextSync = setupTabContentSync(core, editorInstance, models, signal, telemetry);

    setupSchemaFetching(core, editorInstance, signal, telemetry);
    setupSchemaSync(core, editorInstance, signal);
    setupQueryErrorSync(core, editorInstance, signal);

    const handle = new KustoEditorHandle(core, signal, editorInstance, flushTextSync);

    addEditorActions(core, editorInstance, handle, signal, telemetry, render, customActions);

    setupCodeLensSync(core, signal);

    setUpShowQueryOperationDocumentation(core, editorInstance);

    return handle;
}

export const KustoEditor: React.FunctionComponent<{
    documentationSuffix?: string;
    customActions?: CustomActionDescriptorType;
}> = observer(function Monaco({ documentationSuffix, customActions }) {
    const core = useQueryCore();
    const render = useRender();
    const uncontrolledFocusAttributes = useUncontrolledFocus();

    const containerRef = React.useRef<HTMLDivElement | null>(null);

    React.useLayoutEffect(
        () =>
            mobx.runInAction(() => {
                const abortController = new AbortController();

                const handle = setupMonacoEditor(
                    containerRef.current!,
                    core,
                    abortController.signal,
                    render,
                    documentationSuffix,
                    customActions
                );

                core.kustoEditor.set(handle, abortController.signal);

                return () => {
                    abortController.abort();
                };
            }),
        [documentationSuffix, core, render, customActions]
    );

    return (
        <div
            {...uncontrolledFocusAttributes}
            ref={containerRef}
            className={styles.monacoContainer}
            role="main"
            aria-label={core.strings.query.queryEditor}
        />
    );
});
