import { makeResetStyles, makeStyles, mergeClasses, shorthands } from '@griffel/react';
import { tokens } from '@fluentui/react-theme';
export const interactionTagClassNames = {
    root: 'fui-InteractionTag'
};
const useRootBaseClassName = makeResetStyles({
    display: 'inline-flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: 'fit-content'
});
const useRootStyles = makeStyles({
    rounded: shorthands.borderRadius(tokens.borderRadiusMedium),
    circular: shorthands.borderRadius(tokens.borderRadiusCircular),
    medium: {
        height: '32px'
    },
    small: {
        height: '24px'
    },
    'extra-small': {
        height: '20px'
    }
});
/**
 * Apply styling to the InteractionTag slots based on the state
 */ export const useInteractionTagStyles_unstable = (state)=>{
    const rootBaseClassName = useRootBaseClassName();
    const rootStyles = useRootStyles();
    const { shape, size } = state;
    state.root.className = mergeClasses(interactionTagClassNames.root, rootBaseClassName, rootStyles[shape], rootStyles[size], state.root.className);
    return state;
};
