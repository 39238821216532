@use '@kusto/style/tokens';
@use '@kusto/style/typography';

.highchartsContainer {
    height: 100%;
    min-height: 178px;
    flex-grow: 1;
}

.okView {
    height: 178px;
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: height 0.1s ease-in-out;
    container: okView / size;
}

.errView {
    position: relative;
    display: flex;
    align-items: center;
    gap: tokens.$spacingHorizontalM;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    padding: tokens.$spacingVerticalL tokens.$spacingHorizontalL;
    transition: height 0.3s ease-in-out;
    container: errorView / inline-size;
}

.errMsg {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: tokens.$spacingHorizontalXS;
    border-radius: tokens.$borderRadiusXLarge;
    padding: tokens.$spacingVerticalS 0;
}
@container errorView (width < 500px) {
    .errView {
        flex-flow: column-reverse;
        align-items: start;
    }

    .errMsg {
        background-color: tokens.$colorNeutralBackground3;
        width: 100%;
    }
}

.rowCountContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: tokens.$spacingVerticalXS;
}

.scopedRowCount {
    color: tokens.$colorBrandForeground2;
    font-family: tokens.$fontFamilyBase;
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px;
}

.fullRowCount {
    @extend %typography--body1;
    color: tokens.$colorBrandForeground2;
}

.firstBin {
    position: absolute;
    bottom: 40px;
    left: tokens.$spacingHorizontalL;
}

.lastBin {
    position: absolute;
    bottom: 40px;
    right: tokens.$spacingHorizontalL;
}

.histogramWrapper {
    min-height: 64px;
    width: 100%;
}

.histogramOverlay {
    position: absolute;
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-between;
    top: tokens.$spacingVerticalL;
    right: tokens.$spacingHorizontalL;
    left: tokens.$spacingHorizontalL;
}

@container okView (width < 500px) {
    .histogramOverlay {
        flex-flow: column;
        gap: tokens.$spacingVerticalS;
    }

    .columnPickerWrapper {
        align-self: flex-start;
    }

    .scopedRowCount {
        font-size: 36px;
        line-height: 32px;
    }

    .fullRowCount {
        font-size: var(--fontSizeBase200);
        line-height: var(--lineHeightBase200);
    }
}
