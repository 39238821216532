import React from 'react';
import { getKustoWorker } from '@kusto/monaco-kusto';
import { editor } from 'monaco-editor/esm/vs/editor/editor.api';
import ReactMonacoEditor, { EditorConstructionOptions, EditorDidMount } from 'react-monaco-editor';

import { getTheme } from '../MonacoEditorUtils/utils';

import styles from '../MonacoEditorUtils/MonacoEditor.module.scss';

const defaultEditorOptions: EditorConstructionOptions = {
    selectOnLineNumbers: true,
    automaticLayout: true,
    minimap: {
        enabled: false,
    },
    wordWrap: 'on',
    mouseWheelZoom: true,
    readOnly: true,
    folding: false,
};

export interface MonacoEditorKQLProps {
    /** Text to display in the editor */
    kqlText: string;
    /** KQL data context, used for highlighting databases and tables names */
    context: {
        clusterUrl: string;
        databaseName: string;
        schema: unknown;
    };
    abortSignal: AbortSignal;
    /** Dark theme */
    isDark?: boolean;
    /** Custom options */
    editorOptions?: EditorConstructionOptions;
}

export interface TestableMonacoEditorElement extends HTMLElement {
    __containerRef: editor.IStandaloneCodeEditor;
}

/** Monaco editor with KQL syntax highlight */
export const MonacoEditorKQL: React.FunctionComponent<MonacoEditorKQLProps> = ({
    kqlText,
    context,
    abortSignal,
    isDark,
    editorOptions,
}) => {
    const loadKqlSyntax: EditorDidMount = (editor) => {
        // For tests, provide a way to access the editor. Used to read the editor content.
        (editor.getContainerDomNode() as TestableMonacoEditorElement).__containerRef = editor;

        getKustoWorker().then((workerAccessor) => {
            const model = editor.getModel();
            if (!model || abortSignal.aborted) {
                return; // abort
            }

            workerAccessor(model.uri).then((worker) => {
                if (!abortSignal.aborted) {
                    worker.setSchemaFromShowSchema(context.schema, context.clusterUrl, context.databaseName);
                }
            });
        });
    };

    return (
        <ReactMonacoEditor
            language="kusto"
            value={kqlText}
            theme={getTheme(isDark)}
            className={styles.monacoEditor}
            options={{ ...defaultEditorOptions, ...editorOptions }}
            editorDidMount={loadKqlSyntax}
        />
    );
};
