import React from 'react';
import { Label, TooltipHost } from '@fluentui/react';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import {
    DropdownWithSearch,
    DropdownWithSearchOption,
    DropdownWithSearchProps,
    SelectedOption,
} from '@kusto/ui-components';
import { KweException } from '@kusto/utils';

import type { KweVisualFwkLocale } from '../../types';
import { SchemaState, VisualInput, VisualInputSelector } from '../../visualConfig/input';
import { VisualOptionKey, VisualOptionsKeysFor } from '../../visualOptions';
import { dividerDropdownOption } from './constants';
import { dropdownWithTypeTextProps } from './dropdownWithTypeText';
import {
    columnDropdownOption,
    columnOptionKey,
    ETPColumnOption,
    getSchemaDerived,
    INFER_OPTION_KEY,
    resolveInferOption,
} from './utils';

import * as styles from '../styles.module.scss';

interface MultipleColumnDropdownProps {
    t: KweVisualFwkLocale;

    id: string;
    schema: SchemaState;
    errorMessage?: string;
    selected: null | readonly string[];
    onChange: DropdownWithSearchProps['onChange'];
    label: string;
    disabled?: boolean;
    /**
     * Note: A more general version of "addInferChoice" might be "add reset option"
     */
    addInferChoice?: boolean;
    placeholder?: string;
    inferColumns?: readonly string[];
}

const MultipleColumnDropdown: React.FC<MultipleColumnDropdownProps> = ({
    t,
    id,
    label,
    schema,
    errorMessage,
    disabled,
    selected,
    onChange,
    addInferChoice,
    placeholder,
    inferColumns,
}) => {
    const selectedKeys = React.useMemo(
        () => (selected === null ? [INFER_OPTION_KEY] : selected.map(columnOptionKey)),
        [selected]
    );

    // Code here is a little complex because we need to ensure that selected choices
    // are shown in the dropdown, even if they are no longer available.
    const { options, disabledReason } = React.useMemo(() => {
        const schemaDerived = getSchemaDerived(t, schema);
        const inferOption = resolveInferOption(t, inferColumns, schemaDerived.typesByColumn);

        const innerOptions: DropdownWithSearchOption[] = [];

        if (selected === null) {
            innerOptions.push(inferOption, dividerDropdownOption, ...schemaDerived.options);
        } else {
            const selectedValues = selected;

            let shouldAddDivider = false;

            if (addInferChoice) {
                innerOptions.push(inferOption);
                shouldAddDivider = true;
            }

            const unavailableColumns: ETPColumnOption[] = [];

            for (const columnName of selectedValues) {
                if (!schemaDerived.typesByColumn.has(columnName)) {
                    unavailableColumns.push(columnDropdownOption(columnName, { kind: 'unavailable' }));
                }
            }

            if (unavailableColumns.length) {
                innerOptions.push(...unavailableColumns);
                shouldAddDivider = true;
            }

            if (shouldAddDivider) {
                innerOptions.push(dividerDropdownOption);
            }

            innerOptions.push(...schemaDerived.options);
        }
        return { options: innerOptions, disabledReason: schemaDerived.disabledReason };
    }, [schema, inferColumns, selected, addInferChoice, t]);

    const dropdownId = `visual-options--${id}`;

    return (
        <div className={styles.basicInput}>
            <Label htmlFor={dropdownId}>{label}</Label>
            <TooltipHost content={disabledReason ? <>{disabledReason}</> : undefined}>
                <DropdownWithSearch
                    multiselect={true}
                    id={dropdownId}
                    selectedKeys={selectedKeys}
                    options={options}
                    aria-label={label}
                    onChange={onChange}
                    disabled={disabled || disabledReason !== undefined}
                    placeholder={placeholder}
                    validationMessage={errorMessage}
                    {...dropdownWithTypeTextProps(t)}
                />
            </TooltipHost>
        </div>
    );
};

export function applyInferableColumnSelectionToState(
    current: null | readonly string[],
    choice: null | string,
    select: boolean
): undefined | null | readonly string[] {
    if (choice === null) {
        if (current !== null && select) {
            return null;
        }
        return;
    }

    if (current === null) {
        if (select) {
            return [choice];
        }
        return;
    }

    const currentColumns = current;
    const alreadySelected = currentColumns.includes(choice);

    let newColumns: string[];

    if (select) {
        if (alreadySelected) {
            return;
        } else {
            newColumns = [...currentColumns, choice];
        }
    } else {
        if (alreadySelected) {
            if (currentColumns.length === 1) {
                return null;
            }
            newColumns = currentColumns.filter((e) => e !== choice);
        } else {
            return;
        }
    }

    return newColumns;
}

export function createInferableMultipleColumnOption<C extends VisualOptionKey = VisualOptionKey, H = unknown>(
    key: VisualOptionsKeysFor<C, null | readonly string[]>,
    label: string,
    {
        selectInferColumns,
        selectErrorMessage,
    }: {
        selectInferColumns?: VisualInputSelector<C, undefined | readonly string[], H>;
        selectErrorMessage?: VisualInputSelector<C, undefined | string, H>;
    } = {}
): VisualInput<C, H> {
    return {
        id: `columnsNullable--${key}`,
        keys: [key],
        Component: observer(function EtpVisualOptionsInferableMultipleColumnOption({ t, disabled, model }) {
            const selected = model.get(key);

            const { onChange, inferColumns, errorMessage } = React.useMemo(
                () => ({
                    onChange: mobx.action((_: unknown, option?: SelectedOption) => {
                        if (option === undefined) {
                            throw new KweException();
                        }
                        const newValue = applyInferableColumnSelectionToState(
                            model.get(key),
                            option.data,
                            !!option.selected
                        );
                        if (newValue !== undefined) {
                            model.set(key, newValue);
                        }
                    }),
                    inferColumns: model.resolveSelector(selectInferColumns),
                    errorMessage: model.resolveSelector(selectErrorMessage),
                }),
                [model]
            );

            return (
                <MultipleColumnDropdown
                    t={t}
                    selected={selected}
                    schema={model.getSchema()}
                    onChange={onChange}
                    disabled={disabled}
                    id={key}
                    label={label}
                    addInferChoice
                    inferColumns={inferColumns.get()}
                    errorMessage={errorMessage.get()}
                />
            );
        }),
    };
}
