import * as React from 'react';
import { mergeArrowOffset, resolvePositioningShorthand, usePositioning } from '@fluentui/react-positioning';
import { useTooltipVisibility_unstable as useTooltipVisibility, useFluent_unstable as useFluent } from '@fluentui/react-shared-contexts';
import { KEYBORG_FOCUSIN } from '@fluentui/react-tabster';
import { applyTriggerPropsToChildren, useControllableState, useId, useIsomorphicLayoutEffect, useIsSSR, useMergedRefs, useTimeout, getTriggerChild, mergeCallbacks, useEventCallback, slot } from '@fluentui/react-utilities';
import { arrowHeight, tooltipBorderRadius } from './private/constants';
import { Escape } from '@fluentui/keyboard-keys';
/**
 * Create the state required to render Tooltip.
 *
 * The returned state can be modified with hooks such as useTooltipStyles_unstable,
 * before being passed to renderTooltip_unstable.
 *
 * @param props - props from this instance of Tooltip
 */ export const useTooltip_unstable = (props)=>{
    var _child_props, _child_props1, _child_props2, _child_props3;
    const context = useTooltipVisibility();
    const isServerSideRender = useIsSSR();
    const { targetDocument } = useFluent();
    const [setDelayTimeout, clearDelayTimeout] = useTimeout();
    const { appearance = 'normal', children, content, withArrow = false, positioning = 'above', onVisibleChange, relationship, showDelay = 250, hideDelay = 250, mountNode } = props;
    const [visible, setVisibleInternal] = useControllableState({
        state: props.visible,
        initialState: false
    });
    const setVisible = React.useCallback((ev, data)=>{
        clearDelayTimeout();
        setVisibleInternal((oldVisible)=>{
            if (data.visible !== oldVisible) {
                onVisibleChange === null || onVisibleChange === void 0 ? void 0 : onVisibleChange(ev, data);
            }
            return data.visible;
        });
    }, [
        clearDelayTimeout,
        setVisibleInternal,
        onVisibleChange
    ]);
    const state = {
        withArrow,
        positioning,
        showDelay,
        hideDelay,
        relationship,
        visible,
        shouldRenderTooltip: visible,
        appearance,
        mountNode,
        // Slots
        components: {
            content: 'div'
        },
        content: slot.always(content, {
            defaultProps: {
                role: 'tooltip'
            },
            elementType: 'div'
        })
    };
    state.content.id = useId('tooltip-', state.content.id);
    const positioningOptions = {
        enabled: state.visible,
        arrowPadding: 2 * tooltipBorderRadius,
        position: 'above',
        align: 'center',
        offset: 4,
        ...resolvePositioningShorthand(state.positioning)
    };
    if (state.withArrow) {
        positioningOptions.offset = mergeArrowOffset(positioningOptions.offset, arrowHeight);
    }
    const { targetRef, containerRef, arrowRef } = usePositioning(positioningOptions);
    state.content.ref = useMergedRefs(state.content.ref, containerRef);
    state.arrowRef = arrowRef;
    // When this tooltip is visible, hide any other tooltips, and register it
    // as the visibleTooltip with the TooltipContext.
    // Also add a listener on document to hide the tooltip if Escape is pressed
    useIsomorphicLayoutEffect(()=>{
        if (visible) {
            var _context_visibleTooltip;
            const thisTooltip = {
                hide: (ev)=>setVisible(undefined, {
                        visible: false,
                        documentKeyboardEvent: ev
                    })
            };
            (_context_visibleTooltip = context.visibleTooltip) === null || _context_visibleTooltip === void 0 ? void 0 : _context_visibleTooltip.hide();
            context.visibleTooltip = thisTooltip;
            const onDocumentKeyDown = (ev)=>{
                if (ev.key === Escape && !ev.defaultPrevented) {
                    thisTooltip.hide(ev);
                    // stop propagation to avoid conflicting with other elements that listen for `Escape`
                    // e,g: Dialog, Popover, Menu and Tooltip
                    ev.preventDefault();
                }
            };
            targetDocument === null || targetDocument === void 0 ? void 0 : targetDocument.addEventListener('keydown', onDocumentKeyDown, {
                // As this event is added at targeted document,
                // we need to capture the event to be sure keydown handling from tooltip happens first
                capture: true
            });
            return ()=>{
                if (context.visibleTooltip === thisTooltip) {
                    context.visibleTooltip = undefined;
                }
                targetDocument === null || targetDocument === void 0 ? void 0 : targetDocument.removeEventListener('keydown', onDocumentKeyDown, {
                    capture: true
                });
            };
        }
    }, [
        context,
        targetDocument,
        visible,
        setVisible
    ]);
    // Used to skip showing the tooltip  in certain situations when the trigger is focued.
    // See comments where this is set for more info.
    const ignoreNextFocusEventRef = React.useRef(false);
    // Listener for onPointerEnter and onFocus on the trigger element
    const onEnterTrigger = React.useCallback((ev)=>{
        if (ev.type === 'focus' && ignoreNextFocusEventRef.current) {
            ignoreNextFocusEventRef.current = false;
            return;
        }
        // Show immediately if another tooltip is already visible
        const delay = context.visibleTooltip ? 0 : state.showDelay;
        setDelayTimeout(()=>{
            setVisible(ev, {
                visible: true
            });
        }, delay);
        ev.persist(); // Persist the event since the setVisible call will happen asynchronously
    }, [
        setDelayTimeout,
        setVisible,
        state.showDelay,
        context
    ]);
    // Callback ref that attaches a keyborg:focusin event listener.
    const [keyborgListenerCallbackRef] = React.useState(()=>{
        const onKeyborgFocusIn = (ev)=>{
            var _ev_detail;
            // Skip showing the tooltip if focus moved programmatically.
            // For example, we don't want to show the tooltip when a dialog is closed
            // and Tabster programmatically restores focus to the trigger button.
            // See https://github.com/microsoft/fluentui/issues/27576
            if ((_ev_detail = ev.detail) === null || _ev_detail === void 0 ? void 0 : _ev_detail.isFocusedProgrammatically) {
                ignoreNextFocusEventRef.current = true;
            }
        };
        // Save the current element to remove the listener when the ref changes
        let current = null;
        // Callback ref that attaches the listener to the element
        return (element)=>{
            current === null || current === void 0 ? void 0 : current.removeEventListener(KEYBORG_FOCUSIN, onKeyborgFocusIn);
            element === null || element === void 0 ? void 0 : element.addEventListener(KEYBORG_FOCUSIN, onKeyborgFocusIn);
            current = element;
        };
    });
    // Listener for onPointerLeave and onBlur on the trigger element
    const onLeaveTrigger = React.useCallback((ev)=>{
        let delay = state.hideDelay;
        if (ev.type === 'blur') {
            // Hide immediately when losing focus
            delay = 0;
            // The focused element gets a blur event when the document loses focus
            // (e.g. switching tabs in the browser), but we don't want to show the
            // tooltip again when the document gets focus back. Handle this case by
            // checking if the blurred element is still the document's activeElement.
            // See https://github.com/microsoft/fluentui/issues/13541
            ignoreNextFocusEventRef.current = (targetDocument === null || targetDocument === void 0 ? void 0 : targetDocument.activeElement) === ev.target;
        }
        setDelayTimeout(()=>{
            setVisible(ev, {
                visible: false
            });
        }, delay);
        ev.persist(); // Persist the event since the setVisible call will happen asynchronously
    }, [
        setDelayTimeout,
        setVisible,
        state.hideDelay,
        targetDocument
    ]);
    // Cancel the hide timer when the mouse or focus enters the tooltip, and restart it when the mouse or focus leaves.
    // This keeps the tooltip visible when the mouse is moved over it, or it has focus within.
    state.content.onPointerEnter = mergeCallbacks(state.content.onPointerEnter, clearDelayTimeout);
    state.content.onPointerLeave = mergeCallbacks(state.content.onPointerLeave, onLeaveTrigger);
    state.content.onFocus = mergeCallbacks(state.content.onFocus, clearDelayTimeout);
    state.content.onBlur = mergeCallbacks(state.content.onBlur, onLeaveTrigger);
    const child = getTriggerChild(children);
    const triggerAriaProps = {};
    if (relationship === 'label') {
        // aria-label only works if the content is a string. Otherwise, need to use aria-labelledby.
        if (typeof state.content.children === 'string') {
            triggerAriaProps['aria-label'] = state.content.children;
        } else {
            triggerAriaProps['aria-labelledby'] = state.content.id;
            // Always render the tooltip even if hidden, so that aria-labelledby refers to a valid element
            state.shouldRenderTooltip = true;
        }
    } else if (relationship === 'description') {
        triggerAriaProps['aria-describedby'] = state.content.id;
        // Always render the tooltip even if hidden, so that aria-describedby refers to a valid element
        state.shouldRenderTooltip = true;
    }
    // Don't render the Tooltip in SSR to avoid hydration errors
    if (isServerSideRender) {
        state.shouldRenderTooltip = false;
    }
    // Apply the trigger props to the child, either by calling the render function, or cloning with the new props
    state.children = applyTriggerPropsToChildren(children, {
        ...triggerAriaProps,
        ...child === null || child === void 0 ? void 0 : child.props,
        ref: useMergedRefs(child === null || child === void 0 ? void 0 : child.ref, keyborgListenerCallbackRef, // If the target prop is not provided, attach targetRef to the trigger element's ref prop
        positioningOptions.target === undefined ? targetRef : undefined),
        onPointerEnter: useEventCallback(mergeCallbacks(child === null || child === void 0 ? void 0 : (_child_props = child.props) === null || _child_props === void 0 ? void 0 : _child_props.onPointerEnter, onEnterTrigger)),
        onPointerLeave: useEventCallback(mergeCallbacks(child === null || child === void 0 ? void 0 : (_child_props1 = child.props) === null || _child_props1 === void 0 ? void 0 : _child_props1.onPointerLeave, onLeaveTrigger)),
        onFocus: useEventCallback(mergeCallbacks(child === null || child === void 0 ? void 0 : (_child_props2 = child.props) === null || _child_props2 === void 0 ? void 0 : _child_props2.onFocus, onEnterTrigger)),
        onBlur: useEventCallback(mergeCallbacks(child === null || child === void 0 ? void 0 : (_child_props3 = child.props) === null || _child_props3 === void 0 ? void 0 : _child_props3.onBlur, onLeaveTrigger))
    });
    return state;
};
