import type { Locale } from '../types';
import type { DataFrameTypes, KustoDataType } from './dataTypes';

export type ValueToDisplayString<T> = (value: T, timeZone: string, locale: Locale) => string;

function formatNumericString(value: string, timeZone: string, locale: Locale) {
    try {
        // Types not correct because we're using types for our target browser versions, which don't support strings here
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new Intl.NumberFormat(locale).format(value as any);
    } catch {
        // Fallback to no formatting if the browser doesn't support formatting strings as numbers
        // https://caniuse.com/mdn-javascript_builtins_intl_numberformat_format_number_parameter-string_decimal
        return value;
    }
}

export const valueToDisplayStringImpl: {
    readonly [Tag in KustoDataType]: ValueToDisplayString<DataFrameTypes[Tag]>;
} = {
    string(value) {
        return value;
    },
    int(value, _timeZone, locale) {
        return value.toLocaleString(locale);
    },
    long: formatNumericString,
    real(value, _timeZone, locale) {
        return value.toLocaleString(locale);
    },
    decimal: formatNumericString,
    bool(value) {
        return value.toString();
    },
    datetime(value: number, timeZone: string, locale) {
        return new Date(value).toLocaleString(locale, { timeZone });
    },
    // TODO: Localize
    timespan(value) {
        return value;
    },
    guid(value) {
        return value;
    },
    // TODO: Localize nested values?
    // TODO: Not very well tested
    dynamic(value, _timeZone, locale) {
        if (typeof value === 'number') {
            return value.toLocaleString(locale);
        }
        return JSON.stringify(value);
    },
};
