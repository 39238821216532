import type { DeletedFlags, FeatureFlagList, TypeFromFlagList } from '@kusto/app-common';
import type { VisualFwkFeatureFlag } from '@kusto/visual-fwk';

export const dashboardFlags = {
    'Dashboards feature': [
        [
            'baseQueriesAsDataSource',
            'Enables using a base query as a tile query. Queries can be selected from the data source dropdown.',
        ],
        ['baseQueryCascading', "Enables using a base query that's in a different data source"],
        ['queryBatching', 'Enables sending batches to the kusto scope that include more than one query in the result'],
        ['exportAsVersion', 'Enables UX for exporting a dashboard as a specific version'],
        'scope--json',
        'scope--csv',
        'enable-msa-sharing',
        ['newRtdShareMenu', 'Enables new permission sharing experience'],
        [
            'dashboards--experimental-system-variables',
            'Experimental system variables. Type "_app" to auto-complete available variables.',
        ],
    ],
    'Dashboards Debug': [
        'debugVisualConfig',
        ['tile-menu--copy-as-dev-json', "Enables coping query results as KWE's internal format in the tile menu"],
    ],
    'Dashboards system upgrades': [
        ['OutageGeoMigrationWarning', 'Display message bar that a system migration is in progress'],
    ],
} as const satisfies FeatureFlagList;

export const dashboardsUrlFeatureFlags = {
    'Dashboards embedding': [
        'DisableDashboardTopBar',
        'DisableNewDashboard',
        'DisableDashboardSearch',
        'DisableDashboardEditMenu',
        'DisableDashboardFileMenu',
        'DisableDashboardShareMenu',
        'DisableDashboardDelete',
        'DisableTileRefresh',
        'DisableDashboardAutoRefresh',
        'DisableExploreQuery',
        'DisableCrossFiltering',
        'HideDashboardParametersBar',
    ],
} as const satisfies FeatureFlagList;

export type DashboardsFeatureFlag =
    | TypeFromFlagList<typeof dashboardFlags>
    | VisualFwkFeatureFlag
    | TypeFromFlagList<typeof dashboardsUrlFeatureFlags>
    | VisualFwkFeatureFlag;

export const deletedFlags: DeletedFlags<DashboardsFeatureFlag> = {
    sharedQueryAsDataSource: {
        date: '2023-06-16',
        renamedTo: 'baseQueriesAsDataSource',
    },
    sharedQueryCascading: {
        date: '2023-06-16',
        renamedTo: 'baseQueryCascading',
    },
    baseQueries: {
        date: '2023-10-04',
    },
};
