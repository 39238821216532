import * as React from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';

export interface ErrorSnackbarProps {
    id?: string;
    message: string;
    level?: MessageBarType;
}

export const ErrorSnackbar: React.FC<ErrorSnackbarProps> = ({ id, message, level }) => {
    return (
        <MessageBar
            id={id}
            className="error-snackbar"
            styles={{
                root: {
                    minHeight: 48,
                    maxHeight: 144,
                    overflow: 'auto',
                    whiteSpace: 'pre-wrap',
                },
            }}
            messageBarType={level || MessageBarType.error}
            // tabIndex is to make the error focusable and therefore scrollable using the keyboard
            tabIndex={0}
            aria-label={message}
        >
            {message}
        </MessageBar>
    );
};
