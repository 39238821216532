import type { Database, Function, InputParameter, Table } from '@kusto/monaco-kusto';

import {
    BaseTableSchema,
    isTabularParameter,
    KustoDatabaseSchema,
    KustoFunctionSchema,
    KustoInputParameterSchema,
    ScalarParameter,
    TabularParameter,
} from './schemaTypes';

/**
 * Convert Database Json (Json returned from .show schema) into a Monaco-Kusto's database symbol.
 */
export const convertDatabaseJsonToDatabaseSymbol = (db: KustoDatabaseSchema): Database => {
    const convertTableJsonToTableSymbol = (table: BaseTableSchema): Table => ({
        name: table.Name ?? '',
        docstring: table.DocString,
        entityType: table.tableType,
        columns: table.OrderedColumns.map((col) => ({
            name: col.Name,
            type: col.Type,
            docstring: col.DocString,
        })),
    });
    const tables = Object.values(db.Tables).map((table: BaseTableSchema) => convertTableJsonToTableSymbol(table));
    const materializedViews = Object.values(db.MaterializedViews ?? []).map((table: BaseTableSchema) =>
        convertTableJsonToTableSymbol(table)
    );
    const externalTables = Object.values(db.ExternalTables ?? []).map((table: BaseTableSchema) =>
        convertTableJsonToTableSymbol(table)
    );
    const allTables = tables.concat(materializedViews).concat(externalTables);

    // eslint-disable-next-line @typescript-eslint/ban-types
    const convertFuncJsonToFunctionSymbol = (f: KustoFunctionSchema): Function => {
        const inputParameters = !f.InputParameters
            ? []
            : f.InputParameters.map((inputParameter: KustoInputParameterSchema) =>
                  convertInputParametersFunctionSymbol(inputParameter)
              );

        return {
            name: f.Name,
            body: f.Body,
            docstring: f.DocString,
            inputParameters: inputParameters,
        };
    };

    const entityGroups = Object.entries(db.EntityGroups ?? {}).map(([entityGroupName, entityGroup]) => {
        return {
            name: entityGroupName,
            members: entityGroup,
        };
    });

    return {
        name: db.Name,
        alternateName: db.PrettyName,
        majorVersion: db.MajorVersion,
        minorVersion: db.MinorVersion,
        tables: allTables,
        functions: Object.values(db.Functions ?? {}).map((func: KustoFunctionSchema) =>
            convertFuncJsonToFunctionSymbol(func)
        ),
        entityGroups,
    };
};

export const convertInputParametersFunctionSymbol = (inputParameter: KustoInputParameterSchema): InputParameter => {
    return isTabularParameter(inputParameter)
        ? convertTabularParameter(inputParameter)
        : convertScalarParameter(inputParameter);
};

function convertTabularParameter(tabularParameter: TabularParameter): InputParameter {
    return {
        name: tabularParameter.Name,
        columns: tabularParameter.Columns?.map(convertInputParametersFunctionSymbol),
    };
}

function convertScalarParameter(scalarParameter: ScalarParameter): InputParameter {
    return {
        name: scalarParameter.Name,
        cslType: scalarParameter.CslType,
        type: scalarParameter.Type,
        cslDefaultValue: scalarParameter.CslDefaultValue,
    };
}
