import * as React from 'react';
import { observer } from 'mobx-react-lite';

import {
    Chart,
    ChartError,
    ChartEvents,
    ChartProps,
    Columns,
    ExtendedVisualizationOptions,
    Rows,
} from '@kusto/visualizations';

import { useQueryCore } from '../../core/core';
import { getTelemetryClient } from '../../utils/telemetryClient';

const { trackEvent } = getTelemetryClient({ component: 'Chart', flow: '' });

export interface KweChartProps {
    /**
     * Kusto visualization options as supported by Kusto render command
     */
    visualizationOptions: ExtendedVisualizationOptions;
    rows: Rows;
    columns: Columns;
    disableAnimation?: boolean;
    onPointClick?: ChartEvents['onPointClick'];
    onHighchartsPointMenuItems?: ChartEvents['onHighchartsPointMenuItems'];
    formatMessage?: ChartProps['formatMessage'];
    chartTitle?: string;
}

export const staticChartEvents: Pick<ChartEvents, 'presentingError' | 'presentingChart'> = {
    presentingError(error: ChartError, visualization: string) {
        trackEvent('presenting-chart-error', { error, visualization });
    },
    presentingChart(rowsCount: number, visualization: string) {
        trackEvent('presenting-chart', { rowsCount: `${rowsCount}`, visualization });
    },
};

export const KweChart = observer(function KweChart({
    columns,
    rows,
    visualizationOptions,
    disableAnimation,
    onPointClick,
    onHighchartsPointMenuItems,
    formatMessage,
    chartTitle,
}: KweChartProps) {
    const core = useQueryCore();

    const events: Partial<ChartEvents> = React.useMemo(() => {
        return {
            ...staticChartEvents,
            onPointClick,
            onHighchartsPointMenuItems,
            disableChartLimits: () => core.store.settings.setIgnoreChartLimits(true),
        };
    }, [core, onHighchartsPointMenuItems, onPointClick]);

    return (
        <Chart
            strings={core.strings}
            locale={core.strings.locale}
            telemetry={core.telemetry}
            enableInteractiveLegend={core.featureFlags.EnableInteractiveLegend}
            showAllSeriesOnHover={core.store.settings.showAllSeriesOnHover}
            theme={core.store.settings.theme}
            useBoost={core.store.settings.useBoost}
            azureMapSubscriptionKey={core.config?.azureMapToken}
            columns={columns}
            rows={rows}
            visualizationOptions={visualizationOptions}
            disableAnimation={disableAnimation}
            events={events}
            timezone={core.store.settings.resolvedTimeZone}
            formatMessage={formatMessage}
            chartTitle={chartTitle}
            ignoreChartLimits={core.store.settings.ignoreChartLimits}
        />
    );
});
