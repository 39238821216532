import { err, formatLiterals, IKweTelemetry, Mutable, ok, Result } from '@kusto/utils';

import type { KustoDomains } from './KustoDomains';
import type { KustoClientLocale } from './types';

export interface ConnectionInfo {
    readonly clusterName: string;
    readonly connectionString: string;
    readonly initialCatalog?: string;
}

/**
 * By deferring access to string we allow people to call
 * {@link parseClusterConnectionString} when localized copy hasn't been loaded
 * yet
 */
export type ParseConnectionStringErrorMessage = (strings: KustoClientLocale) => string;

export type ParseConnectionStringError =
    | {
          readonly code: 'invalidUrl';
          readonly message: ParseConnectionStringErrorMessage;
      }
    | {
          readonly code: 'unknownHost';
          readonly host: string;
          readonly message: ParseConnectionStringErrorMessage;
      }
    | {
          readonly code: 'unexpectedCrash';
          readonly exception: unknown;
          readonly message: ParseConnectionStringErrorMessage;
      };

export type ParseConnectionStringResult = Result<ConnectionInfo, ParseConnectionStringError>;

/**
 * Returns the cluster name and url from user input that tries to identify a cluster
 * Note: Cluster name is the name shown before `.kusto.windows.net`, alias is a display name user gives to a cluster.
 *
 * @param telemetry Used to log an exception if the parsing fails in an unexpected way. If not passed, the returned error should be logged by the caller.
 * @param userInput User input meant to identify a cluster. supported formats are
 *
 * kuskus
 * kuskus.eastus
 * kuskus.kusto.windows.net
 * kusto.aria.microsoft.com
 * https://kuskus.kusto.windows.net
 */
export function parseClusterConnectionString(
    telemetry: undefined | IKweTelemetry,
    kustoDomains: KustoDomains,
    userInput: string
): ParseConnectionStringResult {
    userInput = userInput.trim();

    let url: URL | undefined = undefined;
    let clusterName: string;
    let connectionString: string;

    const decodedInput = decodeURIComponent(userInput);
    const isInputEncoded = decodedInput !== userInput;

    // if this is a proper url
    try {
        url = new URL(decodedInput);
    } catch {}

    try {
        let withInitialCatalog = false;
        if (!url) {
            const uri = encodeURI(
                'https://' +
                    (isInputEncoded || kustoDomains.isKustoSubDomain(userInput) || decodedInput.indexOf('/') > 0
                        ? decodedInput
                        : userInput + kustoDomains.Default)
            );
            try {
                url = new URL(uri);
            } catch {
                return err({
                    code: 'invalidUrl',
                    message: (strings) => strings.client.parseClusterConnectionString.invalidUrl,
                });
            }

            // Add initial catalog support only to Aria clusters (authorization issue)
            withInitialCatalog =
                kustoDomains.isAriaDomain(url.host) &&
                !!url.pathname &&
                url.pathname !== '/' &&
                url.pathname.length > 0;
        }

        // Validate that we're sending a token to an allowed host
        if (!kustoDomains.isUrlKustoSubDomain(url)) {
            const host = `${url.protocol}//${url.host}`;
            return err({
                code: 'unknownHost',
                host,
                message: (strings) =>
                    formatLiterals(strings.client.parseClusterConnectionString.notAdxHost, {
                        host,
                    }),
            });
        }

        connectionString = withInitialCatalog ? url.origin : url.toString() + ';fed=true';
        clusterName = encodeURIComponent(
            // First decode url to avoid double encoding then encodeURIComponent
            // * URL to string return string which is uri encoded
            // * alias need to be uri component encoded (it used as part uri pathname )
            decodeURI(kustoDomains.getClusterName(withInitialCatalog ? url.hostname : url.toString()))
        );

        const res: Mutable<ConnectionInfo> = {
            connectionString,
            clusterName: clusterName,
        };

        if (withInitialCatalog) {
            res.initialCatalog = url.pathname.slice(1);
        }

        return ok(res);
    } catch (exception) {
        telemetry?.exception('Unexpectedly failed to parse URI', { exception });
        return err({
            code: 'unexpectedCrash',
            exception,
            message: (strings) => strings.utils.util.error.unidentifiedErrorMessage,
        });
    }
}
