import React from 'react';

import * as styles from './error.module.scss';

export type QueryMessageLevel = 'error' | 'warn' | 'info';

/**
 * TODO(visual-fwk): Move into dashboards package. No reason to have this here.
 */
export type VariableSource =
    | {
          readonly kind: 'parameter';
          readonly parameterId: string;
      }
    | {
          readonly kind: 'baseQuery';
          readonly baseQueryId: string;
      }
    /**
     * Always available, not created by users. For example,
     * "_dashboard_timezone" Prefixed with "_dashboard".
     */
    | { readonly kind: 'system' };

export interface SingleQueryMessage {
    readonly title: string;
    readonly body?: string;
    readonly level: QueryMessageLevel;
    /**
     * If message comes from a dashboard variable, source information is added
     * here. If undefined, message can be assumed to have come from the current
     * item.
     *
     * Currently only actually used to track if a message has been for a
     * dashboard variable or not, so we could just make it a boolean if the
     * extra information doesn't prove useful.
     *
     * TODO(visual-fwk): Move into dashboards package. No reason to have this here.
     */
    readonly variableSource?: VariableSource;
}

/**
 * Normal version of {@link QueryError}
 */
export type QueryMessageNormalized = SingleQueryMessage | readonly SingleQueryMessage[];

export type QueryMessagesNormal = readonly SingleQueryMessage[];

/**
 * Serializable error message
 */
export function queryErrorMessage(
    title: string,
    {
        body,
        level = 'error',
        variableSource,
    }: { body?: string; level?: QueryMessageLevel; variableSource?: VariableSource } = {}
): SingleQueryMessage {
    return { title, body, level, variableSource };
}

export function maxQueryMessageLevel(message: QueryMessageNormalized): QueryMessageLevel {
    if (!('length' in message)) {
        return message.level;
    }

    let maxLevel: QueryMessageLevel = 'info';

    for (const item of message) {
        switch (item.level) {
            case 'error':
                return 'error';
            case 'warn':
                maxLevel = 'warn';
        }
    }

    return maxLevel;
}

/**
 * For now this doesn't contain many styles of it's own. The container is
 * expected to style it. This makes changes hard, so it might be nice if we
 * created a few canned versions of it (large/small?) to make maintenance easier.
 */
export function renderQueryError(err: QueryMessageNormalized, title?: string): React.ReactElement {
    if (Array.isArray(err)) {
        if (err.length === 1) {
            err = err[0];
        } else {
            const _err = err as Extract<typeof err, readonly unknown[]>;
            return (
                <>
                    {title && <p>{title}</p>}
                    {/* TODO(multiple errors): Needs design input */}
                    <ul className={styles.list}>
                        {_err.map((e, i) => (
                            <li key={i}>
                                {!e.body ? (
                                    e.title
                                ) : (
                                    <>
                                        <strong>{e.title}</strong>: {e.body}
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </>
            );
        }
    }
    const e = err as SingleQueryMessage;

    return (
        <>
            {title && (
                <p>
                    <strong>{title}</strong>
                </p>
            )}
            <p>{!title && e.body ? <strong>{e.title}</strong> : e.title}</p>
            {e.body && <p>{e.body}</p>}
        </>
    );
}
