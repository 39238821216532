export var SelectionHandleType;
(function (SelectionHandleType) {
    SelectionHandleType[SelectionHandleType["FILL"] = 0] = "FILL";
    SelectionHandleType[SelectionHandleType["RANGE"] = 1] = "RANGE";
})(SelectionHandleType || (SelectionHandleType = {}));
export var CellRangeType;
(function (CellRangeType) {
    CellRangeType[CellRangeType["VALUE"] = 0] = "VALUE";
    CellRangeType[CellRangeType["DIMENSION"] = 1] = "DIMENSION";
})(CellRangeType || (CellRangeType = {}));
