import { useIsomorphicLayoutEffect } from '@fluentui/react-utilities';
import * as React from 'react';
import { useResizeObserverRef_unstable } from './useResizeObserverRef';
import { useRef } from 'react';
/**
 * React hook that measures virtualized space dynamically to ensure optimized virtualization length.
 */ export const useDynamicVirtualizerMeasure = (virtualizerProps)=>{
    const { defaultItemSize, direction = 'vertical', numItems, getItemSize, currentIndex } = virtualizerProps;
    const indexRef = useRef(currentIndex);
    indexRef.current = currentIndex;
    const [state, setState] = React.useState({
        virtualizerLength: 0,
        virtualizerBufferItems: 0,
        virtualizerBufferSize: 0
    });
    const { virtualizerLength, virtualizerBufferItems, virtualizerBufferSize } = state;
    const container = React.useRef(null);
    const handleScrollResize = React.useCallback((scrollRef)=>{
        if (!(scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current)) {
            // Error? ignore?
            return;
        }
        if (scrollRef.current !== container.current) {
            container.current = scrollRef.current;
        }
        const containerSize = direction === 'vertical' ? scrollRef.current.getBoundingClientRect().height : scrollRef.current.getBoundingClientRect().width;
        let indexSizer = 0;
        let length = 0;
        while(indexSizer <= containerSize && length < numItems){
            const iItemSize = getItemSize(indexRef.current + length);
            // Increment
            indexSizer += iItemSize;
            length++;
        }
        /*
       * Number of items to append at each end, i.e. 'preload' each side before entering view.
       */ const bufferItems = Math.max(Math.floor(length / 4), 4);
        /*
       * This is how far we deviate into the bufferItems to detect a redraw.
       */ const bufferSize = Math.max(Math.floor(length / 8 * defaultItemSize), 1);
        const totalLength = length + bufferItems * 2 + 1;
        setState({
            virtualizerLength: totalLength,
            virtualizerBufferSize: bufferSize,
            virtualizerBufferItems: bufferItems
        });
    }, [
        defaultItemSize,
        direction,
        getItemSize,
        numItems
    ]);
    const resizeCallback = React.useCallback((_entries, _observer, scrollRef)=>{
        if (scrollRef) {
            handleScrollResize(scrollRef);
        }
    }, [
        handleScrollResize
    ]);
    const scrollRef = useResizeObserverRef_unstable(resizeCallback);
    useIsomorphicLayoutEffect(()=>{
        var _container_current, _container_current1;
        if (!container.current) {
            return;
        }
        const containerSize = direction === 'vertical' ? ((_container_current = container.current) === null || _container_current === void 0 ? void 0 : _container_current.getBoundingClientRect().height) * 1.5 : ((_container_current1 = container.current) === null || _container_current1 === void 0 ? void 0 : _container_current1.getBoundingClientRect().width) * 1.5;
        let couldBeSmaller = false;
        let recheckTotal = 0;
        for(let i = currentIndex; i < currentIndex + virtualizerLength; i++){
            const newItemSize = getItemSize(i);
            recheckTotal += newItemSize;
            const newLength = i - currentIndex;
            const bufferItems = Math.max(Math.floor(newLength / 4), 2);
            const totalNewLength = newLength + bufferItems * 2 + 4;
            const compareLengths = totalNewLength < virtualizerLength;
            if (recheckTotal > containerSize && compareLengths) {
                couldBeSmaller = true;
                break;
            }
        }
        // Check if the render has caused us to need a re-calc of virtualizer length
        if (recheckTotal < containerSize || couldBeSmaller) {
            handleScrollResize(container);
        }
    }, [
        getItemSize,
        currentIndex,
        direction,
        virtualizerLength,
        resizeCallback,
        handleScrollResize
    ]);
    return {
        virtualizerLength,
        bufferItems: virtualizerBufferItems,
        bufferSize: virtualizerBufferSize,
        scrollRef
    };
};
