import { KustoDomains } from '@kusto/client';

function isTrustedHostValid(trustedHost: string): boolean {
    try {
        new RegExp(trustedHost);
        return true;
    } catch {
        return false;
    }
}

/**
 * This method uses kusto client to set kusto sub domains and additional trusted hosts.
 * @param rawTrustedHosts optional trusted hosts, separated by ';'
 */
export function configureSubDomains(kustoDomains: KustoDomains, rawTrustedHosts?: string) {
    const domains: string[] = [];

    if (rawTrustedHosts) {
        // modify trusted hosts value: remove white spaces, split, remove empty and invalid items
        domains.push(
            ...rawTrustedHosts
                .replace(/\s+/g, '')
                .split(';')
                .filter((host) => host && isTrustedHostValid(host))
        );
    }

    kustoDomains.setDomains(domains);
}
