import React from 'react';
import { Button } from '@fluentui/react-button';
import { makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';

import type { Aborted, KweUtilsStrings, Ok } from '@kusto/utils';

import { RenderHelper } from '../utils/RenderHelper';

export const useAlertStyles = makeStyles({
    title: {
        // Fix issue with Fluent ui: https://github.com/microsoft/fluentui/issues/31839
        overflowWrap: 'break-word',
    },
});

export interface KweAlertProps extends Omit<KweAlertOptions, 'onResolved' | 'signal'> {
    title: React.ReactNode;

    t: KweUtilsStrings;
    resolve: () => void;
}

/**
 * Designed primarily for use with {@link kweAlert}. Intentionally does _not_
 * support many options to keep implementation simple. If flexibility is needed,
 * copy+paste and customize.
 */
export const KweAlert: React.FC<KweAlertProps> = ({
    resolve,
    t,
    title,
    content,
    closeText,
    additionalActions,
    ...dialogProps
}) => {
    const classes = useAlertStyles();

    return (
        <Dialog
            open
            modalType="alert"
            onOpenChange={(_event, data) => {
                if (!data.open) {
                    resolve();
                }
            }}
            {...dialogProps}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle className={classes.title}>{title}</DialogTitle>
                    {content && <DialogContent>{content}</DialogContent>}
                    {/* Not sure why `fluid` isn't the default behavior */}
                    <DialogActions fluid>
                        <DialogTrigger>
                            <Button appearance="primary">{closeText ?? t.util.buttons.close}</Button>
                        </DialogTrigger>
                        {additionalActions}
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export interface KweAlertOptions extends Omit<DialogProps, 'children'> {
    content?: React.ReactNode;
    closeText?: string;
    additionalActions?: React.ReactNode;
    /**
     * TODO(a11y): remove this once we upgrade to Fluent 9 across Dashboards
     */
    onResolved?: () => void;
    signal?: AbortSignal;
}

/**
 * A note on a11y: When using {@link kweAlert}, please check if the focus correctly moves to the Alert
 * and when the Alert is closed (de-rendered) the focus moves back to the original trigger element. If this
 * isn't happening automatically, then you can use the `onResolved` callback to manually move focus yourself.
 * Please use `onResolved` if needed so it will be easier for us to remove it later once we've fully upgraded
 * to Fluent 9 which _should_ solve these focus issues for us automatically.
 *
 * @example
 *
 * async function handleClick() {
 *   await kweAlert(..., {
 *     onResolved: () => {
 *       htmlDropdownRef.current.focus();
 *     }
 *   });
 *   // do more stuff
 * }
 */
export async function kweAlert(
    render: RenderHelper,
    t: KweUtilsStrings,
    title: React.ReactNode,
    options?: KweAlertOptions
): Promise<Ok<unknown> | Aborted> {
    const res = await render(
        (resolve) => <KweAlert t={t} title={title} {...options} resolve={resolve} />,
        options?.signal
    );
    options?.onResolved?.();
    return res;
}
