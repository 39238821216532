import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';

/**
 * Get the container element.
 * @param container Container element ID or container element.
 * @returns HTML element, or throws an error if not found.
 */
export function getContainer(container: string | HTMLElement): HTMLElement {
    if (typeof container !== 'string') {
        return container;
    }

    const element = document.getElementById(container);
    if (!element) {
        throw new Error('RenderComponent: Container element not found');
    }

    return element;
}

export const unrenderComponent = (container: string | HTMLElement) => {
    ReactDOM.render(<></>, getContainer(container));
};

export function useRenderComponent() {
    const renderComponent = useCallback(
        /**
         * Render a custom component.
         * @param Component to render.
         * @param container Container element ID.
         * @param WrapperComponent Optional wrapper component; can be used for Theme or Context warpers. Defaults to Fragment.
         */
        (
            Component: React.FunctionComponent,
            container: string | HTMLElement,
            WrapperComponent: React.FunctionComponent = React.Fragment
        ) => {
            const containerElement = getContainer(container);

            ReactDOM.render(
                <WrapperComponent>
                    <Component />
                </WrapperComponent>,
                containerElement
            );
        },
        []
    );

    return {
        unrenderComponent,
        renderComponent,
    };
}
