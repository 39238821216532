.container {
    display: flex;
    flex-flow: column nowrap;

    .pointInfo {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        gap: var(--spacingHorizontalS);
        padding: var(--spacingVerticalS) var(--spacingHorizontalS) var(--spacingVerticalM);

        .pointName {
            display: flex;
            gap: var(--spacingHorizontalXS);
            align-items: center;
        }
    }

    .pointActions {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        gap: var(--spacingHorizontalS);
        border-top: var(--strokeWidthThin) solid var(--colorNeutralStroke1);
    }
}
