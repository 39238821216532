import React from 'react';
import { Button, ButtonProps } from '@fluentui/react-components';
import { Add20Regular, ArrowReset20Regular } from '@fluentui/react-icons';
import * as mobx from 'mobx';
import { Observer, observer } from 'mobx-react-lite';

import { useRender } from '@kusto/ui-components';
import { KweException, useAbortSignal, usePullState } from '@kusto/utils';

import type { RtdPage } from '../../dashboardApi';
import type { InteractionTarget } from '../../interactions/target';
import type { KweVisualFwkLocale } from '../../types';
import { VisualInput, VisualInputSelector } from '../../visualConfig/input';
import { DrillthroughConfig, VisualOptionKey, VisualOptionsKeysFor } from '../../visualOptions';
import { ConfigurationItemCallout } from '../configurationList/ConfigurationItemCallout';
import { DrillthroughForm } from './Form';
import { DrillthroughRow, rowId } from './Row';
import { setDifference } from './util';
import { DrillthroughVisual } from './VisualInteraction';

import * as configurationListStyles from '../configurationList/styles.module.scss';
import * as styles from './styles.module.scss';

const DrillthroughActionButton: React.FC<ButtonProps> = (props) => {
    return (
        // Adding extra wrapping div for the sake of simple CSS and centering on tooltip
        <div className={configurationListStyles.addConfigItemButton}>
            <Button appearance="transparent" {...props}>
                {props.children}
            </Button>
        </div>
    );
};

interface DrillthroughDialogProps {
    t: KweVisualFwkLocale;
    id: number;
    config: DrillthroughConfig;
    targetId: string;
    onDismiss: () => void;
    onApply: (config: DrillthroughConfig) => void;
    parameters: readonly InteractionTarget[];
    interaction: DrillthroughVisual.Interaction;
    propertiesIndex: PropertiesIndex;
    parametersRecord: undefined | ReadonlyMap<string, InteractionTarget>;
    pages: readonly RtdPage[];
    pagesRecord: undefined | ReadonlyMap<string, RtdPage>;
    disabledPageIdsByConfigId: Map<number, Readonly<Set<string>>>;
}

const DrillthroughDialog: React.FC<DrillthroughDialogProps> = ({
    t,
    id,
    config,
    targetId,
    onDismiss,
    onApply,
    parameters,
    interaction,
    propertiesIndex,
    parametersRecord,
    pages,
    pagesRecord,
    disabledPageIdsByConfigId,
}) => {
    const [state, setState, getState] = usePullState(config);

    const { onSave, onChange, disabledPageIds } = React.useMemo(
        () => ({
            onSave() {
                onApply(getState());
            },
            onChange(_id: number, value: DrillthroughConfig) {
                setState(value);
            },
            get disabledPageIds() {
                const maybeIds = disabledPageIdsByConfigId.get(id);

                if (!maybeIds) {
                    return new Set<string>();
                }

                return maybeIds;
            },
        }),
        [getState, onApply, setState, id, disabledPageIdsByConfigId]
    );

    return (
        <ConfigurationItemCallout
            title={t.visualFwk.visualConfig.interactions.drillthrough.calloutTitle}
            targetId={targetId}
            className={configurationListStyles.withMargin}
            position="below"
            onClose={onDismiss}
            onSave={onSave}
            applyButtonLabel={t.utils.util.buttons.apply}
            cancelButtonLabel={t.utils.util.buttons.cancel}
        >
            <DrillthroughForm
                t={t}
                id={0}
                value={state}
                onChange={onChange}
                getValue={getState}
                disabled={false}
                parameters={parameters}
                showDisable
                interaction={interaction}
                propertiesIndex={propertiesIndex}
                parametersRecord={parametersRecord}
                pages={pages}
                pagesRecord={pagesRecord}
                disabledPageIds={disabledPageIds}
            />
        </ConfigurationItemCallout>
    );
};

interface ActionsFooterProps {
    t: KweVisualFwkLocale;
    totalConfigsInUse: number;
    totalDisabledPageIds: number;
    resetAllDestinationPages: () => void;
    onAdd: () => void;
}

const ActionsFooter: React.FC<ActionsFooterProps> = ({
    t,
    totalConfigsInUse,
    totalDisabledPageIds,
    resetAllDestinationPages,
    onAdd,
}) => {
    const addButton = (
        <DrillthroughActionButton icon={<Add20Regular />} onClick={onAdd}>
            {totalConfigsInUse === 0
                ? t.visualFwk.visualConfig.interactions.drillthrough.addConfigurationButtonText.none
                : t.visualFwk.visualConfig.interactions.drillthrough.addConfigurationButtonText.many}
        </DrillthroughActionButton>
    );

    const resetAllButton =
        totalConfigsInUse === 0 ? null : (
            <DrillthroughActionButton
                icon={<ArrowReset20Regular />}
                disabled={totalDisabledPageIds === 0}
                onClick={resetAllDestinationPages}
            >
                {t.visualFwk.visualConfig.interactions.drillthrough.resetAllDestinationPages}
            </DrillthroughActionButton>
        );

    return (
        <div className={styles.rootInteractions}>
            {addButton}
            {resetAllButton}
        </div>
    );
};

let lastConfigId = 0;
function newConfigId() {
    return lastConfigId++;
}

function newConfig(): DrillthroughConfig {
    return {
        pairs: [
            {
                property: undefined,
                parameterId: undefined,
            },
        ],
        destinationPages: new Set(),
        notes: undefined,
        disabled: false,
    };
}

export type PropertiesIndex = ReadonlyMap<string, DrillthroughVisual.Property>;

function indexProperties(interaction: DrillthroughVisual): PropertiesIndex {
    const index = new Map<string, DrillthroughVisual.Property>();

    if (interaction.kind === 'available') {
        for (const property of interaction.properties) {
            index.set(property.id, property);
        }
    }

    return index;
}

/**
 * @param interactionsConfig Returning `undefined` disables the input
 * @param idSuffix Right now we're overloading the `DrillthroughConfig` type and
 * saving in it that's different. Use this key to differentiate between them.
 */
export function createDrillthroughConfig<C extends VisualOptionKey, H = unknown>(
    key: VisualOptionsKeysFor<C, null | readonly DrillthroughConfig[]>,
    idSuffix: string,
    interactionsConfig: VisualInputSelector<C, DrillthroughVisual | readonly DrillthroughVisual[], H>
): VisualInput<C, H, Map<number, DrillthroughConfig>> {
    return {
        id: `drillthrough--${key}--${idSuffix}`,
        keys: [key],
        init(model, prev) {
            if (prev) {
                return prev;
            }

            let initial = model.get(key);
            if (initial.length === 0) {
                initial = [];
            }
            return new Map(initial.map((config: DrillthroughConfig) => [newConfigId(), config]));
        },
        changed(model) {
            const config = model.getTemp();
            if (config.size !== 1) {
                return true;
            }
            const value: DrillthroughConfig = config.values().next().value;
            return (
                value.disabled ||
                value.notes !== undefined ||
                value.pairs.length !== 0 ||
                value.destinationPages.size !== 0
            );
        },
        Component: observer(function DrillthroughManagedConfig({ t, model, dashboard }) {
            if (!dashboard) {
                throw new KweException('Drillthrough configuration is only support in dashboards');
            }
            const abortSignal = useAbortSignal();
            const render = useRender();

            // Shallow copies of parameters and pages to remove mobx stuff
            const parameterMap = React.useMemo(
                () => mobx.computed(() => new Map(dashboard.state.interactionTargetsMap)),
                [dashboard]
            ).get();
            const pagesMap = React.useMemo(
                () => mobx.computed(() => new Map(dashboard.state.pagesMap)),
                [dashboard]
            ).get();

            const {
                interactionsObs,
                allDisabledPageIdsObs,
                onChange,
                onAdd,
                onDelete,
                onEdit,
                resetDestinationPages,
                resetAllDestinationPages,
            } = React.useMemo(() => {
                const interactions = mobx.computed((): readonly DrillthroughVisual.Interaction[] => {
                    const result = model.resolveSelector(interactionsConfig).get();
                    if (Array.isArray(result)) {
                        return result;
                    }
                    return [result as DrillthroughVisual.Interaction];
                });

                const allDisabledPageIds = mobx.computed((): Readonly<Set<string>> => {
                    const disabledPageIds = new Set<string>();

                    for (const drillthroughConfig of model.getTemp().values()) {
                        for (const pageId of drillthroughConfig.destinationPages) {
                            disabledPageIds.add(pageId);
                        }
                    }

                    return disabledPageIds;
                });

                const disabledPageIdsByConfigId = mobx.computed((): Map<number, Readonly<Set<string>>> => {
                    const map = new Map<number, Readonly<Set<string>>>();

                    for (const [configId, config] of model.getTemp().entries()) {
                        const disabledPageIds = setDifference(allDisabledPageIds.get(), config.destinationPages);
                        map.set(configId, disabledPageIds);
                    }

                    return map;
                });

                const dialogState = mobx.observable<{ showingDialog: boolean; dispose: null | (() => void) }>({
                    showingDialog: false,
                    dispose: null,
                });

                return {
                    interactionsObs: interactions,
                    allDisabledPageIdsObs: allDisabledPageIds,
                    onChange: mobx.action((id: number, drillthrough: DrillthroughConfig) => {
                        const prev = model.getTemp();
                        if (!prev.has(id)) {
                            // Value has been deleted. Ignore action.
                            return;
                        }
                        const next = new Map(prev);
                        next.set(id, drillthrough);
                        model.setTemp(next);
                        model.set(key, [...next.values()]);
                    }),
                    onAdd: mobx.action(() => {
                        const next = new Map(model.getTemp());
                        const id = newConfigId();
                        next.set(id, newConfig());
                        model.setTemp(next);
                        model.set(key, [...next.values()]);
                        onEdit(id);
                    }),
                    onDelete: mobx.action((id: number) => {
                        const prev = model.getTemp();
                        if (!prev.has(id)) {
                            return;
                        }
                        const next = new Map(prev);
                        next.delete(id);
                        // If there is only 1 value left, and it's disabled, enable it.
                        if (next.size === 1 && next.values().next().value.disabled) {
                            const [lastId, value] = next.entries().next().value;
                            next.set(lastId, {
                                ...value,
                                disabled: false,
                            });
                        }
                        model.setTemp(next);
                        model.set(key, [...next.values()]);
                    }),
                    resetDestinationPages: mobx.action((id: number) => {
                        const prev = model.getTemp();
                        const drillthrough = prev.get(id);
                        if (!drillthrough) {
                            // Value has been deleted. Ignore action.
                            return;
                        }
                        const next = new Map(prev);
                        next.set(id, { ...drillthrough, destinationPages: new Set() });
                        model.setTemp(next);
                        model.set(key, [...next.values()]);
                    }),
                    resetAllDestinationPages: mobx.action(() => {
                        const prev = model.getTemp();
                        const next = new Map(prev);

                        for (const id of model.getTemp().keys()) {
                            const drillthrough = prev.get(id);
                            if (!drillthrough) {
                                // Value has been deleted. Ignore action.
                                continue;
                            }
                            next.set(id, { ...drillthrough, destinationPages: new Set() });
                        }

                        model.setTemp(next);
                        model.set(key, [...next.values()]);
                    }),
                    onEdit: mobx.action((id: number) => {
                        const prev = model.getTemp().get(id);
                        if (!prev) {
                            return;
                        }

                        render((dispose, { signal }) => {
                            signal.addEventListener(
                                'abort',
                                mobx.when(() => model.getTemp().get(id) === undefined, dispose)
                            );

                            // Enables UX where clicking on any
                            // edit button will close any opened
                            // dialog
                            if (dialogState.showingDialog) {
                                dialogState.dispose?.();
                                dialogState.showingDialog = false;
                                dialogState.dispose = null;
                            } else {
                                dialogState.dispose = dispose;
                                dialogState.showingDialog = true;
                            }

                            /**
                             * Drillthrough UX should only support the columnInteraction
                             * so we can make the assumption it's the only item in the
                             * interactions array
                             */
                            const interaction = interactions.get()[0];
                            return (
                                <Observer>
                                    {() => (
                                        <DrillthroughDialog
                                            t={t}
                                            id={id}
                                            targetId={`${rowId(id)}`}
                                            config={prev}
                                            onDismiss={dispose}
                                            onApply={(next) => {
                                                onChange(id, next);
                                                dispose();
                                            }}
                                            parameters={dashboard.state.interactionTargets}
                                            interaction={interaction}
                                            disabledPageIdsByConfigId={disabledPageIdsByConfigId.get()}
                                            propertiesIndex={indexProperties(interaction)}
                                            pages={dashboard.state.pages}
                                            // Shallow copies of parameters and pages to remove mobx stuff
                                            parametersRecord={new Map(dashboard.state.interactionTargetsMap)}
                                            pagesRecord={new Map(dashboard.state.pagesMap)}
                                        />
                                    )}
                                </Observer>
                            );
                        }, abortSignal);
                    }),
                };
            }, [abortSignal, model, render, dashboard, t]);

            const resolvedInteractions = interactionsObs.get();

            const tempValues = [...model.getTemp().entries()];

            const { columnInteraction, propertiesIndex } = React.useMemo((): {
                columnInteraction: DrillthroughVisual.Interaction;
                propertiesIndex: PropertiesIndex;
            } => {
                /**
                 * Drillthrough UX should only support the columnInteraction
                 * so we can make the assumption it's the only item in the
                 * interactions array
                 */
                const interaction = resolvedInteractions[0];
                return {
                    columnInteraction: interaction,
                    propertiesIndex: indexProperties(interaction),
                };
            }, [resolvedInteractions]);

            return (
                <>
                    {tempValues.length !== 0 && (
                        <div>
                            {tempValues.map(([id, config]) => (
                                <DrillthroughRow
                                    t={t}
                                    key={id}
                                    id={id}
                                    config={config}
                                    onEdit={onEdit}
                                    onDelete={onDelete}
                                    parametersRecord={parameterMap}
                                    interaction={columnInteraction}
                                    propertiesIndex={propertiesIndex}
                                    pagesRecord={pagesMap}
                                    resetDestinationPages={resetDestinationPages}
                                />
                            ))}
                        </div>
                    )}
                    <ActionsFooter
                        t={t}
                        totalConfigsInUse={tempValues.length}
                        totalDisabledPageIds={allDisabledPageIdsObs.get().size}
                        resetAllDestinationPages={resetAllDestinationPages}
                        onAdd={onAdd}
                    />
                </>
            );
        }),
    };
}
