import React from 'react';
import { VirtualizerScrollViewDynamic } from '@fluentui/react-components/unstable';

import { useStrings } from '../../../hooks/useStrings.tsx';
import { DropdownWithSearchOption } from '../types.ts';
import { DropdownItem } from './DropdownItem.tsx';

import styles from '../DropdownWithSearch.module.scss';

const MAX_OPTIONS_WITHOUT_VIRTUALIZATION = 200; // Virtualizer is still unstable (UX wise), so it's preferable to use it only when necessary.
const OPTION_ITEM_SIZE = 32;

interface DropdownMenuProps {
    options: DropdownWithSearchOption[];
    filteredOptions: DropdownWithSearchOption[];
    renderOption?: (option: DropdownWithSearchOption) => React.ReactElement;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ options, filteredOptions, renderOption }) => {
    const strings = useStrings();

    /** No results message */
    if (!filteredOptions.length) {
        return <div className={styles.noResults}>{strings.uiComponents.dropdownWithSearch.noResults}</div>;
    }

    /** Option list */
    if (options.length > MAX_OPTIONS_WITHOUT_VIRTUALIZATION) {
        return (
            <VirtualizerScrollViewDynamic
                numItems={filteredOptions.length}
                itemSize={OPTION_ITEM_SIZE}
                container={{ style: { height: filteredOptions.length * OPTION_ITEM_SIZE, overflowX: 'hidden' } }}
            >
                {(index) => {
                    return (
                        <DropdownItem
                            key={filteredOptions[index].key}
                            option={filteredOptions[index]}
                            renderOption={renderOption}
                        />
                    );
                }}
            </VirtualizerScrollViewDynamic>
        );
    } else {
        return (
            <>
                {filteredOptions.map((option) => (
                    <DropdownItem key={option.key} option={option} renderOption={renderOption} />
                ))}
            </>
        );
    }
};
