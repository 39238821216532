import React, { useRef } from 'react';
import { Toolbar, ToolbarButton } from '@fluentui/react-components';

import { Icon, renderIconIfDefined } from '@kusto/ui-components';

import { useKql } from '../../hooks/useKql';
import { useDataExplorationStore } from '../../store/DataExplorationStore';
import { VisualState } from '../../store/state';

import styles from './DataExplorationToolbar.module.scss';

type ButtonEl = HTMLButtonElement | HTMLAnchorElement | null;

export interface ToolbarItem {
    /** Item text */
    label: string;
    /** Icon component JSX */
    icon?: Icon | JSX.Element;
    /** Whether the item is disabled or not */
    disabled?: boolean;
    /** The action to execute when the item is clicked */
    onClick: (state: { computedKql: string; visual: VisualState }, buttonEl: ButtonEl) => void;
}

export interface DataExplorationToolbarProps {
    buttons?: ToolbarItem[];
}

export const DataExplorationToolbar: React.FunctionComponent<DataExplorationToolbarProps> = ({ buttons }) => {
    const { visual } = useDataExplorationStore().state;
    const computedKql = useKql();

    const buttonsRef = useRef<ButtonEl[]>(Array.from({ length: buttons?.length ?? 0 }, () => null));

    if (!buttons || buttons.length === 0) {
        return null;
    }

    return (
        <Toolbar className={styles.toolbar}>
            {buttons.map((button, i) => (
                <ToolbarButton
                    onClick={() => button.onClick({ computedKql, visual }, buttonsRef.current[i])}
                    icon={renderIconIfDefined(button.icon)}
                    disabled={button.disabled}
                    key={i}
                    ref={(el) => (buttonsRef.current[i] = el)}
                >
                    {button.label}
                </ToolbarButton>
            ))}
        </Toolbar>
    );
};
