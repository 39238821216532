import React from 'react';
import { Button, Tooltip } from '@fluentui/react-components';
import { ArrowClockwiseRegular, EraserRegular } from '@fluentui/react-icons';

import { useRefreshDataProfile } from '../../../../DataExplorationApi';
import { useQueryResults } from '../../../hooks/useResults';
import { useStrings } from '../../../hooks/useStrings';
import { useDataExplorationStore } from '../../../store/DataExplorationStore';

import styles from '../PillsBar.module.scss';

export const PillBarActions: React.FunctionComponent = () => {
    const { strings } = useStrings();
    const { actions } = useDataExplorationStore();
    const { mutate } = useQueryResults();
    const refreshSchemaColumns = useRefreshDataProfile();

    return (
        <div className={styles.farButtons}>
            <Tooltip relationship="label" content={strings.dataExplorationModal.pillsBar.actions.reset}>
                <Button
                    icon={<EraserRegular />}
                    appearance="transparent"
                    aria-label={strings.dataExplorationModal.pillsBar.actions.reset}
                    onClick={() => actions.resetToInitialState()}
                />
            </Tooltip>
            <Tooltip relationship="label" content={strings.dataExplorationModal.pillsBar.actions.refresh}>
                <Button
                    icon={<ArrowClockwiseRegular />}
                    appearance="transparent"
                    aria-label={strings.dataExplorationModal.pillsBar.actions.refresh}
                    onClick={() => {
                        mutate();
                        refreshSchemaColumns();
                    }}
                />
            </Tooltip>
        </div>
    );
};
