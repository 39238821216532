import React from 'react';
import { useOverflowMenu } from '@fluentui/react-components';

import { useStrings } from '../../../hooks/useStrings';
import { KqlResult } from '../../../kqlParser/kqlParser';
import { CodeIcon, getSystemPillIcon, TableIcon } from '../../../kqlParser/pillToIcons';
import { pillToKql } from '../../../kqlParser/pillToKql';
import { getPipeDescription } from '../../../kqlParser/pillToText';
import { Pill } from '../../../types/Pills.interfaces';
import { TagPill } from './TagPill';

/** Statements - from the parsed KQL */
export const StatementsPill: React.FunctionComponent<{ statements: KqlResult['prefixStatements'] }> = ({
    statements,
}) => {
    const { strings } = useStrings();
    const systemPillsStr = strings.dataExplorationModal.pillsBar.systemPills;

    if (!statements.length) {
        return null; // hide pill if no statements
    }

    return (
        <TagPill
            text={`${systemPillsStr.statementsPillText} (${statements.length})`}
            kqlDescription={systemPillsStr.statementsPillDescription}
            kqlText={statements.join('\n')}
            icon={CodeIcon}
            overflow={{ id: 'statements-pill', priority: 999 }}
            bold
        />
    );
};

/** Source - from the parsed KQL */
export const SourcePill: React.FunctionComponent<{ source: KqlResult['source'] }> = ({ source }) => {
    const { strings } = useStrings();
    const systemPillsStr = strings.dataExplorationModal.pillsBar.systemPills;

    return (
        <TagPill
            text={source}
            kqlDescription={systemPillsStr.sourcePillDescription}
            kqlText={source}
            icon={TableIcon}
            overflow={{ id: 'source-pill', priority: 1000 }}
            bold
        />
    );
};

/** Previous - System, and Users pills that grouped under single pill due to overflow  from the parsed KQL */
export const PreviousPill: React.FunctionComponent<{ pipes: KqlResult['pipes']; pills: Pill[] }> = ({
    pipes,
    pills,
}) => {
    const { strings } = useStrings();
    const systemPillsStr = strings.dataExplorationModal.pillsBar.systemPills;

    const { overflowCount, isOverflowing } = useOverflowMenu<HTMLButtonElement>();

    if (!isOverflowing) {
        return null; // hide pill if no overflowing items
    }

    const previousPipes = [...pipes, ...pills.map((p) => pillToKql(p))]
        .slice(0, overflowCount)
        .map((p) => `| ${p}`)
        .join('\n');

    return (
        <TagPill
            text={`${systemPillsStr.previousPillText} (${overflowCount})`}
            kqlDescription={systemPillsStr.previousPillDescription}
            kqlText={previousPipes}
            icon={CodeIcon}
            overflow={{ id: 'previous-pill', priority: 1001 }}
            bold
        />
    );
};

/** PipeExpressions - from the parsed KQL */
export const SystemPills: React.FunctionComponent<{ pipes: KqlResult['pipes']; readonly?: boolean }> = ({
    pipes,
    readonly,
}) => {
    const { strings } = useStrings();

    if (!pipes.length) {
        return null; // hide pills if no pipe-expressions
    }

    return (
        <>
            {pipes.map((pipe, i) => (
                <TagPill
                    text={pipe}
                    kqlDescription={getPipeDescription(pipe, strings)}
                    kqlText={pipe}
                    icon={getSystemPillIcon(pipe)}
                    readonly={i !== pipes.length - 1 || readonly}
                    overflow={{ id: `system-pill-${i}` }}
                    key={i}
                />
            ))}
        </>
    );
};
