import * as React from 'react';
export function useInteractionTagContextValues_unstable(state) {
    const { appearance, disabled, handleTagDismiss, interactionTagPrimaryId, shape, size, value } = state;
    return {
        interactionTag: React.useMemo(()=>({
                appearance,
                disabled,
                handleTagDismiss,
                interactionTagPrimaryId,
                shape,
                size,
                value
            }), [
            appearance,
            disabled,
            handleTagDismiss,
            interactionTagPrimaryId,
            shape,
            size,
            value
        ])
    };
}
