import { useMemo } from 'react';

import { JPathArrayRepresentation } from './types';
import { buildJsonPathForEachLine } from './utils';

export function useJPath(jsonStr: string, line: number): JPathArrayRepresentation {
    const lineToJsonPathMap = useMemo(() => buildJsonPathForEachLine(jsonStr), [jsonStr]);

    return lineToJsonPathMap && line >= 0 && lineToJsonPathMap[line] ? lineToJsonPathMap[line] : [];
}
