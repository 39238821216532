import * as React from 'react';
import {
    ActionButton,
    Announced,
    CommandBar,
    CommandBarButton,
    DirectionalHint,
    IButton,
    IButtonStyles,
    IContextualMenuItem,
    IOverflowSetItemProps,
    IPivotItemProps,
    IRefObject,
    ITheme,
    OverflowSet,
    Pivot,
    PivotItem,
    Stack,
    StackItem,
    TooltipHost,
} from '@fluentui/react';
import range from 'lodash/range';
import { observer } from 'mobx-react-lite';
import { isMacOs } from 'react-device-detect';
import ReactResizeDetector from 'react-resize-detector';

import { ActionButtonWithTooltip, formatLiterals, formatToSeconds, TimeZoneLabel } from '@kusto/utils';

import {
    ConfigurableBarItemsFunc,
    configureSortAndFilterBarItems,
    OptionalConfigurableBarItem,
} from '../../common/barConfig';
import { useQueryCore } from '../../core/core';
import { QueryStoreEnv } from '../../stores';
import { Tab } from '../../stores/tab';
import { CopyTextActionButton } from '../CopyTextActionButton';
import { TimerActionButton, TimerActionButtonProps } from '../TimerActionButton';
import { ExpandSelectionMenu } from './ExpandViewMenu';

import * as styles from './QueryResultsBar.module.scss';

interface JPathMenuProps {
    showLabel?: boolean;
    isMenuItem?: boolean;
}
export interface JPathCommandBarProps {
    component: React.FC<JPathMenuProps>;
    id: string;
    props?: JPathMenuProps;
}

export enum QueryResultBarFarItemNames {
    SEARCH,
    TIMEZONE,
    STATUS,
    ROW_COUNT,
    HIDE_EMPTY_COLUMNS,
    COPY,
    EXPAND_SELECTION,
}
export type QueryResultBarItemProps = OptionalConfigurableBarItem;
//  {
//       name: string;
//       key: string;
//       onRender: () => JSX.Element;
//       moveToOverflow?: boolean;
//       order?: number;
//   }
// | undefined
// | null;

interface QueryResultBarConfigItemsProps {
    tab: Tab;
    theme: ITheme;
    haveResult: boolean;
    isCompact: boolean;
}

export interface QueryResultBarConfig {
    getFarItems?: ConfigurableBarItemsFunc<
        (typeof QueryResultBarFarItemNames)[keyof typeof QueryResultBarFarItemNames],
        QueryResultBarConfigItemsProps
    >;
    getCommandBarWidth?: (isChartDisplayed: boolean) => number;
    getItems?: ConfigurableBarItemsFunc<'resultTablesItems', QueryResultBarConfigItemsProps>;
}

export interface QueryResultBarProps {
    onSearchClicked?: () => void;
    onLinkClick: (itemKey?: string) => void;
    theme: ITheme;
    collapseIconClassName: string;
    searchButtonRef: React.RefObject<IButton>;
}

export const ADD_VISUAL_ITEM_KEY = 'AddVisual';

export const QueryResultsBar: React.FunctionComponent<QueryResultBarProps> = observer(function QueryResultsBar({
    onSearchClicked,
    onLinkClick,
    theme,
    searchButtonRef,
    collapseIconClassName,
}) {
    const [isCompactMode, setIsCompactMode] = React.useState(false);
    const pivotContainer = React.useRef<HTMLDivElement>(null);
    const [pivotWidth, setPivotWidth] = React.useState<number | null>(null);
    const core = useQueryCore();
    const rootStore = core.store;
    const getItems = core.config.useQueryResultBar?.().getItems;
    const tab = rootStore.tabs.tabInContext;
    const results = tab.results;
    const completionInfo = tab.completionInfo;
    const haveResult =
        !tab.isFetching && tab.runRequest === 'No' && tab.completionInfo && results && results.resultsToDisplay;
    const selectedTab = completionInfo?.resultIndex?.toString();

    const pivotItems = usePivotItems(theme);

    const setIsMonacoEditorCollapsed = rootStore.layout.setIsMonacoEditorCollapsed;
    const isMonacoEditorCollapsed = rootStore.layout.isMonacoEditorCollapsed;

    const getFarItems = React.useCallback(
        (isCompact: boolean) => {
            const overflowSet = {
                key: 'overflowSetItem',
                name: 'overflowSetItem',
                onRender: () => (
                    <FarItemsOverflowSet
                        isCompactMode={isCompact}
                        searchButtonRef={searchButtonRef}
                        onSearchClicked={onSearchClicked}
                        theme={theme}
                    />
                ),
            };
            const items = [overflowSet];
            const editorCollapseButton = getEditorCollapseButton(
                core,
                collapseIconClassName,
                setIsMonacoEditorCollapsed,
                isMonacoEditorCollapsed
            );
            if (editorCollapseButton) {
                items.push(editorCollapseButton);
            }
            return items;
        },
        [
            core,
            collapseIconClassName,
            setIsMonacoEditorCollapsed,
            isMonacoEditorCollapsed,
            searchButtonRef,
            onSearchClicked,
            theme,
        ]
    );

    const farItems = React.useMemo(() => {
        return getFarItems(false);
    }, [getFarItems]);

    const farItemsReduced = React.useMemo(() => {
        return getFarItems(true);
    }, [getFarItems]);

    const items = React.useMemo(() => {
        const resultTablesItems: QueryResultBarItemProps = {
            key: 'resultTable',
            order: 20,
            onRender: () => (
                <PivotElement
                    key="resultTable"
                    selectedTab={selectedTab}
                    pivotItems={pivotItems}
                    onLinkClick={onLinkClick}
                    isCompactMode={isCompactMode}
                />
            ),
        };
        const items = configureSortAndFilterBarItems(
            getItems,
            { tab, theme, haveResult: !!haveResult, isCompact: isCompactMode },
            { resultTablesItems }
        );

        return <>{items.map((item) => item?.onRender())}</>;
    }, [getItems, haveResult, isCompactMode, onLinkClick, pivotItems, selectedTab, tab, theme]);

    const onResize = (width: number) => {
        // This is an approximate width of the width of the command bar items.
        const COMMAND_BAR_DEFAULT_WIDTH = 550;
        const isChartDisplayed = tab.isChartDisplayed || false;
        const commandBarWidth =
            core.config.useQueryResultBar?.().getCommandBarWidth?.(isChartDisplayed) || COMMAND_BAR_DEFAULT_WIDTH;
        const pivotContainerWidth = pivotWidth ?? pivotContainer.current?.clientWidth;
        if (!pivotContainerWidth) {
            return;
        }
        if (pivotWidth === null) {
            setPivotWidth(pivotContainerWidth);
        }
        if (pivotContainerWidth + commandBarWidth >= width) {
            setIsCompactMode(true);
        } else {
            setIsCompactMode(false);
        }
    };
    // The className="actionCommandBar" is used for the explorer Education tour
    return (
        <Stack horizontal={true}>
            <ReactResizeDetector handleWidth={true} onResize={onResize} />
            <div ref={pivotContainer}>{items}</div>
            <StackItem grow={true} className="actionCommandBar">
                <CommandBar
                    items={[]}
                    farItems={isCompactMode ? farItemsReduced : farItems}
                    styles={{
                        root: {
                            paddingLeft: 0,
                            paddingRight: 0,
                            alignItems: 'center',
                        },
                    }}
                />
            </StackItem>
        </Stack>
    );
});

interface PivotElementProps {
    selectedTab?: string;
    pivotItems: IPivotItemProps[];
    onLinkClick: (itemKey?: string) => void;
    isCompactMode?: boolean;
}

const PivotElement = ({ selectedTab, pivotItems, onLinkClick, isCompactMode }: PivotElementProps) => {
    const onLinkClickInner = React.useCallback((itemKey) => onLinkClick(itemKey), [onLinkClick]);

    const pivotComponent = React.useMemo(() => {
        if (isCompactMode) {
            const pivotItemsAsContextMenu = convertPivotItemsToContextMenuItem(pivotItems, onLinkClickInner);
            return renderContextualMenu(pivotItemsAsContextMenu, selectedTab);
        }
        return renderPivot(pivotItems, onLinkClickInner, selectedTab);
    }, [pivotItems, onLinkClickInner, selectedTab, isCompactMode]);

    return pivotComponent;
};

const renderContextualMenu = (items: IContextualMenuItem[], selectedKey?: string) => {
    const selectedItem = items.find((item) => item.key === selectedKey) ?? items[0];
    const actionButtonText = selectedItem?.text;
    const iconName = selectedItem?.iconProps?.iconName;
    return (
        <ActionButton
            text={actionButtonText}
            iconProps={{
                iconName: iconName,
            }}
            menuProps={{
                items: items,
            }}
        />
    );
};

const renderPivot = (pivotItems: IPivotItemProps[], onLinkClick: (itemKey?: string) => void, selectedKey?: string) => {
    return (
        <Pivot
            headersOnly={true}
            selectedKey={selectedKey}
            onLinkClick={(item?: PivotItem) => onLinkClick(item?.props.itemKey)}
            linkFormat="links"
        >
            {pivotItems.map((pivotProps, i) => (
                <PivotItem key={`resultTab${i}`} {...pivotProps} />
            ))}
        </Pivot>
    );
};

const convertPivotItemsToContextMenuItem = (pivotItems: IPivotItemProps[], onLinkClick: (itemKey?: string) => void) => {
    const itemsAsContextMenuItems: IContextualMenuItem[] = pivotItems.map((item) => ({
        key: item.itemKey!,
        text: item.headerText,
        iconProps: {
            iconName: item.itemIcon,
        },
        onClick: () => onLinkClick(item.itemKey),
    }));
    return itemsAsContextMenuItems;
};

const usePivotItems = (theme: ITheme) => {
    const core = useQueryCore();
    const tabInContext = core.store.tabs.tabInContext;
    const showStats = !!tabInContext.completionInfo?.queryResourceConsumption;
    const haveResult =
        !tabInContext.isFetching &&
        tabInContext.runRequest === 'No' &&
        tabInContext.completionInfo &&
        tabInContext.results?.resultsToDisplay;

    return React.useMemo(() => {
        const resultsToDisplay = tabInContext.results?.resultsToDisplay;
        let navigationActions: (IPivotItemProps & React.Attributes)[] = [];

        if (haveResult && resultsToDisplay) {
            navigationActions = range(0, resultsToDisplay.length).map((i) => {
                const isChart = resultsToDisplay[i].isChart;

                // If tableName is not provided as part of the response, just call it 'Table i'
                const tableName =
                    resultsToDisplay[i].tableName && resultsToDisplay[i].tableName !== 'PrimaryResult'
                        ? resultsToDisplay[i].tableName!
                        : formatLiterals(core.strings.query.tableResultNoName, {
                              tableNumber: (resultsToDisplay[i].queryIndex + 1).toLocaleString(),
                          });

                let headerText;
                if (core.featureFlags.QueryVisualOptions) {
                    if (isChart) {
                        headerText = tabInContext.results?.visualAdded
                            ? core.strings.query.editVisual
                            : core.strings.query.visual;
                    } else {
                        headerText = tableName;
                    }
                } else {
                    headerText = isChart ? core.strings.query.graph : tableName;
                }

                return {
                    headerText,
                    itemIcon: isChart ? 'BarChartVertical' : 'Table',
                    key: i,
                    itemKey: i.toString(),
                };
            });

            if (
                core.featureFlags.QueryVisualOptions &&
                core.featureFlags.AddVisual &&
                !resultsToDisplay.find((r) => r.isChart)
            ) {
                navigationActions.push({
                    headerText: core.strings.query.addVisual,
                    itemIcon: 'Add',
                    onRenderItemLink: (props, defaultRenderer) =>
                        resultsToDisplay.length === 1 ? (
                            defaultRenderer?.(props) ?? null
                        ) : (
                            <TooltipHost content={core.strings.query.addVisualDisabled}>
                                {defaultRenderer?.(props) ?? null}
                            </TooltipHost>
                        ),
                    headerButtonProps: {
                        style:
                            resultsToDisplay.length === 1
                                ? undefined
                                : { backgroundColor: 'transparent', color: theme.semanticColors.disabledText },
                    },
                    key: 1,
                    itemKey: ADD_VISUAL_ITEM_KEY,
                });
            }

            if (showStats) {
                navigationActions.push({
                    headerText: core.strings.query.stats,
                    itemIcon: 'DonutChart',
                    key: navigationActions.length,
                    itemKey: navigationActions.length.toString(),
                });
            }
        }

        return navigationActions;
    }, [
        core.featureFlags.AddVisual,
        core.featureFlags.QueryVisualOptions,
        core.strings.query.addVisual,
        core.strings.query.editVisual,
        core.strings.query.graph,
        core.strings.query.stats,
        core.strings.query.tableResultNoName,
        core.strings.query.visual,
        core.strings.query.addVisualDisabled,
        haveResult,
        showStats,
        tabInContext.results?.resultsToDisplay,
        tabInContext.results?.visualAdded,
        theme.semanticColors.disabledText,
    ]);
};

const onRenderOverflowButton = (overflowItems: IOverflowSetItemProps[] | undefined): JSX.Element => {
    const buttonStyles: Partial<IButtonStyles> = {
        root: {
            minWidth: 0,
            padding: '0 4px',
            alignSelf: 'stretch',
            height: 'auto',
        },
    };
    return (
        <CommandBarButton
            ariaLabel="More items"
            role="menuitem"
            styles={buttonStyles}
            menuIconProps={{ iconName: 'More' }}
            menuProps={{ items: overflowItems! }}
        />
    );
};

const FarItemsOverflowSet = observer(function FarItemsOverflowSet({
    isCompactMode,
    searchButtonRef,
    onSearchClicked,
    theme,
}: {
    isCompactMode?: boolean;
    onSearchClicked?: () => void;
    searchButtonRef: IRefObject<IButton>;
    theme: ITheme;
}) {
    const core = useQueryCore();
    const getFarItems = core.config.useQueryResultBar?.().getFarItems;
    const tab = core.store.tabs.tabInContext;
    const openTimezoneSetting = () => core.config.actions?.onOpenSetting?.('timezone');
    const searchButton = getSearchButton(core, searchButtonRef, tab, onSearchClicked);
    const hideEmptyColumns = getHideEmptyColumnsButton(core, tab, isCompactMode);
    const timezoneIndicator = getTimezoneIndicator(core, core.store.settings.resolvedTimeZone, openTimezoneSetting);
    const executionStatusIndicator = getExecutionStatusIndicatorElement(core, tab, isCompactMode);
    const rowCountIndicator = getRowCountIndicator(core, theme, tab.resultRowCount, isCompactMode);
    const copyActivityIdButton = getCopyActivityIdButton(core, tab, isCompactMode);

    const results = tab.results;
    const haveResult =
        !tab.isFetching && tab.runRequest === 'No' && tab.completionInfo && results && results.resultsToDisplay;
    const expandSelectionButton = haveResult && tab.isTableShown ? getExpandSelectionButton(isCompactMode) : undefined;

    const enrichItem = (
        basic: QueryResultBarItemProps,
        order: number,
        moveToOverflow = false
    ): QueryResultBarItemProps => (basic ? { ...basic, order, moveToOverflow } : basic);
    const menuItems: { [key in QueryResultBarFarItemNames]: QueryResultBarItemProps } = {
        [QueryResultBarFarItemNames.SEARCH]: enrichItem(searchButton, 1, true),
        [QueryResultBarFarItemNames.TIMEZONE]: enrichItem(timezoneIndicator, 2, true),
        [QueryResultBarFarItemNames.STATUS]: enrichItem(executionStatusIndicator, 3),
        [QueryResultBarFarItemNames.ROW_COUNT]: enrichItem(rowCountIndicator, 4),
        [QueryResultBarFarItemNames.HIDE_EMPTY_COLUMNS]: enrichItem(hideEmptyColumns, 5, true),
        [QueryResultBarFarItemNames.COPY]: enrichItem(copyActivityIdButton, 6, true),
        [QueryResultBarFarItemNames.EXPAND_SELECTION]: enrichItem(expandSelectionButton, 7, true),
    };
    const displayedItems = configureSortAndFilterBarItems(
        getFarItems,
        { tab, theme, haveResult: !!haveResult, isCompact: !!isCompactMode },
        menuItems
    );

    const items = displayedItems.filter((item) => !isCompactMode || item?.moveToOverflow !== true);
    const overflowItems = displayedItems.filter((item) => isCompactMode && item?.moveToOverflow === true);

    return (
        <OverflowSet
            className={styles.overflowSet}
            items={items}
            overflowItems={overflowItems}
            onRenderItem={(item) => item.onRender()}
            onRenderOverflowButton={onRenderOverflowButton}
        />
    );
});

export const getEditorCollapseButton = (
    env: QueryStoreEnv,
    collapseIconClassName: string,
    onClick: (isCollapsed: boolean) => void,
    isMonacoEditorCollapsed: boolean
) => {
    const toolTip = isMonacoEditorCollapsed ? env.strings.query.button$showEditor : env.strings.query.button$hideEditor;
    const ariaText = toolTip;

    return {
        key: 'collapseEditor',
        name: 'collapseEditor',
        onRender: () => (
            <ActionButtonWithTooltip
                key="collapseEditor"
                tooltipProps={{
                    content: toolTip,
                    directionalHint: DirectionalHint.topCenter,
                }}
                ariaDescription={ariaText}
                iconProps={{
                    iconName: isMonacoEditorCollapsed ? 'DoubleChevronDown12' : 'DoubleChevronUp12',
                    className: collapseIconClassName,
                }}
                onClick={() => onClick(!isMonacoEditorCollapsed)}
                role="menuitem"
            />
        ),
    };
};

const getHideEmptyColumnsButton = (env: QueryStoreEnv, tab?: Tab, isCompactMode?: boolean) => {
    if (!tab?.isTableShown) {
        return undefined;
    }
    const checked = !!tab?.hideEmptyColumns;
    const text = checked ? env.strings.query.showEmptyColumnsTooltip : env.strings.query.hideEmptyColumnsTooltip;
    const lightGray = '#A19F9D';

    return {
        key: 'hideEmptyColumnsButton',
        name: 'hideEmptyColumnsButton',
        onRender: () => (
            <TooltipHost content={text} key={'hideEmptyColumnsButton'}>
                <ActionButton
                    ariaDescription={text}
                    iconProps={{ iconName: 'RedEye' }}
                    styles={{ iconChecked: { color: lightGray } }}
                    checked={checked}
                    onClick={() => tab?.setHideEmptyColumns(!checked)}
                    text={isCompactMode ? text : undefined}
                />
            </TooltipHost>
        ),
    };
};

/**
 * Gets the search button. If search is not supported return undefined.
 */
const getSearchButton = (
    env: QueryStoreEnv,
    searchButtonRef: IRefObject<IButton>,
    tab?: Tab,
    onSearchClicked?: () => void
) => {
    if (tab?.isTableShown) {
        const strings = env.strings.query;
        return {
            key: 'SearchButton',
            name: 'SearchButton',
            onRender: () => (
                <ActionButtonWithTooltip
                    componentRef={searchButtonRef}
                    tooltipProps={{
                        content: `${isMacOs ? 'Command' : 'Control'}+Shift+F`,
                        directionalHint: DirectionalHint.topCenter,
                    }}
                    key="SearchButton"
                    iconProps={{ iconName: 'Search' }}
                    onClick={() => {
                        if (onSearchClicked) {
                            onSearchClicked();
                        }
                        tab?.enableSearch(true);
                    }}
                    text={strings.querySearchResults$buttonText}
                />
            ),
        };
    }
    return undefined;
};

export const getTimezoneIndicator = (env: QueryStoreEnv, timezone: string, onClick?: () => void) => {
    if (timezone && env.featureFlags.Timezone) {
        const strings = env.strings.query;
        return {
            key: 'timezoneIndicator',
            name: 'timezoneIndicator',
            onRender: () => (
                <TimeZoneLabel
                    key="timeZoneLabel"
                    openSettingsClicked={onClick}
                    timeZone={timezone}
                    strings={strings}
                />
            ),
        };
    }
    return undefined;
};

/**
 * Create a UI element that shows whether the query execution failed, succeeded, cancelled, retrieved from cache ETC
 * and the time it took to run.
 * When the query is running - show the elapsed time.
 */
const getExecutionStatusIndicatorElement = (env: QueryStoreEnv, tab: Tab, isCompactMode?: boolean) => {
    const fetchStartTime = tab.fetchStartTime;
    let statusProps: TimerActionButtonProps | null = null;
    const getFormatter = (text: string) => (elapsed: number, precision?: number) =>
        `${text} (${formatToSeconds(elapsed, precision)}) s`;
    const strings = env.strings.query;

    if (tab.isFetching) {
        const executionStatus = env.strings.query.executionStatusRunning;
        statusProps = {
            formatText: getFormatter(executionStatus),
            start: fetchStartTime,
        };
    } else if (tab.runRequest !== 'No') {
        statusProps = {
            formatText: () => strings.executionStatusPreparing,
            start: fetchStartTime,
        };
    } else {
        if (!tab.completionInfo) {
            return;
        }
        switch (tab.executionStatus) {
            case 'done':
                statusProps = {
                    text: formatLiterals(strings.executionStatusDone, {
                        executionTimeInSeconds: formatToSeconds(tab.completionInfo!.executionTimeInMilliseconds),
                    }),
                    iconProps: {
                        iconName: 'SkypeCircleCheck',
                        styles: {
                            root: {
                                color: 'green',
                            },
                        },
                    },
                };
                break;
            case 'canceled':
                statusProps = {
                    text: formatLiterals(strings.executionStatusCancelled, {
                        executionTimeInSeconds: formatToSeconds(tab.fetchEndtime - tab.fetchStartTime),
                    }),
                    iconProps: {
                        iconName: 'Cancel',
                    },
                };
                break;
            case 'failed':
                statusProps = {
                    text: formatLiterals(strings.executionStatusError, {
                        executionTimeInSeconds: formatToSeconds(tab.completionInfo!.executionTimeInMilliseconds),
                    }),
                    iconProps: {
                        iconName: 'StatusErrorFull',
                        styles: {
                            root: {
                                color: 'red',
                            },
                        },
                    },
                };
                break;
            case 'gotFromCache':
                statusProps = {
                    text: formatLiterals(strings.executionStatusCached, {
                        executionTimeInSeconds: formatToSeconds(tab.completionInfo!.executionTimeInMilliseconds),
                    }),
                    iconProps: {
                        iconName: 'Dictionary',
                    },
                };
                break;
            default:
                break;
        }
    }
    if (statusProps && isCompactMode) {
        statusProps.iconOnly = true;
    }
    const id = tab.id;
    return statusProps
        ? {
              key: 'executionStatus',
              name: 'executionStatus',
              onRender: () => (
                  <>
                      <Announced message={statusProps?.text} />
                      <TimerActionButton key={'executionStatus' + id} ariaLabel={strings.query} {...statusProps} />
                  </>
              ),
          }
        : null;
};

/**
 * Create a UI element that shows the number of records retrieved in current result tab.
 */
const getRowCountIndicator = (env: QueryStoreEnv, theme: ITheme, rowCount?: number, compactMode?: boolean) => {
    const iconProps = compactMode ? {} : { iconName: 'NumberField' };
    const fullText = rowCount
        ? formatLiterals(env.strings.query.resultsCount, {
              resultRowCount: rowCount.toLocaleString(),
          })
        : undefined;
    const text = compactMode ? `${rowCount}` : fullText;
    const styles: IButtonStyles | undefined = compactMode
        ? {
              textContainer: {
                  border: `1px ${theme.palette.themePrimary} solid`,
                  color: theme.palette.themePrimary,
              },
          }
        : undefined;

    const rowCountIndicator: QueryResultBarItemProps = rowCount
        ? {
              key: 'rowCount',
              onRender: () => (
                  // Use TimerActionButton is order to support accessibility status
                  <TimerActionButton
                      key="rowcount"
                      text={text}
                      iconProps={iconProps}
                      tooltip={fullText}
                      styles={styles}
                  />
              ),
          }
        : null;

    return rowCountIndicator;
};

/**
 * Create a UI element that allows a user to copy the client activity ID.
 */
const getCopyActivityIdButton = (env: QueryStoreEnv, tab: Tab, isCompactMode?: boolean) => {
    const completionInfo = tab.completionInfo;
    const activityId = tab.isFetching ? tab.clientRequestId : completionInfo ? completionInfo.clientActivityId : null;
    const strings = env.strings.query;
    // Tooltip will show 'Copy request to clipboard" and will change to 'Copied' button clicked.
    // The tooltip will revert to show the message 5 seconds after the copy activity took place.
    const copyActivityIdButton: QueryResultBarItemProps = activityId
        ? {
              key: 'copyActivityId',
              onRender: () => (
                  <CopyTextActionButton
                      key="copyActivityId"
                      messageBeforeCopy={strings.copyActivityId}
                      messageAfterCopy={strings.copyActivityIdDone}
                      textToCopy={activityId}
                      label={isCompactMode ? strings.copyActivityIdLabel : undefined}
                  />
              ),
          }
        : null;

    return copyActivityIdButton;
};

const getExpandSelectionButton = (isCompactMode?: boolean) => {
    return {
        key: 'ExpandSelectionButton',
        name: 'ExpandSelectionButton',
        onRender: () => <ExpandSelectionMenu showLabel={isCompactMode} isMenuItem={isCompactMode} />,
    };
};
