import React from 'react';
import { Spinner } from '@fluentui/react-components';

import { createFlagsObj } from '@kusto/app-common';
import { LOADING, ok, Theme } from '@kusto/utils';
import { KweVisual, VisualFwkFeatureFlag } from '@kusto/visual-fwk';

import { useDataExplorationContext } from '../../..';
import { useQueryResults } from '../../hooks/useResults';
import { useDataExplorationStore } from '../../store/DataExplorationStore';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { VisualTypeMenu } from './VisualTypeMenu';

import styles from './Chart.module.scss';

const visualFwkFeatureFlags: readonly VisualFwkFeatureFlag[] = ['EnableInteractiveLegend'];
const featureFlags = createFlagsObj<VisualFwkFeatureFlag>((flag) => visualFwkFeatureFlags.includes(flag));

export const Chart: React.FunctionComponent = () => {
    const { theme, t, parsedVisuals } = useDataExplorationContext();
    const {
        state: {
            visual: { type: visualType, options: visualOptions },
        },
    } = useDataExplorationStore();

    const { data } = useQueryResults();

    const loadingStateMarkup = (
        <div className={styles.loader}>
            <Spinner label={t.dataExploration.dataExplorationModal.chart.loadingVisual} labelPosition="below" />
        </div>
    );

    // Memo used so `ok()` doesn't cause an unstable reference
    // TODO: Include errors
    const queryResult = React.useMemo(() => {
        return data
            ? ok({
                  dataFrame: data.dataFrame,
                  sorted: data.visualizationOptions?.IsQuerySorted,
              })
            : LOADING;
    }, [data]);

    return (
        <div className={styles.chartArea}>
            <VisualTypeMenu />
            <KweVisual
                visualType={visualType}
                options={visualOptions}
                queryResult={queryResult}
                timeZone="UTC"
                isDarkTheme={theme === Theme.Dark}
                // TODO: Pass app flags?
                featureFlags={featureFlags}
                visuals={parsedVisuals}
                t={t}
                // TODO: Consider using built-in loading spinner
                renderLoading={() => loadingStateMarkup}
                addMessage={() => () => {}}
                onLinkClick={() => {}}
                renderErrorBoundary={(e) => <ErrorBoundary>{e}</ErrorBoundary>}
            />
        </div>
    );
};
