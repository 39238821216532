/**
 * Parses Floating UI placement and returns the different components
 * @param placement - the floating ui placement (i.e. bottom-start)
 *
 * @returns side and alignment components of the placement
 */ export function parseFloatingUIPlacement(placement) {
    const tokens = placement.split('-');
    return {
        side: tokens[0],
        alignment: tokens[1]
    };
}
