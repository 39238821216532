// Because we're importing the ".min.js" version of Kusto.Language.Bridge we need to include the types separately
/// <reference types="@kusto/language-service-next" />
import '@kusto/language-service-next/Kusto.Language.Bridge.min.js';

export interface KqlResult {
    /** Data that appear before the source, for example: `let` definitions */
    prefixStatements: string[];
    /** Data source for querying (usually a table name) */
    source: string;
    /** List of pipe expressions, for example: `take 10` or `project column1, column2` */
    pipes: string[];
}

class KqlParserError extends Error {
    constructor(message: string) {
        super(message);
        this.message = message;
        this.name = 'KQL Parser Error';
    }
}

/**
 * Extract KQL code from an expression.
 * An element can be: `pipe`, `pipe.Operator`, `pipe.Bar` or `pipe.Expression`.
 * Any expression have `.toString()` method that returns the raw kql-code, and `DebugText` that returns the clean code (without spaces, new-lines and comments).
 * However, the `DebugText` is private and not accessible, so we are using the `TextStart` and `End` properties to extract the kql-code.
 *
 * @param kqlText KQL query text
 * @param element object with `TextStart` and `End` properties
 */
function extractKql(kqlText: string, element: Kusto.Language.Syntax.SyntaxElement): string {
    try {
        return kqlText.slice(element.TextStart, element.End);
    } catch (error) {
        throw new KqlParserError('Error extracting KQL from element');
    }
}

/**
 * Check if a pipe is supported by DataExpo.
 * @param pipeText pipe text to check
 */
function supportedPipe(pipeText: string) {
    return !pipeText.startsWith('render'); // filter out 'render' operators
}

export function parseKql(kqlText: string): KqlResult {
    const result: KqlResult = {
        prefixStatements: [],
        source: '',
        pipes: [],
    };

    kqlText = kqlText.trim();

    const kustoCode = Kusto.Language.KustoCode.ParseAndAnalyze(kqlText);
    if (!kustoCode) {
        throw new KqlParserError('Error Parsing KQL - validate the query syntax');
    }

    const tree: Kusto.Language.Syntax.SyntaxTree =
        // @ts-expect-error - Wrong Type, but is there in run-time
        kustoCode.Tree;

    const root = tree.Root;
    if (!root) {
        throw new KqlParserError('Error "Root" not found on Tree');
    }

    const statements: Kusto.Language.Syntax.SyntaxList$1<
        Kusto.Language.Syntax.SeparatedElement$1<Kusto.Language.Syntax.Statement>
    > | null =
        // @ts-expect-error - Wrong Type, but the data there in run-time
        root.Statements;

    if (!statements) {
        throw new KqlParserError('Error "Statements" not found on Root');
    }

    let expression: Kusto.Language.Syntax.SyntaxElement | undefined;

    const statementLength = statements.Count;
    for (let i = 0; i < statementLength; i++) {
        const statement = statements.getItem(i);

        if (i === statementLength - 1) {
            expression =
                // @ts-expect-error - Wrong Type, but the data there in run-time
                statement.Element?.Expression;
        } else {
            result.prefixStatements.push(extractKql(kqlText, statement));
        }
    }

    if (!expression) {
        throw new KqlParserError('Error "Expression" not found on Statements');
    }

    while (expression) {
        const operator: Kusto.Language.Syntax.SyntaxElement | undefined =
            // @ts-expect-error - Wrong Type, but the data there in run-time
            expression.Operator;

        if (operator) {
            const pipeText = extractKql(kqlText, operator);
            if (supportedPipe(pipeText)) {
                result.pipes.push(pipeText);
            }
        } else {
            result.source = extractKql(kqlText, expression);
            break;
        }

        expression =
            // @ts-expect-error - Wrong Type, but the data there in run-time
            expression.Expression;
    }

    result.pipes = result.pipes.reverse(); // push() and reverse() is faster than using unshift()

    return result;
}

/**
 * Create a KQL query from a parse result
 * @param parseResult source and list of pipe expressions
 * @returns KQL query string
 */
export function toKQL(parseResult: KqlResult): string {
    const { prefixStatements, source, pipes } = parseResult;
    return [...prefixStatements, source, ...pipes.map((p) => `| ${p}`)].join('\n');
}

/**
 * Extract the operator from a pipe expression.
 * @param pipe pipe expression
 * @returns operator, like: `project`, `extend`, `where`, etc.
 */
export function pipeOperator(pipe: string): string {
    return pipe.split(' ')[0];
}
