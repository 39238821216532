import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Accessibility from 'highcharts/modules/accessibility';
import Boost from 'highcharts/modules/boost';
import Funnel from 'highcharts/modules/funnel';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import debounce from 'lodash/debounce';

import { RenderHelperHandle, RenderHelperRenderer } from '@kusto/ui-components';
import { formatLiterals, KweException, Locale } from '@kusto/utils';

import { ChartMessageRendererAction } from '../components/Chart';
import { InternalChartProps } from '../types';
import { addPointContextMenuHandler } from './addPointContextMenuHandler';

import * as styles from './styles.module.scss';

const MAX_DATA_POINTS = 50_000;
const MAX_SERIES_COUNT = 100;

Boost(Highcharts);
Accessibility(Highcharts);
Funnel(Highcharts);
addNegativeLogarithmicSupport(Highcharts);
HighchartsHeatmap(Highcharts);

/**
 * Fixes accessibility issue with area chart - legend does not have shapes
 * Wrapping in a try/catch to prevent accessing undefined values (risk of casting to "any")
 */
try {
    // Added while enabling lints
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (Highcharts as any).seriesTypes.area.prototype.drawLegendSymbol = // Added while enabling lints
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (Highcharts as any).seriesTypes.line.prototype.drawLegendSymbol;
} catch (e) {}

let lastAppliedLocale: undefined | string;
export function setHighchartsLocale(locale: Locale) {
    if (lastAppliedLocale === locale) {
        return;
    }

    const parts = new Intl.NumberFormat(locale).formatToParts(1000.1);
    const decimalPoint = parts.find((p) => p.type === 'decimal')?.value;
    const thousandsSep = parts.find((p) => p.type === 'group')?.value;
    if (decimalPoint && thousandsSep) {
        /**
         * Have to set the "lang" property this way because
         * it's not reflected via the options prop
         * @see https://github.com/highcharts/highcharts-react/issues/316
         * @see https://github.com/highcharts/highcharts-react/issues/370
         */
        Highcharts.setOptions({ lang: { decimalPoint, thousandsSep } });
        lastAppliedLocale = locale;
    }
}

let lastAppliedNumericSymbols: undefined | string[];
function setHighchartsNumericSymbols(numericSymbols: undefined | string[]) {
    if (lastAppliedNumericSymbols === numericSymbols) {
        return;
    }

    /**
     * Have to set the "lang" property this way because
     * it's not reflected via the options prop
     * @see https://github.com/highcharts/highcharts-react/issues/316
     * @see https://github.com/highcharts/highcharts-react/issues/370
     */
    Highcharts.setOptions({
        lang: { numericSymbols },
    });

    lastAppliedNumericSymbols = numericSymbols;
}

/**
 * When the axis min-value is set to zero
 * or a negative number then Highcharts throws
 * an error. This fn addresses that issue by
 * supporting negative log numbers which avoids
 * any broken UX.
 *
 * @see https://assets.highcharts.com/errors/10/
 * @see https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/yaxis/type-log-negative/
 */
function addNegativeLogarithmicSupport(h: typeof Highcharts) {
    h.addEvent(h.Axis, 'afterInit', function addNegativeLogarithmicSupport() {
        /**
         * Marking `this` as "any" here because h.Axis
         * isn't properly exposing all of the public
         * properties that exist on `this`
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const self = this as any;
        const logarithmic = self.logarithmic;

        // Only add support for negative numbers on a log axis
        if (logarithmic) {
            self.positiveValuesOnly = false;

            logarithmic.log2lin = (num: number) => {
                const isNegative = num < 0;

                let adjustedNum = Math.abs(num);

                if (adjustedNum < 10) {
                    adjustedNum += (10 - adjustedNum) / 10;
                }

                const result = Math.log(adjustedNum) / Math.LN10;
                return isNegative ? -result : result;
            };

            logarithmic.lin2log = (num: number) => {
                const isNegative = num < 0;

                let result = Math.pow(10, Math.abs(num));
                if (result < 10) {
                    result = (10 * (result - 1)) / (10 - 1);
                }
                return isNegative ? -result : result;
            };
        }
    });
}

/**
 * A component that shows a highchart given ChartProps.
 */
const HighchartInner = React.forwardRef((props: HighchartProps, parentRef) => {
    const ref = React.useRef<HighchartsReact.RefObject | null>(null);
    const renderHelper = React.useRef<RenderHelperHandle>(null);

    const onRef = React.useCallback<(handle: null | HighchartsReact.RefObject) => void>(
        (handle: null | HighchartsReact.RefObject) => {
            ref.current = handle;
            if (typeof parentRef === 'function') {
                parentRef(handle);
            } else if (parentRef) {
                parentRef.current = handle;
            }
        },
        [parentRef]
    );

    React.useLayoutEffect(() => {
        if (props.locale) {
            setHighchartsLocale(props.locale);
        }

        if (props.options.lang?.numericSymbols) {
            setHighchartsNumericSymbols(props.options.lang.numericSymbols);
        }
    }, [props.locale, props.options.lang]);

    React.useEffect(() => {
        const chart = ref.current;
        if (!chart) {
            return;
        }

        const abortController = new AbortController();

        const container = chart.container.current;

        if (!container) {
            throw new KweException('Missing highcharts container');
        }

        const reflow = debounce(() => chart.chart.reflow(), 100);
        const observer = new ResizeObserver(reflow);
        observer.observe(container);
        abortController.signal.addEventListener('abort', () => {
            reflow.cancel();
            observer.disconnect();
        });

        if (renderHelper.current) {
            addPointContextMenuHandler(
                abortController.signal,
                renderHelper.current.render,
                chart.chart,
                props.onHighchartsPointMenuItems
            );
        }

        return () => abortController.abort();
    }, [props.onHighchartsPointMenuItems]);

    return (
        <>
            <RenderHelperRenderer ref={renderHelper} />
            <HighchartsReact
                highcharts={Highcharts}
                options={props.options}
                immutable={props.shouldRecreateChartObject}
                containerProps={{ className: styles.highchartsContainer }}
                ref={onRef}
            />
        </>
    );
});

function bypassLimitsActions(
    props: HighchartProps,
    localBypassLimits: (value: boolean) => void
): ChartMessageRendererAction[] {
    const actions: ChartMessageRendererAction[] = [
        {
            key: 'bypass-highcharts-limits',
            text: props.strings.errorActionBypassRenderLimits,
            onClick: () => localBypassLimits(true),
        },
    ];
    const disableChartLimits = props.disableChartLimits;
    if (disableChartLimits) {
        actions.push({
            key: 'disable-highcharts-limits',
            text: props.strings.errorActionDisableLimits,
            onClick: () => disableChartLimits(),
        });
    }
    return actions;
}

export interface HighchartProps
    extends Pick<
        InternalChartProps,
        | 'locale'
        | 'onHighchartsPointMenuItems'
        | 'isDarkTheme'
        | 'ignoreChartLimits'
        | 'dataItemsLength'
        | 'formatMessage'
        | 'strings'
        | 'disableChartLimits'
    > {
    options: Highcharts.Options;
    shouldRecreateChartObject: boolean;
}

export const Highchart = React.forwardRef((props: HighchartProps, parentRef) => {
    const [localBypassLimits, setLocalBypassLimits] = React.useState(false);
    const { options, ignoreChartLimits, dataItemsLength, formatMessage, strings } = props;
    const bypassLimits = ignoreChartLimits || localBypassLimits;
    if (!bypassLimits && dataItemsLength > MAX_DATA_POINTS) {
        return formatMessage({
            level: 'warn',
            message: formatLiterals(strings.errors.tooManyPointsMessage, {
                currentPoints: dataItemsLength.toLocaleString(),
                maximumPoints: MAX_DATA_POINTS.toLocaleString(),
            }),
            title: strings.errors.tooManyPointsTitle,
            options: {
                actions: bypassLimitsActions(props, setLocalBypassLimits),
            },
        });
    }

    // Cannot read series count off of heuristics series columns because if data
    // is "series formatted" (all in one row), there will be no specified series
    // columns in heuristics.
    if (!bypassLimits && options.series && options.series.length > MAX_SERIES_COUNT) {
        return formatMessage({
            level: 'warn',
            message: formatLiterals(props.strings.errors.tooManySeriesTypesMessage, {
                currentSeriesTypes: options.series.length.toLocaleString(),
                maximumSeriesTypes: MAX_SERIES_COUNT.toLocaleString(),
            }),
            title: strings.errors.tooManySeriesTypesTitle,
            options: {
                actions: bypassLimitsActions(props, setLocalBypassLimits),
            },
        });
    }

    return <HighchartInner ref={parentRef} {...props} options={options} />;
});
