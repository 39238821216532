import type { FeatureFlagMapAll } from '../../core';

export const ibizaFeatureFlags: FeatureFlagMapAll = {
    ShowConnectionButtons: false, // diff from kweSiteFeatureFlags
    EnableAddTable: false, // diff from kweSiteFeatureFlags
    EnableGetData: false, // diff from kweSiteFeatureFlags
    EnableDataProfile: false, // diff from kweSiteFeatureFlags
    DataProfilePinned: false, // diff from kweSiteFeatureFlags
    GridWithExpandRewrite: true,
    ShowShareMenu: true,
    ShowFileMenu: true,
    ShowPersona: true,
    IFrameAuth: true, // diff from kweSiteFeatureFlags
    ShowOpenNewWindowButton: true, // diff from kweSiteFeatureFlags
    ShowSmoothIngestion: true,
    ShowPageHeader: false, // diff from kweSiteFeatureFlags
    UseMeControl: true,
    EnableDashboards: false, // diff from kweSiteFeatureFlags
    QueryResultsSearch: true,
    SyncBrowserTabs: false, // diff from kweSiteFeatureFlags
    EnableEventHubSource: true,
    HighlightUrlColumns: false, // diff from kweSiteFeatureFlags
    EnableCreateExternalTable: true,
    Timezone: false, // diff from kweSiteFeatureFlags
    HideDashboardsOnly: false,
    BrowseSource: true,
    UseIFrameSharing: false,
    UseLocalStorageForTokens: true,
    statsv2: true,
    AddHelpClusterOnEmptyState: false, // diff from kweSiteFeatureFlags
    consoleLogs: false,
    verboseLogs: false,
    EnableNPS: false, // diff from kweSiteFeatureFlags
    enableTablePolicy: false, // diff from kweSiteFeatureFlags
    enableManageDatabase: false,
    HideTabs: false,
    EnableQueryParameterLogging: false,
    VirtualCluster: false, // diff from kweSiteFeatureFlags
    // TODO: Cleanup the specific FF once the new VC is fully rolled out
    VirtualClusterUpgrade: false, // diff from kweSiteFeatureFlags
    VirtualClusterEventHub: false,
    VirtualClusterFastProvisioning: false,
    // TODO: Cleanup the specific FF once the new VC is fully rolled out
    AddSuggestNewFeatureLink: false, // diff from kweSiteFeatureFlags
    Homepage: false, // diff from kweSiteFeatureFlags
    queryRecommendations: true,
    cheackClusterHealth: false,
    ShowNavigation: false, // diff from kweSiteFeatureFlags
    WhatIsNew: false, // diff from kweSiteFeatureFlags
    ShowItalyAccessibilityDeclarations: true,
    DisableTelemetryPiiRedactor: true,
    EnableNoLoginVirtualCluster: true,
    EnableMarkdownImageOverflow: false,
    EnableInteractiveLegend: true,
    DisableFeedback: false, // diff from kweSiteFeatureFlags
    FindMyPartner: false, // diff from kweSiteFeatureFlags
    QueryVisualOptions: false, // diff from kweSiteFeatureFlags
    ClusterAccount: false, // diff from kweSiteFeatureFlags
    AddVisual: false, // diff from kweSiteFeatureFlags
    HomepagePromotionBanner: false, // diff from kweSiteFeatureFlags
    SecurePlotlyIframe: false,
    EnableRoamingProfile: false,
    KeepOnlyOpenInExcelOption: false,
    DashboardsControlCommandParameters: false,
    EnableCreateTableWizard: false,
    EnableDashboardsDevMenu: false,
    HideConnectionPane: false,
    NewTabs: true,
    PersistAfterEachRun: true, // diff from kweSiteFeatureFlags
    RefreshConnection: false,
    SkipMonacoFocusOnInit: true, // diff from kweSiteFeatureFlags
    TridnetView: false,
    ducks: false,
    enableLanguageHotReloading: false,
    ibizaPortal: true, // diff from kweSiteFeatureFlags
    ConnectionPaneUsingFluentV9: false,
    EnableConnectionPaneGroups: false,
    GridDebugMode: false, // Enable only with query params
    ppe: false,
    canary: false,
    TenantAdminSettings: false,
    EnableExploreData: false,
    enableSelectedColumnInfo: true,

    /// Dashboards
    DisableDashboardTopBar: false,
    DisableNewDashboard: false,
    DisableDashboardSearch: false,
    DisableDashboardEditMenu: false,
    DisableDashboardFileMenu: false,
    DisableDashboardShareMenu: false,
    DisableDashboardDelete: false,
    DisableTileRefresh: false,
    DisableDashboardAutoRefresh: false,
    DisableExploreQuery: false,
    DisableCrossFiltering: false,
    HideDashboardParametersBar: false,
    debugVisualConfig: false,
    baseQueryCascading: false,
    queryBatching: false,
    exportAsVersion: false,
    newRtdShareMenu: false,
    'tile-menu--copy-as-dev-json': false,
    baseQueriesAsDataSource: false,
    'scope--json': false,
    'scope--csv': false,
    'enable-msa-sharing': false,
    OutageGeoMigrationWarning: false,
    'dashboards--experimental-system-variables': false,

    /// KweVisual
    'cross-filter--series--point': false,
};

// SkipMonacoFocusOnInit
// Due to issues with focus on monaco empty (new) tab in Firefox & chrome from ibiza portal
//
// Monaco set the focus once and follow up clicks (in the editor) are ignored until blur event (keeping the state internally)
// due to some race condition between monaco and Ibiza portal focus handling
// the document active element is not monaco (although editor.focus() was called ).
