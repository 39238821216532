import * as React from 'react';

import { formatLiterals } from '@kusto/utils';

import type { KweVisualFwkLocale } from '../types';

export interface UnsupportedVisualTypeProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
    type: string;
    t: KweVisualFwkLocale;
}

export const UnsupportedVisualType: React.FC<UnsupportedVisualTypeProps> = ({ id, type, t, ...props }) => {
    return (
        <p {...props} id={id} data-testid="unsupported-visual-type">
            {formatLiterals(t.visualFwk.visuals.unknownVisualTypeMessage, { visualType: type })}
        </p>
    );
};
