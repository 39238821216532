import React, { useContext, useEffect } from 'react';
import { getId, ILayerHostProps, ILayerProps, Layer, LayerHost } from '@fluentui/react';
import Pane from 'react-split-pane/lib/Pane';

import { StyledSplitPane } from '@kusto/visualizations';

//
// Context provider to mark the place for display component out of scope
// this context is a wrapper of UI-Fabric Layer & HostLayer components
//
// The provider have 3 parts:
//     - ExternalPanelProvider: the container of external panel.
//          The panel provider add Slipt Pane capabilities
//
//     - ExternalPanelProvider.PlaceHolder: marks the actual place
//          to inject the external panel relative to other content/elements in the container
//           - enable placing the panel right/left/above/below the content
//
//     - ExternalLayer: wrap the content that will be display externally (UI Fabric Layout)
//
interface externalPanelContext {
    id?: string;
    setExternalPanelProps?: (props: { visible: boolean; allowResize?: boolean; width?: string }) => void;
}
export const ExternalPanelContext = React.createContext<externalPanelContext>({});
interface ExternalPanelProps {
    externalPanelId?: string;
    children: [JSX.Element, JSX.Element];
}
export const ExternalPanelProvider: React.StatelessComponent<ExternalPanelProps> & {
    PlaceHolder: React.StatelessComponent<ILayerHostProps>;
} = ({ externalPanelId, children }) => {
    const [enabled, setEnabled] = React.useState(false);
    const [allowResize, setAllowResize] = React.useState(true);
    const [width, setWidth] = React.useState<string | undefined>();

    const elemId = React.useMemo(() => getId(externalPanelId!), [externalPanelId]);
    return (
        <ExternalPanelContext.Provider
            value={{
                id: elemId,
                setExternalPanelProps: ({ visible, allowResize, width }) => {
                    setEnabled(visible);
                    setAllowResize(allowResize ?? false);
                    setWidth(width);
                },
            }}
        >
            <StyledSplitPane allowResize={enabled && allowResize}>
                <Pane minSize="10px">{children[0]}</Pane>
                <Pane size={enabled ? width : '0px'} minSize={enabled ? '10px' : '0px'}>
                    {children[1]}
                </Pane>
            </StyledSplitPane>
        </ExternalPanelContext.Provider>
    );
};
const DEFAULT_EXTERNAL_PANEL_ID = 'grid-external-panel';
ExternalPanelProvider.defaultProps = {
    externalPanelId: DEFAULT_EXTERNAL_PANEL_ID,
};
const useExternalPanelProvider = () => useContext(ExternalPanelContext) || ExternalPanelProvider.defaultProps;
ExternalPanelProvider.PlaceHolder = (props) => {
    // eslint issue ...
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { id } = useExternalPanelProvider();
    return <LayerHost id={id} {...props} style={{ height: '100%', overflow: 'hidden', position: 'relative' }} />;
};
export const ExternalContent = ({
    visible,
    allowResize,
    width,
    ...props
}: ILayerProps & {
    visible: boolean;
    allowResize: boolean;
    width: string;
}) => {
    const { id, setExternalPanelProps } = useExternalPanelProvider();
    useEffect(() => {
        setExternalPanelProps?.({
            visible,
            allowResize,
            width,
        });
    }, [allowResize, setExternalPanelProps, visible, width]);

    useEffect(() => {
        return () => setExternalPanelProps?.({ visible: false });
    }, [setExternalPanelProps]);

    if (!setExternalPanelProps || !id || !visible) {
        return <></>;
    }
    return <Layer hostId={id} {...props} />;
};
