import type { DataFrameSchema } from '@kusto/utils';
import type { TableRenderLinkConfig } from '@kusto/visual-fwk';

import type { RtdProviderLocale } from '../../../i18n';

/**
 * This will always be an error level so handle accordingly (e.g. display in red, etc)
 */
export function getTypeError(
    t: RtdProviderLocale,
    config: TableRenderLinkConfig,
    queryResultSchema: DataFrameSchema
): undefined | string {
    if (config.urlColumn) {
        const urlColumnType = queryResultSchema.find((value) => value.name === config.urlColumn)?.type;

        if (urlColumnType !== undefined && urlColumnType !== 'string') {
            return t.rtdProvider.visuals.table.renderLinks.urlColumnNotStringType;
        }
    }
}
