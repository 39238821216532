import { EntityType } from '../../../common';
import { Cluster, Database, QueryStoreEnv } from '../../../stores';
import { Action, Icon, ITreeEntityMapper } from '../actions';
import { RowDataType } from '../RowDataType';

export abstract class RowDataTypeBase implements RowDataType {
    public id: string;
    public pathFromRoot: string[];
    public label: string;
    public entityType: EntityType;
    public baseData: Cluster | Database;
    public icon: Icon;
    public actions: Action[] = [];
    public details?: string;
    public info?: string;
    public isFavorite?: boolean;
    public treeEntityMapper: ITreeEntityMapper;

    public childrenLoader?: (env: QueryStoreEnv, base: RowDataType) => RowDataType[];

    constructor(
        id: string,
        pathFromRoot: string[],
        label: string,
        entityType: EntityType,
        baseData: Cluster | Database,
        icon: Icon,
        treeEntityMapper: ITreeEntityMapper
    ) {
        this.id = id;
        this.label = label;
        this.pathFromRoot = pathFromRoot;
        this.entityType = entityType;
        this.baseData = baseData;
        this.icon = icon;
        this.treeEntityMapper = treeEntityMapper;
    }
}
