import cloneDeep from 'lodash/cloneDeep';

import { SimpleRegexpCustomRedactorConfig, SyncRedactor } from '../../src/opensource/redact-pii';
import type { ReadonlyRecord } from '../types';
import { castToError } from '../utils/errors';
import { Ok, ok } from '../utils/result';
import {
    getObjectEntries,
    isRedactable,
    isRedactableIterable,
    setValueOnIterable,
    setValueOnObject,
    TBuiltInIterable,
    TMaybeObjectLike,
} from './util';

const UNEXPECTED_FAILURE_REASON = 'Redaction process hit an exception while running.';

function unexpectedFailureError(e: unknown): ErrorRedactedResult {
    return {
        kind: 'err',
        reason: UNEXPECTED_FAILURE_REASON,
        exception: castToError(e),
    };
}

export interface RedactConfig {
    /**
     * Use this to target specific values and replace them
     * with your mask. The key is the target and the value
     * is the replacement/mask.
     */
    readonly knownValuesToRedact?: ReadonlyRecord<string, string>;
}

function createTargetedRedactors(config?: RedactConfig): undefined | SimpleRegexpCustomRedactorConfig[] {
    const redactors: SimpleRegexpCustomRedactorConfig[] = [];

    if (!config || !config.knownValuesToRedact) {
        return;
    }

    const { knownValuesToRedact } = config;

    for (const [target, textReplacement] of Object.entries(knownValuesToRedact)) {
        redactors.push({
            regexpPattern: new RegExp(target, 'g'),
            replaceWith: textReplacement,
        });
    }

    return redactors;
}

/**
 * Cache for seen object references to avoid infinite recursion on
 * circular references
 *
 * See this link why we use a WeakSet:
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/WeakSet#use_case_detecting_circular_references
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SeenObjectRefCache = WeakSet<any>;

export type OkRedactedResult<Data = unknown> = Ok<Data>;
export interface ErrorRedactedResult {
    readonly kind: 'err';
    readonly reason: string;
    readonly exception: Error;
}
export type MaybeRedactedResult<Data = unknown> = OkRedactedResult<Data> | ErrorRedactedResult;

export class PiiRedactor {
    /**
     * @param data The data to be redacted
     */
    public redact<Data = unknown>(data: Data, config?: RedactConfig): MaybeRedactedResult<Data> {
        try {
            // Need to clone the data because there's the edge case
            // that devs pass in an object to telemetry
            // that they also use to display to customers.
            // This means that the displayed data will
            // look like it's been redacted too.
            const clonedData = cloneDeep(data);
            const res = this.deepRedact(clonedData, config);
            return ok(res);
        } catch (e) {
            return unexpectedFailureError(e);
        }
    }

    private redactString(input: string, config?: RedactConfig): string {
        const redactor = new SyncRedactor({
            customRedactors: {
                before: createTargetedRedactors(config),
            },
            builtInRedactors: {
                // These are triggering false-positives because some
                // of them are matching against our properties that
                // contain GUID/UUID (e.g. client-requed-id or sessionId).
                // So turning these off because we wouldn't be storing
                // and/or logging these directly. Plus, we don't directly
                // ask for this information from the user nor actually use it
                // ourselves in the App.
                creditCardNumber: { enabled: false },
                zipcode: { enabled: false },
                phoneNumber: { enabled: false },
                usSocialSecurityNumber: { enabled: false },
                digits: { enabled: false },
            },
        });
        return redactor.redact(input);
    }

    /**
     * Mutates the iterable's elements by
     * directly assigning redacted values.
     * This way we avoid cloning the
     * iterable of an unknown size (that
     * could be very large).
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private redactIterable(input: TBuiltInIterable, config?: RedactConfig, seen?: SeenObjectRefCache): void {
        /**
         * Optimization here to bail out early.
         * It's a code standard (assumption?) that we don't mix types.
         * For example:
         *
         * Array<number | string> = [10, 'hello', 19, 'world']
         *
         * So, let's use that to our advantage and check the
         * first element to see if it's something that
         * is not redactable to exit early.
         */
        const firstElement = input[Symbol.iterator]().next().value;

        if (!isRedactable(firstElement)) {
            /**
             * If the first element is not redactable
             * it's safe to assume the rest of the
             * iterable is also not redactable
             * so we can bail early.
             */
            return;
        }

        let index = 0;
        // `for-of` works on both Set and Array so using that for easy compatibility
        for (const element of input) {
            const redactedElement = this.deepRedact(element, config, seen);
            // Casting because TS can't infer that `input` adheres to TNativeIterable
            setValueOnIterable(input as unknown as TBuiltInIterable, index, element, redactedElement);
            index++;
        }
    }

    private redactObject<Input extends TMaybeObjectLike>(
        input: Input,
        config?: RedactConfig,
        seen?: SeenObjectRefCache
    ): void {
        const entries = getObjectEntries(input);

        for (const [key, value] of entries) {
            const redactedValue = this.deepRedact(value, config, seen);
            setValueOnObject(input, key, redactedValue);
        }
    }

    /**
     * @param seen Used to avoid circular references
     */
    private deepRedact<Input = unknown>(
        input: Input,
        config?: RedactConfig,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        seen: SeenObjectRefCache = new WeakSet<any>()
    ): Input {
        if (!isRedactable(input)) {
            return input;
        }

        if (typeof input === 'string') {
            return this.redactString(input, config) as Input;
        }

        /**
         * We should check if we've already seen this input before
         * diving in further to avoid circular references. Otherwise
         * we could recurse infinitely.
         */
        if (seen.has(input)) {
            return input;
        }

        // By this point, the input is either an ArrayLike or ObjectLike instance
        if (isRedactableIterable(input)) {
            seen.add(input);
            this.redactIterable(input, config, seen);
            return input;
        }

        seen.add(input);
        this.redactObject(input as unknown as TMaybeObjectLike, config, seen);
        return input;
    }
}
