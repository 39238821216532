import { useDataExplorationContext } from '../../context/DataExplorationContext';

export function useStrings() {
    const { t } = useDataExplorationContext();

    return {
        strings: t.dataExploration,
        locale: t.locale,
    };
}
