import './ExpandTopBar.scss';

import React from 'react';
import { useTheme } from '@fluentui/react';
import { useArrowNavigationGroup } from '@fluentui/react-components';
import ReactResizeDetector from 'react-resize-detector';

import { JPathView, JsonModelData } from '@kusto/ag-grid';

import { VisualizationsLocale } from '../types';

export interface ExpandTopBarProps {
    jsonModelData: JsonModelData;
    locale: VisualizationsLocale;
    renderExpandBarItems: (isCompactMode?: boolean) => JSX.Element;
    styles?: React.CSSProperties;
}

const MAX_COMPACT_WIDTH = 400;

export const ExpandTopBar: React.FC<ExpandTopBarProps> = ({ jsonModelData, locale, renderExpandBarItems, styles }) => {
    const theme = useTheme();
    const [isCompactMode, setIsCompactMode] = React.useState(false);

    const onResize = (width: number) => {
        setIsCompactMode(MAX_COMPACT_WIDTH >= width);
    };

    const attributes = useArrowNavigationGroup({ axis: 'horizontal' });
    return (
        <div
            role="toolbar"
            {...attributes}
            className="expand-bar__container"
            style={{ backgroundColor: theme.palette.white, ...styles }}
        >
            <ReactResizeDetector handleWidth={true} onResize={onResize} />
            <div className="expand-bar__body">
                <JPathView {...jsonModelData} locale={locale}>
                    {renderExpandBarItems(isCompactMode)}
                </JPathView>
            </div>
        </div>
    );
};
