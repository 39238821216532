import * as React from 'react';
import { IDropdownOption } from '@fluentui/react';
import classNames from 'classnames';

import { KustoDataType, KweException, RTDDropdownOption, RTDDropdownProps } from '@kusto/utils';

import { DASHBOARD_VALUE_DISPLAY_STRINGS } from '../../../constants';
import type { KweVisualFwkLocale } from '../../../types';
import { INFER_OPTION_KEY } from './utils';

import * as styles from './dropdownWithType.module.scss';

export type ColumnStatus =
    | {
          kind: 'unavailable';
      }
    | {
          kind: 'available';
          /** Parameter types + column types */
          type: 'duration' | 'array' | 'null' | KustoDataType;
      };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface DropdownOptionWithType<T = any> extends RTDDropdownOption<T> {
    data?: T;
    status?: ColumnStatus;
    /**
     * Only defined when the Dropdown
     * Option is of type "infer"
     * and it has inferred items.
     */
    inferredTextItems?: Array<{
        text: string;
        status?: ColumnStatus;
    }>;
    inferLabel?: string;
}

function statusToText(t: KweVisualFwkLocale, status: ColumnStatus) {
    if (status.kind === 'unavailable') {
        return t.visualFwk.value.unavailable;
    }
    const type = status.type;

    switch (type) {
        case 'duration':
            return t.visualFwk.value.duration;
        case 'array':
            return DASHBOARD_VALUE_DISPLAY_STRINGS.kustoArray;
        case 'null':
            return DASHBOARD_VALUE_DISPLAY_STRINGS.null;
        default:
            return type;
    }
}

interface ItemTextProps {
    t: KweVisualFwkLocale;
    option: DropdownOptionWithType;
    titleDisabled?: boolean;
    specificItemContainerClassName?: string;
    specificItemTextClassName?: string;
    inferItemContainerClassName?: string;
}

const ItemText: React.FC<ItemTextProps> = ({
    t,
    option,
    titleDisabled = false,
    specificItemContainerClassName,
    specificItemTextClassName,
    inferItemContainerClassName,
}) => {
    const titleText = titleDisabled ? undefined : getTitleText(t, [option]);

    if (option.key !== INFER_OPTION_KEY) {
        return (
            <span title={titleText} className={specificItemContainerClassName}>
                <span className={specificItemTextClassName}>{option.text}</span>
                {option.status && (
                    <span className={classNames(option.status?.kind, styles.typeText)}>
                        ({statusToText(t, option.status)})
                    </span>
                )}
            </span>
        );
    }

    const { inferredTextItems, inferLabel } = option;

    if (!inferredTextItems) {
        return <span title={titleText}>{option.text}</span>;
    }

    return (
        <span title={titleText} className={inferItemContainerClassName}>
            <span>
                {inferLabel ? inferLabel : t.visualFwk.visualConfig.dropdownInputInferOption}
                {': '}
            </span>
            {inferredTextItems.map((item, i) => (
                <React.Fragment key={i}>
                    {item.text}
                    {item.status && (
                        <span className={classNames(item.status?.kind, styles.typeText)}>
                            ({statusToText(t, item.status)})
                        </span>
                    )}
                    {i < inferredTextItems.length - 1 ? ', ' : null}
                </React.Fragment>
            ))}
        </span>
    );
};

interface DropdownOptionTextProps {
    t: KweVisualFwkLocale;
    option: DropdownOptionWithType;
}

const DropdownItemText: React.FC<DropdownOptionTextProps> = ({ t, option }) => {
    return (
        <ItemText
            t={t}
            option={option}
            specificItemContainerClassName={styles.specificItemContainer}
            specificItemTextClassName={styles.truncateText}
            inferItemContainerClassName={styles.truncateText}
        />
    );
};

/**
 * @returns A generated string of the column names + types
 */
function getTitleText(t: KweVisualFwkLocale, options: DropdownOptionWithType[]) {
    const textArr: string[] = [];

    for (const option of options) {
        if (option.key === INFER_OPTION_KEY) {
            const { inferredTextItems } = option;

            if (!inferredTextItems) {
                textArr.push(option.text);
                continue;
            }

            for (const textItem of inferredTextItems) {
                const status = textItem.status ? `(${statusToText(t, textItem.status)})` : '';
                textArr.push(`${textItem.text} ${status}`);
            }
        } else {
            const status = option.status ? `(${statusToText(t, option.status)})` : '';
            textArr.push(`${option.text} ${status}`);
        }
    }

    return textArr.join(', ');
}

interface DropdownTitleProps {
    t: KweVisualFwkLocale;
    selected: IDropdownOption[];
}

const DropdownTitle: React.FC<DropdownTitleProps> = ({ t, selected }) => {
    const titleText = getTitleText(t, selected as DropdownOptionWithType[]);

    return (
        <span title={titleText} className={styles.truncateText}>
            {selected.map((s, i) => (
                <React.Fragment key={s.key}>
                    {/* Disabling item title or else we'd have 2 titles */}
                    <ItemText t={t} titleDisabled option={s as DropdownOptionWithType} />
                    {i < selected.length - 1 ? ', ' : null}
                </React.Fragment>
            ))}
        </span>
    );
};

function createOnRenderTitle(t: KweVisualFwkLocale): RTDDropdownProps['onRenderTitle'] {
    return (selected) => {
        if (selected === undefined || selected.length === 0) {
            throw new KweException('Unable to determine visual selected dropdown selection');
        }
        return <DropdownTitle t={t} selected={selected} />;
    };
}

function createOnRenderOption(t: KweVisualFwkLocale): RTDDropdownProps['onRenderOption'] {
    return (option) => {
        if (option === undefined) {
            throw new KweException();
        }
        return <DropdownItemText t={t} option={option as DropdownOptionWithType} />;
    };
}

/**
 * Uses the data property on an dropdown option to render an icon on it
 *
 * Note: Does not have good support for multi select dropdowns
 * (Only renders the first selection when rendering the title)
 */
export function dropdownWithTypeTextProps(
    t: KweVisualFwkLocale
): Pick<RTDDropdownProps, 'onRenderTitle' | 'onRenderOption'> {
    return {
        onRenderOption: createOnRenderOption(t),
        onRenderTitle: createOnRenderTitle(t),
    };
}
