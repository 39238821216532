import React from 'react';

import * as Fwk from '@kusto/visual-fwk';
import {
    ConditionalFormattingConfig,
    ConditionalFormattingOptions,
    VColorRuleByCondition,
    VColorRuleByValue,
    VUColorRule,
} from '@kusto/visualizations';

export function parseRtdRule(rule: Fwk.ColorRule.UColorRule): VUColorRule {
    return rule.ruleType === 'colorByCondition' ? parseColorRuleByCondition(rule) : parseColorRuleByValue(rule);
}

function parseColorRuleByCondition(rule: Fwk.ColorRule.ColorRuleByCondition): VColorRuleByCondition {
    const options: ConditionalFormattingOptions = {
        iconName: rule.icon ? Fwk.colorRulesIconToIcon[rule.icon] : undefined,
        color: rule.color ?? undefined,
        subLabel: rule.tag,
        colorStyle: rule.colorStyle,
        hideText: !!rule.hideText,
    };
    const firstConditionColumn = rule.conditions[0]?.column ?? null;
    return {
        id: rule.id,
        ruleType: rule.ruleType,
        applyToColumn: rule.applyToColumn ?? firstConditionColumn,
        conditions: rule.conditions,
        chainingOperator: rule.chainingOperator,
        applyTo: rule.applyTo,
        options,
    };
}

function parseColorRuleByValue(rule: Fwk.ColorRule.ColorRuleByValue): VColorRuleByValue {
    const trimmedMaxValue = rule.maxValue?.trim();
    const trimmedMinValue = rule.minValue?.trim();

    const options: ConditionalFormattingOptions = {
        colorStyle: 'bold',
    };
    return {
        id: rule.id,
        ruleType: rule.ruleType,
        column: rule.column,
        applyTo: rule.applyTo,
        themeName: rule.themeName ?? undefined,
        maxValue: trimmedMaxValue === '' || trimmedMaxValue === undefined ? undefined : trimmedMaxValue,
        minValue: trimmedMinValue === '' || trimmedMinValue === undefined ? undefined : trimmedMinValue,
        reverseTheme: rule.reverseTheme,
        options,
    };
}
export function useCreateConditionalFormatting(
    t: Fwk.KweVisualFwkLocale,
    { addMessage, visualOptions, isDarkTheme }: Fwk.IDataVisualProps<'colorRules' | 'colorRulesDisabled'>,
    visualType: Fwk.ColorRulesVisualType
): ConditionalFormattingConfig {
    const { returnValue, colorRules } = React.useMemo(() => {
        const colorRules = Fwk.filterColorRules(visualOptions.colorRules, visualType);

        const returnValue: ConditionalFormattingConfig = {
            colorRulesDisabled: visualOptions.colorRulesDisabled,
            colorRules: colorRules.map((r) => parseRtdRule(r)),
        };

        return { colorRules, returnValue };
    }, [visualOptions.colorRules, visualOptions.colorRulesDisabled, visualType]);

    Fwk.useColorRulesMessages(t, visualType, addMessage, colorRules, visualOptions.colorRulesDisabled, isDarkTheme);

    return returnValue;
}
