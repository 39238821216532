/**
 * Turns a time interval into milliseconds.
 * @param timespan A time interval, in the format of HH:mm:ss
 * @example
 *  - 00:00:10 => 0*60*60*1000 + 0*60*1000 + 10*1000 = 10,000
 *  - 00:01:10 => 0*60*60*1000 + 1*60*1000 + 10*1000 = 70,000
 *  - 01:00:10 => 1*60*60*1000 + 0*60*1000 + 10*1000 = 3,610,000
 */
export const timespanInMilliseconds = (timespan: string) => {
    const theBigBangDateAccordingToJavascript = '1970/1/1';
    const timespanAsDate = new Date(`${theBigBangDateAccordingToJavascript} ${timespan} UTC`);
    const timespanInMilliseconds = timespanAsDate.getTime();
    if (isNaN(timespanInMilliseconds)) {
        return 0;
    }
    return timespanInMilliseconds;
};
