import React from 'react';
import { Body1, Spinner } from '@fluentui/react-components';
import { EmptyState } from '@trident/ux-react';
import { observer } from 'mobx-react-lite';

import { useStrings } from '../../DataExplorationModal/hooks/useStrings';

import styles from './SchemaColumnInfo.module.scss';

interface ColumnInfoStatusWrapperProps {
    isLoading: boolean;
    errorMessage?: string;
    children: React.ReactElement;
}

export const ColumnInfoStatusWrapper: React.FC<ColumnInfoStatusWrapperProps> = observer(
    function ColumnInfoStatusWrapper({ isLoading, errorMessage, children }) {
        const { strings } = useStrings();

        if (errorMessage) {
            return (
                <EmptyState
                    emptyStateType="noResult"
                    size="medium"
                    emptyStateTitle={strings.errorStateTitle}
                    className={styles.emptyState}
                >
                    <Body1 align="center">{errorMessage}</Body1>
                </EmptyState>
            );
        }

        if (isLoading) {
            return (
                <div className={styles.loadingIndicator}>
                    <Spinner size="huge" label={strings.schemaInsights.loading} labelPosition="below" />
                </div>
            );
        }

        return children;
    }
);
