import { Column, EntityType, Table } from '../../../common';
import { Database, QueryStoreEnv } from '../../../stores';
import { ITreeEntityMapper } from '../actions';
import { RowDataType } from '../RowDataType';
import { getBaseRootPath } from '../utils';
import { iconMapper } from './IconMapper';
import { RowDataTypeBase } from './RowDataTypeBase';
import { getFromCacheOrCreate } from './RowDataTypeCache';
import { TableRowDataType } from './TableRowDataType';

export class ColumnRowDataType extends RowDataTypeBase {
    private constructor(
        column: Column,
        table: Table,
        database: Database,
        baseRootPath: string[],
        treeEntityMapper: ITreeEntityMapper
    ) {
        const pathFromRoot = TableRowDataType.buildTableFolderPath(baseRootPath, table, treeEntityMapper);
        pathFromRoot.push(column.name);
        super(
            column.id,
            pathFromRoot,
            column.name,
            EntityType.Column,
            database,
            iconMapper[column.entityType],
            treeEntityMapper
        );

        this.details = column.type;
        this.info = column.docstring;
        this.actions = this.treeEntityMapper.getActions(this.entityType, false, this.info);
    }

    public static fromCacheOrCreate(
        env: QueryStoreEnv,
        column: Column,
        table: Table,
        database: Database,
        treeEntityMapper: ITreeEntityMapper
    ): RowDataType {
        return getFromCacheOrCreate(
            database,
            column.id,
            () => new ColumnRowDataType(column, table, database, getBaseRootPath(env, database), treeEntityMapper)
        );
    }
}
