import React from 'react';
import { tokens } from '@fluentui/react-components';
import { ArrowMinimize20Regular, ExpandUpRight20Regular } from '@fluentui/react-icons';
import { observer } from 'mobx-react';

import { formatLiterals } from '@kusto/utils';
import { ContentViewModeType } from '@kusto/visualizations';

import { useQueryCore } from '../../core/core';
import { IJPathMenuProps, IMenuComponentProps, IMenuOption, JPathMenuComponent } from './MenuComponent';

export const ContentViewSelectionMenu = observer(function ContentViewSelectionMenu({
    showLabel,
    isMenuItem,
}: IJPathMenuProps) {
    const core = useQueryCore();
    const {
        strings,
        store: { layout },
    } = core;
    const onSelected = layout.setContentViewMode;
    const selected = layout.contentViewMode;
    const menuOptions: IMenuOption[] = [
        {
            key: strings.query.queryContentViewMode.fullModeLabel,
            data: ContentViewModeType.Full,
            label: strings.query.queryContentViewMode.fullModeLabel,
            tooltipProps: { content: strings.query.queryContentViewMode.fullModeDescription },
            icon: <ExpandUpRight20Regular primaryFill={tokens.colorPaletteBlueBorderActive} />,
            isDisabled: false,
        },
        {
            key: strings.query.queryContentViewMode.compactModeLabel,
            data: ContentViewModeType.Compact,
            label: strings.query.queryContentViewMode.compactModeLabel,
            tooltipProps: { content: strings.query.queryContentViewMode.compactModeDescription },
            icon: <ArrowMinimize20Regular primaryFill={tokens.colorPaletteBlueBorderActive} />,
        },
    ];

    const selectedItem: IMenuOption =
        menuOptions.find((option: IMenuOption) => (option.data as number) === selected) ?? menuOptions[0];

    const ariaLabel = formatLiterals(strings.query.queryContentViewMode.ariaLabel, {
        selected: selectedItem.key,
    });
    const tooltipContent = strings.query.queryContentViewMode.toolTip;
    const props: IMenuComponentProps = {
        selectedItem,
        tooltipContent,
        showLabel,
        isMenuItem,
        labelWithSelection: ariaLabel,
        menuOptions,
        onItemSelected: onSelected as (item: IMenuOption['data']) => void,
    };
    return <JPathMenuComponent {...props} />;
});
