import React from 'react';
import { Events, GridApi, IStatusPanelParams } from '@ag-grid-community/core';
import { Announced } from '@fluentui/react';
import moment from 'moment';

import { useQueryCore } from '../..';
import { QueryCompletionColumns } from '../../stores';
import { getRangeSelection } from './utils';

const StatusValue: React.FC<{ label: string; value: string | number | null }> = ({ label, value }) => {
    return (
        <div className={`ag-status-name-value ${value === null ? 'ag-hidden' : ''}`}>
            <span>{label}</span>:&nbsp;
            <span className="ag-status-name-value-value">{value}</span>
        </div>
    );
};

export interface GridStatusPanelProps extends IStatusPanelParams {
    columns: QueryCompletionColumns;
}

interface State {
    count: number;
    min: number | null;
    max: number | null;
    avg: string | null;
    sum: string | null;
    timeDiff: string | null;
    numberCount: number;
    dateCount: number;
}

export const GridStatusPanel = (props: GridStatusPanelProps) => {
    const core = useQueryCore();
    const [values, setValues] = React.useState<State>({
        count: 0,
        min: null,
        max: null,
        avg: null,
        sum: null,
        timeDiff: null,
        numberCount: 0,
        dateCount: 0,
    });
    const [filteredRowsAnnounce, setFilteredRowsAnnounce] = React.useState<string>();

    const setAgregationValues = React.useCallback(() => {
        setValues(onRangeSelectionChanged(props.api, props.columns));
    }, [props.api, props.columns]);

    const onDataChanged = React.useCallback(() => {
        const rowCount = getFilteredRowCountValue(props.api);
        const totalRowCount = getTotalRowCount(props.api);
        const filteredRowsAnnounceText =
            rowCount !== totalRowCount ? `${rowCount} of ${totalRowCount} rows` : `${rowCount} rows`;
        setFilteredRowsAnnounce(filteredRowsAnnounceText);
    }, [props.api]);

    React.useEffect(() => {
        props.api.addEventListener('rangeSelectionChanged', setAgregationValues);
        props.api.addEventListener(Events.EVENT_MODEL_UPDATED, onDataChanged);
        return () => {
            props.api.removeEventListener(Events.EVENT_MODEL_UPDATED, onDataChanged);
            props.api.removeEventListener('rangeSelectionChanged', setAgregationValues);
        };
    }, [setAgregationValues, props.api, onDataChanged]);

    return (
        <>
            <Announced message={filteredRowsAnnounce} />
            <div className="ag-status-panel ag-status-panel-aggregations">
                {values.count > 1 && (
                    <StatusValue label={core.strings.query.grisStatusPanel$selected} value={values.count} />
                )}
                {values.dateCount > 1 && (
                    <StatusValue label={core.strings.query.grisStatusPanel$timeDiff} value={values.timeDiff} />
                )}
                {values.numberCount > 1 && (
                    <>
                        <StatusValue label={core.strings.query.grisStatusPanel$avg} value={values.avg} />
                        <StatusValue label={core.strings.query.grisStatusPanel$count} value={values.numberCount} />
                        <StatusValue label={core.strings.query.grisStatusPanel$min} value={values.min} />
                        <StatusValue label={core.strings.query.grisStatusPanel$max} value={values.max} />
                        <StatusValue label={core.strings.query.grisStatusPanel$sum} value={values.sum} />
                    </>
                )}
            </div>
        </>
    );
};

function getFilteredRowCountValue(api: GridApi) {
    let filteredRowCount = 0;
    api.forEachNodeAfterFilter((node) => {
        if (!node.group) {
            filteredRowCount++;
        }
    });
    return filteredRowCount;
}

function getTotalRowCount(api: GridApi) {
    let totalRowCount = 0;
    api.forEachNode((node) => {
        if (!node.group) {
            totalRowCount++;
        }
    });
    return totalRowCount;
}

/** This method is almost completely copied from ag-grid:
 ** https://github.com/ag-grid/ag-grid/blob/ca102f728390eda5dfe70f583cead91f2841d073/enterprise-modules/status-bar/src/statusBar/providedPanels/aggregationComp.ts
 */
function onRangeSelectionChanged(api: GridApi, columns: QueryCompletionColumns) {
    let cellRanges = null;
    try {
        cellRanges = getRangeSelection(api);
    } catch (_ex) {}

    let sum = 0;
    let count = 0;
    let numberCount = 0;
    let dateCount = 0;
    let min: number | null = null;
    let max: number | null = null;
    let minDate: Date | null = null;
    let maxDate: Date | null = null;

    const cellsSoFar: Set<string> = new Set();

    if (cellRanges) {
        for (const cellRange of cellRanges) {
            let currentRow = 0;
            let lastRow = 0;
            if (cellRange.startRow && cellRange.endRow) {
                currentRow = Math.min(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex);
                lastRow = Math.max(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex);
            }
            if (!cellRange.columns || currentRow === null) {
                break;
            }
            while (currentRow <= lastRow) {
                for (let i = 0; i < cellRange.columns.length; i++) {
                    const col = cellRange.columns[i];
                    // we only want to include each cell once, in case a cell is in multiple ranges
                    const cellId = `${currentRow}.${col.getId()}`;
                    if (cellsSoFar.has(cellId)) {
                        continue;
                    }
                    cellsSoFar.add(cellId);
                    const model = api.getModel();
                    const rowNode = model.getRow(currentRow);
                    if (!rowNode) {
                        continue;
                    }

                    let value = api.getValue(col, rowNode);

                    // if empty cell, skip it, doesn't impact count or anything
                    if (value === null || value === undefined || value === '') {
                        count++;
                        continue;
                    }

                    // see if value is wrapped, can happen when doing count() or avg() functions
                    if (value.value) {
                        value = value.value;
                    }

                    let valueAsNumber = value;
                    if (typeof value === 'string') {
                        valueAsNumber = Number(value);
                    }

                    if (typeof valueAsNumber === 'number' && !isNaN(valueAsNumber)) {
                        sum += valueAsNumber;

                        if (max === null || valueAsNumber > max) {
                            max = valueAsNumber;
                        }

                        if (min === null || valueAsNumber < min) {
                            min = valueAsNumber;
                        }

                        numberCount++;
                    } else {
                        // Try to treat the value as date
                        const valueAsDate = new Date(value);
                        const resultCol = columns?.find((c) => c.field === col.getColDef().field);
                        if (resultCol?.columnType === 'datetime' && !isNaN(valueAsDate.valueOf())) {
                            if (minDate === null || valueAsDate < minDate) {
                                minDate = valueAsDate;
                            }
                            if (maxDate === null || valueAsDate > maxDate) {
                                maxDate = valueAsDate;
                            }
                            dateCount++;
                        }
                    }

                    count++;
                }

                currentRow += 1;
            }
        }
    }

    let timeDiff = null;
    if (minDate && maxDate) {
        const diffTime = Math.abs(maxDate.getTime() - minDate.getTime());
        const dur = moment.duration(diffTime);
        const days = Math.floor(dur.asDays());
        const hours = padTimeUnit(String(dur.hours()));
        const minutes = padTimeUnit(String(dur.minutes()));
        const seconds = padTimeUnit(String(dur.seconds()));
        const milliseconds = dur.milliseconds();
        let timespan = `${hours}:${minutes}:${seconds}`;
        if (days) {
            timespan = `${days}.${timespan}`;
        }
        if (milliseconds) {
            timespan = `${timespan}.${milliseconds}`;
        }
        timeDiff = timespan;
    }

    return {
        count,
        sum: sum.toFixed(3),
        min,
        max,
        avg: (sum / numberCount).toFixed(3),
        timeDiff,
        numberCount,
        dateCount,
    };
}

function padTimeUnit(value: string) {
    if (value.length === 1) {
        return `0${value}`;
    }
    return value;
}
