import type { DataFrameTypes, Ok, Result } from '@kusto/utils';

import type { KweVisualFwkLocale } from '../types';
import type { TaggedBasicNumericType, TaggedValue } from './taggedValue';

export type InteractionMessageLevel = 'error' | 'warn';

export interface InteractionErrorMessage {
    readonly level: InteractionMessageLevel;
    text(): string;
}

export function interactionErrorMessage(
    text: () => string,
    level: InteractionMessageLevel = 'error'
): InteractionErrorMessage {
    return { level, text };
}

export interface InteractionErrorList {
    readonly errors: readonly InteractionErrorMessage[];
    /**
     * Highest level in the error list
     */
    readonly kind: InteractionMessageLevel;
}

/**
 * Differs from Kwe scalar types in that it doesn't include guid or timespan
 *
 * Should be a subset of import('@kusto/utils').KweDataType
 */
export type BasicParamTypeTag = TaggedBasicNumericType | 'bool' | 'string';

/**
 * # Basic parameter unknown serialized value
 *
 * Using the symbol "UnknownSF" because it's written so often
 *
 * Union of formats basic parameters can be serialized to. As of writing,
 * including string, number, and boolean.
 */
export type BasicParamUnknown = DataFrameTypes[BasicParamTypeTag];

export declare namespace ParamDataType {
    export type All = readonly ['all'];
    export type UserSelectionRequired = readonly ['no-selection'];
    export type DefaultOnResult = readonly ['query-result'];
    export type DataSource = readonly ['data-source'];

    export type Scalar<K> = readonly [K];

    /**
     * Duplicate values are not supported at this time
     */
    export type RtdArray<K> = readonly ['array', K];

    export type ScalarOrArray<K> = Scalar<K> | RtdArray<K>;

    export type UBasicScalarOrArray =
        | ScalarOrArray<'string'>
        | ScalarOrArray<'int'>
        | ScalarOrArray<'long'>
        | ScalarOrArray<'real'>
        | ScalarOrArray<'decimal'>
        | ScalarOrArray<'bool'>
        | ScalarOrArray<'datetime'>;

    export type UBasicType = All | UBasicScalarOrArray;

    export type Duration = Scalar<'duration'>;

    export type UType = UBasicType | Duration | UserSelectionRequired | DefaultOnResult | DataSource;

    /**
     * Note: `Scalar<'duration' | keyof KweDataTypes>` so we get a union of
     * Scalar types, instead of a single scalar type with a union property
     */
    export type UScalar = Extract<UType, Scalar<'duration' | BasicParamTypeTag>>;

    export type UArray = Extract<UType, RtdArray<unknown>>;
}

export type UParamDataType = ParamDataType.UType;

export interface InteractionTarget {
    /**
     * (UUID)
     */
    readonly id: string;
    readonly displayName: string;
    typeDisplayName(strings: KweVisualFwkLocale): string;
    /**
     * Check if a type of value will do anything if it's applied.
     *
     * If a single variant will be applied at least some of the time, kind will
     * be warning. Kind is error if there is no chance the value can be applied
     */
    canApply(types: readonly UParamDataType[]): Ok | InteractionErrorList;
}

export const basicValueDataTypes: readonly BasicParamTypeTag[] = [
    'string',
    'real',
    'decimal',
    'int',
    'bool',
    'datetime',
    'long',
];

export const ALL_SELECTION: ParamValue.All = { kind: 'all' };
export const NO_SELECTION: ParamValue.SelectionRequired = { kind: 'no-selection' };
export const DEFAULT_ON_RESULT: ParamValue.DefaultOnResult = { kind: 'query-result' };

export declare namespace ParamValue {
    /**
     * Serializes as either null or every available option, depending on the parameter config
     */
    export interface All {
        readonly kind: 'all';
    }

    /**
     * When this is a parameters value, the dependents of the parameter will not
     * run. Users will need to change the selected value before dependents run.
     */
    export interface SelectionRequired {
        readonly kind: 'no-selection';
    }

    /**
     * # Default on query result
     *
     * When this is a parameters value, the parameter will be updated using the
     * first available query result.
     */
    export interface DefaultOnResult {
        readonly kind: 'query-result';
    }

    /**
     * Value of a data source parameter
     */
    export interface DataSource {
        readonly kind: 'data-source';
        readonly dataSourceId: string;
    }

    export type UTagged =
        | TaggedValue.TaggedDuration
        | TaggedValue.UBasicScalar
        | TaggedValue.UBasicArray
        | All
        | SelectionRequired
        | DefaultOnResult
        | DataSource;
}

export type UTaggedParamValue = ParamValue.UTagged;

export type MaybeParamValue = Result<UTaggedParamValue, React.ReactNode>;

export interface InteractionPair {
    readonly parameterId: string;
    readonly value: MaybeParamValue;
}

export type InteractionValues = Result<ReadonlyArray<Result<InteractionPair>>>;
