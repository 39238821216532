import type { CrossFilterConfig, CrossFilterInteraction, CrossFilterProperty } from '../../visualOptions';

export interface ReadyCrossFilter extends CrossFilterConfig {
    readonly interaction: CrossFilterInteraction;
    readonly property: CrossFilterProperty;
    readonly parameterId: string;
    readonly disabled: false;
}

export function crossFilterIsReady(crossFilter: CrossFilterConfig): crossFilter is ReadyCrossFilter {
    return (
        !crossFilter.disabled &&
        crossFilter.interaction !== undefined &&
        crossFilter.parameterId !== undefined &&
        crossFilter.property !== undefined
    );
}
