import { size } from '@floating-ui/dom';
import { getBoundary } from '../utils/getBoundary';
import { toFloatingUIPadding } from '../utils';
/**
 * floating-ui `size` middleware uses floating element's height/width to calculate available height/width.
 * This middleware only runs once per lifecycle, resetting styles applied by maxSize from previous lifecycle.
 * Then floating element's original size is restored and `size` middleware can calculate available height/width correctly.
 */ export const resetMaxSize = (autoSize)=>({
        name: 'resetMaxSize',
        fn ({ middlewareData, elements }) {
            var _middlewareData_resetMaxSize;
            if ((_middlewareData_resetMaxSize = middlewareData.resetMaxSize) === null || _middlewareData_resetMaxSize === void 0 ? void 0 : _middlewareData_resetMaxSize.maxSizeAlreadyReset) {
                return {};
            }
            const { applyMaxWidth, applyMaxHeight } = autoSize;
            if (applyMaxWidth) {
                elements.floating.style.removeProperty('box-sizing');
                elements.floating.style.removeProperty('max-width');
                elements.floating.style.removeProperty('width');
            }
            if (applyMaxHeight) {
                elements.floating.style.removeProperty('box-sizing');
                elements.floating.style.removeProperty('max-height');
                elements.floating.style.removeProperty('height');
            }
            return {
                data: {
                    maxSizeAlreadyReset: true
                },
                reset: {
                    rects: true
                }
            };
        }
    });
export function maxSize(autoSize, options) {
    const { container, overflowBoundary, overflowBoundaryPadding, isRtl } = options;
    return size({
        ...overflowBoundaryPadding && {
            padding: toFloatingUIPadding(overflowBoundaryPadding, isRtl)
        },
        ...overflowBoundary && {
            altBoundary: true,
            boundary: getBoundary(container, overflowBoundary)
        },
        apply ({ availableHeight, availableWidth, elements, rects }) {
            const applyMaxSizeStyles = (apply, dimension, availableSize)=>{
                if (!apply) {
                    return;
                }
                elements.floating.style.setProperty('box-sizing', 'border-box');
                elements.floating.style.setProperty(`max-${dimension}`, `${availableSize}px`);
                if (rects.floating[dimension] > availableSize) {
                    elements.floating.style.setProperty(dimension, `${availableSize}px`);
                    const axis = dimension === 'width' ? 'x' : 'y';
                    if (!elements.floating.style.getPropertyValue(`overflow-${axis}`)) {
                        elements.floating.style.setProperty(`overflow-${axis}`, 'auto');
                    }
                }
            };
            const { applyMaxWidth, applyMaxHeight } = autoSize;
            applyMaxSizeStyles(applyMaxWidth, 'width', availableWidth);
            applyMaxSizeStyles(applyMaxHeight, 'height', availableHeight);
        }
    });
}
