import React from 'react';
import { Tooltip } from '@fluentui/react-components';

export interface TooltipIfNeededProps {
    tooltip?: string;
    children: React.ReactElement;
}

// Fluent 9 tooltip wrapper
export const TooltipIfNeeded: React.FC<TooltipIfNeededProps> = ({ children, tooltip }) => {
    return tooltip ? (
        <Tooltip content={tooltip} relationship="description" positioning="above-start">
            {children}
        </Tooltip>
    ) : (
        <>{children}</>
    );
};
