import React from 'react';
import { ToolbarButton, Tooltip } from '@fluentui/react-components';
import { Dismiss20Regular } from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';

import { useQueryCore } from '../../../core/core';

export const DataProfileCloseButton: React.FC<{ onClose(): void }> = observer(function DataProfileCloseButton({
    onClose,
}) {
    const core = useQueryCore();
    return (
        <Tooltip content={core.strings.query.close} relationship="label">
            <ToolbarButton appearance="subtle" icon={<Dismiss20Regular />} onClick={onClose} />
        </Tooltip>
    );
});
