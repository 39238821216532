import React from 'react';
import { useArrowNavigationGroup, useFocusFinders } from '@fluentui/react-components';

import { useResizeObserver } from '@kusto/utils';

import { JPathView, JsonModelData } from '../jpath';
import { KweAgGridLocale } from '../locale';

import classNames from './ExpandTopBar.module.scss';

export interface ExpandTopBarProps {
    jsonModelData: JsonModelData;
    locale: KweAgGridLocale;
    renderExpandBarItems: (isCompactMode?: boolean) => JSX.Element;
    styles?: React.CSSProperties;
    onTabPressOnLastBarItem?: () => void;
}

const MAX_COMPACT_WIDTH = 400;

/**
 * Ag grid uses a different focus management system than Fluent UI components.
 * this hook uses fluent's focusable element finder to add an event listener on
 * the last focusable element in the arrow group to handle tab key press.
 * the next tab stop is handled via callback passed to the hook.
 */
const useSyncFocusBetweenFluentAndAgGrid = (onTabPressOnLastBarItem?: () => void) => {
    const { findLastFocusable } = useFocusFinders();
    const [buttonContainerRet, setButtonContainerRef] = React.useState<HTMLElement | null>(null);

    const handleTabOutside = React.useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Tab' && !e.shiftKey) {
                e.preventDefault();
                onTabPressOnLastBarItem?.();
            }
        },
        [onTabPressOnLastBarItem]
    );

    React.useEffect(() => {
        if (buttonContainerRet) {
            const lastButton = findLastFocusable(buttonContainerRet);
            if (lastButton) {
                lastButton.addEventListener('keydown', handleTabOutside);
                return () => {
                    lastButton.removeEventListener('keydown', handleTabOutside);
                };
            }
        }
    }, [buttonContainerRet, handleTabOutside, findLastFocusable]);

    return { setButtonContainerRef };
};

export const ExpandTopBar: React.FC<ExpandTopBarProps> = ({
    jsonModelData,
    locale,
    renderExpandBarItems,
    styles,
    onTabPressOnLastBarItem,
}) => {
    const { ref: resizeRef, width } = useResizeObserver<HTMLDivElement>();
    const isCompactMode = MAX_COMPACT_WIDTH >= (width || 0);
    const { setButtonContainerRef } = useSyncFocusBetweenFluentAndAgGrid(onTabPressOnLastBarItem);

    const attributes = useArrowNavigationGroup({ axis: 'horizontal', tabbable: true });
    return (
        <div
            role="toolbar"
            {...attributes}
            ref={setButtonContainerRef} // HTML element ref prop accepts a function, and passes the DOM element to it as an argument. passing a state setter function from useState allows reacting to the element changing after render.
            className={classNames.expandBarContainer}
            style={styles}
        >
            <div className={classNames.expandBarBody} ref={resizeRef}>
                <JPathView {...jsonModelData} locale={locale}>
                    {renderExpandBarItems(isCompactMode)}
                </JPathView>
            </div>
        </div>
    );
};
