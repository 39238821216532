import { createContext, useContextSelector } from '@fluentui/react-context-selector';
import { ImmutableSet } from '../utils/ImmutableSet';
import { ImmutableMap } from '../utils/ImmutableMap';
/**
 * @internal
 */ const defaultTreeContextValue = {
    level: 0,
    contextType: 'root',
    treeType: 'nested',
    selectionMode: 'none',
    openItems: ImmutableSet.empty,
    checkedItems: ImmutableMap.empty,
    requestTreeResponse: noop,
    appearance: 'subtle',
    size: 'medium'
};
function noop() {
/* noop */ }
/**
 * @internal
 */ export const TreeContext = createContext(undefined);
export const useTreeContext_unstable = (selector)=>useContextSelector(TreeContext, (ctx = defaultTreeContextValue)=>selector(ctx));
