import React from 'react';
import {
    AzureMap,
    AzureMapDataSourceProvider,
    AzureMapLayerProvider,
    AzureMapPopup,
    AzureMapsProvider,
    type IAzureMap,
    type IAzureMapOptions,
} from 'react-azure-maps';

import 'azure-maps-control/dist/atlas.min.css';

import { AuthenticationType, Map, data as mapData, type MapEvent, type MapMouseEvent } from 'azure-maps-control';
import debounce from 'lodash/debounce';

import { KweException, ok, Theme } from '@kusto/utils';
import type { VisualMessageFormatter } from '@kusto/visual-fwk';

import { DataItemWithRow, Rows, VisualizationsStrings } from '../types';
import { getChartColors } from '../utils/getChartColors';
import type { ExtendedVisualizationOptions } from '../utils/visualization';
import { MapTooltip, MapTooltipProps } from './MapTooltip';
import { bringDataIntoView, getBubbleMapFeatureCollection, getDefaultFeatureCollection, MapFeature } from './utils';

import * as styles from './map.module.scss';

/**
 * There's no focus map event for bubble layers that's exposed
 * from azure-maps-control so we have to define one ourselves.
 */
interface FocusInMapEvent extends MapEvent {
    shape: {
        data: MapFeature;
    };
}

const MAP_CONTROLS: IAzureMap['controls'] = [{ controlName: 'ZoomControl', controlOptions: { style: 'auto' } }];

interface SuccessAzureMapProps
    extends Pick<BaseKustoAzureMapProps, 'azureMapSubscriptionKey' | 'isDarkTheme'>,
        Pick<ExtendedVisualizationOptions, 'DisableSize' | 'Visualization' | 'Kind'> {
    strings: VisualizationsStrings;
    featureCollection: mapData.FeatureCollection;
}

const SuccessAzureMap: React.FC<SuccessAzureMapProps> = ({
    strings,
    featureCollection,
    azureMapSubscriptionKey,
    isDarkTheme,
    DisableSize,
    Kind,
    Visualization,
}) => {
    const rootRef = React.useRef<HTMLDivElement | null>(null);
    const mapRef = React.useRef<Map | undefined>(undefined);
    const [activePopupState, setActivePopupState] = React.useState<null | Omit<
        MapTooltipProps,
        'strings' | 'isDarkTheme'
    >>(null);

    // Turns out we're rendering multiple times when switching in dashboards from param = A to param = B.
    // onReady is only called on the 1st time, and it has the old feautres value (even when using a ref).
    // thus in this dashboarding scenario the zoom box will always be the previous one.
    // This useEffect solves this issue.
    // We cannot get rid of the onReady call , since when rendering a map in the query experience, this effect runs
    // before the map is ready (mapRef is undefined), thus it would have no effect.
    // using timeout here to increase the change this happens after the ready call.
    React.useEffect(() => {
        let canceled = false;

        setTimeout(() => {
            if (!canceled && mapRef.current) {
                bringDataIntoView(featureCollection, mapRef.current);
            }
        }, 500);

        return () => {
            canceled = true;
        };
    }, [featureCollection]);

    React.useLayoutEffect(() => {
        const root = rootRef.current;

        if (!root) {
            throw new KweException('Missing azure maps root container');
        }

        const resize = debounce(() => mapRef.current?.resize(), 100);
        const observer = new ResizeObserver(resize);
        observer.observe(root);

        return () => observer.disconnect();
    }, []);

    React.useLayoutEffect(() => {
        mapRef.current?.setStyle({ style: isDarkTheme ? 'night' : 'road' });
    }, [isDarkTheme]);

    const mapOptions: IAzureMapOptions = React.useMemo(
        () => ({
            zoom: 3,
            center: [-95.7129, 37.0902],
            preserveDrawingBuffer: true,
            authOptions: {
                authType: AuthenticationType.subscriptionKey,
                subscriptionKey: azureMapSubscriptionKey,
            },
            style: isDarkTheme ? 'night' : 'road',
        }),
        [azureMapSubscriptionKey, isDarkTheme]
    );

    const mapEvents = React.useMemo(() => {
        return {
            ready: (event: MapEvent) => {
                mapRef.current = event.map;
                bringDataIntoView(featureCollection, event.map);

                // Accessibility workaround - making sure the copyright link is last child for the sake of tabs order
                // Related bug - https://msazure.visualstudio.com/One/_workitems/edit/8182058
                const copyrightLink = mapRef.current.getMapContainer().querySelector(`.map-copyright`);
                if (copyrightLink) {
                    // Added while enabling lints

                    copyrightLink!.parentElement?.appendChild(copyrightLink);
                }
            },
        };
    }, [featureCollection]);

    const mapLayerOptions = React.useMemo(() => {
        const cantInferMinOrMax =
            (Visualization !== 'piechart' && Visualization !== 'map') || (DisableSize && Kind === 'bubble');

        let maxValue: undefined | number;
        let minValue: undefined | number;
        if (!cantInferMinOrMax) {
            maxValue = featureCollection.features.reduce((prev, curr) => {
                // cast because it got widened
                const size = (curr as MapFeature).properties?.size;

                if (size === undefined) {
                    return prev;
                }

                return size > prev ? size : prev;
            }, -Infinity);

            minValue = featureCollection.features.reduce((prev, curr) => {
                // cast because it got widened
                const size = (curr as MapFeature).properties?.size;

                if (size === undefined) {
                    return prev;
                }

                return size <= prev ? size : prev;
            }, +Infinity);
        }

        return {
            /**
             * When turning Size option on, there will be a warning in the console that is a result of a bug on azure maps end.
             * Azure maps is using the older version of the feature collection, when the size property is still null.
             * this is resolved after a few milliseconds and causes no apparent problem.
             */
            radius:
                (Visualization === 'piechart' || Kind === 'bubble') && minValue !== maxValue
                    ? ['interpolate', ['linear'], ['get', 'size'], minValue, 5, maxValue, 60]
                    : 10,
            opacity: 0.6,
            color: ['string', ['get', 'color']],
            /**
             * This is important to have turned on. It makes the bubble layers keyboard accessible.
             */
            createIndicators: true,
        };
    }, [featureCollection, Visualization, DisableSize, Kind]);

    const mapLayerEvents = React.useMemo(() => {
        return {
            mouseover: (event: MapMouseEvent) => {
                event.map.getCanvasContainer().style.cursor = 'pointer';
            },
            mouseout: (event: MapMouseEvent) => {
                event.map.getCanvasContainer().style.cursor = 'grab';
            },
            click: (event: MapMouseEvent) => {
                const point = event?.shapes?.[0];
                if (!point) {
                    return;
                }

                // Casting to any for the shape because the types
                // don't show there's a `data` property. Also,
                // we're treating the data as a MapFeature because
                // it comes from `featureCollectionResult`
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const feature = (point as any).data as MapFeature;

                if (!feature.properties) {
                    return;
                }

                setActivePopupState({
                    position: feature.geometry.coordinates,
                    argument: feature.properties.argument,
                    color: feature.properties.color,
                    value: feature.properties.value,
                });
            },
            focusin: (event: FocusInMapEvent) => {
                const feature = event.shape.data;

                if (!feature.properties) {
                    return;
                }

                setActivePopupState({
                    position: feature.geometry.coordinates,
                    argument: feature.properties.argument,
                    color: feature.properties.color,
                    value: feature.properties.value,
                });
            },
            focusout: () => {
                setActivePopupState(null);
            },
        };
    }, []);

    const memoizedPopup = React.useMemo(() => {
        if (!activePopupState) {
            return null;
        }

        return (
            <AzureMapPopup
                isVisible={true}
                options={{ position: activePopupState.position }}
                popupContent={<MapTooltip {...activePopupState} strings={strings} isDarkTheme={isDarkTheme} />}
                events={[
                    {
                        eventName: 'close',
                        callback: () => setActivePopupState(null),
                    },
                ]}
            />
        );
    }, [strings, isDarkTheme, activePopupState]);

    return (
        <div ref={rootRef} className={styles.successRoot}>
            <AzureMapsProvider>
                <AzureMap events={mapEvents} options={mapOptions} controls={MAP_CONTROLS}>
                    <AzureMapDataSourceProvider id="QueryResult" collection={featureCollection}>
                        <AzureMapLayerProvider
                            id="Bubble"
                            options={mapLayerOptions}
                            type="BubbleLayer"
                            events={mapLayerEvents}
                        />
                    </AzureMapDataSourceProvider>
                    <>{memoizedPopup}</>
                </AzureMap>
            </AzureMapsProvider>
        </div>
    );
};

interface BaseKustoAzureMapProps {
    visualizationOptions: Pick<
        ExtendedVisualizationOptions,
        | 'LabelColumn'
        | 'SizeColumn'
        | 'DisableSize'
        | 'Visualization'
        | 'Kind'
        | 'Longitude'
        | 'Latitude'
        | 'GeoType'
        | 'GeoPointColumn'
    >;
    azureMapSubscriptionKey?: string;
    dataItems?: readonly DataItemWithRow[];
    rows?: Rows;
    isDarkTheme?: boolean;
    strings: VisualizationsStrings;
    formatMessage: VisualMessageFormatter;
}

const BaseKustoAzureMap: React.FC<BaseKustoAzureMapProps> = ({
    visualizationOptions,
    isDarkTheme,
    dataItems,
    rows,
    azureMapSubscriptionKey,
    strings,
    formatMessage,
}) => {
    const featureCollectionResult = React.useMemo(() => {
        const { colors } = getChartColors(isDarkTheme ? Theme.Dark : Theme.Light);

        if (visualizationOptions.Visualization === 'map' && visualizationOptions.Kind === 'bubble') {
            if (!rows) {
                return null;
            }

            return getBubbleMapFeatureCollection(rows, visualizationOptions, strings.errors.columnTypeError, colors);
        }

        if (!dataItems) {
            return null;
        }

        // wrapping with ok so the structure of
        // `featureCollectionResult` appears the same
        return ok(getDefaultFeatureCollection(dataItems, visualizationOptions, colors));
    }, [rows, visualizationOptions, strings.errors.columnTypeError, dataItems, isDarkTheme]);

    if (!azureMapSubscriptionKey || featureCollectionResult === null) {
        return null;
    }

    return featureCollectionResult.kind === 'err' ? (
        formatMessage({ message: featureCollectionResult.err, level: 'warn' })
    ) : (
        <SuccessAzureMap
            strings={strings}
            featureCollection={featureCollectionResult.value}
            azureMapSubscriptionKey={azureMapSubscriptionKey}
            isDarkTheme={isDarkTheme}
            DisableSize={visualizationOptions.DisableSize}
            Visualization={visualizationOptions.Visualization}
            Kind={visualizationOptions.Kind}
        />
    );
};

/**
 * Taken from the Three.js library
 * @see https://github.com/mrdoob/three.js/blob/11b7b5c1ace4cd1a62646adb8cf946580dc59dac/examples/jsm/capabilities/WebGL.js
 */
export function isWebGLAvailableOnDevice(): boolean {
    try {
        const canvas = document.createElement('canvas');
        return !!(
            window.WebGLRenderingContext &&
            (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
        );
    } catch (e) {
        return false;
    }
}

// singleton so we don't need to check
// every time a map visual goes to render
const isWebGLAvailableValue = isWebGLAvailableOnDevice();

export interface KustoAzureMapProps extends BaseKustoAzureMapProps {
    /**
     * Optional. Will render an error message if `false` as
     * Azure Maps needs WebGL enabled in order to run.
     *
     * Defaults to the returned result of {@link isWebGLAvailableOnDevice}
     */
    isWebGLAvailable?: boolean;
}

export const KustoAzureMap: React.FC<KustoAzureMapProps> = ({ isWebGLAvailable = isWebGLAvailableValue, ...props }) => {
    if (isWebGLAvailable) {
        return <BaseKustoAzureMap {...props} />;
    }

    return props.formatMessage({ message: props.strings.maps.webGLUnavailable, level: 'error' });
};
