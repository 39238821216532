import { Column, IRowNode } from '@ag-grid-community/core';

import { JsonModelData } from './types';

export interface ModelJSONNode {
    start: number;
    end: number;
    value: string;
    isValidDynamic: boolean;
}

export interface ModelsBuilderResult {
    editorText: string;
    cellJsonModel: ModelJSONNode[];
}

export const buildJsonEntry = (
    headerName: string | undefined,
    value: string | null,
    EOL: string,
    showComma: boolean
) => {
    return `"${headerName}": ${value}${showComma ? ',' : ''}` + EOL;
};

const NEW_LINE = /\r?\n/;

const getNextFreeLine = (str: string) => {
    return str.split(NEW_LINE).length;
};

const buildModelJSONNode = (
    val: string,
    actualValue: string | null,
    headerName: string | undefined,
    start: number,
    isValidDynamic: boolean
): ModelJSONNode => {
    const end = start + getNextFreeLine(val) - 1; // 'getNextLine' returns the next free line, therefore subtracts 1
    const value = `{${buildJsonEntry(headerName, actualValue, '', false)}}`;
    return { start, end, value, isValidDynamic };
};

export const getJsonStringAndRelativeLineNumber = (
    nodes: ModelJSONNode[],
    lineNumber: number | undefined
): Pick<JsonModelData, 'rawString' | 'lineNumber'> | undefined => {
    if (lineNumber !== undefined && lineNumber >= 0) {
        const modelJsonNode = nodes.find((node) => lineNumber >= node.start && lineNumber <= node.end);

        if (modelJsonNode) {
            const { start, value, isValidDynamic } = modelJsonNode;
            const relativeLineNumber = isValidDynamic ? lineNumber - start + 1 : 1;
            return { rawString: value, lineNumber: relativeLineNumber };
        }
    }
};

export const buildEditorAndJsonModels = (
    row: IRowNode | undefined,
    columns: Column[] | null[] | null,
    EOL: string,
    hideEmptyCells?: boolean
): ModelsBuilderResult => {
    let editorText = '';
    const cellJsonModel: ModelJSONNode[] = [];

    let nextFreeLine = 1;
    columns?.forEach((col, index) => {
        if (!col) {
            return;
        }
        const { field, headerName: maybeHeaderName, cellRendererParams } = col.getColDef();
        if (!field) {
            return;
        }
        const headerName = maybeHeaderName ?? field;
        let val = row?.data[field];
        let jsonNodeVal = null; // As long as the column editorText is not a valid JSON, the editorText is irrelevant
        let isValidDynamic = false;
        let pureStringValue = false;
        if (val === undefined || val === null || val === '') {
            if (hideEmptyCells) {
                return;
            }
            val = '';
        } else if (cellRendererParams && cellRendererParams.columnType === 'dynamic') {
            try {
                jsonNodeVal = val; // might be a valid JSON
                val = JSON.stringify(JSON.parse(val), null, '\t');
                isValidDynamic = true;
            } catch (e) {
                jsonNodeVal = null; // Not a valid JSON, fallback to null
                pureStringValue = true; // use the original editorText
            }
        } else {
            val = val.toString();
        }

        if (columns.length > 1) {
            if (pureStringValue) {
                const linesArr = val.split(NEW_LINE);
                val = linesArr.join(EOL + '\t');
            }

            const node = buildModelJSONNode(val, jsonNodeVal, headerName, nextFreeLine, isValidDynamic);
            cellJsonModel.push(node);
            nextFreeLine = node.end + 1;

            // Don't show comma if it's the last entry in the json
            const showComma = index < columns.length - 1;
            editorText = editorText + buildJsonEntry(headerName, val, EOL, showComma);
        } else {
            if (isValidDynamic) {
                // skip entry building if already a valid JSON
                cellJsonModel.push(buildModelJSONNode(val, jsonNodeVal, headerName, nextFreeLine, isValidDynamic));
            }

            editorText = val;
        }
    });

    return { editorText, cellJsonModel };
};
