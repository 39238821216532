import React, { CSSProperties } from 'react';
import { ProgressBar, Text } from '@fluentui/react-components';
import { tokens } from '@fluentui/react-theme';

import styles from './ProgressValue.module.scss';

export interface ProgressValueProps {
    /** A decimal number between 0 and 1 (or between 0 and `maxValue` if given), which specifies how much of the task has been completed. */
    value: number;
    /** A string to be displayed in the progress bar. If not given, the `value` will be displayed. */
    displayedValue?: string;
    /** The maximum value, which indicates the task is complete. The ProgressBar bar will be full when value equals. Default 1  */
    maxValue?: number;
    /** The color of the progress bar. Theme color is used by Default */
    color?: string;
    /** Custom style for the component */
    style?: CSSProperties;
}

/** Display a progress bar with a value and a label */
export const ProgressValue: React.FunctionComponent<ProgressValueProps> = (props) => {
    const { value, displayedValue, maxValue = 1, color = tokens.colorBrandBackground, style } = props;

    const label = (displayedValue ?? value).toString();

    // start the progress-bar from 5%
    const percentComplete = 0.05 + (value / maxValue) * 0.95;

    return (
        <div className={styles.progressValue}>
            <Text className={styles.label}>{label}</Text>
            <ProgressBar
                value={percentComplete}
                className={styles.progressBar}
                style={{ ...style, ...({ '--progressBarColor': color } as CSSProperties) }}
                shape="rounded"
                thickness="large"
            />
        </div>
    );
};
