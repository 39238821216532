import React from 'react';
import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import { Checkmark20Regular } from '@fluentui/react-icons';

import { SearchBoxDebounce, SearchBoxProps } from '../SearchBoxDebounce';

const useStyles = makeStyles({
    input: {
        ...shorthands.flex('auto'),
        ...shorthands.margin('0px'),
    },

    container: {
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap('8px'),
        ...shorthands.flex('auto'),
    },
});

export type InputAndButtonProp = SearchBoxProps & {
    buttonIcon?: React.JSX.Element;
    onConfirm: () => void;
    placeholder?: string;
    searchString?: string | undefined;
};

export const InputAndButton = React.forwardRef<HTMLInputElement, InputAndButtonProp>(
    ({ buttonIcon, onConfirm, placeholder, searchString, clearIcon, ...props }, ref) => {
        const styles = useStyles();

        const inputProps = { ref, ...props };
        return (
            <div className={styles.container}>
                <SearchBoxDebounce
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    clearIcon={clearIcon}
                    hideContentBefore={true}
                    className={styles.input}
                    value={searchString}
                    placeholder={placeholder}
                    {...inputProps}
                />
                <Button
                    icon={buttonIcon ?? <Checkmark20Regular />}
                    appearance="transparent"
                    onClick={onConfirm}
                    aria-label="Close search box"
                />
            </div>
        );
    }
);
