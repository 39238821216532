.progressValue {
    span.label {
        display: block;
        margin-bottom: var(--spacingVerticalM);
    }

    div.progressBar {
        div {
            // filled section of the progress bar
            background: var(--progressBarColor); // custom variable
        }
    }
}
