import React from 'react';
import { Observer } from 'mobx-react-lite';

import type { KweVisualFwkLocale, KweVisualFwkStringSelector } from '../../types';

export function i18nElement(
    t: () => KweVisualFwkLocale,
    selector: KweVisualFwkStringSelector<React.ReactNode>
): React.ReactElement {
    return <Observer>{() => <>{selector(t())}</>}</Observer>;
}
