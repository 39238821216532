import { formatLiterals, KweException } from '@kusto/utils';
import type { PointOptionsObject } from '@kusto/visualizations';

import type { KweRtdVisualContext } from '../../context';
import type { FunnelDataItemWithRow } from './types';

export function isDataAscending(data: PointOptionsObject<FunnelDataItemWithRow>[]) {
    // Need at least two data points
    // to actually compute the direction
    if (data.length <= 1) {
        return false;
    }

    /**
     * Because the data is unsorted,
     * we can calculate the trend line
     * and see if the first and last point
     * on that trend line is lesser than
     * or greater than one another to
     * determine the direction. Lesser
     * means it's in ascending order.
     * Greater means it's in descending
     * order.
     *
     * @see https://classroom.synonym.com/calculate-trendline-2709.html
     */
    let xySummation = 0;
    let xSummation = 0;
    let ySummation = 0;
    let xSquaredSummation = 0;
    for (let i = 0; i < data.length; i++) {
        const y = data[i].y;
        xySummation += (i + 1) * y; // index + 1 because we are zero-indexed
        xSummation += i + 1; // index + 1 because we are zero-indexed
        ySummation += y;
        xSquaredSummation += (i + 1) ** 2; // index + 1 because we are zero-indexed
    }

    const a = data.length * xySummation;
    const b = xSummation * ySummation;
    const c = data.length * xSquaredSummation;
    const d = xSummation ** 2;

    const m = (a - b) / (c - d);

    const e = ySummation;
    const f = m * xSummation;

    const yIntercept = (e - f) / data.length;

    // mx + b
    const firstTrendPoint = yIntercept; // m * 0 + yIntercept = yIntercept
    const lastTrendPoint = m * data.length + yIntercept;

    if (firstTrendPoint < lastTrendPoint) {
        // this is in ascending order
        return true;
    } else if (firstTrendPoint > lastTrendPoint) {
        // this is in descending order
        return false;
    } else {
        // When equal this is probably a user error
        // with their dataset but let's default
        // to "descending" order since they
        // probably know to adjust their query
        return false;
    }
}

interface CustomPointObject {
    dataItem: PointOptionsObject<FunnelDataItemWithRow>['custom']['dataItem'];
    initialPercentageValue: string;
    initialPercentage: string;
    previousPercentage: string;
    totalPercentage: string;
}

export interface FunnelPointOptionsObject extends Omit<PointOptionsObject<FunnelDataItemWithRow>, 'custom'> {
    custom: CustomPointObject;
}

/**
 * @param pointOptions These should be ordered in descending fashion ahead of time
 */
export function addPercentageToData(
    ctx: KweRtdVisualContext,
    pointOptions: PointOptionsObject<FunnelDataItemWithRow>[],
    isAscending: boolean
): FunnelPointOptionsObject[] {
    const firstPointValue: undefined | number = pointOptions[0]?.y;

    if (firstPointValue === undefined) {
        throw new KweException('Unable to format data when point value(s) are missing');
    }

    const totalY = pointOptions.reduce<number>((total, current) => total + current.y, 0);

    const pointOptionsWithPercentage: FunnelPointOptionsObject[] = pointOptions.map((option, index) => {
        const initialPercentage = ((option.y / firstPointValue) * 100).toFixed(2);

        // The ascending direction influences how we calculate the
        // "previous" value. Either we use the option before or
        // after our current option.
        let prevIndex: number;
        if (isAscending) {
            // we want the option after our current one
            if (index + 1 < pointOptions.length) {
                prevIndex = index + 1;
            } else {
                prevIndex = index;
            }
        } else {
            // we want the option before our current one
            if (index - 1 > 0) {
                prevIndex = index - 1;
            } else {
                prevIndex = 0;
            }
        }
        const prevValue: number = pointOptions[prevIndex].y;
        const previousPercentage = ((option.y / prevValue) * 100).toFixed(2);

        const totalPercentage = ((option.y / totalY) * 100).toFixed(2);

        return {
            ...option,
            custom: {
                ...option.custom,
                initialPercentageValue: `${initialPercentage}%`,
                initialPercentage: formatLiterals(ctx.strings.rtdProvider.visuals.funnel.percentages.initial, {
                    value: initialPercentage,
                }),
                previousPercentage: formatLiterals(ctx.strings.rtdProvider.visuals.funnel.percentages.previous, {
                    value: previousPercentage,
                }),
                totalPercentage: formatLiterals(ctx.strings.rtdProvider.visuals.funnel.percentages.total, {
                    value: totalPercentage,
                }),
            },
        };
    });

    return pointOptionsWithPercentage;
}
