import * as React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import {
    classNamesFunction,
    Icon as FabricIcon,
    FontIcon,
    IStyle,
    IStyleFunctionOrObject,
    ITheme,
    registerIcons,
    styled,
} from '@fluentui/react';
import { Tooltip } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';

import { useQueryCore } from '../../core/core';
import { Action, Icon } from './actions';
import { ConnectionExplorerIcons as Icons } from './icons';
import { TreeViewRow } from './TreeView';

import * as styles from './AgGridCellRenderers.module.scss';

function TextWithLink({ text }: { text: string }): JSX.Element {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Split the text into an array of segments based on the URL regex
    const segments = text.split(urlRegex);

    // Map each segment to either a text node or a link element
    const content = segments.map((segment: string, index: number) => {
        if (segment.match(urlRegex)) {
            // If the segment is a URL, create a link element
            return (
                <a key={index} href={segment} target="_blank" rel="noopener noreferrer">
                    {segment}
                </a>
            );
        } else {
            // If the segment is not a URL, render it as plain text
            return <span key={index}>{segment}</span>;
        }
    });

    return <div className={styles.tooltipContent}>{content}</div>;
}

const ActionIcon = ({
    icon,
    className,
    addTabIndex,
    title,
}: {
    icon: Icon;
    className: string;
    addTabIndex: boolean;
    title?: string;
}) => {
    const jsxElement = <TextWithLink text={title ?? ''} />;
    const [ref, setRef] = React.useState<HTMLDivElement | null>(null);
    return (
        <Tooltip
            content={jsxElement}
            relationship="label"
            hideDelay={500}
            withArrow
            positioning={{
                target: ref,
            }}
        >
            <div ref={setRef}>
                <FontIcon
                    iconName={icon.iconName}
                    className={className}
                    tabIndex={addTabIndex ? 0 : -1}
                    aria-label={icon.ariaLabel}
                />
            </div>
        </Tooltip>
    );
};

/**
 * Register custom icons for icon cell renderer
 */
registerIcons({
    icons: {
        TablesFolder: <Icons.TablesFolderIcon />,
        Cluster: <Icons.ClusterIcon />,
        VirtualClusterIcon: <Icons.VirtualClusterIcon />,
        AffiliatedClusterIcon: <Icons.AffiliatedClusterIcon />,
        AffiliatedVirtualClusterIcon: <Icons.AffiliatedVirtualClusterIcon />,
        Column: <Icons.ColumnIcon />,
        ExternalTable: <Icons.ExternalTableIcon />,
        ExternalTablesFolder: <Icons.ExternalTablesFolderIcon />,
        FollowerDatabase: <Icons.FollowerDatabaseIcon />,
        FunctionsFolder: <Icons.FunctionsFolderIcon />,
        MaterializedViewTableFolder: <Icons.MaterializedViewTableFolderIcon />,
        ReadOnlyDatabase: <Icons.ReadOnlyDatabaseIcon />,
        ViewFunction: <Icons.ViewFunctionIcon />,
        EntityGroupFolder: <Icons.EntityGroupFolderIcon />,
        EntityGroup: <Icons.EntityGroupIcon />,
        EntityGroupMember: <Icons.EntityGroupMemberIcon />,
        SuspendedDatabase: <Icons.SuspendedDatabaseIcon />,
    },
});

// #endregion

// #region TreeViewCellRendererProps

interface TreeViewCellRendererProps extends ICellRendererParams {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<TreeViewCellRendererStyleProps, TreeViewCellRendererStyles>;
}

type TreeViewCellRendererStyleProps = Required<Pick<TreeViewCellRendererProps, 'theme'>>;
interface TreeViewCellRendererStyles {
    root: IStyle;
    fontIcon: IStyle;
    actionIcon: IStyle;
}

const getTreeViewCellRendererStyles = (props: TreeViewCellRendererStyleProps): TreeViewCellRendererStyles => {
    const palette = props.theme.palette;
    return {
        root: {
            color: palette.neutralPrimary,
            display: 'flex',
        },
        fontIcon: {
            color: palette.neutralSecondary,
            fontSize: 16,
        },
        actionIcon: {
            color: palette.neutralSecondary,
        },
    };
};

const getTreeViewCellRendererClassNames = classNamesFunction<
    TreeViewCellRendererStyleProps,
    TreeViewCellRendererStyles
>();

const getMarkedText = (text: string, highlightString: string) => {
    // add capture group to the regex since it causes split to add the matches to the array
    const regex = new RegExp(`(${highlightString})`, 'gi');
    const splitText = text.split(regex);
    const matches = text.match(regex);

    return splitText.map((part, i) => (matches?.includes(part) ? <mark key={i}>{part}</mark> : <>{part}</>));
};

export const TreeViewCellRendererBase: React.FC<TreeViewCellRendererProps> = observer(function TreeViewCellRendererBase(
    props
) {
    const core = useQueryCore();
    const rowData = props.data as TreeViewRow | undefined;

    const classNames = getTreeViewCellRendererClassNames(props.styles!, { theme: props.theme! });

    const icon = rowData?.icon;

    // params.value includes the details to enforce refresh
    // get the actual value from the node (as Ag-Grid gets it)
    const label =
        rowData?.label ??
        // Backwards probably can be removed

        (props.colDef?.colId && (props.node?.groupData?.[props.colDef.colId!] as string | undefined)) ??
        '';
    const details = rowData?.details;

    // If we have a node that doesn't have data, just display the text without any icons.
    // (i.e it is created by ag-grid because it's part of tha real elements' path property)
    if (!rowData || !icon) {
        return <span>{label}</span>;
    }

    let markedLabel: string | JSX.Element[] = label;
    let markedDetails: string | undefined | JSX.Element[] = details;
    // wrap matches with <mark> JSX elements
    const highlightString = props.context && props.context.highlightRegExp;
    if (highlightString) {
        markedLabel = getMarkedText(label, highlightString);
        markedDetails = details ? getMarkedText(details, highlightString) : [];
    }

    const actions = (rowData.actions as Action[]).filter((action) => action);
    const hoverIcons = actions.map((element, i) => (
        <div key={i}>
            <ActionIcon
                icon={element.mainIcon}
                className={`${classNames.actionIcon} actionIconOnHover`}
                addTabIndex={true}
                title={element.textOnHover}
            />
        </div>
    ));
    const nonHoverIcons = actions
        .filter((element) => element.nonHoverIcon)

        .map((element, j) => (
            <div key={j}>
                <ActionIcon icon={element.nonHoverIcon!} className={classNames.actionIcon} addTabIndex={false} />
            </div>
        ));

    const { iconName, isBold, iconType, ariaLabel } = icon;

    return (
        <span className={classNames.root}>
            {/* fontIcon should be more efficient than icon. thus we're using it where we can */}
            {iconName &&
                (iconType === 'custom' ? (
                    <FabricIcon styles={{ root: { fontSize: 16 } }} iconName={iconName} />
                ) : (
                    <FontIcon iconName={iconName} className={classNames.fontIcon} />
                ))}{' '}
            {ariaLabel && <span className="screen-reader-only">{ariaLabel}</span>}
            <span className="row-content">
                <span className="row-content-left">
                    {isBold ? (
                        <span style={{ fontWeight: 600 }} className="row-content-text">
                            {markedLabel}
                        </span>
                    ) : (
                        markedLabel
                    )}
                    {details && <span className="row-content-data-type"> ({markedDetails})</span>}
                </span>
                <span
                    role="button"
                    aria-label={core.strings.query.connectionPaneMoreOptionAriaLabel}
                    style={{ width: 0, overflow: 'hidden' }}
                />
                {/* Disabled while enabling lint rule */}
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <span className="row-content-right" onMouseDown={onMouseDownHandler}>
                    <span className="row-content-action-icons">{hoverIcons}</span>
                    <span className="row-content-status-icon">{nonHoverIcons}</span>
                </span>
            </span>
        </span>
    );
});

const onMouseDownHandler = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    // prevents the blue border around the cell when clicking an action icon.
    e.preventDefault();
};

export const ReactTreeViewCellRenderer = styled(TreeViewCellRendererBase, getTreeViewCellRendererStyles);

// #endregion ReactTreeViewCellRenderer
