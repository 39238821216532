@use '../configurationList/styles.module.scss' as configList;

.formWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--option-spacing);
}

.axisHorizontalLine {
    margin-right: 4;
}

.horizontalLineLabel {
    font-weight: 600;
    padding: 0;
    // Top and bottom have 3 subtracted to compensate for light height, and bottom subtracts the flexbox gap
    margin: -3px 0 calc(-3px - var(--option-spacing)) 0;
    font-size: 14px;
    line-height: 20px;
}

.addYAxisButton {
    align-self: end;
}

.addReferenceLineButton {
    margin-bottom: calc(-1 * configList.$button-total-vertical-padding);
}
