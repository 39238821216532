import type { QueryStrings } from '../core/strings';

export enum LoadAssessment {
    Low,
    Medium,
    High,
}

/**
 * Assesses an input number as Low, Medium or High.
 * @param input the number to assess
 * @param mediumLimit If the input number is between 'mediumLimit' and 'highLimit', 'assess' will return "medium"
 * @param highLimit   If the input number is higher or equal to 'highLimit', 'assess' will return "medium"
 */
export const assess = (strings: QueryStrings, input: number, mediumLimit: number, highLimit: number) => {
    let loadAssessment: LoadAssessment;
    if (input < mediumLimit) {
        loadAssessment = LoadAssessment.Low;
    } else if (input < highLimit) {
        loadAssessment = LoadAssessment.Medium;
    } else {
        loadAssessment = LoadAssessment.High;
    }

    return localizeLoadAssessment(strings, loadAssessment);
};

/**
 * Returns load assessment ("Low", "Medium", "High") as a translated string.
 */
export const localizeLoadAssessment = (strings: QueryStrings, loadAssessment: LoadAssessment) => {
    switch (loadAssessment) {
        case LoadAssessment.Low:
            return strings.loadAssessmentLow;
        case LoadAssessment.Medium:
            return strings.loadAssessmentMedium;
        case LoadAssessment.High:
            return strings.loadAssessmentHigh;
    }
};
