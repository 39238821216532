import * as React from 'react';
import * as ReactDOM from 'react-dom';

import type { IKweTelemetry } from '@kusto/utils';

function renderApp(uuid: string) {
    ReactDOM.render(<div>Blocked in iframe ({uuid})</div>, document.getElementById('root') as HTMLElement);
}
/**
 * Block loading of the site in iFrame when IframeAuthenticator flags are not set
 * (featureFlags.ibizaPortal || featureFlags.IFrameAuth)
 *
 * Security case 77659 - 'dataexplorer.azure.com - Kusto Web Explorer Cross Frame Scripting'
 *
 * @returns true if app should be blocked
 */
export function IframeProtection(telemetry: IKweTelemetry): boolean {
    if (window.parent !== window) {
        const uuid = crypto.randomUUID();
        telemetry.info('pageLoad.blockingIframe', {
            href: document.location.href,
            referrer: document.referrer,
            uuid,
        });
        renderApp(uuid);
        return true;
    }
    return false;
}
