import React, { CSSProperties, HTMLAttributeAnchorTarget } from 'react';
import { Link } from '@fluentui/react-components';
import { Open16Regular } from '@fluentui/react-icons';
import { tokens } from '@fluentui/react-theme';

export interface LinkUrlProps {
    /** The URL to link to. */
    url: string;
    /** The text to display for the link. If not provided, the URL will be used. */
    text?: string;
    /** The target for the link. Defaults to '_blank' (new tab). */
    target?: HTMLAttributeAnchorTarget;
    /** Whether to show the new tab icon. Default is false. */
    showNewTabIcon?: boolean;
    /** Custom style for the link text like underline. */
    textStyle?: CSSProperties;
}

/** A wrapper to fluent's Link component. */
export const LinkUrl: React.FunctionComponent<LinkUrlProps> = ({
    url,
    text,
    target = '_blank',
    showNewTabIcon = false,
    textStyle = {},
}) => (
    <Link target={target} href={url}>
        <div style={{ display: 'flex' }}>
            <div style={{ ...textStyle }}>{text ?? url}</div>
            <div style={{ marginLeft: tokens.spacingHorizontalS }}>{showNewTabIcon && <Open16Regular />}</div>
        </div>
    </Link>
);
