import * as React from 'react';
const noop = ()=>undefined;
const activeDescendantContextDefaultValue = {
    controller: {
        active: noop,
        blur: noop,
        find: noop,
        first: noop,
        focus: noop,
        focusLastActive: noop,
        scrollActiveIntoView: noop,
        last: noop,
        next: noop,
        prev: noop,
        showAttributes: noop,
        hideAttributes: noop,
        showFocusVisibleAttributes: noop,
        hideFocusVisibleAttributes: noop
    }
};
const ActiveDescendantContext = React.createContext(undefined);
export const ActiveDescendantContextProvider = ActiveDescendantContext.Provider;
export const useActiveDescendantContext = ()=>{
    var _React_useContext;
    return (_React_useContext = React.useContext(ActiveDescendantContext)) !== null && _React_useContext !== void 0 ? _React_useContext : activeDescendantContextDefaultValue;
};
export const useHasParentActiveDescendantContext = ()=>!!React.useContext(ActiveDescendantContext);
