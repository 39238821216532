import type { FeatureFlagsSetting } from '@kusto/app-common';
import type { DashboardVersion, IManualKustoDataSource, MEMORY_DASHBOARD_VERSION } from '@kusto/dashboard';
import type { ReadonlyRecord } from '@kusto/utils';

import type { FeatureFlag, FeatureFlagMap } from '../../core/FeatureFlags';
import type { KweEnv } from './env';
import * as staticConfig from './staticConfig';

const tenants = {
    multiTenant: 'organizations',
    microsoft: '72f988bf-86f1-41af-91ab-2d7cd011db47',
    /**
     * ### Public Management Environment
     * A Microsoft production identity environment that provides access to
     * online services outside of the Azure Management Environment scope.
     */
    pme: '975f013f-7f24-47e8-a7d3-abc4752bf346',
    /**
     * ubs.com
     */
    ubs: 'fb6ea403-7cf1-4905-810a-fe5547e98204',
    torus: 'cdc5aeea-15c5-4db6-b079-fcadd2505dc2',
    exxonmobil: 'd1ee1acd-bc7a-4bc4-a787-938c49a83906',
    docusign: '237e701c-327f-4cad-a5a1-dda2412d89d9',
    bmw: 'ce849bab-cc1c-465b-b62e-18f07c9ac198',
};

export const domains = {
    localhost: 'localhost:3000',
    preview: 'preview.dataexplorer.azure.com',
    prod: 'dataexplorer.azure.com',
};

const tenantFeatureFlags: ReadonlyRecord<string, FeatureFlagsSetting<FeatureFlag>> = {
    [tenants.ubs]: { KeepOnlyOpenInExcelOption: true },
    [tenants.microsoft]: { EnableDashboardsDevMenu: true },
    [tenants.pme]: { EnableDashboardsDevMenu: true },
    [tenants.torus]: { EnableDashboardsDevMenu: true },
    // exxonmobil had external security review demanding that tokens be stored in session storage
    // https://portal.microsofticm.com/imp/v3/incidents/details/202343369/home
    [tenants.exxonmobil]: { UseLocalStorageForTokens: false },
    [tenants.docusign]: { EnableQueryParameterLogging: true },
    [tenants.bmw]: { EnableMarkdownImageOverflow: true },
};

export const KustoRequestAppName = 'KusWeb';

/**
 * @see {@link import('./env').KweNodeEnv}
 */
export let KWE_ENV: KweEnv;

if (process.env.NODE_ENV !== 'test') {
    KWE_ENV = JSON.parse(process.env.KWE_ENV!);
} else {
    KWE_ENV = {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        ...require('../../../config/env').test,
        appVersion: 'testAppVersion',
        buildId: 'testBuildId',
        environment: 'test',
        azureMapToken: undefined,
        publicUrl: '/',
    };
}

const domainFeatureFlags: ReadonlyRecord<string, FeatureFlagMap> = {
    [domains.localhost]: {
        DataProfilePinned: true,
        exportAsVersion: true,
        newRtdShareMenu: true,
        TenantAdminSettings: true,
        EnableConnectionPaneGroups: true,
        'tile-menu--copy-as-dev-json': true,
    },
    [domains.preview]: {
        DisableTelemetryPiiRedactor: false,
        exportAsVersion: true,
        TenantAdminSettings: true,
        EnableConnectionPaneGroups: true,
    },
};

/**
 * Feature flags by domain to override user specific flags when needed
 */
const forcedFeatureFlags: ReadonlyRecord<string, FeatureFlagMap> = {};

const MANUAL_KUSTO_SCOPE_ID: IManualKustoDataSource['scopeId'] = 'kusto';

/**
 * Not using MEMORY_DASHBOARD_VERSION from @kusto/dashboard to avoid the import
 */
const memoryDashboardVersion: typeof MEMORY_DASHBOARD_VERSION = '52';

export const KWE_CONSTANTS = {
    /**
     * For use with @kusto/utils's ExceptionBoundary
     */
    dashboardsSupportEmail: 'adxdbdsup@microsoft.com',
    /**
     * For use with @kusto/utils's ExceptionBoundary
     */
    supportEmail: 'KustoWebExpFeedback@service.microsoft.com',
    /**
     * Includes items that the app users in the url's search. No page should use
     * query items that match, or start with, anything. Check to make sure no
     * page has conflicts before adding to this.
     *
     * Changes to our url's schema, which includes these, should first be done
     * in a backwards compatible way, and then finished when telemetry tells us
     * it is safe.
     */
    globalReservedSearchItems: {
        featureFlagPrefix: 'f-',
        settings: [
            'hosting',
            'theme',
            'appPage',
            'language',
            'tenant',
            'login_hint',
            'SessionId',
            'allowedDomainRegex',
        ],
    },
    tenants,
    tenantFeatureFlags,
    fluent8IconsNamespace: staticConfig.fluent8IconsNamespace,
    dashboards: {
        /**
         * Referenced inside of dashboard json data sources, so cannot be changed.
         */
        manualKustoScopeId: MANUAL_KUSTO_SCOPE_ID,
        prefixClientRequestId: 'Dashboards',
        controlCommandAppName: KustoRequestAppName,
        publicSchemaFolder: staticConfig.dashboardPublicSchemaFolder,
    },
    domainFeatureFlags,
    forcedFeatureFlags,
    survey: {
        name: 'azuredataexplorer-nps',
        teamName: 'azuredataexplorer',
        eventName: 'string',
    },
    feedback: {
        addFeatureLink: 'https://aka.ms/adx.ideas',
    },
    themes: {
        lightClassName: 'kweThemeLight',
        darkClassName: 'kweThemeDark',
    },
    appName: 'Kusto Web Explorer',
    whatIsNewUrl: 'https://go.microsoft.com/fwlink/?linkid=2212256',
    diskDashboardVersion:
        process.env.NODE_ENV === 'development' ? memoryDashboardVersion : ('52' satisfies DashboardVersion),
    localStorageKeys: {
        dashboardsDebugFlags: 'debugFlags',
        dashboardsFeatureFlagOverride: 'dashboards--feature-flag-override',
    },
} as const;
