import sortBy from 'lodash/sortBy';

import { ArgumentColumnType, DataItem } from '@kusto/charting';

import { formatMillisecondsToTimeString } from '../utils/charting';
import { PointOptionsObject } from './types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DataFromDataItems {
    export type TDataItemProperties =
        | 'ArgumentDateTime'
        | 'ValueData'
        | 'ArgumentNumeric'
        | 'ArgumentData'
        | 'SeriesName';

    export type TDataItem = Pick<DataItem, TDataItemProperties>;

    export interface ReturnType<D extends TDataItem> {
        pointOptions: readonly PointOptionsObject<D>[];
        groupedBySeries: Record<string, PointOptionsObject<D>[]>;
        seriesNames: string[];
    }
}

export function getDataFromDataItems<D extends DataFromDataItems.TDataItem>(
    argumentType: ArgumentColumnType,
    dataItems: ReadonlyArray<D>
): DataFromDataItems.ReturnType<D> {
    type ReturnData = DataFromDataItems.ReturnType<D>;

    // Highcharts likes its data grouped by series rather than a flat array of data points.
    // We also take this opportunity to figure out which Argument variable to access  based on chart / data type.
    const pointOptions: ReturnData['pointOptions'] = dataItems.map((dataItem) => {
        let x: undefined | number;
        let y: number;
        let name: undefined | string;
        switch (argumentType) {
            case ArgumentColumnType.TimeSpan:
                // Added while enabling lints
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const milliseconds = (dataItem.ArgumentDateTime as any).ticks.div(1e4).toNumber();
                const timeString = formatMillisecondsToTimeString(milliseconds);
                name = timeString;
                y = dataItem.ValueData;
                break;
            case ArgumentColumnType.DateTime:
                // Added while enabling lints
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                x = (dataItem.ArgumentDateTime as any).getTime();
                y = dataItem.ValueData;
                break;
            case ArgumentColumnType.Numeric:
                x = dataItem.ArgumentNumeric;
                y = dataItem.ValueData;
                break;
            default:
                y = dataItem.ValueData;
                name = dataItem.ArgumentData ?? '';
        }

        return { x, name, y, custom: { dataItem } };
    });

    const groupedBySeries = sortBy(
        pointOptions,
        (pointOption: PointOptionsObject<D>) => pointOption.custom.dataItem.SeriesName
    ).reduce<ReturnData['groupedBySeries']>((prev, pointOption) => {
        const seriesName = pointOption.custom.dataItem.SeriesName ?? '';
        let seriesList = prev[seriesName];
        if (seriesList === undefined) {
            seriesList = [];
            prev[seriesName] = seriesList;
        }

        seriesList.push(pointOption);

        return prev;
    }, {});

    return {
        pointOptions,
        groupedBySeries,
        seriesNames: Object.keys(groupedBySeries),
    };
}
