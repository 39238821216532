import { createContext, useContextSelector } from '@fluentui/react-context-selector';
const tabListContextDefaultValue = {
    appearance: 'transparent',
    reserveSelectedTabSpace: true,
    selectTabOnFocus: false,
    disabled: false,
    selectedValue: undefined,
    onRegister: ()=>{
    /* noop */ },
    onUnregister: ()=>{
    /* noop */ },
    onSelect: ()=>{
    /* noop */ },
    getRegisteredTabs: ()=>{
        return {
            registeredTabs: {}
        };
    },
    size: 'medium',
    vertical: false
};
export const TabListContext = createContext(undefined);
export const TabListProvider = TabListContext.Provider;
export const useTabListContext_unstable = (selector)=>useContextSelector(TabListContext, (ctx = tabListContextDefaultValue)=>selector(ctx));
