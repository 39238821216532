// We define the dialog sizes according to the screen size; with 'media-queries'.

// Large screen (desktops)
@media (min-width: 1024px) {
    div:has(> .dialog) {
        --backdrop-padding-horizontal: 90px;
        --backdrop-padding-vertical: 50px;
        --dialog-padding: 0 var(--spacingHorizontalXXL) var(--spacingVerticalXXL);
        --header-height: 80px;
        --header-padding: var(--spacingVerticalL) 0;
    }

    // Extra Large screen (wide screen)
    @media (min-width: 1600px) {
        div:has(> .dialog) {
            --backdrop-padding-horizontal: 126px;
            --backdrop-padding-vertical: 70px;
        }
    }
}

// Medium screen (tablets/laptops)
@media (min-width: 480px) and (max-width: 1024px) {
    div:has(> .dialog) {
        --backdrop-padding-horizontal: 10px;
        --backdrop-padding-vertical: 10px;
        --dialog-padding: 0 var(--spacingHorizontalXL) var(--spacingVerticalXL);
        --header-height: 60px;
        --header-padding: var(--spacingVerticalXXS) 0;
    }
}

// Small screen (phones)
@media (max-width: 480px) {
    div:has(> .dialog) {
        --backdrop-padding-horizontal: 0;
        --backdrop-padding-vertical: 0;
        --dialog-padding: 0;
        --header-height: 60px;
        --header-padding: var(--spacingVerticalXXS) var(--spacingHorizontalM);
    }
}

.dialog {
    // override default dialog styles
    background: var(--colorNeutralBackground3) !important;
    max-width: unset !important;
    // Can't use padding, we don't control the parent container.
    // Instead, we remove the padding size from the dialog width/height.
    width: calc(100vw - (2 * var(--backdrop-padding-horizontal))) !important;
    height: calc(100vh - (2 * var(--backdrop-padding-vertical))) !important;
    padding: var(--dialog-padding);

    .dialogContent {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: unset;
        gap: 0;

        .closeButton {
            position: absolute;
            right: var(--spacingHorizontalM);
            top: var(--spacingVerticalM);
        }

        .dialogHeader {
            display: flex;
            flex-direction: column;
            gap: var(--spacingVerticalXXS);
            height: var(--header-height);
            padding: var(--header-padding);
            box-sizing: border-box;

            .subTitle {
                color: var(--colorNeutralForeground3);
            }
        }

        .dialogBody {
            height: 100%;
            overflow: hidden;
        }
    }
}
