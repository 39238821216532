@use '@kusto/style/tokens';

.minMaxContainer {
    display: flex;
    gap: tokens.$spacingHorizontalL;
}

.minMax {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.cardinalityContainer {
    display: flex;
    align-items: center;
    gap: tokens.$spacingHorizontalXS;
}

.textCaption1 {
    color: tokens.$colorNeutralForeground4;
}

button:hover .textCaption1 {
    color: tokens.$colorNeutralForeground3BrandHover;
}
