import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Portal } from '@fluentui/react-components';
import * as clipboard from 'clipboard-polyfill';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

import 'monaco-editor/esm/vs/language/json/monaco.contribution';

import ReactMonacoEditor from 'react-monaco-editor';
import Pane from 'react-split-pane/lib/Pane';
import SplitPane from 'react-split-pane/lib/SplitPane';

import { Theme } from '@kusto/utils';

import { buildJsonPathForEachLine, convertJPathArrayRepresentationToString, JsonModelData } from '../jpath';
import { buildEditorAndJsonModels, getJsonStringAndRelativeLineNumber } from '../jpath/editorUtils';
import { KweAgGridLocale } from '../locale';
import { ExpandTopBar } from './ExpandTopBar';
import { ContentViewModeType, DetailsViewType, EXPAND_ROW_COL_ID } from './GridWithExpand';

import styles from './styles.module.scss';

const MONACO_ACTION_ID_PREFIX = 'editor.action.detailsView.';
const MONACO_INITIAL_CURSOR_STATE: monaco.editor.ICursorState = {
    inSelectionMode: false,
    position: { lineNumber: 1, column: 1 },
    selectionStart: { lineNumber: 1, column: 1 },
};

interface ExpandViewRendererProps extends ICellRendererParams {
    lastExpandedCell: { rowId: string | null; colId: string };
    persistedEditorState: React.MutableRefObject<monaco.editor.ICodeEditorViewState | null>;
    expandType: DetailsViewType;
    onExpandTypeChange?: (expandType: DetailsViewType) => void;
    belowGridContainer: HTMLElement | null;
    externalViewContainer: HTMLElement | null;
    contentViewModeType: ContentViewModeType;
    mouseWheelZoom: boolean;
    locale: KweAgGridLocale;
    theme: Theme;
    hideEmptyCells?: boolean;
    renderExpandBarItems?: (isCompactMode?: boolean) => JSX.Element;
    disableJPath?: boolean;
    onRequestPaste?: (content: string) => void;
    getExpandRowHeight(): number;
    onExpandResize(newHeight: number): void;
}

const getMonacoEditorOptions = (
    contentViewModeType: ContentViewModeType,
    mouseWheelZoom: boolean
): monaco.editor.IEditorOptions => ({
    formatOnType: true,
    folding: true,
    links: true,
    hover: { enabled: false },
    automaticLayout: true,
    minimap: { enabled: false },
    showFoldingControls: 'always',
    readOnly: true,
    wordWrap: contentViewModeType === ContentViewModeType.Compact ? 'off' : 'on',
    wrappingIndent: 'deepIndent',
    renderLineHighlight: 'none',
    selectionHighlight: false,
    scrollBeyondLastLine: false,
    foldingStrategy: 'auto',
    mouseWheelZoom,
    disableLayerHinting: true,
});

const useEditorDidMount = ({
    data,
    locale,
    api,
    lastExpandedCell,
    onExpandTypeChange,
    node,
    columnApi,
    hideEmptyCells,
    persistedEditorState,
    onRequestPaste,
    disableJPath,
    mountNode,
}: Pick<
    ExpandViewRendererProps,
    | 'data'
    | 'locale'
    | 'api'
    | 'lastExpandedCell'
    | 'onExpandTypeChange'
    | 'node'
    | 'columnApi'
    | 'hideEmptyCells'
    | 'persistedEditorState'
    | 'onRequestPaste'
    | 'disableJPath'
> & { mountNode: HTMLElement | null }) => {
    const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
    const [eol, setEol] = React.useState<string>('');
    const [jsonModelData, setJsonModelData] = React.useState<JsonModelData | undefined>();

    const { editorText, cellJsonModel } = React.useMemo(() => {
        const columns =
            lastExpandedCell.colId === EXPAND_ROW_COL_ID
                ? columnApi.getColumns()
                : [columnApi.getColumn(lastExpandedCell.colId)!];

        return buildEditorAndJsonModels(node, columns, eol, hideEmptyCells);
    }, [columnApi, eol, hideEmptyCells, lastExpandedCell.colId, node]);

    const updateJsonPath = React.useCallback(() => {
        const jsonAndLineNumber = getJsonStringAndRelativeLineNumber(
            cellJsonModel,
            editorRef.current?.getPosition()?.lineNumber ?? 1
        );

        if (jsonAndLineNumber) {
            setJsonModelData(jsonAndLineNumber);
        }

        const viewState = editorRef.current?.saveViewState();
        if (viewState) {
            persistedEditorState.current = viewState;
        }
    }, [cellJsonModel, persistedEditorState]);

    const getPathValue = React.useCallback(() => {
        if (lastExpandedCell.colId) {
            const jsonAndLineNumber = getJsonStringAndRelativeLineNumber(
                cellJsonModel,
                editorRef.current?.getPosition()?.lineNumber ?? 1
            );
            if (jsonAndLineNumber) {
                const { rawString, lineNumber } = jsonAndLineNumber;
                const jsonPathMap = buildJsonPathForEachLine(rawString);
                const pathToValue = jsonPathMap[lineNumber];
                pathToValue.shift(); // Get path to value starting from index 0

                let cellValue = jsonAndLineNumber.rawString;
                if (lastExpandedCell.colId === EXPAND_ROW_COL_ID) {
                    // Row expanded, get the cell value from the corresponding column

                    cellValue = data[pathToValue[0]];
                    pathToValue.shift(); // Remove column name from the path
                }

                try {
                    if (pathToValue.length) {
                        // Valid dynamic value - traverse to get the value
                        let nestedValue = JSON.parse(cellValue);
                        pathToValue.forEach((item) => (nestedValue = nestedValue[item]));
                        return nestedValue;
                    } else {
                        return JSON.parse(cellValue); // try to return the cell content under a dynamic column;
                    }
                } catch (_e) {
                    return cellValue; // not a dynamic column, return raw content
                }
            }

            return data[lastExpandedCell.colId]; // Non-dynamic data from cell
        }
        return '';
    }, [cellJsonModel, data, lastExpandedCell.colId]);

    const getPathAsString = React.useCallback(() => {
        const jsonAndLineNumber = getJsonStringAndRelativeLineNumber(
            cellJsonModel,
            editorRef.current?.getPosition()?.lineNumber ?? 1
        );
        if (jsonAndLineNumber) {
            const { rawString, lineNumber } = jsonAndLineNumber;
            const jsonPathMap = buildJsonPathForEachLine(rawString);
            return convertJPathArrayRepresentationToString(jsonPathMap[lineNumber]);
        }
        return lastExpandedCell.colId;
    }, [cellJsonModel, lastExpandedCell.colId]);

    const copyPath = React.useCallback(() => {
        const dt = new clipboard.DT();
        dt.setData('text/plain', getPathAsString());
        clipboard.write(dt);
    }, [getPathAsString]);

    const copyValue = React.useCallback(() => {
        const dt = new clipboard.DT();
        const pathValue = getPathValue();
        dt.setData('text/plain', typeof pathValue === 'object' ? JSON.stringify(pathValue) : pathValue);
        clipboard.write(dt);
    }, [getPathValue]);

    const addPathAsFilter = React.useCallback(() => {
        const path = getPathAsString();
        const pathValue = getPathValue();

        onRequestPaste!(
            `\n| where ${path} == ${
                typeof pathValue === 'object' ? `${JSON.stringify(JSON.stringify(pathValue))}` : `"${pathValue}"`
            }`
        ); // Parsing the object twice for escaping
    }, [getPathAsString, getPathValue, onRequestPaste]);

    const editorDidMount = React.useCallback(
        (editor: monaco.editor.IStandaloneCodeEditor) => {
            editorRef.current = editor;

            editor.addAction({
                label: locale.agGrid.expand.search,
                id: MONACO_ACTION_ID_PREFIX + 'search',
                keybindings: [monaco.KeyCode.KeyF | monaco.KeyMod.CtrlCmd],
                run: () => editor.getAction('actions.find')?.run(),
                contextMenuGroupId: 'navigation',
                contextMenuOrder: 1,
            });
            editor.addAction({
                label: locale.agGrid.expand.closeDetailsView,
                keybindings: [monaco.KeyCode.Escape],
                id: MONACO_ACTION_ID_PREFIX + 'close',
                run: () => {
                    const rowToClose = api.getRowNode(lastExpandedCell.rowId!);
                    if (rowToClose?.rowIndex) {
                        rowToClose.setExpanded(false);
                        api.setFocusedCell(rowToClose.rowIndex, lastExpandedCell.colId);
                    }
                },
                precondition: '!findWidgetVisible',
                contextMenuGroupId: 'navigation',
                contextMenuOrder: 2,
            });
            editor.addAction({
                label: locale.agGrid.expand.backToTable,
                id: MONACO_ACTION_ID_PREFIX + 'backToGrid',
                keybindings: [monaco.KeyCode.Tab, monaco.KeyCode.Tab | monaco.KeyMod.Shift],
                run: () => {
                    const firstExpandBarButton = mountNode?.querySelector('button');
                    if (firstExpandBarButton) {
                        firstExpandBarButton.focus();
                    } else {
                        const rowToFocus = api.getRowNode(lastExpandedCell.rowId!);
                        if (rowToFocus?.rowIndex) {
                            api.setFocusedCell(rowToFocus.rowIndex, lastExpandedCell.colId);
                        }
                    }
                },
                contextMenuGroupId: 'navigation',
                contextMenuOrder: 3,
            });

            if (!disableJPath) {
                editor.addAction({
                    label: locale.agGrid.jpath.copyValue,
                    id: MONACO_ACTION_ID_PREFIX + 'copyValue',
                    run: () => copyValue(),
                    contextMenuGroupId: '9_cutcopypaste',
                    contextMenuOrder: 50,
                });
                editor.addAction({
                    label: locale.agGrid.jpath.copyPath,
                    id: MONACO_ACTION_ID_PREFIX + 'copyPath',
                    run: () => copyPath(),
                    contextMenuGroupId: '9_cutcopypaste',
                    contextMenuOrder: 51,
                });
                editor.addAction({
                    label: locale.agGrid.jpath.addAsFilter,
                    id: MONACO_ACTION_ID_PREFIX + 'addAsFilter',
                    keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.Space],
                    run: () => addPathAsFilter(),
                    contextMenuGroupId: '9_cutcopypaste',
                    contextMenuOrder: 52,
                });
            }

            if (onExpandTypeChange) {
                editor.addAction({
                    label: 'Details view in table',
                    id: MONACO_ACTION_ID_PREFIX + 'inGrid',
                    keybindings: [monaco.KeyCode.Digit1 | monaco.KeyMod.Alt],
                    run: () => onExpandTypeChange(DetailsViewType.InGrid),
                    contextMenuGroupId: 'navigation2',
                    contextMenuOrder: 100,
                });
                editor.addAction({
                    label: 'Details view below table',
                    id: MONACO_ACTION_ID_PREFIX + 'belowGrid',
                    keybindings: [monaco.KeyCode.Digit2 | monaco.KeyMod.Alt],
                    run: () => onExpandTypeChange(DetailsViewType.BelowGrid),
                    contextMenuGroupId: 'navigation2',
                    contextMenuOrder: 101,
                });
                editor.addAction({
                    label: 'Details view in external Panel',
                    id: MONACO_ACTION_ID_PREFIX + 'external',
                    keybindings: [monaco.KeyCode.Digit3 | monaco.KeyMod.Alt],
                    run: () => onExpandTypeChange(DetailsViewType.ExternalPanel),
                    contextMenuGroupId: 'navigation2',
                    contextMenuOrder: 102,
                });
            }

            editor.onDidChangeCursorPosition(updateJsonPath);

            if (persistedEditorState.current) {
                editor.restoreViewState(persistedEditorState.current);
            } else {
                const viewState = editor.saveViewState();
                if (viewState) {
                    viewState.cursorState = [MONACO_INITIAL_CURSOR_STATE];
                    editor.restoreViewState(viewState);
                }
            }
            updateJsonPath();
            setEol(editor.getModel()?.getEOL() ?? '');
        },
        [
            addPathAsFilter,
            api,
            copyPath,
            copyValue,
            disableJPath,
            lastExpandedCell.colId,
            lastExpandedCell.rowId,
            locale.agGrid.expand.backToTable,
            locale.agGrid.expand.closeDetailsView,
            locale.agGrid.expand.search,
            locale.agGrid.jpath.addAsFilter,
            locale.agGrid.jpath.copyPath,
            locale.agGrid.jpath.copyValue,
            mountNode,
            onExpandTypeChange,
            persistedEditorState,
            updateJsonPath,
        ]
    );
    return { editorDidMount, editorRef, editorText, jsonModelData };
};

export const ExpandViewRenderer: React.FC<ExpandViewRendererProps> = ({
    data,
    node,
    api,
    columnApi,
    eParentOfValue,
    theme,
    lastExpandedCell,
    contentViewModeType,
    mouseWheelZoom,
    locale,
    expandType,
    onExpandTypeChange,
    belowGridContainer,
    externalViewContainer,
    hideEmptyCells,
    persistedEditorState,
    renderExpandBarItems = () => <></>,
    onRequestPaste,
    disableJPath,
    getExpandRowHeight,
    onExpandResize,
}) => {
    const [inGridElement, setInGridElement] = React.useState<HTMLDivElement | null>(null);

    const mountNode = {
        [DetailsViewType.BelowGrid]: belowGridContainer,
        [DetailsViewType.InGrid]: inGridElement,
        [DetailsViewType.ExternalPanel]: externalViewContainer,
    }[expandType];

    const { editorDidMount, editorRef, editorText, jsonModelData } = useEditorDidMount({
        data,
        locale,
        api,
        lastExpandedCell,
        onExpandTypeChange,
        node,
        columnApi,
        hideEmptyCells,
        persistedEditorState,
        onRequestPaste,
        disableJPath,
        mountNode,
    });

    const onParentElFocus = React.useCallback(() => {
        editorRef.current?.focus();
    }, [editorRef]);

    const focusOnParentCell = React.useCallback(() => {
        const rowToFocus = api.getRowNode(lastExpandedCell.rowId!);
        if (rowToFocus?.rowIndex) {
            api.setFocusedCell(rowToFocus.rowIndex, lastExpandedCell.colId);
        }
    }, [api, lastExpandedCell.colId, lastExpandedCell.rowId]);

    React.useEffect(() => {
        eParentOfValue.addEventListener('focus', onParentElFocus);

        return () => {
            eParentOfValue.removeEventListener('focus', onParentElFocus);
        };
    }, [eParentOfValue, onParentElFocus]);

    const themeName = theme ? (theme === Theme.Light ? 'kusto-light' : 'kusto-dark') : undefined;

    const isInGridExpand = expandType === DetailsViewType.InGrid;
    const maxHeight = DetailsViewType.ExternalPanel !== expandType ? '10000px' : undefined;

    const showJPath = !disableJPath && jsonModelData;

    return (
        <div
            data-testid="expand-row-content"
            style={{ height: isInGridExpand ? '100vh' : '100%', display: mountNode ? undefined : 'none' }}
            onContextMenu={(e) => {
                // don't show AG grid context menu in expand view
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <SplitPane
                split="horizontal"
                className={`${styles.splitPane} ${isInGridExpand ? styles.resizeEnabled : ''}`}
                allowResize={isInGridExpand}
                onChange={(sizes) => {
                    if (isInGridExpand) {
                        const height = Number.parseFloat(sizes[0].replace('px', ''));
                        node.setRowHeight(height);
                        onExpandResize(height);
                    }
                }}
            >
                <Pane size={isInGridExpand ? getExpandRowHeight() + 'px' : '100%'} maxSize={maxHeight} minSize="10px">
                    <div ref={setInGridElement} style={{ height: '100%' }} />
                </Pane>
                <div />
            </SplitPane>
            <Portal mountNode={mountNode}>
                {showJPath ? (
                    <ExpandTopBar
                        jsonModelData={jsonModelData}
                        locale={locale}
                        renderExpandBarItems={renderExpandBarItems}
                        styles={expandType === DetailsViewType.ExternalPanel ? { paddingRight: '44px' } : {}}
                        onTabPressOnLastBarItem={focusOnParentCell}
                    />
                ) : null}
                <div style={{ height: showJPath ? 'calc(100% - 60px)' : 'inherit' }}>
                    <ReactMonacoEditor
                        language="json"
                        options={getMonacoEditorOptions(contentViewModeType, mouseWheelZoom)}
                        value={editorText}
                        editorDidMount={editorDidMount}
                        theme={themeName}
                        tab-index="0"
                    />
                </div>
            </Portal>
        </div>
    );
};
