import * as React from 'react';
import { Announced, Icon, Text } from '@fluentui/react';
import type atlas from 'azure-maps-control';
import classNames from 'classnames';

import { formatLiterals } from '@kusto/utils';

import type { VisualizationsStrings } from '../types';
import type { FeatureProperties } from './utils';

import * as styles from './map.module.scss';

export interface MapTooltipProps extends Pick<FeatureProperties, 'value' | 'argument' | 'color'> {
    strings: VisualizationsStrings;
    position?: atlas.data.Position;
    isDarkTheme?: boolean;
}

export const MapTooltip: React.FC<MapTooltipProps> = ({
    strings,
    position,
    value = null,
    argument,
    color,
    isDarkTheme,
}) => {
    const coordinates = position ? `[${position[0]}, ${position[1]}]` : '';

    /**
     * We have to announce the a11y message for Screen Readers because when tabbing in to focus
     * a map layer, azure-maps doesn't try to add an aria-describedby attribute so a customer
     * wouldn't understand what exactly they are focusing on besides the current index which is
     * not very helpful.
     */
    const a11yMessage = formatLiterals(strings.maps.tooltipContentA11yMsg, {
        coordinates,
        argument: argument ?? strings.maps.emptyContent,
        value: value?.toLocaleString() ?? strings.maps.emptyContent,
    });

    return (
        <>
            <Announced message={a11yMessage} />
            <div className={classNames(styles.mapTooltipWrapper, { [styles.darkTheme]: isDarkTheme })}>
                <Text className={styles.coordinates}>{coordinates}</Text>
                {argument !== undefined && (
                    <>
                        <Icon iconName="CircleFill" className={styles.circle} style={{ color }} />
                        <Text className={styles.argument}>{argument}</Text>
                    </>
                )}
                {value !== null && <Text className={styles.value}>{value}</Text>}
            </div>
        </>
    );
};
