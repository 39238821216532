import React from 'react';
import { usePrevious } from '@fluentui/react-hooks';
import classNames from 'classnames';
import groupBy from 'lodash/groupBy';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import { KweException } from '@kusto/utils';
import type { IDataVisualProps } from '@kusto/visual-fwk';

import type { KweRtdVisualContext } from '../../../context';
import type { RtdProviderLocale, RtdProviderStrings } from '../../../i18n';
import { PLOTLY_MSG_TYPE, PlotlyPacket } from '../messaging/packet';
import { listenOnPort, sendUpdatePlotly } from '../messaging/parent';
import type { SupportedPlotlyVersion } from '../versions';
import { InlinePrompt, InlinePromptProps } from './InlinePrompt';
import { createSrcDoc, getFormattedDLC } from './lib';
import { AddKind, useOriginAllowlist } from './useOriginAllowlist';

import * as styles from './styles.module.scss';

export type ViolatedUrls = Array<[origin: string, urls: string[]]>;
type PlotlyErrorKey = keyof Pick<
    RtdProviderStrings['visuals']['plotly']['errors'],
    'newPlot' | 'missingSetup' | 'unexpectedError' | 'jsonParse' | 'updatePlot'
>;

interface PlotlyIframeErr {
    kind: 'critical' | 'error';
    error: PlotlyErrorKey | ((t: RtdProviderLocale) => string);
}

interface PlotlyIframeProps {
    ctx: KweRtdVisualContext;
    isDarkTheme: boolean;
    version: SupportedPlotlyVersion;
    /** Abbreviation for serializedDataLayoutConfig */
    sdlc: string;
    formatMessage: IDataVisualProps['formatMessage'];
}

export const PlotlyIframe: React.FC<PlotlyIframeProps> = observer(function PlotlyIframe({
    ctx,
    version,
    sdlc,
    isDarkTheme,
    formatMessage,
}) {
    const [violatedUrls, setViolatedUrls] = React.useState<ViolatedUrls>([]);
    const [error, setError] = React.useState<null | PlotlyIframeErr>(null);
    const channelRef = React.useRef<undefined | MessageChannel>();
    const { originAllowlist, addToOriginAllowlist } = useOriginAllowlist(ctx);
    const prevOriginAllowlist = usePrevious(originAllowlist);

    const srcDoc = React.useMemo(
        () => createSrcDoc(ctx.publicRtdPlotlyUrl(version), originAllowlist, ctx.securePlotlyIframe),
        [ctx, version, originAllowlist]
    );

    const handleIframeLoad = React.useCallback<React.ReactEventHandler<HTMLIFrameElement>>(
        (e) => {
            const iframeContentWindow = e.currentTarget.contentWindow;

            if (iframeContentWindow === null) {
                throw new KweException('iframeContentWindow is null when it should not be');
            }

            // The channel instance is tied to this onLoad so we don't pass
            // the same port2 more than once or else it's going to throw a
            // "Error: Port at index 0 is already neutered"
            const channel = new MessageChannel();
            channelRef.current = channel;

            listenOnPort(channel.port1, {
                onViolatedUrls: (violatedUrls) => {
                    setViolatedUrls((prevViolatedUrls) => {
                        const groupedByOrigin = groupBy(violatedUrls, (url) => new URL(url).origin);
                        const groupedUrls = Object.entries(groupedByOrigin);
                        return [...prevViolatedUrls, ...groupedUrls];
                    });
                },
                onNewPlotErr: (exception) => {
                    // Using "severityLeverl=warning" here because there's a good chance these are triggered
                    // by bad customer input and not exactly our's or Plotly's fault. Otherwise, it would
                    // create a lot of noise with how often it's expected these to be called.
                    ctx.telemetry.exception('plotly new plot', { severityLevel: 'warning', exception });
                    setError({ kind: 'error', error: 'newPlot' });
                },
                onUpdatePlotErr: (exception) => {
                    // Using "severityLeverl=warning" here because there's a good chance these are triggered
                    // by bad customer input and not exactly our's or Plotly's fault. Otherwise, it would
                    // create a lot of noise with how often it's expected these to be called.
                    ctx.telemetry.exception('plotly update plot', { severityLevel: 'warning', exception });
                    setError({ kind: 'error', error: 'updatePlot' });
                },
                onMissingSetupErr: () => {
                    ctx.telemetry.exception('plotly missing setup', { severityLevel: 'critical' });
                    setError({ kind: 'critical', error: 'missingSetup' });
                },
                onUnexpectedErr: (exception) => {
                    ctx.telemetry.exception('plotly unexpected error', { severityLevel: 'critical', exception });
                    setError({ kind: 'critical', error: 'unexpectedError' });
                },
            });

            const dlcRes = getFormattedDLC(sdlc, isDarkTheme);

            if (dlcRes.kind === 'err') {
                // This is a critical error because if Plotly was to try
                // and render with this malformed data then we'd end up
                // hitting the "onNewPlotErr". So let's save a roundtrip
                // and error out early.
                setError({ kind: 'critical', error: dlcRes.err });
                return;
            }

            const initPacket: PlotlyPacket = {
                type: PLOTLY_MSG_TYPE.INIT,
                dlc: dlcRes.value,
            };

            // We want to postMessage directly on the iframe's content
            // window so that it doesn't get picked up by other <Iframe />
            // components that are rendered on the screen. Plus, we want to
            // tell the iframe to use the port for future messaging usage.
            iframeContentWindow.postMessage(initPacket, window.location.origin, [channel.port2]);
        },
        [ctx.telemetry, sdlc, isDarkTheme]
    );

    React.useLayoutEffect(() => {
        // The allowlist is the same but we've re-rendered. This means
        // that the customer's Plotly JSON most likely changed so we need
        // to propagate the new JSON data via the MessageChannel to the iframe.
        // This is more efficient then destroying and re-creating the iframe again.
        if (prevOriginAllowlist === originAllowlist) {
            // if (prevOriginAllowlist.current === originAllowlist) {
            const dlcRes = getFormattedDLC(sdlc, isDarkTheme);

            if (dlcRes.kind === 'err') {
                // This is a regular error because the iframe _should_ have been
                // rendering just fine so there's a chance the customer's
                // query will get fixed with an update from their parameter(s)
                // in the next render
                setError({ kind: 'error', error: dlcRes.err });
            } else {
                if (channelRef.current) {
                    sendUpdatePlotly(channelRef.current.port1, dlcRes.value);
                    // Since we're "ok" we should clear any existing errors that could
                    // still be around from the previous run of this useEffect
                    setError(null);
                }
            }
        }
    }, [ctx, isDarkTheme, sdlc, prevOriginAllowlist, originAllowlist]);

    const getMsg = (error: PlotlyIframeErr['error']): string => {
        if (typeof error === 'function') {
            return mobx.runInAction(() => error(ctx.strings));
        }

        return mobx.runInAction(() => ctx.strings.rtdProvider.visuals.plotly.errors[error]);
    };

    if (error?.kind === 'critical') {
        return formatMessage({ message: getMsg(error.error), level: 'error' });
    }

    if (violatedUrls.length) {
        const onComplete: InlinePromptProps['onComplete'] = (results) => {
            Object.entries(results).forEach(([kind, urls]) => {
                if (urls.length) {
                    addToOriginAllowlist(kind as unknown as AddKind, urls);
                }
            });

            setViolatedUrls([]);
        };

        // TODO: If we want to re-use the same prompt in Markdown visuals then we'll want to
        // move this somewhere else into dashboards. Maybe rtd/host/ folder?
        return <InlinePrompt t={ctx.strings} violatedUrls={violatedUrls} onComplete={onComplete} />;
    }

    // We make the iframe invisible for regular errors, because we don't
    // want to unmount the iframe (we want to keep it around for reuse)
    const errorElement = error?.kind === 'error' && formatMessage({ message: getMsg(error.error), level: 'error' });
    const isIframeInvisible = Boolean(errorElement);

    return (
        <>
            {errorElement}
            <div className={classNames(styles.parent, { [styles.invisible]: isIframeInvisible })}>
                <iframe
                    className={styles.iframe}
                    title={ctx.strings.rtdProvider.visuals.plotly.iframeTitle}
                    onLoad={handleIframeLoad}
                    srcDoc={srcDoc}
                    allowFullScreen={false}
                    referrerPolicy="no-referrer"
                />
            </div>
        </>
    );
});
