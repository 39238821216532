import React from 'react';

import { createFlagsObj } from '@kusto/app-common';
import { LOADING, ok, Theme } from '@kusto/utils';
import { KweVisual } from '@kusto/visual-fwk';

import { useDataExplorationContext } from '../../../context/DataExplorationContext';
import { useQueryResults } from '../../hooks/useResults';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

export const ResultsGrid: React.FunctionComponent = () => {
    const { theme, t, parsedVisuals } = useDataExplorationContext();
    const { data } = useQueryResults();
    const featureFlags = createFlagsObj(() => false);

    // Memo used so `ok()` doesn't cause an unstable reference
    // TODO: Include errors
    const queryResult = React.useMemo(() => {
        return data
            ? ok({
                  dataFrame: data.dataFrame,
                  sorted: data.visualizationOptions?.IsQuerySorted,
              })
            : LOADING;
    }, [data]);

    //TODO replace with more direct AG grid usage, eg useDataFrameAgGridProps after GridWithExpand refactor
    return (
        <KweVisual
            visualType={'table'}
            queryResult={queryResult}
            timeZone="UTC"
            isDarkTheme={theme === Theme.Dark}
            featureFlags={featureFlags}
            visuals={parsedVisuals}
            t={t}
            addMessage={() => () => {}}
            onLinkClick={() => {}}
            renderErrorBoundary={(e) => <ErrorBoundary>{e}</ErrorBoundary>}
        />
    );
};
