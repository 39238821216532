export type OptimisticLock = ReturnType<typeof makeOptimisticLock>;

interface Version {
    timestamp: number;
}

export function makeOptimisticLock() {
    let lastSavedVersion: Version = { timestamp: 0 };

    return {
        createVersion: () => ({ timestamp: Date.now() }),
        shouldUpdate: (currentVersion: Version) => currentVersion.timestamp > lastSavedVersion.timestamp,
        setNewVersion: (newVersion: Version) => {
            lastSavedVersion = newVersion;
        },
    };
}
