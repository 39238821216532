import * as client from '@kusto/client';
import { assertNever } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';
import { dataFrameFromVColumnsRows, TableResult } from '@kusto/visualizations';

/// Contains code related to converting to and from dashboard parameter types

export function rtdParamTypeFromExtended(dataType: client.KustoScalarTypeExtended): Fwk.BasicParamTypeTag {
    switch (dataType) {
        case 'datetime':
        case 'date':
            return 'datetime';
        case 'bool':
        case 'boolean':
            return 'bool';
        case 'string':
        case 'decimal':
        case 'int':
        case 'long':
            return dataType;
        case 'timespan':
        case 'time':
        case 'guid':
            return 'string';
        case 'double':
        case 'real':
            return 'real';
        default:
            assertNever(dataType);
    }
}

export function clientTaggedValueToKweTaggedValue(
    kustoValue: client.KustoTaggedValue
): Fwk.TaggedValue.Null | Fwk.TaggedValue.UBasicScalar {
    if (kustoValue.value === null) {
        return Fwk.rtdNullValue;
    }

    if (kustoValue.dataType === 'dynamic') {
        let value: string;
        // Dynamic values can be string, number, array or object
        if (typeof kustoValue.value === 'string') {
            value = kustoValue.value;
        } else {
            value = JSON.stringify(kustoValue.value);
        }
        return { kind: 'scalar', tag: 'string', value: value };
    }

    const tag = rtdParamTypeFromExtended(kustoValue.dataType);
    const value = client.kweScalarValueFromClientScalarValue(kustoValue.value, kustoValue.dataType);

    return { kind: 'scalar', tag, value } as Fwk.TaggedValue.UBasicScalar;
}

/**
 * Does _not_ currently apply the app insights hack dashboards gets when going strait from a query result to a kwe table
 */
export function visualTableResultToRtdQueryResult(tableResult: TableResult, sorted: boolean): Fwk.OkQueryResult {
    return {
        sorted,
        dataFrame: dataFrameFromVColumnsRows(tableResult.columns, tableResult.rows),
    };
}
