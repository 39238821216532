/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { ABORTED, Aborted, KweException, ok, Ok, usePullState } from '@kusto/utils';

/**
 * # Render helper result
 *
 * @param T "ok" value
 * @param U Additional union members
 */
export type RHResult<T = unknown, U = never> = Ok<T> | Aborted | U;

/**
 * # Render helper callback
 *
 * @param resolveOk Resolves the promise that was returned when {@link RenderHelper} was called. If you need to resolve a non "ok" result, use options.resolve
 * @param options.signal Aborts when either with signal passed to {@link RenderHelper} as an argument is aborted, {@link RHCallback} calls it's "resolve" argument, or the {@link RenderHelperProvider} is un-mounted.
 * @param options.resolve Resolve promise with any result
 */
export type RHCallback<T = unknown, U = never> = (
    resolveOk: (value: T) => void,
    options: {
        signal: AbortSignal;
        resolve: (value: RHResult<T, U>) => void;
    }
) => React.ReactNode;

/**
 * @param callback {@link RHCallback}
 */
export interface RenderHelper {
    (
        callback: (
            resolveOk: () => void,
            options: {
                signal: AbortSignal;
                resolve: (value: RHResult) => void;
            }
        ) => React.ReactNode,
        signal?: AbortSignal
    ): Promise<RHResult<unknown>>;
    <T = unknown, U = never>(callback: RHCallback<T, U>, signal?: AbortSignal): Promise<RHResult<T, U>>;
}

/**
 * Includes public methods of {@link RenderHelperRenderer}
 */
export interface RenderHelperHandle {
    /**
     * @see {@link RenderHelper}
     */
    readonly render: RenderHelper;
    /**
     * All rendered components are unmounted, and promises resolved with
     * {@link Aborted}
     */
    clear(): void;
}

type Dispose = () => void;

type Nodes = Map<number, [React.ReactNode, Dispose]>;

let nextId = 0;

/**
 * Does the rendering of RenderHelper. Methods are provided via a ref.
 *
 * @see {@link RenderHelperHandle}
 */
export const RenderHelperRenderer = React.forwardRef<RenderHelperHandle>(function RenderHelper(_props, ref) {
    const [nodes, setNodes, getNodes] = usePullState<Nodes>(new Map());

    const clear = React.useCallback(() => {
        for (const [, dispose] of getNodes().values()) {
            dispose();
        }
    }, [getNodes]);

    React.useImperativeHandle(
        ref,
        () => ({
            render: (<T, U>(callback: RHCallback<T, U>, signal?: AbortSignal) => {
                return new Promise<RHResult<T, U>>((resolve) => {
                    const id = nextId;
                    nextId++;
                    const controller = new AbortController();

                    const dispose = () => {
                        resolve(ABORTED);
                        controller.abort();
                    };

                    signal?.addEventListener('abort', dispose);

                    controller.signal.addEventListener('abort', () => {
                        signal?.removeEventListener('abort', dispose);
                        setNodes((prev) => {
                            const next = new Map(prev);
                            next.delete(id);
                            return next;
                        });
                    });

                    setNodes((prev) => {
                        const next = new Map(prev);
                        const element = callback(
                            // `extends` fancy-ness in the type signature breaks
                            // this. Callers are unaffected
                            (value: any) => {
                                resolve(ok(value));
                                controller.abort();
                            },
                            {
                                signal: controller.signal,
                                resolve: (value) => {
                                    resolve(value);
                                    controller.abort();
                                },
                            }
                        );
                        next.set(id, [element, dispose]);
                        return next;
                    });
                });
            }) as RenderHelper,
            clear,
        }),
        [clear, setNodes]
    );

    const items: React.ReactNode[] = [];

    for (const [id, [element]] of nodes.entries()) {
        items.push(<React.Fragment key={id}>{element}</React.Fragment>);
    }

    return <>{items}</>;
});

export const renderHelperContext = React.createContext<undefined | RenderHelperHandle>(undefined);

/**
 * If you're only calling {@link RenderHandle.render}, use {@link useRender} instead.
 */
export function useRenderHelperHandle(): RenderHelperHandle {
    const value = React.useContext(renderHelperContext);
    if (!value) {
        throw new KweException('useRender must be used inside of a render helper context');
    }
    return value;
}

/**
 * The context that this provides must be present to use {@link useRender}.
 * Mounting this while another provider has already made the context available
 * may be desirable so rendered components can access more contexts, or render
 * in different part of the dom.
 */
export const RenderHelperProvider = React.forwardRef<RenderHelperHandle, { children: React.ReactNode }>(
    ({ children }, parentRef) => {
        const ref = React.useRef<null | RenderHelperHandle>(null);
        const { onRef, value } = React.useMemo<{
            onRef: (handle: null | RenderHelperHandle) => void;
            value: RenderHelperHandle;
        }>(
            () => ({
                onRef: (handle: null | RenderHelperHandle) => {
                    ref.current = handle;
                    if (typeof parentRef === 'function') {
                        parentRef(handle);
                    } else if (parentRef) {
                        parentRef.current = handle;
                    }
                },
                value: {
                    render: (callback: any, options: any) => {
                        if (ref.current) {
                            return ref.current.render(callback, options);
                        }
                        // eslint-disable-next-line no-console
                        console.warn(
                            'Value returned from `useRender` is stale. `RenderHelperProvider` was unmounted before this was called.'
                        );
                        return Promise.resolve(ABORTED);
                    },
                    clear: () => {
                        if (ref.current) {
                            ref.current.clear();
                        } else {
                            // eslint-disable-next-line no-console
                            console.warn('`RenderHelperProvider` was unmounted before this was called.');
                        }
                    },
                },
            }),
            [parentRef]
        );

        return (
            <renderHelperContext.Provider value={value}>
                <RenderHelperRenderer ref={onRef} />
                {children}
            </renderHelperContext.Provider>
        );
    }
);

/**
 * Makes all React components work like `prompt`.
 *
 * @example
 * ```typescript
 * async function myPrompt(message: string, render: RenderHelper, signal: AbortSignal) {
 *     return render<boolean>((resolve) => (
 *         <dialog>
 *             <h1>{question}</h1>
 *         </dialog>,
 *         { signal }
 *     ));
 * }
 *
 * @example
 * ```typescript
 * interface WidgetService {
 *     get(id: string, abortSignal: AbortSignal): AsyncResult;
 * }
 *
 * async function fetchWidget(widgetService: WidgetService, id: string, abortSignal: AbortSignal, render: RenderHelper) {
 *     const res = await widgetService.get(id, abortSignal);
 *
 *     switch (res.kind) {
 *         case 'abort':
 *             return;
 *         case 'err':
 *             render((resolve) => (
 *                 <dialog>
 *                     <h1>Error fetching widgets</h1>
 *                     <p>{res.err}</p>
 *                     <button onClick={() => resolve(ok())}>bummer</button>
 *                 </dialog>,
 *                 { signal: abortSignal }
 *             ));
 *             return;
 *         case 'ok':
 *         // Do stuff with widget
 *     }
 * }
 * ```
 */
export function useRender(): RenderHelper {
    return useRenderHelperHandle().render;
}
