import { ICommandBarStyleProps, ICommandBarStyles } from '@fluentui/react';
import { tokens } from '@fluentui/tokens';
import { IStyleFunctionOrObject } from '@fluentui/utilities';

export const getCommandBarStyles = (): IStyleFunctionOrObject<ICommandBarStyleProps, ICommandBarStyles> => {
    const commandBarStyles: IStyleFunctionOrObject<ICommandBarStyleProps, ICommandBarStyles> = {
        root: [
            {
                height: 39,
                borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
            },
        ],
    };

    return commandBarStyles;
};
