// due to the way monaco loads its internal services/contrib/modules, we need to set up the environment before it is loaded
// TODO - try to load only services/contrib/modules needed for better bundle size and performance
import 'monaco-editor/esm/vs/editor/edcore.main.js';

import kustoWorkerUrl from 'url:@kusto/monaco-kusto/release/esm/kusto.worker';
import editorWorkerUrl from 'url:monaco-editor/esm/vs/editor/editor.worker';
import jsonWorkerUrl from 'url:monaco-editor/esm/vs/language/json/json.worker';

window.MonacoEnvironment = {
    createTrustedTypesPolicy: () => undefined,
    getWorkerUrl(_moduleId, label) {
        switch (label) {
            case 'kusto':
                return kustoWorkerUrl;
            case 'json':
                return jsonWorkerUrl;
            default:
                return editorWorkerUrl;
        }
    },
};
