import React from 'react';
import {
    Dropdown,
    IDropdown,
    IDropdownOption,
    IDropdownProps,
    IDropdownStyleProps,
    IDropdownStyles,
} from '@fluentui/react/lib/Dropdown';
import { ISelectableDroppableTextProps } from '@fluentui/react/lib/SelectableOption';
import { RefObject, styled } from '@fluentui/react/lib/Utilities';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';

import type { IKweTelemetry } from '../../../../telemetry';
import { useThemeState } from '../../../theming';
import { useRtdProviderStrings } from '../../stringsContext';
import { getStyles } from './CustomListDropdown.styles';
import { CustomListDropdownMenuProps } from './CustomListDropdownMenu';
import { CustomListDropdownMenuProvider } from './CustomListDropdownMenuContext';
import { RTDDropdownOption } from './types';

type SelectableDropdownProps = ISelectableDroppableTextProps<IDropdown, HTMLDivElement>;

export interface CustomListDropdownBaseProps extends Omit<IDropdownProps, 'componentRef'> {
    componentRef?: React.RefObject<IDropdown> | RefObject<IDropdown> | ((ref: IDropdown | null) => void);

    onRenderHeaderPrefix?: () => JSX.Element | null;
    onRenderMenu: (props: CustomListDropdownMenuProps) => JSX.Element | null;

    options: RTDDropdownOption[];
    label?: string;

    defaultSelectedKey?: string;
    defaultSelectedKeys?: string[];
    selectedKey?: string | null;
    selectedKeys?: string[] | null;

    telemetry: IKweTelemetry;
}

export interface CustomListDropdownProps
    extends Omit<CustomListDropdownBaseProps, 'onRenderMenu' | 'onRenderHeaderPrefix'> {
    onRenderHeader?: () => JSX.Element | null;
}

const CustomListDropdownBase: React.FC<CustomListDropdownBaseProps> = (props) => {
    const {
        componentRef: externalComponentRef,
        onRenderMenu,
        onRenderHeaderPrefix,
        onChange: externalOnChange,
        options,
        defaultSelectedKey,
        defaultSelectedKeys,
        selectedKey,
        selectedKeys,
        multiSelect,
        telemetry,
        ariaLabel,
        label,
    } = props;

    const t = useRtdProviderStrings();
    const theme = useThemeState();

    const dropdownRef = React.useRef<IDropdown | null>(null);
    const currentSelectedIndexes = React.useRef<Set<number>>();

    const componentRef = React.useCallback(
        (ref: IDropdown | null) => {
            // Support our internal ref, as well as possible props
            dropdownRef.current = ref;

            if (isFunction(externalComponentRef)) {
                externalComponentRef(ref);
            } else if (externalComponentRef) {
                (externalComponentRef as { current: IDropdown | null }).current = ref;
            }
        },
        [externalComponentRef]
    );

    const onChange = React.useCallback(
        (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) => {
            externalOnChange?.(event, option, index);

            if (option === undefined || index === undefined) {
                return;
            }

            if (!currentSelectedIndexes.current || !multiSelect) {
                // Clear set if not multiselect
                currentSelectedIndexes.current = new Set();
            }

            if (option.selected || !multiSelect) {
                // Always add if not multiselect
                currentSelectedIndexes.current.add(index);
            } else {
                currentSelectedIndexes.current.delete(index);
            }

            // a11y: Looks like after selection, the focus
            // is not returning back to the main input so we're
            // doing that manually. Only need to do this
            // for single select. Multi-select is
            // handled correctly since Enter/Space key
            // will check/uncheck and not actually close
            // the dropdown menu.
            if (!multiSelect) {
                dropdownRef.current?.focus(false);
            }
        },
        [externalOnChange, multiSelect]
    );

    const onRenderMenuList = React.useCallback(
        (
            innerProps?: SelectableDropdownProps,
            defaultRender?: (p: SelectableDropdownProps) => React.ReactElement | null
        ) => {
            const defaultList = innerProps && defaultRender?.(innerProps);

            // WARNING: This is very dangerous. In most circumstances, do not do
            // this We must do it here because the Dropdown API is lacking and
            // doesn't provide defaultRender for onRenderItem. Therefore we let
            // the defaultRender for onRenderMenuList render the list, and grab
            // the children from that render
            let children = defaultList?.props.children as React.ReactElement<{
                role?: string;
                children?: React.ReactChildren;
            }>[];

            // WARNING: see above + depend on defaultRender internal structure
            // of rendering headers option for each header option it will create
            // a group element and each following option will be sub-children of
            // the group including the header option itself flatting the
            // sub-children's of all groups to single list
            if (children.some((elem) => elem?.props?.role === 'group')) {
                const flattenChildrenGroups: React.ReactElement[] = [];
                for (const el of children) {
                    if (el?.props?.role === 'group' && Array.isArray(el.props.children)) {
                        flattenChildrenGroups.push(...el.props.children);
                    } else {
                        flattenChildrenGroups.push(el);
                    }
                }
                children = flattenChildrenGroups;
            }

            return (
                <CustomListDropdownMenuProvider>
                    {onRenderMenu({
                        ...innerProps,
                        options: innerProps?.options,
                        onRenderHeaderPrefix,
                        ...(Array.isArray(children) ? { renderedOptions: children } : { defaultList }),
                        currentSelectedIndexes,
                        // Fabric allows number keys, but we do not (nor can this property be a number)
                        selectedKey: innerProps?.selectedKey as string | undefined | null,
                        telemetry,
                        focusDropdown: () => {
                            dropdownRef?.current?.focus(false);
                        },
                    })}
                </CustomListDropdownMenuProvider>
            );
        },
        [onRenderMenu, onRenderHeaderPrefix, telemetry]
    );

    React.useLayoutEffect(() => {
        // Must be a layout effect to fire prior to the scrolling in SearchDropdownMenu, triggered via useEffect
        buildSelectedIndexes(defaultSelectedKeys, defaultSelectedKey, options, currentSelectedIndexes);
        // Default values are only updated on initial render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useLayoutEffect(() => {
        // Must be a layout effect to fire prior to the scrolling in SearchDropdownMenu, triggered via useEffect
        buildSelectedIndexes(selectedKeys, selectedKey, options, currentSelectedIndexes);
    }, [selectedKeys, selectedKey, options]);

    return (
        <Dropdown
            {...props}
            calloutProps={{
                ...props.calloutProps,
                className: classNames(theme.classNames, props.calloutProps?.className),
            }}
            componentRef={componentRef}
            onRenderList={onRenderMenuList}
            onChange={onChange}
            ariaLabel={ariaLabel ?? label ?? t.utils.components.rtdDropdown.dropdownListAriaLabel}
        />
    );
};

export const CustomListDropdown: React.FunctionComponent<CustomListDropdownBaseProps> = styled<
    CustomListDropdownBaseProps,
    IDropdownStyleProps,
    IDropdownStyles
>(CustomListDropdownBase, getStyles, undefined, {
    scope: 'CustomListDropdown',
});

const findSelectedIndexes = (selectedKeys: string[], options: RTDDropdownOption[]): Set<number> => {
    const set = new Set<number>();

    const keyToIndex: Record<string, number> = {};

    for (let i = 0; i < options.length; i++) {
        const option = options[i];
        keyToIndex[option.key] = i;
    }

    for (const key of selectedKeys) {
        const index = keyToIndex[key];

        if (index !== undefined) {
            set.add(index);
        }
    }

    return set;
};

const buildSelectedIndexes = (
    selectedKeys: string[] | null | undefined,
    selectedKey: string | null | undefined,
    options: RTDDropdownOption[],
    currentSelectedIndexes: React.MutableRefObject<Set<number> | undefined>
) => {
    if (selectedKeys === undefined && selectedKey === undefined) {
        return;
    }

    if (selectedKeys) {
        currentSelectedIndexes.current = findSelectedIndexes(selectedKeys, options);
    } else if (selectedKey) {
        currentSelectedIndexes.current = findSelectedIndexes([selectedKey], options);
    } else {
        currentSelectedIndexes.current = undefined;
    }
};
