.schemaInsightsWrapper {
    flex: 1 1 auto;
    display: flex;
    align-self: stretch;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;

    .content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;
    }

    .sectionWrapper {
        flex: 50% 0 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &:only-child {
            flex-basis: 100%;
        }
        border-right: var(--strokeWidthThin) solid var(--colorNeutralStroke1);
        background: var(--colorNeutralBackground1);
        border-radius: var(--borderRadiusXLarge) 0 0 var(--borderRadiusXLarge);
        min-width: 0;

        &:last-child {
            border-right: none;
            border-radius: var(--borderRadiusXLarge);
        }
    }

    .selectedColumn {
        display: flex;
        height: 24px;
        align-items: center;
        border: var(--strokeWidthThin) solid var(--colorBrandForegroundInverted);
        border-radius: var(--borderRadiusCircular);
        padding: var(--spacingVerticalXS) var(--spacingHorizontalM);

        gap: var(--spacingHorizontalXS);
    }
    .backButton {
        padding: 0;
    }

    .distributionListMain {
        align-self: stretch;
    }
}

.statusBar {
    border-top: 1px solid var(--colorNeutralStroke1);
    background: var(--colorNeutralBackground3);
    color: var(--colorNeutralForeground2);
    padding: 0 var(--spacingHorizontalXS);
    display: flex;
    align-items: center;
    gap: var(--spacingHorizontalXS);
    flex: 0 0 24px;
}

.fixedSize {
    min-height: 0;
    & .content {
        min-height: 0;
    }
}

.box {
    box-shadow: var(--shadow4);
    border-radius: var(--borderRadiusXLarge);
    background: var(--colorNeutralBackground1);
    overflow: hidden;
}
