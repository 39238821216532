import React from 'react';
import { Divider } from '@fluentui/react-components';

import { TabDividerProps } from '../Tabs.interfaces';
import { useTabsBarContext } from '../TabsContext/TabsBar.context';

/** Divider that will appear between tabs if all conditions met */
export const TabDivider: React.FunctionComponent<TabDividerProps> = ({ index }) => {
    const context = useTabsBarContext();

    function getIsVisible() {
        if (context.tabsList[index]?.tabId === context.selectedTabId) {
            return false; // selected item don't have divider
        }

        // In drag and drop state the divider visibility is a bit complex, we have a few cases:
        // 1. dragging outside the container - show all dividers
        // 2. dragging inside the container - need to hide the divider before and after the dragged item
        // The dragged index is different if dragged to the left or to the right, from source position.
        // 2.1 drag item from left to right (from < to) - hide divider after the item below the dragged item
        // 2.2 drag item from right to left (from > to) - hide divider before the item below the dragged item
        // 2.3 drag item from and to the same index, meaning the dragged item returned to it's original index - handle as if not in drag mode

        if (context.dragging.state !== undefined) {
            // in dragging state
            if (context.dragging.state.to !== undefined) {
                // dragging inside container
                if (context.dragging.state.from < context.dragging.state.to) {
                    // dragging from left to right
                    if (context.dragging.state.to === index) {
                        return false;
                    }
                } else if (context.dragging.state.from > context.dragging.state.to) {
                    // dragging from right to left
                    if (context.dragging.state.to - 1 === index) {
                        return false;
                    }
                } else {
                    // same index as started before move, act is if not moved - hide divider before the selected element
                    if (context.tabsList[index + 1]?.tabId === context.selectedTabId) {
                        return false; // [in drag mode] tab before selected don't have divider
                    }
                }
            } else {
                return true; // dragging outside container (show all dividers)
            }
        } else if (context.tabsList[index + 1]?.tabId === context.selectedTabId) {
            return false; // [not in drag mode] tab before selected don't have divider
        }

        return true;
    }

    const isRender = index !== context.tabsList.length - 1; // remove space
    const isVisible = getIsVisible(); // keep space

    return isRender ? <Divider vertical style={{ visibility: isVisible ? undefined : 'hidden' }} /> : <></>;
};
