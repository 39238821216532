import * as React from 'react';

export const MIN_ITEMS_FOR_ACTIONS = 2;
const MAX_NUM_OF_ROWS = 3;
const NUM_OF_ITEMS_IN_SMALL_LAYOUT = 6;

export const useHorizontalGrid = (
    isHorizontal: boolean | undefined,
    containerRef: React.RefObject<HTMLDivElement>,
    totalItems: number
) => {
    React.useLayoutEffect(() => {
        if (containerRef.current && isHorizontal) {
            const maybeNumOfRows = Math.floor(totalItems / NUM_OF_ITEMS_IN_SMALL_LAYOUT + 1);
            const numOfRows = totalItems > MIN_ITEMS_FOR_ACTIONS ? Math.min(maybeNumOfRows, MAX_NUM_OF_ROWS) : 1;
            containerRef.current.style.gridTemplateRows = `repeat(${numOfRows}, 1fr)`;
        }
    }, [containerRef, totalItems, isHorizontal]);
};
