import { createContext, useContextSelector } from '@fluentui/react-context-selector';
export const PopoverContext = createContext(undefined);
const popoverContextDefaultValue = {
    open: false,
    setOpen: ()=>null,
    toggleOpen: ()=>null,
    triggerRef: {
        current: null
    },
    contentRef: {
        current: null
    },
    arrowRef: {
        current: null
    },
    openOnContext: false,
    openOnHover: false,
    size: 'medium',
    trapFocus: false,
    inline: false
};
export const PopoverProvider = PopoverContext.Provider;
export const usePopoverContext_unstable = (selector)=>useContextSelector(PopoverContext, (ctx = popoverContextDefaultValue)=>selector(ctx));
