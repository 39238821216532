import { EntityType, Function as KustoFunction } from '../../../common';
import { Database, QueryStoreEnv } from '../../../stores';
import { ITreeEntityMapper } from '../actions';
import { RowDataType } from '../RowDataType';
import { getBaseRootPath } from '../utils';
import { functionsFolderName } from './DatabaseFolderRowDataType';
import { iconMapper } from './IconMapper';
import { RowDataTypeBase } from './RowDataTypeBase';
import { getFromCacheOrCreate } from './RowDataTypeCache';

function infoForFunction(func: KustoFunction): string | undefined {
    const infoParts = func.docstring ? [func.docstring] : [];
    if (func.inputParameters.length) {
        infoParts.push(
            'Input parameters:',
            ...func.inputParameters.map(
                (p) => `  ${p.name}: ${p.cslType ?? 'N/A'}${p.cslDefaultValue ? ` = ${p.cslDefaultValue}` : ''}`
            )
        );
    }
    return infoParts.length ? infoParts.join('\n') : undefined;
}

export class FunctionRowDataType extends RowDataTypeBase {
    private constructor(
        // eslint-disable-next-line @typescript-eslint/ban-types
        func: KustoFunction,
        database: Database,
        baseRootPath: string[],
        treeEntityMapper: ITreeEntityMapper
    ) {
        super(
            func.id,
            FunctionRowDataType.buildFunctionFolderPath(baseRootPath, func),
            func.name,
            func.entityType,
            database,
            iconMapper[func.entityType],
            treeEntityMapper
        );

        this.info = infoForFunction(func);
        this.actions = treeEntityMapper.getActions(this.entityType, false, this.info);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    public static buildFunctionFolderPath(baseRootPath: string[], func: KustoFunction): string[] {
        const pathFromRoot: string[] = [];
        if (!func.folder && func.entityType === EntityType.ViewFunction) {
            // View Functions without a folder will be shown under the database.
            pathFromRoot.push(...baseRootPath, func.id);
        } else {
            const folderPath = func.folder ? func.folder.split(/[\\/]/g) : [];
            pathFromRoot.push(...baseRootPath, functionsFolderName, ...folderPath, func.id);
        }

        return pathFromRoot;
    }

    public static fromCacheOrCreate(
        env: QueryStoreEnv,
        // eslint-disable-next-line @typescript-eslint/ban-types
        func: KustoFunction,
        database: Database,
        entityActionsMapper: ITreeEntityMapper
    ): RowDataType {
        return getFromCacheOrCreate(
            database,
            func.id,
            () => new FunctionRowDataType(func, database, getBaseRootPath(env, database), entityActionsMapper)
        );
    }
}
