import React from 'react';
import { observer } from 'mobx-react-lite';

import { formatLiterals } from '@kusto/utils';

import {
    DataProfileTimeRange,
    DateTimeColumn,
    HistogramYColumn,
    useFullRowCount,
    useHistogram,
    useScopedRowCount,
} from '../DataExplorationApi';
import { useStrings } from '../DataExplorationModal/hooks/useStrings';
import type { QueryRunnerV2 } from '../types';
import { DateTimeColumnPicker } from './DateTimeColumnPicker/DateTimeColumnPicker';
import { HistogramErrView } from './HistogramErrorView';
import { HistogramOKView } from './HistogramOKView';
import { HistogramVisualProps } from './HistogramVisual';

import * as styles from './styles.module.scss';

export interface HistogramContentProps extends Omit<HistogramVisualProps, 'data' | 'strings'> {
    dbCacheToken: string;
    queryText: string;
    timeRange: DataProfileTimeRange;
    defaultSelectedColumn: HistogramYColumn;
    datetimeColumns: DateTimeColumn[];
    isIngestionTimePolicyEnabled?: boolean;
    executeV2Query: QueryRunnerV2;
}

export const HistogramContent: React.FC<HistogramContentProps> = observer(function HistogramContent({
    dbCacheToken,
    queryText,
    timeRange,
    defaultSelectedColumn,
    datetimeColumns,
    isIngestionTimePolicyEnabled,
    executeV2Query,
    ...props
}) {
    const { strings, locale } = useStrings();
    const { data: scopedRowCount, isLoading: isLoadingScopedRowCount } = useScopedRowCount(queryText, timeRange);
    const [selectedColumn, setSelectedColumn] = React.useState<HistogramYColumn>(defaultSelectedColumn);

    const {
        data,
        isLoading: isLoadingHistogram,
        error,
    } = useHistogram(dbCacheToken, queryText, timeRange, selectedColumn, executeV2Query, !scopedRowCount);

    const { data: fullRowCount, isLoading: isLoadingFullRowCount } = useFullRowCount(queryText);
    const isLoading = isLoadingFullRowCount || isLoadingScopedRowCount || isLoadingHistogram;
    const isErrorState = error || (!data?.length && !isLoading);

    const showRowCount = fullRowCount !== undefined && scopedRowCount !== undefined && !isErrorState;
    const fullCountFormat = new Intl.NumberFormat(locale, { style: 'decimal', notation: 'compact' });
    const scopedCountFormat = new Intl.NumberFormat(locale, { style: 'decimal' });

    if (isErrorState) {
        return (
            <div className={styles.errView}>
                <HistogramErrView
                    msg={scopedRowCount ? strings.histogram.somethingWentWrong : strings.histogram.nothingToDisplay}
                    level={error ? 'error' : 'warn'}
                />
                <DateTimeColumnPicker
                    dateTimeColumns={datetimeColumns}
                    isIngestionTimePolicyEnabled={isIngestionTimePolicyEnabled}
                    selectedColumn={selectedColumn}
                    onSelect={(col) => setSelectedColumn(col)}
                />
            </div>
        );
    }

    return (
        <div className={styles.okView}>
            <HistogramOKView isLoading={isLoading} {...props} data={data ?? []} />
            <div className={styles.histogramOverlay}>
                <div className={styles.columnPickerWrapper}>
                    <DateTimeColumnPicker
                        dateTimeColumns={datetimeColumns}
                        isIngestionTimePolicyEnabled={isIngestionTimePolicyEnabled}
                        selectedColumn={selectedColumn}
                        onSelect={(col) => setSelectedColumn(col)}
                    />
                </div>
                {showRowCount ? (
                    <div className={styles.rowCountContainer}>
                        <div className={styles.scopedRowCount}>{scopedCountFormat.format(scopedRowCount)}</div>
                        <div className={styles.fullRowCount}>
                            {formatLiterals(strings.histogram.outOfTotalRows, {
                                fullRowCount: fullCountFormat.format(fullRowCount),
                            })}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
});
