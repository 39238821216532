const activeTenantKey = 'kwe.active.tenant';

export const setActiveTenant = (tenantId: string): void => {
    localStorage.setItem(activeTenantKey, tenantId);
};

export const deleteActiveTenant = (): void => {
    localStorage.removeItem(activeTenantKey);
};

export const getActiveTenant = (): string | null => {
    return localStorage.getItem(activeTenantKey);
};
