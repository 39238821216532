// Container used to increase specificity of the styles - instead of using !important everywhere
.container {
    .mainButton {
        padding: 0 var(--spacingHorizontalSNudge);
        cursor: pointer;
    }

    .actionsDivider {
        margin: 0 var(--spacingHorizontalXS);
    }
}

.menuPopover {
    max-height: 40vh;

    .menuItem {
        display: flex;
        overflow-x: hidden;

        .itemText {
            flex: 1;
            overflow-x: hidden;
            text-overflow: ellipsis;

            .text {
                white-space: unset;
            }
            .subText {
                white-space: unset;
                color: var(--colorNeutralForegroundDisabled);
            }
        }

        .hoverContent {
            transition: opacity 0.4s ease;
            visibility: hidden;
            opacity: 0;
        }
    }
    .menuItem:hover {
        .hoverContent {
            visibility: visible;
            opacity: 1;
        }
    }
}
