import type { Dispose } from '../types';

/**
 * Makes flushing many debounced/throttled things easier
 *
 * @example Minimal example
 * import debounce from 'lodash/debounce';
 * import { FlushController } from '@kusto/utils';
 *
 * /// Setup
 *
 * const flushController = new FlushController();
 *
 * const debounced = debounce(() => { // Do something }, 1000);
 * flushController.register(() => debounced.flush());
 *
 * /// Later
 *
 * // Action called
 * debounced();
 *
 * // Now, if we need to be sure we're reading the latest state, we call `.flush()`
 * await flushController.flush();
 *
 * @example Large example
 * import React from 'react';
 * import debounce from 'lodash/debounce';
 *
 * import { FlushController } from '@kusto/utils';
 *
 * class MyModel {
 *   // "register" method passed to UX that's debounced/throttled
 *   public readonly flushController = new FlushController();
 *
 *   async persist() {
 *       // Before accessing debounced/flushed state, call `flush()`
 *       await this.flushController.flush();
 *       // Access is now safe
 *   }
 * }
 *
 * const MyComponent: React.FC = () => {
 *   const myModel = React.useState(() => new MyModel())[0];
 *
 *   // Some external model that needs to be flushed, for example, a Monaco Editor model
 *   const externalModel = React.useState(() => new SomeExternalModule())[0];
 *
 *   // Register external model with myModel's flush controller
 *   React.useEffect(() => myModel.flushController.register(() => externalModel.persist()), [myModel, externalModel]);
 *
 *   // Assume this contains state that needs to be flushed before some actions
 *   const myCallback = React.useMemo(() => debounce((event) => {}, 1000), []);
 *
 *   React.useEffect(() => myModel.flushController.register(() => myCallback.flush()), [myModel, externalModel]);
 *
 *   return null;
 * }
 */
export class FlushController {
    private registrations: Set<() => unknown> = new Set();

    constructor() {
        this.flush = this.flush.bind(this);
        this.register = this.register.bind(this);
    }

    public async flush(): Promise<void> {
        const maybePromises: unknown[] = [];
        for (const flush of this.registrations) {
            maybePromises.push(flush());
        }
        await Promise.all(maybePromises);
    }

    public register(listener: () => unknown): Dispose {
        this.registrations.add(listener);
        return () => this.registrations.delete(listener);
    }
}
