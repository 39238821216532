import type { Kind, VisualizationOptions as vo, VisualizationType as vt } from '@kusto/charting';
import type { UnknownDataFrameValue } from '@kusto/utils';
import type { MultipleYAxesConfig } from '@kusto/visual-fwk';

import type { VUColorRule } from './conditionalFormatting/types';

// Originally these exports were X = X
// but TS 3.7.5 now complain about the type already being defined. so had to rename the imports.
export type VisualizationType = vt;
export type VisualizationOptions = vo;

export type TextSize = 'small' | 'large' | 'auto';

/**
 * VisualizationType that extends Query visualization types to include types that are supported by dashboards.
 */
export type ExtendedVisualizationType = VisualizationType | 'multistat' | 'map' | 'heatmap';

export type ExtendedKind = Kind | 'bubble' | 'donut' | 'pie';

export interface ColumnFormatting {
    /**
     * Allow for Conditional Formatting configuration
     */
    ConditionalFormattingConfig?: ConditionalFormattingConfig;

    /**
     * Configuration for links
     */
    LinkConfig?: LinkConfig;
}

/**
 * @deprecated Use visual-fwk's types instead when possible
 */
export interface ConditionalFormattingConfig {
    colorRulesDisabled: boolean;
    colorRules: readonly VUColorRule[];
}

export type RenderAsLinkFn = (column: string, row: Record<string, UnknownDataFrameValue>) => undefined | string;

export interface LinkConfig {
    /**
     * If provided, and it returns a url string when called, the
     * cell will try to be rendered as a link
     */
    renderAsLink?: RenderAsLinkFn;
}

export type PieLabelOptions = 'percentage' | 'value' | 'name';

export type PieTooltipOptions = 'percentage' | 'value' | 'name';

/**
 * Additional visualization options that aren't necessarily supported by Kusto render command
 */
export interface ExtendedVisualizationOptions extends Omit<VisualizationOptions, 'Visualization' | 'Kind'> {
    /**
     * can contain visualization types that aren't included in kusto visualization type.
     */
    readonly Visualization: ExtendedVisualizationType;

    /**
     * Supporting more kinds
     */
    readonly Kind: ExtendedKind;

    /**
     * Size of text to display.
     */
    readonly TextSize?: TextSize;

    /**
     * for visualizations like multistat that have a grid-like structure, heigh specifies the number of quadrants / cells on the up/down direction
     */
    readonly Height?: number;

    /**
     * for visualizations like multistat that have a grid-like structure, width specifies the number of quadrants  / cells on the left-right direction.
     */
    readonly Width?: number;

    /**
     * Optional configuration for Column formatting
     */
    readonly ColumnFormatting?: ColumnFormatting;
    /**
     * Horizontal line value currently available only for line and time charts
     */
    readonly HorizontalLine?: string;
    /**
     * Vertical line value currently available only for line and time charts
     */
    readonly VerticalLine?: string;

    readonly MultipleYAxes?: MultipleYAxesConfig;
    /**
     * Heatmap options
     */
    readonly DataColumn?: string | null;
    /**
     * Pie chart options
     */
    readonly LabelDisabled?: boolean;

    readonly LabelOptions?: readonly PieLabelOptions[];

    readonly TooltipDisabled?: boolean;

    readonly TooltipOptions?: readonly PieTooltipOptions[];

    readonly PieOrderBy?: 'none' | 'name' | 'size';

    readonly TopNSlices?: null | number;

    /*
     * Bubble map related properties
     */
    readonly GeoType?: string;

    readonly Longitude?: string | null;

    readonly Latitude?: string | null;

    readonly LabelColumn?: string | null;

    readonly DisableSize?: boolean;

    readonly SizeColumn?: string | null;

    readonly GeoPointColumn?: string | null;
}

export const TableVisualizationOptionsSnapshot: VisualizationOptions = {
    Accumulate: false,
    IsQuerySorted: false,
    Kind: null,
    Legend: null,
    Series: null,
    Title: null,
    Visualization: 'table',
    XAxis: null,
    XColumn: null,
    XTitle: null,
    YAxis: null,
    YColumns: null,
    YSplit: null,
    YTitle: null,
    AnomalyColumns: null,
    Ymin: 'NaN',
    Ymax: 'NaN',
};
