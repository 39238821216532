import React from 'react';
import { IconButton, IStyle, ITheme, Link, Panel, PanelType } from '@fluentui/react';
import { IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { inject, observer } from 'mobx-react';

import { EmptyVisualizationOptionsSnapshot } from '@kusto/client';
import { MonacoEditorJSON } from '@kusto/ui-components';
import { Theme } from '@kusto/utils';

import { useQueryCore } from '../../core/core';
import { QueryCompletionInfo } from '../../stores/queryCompletionInfo';
import { IRootStore } from '../../stores/rootStore';
import { getTelemetryClient } from '../../utils/telemetryClient';
import { KweChart } from '../charting/KweChart';
import {
    BoldValueCardItem,
    DashboardContainer,
    FlexRow,
    NumericCard,
    PrimaryColorValueCardItem,
    Tile,
    VerticalNumericCard,
} from '../charting/Tile';

const { trackTrace } = getTelemetryClient({ component: 'QueryResourceConsumption', flow: '' });

export interface Props {
    completionInfo: QueryCompletionInfo;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<StyleProps, Styles>;
    settingsTheme?: Theme;
}

type StyleProps = Required<Pick<Props, 'theme' | 'completionInfo'>>;

interface Styles {
    root: IStyle;
}

const getStyles = (props: StyleProps): Styles => {
    return {
        root: {
            backgroundColor: props.theme?.palette.white,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
        },
    };
};

/**
 * A presentational react component that knows how to display query statistics (cpu, cache hits ETC)
 */
// const QueryResourceConsumptionBase = (props: Props) => {
const QueryResourceConsumptionBase = (props: Props) => {
    const [showMetadataInRightPanel, setShowMetadataInRightPanel] = React.useState(false);

    const { completionInfo } = props;
    const { queryResourceConsumption: stats } = completionInfo;

    const queryCore = useQueryCore();

    if (!stats) {
        trackTrace('queryResourceConsumption (stats) object is empty', SeverityLevel.Error);
        return <div />;
    }

    const isDarkTheme = queryCore.store.settings.theme === Theme.Dark;

    return (
        <>
            <DashboardContainer>
                <FlexRow>
                    <Tile header={queryCore.strings.query.statsTime}>
                        <NumericCard>
                            <PrimaryColorValueCardItem
                                value={stats.ExecutionTime ?? 0}
                                label={queryCore.strings.query.statsTimeLabel}
                                shouldLocalize={false}
                            />
                        </NumericCard>
                    </Tile>
                    <Tile header={queryCore.strings.query.statsCPU}>
                        <NumericCard>
                            <PrimaryColorValueCardItem
                                value={completionInfo.totalCPU}
                                label={queryCore.strings.query.statsCPUTotal}
                                shouldLocalize={false}
                            />
                            <PrimaryColorValueCardItem
                                value={stats.resource_usage?.cpu?.kernel ?? ''}
                                label={queryCore.strings.query.statsCPUKernel}
                                shouldLocalize={false}
                            />
                            <PrimaryColorValueCardItem
                                value={stats.resource_usage?.cpu?.user ?? ''}
                                label={queryCore.strings.query.statsCPUUser}
                                shouldLocalize={false}
                            />
                        </NumericCard>
                    </Tile>
                    <Tile header={queryCore.strings.query.statsMemory}>
                        <NumericCard>
                            <PrimaryColorValueCardItem
                                value={completionInfo.peakPerNodeMemory}
                                label={queryCore.strings.query.statsMemoryPeaks}
                                shouldLocalize={false}
                            />
                        </NumericCard>
                    </Tile>
                    <Tile header={queryCore.strings.query.statsDataSet}>
                        <NumericCard>
                            <PrimaryColorValueCardItem
                                value={completionInfo.numberOfRowsInDatasets}
                                label={queryCore.strings.query.statsDataSetRows}
                                shouldLocalize={true}
                            />
                            <PrimaryColorValueCardItem
                                value={completionInfo.sizeOfDatasets}
                                label={queryCore.strings.query.statsDataSetSize}
                                shouldLocalize={true}
                            />
                        </NumericCard>
                    </Tile>
                </FlexRow>
                <FlexRow style={{ minHeight: '150px' }}>
                    <Tile header={queryCore.strings.query.statsDiskCache}>
                        <KweChart
                            chartTitle={queryCore.strings.query.statsDiskCache}
                            columns={[
                                {
                                    field: 'Name',
                                    headerName: 'Name',
                                    columnType: 'string',
                                },
                                {
                                    field: 'Value',
                                    headerName: 'value',
                                    columnType: 'int',
                                },
                            ]}
                            rows={[
                                {
                                    Name: queryCore.strings.query.statsHits,
                                    Value: completionInfo.shardDiskHitsInBytes,
                                },
                                {
                                    Name: queryCore.strings.query.statsMisses,
                                    Value: completionInfo.shardDiskMissesInBytes,
                                },
                            ]}
                            visualizationOptions={{
                                ...EmptyVisualizationOptionsSnapshot,
                                Visualization: 'barchart',
                            }}
                        />
                    </Tile>
                    <Tile
                        header={queryCore.strings.query.statsMetadataJsonCardHeader}
                        styles={{ tile: { minWidth: '270px' } }}
                        headerRight={
                            <div
                                style={{ cursor: 'pointer' }}
                                role="presentation"
                                onMouseDown={() => {
                                    setShowMetadataInRightPanel(true);
                                }}
                                onKeyPress={(keyEvent) => {
                                    if (keyEvent.key === 'Enter') {
                                        setShowMetadataInRightPanel(true);
                                    }
                                }}
                            >
                                <Link>{queryCore.strings.query.statsMetadataJsonCardViewJsonLink}</Link>
                                <IconButton
                                    ariaLabel={queryCore.strings.query.statsMetadataJsonCardViewJsonLink}
                                    styles={{
                                        rootHovered: {
                                            backgroundColor: 'transparent',
                                        },
                                        rootPressed: {
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                    iconProps={{
                                        iconName: 'FullScreen',
                                        styles: {
                                            root: {
                                                fontSize: '12px',
                                            },
                                        },
                                    }}
                                />
                            </div>
                        }
                    >
                        <MonacoEditorJSON json={completionInfo.metadataAsJson} isDark={isDarkTheme} />
                    </Tile>
                </FlexRow>
                {queryCore.featureFlags.statsv2 ? (
                    <FlexRow>
                        <Tile header={queryCore.strings.query.statsGeneralCardHeader}>
                            <VerticalNumericCard>
                                <BoldValueCardItem
                                    value={completionInfo.clientActivityId}
                                    label={queryCore.strings.query.statsClientRequestIDButtonTooltip}
                                    shouldLocalize={false}
                                    showTooltip={true}
                                    showCopyToClipboardButton={true}
                                />
                                <BoldValueCardItem
                                    value={completionInfo.cpuLoadLoadAssessment}
                                    label={queryCore.strings.query.statsGeneralCpuLoadLabel}
                                    shouldLocalize={false}
                                />
                                <BoldValueCardItem
                                    value={completionInfo.parallelismLevel}
                                    label={queryCore.strings.query.statsGeneralParallelismLabel}
                                    shouldLocalize={false}
                                />
                            </VerticalNumericCard>
                        </Tile>
                        <Tile header={queryCore.strings.query.statsPerformanceCardHeader}>
                            <VerticalNumericCard>
                                <BoldValueCardItem
                                    value={completionInfo.totalDataScanned}
                                    label={queryCore.strings.query.statsPerformanceDataScannedLabel}
                                    shouldLocalize={false}
                                />
                                <BoldValueCardItem
                                    value={completionInfo.coldDataScanned}
                                    label={queryCore.strings.query.statsPerformanceColdDataAccessedLabel}
                                    shouldLocalize={false}
                                />
                            </VerticalNumericCard>
                        </Tile>
                        <Tile header={queryCore.strings.query.statsExternalDataCardHeader}>
                            <VerticalNumericCard>
                                <BoldValueCardItem
                                    value={`${completionInfo.externalDownloadedSize} bytes`}
                                    label={queryCore.strings.query.statsExternalDataDownloadedSizeLabel}
                                    shouldLocalize={false}
                                />
                                <BoldValueCardItem
                                    value={completionInfo.externalDownloadedFiles}
                                    label={queryCore.strings.query.statsExternalDataDownloadedFilesLabel}
                                    shouldLocalize={false}
                                />
                                <BoldValueCardItem
                                    value={completionInfo.externalSkippedFiles}
                                    label={queryCore.strings.query.statsExternalDataSkippedFilesLabel}
                                    shouldLocalize={false}
                                />
                            </VerticalNumericCard>
                        </Tile>
                    </FlexRow>
                ) : (
                    <div />
                )}
            </DashboardContainer>
            <Panel
                headerText={queryCore.strings.query.statsMetadataJsonPanelTitle}
                isOpen={showMetadataInRightPanel}
                onDismiss={() => setShowMetadataInRightPanel(false)}
                closeButtonAriaLabel={queryCore.strings.query.close}
                isLightDismiss={true}
                isHiddenOnDismiss={false}
                type={PanelType.medium}
                style={{ margin: '0px' }}
                styles={{
                    content: { height: '100%', width: '100%', display: 'flex', alignItems: 'stretch', padding: '0px' },
                    scrollableContent: { height: '100%' },
                }}
            >
                <MonacoEditorJSON json={completionInfo.metadataAsJson} isDark={isDarkTheme} />
            </Panel>
        </>
    );
};

export const QueryResourceConsumption = inject((allStores: { store: IRootStore }) => ({
    settingsTheme: allStores.store.settings.theme,
    // Added while enabling new lints
    // eslint-disable-next-line mobx/no-anonymous-observer
}))(styled(observer(QueryResourceConsumptionBase), getStyles));
