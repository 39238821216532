import * as React from 'react';
import { MessageBar, MessageBarType, ThemeProvider } from '@fluentui/react';
import { Button } from '@fluentui/react-components';
import { ArrowCollapseAll20Regular } from '@fluentui/react-icons';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import { useComputed, useCurrent, useThemeState } from '@kusto/utils';

import type { KweVisualFwkLocale } from '../../types';
import type { VisualOptionKey } from '../../visualOptions';
import type { VisualConfigLayoutFiltered } from '../lib';
import type { VisualOptionsModel, VisualPluginModel } from '../model/model';
import type { RenderConfigurationsOptions } from '../pub';
import { SchemaErrorBanner } from './SchemaErrorBanner';
import { ConfigSegment, EmptyConfigSegment } from './Segment';
import { VisualizationTypePicker } from './VisualizationTypePicker';

import * as styles from './Container.module.scss';

type ClosedSegments = Set<string>;

const SectionDivider = () => <hr className={styles.sectionDivider} />;

interface SegmentsProps<C extends VisualOptionKey, H> {
    t: KweVisualFwkLocale;
    paneModel: VisualOptionsModel;
    inputModel: VisualPluginModel<C, H>;
    segments: ReadonlyArray<VisualConfigLayoutFiltered.Segment<C, H>>;
    closedSegments: ClosedSegments;
    toggleSegmentOpen: (displayName: string) => void;
}

const Segments = <C extends VisualOptionKey, H>({
    t,
    paneModel,
    inputModel,
    segments,
    closedSegments,
    toggleSegmentOpen,
}: SegmentsProps<C, H>) => {
    const result: React.ReactElement[] = [];

    for (const segment of segments) {
        if (segment.inputs) {
            result.push(
                <ConfigSegment<C, H>
                    {...segment}
                    t={t}
                    key={segment.displayName}
                    paneModel={paneModel}
                    toggleSegmentOpen={toggleSegmentOpen}
                    inputs={segment.inputs}
                    inputModel={inputModel}
                    closedSegments={closedSegments}
                />
            );
        } else if (segment.toggle) {
            result.push(
                <EmptyConfigSegment<C, H>
                    key={segment.displayName}
                    paneModel={paneModel}
                    title={segment.displayName}
                    inputModel={inputModel}
                    segmentToggleInfo={segment.toggle}
                />
            );
        }
    }

    return (
        <>
            {result.map((element, i) => (
                <React.Fragment key={element.key}>
                    {element}
                    {i + 1 !== result.length && <SectionDivider />}
                </React.Fragment>
            ))}
        </>
    );
};

interface SectionProps<C extends VisualOptionKey, H> {
    t: KweVisualFwkLocale;
    paneModel: VisualOptionsModel;
    visualModel: VisualPluginModel<C, H>;
    section: VisualConfigLayoutFiltered.Section<C, H>;
    closedSegments: ClosedSegments;
    toggleSegmentOpen: (displayName: string) => void;
}

const Section = observer(function Section<C extends VisualOptionKey, H>({
    t,
    section,
    visualModel: inputModel,
    ...props
}: SectionProps<C, H>) {
    return (
        <>
            {section?.head?.map((input) => inputModel.getInputInstance(input).render(t, false))}
            {/* Segments need a wrapper div to avoid grid-gap */}
            {section?.segments && (
                <div>
                    <Segments {...props} t={t} inputModel={inputModel} segments={section.segments} />
                </div>
            )}
        </>
    );
});

function sectionDisplayNames<C extends VisualOptionKey, H>(
    section: undefined | VisualConfigLayoutFiltered.Section<C, H>
) {
    return section?.segments?.filter((seg) => seg.inputs !== undefined).map((seg) => seg.displayName);
}

interface SectionsContainerProps extends RenderConfigurationsOptions {
    paneModel: VisualOptionsModel;
}

export const SectionsContainer = observer(function SectionsContainer({
    section = 'visual',
    ...props
}: SectionsContainerProps) {
    const theme = useThemeState();
    const [closedSegments] = React.useState(() => mobx.observable.set<string>());
    const t = props.paneModel.args.locale();

    const currentSection = useCurrent(section);

    const { onVisualSectionsCollapse, toggleSegmentOpen, layoutObs } = React.useMemo(() => {
        const layoutObs2 = mobx.computed(() => {
            return props.paneModel._pluginStatus.get().value?.normalizedInputLayout;
        });
        return {
            layoutObs: layoutObs2,
            onVisualSectionsCollapse: mobx.action(() => {
                // Can't reuse the value computed for the ui because depending
                // on it will cause the useMemo to occasionally be recomputed which we'd rather avoid.
                //
                // Maybe we could figure out how to de-dupe it while doing a larger refactor?
                const displayNames = sectionDisplayNames(layoutObs2.get()?.[currentSection.current]);
                if (displayNames) {
                    if (displayNames.some((name) => !closedSegments.has(name))) {
                        // If any section is open, close all sections
                        for (const name of displayNames) {
                            closedSegments.add(name);
                        }
                    } else {
                        // If all sections are closed, open all sections
                        for (const name of displayNames) {
                            closedSegments.delete(name);
                        }
                    }
                }
            }),
            toggleSegmentOpen: mobx.action((displayName: string) => {
                if (closedSegments.has(displayName)) {
                    closedSegments.delete(displayName);
                } else {
                    closedSegments.add(displayName);
                }
            }),
        };
    }, [closedSegments, currentSection, props.paneModel]);

    // If all segments are closed in a section, keep it that way when changing layouts
    React.useEffect(
        () =>
            mobx.reaction(
                () => props.paneModel._pluginStatus.get().value?.normalizedInputLayout,
                (layout, prevLayout) => {
                    if (!layout || !prevLayout) {
                        return;
                    }
                    for (const sectionKey of ['visual', 'interactions'] as const) {
                        const wasCompletelyClosed =
                            sectionDisplayNames(prevLayout[sectionKey])?.every((name) => closedSegments.has(name)) ||
                            false;
                        if (wasCompletelyClosed) {
                            const names = sectionDisplayNames(layout[sectionKey]);
                            if (names) {
                                for (const name of names) {
                                    closedSegments.add(name);
                                }
                            }
                        }
                    }
                },
                { fireImmediately: false }
            ),
        [closedSegments, props.paneModel]
    );

    const layout = layoutObs.get();

    let body: React.ReactNode;

    const visualModel = props.paneModel._pluginStatus.get();

    switch (visualModel.kind) {
        case 'loading':
            body = null;
            break;
        case 'err':
            body = <MessageBar messageBarType={MessageBarType.error}>{visualModel.err}</MessageBar>;
            break;
        case 'ok': {
            const sectionConfig = layout?.[section];
            body = sectionConfig && (
                <Section
                    t={t}
                    paneModel={props.paneModel}
                    visualModel={visualModel.value}
                    section={sectionConfig}
                    closedSegments={closedSegments}
                    toggleSegmentOpen={toggleSegmentOpen}
                />
            );
            break;
        }
    }

    const anyOpen = useComputed(() => {
        return sectionDisplayNames(layoutObs.get()?.[section])?.some((name) => !closedSegments.has(name)) ?? true;
    }, [closedSegments, layoutObs, section]);

    let collapseButtonText: string;
    let collapseButtonTitle: string;

    if (anyOpen) {
        collapseButtonText = t.visualFwk.visualConfig.collapseAllButtonText;
        collapseButtonTitle = t.visualFwk.visualConfig.collapseAllButtonTitle;
    } else {
        collapseButtonText = t.visualFwk.visualConfig.expandAllButtonText;
        collapseButtonTitle = t.visualFwk.visualConfig.expandAllButtonTitle;
    }

    return (
        <>
            <SchemaErrorBanner t={t} model={props.paneModel} />

            {/* Pivot contents rendered outside of Pivot to avoid wrapping div's making styling much harder */}
            <Button
                appearance="subtle"
                disabled={layout === undefined}
                icon={<ArrowCollapseAll20Regular />}
                onClick={onVisualSectionsCollapse}
                className={styles.collapseButton}
                title={collapseButtonTitle}
            >
                {collapseButtonText}
            </Button>
            <ThemeProvider
                className={`${theme.classNames} ${styles.optionsListBody}`} //required for styles to show in query area
                theme={theme.theme.uiFabricTheme} //required to update some fields outside of dashboards (query area)
            >
                {props.headAdditions}
                {section === 'visual' && <VisualizationTypePicker t={t} model={props.paneModel} />}
                {body}
            </ThemeProvider>
        </>
    );
});
