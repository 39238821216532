import * as React from 'react';
import { useId } from '@fluentui/react-hooks';
import { useThemeClassName_unstable } from '@fluentui/react-shared-contexts';
import { Callout, ICalloutProps } from '@fluentui/react/lib/Callout';
import Dialog, { IDialogProps } from '@fluentui/react/lib/Dialog';
import { ILayerProps, Layer } from '@fluentui/react/lib/Layer';
import Modal, { IModalProps } from '@fluentui/react/lib/Modal';
import { IPanelProps, Panel } from '@fluentui/react/lib/Panel';
import { makeStyles, mergeClasses } from '@griffel/react';

import { useThemeState } from './theming';

import * as styles from './panelsWrapped.module.scss';

const rtdPanelsContext = React.createContext<ILayerProps['hostId']>(undefined);

export const RtdPanelsHostContextProvider: React.FC<{ value: ILayerProps['hostId'] }> = ({ value, children }) => {
    return <rtdPanelsContext.Provider value={value}>{children}</rtdPanelsContext.Provider>;
};

function useHostId(overrideHostId: undefined | ILayerProps['hostId']): ILayerProps['hostId'] {
    const config = React.useContext(rtdPanelsContext);

    return overrideHostId ?? config;
}

const useFluent9StyleOverride = makeStyles({ layer: { backgroundColor: 'none', position: 'fixed' } });

export function useFluent8And9ClassName(className?: string) {
    const fluent8Theme = useThemeState();
    const fluent9ClassName = useThemeClassName_unstable();
    const fluent9StyleOverride = useFluent9StyleOverride().layer;
    return React.useMemo(
        () => mergeClasses(fluent8Theme.classNames, fluent9ClassName, fluent9StyleOverride, className),
        [fluent8Theme.classNames, fluent9ClassName, fluent9StyleOverride, className]
    );
}

function useLayerProps(props: undefined | ILayerProps): ILayerProps {
    const hostId = useHostId(props?.hostId);
    const className = useFluent8And9ClassName(props?.className);
    return { ...props, className, hostId };
}

/**
 * `position` defaults to absolute, which would cause modals to flow over
 * content outside of RTD
 */
function useModalProps(props: undefined | IModalProps): IModalProps {
    const layerProps = useLayerProps(props?.layerProps);
    // So many hacks around the dialog's className, but it's the only way to get it to work with Fabric themes
    // (this is a Fluent8 dialog anyway, and when replaced won't need this hack)
    const containerClassName = useFluent8And9ClassName(props?.containerClassName);
    // Sizing needs to be fixed too
    const className = mergeClasses(props?.className, styles.fluent8DialogFix);

    return {
        ...props,
        styles: { root: { position: 'absolute' } },
        layerProps,
        containerClassName,
        className,
    };
}

/*
 * These components render through portals on the document body by default. The
 * wrapper lets the loading context know that a modal is open, so it will render
 * above the modal.
 */
export const RTDCallout: React.FC<ICalloutProps> = (props) => {
    // Passing "hostId" to the layer causes the layer to prevent interaction
    // with items behind it for some reason. TODO: Fix that and re-enable

    // Variables need to be re-applied because the layer is outside of RTDRoot
    const theme = useThemeState();
    return (
        <Callout
            {...props}
            className={
                props.className ? `${theme.classNames} ${props.className}` : theme.classNames
            } /* layerProps={{ hostId, ...props.layerProps }} */
        />
    );
};

export const RTDModal: React.FC<IModalProps> = (props) => {
    props = useModalProps(props);

    return <Modal {...props} />;
};

export interface RTDPanelProps extends IPanelProps {
    withoutHostId?: boolean;
}

export const RTDPanel: React.FC<RTDPanelProps> = (props) => {
    const id = useId(props.id);

    const onDismiss = React.useMemo(
        () =>
            props.onDismiss &&
            ((event?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => {
                // If click wasn't to an element blocked by this panel, ignore
                if (event?.target instanceof Node && !document.getElementById(id)!.contains(event.target)) {
                    return;
                }

                // Safe as long as props aren't mutated
                props.onDismiss!(event);
            }),
        [id, props.onDismiss]
    );

    const layerProps = useLayerProps(props.layerProps);

    return (
        <Panel
            {...props}
            id={id}
            onDismiss={onDismiss}
            className={mergeClasses(props.className, styles.panelFix)}
            layerProps={layerProps}
        />
    );
};

export const RTDDialog: React.FC<IDialogProps> = (props) => {
    const modalProps = useModalProps(props.modalProps);
    return <Dialog {...props} modalProps={modalProps} />;
};

export const RTDLayer: React.FC<ILayerProps> = (props) => {
    props = useLayerProps(props);
    return <Layer {...props} />;
};
