import React from 'react';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import type { VisualInput } from '../../visualConfig/input';
import type { Slot, VisualOptionKey, VisualOptionsKeysFor } from '../../visualOptions';
import { SlotInput } from './SlotInput';

export function createSlotInput<C extends VisualOptionKey>(key: VisualOptionsKeysFor<C, Slot>): VisualInput<C> {
    return {
        id: `slot--${key}`,
        keys: [key],
        Component: observer(function SlotInputWrapper({ t, model: inputModel, disabled }) {
            const onChange = React.useMemo(() => mobx.action((slot: Slot) => inputModel.set(key, slot)), [inputModel]);

            return (
                <SlotInput
                    t={t}
                    id="etp--visual-configuration--slot"
                    slot={inputModel.get(key)}
                    onChange={onChange}
                    disabled={disabled}
                />
            );
        }),
    };
}
