import * as React from 'react';
import { createContext, useContextSelector } from '@fluentui/react-context-selector';
import { headlessTreeRootId } from '../utils/createHeadlessTree';
const defaultContextValue = {
    value: headlessTreeRootId,
    selectionRef: React.createRef(),
    layoutRef: React.createRef(),
    treeItemRef: React.createRef(),
    subtreeRef: React.createRef(),
    actionsRef: React.createRef(),
    expandIconRef: React.createRef(),
    isActionsVisible: false,
    isAsideVisible: false,
    itemType: 'leaf',
    open: false,
    checked: false
};
export const TreeItemContext = createContext(undefined);
export const { Provider: TreeItemProvider } = TreeItemContext;
export const useTreeItemContext_unstable = (selector)=>useContextSelector(TreeItemContext, (ctx = defaultContextValue)=>selector(ctx));
