const bytesMap = [
    { unit: 'PB', bytes: 1125899906842624 }, // Math.pow(1024, 5)
    { unit: 'TB', bytes: 1099511627776 }, // Math.pow(1024, 4)
    { unit: 'GB', bytes: 1073741824 }, // Math.pow(1024, 3)
    { unit: 'MB', bytes: 1048576 }, // Math.pow(1024, 2)
    { unit: 'KB', bytes: 1024 },
    { unit: 'B', bytes: 1 },
];

/**
 * Shorten a number into Bytes, kilobytes, Megabytes, Gigabytes, Terabytes,
 * Petabytes and add the suitable abbreviation: B, KB, MB, GB, TB, PB
 *
 * Localize the results before returning.
 *
 * @example
 * - 1050, en       -> 1.03 KB
 * - 1111111, en    -> 1.06 MB
 * - 1111111, fr    -> 1,06 MB
 * @param bytes Number of bytes
 * @param lang language to localize to. E.g. en, fr, ru, sv, zh-hant. If
 * `undefined` is passed, browser locale is used
 */
export const abbreviateAndLocalizeNumber = (bytes: number, lang: undefined | string): string => {
    if (!Number.isNaN(bytes) && Number.isFinite(bytes)) {
        const mapped = bytesMap.find((mapLine) => {
            return bytes >= mapLine.bytes;
        });
        if (mapped !== undefined) {
            const val = bytes / mapped.bytes;
            return val.toLocaleString(lang, { maximumFractionDigits: 2 }) + mapped.unit;
        }
    }
    return '0';
};
