import React, { useCallback } from 'react';
import { Button } from '@fluentui/react-components';

import { renderIcon } from '../Icon';
import { OptionalTooltip } from '../OptionalTooltip/OptionalTooltip';
import { ActionItem } from './types';
import { keyboardExecute } from './utils';

/** Icon button, that appear in the end of the drop-down */
export const ActionButton: React.FunctionComponent<ActionItem> = ({ children, ...action }) => {
    const onExecute = useCallback(
        (event: React.BaseSyntheticEvent) => {
            action.onClick?.();
            event.stopPropagation(); // prevent dropdown from opening
        },
        [action]
    );

    return (
        <OptionalTooltip content={action.disabled ? undefined : action.tooltip} relationship="label">
            <Button
                aria-label={action.tooltip}
                icon={renderIcon(action.icon)}
                disabled={action.disabled}
                onClick={onExecute}
                onKeyDown={(e) => keyboardExecute(e, onExecute)}
                appearance="transparent"
                size="small"
            />
        </OptionalTooltip>
    );
};
