import React from 'react';
import { TooltipHost } from '@fluentui/react';
import { Button, ButtonProps, Switch, Text } from '@fluentui/react-components';
import { Add20Regular } from '@fluentui/react-icons';
import classNames from 'classnames';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import type { KweVisualFwkLocale } from '../../types';
import { VisualInput, VisualInputSelector } from '../../visualConfig/input';
import { BASE_Y_AXIS_ID, MultipleYAxesConfig, YAxisConfig } from '../../visualOptions';
import { ConfigurationItemRow } from '../configurationList/ConfigurationItemRow';
import { BaseYAxisForm } from './BaseYAxisForm';
import { MultipleYAxesCallout } from './MultipleYAxesCallout';
import { ApplyYAxisChange, MultipleYAxesModel } from './util';

import * as gridStyles from '../configurationList/grid.module.scss';
import * as configListStyles from '../configurationList/styles.module.scss';
import * as multipleYAxesStyles from './styles.module.scss';

function formatYAxisColumn(columns: readonly string[], strings: KweVisualFwkLocale) {
    return `${strings.visualFwk.visualConfig.multipleYAxes.columnDropdownLabel} ${columns.join(', ')}`;
}

const replaceYAxis =
    (yAxis: YAxisConfig): ApplyYAxisChange =>
    (multipleYAxisConfig) => {
        if (multipleYAxisConfig.base.id === yAxis.id) {
            return { ...multipleYAxisConfig, base: yAxis };
        }
        const additionalYAxes = multipleYAxisConfig.additional;
        const index = additionalYAxes.findIndex((y) => y.id === yAxis.id);

        if (index === -1) {
            return undefined;
        }
        return {
            ...multipleYAxisConfig,
            additional: [...additionalYAxes.slice(0, index), yAxis, ...additionalYAxes.slice(index + 1)],
        };
    };

type AddYAxisButtonProps = ButtonProps & {
    text: string;
    tooltipText?: string;
};

const AddYAxisButton: React.FC<AddYAxisButtonProps> = ({ text, tooltipText, ...buttonProps }) => {
    return (
        <TooltipHost content={tooltipText} hostClassName={multipleYAxesStyles.addYAxisButton}>
            <Button appearance="primary" icon={<Add20Regular />} {...buttonProps}>
                {text}
            </Button>
        </TooltipHost>
    );
};

function rowId(id: string): string {
    return `multiple-y-axis--row--${id}`;
}

export interface MultipleYAxesProps {
    t: KweVisualFwkLocale;
    baseAxis: YAxisConfig;
    inferredColumns: null | readonly string[];
    additionalAxes: readonly YAxisConfig[];
    showMultiplePanels: boolean;
    onChange: (applyChange: ApplyYAxisChange) => void;
    model: MultipleYAxesModel;
}

export const MultipleYAxes: React.FC<MultipleYAxesProps> = observer(function MultipleYAxes({
    t,
    baseAxis,
    inferredColumns,
    additionalAxes,
    showMultiplePanels,
    onChange,
    model,
}) {
    const [calloutAxisId, setCalloutAxisId] = React.useState<undefined | string>();

    const yColumnNames = React.useMemo(() => {
        return inferredColumns === null ? [] : inferredColumns;
    }, [inferredColumns]);

    // adding 1 to include the base y axis
    const addButtonDisabled = additionalAxes.length + 1 >= yColumnNames.length;

    const { onCalloutClose, onCalloutSave, onEditClicked } = React.useMemo(() => {
        const onCalloutCloseMemo = () => setCalloutAxisId(undefined);

        const onCalloutSaveMemo = (newYAxis: YAxisConfig) => {
            onChange(replaceYAxis(newYAxis));
            setCalloutAxisId((id) => {
                if (id === newYAxis.id) {
                    return undefined;
                }
                return id;
            });
        };
        const onEditClickedMemo = (item: YAxisConfig) => setCalloutAxisId(item.id);

        return {
            onCalloutClose: onCalloutCloseMemo,
            onCalloutSave: onCalloutSaveMemo,
            onEditClicked: onEditClickedMemo,
        };
    }, [onChange]);

    const availableColumns = getAvailableYColumns(inferredColumns, additionalAxes);

    const onAddYAxis = React.useCallback(() => {
        const id = crypto.randomUUID();
        const addYAxis: ApplyYAxisChange = (multipleYAxesConfig) => {
            const additionalYAxes = multipleYAxesConfig.additional;
            // Total number of axes (additional + base) cannot exceed number on y columns
            if (additionalYAxes.length + 1 >= yColumnNames.length || availableColumns.length <= 1) {
                return multipleYAxesConfig;
            }

            const newAxis: YAxisConfig = {
                id,
                columns: [availableColumns[availableColumns.length - 1]],
                label: '',
                yAxisMaximumValue: null,
                yAxisMinimumValue: null,
                yAxisScale: 'linear',
                horizontalLines: [],
            };

            return {
                ...multipleYAxesConfig,
                additional: [...additionalYAxes, newAxis],
            };
        };
        onChange(addYAxis);
        setCalloutAxisId(id);
    }, [onChange, availableColumns, yColumnNames]);

    const onDeleteYAxis = React.useCallback(
        (item: YAxisConfig) => {
            const deleteYAxis: ApplyYAxisChange = (multipleYAxesConfig) => {
                const additionalYAxes = multipleYAxesConfig.additional;
                const index = additionalYAxes.findIndex((axis) => axis.id === item.id);

                if (index === -1) {
                    return undefined;
                }
                return {
                    ...multipleYAxesConfig,
                    additional: [...additionalYAxes.slice(0, index), ...additionalYAxes.slice(index + 1)],
                };
            };
            onChange(deleteYAxis);
            setCalloutAxisId((id) => {
                if (id === item.id) {
                    return undefined;
                }
                return id;
            });
        },
        [onChange]
    );

    const onToggleMultiplePanels = React.useCallback(() => {
        const toggleMultiplePanels: ApplyYAxisChange = (multipleYAxesConfig) => ({
            ...multipleYAxesConfig,
            showMultiplePanels: !multipleYAxesConfig.showMultiplePanels,
        });
        onChange(toggleMultiplePanels);
    }, [onChange]);

    React.useLayoutEffect(() => {
        if (additionalAxes.length === 0) {
            setCalloutAxisId(undefined);
        }
    }, [additionalAxes.length]);

    const allYAxes = [baseAxis, ...additionalAxes];

    const addButtonTooltip = addButtonDisabled
        ? t.visualFwk.visualConfig.multipleYAxes.addYAxisDisabledTooltip
        : undefined;

    const calloutYAxis = calloutAxisId === undefined ? undefined : allYAxes.find((r) => r.id === calloutAxisId);

    return (
        <>
            {additionalAxes.length === 0 && (
                <div className={multipleYAxesStyles.formWrapper}>
                    <BaseYAxisForm t={t} model={model} onChange={onChange} />
                    <AddYAxisButton
                        text={t.visualFwk.visualConfig.multipleYAxes.addYAxisButtonText}
                        onClick={onAddYAxis}
                        disabled={addButtonDisabled}
                        tooltipText={addButtonTooltip}
                    />
                </div>
            )}
            {additionalAxes.length > 0 && (
                <>
                    <div className={configListStyles.configurationList}>
                        {allYAxes.map((axis) => (
                            <ConfigurationItemRow<YAxisConfig>
                                key={axis.id}
                                id={rowId(axis.id)}
                                onDelete={onDeleteYAxis}
                                item={axis}
                                onEdit={onEditClicked}
                                disableDelete={axis.id === BASE_Y_AXIS_ID}
                                editButtonTitle={t.utils.util.buttons.edit}
                                deleteButtonTitle={t.utils.util.buttons.delete}
                            >
                                <ItemComponent
                                    strings={t}
                                    item={axis}
                                    model={model}
                                    inferredColumns={inferredColumns}
                                />
                            </ConfigurationItemRow>
                        ))}
                    </div>
                    <AddYAxisButton
                        text={t.visualFwk.visualConfig.multipleYAxes.addYAxisButtonText}
                        onClick={onAddYAxis}
                        disabled={allYAxes.length >= yColumnNames.length}
                        tooltipText={addButtonTooltip}
                    />
                    <Switch
                        checked={showMultiplePanels && yColumnNames.length > 0}
                        label={t.visualFwk.visualConfig.multipleYAxes.multiplePanelsToggleLabel}
                        labelPosition="above"
                        disabled={yColumnNames.length === 0}
                        onChange={onToggleMultiplePanels}
                    />
                </>
            )}
            {calloutYAxis && (
                <MultipleYAxesCallout
                    t={t}
                    model={model}
                    inferredColumns={inferredColumns}
                    onClose={onCalloutClose}
                    onSave={onCalloutSave}
                    yAxis={calloutYAxis}
                    targetId={`${rowId(calloutYAxis.id)}`}
                    baseYAxisColumns={availableColumns}
                />
            )}
            {additionalAxes.length === 0 && (
                <Switch
                    checked={showMultiplePanels && yColumnNames.length === 1}
                    labelPosition="above"
                    label={t.visualFwk.visualConfig.multipleYAxes.splitMetricsToggleLabel}
                    disabled={yColumnNames.length !== 1}
                    onChange={onToggleMultiplePanels}
                />
            )}
        </>
    );
});

export const getAvailableYColumns = (yColumns: null | readonly string[], yAxes: readonly YAxisConfig[]) => {
    const columnsInUse = yAxes.flatMap((axis) => axis.columns);
    return yColumns === null ? [] : yColumns.filter((y) => !columnsInUse.includes(y));
};

interface ItemComponentProps {
    strings: KweVisualFwkLocale;
    item: YAxisConfig;
    model: MultipleYAxesModel;
    inferredColumns: null | readonly string[];
}

const ItemComponent: React.FC<ItemComponentProps> = observer(function ItemComponent({
    strings,
    item,
    model,
    inferredColumns,
}) {
    let columns = item.columns;
    const multipleYAxes = model.get('multipleYAxes');
    const isBaseAxis = item.id === BASE_Y_AXIS_ID;

    if (isBaseAxis) {
        columns = getAvailableYColumns(inferredColumns, multipleYAxes.additional);
    }
    const axisColumnsFormatted = formatYAxisColumn(columns, strings);
    const axisLabel = isBaseAxis
        ? `${strings.visualFwk.visualConfig.multipleYAxes.mainAxis}: ${axisColumnsFormatted}`
        : axisColumnsFormatted;

    return (
        <span className={classNames(gridStyles.content, configListStyles.configItemText)}>
            <Text truncate={true} wrap={false} weight={isBaseAxis ? 'semibold' : 'regular'}>
                {axisLabel}
            </Text>
            <Text size={200}>{item.label}</Text>
        </span>
    );
});

export function multipleYAxesManagedConfig<H = unknown>(
    inferredYColumns?: VisualInputSelector<'multipleYAxes', undefined | readonly string[], H>
): VisualInput<'multipleYAxes', H> {
    return {
        id: 'multipleYAxes',
        keys: ['multipleYAxes'],
        Component: observer(function MultipleYAxesManagedConfig({ t, model }) {
            const { base, additional, showMultiplePanels } = model.get('multipleYAxes');

            const { onChange, inferredColumns } = React.useMemo(
                () => ({
                    onChange: mobx.action(
                        (
                            applyChange: (multipleYAxesConfig: MultipleYAxesConfig) => MultipleYAxesConfig | undefined
                        ) => {
                            const updatedYAxes = applyChange(model.get('multipleYAxes'));

                            if (updatedYAxes) {
                                model.set('multipleYAxes', updatedYAxes);
                            }
                        }
                    ),
                    inferredColumns: model.resolveSelector(inferredYColumns),
                }),
                [model]
            );

            return (
                <MultipleYAxes
                    t={t}
                    baseAxis={base}
                    inferredColumns={inferredColumns.get() ?? null}
                    additionalAxes={additional}
                    showMultiplePanels={showMultiplePanels}
                    onChange={onChange}
                    model={model}
                />
            );
        }),
    };
}
