$selectedCellColor: var(--themeLight);

.grid {
    font-size: 0;
    display: grid;
    grid-template-columns: repeat(5, auto);
    margin-bottom: 12px;
}

.grid:not(:hover) .cell.selected::before {
    background-color: $selectedCellColor;
}

.cell {
    // Add padding so that a cell is not hovered until the colored part has been
    // entered
    padding: 0 8px 8px 0;
    &:nth-child(5n) {
        padding-right: 0;
    }
    &:nth-last-child(-n + 5) {
        padding-bottom: 0;
    }
    cursor: pointer;

    &::before {
        content: '';
        display: block;
        width: 32px;
        height: 32px;
        background-color: var(--neutralLighterAlt);
    }

    &:focus {
        margin: -1px;
        &::before {
            border: 1px solid var(--black);
        }
        outline: none;
    }

    &.hovered::before {
        background-color: $selectedCellColor;
    }

    // reset button style
    border: none;
    background: none;
    margin: 0;
}

.selectedText {
    display: block;
    width: 192px;
}
