import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { mergeStyles } from '@fluentui/merge-styles';
import { Icon, IStyle } from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import type * as kusto from '@kusto/client';
import { assertNever } from '@kusto/utils';
import type { Theme } from '@kusto/utils';
import { ColorRule, conditionalFormattingColors } from '@kusto/visual-fwk';

import type { ConditionalFormattingConfig, ExtendedVisualizationOptions } from '../../utils/visualization';
import type { ConditionalFormattingOptions, VColorRuleByCondition } from './types';

export const colorRulesColorLabels = {
    red: 'Critical',
    yellow: 'Warning',
    green: 'Healthy',
    blue: 'Normal',
};

const MIDPOINTS_IN_THEME = 10;

export const getConditionalFormattingColors = (
    conditionalFormattingOptions: ConditionalFormattingOptions,
    theme: 'light' | 'dark'
) => {
    const colorName = conditionalFormattingOptions.color;
    const colorStyle = conditionalFormattingOptions.colorStyle;
    const colorValue = colorStyle && colorName && conditionalFormattingColors[theme][colorStyle][colorName];
    const invertedTextColors = {
        dark: '#323130',
        light: '#FFFFFF',
    };
    const nonInvertedTextColors = {
        dark: '#f3f2f1',
        light: '#000',
    };
    let textColor;
    let backgroundColor;
    let headerColor;
    let labelColor;
    if (colorValue) {
        if (colorStyle === 'light') {
            textColor = colorValue.color;
        } else {
            textColor = colorValue.invertText ? invertedTextColors[theme] : nonInvertedTextColors[theme];
            backgroundColor = colorValue.color;
            headerColor = textColor;
            labelColor = textColor;
        }
    }
    return {
        textColor,
        backgroundColor,
        headerColor,
        labelColor,
    };
};

interface ConditionalFormattingCellParams extends ICellRendererParams {
    theme?: Theme;
    visualizationOptions?: ExtendedVisualizationOptions;
    columnTypeByName: Record<string, kusto.ColumnType>;
    columnUniqueValues: Record<string, Record<string, number>>;
    edgeValues: Record<string, { min: string; max: string }>;
}

/**
 * @param baseCell - Base cell renderer to wrap with conditionalFormatting
 * @param params - The renderer params
 */
// Added while enabling lints
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const wrapCellWithConditionalFormatting = (baseCell: (p: any) => React.ReactNode) => {
    return (params: ConditionalFormattingCellParams) => {
        const conditionalFormattingConfig: ConditionalFormattingConfig | undefined =
            params.visualizationOptions?.ColumnFormatting?.ConditionalFormattingConfig;
        const useConditionalFormatting = conditionalFormattingConfig?.colorRulesDisabled === false;
        if (!useConditionalFormatting) {
            return baseCell(params);
        }
        const columnName = params.colDef?.headerName || '';
        const colOptions = getConditionalFormattingOptions({
            row: params.data,
            columnsToType: params.columnTypeByName,
            edgeValues: params.edgeValues,
            columnsUniqueValues: params.columnUniqueValues,
            applyTo: 'cells',
            targetColumn: columnName,
            conditionalFormattingOptions: conditionalFormattingConfig,
        });

        const formattedParams: ConditionalFormattingCellParams = {
            ...params,
            value: colOptions.hideText ? '' : params.value,
            valueFormatted: colOptions.hideText ? '' : params.valueFormatted,
        };

        return (
            <div className={`cf-wrapper`}>
                {colOptions.iconName && <Icon iconName={colOptions.iconName} className="cf-icon" />}
                <span className="cf-content">{baseCell(formattedParams)}</span>
                {!isEmpty(colOptions.subLabel?.trim()) && <span className="cf-sub-label">{colOptions.subLabel}</span>}
            </div>
        );
    };
};

export function getThemeColor(
    themeName: ColorRule.Theme,
    row: kusto.KustoQueryResultRowObject,
    columnName: string,
    columnType: kusto.ColumnType,
    minValue?: string,
    maxValue?: string,
    reverseTheme?: boolean
): string {
    const value = row[columnName];
    if (value === undefined || value === null || minValue === undefined || maxValue === undefined) {
        return '';
    }
    const convertedValue = convertValueToColumnType(value.toString(), columnType);
    const convertedMaxValue = convertValueToColumnType(maxValue, columnType);
    const convertedMinValue = convertValueToColumnType(minValue, columnType);
    // Currently we support only numbers. Later we should support dates as well
    // Also check if the value is between the min/max values. If not - no need to color it
    if (
        typeof convertedMaxValue === 'number' &&
        typeof convertedMinValue === 'number' &&
        typeof convertedValue === 'number' &&
        convertedValue <= convertedMaxValue &&
        convertedValue >= convertedMinValue
    ) {
        const diff = convertedMaxValue - convertedMinValue;
        const delta = diff / MIDPOINTS_IN_THEME;
        const midpoints = [];
        for (let i = 1; i < MIDPOINTS_IN_THEME; i++) {
            midpoints.push(convertedMinValue + delta * i);
        }
        midpoints.push(convertedMaxValue);
        // Since the midpoints are sorted in ascending order, find the first midpoint that is greater than our value.
        let themeColorIndex = midpoints.findIndex((midpoint) => convertedValue <= midpoint);
        if (reverseTheme) {
            themeColorIndex = midpoints.length - 1 - themeColorIndex;
        }
        return `${themeName}-theme-${themeColorIndex}`;
    }
    return '';
}

// Generates a class name according to the theme, style and color name.
export const generateCFClassName = ({
    theme,
    colorStyle,
    colorName,
    excludeCFClassName,
}: {
    theme?: string;
    colorStyle?: string;
    colorName?: string;
    excludeCFClassName?: boolean;
}) => {
    if (!theme || !colorStyle || !colorName) {
        return '';
    }
    let className = `${theme}-${colorStyle}-${colorName}`.toLocaleLowerCase();
    className = excludeCFClassName ? className : `${cfClassName} ${className}`;
    return className.toLowerCase();
};

export const getConditionalFormattingClassName = () => {
    return mergeStyles(getConditionalFormattingStyles());
};

const getCFStyles = ({ textColor, backgroundColor }: { textColor?: string; backgroundColor?: string }) => {
    const wrapperStyles: IStyle = {
        color: textColor,
    };
    const iconStyles: IStyle = {
        color: textColor,
        selectors: {
            // Sometimes we use SVGs if the icon is not in the Fabric icons library. we need to alter their CSS spesifically
            path: {
                fill: textColor,
            },
        },
    };
    const contentStyles: IStyle = {};
    const subLabelStyles: IStyle = {
        color: textColor,
        borderColor: textColor ?? '',
    };

    return {
        backgroundColor,
        wrapperStyles,
        contentStyles,
        subLabelStyles,
        iconStyles,
    };
};

function generateCSSRulesForCombination(theme?: string, colorStyle?: string, colorName?: string) {
    const colorSelectors: { [key: string]: IStyle } = {};

    const className = generateCFClassName({ theme, colorStyle, colorName, excludeCFClassName: true });
    const colors = getConditionalFormattingColors(
        { color: colorName, colorStyle: colorStyle } as ConditionalFormattingOptions,
        theme as 'light' | 'dark'
    );
    const { wrapperStyles, iconStyles, contentStyles, subLabelStyles, backgroundColor } = getCFStyles({
        textColor: colors.textColor,
        backgroundColor: colors.backgroundColor,
    });
    colorSelectors[`&.${className}`] = {
        backgroundColor: `${backgroundColor} !important`,
        color: `${colors.textColor}`,
        selectors: {
            '.cf-wrapper': {
                ...wrapperStyles,
                selectors: {
                    '.cf-content': {
                        ...contentStyles,
                    },
                    '.cf-icon': {
                        ...iconStyles,
                    },
                    '.cf-sub-label': {
                        ...subLabelStyles,
                    },
                    a: {
                        color: wrapperStyles.color + ' !important',
                    },
                },
            },
        },
    };
    colorSelectors[`&.ag-cell.${className}`] = {
        color: colors.textColor,
        selectors: {
            '.cf-wrapper': {
                color: colors.textColor,
                selectors: {
                    '.cf-content': {
                        color: colors.textColor,
                    },
                    '.cf-icon': {
                        color: colors.textColor,
                    },
                    '.cf-sub-label': {
                        color: colors.textColor,
                    },
                },
            },
        },
    };
    return colorSelectors;
}

export const getConditionalFormattingStyles = (): IStyle => {
    let colorSelectors: { [key: string]: IStyle } = {};
    // Generate all possible combinations of classNames. (theme-style-color)
    Object.entries(conditionalFormattingColors).forEach(([theme, themeColors]) => {
        Object.entries(themeColors).forEach(([colorStyle, colors]) => {
            Object.keys(colors).forEach((colorName) => {
                const selectors = generateCSSRulesForCombination(theme, colorStyle, colorName);
                colorSelectors = {
                    ...colorSelectors,
                    ...selectors,
                };
            });
        });
    });

    return {
        selectors: colorSelectors,
    };
};

// Base className from mergeStyles
export const cfClassName = getConditionalFormattingClassName();

const NUMBER_OF_HIGHLIGHT_COLORS = 32;

interface GetConditionalFormattingOptionsArgs {
    row: kusto.KustoQueryResultRowObject;
    columnsToType?: Record<string, kusto.ColumnType>;
    edgeValues?: Record<string, { min: string; max: string }>;
    columnsUniqueValues?: Record<string, Record<string, number>>;
    applyTo: 'cells' | 'rows';
    targetColumn?: string;
    conditionalFormattingOptions?: ConditionalFormattingConfig;
    inferredColumnName?: string;
}

// Returns a map between column name to its formatting options
export const getConditionalFormattingOptions = ({
    row,
    columnsToType = {},
    edgeValues = {},
    columnsUniqueValues = {},
    applyTo,
    targetColumn,
    conditionalFormattingOptions,
    inferredColumnName,
}: GetConditionalFormattingOptionsArgs): ConditionalFormattingOptions => {
    if (!conditionalFormattingOptions || conditionalFormattingOptions.colorRulesDisabled || !row) {
        return {};
    }
    const { colorRules } = conditionalFormattingOptions;
    // We want to always take the last rule first
    for (const rule of [...colorRules].reverse()) {
        if (rule.applyTo !== applyTo) {
            continue;
        }
        if (rule.ruleType === 'colorByCondition') {
            const columnName = rule.applyToColumn ?? inferredColumnName;
            if (
                applyTo === 'cells' &&
                (columnName === undefined || (targetColumn !== undefined && targetColumn !== columnName))
            ) {
                continue;
            }
            const applyRules = doesRowMatchRuleConditions(rule, row, columnsToType, inferredColumnName);
            if (applyRules) {
                return rule.options;
            }
        } else {
            const columnName = rule.column ?? inferredColumnName;
            if (columnName === undefined || (targetColumn !== undefined && targetColumn !== columnName)) {
                continue;
            }
            let colorByValueClassName: string | undefined;
            if (rule.themeName) {
                const minValue = rule.minValue ?? edgeValues[columnName]?.min;
                const maxValue = rule.maxValue ?? edgeValues[columnName]?.max;
                colorByValueClassName = getThemeColor(
                    rule.themeName,
                    row,
                    columnName,
                    columnsToType[columnName],
                    minValue,
                    maxValue,
                    rule.reverseTheme
                );
            } else {
                // If we don't have a theme - then we need to calculate color based on unique values
                const val = row[columnName];
                if (val !== null && val !== undefined) {
                    const uniqueValues = columnsUniqueValues[columnName];
                    const idx = uniqueValues[val.toString()];
                    colorByValueClassName = `highlight-color-${idx % NUMBER_OF_HIGHLIGHT_COLORS}`;
                }
            }
            return { ...rule.options, colorByValueClassName };
        }
    }

    return {};
};

function doesRowMatchRuleConditions(
    rule: VColorRuleByCondition,
    row: kusto.KustoQueryResultRowObject,
    columnsToType: Record<string, kusto.ColumnType>,
    inferredColumnName?: string
) {
    const matches = rule.conditions.map((condition) => {
        const conditionColumnName = condition.column ?? inferredColumnName;
        if (conditionColumnName === undefined || columnsToType[conditionColumnName] === undefined) {
            return false;
        }
        const columnType = columnsToType[conditionColumnName];
        const value = row[conditionColumnName];
        if (value === undefined || value === null) {
            return false;
        }
        const convertedValue = convertValueToColumnType(value.toString(), columnType);
        if (convertedValue === undefined) {
            return false;
        }
        const convertedConditionValues = condition.values.map((v) =>
            convertValueToColumnType(v.toString(), columnType)
        );
        const [firstConditionValue, secondConditionValue] = convertedConditionValues;

        if (firstConditionValue === undefined) {
            return false;
        }

        switch (condition.operator) {
            case '>':
                return convertedValue > firstConditionValue;
            case '>=':
                return convertedValue >= firstConditionValue;
            case '<':
                return convertedValue < firstConditionValue;
            case '<=':
                return convertedValue <= firstConditionValue;
            case '==':
                return convertedValue === firstConditionValue;
            case '!=':
                return convertedValue !== firstConditionValue;
            case 'between':
                return (
                    secondConditionValue !== undefined &&
                    convertedValue >= firstConditionValue &&
                    convertedValue <= secondConditionValue
                );
            default:
                return false;
        }
    });
    return rule.chainingOperator === 'and' ? !matches.includes(false) : matches.includes(true);
}

function convertValueToColumnType(value: string, columnType: kusto.ColumnType) {
    // If columnType is dynamic, at least try to convert to a number

    switch (columnType) {
        case 'dynamic': {
            const numValue = parseFloat(value);
            return !isNaN(numValue) ? numValue : undefined;
        }
        case 'int':
        case 'real':
        case 'long':
        case 'decimal': {
            const numValue = parseFloat(value);
            return !isNaN(numValue) ? numValue : undefined;
        }
        case 'datetime': {
            const date = new Date(value);
            return date instanceof Date && !isNaN(date.getTime()) ? date.getTime() : undefined;
        }
        case 'timespan': {
            return moment.duration(value).asMilliseconds();
        }
        case 'string': {
            // TODO needs migration and better UX
            // to avoid an empty rule when it is not intended (after adding)
            // https://msazure.visualstudio.com/One/_workitems/edit/28958282/
            return value; // value === '' ? undefined : value;
        }
        case 'bool':
        case 'guid': {
            return value;
        }
        default: {
            assertNever(columnType);
        }
    }
}
