import * as React from 'react';
import { ActionButton, IIconProps, ITooltipHostStyles, TooltipHost } from '@fluentui/react';
import * as clipboard from 'clipboard-polyfill';

interface Props {
    messageBeforeCopy: string;
    messageAfterCopy: string;
    textToCopy: string;
    iconProps?: IIconProps;
    label?: string;
    tooltipHostStyles?: ITooltipHostStyles;
    onClick?: () => void;
}

interface State {
    didCopyActivityId: boolean;
}

export class CopyTextActionButton extends React.Component<Props, State> {
    private tooltipHostId = 'copyTextActionButtonTooltipHost';
    constructor(props: Props) {
        super(props);
        this.state = { didCopyActivityId: false };
    }

    render() {
        this.addRoleAlertAfterCopy();
        return (
            <TooltipHost
                content={this.state.didCopyActivityId ? this.props.messageAfterCopy : this.props.messageBeforeCopy}
                id={this.tooltipHostId}
                styles={this.props.tooltipHostStyles}
            >
                <ActionButton
                    ariaDescription={this.props.messageBeforeCopy}
                    iconProps={this.props.iconProps || { iconName: 'ClipboardSolid' }}
                    onClick={() => {
                        clipboard.writeText(this.props.textToCopy);
                        this.setState({ didCopyActivityId: true });
                        setTimeout(() => {
                            this.deleteRole();
                            this.setState({ didCopyActivityId: false });
                        }, 5000);
                    }}
                    text={this.props.label}
                />
            </TooltipHost>
        );
    }

    /** Add the attribute role='alert' to TooltipHost. This will trigger the screen reader to say "Copied". */
    private addRoleAlertAfterCopy() {
        // Adding a timeout, to make sure that the attribute is set after the element has already added to the DOM.
        setTimeout(() => {
            if (this.state.didCopyActivityId) {
                document.getElementById(this.tooltipHostId)?.setAttribute('role', 'alert');
            }
        }, 10);
    }

    /** Deletes the attribute role='alert' from TooltipHost. Without deleting the attribute screen reader says nothing. */
    private deleteRole() {
        document.getElementById(this.tooltipHostId)?.removeAttribute('role');
    }
}
