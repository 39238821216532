import React from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
    CellEvent,
    CellFocusedEvent,
    CellRangeParams,
    ColDef,
    Column,
    ColumnApi,
    Events,
    GridApi,
    GridOptions,
    GridReadyEvent,
    ICellRendererComp,
    ICellRendererParams,
    IRowNode,
    NavigateToNextCellParams,
    RangeSelectionChangedEvent,
    RowClassParams,
    RowNode,
    SuppressKeyboardEventParams,
} from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
// Keep the reference in order to avoid importing of the library
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { FontIcon, Link } from '@fluentui/react';
import { KeyCodes } from '@fluentui/utilities';
import * as clipboard from 'clipboard-polyfill';
import concat from 'lodash/concat';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import isEqualWith from 'lodash/isEqualWith';
import merge from 'lodash/merge';
import memoize from 'memoize-one';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { isMacOs } from 'react-device-detect';
import ReactDOM from 'react-dom';
import Pane from 'react-split-pane/lib/Pane';

import { Theme, UnknownDataFrameValue } from '@kusto/utils';

import './agGridExpandView.scss';

import {
    buildEditorAndJsonModels,
    buildJsonPathForEachLine,
    convertJPathArrayRepresentationToString,
    getJsonStringAndRelativeLineNumber,
    GridWithSearchProps,
    JsonModelData,
    ModelJSONNode,
} from '@kusto/ag-grid';

import { ExpandTopBar } from '../components/ExpandTopBar';
import { StyledSplitPane } from '../components/StyledReactSplitPane';
import type { VisualizationsLocale, VisualizationsStrings } from '../types';
import { wrapCellWithConditionalFormatting } from '../utils/conditionalFormatting/conditionalFormatting';
import type { RenderAsLinkFn } from '../utils/visualization';

//TODO: Move to a separate file in @kusto/ag-grid package
export const CellText: React.FC<RendererParams> = (params) => {
    const text = params.valueFormatted || params.value;
    const { renderAsLink } = params;

    const plainValue = <>{text}</>;

    if (!renderAsLink) {
        return plainValue;
    }

    const column = params.colDef?.headerName;
    const rowData: undefined | Record<string, UnknownDataFrameValue> = params.node.data;

    if (!column || !rowData) {
        return plainValue;
    }

    const url = renderAsLink(column, rowData);

    if (url) {
        // important! `highlighted-url` is not just used for styling. It also has business logic implications.
        // To learn more, search for targetClasses.contains('highlighted-url') to see how it is being used.
        // important! `data-url` is used to override the url for the link. Search for `data-url`.

        return (
            <Link className="highlighted-url" data-url={url} target="_blank" onMouseUp={(e) => e.preventDefault()}>
                {text}
            </Link>
        );
    }

    return plainValue;
};

interface RendererParams extends ICellRendererParams {
    /**
     * When undefined, the cell is rendered as-is.
     * When defined, we render the cell with
     * the config.
     */
    renderAsLink?: RenderAsLinkFn;
    theme: Theme;
    strings: VisualizationsStrings;
}

export function closableCell(params: RendererParams) {
    return (
        <>
            <CellText {...params} />
            <FontIcon
                iconName="Cancel"
                className="expand-close"
                aria-label={params.strings ? params.strings.agGrid.expand.expanded : ''}
                style={{ fontWeight: 'bold', top: 0, fontSize: 10 }}
            />
        </>
    );
}

const ExpandCellRenderer = (props: ICellRendererParams) => {
    // Need to add aria-expanded to the expand cell to fix accessibility bug
    // Link to the bug: https://msazure.visualstudio.com/One/_workitems/edit/8182264
    props.eGridCell.setAttribute('aria-expanded', props.node.expanded?.toString() ?? 'false');

    if (props.node.group === true) {
        return null;
    }
    if (props.node.expanded) {
        return <FontIcon iconName="ChevronDown" style={{ width: 11, fontSize: 10, float: 'none' }} />;
    } else {
        return (
            <FontIcon
                iconName="ChevronRight"
                style={{
                    fontSize: 10,
                    width: 11,
                }}
            />
        );
    }
};

class DetailedCellRender implements ICellRendererComp {
    private eGui?: HTMLSpanElement;

    init(params: ICellRendererParams): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.eGui = (params as any).expandPortal;
    }

    refresh(params: ICellRendererParams): boolean {
        if (params.node.rowIndex === null) {
            params.node.setExpanded(false);
        } else {
            this.init(params);
        }
        return true;
    }
    getGui(): HTMLElement {
        // Added while enabling lints

        return this.eGui!;
    }
}

const MONACO_ACTION_ID_PREFIX = 'editor.action.detailsView.';
export const EXPAND_ROW_COL_ID = '__expandRowColID';
const MONACO_INITIAL_CURSOR_STATE: monaco.editor.ICursorState = {
    inSelectionMode: false,
    position: { lineNumber: 1, column: 1 },
    selectionStart: { lineNumber: 1, column: 1 },
};

export enum DetailsViewType {
    InGrid = 0,
    BelowGrid,
    ExternalPanel,
}

// @deprecated use Same enum from @kusto/ag-grid
export enum ContentViewModeType {
    Full = 0,
    Compact,
}

interface State {
    expanded: boolean;
    jsonModelData?: JsonModelData;
}

export interface GridExpandView<T extends HTMLElement = HTMLElement> {
    onReady: (container: T | null) => void;
    onDismissed: () => void;
}

export interface GridWithExpandOldProps extends GridWithSearchProps {
    theme?: Theme;
    openExternalView?: (expandView: GridExpandView) => void;
    closeExternalView?: (expandView: GridExpandView) => void;
    onEditorLoaded?: (editor: monaco.editor.IStandaloneCodeEditor) => void;
    onEditorOpen?: (internal: boolean) => void;
    onEditorClose?: () => void;
    onExpandTypeChange?: (expandType: DetailsViewType) => void;
    onRequestPaste?: (content: string) => void;
    /**
     * Called when a user clicks a link.
     * @param url A url to the clicked link
     * @param ctrlOrCommandPressed return true if, in addition to clicking the link, the user also pressed the ctrl key (on windows) or the command key (on MacOS)
     * */
    renderExpandBarItems?: (isCompactMode?: boolean) => JSX.Element;
    onLinkClicked?: (url: string, ctrlOrCommandPressed: boolean) => void;
    hideEmptyCells?: boolean;
    expandType?: DetailsViewType;
    contentViewModeType?: ContentViewModeType;
    closeExpandViewOnClick?: boolean;
    mouseWheelZoom?: boolean;
    locale: VisualizationsLocale;
    renderEditor: (
        editorDidMount: (editor: monaco.editor.IStandaloneCodeEditor, _monaco: typeof monaco) => void,
        options: monaco.editor.IStandaloneEditorConstructionOptions,
        theme?: Theme
    ) => JSX.Element;
    disableJPath?: boolean;
}

export class GridWithExpandOld extends React.Component<GridWithExpandOldProps, State> {
    static defaultProps = {
        onExpandTypeChange: () => {
            /** */
        },
        onRequestPaste: () => {
            /** */
        },
        expandType: DetailsViewType.InGrid,
        contentViewModeType: ContentViewModeType.Full,
        renderExpandBarItems: () => <></>,
    };

    private gridApi?: GridApi;
    private columnApi?: ColumnApi;

    private lastExpandCell: { rowId?: string; column?: Column; updated?: DOMHighResTimeStamp } = {};
    private lastDetailedRowHeight?: number;
    private lastDoubleClickedTimestamp = 0;
    private lastClickedTimestamp = 0;

    private expandPortal: HTMLElement = document.createElement('span');
    private detailedViewSelectionPortal: HTMLElement = document.createElement('div');
    private containerRef = React.createRef<HTMLDivElement>();
    private belowGridRef = React.createRef<HTMLDivElement>();
    private externalViewContainer: HTMLElement | null = null;
    private updateValueQueue: {
        inProgress: boolean;
        updateWaiting: boolean;
    } = {
        inProgress: false,
        updateWaiting: false,
    };
    private ExpandViewControl: GridExpandView = {
        onReady: (container: HTMLElement | null) => {
            this.externalViewContainer = container;
            if (this.expandPortal) {
                this.appendDetailsViewToExternalPanel();
            }
        },
        onDismissed: () => {
            if (this.expandPortal) {
                this.closeDetails();
            }
            this.externalViewContainer = null;
        },
    };

    private mainEditor?: monaco.editor.IStandaloneCodeEditor;
    private shadowEditor?: monaco.editor.ICodeEditor;

    private expandCellJsonModel: ModelJSONNode[] = [];

    private getWordWrapFromContentViewMode = (contentViewMode: ContentViewModeType | undefined) => {
        return contentViewMode === ContentViewModeType.Compact ? 'off' : 'on';
    };

    private readonly monacoOptions: monaco.editor.IEditorConstructionOptions = {
        formatOnType: true,
        folding: true,
        links: true,
        hover: { enabled: false },
        automaticLayout: true,
        minimap: { enabled: false },
        showFoldingControls: 'always',
        readOnly: true,
        wordWrap: this.getWordWrapFromContentViewMode(this.props.contentViewModeType),
        wrappingIndent: 'deepIndent',
        renderLineHighlight: 'none',
        selectionHighlight: false,
        scrollBeyondLastLine: false,
        foldingStrategy: 'auto',
    };

    constructor(props: GridWithExpandOldProps) {
        super(props);
        this.state = { expanded: false };
    }

    componentDidUpdate(prevProps: GridWithExpandOldProps) {
        if (prevProps.searchFocusedCell !== this.props.searchFocusedCell) {
            const cellPosition = this.props.searchFocusedCell;
            if (this.gridApi && this.state.expanded && cellPosition?.column) {
                const row = this.gridApi.getDisplayedRowAtIndex(cellPosition.rowIndex);
                this.updateExpand(row, cellPosition?.column);
            }
        }
        if (prevProps.contentViewModeType !== this.props.contentViewModeType && this.mainEditor) {
            const wordWrap = this.getWordWrapFromContentViewMode(this.props.contentViewModeType);
            this.mainEditor.updateOptions({ wordWrap });
        }
        const { rowId, column } = this.lastExpandCell;
        if (
            !this.state.expanded ||
            prevProps.expandType === this.props.expandType ||
            rowId === undefined ||
            !column ||
            !this.mainEditor
        ) {
            return;
        }
        // Added while enabling lints

        this.hideExpandView(rowId, prevProps.expandType!);
        this.setupDetailsView(rowId, column, undefined);
        this.mainEditor.focus();
    }

    UNSAFE_componentWillMount() {
        this.expandPortal.addEventListener('keydown', this.keyboardBlocker);
    }

    componentWillUnmount() {
        this.expandPortal.removeEventListener('keydown', this.keyboardBlocker);
        if (this.shadowEditor) {
            const shadowEditorToDispose = this.shadowEditor;
            // delay the disposal of shadow editor until all json formatting timers are done
            setTimeout(() => shadowEditorToDispose.dispose(), 1000);
            this.shadowEditor = undefined;
            this.mainEditor = undefined;
        }
    }

    render() {
        const {
            defaultColDef,
            columnDefs,
            gridOptions,
            hideEmptyCells,
            expandType,
            theme,
            openExternalView,
            closeExternalView,
            onEditorLoaded,
            onEditorOpen,
            onEditorClose,
            onExpandTypeChange,
            onRequestPaste,
            renderExpandBarItems,
            onLinkClicked,
            contentViewModeType,
            closeExpandViewOnClick,
            mouseWheelZoom,
            locale,
            renderEditor,
            disableJPath,
            searchFocusedCell,
            ...agGridProps
        } = this.props;
        const mergeOptions = this.memoizeCalcMergedOptions(defaultColDef, columnDefs, gridOptions);

        const isBelowGridExpanded = expandType === DetailsViewType.BelowGrid && this.state.expanded;

        return (
            <div
                ref={this.containerRef}
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                }}
            >
                {/**
                 * Important Note:
                 *
                 * In the future, we will probably want to replace react-split-pane for our
                 * own solution where it is used inside GridWithExpand. To make a long story
                 * short, react-split-pane + the inlined monaco editor on the Grid isn't
                 * quite compatible with one another.
                 *
                 * We have the following options:
                 * 1. Leave as-is: ReactSplitPane will flow over the other rows, preventing interactions
                 * 2. Prevent ReactSplitPane from flowing over other rows: We will no longer be able to resize the editor to be larger
                 * 3. If we apply overflow: hidden;, the monaco editors menus will be visibly clipped, but we will be able to resize the editor, and interact with other rows
                 *
                 * See the following links for reference.
                 * @see https://msazure.visualstudio.com/DefaultCollection/One/_git/Azure-Kusto-WebUX/pullrequest/5772783
                 * @see https://msazure.visualstudio.com/DefaultCollection/One/_git/Azure-Kusto-WebUX/pullrequest/5291415
                 * @see https://msazure.visualstudio.com/DefaultCollection/One/_git/Azure-Kusto-WebUX/pullrequest/5169130
                 */}
                <StyledSplitPane
                    split="horizontal"
                    allowResize={isBelowGridExpanded}
                    onChange={(sizes) => {
                        if (isBelowGridExpanded) {
                            const height = Number.parseFloat(sizes[1].replace('px', ''));
                            this.lastDetailedRowHeight = height;
                        }
                    }}
                >
                    <Pane minSize="10px">
                        {this.memoizeGridRender({
                            ...agGridProps,
                            columnDefs: mergeOptions.columnDefs,
                            gridOptions: mergeOptions,
                        })}
                    </Pane>
                    <Pane
                        key="expandPaneBelow"
                        size={(isBelowGridExpanded ? this.getDetailedHeight() : 0) + 'px'}
                        minSize={isBelowGridExpanded ? '15px' : '0px'}
                    >
                        <div ref={this.belowGridRef} />
                    </Pane>
                </StyledSplitPane>
                {this.renderExpand()}
            </div>
        );
    }

    private renderExpandTopBar = () => {
        const { jsonModelData } = this.state;
        return jsonModelData ? (
            <ExpandTopBar
                jsonModelData={jsonModelData}
                locale={this.props.locale}
                renderExpandBarItems={this.props.renderExpandBarItems!}
                styles={this.getExpandBarContainerStyles()}
            />
        ) : (
            <></>
        );
    };

    private renderExpand = () => {
        const maxHeight = DetailsViewType.ExternalPanel !== this.props.expandType ? '10000px' : undefined;
        const enable = DetailsViewType.InGrid === this.props.expandType && this.state.expanded;
        const expand = (
            <div
                style={{ height: enable ? '100vh' : '100%', width: '100%' }}
                onContextMenu={(e) => e.stopPropagation()}
                onKeyUp={(e) => e.preventDefault()}
                role="none"
            >
                <StyledSplitPane
                    split="horizontal"
                    allowResize={enable}
                    onChange={(sizes) => {
                        if (enable) {
                            const height = Number.parseFloat(sizes[0].replace('px', ''));
                            this.lastDetailedRowHeight = height;
                            if (this.gridApi && this.lastExpandCell.rowId !== undefined) {
                                const row = this.gridApi.getRowNode(this.lastExpandCell.rowId) as RowNode;
                                if (row && row.detailNode) {
                                    row.detailNode.setRowHeight(height);
                                    this.debounceGridOnRowHeightChanged();
                                }
                            }
                        }
                    }}
                >
                    <Pane size={enable ? this.getDetailedHeight() + 'px' : '100%'} maxSize={maxHeight} minSize="10px">
                        {!this.props.disableJPath && this.renderExpandTopBar()}
                        <div style={{ height: this.getEditorContainerHeight() }}>
                            {this.props.renderEditor(this.expandDidMount, this.monacoOptions, this.props.theme)}
                        </div>
                    </Pane>
                    <div />
                </StyledSplitPane>
            </div>
        );
        return ReactDOM.createPortal(expand, this.expandPortal);
    };

    private memoizeGridRender = memoize(
        (props: AgGridReactProps) => (
            <AgGridReact
                modules={[
                    ColumnsToolPanelModule,
                    CsvExportModule,
                    ClipboardModule,
                    ClientSideRowModelModule,
                    ExcelExportModule,
                    MasterDetailModule,
                    MenuModule,
                    RangeSelectionModule,
                    RowGroupingModule,
                    SetFilterModule,
                    StatusBarModule,
                ]}
                {...props}
            />
        ),
        (a, b) =>
            isEqualWith(a, b, (value, other, indexOrKey) => (indexOrKey === 'rowData' ? value === other : undefined))
    );

    private doNothing = () => {
        /* do nothing */
    };

    private memoizeCalcMergedOptions = memoize(
        (defaultColDef?: ColDef, propsColumnDef?: ColDef[] | null, gridOptions?: GridOptions) => {
            const mergeDefaultCol: ColDef = merge(
                {},
                // Added while enabling lints

                gridOptions!.defaultColDef,
                defaultColDef,
                this.gridOptions.defaultColDef
            );
            const mergeOptions: GridOptions = merge({}, gridOptions, this.gridOptions);
            mergeOptions.onGridReady = this.onGridReady;
            if (mergeOptions.columnDefs && mergeOptions.columnDefs.length > 0) {
                mergeOptions.columnDefs = [
                    {
                        headerName: this.props.locale.visualizations.agGrid.expand.expandRow,
                        colId: EXPAND_ROW_COL_ID,
                        onCellContextMenu: this.doNothing,
                        cellClass: 'ms-Icon rowExpandIndicator',
                        cellRenderer: ExpandCellRenderer,
                        width: 21,
                        maxWidth: 21,
                        enableRowGroup: false,
                        enablePivot: false,
                        enableValue: false,
                        suppressFiltersToolPanel: true,
                        suppressColumnsToolPanel: true,
                        suppressMovable: true,
                        suppressMenu: true,
                    },
                    ...(propsColumnDef || mergeOptions.columnDefs),
                ];
            }
            mergeOptions.defaultColDef = mergeDefaultCol;

            return mergeOptions;
        },
        isEqual
    );

    private onGridReady = (params: GridReadyEvent) => {
        if (params.api) {
            this.gridApi = params.api;
            this.columnApi = params.columnApi || undefined;

            this.gridApi.addEventListener(
                Events.EVENT_RANGE_SELECTION_CHANGED,
                this.debouncedRemoveExpandRowAndColFromSelection
            );
        }
        if (this.props.gridOptions && this.props.gridOptions.onGridReady) {
            this.props.gridOptions.onGridReady(params);
        }
    };

    private getPathValue = (): object | string => {
        if (this.lastExpandCell.column && this.columnApi && this.gridApi) {
            const { column, rowId } = this.lastExpandCell;

            const row = this.gridApi.getRowNode(rowId!);

            if (this.state.jsonModelData) {
                const { rawString, lineNumber } = this.state.jsonModelData;
                const jsonPathMap = buildJsonPathForEachLine(rawString);
                const pathToValue = jsonPathMap[lineNumber];
                pathToValue.shift(); // Get path to value starting from index 0

                let cellValue = this.state.jsonModelData.rawString;
                if (this.isExpandRow(column)) {
                    // Row expanded, get the cell value from the corresponding column

                    cellValue = row!.data[pathToValue[0]];
                    pathToValue.shift(); // Remove column name from the path
                }

                try {
                    if (pathToValue.length) {
                        // Valid dynamic value - traverse to get the value
                        let nestedValue = JSON.parse(cellValue);
                        pathToValue.forEach((item) => (nestedValue = nestedValue[item]));
                        return nestedValue;
                    } else {
                        return JSON.parse(cellValue); // try to return the cell content under a dynamic column;
                    }
                } catch (_e) {
                    return cellValue; // not a dynamic column, return raw content
                }
            }

            return row!.data[column.getColId()]; // Non-dynamic data from cell
        }
        return '';
    };

    private getPathAsString = (): string => {
        if (this.state.jsonModelData) {
            const { rawString, lineNumber } = this.state.jsonModelData;
            const jsonPathMap = buildJsonPathForEachLine(rawString);
            return convertJPathArrayRepresentationToString(jsonPathMap[lineNumber]);
        }
        return this.lastExpandCell?.column?.getColId() ?? '';
    };

    private copyValue = () => {
        const dt = new clipboard.DT();
        const pathValue = this.getPathValue();
        dt.setData('text/plain', typeof pathValue === 'object' ? JSON.stringify(pathValue) : pathValue);
        clipboard.write(dt);
    };

    private copyPath = () => {
        const dt = new clipboard.DT();
        dt.setData('text/plain', this.getPathAsString());
        clipboard.write(dt);
    };

    private addPathAsFilter = () => {
        const path = this.getPathAsString();
        const pathValue = this.getPathValue();

        this.props.onRequestPaste!(
            `\n| where ${path} == ${
                typeof pathValue === 'object' ? `${JSON.stringify(JSON.stringify(pathValue))}` : `"${pathValue}"`
            }`
        ); // Parsing the object twice for escaping
    };

    private hideExpandView = (rowToClose: string, viewType: DetailsViewType, dontChangeFocus?: boolean) => {
        switch (viewType) {
            case DetailsViewType.InGrid:
                if (this.gridApi && rowToClose !== undefined) {
                    const row = this.gridApi.getRowNode(rowToClose);
                    if (!row) {
                        return;
                    }
                    row.setExpanded(false);
                    setTimeout(() => {
                        // Timeout is needed since setExpanded works with timeout as well.
                        // // Re- get the rowNode, because setExpand update it
                        // // DON'T !! optimize getDisplayedRowAtInIndex(last)
                        // eslint-disable-next-line no-console
                        console.assert(
                            row === this.gridApi?.getRowNode(rowToClose),
                            'not good',
                            row,
                            this.gridApi?.getRowNode(rowToClose)
                        );
                        this.gridApi?.redrawRows({ rowNodes: [row] });
                        if (!dontChangeFocus) {
                            this.verifyFocusOnCell(undefined /* rowToClose*/);
                            this.gridApi?.clearRangeSelection();
                            if (row.rowIndex !== null && this.lastExpandCell.column) {
                                this.gridApi?.setFocusedCell(row.rowIndex, this.lastExpandCell.column);
                            }
                        }
                    }, 0);
                }
                break;
            default:
                this.verifyFocusOnCell();
        }
    };

    private closeDetails = (dontChangeFocus?: boolean) => {
        if (this.state.expanded && this.lastExpandCell.rowId !== undefined && this.mainEditor) {
            const rowToClose = this.lastExpandCell.rowId;

            this.lastExpandCell.rowId = undefined;
            // Added while enabling lints

            this.hideExpandView(rowToClose, this.props.expandType!, dontChangeFocus);
            this.mainEditor.setValue('');
            this.setState({ expanded: false });
            if (this.props.onEditorClose) {
                this.props.onEditorClose();
            }
        }
        this.closeExternalView();
    };
    private verifyFocusOnCell = (forceRowId?: string) => {
        if (this.gridApi) {
            const focusCell = this.gridApi.getFocusedCell();
            if (focusCell) {
                let rowIndex = focusCell.rowIndex;
                if (forceRowId !== undefined) {
                    const row = this.gridApi.getRowNode(forceRowId);
                    // is there a better way to do it?
                    if (row && row.rowIndex && row === this.gridApi.getDisplayedRowAtIndex(row.rowIndex)) {
                        rowIndex = row.rowIndex;
                    }
                }

                // Added while enabling lints

                this.gridApi!.setFocusedCell(rowIndex, focusCell.column || this.lastExpandCell.column);
            }
        }
    };
    private updateInGridExpand = (row?: RowNode, _column?: Column, prevRow?: string) => {
        if (!row || !_column || row.id === prevRow || !this.gridApi) {
            return;
        }

        // const fixFocusRowIndex = (prevRow !== undefined && row.rowIndex > prevRow);
        // const expandIndexFixer = fixFocusRowIndex ? 1 : 0;
        // const newExpandedIndex = row.rowIndex - expandIndexFixer;

        const rowToRedraw = [row];
        this.lastExpandCell.rowId = row.id;

        if (prevRow !== undefined) {
            // Added while enabling lints

            const lastRow = this.gridApi!.getRowNode(prevRow) as RowNode;
            if (lastRow) {
                lastRow.expanded = false;
                rowToRedraw.push(lastRow);
            }
        }

        if (row.detailNode) {
            row.detailNode.rowHeight = this.getDetailedHeight();
        }

        row.setExpanded(true);

        // this is a react component that needs to be re-rendered.
        //this.gridApi.redrawRows({ rowNodes: [row] });

        setTimeout(() => {
            // Timeout is needed because setExpanded works with timeout in version 26 of ag-grid
            this.fixAccessibilitySetup();
            if (row.rowIndex !== null) {
                // Added while enabling lints

                this.gridApi?.ensureIndexVisible(row.rowIndex! + 1);
                this.gridApi?.ensureIndexVisible(row.rowIndex);
            }
            this.verifyFocusOnCell(row.id);
            // Added while enabling lints

            this.gridApi!.clearRangeSelection();

            // redraw without losing focus
            const cell = this.gridApi?.getFocusedCell();
            this.gridApi?.redrawRows({ rowNodes: rowToRedraw });
            if (cell) {
                this.gridApi?.setFocusedCell(cell.rowIndex, cell.column);
            }
        }, 50);
    };

    private closeExternalView() {
        if (this.externalViewContainer) {
            this.externalViewContainer = null;
            if (this.props.closeExternalView) {
                this.props.closeExternalView(this.ExpandViewControl);
            }
        }
    }
    private appendDetailsViewToExternalPanel() {
        if (this.props.expandType === DetailsViewType.ExternalPanel && this.lastExpandCell.rowId !== undefined) {
            if (this.externalViewContainer && this.expandPortal) {
                if (this.expandPortal.parentElement !== this.externalViewContainer) {
                    this.externalViewContainer.appendChild(this.expandPortal);
                }
            } else if (this.props.openExternalView) {
                this.props.openExternalView(this.ExpandViewControl);
            }
            return true;
        }
        return false;
    }

    private setupDetailsView(row: IRowNode | string, column: Column, prevRow?: string) {
        if (this.props.expandType === DetailsViewType.BelowGrid && this.belowGridRef.current) {
            const belowRef = this.belowGridRef.current;
            (belowRef.parentElement as HTMLElement).insertBefore(this.expandPortal, belowRef);
        } else if (this.appendDetailsViewToExternalPanel()) {
            return;
        } else if (this.props.expandType === DetailsViewType.InGrid && this.gridApi) {
            const rowNode = (typeof row === 'string' ? this.gridApi.getRowNode(row) : row) as RowNode;
            this.updateInGridExpand(rowNode, column, prevRow);
        }
        this.closeExternalView();
    }

    private updateExpand = (row?: IRowNode, column?: Column) => {
        if (!this.mainEditor) {
            // don't open the expand if monaco editor not ready
            return;
        }
        if (!row || !column) {
            return;
        }
        if (row.group || column.getColDef().type === 'autoColumn') {
            this.closeDetails(true);
            return;
        }
        if (this.lastExpandCell.rowId === row.id && this.lastExpandCell.column === column) {
            return;
        }

        const opening = this.lastExpandCell.rowId === undefined;
        if (opening) {
            this.updateValueQueue = { inProgress: false, updateWaiting: false };
            this.setState({ expanded: true });
            if (this.props.onEditorOpen) {
                // Added while enabling lints

                this.props.onEditorOpen!(true);
            }
        }
        const prevRow = this.lastExpandCell.rowId;
        this.lastExpandCell = { rowId: row.id, column, updated: performance.now() };
        this.setupDetailsView(row, column, prevRow);

        this.updateValue();
    };

    private onDoubleClick = (params: CellEvent) => {
        this.lastDoubleClickedTimestamp = Date.now();
        if (!this.isExpandRow(params.column)) {
            this.updateExpand(params.node, params.column);
        }
    };
    private onCellClick = (params: CellEvent) => {
        if (params.event) {
            params.event.stopPropagation();
        }
        if (params.node && params.node.detail) {
            return;
        }
        const mEvent = params.event as MouseEvent;
        const target = mEvent.target as HTMLElement;

        const targetClasses = mEvent && target && target.classList;
        if (
            this.props.onLinkClicked &&
            mEvent instanceof MouseEvent &&
            target &&
            targetClasses &&
            targetClasses.contains('highlighted-url')
        ) {
            const columnId = params.column.getColId();
            // data-url is used for pretty table links so
            // it has a higher precedence then the others.
            const url: undefined | string = target.getAttribute('data-url') || params.data?.[columnId];

            if (!url) {
                return;
            }
            return this.props.onLinkClicked(url, isMacOs ? mEvent.metaKey : mEvent.ctrlKey);
        }
        if (
            mEvent instanceof MouseEvent &&
            (mEvent.button !== 0 || (targetClasses && targetClasses.contains('expand-close')))
        ) {
            this.closeDetails(true);
            return;
        }
        this.lastClickedTimestamp = Date.now();

        this.delayedClickHandler(params);
    };
    private delayedClickHandler = debounce(
        (params: CellEvent) => {
            const t = Date.now();
            const used = this.lastDoubleClickedTimestamp + 300 > t;

            if (this.isExpandRow(params.column)) {
                const { rowId, column, updated } = this.lastExpandCell;
                if (rowId === undefined || rowId !== params.node.id || column !== params.column) {
                    this.updateExpand(params.node, params.column);
                } else if ((params.event?.timeStamp ?? 0) > (updated ?? 0) + 300) {
                    this.closeDetails();
                }
            } else if (this.props.closeExpandViewOnClick && !used) {
                this.closeDetails();
            }
        },
        200,
        { maxWait: 450 }
    );

    private updateJsonPath = () => {
        const jsonAndLineNumber = getJsonStringAndRelativeLineNumber(
            this.expandCellJsonModel,
            this.mainEditor?.getPosition()?.lineNumber
        );

        if (jsonAndLineNumber) {
            const { rawString, lineNumber } = jsonAndLineNumber;
            this.setState({ jsonModelData: { rawString, lineNumber } });
        } else {
            this.setState({ jsonModelData: undefined });
        }
    };

    private delayedFocusSet = debounce(
        (params?: CellFocusedEvent) => {
            const t = Date.now();
            const notUsed = this.lastClickedTimestamp + 150 < t;
            if (!this.gridApi) {
                return;
            }

            const data = this.gridApi.getFocusedCell();
            const shouldWaitForClick = this.props.closeExpandViewOnClick || (data && this.isExpandRow(data.column));

            if (!notUsed && shouldWaitForClick) {
                return;
            }

            if (this.lastExpandCell.rowId !== undefined && (!this.mainEditor || !this.mainEditor.hasWidgetFocus())) {
                if (!data) {
                    this.closeDetails();
                } else {
                    const row = this.gridApi.getDisplayedRowAtIndex(data.rowIndex);
                    if ((row && row.id !== this.lastExpandCell.rowId) || data.column !== this.lastExpandCell.column) {
                        if (row?.detail) {
                            return;
                        }
                        this.updateExpand(row, data.column);
                        this.verifyFocusOnCell();
                    }
                }
            }

            if (params && this.props.gridOptions && this.props.gridOptions.onCellFocused) {
                this.props.gridOptions.onCellFocused(params);
            }
        },
        30,
        { maxWait: 70 }
    );

    private keyboardHandler = (data: SuppressKeyboardEventParams) => {
        const { keyCode } = data.event;
        if (keyCode === KeyCodes.enter && data.node !== null && !data.node.detail) {
            this.updateExpand(data.node, data.column);
            return true;
        }
        if (keyCode === KeyCodes.escape) {
            this.closeDetails();
            return true;
        }
        if (keyCode === KeyCodes.tab) {
            if (this.lastExpandCell.rowId !== undefined && this.gridApi) {
                if (data.node.detail) {
                    const row = this.gridApi.getRowNode(this.lastExpandCell.rowId);
                    if (row?.rowIndex) {
                        // Added while enabling lints

                        this.gridApi.setFocusedCell(row.rowIndex, this.lastExpandCell.column!);
                    }
                } else if (this.mainEditor) {
                    this.mainEditor.focus();
                }
                data.event.stopPropagation();
                data.event.preventDefault();
            }
            return true;
        }
        return false;
    };

    private navigationHandler = (params: NavigateToNextCellParams) => {
        if (params.nextCellPosition && this.gridApi && this.lastExpandCell.rowId !== undefined) {
            const rowIndex = params.nextCellPosition.rowIndex;
            if (rowIndex !== params.previousCellPosition.rowIndex) {
                const nextFocusRow = this.gridApi.getDisplayedRowAtIndex(rowIndex);

                let next = rowIndex;
                if (nextFocusRow?.detail) {
                    const movingDirection = rowIndex - params.previousCellPosition.rowIndex;
                    next = rowIndex + movingDirection;

                    if (next < 0 || next >= this.gridApi.getDisplayedRowCount()) {
                        return params.previousCellPosition;
                    }
                }
                this.updateExpand(this.gridApi.getDisplayedRowAtIndex(next), params.nextCellPosition.column);
                params.nextCellPosition.rowIndex = nextFocusRow?.rowIndex ?? params.nextCellPosition.rowIndex;
            }
        }
        return params.nextCellPosition ?? params.previousCellPosition;
    };

    private getDetailedHeight = (): number => {
        if (!this.state.expanded) {
            return 0;
        }
        if (this.lastDetailedRowHeight === undefined) {
            const container = this.containerRef.current;

            this.lastDetailedRowHeight = Math.max((container && container.offsetHeight / 3) || 300, 50);
        }
        return this.lastDetailedRowHeight;
    };

    private getExpandBarContainerStyles = (): React.CSSProperties => {
        if (this.props.expandType === DetailsViewType.ExternalPanel) {
            return { paddingRight: 44 };
        }
        return {};
    };

    private getEditorContainerHeight = (): string => {
        if (this.state.jsonModelData && !this.props.disableJPath) {
            return 'calc(100% - 60px)';
        }
        return 'inherit';
    };

    private postValueUpdate = () => {
        const { updateWaiting } = this.updateValueQueue;
        this.updateValueQueue = { inProgress: false, updateWaiting: false };
        if (updateWaiting) {
            this.updateValue();
        }
    };

    private updateValue = () => {
        if (!this.shadowEditor || !this.mainEditor || this.updateValueQueue.inProgress) {
            this.updateValueQueue.updateWaiting = true;
            return;
        }
        this.shadowEditor.setValue('');
        // Added while enabling lints

        const model = this.shadowEditor!.getModel();
        const { column, rowId } = this.lastExpandCell;
        if (!rowId || !this.gridApi || !column || !model) {
            this.mainEditor.setValue('');
            return;
        }
        // Added while enabling lints

        const row = this.gridApi!.getRowNode(rowId);
        const isRow = this.isExpandRow(column);
        // Added while enabling lints

        const columns = isRow ? this.columnApi!.getAllColumns() : [column];

        const { editorText, cellJsonModel } = buildEditorAndJsonModels(
            row,
            columns,
            model.getEOL(),
            this.props.hideEmptyCells
        );
        this.expandCellJsonModel = cellJsonModel;
        this.shadowEditor.setValue(editorText);

        const unFoldAction = this.shadowEditor.getAction('editor.unfold');
        if (unFoldAction) {
            this.updateValueQueue.inProgress = true;
            unFoldAction.run().then(() => {
                if (this.mainEditor && this.shadowEditor) {
                    const viewState = this.mainEditor.saveViewState();
                    const shadowViewState = this.shadowEditor.saveViewState();
                    if (viewState && shadowViewState) {
                        viewState.contributionsState = shadowViewState.contributionsState;
                        viewState.viewState.scrollTop = 0;
                        viewState.viewState.scrollLeft = 0;
                        viewState.cursorState = [MONACO_INITIAL_CURSOR_STATE];
                        this.mainEditor.setValue(this.shadowEditor.getValue());
                        this.mainEditor.restoreViewState(viewState);
                    } else {
                        this.mainEditor.setValue(this.shadowEditor.getValue());
                    }
                }
                this.postValueUpdate();
            }, this.postValueUpdate);
        }
    };

    private debounceGridOnRowHeightChanged = debounce(
        () => {
            if (this.gridApi) {
                this.gridApi.onRowHeightChanged();
            }
        },
        30,
        { maxWait: 50 }
    );

    // Ag Grid is getting the keyboard before the expand portal
    // So, we have to block them
    private keyboardBlocker = (e: KeyboardEvent) => {
        e.stopPropagation();
    };

    private isExpandRow = (column: Column) => column && column.getColDef().colId === EXPAND_ROW_COL_ID;

    private fixAccessibilitySetup = () => {
        if (!this.gridApi) {
            return;
        }
        const rowsContainer = document.querySelectorAll('.ag-full-width-container');
        rowsContainer?.forEach((container) => {
            // The expanded inline row (detailed row) has tabindex of -1 and is not accessible! removing the tab index.
            const detailRows = container?.querySelectorAll('.detailed-row');
            detailRows?.forEach((row) => row.removeAttribute('tabindex'));
        });
    };

    private readonly gridOptions: GridOptions = {
        onCellDoubleClicked: this.onDoubleClick,
        animateRows: false,
        onCellFocused: this.delayedFocusSet,
        onCellClicked: this.onCellClick,
        navigateToNextCell: (params) => this.navigationHandler(params),
        detailCellRenderer: 'detailCellRenderer',
        components: {
            detailCellRenderer: DetailedCellRender,
        },
        detailCellRendererParams: {
            expandPortal: this.expandPortal,
        },
        rowClassRules: {
            expanded: (params: { node: IRowNode }) => params.node.detail && params.node.expanded,
            'cell-expand': (params: { node: IRowNode }) => params.node.id === this.lastExpandCell.rowId,
        },
        getRowClass: (params: RowClassParams) => {
            let classes: string[] = [];

            if (this.props.gridOptions && this.props.gridOptions.getRowClass) {
                classes = concat(classes, this.props.gridOptions.getRowClass(params) ?? []);
            }

            if (params.node.detail) {
                classes.push('detailed-row');
            }

            return classes;
        },
        getRowHeight: (params: { node: IRowNode }) => {
            if (params.node.detail) {
                return this.getDetailedHeight();
            }
            return 25; // AgGrid Default ;-(
        },
        masterDetail: true,
        defaultColDef: {
            suppressKeyboardEvent: (params) => this.keyboardHandler(params),
            editable: false,
            cellRenderer: wrapCellWithConditionalFormatting(closableCell),
        },
        autoGroupColumnDef: {
            type: 'autoColumn',
        },
    };

    private expandDidMount = (editor: monaco.editor.IStandaloneCodeEditor, _monaco: typeof monaco) => {
        if (typeof _monaco !== 'undefined' && _monaco.languages?.json?.jsonDefaults?.diagnosticsOptions?.validate) {
            _monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                validate: false,
            });
        }
        this.mainEditor = editor;
        this.shadowEditor = _monaco.editor.create(document.createElement('div'), {
            ...this.monacoOptions,
            language: 'json',
            mouseWheelZoom: this.props.mouseWheelZoom !== false,
            readOnly: false,
        });
        editor.addAction({
            label: this.props.locale.visualizations.agGrid.expand.search,
            id: MONACO_ACTION_ID_PREFIX + 'search',
            keybindings: [monaco.KeyCode.KeyF | monaco.KeyMod.CtrlCmd],
            run: () => editor.getAction('actions.find')?.run(),
            contextMenuGroupId: 'navigation',
            contextMenuOrder: 1,
        });
        editor.addAction({
            label: this.props.locale.visualizations.agGrid.expand.closeDetailsView,
            keybindings: [monaco.KeyCode.Escape],
            id: MONACO_ACTION_ID_PREFIX + 'close',
            run: () => this.closeDetails(),
            precondition: '!findWidgetVisible',
            contextMenuGroupId: 'navigation',
            contextMenuOrder: 2,
        });
        editor.addAction({
            label: this.props.locale.visualizations.agGrid.expand.backToTable,
            id: MONACO_ACTION_ID_PREFIX + 'backToGrid',
            keybindings: [monaco.KeyCode.Tab, monaco.KeyCode.Tab | monaco.KeyMod.Shift],
            run: () => this.verifyFocusOnCell(this.lastExpandCell.rowId),
            contextMenuGroupId: 'navigation',
            contextMenuOrder: 3,
        });
        editor.addAction({
            label: this.props.locale.visualizations.agGrid.jpath.copyValue,
            id: MONACO_ACTION_ID_PREFIX + 'copyValue',
            run: this.copyValue,
            contextMenuGroupId: '9_cutcopypaste',
            contextMenuOrder: 50,
        });
        editor.addAction({
            label: this.props.locale.visualizations.agGrid.jpath.copyPath,
            id: MONACO_ACTION_ID_PREFIX + 'copyPath',
            run: this.copyPath,
            contextMenuGroupId: '9_cutcopypaste',
            contextMenuOrder: 51,
        });
        editor.addAction({
            label: this.props.locale.visualizations.agGrid.jpath.addAsFilter,
            id: MONACO_ACTION_ID_PREFIX + 'addAsFilter',
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.Space],
            run: this.addPathAsFilter,
            contextMenuGroupId: '9_cutcopypaste',
            contextMenuOrder: 52,
        });
        editor.addAction({
            label: 'Details view in table',
            id: MONACO_ACTION_ID_PREFIX + 'inGrid',
            keybindings: [monaco.KeyCode.Digit1 | monaco.KeyMod.Alt],
            // Added while enabling lints

            run: () => this.props.onExpandTypeChange!(DetailsViewType.InGrid),
            contextMenuGroupId: 'navigation2',
            contextMenuOrder: 100,
        });
        editor.addAction({
            label: 'Details view below table',
            id: MONACO_ACTION_ID_PREFIX + 'belowGrid',
            keybindings: [monaco.KeyCode.Digit2 | monaco.KeyMod.Alt],
            // Added while enabling lints

            run: () => this.props.onExpandTypeChange!(DetailsViewType.BelowGrid),
            contextMenuGroupId: 'navigation2',
            contextMenuOrder: 101,
        });
        editor.addAction({
            label: 'Details view in external Panel',
            id: MONACO_ACTION_ID_PREFIX + 'external',
            keybindings: [monaco.KeyCode.Digit3 | monaco.KeyMod.Alt],
            // Added while enabling lints

            run: () => this.props.onExpandTypeChange!(DetailsViewType.ExternalPanel),
            contextMenuGroupId: 'navigation2',
            contextMenuOrder: 102,
        });

        editor.onDidChangeModelContent(() => this.setState({ jsonModelData: undefined }));
        editor.onDidChangeCursorPosition(this.updateJsonPath);

        editor.addOverlayWidget({
            getId: () => 'KustoWeb.DetailsView',
            getDomNode: () => this.detailedViewSelectionPortal,
            getPosition: () => null,
        });
    };

    private debouncedRemoveExpandRowAndColFromSelection = debounce((params: RangeSelectionChangedEvent) => {
        if (!this.gridApi || !params.finished || this.lastExpandCell.rowId === undefined) {
            return;
        }

        const origSelection = this.gridApi.getCellRanges();

        let changed = false;
        const row = this.gridApi.getRowNode(this.lastExpandCell.rowId) as RowNode;
        if (!row || !row.detailNode) {
            return;
        }

        const rowIndex = row.detailNode.rowIndex;

        const cleanSelections =
            origSelection &&
            origSelection.reduce<CellRangeParams[]>((clean, range) => {
                const shouldRemoveFirstCol =
                    range.columns &&
                    range.columns.length > 0 &&
                    range.columns[0].getColDef().colId === EXPAND_ROW_COL_ID;

                const cleanColumn = shouldRemoveFirstCol ? range.columns.slice(1) : range.columns;
                if (cleanColumn.length === 0) {
                    changed = true;
                    return clean;
                }

                // ag grid supports selecting all rows in which case both start and end will be undefined.
                // TOOD not sure if that's the right logic in this case.
                if (range.startRow === undefined || range.endRow === undefined) {
                    return clean;
                }

                const rowsIndex = [range.startRow.rowIndex, range.endRow.rowIndex];
                const start = Math.min(...rowsIndex);
                const end = Math.max(...rowsIndex);
                const detailsInRange =
                    rowIndex !== undefined && rowIndex !== null && rowIndex >= start && rowIndex <= end;
                const cleanRows: { start: number; end: number }[] = detailsInRange
                    ? [
                          // Added while enabling lints

                          { start, end: rowIndex! - 1 },
                          // Added while enabling lints

                          { start: rowIndex! + 1, end },
                      ]
                    : [{ start, end }];
                cleanRows
                    .filter((crow) => crow.start <= crow.end)
                    .forEach((rows) =>
                        clean.push({
                            rowStartIndex: rows.start,
                            rowEndIndex: rows.end,
                            columnStart: cleanColumn[0],
                            columnEnd: cleanColumn[cleanColumn.length - 1],
                        })
                    );
                changed = changed || shouldRemoveFirstCol || detailsInRange;
                return clean;
            }, []);

        if (changed && cleanSelections) {
            setTimeout(() => {
                if (this.gridApi) {
                    this.gridApi.clearRangeSelection();
                    // Added while enabling lints

                    cleanSelections.forEach((range) => this.gridApi!.addCellRange(range));
                }
            }, 0);
        }
    }, 70);
}
