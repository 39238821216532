import * as React from 'react';
const avatarContext = React.createContext(undefined);
const avatarContextDefaultValue = {};
/**
 * @internal
 */ export const AvatarContextProvider = avatarContext.Provider;
/**
 * @internal
 */ export const useAvatarContext = ()=>{
    var _React_useContext;
    return (_React_useContext = React.useContext(avatarContext)) !== null && _React_useContext !== void 0 ? _React_useContext : avatarContextDefaultValue;
};
