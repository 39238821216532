import React from 'react';
import { Button } from '@fluentui/react-components';

import { useStrings } from '../../../../hooks/useStrings';

import styles from './../Popovers.module.scss';

export interface PopoverButtonsProps {
    onApply: () => void;
    onCancel: () => void;
    /** whether the 'Apply' button is disabled */
    disabled?: boolean;
}

/** Set of 'Apply' and 'Cancel' buttons for the Popover */
export const PopoverButtons: React.FunctionComponent<PopoverButtonsProps> = ({ onApply, onCancel, disabled }) => {
    const { strings } = useStrings();

    return (
        <div className={styles.formButtons}>
            <Button appearance="primary" style={{ minWidth: 'unset' }} onClick={onApply} disabled={disabled}>
                {strings.dataExplorationModal.pillsBar.applyButtonLabel}
            </Button>
            <Button style={{ minWidth: 'unset' }} onClick={onCancel}>
                {strings.dataExplorationModal.pillsBar.cancelButtonLabel}
            </Button>
        </div>
    );
};
