import React, { useCallback } from 'react';

import { DialogButton, DialogComponent, DialogComponentProps } from '../DialogComponent/DialogComponent';
import { getContainer, useRenderComponent } from '../RenderComponent';

// PopupDialogProps is the same as DialogComponentProps, except the button's onClick callback property.
// While DialogButton's onClick return void; the PopUpDialogButton's onClick return async result.

export type PopUpDialogButton<Result> = Omit<DialogButton, 'onClick'> & {
    /** Button click handler */
    onClick: () => Result | Promise<Result>;
};

export type PopupDialogProps<Result = void> = Omit<DialogComponentProps, 'buttons'> & {
    /** Dialog buttons */
    buttons: PopUpDialogButton<Result>[];
};

export function usePopupDialog() {
    const { renderComponent, unrenderComponent } = useRenderComponent();

    const openPopup = useCallback(
        /**
         * Opens a popup dialog.
         * @param props Dialog props - title, text and buttons definitions.
         * @param containerId Container element ID.
         * @param ContainerComponent Optional container component. Defaults to Fragment.
         * @returns A promise that will be resolved with the result of the clicked button.
         */
        <Result,>(
            props: PopupDialogProps<Result>,
            containerId: string,
            ContainerComponent: React.FunctionComponent = React.Fragment
        ) =>
            new Promise<Result>((resolve) => {
                const containerElement = getContainer(containerId);

                // Override the default onClick - wait till async flow completes, then close the dialog.
                const dialogProps = {
                    ...props,
                    buttons: props.buttons.map((button) => ({
                        ...button,
                        onClick: async () => {
                            const result = await button.onClick();
                            unrenderComponent(containerElement);
                            resolve(result);
                        },
                    })),
                };

                return renderComponent(
                    () => <DialogComponent {...dialogProps} />,
                    containerElement,
                    ContainerComponent
                );
            }),
        [renderComponent, unrenderComponent]
    );

    return {
        closePopup: unrenderComponent,
        openPopup,
    };
}
