.dropdownItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.itemText {
    margin-left: 8px;
    &.noIcon {
        margin-left: 0;
    }
}
