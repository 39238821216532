import * as React from 'react';
const buttonContext = React.createContext(undefined);
const buttonContextDefaultValue = {};
/**
 * @internal
 * Internal context provider used to update default values between internal components
 */ export const ButtonContextProvider = buttonContext.Provider;
/**
 * @internal
 * Internal context hook used to update default values between internal components
 */ export const useButtonContext = ()=>{
    var _React_useContext;
    return (_React_useContext = React.useContext(buttonContext)) !== null && _React_useContext !== void 0 ? _React_useContext : buttonContextDefaultValue;
};
