import React from 'react';
import { IStackItemStyles, IStackStyles, Stack } from '@fluentui/react';
import { classNamesFunction, styled } from '@fluentui/utilities';

interface Props {
    filterBox: JSX.Element;
    favoritesButton?: JSX.Element;
    styles?: Styles;
}

export interface Styles {
    subComponentStyles: SubComponentStyles;
}

export interface SubComponentStyles {
    bar: IStackStyles;
    firstChild: IStackItemStyles;
}

const getClassNames = classNamesFunction<never, Styles>();

export const getStyles = (): Styles => {
    const defaultStyles = {
        subComponentStyles: {
            bar: {
                root: {
                    alignItems: 'center',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                },
            },
            firstChild: {
                root: {
                    flexGrow: 1,
                    width: '80px',
                    maxWidth: '300px',
                    marginRight: '16px',
                },
            },
        },
    };
    return defaultStyles;
};

/**
 * FilterActionBar shows 2 elements.
 * First, the filter, is aligned to the left with width between 80px to 300px.
 * Second, the favorite button, is aligned to the right.
 */
export const FilterActionBar = styled((props: Props) => {
    const { styles, filterBox, favoritesButton } = props;
    const classNames = getClassNames(styles); // merge default styles with passed in props styles
    return (
        <Stack styles={classNames.subComponentStyles.bar}>
            <Stack.Item styles={classNames.subComponentStyles.firstChild}>{filterBox}</Stack.Item>
            {favoritesButton && <Stack.Item>{favoritesButton}</Stack.Item>}
        </Stack>
    );
}, getStyles);
