import { RTD_DROPDOWN } from '../../constants';
import { RTDDropdownOption } from './types';

export const buildRowHeightFunc = (options: RTDDropdownOption[]) => (index: number) => {
    const option = options[index];

    if (option.hidden) {
        return 0;
    }

    return RTD_DROPDOWN.rowHeight;
};
