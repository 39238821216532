// Eslint is incorrectly flagging some things as unused. Disabling here so keep
// code in this file readable.
/* eslint-disable @typescript-eslint/no-unused-vars */

export interface Ok<T = undefined> {
    readonly kind: 'ok';
    readonly value: T;
    readonly err?: undefined;
}
export interface Err<T = string> {
    readonly kind: 'err';
    readonly value?: undefined;
    readonly err: T;
}

/**
 * Return value for an async function that was aborted/canceled
 * programmatically. Should not be used to indicate user choices, e.g., clicking
 * a cancel button.
 *
 * Use via {@link ABORTED} singleton.
 *
 * Can be used to indicate things canceled as a side effect of a user action,
 * e.g., a user closes a modal, which cancels an effect, an which cancels a
 * promise.
 */
export interface Aborted {
    readonly kind: 'abort';
    readonly value?: undefined;
    readonly err?: undefined;
}

/**
 * `undefined` added to variants for optional chaining support, e.g.,
 * `result.value?.property`
 *
 * Handling a result type:
 * ```typescript
 * const value: Result<number> = someFunc();
 *
 * if (value.kind === 'err') {
 *   // Escalate or handle
 *   return value;
 * }
 *
 * // Use value as normal
 * ```
 */
export type Result<V = undefined, E = string> = Ok<V> | Err<E>;

export type ResultWithAbort<V = undefined, E = string> = Result<V, E> | Aborted;
/**
 * Async code should be abort-able most of the time. Aborted async code should
 * still resolve the returned promise. Use `Aborted` to keep the signature * consistent.
 *
 * @example
 * ```typescript
 *   async function getTotalUsers(): AsyncResult<number>  {
 *     let res: Response;
 *     try {
 *         res = await fetch('...');
 *     } catch (e) {
 *          if (isAbortError(e)) {
 *              return ABORTED;
 *          }
 *          throw e;
 *     }
 *     if (!res.ok) {
 *         return err('Special error message');
 *     }
 *     const data = await res.json();
 *     return ok(data.totalUsers);
 *   }
 *
 */
export type AsyncResult<V = undefined, E = string> = Promise<ResultWithAbort<V, E>>;

/**
 * Similar to {@link AsyncResult}. Gives more flexibility to the creator at the
 * cost of complexity/ambiguity for the consumer.
 */
export type AwaitableResult<V = undefined, E = string> = ResultWithAbort<V, E> | Promise<ResultWithAbort<V, E>>;

export function ok<T>(value: T): Ok<T>;
export function ok(value?: undefined): Ok<undefined>;
export function ok<T>(value: T): Ok<T> {
    return { kind: 'ok', value };
}

export function err<T>(value: T): Err<T>;
export function err(value?: undefined): Err<undefined>;
export function err<T>(value: T): Err<T> {
    return { kind: 'err', err: value };
}

/**
 * @see {@link Aborted}
 */
export const ABORTED: Aborted = { kind: 'abort' };

/**
 * Utility interface for tracking loading inside of reactive state
 *
 * @example
 * ```ts
 * const [status, setStatus] = useState<Loading | Result<Data>>(LOADING);
 * ```
 */
export interface Loading {
    readonly kind: 'loading';
    readonly value?: undefined;
    readonly err?: undefined;
}
export const LOADING: Loading = { kind: 'loading' };
