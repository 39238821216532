import * as React from 'react';

import { CustomListDropdown, CustomListDropdownProps } from './components/customListDropdown/CustomListDropdown';
import { CustomListDropdownMenuProps } from './components/customListDropdown/CustomListDropdownMenu';
import { SearchDropdownMenu } from './components/searchDropdownMenu/SearchDropdownMenu';

function onRenderMenu(innerProps: CustomListDropdownMenuProps) {
    return <SearchDropdownMenu {...innerProps} />;
}

export const SearchDropdown: React.FC<CustomListDropdownProps> = ({ onRenderHeader, ...props }) => (
    <CustomListDropdown {...props} onRenderHeaderPrefix={onRenderHeader} onRenderMenu={onRenderMenu} />
);
