import React from 'react';
import * as mobx from 'mobx';

import type { IKweTelemetry } from '../telemetry';
import type { Dispose } from '../types';

/**
 * Mobx autorun with React useEffect style cleanup callback support
 * @see {@link mobx.autorun}
 *
 * @example
 * ```tsc
 * let val: undefined | number;
 * const obs = mobx.observable.box(5);
 *
 * const dispose = mobxAutorunD((_, onDispose) => {
 *   val = obs.get();
 *   return () => {
 *     val = undefined;
 *   };
 * });
 *
 * assert(val === 5);
 * dispose()
 * assert(val === undefined);
 * ```
 */
export function mobxEffect(
    view: (r: mobx.IReactionPublic) => void | null | undefined | Dispose,
    opts?: mobx.IAutorunOptions
): mobx.IReactionDisposer {
    let disposeView: void | null | undefined | Dispose;

    const disposeAutorun = mobx.autorun(function mobxEffectAutorun(r) {
        disposeView?.();
        disposeView = view(r);
    }, opts);

    function dispose() {
        disposeAutorun();
        disposeView?.();
    }

    dispose.$mobx = disposeAutorun.$mobx;

    return dispose;
}

/**
 * Merge objects while preserving getters
 */
export function getterPreservingMerge<T extends object>(target: T): T;
export function getterPreservingMerge<T extends object, U>(target: T, source1: undefined | U): T & U;
export function getterPreservingMerge<T extends object, U, V>(target: T, source1: U, source2: undefined | V): T & U & V;
export function getterPreservingMerge<T extends object, U, V, W>(
    target: T,
    source1: undefined | U,
    source2: undefined | V,
    source3: undefined | W
): T & U & V & W;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getterPreservingMerge(base: any, ...newProps: readonly any[]): any {
    const properties = Object.getOwnPropertyDescriptors(base);

    for (const props of newProps) {
        if (props) {
            Object.assign(properties, Object.getOwnPropertyDescriptors(props));
        }
    }

    return Object.create({}, properties);
}

/**
 * # MobX aware `React.useMemo`
 *
 * `React.useMemo`'s callback cannot read MobX values. This is a slightly more
 * expensive version of `useMemo` that can.
 *
 * ```js
 * const MyComponent = observer(function MyComponent() => {
 *   const [obs] = React.useState(() => mobx.observable.box(0));
 *
 *   const stale = useComputed(() => {
 *       return obs.get() // Allowed! 🥳
 *     },
 *     [obs]
 *   );
 *
 *   return null;
 * });
 * ```
 *
 * ## Why can't `React.useMemo` read MobX values?
 *
 * `React.useMemo` expects it's result to only change if one of the values in
 * it's dependent array changes. Because MobX values are mutable, `useMemo` may
 * not be re-run if a MobX observable is in the dependency array, and it's value
 * is not.
 *
 * js
 * ```
 * const MyComponent = observer(function MyComponent() => {
 *   const [obs] = React.useState(() => mobx.observable.box(0));
 *
 *   const stale = React.useMemo(() => {
 *       return obs.get()
 *     },
 *     [obs] // Dependencies list never changes, so `sale` is never recalculated
 *   );
 *
 *   return null;
 * });
 * ```
 *
 * MobX docs have a related explanation for `React.useEffect`:
 * https://mobx.js.org/react-integration.html#tips
 *
 * ## Why don't I see MobX warnings when I access MobX observables in a `React.useMemo` callback?
 *
 * If our component is a MobX `observer` component then that component subscribe
 * to observables accessed in the `useMemo` callback the first time it's called.
 * MobX isn't aware that `React.useMemo` was called.
 */
export function useComputed<T>(cb: () => T, deps: unknown[]) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => mobx.computed(cb), deps).get();
}

export function setupMobxExceptionLogging(telemetry: IKweTelemetry): Dispose {
    return mobx.onReactionError((exception, info) => {
        // TODO: bring back "...info" after verifying it doesn't cause bug https://dev.azure.com/msazure/One/_workitems/edit/26263397 anymore.
        // telemetry.exception('MobX reaction crash - ' + info.name_, { exception, ...info });
        telemetry.exception('MobX reaction crash - ' + info.name_, { exception });
    });
}

/**
 * https://mobx.js.org/configuration.html#configuration-
 *
 * ## computedRequiresReaction + observableRequiresReaction
 * Generate lots of false positives (warnings that aren't bugs), but, also
 * catches lots of bugs, and the bugs it catches can be very difficult to
 * find without this.
 *
 * MobX recommends keeping this off, but, we've found that without it many
 * bugs slip into production. Best guess is that apps MobX developers are
 * thinking of when they make this recommendation don't look much like ours.
 */
export const mobxConfig: Parameters<typeof mobx.configure>[0] = {
    computedRequiresReaction: true,
    /**
     * Disabled because some components conditionally access mobx state, which
     * generates false positives with no way to suppress them.
     */
    reactionRequiresObservable: false,
    observableRequiresReaction: true,
};
