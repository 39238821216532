import * as React from 'react';
import { IButtonProps, Icon, Spinner, SpinnerSize } from '@fluentui/react';

import { ActionButtonWithTooltip } from '@kusto/utils';

export interface TimerActionButtonProps extends IButtonProps, React.Attributes {
    formatText?: (elapsed: number, precision?: number) => string; // formatToSeconds
    start?: number; // if not provided will use the componentDidMount executionTime
    iconOnly?: boolean;
    tooltip?: string;
}

const accessibilityStatusUpdateInterval = 3000;

interface State {
    start: number;
    text?: string;
    accessibilityText?: string;
}

const defaultFormatting = (elapsed: number, precision?: number) => (elapsed / 1000).toFixed(precision);

/**
 * ActionButton that knows how to continuously display elapsed time from a certain point in time.
 * In order to support accessibility, it will update shadow div with status role,
 * every few seconds (accessibilityStatusUpdateInterval)
 */
export class TimerActionButton extends React.Component<TimerActionButtonProps, State> {
    private timer?: number;
    private initialTimer?: number;
    private accessibilityUpdateTime = 0;

    constructor(props: TimerActionButtonProps) {
        super(props);
        this.state = { start: 0 };
    }

    componentDidMount() {
        if (this.props.text) {
            // Aria live have to change ;-(
            // so we can't update it on loading
            this.initialTimer = window.setTimeout(() => {
                this.initialTimer = undefined;
                this.setState({
                    accessibilityText: this.props.text,
                    text: this.props.text,
                });
            }, 10);
        } else if (this.props.start) {
            this.startTimer();
        }
    }

    componentWillUnmount() {
        this.stopTimer();
        if (this.initialTimer) {
            clearTimeout(this.initialTimer);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: TimerActionButtonProps) {
        if (nextProps.text) {
            this.stopTimer();
            this.setState({
                accessibilityText: nextProps.text,
                text: nextProps.text,
            });
        } else if (nextProps.start && nextProps.start !== this.state.start) {
            this.stopTimer();
            this.setState({ start: nextProps.start }, () => {
                this.startTimer();
            });
        } else if (!nextProps.start) {
            this.stopTimer();
        }
    }
    render() {
        return (
            <div style={{ display: 'inline-block' }}>
                <div
                    style={{ display: 'none' }}
                    aria-live="polite"
                    role="status"
                    aria-label={(this.props.ariaLabel || '') + ' ' + this.state.accessibilityText}
                >
                    {this.state.accessibilityText}
                </div>
                <ActionButtonWithTooltip
                    {...this.props}
                    styles={{
                        ...(this.props.styles ?? {}),
                        root: { cursor: 'default' },
                        rootHovered: { color: 'inherit' },
                    }}
                    aria-hidden="true"
                    text={!this.props.iconOnly ? this.state.text : undefined}
                    onRenderIcon={(props) => {
                        const iconProps = props?.iconProps;
                        if (iconProps?.iconName === 'ProgressRingDots') {
                            return <Spinner size={SpinnerSize.xSmall} />;
                        }
                        return <Icon {...iconProps} />;
                    }}
                    iconProps={this.props.iconProps || { iconName: 'ProgressRingDots' }}
                    ariaLabel=""
                    tabIndex={-1}
                    tooltipProps={{ content: this.props.tooltip ?? this.state.accessibilityText }}
                />
            </div>
        );
    }

    private updateTimerState(defaultStart?: number) {
        const now = new Date().getTime();
        const start = defaultStart || now;
        const formatter = this.props.formatText || defaultFormatting;
        const updateState: State = {
            start,
            text: formatter(now - start),
        };

        // Update accessibility status once in few seconds
        if (now > this.accessibilityUpdateTime) {
            updateState.accessibilityText = formatter(now - start, 0);
            this.accessibilityUpdateTime = now + accessibilityStatusUpdateInterval;
        }
        this.setState(updateState);
    }

    private tick = () => this.updateTimerState(this.state.start);

    private startTimer() {
        this.updateTimerState(this.props.start);
        this.timer = window.setInterval(this.tick, 100);
    }

    private stopTimer() {
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.timer = undefined;
    }
}
