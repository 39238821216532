import React from 'react';
import { Body1 } from '@fluentui/react-components';
import { EmptyState } from '@trident/ux-react';

import * as styles from './ErrorBoundary.module.scss';

interface ErrorBoundaryProps {
    errorStateTitle: string;
    fullSize?: boolean;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error?: Error;
}

export class DataProfileErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    render() {
        if (this.state.hasError) {
            return (
                <EmptyState
                    emptyStateTitle={this.props.errorStateTitle}
                    emptyStateType="error"
                    size={this.props.fullSize ? 'large' : 'medium'}
                    className={this.props.fullSize ? styles.fullSizeError : styles.errorWrapper}
                >
                    <Body1 align="center">{this.state.error?.message ?? null}</Body1>
                </EmptyState>
            );
        }
        return this.props.children;
    }
}
