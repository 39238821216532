import * as React from 'react';

import { IKweTelemetry, ReadonlyRecord, useTelemetry } from '@kusto/utils';

import { generateCid } from './crash';
import { CrashErrorText, CrashErrorTextProps } from './CrashErrorText';

interface ExceptionBoundaryInternalProps extends ExceptionBoundaryProps {
    telemetry: IKweTelemetry;
}

interface ExceptionBoundaryState {
    cid?: string;
}

class ExceptionBoundaryBaseline extends React.Component<ExceptionBoundaryInternalProps, ExceptionBoundaryState> {
    public readonly state: ExceptionBoundaryState = {};

    componentDidCatch(exception: Error, reactErrorInfo: React.ErrorInfo) {
        const cid = generateCid(this.props.cidPrefix);
        let additionalInfo: undefined | ReadonlyRecord<string, unknown>;
        try {
            additionalInfo = this.props.addExceptionProperties?.();
        } catch (e) {
            this.props.telemetry.exception('addExceptionProperties', {
                exception: e,
                cidPrefix: this.props.cidPrefix,
                cid,
                area: this.props.area,
            });
            additionalInfo = { addExceptionInfoCrash: true };
        }
        this.props.telemetry.exception(`${this.props.area} crash`, {
            exception,
            cidPrefix: this.props.cidPrefix,
            cid,
            reactErrorInfo,
            ...additionalInfo,
        });
        this.setState({ cid });
    }

    render() {
        if (this.state.cid) {
            return this.props.wrapError(
                <CrashErrorText
                    area={this.props.area}
                    // TODO: Pass undefined here if we're unable to log the exception
                    cid={this.state.cid}
                    supportEmail={this.props.supportEmail}
                    headerLevel={this.props.headerLevel}
                    t={this.props.t}
                    reloadCallback={this.props.reloadCallback ?? (() => this.setState({ cid: undefined }))}
                />
            );
        }

        return this.props.children;
    }
}

export interface ExceptionBoundaryProps
    extends Pick<CrashErrorTextProps, 'area' | 'supportEmail' | 'headerLevel' | 't' | 'reloadCallback'> {
    /** Error is wrapped in this */
    wrapError: (error: React.ReactNode) => React.ReactNode;
    addExceptionProperties?: () => ReadonlyRecord<string, unknown>;
    cidPrefix: string;
}

/**
 * Please make sure to wrap ExceptionBoundary with theme provider, in order to have s styled component experience
 *
 * @example
 * ```tsx
 * export type RtdErrorBoundaryProps = Pick<ExceptionBoundary, 'wrapError' | 'headerLevel'>;
 *
 * export const RtdErrorBoundary: React.FC<RtdErrorBoundaryProps> = observer(function RtdErrorBoundary(props) {
 *     const ctx = useCtx();
 *     return (
 *         <ExceptionBoundary
 *             area="Dashboards"
 *             cidPrefix="REB"
 *             supportEmail={ctx.config.supportEmailAddress}
 *             t={ctx.locale}
 *             {...props}
 *         />
 *     );
 * });
 * ```
 */
export const ExceptionBoundary: React.FC<ExceptionBoundaryProps> = ({ children, ...props }) => {
    const { telemetry } = useTelemetry();

    return (
        <ExceptionBoundaryBaseline {...props} telemetry={telemetry}>
            {children}
        </ExceptionBoundaryBaseline>
    );
};
