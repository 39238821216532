import React from 'react';
// lodash import is restricted to avoid importing the entire library, so
// type-only imports are safe
// eslint-disable-next-line no-restricted-imports
import type { ThrottleSettings } from 'lodash';
import throttle from 'lodash/throttle';
import { Observer, observer } from 'mobx-react-lite';

import { DataVisualSpinner, QueryResultErrors, QueryResultErrorsProps } from '../../KweVisual/KweVisual';
import { VisualMessage } from '../../KweVisual/VisualMessage';
import type { VisualOptionKey } from '../../visualOptions';
import type { VisualOptionsModel, VisualPluginModel } from '../model/model';
import type { RenderVisualOptions } from '../pub';

interface ThrottleProps extends ThrottleSettings {
    timeout?: number;
    children: React.ReactElement;
}

const Throttle: React.FC<ThrottleProps> = ({ children, timeout = 250, leading = true, trailing = true }) => {
    const [element, setElement] = React.useState<React.ReactElement>(children);

    const throttledSetNode = React.useMemo(
        () => throttle(setElement, timeout, { leading, trailing }),
        [leading, trailing, timeout]
    );

    React.useLayoutEffect(() => {
        throttledSetNode(children);
        return () => throttledSetNode.cancel();
    }, [children, throttledSetNode]);

    return element;
};

interface VisualStateOkProps<C extends VisualOptionKey = VisualOptionKey, H = undefined>
    extends VisualOptionsVisualProps {
    visualModel: VisualPluginModel<C, H>;
}

const VisualStateOk = observer(function VisualStateOk({
    visualModel,
    model,
    renderErrorBoundary,
    ...componentProps
}: VisualStateOkProps) {
    const t = model.args.locale();

    // Memo here might be excessive, but we really want to avoid extra
    // renders of the visual
    const VisualComponent = React.useMemo(
        () => React.memo(visualModel.config.Component),
        [visualModel.config.Component]
    );

    const renderSuccess: QueryResultErrorsProps['renderSuccess'] = (resultSuccess) => {
        return (
            <Observer>
                {function renderSuccessReaction() {
                    return (
                        <Throttle>
                            <VisualComponent
                                queryResult={resultSuccess}
                                heuristics={visualModel.heuristics.get()}
                                visualType={visualModel.visualType}
                                visualOptions={visualModel.narrowedOptionsCopy}
                                locale={t.locale}
                                {...componentProps}
                            />
                        </Throttle>
                    );
                }}
            </Observer>
        );
    };

    return (
        <QueryResultErrors
            t={t}
            messageHeaderLevel={model.args.headerLevel}
            queryResult={model.args.queryResult}
            renderErrorBoundary={renderErrorBoundary}
            renderSuccess={renderSuccess}
        />
    );
});

export interface VisualOptionsVisualProps extends RenderVisualOptions {
    model: VisualOptionsModel;
}

export const VisualOptionsVisual: React.FC<VisualOptionsVisualProps> = observer(function VisualOptionsVisual(props) {
    const visualState = props.model._pluginStatus.get();

    switch (visualState.kind) {
        case 'err':
            return <VisualMessage headerLevel={props.model.args.headerLevel} message={visualState.err} />;
        case 'loading':
            return <DataVisualSpinner t={props.model.args.locale()} />;
        case 'ok':
            return <VisualStateOk {...props} visualModel={visualState.value} />;
    }
});
