import React from 'react';
import {
    ContextualMenuItemType,
    DirectionalHint,
    getColorFromString,
    IButtonStyles,
    Icon,
    IContextualMenuItem,
    IPalette,
    isDark,
    IStyle,
    ITheme,
    registerIcons,
    Stack,
    TooltipHost,
    TooltipOverflowMode,
} from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { classNamesFunction, IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import MoreTabsIcon from 'jsx:./moreTabs.svg';
import debounce from 'lodash/debounce';
import merge from 'lodash/merge';
import { observer } from 'mobx-react-lite';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';

import { ActionButtonWithTooltip, IconButtonWithTooltip, Theme } from '@kusto/utils';

import { ConfigurableBarItem, ConfigurableBarItemsFunc, configureSortAndFilterBarItems } from '../../common/barConfig';
import { QueryCore, useQueryCore } from '../../core/core';
import type { QueryStrings } from '../../core/strings';
import { Scrollbar } from '../Scrollbar/Scrollbar';
import { TAB_WIDTH, TabItemPropsBase } from './TabItem';
import { TabList } from './TabList';

registerIcons({
    icons: {
        moreTabs: <MoreTabsIcon />,
    },
});

const getClassNames = classNamesFunction<TabBarStyleProps, TabBarStyles>();

export type TabBarStyleProps = Required<Pick<TabBarProps, 'theme'>>;

export interface TabBarStyles {
    root: IStyle;
}

const getTabBarBackground = (palette: IPalette) => palette.white;

const TAB_WIDTH_FOR_LAST_SELECTED_TAB = TAB_WIDTH - 1;

const getStyles = (props: TabBarStyleProps): TabBarStyles => {
    const { theme } = props;
    const { palette } = theme;

    const isDarkTheme = isDark(getColorFromString(theme.palette.white)!);

    return {
        root: [
            'tabs-bar',
            {
                paddingLeft: 1,
                display: 'flex',
                backgroundColor: getTabBarBackground(palette),
                flex: '0 0 auto',
                alignItems: 'center',
                position: 'relative',
                height: 33,
                borderTop: isDarkTheme ? 0 : `1px solid ${theme.palette.neutralQuaternaryAlt}`,
                selectors: {
                    // Space between tabs and far items (settings ETC in case we're in ibiza).
                    ' .spacer': {
                        minWidth: 30,
                        flex: '1 0 auto',
                    },
                    '& .tabs': {
                        ':before': {
                            background: isDarkTheme ? 'white' : theme.palette.neutralQuaternaryAlt,
                            bottom: 0,
                            content: '""',
                            height: '1px',
                            left: 0,
                            position: 'absolute',
                            right: 0,
                        },
                        position: 'relative',
                        display: 'flex',
                        flex: '0 1 auto',
                        overflow: 'hidden',
                        height: 33,
                    },
                    ' .tab-item': {
                        minWidth: TAB_WIDTH,
                    },
                    ' .tab-item:not(:first-child)': {
                        // a margin to separate each tab (without the first tab)
                        marginLeft: 1,
                    },
                    ' .tab-item.selected:last-child': {
                        // To fix a bug thats selecting the last tab won't show entire tab
                        width: TAB_WIDTH_FOR_LAST_SELECTED_TAB,
                        minWidth: TAB_WIDTH_FOR_LAST_SELECTED_TAB,
                        ' .tab-item-container': {
                            width: TAB_WIDTH_FOR_LAST_SELECTED_TAB,
                            ':not(.is-dragging)': {
                                // To overcome double border to the right side (buttons container border + selected item border)
                                borderRightWidth: isDarkTheme ? 1 : 0,
                            },
                        },
                    },
                    ' .tab-item.dragging ~ .tab-item.selected': {
                        ' .tab-item-container': {
                            // To overcome an issue that when last item is selected it doesn't have a border, and when dragging it's exposed
                            borderRightWidth: `1px !important`,
                        },
                    },
                    ' .tab-item.selected': {
                        // Remove the margin of a selected tab - it has a border instead
                        marginLeft: 0,
                    },
                    ' .tab-item.selected + .tab-item': {
                        // A tab after a selected tab to have no margin left - the selected tab has border instead
                        marginLeft: 0,
                    },
                    // This is for the chevron menu icon
                    '& .ms-Button-menuIcon': {
                        color: theme.palette.neutralSecondary,
                    },
                    ' .scrollbar-container': {
                        display: 'flex',
                        flex: '0 1 auto',
                    },
                    ' .buttons-container': {
                        display: 'flex',
                        flexGrow: 1,
                        borderLeft: `1px solid ${
                            isDarkTheme ? 'rgba(255, 255, 255, 0.2)' : theme.palette.neutralQuaternaryAlt
                        }`,
                        height: '100%',
                        position: 'relative',
                        ':before': {
                            background: isDarkTheme ? 'white' : theme.palette.neutralQuaternaryAlt,
                            bottom: 0,
                            content: '""',
                            height: '1px',
                            left: '-1px',
                            position: 'absolute',
                            right: 0,
                        },
                    },
                },
            } satisfies IStyle,
        ],
    };
};

export const iconsStyle = (
    theme: ITheme,
    color?: string,
    withDivider?: boolean,
    removeLeftMargin?: boolean
): IButtonStyles => ({
    root: {
        flexShrink: 0,
        height: 32,
        fontSize: 16,
        '&:before': withDivider
            ? {
                  background: theme.palette.neutralQuaternaryAlt,
                  bottom: 0,
                  top: 0,
                  margin: 'auto',
                  content: '""',
                  width: 1,
                  height: 16,
                  left: -8,
                  position: 'absolute',
              }
            : {},
        marginLeft: removeLeftMargin ? 0 : withDivider ? 16 : 4,
        padding: 0,
    },
    rootHovered: {
        backgroundColor: 'transparent',
    },
    icon: {
        margin: 0,
        color: color ?? theme.palette.neutralSecondary,
    },
    rootExpanded: {
        backgroundColor: theme.palette.neutralLighter,
    },
    rootDisabled: {
        backgroundColor: 'transparent',
        color: theme.palette.themeTertiary,
    },
});

export const AddTabButton: React.FC<{
    onNewTab: () => void;
    split: boolean;
    styles?: IButtonStyles;
    theme: ITheme;
    scrolling?: boolean;
    items?: IContextualMenuItem[];
}> = observer(function AddTabButton({ onNewTab, split, styles: additionalStyles, theme, scrolling, items }) {
    const core = useQueryCore();
    const addButtonStyled = iconsStyle(theme, theme.palette.themePrimary, scrolling, !scrolling);

    return (
        <IconButtonWithTooltip
            split={split}
            styles={merge(addButtonStyled, additionalStyles)}
            iconProps={{ iconName: 'Add' }}
            tooltipProps={{ content: core.strings.query.addNewTab }}
            ariaLabel={core.strings.query.addNewTabDescription}
            onClick={onNewTab}
            splitButtonAriaLabel={core.strings.query.addNewTabDescription}
            menuProps={split && items ? { items } : undefined}
            key="addTabButton"
        />
    );
});

export type TabBarAdditionalItems = Record<'addButton' | 'moreTabs' | 'spacer', ConfigurableBarItem>;

export interface TabBarProps {
    core: QueryCore; // Only access in callbacks because `TabBarBase` isn't a observer component
    strings: QueryStrings;
    selectedKey: string;
    onClick: (tabKey: string) => void;
    onNewTab: () => void;
    onTabsReorder: (tabIds: string[]) => void;
    onCloseAllTabs: () => void;
    items: TabItemPropsBase[];
    additionalBarItems?: ConfigurableBarItemsFunc<keyof TabBarAdditionalItems, { scrolling: boolean }>;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<TabBarStyleProps, TabBarStyles>;
}

interface State {
    scrolling?: boolean;
}

// Pivot is blocking double clicks detection ( preventDefault on click), so we need to emulate detection of DBL click

class TabBarBase extends React.Component<TabBarProps, State> {
    private scrollTabs: HTMLElement | undefined;
    private scrollTime = 0;

    constructor(props: TabBarProps) {
        super(props);
        this.state = {
            scrolling: false,
        };
    }

    componentDidMount() {
        // Need setTimeout to overcome an issue that the scrollbar isn't completely rendered at this point
        setTimeout(() => {
            this.scrollSelectedTabToView(this.props.selectedKey, true);
        }, 0);
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        this.onResize.cancel();
    }

    componentDidUpdate(prevProps: TabBarProps) {
        if (prevProps.items.length !== this.props.items.length) {
            this.scrollSelectedTabToView(this.props.selectedKey);
        }
    }

    onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const items = reorderTabs(this.props.items, result.source.index, result.destination.index);

        this.props.onTabsReorder(items.map((item) => item.tabKey));
    };

    render() {
        const theme = this.props.theme!;

        const classNames = getClassNames(this.props.styles!, {
            theme,
        });

        const isDarkTheme = isDark(getColorFromString(theme.palette.white)!);

        const addTabButton = () => (
            <AddTabButton
                key="addButton"
                onNewTab={this.props.onNewTab}
                split={false}
                theme={theme}
                scrolling={!!this.state.scrolling}
            />
        );

        const additionalItems = configureSortAndFilterBarItems(
            this.props.additionalBarItems,
            { scrolling: !!this.state.scrolling },
            {
                spacer: {
                    key: 'spacer',
                    onRender: () => <div className="spacer" key="spacer" />,
                    order: 20,
                },
                moreTabs: {
                    key: 'moreTabs',
                    onRender: this.renderMoreTabs,
                    order: 30,
                },
                addButton: {
                    key: 'addButton',
                    onRender: addTabButton,
                    // before spacer if tabs doesn't take the full length (no scrolling)
                    order: this.state.scrolling ? 50 : 10,
                },
            }
        );

        return (
            <div className={classNames.root}>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided: DroppableProvided) => (
                            <div ref={provided.innerRef} className="tabs" {...provided.droppableProps}>
                                <Scrollbar
                                    containerRef={(ref) => {
                                        this.scrollTabs = ref;
                                    }}
                                    theme={isDarkTheme ? Theme.Dark : Theme.Light}
                                    onWheel={(evt) => {
                                        evt.preventDefault();
                                        if (this.scrollTabs) {
                                            this.scrollTabs.scrollLeft += evt.deltaY;
                                        }
                                    }}
                                >
                                    <TabList
                                        selectedTabKey={this.props.selectedKey}
                                        theme={theme}
                                        tabs={this.props.items}
                                        onTabClick={(key: string) => {
                                            this.scrollSelectedTabToView(key, true);
                                            this.props.onClick(key);
                                        }}
                                    />
                                    {provided.placeholder}
                                </Scrollbar>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className="buttons-container">{additionalItems.map((item) => item.onRender())}</div>
            </div>
        );
    }

    private renderMoreTabs = () => {
        const theme = this.props.theme!;

        const collapsedTabsButtonStyled = {
            ...iconsStyle(theme, undefined, false, true),
            icon: { fontSize: 12 },
            flexContainer: { fontSize: 12 },
            rootHovered: { color: theme.palette.neutralPrimary },
            rootExpanded: { color: theme.palette.neutralPrimary, backgroundColor: theme.palette.neutralLighter },
        };

        const closeAllButton: IContextualMenuItem = {
            key: 'closeAll',
            text: this.props.strings.closeAllTabs,
            onClick: () => {
                this.props.onCloseAllTabs();
            },
            disabled: this.props.items.length <= 1,
        };

        const moreTabsButtonItems = this.props.items.map((item) => {
            const menuItem: IContextualMenuItem = {
                key: item.tabKey!,
                text: item.text,
                style: { fontWeight: this.props.selectedKey === item.tabKey ? 'bold' : 'unset' },
                onClick: (_ev, clickedItem: IContextualMenuItem | undefined) => {
                    if (clickedItem) {
                        this.props.onClick(clickedItem.key);
                        this.scrollSelectedTabToView(clickedItem.key, true);
                    }
                },
                onClose: item.onClose,
                /**
                 * Using custom onRender because of 2 reasons:
                 * 1. The requirement to have an X button for each item
                 * 2. Delete keyboard button should mimic the X button
                 *
                 * Styles are mostly copied from the built in default menu item
                 */
                onRender: (item) => (
                    <TabMenuItem
                        isSelected={this.props.selectedKey === item.key}
                        item={item}
                        theme={theme}
                        showCloseButton={this.props.items.length > 1}
                    />
                ),
            };
            return menuItem;
        });

        return (
            <ActionButtonWithTooltip
                tooltipProps={{ content: this.props.strings.openTabs }}
                iconProps={{
                    iconName: 'moreTabs',
                }}
                styles={collapsedTabsButtonStyled}
                menuProps={{
                    styles: {
                        root: {
                            ' button.ms-ContextualMenu-link': {
                                padding: 0,
                            },
                        },
                    },
                    coverTarget: false,
                    directionalHint: DirectionalHint.bottomRightEdge,
                    directionalHintFixed: true,
                    items: [
                        closeAllButton,
                        { key: 'divider', itemType: ContextualMenuItemType.Divider },
                        ...moreTabsButtonItems,
                    ],
                }}
                key="moreTabsButton"
            >
                {this.props.items.length}
            </ActionButtonWithTooltip>
        );
    };

    private updateScrollingState = (): State => {
        const tabs = this.scrollTabs;
        if (!tabs) {
            return {};
        }
        const scrolling = tabs.offsetWidth < tabs.scrollWidth;
        let updateState = this.state;
        if (scrolling !== this.state.scrolling) {
            updateState = { scrolling };
            this.setState(updateState);
        }
        return updateState;
    };

    // The function will update the scrolling state and
    // make sure that "selected tab" is viewable (unless the user scrolled intentionally)
    private scrollSelectedTabToView = (tabKey: string, force?: boolean) => {
        if (!this.scrollTabs) {
            return;
        }

        const { scrolling } = this.updateScrollingState();

        // In case the user scrolled, don't go back to selected tab
        if (!force && (!scrolling || Date.now() - this.scrollTime < 1000)) {
            return;
        }

        const tabs = this.scrollTabs!;
        const selected = tabs.getElementsByClassName(tabKey);
        if (selected && selected.length > 0) {
            const selectedOffset = (selected[0] as HTMLDivElement).offsetLeft;
            const pos = tabs.scrollLeft;
            if (pos >= selectedOffset) {
                this.scrollLeftFrom(selectedOffset + 1);
                setTimeout(this.updateScrollingState, 100);
            } else if (pos + tabs.offsetWidth <= selectedOffset + TAB_WIDTH + 1) {
                this.scrollRightFrom(selectedOffset - tabs.offsetWidth + TAB_WIDTH + 1);
                setTimeout(this.updateScrollingState, 100);
            }
        }
    };

    private onResize = debounce(
        () => {
            // make sure the selected tab is fully visible
            this.scrollSelectedTabToView(this.props.selectedKey);
        },
        200,
        { maxWait: 500 }
    );

    private scrollLeftFrom = (position?: number) => {
        const tabs = this.scrollTabs;
        if (!tabs) {
            return;
        }
        let pos = position === undefined ? tabs.scrollLeft - 1 : position;
        pos -= pos % (TAB_WIDTH + 1);
        tabs.scroll(pos, 0);
        this.updateScrollingState();
    };

    private scrollRightFrom = (position?: number) => {
        const tabs = this.scrollTabs!;
        if (!tabs) {
            return;
        }
        let pos = position === undefined ? tabs.scrollLeft + TAB_WIDTH : position;

        pos -= pos % (TAB_WIDTH + 1);

        pos += TAB_WIDTH + 1 - (tabs.offsetWidth % (TAB_WIDTH + 1));
        pos = Math.min(pos, tabs.scrollWidth - tabs.offsetWidth);
        tabs.scroll(pos, 0);
        this.updateScrollingState();
    };
}

export const TabBar = styled(TabBarBase, getStyles, undefined, {
    scope: 'TabBar',
});

////////////////////////////////////////////////////////////////////////////////////////////
if (!Element.prototype.scroll) {
    // See more - https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/15534521/
    // @ts-expect-error: Workaround for Microsoft Edge/IE issue with scroll Element
    Element.prototype.scroll = function (x: number, y: number) {
        this.scrollLeft = x;
        this.scrollTop = y;
    };
}
////////////////////////////////////////////////////////////////////////////////////////////

const reorderTabs = (list: TabItemPropsBase[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

interface TabMenuItemProps {
    theme: ITheme;
    item: IContextualMenuItem;
    showCloseButton: boolean;
    isSelected: boolean;
}

const TabMenuItem: React.FC<TabMenuItemProps> = ({ theme, item, showCloseButton, isSelected }) => {
    const core = useQueryCore();

    const isDarkTheme = isDark(getColorFromString(theme.palette.white)!);
    return (
        <Stack
            data-is-focusable={true}
            horizontal
            aria-label={`${item.text} ${showCloseButton ? core.strings.query.closeTabAriaLabel : ''}`}
            styles={{
                root: {
                    height: 36,
                    justifyContent: 'space-between',
                    lineHeight: 36,
                    alignItems: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: isDarkTheme
                            ? theme.palette.neutralQuaternaryAlt
                            : theme.palette.neutralLighter,
                    },
                    outline: 'transparent',
                    '&:focus': {
                        backgroundColor: theme.palette.white,
                        '::after': {
                            content: '""',
                            position: 'absolute',
                            inset: 1,
                            border: `1px solid ${theme.palette.white}`,
                            outline: `${isDarkTheme ? NeutralColors.gray90 : NeutralColors.gray130} solid 1px`,
                            zIndex: 1,
                        },
                    },
                },
            }}
            onClick={(e) => item.onClick?.(e, item)}
            onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === 'Delete') {
                    item.onClose?.();
                }
            }}
        >
            <TooltipHost
                overflowMode={TooltipOverflowMode.Self}
                content={item.text}
                styles={{
                    root: {
                        overflow: 'hidden',
                        display: 'block',
                        alignItems: 'center',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: 200,
                        padding: '0 4px',
                    },
                }}
            >
                {item.text}
            </TooltipHost>
            {isSelected && <Icon iconName="CaretLeftSolid8" styles={{ root: { fontSize: 12, lineHeight: 35 } }} />}
            {showCloseButton && (
                <Icon
                    styles={{
                        root: {
                            padding: '0 4px',
                            color: theme.palette.neutralSecondary,
                            textAlign: 'center',
                            ':hover': {
                                color: 'red',
                            },
                            // zIndex is needed when the menu item is focused
                            zIndex: 2,
                        },
                    }}
                    ariaLabel={core.strings.query.close}
                    iconName="Cancel"
                    onClick={item.onClose}
                />
            )}
        </Stack>
    );
};
