import React from 'react';

import '@ag-grid-community/styles/ag-grid.css';

import {
    CellClickedEvent,
    CellDoubleClickedEvent,
    CellKeyDownEvent,
    ColumnApi,
    GridApi,
    GridReadyEvent,
    IRowNode,
    RowDataUpdatedEvent,
    RowGroupOpenedEvent,
    RowSelectedEvent,
} from '@ag-grid-community/core';
import { IContextualMenuItem } from '@fluentui/react';
import escapeRegExp from 'lodash/escapeRegExp';
import { observer } from 'mobx-react';

import { QueryCore } from '../../core/core';
import { getTelemetryClient } from '../../utils';
import { ITreeEntityMapper } from './actions';
import { ConnectionTree } from './connectionTree';
import { RowDataType } from './RowDataType';
import { databaseDescendantsComparer } from './RowDataTypes/DatabaseRowDataType';
import { FilterFlow } from './RowDataTypes/FilterFlow';
import { TreeViewRow } from './TreeView';

const { trackEvent, startTrackEvent, stopTrackEvent } = getTelemetryClient({
    component: 'ConnectionSearchTree',
    flow: '',
});

export interface ConnectionSearchTreeProps {
    core: QueryCore;
    searchTerm: string;
    entityActionsMapper: ITreeEntityMapper;
    onCellClicked: (event: CellClickedEvent) => void;
    onCellDoubleClicked: (event: CellDoubleClickedEvent) => void;
    onCellKeyDown: (event: CellKeyDownEvent) => void;
    onSelected: (event: RowSelectedEvent) => void;
    selectNodeInContext: (changeVisibility: boolean, agGridApi?: GridApi) => void;
    getContextMenuItems: (params?: IRowNode) => IContextualMenuItem[];
    onGridReady: (params: GridReadyEvent) => void;
    getRowData?: (expanded: { [id: string]: boolean }, treeEntityMapper: ITreeEntityMapper) => TreeViewRow[];
}

const MAX_SEARCH_RESULT = 10000;

export class ConnectionSearchTree extends React.Component<ConnectionSearchTreeProps> {
    private gridApi?: GridApi;
    private columnApi?: ColumnApi;
    private sessionID = `${new Date().getTime()}`;
    private countSearches = 0;
    private rowData: RowDataType[] = [];
    private filterFlow: FilterFlow;

    constructor(props: ConnectionSearchTreeProps) {
        super(props);
        this.filterFlow = new FilterFlow(props.core, this.props.entityActionsMapper);
    }

    public componentDidUpdate(prevProps: ConnectionSearchTreeProps): void {
        if (prevProps.searchTerm !== this.props.searchTerm) {
            this.expandAll();
            this.props.selectNodeInContext(false, this.gridApi);
            this.countSearches++;
        }
    }

    public componentDidMount(): void {
        const preFilterCount = this.props.core.store.connectionPane.countClustersWithDescendant();
        trackEvent('startFilter', {
            sessionID: this.sessionID,
            preFilterClustersCount: `${preFilterCount.clusters}`,
            preFilterDatabasesCount: `${preFilterCount.databases}`,
            preFilterFunctionsCount: `${preFilterCount.functions}`,
            preFilterTablesCount: `${preFilterCount.tables}`,
            preFilterColumnsCount: `${preFilterCount.columns}`,
            preFilterTotal: `${
                preFilterCount.clusters +
                preFilterCount.databases +
                preFilterCount.functions +
                preFilterCount.tables +
                preFilterCount.columns
            }`,
        });
    }

    public componentWillUnmount(): void {
        trackEvent('endFilter', {
            sessionID: this.sessionID,
            countSearches: `${this.countSearches}`,
        });
        this.filterFlow.removeFocusIcon(this.rowData);
    }

    render() {
        const escapedRegExp = escapeRegExp(this.props.searchTerm);
        const filteredRes = this.filterData(escapedRegExp);
        let rowData = filteredRes.rowData;
        let noRowDataText = '';
        if (filteredRes.hasTooManySearchResults) {
            noRowDataText = this.props.core.strings.query.connectionPane$search$tooManyResults;
            rowData = [];
        }

        if (filteredRes.hasEmptySearchResults) {
            noRowDataText = this.props.core.strings.query.connectionPane$search$noResults;
            rowData = [];
        }

        return (
            <ConnectionTree
                strings={this.props.core.strings}
                getContextMenuItems={this.props.getContextMenuItems}
                onCellClicked={this.onCellClicked}
                onCellDoubleClicked={this.props.onCellDoubleClicked}
                onCellKeyDown={this.props.onCellKeyDown}
                onRowGroupOpened={this.onRowGroupOpened}
                onRowDataUpdated={this.onRowDataUpdated}
                onGridReady={this.onGridReady}
                rowData={rowData}
                comparator={this.comparator}
                noRowsText={noRowDataText}
                highlightRegExp={escapedRegExp}
                treeEntityMapper={this.props.entityActionsMapper}
                getRowData={this.props.getRowData}
            />
        );
    }

    private onCellClicked = (event: CellClickedEvent) => {
        // backup the old entity
        const oldEntityInContext = this.props.core.store.connectionPane.entityInContext;

        // invoke props.onCellClicked
        this.props.onCellClicked(event);

        // send telemetry.
        const newEntityInContext = this.props.core.store.connectionPane.entityInContext;
        if (oldEntityInContext && newEntityInContext && oldEntityInContext.id !== newEntityInContext.id) {
            const newNode = event.api.getRowNode(newEntityInContext.id);
            if (newNode) {
                newNode.setSelected(true, true);
                trackEvent('selected', {
                    entityType: newEntityInContext.entityType,
                    oldEntityType: oldEntityInContext.entityType ?? '',
                    sessionID: this.sessionID,
                });
            }
        }
    };

    private onRowGroupOpened = (event: RowGroupOpenedEvent) => {
        if (event.data) {
            trackEvent('expanded', {
                expanded: `${event.node.expanded}`,
                entityType: event.data ? event.data.entityType : undefined,
                sessionID: this.sessionID,
            });
        }
    };

    private onRowDataUpdated = (params: RowDataUpdatedEvent): void => {
        if (params.api) {
            this.props.selectNodeInContext(false, params.api);
        }
    };

    private onGridReady = (params: GridReadyEvent): void => {
        if (!params.api) {
            return;
        }
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.expandAll();
        this.props.selectNodeInContext(false, this.gridApi);
        this.props.onGridReady(params);
        this.setSortDirection();
    };

    private comparator = (_valueA: unknown, _valueB: unknown, nodeA: IRowNode, nodeB: IRowNode): number => {
        return databaseDescendantsComparer(nodeA.data, nodeB.data);
    };

    private setSortDirection() {
        if (this.columnApi) {
            const colId = 'ag-Grid-AutoColumn';
            const columnState = this.columnApi.getColumnState();
            const targetIndex = columnState.findIndex((currentColumnState) => currentColumnState.colId === colId);

            if (targetIndex !== -1) {
                columnState[targetIndex] = { ...columnState[targetIndex], sort: 'asc' };
                this.columnApi.applyColumnState({ state: columnState });
            }
        }
    }

    private filterData = (
        searchTerm?: string
    ): {
        hasEmptySearchResults: boolean;
        hasTooManySearchResults: boolean;
        rowData?: RowDataType[];
    } => {
        const ignoreCase = 'i';
        const searchRegExp = searchTerm ? new RegExp(searchTerm, ignoreCase) : undefined;
        const filter = (id: string, details?: string) =>
            this.passFilterRule(id, searchRegExp) || this.passFilterRule(details, searchRegExp);
        startTrackEvent('filteredResultCountOnly');

        const count = this.filterFlow.getRowDataTypesCount(this.props.core.store.connectionPane!, filter);
        stopTrackEvent('filteredResultCountOnly', { sessionID: this.sessionID, count: `${count}` });
        if (count > MAX_SEARCH_RESULT) {
            return { hasEmptySearchResults: false, hasTooManySearchResults: true, rowData: undefined };
        }
        if (count === 0) {
            return { hasEmptySearchResults: true, hasTooManySearchResults: false, rowData: undefined };
        }

        startTrackEvent('filteredResult');
        this.filterFlow.removeFocusIcon(this.rowData);

        this.rowData = this.filterFlow.getRowDataTypes(this.props.core.store.connectionPane!, filter);
        stopTrackEvent('filteredResult', { sessionID: this.sessionID, count: `${count}` });
        this.filterFlow.addFocusIcon(this.rowData);
        return { hasEmptySearchResults: false, hasTooManySearchResults: false, rowData: this.rowData };
    };

    private passFilterRule(str?: string, searchRegExp?: RegExp) {
        const hasPassedFilter = !!searchRegExp && !!str && searchRegExp.test(str);
        return hasPassedFilter;
    }

    private expandAll() {
        if (this.gridApi) {
            this.gridApi.forEachNode((node) => {
                node.expanded = true;
            });
            this.gridApi.onGroupExpandedOrCollapsed();
        }
    }
}

// eslint-disable-next-line mobx/no-anonymous-observer
export default observer(ConnectionSearchTree);
