import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Button } from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';

import { KweAgGridLocale } from '../locale';

import styles from './styles.module.scss';

interface ClosableCellParams extends ICellRendererParams {
    wrappedRenderer: React.FC<ICellRendererParams>;
    locale: KweAgGridLocale;
}

const autoGroupColumnId = 'ag-Grid-AutoColumn';

export function closableCell({ locale, wrappedRenderer, ...params }: ClosableCellParams) {
    return (
        <>
            {wrappedRenderer(params)}
            {params.column?.getColId() !== autoGroupColumnId ? (
                <Button
                    icon={<DismissRegular />}
                    className={styles.expandClose}
                    aria-label={locale.agGrid.expand.closeDetailsView}
                    size="small"
                    appearance="transparent"
                    onClick={() => params.node.setExpanded(false)}
                />
            ) : null}
        </>
    );
}
