import { Database, getClusterFromDatabase, QueryStoreEnv } from '../../stores';

export const getBaseRootPath = (env: QueryStoreEnv, database: Database) => {
    if (isTridentView(env)) {
        return [];
    } else {
        const cluster = getClusterFromDatabase(database);
        return [cluster.name, database.name];
    }
};

// This function was added to support trident workflow. In trident clusters are not visible in the tree, only the databases.
export const isTridentView = (env: QueryStoreEnv) => {
    return env.featureFlags.TridnetView ? true : false;
};
