import React from 'react';
import { Button, Caption1 } from '@fluentui/react-components';
import { ChevronDoubleDown16Regular, ChevronDoubleUp16Regular } from '@fluentui/react-icons';

import styles from './CollapsibleMessageBar.module.scss';

export interface CollapsibleMessageBarProps {
    icon?: React.ReactNode;
    title?: string;
    text?: string;
    className?: string;
}

export const CollapsibleMessageBar: React.FunctionComponent<CollapsibleMessageBarProps> = ({
    icon,
    title,
    text,
    className: externalClassName,
}) => {
    const contentRef = React.useRef<HTMLDivElement>(null);

    const [isOverflown, setIsOverflown] = React.useState(false);
    const [isExpanded, setExpanded] = React.useState(false);

    function checkOverflow(elem: Element) {
        const element = elem.lastChild as HTMLDivElement | null;
        if (element) {
            setIsOverflown(element.clientWidth < element.scrollWidth);
        }
    }

    React.useLayoutEffect(() => {
        const ob = new ResizeObserver(([content]) => {
            checkOverflow(content.target);
        });

        ob.observe(contentRef.current!);

        checkOverflow(contentRef.current!);

        return () => ob.disconnect();
    }, [text, isExpanded]);

    return (
        <div className={`${styles.messageBar} ${externalClassName ?? ''}`}>
            {icon ? <div className={styles.icon}>{icon}</div> : null}
            <div className={styles.content} ref={contentRef}>
                <Caption1 wrap={isExpanded} block truncate={!isExpanded}>
                    {title ? <span className={styles.title}>{title} </span> : null}
                    {text ?? ''}
                </Caption1>
            </div>
            <div className={styles.collapseExpand}>
                {isOverflown || isExpanded ? (
                    <Button
                        size="small"
                        appearance="transparent"
                        onClick={() => setExpanded((e) => !e)}
                        icon={isExpanded ? <ChevronDoubleUp16Regular /> : <ChevronDoubleDown16Regular />}
                    />
                ) : null}
            </div>
        </div>
    );
};
