import React from 'react';
import { observer } from 'mobx-react-lite';

import * as client from '@kusto/client';
import { formatLiterals, Theme } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';
import { ExtendedVisualizationOptions, KustoDataProps, RenderAsLinkFn } from '@kusto/visualizations';

import { defaultVisualOptions } from '../../charting';
import { useCreateConditionalFormatting } from '../../colorRules';
import { standardSizes } from '../../constants';
import { KweRtdVisualContext } from '../../context';
import { colorRulesSegment, crossFilterSegmentToggle, drillthroughSegmentToggle } from '../../inputLib';
import { createCrossFilterConfig } from './crossFilter';
import { createDrillthroughConfig } from './drillthrough';
import { model, RtdVisualSelector, TableModelDef } from './model';
import { createTableRenderLinksConfig } from './TableRenderLinks';

type ClickableColumnsMap = Map<string, { kind: 'self' } | { kind: 'ref'; urlColumn: string }>;

function createClickableColumnsMap(
    visualOptions: Fwk.IDataVisualProps<TableModelDef>['visualOptions']
): undefined | ClickableColumnsMap {
    if (!visualOptions.table__enableRenderLinks) {
        return;
    }

    const clickableColumnsMap: ClickableColumnsMap = new Map();

    for (const renderLink of visualOptions.table__renderLinks) {
        if (renderLink.disabled || renderLink.urlColumn === undefined) {
            continue;
        }

        if (renderLink.displayColumn && renderLink.urlColumn !== renderLink.displayColumn) {
            clickableColumnsMap.set(renderLink.displayColumn, { kind: 'ref', urlColumn: renderLink.urlColumn });
        } else {
            clickableColumnsMap.set(renderLink.urlColumn, { kind: 'self' });
        }
    }

    return clickableColumnsMap;
}

function createRenderAsLink(visualOptions: Fwk.IDataVisualProps<TableModelDef>['visualOptions']) {
    const clickableColumnsMap = createClickableColumnsMap(visualOptions);

    const renderAsLink: RenderAsLinkFn = (column, rowData) => {
        const target = clickableColumnsMap?.get(column);

        if (!target) {
            return;
        }

        const maybeUrl = rowData[target.kind === 'self' ? column : target.urlColumn];
        // We'll allow any kind of a string to be
        // a url. This way customers can configure
        // relative links if they so choose.
        return typeof maybeUrl === 'string' ? maybeUrl.trim() : undefined;
    };

    return renderAsLink;
}

function createRtdTableVisual(ctx: KweRtdVisualContext): React.FC<Fwk.IDataVisualProps<TableModelDef>> {
    return observer(function RtdTableVisual(props) {
        const strings = ctx.strings;
        const { queryResult, visualOptions, dashboard, timeZone } = props;
        const { columns, rows } = React.useMemo(
            () => ({
                columns: client.clientColumnsFromKweColumns(queryResult.dataFrame.fields),
                rows: client.queryAreaRowObjectsFromDataFrame(queryResult.dataFrame),
            }),
            [queryResult]
        );

        const ConditionalFormattingConfig = useCreateConditionalFormatting(ctx.strings, props, 'table');

        const crossFilter = React.useMemo(
            () => createCrossFilterConfig(columns, visualOptions, dashboard, strings),
            [columns, visualOptions, dashboard, strings]
        );
        const drillthrough = React.useMemo(
            () => createDrillthroughConfig(columns, visualOptions, dashboard, strings),
            [columns, visualOptions, dashboard, strings]
        );
        const getGridMenu: KustoDataProps['getContextMenuItemsKwe'] = React.useCallback(
            (defaultMenu, event) => {
                const items = [...defaultMenu];
                if (event?.colDef?.colId && drillthrough?.[event.colDef.colId]) {
                    const createSubItems = drillthrough[event.colDef.colId];

                    items.unshift(
                        {
                            key: 'drillthrough',
                            text: ctx.strings.rtdProvider.visuals.drillthrough,
                            subMenuProps: {
                                items: createSubItems?.(event) ?? [],
                            },
                        }
                        // Colors are messed up on this. Would be nice to have it though.
                        // { key: 'divider_1', itemType: ContextualMenuItemType.Divider },
                    );
                }

                if (event?.colDef?.colId && crossFilter?.[event.colDef.colId]) {
                    const onCrossFilter = crossFilter[event.colDef.colId];
                    items.unshift(
                        {
                            key: 'cross-filter',
                            name: ctx.strings.rtdProvider.visuals.crossFilter,
                            onClick: () => {
                                onCrossFilter.map((callback) => callback(event));
                            },
                        }
                        // Colors are messed up on this. Would be nice to have it though.
                        // { key: 'divider_1', itemType: ContextualMenuItemType.Divider },
                    );
                }

                return items;
            },
            [crossFilter, drillthrough]
        );

        const visualizationOptions: ExtendedVisualizationOptions = React.useMemo(
            () => ({
                ...defaultVisualOptions,
                Visualization: 'table',
                ColumnFormatting: {
                    ConditionalFormattingConfig,
                    LinkConfig: { renderAsLink: createRenderAsLink(visualOptions) },
                },
                // Without this, table will be sorted for the user
                IsQuerySorted: queryResult.sorted ?? false,
            }),
            [visualOptions, queryResult.sorted, ConditionalFormattingConfig]
        );

        const resultToDisplay = React.useMemo(() => {
            return {
                tableName: null,
                rows: rows,
                columns: columns,
                visualizationOptions: visualizationOptions as client.VisualizationOptions,
            };
        }, [visualizationOptions, rows, columns]);

        return ctx.renderTable({
            resultToDisplay: resultToDisplay,
            autoSizeAllData: true,
            locale: ctx.strings,
            timezone: timeZone,
            theme: props.isDarkTheme ? Theme.Dark : Theme.Light,
            getContextMenuItemsKwe: getGridMenu,
        });
    });
}

function inputLayout(ctx: KweRtdVisualContext): RtdVisualSelector {
    return () => {
        return {
            visual: {
                segments: [
                    Fwk.tileInputSegment(ctx.strings.rtdProvider.visuals.input.segment$generalTitle, {}),
                    Fwk.tileInputSegment<TableModelDef>(
                        ctx.strings.rtdProvider.visuals.table.renderLinks.segmentTitle,
                        {
                            hideReset: true,
                            toggle: {
                                optionKey: 'table__enableRenderLinks',
                                titleText: ctx.strings.rtdProvider.visuals.table.renderLinks.toggleTitleText,
                                labels: {
                                    disabled: ctx.strings.rtdProvider.visuals.input.segment$toggleOnOffLabels$off,
                                    enabled: ctx.strings.rtdProvider.visuals.input.segment$toggleOnOffLabels$on,
                                },
                            },
                        },
                        createTableRenderLinksConfig(ctx.strings)
                    ),
                    colorRulesSegment(ctx, 'table'),
                ],
            },
            interactions: {
                segments: [
                    Fwk.tileInputSegment<TableModelDef>(
                        ctx.strings.rtdProvider.visuals.input.segment$crossFilterTitle,
                        {
                            toggle: crossFilterSegmentToggle(ctx),
                            hideReset: true,
                        },
                        Fwk.createTileInput.crossFilter('crossFilter', 'table', (options): Fwk.CrossFilterVisual => {
                            const schema = options.getSchema();
                            return {
                                kind: 'available',
                                id: 'column',
                                displayName: ctx.strings.rtdProvider.visuals.crossFilterClickInteractionType,
                                propertiesDisplayName: ctx.strings.rtdProvider.visuals.crossFilterColumnDropdownLabel,
                                properties:
                                    schema.kind !== 'available'
                                        ? []
                                        : schema.schema.map((c) => ({
                                              id: c.name,
                                              kind: 'available',
                                              displayName: c.name,
                                              dataTypes: [[Fwk.basicParamTypeFromColumnType(c.type)]],
                                          })),
                                formatUnavailablePropertyError: (property) => {
                                    return formatLiterals(
                                        ctx.strings.rtdProvider.visuals.highcharts
                                            .crossFilterDimensions$columnNotFoundError,
                                        { columnName: property }
                                    );
                                },
                            };
                        })
                    ),
                    Fwk.tileInputSegment<TableModelDef>(
                        ctx.strings.rtdProvider.visuals.input.segment$drillthroughTitle,
                        {
                            toggle: drillthroughSegmentToggle(ctx),
                            hideReset: true,
                        },
                        Fwk.createTileInput.drillthrough('drillthrough', 'table', (options): Fwk.DrillthroughVisual => {
                            const schema = options.getSchema();
                            return {
                                kind: 'available',
                                id: 'column',
                                displayName: ctx.strings.rtdProvider.visuals.crossFilterClickInteractionType,
                                propertiesDisplayName: ctx.strings.rtdProvider.visuals.crossFilterColumnDropdownLabel,
                                properties:
                                    schema.kind !== 'available'
                                        ? []
                                        : schema.schema.map((c) => ({
                                              id: c.name,
                                              kind: 'available',
                                              displayName: c.name,
                                              dataTypes: [[Fwk.basicParamTypeFromColumnType(c.type)]],
                                          })),
                                formatUnavailablePropertyError: (property: string) => {
                                    return formatLiterals(
                                        ctx.strings.rtdProvider.visuals.highcharts
                                            .crossFilterDimensions$columnNotFoundError,
                                        { columnName: property }
                                    );
                                },
                            };
                        })
                    ),
                ],
            },
        };
    };
}

export function tableVisualConfig(ctx: KweRtdVisualContext) {
    return Fwk.visualConfig<TableModelDef>({
        label: 'Table',
        iconName: ctx.strings.rtdProvider.visuals.table.visualTitle,
        config: {
            model,
            inputLayout: inputLayout(ctx),
            heuristics: () => undefined,
            Component: createRtdTableVisual(ctx),
            ...standardSizes,
        },
    });
}
