// @kusto/visual-fwk doesn't feel like the right place to put this, but I'm not sure
// where else it should go. @kusto/utils? It's own package?

import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';

import { hrefCapturing } from '@kusto/utils';

import * as styles from './Markdown.module.scss';

/**
 * Isn't used anywhere in this package.
 *
 *  Dashboard build-in text tiles and the markdown visual need to have their
 *  sizes kept in sync, and they don't depend on each other, so this needs to
 *  live in an unrelated spot.
 */
export const markdownSizes = {
    defaultSize: { width: 9, height: 7 },
    minimumSize: { width: 2, height: 1 },
};

const remarkPlugins = [remarkGfm];

export interface MarkdownProps extends React.HTMLAttributes<HTMLDivElement> {
    onLinkClick: (href: string, newTab: boolean) => void;
    markdownText: string;
    enableImageOverflow?: boolean;
}

export const Markdown: React.FC<MarkdownProps> = observer(function Markdown({
    markdownText,
    className,
    onLinkClick,
    enableImageOverflow,
    ...divProps
}) {
    const components: ReactMarkdownOptions['components'] = React.useMemo(
        () => ({
            a: ({ node, ...props }) => (
                // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <a
                    onClick={hrefCapturing(
                        () => {
                            if (props.href) {
                                onLinkClick(props.href, false);
                            }
                        },
                        { ignoreExternalLinks: true }
                    )}
                    {...props}
                />
            ),
        }),
        [onLinkClick]
    );
    return (
        <div
            // Tab index required because this div may have a scroll bar. See link below.
            // https://www.chromestatus.com/feature/5231964663578624
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            {...divProps}
            className={classNames(styles.markdown, className, enableImageOverflow && styles.enableImageOverflow)}
        >
            {/* Markdown appears to be working, I'm _pretty_ sure this is an issue with the types. */}
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <ReactMarkdown remarkPlugins={remarkPlugins as any} components={components}>
                {markdownText}
            </ReactMarkdown>
        </div>
    );
});
