// Configurable bar allow to setup default tool bar element and that will be configurable by components that use the bar
// For example:
//    - tabsBar set the defaults new tab, tabs list & undo tabs.
//    - KWE will append the headers buttons in case of ibiza mode (no headers) and KWE samples when needed (Feature flags/ App more)
//
export interface ConfigurableBarItem {
    key: string;
    onRender: () => JSX.Element;
    moveToOverflow?: boolean;
    order?: number;
}

export type OptionalConfigurableBarItem = ConfigurableBarItem | undefined | null;

export type ConfigurableBarItems<ItemsKey extends string | number = string> = Record<
    ItemsKey,
    OptionalConfigurableBarItem
>;

export type ConfigurableBarItemsFunc<DefaultItemKey extends string | number, Props> = (
    props: Props,
    defaultItems: ConfigurableBarItems<DefaultItemKey>
) => ConfigurableBarItems;

export const configureSortAndFilterBarItems = <DefaultItemKey extends string | number, Props>(
    configureFunc: ConfigurableBarItemsFunc<DefaultItemKey, Props> | undefined,
    props: Props,
    defaultItems: ConfigurableBarItems<DefaultItemKey>
) => {
    const items = configureFunc?.(props, defaultItems) ?? defaultItems;
    return Object.values(items)
        .filter((item): item is ConfigurableBarItem => item !== undefined && item !== null)
        .sort((a, b) => (a?.order ?? -1) - (b?.order ?? -1));
};
