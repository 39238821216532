import * as React from 'react';
/**
 * @internal
 */ const defaultSubTreeContextValue = {
    level: 0,
    contextType: 'subtree'
};
/**
 * @internal
 */ export const SubtreeContext = React.createContext(undefined);
export const useSubtreeContext_unstable = ()=>{
    var _React_useContext;
    return (_React_useContext = React.useContext(SubtreeContext)) !== null && _React_useContext !== void 0 ? _React_useContext : defaultSubTreeContextValue;
};
