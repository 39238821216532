import * as React from 'react';
import { useARIAButtonProps } from '@fluentui/react-aria';
import { getIntrinsicElementProps, slot } from '@fluentui/react-utilities';
import { useButtonContext } from '../../contexts/ButtonContext';
/**
 * Given user props, defines default props for the Button, calls useButtonState, and returns processed state.
 * @param props - User provided props to the Button component.
 * @param ref - User provided ref to be passed to the Button component.
 */ export const useButton_unstable = (props, ref)=>{
    const { size: contextSize } = useButtonContext();
    const { appearance = 'secondary', as = 'button', disabled = false, disabledFocusable = false, icon, iconPosition = 'before', shape = 'rounded', size = contextSize !== null && contextSize !== void 0 ? contextSize : 'medium' } = props;
    const iconShorthand = slot.optional(icon, {
        elementType: 'span'
    });
    return {
        // Props passed at the top-level
        appearance,
        disabled,
        disabledFocusable,
        iconPosition,
        shape,
        size,
        iconOnly: Boolean((iconShorthand === null || iconShorthand === void 0 ? void 0 : iconShorthand.children) && !props.children),
        components: {
            root: 'button',
            icon: 'span'
        },
        root: slot.always(getIntrinsicElementProps(as, useARIAButtonProps(props.as, props)), {
            elementType: 'button',
            defaultProps: {
                ref: ref,
                type: 'button'
            }
        }),
        icon: iconShorthand
    };
};
