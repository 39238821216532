@use '@kusto/style/tokens';

.distributionList {
    margin: 0;
    padding: 0;
    width: 100%;
}

.itemContainer {
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    border-bottom: tokens.$strokeWidthThin tokens.$colorNeutralStroke2 solid;
    align-items: center;
    justify-content: center;
    padding: tokens.$spacingVerticalM tokens.$spacingHorizontalS;

    .textLine {
        display: flex;
        padding: 0 tokens.$spacingHorizontalL tokens.$spacingVerticalS 0;
        align-items: center;
        justify-content: space-between;
        gap: tokens.$spacingHorizontalS;
        align-self: stretch;

        .title {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .percentage {
            color: tokens.$colorNeutralForeground4;
        }
    }
    .percentageBar {
        align-self: stretch;
        .percentageBarFill {
            background-color: tokens.$colorBrandForegroundInverted;
            height: tokens.$strokeWidthThickest;
            border-radius: tokens.$borderRadiusXLarge;
        }
    }
}
