import { ImmutableMap } from './ImmutableMap';
export function createCheckedItems(iterable) {
    if (iterable === undefined) {
        return ImmutableMap.empty;
    }
    if (ImmutableMap.isImmutableMap(iterable)) {
        return iterable;
    }
    const internalMap = new Map();
    for (const item of iterable){
        if (Array.isArray(item)) {
            internalMap.set(item[0], item[1]);
        } else {
            internalMap.set(item, true);
        }
    }
    return ImmutableMap.dangerouslyCreate_unstable(internalMap);
}
