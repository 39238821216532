import * as React from 'react';
/**
 * Context used communicate with a child menu item that it is a trigger for a submenu
 */ const MenuTriggerContext = React.createContext(undefined);
const menuTriggerContextDefaultValue = false;
export const MenuTriggerContextProvider = MenuTriggerContext.Provider;
export const useMenuTriggerContext_unstable = ()=>{
    var _React_useContext;
    return (_React_useContext = React.useContext(MenuTriggerContext)) !== null && _React_useContext !== void 0 ? _React_useContext : menuTriggerContextDefaultValue;
};
