import React from 'react';
import { ContextualMenu, IContextualMenuItem } from '@fluentui/react';
import * as clipboard from 'clipboard-polyfill';
import * as H from 'highcharts';

import { RenderHelper } from '@kusto/ui-components';

import { ChartEvents } from '../components/Chart';

export function addPointContextMenuHandler(
    signal: AbortSignal,
    render: RenderHelper,
    chart: H.Chart,
    additionalItems?: ChartEvents['onHighchartsPointMenuItems']
) {
    const listener = (event: MouseEvent) => {
        // This appears to be working for all cases, as far as I can tell, but
        // it doesn't use the same code paths as click events I think?
        //
        // code like this _seems_ like it should be the more "correct" solution,
        // but `findNearestKDPoint` always fails the first time because the
        // kdtree is build asynchronously.
        //
        // ```typescript
        // const normal = chart.pointer.normalize(event);
        // H.extend(normal, chart.pointer.getCoordinates(normal) as any);
        // const point = chart.pointer.findNearestKDPoint(chart.series, false,  normal);
        // ```
        // `chart.hoverPoint` is not present if tooltips are disabled. If we
        // disable tooltips in the future we'll need to figure out how to
        // make the above code work.
        const hoverPoint = chart.hoverPoint;
        if (!hoverPoint) {
            return;
        }
        // Added while enabling lints

        const row = hoverPoint.options.custom!.dataItem.row;

        event.preventDefault();
        render((dispose, { signal }) => {
            document.addEventListener('contextmenu', dispose, { capture: true });
            signal.addEventListener('abort', () =>
                document.removeEventListener('contextmenu', dispose, { capture: true })
            );
            const items: IContextualMenuItem[] = [
                {
                    key: 'copy',
                    text: 'Copy',
                    onClick: () => {
                        const format = hoverPoint.options.x ? '{point.x} {point.y}' : '{point.name} {point.y}';
                        clipboard.writeText(hoverPoint.tooltipFormatter(format));
                    },
                },
            ];

            // row is only present on dataItem if dataItems.length === rows.length
            if (row && additionalItems) {
                items.push(...additionalItems(row));
            }

            return <ContextualMenu items={items} hidden={false} target={event} onDismiss={dispose} />;
        });
    };

    const container = chart.container;
    container.addEventListener('contextmenu', listener);
    signal.addEventListener('abort', () => container.removeEventListener('contextmenu', listener));
}
