import React from 'react';
import { observer } from 'mobx-react-lite';

import type { DropdownWithSearchOption } from '@kusto/ui-components';
import { KweException } from '@kusto/utils';

import { tileInputClassNames } from '../../inputLib/createTileInput';
import type { KweVisualFwkLocale } from '../../types';
import type { VisualOptionsModel } from '../model/model';
import { VisualizationOption, VisualTypePicker } from './VisualTypePicker';

export interface VisualizationTypePickerProps {
    t: KweVisualFwkLocale;
    model: VisualOptionsModel;
}

export const VisualizationTypePicker: React.FC<VisualizationTypePickerProps> = observer(
    function VisualizationTypePicker({ t, model }) {
        const layout = model.args.layout;
        const visuals = model.args.parsedVisuals;
        const visualType = model.pluginKey.get();

        const defaultVariants = React.useMemo(() => {
            const variants: Record<string, undefined | string> = {};

            for (const option of layout) {
                if (typeof option === 'object') {
                    for (const variant of option.variants) {
                        variants[variant] = option.default;
                    }
                }
            }

            return variants;
        }, [layout]);

        const options = React.useMemo(
            (): DropdownWithSearchOption[] =>
                layout.map((option) => {
                    if (typeof option === 'string') {
                        const visual = visuals[option];
                        if (visual === undefined) {
                            throw new KweException();
                        }
                        return {
                            key: option,
                            text: visual.label,
                            content: <VisualizationOption label={visual.label} iconName={visual.iconName} />,
                        };
                    }
                    return {
                        key: option.default,
                        text: option.label,
                        icon: option.iconName,
                        content: <VisualizationOption label={option.label} iconName={option.iconName} />,
                    };
                }),
            [layout, visuals]
        );
        return (
            <VisualTypePicker
                className={tileInputClassNames.basicInput}
                data-testid="visual-type"
                id="visualType"
                selectedKeys={defaultVariants[visualType] ?? visualType}
                options={options}
                label={t.visualFwk.visualConfig.visualType}
                model={model}
            />
        );
    }
);
