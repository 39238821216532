import { Account } from '@kusto/utils';

import { KWE_CONSTANTS, KWE_ENV } from '../../common/constants';

export const MSA_PASSTHROUGH_TENANT = 'f8cdef31-a31e-4b4a-93e4-5f571e91255a';
const MSA_TENANT = '9188040d-6c67-4c5b-b112-36a304b66dad';

const msaTenants = [MSA_PASSTHROUGH_TENANT, MSA_TENANT];

export const isMSATenant = (tenantId: string): boolean => {
    return msaTenants.some((msaTenant: string) => msaTenant === tenantId);
};

export const isMSAAccount = (account: Account): boolean => {
    return msaTenants.some((msaTenant: string) => account.homeAccountId.endsWith(msaTenant));
};

export const createAuthorityUrl = (tenant?: string) => {
    return `${KWE_ENV.aadEndpoint}${tenant ?? KWE_CONSTANTS.tenants.multiTenant}`;
};

/**
 * Return true if this account is under the home account.
 * Home tenant = the tenant in which the account is from, in all other tenants the account is just a guest.
 * see https://docs.microsoft.com/en-us/azure/active-directory/develop/single-and-multi-tenant-apps#who-can-sign-in-to-your-app
 * For MSA account there are 2 global tenants (see msaTenants)
 */
export const isHomeTenant = (account: Account): boolean => {
    if (isMSAAccount(account)) {
        return msaTenants.some((msaTenant: string) => msaTenant === account.tenantId);
    } else {
        return account.homeAccountId.endsWith(account.tenantId);
    }
};
