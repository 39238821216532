import React, { CSSProperties, useCallback } from 'react';
import { Link, Text, Tooltip } from '@fluentui/react-components';
import { Copy20Regular } from '@fluentui/react-icons';

import styles from './CopyButton.module.scss';

export function copyToClipboard(text: string) {
    return navigator.clipboard.writeText(text);
}

export interface CopyButtonProps {
    /** Text to display for the button, if not provided will display the `copyText`. */
    displayText?: string;
    /** Text to copy when the button clicked */
    copyText: string;
    /** Color of the button, default: Theme color */
    color?: string;
    /** Callback to execute when the text was copied */
    onCopied?: () => void;
    /** Localized strings */
    strings: {
        /** "Copy to clipboard" text, will appear in the tooltip */
        copyToClipboard: string;
    };
    /** Custom style for the component */
    style?: CSSProperties;
    customTextStyle?: CSSProperties;
    customIconStyle?: CSSProperties;
}

/** Button that copies text to clipboard */
export const CopyButton: React.FunctionComponent<CopyButtonProps> = (props) => {
    const {
        displayText,
        copyText,
        color,
        onCopied,
        strings,
        style,
        customTextStyle = {},
        customIconStyle = {},
    } = props;

    const onClickCopy = useCallback(
        async (text: string) => {
            await copyToClipboard(text);
            onCopied?.();
        },
        [onCopied]
    );

    return (
        <Tooltip content={strings.copyToClipboard} relationship="label" withArrow>
            <Link onClick={() => onClickCopy(copyText)}>
                <div className={styles.copyButton} style={style}>
                    {(displayText ?? copyText) && ( // to avoid the margin when there is no text
                        <Text className={styles.text} style={{ ...customTextStyle, color }}>
                            {displayText ?? copyText}
                        </Text>
                    )}
                    <Copy20Regular color={color} style={customIconStyle} />
                </div>
            </Link>
        </Tooltip>
    );
};
