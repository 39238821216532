import React from 'react';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';

import { Announced, IButton, ISearchBox, IStyle, ITheme } from '@fluentui/react';
import { classNamesFunction, IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import debounce from 'lodash/debounce';
import * as mobx from 'mobx';
import { Observer, observer } from 'mobx-react-lite';

import { formatLiterals, IKweTelemetry, Theme, useTelemetry } from '@kusto/utils';

import { useQueryCore } from '../../core/core';
import { QueryStrings } from '../../core/strings';
import { Layout } from '../../stores';
import { TableResult } from '../../stores/queryCompletionInfo';
import { ISettings } from '../../stores/settings/settings';
import { Tab } from '../../stores/tab';
import { ErrorSnackbar } from './ErrorSnackbar';
import { ADD_VISUAL_ITEM_KEY, QueryResultsBar } from './QueryResultsBar';
import { QueryResultVisualization } from './QueryResultVisualization';
import { captureResultImageIfNeeded } from './utils.ts';

import './QueryResults.scss';

const styles: { root: React.CSSProperties } = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
};

interface Props {
    strings: QueryStrings;
    telemetry: IKweTelemetry;
    tableResults: undefined | Array<TableResult>;
    tabInContext: Tab;
    layout: Layout;
    searchEnabled: number;
    hideEmptyColumns: boolean;
    isFetching: boolean;
    fetchStartTime: number;
    settings: ISettings;
    settingsTheme: Theme;

    theme?: ITheme;
    styles?: IStyleFunctionOrObject<QueryResultsStyleProps, QueryResultsStyle>;
}

interface State {
    // Elapsed time as we want to show it (seconds with 3 numbers after decimal dot).
    formattedElapsedTime: string;
    barWidth?: number;
    isCompactMode?: boolean;
}

const getClassNames = classNamesFunction<QueryResultsStyleProps, QueryResultsStyle>();
type QueryResultsStyleProps = Required<Pick<Props, 'theme'>>;
interface QueryResultsStyle {
    searchBar: IStyle;
    collapseIcon: IStyle;
    actionBar: IStyle;
}
const getStyles = (props: QueryResultsStyleProps): QueryResultsStyle => {
    const { theme } = props;
    const { palette } = theme;

    return {
        searchBar: [
            'queryResults-searchBar',
            {
                backgroundColor: palette.white,
            },
        ],
        collapseIcon: [
            'collapse-monaco-editor',
            {
                color: `${props.theme.palette.black} !important`, // important against customIcons.scss
                fontSize: `8px !important`, // important against customIcons.scss
            },
        ],
        actionBar: {
            backgroundColor: palette.white,
            position: 'relative',
        },
    };
};

/**
 * Displays query results. this includes several tabs (graphs, tables ETC) and query execution status.
 */
class QueryResultsBase extends React.Component<Props, State> {
    private searchButtonRef = React.createRef<IButton>(); // used to re-focus on the search button after the search bar dismisses
    private searchBoxRef = React.createRef<ISearchBox>(); // used to set focus on the SearchBox
    private searchBarRef = React.createRef<HTMLDivElement>(); // used to fade out the Search Bar
    private searchPlaceholderRef = React.createRef<HTMLDivElement>(); // passed to child component. The search component will be injected into it.
    constructor(props: Props) {
        super(props);
        this.state = {
            formattedElapsedTime: '0',
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.searchEnabled! > 0) {
            this.resetSearchEnabledAfterRunningQuery(prevProps.tableResults !== this.props.tableResults);

            // re-focus on the search box, if search was enabled again (using shortcuts) when search was already enabled.
            if (prevProps.searchEnabled !== this.props.searchEnabled && this.searchBoxRef.current) {
                this.props.telemetry.trace('onSearchRefocused');
                this.searchBoxRef.current.focus();
            }
        }

        this.debounceCaptureResultImageIfNeeded();
    }

    componentDidMount() {
        this.debounceCaptureResultImageIfNeeded();
    }

    render() {
        return (
            // We can't access mobx observables in the `render` method of a class component directly
            <Observer>
                {() => {
                    const tab = this.props.tabInContext;
                    if (!tab) {
                        return null;
                    }

                    let errorElement: JSX.Element | undefined = undefined;

                    const executionStatus = tab.executionStatus;

                    const completionInfo = tab.completionInfo;
                    const { queryResourceConsumption: stats, clientActivityId } = completionInfo || {
                        queryResourceConsumption: undefined,
                        clientActivityId: 'empty',
                    };

                    const classNames = getClassNames(this.props.styles, { theme: this.props.theme! });

                    let announcement: string | undefined;
                    // if we're currently during query execution we don't display any results.
                    if (!tab.isFetching && tab.runRequest === 'No') {
                        switch (executionStatus) {
                            case 'notStarted':
                                break;
                            case 'canceled':
                                // don't display any results if we're cancelled or didn't execute anything yet.
                                announcement = this.props.strings.screenReader$QueryCancelled;
                                break;
                            case 'failed':
                            case 'gotFromCache':
                            case 'done':
                                if (executionStatus === 'failed') {
                                    const message = tab.errorMessage;

                                    errorElement = <ErrorSnackbar message={message} />;
                                }

                                announcement = this.props.strings.screenReader$QueryCompletedNoDatasets;
                                const numberOfDatasets = stats?.dataset_statistics?.length ?? 0;
                                if (numberOfDatasets) {
                                    announcement = formatLiterals(
                                        this.props.strings.screenReader$QueryCompletedWithDatasets,
                                        {
                                            numberOfDatasets: numberOfDatasets.toLocaleString(),
                                        }
                                    );
                                }
                                break;
                            default:
                                throw new Error(`unexpected executionStatus ${executionStatus}`);
                        }
                    }

                    // This color style was added mainly because recharts have parts (such as legend) that inherit the colors.
                    // In our case they were inherited from top-level <Fabric> tag which set color to be #333 (pretty dark).
                    // This is not good when we're on dark theme.
                    const color = this.props.settingsTheme === Theme.Dark ? 'white' : undefined;
                    const searchBar = this.getSearchBar(classNames.searchBar);

                    return (
                        // Disabled during lint rule enable
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                        <div
                            role="region"
                            key={clientActivityId}
                            onKeyDown={(e) => this.onKeyDown(e)}
                            aria-label={this.props.strings.queryResults}
                            style={{ ...styles.root, color }}
                        >
                            <div className={classNames.actionBar}>
                                <QueryResultsBar
                                    onLinkClick={(itemKey) => {
                                        const tabInContext = this.props.tabInContext!;

                                        const completionInfo = tabInContext.completionInfo!;

                                        const results = tabInContext.results!;
                                        if (itemKey === ADD_VISUAL_ITEM_KEY) {
                                            this.props.telemetry.trace('VisualOptions.addVisual');
                                            mobx.runInAction(() => {
                                                if (results.resultsToDisplay.length === 1) {
                                                    results.addVisual();
                                                    completionInfo.setResultInContext('1');
                                                    this.props.layout.setVisualConfigOpen(true);
                                                }
                                            });
                                        } else {
                                            completionInfo.setResultInContext(itemKey!);
                                        }
                                    }}
                                    theme={this.props.theme!}
                                    collapseIconClassName={classNames.collapseIcon}
                                    onSearchClicked={() =>
                                        this.props.telemetry.trace('onSearch', { trigger: 'button' })
                                    }
                                    searchButtonRef={this.searchButtonRef}
                                />
                                {searchBar}
                            </div>
                            {errorElement}
                            <QueryResultVisualization
                                searchBoxRef={this.searchBoxRef}
                                searchEnabled={this.isSearchEnabled}
                                searchPlaceholderRef={this.searchPlaceholderRef}
                                onSearchClear={this.onCloseSearchButtonClicked}
                            />
                            {announcement && <Announced message={announcement} />}
                        </div>
                    );
                }}
            </Observer>
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private onKeyDown(event: any) {
        if (event.ctrlKey && event.key === 'f') {
            this.props.telemetry.trace('onSearch', { trigger: 'shortcut', shortcut: 'ctrl+f' });
            this.props.tabInContext?.enableSearch(true);
            event.preventDefault();
            event.stopPropagation();
        }
    }

    /**
     * Hides the search bar when a query is executing or when results has changed.
     */
    private resetSearchEnabledAfterRunningQuery(hasResultsChanged: boolean) {
        if (this.props.searchEnabled === 0 || !this.props.tabInContext) {
            return;
        }
        mobx.runInAction(() => {
            const tab = this.props.tabInContext!;
            const isRunningQuery = tab.isFetching || tab.runRequest === 'Yes';
            if (hasResultsChanged || isRunningQuery) {
                this.props.tabInContext?.enableSearch(false);
            }
        });
    }

    /**
     * Gets whether the search is enabled. Basically casts `this.props.searchEnabled` into a boolean.
     */
    private get isSearchEnabled(): boolean {
        if (this.props.searchEnabled) {
            return this.props.searchEnabled > 0;
        }
        return false;
    }

    /**
     * Hides the search bar with animation and then mark it as disabled.
     */
    private onCloseSearchButtonClicked = () => {
        if (this.searchBarRef.current) {
            this.searchBarRef.current!.className = 'searchContainerFadeOut searchBar';

            this.searchBarRef.current!.onanimationend = () => {
                this.searchBarRef.current!.onanimationend = () => {};
                this.props.tabInContext?.enableSearch(false);
                this.searchButtonRef.current?.focus();
            };
        }
    };

    /**
     * Returns a search bar.
     * Also sets CSS animation, so when mounted the search bar will fade in.
     */
    private getSearchBar(searchBarClass: string) {
        const searchContainerClassName = this.isSearchEnabled ? 'searchContainerVisible' : 'searchContainerHidden';
        return (
            <div ref={this.searchBarRef} className={searchContainerClassName + ' searchBar ' + searchBarClass}>
                <div ref={this.searchPlaceholderRef} className="searchPlaceholder" />
            </div>
        );
    }

    /**
     * Unfortunately, browsers won't allow code to populate the clipboard _unless_ it is in the onClick event of a
     * button. Not only that, any asynchronous code that was triggered by this event is no longer considered part of
     * the event, hence not allowed to populate the clipboard.
     *
     * Since support for svg in email is lackluster, we have to convert the chart svg to a canvas. Luckily we have
     * a great library for that. Only problem is that process is asynchronous.
     *
     * Thus we have to prepare the chart image in advance (we do it after it renders), and have the element ready
     * for synchronous copying to the onClick event.
     */

    private debounceCaptureResultImageIfNeeded = debounce(this.captureResultImageIfNeeded, 2000);

    private async captureResultImageIfNeeded() {
        const tab = this.props.tabInContext;
        try {
            captureResultImageIfNeeded(tab);
        } catch (exception) {
            this.props.telemetry.exception('afterRender', { exception });
        }
    }
}

const QueryResults = styled(QueryResultsBase, getStyles);

export const QueryResultsPane = observer(function QueryResultsPane() {
    const core = useQueryCore();
    const { telemetry } = useTelemetry();
    const tabInContext = core.store.tabs.tabInContext;

    return (
        <QueryResults
            strings={core.strings.query}
            telemetry={telemetry}
            tableResults={tabInContext.results?.results}
            tabInContext={tabInContext}
            layout={core.store.layout}
            searchEnabled={tabInContext.searchEnabled ?? 0}
            hideEmptyColumns={tabInContext.hideEmptyColumns}
            isFetching={tabInContext.isFetching}
            settings={core.store.settings}
            settingsTheme={core.store.settings.theme}
            fetchStartTime={tabInContext.fetchStartTime}
        />
    );
});
