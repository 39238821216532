.expandBarItems {
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
}

.overflowSet {
    display: flex;
    align-items: center;
}

.overflowContainer {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    min-width: fit-content;
    overflow: hidden;
}
