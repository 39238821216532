import React, { useCallback } from 'react';
import { TooltipHost } from '@fluentui/react';
import { Button, Input, InputOnChangeData, InputProps } from '@fluentui/react-components';
import { Add20Regular } from '@fluentui/react-icons';
import classNames from 'classnames';
// lodash import is restricted to avoid importing the entire library, so
// type-only imports are safe
// eslint-disable-next-line no-restricted-imports
import type { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';

import { RegisterFlush, useRegisterDebounce } from '@kusto/utils';

import { VISUAL_OPTIONS__TEXT_DEBOUNCE } from '../../constants';
import type { KweVisualFwkLocale } from '../../types';
import type { HorizontalLine, YAxisConfig } from '../../visualOptions';
import { ConfigurationItemRow } from '../configurationList/ConfigurationItemRow';

import * as gridStyles from '../configurationList/grid.module.scss';
import * as configurationListStyles from '../configurationList/styles.module.scss';
import * as styles from './styles.module.scss';

export type ApplyHorizontalLineChange = (items: readonly HorizontalLine[]) => readonly HorizontalLine[] | undefined;

export interface HorizontalLineConfiguration {
    id: string;
    value: number | null;
}

export interface HorizontalLineListItemComponentProps {
    t: KweVisualFwkLocale;
    item: HorizontalLineConfiguration;
    onChange: (id: string, newValue?: string) => void;
    registerFlush: RegisterFlush;
}

/**
 * Input field for horizontal line in the base y axis configuration
 */
export const BaseYAxisHorizontalLineField: React.FC<HorizontalLineListItemComponentProps> = ({
    t,
    item,
    onChange,
    registerFlush,
}) => {
    // Local values shadow global values, and are cleared when debounce resolves
    const [localValue, setLocalValue] = React.useState<undefined | string>(undefined);
    const { id, value } = item;
    const onInputChange = React.useMemo(() => {
        const setGlobalValue = debounce((newValue: string) => onChange(id, newValue), VISUAL_OPTIONS__TEXT_DEBOUNCE);

        const innerOnChange = ((_: unknown, data: InputOnChangeData) => {
            setLocalValue(data.value);
            setGlobalValue(data.value);
        }) as DebouncedFunc<(_: unknown, data: InputOnChangeData) => void>;
        innerOnChange.flush = setGlobalValue.flush;
        innerOnChange.cancel = setGlobalValue.cancel;
        return innerOnChange;
    }, [onChange, id]);

    useRegisterDebounce(registerFlush, onInputChange);

    return <HorizontalLineInput t={t} value={localValue ?? value?.toString() ?? ''} onChange={onInputChange} />;
};

const MAX_HORIZONTAL_LINES = 2;

export interface HorizontalLineListProps {
    t: KweVisualFwkLocale;
    yAxisConfig: YAxisConfig;
    onChangeHorizontalLine: (applyChange: ApplyHorizontalLineChange) => void;
    ItemComponent: React.ComponentType<HorizontalLineListItemComponentProps>;
    onHorizontalLineInputChange: HorizontalLineListItemComponentProps['onChange'];
    registerFlush: RegisterFlush;
}

export const HorizontalLineList: React.FC<HorizontalLineListProps> = ({
    t,
    yAxisConfig,
    onChangeHorizontalLine,
    ItemComponent,
    onHorizontalLineInputChange,
    registerFlush,
}) => {
    const onAddHorizontalLine = useCallback(() => {
        const addHorizontalLine: ApplyHorizontalLineChange = (lines) => {
            if (lines.length >= MAX_HORIZONTAL_LINES) {
                return lines;
            }
            const newHorizontalLine = { id: crypto.randomUUID(), value: null };

            return [...lines, newHorizontalLine];
        };
        onChangeHorizontalLine(addHorizontalLine);
    }, [onChangeHorizontalLine]);

    const onDeleteHorizontalLine = useCallback(
        (item: HorizontalLine) => {
            const deleteHorizontalLine: ApplyHorizontalLineChange = (lines) => {
                const index = lines.findIndex((line) => line.id === item.id);
                return [...lines.slice(0, index), ...lines.slice(index + 1)];
            };
            onChangeHorizontalLine(deleteHorizontalLine);
        },
        [onChangeHorizontalLine]
    );

    const mainAxisHorizontalLines = yAxisConfig.horizontalLines;

    const addButtonTooltip =
        mainAxisHorizontalLines.length >= MAX_HORIZONTAL_LINES
            ? t.visualFwk.visualConfig.horizontalLineAddButtonDisabledTooltip
            : undefined;

    return (
        <>
            <div className={styles.horizontalLineLabel}>{t.visualFwk.visualConfig.horizontalLineListLabel}</div>
            <div className={configurationListStyles.configurationList}>
                {mainAxisHorizontalLines.map((i) => (
                    <ConfigurationItemRow<HorizontalLine>
                        key={i.id}
                        onDelete={onDeleteHorizontalLine}
                        item={i}
                        hideEditButton={true}
                        editButtonTitle={t.utils.util.buttons.edit}
                        deleteButtonTitle={t.utils.util.buttons.delete}
                    >
                        <ItemComponent
                            t={t}
                            registerFlush={registerFlush}
                            item={i}
                            onChange={onHorizontalLineInputChange}
                        />
                    </ConfigurationItemRow>
                ))}
                <div className={classNames(configurationListStyles.addConfigItemButton, styles.addReferenceLineButton)}>
                    <TooltipHost content={addButtonTooltip}>
                        <Button
                            appearance="transparent"
                            icon={<Add20Regular />}
                            onClick={onAddHorizontalLine}
                            disabled={mainAxisHorizontalLines.length >= MAX_HORIZONTAL_LINES}
                        >
                            {t.visualFwk.visualConfig.horizontalLineAddButtonText}
                        </Button>
                    </TooltipHost>
                </div>
            </div>
        </>
    );
};

export interface HorizontalLineInputProps {
    t: KweVisualFwkLocale;
    value: string;
    onChange: InputProps['onChange'];
}

export const HorizontalLineInput: React.FC<HorizontalLineInputProps> = ({ t, onChange, value }) => {
    return (
        <Input
            className={classNames(gridStyles.content, styles.axisHorizontalLine)}
            value={value ?? ''}
            onChange={onChange}
            type="number"
            placeholder={t.visualFwk.visualConfig.horizontalLineInputPlaceholder}
        />
    );
};
