import React from 'react';
import { ITooltipHostProps, TooltipHost } from '@fluentui/react/lib/Tooltip';

export type TooltipWrapperProps = ITooltipHostProps;

/**
 * @deprecated Fluent 8
 */
export const TooltipWrapper = ({ children, ...tooltipProps }: React.PropsWithChildren<TooltipWrapperProps>) => {
    return <TooltipHost {...tooltipProps}>{children}</TooltipHost>;
};
