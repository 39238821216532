import React from 'react';
import { AuthenticationResult, ClientAuthError } from '@azure/msal-browser';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import ReactDOM from 'react-dom';

import { getTelemetryClient } from '@kusto/query';
import { Account, castToError, formatLiterals } from '@kusto/utils';

import { MsalAuthenticationProvider } from '../../AuthenticationProviders/MsalAuthProvider/MsalAuthenticationProvider';
import { createAuthorityUrl, isHomeTenant } from '../../AuthenticationProviders/MsalAuthProvider/MsalUtils';
import type { AppQueryParams } from '../../core';
import { dependencies } from '../../dependencies';
import { ConfirmLoginWithTenant } from './ConfirmLoginWithTenant';
import { deleteActiveTenant, getActiveTenant, setActiveTenant } from './currentTenantStorage';
import { LoginErrorDialog } from './LoginErrorDialog';
import { reloadWithHomeTenant, reloadWithTenant } from './tenantUtils';

function maskEmail(email?: string) {
    if (email) {
        const [textToMask, domain] = email.split('@');

        // Will result in: *******@microsoft.com
        return `${'*'.repeat(textToMask.length)}@${domain}`;
    }

    // keeping with previous behavior we would
    // stringify undefined type
    return `undefined`;
}

const { trackTrace, trackException } = getTelemetryClient({
    component: 'LoginManager',
    flow: '',
});

/**
 * Handles the login logic
 */
export class LoginManager {
    constructor(private msalAuthProvider: MsalAuthenticationProvider) {
        this.init();
    }

    private init(): void {
        if (!this.msalAuthProvider) return;
        this.msalAuthProvider.registerEvents(this.onLogin, this.onLoggingOut);
    }

    /**
     * Return a valid logged-in account with tenant <tenantId> and user <username>.
     */
    private async findAccountWithValidToken(tenantId: string, username?: string): Promise<Account | undefined> {
        const newAccount = this.msalAuthProvider.getAccountByUsernameAndTenant(tenantId, username);
        return this.getAccountIfValid(newAccount);
    }

    /**
     * Return a valid logged-in account with home tenant and user <username>.
     */
    private async findAccountWithValidTokenInHomeTenant(username?: string): Promise<Account | undefined> {
        const newAccount = this.msalAuthProvider.getHomeAccountByUsername(username);
        return this.getAccountIfValid(newAccount);
    }

    /**
     * Tries to get a token silently for the given account. If a valid token exist return that account,
     * otherwise return undefined.
     */
    private getAccountIfValid = async (account?: Account): Promise<Account | undefined> => {
        if (account) {
            const tokenResponse = await this.msalAuthProvider.getTokenSilently(account);
            if (tokenResponse.kind === 'ok') {
                return account;
            }
        }
        return undefined;
    };

    /**
     * The login flow split into 3 parts:
     * 1. tenant passed in the query
     *    search for account with:
     *        tenant from query
     *        loginHint from query or from active account
     *    if an account with a valid token found - persist the tenant and make the account the active account.
     *    else call msal's login with the given tenant and loginHint. Once login finishes, persist the tenant (done in onLogin method)
     *
     * 2. tenant is persisted (not in the query)
     *    search for account with:
     *        persisted tenant
     *        loginHint from query or from active account
     *    if an account with a valid token found - make the account the active account.
     *    else let the user know that the token has expired and ask the user which tenant to use - the home tenant or the persisted one.
     *
     * 3. tenant is not persisted and not in the query
     *    search for account with:
     *        home tenant
     *        loginHint from query or from active account
     *    if an account with a valid token found - make the account the active account.
     *    else call msal's login with loginHint (which default to home tenant with https://.../organizations authority)
     */
    public async login(appSearch: AppQueryParams): Promise<boolean> {
        const tenantFromQuery = appSearch.settings.tenant;
        const loginHint = appSearch.settings.login_hint;
        const persistedTenant = getActiveTenant();

        await this.msalAuthProvider.handleRedirectPromise();

        if (tenantFromQuery) {
            trackTrace(`login: calling loginWithTenantFromQuery`, SeverityLevel.Information);
            await this.loginWithTenantFromQuery(tenantFromQuery, loginHint);
            return true;
        } else if (persistedTenant) {
            trackTrace(`login: calling loginWithPersistedTenant`, SeverityLevel.Information);
            await this.loginWithPersistedTenant(persistedTenant, loginHint);
            return true;
        } else {
            return await this.basicLoginFlow(loginHint);
        }
    }

    private async loginWithTenantFromQuery(tenantFromQuery: string, loginHint?: string): Promise<void> {
        const account = await this.findAccountWithValidToken(tenantFromQuery, loginHint);
        if (account) {
            trackTrace(`loginWithTenantFromQuery: found valid account from tenant in query.`, SeverityLevel.Verbose, {
                tenantFromQuery,
                loginHint: maskEmail(loginHint),
                disablePiiRedactor: false,
            });

            if (isHomeTenant(account)) {
                // home tenant is the default, no need to persist any tenant.
                trackTrace('loginWithTenantFromQuery: delete active tenant', SeverityLevel.Information);
                deleteActiveTenant();
            } else {
                trackTrace('loginWithTenantFromQuery: set active tenant', SeverityLevel.Information);
                setActiveTenant(tenantFromQuery);
            }

            this.msalAuthProvider.setActiveAccount(account);
            return;
        } else {
            trackTrace(`loginWithTenantFromQuery: no valid account. Logging-in.`, SeverityLevel.Verbose, {
                tenantFromQuery,
                loginHint: maskEmail(loginHint),
                disablePiiRedactor: false,
            });

            try {
                setActiveTenant(tenantFromQuery);
                await this.msalAuthProvider.login(loginHint, tenantFromQuery);
            } catch (ex: unknown) {
                if (ex instanceof ClientAuthError) {
                    trackTrace('loginWithTenantFromQuery: authority not valid.', SeverityLevel.Information);
                    await new Promise<void>((resolve) => {
                        ReactDOM.render(
                            <LoginErrorDialog
                                message={formatLiterals(dependencies.strings.kwe$loginErrorDialog$message, {
                                    tenantFromQuery,
                                })}
                                title={dependencies.strings.kwe$loginErrorDialog$title}
                                onClicked={() => {
                                    reloadWithHomeTenant(loginHint);
                                    resolve();
                                }}
                            />,
                            document.getElementById('root')
                        );
                    });
                }

                const typedError = castToError(ex);
                trackException(typedError, 'getTokenSilently: query: error');
                throw typedError;
            }
        }
    }

    private async loginWithPersistedTenant(persistedTenant: string, loginHint?: string): Promise<void> {
        const account = await this.findAccountWithValidToken(persistedTenant, loginHint);
        if (account) {
            trackTrace(
                `loginWithPersistedTenant: activeTenant: found valid account using persisted tenant.`,
                SeverityLevel.Verbose,
                {
                    activeTenant: persistedTenant,
                    loginHint: maskEmail(loginHint),
                    disablePiiRedactor: false,
                }
            );
            this.msalAuthProvider.setActiveAccount(account);
            if (isHomeTenant(account)) {
                // home tenant is the default, no need to persist any tenant.
                trackTrace('loginWithPersistedTenant: delete active tenant', SeverityLevel.Information);
                deleteActiveTenant();
            }
            return;
        } else {
            trackTrace('loginWithPersistedTenant: no cached token.', SeverityLevel.Information);

            // Promise will always redirect.
            await new Promise<void>((resolve) => {
                ReactDOM.render(
                    <ConfirmLoginWithTenant
                        tenantId={persistedTenant}
                        onClicked={(loginIntoTenant: boolean) => {
                            if (loginIntoTenant) {
                                trackTrace('loginWithPersistedTenant: re-login to tenant.', SeverityLevel.Information);
                                reloadWithTenant(persistedTenant, loginHint);
                            } else {
                                trackTrace(
                                    'loginWithPersistedTenant: reset to home tenant.',
                                    SeverityLevel.Information
                                );
                                reloadWithHomeTenant(loginHint);
                            }
                            resolve();
                        }}
                    />,
                    document.getElementById('root')
                );
            });

            throw new Error('loginWithPersistedTenant: ConfirmLoginWithTenant did not redirect');
        }
    }

    private async basicLoginFlow(loginHint?: string): Promise<boolean> {
        trackTrace(`basicLoginFlow: regular login flow.`, SeverityLevel.Verbose, {
            loginHint: maskEmail(loginHint),
            disablePiiRedactor: false,
        });
        const account = await this.findAccountWithValidTokenInHomeTenant(loginHint);
        if (account) {
            trackTrace(`basicLoginFlow: regular login flow: account with valid token found`, SeverityLevel.Verbose, {
                loginHint: maskEmail(loginHint),
                disablePiiRedactor: false,
            });
            await this.msalAuthProvider.setActiveAccount(account);
        } else {
            trackTrace(`basicLoginFlow: regular login flow: no cached tokens. Logging-in.`, SeverityLevel.Verbose, {
                loginHint: maskEmail(loginHint),
                disablePiiRedactor: false,
            });
            try {
                return await this.msalAuthProvider.login(loginHint);
            } catch (ex: unknown) {
                const typedError = castToError(ex);
                trackException(typedError, 'getTokenSilently: regular login flow: error');
                throw typedError;
            }
        }
        return true;
    }

    private async onLogin(loginResults: AuthenticationResult): Promise<void> {
        if (loginResults.account) {
            const homeTenantAuthority = createAuthorityUrl();
            const authorityWithoutTrailingSlash = loginResults.authority.replace(/\/$/, '');
            if (authorityWithoutTrailingSlash === homeTenantAuthority) {
                trackTrace(`onLogin: delete active tenant`, SeverityLevel.Verbose);
                deleteActiveTenant();
            } else {
                trackTrace(`onLogin: set active tenant`, SeverityLevel.Information);
                setActiveTenant(loginResults.account.tenantId);
            }
        }
    }

    private onLoggingOut(): void {
        deleteActiveTenant();
    }
}
