import React, { useCallback, useRef } from 'react';
import { Body1, Body1Strong, Input } from '@fluentui/react-components';
import { bundleIcon, CheckmarkFilled, CheckmarkRegular, DismissFilled, DismissRegular } from '@fluentui/react-icons';

import { TabItem } from '../Tabs.interfaces';
import { useTabsBarContext } from '../TabsContext/TabsBar.context';
import { ButtonTooltip, TabTextTooltip } from '../TabsTooltip/TabsTooltip';

import styles from './TabText.module.scss';

const Checkmark = bundleIcon(CheckmarkFilled, CheckmarkRegular);
const Dismiss = bundleIcon(DismissFilled, DismissRegular);

export const TabText: React.FunctionComponent<TabItem> = (tabItem) => {
    const { tabId, label } = tabItem;
    const context = useTabsBarContext();

    const inputRef = useRef<HTMLInputElement>(null);

    const onEditOk = useCallback(
        (event) => {
            const newLabel = inputRef.current?.value;
            if (newLabel) {
                context.events.onTabRename(tabId, newLabel);
            }

            context.editingTab.setTabId(undefined);
            event.stopPropagation();
        },
        [tabId, context]
    );

    const onEditCancel = useCallback(
        (event) => {
            context.editingTab.setTabId(undefined);
            event.stopPropagation();
        },
        [context]
    );

    const onEditKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key === 'Enter') {
                onEditOk(event);
            }
            if (event.key === 'Escape') {
                onEditCancel(event);
            }
        },
        [onEditOk, onEditCancel]
    );

    // Editing
    if (tabId === context.editingTab.tabId) {
        return (
            <Input
                ref={inputRef}
                className={styles.tabInput}
                defaultValue={label}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                appearance="underline"
                contentAfter={
                    context.flags?.hideSaveDiscardButtons ? null : (
                        <>
                            <ButtonTooltip
                                tooltip={context.strings.rename}
                                icon={<Checkmark />}
                                onClick={onEditOk}
                                appearance="transparent"
                                size="small"
                            />

                            <ButtonTooltip
                                tooltip={context.strings.discard}
                                icon={<Dismiss />}
                                onClick={onEditCancel}
                                appearance="transparent"
                                size="small"
                            />
                        </>
                    )
                }
                onKeyDown={(event) => onEditKeyDown(event)}
                onBlur={onEditOk} // save on blur
            />
        );
    }

    // Selected
    if (tabId === context.selectedTabId) {
        return (
            <TabTextTooltip {...tabItem}>
                <Body1Strong className={styles.tabText}>{label}</Body1Strong>
            </TabTextTooltip>
        );
    }

    // Default
    return (
        <TabTextTooltip {...tabItem}>
            <Body1 className={styles.tabText}>{label}</Body1>
        </TabTextTooltip>
    );
};
