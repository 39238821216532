import {
    AggregationPillData,
    BinaryFilterPillData,
    DateTimePillData,
    FilterPillData,
    LimitPillData,
    Pill,
    STRING_COLUMN_TYPES,
    UNARY_OPERATORS,
    UnaryOperator,
} from '../types/Pills.interfaces';
import { kqlEscapeSourceName } from './kqlUtils';

// FilterPill
export function filterPillToKql(pillData: FilterPillData): string {
    const { column, operator } = pillData;
    if (UNARY_OPERATORS.includes(operator as UnaryOperator)) {
        // where isnotnull(Product)
        return `where ${operator}(${kqlEscapeSourceName(column.ColumnName)})`;
    }
    const value = (pillData as BinaryFilterPillData).value;
    const valueStatement = STRING_COLUMN_TYPES.includes(column.ColumnType) ? `'${value}'` : value;

    // where Product contains 'item1'
    return `where ${kqlEscapeSourceName(column.ColumnName)} ${operator} ${valueStatement}`;
}

// AggregationPill
export function aggregationPillToKql({ functions, groups }: AggregationPillData): string {
    // "Avg=avg(Price), Count=count()"
    const functionsStatement = functions
        .map(
            (func) =>
                `${func.displayName ? `${func.displayName}=` : ''}${func.operator}(${func.column?.ColumnName ?? ''})`
        )
        .join(', ');

    // "by Product=prod, bin(startTime, 1d)"
    const groupBy = groups
        .map((group) => {
            const displayName = group.displayName ? `${group.displayName}=` : '';

            const columnName = kqlEscapeSourceName(group.column.ColumnName);
            const aggregation = group.timespan
                ? `bin(${columnName}, ${group.timespan.value}${group.timespan.unit})`
                : columnName;

            return `${displayName}${aggregation}`;
        })
        .join(', ');
    const groupByStatement = groupBy.length ? ` by ${groupBy}` : '';

    // "summarize Avg=avg(Price), Count=count() by Product=prod, Region(1d)"
    return `summarize ${functionsStatement}${groupByStatement}`;
}

// DateTimePill
export function dateTimePillToKql({ column, start, end }: DateTimePillData): string {
    // where Time between ('2021-01-01T00:00:00.000Z' .. '2021-01-02T00:00:00.000Z')
    return `where ${kqlEscapeSourceName(column.ColumnName)} between ('${start.toISOString()} .. ${end.toISOString()}')`;
}

// LimitPill
export function limitPillToKql({ max }: LimitPillData): string {
    // take 100
    return `take ${max}`;
}

export function pillToKql(pill: Pill): string {
    switch (pill.type) {
        case 'filter':
            return filterPillToKql(pill.data);
        case 'aggregation':
            return aggregationPillToKql(pill.data);
        case 'datetime':
            return dateTimePillToKql(pill.data);
        case 'limit':
            return limitPillToKql(pill.data);
    }
}
