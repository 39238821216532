import { visualFwkIconNames } from '../icons';
import type { ColorRule, LegendLocation } from './property';

/**
 * ID for the base Y axis when multiple y axes is configured
 */
export const BASE_Y_AXIS_ID = '-1';

/**
 * Internal identifiers. Our host app should map these to actual color values,
 * and we should have copy for each of them.
 */
export const colorRulesColors = ['red', 'yellow', 'green', 'blue'] as const;

/**
 * These are both internal identifiers, and user facing.
 * 'between' operator should be inclusive
 */
export const colorRulesOperators = ['>', '>=', '<', '<=', '==', '!=', 'between'] as const;

/**
 * Themes that are available when coloring numeric columns
 */
export const colorRulesThemes = ['traffic-lights', 'cold', 'warm', 'blue', 'red', 'yellow'] as const;

/**
 * Identifiers are different from icon keys so icons can be changed without
 * breaking dashboards
 */
export const colorRulesIconToIcon = {
    critical: 'Error',
    warning: 'Warning',
    completed: 'Completed',
    tentative: visualFwkIconNames.TentativeBooking,
    happy: visualFwkIconNames.Happy,
    sad: 'Sad',
    emojiNeutral: 'EmojiNeutral',
    circle: 'CircleRing',
    circleFill: 'CircleFill',
    contrast: 'Contrast',
    square: visualFwkIconNames.SquareShape,
    squareSolid: 'SquareShapeSolid',
    diamond: visualFwkIconNames.Diamond2Solid,
    octagon: 'Octagon',
    cloud: 'Cloud',
    tvMonitor: 'TvMonitor',
    globe: 'Globe',
    strength: visualFwkIconNames.Strength,
    eatDrink: 'EatDrink',
    cocktail: 'Cocktails',
    apple: visualFwkIconNames.DietTrackerApple,
    repair: 'Repair',
    circleDollar: visualFwkIconNames.CircleDollar,
    rate: visualFwkIconNames.Rate,
    arrowUp: visualFwkIconNames.ArrowUp8,
    arrowDown: visualFwkIconNames.ArrowDown8,
    ferry: 'Ferry',
    car: 'Car',
    plane: 'Airplane',
    baseball: 'Baseball',
    soccer: 'Soccer',
    basketball: visualFwkIconNames.BasketBall,
    football: 'CollegeFootball',
    tennis: 'Tennis',
    golf: 'Golf',
    world: 'World',
    heart: 'Heart',
    insights: visualFwkIconNames.Insights,
} as const;

export const colorRulesIcons = Object.keys(colorRulesIconToIcon) as ColorRule.Icon[];

export const DEFAULT_LEGEND_LOCATION: LegendLocation = 'bottom';
