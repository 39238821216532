import React from 'react';
import { Text } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';

import { formatLiterals, KweException } from '@kusto/utils';

import type { QueryMessageLevel } from '../../error';
import type { InteractionTarget } from '../../interactions/target';
import type { KweVisualFwkLocale } from '../../types';
import { CrossFilterConfig } from '../../visualOptions';
import { ConfigurationItemRow, ConfigurationItemRowContent } from '../configurationList/ConfigurationItemRow';
import { ConfigurationItemRowErrors } from '../configurationList/ConfigurationItemRowErrors';
import { InteractionsIndex } from './CrossFilter';
import { crossFilterTypeWarning, formatUnavailable, useResolveSelections } from './lib';
import { CrossFilterVisual } from './VisualInteraction';

export function rowId(configId: number) {
    return `visualOptions--crossFilterRow--${configId}`;
}

function formatNotSelected(strings: KweVisualFwkLocale, category: string) {
    return formatLiterals(strings.visualFwk.visualConfig.interactions.crossFilter.notSelected, {
        category,
    });
}

function createParameterLabel(
    strings: KweVisualFwkLocale,
    parameterId: undefined | string,
    parametersRecord: undefined | ReadonlyMap<string, InteractionTarget>
) {
    if (parametersRecord === undefined) {
        throw new KweException('Can only be used while a dashboard is loaded');
    }

    if (parameterId === undefined) {
        return formatNotSelected(strings, strings.visualFwk.parameter.displayName);
    } else {
        const parameter = parametersRecord?.get(parameterId);
        if (parameter === undefined) {
            return formatUnavailable(strings, strings.visualFwk.parameter.displayName);
        } else {
            return parameter.displayName;
        }
    }
}

function formatPropertyLabel(
    strings: KweVisualFwkLocale,
    propertyId: undefined | string,
    property: undefined | CrossFilterVisual.Property,
    displayName: undefined | string = strings.visualFwk.visualConfig.interactions.crossFilter.property
        .defaultDisplayName
) {
    if (propertyId === undefined) {
        return formatNotSelected(strings, displayName);
    }

    if (property === undefined) {
        return formatUnavailable(strings, displayName);
    }
    return property.displayName ?? property.id;
}

export interface CrossFilterRowProps {
    t: KweVisualFwkLocale;
    id: number;
    config: CrossFilterConfig;
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
    interactionsIndex: InteractionsIndex;
    parametersRecord: undefined | ReadonlyMap<string, InteractionTarget>;
}

export const CrossFilterRow: React.FC<CrossFilterRowProps> = observer(function CrossFilterRow({
    t,
    id,
    onEdit,
    onDelete,
    config,
    interactionsIndex,
    parametersRecord,
}) {
    const selections = useResolveSelections(config, interactionsIndex, parametersRecord);
    const { interaction, property } = selections;

    const parameterLabel = createParameterLabel(t, config.parameterId, parametersRecord);

    let primaryLabel: string;

    let secondaryLabel: undefined | string;

    const errors: string[] = [];
    let maxErrorLevel: QueryMessageLevel = 'warn';

    const titleText: string[] = [];

    if (config.interaction === undefined) {
        const text = formatNotSelected(
            t,
            t.visualFwk.visualConfig.interactions.crossFilter.interaction.defaultDisplayName
        );
        maxErrorLevel = 'error';
        primaryLabel = text;
        secondaryLabel = parameterLabel;

        titleText.push(text);
        errors.push(text);
    } else {
        if (interaction === undefined) {
            const interactionLabel = formatUnavailable(
                t,
                t.visualFwk.visualConfig.interactions.crossFilter.interaction.defaultDisplayName
            );
            maxErrorLevel = 'error';

            primaryLabel = interactionLabel;
            secondaryLabel = parameterLabel;

            titleText.push(interactionLabel, parameterLabel);
            errors.push(interactionLabel);
        } else {
            if (interaction.kind === 'unavailable') {
                maxErrorLevel = 'error';
                primaryLabel = interaction.unavailable;
                secondaryLabel = parameterLabel;

                titleText.push(primaryLabel, parameterLabel);
                errors.push(interaction.unavailable);
            } else {
                const propertyLabel = formatPropertyLabel(
                    t,
                    config.property,
                    property,
                    interaction.propertiesDisplayName
                );

                primaryLabel = `${propertyLabel}:
                        ${parameterLabel}`;
                titleText.push(propertyLabel, parameterLabel);
                // If only one iteration is available, don't bother showing this
                if (interactionsIndex.size !== 1) {
                    const interactionLabel = interaction.displayName ?? interaction.id;
                    secondaryLabel = interactionLabel;
                    titleText.push(interactionLabel);
                }
            }
        }
    }

    const typeWarning = crossFilterTypeWarning(t, selections);

    if (typeWarning) {
        errors.push(typeWarning.text());
        if (typeWarning.level === 'error') {
            maxErrorLevel = 'error';
        }
    }

    if (config.disabled) {
        titleText.push(`(${t.utils.util.status.disabled})`);
    }

    return (
        <ConfigurationItemRow<number>
            id={rowId(id)}
            item={id}
            onDelete={onDelete}
            onEdit={onEdit}
            editButtonTitle={t.utils.util.buttons.edit}
            deleteButtonTitle={t.utils.util.buttons.delete}
        >
            <ConfigurationItemRowContent title={titleText.join('\n')} disabled={config.disabled}>
                <Text>{primaryLabel}</Text>
                <Text size={200}>{secondaryLabel}</Text>
                {errors.length !== 0 && <ConfigurationItemRowErrors warnings={errors} level={maxErrorLevel} />}
            </ConfigurationItemRowContent>
        </ConfigurationItemRow>
    );
});
