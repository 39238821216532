import React from 'react';
import { observer } from 'mobx-react-lite';

import { type DataTableColumn } from '@kusto/client';
import { ErrorBoundary } from '@kusto/ui-components';

import { DateTimeColumn, HistogramYColumn } from '../DataExplorationApi';
import { useStrings } from '../DataExplorationModal/hooks/useStrings';
import { HistogramContent, HistogramContentProps } from './HistogramContent';
import { HistogramErrView } from './HistogramErrorView';

import * as styles from './styles.module.scss';

export interface DataProfileHistogramProps
    extends Omit<HistogramContentProps, 'selectedColumn' | 'defaultSelectedColumn' | 'datetimeColumns'> {
    columns: DataTableColumn[];
}

// If we spot these column names anywhere in the columns we will
// choose these instead since there's a higher chance the queried
// data by these columns makes more sense then a random datetime
// column we infer
const DEFAULT_INFER_COL_NAMES = new Set<string>(['starttime', 'timestamp']);

function inferDefaultSelectedColName(
    datetimeColumns: DateTimeColumn[],
    isIngestionTimePolicyEnabled?: boolean
): HistogramYColumn {
    if (isIngestionTimePolicyEnabled) {
        return { ColumnType: 'ingestionTime' };
    }

    const defaultInferCol = datetimeColumns.find((col) => DEFAULT_INFER_COL_NAMES.has(col.ColumnName.toLowerCase()));

    if (defaultInferCol) {
        return defaultInferCol;
    }

    return datetimeColumns[0];
}

export const DataProfileHistogram: React.FC<DataProfileHistogramProps> = observer(function DataProfileHistogram({
    columns,
    isIngestionTimePolicyEnabled,
    ...props
}) {
    const { strings } = useStrings();

    const datetimeColumns = React.useMemo(
        () => columns.filter((col) => col.ColumnType === 'datetime') as DateTimeColumn[],
        [columns]
    );

    const defaultSelectedColumn = inferDefaultSelectedColName(datetimeColumns, isIngestionTimePolicyEnabled);

    if (isIngestionTimePolicyEnabled === undefined || !datetimeColumns) {
        return <div className={styles.histogramWrapper}></div>;
    }

    if (datetimeColumns.length === 0 && !isIngestionTimePolicyEnabled) {
        return (
            <div className={styles.histogramWrapper}>
                <div className={styles.errView}>
                    <HistogramErrView msg={strings.histogram.nothingToDisplay} level="error" />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.histogramWrapper}>
            <ErrorBoundary
                fallback={
                    <div className={styles.errView}>
                        <HistogramErrView msg={strings.histogram.somethingWentWrong} level="error" />
                    </div>
                }
            >
                <HistogramContent
                    defaultSelectedColumn={defaultSelectedColumn}
                    datetimeColumns={datetimeColumns}
                    isIngestionTimePolicyEnabled={isIngestionTimePolicyEnabled}
                    {...props}
                />
            </ErrorBoundary>
        </div>
    );
});
