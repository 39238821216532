import { caseInsensitiveComparer } from '@kusto/utils';

import { EntityType } from '../../../common';
import { sortedDatabaseFolders } from '../../../common/entityTypeUtils';
import { Database, isSuspendedDatabase, QueryStoreEnv } from '../../../stores';
import { ITreeEntityMapper } from '../actions';
import { RowDataType } from '../RowDataType';
import { getBaseRootPath, isTridentView } from '../utils';
import { iconMapper } from './IconMapper';
import { RowDataTypeBase } from './RowDataTypeBase';
import { getFromCacheOrCreate } from './RowDataTypeCache';

/**
 * A Database's descendants should be sorted in a case insensitive and alphabetical order but the root folders
 * ("Functions", "Materialized Views", "Tables", "External Tables") should be at the top.
 * When sorting, use this comparer to achieve this order.
 */
export const databaseDescendantsComparer = (a: RowDataType, b: RowDataType): number => {
    if (a == null && b == null) return 0;
    if (b == null) return 1;
    if (a == null) return -1;

    // root folders should be at the top
    for (const rootFolderType of sortedDatabaseFolders) {
        if (a.entityType === rootFolderType) return -1;
        if (b.entityType === rootFolderType) return 1;
    }

    // Folders should always come before entities.
    if (a.entityType === EntityType.Folder && b.entityType !== EntityType.Folder) return -1;
    if (a.entityType !== EntityType.Folder && b.entityType === EntityType.Folder) return 1;

    // Entities and regular folders (=none root ones) should have case insensitive sorting.
    const comparerIndex = Math.min(a.pathFromRoot.length - 1, b.pathFromRoot.length - 1);
    return caseInsensitiveComparer(a.pathFromRoot[comparerIndex], b.pathFromRoot[comparerIndex]);
};

export class DatabaseRowDataType extends RowDataTypeBase {
    private isLoading: boolean;

    private constructor(
        env: QueryStoreEnv,
        database: Database,
        baseRootPath: string[],
        treeEntityMapper: ITreeEntityMapper,
        isLoading?: boolean,
        details?: string
    ) {
        const icon = isSuspendedDatabase(database.suspensionState)
            ? iconMapper.SuspendedDatabase
            : iconMapper[database.accessMode || 'none'] || iconMapper.Database;

        super(
            database.id,
            baseRootPath,
            database.prettyName ?? database.name,
            EntityType.Database,
            database,
            icon,
            treeEntityMapper
        );
        this.isLoading = isLoading ?? DatabaseRowDataType.isLoading(database);
        this.details = details ?? DatabaseRowDataType.details(env, database, this.isLoading);
        this.isFavorite = database.isFavorite;
        this.actions = treeEntityMapper.getActions(database.entityType, this.isFavorite, undefined);
    }

    setIsFavorite(isFavorite: boolean) {
        this.isFavorite = isFavorite;
        this.refreshActions();
    }

    private refreshActions() {
        const database = this.baseData as Database;
        this.actions = this.treeEntityMapper.getActions(database.entityType, this.isFavorite, undefined);
    }

    public static isLoading(database: Database) {
        return database.isFetching && !database.isBackgroundFetch;
    }

    public static details(env: QueryStoreEnv, database: Database, isLoading: boolean) {
        return isLoading
            ? // incase of loading - additional info will be "Loading"
              'Loading'
            : // other wise, if have pretty name, additional info is the db name (as pretty name the row name)
            database.prettyName && !isTridentView(env)
            ? database.name
            : undefined;
    }

    public static fromCacheOrCreate(
        env: QueryStoreEnv,
        database: Database,
        treeEntityMapper: ITreeEntityMapper,
        isLoading?: boolean,
        details?: string,
        shouldRefreshCache?: (old: RowDataType) => boolean
    ): RowDataType {
        return getFromCacheOrCreate(
            database,
            database.id,
            () =>
                new DatabaseRowDataType(
                    env,
                    database,
                    getBaseRootPath(env, database),
                    treeEntityMapper,
                    isLoading,
                    details
                ),
            shouldRefreshCache
        );
    }
}
