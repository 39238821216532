import { ColumnApi, ColumnState, GridApi, IClientSideRowModel, RowNode } from '@ag-grid-community/core';

export type GroupState =
    | boolean
    | {
          expanded: boolean;
          subGroupsState?: { [key: string]: GroupState };
      };

type ColumnSortModelColId = ColumnState['colId'];
type ColumnSortModelSort = ColumnState['sort'];
export type ColumnSortByColumnId = Record<NonNullable<ColumnSortModelColId>, ColumnSortModelSort>;

export interface GridState {
    columns?: ColumnState[];
    visibleCorners?: {
        topLeft: { columnId?: string; rowIndex: number };
        bottomRight: { columnId?: string; rowIndex: number };
    };
    focusCell?: { columnId: string; rowIndex: number };
    expandGroup?: GroupState;
    // Added while enabling lints
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filters?: any;
    columnSortByColumnId?: ColumnSortByColumnId;
    columnToHighlightRowsBy?: string;
}

// recursively loop into group and collect all expanded group
const recursiveGetGroupState = (props: RowNode): GroupState => {
    if (!props.childrenAfterGroup || props.childrenAfterGroup.length === 0 || !props.childrenAfterGroup[0].group) {
        return props.expanded;
    }

    const subGroupsState = props.childrenAfterGroup.reduce((state, child) => {
        const childState = recursiveGetGroupState(child);
        if (childState === false) {
            return state;
        }
        if (state === undefined) {
            state = {};
        }
        if (child.key !== null) {
            state[child.key] = childState;
        }
        return state;
    }, undefined as { [key: string]: GroupState } | undefined);
    if (subGroupsState === undefined) {
        return props.expanded;
    }
    return {
        expanded: props.expanded,
        subGroupsState,
    };
};

const recursiveRestoreGroupState = ({ childrenAfterGroup }: RowNode, state: GroupState) => {
    if (!childrenAfterGroup || childrenAfterGroup.length === 0 || typeof state !== 'object' || !state.subGroupsState) {
        return;
    }
    childrenAfterGroup.forEach((child) => {
        if (child.key === null) {
            return;
        }
        // Added while enabling lints

        const childState = state.subGroupsState![child.key];
        if (childState === undefined) {
            return;
        }
        if (typeof childState === 'boolean') {
            child.setExpanded(childState);
        } else {
            child.setExpanded(childState.expanded);
            recursiveRestoreGroupState(child, childState);
        }
    });
};
export const getExpandGroupsState = (gridApi?: GridApi): GroupState => {
    if (!gridApi) return false;
    const rootNode = (gridApi.getModel() as IClientSideRowModel).getRootNode();
    return recursiveGetGroupState(rootNode);
};

export const restoreExpandGroupsState = (gridApi?: GridApi, state?: GroupState) => {
    if (!gridApi || !state) {
        return;
    }
    const rootNode = (gridApi.getModel() as IClientSideRowModel).getRootNode();

    recursiveRestoreGroupState(rootNode, state);
};

export const getGridState = (gridApi: GridApi, columnApi: ColumnApi): GridState => {
    const focusCell = gridApi.getFocusedCell();
    const columnsInView = columnApi.getAllDisplayedVirtualColumns();
    const columns = columnApi.getAllDisplayedColumns();
    const [leftViewedColumn, rightViewedColumn] =
        columnsInView && columns && columnsInView.length > 2 && columns[2] === columnsInView[2]
            ? [columnsInView[0].getColId(), columnsInView[columnsInView.length - 3].getColId()]
            : columnsInView && columnsInView.length > 4
            ? [columnsInView[2].getColId(), columnsInView[columnsInView.length - 3].getColId()]
            : [undefined, undefined];
    const columnsState = columnApi.getColumnState() ?? [];
    const columnSortByColumnId = columnsState.reduce<ColumnSortByColumnId>((dict, currentItem) => {
        const colId: ColumnSortModelColId = currentItem.colId;
        const sort: ColumnSortModelSort = currentItem.sort;
        if (!colId) return dict;
        dict[colId] = sort;
        return dict;
    }, {});

    return {
        columns: columnApi.getColumnState(),
        focusCell: focusCell
            ? {
                  columnId: focusCell.column.getColId(),
                  rowIndex: focusCell.rowIndex,
              }
            : undefined,
        expandGroup: getExpandGroupsState(gridApi),
        visibleCorners: {
            topLeft: {
                rowIndex: gridApi.getFirstDisplayedRow(),
                columnId: leftViewedColumn,
            },
            bottomRight: {
                rowIndex: gridApi.getLastDisplayedRow(),
                columnId: rightViewedColumn,
            },
        },
        filters: gridApi.getFilterModel(),
        columnSortByColumnId,
    };
};

const verifyColumnsMatch = (gridColumnApi: ColumnApi, columns: ColumnState[]) => {
    // workaround old bug - if all column are 200 width and hidden ignore the cache
    // except expandable col which is 21
    if (columns.every((col) => (col.hide === true && col.width === 200) || col.width === 21)) {
        return false;
    }

    return columns.every((col) => !!gridColumnApi.getColumn(col.colId));
};

export const restoreGridState = (
    gridApi: GridApi,
    columnApi: ColumnApi,
    state: GridState,
    // Added while enabling lints
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gridData: any[],
    gridElement: HTMLElement | null
) => {
    const { columns, expandGroup = false, visibleCorners, focusCell, filters = {}, columnSortByColumnId } = state;

    if (!columns || !verifyColumnsMatch(columnApi, columns)) {
        return false;
    }

    columnApi.applyColumnState({ state: columns });
    gridApi.setRowData(gridData);
    gridApi.setFilterModel(filters);

    /**
     * We only need to apply the new column state
     * with the sort direction when we have
     * the cached column sort by column id
     */
    if (columnSortByColumnId) {
        // we need to manually update the
        // "sort" direction for each column
        const newColumnState = columns.map((colState) => {
            const { colId } = colState;
            const newSort = colId ? columnSortByColumnId[colId] : undefined;

            if (newSort) {
                return {
                    ...colState,
                    sort: newSort,
                };
            }

            return colState;
        });

        columnApi.applyColumnState({ state: newColumnState });
    }

    restoreExpandGroupsState(gridApi, expandGroup);
    if (gridElement) {
        gridElement.style.display = 'block';
    }
    if (focusCell) {
        gridApi.setFocusedCell(focusCell.rowIndex, focusCell.columnId);
    }
    if (visibleCorners) {
        gridApi.ensureIndexVisible(visibleCorners.bottomRight.rowIndex);
        if (visibleCorners.bottomRight.columnId) {
            gridApi.ensureColumnVisible(visibleCorners.bottomRight.columnId);
        }
        gridApi.ensureIndexVisible(visibleCorners.topLeft.rowIndex);
        if (visibleCorners.topLeft.columnId) {
            gridApi.ensureColumnVisible(visibleCorners.topLeft.columnId);
        }
    }
    return true;
};
