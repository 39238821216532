import { useCallback } from 'react';
import { useControllableState } from '@fluentui/react-utilities';
export const useSelection = (props)=>{
    const { defaultSelectedOptions, multiselect, onOptionSelect } = props;
    const [selectedOptions, setSelectedOptions] = useControllableState({
        state: props.selectedOptions,
        defaultState: defaultSelectedOptions,
        initialState: []
    });
    const selectOption = useCallback((event, option)=>{
        // if the option is disabled, do nothing
        if (option.disabled) {
            return;
        }
        // for single-select, always return the selected option
        let newSelection = [
            option.value
        ];
        // toggle selected state of the option for multiselect
        if (multiselect) {
            const selectedIndex = selectedOptions.findIndex((o)=>o === option.value);
            if (selectedIndex > -1) {
                // deselect option
                newSelection = [
                    ...selectedOptions.slice(0, selectedIndex),
                    ...selectedOptions.slice(selectedIndex + 1)
                ];
            } else {
                // select option
                newSelection = [
                    ...selectedOptions,
                    option.value
                ];
            }
        }
        setSelectedOptions(newSelection);
        onOptionSelect === null || onOptionSelect === void 0 ? void 0 : onOptionSelect(event, {
            optionValue: option.value,
            optionText: option.text,
            selectedOptions: newSelection
        });
    }, [
        onOptionSelect,
        multiselect,
        selectedOptions,
        setSelectedOptions
    ]);
    const clearSelection = (event)=>{
        setSelectedOptions([]);
        onOptionSelect === null || onOptionSelect === void 0 ? void 0 : onOptionSelect(event, {
            optionValue: undefined,
            optionText: undefined,
            selectedOptions: []
        });
    };
    return {
        clearSelection,
        selectOption,
        selectedOptions
    };
};
