import React from 'react';
import { Body1, Button, Caption2, Link } from '@fluentui/react-components';

import { formatLiterals } from '@kusto/utils';

import * as styles from './CrashErrorText.module.scss';

function generateExceptionEmailUrl(area: string, supportEmailAddress: string, cid: string): string {
    return `mailto:${supportEmailAddress}?subject=${area}%20error%20${cid}&body=Please%20describe%20your%20actions%20leading%20up%20to%20this%20error`;
}

/**
 * Should be manually kept in sync with string.json#common.components.crashText
 */
const defaultCrashText = {
    title: 'Something went wrong',
    subTextSuccess: 'This incident has been reported.',
    subTextFailure: 'Failed to report this incident.',
    emailSupportLink: 'Report',
    correlationIdTemplate: 'Correlation ID: ${correlationId}',
    subTextTryAgain:
        'In the meantime, click ‘Try again’. If that doesn’t work, send us an email so we can follow up with you.',
    buttonTryAgain: 'Try again',
    errorCode: 'Error code: ${correlationId}',
};

/**
 * h1-h6 element level
 */
export type HeaderLevel = 1 | 2 | 3 | 4 | 5 | 6; // TODO: understand if the header really is necessary, cause the new design doesn't take into account different sizes.

export interface CrashErrorTextProps {
    headerLevel: HeaderLevel;
    /**
     * @see {@link generateCid}
     *
     * If undefined, user is told the incident has not been reported
     */
    cid: undefined | string;
    supportEmail: string;
    /**
     * User-facing app page that crashed. (e.g. "Dashboards", "Query"). Less
     * specific than the cid prefix.
     */
    area: string;
    /**
     * Optional so that this component can be used during app init, before we
     * have localized copy. Copy in in `common.components.crashText`.
     */
    t?: undefined | typeof defaultCrashText;
    /**
     * If supplied, the 'Try Again' button will be shown and when clicked will trigger the callback.
     */
    reloadCallback?: () => void;
}

/**
 * Show this if an unexpected error (crash) occurs.
 */
export const CrashErrorText: React.FC<CrashErrorTextProps> = ({
    headerLevel,
    cid,
    supportEmail,
    area,
    t = defaultCrashText,
    reloadCallback,
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.svgDivContainer} />
            {React.createElement(`h${headerLevel}`, { className: styles.title }, t.title)}
            <Body1 className={styles.description}>
                {cid ? t.subTextSuccess : t.subTextFailure} {reloadCallback ? t.subTextTryAgain : ''}
            </Body1>
            {cid && <Caption2>{formatLiterals(t.errorCode, { correlationId: cid })}</Caption2>}
            {reloadCallback && (
                <Link
                    href="#"
                    onClick={(e) => {
                        e.preventDefault(); // Prevent the default anchor action
                        window.location.reload(); // Reload the page
                    }}
                >
                    {t.buttonTryAgain}
                </Link>
            )}
            <Button
                appearance="primary"
                style={{ marginTop: '16px' }}
                onClick={() =>
                    window.open(
                        generateExceptionEmailUrl(area, supportEmail, cid ?? '(No CID)'),
                        '_blank',
                        'noopener,noreferrer'
                    )
                }
            >
                {t.emailSupportLink}
            </Button>
        </div>
    );
};
