import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { getIntrinsicElementProps, useId, useEventCallback, slot, elementContains, useMergedRefs } from '@fluentui/react-utilities';
import { Space } from '@fluentui/keyboard-keys';
import { treeDataTypes } from '../../utils/tokens';
import { useTreeContext_unstable, useSubtreeContext_unstable, useTreeItemContext_unstable } from '../../contexts';
import { dataTreeItemValueAttrName } from '../../utils/getTreeItemValueFromElement';
/**
 * Create the state required to render TreeItem.
 *
 * The returned state can be modified with hooks such as useTreeItemStyles_unstable,
 * before being passed to renderTreeItem_unstable.
 *
 * @param props - props from this instance of TreeItem
 * @param ref - reference to root HTMLElement of TreeItem
 */ export function useTreeItem_unstable(props, ref) {
    const treeType = useTreeContext_unstable((ctx)=>ctx.treeType);
    if (treeType === 'flat') {
        warnIfNoProperPropsFlatTreeItem(props);
    }
    const requestTreeResponse = useTreeContext_unstable((ctx)=>ctx.requestTreeResponse);
    const { level: contextLevel } = useSubtreeContext_unstable();
    const parentValue = useTreeItemContext_unstable((ctx)=>{
        var _props_parentValue;
        return (_props_parentValue = props.parentValue) !== null && _props_parentValue !== void 0 ? _props_parentValue : ctx.value;
    });
    // note, if the value is not externally provided,
    // then selection and expansion will not work properly
    const internalValue = useId('fuiTreeItemValue-');
    var _props_value;
    const value = (_props_value = props.value) !== null && _props_value !== void 0 ? _props_value : internalValue;
    const { onClick, onKeyDown, onChange, as = 'div', itemType = 'leaf', 'aria-level': level = contextLevel, 'aria-selected': ariaSelected, 'aria-expanded': ariaExpanded, ...rest } = props;
    const actionsRef = React.useRef(null);
    const expandIconRef = React.useRef(null);
    const layoutRef = React.useRef(null);
    const subtreeRef = React.useRef(null);
    const selectionRef = React.useRef(null);
    const treeItemRef = React.useRef(null);
    const open = useTreeContext_unstable((ctx)=>{
        var _props_open;
        return (_props_open = props.open) !== null && _props_open !== void 0 ? _props_open : ctx.openItems.has(value);
    });
    const getNextOpen = ()=>itemType === 'branch' ? !open : open;
    const selectionMode = useTreeContext_unstable((ctx)=>ctx.selectionMode);
    const checked = useTreeContext_unstable((ctx)=>{
        var _ctx_checkedItems_get;
        return (_ctx_checkedItems_get = ctx.checkedItems.get(value)) !== null && _ctx_checkedItems_get !== void 0 ? _ctx_checkedItems_get : false;
    });
    const handleClick = useEventCallback((event)=>{
        onClick === null || onClick === void 0 ? void 0 : onClick(event);
        if (event.isDefaultPrevented()) {
            return;
        }
        const isEventFromActions = actionsRef.current && elementContains(actionsRef.current, event.target);
        if (isEventFromActions) {
            return;
        }
        const isEventFromSubtree = subtreeRef.current && elementContains(subtreeRef.current, event.target);
        if (isEventFromSubtree) {
            return;
        }
        const isEventFromSelection = selectionRef.current && elementContains(selectionRef.current, event.target);
        if (isEventFromSelection) {
            return;
        }
        const isEventFromExpandIcon = expandIconRef.current && elementContains(expandIconRef.current, event.target);
        ReactDOM.unstable_batchedUpdates(()=>{
            var _props_onOpenChange;
            const data = {
                event,
                value,
                open: getNextOpen(),
                target: event.currentTarget,
                type: isEventFromExpandIcon ? treeDataTypes.ExpandIconClick : treeDataTypes.Click
            };
            (_props_onOpenChange = props.onOpenChange) === null || _props_onOpenChange === void 0 ? void 0 : _props_onOpenChange.call(props, event, data);
            requestTreeResponse({
                ...data,
                itemType,
                requestType: 'open'
            });
            requestTreeResponse({
                ...data,
                itemType,
                parentValue,
                requestType: 'navigate',
                type: treeDataTypes.Click
            });
        });
    });
    const handleKeyDown = useEventCallback((event)=>{
        onKeyDown === null || onKeyDown === void 0 ? void 0 : onKeyDown(event);
        // Ignore keyboard events that do not originate from the current tree item.
        if (event.isDefaultPrevented() || event.currentTarget !== event.target) {
            return;
        }
        switch(event.key){
            case Space:
                if (selectionMode !== 'none') {
                    var _selectionRef_current;
                    (_selectionRef_current = selectionRef.current) === null || _selectionRef_current === void 0 ? void 0 : _selectionRef_current.click();
                    // Prevents the page from scrolling down when the spacebar is pressed
                    event.preventDefault();
                }
                return;
            case treeDataTypes.Enter:
                {
                    return event.currentTarget.click();
                }
            case treeDataTypes.End:
            case treeDataTypes.Home:
            case treeDataTypes.ArrowUp:
            case treeDataTypes.ArrowDown:
                return requestTreeResponse({
                    requestType: 'navigate',
                    event,
                    value,
                    itemType,
                    parentValue,
                    type: event.key,
                    target: event.currentTarget
                });
            case treeDataTypes.ArrowLeft:
                {
                    // do not navigate to parent if the item is on the top level
                    if (level === 1 && !open) {
                        return;
                    }
                    const data = {
                        value,
                        event,
                        open: getNextOpen(),
                        type: event.key,
                        target: event.currentTarget
                    };
                    if (open) {
                        var _props_onOpenChange;
                        (_props_onOpenChange = props.onOpenChange) === null || _props_onOpenChange === void 0 ? void 0 : _props_onOpenChange.call(props, event, data);
                    }
                    return requestTreeResponse({
                        ...data,
                        itemType,
                        parentValue,
                        requestType: open ? 'open' : 'navigate'
                    });
                }
            case treeDataTypes.ArrowRight:
                // do not navigate or open if the item is a leaf
                if (itemType === 'leaf') {
                    return;
                }
                const data = {
                    value,
                    event,
                    open: getNextOpen(),
                    type: event.key,
                    target: event.currentTarget
                };
                if (!open) {
                    var _props_onOpenChange1;
                    (_props_onOpenChange1 = props.onOpenChange) === null || _props_onOpenChange1 === void 0 ? void 0 : _props_onOpenChange1.call(props, event, data);
                }
                return requestTreeResponse({
                    ...data,
                    itemType,
                    parentValue,
                    requestType: open ? 'navigate' : 'open'
                });
        }
        const isTypeAheadCharacter = event.key.length === 1 && event.key.match(/\w/) && !event.altKey && !event.ctrlKey && !event.metaKey;
        if (isTypeAheadCharacter) {
            requestTreeResponse({
                requestType: 'navigate',
                event,
                target: event.currentTarget,
                value,
                itemType,
                type: treeDataTypes.TypeAhead,
                parentValue
            });
        }
    });
    const handleChange = useEventCallback((event)=>{
        onChange === null || onChange === void 0 ? void 0 : onChange(event);
        if (event.isDefaultPrevented()) {
            return;
        }
        const isEventFromSubtree = subtreeRef.current && elementContains(subtreeRef.current, event.target);
        if (isEventFromSubtree) {
            return;
        }
        requestTreeResponse({
            requestType: 'selection',
            event,
            value,
            itemType,
            type: 'Change',
            target: event.currentTarget,
            checked: checked === 'mixed' ? true : !checked
        });
    });
    return {
        value,
        open,
        checked,
        subtreeRef,
        layoutRef,
        selectionRef,
        expandIconRef,
        treeItemRef,
        actionsRef,
        itemType,
        level,
        components: {
            root: 'div'
        },
        // FIXME: this property is not necessary anymore, but as removing it would be a breaking change, we need to keep it as false
        isAsideVisible: false,
        // FIXME: this property is not necessary anymore, but as removing it would be a breaking change, we need to keep it as false
        isActionsVisible: false,
        root: slot.always(getIntrinsicElementProps(as, {
            tabIndex: -1,
            [dataTreeItemValueAttrName]: value,
            ...rest,
            ref: useMergedRefs(ref, treeItemRef),
            role: 'treeitem',
            'aria-level': level,
            'aria-checked': selectionMode === 'multiselect' ? checked : undefined,
            // Casting: when selectionMode is 'single', checked is a boolean
            'aria-selected': ariaSelected !== undefined ? ariaSelected : selectionMode === 'single' ? !!checked : undefined,
            'aria-expanded': ariaExpanded !== undefined ? ariaExpanded : itemType === 'branch' ? open : undefined,
            onClick: handleClick,
            onKeyDown: handleKeyDown,
            onChange: handleChange
        }), {
            elementType: 'div'
        })
    };
}
function warnIfNoProperPropsFlatTreeItem(props) {
    if (process.env.NODE_ENV !== 'production') {
        if (props['aria-posinset'] === undefined || props['aria-setsize'] === undefined || props['aria-level'] === undefined || props.parentValue === undefined && props['aria-level'] !== 1) {
            // eslint-disable-next-line no-console
            console.error(`@fluentui/react-tree [${useTreeItem_unstable.name}]:
A flat treeitem must have "aria-posinset", "aria-setsize", "aria-level"
and "parentValue" (if "aria-level" > 1) to ensure a11y and navigation.

- "aria-posinset": the position of this treeitem in the current level of the tree.
- "aria-setsize": the number of siblings in this level of the tree.
- "aria-level": the current level of the treeitem.
- "parentValue": the "value" property of the parent item of this item.`);
        }
    }
}
