import React, { CSSProperties, Fragment } from 'react';
import { Button, Divider, Input, makeStyles, mergeClasses, Tooltip } from '@fluentui/react-components';

import { Icon, renderIconIfDefined } from '../Icon';

import styles from './ButtonWithActions.module.scss';

// Apply CSS to internal html input element (not to Fluent's Input component)
// Prevent pointer-events on the input element, so it will not act as textbox
const useStyles = makeStyles({ inputReadOnly: { '> input': { pointerEvents: 'none' } } });

export interface ButtonWithActionsProps {
    /** Text of the main button */
    text: string;
    /** Icon of the main button */
    iconBefore?: Icon;
    /** Whether the main button is disabled */
    disabled?: boolean;
    /** Click handler for the main button */
    onClick: () => void;
    /** Custom style for the main button */
    style?: CSSProperties;
    /** Actions (icons) to be displayed at the end of the main button */
    actions: {
        /** Icon of the action button */
        icon: Icon;
        /** Tooltip of the action button */
        tooltip: string;
        /** Whether the action button is disabled */
        disabled?: boolean;
        /** Click handler for the action button */
        onClick?: () => void;
    }[];
}

/** Button with additional actions (icons). Clicking the main button or on an icon will trigger different callbacks. */
export const ButtonWithActions: React.FunctionComponent<ButtonWithActionsProps> = ({
    text,
    iconBefore,
    disabled,
    onClick,
    style = {},
    actions,
}) => {
    const className = useStyles();

    return (
        // Using Input and not Button from Fluent - because unlike Button that has Icon and IconPosition (before/after), Input has contentBefore and contentAfter.
        // This allows us to have both iconBefore and actions at the end of the button.
        <div role="button" onClick={onClick} onKeyPress={onClick} tabIndex={0}>
            <Input
                value={text}
                contentBefore={renderIconIfDefined(iconBefore)}
                contentAfter={
                    <>
                        {actions.map((action, index) => (
                            <Fragment key={index}>
                                <Tooltip content={action.tooltip} relationship="label" withArrow={true}>
                                    <Button
                                        icon={renderIconIfDefined(action.icon)}
                                        disabled={action.disabled}
                                        onClick={(event) => {
                                            action.onClick?.();
                                            event.stopPropagation();
                                        }}
                                        appearance="transparent"
                                        size="small"
                                    />
                                </Tooltip>
                                {index != actions.length - 1 && <Divider vertical className={styles.actionsDivider} />}
                            </Fragment>
                        ))}
                    </>
                }
                style={style}
                className={mergeClasses(styles.mainButton, className.inputReadOnly)}
                disabled={disabled}
            />
        </div>
    );
};
