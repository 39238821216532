import React from 'react';

import type { FeatureFlags } from '@kusto/app-common';
import type * as kustoClient from '@kusto/client';
import { IKweTelemetry, KweException } from '@kusto/utils';
import type { ParsedVisuals } from '@kusto/visual-fwk';

import type { QueryFeatureFlag } from '../featureFlags';
// keep the type to avoid store initialization a head of time
import type { IQueryRootStore } from '../stores/rootStore';
import type { QueryConfiguration } from './config';
import type { KustoEditorHandleContainer } from './coreDefaultSetup';
import type { QueryLocale } from './strings';

/**
 * visual strings and featureFlags may be mobx properties
 */
export interface QueryCore {
    readonly kustoEditor: KustoEditorHandleContainer;
    readonly kustoDomains: kustoClient.KustoDomains;
    readonly kustoClient: kustoClient.IKustoClient;
    readonly store: IQueryRootStore;
    readonly telemetry: IKweTelemetry;
    readonly config: QueryConfiguration;
    readonly strings: QueryLocale;
    readonly parsedVisuals: ParsedVisuals;
    readonly featureFlags: FeatureFlags<QueryFeatureFlag>;
    readonly armClient: undefined | kustoClient.IArmClient;
    readonly authProvider: kustoClient.IKustoClientAuthProvider;
}

export const queryCoreContext = React.createContext<undefined | QueryCore>(undefined);

export function useQueryCore() {
    const core = React.useContext(queryCoreContext);
    if (core === undefined) {
        throw new KweException('getQueryCore can only be called after store is initialized');
    }
    return core;
}

export function useBindTelemetry(
    properties: Readonly<Record<string, unknown>> | (() => Readonly<Record<string, unknown>>),
    dependencies: unknown[]
) {
    const core = useQueryCore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => core.telemetry.bind(properties), dependencies);
}
