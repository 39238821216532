import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import KustoCommandHighlighter from './commandHighlighter';
import KustoCommandFormatter from './commandFormatter';
import { extend } from './extendedEditor';
import { themes } from './syntaxHighlighting/themes';
import { LANGUAGE_ID } from './globals';
import { getCslTypeNameFromClrType, getCallName, getExpression, getInputParametersAsCslString, getEntityDataTypeFromCslType, } from './languageServiceManager/schema';
import { getRangeHtml } from './extendedGlobalApi';
export * from './languageServiceManager/schema';
export * from './languageServiceManager/renderInfo';
export * from './languageServiceManager/settings';
export * from './types';
export * from './extendedGlobalApi';
// --- Kusto configuration and defaults ---------
var LanguageServiceDefaultsImpl = /** @class */ (function () {
    function LanguageServiceDefaultsImpl(languageSettings) {
        this._onDidChange = new monaco.Emitter();
        this.setLanguageSettings(languageSettings);
        // default to never kill worker when idle.
        // reason: when killing worker - schema gets lost. We transmit the schema back to main process when killing
        // the worker, but in some extreme cases web worker runs out of memory while stringifying the schema.
        // This stems from the fact that web workers have much more limited memory that the main process.
        // An alternative solution (not currently implemented) is to just save the schema in the main process whenever calling
        // setSchema. That way we don't need to stringify the schema on the worker side when killing the web worker.
        this._workerMaxIdleTime = 0;
    }
    Object.defineProperty(LanguageServiceDefaultsImpl.prototype, "onDidChange", {
        get: function () {
            return this._onDidChange.event;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LanguageServiceDefaultsImpl.prototype, "languageSettings", {
        get: function () {
            return this._languageSettings;
        },
        enumerable: false,
        configurable: true
    });
    LanguageServiceDefaultsImpl.prototype.setLanguageSettings = function (options) {
        this._languageSettings = options || Object.create(null);
        this._onDidChange.fire(this);
    };
    LanguageServiceDefaultsImpl.prototype.setMaximumWorkerIdleTime = function (value) {
        // doesn't fire an event since no
        // worker restart is required here
        this._workerMaxIdleTime = value;
    };
    LanguageServiceDefaultsImpl.prototype.getWorkerMaxIdleTime = function () {
        return this._workerMaxIdleTime;
    };
    return LanguageServiceDefaultsImpl;
}());
var defaultLanguageSettings = {
    includeControlCommands: true,
    newlineAfterPipe: true,
    openSuggestionDialogAfterPreviousSuggestionAccepted: true,
    enableHover: true,
    formatter: {
        indentationSize: 4,
        pipeOperatorStyle: 'Smart',
    },
    syntaxErrorAsMarkDown: {
        enableSyntaxErrorAsMarkDown: false,
    },
    enableQueryWarnings: false,
    enableQuerySuggestions: false,
    disabledDiagnosticCodes: [],
    quickFixCodeActions: ['Change to', 'FixAll'],
    enableQuickFixes: false,
    completionOptions: { includeExtendedSyntax: false },
};
export function getKustoWorker() {
    return new Promise(function (resolve, reject) {
        withMode(function (mode) {
            mode.getKustoWorker().then(resolve, reject);
        });
    });
}
function withMode(callback) {
    import('./kustoMode').then(callback);
}
export var kustoDefaults = new LanguageServiceDefaultsImpl(defaultLanguageSettings);
monaco.languages.onLanguage('kusto', function () {
    withMode(function (mode) { return mode.setupMode(kustoDefaults, monaco); });
});
monaco.languages.register({
    id: LANGUAGE_ID,
    extensions: ['.csl', '.kql'],
});
themes.forEach(function (_a) {
    var name = _a.name, data = _a.data;
    return monaco.editor.defineTheme(name, data);
});
// Initialize kusto specific language features that don't currently have a natural way to extend using existing apis.
// Most other language features are initialized in kustoMode.ts
monaco.editor.onDidCreateEditor(function (editor) {
    var _a;
    if ((_a = window.MonacoEnvironment) === null || _a === void 0 ? void 0 : _a.globalAPI) {
        // hook up extension methods to editor.
        extend(editor);
    }
    // TODO: asked if there's a cleaner way to register an editor contribution. looks like monaco has an internal contribution registrar but it's no exposed in the API.
    // https://stackoverflow.com/questions/46700245/how-to-add-an-ieditorcontribution-to-monaco-editor
    new KustoCommandHighlighter(editor);
    if (isStandaloneCodeEditor(editor)) {
        new KustoCommandFormatter(editor);
    }
    triggerSuggestDialogWhenCompletionItemSelected(editor);
});
function triggerSuggestDialogWhenCompletionItemSelected(editor) {
    editor.onDidChangeCursorSelection(function (event) {
        // checking the condition inside the event makes sure we will stay up to date when kusto configuration changes at runtime.
        if (kustoDefaults &&
            kustoDefaults.languageSettings &&
            kustoDefaults.languageSettings.openSuggestionDialogAfterPreviousSuggestionAccepted) {
            var didAcceptSuggestion = event.source === 'snippet' && event.reason === monaco.editor.CursorChangeReason.NotSet;
            // If the word at the current position is not null - meaning we did not add a space after completion.
            // In this case we don't want to activate the eager mode, since it will display the current selected word..
            if (!didAcceptSuggestion || editor.getModel().getWordAtPosition(event.selection.getPosition()) !== null) {
                return;
            }
            event.selection;
            // OK so now we in a situation where we know a suggestion was selected, and we want to trigger another one.
            // the only problem is that the suggestion widget itself listens to this same event in order to know it needs to close.
            // The only problem is that we're ahead in line, so we're triggering a suggest operation that will be shut down once
            // the next callback is called. This is why we're waiting here - to let all the callbacks run synchronously and be
            // the 'last' subscriber to run. Granted this is hacky, but until monaco provides a specific event for suggestions,
            // this is the best we have.
            setTimeout(function () { return editor.trigger('monaco-kusto', 'editor.action.triggerSuggest', {}); }, 10);
        }
    });
}
function isStandaloneCodeEditor(editor) {
    return editor.addAction !== undefined;
}
var globalApi = {
    getCslTypeNameFromClrType: getCslTypeNameFromClrType,
    getCallName: getCallName,
    getExpression: getExpression,
    getInputParametersAsCslString: getInputParametersAsCslString,
    getEntityDataTypeFromCslType: getEntityDataTypeFromCslType,
    kustoDefaults: kustoDefaults,
    getKustoWorker: getKustoWorker,
    getRangeHtml: getRangeHtml,
};
monaco.languages.kusto = globalApi;
// I am not sure why this is needed, but it is. Without it, parcel production build removes this function.
export { getRangeHtml };
