// In Trident you could find 'TableNameTransformations.ts' which includes this method and other utils.
// We should create a new package from these utils and other KQL/Monaco related code.

/**
 * @example
 * "MyColumn" >> "MyColumn"
 * "My-Column" >> "['My-Column']"
 */
export function kqlEscapeSourceName(sourceName: string) {
    if (sourceName.match(/[ \-.]/)) {
        return `['${sourceName}']`;
    }

    return sourceName;
}
