/**
 * Sets the virtual parent of an element.
 *
 * @internal
 * @param child - Theme element to set the virtual parent
 * @param parent - The virtual parent, use `undefined` to remove a virtual parent relationship
 */ export function setVirtualParent(child, parent) {
    if (!child) {
        return;
    }
    const virtualChild = child;
    if (!virtualChild._virtual) {
        virtualChild._virtual = {};
    }
    virtualChild._virtual.parent = parent;
}
