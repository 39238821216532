import * as React from 'react';
import { omit } from '../../utils/omit';
import { isSlot } from '../isSlot';
import { SLOT_RENDER_FUNCTION_SYMBOL } from '../constants';
/**
 * Given the state and an array of slot names, will break out `slots` and `slotProps`
 * collections.
 *
 * The root is derived from a mix of `components` props and `as` prop.
 *
 * Slots will render as null if they are rendered as primitives with undefined children.
 *
 * The slotProps will always omit the `as` prop within them, and for slots that are string
 * primitives, the props will be filtered according to the slot type by the type system.
 * For example, if the slot is rendered `as: 'a'`, the props will be filtered for acceptable
 * anchor props. Note that this is only enforced at build time by Typescript -- there is no
 * runtime code filtering props in this function.
 *
 * @deprecated use slot.always or slot.optional combined with assertSlots instead
 *
 * @param state - State including slot definitions
 * @returns An object containing the `slots` map and `slotProps` map.
 */ export function getSlots(state) {
    // eslint-disable-next-line deprecation/deprecation
    const slots = {};
    const slotProps = {};
    const slotNames = Object.keys(state.components);
    for (const slotName of slotNames){
        const [slot, props] = getSlot(state, slotName);
        // eslint-disable-next-line deprecation/deprecation
        slots[slotName] = slot;
        slotProps[slotName] = props;
    }
    // eslint-disable-next-line deprecation/deprecation
    return {
        slots,
        slotProps: slotProps
    };
}
function getSlot(state, slotName) {
    var _state_components, _state_components1;
    const props = state[slotName];
    if (props === undefined) {
        return [
            null,
            undefined
        ];
    }
    // TS Error: Property 'as' does not exist on type 'UnknownSlotProps | undefined'.ts(2339)
    const { as: asProp, children, ...rest } = props;
    const renderFunction = isSlot(props) ? props[SLOT_RENDER_FUNCTION_SYMBOL] : undefined;
    const slot = ((_state_components = state.components) === null || _state_components === void 0 ? void 0 : _state_components[slotName]) === undefined || typeof state.components[slotName] === 'string' ? asProp || ((_state_components1 = state.components) === null || _state_components1 === void 0 ? void 0 : _state_components1[slotName]) || 'div' : state.components[slotName];
    if (renderFunction || typeof children === 'function') {
        const render = renderFunction || children;
        return [
            React.Fragment,
            {
                children: render(slot, rest)
            }
        ];
    }
    const shouldOmitAsProp = typeof slot === 'string' && asProp;
    const slotProps = shouldOmitAsProp ? omit(props, [
        'as'
    ]) : props;
    return [
        slot,
        slotProps
    ];
}
