import React from 'react';

import { formatLiterals } from '@kusto/utils';

import { InteractionTarget } from '../../interactions/target';
import type { KweVisualFwkLocale } from '../../types';
import { DrillthroughPair } from '../../visualOptions';
import type { PropertiesIndex } from './Drillthrough';
import { DrillthroughVisual } from './VisualInteraction';

function localStrings(t: KweVisualFwkLocale) {
    return t.visualFwk.visualConfig.interactions.drillthrough;
}
export interface ResolvedSelections {
    readonly parameter: undefined | InteractionTarget;
    readonly property: undefined | DrillthroughVisual.Property;
}

export function getResolvedSelections(
    pair: DrillthroughPair,
    interaction: DrillthroughVisual.Interaction,
    propertiesIndex: PropertiesIndex,
    parametersRecord: ReadonlyMap<string, InteractionTarget> | undefined
): ResolvedSelections {
    let property: undefined | DrillthroughVisual.Property;

    if (interaction.properties && pair.property !== undefined) {
        property = propertiesIndex.get(pair.property);
    }

    let parameter: undefined | InteractionTarget = undefined;

    if (pair.parameterId) {
        const maybeParam = parametersRecord?.get(pair.parameterId);
        parameter = maybeParam;
    }

    return {
        property,
        parameter,
    };
}

export function useResolvedSelectionsMap(
    pairs: DrillthroughPair[],
    interaction: DrillthroughVisual.Interaction,
    propertiesIndex: PropertiesIndex,
    parametersRecord: ReadonlyMap<string, InteractionTarget> | undefined
): Map<DrillthroughPair, ResolvedSelections> {
    return React.useMemo(() => {
        return new Map(pairs.map((p) => [p, getResolvedSelections(p, interaction, propertiesIndex, parametersRecord)]));
    }, [pairs, interaction, propertiesIndex, parametersRecord]);
}

export function formatUnavailable(t: KweVisualFwkLocale, category: string) {
    return formatLiterals(localStrings(t).unavailable, { category });
}
