import * as React from 'react';
import { Dropdown, Icon, IDropdownOption, IDropdownProps, Label } from '@fluentui/react';

import type { KweVisualFwkLocale } from '../../types';
import type { Slot } from '../../visualOptions';
import { createSlotLabel } from './lib';
import { SlotCallout } from './SlotCallout';

import * as sharedStyles from '../styles.module.scss';
import * as styles from './SlotInput.module.scss';

const onRenderCaretDown = () => <Icon iconName="GridViewMedium" />;

const onRenderPlaceholder = (props?: IDropdownProps) => <span className={styles.inputText}>{props?.placeholder}</span>;

const options: IDropdownOption[] = [];

export interface SlotInputProps {
    t: KweVisualFwkLocale;
    slot: Slot;
    onChange: (slot: Slot) => void;
    id: string;
    disabled?: boolean;
}

export const SlotInput: React.FC<SlotInputProps> = ({ t, slot, onChange, id, disabled }) => {
    const [calloutOpen, setCalloutOpen] = React.useState(false);

    const onCalloutClose = React.useCallback(() => setCalloutOpen(false), []);
    const onCalloutSave = React.useCallback(
        (newSlot: Slot) => {
            onChange(newSlot);
            setCalloutOpen(false);
        },
        [onChange]
    );
    const onCalloutOpen = React.useCallback(() => setCalloutOpen(true), []);

    return (
        <div className={sharedStyles.basicInput}>
            <Label htmlFor={id}>{t.visualFwk.visualConfig.multiStat__slot.labelText}</Label>
            <Dropdown
                placeholder={createSlotLabel(slot, t).text}
                onRenderPlaceholder={onRenderPlaceholder}
                options={options}
                id={id}
                onClick={onCalloutOpen}
                onRenderCaretDown={onRenderCaretDown}
                disabled={disabled}
            />
            {calloutOpen && (
                <SlotCallout t={t} target={`#${id}`} slot={slot} onClose={onCalloutClose} onSave={onCalloutSave} />
            )}
        </div>
    );
};
