import { Theme } from '@kusto/utils';

// taken from https://www.figma.com/file/PASyBiBq72G54ckrtgs86uE7/Azure-Framework-Design-Specs?node-id=3739%3A14
const baseColors = [
    '#3487C7', // 1
    '#CA5010', // 2
    '#4F6BED', // 3
    '#00A5AF', // 4
    '#B27C00', // 5
    '#689920', // 6
    '#009B51', // 7
    '#9470BD', // 8
    '#B146C2', // 9
    '#E61C99', // 10
];

const colorsLight = [
    ...baseColors,
    '#2C72A8', // 11
    '#9A3D0C', // 12
    '#026467', // 13
    '#3C51B4', // 14
    '#0E7A0B', // 15
    '#405F14', // 16
    '#6D5700', // 27
    '#674C8C', // 18
    '#863593', // 19
    '#AD006A', // 20
];

export const colorsDark = [
    ...baseColors,
    '#4FA1E1', // 11
    '#D77440', // 12
    '#4CB4B7', // 13
    '#93A4F4', // 14
    '#27AC22', // 15
    '#73AA24', // 16
    '#D0B232', // 27
    '#A083C9', // 18
    '#C36BD1', // 19
    '#EE5FB7', // 20
];

export interface ChartColors {
    colors: string[];
    tooltipStyle: { backgroundColor: string } | undefined;
    tickStyle: { fill: string };
}

export function getChartColors(theme: Theme | undefined): ChartColors {
    return theme === Theme.Dark
        ? {
              colors: colorsDark,
              tooltipStyle: { backgroundColor: 'black' },
              tickStyle: { fill: 'white' },
          }
        : { colors: colorsLight, tooltipStyle: undefined, tickStyle: { fill: 'black' } };
}
