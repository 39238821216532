import * as React from 'react';
import { tokens } from '@fluentui/react-components';
import { observer } from 'mobx-react';

import { formatLiterals } from '@kusto/utils';
import { DetailsViewType } from '@kusto/visualizations';

import { useQueryCore } from '../../core/core';
import * as Icons from './icons';
import { IJPathMenuProps, IMenuOption, JPathMenuComponent } from './MenuComponent';

export const ExpandSelectionMenu = observer(function ExpandSelectionMenu({ showLabel, isMenuItem }: IJPathMenuProps) {
    const core = useQueryCore();
    const {
        strings,
        store: { layout },
    } = core;
    const expandViewLayout = layout.expandViewLayout;
    const menuOptions: IMenuOption[] = [
        {
            key: strings.query.queryDetailedViewInlineLayout.label,
            data: DetailsViewType.InGrid,
            label: strings.query.queryDetailedViewInlineLayout.label,
            tooltipProps: { content: strings.query.queryDetailedViewInlineLayout.description },
            icon: <Icons.Inline color={tokens.colorPaletteBlueBorderActive} />,
            isDisabled: false,
        },
        {
            key: strings.query.queryDetailedViewBelowLayout.label,
            data: DetailsViewType.BelowGrid,
            label: strings.query.queryDetailedViewBelowLayout.label,
            tooltipProps: { content: strings.query.queryDetailedViewBelowLayout.description },
            icon: <Icons.Below color={tokens.colorPaletteBlueBorderActive} />,
        },
        {
            key: strings.query.queryDetailedViewRightLayoutLabel,
            data: DetailsViewType.ExternalPanel,
            label: strings.query.queryDetailedViewRightLayoutLabel,
            tooltipProps: { content: strings.query.queryDetailedViewRightLayoutDescription },
            icon: <Icons.Right color={tokens.colorPaletteBlueBorderActive} />,
        },
    ];

    const selectedItem = menuOptions.find((item) => item.data === expandViewLayout) || menuOptions[0];

    const ariaLabel = formatLiterals(strings.query.expandViewAriaLabel, {
        selected: selectedItem.key,
    });

    const onItemSelected = (item: DetailsViewType) => {
        layout.setExpandViewLayout(item);
    };
    const isDisabled = layout.windowHeightSizeType === 'Small';
    const tooltipContent =
        layout.windowHeightSizeType === 'Small'
            ? strings.query.expndViewDisabledTooltip
            : strings.query.expandViewToolTip;

    const props = {
        selectedItem,
        tooltipContent,
        isMenuItem,
        showLabel,
        labelWithSelection: ariaLabel,
        isDisabled,
        menuOptions,
        onItemSelected: onItemSelected as (item: IMenuOption['data']) => void,
    };
    return <JPathMenuComponent {...props} />;
});
