import React from 'react';
import { Button } from '@fluentui/react-button';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';

import { Aborted, KweUtilsStrings, Ok } from '@kusto/utils';

import { RenderHelper } from '../utils/RenderHelper';
import { useAlertStyles } from './Alert';

export interface KwePromptProps extends Omit<KwePromptOptions, 'signal'> {
    title: string | React.ReactElement;

    t: KweUtilsStrings;
    resolve: (result: boolean) => void;
}

/**
 * Designed primarily for use with {@link kwePrompt}. Intentionally does _not_
 * support many options to keep implementation simple. If flexibility is needed,
 * copy+paste and customize.
 */
export const KwePrompt: React.FC<KwePromptProps> = ({
    resolve,
    t,
    title,
    content,
    acceptText,
    cancelText,
    hideCancelButton,
    additionalActions,
    ...dialogProps
}) => {
    const classes = useAlertStyles();

    return (
        <Dialog
            open
            modalType="alert"
            onOpenChange={(_event, data) => {
                if (!data.open) {
                    resolve(false);
                }
            }}
            {...dialogProps}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle className={classes.title}>{title}</DialogTitle>
                    {content && <DialogContent>{content}</DialogContent>}
                    {/* Not sure why `fluid` isn't the default behavior */}
                    <DialogActions fluid>
                        {/* Not wrapped in `<DialogTrigger />` so it can `resolve(true)` */}
                        <Button appearance="primary" onClick={() => resolve(true)}>
                            {acceptText ?? t.util.buttons.ok}
                        </Button>
                        {!hideCancelButton && (
                            <DialogTrigger>
                                <Button appearance="secondary">{cancelText ?? t.util.buttons.cancel}</Button>
                            </DialogTrigger>
                        )}
                        {additionalActions}
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export interface KwePromptOptions extends Omit<DialogProps, 'children'> {
    content?: React.ReactNode;
    acceptText?: string;
    cancelText?: string;
    hideCancelButton?: boolean;

    additionalActions?: React.ReactNode;
    /**
     * TODO(a11y): remove this once we upgrade to Fluent 9 across Dashboards
     */
    onResolved?: () => void;

    signal?: AbortSignal;
}

/**
 * A note on a11y: When using {@link kwePrompt}, please check if the focus correctly moves to the Prompt
 * and when the Prompt is closed (de-rendered) the focus moves back to the original trigger element. If this
 * isn't happening automatically, then you can use the `onResolved` callback to manually move focus yourself.
 * Please use `onResolved` if needed so it will be easier for us to remove it later once we've fully upgraded
 * to Fluent 9 which _should_ solve these focus issues for us automatically.
 *
 * @example
 *
 * async function handleClick() {
 *   const res = await kwePrompt(..., {
 *     onResolved: () => {
 *       htmlDropdownRef.current.focus();
 *     }
 *   });
 *   // do more stuff
 * }
 */
export async function kwePrompt(
    render: RenderHelper,
    t: KweUtilsStrings,
    title: string | React.ReactElement,
    options?: KwePromptOptions
): Promise<Ok<boolean> | Aborted> {
    const res = await render<boolean>(
        (resolve) => <KwePrompt t={t} title={title} {...options} resolve={resolve} />,
        options?.signal
    );
    options?.onResolved?.();
    return res;
}
