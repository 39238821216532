import * as client from '@kusto/client';
import { assertNever } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';

export type RtdVisualTypes =
    | 'multistat'
    | 'anomalychart'
    | 'map'
    | 'area'
    | 'bar'
    | 'card'
    | 'column'
    | 'line'
    | 'pie'
    | 'scatter'
    | 'stackedarea'
    | 'stackedbar'
    | 'stackedcolumn'
    | 'stacked100area'
    | 'stacked100bar'
    | 'stacked100column'
    | 'table'
    | 'timechart'
    | 'markdown'
    | 'heatmap'
    | 'plotly'
    | 'funnel';

export function toRtdVisualType(
    visualization: client.VisualizationType | null | undefined,
    kind: client.Kind | undefined
): RtdVisualTypes {
    if (!visualization) {
        return 'table';
    }

    switch (visualization) {
        case 'areachart':
            if (kind === 'stacked') {
                return 'stackedarea';
            }
            if (kind === 'stacked100') {
                return 'stacked100area';
            }
            return 'area';
        case 'anomalychart':
            return 'anomalychart';
        case 'barchart':
            if (kind === 'stacked') {
                return 'stackedbar';
            }
            if (kind === 'stacked100') {
                return 'stacked100bar';
            }
            return 'bar';
        case 'card':
            return 'card';
        case 'columnchart':
            if (kind === 'stacked') {
                return 'stackedcolumn';
            }
            if (kind === 'stacked100') {
                return 'stacked100column';
            }
            return 'column';
        case 'ladderchart':
            return 'table';
        case 'linechart':
            return 'line';
        case 'piechart':
            return kind === 'map' ? 'map' : 'pie';
        case 'pivotchart':
            return 'table';
        case 'scatterchart':
            return kind === 'map' ? 'map' : 'scatter';
        case 'stackedareachart':
            return 'stackedarea';
        case 'table':
            return 'table';
        case 'timechart':
            return 'timechart';
        case 'timeline':
            return 'table';
        case 'timepivot':
            return 'table';
        case 'plotly':
            return 'plotly';
        default:
            assertNever(visualization);
    }
}

export interface RTDRenderOptions {
    visualOptions: Fwk.UnknownVisualOptions;
    visualType: RtdVisualTypes;
    title?: string;
    hideTitle?: boolean;
}

export const defaultVisualOptions: Omit<client.VisualizationOptions, 'Visualization'> = {
    Accumulate: false,
    IsQuerySorted: false,
    Kind: null,
    Legend: null,
    Series: null,
    Title: null,
    XAxis: null,
    XColumn: null,
    XTitle: null,
    YAxis: null,
    YColumns: null,
    YSplit: null,
    YTitle: null,
    AnomalyColumns: null,
    Ymin: 'NaN',
    Ymax: 'NaN',
};
