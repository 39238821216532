import { inject, observer } from 'mobx-react';

import { IRootStore } from '../../stores/rootStore';
import { TreeView } from './TreeView';

// inject produces a higher order component (i.e a function the returns a component).
// The argument to this function is an observer component, which gets injected with the store entities as its props.
export const ConnectionTree = inject((allStores: { store: IRootStore }) => {
    return {
        favorites: allStores.store.connectionPane.favorites,
        theme: allStores.store.settings.theme,
    };
    // Added while enabling new lints
    // eslint-disable-next-line mobx/no-anonymous-observer
})(observer(TreeView));
