import * as React from 'react';
import { IList, List } from '@fluentui/react/lib/List';
import { ISearchBox } from '@fluentui/react/lib/SearchBox';
import { ITextField } from '@fluentui/react/lib/TextField';
import { getFirstFocusable, IRefObject } from '@fluentui/react/lib/Utilities';

import { useCurrent } from '../../../../hooks/useCurrent';
import type { IKweTelemetry } from '../../../../telemetry';
import { RTD_DROPDOWN } from '../../constants';
import { useCustomListDropdownDispatch, useCustomListDropdownSelector } from './CustomListDropdownMenuContext';
import { CustomListDropdownMenuSelectionContext, useIsSelected } from './CustomListDropdownMenuSelectionContext';
import { useOnInputKeyDown, useOnWrapperKeyDown } from './hooks';
import { CustomListDropdownMenuReducerState } from './reducer';
import {
    CustomListDropdownHeaderProps,
    KeyToIndex,
    PrerenderedCustomListDropdownOption,
    RTDDropdownOption,
} from './types';

import * as styles from './CustomListDropdownMenu.module.scss';

export interface CustomListDropdownMenuBaseProps {
    onRenderHeader: (props: CustomListDropdownHeaderProps) => React.ReactElement | null;
    onRenderHeaderPrefix?: () => React.ReactElement | null;
    noData?: React.ReactElement;

    /**
     * The number of items to count as no data
     */
    noDataCount?: number;

    options: RTDDropdownOption[];

    extendedOptionFunc?: (option: RTDDropdownOption) => Record<string, unknown>;
    renderedOptions?: React.ReactElement[] | React.ReactElement;
    defaultList?: React.ReactElement | null;

    /**
     * Not used in this component; exposed for other components implementing these props
     */
    currentSelectedIndexes?: React.RefObject<Set<number> | undefined>;
    /**
     * Not used in this component; exposed for other components implementing these props
     */
    selectedKey?: string | null;
    telemetry: IKweTelemetry;
    /**
     * An escape hatch if you need to focus the target Dropdown that this menu is for
     */
    focusDropdown: () => void;
}

export type CustomListDropdownMenuProps = Omit<CustomListDropdownMenuBaseProps, 'onRenderHeader'>;

const selector = (state: CustomListDropdownMenuReducerState) => state;

export const CustomListDropdownMenu: React.FC<CustomListDropdownMenuBaseProps> = ({
    onRenderHeader,
    onRenderHeaderPrefix,
    noData,
    noDataCount,
    options: externalOptions,
    extendedOptionFunc,
    renderedOptions,
    defaultList,
    telemetry,
    focusDropdown,
}) => {
    const inputRef = React.useRef<ITextField | ISearchBox | null>(null);
    const { listRef, orderedFilteredKeys, activeIndex } = useCustomListDropdownSelector(selector);
    const [dispatch] = useCustomListDropdownDispatch();

    const currentActiveIndex = useCurrent(activeIndex);

    const [options, keyToIndex] = React.useMemo((): [PrerenderedCustomListDropdownOption[], KeyToIndex] => {
        if (!renderedOptions || !Array.isArray(renderedOptions) || renderedOptions.length !== externalOptions.length) {
            return [[], {}];
        }

        const array: PrerenderedCustomListDropdownOption[] = new Array(externalOptions.length);
        const keys: KeyToIndex = {};

        for (let i = 0; i < externalOptions.length; i++) {
            const option = externalOptions[i];
            // We don't need to copy all fields
            const item: PrerenderedCustomListDropdownOption = {
                key: option.key,
                text: option.text,
                element: renderedOptions[i],
                disabled: option.disabled,
                hidden: option.hidden,
            };
            if (extendedOptionFunc) {
                array[i] = { ...item, ...extendedOptionFunc(option) };
            } else {
                array[i] = item as PrerenderedCustomListDropdownOption;
            }

            keys[option.key] = i;
        }

        return [array, keys];
    }, [externalOptions, renderedOptions, extendedOptionFunc]);

    const filteredOptions = React.useMemo((): PrerenderedCustomListDropdownOption[] => {
        if (!orderedFilteredKeys) {
            return options;
        }

        const innerFilteredOptions: PrerenderedCustomListDropdownOption[] = [];

        for (const key of orderedFilteredKeys) {
            const newGlobalIndex = keyToIndex[key];
            const option = options[newGlobalIndex];

            if (option) {
                innerFilteredOptions.push(options[newGlobalIndex]);
            } else {
                /**
                 * We are tracking here just to verify if this issue
                 * is still occurring for users.
                 *
                 * @see https://msazure.visualstudio.com/DefaultCollection/One/_workitems/edit/13725300
                 */
                telemetry.event('dropdown-option-undefined');
            }
        }

        return innerFilteredOptions;
    }, [orderedFilteredKeys, options, keyToIndex, telemetry]);

    const globalActiveIndex = activeIndex !== undefined ? keyToIndex[filteredOptions[activeIndex].key] : undefined;

    const setListRef = React.useCallback(
        (list: IList | null) =>
            dispatch({
                type: 'setListRef',
                listRef: list,
            }),
        [dispatch]
    );

    const onInputKeyDown = useOnInputKeyDown(filteredOptions, currentActiveIndex, listRef, inputRef, dispatch);

    const onWrapperKeyDown = useOnWrapperKeyDown(
        filteredOptions,
        currentActiveIndex,
        listRef,
        inputRef,
        focusDropdown,
        dispatch
    );

    const onRenderCell = React.useCallback(
        (item: PrerenderedCustomListDropdownOption | undefined, globalIndex?: number) => {
            if (item?.element.props.role === 'separator') {
                return item.element;
            }
            const index = item && keyToIndex ? keyToIndex[item.key] : globalIndex ?? -1;

            let element: React.ReactElement | null = null;
            if (item?.element) {
                const filteredIndex = filteredOptions.findIndex((o) => o.key === item?.key);

                /**
                 * Temporary and hacky way to get the aria attributes
                 * for posinset and setsize to be accurate because
                 * the prerendered element is for the list that's
                 * unfiltered. This really only applies to
                 * SearchDropdown. The better fix would be to make
                 * the `options` prop be updated to the filtered
                 * options so that the prerendered element
                 * is for the filtered list.
                 */
                element = React.cloneElement(item?.element, {
                    'aria-posinset': (filteredIndex === -1 ? index : filteredIndex) + 1, // +1 because it's 0-indexed and needs to be 1-indexed for the aria property
                    'aria-setsize': filteredOptions.length,
                });
            }

            return <ListCell index={index} element={element} />;
        },
        [keyToIndex, filteredOptions]
    );

    const wrappedInputRef: IRefObject<ITextField | ISearchBox> = React.useCallback((ref) => {
        // auto focus the input on initial render
        // through the ref instead of the `autoFocus` prop
        // because this gets called later in the painting process
        // and will make sure the search input gets focused
        if (ref !== null) {
            ref.focus();
        }

        inputRef.current = ref;
    }, []);

    return (
        <div role="presentation" onKeyDown={onWrapperKeyDown} className={styles.gridWrapper}>
            {onRenderHeader({
                unfilteredOptions: options,
                filteredOptions,
                onInputKeyDown,
                inputRef: wrappedInputRef,
                onRenderHeaderPrefix,
            })}
            {/* Runtime check just in case a single object is sent through props, rather than an array. Apparently children
      can be a single element (maybe a Fabric bug), so we have to check to make sure. This is really only an issue
      because we're reaching into Fabric's normal render and pulling out the children it generates. */}
            {Array.isArray(renderedOptions) ? (
                <>
                    {filteredOptions.length < (noDataCount ?? 0) + 1 ? (
                        noData
                    ) : (
                        <CustomListDropdownMenuSelectionContext.Provider value={globalActiveIndex}>
                            <List<PrerenderedCustomListDropdownOption>
                                componentRef={setListRef}
                                role="presentation"
                                className={styles.itemsWrapper}
                                data-is-scrollable="true"
                                items={filteredOptions}
                                onRenderCell={onRenderCell}
                                getPageHeight={getPageHeight}
                            />
                        </CustomListDropdownMenuSelectionContext.Provider>
                    )}
                </>
            ) : (
                <div className={styles.itemsWrapper} role="presentation">
                    {defaultList ?? null}
                </div>
            )}
        </div>
    );
};

/**
 * Fabric attempts to estimate the height of pages based on the first page, but somehow messes up. This sets all pages to a static height based on the number of rows
 *
 * If a page contains a hidden element, this isn't the exactly "correct" height, as hidden elements contribute zero height.
 * However, this number is only an estimate.
 */
const getPageHeight = (_: unknown, _2: unknown, itemCount = 0) => itemCount * RTD_DROPDOWN.rowHeight;

/**
 * Separated and wrapped in memo as the same button gets rendered many times, but typically without changes
 */
const ListCell: React.FC<{
    /**
     * Absolute index of the ListCell in the *unfiltered* List
     */
    index: number;
    /**
     * The element for the prerendered
     * dropdown option
     */
    element: React.ReactElement | null;
}> = React.memo(function ListCell({ index, element }) {
    const wrapperRef = React.useRef<HTMLDivElement | null>(null);
    const isActive = useIsSelected(index);

    React.useEffect(() => {
        /**
         * Discovered when the `activeIndex` state
         * is undefined then the focus management for the
         * List Cell Items works properly but as soon as
         * `activeIndex` gets set to a number then
         * the List items don't get focused if you
         * are traversing the ListBox via Up/Down Arrows
         * on keyboard.
         *
         * So this is to ensure that the first focusable
         * element, button for single & checkbox for multi,
         * will get focused if it's not already focused
         */
        if (isActive && wrapperRef.current) {
            const el = getFirstFocusable(wrapperRef.current, wrapperRef.current);

            if (el && document.activeElement !== el) {
                el.focus();
            }
        }
    }, [isActive]);

    return (
        <div ref={wrapperRef} className={isActive ? styles.activeItem : undefined} role="presentation">
            {element}
        </div>
    );
});
