import * as React from 'react';
import { useContext } from 'react';
import { MenuItem, MenuList } from '@fluentui/react-components';
import { mergeClasses } from '@griffel/core';
import * as fuzzysort from 'fuzzysort';
import { Chart, SeriesOptionsType } from 'highcharts';

import { TelemetryContext } from '@kusto/utils';

import { VisualizationsStrings } from '../../types.ts';
import { convertSymbolStrToShape, ExtendedSeries } from '../crosshairUtils.ts';
import { MIN_ITEMS_FOR_ACTIONS, useHorizontalGrid } from './hooks/useHorizontalGrid.tsx';
import { InteractiveActions, InteractiveActionsRef } from './InteractiveActions.tsx';
import { ScrollControls } from './ScrollControls.tsx';
import { ChartSeries, LegendItem } from './types.ts';

import * as styles from './InteractiveLegend.module.scss';

interface InteractiveLegendProps {
    chart: Chart | Chart[];
    seriesList: ChartSeries[];
    seriesOptions: SeriesOptionsType[] | undefined;
    strings: VisualizationsStrings;
    horizontal?: boolean;
}

export const InteractiveLegend: React.FC<InteractiveLegendProps> = ({
    chart,
    seriesList,
    seriesOptions,
    strings,
    horizontal,
}) => {
    const [legendItems, setLegendItems] = React.useState<LegendItem[]>([]);
    const [lastIndexClicked, setLastIndexClicked] = React.useState<number>(0);
    const [arrowSelectionIndex, setArrowSelectionIndex] = React.useState<number | null>(null);
    const menuRef = React.useRef<HTMLDivElement>(null);
    const actionsRef = React.useRef<InteractiveActionsRef>(null);
    const getTelemetryClient = useContext(TelemetryContext);
    const showActions = seriesList.length > MIN_ITEMS_FOR_ACTIONS;
    const { trackEvent } = getTelemetryClient({ component: 'InteractiveLegend', flow: '' });

    useHorizontalGrid(horizontal, menuRef, seriesList.length);

    React.useEffect(() => {
        if (isSeriesListValid(seriesList) && seriesOptions) {
            setArrowSelectionIndex(null);
            actionsRef.current?.clearSearch();
            setLegendItems(convertSeriesListToLegendItems(seriesList));
        }
    }, [seriesList, seriesOptions]);

    const updateLegend = (series?: ChartSeries[]) => {
        setLegendItems(convertSeriesListToLegendItems(series ?? seriesList));
    };

    const redraw = () => {
        if (Array.isArray(chart)) {
            chart.forEach((item) => item.redraw(true));
        } else {
            chart.redraw(true);
        }
    };

    const handleHover = (legendItem: LegendItem | undefined, resetSelectedIndex = true) => {
        if (resetSelectedIndex) {
            setArrowSelectionIndex(null);
        }

        if (legendItem && legendItem.visible) {
            seriesList.forEach((series) => {
                series.name === legendItem.name ? series.setState('normal') : series.setState('inactive');
            });
        } else if (!legendItem) {
            seriesList.forEach((series) => series.setState('normal'));
        }
    };

    const handleClick = (mEvent: React.MouseEvent | undefined, legendItem: LegendItem) => {
        trackEvent('Interactive legend: item clicked');
        const itemIndex = legendItems.indexOf(legendItem) ?? 0;
        const isShiftPressed = mEvent?.shiftKey;

        if (isShiftPressed) {
            const checkInRange = buildRangeChecker(lastIndexClicked, itemIndex);
            seriesList.forEach((series, index) => series.setVisible(checkInRange(index), false));
            legendItems.forEach((legendItem, index) => (legendItem.visible = checkInRange(index)));
        } else {
            const isCtrlPressed = isMacOs() ? mEvent?.metaKey : mEvent?.ctrlKey;
            const singleSelectionClicked = isSingleSelectionClicked(legendItem, legendItems);

            if (!isCtrlPressed) {
                // CTRL wasn't pressed - change series items visibility based on singleSelectionClicked
                seriesList.forEach((series) => series.setVisible(singleSelectionClicked, false));
                legendItems.forEach((legendItem) => (legendItem.visible = singleSelectionClicked));
            }

            const series = seriesList.find((series) => series.name === legendItem.name);
            if (series && !singleSelectionClicked) {
                series.setVisible(undefined, false); // undefined - the visibility is toggled
                legendItem.visible = series.visible;
            }
        }

        redraw();
        setLegendItems([...legendItems]);
        setLastIndexClicked(itemIndex);
    };

    const handleInvertClicked = () => {
        trackEvent('Interactive legend: invert selection clicked');
        seriesList.forEach((series) => series.setVisible(undefined, false));
        redraw();
        updateLegend();
    };

    const handleArrowClicked = (isUp: boolean) => {
        trackEvent(`Interactive legend: ${isUp ? 'Up' : 'Down'} arrow button clicked`);
        const isSingleSelection = legendItems.filter((item) => item.visible).length === 1;
        const currentIndex = arrowSelectionIndex !== null ? arrowSelectionIndex : lastIndexClicked;
        const newIndex = calcUpdatedIndex(currentIndex, legendItems.length, isUp);

        if (newIndex === currentIndex) {
            return;
        }

        // Mimic hovering behavior (highlight only the selected series)
        if (menuRef.current) {
            const menuItem = menuRef.current?.children[newIndex] as HTMLElement | null;
            if (menuItem) {
                menuRef.current.scrollTop = menuItem.offsetTop - menuRef.current.offsetTop;
            }
            if (isSingleSelection) {
                handleClick(undefined, legendItems[newIndex]);
            } else {
                handleHover(legendItems[newIndex], false);
            }
        }

        setArrowSelectionIndex(newIndex);
    };

    const handleSearch = (query: string) => {
        let filteredItems = seriesList;
        if (query) {
            // Filter out irrelevant items
            const items = query
                ? fuzzysort
                      .go(query, seriesList, {
                          key: 'name',
                          threshold: -10000,
                      })
                      .map((i) => i.obj)
                : seriesList;

            // Keep the list order
            filteredItems = seriesList.filter((series) => items.includes(series));

            // Hide all series items (later on we'll show only the relevant ones)
            seriesList.forEach((series) => series.setVisible(false, false));
        }

        // Show all relevant series items and update legend
        filteredItems.forEach((series) => series.setVisible(true, false));
        redraw();
        updateLegend(filteredItems);
    };

    return (
        <div
            className={mergeClasses(styles.container, horizontal ? styles.horizontalContainer : '')}
            data-testid="interactive-legend"
        >
            <MenuList ref={menuRef} className={mergeClasses(styles.list, horizontal ? styles.horizontalList : '')}>
                {legendItems.map((series, index) => (
                    <MenuItem
                        key={series.name}
                        className={mergeClasses(
                            styles.legendItem,
                            !series.visible ? styles.itemDisabled : '',
                            arrowSelectionIndex === index ? styles.selectedSeries : ''
                        )}
                        onClick={(e) => handleClick(e, series)}
                        onMouseEnter={() => handleHover(series)}
                        onMouseLeave={() => handleHover(undefined)}
                        // Accessibility - keyboard navigation hovering
                        onFocus={() => handleHover(series)}
                        onBlur={() => handleHover(undefined)}
                    >
                        <span
                            className={styles.seriesSymbol}
                            style={{ color: series.visible ? series.color : 'inherit' }}
                        >
                            {convertSymbolStrToShape(series.symbol)}
                        </span>
                        <span className={styles.seriesName}>{series.name}</span>
                    </MenuItem>
                ))}
            </MenuList>
            {showActions && (
                <div className={styles.actionsBar}>
                    <InteractiveActions
                        ref={actionsRef}
                        onInvertClick={handleInvertClicked}
                        onArrowClick={handleArrowClicked}
                        onSearch={handleSearch}
                        strings={strings}
                    />
                    {horizontal && (
                        <ScrollControls containerRef={menuRef} visibleItems={legendItems.length} strings={strings} />
                    )}
                </div>
            )}
        </div>
    );
};

export function isMacOs(): boolean {
    return window.navigator.userAgent.toLowerCase().indexOf('mac') > -1;
}

export function convertSeriesListToLegendItems(seriesList: ChartSeries[]): LegendItem[] {
    return (seriesList as ExtendedSeries[]).map((series) => ({
        name: series.name,
        symbol: series.symbol,
        color: series.color,
        visible: series.visible,
    }));
}

/** Returns the next or previous valid index */
function calcUpdatedIndex(currentIndex: number | null, numOfItems: number, isUp: boolean): number {
    if (currentIndex === null) {
        // No selected index - select the first item in the list
        return 0;
    } else if (isUp) {
        // Arrow up was clicked - select the previous item (or the first item if the current one is already the first).
        return currentIndex - 1 >= 0 ? currentIndex - 1 : 0;
    } else {
        // Arrow down was clicked - select the next item (or the last item if the current one is already the last).
        return currentIndex + 1 < numOfItems ? currentIndex + 1 : numOfItems - 1;
    }
}

/** Returns a validation function that checks if an index is within the specified range */
function buildRangeChecker(indexA: number, indexB: number) {
    const startIndex = Math.min(indexA, indexB);
    const endIndex = Math.max(indexA, indexB);
    return (indexToCheck: number) => indexToCheck >= startIndex && indexToCheck <= endIndex;
}

/** Checks if only one item is visible, and if so, determine if it was clicked */
function isSingleSelectionClicked(clickedItem: LegendItem, items: LegendItem[]) {
    const visibleItems = items.filter((item) => item.visible);
    return visibleItems.length === 1 && visibleItems[0] === clickedItem;
}

function isSeriesListValid(seriesList: (ChartSeries | undefined)[]) {
    return seriesList.every((series) => series?.options);
}
