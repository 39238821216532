import { getRangeHtml } from '@kusto/monaco-kusto';
import juice from 'juice';
import { editor } from 'monaco-editor/esm/vs/editor/editor.api';

import { MonacoRange } from '../../stores';

export type ModelsGetter = () => editor.ITextModel[];
export type RangeHtmlGetter = (model: editor.ITextModel, range: MonacoRange) => string;
export type GetQueryInContextHtml = (queryHtmlContext: QueryHtmlContext) => string | undefined;

const modelsGetter = () => editor.getModels();
const rangeHtmlGetter = (model: editor.ITextModel, range: MonacoRange) => getRangeHtml(model, range);

export interface QueryHtmlContext {
    editorModelPath: string;
    queryRange?: MonacoRange;
    queryText?: string;
}

// The returned HTML from Monaco-Kusto contains class names without the actual style values,
// so copy-pasting it will not include these styles. Therefore, the CSS styles are inlined,
// and the result is wrapped with Monaco's styled div to preserve font styling (font family and size).
export function getQueryInContextHtml(queryHtmlContext: QueryHtmlContext): string {
    return getQueryInContextHtmlCreator(modelsGetter, rangeHtmlGetter)(queryHtmlContext);
}

export function getQueryInContextHtmlCreator(modelsGetter: ModelsGetter, rangeHtmlGetter: RangeHtmlGetter) {
    return (queryHtmlContext: QueryHtmlContext) => {
        const linesClassBasedHtml = getMonacoLinesClassBasedHtml(queryHtmlContext, modelsGetter, rangeHtmlGetter);
        const fallbackValue = queryHtmlContext.queryText ?? '';
        return linesClassBasedHtml ? inlineMonacoStylesInHtml(linesClassBasedHtml) : fallbackValue;
    };
}

function getMonacoLinesClassBasedHtml(
    queryHtmlContext: QueryHtmlContext,
    modelsGetter: ModelsGetter,
    rangeHtmlGetter: RangeHtmlGetter
) {
    const { queryRange, editorModelPath } = queryHtmlContext;
    const models = modelsGetter();
    const model = models.find((m) => m.uri.path === editorModelPath);
    if (!model || !queryRange) return;

    return rangeHtmlGetter(model, queryRange);
}

function inlineMonacoStylesInHtml(html: string) {
    const wrappedHtml = wrapWithStyledDiv(html);
    const monacoStyles = getMonacoStyles();

    return juice(wrappedHtml, { extraCss: monacoStyles });
}

function getMonacoStyles() {
    const stylesheet = document.getElementsByClassName('monaco-colors');
    return Array.from(stylesheet)
        .map((sheet) => sheet.innerHTML)
        .join(' ');
}

function wrapWithStyledDiv(html: string) {
    const style =
        "font-family: Menlo, Monaco, 'Courier New', monospace; font-weight: normal; font-size: 12px; line-height: 18px; letter-spacing: 0px;";
    return `<div style="${style}">${html}</div>`;
}
