import type { FeatureFlags } from '@kusto/app-common';
import { IArmClient, IKustoClient, IKustoClientAuthProvider, KustoDomains } from '@kusto/client';
import { KweRtdVisualContext } from '@kusto/rtd-provider';
import { ABORTED, Aborted, IKweTelemetry, KweException, ok, Ok } from '@kusto/utils';
import type { VisualFwkFeatureFlag } from '@kusto/visual-fwk';

import type { KustoEditorHandle } from '../components/KustoEditor';
import type { QueryFeatureFlag } from '../featureFlags';
import { QueryRootStoreSnapshotIn, RootStore } from '../stores/rootStore';
import { QueryMstEnv, QueryStoreEnv } from '../stores/storeEnv';
import { QueryConfiguration } from './config';
import { QueryCore } from './core';
import { createParsedVisuals } from './staticParsedVisuals';
import type { QueryLocale } from './strings';

export class KustoEditorHandleContainer {
    promiseResolvers: Set<(value: Ok<KustoEditorHandle> | Aborted) => void> = new Set();

    ref: KustoEditorHandle | undefined = undefined;

    onChange: ((editor: KustoEditorHandle | undefined) => void) | undefined = undefined;

    constructor(abortSignal?: AbortSignal) {
        abortSignal?.addEventListener('abort', () => {
            for (const resolver of this.promiseResolvers) {
                resolver(ABORTED);
            }
        });
    }

    promise(signal?: AbortSignal): Promise<Ok<KustoEditorHandle> | Aborted> {
        if (this.ref) {
            return Promise.resolve(ok(this.ref));
        }

        return new Promise((resolve) => {
            if (!signal) {
                this.promiseResolvers.add(resolve);
                return;
            }

            const onResolved = (value: Ok<KustoEditorHandle> | Aborted) => {
                signal.removeEventListener('abort', onAborted);
                this.promiseResolvers.delete(onResolved);
                resolve(value);
            };

            const onAborted = () => onResolved(ABORTED);

            signal.addEventListener('abort', onAborted);
            this.promiseResolvers.add(onResolved);
        });
    }

    set(editor: KustoEditorHandle, abortSignal: AbortSignal) {
        if (this.ref !== undefined) {
            throw new KweException();
        }

        this.ref = editor;

        this.onChange?.(editor);

        for (const resolver of this.promiseResolvers) {
            resolver(ok(editor));
        }

        abortSignal.addEventListener('abort', () => {
            if (this.ref === undefined) {
                throw new KweException();
            }
            this.ref = undefined;
            this.onChange?.(undefined);
        });
    }
}

// Keep it in separate file, as it use the concrete rootStore obj, and might cause cyclic dependency or store initialization in wrong order
//

// Setup default query core (create default mobx state tree store)
// use it if you don't extend the mobx state tree store
export function setupDefaultQueryCore(
    kustoDomains: KustoDomains,
    kustoClient: IKustoClient,
    telemetry: IKweTelemetry,
    storeInitState: QueryRootStoreSnapshotIn,
    config: QueryConfiguration,
    authProvider: IKustoClientAuthProvider,
    featureFlags: FeatureFlags<QueryFeatureFlag>,
    visualFwkFlags: FeatureFlags<VisualFwkFeatureFlag>,
    strings: QueryLocale,
    publicRtdPlotlyUrl: KweRtdVisualContext['publicRtdPlotlyUrl'],
    securePlotlyIframe: KweRtdVisualContext['securePlotlyIframe'],
    armClient?: IArmClient
): QueryCore {
    const kustoEditor = new KustoEditorHandleContainer();
    const queryEnv: QueryStoreEnv = {
        kustoEditor,
        kustoDomains,
        kustoClient,
        telemetry,
        featureFlags,
        config,
        authProvider,
        strings,
        armClient: armClient,
    };

    const storeEnv: QueryMstEnv = { queryEnv };
    const store = RootStore.create(storeInitState, storeEnv);

    return {
        ...queryEnv,
        store,
        parsedVisuals: createParsedVisuals(
            store,
            strings,
            telemetry,
            config.azureMapToken,
            publicRtdPlotlyUrl,
            securePlotlyIframe,
            visualFwkFlags
        ),
    };
}
