@use '@kusto/style/tokens';

.treeLayoutCursor {
    &:hover {
        cursor: default;
    }
    :global(.fui-TreeItemLayout__expandIcon) {
        &:hover {
            cursor: pointer;
            color: tokens.$colorBrandBackground !important;
        }
    }
}

.treeItem__dividerAbove {
    border-top: 1px solid tokens.$colorNeutralStroke2 !important;
}

.selectedTreeItem {
    background-color: tokens.$colorBrandStroke2;
    border-radius: 4px;
    &:hover {
        background-color: tokens.$colorBrandBackground2Hover !important;
    }
}
.selectedItem {
    border: 2px solid tokens.$colorBrandForegroundInverted;
    border-radius: 4px;
    height: 35px !important;
}
.notSelectedTreeItem {
    border: 2px solid transparent;
    border-radius: 4px;
}
.hoverTreeItem {
    &:hover {
        border-radius: 4px;
    }
}
.treeItemLayout {
    display: flex;
    align-self: center;
    width: 100%;
}
.unHoverable {
    &:hover {
        background-color: transparent !important;
    }
}
.treeItemIconBefore {
    display: flex;
    align-items: center;
    min-width: 24px !important;
    padding: 0px !important;
    display: flex;
    align-items: center;
}
.treeItemLayoutMain {
    overflow: hidden;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.entityNameTooltipContent {
    max-width: 200;
    word-break: break-all;
}

// The following class is used to make the text un-selectable
.unSelectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    /*
      Introduced in IE 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
    -ms-user-select: none;
    user-select: none;
}

.description {
    font-size: 10px;
    line-height: 14px;
    color: tokens.$colorNeutralForeground2;
}
