import React from 'react';
import { Text } from '@fluentui/react';
import { observer } from 'mobx-react-lite';

import { DataFrameSchema, formatLiterals } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';

import type { RtdProviderLocale } from '../../../i18n';
import { getTypeError } from './lib';

export function rowId(configId: number) {
    return `visualOptions--tableRenderLinks--${configId}`;
}

export interface TableRenderLinkRowProps {
    id: number;
    config: Fwk.TableRenderLinkConfig;
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
    tableSchemaResult: DataFrameSchema;
    t: RtdProviderLocale;
}

export const TableRenderLinkRow: React.FC<TableRenderLinkRowProps> = observer(function TableRenderLinkRow({
    id,
    onEdit,
    onDelete,
    config,
    tableSchemaResult,
    t,
}) {
    let primaryLabel: string;

    const errors: string[] = [];
    let maxErrorLevel: Fwk.QueryMessageLevel = 'warn';

    const titleText: string[] = [];

    if (config.urlColumn && config.displayColumn) {
        primaryLabel = `${config.urlColumn}: ${config.displayColumn}`;
    } else if (!config.urlColumn && config.displayColumn) {
        primaryLabel = formatLiterals(t.rtdProvider.visuals.table.renderLinks.missingValueColumnRowLabel, {
            displayColumn: config.displayColumn,
        });
        errors.push(t.rtdProvider.visuals.table.renderLinks.cannotApplyWithMissingUrlColumn);
    } else if (config.urlColumn && !config.displayColumn) {
        primaryLabel = config.urlColumn;
    } else {
        primaryLabel = t.rtdProvider.visuals.table.renderLinks.noSelectionLabel;
    }

    titleText.push(primaryLabel);

    const typeError = getTypeError(t, config, tableSchemaResult);
    if (typeError) {
        errors.push(typeError);
        maxErrorLevel = 'error';
    }

    if (config.disabled) {
        titleText.push(`(${t.rtdProvider.utilStatus.disabled})`);
    }

    return (
        <Fwk.ConfigurationItemRow<number>
            id={rowId(id)}
            item={id}
            onDelete={onDelete}
            onEdit={onEdit}
            editButtonTitle={t.rtdProvider.utilButtons.edit}
            deleteButtonTitle={t.rtdProvider.utilButtons.delete}
        >
            <Fwk.ConfigurationItemRowContent title={titleText.join('\n')} disabled={config.disabled}>
                <Text>{primaryLabel}</Text>
                {errors.length !== 0 && <Fwk.ConfigurationItemRowErrors warnings={errors} level={maxErrorLevel} />}
            </Fwk.ConfigurationItemRowContent>
        </Fwk.ConfigurationItemRow>
    );
});
