import type { ColumnType, NumericColumnType } from '@kusto/client';
import type { KustoDataType } from '@kusto/utils';

export const standardSizes = {
    defaultSize: { width: 9, height: 7 },
    minimumSize: { width: 4, height: 4 },
};

export const defaultLayout = [
    'table',
    {
        label: 'Bar chart',
        iconName: 'BarChartHorizontal',
        default: 'bar',
        variants: ['bar', 'stackedbar', 'stacked100bar'],
    },
    {
        label: 'Column chart',
        iconName: 'BarChartVertical',
        default: 'column',
        variants: ['column', 'stackedcolumn', 'stacked100column'],
    },
    {
        label: 'Area chart',
        iconName: 'AreaChart',
        default: 'area',
        variants: ['area', 'stackedarea', 'stacked100area'],
    },
    'line',
    'card',
    'multistat',
    'pie',
    'scatter',
    'timechart',
    'anomalychart',
    'funnel',
    'map',
    'markdown',
    'heatmap',
    'plotly',
];

export const visualsWithVariants: ReadonlySet<string> = new Set(
    defaultLayout.flatMap((item) => (typeof item === 'object' ? item.variants : []))
);

export const COLUMN_INTERACTION_ID = 'column' as const;
export const COLUMN_SERIES_X_PROPERTY = 'series-x';
export const COLUMN_SERIES_Y_PROPERTY = 'series-y';
export const DRAG_INTERACTION_ID = 'dragX' as const;
export const DRAG_TIME_RANGE_PROPERTY_ID = 'timeRange' as const;
export const DRAG_START_PROPERTY_ID = 'start' as const;
export const DRAG_END_PROPERTY_ID = 'end' as const;

/**
 * @deprecated Use `KUSTO_NUMERIC_TYPES` directly from @kusto/utils
 */
export const NUMERIC_DATA_TYPE: NumericColumnType[] = ['int', 'real', 'long', 'decimal'];

// TODO: Mark this as readonly once we update callers of `getFirstColumnOfTypeVFormat` to `getFirstFieldOfType`
export const STRING_DATA_TYPE: KustoDataType[] = ['string'];

/**
 * @deprecated Use `KUSTO_TIME_TYPES` directly from @kusto/utils
 */
export const TIME_DATA_TYPE: ColumnType[] = ['datetime', 'timespan'];
