import type { ReadonlyRecord } from '@kusto/utils';

export const COLORS_FOR_PALETTE = {
    BLUE: 'blue',
    GREEN: 'green',
    PURPLE: 'purple',
    ORANGE: 'orange',
    PINK: 'pink',
    YELLOW: 'yellow',
} as const;

export type ColorPaletteKey = (typeof COLORS_FOR_PALETTE)[keyof typeof COLORS_FOR_PALETTE];

/**
 * It is very important that the length of this Palette equals the length of
 * COLOR_STOPS because it's used to generate the tuple colors for highcharts
 */
export type FullPaletteColors = [string, string, string, string, string, string, string, string];
export const COLOR_STOPS = [0, 0.2, 0.35, 0.5, 0.62, 0.75, 0.9, 1] as const;

const BluePalette: FullPaletteColors = [
    '#EFF6FC',
    '#DEECF9',
    '#C7E0F4',
    '#2B88D8',
    '#0078D4',
    '#106EBE',
    '#005A9E',
    '#004578',
];
const GreenPalette: FullPaletteColors = [
    '#CAEAD8',
    '#A0D8B9',
    '#55B17E',
    '#218D51',
    '#107C41',
    '#0F703B',
    '#0C5F32',
    '#094624',
];

const PurplePalette: FullPaletteColors = [
    '#E6D1F2',
    '#D1ABE6',
    '#A864CD',
    '#862EB5',
    '#7719AA',
    '#6C179A',
    '#5B1382',
    '#430E60',
];
const OrangePalette: FullPaletteColors = [
    '#F6DBD4',
    '#EDBCB0',
    '#DC816A',
    '#CB5031',
    '#C43E1C',
    '#B13719',
    '#952F15',
    '#6E220F',
];

const PinkPalette: FullPaletteColors = [
    '#F4D2DC',
    '#EBAEBF',
    '#D76787',
    '#C52E59',
    '#BC1948',
    '#AA1640',
    '#8F1336',
    '#6A0E28',
];

const YellowPalette: FullPaletteColors = [
    '#FDF6D7',
    '#FBEEB5',
    '#F7DE6D',
    '#F4CF2C',
    '#F2C811',
    '#DAB50F',
    '#A98C0C',
    '#7A6816',
];

export const ColorPaletteThemes: Record<ColorPaletteKey, FullPaletteColors> = {
    [COLORS_FOR_PALETTE.BLUE]: BluePalette,
    [COLORS_FOR_PALETTE.GREEN]: GreenPalette,
    [COLORS_FOR_PALETTE.PURPLE]: PurplePalette,
    [COLORS_FOR_PALETTE.ORANGE]: OrangePalette,
    [COLORS_FOR_PALETTE.PINK]: PinkPalette,
    [COLORS_FOR_PALETTE.YELLOW]: YellowPalette,
} as const;

export type PreviewPaletteColors = [string, string, string];

function getPreviewPalette(fullPaletteColors: FullPaletteColors): PreviewPaletteColors {
    const middleIndex = Math.floor(fullPaletteColors.length / 2);
    return [fullPaletteColors[0], fullPaletteColors[middleIndex], fullPaletteColors[fullPaletteColors.length - 1]];
}

// Smaller subset of the shades so that
// we can preview it inside small spaces
// (e.g. as a Dropdown option)
export const ColorsPaletteThemesPreview = Object.fromEntries(
    Object.entries(ColorPaletteThemes).map(([color, fullPaletteColors]) => [
        color,
        getPreviewPalette(fullPaletteColors),
    ])
) as Record<ColorPaletteKey, PreviewPaletteColors>;

export const colorPaletteThemeMap = Object.fromEntries(
    Object.entries(ColorPaletteThemes).map(([color, fullPaletteColors]) => [
        color,
        {
            full: fullPaletteColors,
            preview: ColorsPaletteThemesPreview[color as ColorPaletteKey],
        },
    ])
) as ReadonlyRecord<ColorPaletteKey, { full: FullPaletteColors; preview: PreviewPaletteColors }>;
