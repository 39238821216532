import { useFieldContext_unstable } from './FieldContext';
export function useFieldControlProps_unstable(props, options) {
    return getFieldControlProps(useFieldContext_unstable(), props, options);
}
/**
 * @internal
 * Implementation of useFieldControlProps_unstable.
 * Split out so it can be used directly in renderField_unstable.
 */ export function getFieldControlProps(context, props, options) {
    if (!context) {
        return props;
    }
    // Create a copy of props so we don't modify the original
    props = {
        ...props
    };
    const { generatedControlId, hintId, labelFor, labelId, required, validationMessageId, validationState } = context;
    if (generatedControlId) {
        var _props;
        var _id;
        (_id = (_props = props).id) !== null && _id !== void 0 ? _id : _props.id = generatedControlId;
    }
    // Set aria-labelledby if the control doesn't support label.htmlFor, or if the label's htmlFor doesn't refer
    // to this control (i.e. the user set this control's id prop without also setting the Field's label.htmlFor).
    if (labelId && (!(options === null || options === void 0 ? void 0 : options.supportsLabelFor) || labelFor !== props.id)) {
        var _props1, _arialabelledby;
        var _;
        (_ = (_props1 = props)[_arialabelledby = 'aria-labelledby']) !== null && _ !== void 0 ? _ : _props1[_arialabelledby] = labelId;
    }
    // The control is described by the validation message, or hint, or both.
    // We also preserve and append any aria-describedby from props.
    // For reference: https://github.com/microsoft/fluentui/pull/25580#discussion_r1017259933
    if (validationMessageId || hintId) {
        // NOTE: Not using ??= since we're merging and overriding the user-provided value.
        props['aria-describedby'] = [
            validationMessageId,
            hintId,
            props === null || props === void 0 ? void 0 : props['aria-describedby']
        ].filter(Boolean).join(' ');
    }
    if (validationState === 'error') {
        var _props2, _ariainvalid;
        var _1;
        (_1 = (_props2 = props)[_ariainvalid = 'aria-invalid']) !== null && _1 !== void 0 ? _1 : _props2[_ariainvalid] = true;
    }
    if (required) {
        if (options === null || options === void 0 ? void 0 : options.supportsRequired) {
            var _props3;
            var _required;
            (_required = (_props3 = props).required) !== null && _required !== void 0 ? _required : _props3.required = true;
        } else {
            var _props4, _ariarequired;
            var _2;
            (_2 = (_props4 = props)[_ariarequired = 'aria-required']) !== null && _2 !== void 0 ? _2 : _props4[_ariarequired] = true;
        }
    }
    // Include the size prop if this control supports it
    if (options === null || options === void 0 ? void 0 : options.supportsSize) {
        var _props5;
        var _size;
        (_size = (_props5 = props).size) !== null && _size !== void 0 ? _size : _props5.size = context.size;
    }
    return props;
}
