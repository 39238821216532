import React from 'react';
import { DefaultButton, Dialog, DialogFooter, PrimaryButton, Stack } from '@fluentui/react';

import { formatLiterals } from '@kusto/utils';

import { dependencies } from '../../dependencies';

interface Props {
    tenantId: string;
    onClicked: (loginIntoTenant: boolean) => void;
}

export const ConfirmLoginWithTenant: React.FC<Props> = (props: Props) => {
    const title = dependencies.strings.kwe$confirmLoginWithTenant$title;
    const message = (tenantId: string) =>
        formatLiterals(dependencies.strings.kwe$confirmLoginWithTenant$message, { tenantId });
    const continueButtonText = dependencies.strings.kwe$confirmLoginWithTenant$primaryButton;
    const cancelButtonText = dependencies.strings.kwe$confirmLoginWithTenant$cancelButton;

    const modalProps = {
        titleAriaId: title,
        subtitleAriaId: message(props.tenantId),
        isBlocking: true,
    };

    const dialogContentProps = {
        title: title,
        subText: message(props.tenantId),
    };

    return (
        <Dialog hidden={false} modalProps={modalProps} dialogContentProps={dialogContentProps}>
            <DialogFooter>
                <Stack horizontal={false} horizontalAlign="stretch" tokens={{ childrenGap: '10px' }}>
                    <PrimaryButton onClick={() => props.onClicked(true)} text={continueButtonText} />
                    <DefaultButton onClick={() => props.onClicked(false)} text={cancelButtonText} />
                </Stack>
            </DialogFooter>
        </Dialog>
    );
};
