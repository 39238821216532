import React from 'react';
import { Dropdown, IDropdownOption, IDropdownProps } from '@fluentui/react';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import * as Fwk from '@kusto/visual-fwk';

import { ColorPaletteKey, ColorPaletteThemes } from '../colors';

import * as styles from './styles.module.scss';

interface ColorPalettePreviewProps {
    className: string;
    colors: undefined | string[];
}

const ColorPalettePreview: React.FC<ColorPalettePreviewProps> = ({ className, colors }) => {
    if (!colors) {
        return null;
    }

    return (
        <div className={className} role="presentation">
            {colors.map((color) => (
                <div key={color} style={{ backgroundColor: color }} />
            ))}
        </div>
    );
};

export interface ColorPaletteDropdownOption extends IDropdownOption {
    data: {
        previewColors: string[];
    };
}

const onRenderOption: IDropdownProps['onRenderOption'] = (option) => {
    if (!option) {
        return null;
    }

    const opt = option as ColorPaletteDropdownOption;

    return (
        <div key={opt.key} className={styles.dropdownOption}>
            <ColorPalettePreview className={styles.partialPreviewContainer} colors={opt.data.previewColors} />
            <span>{opt.text}</span>
        </div>
    );
};

const KEY: Fwk.VisualOptionKey = 'heatMap__colorPaletteKey';

const valueSelector: Fwk.VisualSelector<typeof KEY, string> = (o) => {
    return o.get(KEY);
};

const applyChange = mobx.action((model: Fwk.VisualInputModel<typeof KEY>, option: undefined | IDropdownOption) => {
    if (!option) {
        return;
    }

    model.set(KEY, option.key as ColorPaletteKey);
});

export function colorPaletteInput<C extends Fwk.VisualOptionKey>(
    key: C,
    label: string,
    options: ColorPaletteDropdownOption[]
): Fwk.VisualInput<C> {
    return {
        id: key,
        keys: [key],
        Component: observer(function EtpVisualOptionsColorPaletteDropdown({ disabled, model }) {
            const currentColorKey = model.resolveSelector(valueSelector).get() as ColorPaletteKey;
            const onChange: IDropdownProps['onChange'] = (_, option) => applyChange(model, option);

            return (
                <>
                    <Dropdown
                        className={Fwk.tileInputClassNames.basicInput}
                        selectedKey={currentColorKey}
                        options={options as IDropdownOption[]}
                        onChange={onChange}
                        label={label}
                        disabled={disabled}
                        onRenderOption={onRenderOption}
                    />
                    <ColorPalettePreview
                        className={styles.fullPreviewContainer}
                        colors={ColorPaletteThemes[currentColorKey]}
                    />
                </>
            );
        }),
    };
}
