// We define the dialog sizes according to the dialog size; with 'container-queries'.
// Unlike the 'media-queries', the 'container-queries' are not based on the screen size, but on the size of the parent container.

.dataExplorationCard {
    container-type: inline-size;
    container-name: data-exploration;
}

// Large screen (desktops)
@container data-exploration (800px <= width) {
    .rightPane {
        --right-pane-width: 320px;
    }
}

// Medium screen (tablets/laptops)
@container data-exploration (480px <= width) and (width < 800px) {
    .rightPane {
        --right-pane-width: 300px;
    }
}

// Small screen (phones)
@container data-exploration (width < 480px) {
    .rightPane {
        --right-pane-width: 180px;
    }
}

.dataExploration {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacingVerticalM);

    .dataExplorationCard {
        height: 100%;
        display: flex;
        background: var(--colorNeutralBackground1);
        border-radius: var(--borderRadiusXLarge);
        box-shadow: var(--shadow4);
        overflow: hidden;

        .mainSection {
            height: 100%;
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .pillsSection {
                height: 64px;
                min-height: 64px; // prevent shrinking
                border-bottom: 1px solid var(--colorNeutralStroke1);
            }
            .splitPanePanel {
                height: 100%;
                overflow: hidden;
            }
        }
        .rightPane {
            background: var(--colorNeutralBackground3);
            width: var(--right-pane-width);
            border-left: 1px solid var(--colorNeutralStroke1);
        }
    }
}
