@use '@kusto/style/tokens';

$row-height: 60px;

$button-total-vertical-padding: 22px;
// Button has a total of 23px above and below the text. It's recommended for
// containers to remove all but 4px of this
$button-recommended-removed-spacing: $button-total-vertical-padding - 4px;

.addConfigItemButton {
    display: flex;
    align-items: center;
    height: $row-height;
    margin-left: 0;
    padding: 0 4px 0 6px;
}

$marginAdjustment: calc(-1 * $button-recommended-removed-spacing);

.addConfigItemButtonMarginAdjust {
    margin-top: $marginAdjustment;
    margin-bottom: calc($marginAdjustment + 8px);
}

.configItemTooltip {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: 'nowrap';
}

.configItem {
    border: solid var(--border-color);
    border-width: 0 0 1px 0;
    height: $row-height;
    box-sizing: border-box;
    padding-left: 12px;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.configItemText {
    flex: 1 1 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    > *:nth-child(2) {
        color: var(--neutralSecondary);
    }
}

.configurationList {
    display: flex;
    flex-direction: column;
}

// error/warning icon
.tooltipHost {
    // Without the flex config there's a little bit of whitespace below the icon because of auto-layout
    display: flex;
    justify-content: center;
    grid-area: icon;
    &.warning {
        color: var(--yellowDark);
    }
    &.error {
        color: var(--redDark);
    }
    // Match buttons width
    text-align: center;
    width: 32px;
    font-size: 16px;
}

.warningsList {
    list-style: none;
    padding: 0;
}

.itemRowContent {
    display: grid;

    // TODO(parcel): Break out into grid.scss?
    // Only need to break out area="icon"
    grid-template-areas: 'text icon' // TODO: no usage of area="text" or area="sub-text" so far so can we remove?
        'sub-text icon';

    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;

    // Placing items by index
    grid-auto-flow: column;

    align-items: center;

    > span {
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.disabled > span {
        color: var(--disabledBodyTextColor);
    }
}

.withMargin {
    left: -18px !important;
}
