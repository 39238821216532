import type { KustoClientLocale } from '@kusto/client';
import type { KweUtilsLocale } from '@kusto/utils';
import type { KweVisualFwkLocale } from '@kusto/visual-fwk';
import type { VisualizationsLocale } from '@kusto/visualizations';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type RtdProviderStrings = typeof import('../strings.json');

export interface RtdProviderLocale extends KweVisualFwkLocale, VisualizationsLocale, KweUtilsLocale, KustoClientLocale {
    readonly rtdProvider: RtdProviderStrings;
}
