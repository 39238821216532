import React, { forwardRef, useState } from 'react';
import {
    Body1Stronger,
    InteractionTag,
    InteractionTagPrimary,
    InteractionTagSecondary,
    OverflowItem,
    TagAppearance,
    Tooltip,
} from '@fluentui/react-components';

import { Icon, renderIconIfDefined } from '@kusto/ui-components';

import { useStrings } from '../../../hooks/useStrings';
import { PillTooltip } from './PillTooltip';

import styles from './../PillsBar.module.scss';

export interface TagPillProps {
    /** Pill text */
    text: JSX.Element | string;
    /** Pill text appear in bold */
    bold?: boolean;
    /** Description of the KQL that appear in the tooltip */
    kqlDescription: string;
    /** KQL code that appear in the tooltip */
    kqlText: string;
    /** Icon to display before the pill text */
    icon?: Icon;
    /** If readonly, then the pill can't be edited or removed (defaults to `true`) */
    readonly?: boolean;
    /** Tag appearance (defaults to `filled`) */
    appearance?: TagAppearance;
    /** Overflow manager props */
    overflow: {
        /** Used by the overflow manager, to identify an item. */
        id: string;
        /** Used by the overflow manager. Higher priority means the item overflows later. */
        priority?: number;
    };
    /** Click handler for the pill */
    onClick?: () => void;
}

/** Fluent Tag with Pill data wrapped inside Tooltip and OverflowItem */
export const TagPill = forwardRef<HTMLButtonElement, TagPillProps>(
    ({ overflow, text, bold, kqlDescription, kqlText, icon, readonly = true, appearance = 'filled', onClick }, ref) => {
        const { strings } = useStrings();

        const [tooltipVisible, setTooltipVisible] = useState(false);

        return (
            <OverflowItem id={overflow.id} priority={overflow.priority}>
                <Tooltip
                    onVisibleChange={(e, { visible }) => setTooltipVisible(visible)}
                    content={{
                        // We render inside the tooltip a MonacoEditor. The tooltip would be added to the DOM immediately once the dialog is opened.
                        // This would cause the MonacoEditor to be rendered beforehand, which crashes the app.
                        // This is a workaround, rendering the tooltip only when needed (when visible is true).
                        children: tooltipVisible ? (
                            <PillTooltip icon={icon} readableText={kqlDescription} kqlText={kqlText} />
                        ) : null,
                        className: styles.pillTooltipSurface,
                    }}
                    relationship="inaccessible"
                    withArrow
                >
                    <InteractionTag className={styles.pill} shape="circular" appearance={appearance}>
                        <InteractionTagPrimary
                            icon={renderIconIfDefined(icon)}
                            onClick={() => !readonly && onClick?.()}
                            hasSecondaryAction={!readonly}
                            style={{ cursor: readonly ? 'text' : 'pointer' }}
                            className={styles.pillButton}
                            aria-label={kqlDescription}
                            ref={ref} // ref used for pill-popover positioning
                            data-testid="pill"
                        >
                            {bold ? <Body1Stronger>{text}</Body1Stronger> : text}
                        </InteractionTagPrimary>
                        {!readonly ? (
                            <InteractionTagSecondary
                                aria-label={strings.dataExplorationModal.pillsBar.dismissPillAriaLabel}
                            />
                        ) : null}
                    </InteractionTag>
                </Tooltip>
            </OverflowItem>
        );
    }
);
