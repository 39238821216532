import React from 'react';
import { Drawer } from '@fluentui/react-components/unstable';
import { ColorString } from 'highcharts';
import { observer } from 'mobx-react-lite';

import {
    DataExplorationContext,
    DataExplorationContextProvider,
    DataProfileContent,
    DataProfileErrorBoundary,
    DataProfileTimeRange,
} from '@kusto/data-exploration';
import { escapeNameIfNecessary, Theme } from '@kusto/utils';

import { useQueryCore } from '../../core/core';
import { Cluster } from '../../stores';
import { DataProfileNavigation } from './DataProfileNavigation/DataProfileNavigation';

import styles from './DataProfilePanel.module.scss';

interface DataProfilePanelProps {
    isOpen: boolean;
    onClose(): void;
    graphMainColor?: ColorString;
    /**
     * to override the default blue highcharts color for graphs we need to pass a color string available in JS.
     * Fluent colors are not available to JS, tokens are strings with "var(--tokenName)".
     * Acceptable values are hex (e.g. #123456) or (e.g. rgba(255,255,255,0.3))
     *
     * Highcharts can parse these and manipulate them for gradients and such.
     */
    graphFillColor?: ColorString;
    getClusterDisplayName?: (cluster: Cluster) => string | null;
    showDataGridTab?: boolean;
    showKqlExamplesTab?: boolean;
}

export const DataProfilePanel: React.FC<DataProfilePanelProps> = observer(function DataProfilePanel({
    isOpen,
    onClose,
    graphMainColor,
    graphFillColor,
    getClusterDisplayName = (c: Cluster) => c.getAlias(),
    showDataGridTab,
    showKqlExamplesTab,
}) {
    const core = useQueryCore();
    const { telemetry, kustoDomains, kustoClient } = core;
    const [selectedTimeFilter, setSelectedTimeFilter] = React.useState<DataProfileTimeRange>({
        type: 'filterDisabled',
    });
    const { cluster, database, selectedTable, viewMode, databaseTables, setSelectedTable, selectedTableColumns } =
        core.store.dataProfile;
    if (!cluster || !database || !selectedTable) {
        return null; //TODO error state
    }
    const databaseName = database.getDisplayName();
    const clusterUrl = cluster.clusterUrl;

    const context: DataExplorationContext = {
        clusterUrl: cluster?.clusterUrl,
        databaseName: database?.name,
        kustoClient,
        kustoDomains,
        telemetry,
        theme: core.store.settings.theme,
        t: core.strings,
        parsedVisuals: {}, // KweVisual is not use in data profile
        visualsLayout: [], // KweVisual is not use in data profile
    };

    return (
        <DataExplorationContextProvider context={context}>
            <Drawer
                type={viewMode}
                position="end"
                open={isOpen}
                separator
                size="large"
                className={viewMode === 'inline' ? styles.pinned : styles.panel}
                onOpenChange={(_, { open }) => {
                    if (open === false) {
                        onClose();
                    }
                }}
            >
                <DataProfileNavigation
                    clusterAlias={decodeURIComponent(getClusterDisplayName(cluster) ?? '')}
                    databaseName={databaseName}
                    databaseTables={databaseTables}
                    selectedTable={selectedTable}
                    viewMode={viewMode}
                    setViewMode={(mode) => core.store.dataProfile.setViewMode(mode)}
                    setSelectedTableId={(id) => setSelectedTable(id)}
                    onClose={onClose}
                />
                <DataProfileErrorBoundary
                    key={selectedTable.id}
                    fullSize
                    errorStateTitle={core.strings.query.dataProfile.errorStateTitle}
                >
                    <DataProfileContent
                        databaseName={databaseName}
                        clusterUrl={clusterUrl}
                        selectedTableName={escapeNameIfNecessary(selectedTable.name)}
                        selectedTableDocString={selectedTable.docstring}
                        columns={selectedTableColumns}
                        selectedTimeFilter={selectedTimeFilter}
                        setSelectedTimeFilter={setSelectedTimeFilter}
                        isDarkTheme={core.store.settings.theme === Theme.Dark}
                        graphMainColor={graphMainColor}
                        graphFillColor={graphFillColor}
                        timezone={core.store.settings.resolvedTimeZone}
                        showDataGridTab={!!showDataGridTab}
                        showKqlExamplesTab={!!showKqlExamplesTab}
                    />
                </DataProfileErrorBoundary>
            </Drawer>
        </DataExplorationContextProvider>
    );
});
