import * as React from 'react';
export function useTagGroupContextValues_unstable(state) {
    const { handleTagDismiss, size, appearance, dismissible, role } = state;
    return {
        tagGroup: React.useMemo(()=>({
                handleTagDismiss,
                size,
                appearance,
                dismissible,
                role
            }), [
            handleTagDismiss,
            size,
            appearance,
            dismissible,
            role
        ])
    };
}
