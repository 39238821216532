import './StyledReactSplitPane.scss';

import React from 'react';
import { IStyle, ITheme } from '@fluentui/react';
import { classNamesFunction, IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import ReactSplitPane, { SplitPaneProps } from 'react-split-pane/lib/SplitPane';

import { hexToRgb } from '@kusto/utils';

const getClassNames = classNamesFunction<ReactSplitPaneStyleProps, ReactSplitPaneStyles>();

type ReactSplitPaneStyleProps = Required<
    Pick<ReactSplitPaneProps, 'classPrefix' | 'theme' | 'withHeader' | 'allowResize' | 'hideResizer'>
>;

export interface ReactSplitPaneStyles {
    root: IStyle;
}

export interface ReactSplitPaneProps extends SplitPaneProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ReactSplitPaneStyleProps, ReactSplitPaneStyles>;
    withHeader?: boolean;
    children?: JSX.Element[];
    allowResize?: boolean;
    onResizeEnd?: (sizes: string[]) => void;
    classPrefix?: string;
    hideResizer?: boolean;
}

const getStyles = (props: ReactSplitPaneStyleProps): ReactSplitPaneStyles => {
    const { classPrefix, theme, withHeader, allowResize, hideResizer } = props;
    const { palette } = theme;
    // Added while enabling lints

    const { r, g, b } = hexToRgb(palette.black)!;
    return {
        root: {
            displayName: classPrefix,
            selectors: {
                '>div[data-type="Resizer"]': {
                    pointerEvents: allowResize ? 'auto' : 'none',
                    position: 'relative',
                },
                '>div[data-attribute="horizontal"][data-type="Resizer"]': {
                    cursor: 'ns-resize',
                    selectors: {
                        ':before': {
                            width: '100%',
                            height: 4,
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            boxShadow: `0px -4px 6px rgba(${r}, ${g}, ${b}, .42)`,
                        },
                    },
                },
                '>div[data-attribute="vertical"][data-type="Resizer"]': {
                    display: hideResizer ? 'none' : 'block',
                    opacity: 1,
                    background: `linear-gradient(rgba(${r}, ${g}, ${b}, 0.42), rgba(${r}, ${g}, ${b}, 0.42)) content-box no-repeat 2px 0 / 1px 100%`,
                    borderRightWidth: '3px !important',
                    borderLeftWidth: '3px !important',
                    cursor: 'ew-resize',
                    selectors: {
                        ':before': withHeader
                            ? {
                                  width: 5,
                                  height: 28,
                                  borderWidth: '8px 1px',
                                  borderStyle: 'solid',
                                  borderColor: palette.white,
                                  content: '""',
                                  position: 'absolute',
                                  top: 0,
                                  left: -1,
                              }
                            : { display: 'none' },
                        ':hover': {
                            borderLeftColor: 'transparent',
                            borderRightColor: 'transparent',
                            backgroundColor: ` rgba(${r}, ${g}, ${b}, 0.42)`,
                        },
                    },
                },
            },
        },
    };
};

export const StyledSplitPane = styled(
    ({ styles, theme, withHeader, allowResize, hideResizer, ...props }: ReactSplitPaneProps) => {
        // Added while enabling lints

        const classNames = getClassNames(styles!, {
            // Added while enabling lints

            theme: theme!,
            // Added while enabling lints

            withHeader: withHeader!,
            allowResize: !!allowResize,
            classPrefix: props.classPrefix ?? 'root',
            hideResizer: !!hideResizer,
        });
        return <ReactSplitPane {...props} className={classNames.root} />;
    },
    getStyles
);

/**
 * Closes the pane with animation. (brings width to 0 in 500 ms)
 * @param classNamePrefix should be unique and equal to props.classPrefix in StyledSplitPane.
 * @param onAnimationDone Triggered when animation is finished.
 */
export const closePaneWithAnimation = (classNamePrefix: string, onAnimationDone: () => void) => {
    const splitFirstChild: HTMLDivElement | null = document.querySelector(
        `[class*=${classNamePrefix}] > div:first-child`
    );
    if (splitFirstChild) {
        splitFirstChild.onanimationend = () => {
            onAnimationDone();
            if (splitFirstChild) {
                splitFirstChild.onanimationend = null;
                splitFirstChild.classList.remove('splitPaneAnimatedClose');
            }
        };
        splitFirstChild.classList.add('splitPaneAnimatedClose');
    }
};
