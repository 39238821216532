// Keep in sync with imports made in @kusto/monaco-kusto/esm/kusto.worker
import '@kusto/language-service/bridge.min';
import '@kusto/language-service/Kusto.JavaScript.Client.min';
import '@kusto/language-service/newtonsoft.json.min';
import '#languageServiceTypes';

import { assertNever, err, formatLiterals, IKweTelemetry, ok, ReadonlyRecord, Result } from '@kusto/utils';

export import DataItem = Kusto.Charting.DataItem;
export import ArgumentColumnType = Kusto.Charting.ArgumentColumnType;
export import ArgumentRestrictions = Kusto.Charting.ArgumentRestrictions;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
///@ts-ignore
export type ChartingStrings = typeof import('../strings.json');
export interface ChartingLocale {
    readonly charting: ChartingStrings;
}

// TODO: All these visualization options are duplicated in both @kusto/utils
// and @kusto/monaco-kusto. We should really define them in a single spot.

export type VisualizationType =
    | 'anomalychart'
    | 'areachart'
    | 'barchart'
    | 'columnchart'
    | 'ladderchart'
    | 'linechart'
    | 'piechart'
    | 'pivotchart'
    | 'scatterchart'
    | 'stackedareachart'
    | 'timechart'
    | 'table'
    | 'timeline'
    | 'timepivot'
    | 'card'
    | 'plotly'
    | null;
export type LegendVisibility = 'visible' | 'hidden' | null;
export type Scale = 'linear' | 'log' | null;
export type YSplit = 'none' | 'axes' | 'panels' | null;
export type Kind = 'default' | 'unstacked' | 'stacked' | 'stacked100' | 'map' | null;

export type NumericColumnType = 'int' | 'long' | 'real' | 'decimal';

export type ColumnType = NumericColumnType | 'bool' | 'datetime' | 'dynamic' | 'guid' | 'string' | 'timespan';

export interface VisualizationOptions {
    Visualization: VisualizationType;
    Title: string | null;
    XColumn: string | null;
    Series: string[] | null;
    YColumns: string[] | null;
    XTitle: string | null;
    YTitle: string | null;
    XAxis: Scale;
    YAxis: Scale;
    Legend: LegendVisibility;
    YSplit: YSplit;
    Accumulate: boolean;
    IsQuerySorted: boolean;
    Kind: Kind;
    AnomalyColumns: string[] | null;
    Ymin: number | 'NaN';
    Ymax: number | 'NaN';
}

const DataChartsHelper = Kusto.Charting.DataChartsHelper;

function resolveColumnNamesFromData(
    dataSource: Kusto.Charting.IChartingDataSource,
    visualizationOptions: VisualizationOptions
): {
    argumentColumnNames: System.Collections.Generic.IEnumerable$1<string> | null;
    columnsMappedToSeries: System.Collections.Generic.IEnumerable$1<string>;
} {
    const columnsToExclude: string[] = [];

    if (visualizationOptions.YColumns) {
        columnsToExclude.push(...visualizationOptions.YColumns);
    }
    if (visualizationOptions.XColumn) {
        columnsToExclude.push(visualizationOptions.XColumn);
    }

    const argumentColumnNames = DataChartsHelper.GetAllArgumentColumns(
        dataSource,
        new Bridge.ArrayEnumerable(columnsToExclude)
    );
    // Add the first argument as series mapper
    let columnsMappedToSeries: string[] = [];
    if (visualizationOptions.Series && visualizationOptions.Series.length > 0) {
        columnsMappedToSeries = visualizationOptions.Series;
    } else {
        const firstNonNumericColumn = DataChartsHelper.GetFirstStringColumnName(dataSource, 1);
        if (firstNonNumericColumn && firstNonNumericColumn.length > 0) {
            columnsMappedToSeries = [firstNonNumericColumn];
        }
    }

    return {
        argumentColumnNames,
        columnsMappedToSeries: new Bridge.ArrayEnumerable(columnsMappedToSeries),
    };
}

export type Schema = readonly {
    ColumnName: string;
    ColumnType: ColumnType;
}[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Rows = ReadonlyArray<ReadonlyRecord<string, any>>;

export const toArgumentColumnType = (t: ColumnType): ArgumentColumnType => {
    switch (t) {
        case 'decimal':
        case 'int':
        case 'long':
        case 'real':
            return ArgumentColumnType.Numeric;
        case 'datetime':
            return ArgumentColumnType.DateTime;
        case 'timespan':
            return ArgumentColumnType.TimeSpan;
        case 'string':
            return ArgumentColumnType.String;
        case 'dynamic':
            return ArgumentColumnType.Object;
        default:
            return ArgumentColumnType.None;
    }
};
const EPOCH_BASE_DATE = System.DateTime.parseExact('70-01-01', 'yy-MM-dd', undefined);
export function dateTimeToEpoch(time: System.DateTime) {
    // Added while enabling lints
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (System.DateTime as any).subdd(time, EPOCH_BASE_DATE).getTotalMilliseconds() as number;
}
export function timeSpanToEpoch(time: System.DateTime) {
    // Added while enabling lints
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (System.DateTime as any).subdd(time, new System.TimeSpan()).getTotalMilliseconds() as number;
}

// Added while enabling lints
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function normalizeArgumentNumeric(type: ArgumentColumnType, val: any) {
    switch (type) {
        case ArgumentColumnType.Numeric:
            return val as number;
        case ArgumentColumnType.DateTime:
            return dateTimeToEpoch(val);
        case ArgumentColumnType.TimeSpan:
            return timeSpanToEpoch(val);
        default:
            return Number.NaN;
    }
}

function toDataSource(schema: Schema, data: Rows): Kusto.Charting.IChartingDataSource {
    const getSchema = () =>
        new Bridge.ArrayEnumerable(
            schema.map((item) => ({ Item1: item.ColumnName, Item2: toArgumentColumnType(item.ColumnType) }))
        );
    const getValue = (row: number, col: number) => {
        if (col < 0 || col >= schema.length || row < 0 || row >= data.length) {
            return null;
        }
        const columnType = schema[col].ColumnType;
        const rawValue = data[row][schema[col].ColumnName];
        if (columnType === 'datetime') {
            // Make sure that DateTime is kept in GMT !!
            // Added while enabling lints
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let t = (System.DateTime as any).parseExact(
                rawValue,
                "yyyy'-'MM'-'dd'T'HH':'mm':'ss.FFFFFFFZ",
                null,
                !0,
                !0
            );

            // fix seconds frication issue
            // 1. bridge doesn't support more then 3 digit - TODO: Fix this in the future
            // 2. if less then 3 digit provided - it take it as less significant
            //    e.g. 0.1 -> 1 millisecond instead of 100 millisecond
            const len = rawValue?.length;
            if (len === 23) {
                // only 2 digit xxx.12Z - 12 milliseconds - should be 120;
                // Added while enabling lints
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                t = (System.DateTime as any).addMilliseconds(t, t.getMilliseconds() * 9);
            } else if (len === 22) {
                // only 1 digit xxx.4Z - 4 milliseconds - should be 400
                // Added while enabling lints
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                t = (System.DateTime as any).addMilliseconds(t, t.getMilliseconds() * 99);
            }
            return t;
        } else if (columnType === 'timespan') {
            // Added while enabling lints
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return (System.TimeSpan as any).parse(rawValue);
        }
        return rawValue;
    };

    return {
        GetSchema: getSchema,
        Kusto$Charting$IChartingDataSource$GetSchema: getSchema,
        RowsCount: data.length,
        Kusto$Charting$IChartingDataSource$RowsCount: data.length,
        GetValue: getValue,
        Kusto$Charting$IChartingDataSource$GetValue: getValue,
    };
}

// Added while enabling lints
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toArray<T>(ienumerable: any): T[] {
    // Added while enabling lints
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (Bridge as any).toArray(ienumerable);
}

const List = System.Collections.Generic.List$1;

export const EMPTY_ArgumentDateTime = new DataItem().ArgumentDateTime;

export function fillGapsWithNaNs(items: DataItem[], columnType: ColumnType) {
    const bridgifiedItems = new (List<DataItem>(DataItem).$ctor1)(items as DataItem[]);
    const bridgeResult = DataChartsHelper.FillGapsWithNaNs(bridgifiedItems, toArgumentColumnType(columnType));

    return toArray<DataItem>(bridgeResult);
}

function handleException(telemetry: IKweTelemetry, e: unknown): (t: ChartingLocale) => string {
    if (!(e instanceof Kusto.Charting.SeriesCreationException)) {
        throw e;
    }

    // Error strings + regex below _must_ be unit tested, so we know when they
    // need to be updated.

    // SeriesCreationException types are wrong
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const message = (e as any).message;
    switch (message) {
        case 'Any of columns, provided as Series, were not found in data':
            return (t) => t.charting.errors.seriesMissing;
        case 'Any of columns defined as Y-Axes were not found in data, not of an appropriate type or used as argument or series':
            return (t) => t.charting.errors.yColumnMissing;

        // Found in C# transpiled code, but haven't reproduced them yet:
        //   case 'DateTimeRange construction: End parameter should be later than Begin  parameter':
        //   case "GeospatialAsSeries: it's not possible to set both series and x/y columns.":
        //   case 'Y-Axes and X-Axis both should be defined as scalars or as series':
    }

    let res = /^Column (.+), provided as X-Axis, was not found in data$/.exec(message);

    if (res) {
        const columnName = res[1];
        return (t) => formatLiterals(t.charting.errors.xColumnMissing, { columnName });
    }

    res = /^Column (.+), provided as Y-Axis, should be one of types: Numeric, DateTime, Timespan$/.exec(message);
    if (res) {
        const columnName = res[1];
        return (t) => formatLiterals(t.charting.errors.wrongYColumnType, { columnName });
    }

    res = /^Type of column (.+), provided as X-Axis, does not match required by chart type$/.exec(message);
    if (res) {
        const columnName = res[1];
        return (t) => formatLiterals(t.charting.errors.xColumnMissing, { columnName });
    }

    telemetry.trace('C# heuristics error message not localized', { message, severityLevel: 'warning' });

    return () => message;
}

export interface DataItemsAndMetaData {
    items?: DataItem[];
    argumentType?: ArgumentColumnType;
    metaData: null | Kusto.Charting.IChartMetaData;
}

export function getDataItemsAndMetaData(
    columns: Schema,
    rows: Rows,
    visualizationOptions: VisualizationOptions,
    telemetry: IKweTelemetry
): Result<DataItemsAndMetaData, (t: ChartingLocale) => string> {
    const dataSource = toDataSource(columns, rows);
    const series = visualizationOptions.Series ? new Bridge.ArrayEnumerable(visualizationOptions.Series) : undefined;
    const xColumn = visualizationOptions.XColumn || undefined;

    // getMetadata needs to know that we want to have the anomaly column as a data column.
    const yColumnsToResolve =
        visualizationOptions.Visualization === 'anomalychart' &&
        visualizationOptions.YColumns &&
        visualizationOptions.YColumns.length > 0 &&
        visualizationOptions.AnomalyColumns
            ? visualizationOptions.YColumns.concat(visualizationOptions.AnomalyColumns)
            : visualizationOptions.YColumns;
    const yColumns = yColumnsToResolve ? new Bridge.ArrayEnumerable(yColumnsToResolve) : new Bridge.ArrayEnumerable([]);

    let data: undefined | Result<DataItemsAndMetaData, (t: ChartingLocale) => string>;

    const getData = (
        argumentColumnType: ArgumentColumnType,
        argumentRestrictions?: ArgumentRestrictions,
        seriesColumns?: System.Collections.Generic.IEnumerable$1<string>
    ): Result<DataItemsAndMetaData, (t: ChartingLocale) => string> => {
        let metaData: null | Kusto.Charting.IChartMetaData | null;

        try {
            metaData = DataChartsHelper.GetMetaData(
                dataSource,
                argumentColumnType,
                argumentRestrictions,
                seriesColumns || series,
                xColumn,
                yColumns
            );
        } catch (e) {
            return err(handleException(telemetry, e));
        }
        if (metaData) {
            // UnusedIndexes is used for displaying extra columns in tooltip. It's a huge overhead and therefore disabling this feature.
            metaData.UnusedIndexes = new Bridge.ArrayEnumerable([]);
        }
        const items = metaData
            ? toArray<DataItem>(DataChartsHelper.GetData$1(dataSource, metaData, visualizationOptions.Accumulate))
            : [];

        let argumentType =
            metaData && metaData.ArgumentDataColumnIndex >= 0
                ? toArgumentColumnType(columns[metaData.ArgumentDataColumnIndex].ColumnType)
                : undefined;
        if (argumentType === ArgumentColumnType.Object && metaData) {
            argumentType = DataChartsHelper.ResolveJsonArrayType(
                dataSource.GetValue(0, metaData.ArgumentDataColumnIndex)
            );
        }

        return ok({ items, argumentType, metaData });
    };
    const getDataWithArgumentPriority = function getDataWithArgumentPriority(
        argumentColumnTypes: ArgumentColumnType[],
        argumentRestrictions?: ArgumentRestrictions,
        seriesColumns?: System.Collections.Generic.IEnumerable$1<string>
    ): Result<DataItemsAndMetaData, (t: ChartingLocale) => string> {
        for (let i = 0; i < argumentColumnTypes.length; i++) {
            try {
                const data = getData(argumentColumnTypes[i], argumentRestrictions, seriesColumns);
                if (data.value && data.value.items && data.value.items.length > 0) {
                    return data;
                }
            } catch (e) {
                //ignore un-match errors
            }
        }
        return ok({ metaData: null });
    };
    switch (visualizationOptions.Visualization) {
        case 'barchart':
        case 'columnchart':
            const { columnsMappedToSeries } = resolveColumnNamesFromData(dataSource, visualizationOptions);

            data = getData(
                ArgumentColumnType.AllExceptGeospatial,
                ArgumentRestrictions.MustHave | ArgumentRestrictions.NotIncludedInSeries,
                columnsMappedToSeries
            );

            if (!data?.value?.items || data.value.items.length === 0) {
                data = getData(
                    ArgumentColumnType.StringOrDateTimeOrTimeSpan,
                    ArgumentRestrictions.MustHave | ArgumentRestrictions.NotIncludedInSeries,
                    columnsMappedToSeries
                );
            }
            break;
        case 'piechart':
            data =
                visualizationOptions.Kind === 'map'
                    ? getData(ArgumentColumnType.Geospatial, ArgumentRestrictions.GeospatialAsSeries)
                    : getData(
                          ArgumentColumnType.String,
                          ArgumentRestrictions.NotIncludedInSeries | ArgumentRestrictions.MustHave
                      );
            break;
        case 'scatterchart':
            data =
                visualizationOptions.Kind === 'map'
                    ? getData(ArgumentColumnType.Geospatial)
                    : getDataWithArgumentPriority(
                          getArgumentColumnTypePriority(visualizationOptions.Visualization),
                          ArgumentRestrictions.NumericAsSeries | ArgumentRestrictions.NotIncludedInSeries
                      );
            break;
        case 'stackedareachart':
        case 'areachart':
        case 'linechart':
        case 'timechart':
            try {
                if (visualizationOptions.Visualization === 'timechart') {
                    // Fix timechart bug https://dev.azure.com/msazure/One/_workitems/edit/16248448/ (same logic as Kusto Explorer)
                    data = getDataWithArgumentPriority(
                        getArgumentColumnTypePriority(visualizationOptions.Visualization),
                        ArgumentRestrictions.NumericAsSeries | ArgumentRestrictions.NotIncludedInSeries
                    );
                } else {
                    // Don't use KW logic to avoid bug https://dev.azure.com/msazure/One/_workitems/edit/16298039
                    data = getData(ArgumentColumnType.NumericOrDateTimeOrTimeSpan);
                }
            } catch (error) {}

            if (data?.value?.items && data.value.items.length > 0) {
                break;
            }

            // If we got here we couldn't figure out how to draw the chart.
            // maybe the user formatted the numeric / datetime data into a string? If the data is sorted we might be
            // able to chart it anyway by allowing strings to be x axis as well.
            //
            // TODO: Not sure this code path ever actually does anything. Needs a test to demonstrate it. May have been broken by accident at some point?
            try {
                data = getData(ArgumentColumnType.StringOrDateTimeOrTimeSpan, ArgumentRestrictions.NotIncludedInSeries);
            } catch (error) {}

            if (data?.value?.items && data.value.items.length > 0) {
                if (visualizationOptions.IsQuerySorted) {
                    break;
                } else {
                    const visualType = visualizationOptions.Visualization;
                    return err((t) => formatLiterals(t.charting.errors.mustBeNumericOrSorted, { visualType }));
                }
            }
            break;
        case 'anomalychart':
            data = getDataWithArgumentPriority(
                getArgumentColumnTypePriority(visualizationOptions.Visualization),
                ArgumentRestrictions.NotIncludedInSeries | ArgumentRestrictions.NumericAsSeries
            );
            break;
        case 'ladderchart':
        case 'pivotchart':
        case 'table':
        case 'timeline':
        case 'timepivot':
        case 'card':
        case 'plotly':
        case null:
            throw new Error('visualization type cannot be null');
        default:
            assertNever(visualizationOptions.Visualization);
    }

    // TODO: Every case where we return `ok({ metaData: null })` should probably
    // be an error message instead
    return data ?? ok({ metaData: null });
}

function getArgumentColumnTypePriority(visualization: VisualizationType) {
    switch (visualization) {
        case 'anomalychart':
        case 'timechart':
            return [ArgumentColumnType.DateTime, ArgumentColumnType.TimeSpan];
        case 'linechart':
            return [ArgumentColumnType.Numeric, ArgumentColumnType.DateTime, ArgumentColumnType.TimeSpan];

        // Likely time chart
        case 'scatterchart':
        case 'areachart':
        case 'stackedareachart':
            return [ArgumentColumnType.DateTime, ArgumentColumnType.TimeSpan, ArgumentColumnType.Numeric];
        default:
            return [ArgumentColumnType.Numeric, ArgumentColumnType.DateTime, ArgumentColumnType.TimeSpan];
    }
}

export function enhanceDataWithAnomalyDataFromColumns(
    items: readonly DataItem[],
    anomalyColumns: readonly string[],
    yColumns: string[] | null
) {
    const bridgifiedItems = new (List<DataItem>(DataItem).$ctor1)(items as DataItem[]);
    // Added while enabling lints
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const map: any = {};
    const data = Kusto.Charting.AnomalyDataHelper2.EnchanceDataWithAnomalyDataFromColumns(
        bridgifiedItems,
        yColumns,
        anomalyColumns as string[],
        map
    );
    return toArray<DataItem>(data);
}
