import React from 'react';
import { Dropdown, IDropdown } from '@fluentui/react/lib/Dropdown';
import type { ISelectableDroppableTextProps } from '@fluentui/react/lib/SelectableOption';
import classNames from 'classnames';

import { useThemeState } from '../../theming';
import { RTDDropdownProps } from '../RTDDropdown';

/**
 * Unsafe dropdown with custom header support. If `onRenderList` is provided,
 * the header must be manually rendered by the creator of the `onRenderList`
 * callback
 */
export const UnsafeHeaderDropdown: React.FC<RTDDropdownProps> = ({ onRenderHeader, onRenderList, ...props }) => {
    const themeState = useThemeState();

    const internalOnRenderList = React.useCallback(
        (
            innerProps?: ISelectableDroppableTextProps<IDropdown, HTMLDivElement>,
            defaultRender?: (p?: ISelectableDroppableTextProps<IDropdown, HTMLDivElement>) => JSX.Element | null
        ) =>
            onRenderHeader ? (
                <>
                    {onRenderHeader()}
                    {defaultRender?.(innerProps)}
                </>
            ) : (
                defaultRender?.(innerProps) ?? null
            ),
        [onRenderHeader]
    );

    return (
        <Dropdown
            onRenderList={onRenderList ? onRenderList : internalOnRenderList}
            {...props}
            calloutProps={{
                ...props.calloutProps,
                className: classNames(themeState.classNames, props.calloutProps?.className),
            }}
        />
    );
};
