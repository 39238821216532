import React from 'react';
import { IStyle, ITheme } from '@fluentui/react';
import { classNamesFunction, IStyleFunctionOrObject, styled } from '@fluentui/utilities';

import type { Locale, UField, UnknownDataFrameValue } from '@kusto/utils';

import { StatCard, StatCardProps } from './SingleStatCard';

const getClassNames = classNamesFunction<MultiStatCardStyleProps, MultiStatCardStyles>();

export type TextSize = 'small' | 'auto' | 'large';

export type MultiStatCardStyleProps = Required<
    Pick<MultiStatCardProps, 'theme' | 'textSize' | 'numberOfColumns' | 'numberOfRows'>
>;

/**
 * A quadrant (one cell in the grid) has a header (text) and value (the number)
 */
export interface QuadrantData {
    value: UnknownDataFrameValue;
    header: undefined | string;
}

export type ConditionalFormattingProps = Pick<
    StatCardProps,
    'iconName' | 'subLabel' | 'colorStyle' | 'colorStyle' | 'color'
>;

export interface MultiStatCardProps {
    /**
     * number of rows in the multistat
     */
    numberOfRows: number;

    /**
     * number of columns in the multistat
     */
    numberOfColumns: number;

    valueField: UField;
    labelField: undefined | UField;

    /**
     * the array containing the conditional formatting props. It should be indexed in the same order as the "data" array.
     */
    conditionalFormattingProps: ConditionalFormattingProps[];

    /**
     * size of text in the value (header size remains the same)
     */
    textSize: TextSize;

    /**
     * the locale for number localization.
     */
    locale: undefined | Locale;
    timeZone: string;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<MultiStatCardStyleProps, MultiStatCardStyles>;
}

interface MultiStatCardStyles {
    root: IStyle;
    missingStat: IStyle;
}

// When added to `getMultiStatStyles` this get's broken into margin-top, margin-left, etc, which doesn't work because the variable defines 4 properties, not 1.
const style = { margin: `var(--tile-full-bleed-margin)` };

const getMultiStatStyles = (props: MultiStatCardStyleProps): MultiStatCardStyles => {
    return {
        root: {
            // Fill tile + bottom padding
            height: 'calc(100% + 8px)',
            boxSizing: 'border-box',
            display: 'grid',
            // distribute space evenly between quadrants
            gridTemplateColumns: `repeat(${props.numberOfColumns}, 1fr)`,
            gridTemplateRows: `repeat(${props.numberOfRows}, 1fr)`,
            gap: '1px',
            // Added while enabling lints

            backgroundColor: props.theme!.palette.neutralLight,
            // Added while enabling lints

            borderTop: `1px solid ${props.theme!.palette.neutralLight}`,
        },
        missingStat: {
            // Added while enabling lints

            backgroundColor: props.theme!.palette.white,
        },
    };
};

const MultiStatCardBase = ({
    labelField,
    valueField,
    styles,
    theme,
    textSize,
    numberOfColumns,
    numberOfRows,
    conditionalFormattingProps,
    locale,
    timeZone,
}: MultiStatCardProps) => {
    const classNames = getClassNames(styles, {
        // Added while enabling lints

        theme: theme!,
        textSize: textSize ?? 'auto',
        numberOfColumns,
        numberOfRows,
    });

    const items: React.ReactNode[] = [];

    for (let i = 0; i < numberOfColumns * numberOfRows; i++) {
        if (i < valueField.values.length) {
            items.push(
                <StatCard
                    valueField={valueField}
                    rowIndex={i}
                    timeZone={timeZone}
                    textSize={textSize}
                    key={i}
                    header={labelField?.toLocaleString(i, timeZone, locale) ?? ''}
                    {...conditionalFormattingProps[i]}
                    locale={locale}
                />
            );
        } else {
            items.push(<div key={i} className={classNames.missingStat} />);
        }
    }

    return (
        <div className={classNames.root} style={style}>
            {items}
        </div>
    );
};

MultiStatCardBase.defaultProps = {
    textSize: 'auto' as TextSize,
};

/**
 * A component that displays a single stat (usually numeric, but can be textual).
 * Usually displayed on small rectangular surfaces as part of a dashboard.
 */
export const MultiStatCard = styled(MultiStatCardBase, getMultiStatStyles);
