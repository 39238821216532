import { tokens } from '@fluentui/tokens';
import * as Highcharts from 'highcharts';

import type { ChartColors, ChartEvents } from '@kusto/visualizations';

import { KweRtdVisualContext } from '../context';

export type BaseHighchartEvents = Partial<Pick<ChartEvents, 'onPointClick' | 'onHighChartsDragXEnd'>>;

/**
 * These base highchart options should be shareable
 * between all high chart visuals.
 * Important: Don't add any options that
 * are only for a specific visual. If you
 * need to do that then do it in your Visual
 * and not here.
 */
export function createBaseHighchartOptions(
    ctx: KweRtdVisualContext,
    events: undefined | BaseHighchartEvents = {},
    colors: ChartColors['colors'],
    timezone: string
): Highcharts.Options {
    const { onPointClick, onHighChartsDragXEnd } = events;

    return {
        lang: {
            accessibility: {
                // TODO: Localize
                defaultChartTitle: 'Chart',
            },
        },
        boost: { enabled: ctx.chartProps.useBoost, seriesThreshold: 1 },
        title: { text: undefined },
        chart: {
            // Invalid as a background color, which causes it to be ignored, but
            // also used as svg "fill" property, which we need to be valid.
            backgroundColor: 'none',
            style: {
                fontFamily: tokens.fontFamilyBase,
            },
            ...(onHighChartsDragXEnd && {
                zoomType: 'x',
                events: {
                    selection: function (event) {
                        const axis = event.xAxis[0];
                        onHighChartsDragXEnd(axis.min, axis.max);
                        return false;
                    },
                },
            }),
        },
        plotOptions: {
            series: {
                allowPointSelect: false,
                // in turboThreshold only  one dimensional arrays of numbers, or two dimensional arrays with x and y values are allowed
                // when introducing cross-filter, our data is now a PointOptionsObject[] so entering turbo mode causes no chart to be displayed.
                turboThreshold: 0,
                animation: {
                    duration: 500,
                },
                events: {
                    click:
                        onPointClick &&
                        ((event: Highcharts.SeriesClickEventObject) => {
                            // TODO: We need another abstraction layer (another interface)
                            // that isn't tied to Kusto's C# DataItem interface
                            // so that this can be agnostic for future visuals
                            // we add to rtd-provider that are not supported
                            // by Kusto's render command.
                            // e.g. we have a new interface
                            // @example
                            // ```
                            // interface VisualDataItem {
                            //   row: RowData;
                            // }
                            // ```

                            // Non-null assertion because it should be
                            // impossible for data item to not be present
                            // Added while enabling lints

                            const row = event.point.options.custom!.dataItem.row;
                            // row is only present on dataItem if dataItems.length === rows.length
                            if (row) {
                                onPointClick(row);
                            }
                        }),
                },
            },
        },
        colors: colors,
        credits: { enabled: false },
        tooltip: {
            shared: ctx.chartProps.showAllSeriesOnHover,
            outside: true,
        },
        time: { timezone },
    };
}
