import React, { useCallback } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
    CellClickedEvent,
    CellDoubleClickedEvent,
    CellFocusedEvent,
    CellRangeParams,
    Column,
    GridApi,
    ICellRendererParams,
    IRowNode,
    NavigateToNextCellParams,
    RangeSelectionChangedEvent,
    RowClassParams,
    RowClassRules,
    RowGroupOpenedEvent,
    RowNode,
    SuppressKeyboardEventParams,
} from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { AgGridReact } from '@ag-grid-community/react';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { useUncontrolledFocus } from '@fluentui/react-components';
import concat from 'lodash/concat';
import debounce from 'lodash/debounce';
import merge from 'lodash/merge';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import Pane from 'react-split-pane/lib/Pane';
import SplitPane from 'react-split-pane/lib/SplitPane';

import { Theme } from '@kusto/utils';

import { GridWithSearchProps } from '../GridWithSearch.types';
import { KweAgGridLocale } from '../locale';
import { closableCell } from './ClosableCell';
import { ExpandViewRenderer } from './ExpandViewRenderer';

import styles from './styles.module.scss';

export const EXPAND_ROW_COL_ID = '__expandRowColID';

export enum ContentViewModeType {
    Full = 0,
    Compact,
}

export enum DetailsViewType {
    InGrid = 0,
    BelowGrid,
    ExternalPanel,
}

export interface GridWithExpandHandle {
    closeAllExpandedRows: () => void;
}

export interface GridWithExpandProps extends Omit<GridWithSearchProps, 'onGridReady'> {
    theme: Theme;
    locale: KweAgGridLocale;
    contentViewModeType?: ContentViewModeType;
    expandType?: DetailsViewType;
    onExpandTypeChange?: (expandType: DetailsViewType) => void;
    openExternalView?: () => void;
    closeExternalView?: () => void;
    externalViewContainer?: HTMLElement | null;
    gridWithExpandRef?: React.Ref<GridWithExpandHandle>;
    closeExpandViewOnClick?: boolean;
    mouseWheelZoom?: boolean;
    onEditorOpen?: () => void;
    hideEmptyCells?: boolean;
    renderExpandBarItems?: (isCompactMode?: boolean) => JSX.Element;
    disableJPath?: boolean;
    onRequestPaste?: (content: string) => void;
}

export const GridWithExpand: React.FC<GridWithExpandProps> = ({
    defaultColDef: defaultColDefExternal,
    getRowClass: getRowClassExternal,
    rowClassRules: rowClassRulesExternal,
    onCellFocused: onCellFocusedExternal,
    onCellClicked: onCellClickedExternal,
    gridOptions: gridOptionsExternal,
    onExpandTypeChange,
    columnDefs,
    modules,
    expandType,
    contentViewModeType,
    locale,
    openExternalView,
    closeExternalView,
    externalViewContainer,
    gridWithExpandRef,
    closeExpandViewOnClick,
    mouseWheelZoom,
    onEditorOpen,
    searchFocusedCell,
    hideEmptyCells,
    renderExpandBarItems,
    disableJPath,
    onRequestPaste,
    theme,
    ...props
}) => {
    const lastExpandedCell = React.useRef<{ rowId: string | null; colId?: string; dontChangeFocus?: boolean }>({
        rowId: null,
        colId: '',
    });
    // TODO AG Grid 31 has a new feature called reactiveCustomComponents that might make persistedEditorState unnecessary, in V30 the component is recreated, not updated
    const persistedEditorState = React.useRef<monaco.editor.ICodeEditorViewState | null>(null);
    const expandedRowId = React.useRef<string | null>(null);
    const [belowGridContainer, setBelowGridContainer] = React.useState<HTMLDivElement | null>(null);
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const gridApi = React.useRef<GridApi | null>(null);
    const [isAnyRowExpanded, setIsAnyRowExpanded] = React.useState(false);
    const uncontrolledFocusAttributes = useUncontrolledFocus();
    const expandRowHeight = React.useRef<number | null>(null);

    React.useImperativeHandle(
        gridWithExpandRef,
        () => ({
            closeAllExpandedRows: () => {
                if (gridApi.current) {
                    gridApi.current.forEachNode((node) => {
                        if (node.expanded) {
                            node.setExpanded(false);
                        }
                    });
                }
            },
        }),
        []
    );

    const updateLastExpandedRowId = React.useCallback((rowId: string | null) => {
        expandedRowId.current = rowId;
        setIsAnyRowExpanded(!!rowId);
    }, []);

    const onGridReady = React.useCallback(
        (params) => {
            gridApi.current = params.api;
            gridOptionsExternal?.onGridReady?.(params);
        },
        [gridOptionsExternal]
    );
    const updateExpand = useCallback(
        (
            { column, rowIndex, api }: { column: string | Column | null; rowIndex: number | null; api: GridApi },
            dontChangeFocus = false
        ) => {
            const colId = typeof column === 'string' ? column : column?.getId();
            if (rowIndex === null || !colId) {
                return;
            }
            const rowNode = api.getDisplayedRowAtIndex(rowIndex);
            const rowId = rowNode?.id;
            if (!rowId || (rowId === lastExpandedCell.current.rowId && colId === lastExpandedCell.current.colId)) {
                return;
            }
            if (rowNode.detail) {
                return;
            }
            lastExpandedCell.current = { rowId, colId, dontChangeFocus };
            persistedEditorState.current = null;

            setTimeout(() => {
                if (rowNode.expanded) {
                    api.redrawRows({ rowNodes: [(rowNode as RowNode).detailNode] });
                } else if (expandedRowId.current) {
                    api.getRowNode(expandedRowId.current)?.setExpanded(false);
                    rowNode.setExpanded(true);
                    updateLastExpandedRowId(rowNode.id ?? null);
                }
            }, 0);
        },
        [updateLastExpandedRowId]
    );

    const navigateToNextCell = React.useCallback(
        (params: NavigateToNextCellParams) => {
            if (params.nextCellPosition && params.api && !!expandedRowId.current) {
                const rowIndex = params.nextCellPosition.rowIndex;
                if (rowIndex !== params.previousCellPosition.rowIndex) {
                    const nextFocusRow = params.api.getDisplayedRowAtIndex(rowIndex);

                    let next = rowIndex;
                    if (nextFocusRow?.detail) {
                        // if the next row is a detail row, we need to skip it
                        const movingDirection = rowIndex - params.previousCellPosition.rowIndex;
                        next = rowIndex + movingDirection;

                        // if the next row is out of bounds, don't move
                        if (next < 0 || next >= params.api.getDisplayedRowCount()) {
                            return params.previousCellPosition;
                        }
                    }
                    params.nextCellPosition.rowIndex = next ?? params.nextCellPosition.rowIndex;
                }
                updateExpand({
                    column: params.nextCellPosition?.column,
                    rowIndex: params.nextCellPosition?.rowIndex,
                    api: params.api,
                });
            }
            return params.nextCellPosition ?? params.previousCellPosition;
        },
        [updateExpand]
    );

    const onCellDoubleClicked = useCallback((params: CellDoubleClickedEvent) => {
        if (!expandedRowId.current) {
            params.node.setExpanded(true);
        }
    }, []);

    const onCellFocused = React.useMemo(
        () =>
            debounce(
                (params: CellFocusedEvent) => {
                    if (closeExpandViewOnClick && lastExpandedCell.current.rowId) {
                        /* updating the expand view causes AG grid to redraw all rows beneath it
                            this prevents the onClick event from being triggered on the cell that was focused
                            we need the click event to close the expand view if the option is enabled.
                            keyBoardHandler and navigateToNextCell need to updateExpand themselves because
                            the focus event has no source information so we call it here for them
                        */
                        return;
                    }
                    updateExpand(params);
                    onCellFocusedExternal?.(params) ?? gridOptionsExternal?.onCellFocused?.(params);
                },
                30,
                { maxWait: 70 }
            ),
        [closeExpandViewOnClick, gridOptionsExternal, onCellFocusedExternal, updateExpand]
    );

    const onCellClicked = React.useCallback(
        (params: CellClickedEvent) => {
            if (closeExpandViewOnClick && expandedRowId.current) {
                const node = gridApi.current?.getRowNode(expandedRowId.current);
                if (node && node.expanded) {
                    node.setExpanded(false);
                }
            }
            onCellClickedExternal?.(params) ?? gridOptionsExternal?.onCellClicked?.(params);
        },
        [closeExpandViewOnClick, gridOptionsExternal, onCellClickedExternal]
    );

    React.useEffect(() => () => onCellFocused.flush(), [onCellFocused]);

    const debouncedOnRowHeightChanged = React.useMemo(
        () =>
            debounce(
                (newHeight: number) => {
                    expandRowHeight.current = newHeight;
                    gridApi.current?.onRowHeightChanged();
                },
                30,
                { maxWait: 50 }
            ),
        []
    );
    React.useEffect(() => () => debouncedOnRowHeightChanged.flush(), [debouncedOnRowHeightChanged]);

    const onRangeSelectionChanged = React.useMemo(
        () =>
            debounce((params: RangeSelectionChangedEvent) => {
                if (!params.finished || lastExpandedCell.current.rowId === null) {
                    return;
                }

                const origSelection = params.api.getCellRanges();

                let changed = false;
                const row = params.api.getRowNode(lastExpandedCell.current.rowId) as RowNode;
                if (!row || !row.detailNode) {
                    return;
                }

                const rowIndex = row.detailNode.rowIndex;

                const cleanSelections = origSelection?.reduce<CellRangeParams[]>((clean, range) => {
                    const shouldRemoveFirstCol =
                        range.columns?.length > 0 && range.columns[0].getColDef().colId === EXPAND_ROW_COL_ID;

                    const cleanColumn = shouldRemoveFirstCol ? range.columns.slice(1) : range.columns;
                    if (cleanColumn.length === 0) {
                        changed = true;
                        return clean;
                    }

                    // ag grid supports selecting all rows in which case both start and end will be undefined.
                    // TOOD not sure if that's the right logic in this case.
                    if (range.startRow === undefined || range.endRow === undefined) {
                        return clean;
                    }

                    const rowsIndex = [range.startRow.rowIndex, range.endRow.rowIndex];
                    const start = Math.min(...rowsIndex);
                    const end = Math.max(...rowsIndex);
                    const detailsInRange = rowIndex !== null && rowIndex >= start && rowIndex <= end;
                    const cleanRows: { start: number; end: number }[] = detailsInRange
                        ? [
                              { start, end: rowIndex - 1 },
                              { start: rowIndex + 1, end },
                          ]
                        : [{ start, end }];
                    cleanRows
                        .filter((crow) => crow.start <= crow.end)
                        .forEach((rows) =>
                            clean.push({
                                rowStartIndex: rows.start,
                                rowEndIndex: rows.end,
                                columnStart: cleanColumn[0],
                                columnEnd: cleanColumn[cleanColumn.length - 1],
                            })
                        );
                    changed = changed || shouldRemoveFirstCol || detailsInRange;
                    return clean;
                }, []);

                if (changed && cleanSelections) {
                    setTimeout(() => {
                        params.api.clearRangeSelection();

                        cleanSelections.forEach((range) => params.api.addCellRange(range));
                    }, 0);
                }
            }, 70),
        []
    );

    React.useEffect(() => () => onRangeSelectionChanged.flush(), [onRangeSelectionChanged]);

    React.useEffect(() => {
        // sync grid expand with searchFocusedCell, leave focus on search controls
        if (searchFocusedCell && gridApi.current && expandedRowId.current) {
            updateExpand(
                {
                    column: searchFocusedCell.column,
                    rowIndex: searchFocusedCell.rowIndex,
                    api: gridApi.current,
                },
                true
            );
        }
    }, [searchFocusedCell, updateExpand]);

    const keyboardHandler = React.useCallback(
        (params: SuppressKeyboardEventParams) => {
            const { code } = params.event;
            if (code === 'Enter' && params.node !== null && !params.node.detail) {
                params.node.setExpanded(true);
                updateExpand({ rowIndex: params.node.rowIndex, column: params.column, api: params.api });
                return true;
            }
            if (code === 'Escape') {
                if (params.node.expanded) {
                    params.node.setExpanded(false);
                    return true;
                }
            }
            // try this, if not the code below should work        https://www.ag-grid.com/archive/30.2.0/react-data-grid/master-detail-custom-detail/#keyboard-navigation
            if (code === 'Tab') {
                if (lastExpandedCell.current.rowId !== null) {
                    if (params.node.detail) {
                        params.event.stopPropagation();
                        return true;
                    } else if (params.node.expanded && params.node.rowIndex !== null) {
                        const column = params.columnApi.getColumns()?.[0];
                        if (column) {
                            params.api.setFocusedCell(params.node.rowIndex + 1, column);
                        }
                        params.event.stopPropagation();
                        params.event.preventDefault();
                        return true;
                    }
                }
            }
            return (
                defaultColDefExternal?.suppressKeyboardEvent?.(params) ??
                gridOptionsExternal?.defaultColDef?.suppressKeyboardEvent?.(params) ??
                false
            );
        },
        [defaultColDefExternal, gridOptionsExternal?.defaultColDef, updateExpand]
    );

    const onExpandOrCollapse = React.useCallback(
        (params: RowGroupOpenedEvent) => {
            if (params.node.group) {
                params.node.allLeafChildren.forEach((child) => {
                    if (child.expanded) {
                        child.setExpanded(false);
                    }
                });
                return;
            }
            if (params.node.id) {
                if (params.expanded) {
                    if (params.rowIndex === null) {
                        // this happens with node.id == "ROOT_NODE_ID" when searching. not sure why
                        return;
                    }
                    // open detail event
                    if (expandType === DetailsViewType.ExternalPanel) {
                        openExternalView?.();
                    }
                    if (expandedRowId.current && params.node.id !== expandedRowId.current) {
                        const nodeToClose = params.api.getRowNode(expandedRowId.current);
                        nodeToClose?.setExpanded(false);
                    }
                    updateLastExpandedRowId(params.node.id);
                    if (lastExpandedCell.current.rowId && lastExpandedCell.current.colId) {
                        const rowIndex = params.api.getRowNode(lastExpandedCell.current.rowId)?.rowIndex;
                        if (rowIndex !== null && rowIndex !== undefined) {
                            updateExpand({ rowIndex, column: lastExpandedCell.current.colId, api: params.api });
                            if (!lastExpandedCell.current.dontChangeFocus) {
                                params.api.setFocusedCell(rowIndex, lastExpandedCell.current.colId);
                            }
                        }
                    }
                    onEditorOpen?.();
                } else if (expandedRowId.current === params.node.id) {
                    // close detail event
                    updateLastExpandedRowId(null);
                    closeExternalView?.();
                }
            }
        },
        [closeExternalView, expandType, onEditorOpen, openExternalView, updateExpand, updateLastExpandedRowId]
    );

    const getRowClass = React.useCallback(
        (params: RowClassParams) => {
            let classes: string[] = [];

            if (getRowClassExternal) {
                classes = concat(classes, getRowClassExternal(params) ?? []);
            }

            if (gridOptionsExternal?.getRowClass) {
                classes = concat(classes, gridOptionsExternal.getRowClass(params) ?? []);
            }

            if (params.node.detail) {
                classes.push('detailed-row');
            }

            return classes;
        },
        [getRowClassExternal, gridOptionsExternal]
    );

    const getExpandRowHeight = () => {
        if (expandRowHeight.current === null) {
            const container = containerRef.current;
            expandRowHeight.current = Math.max((container && container.offsetHeight / 3) || 300, 50);
        }
        return expandRowHeight.current;
    };

    const detailCellRendererParams = React.useCallback(
        () => ({
            lastExpandedCell: lastExpandedCell.current,
            persistedEditorState,
            belowGridContainer,
            externalViewContainer,
            expandType: expandType || DetailsViewType.InGrid,
            contentViewModeType: contentViewModeType || ContentViewModeType.Full,
            locale,
            onExpandTypeChange,
            hideEmptyCells,
            renderExpandBarItems,
            onRequestPaste,
            disableJPath,
            getExpandRowHeight,
            onExpandResize: debouncedOnRowHeightChanged,
            theme,
        }),
        [
            belowGridContainer,
            externalViewContainer,
            expandType,
            contentViewModeType,
            locale,
            onExpandTypeChange,
            hideEmptyCells,
            renderExpandBarItems,
            onRequestPaste,
            disableJPath,
            debouncedOnRowHeightChanged,
            theme,
        ]
    );

    const getRowHeight = React.useCallback(
        (params: { node: IRowNode }) => {
            if (params.node.detail) {
                return expandType === DetailsViewType.InGrid ? getExpandRowHeight() : 0;
            }
            return 25; // AgGrid Default ;-(
        },
        [expandType]
    );

    const defaultColDef = React.useMemo(
        () =>
            merge({}, defaultColDefExternal ?? {}, gridOptionsExternal?.defaultColDef ?? {}, {
                suppressKeyboardEvent: keyboardHandler,
                editable: false,
                cellRenderer: closableCell,
                cellRendererParams: {
                    locale,
                    wrappedRenderer:
                        defaultColDefExternal?.cellRenderer ??
                        gridOptionsExternal?.defaultColDef?.cellRenderer ??
                        ((params: ICellRendererParams) => params.getValue?.() ?? params.value),
                },
            }),
        [defaultColDefExternal, gridOptionsExternal?.defaultColDef, keyboardHandler, locale]
    );

    const rowClassRules: RowClassRules = React.useMemo(
        () => ({
            ...rowClassRulesExternal,
            'cell-expand': (params: { node: IRowNode }) =>
                params.node.expanded && expandType === DetailsViewType.InGrid,
            'cell-expand-inline': (params: { node: IRowNode }) =>
                params.node.detail && expandType === DetailsViewType.InGrid,
        }),
        [rowClassRulesExternal, expandType]
    );

    const columnDefsWithExpandColumn = React.useMemo(() => {
        if (columnDefs?.length) {
            return [
                {
                    headerName: locale.agGrid.expand.expandRow,
                    colId: EXPAND_ROW_COL_ID,
                    onCellContextMenu: () => {},
                    valueFormatter: () => 'd',
                    cellClass: 'ms-Icon rowExpandIndicator',
                    cellRenderer: 'agGroupCellRenderer',
                    width: 21,
                    maxWidth: 21,
                    enableRowGroup: false,
                    enablePivot: false,
                    enableValue: false,
                    suppressFiltersToolPanel: true,
                    suppressColumnsToolPanel: true,
                    suppressMovable: true,
                    suppressMenu: true,
                },
                ...columnDefs,
            ];
        }
        return columnDefs;
    }, [columnDefs, locale.agGrid.expand.expandRow]);

    //TODO do we need this?
    const mergeAutoGroupColumnDef = React.useMemo(
        () =>
            merge(
                {},
                {
                    type: 'autoColumn',
                },
                props.autoGroupColumnDef
            ),
        [props.autoGroupColumnDef]
    );

    React.useEffect(() => {
        // open or close external view when expandType or externalViewContainer is updated
        if (expandType == DetailsViewType.ExternalPanel) {
            if (!externalViewContainer && expandedRowId.current) {
                openExternalView?.();
            }
        } else if (externalViewContainer) {
            closeExternalView?.();
        }
    }, [closeExternalView, expandType, externalViewContainer, openExternalView]);

    React.useEffect(() => {
        //close external view when component is unmounted
        return () => {
            closeExternalView?.();
        };
    }, [closeExternalView]);

    React.useEffect(() => {
        //redraw row when expandType is updated
        // useEffect is required because ag grid memoizes rendering
        // TODO: this might not be needed in version 31 with reactiveCustomComponents option enabled
        setTimeout(() => {
            if (gridApi.current && expandedRowId.current) {
                const node = gridApi.current.getRowNode(expandedRowId.current);
                if (node) {
                    gridApi.current.redrawRows({ rowNodes: [(node as RowNode).detailNode, node] });
                }
            }
            //row heights are cached even for previously expanded rows, so we need to reset them
            gridApi.current?.resetRowHeights();
        }, 0);
    }, [expandType, contentViewModeType]);

    React.useEffect(() => {
        //redraw row when externalViewContainer is updated
        // useEffect is required because ag grid memoizes rendering
        // TODO: this might not be needed in version 31 with reactiveCustomComponents option enabled
        setTimeout(() => {
            if (externalViewContainer && expandedRowId.current) {
                const node = gridApi.current?.getRowNode(expandedRowId.current);
                if (node && gridApi.current) {
                    gridApi.current.refreshCells({ rowNodes: [(node as RowNode).detailNode, node] });
                }
            }
        }, 0);
    }, [externalViewContainer]);

    const isBelowGridExpanded = expandType === DetailsViewType.BelowGrid && isAnyRowExpanded;

    return (
        <div
            ref={containerRef}
            {...uncontrolledFocusAttributes}
            style={{
                height: '100%',
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexFlow: 'column nowrap',
            }}
            className={expandType === DetailsViewType.InGrid ? styles.expandInGrid : null}
        >
            <SplitPane
                split="horizontal"
                className={`${styles.splitPane} ${isBelowGridExpanded ? styles.resizeEnabled : ''}`}
                allowResize={isBelowGridExpanded}
                onChange={(sizes) => {
                    if (isBelowGridExpanded) {
                        const height = Number.parseFloat(sizes[1].replace('px', ''));
                        expandRowHeight.current = height;
                    }
                }}
            >
                <Pane minSize="10px">
                    <AgGridReact
                        {...props}
                        modules={[
                            ColumnsToolPanelModule,
                            CsvExportModule,
                            ClipboardModule,
                            ClientSideRowModelModule,
                            ExcelExportModule,
                            MasterDetailModule,
                            MenuModule,
                            RangeSelectionModule,
                            RowGroupingModule,
                            SetFilterModule,
                            StatusBarModule,
                            ...(modules ?? []),
                        ]}
                        gridOptions={{ ...gridOptionsExternal, defaultColDef, getRowClass, onGridReady }}
                        navigateToNextCell={navigateToNextCell}
                        onCellDoubleClicked={onCellDoubleClicked}
                        onCellFocused={onCellFocused}
                        onRowGroupOpened={onExpandOrCollapse}
                        onCellClicked={onCellClicked}
                        detailCellRenderer={ExpandViewRenderer}
                        detailCellRendererParams={detailCellRendererParams}
                        getRowHeight={getRowHeight}
                        rowClassRules={rowClassRules}
                        masterDetail={true}
                        columnDefs={columnDefsWithExpandColumn}
                        autoGroupColumnDef={mergeAutoGroupColumnDef}
                        keepDetailRows={false}
                        onRangeSelectionChanged={onRangeSelectionChanged}
                    />
                </Pane>
                <Pane
                    key="expandPaneBelow"
                    size={(isBelowGridExpanded ? getExpandRowHeight() : 0) + 'px'}
                    minSize={isBelowGridExpanded ? '15px' : '0px'}
                >
                    <div ref={setBelowGridContainer} style={{ height: '100%' }} data-testid="expand-below-content" />
                </Pane>
            </SplitPane>
        </div>
    );
};
