import * as React from 'react';
/**
 * Get the context values used when rendering Field.
 */ export const useFieldContextValues_unstable = (state)=>{
    var _state_label, _state_label1, _state_validationMessage, _state_hint;
    const { generatedControlId, orientation, required, size, validationState } = state;
    const labelFor = (_state_label = state.label) === null || _state_label === void 0 ? void 0 : _state_label.htmlFor;
    const labelId = (_state_label1 = state.label) === null || _state_label1 === void 0 ? void 0 : _state_label1.id;
    const validationMessageId = (_state_validationMessage = state.validationMessage) === null || _state_validationMessage === void 0 ? void 0 : _state_validationMessage.id;
    const hintId = (_state_hint = state.hint) === null || _state_hint === void 0 ? void 0 : _state_hint.id;
    const field = React.useMemo(()=>({
            generatedControlId,
            hintId,
            labelFor,
            labelId,
            orientation,
            required,
            size,
            validationMessageId,
            validationState
        }), [
        generatedControlId,
        hintId,
        labelFor,
        labelId,
        orientation,
        required,
        size,
        validationMessageId,
        validationState
    ]);
    return {
        field
    };
};
