import React from 'react';
import { MessageBar, MessageBarType, Toggle } from '@fluentui/react';

import type { SelectedOption } from '@kusto/ui-components';
import { DataFrameSchema } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';

import type { RtdProviderLocale } from '../../../i18n';
import { getTypeError } from './lib';

interface ColumnDropdownProps extends TableRenderLinkFormProps {
    readonly configKey: keyof Omit<Fwk.TableRenderLinkConfig, 'disabled'>;
    readonly label: string;
    showInferAsNoSelection?: boolean;
    t: RtdProviderLocale;
}

const ColumnDropdown: React.FC<ColumnDropdownProps> = ({
    t,
    id,
    configKey,
    onChange,
    value,
    getValue,
    label,
    disabled,
    tableSchemaResult,
    showInferAsNoSelection,
}) => {
    const handleChange = React.useCallback(
        (_event: unknown, option?: SelectedOption) => {
            const prev = getValue(id);
            if (!prev || !option) {
                return;
            }

            // We're treating "infer" as no selection
            // (aka empty/no selection)
            if (option.data === null) {
                onChange(id, {
                    ...prev,
                    [configKey]: undefined,
                });
                return;
            }

            const column = option.data;
            onChange(id, {
                ...prev,
                [configKey]: column,
            });
        },
        [configKey, id, getValue, onChange]
    );

    const schema = React.useMemo((): Fwk.SingleColumnDropdownProps['schema'] => {
        return {
            kind: 'available',
            schema: tableSchemaResult,
        };
    }, [tableSchemaResult]);

    return (
        <Fwk.SingleColumnDropdown
            t={t}
            id={configKey}
            schema={schema}
            onChange={handleChange}
            label={label}
            disabled={disabled}
            inferLabel={showInferAsNoSelection ? t.rtdProvider.visuals.table.renderLinks.noSelectionLabel : undefined}
            inferColumn={showInferAsNoSelection ? value['urlColumn'] : undefined}
            selectedColumn={value[configKey] ?? null}
            addInferChoice={Boolean(showInferAsNoSelection)}
        />
    );
};

interface DisableToggleProps {
    id: number;
    value: Fwk.TableRenderLinkConfig;
    onChange(id: number, value: Fwk.TableRenderLinkConfig): void;
    getValue(id: number): undefined | Fwk.TableRenderLinkConfig;
    t: RtdProviderLocale;
}

const DisableToggle: React.FC<DisableToggleProps> = ({ id, value, onChange, getValue, t }) => {
    const onDisabledToggle = React.useCallback(() => {
        const prev = getValue(id);
        if (!prev) {
            return;
        }
        onChange(id, {
            ...prev,
            disabled: !prev.disabled,
        });
    }, [getValue, id, onChange]);

    return (
        <Toggle
            className={Fwk.tileInputClassNames.basicInput}
            checked={value.disabled}
            label={t.rtdProvider.utilButtons.disable}
            onClick={onDisabledToggle}
        />
    );
};

const TypeWarning: React.FC<Pick<TableRenderLinkFormProps, 'value' | 'tableSchemaResult' | 't'>> = ({
    value,
    tableSchemaResult,
    t,
}) => {
    const errorMsg = getTypeError(t, value, tableSchemaResult);

    if (errorMsg) {
        return (
            <MessageBar isMultiline messageBarType={MessageBarType.error}>
                {errorMsg}
            </MessageBar>
        );
    }

    return null;
};

export interface TableRenderLinkFormProps {
    id: number;
    value: Fwk.TableRenderLinkConfig;
    onChange(id: number, value: Fwk.TableRenderLinkConfig): void;
    getValue(id: number): undefined | Fwk.TableRenderLinkConfig;
    disabled: boolean;
    showDisable: boolean;
    tableSchemaResult: DataFrameSchema;
    t: RtdProviderLocale;
}

export const TableRenderLinkForm: React.FC<TableRenderLinkFormProps> = (props) => {
    const disabled = props.disabled || props.value.disabled;

    return (
        <>
            <ColumnDropdown
                {...props}
                configKey="urlColumn"
                label={props.t.rtdProvider.visuals.table.renderLinks.urlColumnLabel}
                disabled={disabled}
            />
            <ColumnDropdown
                {...props}
                configKey="displayColumn"
                showInferAsNoSelection={true}
                label={props.t.rtdProvider.visuals.table.renderLinks.displayColumnLabel}
                disabled={disabled}
            />
            {props.showDisable && <DisableToggle {...props} />}
            <TypeWarning {...props} />
        </>
    );
};
