import { Icon } from '../Icon';
import { DropDownOption, OptionsGroup } from './types';

/** Execute callback on Enter key */
export const keyboardExecute = (
    event: React.KeyboardEvent<HTMLElement>,
    callback: (event: React.BaseSyntheticEvent) => void
) => {
    if (event.key === 'Enter') {
        callback(event);
    }
};

/**
 * Get the icon of the selected option.
 * If the selected option has icon - return it. Otherwise get the icon of the closest parent with icon
 */
function findOptionIcon<T>(optionsGroups: OptionsGroup<T>[], optionToFind: DropDownOption<T>) {
    if (optionToFind.icon) {
        return optionToFind.icon;
    }

    let icon: Icon | undefined;

    function findItem(option: DropDownOption<T>): boolean {
        if (optionToFind.id === option.id) {
            icon = option.icon;
            return true;
        }

        for (const subItem of option.subMenu ?? []) {
            if (findItem(subItem)) {
                if (!icon && option.icon) {
                    icon = option.icon;
                }
                return true;
            }
        }

        return false;
    }

    for (const group of optionsGroups) {
        for (const option of group.options) {
            const found = findItem(option);
            if (found) {
                return icon;
            }
        }
    }
}

/**
 * Flatten the optionsGroups tree to an array of options.
 * @param optionsGroups Array of optionsGroups.
 * optionsGroups is an array of groups, each group has list of options.
 * Each option can have sub-menu with more options (tree structure).
 * @example
 * optionsGroups = [
 *   {
 *    options: [
 *     { key: '1', text: 'Option 1' },
 *     { key: '2', text: 'Option 2', subMenu: [ { key: '3', text: 'Option 3' } ] },
 *    ]
 *   },
 *   {
 *    options: [
 *     { key: '4', text: 'Option 4', subMenu: [...] },
 *    ]
 *   }
 *  ];
 * @returns Single-level array of options. Without the sub-menu, all options are in the same level (list structure).
 * @example
 * [
 *  { key: '1', text: 'Option 1' },
 *  { key: '2', text: 'Option 2' },
 *  { key: '3', text: 'Option 3' },
    { key: '4', text: 'Option 4' },
    ...
 * ]
 */
function flattenMenu<T>(optionsGroups: OptionsGroup<T>[]): Omit<DropDownOption<T>, 'subMenu'>[] {
    const flatMenu: DropDownOption<T>[] = [];

    const flatOption = (option: DropDownOption<T>) => {
        flatMenu.push({
            ...option,
            subMenu: undefined,
        });
        option.subMenu?.forEach(flatOption);
    };

    optionsGroups.flatMap((group) => group.options).forEach(flatOption);

    return flatMenu;
}

/**
 * Get the option by key.
 * @param optionsGroups Array of optionsGroups.
 * @param optionId The id of the option to find.
 * @returns The option with the given key or `undefined` if not found
 */
function getOption<T>(optionsGroups: OptionsGroup<T>[], optionId?: string) {
    if (optionId) {
        return flattenMenu(optionsGroups).find((option) => option.id === optionId);
    }
}

/** Get the selected option's text and icon. */
export function getSelectedOption<T>(
    optionsGroups: OptionsGroup<T>[],
    selectedOptionId?: string
): Pick<DropDownOption<T>, 'text' | 'icon' | 'selectedTooltip'> | undefined {
    if (!selectedOptionId) {
        return; // no selected option
    }

    const option = getOption(optionsGroups, selectedOptionId);
    if (!option) {
        return; // selected option not found
    }

    return {
        text: option?.text,
        icon: findOptionIcon(optionsGroups, option),
        selectedTooltip: option.selectedTooltip,
    };
}
