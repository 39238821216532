import * as React from 'react';
import { ImmutableMap } from '../../utils/ImmutableMap';
import { createCheckedItems } from '../../utils/createCheckedItems';
export function useNestedCheckedItems(props) {
    return React.useMemo(()=>createCheckedItems(props.checkedItems), [
        props.checkedItems
    ]);
}
export function createNextNestedCheckedItems(data, previousCheckedItems) {
    if (data.selectionMode === 'single') {
        return ImmutableMap.create([
            [
                data.value,
                data.checked
            ]
        ]);
    }
    if (data.selectionMode === 'multiselect') {
        return previousCheckedItems.set(data.value, data.checked);
    }
    return previousCheckedItems;
}
