import React, { CSSProperties } from 'react';
import {
    Clock16Regular,
    Clock20Regular,
    Clock24Regular,
    NumberSymbol16Regular,
    NumberSymbol20Regular,
    NumberSymbol24Regular,
    TextT16Regular,
    TextT20Regular,
    TextT24Regular,
    Timer16Regular,
    Timer20Regular,
    Timer24Regular,
} from '@fluentui/react-icons';
// These icons don’t exist in the ‘@fluentui/react-icons’ package.
import TypeBoolean from 'jsx:./booleanIcon.svg';
import TypeDynamic from 'jsx:./dynamicIcon.svg';
import TypeGuid from 'jsx:./guidIcon.svg';

import { KustoDataType } from '@kusto/utils';

export type IconSize = 'small' | 'medium' | 'large';

const ICONS = {
    Clock: {
        small: Clock16Regular,
        medium: Clock20Regular,
        large: Clock24Regular,
    },
    NumberSymbol: {
        small: NumberSymbol16Regular,
        medium: NumberSymbol20Regular,
        large: NumberSymbol24Regular,
    },
    TextT: {
        small: TextT16Regular,
        medium: TextT20Regular,
        large: TextT24Regular,
    },
    Timer: {
        small: Timer16Regular,
        medium: Timer20Regular,
        large: Timer24Regular,
    },
    TypeBoolean: {
        small: TypeBoolean,
        medium: TypeBoolean,
        large: TypeBoolean,
    },
    TypeDynamic: {
        small: TypeDynamic,
        medium: TypeDynamic,
        large: TypeDynamic,
    },
    TypeGuid: {
        small: TypeGuid,
        medium: TypeGuid,
        large: TypeGuid,
    },
} as const;

function getIconName(type: KustoDataType): keyof typeof ICONS {
    switch (type) {
        case 'datetime':
            return 'Clock';
        case 'timespan':
            return 'Timer';
        case 'decimal':
        case 'int':
        case 'long':
        case 'real':
            return 'NumberSymbol';
        case 'string':
            return 'TextT';
        case 'bool':
            return 'TypeBoolean';
        case 'dynamic':
            return 'TypeDynamic';
        case 'guid':
            return 'TypeGuid';
    }
}

interface ColumnTypeIconProps {
    type: KustoDataType;
    size?: IconSize;
    className?: string;
    style?: CSSProperties;
    color?: CSSProperties['color'];
}

export const ColumnTypeIcon: React.FunctionComponent<ColumnTypeIconProps> = ({ type, size = 'medium', ...props }) => {
    const iconName = getIconName(type);
    if (!ICONS[iconName]) {
        return <></>;
    }
    const Icon = ICONS[iconName][size];

    return <Icon {...props} />;
};
