import { getEnv, IAnyStateTreeNode } from 'mobx-state-tree';

import type { QueryCore } from '../core/core';

/**
 * Query Mobx state tree doesn't have access to a few things on
 * {@link QueryCore} to avoid circular dependencies. We could change this with
 * getters in the future if need be.
 */
export type QueryStoreEnv = Omit<QueryCore, 'store' | 'parsedVisuals'>;

/**
 * Mobx state tree environment value. We're keeping our actual store interface
 * ({@link QueryStoreEnv}) in a single property to avoid conflicts with a larger MobX State Tree
 * env our env might be a part of
 */
export interface QueryMstEnv {
    readonly queryEnv: QueryStoreEnv;
}

export function getQueryStoreEnv(target: IAnyStateTreeNode) {
    return getEnv<QueryMstEnv>(target).queryEnv;
}
