import React from 'react';
import { Tab, TabList } from '@fluentui/react-components';
import { Code20Regular, ColumnTriple20Regular, Table20Regular } from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';

import { useStrings } from '../DataExplorationModal/hooks/useStrings';
import { DataProfileTabType } from './DataProfileTab';

export interface DataProfileTabListProps {
    selectedTab: DataProfileTabType;
    onTabSelect: (tab: DataProfileTabType) => void;
    showDataGridOption: boolean;
    showKqlExamplesOption: boolean;
}

export const DataProfileTabList: React.FC<DataProfileTabListProps> = observer(function DataProfileTabList({
    selectedTab,
    onTabSelect,
    showDataGridOption,
    showKqlExamplesOption,
}) {
    const { strings } = useStrings();
    return (
        <TabList selectedValue={selectedTab} onTabSelect={(ev, { value }) => onTabSelect(value as DataProfileTabType)}>
            <Tab value="Schema" icon={<ColumnTriple20Regular />}>
                {strings.tabNames.schemaInsights}
            </Tab>
            {showDataGridOption ? (
                <Tab value="DataGrid" icon={<Table20Regular />}>
                    {strings.tabNames.dataGrid}
                </Tab>
            ) : null}
            {showKqlExamplesOption ? (
                <Tab value="KqlExamples" icon={<Code20Regular />}>
                    {strings.tabNames.kqlExamples}
                </Tab>
            ) : null}
        </TabList>
    );
});
