export function nextTypeAheadElement(treeWalker, key) {
    const keyToLowerCase = key.toLowerCase();
    const typeAheadFilter = (element)=>{
        var _element_textContent;
        return ((_element_textContent = element.textContent) === null || _element_textContent === void 0 ? void 0 : _element_textContent.trim().charAt(0).toLowerCase()) === keyToLowerCase ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    };
    let nextElement = treeWalker.nextElement(typeAheadFilter);
    if (!nextElement) {
        treeWalker.currentElement = treeWalker.root;
        nextElement = treeWalker.nextElement(typeAheadFilter);
    }
    return nextElement;
}
