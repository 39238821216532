export function useMenuListContextValues_unstable(state) {
    const { checkedValues, hasCheckmarks, hasIcons, selectRadio, setFocusByFirstCharacter, toggleCheckbox } = state;
    // This context is created with "@fluentui/react-context-selector", these is no sense to memoize it
    const menuList = {
        checkedValues,
        hasCheckmarks,
        hasIcons,
        selectRadio,
        setFocusByFirstCharacter,
        toggleCheckbox
    };
    return {
        menuList
    };
}
