import * as mobx from 'mobx';
import { isMacOs } from 'react-device-detect';

import { kweAlert, RenderHelper } from '@kusto/ui-components';
import { formatLiterals } from '@kusto/utils';

import { QueryCore } from '../../core/core';

export async function openGridLink(
    url: string,
    ctrlPressed: boolean,
    core: QueryCore,
    render: RenderHelper,
    signal: AbortSignal
) {
    core.telemetry.event('grid-link-clicked', {
        ctrlPressed,
        showCtrlNeededDialog: !ctrlPressed,
    });

    if (ctrlPressed) {
        core.config.openQueryLink(url, true);
    } else if (!mobx.runInAction(() => core.store.globalStateCache.ctrlNeededDialogShown)) {
        const ctrlOrCommand = isMacOs ? 'Command' : 'Ctrl';
        const title = formatLiterals(core.strings.query.ctrlNeededDialog$title, {
            ctrlOrCommand,
        });
        const content = formatLiterals(core.strings.query.ctrlNeededDialog$text, {
            ctrlOrCommand,
        });

        /**
         * A dialog that teaches the user to ctrl+click unknown link in Query Results Table instead of just clicking it.
         */
        const { kind } = await kweAlert(render, core.strings.utils, title, {
            content,
            closeText: core.strings.query.ctrlNeededDialog$okButton,
            signal,
        });

        if (kind === 'ok') {
            core.store.globalStateCache.setCtrlNeededDialogShown(true);
        }
    }
}
