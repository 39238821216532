.columnInfoHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    align-self: stretch;
    margin-bottom: var(--spacingVerticalM);
}

.sampleValueHeader {
    display: flex;
    align-self: stretch;
    justify-content: space-between;
}

.sampleValueContentArea {
    align-self: stretch;
    height: 195px;
    border-top: var(--strokeWidthThin) solid var(--colorNeutralStroke1);
    padding-top: var(--spacingVerticalL);
    margin: 0 var(--spacingHorizontalL) var(--spacingVerticalL);
}

.loadingIndicator {
    display: flex;
    height: 100%;
    align-self: stretch;
    justify-content: center;
    align-items: center;
}

.emptyState {
    display: flex;
    height: 100%;
    align-self: stretch;
    justify-content: center;
    align-items: center;
}

.simpleText {
    margin: 0 var(--spacingHorizontalXXL) var(--spacingVerticalL);
}

.noPadding {
    padding: 0;
}
