import React, { useMemo, useState } from 'react';
import { Dropdown, Input, Option, Subtitle1 } from '@fluentui/react-components';

import { useStrings } from '../../../hooks/useStrings';
import { translateFilterOperator } from '../../../kqlParser/pillToText';
import {
    BinaryFilterPillData,
    BinaryOperator,
    FilterPill,
    FilterPillData,
    isBinaryOperator,
    operatorsByType,
    UNARY_OPERATORS,
    UnaryOperator,
} from '../../../types/Pills.interfaces';
import { ColumnDropdown } from './helpers/ColumnDropdown';
import { KqlPreview } from './helpers/KqlPreview/KqlPreview';
import { PopoverButtons } from './helpers/PopoverButtons';
import { PopoverField } from './helpers/PopoverField';

import styles from './Popovers.module.scss';

/** Validate the pill data; return valid pill or undefined */
function validatePill(pillData: Partial<FilterPillData>): FilterPill | undefined {
    if (
        pillData.operator &&
        pillData.column &&
        (UNARY_OPERATORS.includes(pillData.operator as UnaryOperator) || (pillData as BinaryFilterPillData).value)
    ) {
        return {
            type: 'filter',
            data: {
                ...pillData,
            } as FilterPillData,
        };
    }

    return undefined;
}

export interface FilterPillPopoverProps {
    defaultValues?: Partial<FilterPillData>;
    onApply: (newPill: FilterPill) => void;
    onCancel: () => void;
    /** whether it's a new pill or editing an existing pill */
    newPill?: boolean;
}

export const FilterPillPopover: React.FC<FilterPillPopoverProps> = ({ defaultValues, onApply, onCancel, newPill }) => {
    const { strings } = useStrings();

    const [pillData, setPillData] = useState<Partial<FilterPillData>>({ ...defaultValues });

    const onFormChange = (newState: Partial<FilterPillData>) => {
        setPillData((prevState) => ({ ...prevState, ...newState }));
    };

    const operatorOptions = useMemo(() => {
        if (!pillData.column) {
            return [];
        }

        const operators = operatorsByType[pillData.column.ColumnType];
        return operators.map((operator) => ({
            label: translateFilterOperator(operator, strings),
            value: operator,
        }));
    }, [pillData.column, strings]);

    const validPill = useMemo(() => validatePill(pillData), [pillData]);

    const submitForm = () => {
        if (!validPill) {
            return; // should not get here, button disabled
        }

        onApply(validPill);
    };

    return (
        <>
            <div data-testid="filter-pill-popover" className={styles.filterPillPopover}>
                <Subtitle1>{strings.dataExplorationModal.pillsBar.filter}</Subtitle1>
                <div className={styles.filterFormContainer}>
                    {/* Column */}
                    <ColumnDropdown
                        selectedColumn={pillData.column}
                        onOptionSelect={(column) => onFormChange({ column, operator: undefined, value: undefined })}
                        isEditMode={!newPill}
                    />
                    {/* Operator */}
                    <PopoverField label={strings.dataExplorationModal.pillsBar.operator} labelId="operator-dropdown">
                        <Dropdown
                            placeholder={strings.dataExplorationModal.pillsBar.operatorDropdownPlaceholder}
                            onOptionSelect={(e, { optionValue }) =>
                                onFormChange({ operator: optionValue as BinaryOperator | UnaryOperator })
                            }
                            defaultValue={
                                // default label
                                pillData.operator ? translateFilterOperator(pillData.operator, strings) : undefined
                            }
                            defaultSelectedOptions={pillData.operator ? [pillData.operator] : undefined} // default value
                            disabled={!operatorOptions.length}
                            aria-labelledby="operator-dropdown"
                        >
                            {operatorOptions.map((option, i) => (
                                <Option value={option.value} key={i} data-testid={`operator-dropdown-option-${i}`}>
                                    {option.label}
                                </Option>
                            ))}
                        </Dropdown>
                    </PopoverField>
                    {
                        /* Value */
                        isBinaryOperator(pillData.operator) ? (
                            <PopoverField label={strings.dataExplorationModal.pillsBar.value} labelId="value-input">
                                <Input
                                    aria-labelledby="value-input"
                                    value={(pillData as BinaryFilterPillData).value ?? ''}
                                    onChange={(e, { value }) => onFormChange({ value })}
                                />
                            </PopoverField>
                        ) : null
                    }
                </div>
                <KqlPreview pill={validPill} />
            </div>
            <PopoverButtons onApply={submitForm} onCancel={onCancel} disabled={!validPill} />
        </>
    );
};
