import { makeResetStyles, makeStyles, mergeClasses, shorthands } from '@griffel/react';
import { createCustomFocusIndicatorStyle } from '@fluentui/react-tabster';
import { tokens } from '@fluentui/react-theme';
export const interactionTagSecondaryClassNames = {
    root: 'fui-InteractionTagSecondary'
};
const mediumIconSize = '20px';
const smallIconSize = '16px';
const extraSmallIconSize = '12px';
const useRootBaseClassName = makeResetStyles({
    // reset default button style:
    color: 'inherit',
    fontFamily: 'inherit',
    padding: '0px',
    borderStyle: 'none',
    appearance: 'button',
    textAlign: 'unset',
    backgroundColor: 'transparent',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    ...createCustomFocusIndicatorStyle({
        outline: `${tokens.strokeWidthThick} solid ${tokens.colorStrokeFocus2}`
    }),
    border: `${tokens.strokeWidthThin} solid ${tokens.colorTransparentStroke}`,
    // divider:
    borderLeftColor: tokens.colorNeutralStroke1,
    borderTopLeftRadius: tokens.borderRadiusNone,
    borderBottomLeftRadius: tokens.borderRadiusNone
});
const useRootStyles = makeStyles({
    filled: {
        backgroundColor: tokens.colorNeutralBackground3,
        color: tokens.colorNeutralForeground2,
        ':hover': {
            cursor: 'pointer',
            backgroundColor: tokens.colorNeutralBackground3Hover,
            color: tokens.colorNeutralForeground2BrandHover
        },
        ':active': {
            backgroundColor: tokens.colorNeutralBackground3Pressed,
            color: tokens.colorNeutralForeground2BrandPressed
        },
        '@media (forced-colors: active)': {
            ':hover': {
                backgroundColor: 'HighlightText'
            },
            ':active': {
                backgroundColor: 'HighlightText'
            }
        }
    },
    outline: {
        backgroundColor: tokens.colorSubtleBackground,
        color: tokens.colorNeutralForeground2,
        ...shorthands.borderColor(tokens.colorNeutralStroke1),
        ':hover': {
            cursor: 'pointer',
            backgroundColor: tokens.colorSubtleBackgroundHover,
            color: tokens.colorNeutralForeground2BrandHover
        },
        ':active': {
            backgroundColor: tokens.colorSubtleBackgroundPressed,
            color: tokens.colorNeutralForeground2BrandPressed
        },
        '@media (forced-colors: active)': {
            ':hover': {
                backgroundColor: 'HighlightText'
            },
            ':active': {
                backgroundColor: 'HighlightText'
            }
        }
    },
    brand: {
        backgroundColor: tokens.colorBrandBackground2,
        color: tokens.colorBrandForeground2,
        borderLeftColor: tokens.colorBrandStroke2,
        ':hover': {
            cursor: 'pointer',
            backgroundColor: tokens.colorBrandBackground2Hover,
            color: tokens.colorCompoundBrandForeground1Hover
        },
        ':active': {
            backgroundColor: tokens.colorBrandBackground2Pressed,
            color: tokens.colorCompoundBrandForeground1Pressed
        },
        '@media (forced-colors: active)': {
            ':hover': {
                backgroundColor: 'HighlightText'
            },
            ':active': {
                backgroundColor: 'HighlightText'
            }
        }
    },
    rounded: {
        borderTopRightRadius: tokens.borderRadiusMedium,
        borderBottomRightRadius: tokens.borderRadiusMedium
    },
    circular: {
        borderTopRightRadius: tokens.borderRadiusCircular,
        borderBottomRightRadius: tokens.borderRadiusCircular
    },
    medium: {
        fontSize: mediumIconSize,
        paddingLeft: '5px',
        paddingRight: '5px'
    },
    small: {
        fontSize: smallIconSize,
        paddingLeft: '3px',
        paddingRight: '3px'
    },
    'extra-small': {
        fontSize: extraSmallIconSize,
        paddingLeft: '5px',
        paddingRight: '5px'
    }
});
const useRootDisabledStyles = makeStyles({
    filled: {
        cursor: 'not-allowed',
        backgroundColor: tokens.colorNeutralBackgroundDisabled,
        color: tokens.colorNeutralForegroundDisabled,
        ...shorthands.borderColor(tokens.colorTransparentStrokeDisabled),
        borderLeftColor: tokens.colorNeutralStrokeDisabled
    },
    outline: {
        cursor: 'not-allowed',
        backgroundColor: tokens.colorSubtleBackground,
        color: tokens.colorNeutralForegroundDisabled,
        ...shorthands.borderColor(tokens.colorNeutralStrokeDisabled)
    },
    brand: {
        cursor: 'not-allowed',
        backgroundColor: tokens.colorNeutralBackgroundDisabled,
        color: tokens.colorNeutralForegroundDisabled,
        ...shorthands.borderColor(tokens.colorTransparentStrokeDisabled),
        borderLeftColor: tokens.colorNeutralStrokeDisabled
    }
});
export const useInteractionTagSecondaryStyles_unstable = (state)=>{
    const rootBaseClassName = useRootBaseClassName();
    const rootStyles = useRootStyles();
    const rootDisabledStyles = useRootDisabledStyles();
    const { shape, size, appearance } = state;
    state.root.className = mergeClasses(interactionTagSecondaryClassNames.root, rootBaseClassName, state.disabled ? rootDisabledStyles[appearance] : rootStyles[appearance], rootStyles[shape], rootStyles[size], state.root.className);
    return state;
};
