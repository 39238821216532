import React from 'react';
import { Button, Subtitle2, Toolbar, ToolbarButton, ToolbarGroup, Tooltip } from '@fluentui/react-components';
import { DrawerHeader, DrawerHeaderNavigation, DrawerHeaderTitle } from '@fluentui/react-components/unstable';
import {
    ChevronDoubleRight16Regular,
    PanelLeftContract20Regular,
    PanelRightContract20Filled,
    Table20Regular,
} from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';

import { Table } from '../../../common/types';
import { useQueryCore } from '../../../core/core';
import { DataProfileCloseButton } from './DataProfileCloseButton';
import { DataProfileRefreshButton } from './DataProfileRefreshButton';
import { TablePickerDropdown } from './TablePicker/TablePicker';

import * as styles from './DataProfileNavigation.module.scss';

interface DataProfileNavigationProps {
    clusterAlias: string;
    databaseName: string;
    databaseTables: Table[];
    selectedTable: Table;
    viewMode: 'inline' | 'overlay';
    setViewMode: (mode: 'inline' | 'overlay') => void;
    setSelectedTableId: (id: string) => void;
    onClose(): void;
}

export const DataProfileNavigation: React.FC<DataProfileNavigationProps> = observer(function DataProfileNavigation({
    viewMode,
    onClose,
    setViewMode,
    ...tablePickerProps
}) {
    const core = useQueryCore();
    const showExpandConnectionPane = core.store.layout.isLeftPaneCollapsed;

    return viewMode === 'overlay' ? (
        <DrawerHeader>
            <DrawerHeaderNavigation>
                <Toolbar className={styles.navigationToolbar}>
                    <TablePickerDropdown {...tablePickerProps} />
                    <ToolbarGroup>
                        <DataProfileRefreshButton />
                        {core.featureFlags.DataProfilePinned ? (
                            <Tooltip content={core.strings.query.dataProfile.showInlineToolTip} relationship="label">
                                <ToolbarButton
                                    appearance="subtle"
                                    icon={<PanelLeftContract20Regular />}
                                    onClick={() => setViewMode('inline')}
                                />
                            </Tooltip>
                        ) : null}
                        <DataProfileCloseButton onClose={onClose} />
                    </ToolbarGroup>
                </Toolbar>
            </DrawerHeaderNavigation>
        </DrawerHeader>
    ) : (
        <DrawerHeader
            className={`${styles.pinnedHeader} ${showExpandConnectionPane ? styles.withExpandConnectionPane : ''}`}
        >
            <DrawerHeaderTitle
                action={
                    <ToolbarGroup>
                        <DataProfileRefreshButton />
                        <Tooltip content={core.strings.query.dataProfile.showOverlayToolTip} relationship="label">
                            <ToolbarButton
                                appearance="subtle"
                                icon={<PanelRightContract20Filled />}
                                onClick={() => setViewMode('overlay')}
                            />
                        </Tooltip>
                        <DataProfileCloseButton onClose={onClose} />
                    </ToolbarGroup>
                }
            >
                <div className={styles.pinnedHeaderText}>
                    <Table20Regular />
                    <Subtitle2>
                        {showExpandConnectionPane ? (
                            <div className={styles.expandConnectionPane}>
                                <Button
                                    size="small"
                                    icon={<ChevronDoubleRight16Regular />}
                                    appearance="subtle"
                                    aria-label={core.strings.query.expandLeftPane}
                                    onClick={() => core.store.layout.toggleIsLeftPaneCollapse()}
                                />
                            </div>
                        ) : null}
                        {tablePickerProps.selectedTable.name}
                    </Subtitle2>
                </div>
            </DrawerHeaderTitle>
        </DrawerHeader>
    );
});
