@use '@kusto/style/tokens';

.type {
    margin-left: 4px;
    color: tokens.$colorNeutralForeground3;
}

.warning {
    margin-left: 4px;
    color: tokens.$colorPaletteDarkOrangeForeground3;
}
