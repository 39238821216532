import * as React from 'react';
import { createContext, Dispatch, useContext, useRef } from 'react';
import {
    ContextSelector,
    createContext as createSelectorContext,
    useContextSelector,
} from '@fluentui/react-context-selector';

import { useThunkReducer } from '../../../../hooks/useThunkReducer';
import { KweException } from '../../../../utils/errors';
import {
    CustomListDropdownMenuAction,
    customListDropdownMenuReducer,
    CustomListDropdownMenuReducerState,
} from './reducer';

type DispatchContextValue = [Dispatch<CustomListDropdownMenuAction>, () => CustomListDropdownMenuReducerState];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomListDropdownMenuContext = createSelectorContext<CustomListDropdownMenuReducerState>(undefined as any);

const noop = () => {
    throw new KweException('Custom list dropdown context default functions');
};
const CustomListDropdownMenuDispatchContext = createContext<DispatchContextValue>([noop, noop]);

export const CustomListDropdownMenuProvider: React.FC = ({ children }) => {
    const [state, dispatch, getState] = useThunkReducer(customListDropdownMenuReducer, {
        listRef: undefined,
        orderedFilteredKeys: undefined,
        activeIndex: undefined,
    });

    // Memoize the creation of this array exactly once as a minor optimization
    // This prevents updating the DispatchContext when state updates
    const dispatchArrayRef = useRef<DispatchContextValue>([dispatch, getState]);

    return (
        <CustomListDropdownMenuContext.Provider value={state}>
            <CustomListDropdownMenuDispatchContext.Provider value={dispatchArrayRef.current}>
                {children}
            </CustomListDropdownMenuDispatchContext.Provider>
        </CustomListDropdownMenuContext.Provider>
    );
};

export const useCustomListDropdownDispatch = () => useContext(CustomListDropdownMenuDispatchContext);

export const useCustomListDropdownSelector = <T,>(
    selector: ContextSelector<CustomListDropdownMenuReducerState, T>
): T => useContextSelector(CustomListDropdownMenuContext, selector);
