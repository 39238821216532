import React from 'react';

import type { DataFrameSchema, Dispose } from '@kusto/utils';

import type { RtdPluginApi } from '../dashboardApi';
import type { OkQueryResult } from '../queryResult';
import type { KweVisualFwkLocale } from '../types';
import type { VisualOptionKey } from '../visualOptions/model';
import type { VisualOptionProperties } from '../visualOptions/property';

export const SCHEMA_UNINITIALIZED: SchemaState = { kind: 'uninitialized' };

export type SchemaState =
    | { readonly kind: 'available'; readonly schema: DataFrameSchema; readonly errorMessage?: React.ReactNode }
    | { readonly kind: 'unavailable'; readonly errorMessage: React.ReactNode }
    | { readonly kind: 'uninitialized' };

export interface VisualInputSelectorOptions<C extends VisualOptionKey = VisualOptionKey, H = unknown, Temp = unknown>
    extends VisualSelectorOptions<C, H> {
    getTemp(): Temp;
    resolveSelector<T>(selector: VisualInputSelector<C, T, H, Temp>): { get: () => T };
}

export type VisualInputSelector<C extends VisualOptionKey, T, H = unknown, Temp = unknown> =
    | Exclude<T, () => void>
    | ((options: VisualInputSelectorOptions<C, H, Temp>) => T);

export interface VisualSelectorOptions<C extends VisualOptionKey = VisualOptionKey, H = unknown> {
    getVisualType(): string;
    get<K extends C>(key: K): VisualOptionProperties[K];
    getSchema(): SchemaState;
    getQueryResult(): undefined | OkQueryResult;
    getHeuristics(): H;
    resolveSelector<T>(selector: VisualSelector<C, T, H>): { get: () => T };
}

export type VisualSelector<C extends VisualOptionKey, T, H = unknown> =
    | Exclude<T, () => void>
    | ((options: VisualSelectorOptions<C, H>) => T);

export interface VisualInputModel<C extends VisualOptionKey = VisualOptionKey, H = unknown, Temp = unknown>
    extends VisualInputSelectorOptions<C, H, Temp> {
    set<K extends C>(key: K, value: VisualOptionProperties[K]): void;

    setTemp(value: Temp): void;
    /**
     * @param listener If a promise is returned, listener blocks with that
     * promise
     */
    registerFlush(listener: () => unknown): Dispose;
}

/**
 * Component that can be be displayed based on visual config layout.
 *
 * These css variables are made available: --border-color
 *
 * TODO: Pass dark/light theme as a prop
 */
export interface VisualInputProps<C extends VisualOptionKey = VisualOptionKey, H = unknown, Temp = unknown> {
    readonly t: KweVisualFwkLocale;
    /**
     * section disabled status
     */
    readonly disabled: boolean;
    /**
     * required for interactions (cross0filter/drillthrough)
     */
    readonly dashboard: RtdPluginApi | undefined;
    readonly model: VisualInputModel<C, H, Temp>;
}

export type VisualInputFC<C extends VisualOptionKey = never> = React.ComponentType<VisualInputProps<C>>;

export interface VisualInput<C extends VisualOptionKey = never, H = unknown, Temp = unknown> {
    /**
     * Used to associate the component with temp data and used as react keys.
     */
    readonly id: string;
    /**
     * Must be present is `Temp` does not extend `undefined`. Types don't
     * prevent this mistake right now.
     */
    readonly init?: (model: VisualInputModel<C, H, undefined | Temp>, prev: undefined | Temp) => Temp;

    /**
     * By default "Reset" button is enabled/disabled by deep comparing options
     * associated with all of the keys for every input in a section.
     *
     * If this is defined, it is used instead of deep comparing values on the
     * associated keys.
     */
    readonly changed?: (model: VisualInputModel<C, H, Temp>) => boolean;
    /**
     * Used for input and for identifying inputs associated with feature flags
     */
    readonly keys: readonly C[];
    /**
     * Elements will be placed in a 1-column grid with a `var(--option-spacing)`
     * gap, and `var(--left-right-padding)` on the container. Use +/- margins to
     * adjust spacing with adjacent elements.
     *
     * TODO: Passing components like this is not ideal. This should really be
     * a `render` function.
     *
     * Passing components is bad because is requires us
     * to dynamically define new components, which causes perf problems with
     * React
     */
    readonly Component: (props: VisualInputProps<C, H, Temp>) => React.ReactElement | null;
}
