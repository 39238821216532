import { KustoClientErrorDescription } from '@kusto/client';
import { AsyncResult, IKweTelemetry } from '@kusto/utils';

export class SchemaFetcher<T> {
    private readonly entitiesFetcher: () => AsyncResult<T, KustoClientErrorDescription[]>;
    private readonly jsonFetcher: () => AsyncResult<T, KustoClientErrorDescription[]>;
    private readonly telemetry: IKweTelemetry;

    constructor(
        entitiesFetcher: () => AsyncResult<T, KustoClientErrorDescription[]>,
        jsonFetcher: () => AsyncResult<T, KustoClientErrorDescription[]>,
        telemetry: IKweTelemetry
    ) {
        this.entitiesFetcher = entitiesFetcher;
        this.jsonFetcher = jsonFetcher;
        this.telemetry = telemetry;
    }

    async fetchSchema(
        useLightSchemaExploration: boolean,
        telemetryProps?: Record<string, string>
    ): AsyncResult<T, KustoClientErrorDescription[]> {
        if (useLightSchemaExploration) {
            try {
                const result = await this.entitiesFetcher();
                switch (result.kind) {
                    case 'ok':
                        return result;
                    case 'err':
                        this.telemetry.error('schemaFetcher.fetchSchemaEntities.failed.httpError', {
                            ...telemetryProps,
                            httpError: result.err,
                        });
                        break;
                    case 'abort':
                        this.telemetry.error('schemaFetcher.fetchSchemaEntities.failed.aborted', telemetryProps);
                        break;
                }
            } catch (e) {
                this.telemetry.exception('schemaFetcher.fetchSchemaEntities.failed.exception', {
                    exception: e,
                    ...telemetryProps,
                });
            }
        }
        return this.jsonFetcher();
    }
}
