import { Configuration, LogLevel } from '@azure/msal-browser';
import { SeverityLevel } from '@microsoft/applicationinsights-common';

import { getTelemetryClient } from '@kusto/query';

const { trackTrace } = getTelemetryClient({ component: 'msalConfig', flow: '' });

const levelToSeverity = (level: LogLevel): SeverityLevel | undefined => {
    switch (level) {
        case LogLevel.Error:
            return SeverityLevel.Error;
        case LogLevel.Info:
            return SeverityLevel.Information;
        case LogLevel.Verbose:
            return SeverityLevel.Verbose;
        case LogLevel.Warning:
            return SeverityLevel.Warning;
    }
    return undefined;
};

export const MsalPublicClientApplicationConfig = (
    redirectUri: string,
    clientId: string,
    useLocalStorageForTokens: boolean
): Configuration => ({
    auth: {
        clientId: clientId,
        redirectUri: redirectUri,
    },
    cache: {
        cacheLocation: useLocalStorageForTokens ? 'localStorage' : 'sessionStorage',
        // Fix for auth loop issues https://aka.ms/known-issues-on-Microsoft-Browsers-due-to-security-zones
        storeAuthStateInCookie: true,
        claimsBasedCachingEnabled: true,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string): void => {
                trackTrace(message, levelToSeverity(level), { flow: 'msalv2' });
            },
            logLevel: LogLevel.Info,
            piiLoggingEnabled: false,
        },
        // Allow more time before a token renewal response from Azure AD should be considered timed out
        loadFrameTimeout: 30000,
    },
});
