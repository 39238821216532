import * as React from 'react';
import { DirectionalHint } from '@fluentui/react/lib/Callout';

import { formatLiterals } from '../../utils/string';
import { ActionButtonWithTooltip } from './ActionButtonWithTooltip';

export interface TimeZoneLabelStrings {
    /** tooltip message. Every `${timeZone}` will be replaced with props.timeZone */
    timeZoneIndicatorToolTip: string;
}

export interface TimeZoneLabelProps {
    timeZone: string;
    openSettingsClicked?: () => void;
    strings?: TimeZoneLabelStrings;
}

/**
 * @deprecated Fluent 8
 */
export const TimeZoneLabel = (props: TimeZoneLabelProps) => {
    const tooltipText = formatLiterals(props.strings?.timeZoneIndicatorToolTip ?? '', {
        timeZone: props.timeZone,
    });

    return (
        <>
            <ActionButtonWithTooltip
                tooltipProps={{
                    content: tooltipText,
                    directionalHint: DirectionalHint.topCenter,
                }}
                key="TimeZoneIndicator"
                iconProps={{ iconName: 'Clock' }}
                text={props.timeZone}
                onClick={props.openSettingsClicked}
            />
        </>
    );
};
