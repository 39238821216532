import React from 'react';
import { Body1, Caption1 } from '@fluentui/react-components';

import { OverflowTooltip } from '../OverflowTooltip';
import { DropDownOption } from './types';

import styles from './DropDownWithActions.module.scss';

export const MenuItemContent: React.FunctionComponent<Pick<DropDownOption, 'text' | 'subText' | 'hoverContent'>> = (
    props
) => (
    <div className={styles.menuItem}>
        <span className={styles.itemText}>
            <OverflowTooltip>
                <>
                    <Body1 className={styles.text}>{props.text}</Body1>{' '}
                    {props.subText && <Caption1 className={styles.subText}>({props.subText})</Caption1>}
                </>
            </OverflowTooltip>
        </span>
        <span className={styles.hoverContent}>{props.hoverContent}</span>
    </div>
);
