.markdown {
    --md--border-color: var(--neutralTertiaryAlt);
    --md--background-emphasis-color: var(--neutralLighter);

    margin: var(--tile-full-bleed-margin);
    padding: 0 8px 8px 8px;

    overflow-y: auto;
    height: 100%;

    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }
    > *:empty:not(hr) {
        display: none;
    }

    // Markdown div is focus-able so users can scroll it using keyboards even if
    // there is nothing focus-able in the markdown. Outline is added because it's
    // focus-able.

    &:focus {
        outline: 1px dashed var(--neutralPrimary);
    }

    @supports selector(*:focus-visible) {
        &:focus {
            outline: none;
        }
        &:focus-visible {
            outline: 1px dashed var(--neutralPrimary);
        }
    }

    color: var(--neutralPrimary);

    a {
        color: var(--themePrimary);
        // Ensure link covers entire image. inline-block is used so link matches
        // images size when image is less wide than tile.
        &:has(img) {
            display: inline-block;
        }
    }

    h1,
    h2 {
        padding-bottom: 0.3em;
        border-bottom: 1px solid var(--md--border-color);
    }

    img {
        max-width: 100%;
    }

    hr {
        height: 0.25em;
        // 4px right margin added because scrollbar is right up against text
        margin: 24px 4px 24px 0;
        border: 0;
        background-color: var(--md--border-color);
    }

    blockquote {
        border-left: 4px solid var(--md--border-color);
        padding: 0 16px;
        margin: 16px 0;
        color: var(--neutralSecondary);
    }

    pre {
        padding: 8px 16px;
        background-color: var(--md--background-emphasis-color);
        border-radius: 6px;
        overflow: auto;
    }

    table {
        border-collapse: collapse;
        th {
            font-weight: bold;
        }
        th,
        td {
            padding: 6px 13px;
            border: 1px solid var(--md--border-color);
        }
        tbody tr {
            &:nth-child(2n + 1),
            &:first-child {
                background-color: var(--md--background-emphasis-color);
            }
        }
    }
    &:empty {
        overflow-y: hidden;
    }
}

.enableImageOverflow {
    img {
        max-width: none;
    }
}
