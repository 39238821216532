


















module.exports = {
  "cs": () => import("./bundles/cs.json"),
  "de": () => import("./bundles/de.json"),
  "en": () => import("./bundles/en.json"),
  "es": () => import("./bundles/es.json"),
  "fr": () => import("./bundles/fr.json"),
  "hu": () => import("./bundles/hu.json"),
  "id": () => import("./bundles/id.json"),
  "it": () => import("./bundles/it.json"),
  "ja": () => import("./bundles/ja.json"),
  "ko": () => import("./bundles/ko.json"),
  "nl": () => import("./bundles/nl.json"),
  "pl": () => import("./bundles/pl.json"),
  "pt-br": () => import("./bundles/pt-br.json"),
  "pt-pt": () => import("./bundles/pt-pt.json"),
  "ru": () => import("./bundles/ru.json"),
  "sv": () => import("./bundles/sv.json"),
  "tr": () => import("./bundles/tr.json"),
  "zh-hans": () => import("./bundles/zh-hans.json"),
  "zh-hant": () => import("./bundles/zh-hant.json")
}