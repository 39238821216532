import { mergeClasses } from '@griffel/react';
export const toolbarGroupClassNames = {
    root: 'fui-ToolbarGroup'
};
/**
 * Apply styling to the Toolbar slots based on the state
 */ export const useToolbarGroupStyles_unstable = (state)=>{
    state.root.className = mergeClasses(toolbarGroupClassNames.root, state.root.className);
    return state;
};
