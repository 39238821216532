import React from 'react';
import { Tooltip, TooltipProps } from '@fluentui/react-components';

/** Adds a Tooltip, only if `content` prop is defined. */
export const OptionalTooltip: React.FunctionComponent<
    // make props optional
    Omit<TooltipProps, 'relationship' | 'content'> & {
        /** Optional tooltip content. Only if defined this element will have tooltip. */
        content?: TooltipProps['content'];
        relationship?: TooltipProps['relationship'];
    }
> = ({ content, children, ...props }) =>
    content ? (
        <Tooltip showDelay={500} withArrow relationship="description" {...props} content={content}>
            {children as JSX.Element}
        </Tooltip>
    ) : (
        <>{children}</>
    );
