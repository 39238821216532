import React from 'react';
import { Button } from '@fluentui/react-components';
import { ArrowReset20Regular } from '@fluentui/react-icons';
import isEqual from 'lodash/isEqual';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import type { KweVisualFwkLocale } from '../../types';
import type { VisualInput } from '../../visualConfig';
import type { VisualOptionKey } from '../../visualOptions';
import type { VisualOptionsModel, VisualPluginModel } from '../model/model';
import { visualTypeVariantInput } from './VisualTypeVariant';

import * as styles from './ResetButton.module.scss';

// Return a visual type if the current visual type is not a default variant
function getDefaultVariant(paneModel: VisualOptionsModel, visualType: string): string | undefined {
    for (const l of paneModel.args.layout) {
        if (typeof l === 'object') {
            if (l.variants.includes(visualType)) {
                return l.default !== visualType ? l.default : undefined;
            }
        } else if (l === visualType) {
            break;
        }
    }
    return undefined;
}

export interface ResetButtonProps<C extends VisualOptionKey, H> {
    t: KweVisualFwkLocale;
    paneModel: VisualOptionsModel;
    inputModel: VisualPluginModel<C, H>;
    inputs: ReadonlyArray<VisualInput<C, H>>;
    disabled: boolean;
}
export const ResetButton = observer(function ResetButton<C extends VisualOptionKey, H>({
    t,
    paneModel,
    inputModel,
    inputs,
    disabled,
}: ResetButtonProps<C, H>) {
    const { nothingToReset, onReset } = React.useMemo(() => {
        return {
            nothingToReset: mobx.computed((): boolean => {
                // Keys collected here so we don't deep compare properties twice
                const keys = new Set<C>();
                for (const input of inputs) {
                    // Visual type variant input does not operate on keys
                    if (
                        input === visualTypeVariantInput &&
                        getDefaultVariant(paneModel, paneModel.pluginKey.get()) !== undefined
                    ) {
                        return false;
                    }

                    if (input.changed) {
                        if (input.changed(inputModel.getInputInstance(input))) {
                            return false;
                        }
                    } else {
                        for (const key of input.keys) {
                            keys.add(key);
                        }
                    }
                }
                for (const key of keys) {
                    // `key in paneModel.options` is because if the user hasn't
                    // made any changes there won't be a value in the pane model
                    if (
                        key in paneModel.unknown_options &&
                        !isEqual(inputModel.config.model[key], paneModel.unknown_options[key])
                    ) {
                        return false;
                    }
                }
                return true;
            }),
            onReset: mobx.action(() => {
                // Keys collected here so we don't deep compare properties twice
                const seenKeys = new Set<C>();
                for (const input of inputs) {
                    if (input === visualTypeVariantInput) {
                        const defaultVariant = getDefaultVariant(paneModel, paneModel.pluginKey.get());
                        if (defaultVariant) {
                            paneModel.pluginKey.set(defaultVariant);
                        }
                    }

                    for (const key of input.keys) {
                        if (!seenKeys.has(key)) {
                            paneModel.unknown_options[key] = inputModel.config.model[key];
                        }
                    }

                    inputModel.getInputInstance(input)?.reset();
                }
            }),
        };
    }, [inputModel, inputs, paneModel]);

    return (
        <Button
            appearance="transparent"
            icon={<ArrowReset20Regular />}
            className={styles.resetButton}
            onClick={onReset}
            disabled={disabled || nothingToReset.get()}
        >
            {t.utils.util.buttons.reset}
        </Button>
    );
});
