import Highcharts from 'highcharts';
import escape from 'lodash/escape';

import { InternalChartProps } from '../types.ts';
import { ExtendedVisualizationOptions, ExtendedVisualizationType } from '../utils/visualization.ts';
import { shouldShowMultiplePanels } from './highChartOptionsUtils.ts';

import * as styles from './styles.module.scss';

interface ExtendedChart extends Highcharts.Chart {
    crosshairConfig?: {
        element?: Highcharts.SVGElement;
        value?: string;
        isDarkTheme?: boolean;
    };
}

export interface ExtendedSeries extends Highcharts.Series {
    symbol: string;
    color: string;
}

const SUPPORTED_CHART_TYPES: Partial<ExtendedVisualizationType[]> = ['timechart', 'linechart', 'anomalychart'];

export function isCrosshairSupported(visualizationOptions: ExtendedVisualizationOptions): boolean {
    return (
        shouldShowMultiplePanels(visualizationOptions) ||
        SUPPORTED_CHART_TYPES.includes(visualizationOptions.Visualization)
    );
}

const pointMouseOverCallback: Highcharts.PointMouseOverCallbackFunction = function (
    this: Highcharts.Point,
    _event: Event
) {
    const chart = this.series.chart as ExtendedChart;
    if (!chart.crosshairConfig) {
        return;
    }

    // Draw a tooltip at the bottom of the crosshair
    const { isDarkTheme } = chart.crosshairConfig;
    chart.crosshairConfig.element = chart.renderer
        .label(chart.crosshairConfig.value!, 0, chart.plotTop, 'callout')
        .attr({
            fill: isDarkTheme ? 'black' : 'white',
            zIndex: 8,
            r: 6,
            y: chart.plotTop + chart.plotHeight + 6,
            anchorY: chart.plotTop + chart.plotHeight + 6,
        })
        .css({ color: isDarkTheme ? '#F3F2F1' : '#242424' })
        .shadow({ color: isDarkTheme ? 'white' : 'black' })
        .add();
};

const pointMouseOutCallback: Highcharts.PointMouseOutCallbackFunction = function (
    this: Highcharts.Point,
    _event: Event
) {
    const chart = this.series.chart as ExtendedChart;
    // Destroy the corsshair tooltip
    if (chart.crosshairConfig?.element) {
        chart.crosshairConfig.element.destroy();
        chart.crosshairConfig.element = undefined;
    }
};

/**
 * If crosshair is supported, returns the crosshair configuration.
 * @param visualizationOptions
 */
export function getCrosshairConfig(visualizationOptions: ExtendedVisualizationOptions): {
    crosshair?: Partial<Highcharts.AxisCrosshairOptions>;
} {
    return isCrosshairSupported(visualizationOptions)
        ? {
              crosshair: {
                  className: styles.crosshair,
                  dashStyle: 'Dot',
                  width: 1,
                  snap: false,
              },
          }
        : {};
}

/**
 * Build a chart load callback to support displaying crosshair a-axis value tooltip.
 * If crosshair isn't supported, return an empty object to prevent redundant listening and potential performance issues.
 * @param props
 * @param xAxisType
 */
export function buildChartLoadCallback(
    props: InternalChartProps,
    xAxisType: string
): {
    load?: Highcharts.ChartLoadCallbackFunction;
} {
    return isCrosshairSupported(props.visualizationOptions)
        ? {
              load: function (this: Highcharts.Chart, _event: Event) {
                  const chart = this as ExtendedChart;
                  const xAxis = chart.xAxis[0];

                  chart.crosshairConfig?.element?.destroy();
                  chart.crosshairConfig = {};
                  chart.crosshairConfig.isDarkTheme = props.isDarkTheme;

                  chart.container.addEventListener('mousemove', function (e) {
                      const crosshairPos = chart.pointer.normalize(e);
                      if (chart.crosshairConfig?.element && crosshairPos) {
                          // Calculating
                          const bBox = chart.crosshairConfig.element.getBBox();
                          const { min, max } = xAxis;
                          const xRange = max! - min!;
                          const crosshairXPos = crosshairPos.chartX - chart.plotLeft;
                          const ratio = crosshairXPos / chart.plotWidth;

                          // Formatting
                          let xValue;
                          if (xAxisType === 'datetime') {
                              // Calculate the timestamp based on the mouse position
                              const timestamp = min! + xRange * ratio;
                              const dateValue = new Date(timestamp);
                              xValue = new Intl.DateTimeFormat(props.locale, {
                                  weekday: 'long',
                                  month: 'short',
                                  day: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  second: '2-digit',
                                  hour12: false,
                                  timeZone: props.timezone,
                              }).format(dateValue);
                          } else if (xAxisType === 'category') {
                              const categoryIndex = Math.round((xAxis.categories.length - 1) * ratio);
                              xValue = xAxis.categories[categoryIndex];
                          } else {
                              // For non-datetime values, calculate xValue as number
                              xValue = (min! + xRange * ratio).toFixed(2);
                          }

                          // Redrawing
                          chart.crosshairConfig.value = xValue;
                          chart.crosshairConfig.element.attr({
                              text: chart.crosshairConfig.value,
                              x: crosshairPos.chartX - bBox.width / 2,
                              anchorX: crosshairPos.chartX,
                          });
                      }
                  });
              },
          }
        : {};
}

/**
 * Build point callbacks to support displaying crosshair a-axis value tooltip.
 * If crosshair isn't supported, return an empty object to prevent redundant listening and potential performance issues.
 * @param props
 */
export function buildPointMouseCallbacks(props: InternalChartProps): {
    mouseOver?: Highcharts.PointMouseOverCallbackFunction;
    mouseOut?: Highcharts.PointMouseOutCallbackFunction;
} {
    return isCrosshairSupported(props.visualizationOptions)
        ? {
              mouseOver: pointMouseOverCallback,
              mouseOut: pointMouseOutCallback,
          }
        : {};
}

export function convertSymbolStrToShape(symbolStr: string): string {
    switch (symbolStr) {
        case 'diamond':
            return '\u25C6'; // ◆
        case 'square':
            return '\u25A0'; // ■
        case 'triangle':
            return '\u25B2'; // ▲
        case 'triangle-down':
            return '\u25BC'; // ▼
        case 'circle':
        default:
            return '\u25CF'; // ●
    }
}

/**
 * Build a tooltip point formatter to format the tooltip content when the crosshair is enabled.
 * If crosshair isn't supported, return an empty object to prevent potential performance issues.
 * @param props
 */
export function buildTooltipPointFormatter(props: InternalChartProps): {
    useHTML?: boolean;
    pointFormatter?: Highcharts.FormatterCallbackFunction<Highcharts.Point>;
} {
    return isCrosshairSupported(props.visualizationOptions)
        ? {
              useHTML: true,
              pointFormatter: function () {
                  const series = this.series as ExtendedSeries;
                  return `<div class="${styles.tooltip}">
                        <div class="${styles.seriesLabel}">
                            <span style="color:${
                                this.color
                            }; width: 14px; text-align: center;"> ${convertSymbolStrToShape(series.symbol)} </span>
                            <span class="${styles.seriesName}">${escape(series.name)}:</span>
                        </div>
                        <div class="${styles.pointValueLabel}">${escape(this.y?.toString())}</div>
                     </div>`;
              },
          }
        : {};
}
