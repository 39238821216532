import type { KustoDataType } from '@kusto/utils';

export const ANY_TYPE = '(*)';

// used for .show schema (MaterializedView vs MaterializedViewTable)
// EntityType is used by intellisense library
export type SchemaEntityType = 'Table' | 'ExternalTable' | 'MaterializedView' | 'EntityGroup' | 'Function';

export type TableTypes = EntityType.Table | EntityType.ExternalTable | EntityType.MaterializedViewTable;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ParameterDefaultValue = any;

export type KustoInputParameterSchema = ScalarParameter | TabularParameter;

export interface ScalarParameter extends BaseInputParam {
    Type?: string;
    CslType?: string;
    CslDefaultValue?: string;
}

export interface TabularParameter extends BaseInputParam {
    Columns: KustoInputParameterSchema[];
}

interface BaseInputParam {
    Name: string;
}

export interface OrderedColumn {
    Name: string;
    Type: string;
    CslType?: KustoDataType;
    DocString?: string;
}

export interface KustoFunctionSchema {
    Name: string;
    InputParameters?: KustoInputParameterSchema[];
    Body: string;
    Folder?: string;
    DocString?: string;
    FunctionKind: string;
    OutputColumns: string[];
}

export class BaseTableSchema {
    Name: string | undefined;
    OrderedColumns!: OrderedColumn[];
    Folder?: string;
    DocString?: string;
    tableType!: TableTypes;
    entityType?: SchemaEntityType;
}

export class TableSchema extends BaseTableSchema {}

export class ExternalTableSchema extends BaseTableSchema {}

class MaterializedViewSchema extends BaseTableSchema {}

export interface ShowDatabasesCommandResult {
    name: string;
    prettyName: string | undefined;
    accessMode?: string;
    majorVersion: number;
    minorVersion: number;
    suspensionState: SuspensionState | undefined;
}

export interface KustoDatabaseSchema {
    Name: string;
    Tables: {
        [key: string]: TableSchema;
    };
    ExternalTables?: {
        [key: string]: ExternalTableSchema;
    };
    MaterializedViews?: {
        [key: string]: MaterializedViewSchema;
    };
    PrettyName?: string;
    MajorVersion: number;
    MinorVersion: number;
    Functions: { [key: string]: KustoFunctionSchema } | undefined; // Only App Insights proxy returns undefined
    EntityGroups: { [key: string]: string[] } | undefined; // Only App Insights proxy returns undefined
    DatabaseAccessMode?: string;
}

export interface DatabaseSchemaById {
    [key: string]: KustoDatabaseSchema;
}

export interface KustoClusterSchema {
    Databases: DatabaseSchemaById;
}

export interface SchemaEntityCommandResult {
    databaseName: string;
    entityType: string;
    name: string;
    docString: string;
    folder: string;
    cslInputSchema: string;
    content: string;
    cslOutputSchema: string;
}

export interface SuspensionState {
    CMKSuspensionStart?: Date;
}

export enum EntityType {
    Cluster = 'Cluster',
    Database = 'Database',
    Table = 'Table',
    ExternalTable = 'ExternalTable',
    MaterializedViewTable = 'MaterializedViewTable',
    Function = 'Function',
    ViewFunction = 'ViewFunction',
    Column = 'Column',
    Folder = 'Folder',
    FunctionsFolder = 'FunctionsFolder',
    TablesFolder = 'TablesFolder',
    ExternalTableFolder = 'ExternalTableFolder',
    MaterializedViewTableFolder = 'MaterializedViewTableFolder',
    LoadingIndicator = 'LoadingIndicator',
    EntityGroup = 'EntityGroup',
    EntityGroupFolder = 'EntityGroupFolder',
    EntityGroupMember = 'EntityGroupMember',
}

export type KustoType =
    | 'bool'
    | 'datetime'
    | 'decimal'
    | 'dynamic'
    | 'float'
    | 'guid'
    | 'int'
    | 'int16'
    | 'long'
    | 'real'
    | 'string'
    | 'timespan'
    | 'uint'
    | 'uint8'
    | 'uint16'
    | 'ulong';

export type DotNetType =
    | 'Newtonsoft.Json.Linq.JArray'
    | 'Newtonsoft.Json.Linq.JObject'
    | 'Newtonsoft.Json.Linq.JToken'
    | 'System.Boolean'
    | 'System.Byte'
    | 'System.Data.SqlTypes.SqlDecimal'
    | 'System.DateTime'
    | 'System.Double'
    | 'System.Guid'
    | 'System.Int16'
    | 'System.Int32'
    | 'System.Int64'
    | 'System.Object'
    | 'System.SByte'
    | 'System.Single'
    | 'System.String'
    | 'System.TimeSpan'
    | 'System.UInt16'
    | 'System.UInt32'
    | 'System.UInt64';

export function isTabularParameter(param: KustoInputParameterSchema): param is TabularParameter {
    return (param as TabularParameter).Columns !== undefined;
}
