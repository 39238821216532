const emptyImmutableMap = createImmutableMap();
/**
 * properly creates an ImmutableMap instance from an iterable
 */ function createImmutableMap(iterable) {
    const internalMap = new Map(iterable);
    return dangerouslyCreateImmutableMap(internalMap);
}
/**
 * Avoid using *dangerouslyCreateImmutableMap*, since this method will expose internally used set, use  createImmutableMap instead,
 * @param internalMap - a set that is used internally to store values.
 */ function dangerouslyCreateImmutableMap(internalMap) {
    return {
        size: internalMap.size,
        set: (key, value)=>{
            const nextSet = new Map(internalMap);
            nextSet.set(key, value);
            return dangerouslyCreateImmutableMap(nextSet);
        },
        get: (key)=>internalMap.get(key),
        clear: ()=>emptyImmutableMap,
        delete (value) {
            const nextSet = new Map(internalMap);
            nextSet.delete(value);
            return dangerouslyCreateImmutableMap(nextSet);
        },
        has: (value)=>internalMap.has(value),
        [Symbol.iterator]: ()=>internalMap[Symbol.iterator](),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dangerouslyGetInternalMap_unstable: ()=>internalMap
    };
}
function isImmutableMap(value) {
    return typeof value === 'object' && value !== null && 'dangerouslyGetInternalMap_unstable' in value;
}
export const ImmutableMap = {
    empty: emptyImmutableMap,
    create: createImmutableMap,
    isImmutableMap,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    dangerouslyCreate_unstable: dangerouslyCreateImmutableMap
};
