.kustoAlignmentRight {
    text-align: right;
    :global(.cf-wrapper) {
        justify-content: flex-end;
    }
    :global(.cf-content) {
        text-align: right;
    }
}

.agGridWithKustoData {
    width: 100%;
    height: 100%;
    min-height: 0;
    box-sizing: border-box;
    position: relative;
}

.hidden {
    display: none;
}
