import { Err, err, formatLiterals, IExceptionTelemetry, IKweTelemetry, KweUtilsLocale } from '@kusto/utils';

const defaultCidPrefix = 'UKN';

/**
 * Generates an unique correlation vector for a given error to be reported
 * @param cidPrefix Short identifier that should identify which component/area
 * the cid is for. E.g "DVEB" or "REB".
 */
export function generateCid(cidPrefix = defaultCidPrefix) {
    return `${cidPrefix}${crypto.randomUUID()}`;
}

/**
 * If a bug occurs, display this to the users instead of whatever developer
 * facing information we have.
 *
 * If you need to log the exception, considering using {@link handleException}
 * instead.
 */
export function unexpectedErrorMessage(t: KweUtilsLocale, cid: string) {
    return formatLiterals(t.utils.util.error.unidentifiedErrorMessageWithCid, { cid });
}

/**
 * Logs an exception and creates a user facing message.
 *
 * If you're writing a try/catch block, consider using
 * {@link handleFunctionCrashes} instead.
 *
 * If you're inside of an exception boundary, consider throwing an error.
 *
 * @example
 * function getFirstValue(arr: string[]): Result<string> {
 *     const value = arr[0];
 *
 *     // Should be impossible
 *     if (value === undefined) {
 *        return err(handleException({
 *         t,
 *         telemetry,
 *         telemetryName: 'Value missing from array',
 *       })
 *     );
 *
 *     return ok(value);
 * }
 */
export function handleException(options: {
    t: KweUtilsLocale;
    exception?: unknown;
    telemetryName?: string;
    errorMessage?: undefined;
    cidPrefix?: string;
    telemetry: IKweTelemetry;
    properties?: IExceptionTelemetry;
}): string;
export function handleException<T = string>(options: {
    t: KweUtilsLocale;
    exception?: unknown;
    telemetryName?: string;
    // Exclude<> is because when the generic argument is `undefined` or
    // `Function`, this must be a function that returns that value.
    errorMessage: Exclude<T, undefined | (() => unknown)> | ((t: KweUtilsLocale, cid: string) => T);
    cidPrefix?: string;
    telemetry: IKweTelemetry;
    properties?: IExceptionTelemetry;
}): T;
export function handleException<T = string>({
    t,
    exception,
    telemetryName = String(exception),
    errorMessage = unexpectedErrorMessage as (t: KweUtilsLocale, cid: string) => T,
    cidPrefix = defaultCidPrefix,
    telemetry,
    properties,
}: {
    t: KweUtilsLocale;
    exception?: unknown;
    telemetryName?: string;
    errorMessage?: T | ((t: KweUtilsLocale, cid: string) => T);
    cidPrefix?: string;
    telemetry: IKweTelemetry;
    properties?: IExceptionTelemetry;
}): T {
    const cid = generateCid(cidPrefix);

    telemetry.exception(telemetryName, { ...properties, exception, cidPrefix, cid });

    if (typeof errorMessage === 'function') {
        errorMessage = (errorMessage as (t: KweUtilsLocale, cid: string) => T)(t, cid);
    }

    return errorMessage as T;
}

/**
 * Handle function crashes. Useful when we want to prevent some complex code
 * from crashing the whole app.
 *
 * Like a React error boundary, but for any function.
 *
 * @example
 * ```
 * const res = await handleFunctionCrashes(
 *   t,
 *   'myKustoQuery',
 *   telemetry,
 *   async (): AsyncResult<MyResult> => {
 *      // Run kusto query
 *   }
 * );
 *
 * if (res.kind === 'err') {
 *   // If a crash occurred, it's been logged, and res.err is set to a
 *   // user-facing message.
 *   kwePrompt(res.err);
 * }
 * ```
 */
export function handleFunctionCrashes<T>(
    t: KweUtilsLocale,
    telemetry: IKweTelemetry,
    callback: () => Promise<T>,
    options?: {
        cidPrefix?: string;
        telemetryName?: string;
        properties?: () => IExceptionTelemetry;
    }
): Promise<T | Err<string>>;
export function handleFunctionCrashes<T, E>(
    t: KweUtilsLocale,
    telemetry: IKweTelemetry,
    callback: () => Promise<T>,
    options: {
        cidPrefix?: string;
        telemetryName?: string;
        returnOnCrash: (t: KweUtilsLocale, cid: string) => E;
        properties?: () => IExceptionTelemetry;
    }
): Promise<T | E>;
export function handleFunctionCrashes<T>(
    t: KweUtilsLocale,
    telemetry: IKweTelemetry,
    callback: () => Promise<T> | T,
    options?: {
        cidPrefix?: string;
        telemetryName?: string;
        properties?: () => IExceptionTelemetry;
    }
): T | Err<string> | Promise<T | Err<string>>;
export function handleFunctionCrashes<T, E>(
    t: KweUtilsLocale,
    telemetry: IKweTelemetry,
    callback: () => Promise<T> | T,
    options: {
        cidPrefix?: string;
        telemetryName?: string;
        returnOnCrash: (t: KweUtilsLocale, cid: string) => E;
        properties?: () => IExceptionTelemetry;
    }
): T | E | Promise<T | E>;
export function handleFunctionCrashes<T>(
    t: KweUtilsLocale,
    telemetry: IKweTelemetry,
    callback: () => T,
    options?: {
        cidPrefix?: string;
        telemetryName?: string;
        properties?: () => IExceptionTelemetry;
    }
): T | Err<string>;
export function handleFunctionCrashes<T, E>(
    t: KweUtilsLocale,
    telemetry: IKweTelemetry,
    callback: () => T,
    options: {
        cidPrefix?: string;
        telemetryName?: string;
        returnOnCrash: (t: KweUtilsLocale, cid: string) => E;
        properties?: () => IExceptionTelemetry;
    }
): T | E;
export function handleFunctionCrashes<T>(
    t: KweUtilsLocale,
    telemetry: IKweTelemetry,
    callback: () => Promise<T> | T,
    options?: {
        cidPrefix?: string;
        telemetryName?: string;
        returnOnCrash?: (t: KweUtilsLocale, cid: string) => unknown;
        properties?: () => IExceptionTelemetry;
    }
): T | unknown | Promise<T | unknown> {
    let res: Promise<T> | T;
    // Try/catch to handle sync crashes
    try {
        res = callback();
    } catch (e) {
        return handleException({
            t,
            exception: e,
            telemetry,
            cidPrefix: options?.cidPrefix,
            telemetryName: options?.telemetryName,
            errorMessage: (t, cid) => options?.returnOnCrash ?? err(unexpectedErrorMessage(t, cid)),
            properties: options?.properties?.(),
        });
    }

    // Handle async crashes
    if (res instanceof Promise) {
        return res.catch((e) => {
            return handleException({
                t,
                exception: e,
                telemetry,
                cidPrefix: options?.cidPrefix,
                telemetryName: options?.telemetryName,
                errorMessage: (t, cid) => options?.returnOnCrash ?? err(unexpectedErrorMessage(t, cid)),
                properties: options?.properties?.(),
            });
        });
    }

    return res;
}
