import * as React from 'react';
import { useFocusFinders } from '@fluentui/react-tabster';
import { useFluent_unstable } from '@fluentui/react-shared-contexts';
/**
 * Focus first element on content when dialog is opened,
 */ export function useFocusFirstElement(open, modalType) {
    const { findFirstFocusable } = useFocusFinders();
    const { targetDocument } = useFluent_unstable();
    const dialogRef = React.useRef(null);
    React.useEffect(()=>{
        if (!open) {
            return;
        }
        const element = dialogRef.current && findFirstFocusable(dialogRef.current);
        if (element) {
            element.focus();
        } else {
            var _dialogRef_current;
            (_dialogRef_current = dialogRef.current) === null || _dialogRef_current === void 0 ? void 0 : _dialogRef_current.focus(); // https://github.com/microsoft/fluentui/issues/25150
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.warn(`@fluentui/react-dialog [useFocusFirstElement]:
A Dialog should have at least one focusable element inside DialogSurface.
Please add at least a close button either on \`DialogTitle\` action slot or inside \`DialogActions\``);
            }
        }
    }, [
        findFirstFocusable,
        open,
        modalType,
        targetDocument
    ]);
    return dialogRef;
}
