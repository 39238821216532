import { useControllableState } from '@fluentui/react-utilities';
import * as React from 'react';
import { ImmutableSet } from '../utils/ImmutableSet';
import { createOpenItems } from '../utils/createOpenItems';
/**
 * @internal
 */ export function useControllableOpenItems(props) {
    return useControllableState({
        state: React.useMemo(()=>props.openItems && createOpenItems(props.openItems), [
            props.openItems
        ]),
        defaultState: ()=>createOpenItems(props.defaultOpenItems),
        initialState: ImmutableSet.empty
    });
}
/**
 * @internal
 */ export function createNextOpenItems(data, previousOpenItems) {
    if (data.value === null) {
        return previousOpenItems;
    }
    const previousOpenItemsHasId = previousOpenItems.has(data.value);
    if (data.open ? previousOpenItemsHasId : !previousOpenItemsHasId) {
        return previousOpenItems;
    }
    const nextOpenItems = ImmutableSet.create(previousOpenItems);
    return data.open ? nextOpenItems.add(data.value) : nextOpenItems.delete(data.value);
}
