import { TabsHeader } from './TabsHeader_v2';

export * from './commonComponents';
export * from './charting';
export * from './QueryResults/QueryResults';
export * from './QueryResults/QueryResultsBar';
export * from './QueryResults/GridStatusPanel';
export * from './QueryResults/QueryResultGrid';
export * from './QueryResults/ExternalResizablePanelProvider';
export * from './KustoEditor';
export * from './RightSidePanel';
export * from './TabHeaders';
export * from './tabs/TabBar';
export * from './SplitPane';
export * from './connectionExplorer';
export * from './DataProfile';

export const TabsHeaderV2 = TabsHeader;
