import * as React from 'react';
export function useMotionImperativeRef(imperativeRef) {
    const animationRef = React.useRef();
    React.useImperativeHandle(imperativeRef, ()=>({
            setPlayState: (state)=>{
                if (state === 'running') {
                    var _animationRef_current;
                    (_animationRef_current = animationRef.current) === null || _animationRef_current === void 0 ? void 0 : _animationRef_current.play();
                }
                if (state === 'paused') {
                    var _animationRef_current1;
                    (_animationRef_current1 = animationRef.current) === null || _animationRef_current1 === void 0 ? void 0 : _animationRef_current1.pause();
                }
            },
            setPlaybackRate: (rate)=>{
                if (animationRef.current) {
                    animationRef.current.playbackRate = rate;
                }
            }
        }));
    return animationRef;
}
