import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { getTelemetryClient } from '@kusto/query';

import { deleteActiveTenant } from './currentTenantStorage';

const { trackTrace } = getTelemetryClient({
    component: 'tenantUtils',
    flow: '',
});

/**
 * Delete the active tenant and reload the page without tenant param in the query string.
 * This will cause the page to login with the home tenant.
 */
export const reloadWithHomeTenant = (loginHint?: string) => {
    const url = new URL(window.location.href);

    // delete tenant
    deleteActiveTenant();
    url.searchParams.delete('tenant');

    // add/delete login_hint
    url.searchParams.delete('login_hint');
    if (loginHint) {
        url.searchParams.append('login_hint', loginHint);
    }

    // reload
    if (url.toString() !== window.location.href) {
        window.location.replace(url.toString());
    } else {
        window.location.reload();
    }
};

/**
 * Reload the page with the tenant, and maybe the loginHint, passed in the query string.
 */
export const reloadWithTenant = (tenant: string, loginHint?: string) => {
    trackTrace(`reloadWithTenant: called`, SeverityLevel.Information);
    const url = new URL(window.location.href);

    // set tenant
    url.searchParams.set('tenant', tenant);

    // add/delete login_hint
    url.searchParams.delete('login_hint');
    if (loginHint) {
        url.searchParams.append('login_hint', loginHint);
    }

    // reload
    trackTrace(`reloadWithTenant: redirecting to ${url.toString()}`, SeverityLevel.Verbose);
    if (url.toString() !== window.location.href) {
        window.location.replace(url.toString());
    } else {
        window.location.reload();
    }
};
