import { EntityType } from '../../../common';
import { Database, QueryStoreEnv } from '../../../stores';
import { ITreeEntityMapper } from '../actions';
import { RowDataType } from '../RowDataType';
import { getBaseRootPath } from '../utils';
import { iconMapper } from './IconMapper';
import { RowDataTypeBase } from './RowDataTypeBase';
import { getFromCacheOrCreate } from './RowDataTypeCache';

export class DatabaseLoadingRowDataType extends RowDataTypeBase {
    private constructor(database: Database, baseRootPath: string[], treeEntityMapper: ITreeEntityMapper) {
        super(
            DatabaseLoadingRowDataType.buildId(database.id),
            [...baseRootPath, 'Loading'],
            'Loading',
            EntityType.LoadingIndicator,
            database,
            iconMapper.LoadingIndicator,
            treeEntityMapper
        );

        this.actions = treeEntityMapper.getActions(database.entityType);
    }

    private static buildId(databaseId: string) {
        return `$loadingIndicator_${databaseId}`;
    }

    public static fromCacheOrCreate(
        env: QueryStoreEnv,
        database: Database,
        treeEntityMapper: ITreeEntityMapper
    ): RowDataType {
        const id = DatabaseLoadingRowDataType.buildId(database.id);
        return getFromCacheOrCreate(
            database,
            id,
            () => new DatabaseLoadingRowDataType(database, getBaseRootPath(env, database), treeEntityMapper)
        );
    }
}
