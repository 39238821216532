import React from 'react';
import { observer } from 'mobx-react-lite';

import type { DropdownWithSearchOption } from '@kusto/ui-components';
import { KweException, useTelemetry } from '@kusto/utils';

import { tileInputClassNames } from '../../inputLib/createTileInput';
import type { VisualInput, VisualInputFC } from '../../visualConfig';
import { useVisualOptionsModel } from '../model/ModelProvider';
import { VisualizationOption, VisualTypePicker } from './VisualTypePicker';

const VisualTypeVariant: VisualInputFC = observer(function VisualTypeVariant({ t, disabled }) {
    const { telemetry } = useTelemetry();
    const model = useVisualOptionsModel();

    const visualType = model.pluginKey.get();

    const visuals = model.args.parsedVisuals;
    const layout = model.args.layout;
    const optionsMap = React.useMemo(
        () =>
            layout.reduce<Record<string, DropdownWithSearchOption[]>>((acc, option) => {
                if (typeof option === 'object') {
                    const options = option.variants.map((variant) => {
                        const config = visuals[variant];
                        if (!config) {
                            throw new KweException();
                        }
                        return {
                            key: variant,
                            text: config.label,
                            content: <VisualizationOption label={config.label} iconName={config.iconName} />,
                        };
                    });
                    for (const variant of option.variants) {
                        acc[variant] = options;
                    }
                }
                return acc;
            }, {}),
        [layout, visuals]
    );

    const options = optionsMap[visualType];

    if (options === undefined) {
        const message = 'Visual type variant input should only be used when there are variants';
        if (process.env.NODE_ENV !== 'production') {
            throw new KweException(message);
        } else {
            telemetry.exception(message);
            return null;
        }
    }

    return (
        <VisualTypePicker
            className={tileInputClassNames.basicInput}
            selectedKeys={visualType}
            options={options}
            label={t.visualFwk.visualConfig.visualTypeVariableDropdownLabel}
            disabled={disabled}
            model={model}
        />
    );
});

export const visualTypeVariantInput: VisualInput<never> = {
    id: 'visualTypeVariant',
    keys: [],
    Component: VisualTypeVariant,
};
