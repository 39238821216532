import type { CellClickedEvent } from '@ag-grid-community/core';
import type { IContextualMenuItem } from '@fluentui/react';
// lodash import is restricted to avoid importing the entire library, so
// type-only imports are safe
// eslint-disable-next-line no-restricted-imports
import type { Dictionary } from 'lodash';
import groupBy from 'lodash/groupBy';

import type * as client from '@kusto/client';
import { ok } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';

import type { RtdProviderLocale } from '../../i18n';
import type { RtdDataVisualProps } from './model';
import { ErrorStrings, extractRtdValue } from './util';

/**
 * column => context menu items
 */
type QueryResultGridDrillthrough = Record<string, (event: CellClickedEvent) => IContextualMenuItem[]>;

export function createDrillthroughConfig(
    columns: readonly client.KustoColumn[],
    visualOptions: RtdDataVisualProps['visualOptions'],
    dashboardApi: undefined | Fwk.DashboardVisualApi,
    strings: RtdProviderLocale
): undefined | QueryResultGridDrillthrough {
    const drillthroughConfigs = visualOptions.drillthroughDisabled ? null : visualOptions.drillthrough;
    const applyDrillthrough = dashboardApi?.drillthrough;
    const pagesRecord = dashboardApi?.state.pagesMap;
    if (!drillthroughConfigs || !applyDrillthrough || !pagesRecord) {
        return;
    }

    const errorStrings: ErrorStrings = {
        noColumn: strings.rtdProvider.visuals.crossFilter$errors$noColumn,
        missingColumn: strings.rtdProvider.visuals.crossFilter$errors$missingColumn,
    };

    const propertyToPairsPagesIndex = new Map<
        string,
        {
            destinationPages: Fwk.DrillthroughConfig['destinationPages'];
            pairsByPageId: Map<string, Fwk.ReadyDrillthroughPair[]>;
        }
    >();

    for (const d of drillthroughConfigs) {
        if (Fwk.drillthroughIsReady(d)) {
            // Only grabbing the ready pairs
            const readyPairs = d.pairs.filter(Fwk.drillthroughPairIsReady);
            const readyPairsByProperty: Dictionary<Fwk.ReadyDrillthroughPair[]> = groupBy(readyPairs, 'property');

            for (const [property, pairs] of Object.entries(readyPairsByProperty)) {
                const prev = propertyToPairsPagesIndex.get(property);

                if (!prev) {
                    propertyToPairsPagesIndex.set(property, {
                        destinationPages: d.destinationPages,
                        pairsByPageId: new Map(Array.from(d.destinationPages).map((pageId: string) => [pageId, pairs])),
                    });
                } else {
                    const nextPairsByPageId = new Map(prev.pairsByPageId);

                    for (const pageId of d.destinationPages) {
                        const prevPairs = nextPairsByPageId.get(pageId);

                        if (prevPairs) {
                            nextPairsByPageId.set(pageId, [...prevPairs, ...pairs]);
                        } else {
                            nextPairsByPageId.set(pageId, pairs);
                        }
                    }

                    const nextData = {
                        pairsByPageId: nextPairsByPageId,
                        destinationPages: new Set([...prev.destinationPages, ...d.destinationPages]),
                    };

                    propertyToPairsPagesIndex.set(property, nextData);
                }
            }
        }
    }

    const config: QueryResultGridDrillthrough = {};
    for (const [property, data] of propertyToPairsPagesIndex.entries()) {
        config[property] = createCallbackFn(
            data.pairsByPageId,
            data.destinationPages,
            columns,
            applyDrillthrough,
            pagesRecord,
            errorStrings
        );
    }

    if (Object.keys(config).length === 0) {
        return undefined;
    }
    return config;
}

function createCallbackFn(
    pairsByPageId: Map<string, Fwk.ReadyDrillthroughPair[]>,
    destinationPages: Fwk.DrillthroughConfig['destinationPages'],
    columns: readonly client.KustoColumn[],
    applyDrillthrough: NonNullable<Fwk.DashboardVisualApi['drillthrough']>,
    pagesRecord: ReadonlyMap<string, Fwk.RtdPage>,
    errorStrings: ErrorStrings
): QueryResultGridDrillthrough[string] {
    return (event: CellClickedEvent) => {
        const items: IContextualMenuItem[] = [];

        const onClick = (pairs: Fwk.ReadyDrillthroughPair[], destinationPageId: string) => {
            const value = extractRtdValue(columns, event, errorStrings);
            const pairsResult = pairs.map((p) => ok({ parameterId: p.parameterId, value }));
            applyDrillthrough(ok(pairsResult), destinationPageId);
        };

        for (const pageId of destinationPages) {
            const pageName = pagesRecord.get(pageId)?.name;
            if (!pageName) {
                continue;
            }

            const pairs = pairsByPageId.get(pageId);

            if (!pairs?.length) {
                continue;
            }

            items.push({
                key: `drillthrough-menuitem-${pageId}`,
                onClick: () => onClick(pairs, pageId),
                text: pageName,
            });
        }

        return items;
    };
}
