import * as React from 'react';
import { useMergedRefs, useEventCallback, useControllableState, getIntrinsicElementProps, slot } from '@fluentui/react-utilities';
import { useArrowNavigationGroup, useFocusFinders } from '@fluentui/react-tabster';
import { useHasParentContext } from '@fluentui/react-context-selector';
import { useMenuContext_unstable } from '../../contexts/menuContext';
import { MenuContext } from '../../contexts/menuContext';
/**
 * Returns the props and state required to render the component
 */ export const useMenuList_unstable = (props, ref)=>{
    const { findAllFocusable } = useFocusFinders();
    const menuContext = useMenuContextSelectors();
    const hasMenuContext = useHasParentContext(MenuContext);
    const focusAttributes = useArrowNavigationGroup({
        circular: true,
        ignoreDefaultKeydown: {
            Tab: hasMenuContext
        }
    });
    if (usingPropsAndMenuContext(props, menuContext, hasMenuContext)) {
        // TODO throw warnings in development safely
        // eslint-disable-next-line no-console
        console.warn('You are using both MenuList and Menu props, we recommend you to use Menu props when available');
    }
    const innerRef = React.useRef(null);
    const setFocusByFirstCharacter = React.useCallback((e, itemEl)=>{
        // TODO use some kind of children registration to reduce dependency on DOM roles
        const acceptedRoles = [
            'menuitem',
            'menuitemcheckbox',
            'menuitemradio'
        ];
        if (!innerRef.current) {
            return;
        }
        const menuItems = findAllFocusable(innerRef.current, (el)=>el.hasAttribute('role') && acceptedRoles.indexOf(el.getAttribute('role')) !== -1);
        let startIndex = menuItems.indexOf(itemEl) + 1;
        if (startIndex === menuItems.length) {
            startIndex = 0;
        }
        const firstChars = menuItems.map((menuItem)=>{
            var _menuItem_textContent;
            return (_menuItem_textContent = menuItem.textContent) === null || _menuItem_textContent === void 0 ? void 0 : _menuItem_textContent.charAt(0).toLowerCase();
        });
        const char = e.key.toLowerCase();
        const getIndexFirstChars = (start, firstChar)=>{
            for(let i = start; i < firstChars.length; i++){
                if (char === firstChars[i]) {
                    return i;
                }
            }
            return -1;
        };
        // Check remaining slots in the menu
        let index = getIndexFirstChars(startIndex, char);
        // If not found in remaining slots, check from beginning
        if (index === -1) {
            index = getIndexFirstChars(0, char);
        }
        // If match was found...
        if (index > -1) {
            menuItems[index].focus();
        }
    }, [
        findAllFocusable
    ]);
    var _props_checkedValues;
    const [checkedValues, setCheckedValues] = useControllableState({
        state: (_props_checkedValues = props.checkedValues) !== null && _props_checkedValues !== void 0 ? _props_checkedValues : hasMenuContext ? menuContext.checkedValues : undefined,
        defaultState: props.defaultCheckedValues,
        initialState: {}
    });
    var _props_onCheckedValueChange;
    const handleCheckedValueChange = (_props_onCheckedValueChange = props.onCheckedValueChange) !== null && _props_onCheckedValueChange !== void 0 ? _props_onCheckedValueChange : hasMenuContext ? menuContext.onCheckedValueChange : undefined;
    const toggleCheckbox = useEventCallback((e, name, value, checked)=>{
        const checkedItems = (checkedValues === null || checkedValues === void 0 ? void 0 : checkedValues[name]) || [];
        const newCheckedItems = [
            ...checkedItems
        ];
        if (checked) {
            newCheckedItems.splice(newCheckedItems.indexOf(value), 1);
        } else {
            newCheckedItems.push(value);
        }
        handleCheckedValueChange === null || handleCheckedValueChange === void 0 ? void 0 : handleCheckedValueChange(e, {
            name,
            checkedItems: newCheckedItems
        });
        setCheckedValues((s)=>({
                ...s,
                [name]: newCheckedItems
            }));
    });
    const selectRadio = useEventCallback((e, name, value)=>{
        const newCheckedItems = [
            value
        ];
        setCheckedValues((s)=>({
                ...s,
                [name]: newCheckedItems
            }));
        handleCheckedValueChange === null || handleCheckedValueChange === void 0 ? void 0 : handleCheckedValueChange(e, {
            name,
            checkedItems: newCheckedItems
        });
    });
    return {
        components: {
            root: 'div'
        },
        root: slot.always(getIntrinsicElementProps('div', {
            // FIXME:
            // `ref` is wrongly assigned to be `HTMLElement` instead of `HTMLDivElement`
            // but since it would be a breaking change to fix it, we are casting ref to it's proper type
            ref: useMergedRefs(ref, innerRef),
            role: 'menu',
            'aria-labelledby': menuContext.triggerId,
            ...focusAttributes,
            ...props
        }), {
            elementType: 'div'
        }),
        hasIcons: menuContext.hasIcons || false,
        hasCheckmarks: menuContext.hasCheckmarks || false,
        checkedValues,
        hasMenuContext,
        setFocusByFirstCharacter,
        selectRadio,
        toggleCheckbox
    };
};
/**
 * Adds some sugar to fetching multiple context selector values
 */ const useMenuContextSelectors = ()=>{
    const checkedValues = useMenuContext_unstable((context)=>context.checkedValues);
    const onCheckedValueChange = useMenuContext_unstable((context)=>context.onCheckedValueChange);
    const triggerId = useMenuContext_unstable((context)=>context.triggerId);
    const hasIcons = useMenuContext_unstable((context)=>context.hasIcons);
    const hasCheckmarks = useMenuContext_unstable((context)=>context.hasCheckmarks);
    return {
        checkedValues,
        onCheckedValueChange,
        triggerId,
        hasIcons,
        hasCheckmarks
    };
};
/**
 * Helper function to detect if props and MenuContext values are both used
 */ const usingPropsAndMenuContext = (props, contextValue, hasMenuContext)=>{
    let isUsingPropsAndContext = false;
    for(const val in contextValue){
        if (props[val]) {
            isUsingPropsAndContext = true;
        }
    }
    return hasMenuContext && isUsingPropsAndContext;
};
