import type { ColorRule } from '@kusto/visual-fwk';

/**
 * @deprecated Use {@link ColorRule.ColorRule} instead
 */
export interface VColorRule {
    readonly id: string;
    readonly ruleType: 'colorByValue' | 'colorByCondition';
    readonly applyTo: 'rows' | 'cells';

    /**
     * When undefined there is no indicator
     */
    readonly options: ConditionalFormattingOptions;
}

/**
 * @deprecated Use {@link ColorRule.ColorRuleByCondition} instead
 */
export interface VColorRuleByCondition extends VColorRule {
    readonly ruleType: 'colorByCondition';
    readonly conditions: readonly ColorRule.Condition[];
    readonly chainingOperator: 'and' | 'or';
    readonly applyToColumn: string | null;
}

/**
 * @deprecated Use {@link ColorRule.ColorRuleByValue} instead
 */
export interface VColorRuleByValue extends VColorRule {
    readonly ruleType: 'colorByValue';
    readonly column: string | null;
    readonly themeName?: ColorRule.Theme;
    readonly minValue?: string;
    readonly maxValue?: string;
    readonly reverseTheme?: boolean;
}

/**
 * @deprecated Use {@link ColorRule.UColorRule} instead
 */
export type VUColorRule = VColorRuleByCondition | VColorRuleByValue;

export interface ConditionalFormattingOptions {
    /**
     * Name registered with @fluentui/react
     */
    readonly iconName?: string;
    readonly color?: ColorRule.Color;
    readonly subLabel?: string;
    readonly colorStyle?: ColorRule.ColorStyle;
    readonly hideText?: boolean;
    readonly colorByValueClassName?: string;
}
