import { DataFrame, err, formatLiterals, ok, Result } from '@kusto/utils';

export interface Markdown_Heuristics {
    value: Result<string | null, { columnError: boolean; value: string }>;
    inferColumnName?: string;
}

interface LocalStrings {
    errors: {
        noRows: string;
        configuredColumnNotAvailable: string;
        configuredColumnNotStringType: string;
        noColumns: string;
        noStringColumns: string;
    };
}

export function markdown_getInferredTableValue(
    strings: LocalStrings,
    dataFrame: DataFrame,
    xColumn: null | string
): Markdown_Heuristics {
    if (dataFrame.size === 0) {
        return { value: err({ columnError: false, value: strings.errors.noRows }) };
    }

    let value: undefined | Markdown_Heuristics['value'];
    let inferColumnName: undefined | string;

    if (xColumn !== null) {
        const frame = dataFrame.fields.find((c) => c.name === xColumn);
        if (!frame) {
            value = err({
                columnError: true,
                value: formatLiterals(strings.errors.configuredColumnNotAvailable, {
                    columnName: xColumn,
                }),
            });
        } else {
            if (frame.type !== 'string') {
                inferColumnName = frame.name;
                value = err({
                    columnError: true,
                    value: formatLiterals(strings.errors.configuredColumnNotStringType, {
                        columnName: xColumn,
                    }),
                });
            } else {
                value = ok(frame.values[0]);
            }
        }
    } else if (dataFrame.fields.length === 0) {
        value = err({ columnError: false, value: strings.errors.noColumns });
    } else {
        // Changes to how this logic (infer logic) works are breaking changes.
        // Think _very_ hard before doing this, as visuals relying on `infer`
        // will change.

        for (const frame of dataFrame.fields) {
            if (frame.type === 'string') {
                inferColumnName = frame.name;
                value = ok(frame.values[0]);
                break;
            }
        }

        value ??= err({ columnError: false, value: strings.errors.noStringColumns });
    }

    return { value, inferColumnName };
}
