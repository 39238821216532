import React, { useMemo } from 'react';
import { DirectionalHint, ICalloutProps } from '@fluentui/react/lib/Callout';
import type { IDropdownProps } from '@fluentui/react/lib/Dropdown';

import { IKweTelemetry, useTelemetry } from '../../telemetry';
import { UnsafeHeaderDropdown } from './components/HeaderDropdown';
import { RTD_DROPDOWN } from './constants';
import { InputDropdown, InputDropdownAdditionalProps } from './InputDropdown';
import { SearchDropdown } from './SearchDropdown';
import { RTDDropdownOption } from './types';

const baseCalloutProps: ICalloutProps = {
    calloutMaxHeight: 470,
    directionalHint: DirectionalHint.bottomCenter,
};

const defaultBacking: RTDBackingDropdown = {
    type: 'standard',
};

export interface InputDropdownOptions extends InputDropdownAdditionalProps {
    /**
     * Dropdown that contains an input box
     */
    type: 'input';
    /**
     * The input's `type` prop
     */
    inputType?: string;
}

export type RTDBackingDropdown =
    | {
          /**
           * **Default**
           *
           * Dropdown that dynamically switches between fabric and filterable
           */
          type: 'standard';
          /**
           * The minimum size at which to switch to the virtualized SearchDropdown. If not provided, will switch at 10 items
           */
          minFilterSize?: number;
      }
    | {
          /**
           * Dropdown that is only a traditional Fabric dropdown
           */
          type: 'force-fabric';
      }
    | {
          /**
           * Dropdown that is only a virtualized+filterable dropdown
           */
          type: 'force-filterable';
      }
    | InputDropdownOptions;

export interface RTDDropdownProps extends IDropdownProps {
    options: RTDDropdownOption[];

    onRenderHeader?: () => React.ReactElement | null;

    /**
     * Defaults to `force-fabric`
     */
    backingDropdown?: RTDBackingDropdown;

    defaultSelectedKey?: string;
    defaultSelectedKeys?: string[];
    selectedKey?: string | null;
    selectedKeys?: string[] | null;
}

function renderComponent(props: RTDDropdownProps, calloutProps: ICalloutProps, telemetry: IKweTelemetry) {
    const backing = props.backingDropdown ?? defaultBacking;
    switch (backing.type) {
        case 'force-fabric':
            return <UnsafeHeaderDropdown {...props} calloutProps={calloutProps} />;
        case 'standard': {
            const minFilterSize = backing.minFilterSize ?? RTD_DROPDOWN.minFilterSize;

            if (minFilterSize !== undefined && props.options.length >= minFilterSize) {
                return <SearchDropdown {...props} calloutProps={calloutProps} telemetry={telemetry} />;
            }

            return <UnsafeHeaderDropdown {...props} calloutProps={calloutProps} />;
        }
        case 'force-filterable':
            return <SearchDropdown {...props} calloutProps={calloutProps} telemetry={telemetry} />;
        case 'input':
            return <InputDropdown {...props} calloutProps={calloutProps} {...backing} telemetry={telemetry} />;
    }
}

// We explicitly want to optimize for scenarios where there are no calloutProps,
// so we avoid running a useMemo unnecessarily
const RTDDropdownWithoutCalloutProps: React.FC<Omit<RTDDropdownProps, 'calloutProps'>> = (props) => {
    const { telemetry } = useTelemetry();
    return renderComponent(props, baseCalloutProps, telemetry);
};

const RTDDropdownWithCalloutProps: React.FC<RTDDropdownProps> = (props) => {
    const { telemetry } = useTelemetry();
    const memoedCalloutProps = useMemo(() => ({ ...baseCalloutProps, ...props.calloutProps }), [props.calloutProps]);

    return renderComponent(props, memoedCalloutProps, telemetry);
};

export const RTDDropdown: React.FC<RTDDropdownProps> = (props) =>
    props.calloutProps ? <RTDDropdownWithCalloutProps {...props} /> : <RTDDropdownWithoutCalloutProps {...props} />;
