import * as React from 'react';
const MenuGroupContext = React.createContext(undefined);
const menuGroupContextDefaultValue = {
    headerId: ''
};
export const MenuGroupContextProvider = MenuGroupContext.Provider;
export const useMenuGroupContext_unstable = ()=>{
    var _React_useContext;
    return (_React_useContext = React.useContext(MenuGroupContext)) !== null && _React_useContext !== void 0 ? _React_useContext : menuGroupContextDefaultValue;
};
