@use '../configurationList/styles.module.scss' as configList;

.configItemColor {
    width: 16px;
    height: 16px;
}

.addConfigItemButton {
    margin-bottom: calc(-1 * configList.$button-recommended-removed-spacing) + 4px;
}
