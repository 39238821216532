import React from 'react';

import { assertNever, FieldSchema, KustoDataType } from '@kusto/utils';

import type { KweVisualFwkLocale } from '../../types';
import { SchemaState } from '../../visualConfig/input';
import { ColumnStatus, DropdownOptionWithType } from './dropdownWithTypeText';

export const INFER_OPTION_KEY = 'infer';

export function createUnspecifiedColumnInferOption(t: KweVisualFwkLocale, inferLabel?: string): ETPColumnOption {
    return {
        key: INFER_OPTION_KEY,
        text: inferLabel ?? t.visualFwk.visualConfig.dropdownInputInferOption,
        data: null,
    };
}

/**
 * Column names need to be prefixed before being used as option keys to
 * guarantee we don't have collisions.
 */
export function columnOptionKey(columnName: string) {
    return `column-${columnName}`;
}

export function columnsTooltipFromSchema(
    strings: KweVisualFwkLocale,
    schema: SchemaState
): React.ReactNode | undefined {
    switch (schema.kind) {
        case 'uninitialized':
            return strings.visualFwk.visualConfig.schemaLoadingMessage;
        case 'unavailable':
            return schema.errorMessage;
        case 'available':
            if (schema.schema.length === 0) {
                return strings.visualFwk.visualConfig.noColumnsAvailableMessage;
            }
            return undefined;
        default:
            assertNever(schema);
    }
}

export type ETPColumnOption = DropdownOptionWithType & {
    data: null | string;
};

/**
 * @param disabled Defaults to `false`
 */
export function columnDropdownOption(columnName: string, status: ColumnStatus, disabled = false): ETPColumnOption {
    return {
        key: columnOptionKey(columnName),
        text: columnName,
        data: columnName,
        status,
        disabled,
    };
}

export function getSchemaDerived(
    t: KweVisualFwkLocale,
    schema: SchemaState,
    selectOptionDisabled?: (column: FieldSchema) => boolean
): {
    typesByColumn: Map<string, KustoDataType>;
    options: ETPColumnOption[];
    disabledReason: React.ReactNode | undefined;
} {
    const disabledReason = columnsTooltipFromSchema(t, schema);
    if (schema.kind !== 'available') {
        return { typesByColumn: new Map(), options: [], disabledReason };
    }
    const options = schema.schema.map((c) =>
        columnDropdownOption(c.name, { kind: 'available', type: c.type }, selectOptionDisabled?.(c))
    );

    const typesByColumn = new Map(schema.schema.map((o) => [o.name, o.type] as const));

    return { typesByColumn, options, disabledReason };
}

function* getColumnTypes(
    inferColumn: string | readonly string[],
    typesByColumn: ReadonlyMap<string, KustoDataType>
): Generator<[columnName: string, type: undefined | KustoDataType]> {
    if (typeof inferColumn === 'string') {
        yield [inferColumn, typesByColumn.get(inferColumn)];
    } else {
        for (const columnName of inferColumn) {
            yield [columnName, typesByColumn.get(columnName)];
        }
    }
}

export function resolveInferOption(
    t: KweVisualFwkLocale,
    inferColumn: undefined | string | readonly string[],
    typesByColumn: ReadonlyMap<string, KustoDataType>,
    inferLabel?: string
): ETPColumnOption {
    const baseInferOption = createUnspecifiedColumnInferOption(t, inferLabel);

    if (!inferColumn) {
        return baseInferOption;
    }
    const textItems: ETPColumnOption['inferredTextItems'] = [];

    if (inferColumn) {
        for (const [columnName, type] of getColumnTypes(inferColumn, typesByColumn)) {
            const status: ColumnStatus = type ? { kind: 'available', type } : { kind: 'unavailable' };

            textItems.push({
                text: columnName,
                status,
            });
        }
    }

    return {
        ...baseInferOption,
        inferredTextItems: textItems,
        inferLabel,
    };
}
