import * as React from 'react';
import { slot } from '@fluentui/react-utilities';
import { useButton_unstable } from '../Button/index';
/**
 * Given user props, defines default props for the CompoundButton, calls useButtonState, and returns processed state.
 * @param props - User provided props to the CompoundButton component.
 * @param ref - User provided ref to be passed to the CompoundButton component.
 */ export const useCompoundButton_unstable = ({ contentContainer, secondaryContent, ...props }, ref)=>{
    var _state_icon, _state_secondaryContent;
    const state = {
        // Button state
        ...useButton_unstable(props, ref),
        // Slots definition
        components: {
            root: 'button',
            icon: 'span',
            contentContainer: 'span',
            secondaryContent: 'span'
        },
        contentContainer: slot.always(contentContainer, {
            elementType: 'span'
        }),
        secondaryContent: slot.optional(secondaryContent, {
            elementType: 'span'
        })
    };
    // Recalculate iconOnly to take into account secondaryContent.
    state.iconOnly = Boolean(((_state_icon = state.icon) === null || _state_icon === void 0 ? void 0 : _state_icon.children) && !props.children && !((_state_secondaryContent = state.secondaryContent) === null || _state_secondaryContent === void 0 ? void 0 : _state_secondaryContent.children));
    return state;
};
