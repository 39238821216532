import { DataTable } from './KustoClient';

export interface Memory {
    hits?: number;
    misses?: number;
    total?: number;
}

export interface Disk {
    hits?: number;
    misses?: number;
    total?: number;
}

export interface Cache {
    memory?: Memory;
    disk?: Disk;
    shards?: Shards;
}

export interface ShardStats {
    hitbytes?: number;
    missbytes?: number;
    retrievebytes?: number;
}

export interface Shards {
    hot?: ShardStats;
    cold?: ShardStats;
    bypassbytes?: number;
}

export interface Cpu {
    user: string;
    kernel: string;
    'total cpu'?: string;
}

export interface ResourceUsage {
    cache?: Cache;
    cpu?: Cpu;
    memory?: {
        peak_per_node?: number;
    };
}

export interface DatasetStatistic {
    table_row_count?: number;
    table_size?: number;
}

export interface QueryResourceConsumptionData {
    ExecutionTime?: number;
    resource_usage?: ResourceUsage;
    input_dataset_statistics?: InputDatasetStatistics;
    dataset_statistics?: DatasetStatistic[];
}

export interface InputDatasetStatistics {
    extents: DatasetExtentsStatistics;
    rows: DatasetRowsStatistics;
    rowstores: DatasetRowStoresStatistics;
    shards: DatasetShardsStatistics;
    external_data: ExternalDataStatistics;
}

export interface ExternalDataStatistics {
    downloaded_bytes?: number;
    downloaded_items?: number;
    iterated_artifacts?: number;
}

export interface DatasetShardsStatistics {
    queries_generic: number;
    queries_specialized: number;
}

export interface DatasetRowStoresStatistics {
    scanned_rows: number;
    scanned_values_size: number;
}

export interface DatasetRowsStatistics {
    total: number;
    scanned: number;
}

export interface DatasetExtentsStatistics {
    total: number;
    scanned: number;
    scanned_min_datetime: string; // e.g. "0001-01-01T00:00:00.0000000Z"
    scanned_max_datetime: string; // e.g. "0001-01-01T00:00:00.0000000Z"
}

/**
 * extract query resource consumption data from json to a strongly typed object.
 */
export const getQueryResourceConsumption = (dataTableFrames: DataTable[]): QueryResourceConsumptionData | null => {
    const completionInfoFrame = dataTableFrames.filter((frame) => frame.TableKind === 'QueryCompletionInformation')[0];

    if (!completionInfoFrame) {
        return null;
    }

    const eventTypeNameIndex = 10;
    const payloadIndex = 11;
    // cannot have OneApiError here so we can cast it out.
    // Added while enabling lints
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rows = completionInfoFrame.Rows as { [key: string]: any }[];
    const queryStatsRow = rows.filter((row) => row[eventTypeNameIndex] === 'QueryResourceConsumption')[0];
    if (!queryStatsRow) {
        return null;
    }

    const resourceConsumption = JSON.parse(queryStatsRow[payloadIndex]) as QueryResourceConsumptionData;
    return resourceConsumption;
};
