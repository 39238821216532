import { resolvePositioningShorthand, usePositioning } from '@fluentui/react-positioning';
import * as React from 'react';
export function useComboboxPositioning(props) {
    const { positioning } = props;
    // Set a default set of fallback positions to try if the dropdown does not fit on screen
    const fallbackPositions = [
        'above',
        'after',
        'after-top',
        'before',
        'before-top'
    ];
    // popper options
    const popperOptions = {
        position: 'below',
        align: 'start',
        offset: {
            crossAxis: 0,
            mainAxis: 2
        },
        fallbackPositions,
        matchTargetSize: 'width',
        ...resolvePositioningShorthand(positioning)
    };
    const { targetRef, containerRef } = usePositioning(popperOptions);
    return [
        containerRef,
        targetRef
    ];
}
