import React from 'react';
import ReactMonacoEditor, { EditorConstructionOptions } from 'react-monaco-editor';

import { UIComponentsError } from '../../utils/UIComponentsError';
import { getTheme } from '../MonacoEditorUtils/utils';

import styles from '../MonacoEditorUtils/MonacoEditor.module.scss';

const defaultEditorOptions: EditorConstructionOptions = {
    lineNumbers: 'off',
    minimap: { enabled: false },
    readOnly: true,
    automaticLayout: true,
};

function prettifyJSON(val: string) {
    try {
        const res = JSON.parse(val);
        return JSON.stringify(res, null, 2);
    } catch (exception) {
        throw new UIComponentsError({
            componentName: 'MonacoEditorJSON',
            message: 'Failed to parse json',
            exception,
        });
    }
}

export interface MonacoEditorJSONProps {
    /** Text to display in the editor */
    json: string;
    /** Dark theme */
    isDark?: boolean;
    /** Custom options */
    editorOptions?: EditorConstructionOptions;
}

/**
 * Prettify a JSON string and show it in a Monaco Editor.
 */
export const MonacoEditorJSON: React.FunctionComponent<MonacoEditorJSONProps> = ({ json, isDark, editorOptions }) => {
    return (
        <ReactMonacoEditor
            language="json"
            value={prettifyJSON(json)}
            theme={getTheme(isDark)}
            className={styles.monacoEditor}
            options={{ ...defaultEditorOptions, ...editorOptions }}
        />
    );
};
