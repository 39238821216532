import * as React from 'react';
import { classNamesFunction, IButtonStyles, IStyle, IStyleFunctionOrObject, ITheme, styled } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import { isMacOs } from 'react-device-detect';

import { formatLiterals, IconButtonWithTooltip } from '@kusto/utils';

import { ConfigurableBarItemsFunc } from '../common/barConfig.ts';
import { useQueryCore } from '../core/core';
import { getTelemetryClient } from '../utils/telemetryClient';
import { TabsHeaderV2 } from './index.ts';
import { iconsStyle, TabBar, TabBarProps, TabBarStyles } from './tabs/TabBar';
import { TabItemPropsBase } from './tabs/TabItem';

const { trackEvent } = getTelemetryClient({ component: 'TabHeaders', flow: '' });
const undoIconButtonRef = React.createRef<HTMLDivElement>();

// $G$ need to delete, used by tabsV1 only
export type ExtendsBarConfig<
    BaseConfigFunc,
    MoreKeys extends string,
    MoreProps = unknown
> = BaseConfigFunc extends ConfigurableBarItemsFunc<infer T, infer P>
    ? ConfigurableBarItemsFunc<T | MoreKeys, P & MoreProps>
    : never;

export interface TabsProps {
    additionalItems?: ExtendsBarConfig<TabBarProps['additionalBarItems'], 'reopenLastTabs', { onNewTab: () => void }>;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<TabsStyleProps, TabHeadersStyles>;

    // The version prop used by Trident, while KWE uses featureFlags:NewTabs
    /** Temporary prop to allow viewing old and new tabs-bar design. By default use the 'v1' design. */
    version?: 'v1' | 'v2';
}

/**
 * Creating types for styled(...). Calling styled(...) registers into theme-changed-events.
 * Without calling styled(...), changing theme from light to dark and vice versa
 * will not refresh the component.
 */
type TabsStyleProps = Required<Pick<TabsProps, 'theme'>>;
export interface TabHeadersStyles {
    root: IStyle;
    subComponentStyles: {
        tabBar: TabBarStyles;
    };
}
const getStyles = (_props: TabsStyleProps): Record<string, IStyle> => ({});

const getClassNames = classNamesFunction<TabsStyleProps, TabHeadersStyles>();

/**
 * This component renders the tab bar (no content, just the tabs themselves).
 * @param props tabs from the store
 */
const TabHeadersBase: React.FC<TabsProps> = observer(function TabHeadersBase(props: TabsProps) {
    const core = useQueryCore();
    const tabs = core.store.tabs;

    const onLinkClick = (itemKey: string): void => {
        const tab = tabs.tabs.filter((t) => t.id === itemKey)[0];
        if (tab) {
            tabs.setTabInContext(tab);
        }
    };

    const tabHeaders = tabs.tabs.map((tab) => {
        const tabProps: TabItemPropsBase = {
            text: tab.calcTitle,
            tabKey: tab.id,

            onClose: () => tabs.removeTab(tab),
        };

        return tabProps;
    });

    const tabInContextId = tabs.tabInContext.id;

    /**
     * The Undo icon is using the same style as in TabBar's icons, with one difference - the icon's color is
     * light blue instead of neutralSecondary.
     */
    const getUndoIconStyle = (): IButtonStyles => {
        const undoIconStyle = iconsStyle(props.theme!, props.theme?.palette?.themePrimary);
        return undoIconStyle;
    };

    const latestTabButton = (
        <IconButtonWithTooltip
            styles={getUndoIconStyle()}
            tooltipProps={{
                content: formatLiterals(core.strings.query.undoClosedTabButtonTooltip, {
                    ctrlOrCommand: isMacOs ? 'Command' : 'Ctrl',
                }),
            }}
            ariaLabel={core.strings.query.undoClosedTabButtonAria}
            iconProps={{ iconName: 'Undo' }}
            disabled={tabs.recentlyCloseTabs.length === 0}
            onClick={() => {
                trackEvent('UndoCloseTabClicked', { trigger: 'button' });
                tabs.undoTabRemoval();
            }}
            key="UndoCloseTabButton"
        />
    );

    // do not show buttons in tab bar if we're already showing them at page header.
    const additionalBarItems: TabBarProps['additionalBarItems'] = (itemsProps, defaultItems) => {
        const tabsItems = {
            ...defaultItems,
            reopenLastTabs: {
                key: 'reopenLastTabs',
                onRender: () => latestTabButton,
                order: 100,
            },
        };
        return (
            props.additionalItems?.(
                { ...itemsProps, onNewTab: () => tabs.addTab({ origin: 'additionalBarItems.onNewTab' }) },
                tabsItems
            ) ?? tabsItems
        );
    };

    const onTabsReorder = React.useCallback(
        (tabIds: string[]) => {
            const tabsStore = tabs;
            tabsStore.setTabs(tabIds);
            trackEvent('tabs-reordered');
        },
        [tabs]
    );

    const classNames = getClassNames(props.styles, { theme: props.theme! });
    return (
        <div role="navigation" ref={undoIconButtonRef} aria-label={core.strings.query.tabs} className={classNames.root}>
            <TabBar
                core={core}
                strings={core.strings.query}
                selectedKey={tabInContextId}
                onClick={onLinkClick}
                additionalBarItems={additionalBarItems}
                onTabsReorder={onTabsReorder}
                onCloseAllTabs={tabs.removeAllTabs}
                onNewTab={() => tabs.addTab({ origin: 'tabHeader.onNewTab' })}
                items={tabHeaders}
                styles={classNames.subComponentStyles.tabBar}
            />
        </div>
    );
});

const TabHeadersV1 = styled(TabHeadersBase, getStyles);

export const TabHeaders: React.FunctionComponent<TabsProps> = (props) => {
    const core = useQueryCore();
    if (props.version === 'v2' || core.featureFlags.NewTabs) {
        return <TabsHeaderV2 />;
    }

    return <TabHeadersV1 {...props} />;
};
