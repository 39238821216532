import React from 'react';
import type { Template as StringTemplate } from 'es6-template-strings';
import compileStrTemplate from 'es6-template-strings/compile';
import resolveStrTemplate from 'es6-template-strings/resolve-to-string';
import moment from 'moment';

import type { ReadonlyRecord } from '../types';

export function normalizeSpaces(text: string) {
    return text.replace(/\s\s+/g, ' ').trim();
}

export function formatToSeconds(milliseconds: number | null | undefined, precision = 3): string {
    if (!milliseconds) {
        return '';
    }

    return (milliseconds / 1000).toFixed(precision);
}

const momentFormat = (val: number, timeFormatter: string) =>
    moment(val)
        .utc()
        .format(val % 1000 === 0 ? timeFormatter : timeFormatter + '.SSS');

export function formatMillisecondsToTimeString(ms: number | null | undefined) {
    if (ms == null) {
        return '';
    }

    const absVal = Math.abs(ms);
    const hours = (absVal / (1000 * 60 * 60)) >> 0;
    const rest = momentFormat(absVal, 'mm:ss');
    return hours ? hours + ':' + rest : rest;
}

export function isFalsyOrWhitespace(str: string | null | undefined) {
    return !str || str.trim() === '';
}

export function isWhitespace(str: string) {
    return str.trim() === '';
}

// Dictionary for literals (Functions) to be used as caching in formatLiterals()
const LiteralsDictionary: Map<string, StringTemplate> = new Map<string, StringTemplate>();

/**
 *
 * Decorates a string with the following rules:
 * Text to be decorated should start and end with "*"
 * Italic decoration should start and end with "%"
 *
 * Warning: this function removes "*" if comes in start and end of a phrase
 */
export const decorateString = (text: string) => {
    const DECORATION_SYMBOL = '*';
    const ITALIC_SYMBOL = '%';
    const splitText = text.split(DECORATION_SYMBOL);
    const splitJSX: React.ReactNode[] = splitText.map<React.ReactNode>((item: string, index) => {
        if (item.startsWith(ITALIC_SYMBOL) && item.endsWith(ITALIC_SYMBOL)) {
            return <i key={index}>{item.substring(1, item.length - 1)}</i>;
        } else {
            return <span key={index}>{item}</span>;
        }
    });
    return splitJSX.reduce((prev, curr) => [prev, ' ', curr]);
};

/** use this function to convert regular string to string literals
 * string literals are saved in the string.json as regular strings and we need to use them as literals.
 * eval is not allowed so we convert them to literals with a Function.
 * example: formatLiterals('Welcome ${firstName} ${lastName}', {firstName: "John", lastName: "Doe"})
 * result: 'Welcome John Doe'
 */
export function formatLiterals(text: string, params?: ReadonlyRecord<string, string>): string {
    let compiledTemplate = undefined;
    let result = '';
    // new Function() is used for injecting template literals for a regular string.
    // this is basically eval, a heavy operation and cached using the text as key.
    // when we change the lang, the text will be changed and new function will be created and cached
    compiledTemplate = LiteralsDictionary.get(text);
    if (compiledTemplate === undefined) {
        // eslint-disable-next-line no-new-func
        compiledTemplate = compileStrTemplate(text);
        LiteralsDictionary.set(text, compiledTemplate);
    }
    // try/catch in case the string replacement fails, like when the $(param) had a typo
    try {
        result = resolveStrTemplate(compiledTemplate, params ? params : {});
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
        result = text;
    }
    return result;
}

// Reference in Kusto-service : https://msazure.visualstudio.com/One/_git/Azure-Kusto-Service/commit/c3d252b74bd611b7a0d424380cbf5305d901436d?refName=refs%2Fheads%2Fdev
// file: https://msazure.visualstudio.com/One/_git/Azure-Kusto-Service?path=%2FSrc%2FCommon%2FKusto.Cloud.Platform%2FText%2FExtendedString.cs&version=GBdev
const AlternativeSpaceChars = [
    '\u00a0',
    '\u1680',
    '\u180E',
    '\u2000',
    '\u2001',
    '\u2002',
    '\u2003',
    '\u2004',
    '\u2005',
    '\u2006',
    '\u2007',
    '\u2008',
    '\u2009',
    '\u200a',
    '\u200b',
    '\u202f',
    '\u205f',
    '\u3000',
    '\ufeff',
];
export const unifySpaces = (str: string) => {
    const spacesRegExp = new RegExp(AlternativeSpaceChars.join('|'), 'g');
    return str.replace(spacesRegExp, ' ');
};
const AlternativeNewlineChars = ['\v', '\r\n?'];
export const unifyNewLines = (str: string) => {
    const newLineRegExp = new RegExp(AlternativeNewlineChars.join('|'), 'g');
    return str.replace(newLineRegExp, '\n');
};

export const unifyWhiteSpaces = (str: string) => unifySpaces(unifyNewLines(str || ''));

export function caseInsensitiveComparer(a: string, b: string) {
    a = a.toLowerCase();
    b = b.toLowerCase();
    if (a === b) {
        return 0;
    }

    return a < b ? -1 : 1;
}

const isValidUrl = (candidate: string) => {
    try {
        new URL(candidate);
        return true;
    } catch (err) {
        return false;
    }
};

/**
 * Splits the input string into parts containing URLs and text, preserving brackets.
 *
 * This function processes an input string and splits it into parts based on the presence of URLs.
 * URLs can be optionally enclosed in square brackets `[]` or parentheses `()`. Each part is classified
 * as either a URL or plain text. If a URL is enclosed in brackets, the brackets are preserved and split
 * into separate parts. The function returns an array of objects, where each object has a type ('url' or 'text')
 * and a value (the corresponding string).
 *
 * @param input - The input string to process.
 * @returns An array of objects containing URLs and text parts.
 *          Each object has the following structure:
 *          {
 *              type: 'url' | 'text';
 *              value: string;
 *          }
 */
export function extractUrlsAndTexts(input: string): {
    type: 'url' | 'text';
    value: string;
}[] {
    // The urlRegex matches URLs starting with http or https, optionally inside brackets or parentheses
    const urlRegex = /(\[https?:\/\/[^\s\]]+\]|\(https?:\/\/[^\s)]+\)|https?:\/\/[^\s\])]+)/g;
    const parts = input.split(urlRegex).filter((part) => part !== '');

    return parts.flatMap((part) => {
        if (urlRegex.test(part)) {
            const openingBracket = part[0];
            const closingBracket = part[part.length - 1];
            const hasBrackets =
                (openingBracket === '[' && closingBracket === ']') ||
                (openingBracket === '(' && closingBracket === ')');
            const urlPart = hasBrackets ? part.slice(1, -1) : part;

            if (isValidUrl(urlPart)) {
                if (hasBrackets) {
                    return [
                        { type: 'text', value: openingBracket },
                        { type: 'url', value: urlPart },
                        { type: 'text', value: closingBracket },
                    ];
                } else {
                    return [{ type: 'url', value: part }];
                }
            }
        }
        return [{ type: 'text', value: part }];
    });
}
