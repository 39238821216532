import AffiliatedClusterIcon from 'jsx:./AffiliatedCluster.svg';
import AffiliatedVirtualClusterIcon from 'jsx:./AffiliatedVirtualCluster.svg';
import AddClusterIcon from 'jsx:./light/AddCluster.svg';
import ClusterIcon from 'jsx:./light/Cluster.svg';
import ColumnIcon from 'jsx:./light/Column.svg';
import EntityGroupIcon from 'jsx:./light/EntityGroup.svg';
import EntityGroupFolderIcon from 'jsx:./light/EntityGroupFolder.svg';
import EntityGroupMemberIcon from 'jsx:./light/EntityGroupMember.svg';
import ExportCsvIcon from 'jsx:./light/ExportCsv.svg';
import ExternalTableIcon from 'jsx:./light/ExternalTable.svg';
import ExternalTablesFolderIcon from 'jsx:./light/ExternalTablesFolder.svg';
import FavoriteListOnIcon from 'jsx:./light/FavoriteListOn.svg';
import FollowerDatabaseIcon from 'jsx:./light/FollowerDatabase.svg';
import FunctionsFolderIcon from 'jsx:./light/FunctionsFolder.svg';
import MaterializedViewTableFolderIcon from 'jsx:./light/MaterializedViewTableFolder.svg';
import ReadOnlyDatabaseIcon from 'jsx:./light/ReadOnlyDatabase.svg';
import TablesFolderIcon from 'jsx:./light/TablesFolder.svg';
import ViewFunctionIcon from 'jsx:./light/ViewFunction.svg';
import VirtualClusterIcon from 'jsx:./light/VirtualCluster.svg';
import SuspendedDatabaseIcon from 'jsx:./SuspendedDatabase.svg';

export const ConnectionExplorerIcons = {
    ClusterIcon,
    VirtualClusterIcon,
    AffiliatedClusterIcon,
    AffiliatedVirtualClusterIcon,
    ExternalTableIcon,
    ColumnIcon,
    FunctionsFolderIcon,
    TablesFolderIcon,
    MaterializedViewTableFolderIcon,
    ExternalTablesFolderIcon,
    FollowerDatabaseIcon,
    ReadOnlyDatabaseIcon,
    FavoriteListOnIcon,
    AddClusterIcon,
    ExportCsvIcon,
    ViewFunctionIcon,
    EntityGroupFolderIcon,
    EntityGroupIcon,
    EntityGroupMemberIcon,
    SuspendedDatabaseIcon,
};
