import { ArrowDown, ArrowLeft, ArrowRight, ArrowUp, End, Home, Enter } from '@fluentui/keyboard-keys';
export const treeItemLevelToken = '--fluent-TreeItem--level';
export const treeAvatarSize = {
    medium: 32,
    small: 24
};
export const treeDataTypes = {
    ArrowLeft,
    ArrowRight,
    Enter,
    Click: 'Click',
    ExpandIconClick: 'ExpandIconClick',
    End,
    Home,
    ArrowUp,
    ArrowDown,
    TypeAhead: 'TypeAhead',
    Change: 'Change'
};
