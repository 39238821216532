import * as React from 'react';
import { Announced } from '@fluentui/react/lib/Announced';
import { DropdownMenuItemType } from '@fluentui/react/lib/Dropdown';
import { ScrollToMode } from '@fluentui/react/lib/List';
import { TextField } from '@fluentui/react/lib/TextField';
import fuzzysort from 'fuzzysort';
import debounce from 'lodash/debounce';

import { useCurrent } from '../../../../hooks/useCurrent';
import { RTD_DROPDOWN } from '../../constants';
import { NoData } from '../../NoData';
import { useRtdProviderStrings } from '../../stringsContext';
import { CustomListDropdownMenu, CustomListDropdownMenuProps } from '../customListDropdown/CustomListDropdownMenu';
import {
    useCustomListDropdownDispatch,
    useCustomListDropdownSelector,
} from '../customListDropdown/CustomListDropdownMenuContext';
import {
    CustomListDropdownHeaderProps,
    PrerenderedCustomListDropdownOption,
    RTDDropdownOption,
} from '../customListDropdown/types';
import { buildRowHeightFunc } from '../customListDropdown/util';

import * as styles from '../customListDropdown/CustomListDropdownMenu.module.scss';

const debouncedFilter = debounce((...args: Parameters<typeof filter>) => filter(...args), 300);

/*
Match one or more whitespace characters globally in the string.
e.g. ('  new    york  ').replace(R, '') // output: 'new york'
 */
const whitespaceRegex = /\s+/g;

/**
 * The dropdown menu component of a SearchDropdown.
 *
 * **NOTE**: If renderedOptions are not provided, or it is not an array of elements, rendering falls back to the
 * default Fabric dropdown renderer, **without** search functionality
 */
export const SearchDropdownMenu: React.FC<CustomListDropdownMenuProps> = (props) => {
    const strings = useRtdProviderStrings();
    const { options, currentSelectedIndexes, onRenderHeaderPrefix, telemetry } = props;

    const dropdownTelemetry = React.useMemo(() => telemetry.bind({ component: 'SearchDropdownMenu' }), [telemetry]);
    const currentOptions = useCurrent(options);

    const { listRef, activeIndex, orderedFilteredKeys } = useCustomListDropdownSelector((state) => state);
    const [dispatch] = useCustomListDropdownDispatch();

    const currentListRef = useCurrent(listRef);

    const setFilteredOptions = React.useCallback(
        (innerOptions: PrerenderedCustomListDropdownOption[] | undefined) =>
            dispatch({
                type: 'setFilteredOptions',
                options: innerOptions,
            }),
        [dispatch]
    );

    const onRenderHeader = React.useCallback(
        ({
            inputRef,
            unfilteredOptions,
            filteredOptions,
            onInputKeyDown,
            onRenderHeaderPrefix: innerOnRenderHeaderPrefix,
        }: CustomListDropdownHeaderProps) => {
            const onSearch = (_event: unknown, value: string | undefined) => {
                debouncedFilter(value?.replace(whitespaceRegex, ''), unfilteredOptions, setFilteredOptions);
            };

            return (
                <>
                    {innerOnRenderHeaderPrefix?.()}
                    <TextField
                        role="searchbox"
                        componentRef={inputRef}
                        className={styles.search}
                        autoComplete="off"
                        onChange={onSearch}
                        onKeyDown={onInputKeyDown}
                        placeholder={strings.utils.components.rtdDropdown.searchPlaceholder}
                        ariaLabel={strings.utils.components.rtdDropdown.searchAriaLabel}
                        data-testid="dropdownSearchBox"
                    />
                    <Announced
                        message={`${strings.utils.components.rtdDropdown.itemsAfterFilter} ${filteredOptions.length}`}
                    />
                </>
            );
        },
        [setFilteredOptions, strings]
    );

    // As long as List has getPageHeight set (has a stable page height), scrolling can be run as a layoutEffect
    // If the page height was not stable, Fabric would have to render the list to calculate the page height then perform some logic, so
    // useEffect would have to be used instead
    React.useLayoutEffect(() => {
        // Scroll to top every time filter changes
        currentListRef.current?.scrollToIndex(0, buildRowHeightFunc(currentOptions.current));

        // Clear active index
        dispatch({
            type: 'setActiveIndex',
            index: undefined,
        });
    }, [orderedFilteredKeys, currentListRef, currentOptions, dispatch]);

    React.useLayoutEffect(() => {
        if (!currentSelectedIndexes?.current) {
            // No selected indexes
            return;
        }

        const minIndex = Math.min(...currentSelectedIndexes.current);

        if (minIndex !== Infinity) {
            // Row height method is necessary to properly scroll
            listRef?.scrollToIndex(minIndex, buildRowHeightFunc(currentOptions.current), ScrollToMode.center);
        }
        // Only run on mount, when listRef becomes available
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listRef]);

    React.useLayoutEffect(() => {
        if (activeIndex !== undefined) {
            listRef?.scrollToIndex(activeIndex, buildRowHeightFunc(currentOptions.current));
        }
    }, [activeIndex, listRef, currentOptions]);

    return (
        <CustomListDropdownMenu
            {...props}
            onRenderHeaderPrefix={onRenderHeaderPrefix}
            onRenderHeader={onRenderHeader}
            noData={<NoData className={styles.noResults} message={strings.utils.components.rtdDropdown.noResults} />}
            extendedOptionFunc={extendedOptionFunc}
            telemetry={dropdownTelemetry}
        />
    );
};

function extendedOptionFunc(option: RTDDropdownOption) {
    return {
        preparedSearchTarget: fuzzysort.prepare(option.text),
    };
}

function filter(
    value: string | undefined,
    options: PrerenderedCustomListDropdownOption[],
    setFilteredOptions: (o: PrerenderedCustomListDropdownOption[] | undefined) => void
) {
    if (value === undefined || value.trim().length < 1) {
        setFilteredOptions(undefined);
        return;
    }

    const res = fuzzysort.go(
        value,
        options.filter(
            (o) =>
                !o.key.startsWith(RTD_DROPDOWN.rtdInternalPrefix) &&
                (o.itemType === undefined || o.itemType === DropdownMenuItemType.Normal)
        ),
        {
            key: 'preparedSearchTarget',
            threshold: -10000,
        }
    );

    setFilteredOptions(res.map((result) => result.obj));
}
