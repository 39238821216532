import * as Charting from '@kusto/charting';
import { FieldSchema, formatLiterals } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';

import {
    COLUMN_INTERACTION_ID,
    COLUMN_SERIES_X_PROPERTY,
    COLUMN_SERIES_Y_PROPERTY,
    DRAG_END_PROPERTY_ID,
    DRAG_INTERACTION_ID,
    DRAG_START_PROPERTY_ID,
    DRAG_TIME_RANGE_PROPERTY_ID,
} from '../../constants';
import { KweRtdVisualContext } from '../../context';
import { crossFilterSegmentToggle, drillthroughSegmentToggle } from '../../inputLib';
import { Interaction } from './types';

function createColumnInteraction<H extends Interaction.Heuristics>(
    options: Fwk.VisualInputSelectorOptions<'crossFilter', H, unknown>,
    ctx: KweRtdVisualContext
): Fwk.VisualInteraction {
    const schema = options.getSchema();
    const heuristics = options.getHeuristics();

    if (
        ctx.flags['cross-filter--series--point'] &&
        heuristics?.result.value?.kustoHeuristics.metaData?.IsDataFormedAsSeries
    ) {
        // If the chart is displaying the x axis as "datetime" well convert the
        // start/end values to datetime for the users. Leaving the values as
        // real for now for other cases, but I think we'll want to do better
        // before we remove the feature flag.
        const dataTypes: readonly Fwk.UParamDataType[] =
            heuristics.result.value.kustoHeuristics.argumentType === Charting.ArgumentColumnType.DateTime
                ? [['datetime']]
                : [['real']];

        return {
            kind: 'available',
            id: COLUMN_INTERACTION_ID,
            displayName: ctx.strings.rtdProvider.visuals.crossFilter$pointInteractionLabel,
            properties: [
                {
                    id: COLUMN_SERIES_X_PROPERTY,
                    kind: 'available',
                    displayName: 'X',
                    dataTypes,
                },
                {
                    id: COLUMN_SERIES_Y_PROPERTY,
                    kind: 'available',
                    displayName: 'Y',
                    dataTypes,
                },
            ],
        };
    }

    return {
        kind: 'available',
        id: COLUMN_INTERACTION_ID,
        displayName: ctx.strings.rtdProvider.visuals.crossFilter$pointInteractionLabel,
        propertiesDisplayName: ctx.strings.rtdProvider.visuals.crossFilterColumnDropdownLabel,
        properties:
            schema.kind !== 'available'
                ? []
                : schema.schema.map((c) => ({
                      id: c.name,
                      kind: 'available',
                      displayName: c.name,
                      dataTypes: [[Fwk.basicParamTypeFromColumnType(c.type)]],
                  })),
        formatUnavailablePropertyError: (property) => {
            return formatLiterals(
                ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$columnNotFoundError,
                {
                    columnName: property,
                }
            );
        },
    };
}

function createDragInteraction<H extends Interaction.Heuristics>(
    options: Fwk.VisualInputSelectorOptions<'crossFilter', H, unknown>,
    ctx: KweRtdVisualContext
): Fwk.VisualInteraction {
    const schema = options.getSchema();
    const heuristics = options.getHeuristics();

    const xColumnName = heuristics?.result.value?.xColumn;
    let xAxisType: FieldSchema | undefined;
    if (
        heuristics?.result.value?.kustoHeuristics.metaData?.IsDataFormedAsSeries &&
        heuristics.result.value.kustoHeuristics.argumentType === Charting.ArgumentColumnType.DateTime
    ) {
        xAxisType = { name: 'X', type: 'datetime' };
    } else {
        xAxisType = schema.kind !== 'available' ? undefined : schema.schema.find((c) => c.name === xColumnName);
    }

    const timeRangeAvailable = xAxisType?.type === 'datetime';

    let startEndProperties: Fwk.VisualInteraction.Property[];

    if (xAxisType === undefined || ['bool', 'json', 'string', 'timespan'].includes(xAxisType.type)) {
        let unavailableReason: string;
        if (xAxisType === undefined) {
            unavailableReason = ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$xColumnNotSelected;
        } else if (xAxisType.type === 'timespan') {
            unavailableReason =
                ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$columnTimespanNotSupportedError;
        } else {
            unavailableReason = ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$xColumnNotNumericError;
        }
        startEndProperties = [
            {
                kind: 'unavailable',
                unavailableReason,
                id: DRAG_START_PROPERTY_ID,
                displayName: ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$dragXStartPropertyName,
            },
            {
                kind: 'unavailable',
                unavailableReason,
                id: DRAG_END_PROPERTY_ID,
                displayName: ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$dragXEndPropertyName,
            },
        ];
    } else {
        const dataTypes = [[Fwk.basicParamTypeFromColumnType(xAxisType.type)]] as const;
        startEndProperties = [
            {
                kind: 'available',
                id: DRAG_START_PROPERTY_ID,
                displayName: ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$dragXStartPropertyName,
                dataTypes,
            },
            {
                kind: 'available',
                id: DRAG_END_PROPERTY_ID,
                displayName: ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$dragXEndPropertyName,
                dataTypes,
            },
        ];
    }

    return {
        kind: 'available',
        id: DRAG_INTERACTION_ID,
        displayName: ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$dragXDimensionName,
        properties: [
            {
                kind: timeRangeAvailable ? 'available' : 'unavailable',
                unavailableReason:
                    ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$timeRangeNotAvailable,
                id: DRAG_TIME_RANGE_PROPERTY_ID,
                displayName:
                    ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$dragXTimeRangePropertyName,
                dataTypes: [['duration']],
            },
            ...startEndProperties,
        ],
    };
}

export function crossFilterSegment<H extends Interaction.Heuristics>(ctx: KweRtdVisualContext) {
    return Fwk.tileInputSegment<'crossFilter' | 'crossFilterDisabled', H>(
        ctx.strings.rtdProvider.visuals.input.segment$crossFilterTitle,
        {
            toggle: crossFilterSegmentToggle(ctx),
            hideReset: true,
        },
        Fwk.createTileInput.crossFilter<'crossFilter', H>('crossFilter', 'highcharts', (options) => {
            return [createColumnInteraction<H>(options, ctx), createDragInteraction<H>(options, ctx)];
        })
    );
}

export function drillthroughSegment<H extends Interaction.Heuristics>(ctx: KweRtdVisualContext) {
    return Fwk.tileInputSegment<'drillthrough' | 'drillthroughDisabled', H>(
        ctx.strings.rtdProvider.visuals.input.segment$drillthroughTitle,
        {
            toggle: drillthroughSegmentToggle(ctx),
            hideReset: true,
        },

        Fwk.createTileInput.drillthrough<'drillthrough', H>('drillthrough', 'highcharts', (options) => {
            return [createColumnInteraction<H>(options, ctx)];
        })
    );
}
