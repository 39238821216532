import * as React from 'react';
import { Field, Input, InputOnChangeData } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';

import { DropdownWithSearch, SelectedOption } from '@kusto/ui-components';
import { useThunkReducer } from '@kusto/utils';

import type { KweVisualFwkLocale } from '../../types';
import { BASE_Y_AXIS_ID, YAxisConfig } from '../../visualOptions';
import { dropdownWithTypeTextProps } from '../columnDropdowns/dropdownWithTypeText';
import { columnDropdownOption, columnOptionKey, ETPColumnOption } from '../columnDropdowns/utils';
import { ConfigurationItemCallout } from '../configurationList/ConfigurationItemCallout';
import {
    ApplyHorizontalLineChange,
    HorizontalLineInput,
    HorizontalLineList,
    HorizontalLineListItemComponentProps,
} from './HorizontalLines';
import { LabelWithInfo } from './LabelWithInfo';
import { multipleYAxesReducer } from './reducer';
import { createAxisScaleOptions, MultipleYAxesModel } from './util';

import * as configurationListStyles from '../configurationList/ConfigurationItemCallout.module.scss';

const yColumnsDropdownTooltipProps = {
    id: 'multiple-yaxis--ycolumn-description',
};

const onRenderColumnLabel = (strings: KweVisualFwkLocale) => () =>
    (
        <LabelWithInfo
            text={strings.visualFwk.visualConfig.multipleYAxes.columnDropdownLabel}
            infoText={strings.visualFwk.visualConfig.multipleYAxes.yColumnDropdownInfo}
            tooltipProps={yColumnsDropdownTooltipProps}
        />
    );

interface YAxisColumnsDropdownProps {
    t: KweVisualFwkLocale;
    inferredColumns: null | readonly string[];
    model: MultipleYAxesModel;
    selectedColumns: readonly string[];
    onChange: (_: unknown, option?: SelectedOption) => void;
    baseYAxis: boolean;
    yAxisId: string;
}

const YAxisColumnsDropdown: React.FC<YAxisColumnsDropdownProps> = observer(function YAxisColumnsDropdown({
    t,
    model,
    inferredColumns,
    selectedColumns,
    onChange,
    baseYAxis,
    yAxisId,
}) {
    const multipleYAxes = model.get('multipleYAxes');
    const schema = model.getSchema();

    const columnOptions = React.useMemo(() => {
        if (schema.kind !== 'available') {
            return [];
        }
        if (inferredColumns === null) {
            return schema.schema.map((c) => columnDropdownOption(c.name, { kind: 'available', type: c.type }));
        }
        const byName = new Map(schema.schema.map((c) => [c.name, c] as const));

        return inferredColumns.map((val) => {
            const column = byName.get(val);
            const isColumnAlreadyInUse = multipleYAxes.additional.some(
                (col) => col.columns[0] === val && col.id !== yAxisId
            );
            return columnDropdownOption(
                val,
                column ? { kind: 'available', type: column.type } : { kind: 'unavailable' },
                isColumnAlreadyInUse
            );
        });
    }, [inferredColumns, multipleYAxes, schema, yAxisId]);

    const { selectedKey, selectedKeys } = React.useMemo(() => {
        let selectedKeysMemo: string[] = [];
        let selectedKeyMemo = '';
        if (!baseYAxis) {
            selectedKeyMemo = columnOptionKey(selectedColumns[0]);
        } else {
            selectedKeysMemo = selectedColumns.map((op) => columnOptionKey(op));
        }
        return { selectedKey: selectedKeyMemo, selectedKeys: selectedKeysMemo };
    }, [selectedColumns, baseYAxis]);

    const {
        options,
        invalidSelection,
    }: {
        options: ETPColumnOption[];
        invalidSelection?: boolean;
    } = React.useMemo(() => {
        if (!baseYAxis && !columnOptions.some((e) => e.key === selectedKey)) {
            return {
                options: [columnDropdownOption(selectedColumns[0], { kind: 'unavailable' }), ...columnOptions],
                invalidSelection: true,
            };
        }
        return {
            options: columnOptions,
        };
    }, [selectedColumns, columnOptions, baseYAxis, selectedKey]);

    const isMultiSelect = baseYAxis;

    return (
        <DropdownWithSearch
            className={configurationListStyles.fullWidth}
            renderLabel={onRenderColumnLabel(t)}
            options={options}
            selectedKeys={isMultiSelect ? selectedKeys : selectedKey}
            onChange={onChange}
            aria-label={t.visualFwk.visualConfig.multipleYAxes.columnDropdownLabel}
            aria-describedby={yColumnsDropdownTooltipProps.id}
            // combobox only displays one item so that Narrator can announce it.
            role="combobox"
            validationMessage={invalidSelection ? t.visualFwk.visualConfig.multipleYAxes.yColumnInvalidSelection : ''}
            disabled={baseYAxis}
            multiselect={isMultiSelect}
            {...dropdownWithTypeTextProps(t)}
        />
    );
});

export interface MultipleYAxesCalloutProps {
    t: KweVisualFwkLocale;
    model: MultipleYAxesModel;
    inferredColumns: null | readonly string[];
    yAxis: YAxisConfig;
    onClose: () => void;
    onSave: (yAxis: YAxisConfig) => void;
    targetId: string;
    baseYAxisColumns: string[];
}

export const MultipleYAxesCallout: React.FC<MultipleYAxesCalloutProps> = ({
    t,
    model,
    yAxis,
    onClose,
    onSave,
    targetId,
    baseYAxisColumns,
    inferredColumns,
}) => {
    const [state, dispatch, getState] = useThunkReducer(multipleYAxesReducer, yAxis);

    const {
        onLabelChange,
        onColumnChange,
        onMinValueChange,
        onMaxValueChange,
        onYAxisScaleChange,
        onHorizontalLineInputChange,
        axisScaleOptions,
    } = React.useMemo(() => {
        const onLabelChangeMemo = (_: unknown, data: InputOnChangeData) =>
            dispatch({ type: 'setLabel', label: data.value });

        const onColumnChangeMemo = (_: unknown, option?: SelectedOption) => {
            if (option?.data) {
                dispatch({ type: 'setColumn', column: option.data });
            }
        };

        const onMinValueChangeMemo = (_: unknown, data: InputOnChangeData) =>
            dispatch({
                type: 'updateYAxisLimit',
                property: 'yAxisMinimumValue',
                value: data.value,
            });

        const onMaxValueChangeMemo = (_: unknown, data: InputOnChangeData) =>
            dispatch({
                type: 'updateYAxisLimit',
                property: 'yAxisMaximumValue',
                value: data.value,
            });

        const onYAxisScaleChangeMemo = (_: unknown, option?: SelectedOption) => {
            dispatch({
                type: 'setYAxisScale',
                yAxisScale: option?.key as YAxisConfig['yAxisScale'],
            });
        };

        const onHorizontalLineInputChangeMemo = (id: string, newValue?: string) => {
            dispatch({
                type: 'updateHorizontalLine',
                id: id,
                value: newValue ?? '',
            });
        };

        return {
            onLabelChange: onLabelChangeMemo,
            onColumnChange: onColumnChangeMemo,
            onMinValueChange: onMinValueChangeMemo,
            onMaxValueChange: onMaxValueChangeMemo,
            onYAxisScaleChange: onYAxisScaleChangeMemo,
            onHorizontalLineInputChange: onHorizontalLineInputChangeMemo,
            axisScaleOptions: createAxisScaleOptions(t),
        };
    }, [dispatch, t]);

    const onHorizontalLinesChange = React.useCallback(
        (applyChange: ApplyHorizontalLineChange) => {
            dispatch({
                type: 'setHorizontalLines',
                lines: applyChange(state.horizontalLines) ?? [],
            });
        },
        [dispatch, state.horizontalLines]
    );

    const innerOnSave = React.useCallback(() => onSave(getState()), [onSave, getState]);

    const HorizontalLineInputField = React.useMemo(
        () =>
            ({ item, onChange }: HorizontalLineListItemComponentProps) => {
                const { id, value } = item;

                return (
                    <HorizontalLineInput
                        t={t}
                        value={value?.toString() ?? ''}
                        onChange={(_, data) => onChange(id, data.value)}
                    />
                );
            },
        [t]
    );

    return (
        <ConfigurationItemCallout
            title={t.visualFwk.visualConfig.multipleYAxes.multipleYAxesCalloutTitle}
            onSave={innerOnSave}
            onClose={onClose}
            targetId={targetId}
            position="before"
            applyButtonLabel={t.utils.util.buttons.apply}
            cancelButtonLabel={t.utils.util.buttons.cancel}
        >
            <YAxisColumnsDropdown
                t={t}
                model={model}
                inferredColumns={inferredColumns}
                selectedColumns={yAxis.id === BASE_Y_AXIS_ID ? baseYAxisColumns : state.columns}
                onChange={onColumnChange}
                baseYAxis={yAxis.id === BASE_Y_AXIS_ID}
                yAxisId={yAxis.id}
            />
            <Field
                label={t.visualFwk.visualConfig.multipleYAxes.yAxisLabelInputLabel}
                className={configurationListStyles.fullWidth}
            >
                <Input onChange={onLabelChange} value={state.label} autoComplete="off" />
            </Field>
            <Field
                label={t.visualFwk.visualConfig.yAxisMaximumValue.inputLabel}
                className={configurationListStyles.fullWidth}
            >
                <Input
                    placeholder={t.visualFwk.visualConfig.textInputInferPlaceholder}
                    value={state.yAxisMaximumValue?.toString() ?? ''}
                    type="number"
                    onChange={onMaxValueChange}
                />
            </Field>
            <Field
                label={t.visualFwk.visualConfig.yAxisMinimumValue.inputLabel}
                className={configurationListStyles.fullWidth}
            >
                <Input
                    placeholder={t.visualFwk.visualConfig.textInputInferPlaceholder}
                    value={state.yAxisMinimumValue?.toString() ?? ''}
                    type="number"
                    onChange={onMinValueChange}
                />
            </Field>
            <DropdownWithSearch
                className={configurationListStyles.fullWidth}
                selectedKeys={state.yAxisScale}
                options={axisScaleOptions}
                onChange={onYAxisScaleChange}
                label={t.visualFwk.visualConfig.axisScale.label.yAxisScale}
            />
            <div className={configurationListStyles.fullWidth}>
                <HorizontalLineList
                    t={t}
                    registerFlush={model.registerFlush}
                    yAxisConfig={state}
                    onChangeHorizontalLine={onHorizontalLinesChange}
                    ItemComponent={HorizontalLineInputField}
                    onHorizontalLineInputChange={onHorizontalLineInputChange}
                />
            </div>
        </ConfigurationItemCallout>
    );
};
