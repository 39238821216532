import * as mobx from 'mobx';

import { isSecurityError, Mutable, ReadonlyRecord } from '@kusto/utils';

import { createFlagsObj } from './lib';
import type { FeatureFlagsSetting, IFeatureFlagsService } from './types';

const about3Months = 3 * 30 * 24 * 60 * 60 * 1000;

function getLocalStorageFlags<Flag extends string>(
    deletedFlags: DeletedFlags<Flag>,
    localStorageKey: string
): Mutable<FeatureFlagsSetting<string>> {
    let str: undefined | null | string;
    try {
        str = localStorage.getItem(localStorageKey);
    } catch (e) {
        if (!isSecurityError(e)) {
            // _Ideally_ we'd bank non-security exceptions here and log them
            // after app init is done. Throwing them after an await pause will
            // cause them to be logged if the telemetry service has initialized,
            // but not if we're in the app-init path.
            setTimeout(() => {
                throw e;
            }, 0);
        }
    }

    if (!str) {
        return {};
    }

    const flags = JSON.parse(str);

    for (const [oldFlag, { date, renamedTo }] of Object.entries(deletedFlags)) {
        if (oldFlag in flags) {
            if (renamedTo) {
                // NOTE: Flag renaming doesn't support rollbacks, because the
                // new name won't be applied to the old flag
                flags[renamedTo] = flags[oldFlag];
            }
            // Wait a bit to delete old flags incase we rollback
            if (Date.parse(date) + about3Months < Date.now()) {
                delete flags[oldFlag];
            }
        }
    }

    return flags;
}

export type FlagsConfigGetter<Flag extends string = string> = (flag: Flag) => undefined | boolean;

export interface DeletedFlagInfo<Flag extends string = string> {
    /**
     * YYYY-MM-DD
     *
     * parsed as UTC
     */
    readonly date: `${string}-${string}-${string}`;
    /**
     * If present, flag is renamed when reading from locale storage
     */
    readonly renamedTo?: Flag;
}

/**
 * Items added to this list are removed from locale storage around 3 months
 * ({@link about3Months}), and optionally renamed.
 *
 * @example
 * export const deletedFlags: DeletedFlags<DashboardsFeatureFlag> = {
 *     sharedQueryCascading: {
 *         date: '2023-06-16',
 *         renamedTo: 'baseQueryCascading',
 *     },
 *     baseQueries: {
 *         date: '2023-10-04',
 *     },
 * };
 */
export type DeletedFlags<Flag extends string = string> = ReadonlyRecord<string, DeletedFlagInfo<Flag>>;

export class FeatureFlagsService<Flag extends string = string> implements IFeatureFlagsService<Flag> {
    readonly override: Mutable<FeatureFlagsSetting<string>>;

    /**
     * @param mutableFlags Flags configured here can be overridden by the user in the dev menu
     * @param immutableFlags Flags configured here cannot be configured in the dev menu
     * @param deletedFlags @see {@link DeletedFlags}
     */
    constructor(
        readonly flagConfigValue: IFeatureFlagsService<Flag>['flagConfigValue'],
        deletedFlags: DeletedFlags,
        readonly localStorageKey: string
    ) {
        this.override = mobx.observable(getLocalStorageFlags(deletedFlags, localStorageKey));

        mobx.makeObservable(this, {
            setFlagOverride: mobx.action,
        });
    }

    private get(flag: Flag): boolean {
        const config = this.flagConfigValue(flag);
        if (config.force) {
            return config.value;
        }

        return this.override[flag] ?? config.value;
    }

    readonly flags = createFlagsObj<Flag>((f) => this.get(f));
    readonly untracked = createFlagsObj<Flag>(mobx.action((f) => this.get(f)));

    setFlagOverride(flag: Flag, value: boolean | undefined) {
        if (value === undefined) {
            delete this.override[flag];
        } else {
            this.override[flag] = value;
        }
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.override));
    }
}
