import { EntityType } from '../../../common';
import { Icon } from '../actions';

export const iconMapper: { [t in EntityType | string]: Icon } = {
    Cluster: {
        iconName: 'Cluster',
        iconType: 'custom',
        isBold: true,
        ariaLabel: 'Cluster',
    },
    'Azure Data Explorer Personal': {
        iconName: 'VirtualClusterIcon',
        iconType: 'custom',
        isBold: true,
        ariaLabel: 'Cluster',
    },
    'Cluster Affiliated': {
        iconName: 'AffiliatedClusterIcon',
        iconType: 'custom',
        isBold: true,
        ariaLabel: 'Cluster',
    },
    'Azure Data Explorer Personal Affiliated': {
        iconName: 'AffiliatedVirtualClusterIcon',
        iconType: 'custom',
        isBold: true,
        ariaLabel: 'Cluster',
    },
    Database: {
        iconName: 'Database',
        iconType: 'fluent',
        isBold: true,
        ariaLabel: 'Database',
    },
    Folder: {
        iconName: 'FolderHorizontal',
        iconType: 'fluent',
        isBold: false,
        ariaLabel: 'Folder',
    },
    Function: {
        iconName: 'Variable',
        iconType: 'fluent',
        isBold: false,
        ariaLabel: 'Function',
    },
    ViewFunction: {
        iconName: 'ViewFunction',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'View Function',
    },
    EntityGroup: {
        iconName: 'EntityGroup',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'Entity Group',
    },
    EntityGroupFolder: {
        iconName: 'EntityGroupFolder',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'Entity Group Folder',
    },
    EntityGroupMember: {
        iconName: 'EntityGroupMember',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'Entity Group Member',
    },
    Table: {
        iconName: 'Table',
        iconType: 'fluent',
        isBold: false,
        ariaLabel: 'Table',
    },
    ExternalTable: {
        iconName: 'ExternalTable',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'External Table',
    },
    Column: {
        iconName: 'Column',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'Column',
    },
    FunctionsFolder: {
        iconName: 'FunctionsFolder',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'Functions Folder',
    },
    TablesFolder: {
        iconName: 'TablesFolder',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'Tables Folder',
    },
    MaterializedViewTableFolder: {
        iconName: 'MaterializedViewTableFolder',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'Materialized Views Folder',
    },
    ExternalTableFolder: {
        iconName: 'ExternalTablesFolder',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'External Tables Folder',
    },
    LoadingIndicator: {
        iconName: 'Refresh',
        iconType: 'fluent',
        isBold: false,
        ariaLabel: 'Refresh',
    },
    MaterializedViewTable: {
        iconName: 'TableComputed',
        iconType: 'fluent',
        isBold: false,
        ariaLabel: 'Materialized View',
    },
    ReadOnlyFollowing: {
        // Database
        iconName: 'FollowerDatabase',
        ariaLabel: 'Follower Database',
        iconType: 'custom',
        isBold: true,
    },
    ReadOnly: {
        iconName: 'ReadOnlyDatabase',
        ariaLabel: 'read only Database',
        iconType: 'custom',
        isBold: true,
    },
    SuspendedDatabase: {
        iconName: 'SuspendedDatabase',
        ariaLabel: 'suspended',
        iconType: 'custom',
        isBold: true,
    },
};
