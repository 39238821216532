import type { CellClickedEvent } from '@ag-grid-community/core';

import type * as client from '@kusto/client';
import { err, formatLiterals, ok } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';

import { clientTaggedValueToKweTaggedValue } from '../../utils';

export interface ErrorStrings {
    noColumn: string;
    missingColumn: string;
}

export function extractRtdValue(
    columns: readonly client.KustoColumn[],
    event: CellClickedEvent,
    errorStrings: ErrorStrings
): Fwk.MaybeParamValue {
    const columnName = event.colDef.colId;

    if (columnName === undefined) {
        return err(errorStrings.noColumn);
    }

    const column = columns.find((c) => c.headerName === event.colDef.colId);

    if (column === undefined) {
        return err(formatLiterals(errorStrings.missingColumn, { columnName }));
    }

    const value = clientTaggedValueToKweTaggedValue({ value: event.value, dataType: column.columnType });

    return ok(value.kind === Fwk.rtdNullValue.kind ? Fwk.ALL_SELECTION : value);
}
