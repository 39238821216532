import React from 'react';
import type { IContextualMenuItem } from '@fluentui/react';

import type * as client from '@kusto/client';
import { err, formatLiterals, KweException, Ok, ok } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';
import type { ChartEvents } from '@kusto/visualizations';

import { KweRtdVisualContext } from '../../context';
import { clientTaggedValueToKweTaggedValue } from '../../utils';
import { Interaction } from './types';

function extractParamValue(
    column: string,
    columns: readonly client.KustoColumn[],
    row: client.KustoQueryResultRowObject,
    ctx: KweRtdVisualContext
): Fwk.MaybeParamValue {
    const columnDef = columns.find((c) => c.field === column);

    if (columnDef === undefined) {
        return err(
            formatLiterals(ctx.strings.rtdProvider.visuals.crossFiltering$configuredColumnNotFound, {
                columnName: column,
            })
        );
    }

    if (!(column in row)) {
        throw new KweException(`Unexpectedly missing column "${column}" in row`);
    }

    const value = row[column];

    const tagged = clientTaggedValueToKweTaggedValue({ value, dataType: columnDef.columnType });
    return ok(tagged.kind === 'null' ? Fwk.ALL_SELECTION : tagged);
}

type Props = Fwk.IDataVisualProps<'drillthrough' | 'drillthroughDisabled'>;

export type DrillthroughHeuristics = null | Pick<Interaction.HeuristicsSuccess, 'columns'>;

export function useDrillthroughEvents(
    ctx: KweRtdVisualContext,
    visualOptions: Props['visualOptions'],
    heuristics: DrillthroughHeuristics,
    dashboardApi: undefined | Fwk.DashboardVisualApi
) {
    const applyDrillthrough = dashboardApi?.drillthrough;
    const pagesRecord = dashboardApi?.state.pagesMap;
    const drillthroughConfigs = visualOptions.drillthrough;
    const drillthroughDisabled = visualOptions.drillthroughDisabled;
    const events: undefined | Partial<Pick<ChartEvents, 'onHighchartsPointMenuItems'>> = React.useMemo(() => {
        if (
            drillthroughDisabled ||
            drillthroughConfigs.length === 0 ||
            !applyDrillthrough ||
            !heuristics ||
            !pagesRecord
        ) {
            return undefined;
        }

        const events: Partial<Pick<ChartEvents, 'onHighchartsPointMenuItems'>> = {};

        const readyPairsByPageId = new Map<string, Fwk.ReadyDrillthroughPair[]>();
        for (const config of drillthroughConfigs) {
            if (Fwk.drillthroughIsReady(config)) {
                const readyPairs = config.pairs.filter(Fwk.drillthroughPairIsReady);

                for (const pageId of config.destinationPages) {
                    readyPairsByPageId.set(pageId, readyPairs);
                }
            }
        }

        if (readyPairsByPageId.size !== 0) {
            const onClick = (
                row: undefined | client.KustoQueryResultRowObject,
                destinationPageId: string,
                pairs: Fwk.ReadyDrillthroughPair[]
            ) => {
                if (!row) return;

                const res = pairs.map((p) => {
                    const maybeValue = extractParamValue(p.property, heuristics.columns, row, ctx);

                    const okPairValue: Ok<Fwk.InteractionPair> = ok({
                        parameterId: p.parameterId,
                        value: maybeValue,
                    });

                    return okPairValue;
                });

                applyDrillthrough(ok(res), destinationPageId);
            };

            const createSubMenuItems = (row: undefined | client.KustoQueryResultRowObject) => {
                if (!row) return [];

                const items: IContextualMenuItem[] = [];

                for (const [pageId, pairs] of readyPairsByPageId.entries()) {
                    const pageName = pagesRecord.get(pageId)?.name;
                    if (!pageName) {
                        continue;
                    }

                    items.push({
                        key: `drillthrough-menuitem-${pageId}`,
                        onClick: () => onClick(row, pageId, pairs),
                        text: pageName,
                    });
                }

                return items;
            };

            events.onHighchartsPointMenuItems = (row: undefined | client.KustoQueryResultRowObject) => [
                {
                    text: ctx.strings.rtdProvider.visuals.drillthroughContextMenuText,
                    key: 'drillthrough',
                    subMenuProps: {
                        items: createSubMenuItems(row),
                    },
                },
            ];
        }

        return events;
    }, [drillthroughDisabled, drillthroughConfigs, applyDrillthrough, heuristics, ctx, pagesRecord]);

    return events;
}
