import React from 'react';
import { ActionButton } from '@fluentui/react/lib/Button';

import { TooltipWrapper, TooltipWrapperProps } from './TooltipWrapper';

export interface ActionButtonWithTooltipProps extends React.ComponentProps<typeof ActionButton> {
    tooltipProps: TooltipWrapperProps;
}

/**
 * @deprecated Avoid Fluent 8
 */
export const ActionButtonWithTooltip = ({
    title,
    tooltipProps,
    ...actionButtonProps
}: ActionButtonWithTooltipProps) => {
    return (
        <TooltipWrapper content={title} {...tooltipProps}>
            <ActionButton {...actionButtonProps}></ActionButton>
        </TooltipWrapper>
    );
};
