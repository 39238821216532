import React from 'react';
import { FocusZone } from '@fluentui/react';
import { ITheme } from '@fluentui/style-utilities';

import { TabItem, TabItemPropsBase } from './TabItem';

interface TabListProps {
    tabs: TabItemPropsBase[];
    theme: ITheme;
    selectedTabKey: string;
    onTabClick: (tabKey: string) => void;
}

export const TabList = React.memo((props: TabListProps) => {
    return (
        <FocusZone style={{ display: 'flex' }} role="tablist">
            {props.tabs.map((tabProps, index) => {
                return (
                    <TabItem
                        isSelected={props.selectedTabKey === tabProps.tabKey}
                        theme={props.theme}
                        key={tabProps.tabKey}
                        index={index}
                        {...tabProps}
                        onClick={props.onTabClick}
                    />
                );
            })}
        </FocusZone>
    );
});
