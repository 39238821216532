import {
    ColumnApi,
    ColumnState,
    GetMainMenuItems,
    GetMainMenuItemsParams,
    GridApi,
    GridOptions,
    GridReadyEvent,
    SuppressHeaderKeyboardEventParams,
    SuppressKeyboardEventParams,
} from '@ag-grid-community/core';
import * as clipboard from 'clipboard-polyfill';

import { VisualizationsLocale } from '../../types';

const ColumnsButtonSelector = '.ag-side-bar .ag-side-buttons button';
const headerSelector = (colId: string) => `.kusto-query-header[col-id="${colId}"]`;

export class AccessibleGrid {
    private columnApi: ColumnApi | null = null;

    constructor(
        private getGridElement: () => HTMLDivElement | null,
        private getAdditionalMenuItems?: GetMainMenuItems
    ) {}

    public init(e: GridReadyEvent) {
        this.columnApi = e.columnApi!;
    }

    public gridOptions(strings: VisualizationsLocale): GridOptions {
        return {
            ensureDomOrder: true,
            rowBuffer: 0,
            sideBar: {
                toolPanels: [
                    {
                        id: 'columns',
                        labelDefault: strings.visualizations.columns,
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                    },
                ],
                defaultToolPanel: '',
            },
            getMainMenuItems: (params: GetMainMenuItemsParams) => {
                return [
                    ...params.defaultItems,
                    'separator',
                    {
                        name: strings.visualizations.agGrid.accessability.sort.asc,
                        action: () => {
                            this.setSortForColumnId(params.column.getId(), 'asc');
                        },
                    },
                    {
                        name: strings.visualizations.agGrid.accessability.sort.desc,
                        action: () => {
                            this.setSortForColumnId(params.column.getId(), 'desc');
                        },
                    },
                    {
                        name: strings.visualizations.agGrid.accessability.sort.none,
                        action: () => {
                            this.clearSortForAllColumns();
                        },
                    },
                    'separator',
                    {
                        name: strings.visualizations.agGrid.accessability.copyColumnName,
                        action: () => {
                            const name = params.column.getColDef().headerName ?? params.column.getColDef().field ?? '';
                            const dt = new clipboard.DT();
                            dt.setData('text/plain', name);
                            clipboard.write(dt);
                        },
                    },
                    ...(this.getAdditionalMenuItems ? this.getAdditionalMenuItems(params) : []),
                ];
            },
            defaultColDef: {
                headerClass: 'kusto-query-header',
                suppressHeaderKeyboardEvent: (params: SuppressHeaderKeyboardEventParams) => {
                    if (params.event.key === 'Tab' && !params.event.shiftKey) {
                        this.moveToGridTable(params.api, params.columnApi);
                        return true;
                    }
                    return false;
                },
                suppressKeyboardEvent: ({ event, column }: SuppressKeyboardEventParams) => {
                    if (event.key === 'Tab') {
                        let focusSelector = null;
                        if (event.shiftKey) {
                            focusSelector = headerSelector(column.getColId());
                        } else {
                            focusSelector = ColumnsButtonSelector;
                        }
                        if (focusSelector) {
                            const focusElem = this.getGridElement()?.querySelector<HTMLElement>(focusSelector);
                            if (focusElem) {
                                event.preventDefault();
                                event.stopPropagation();
                                focusElem.focus();
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }
                    return false;
                },
            },
        };
    }

    public moveToGridTable = (gridApi: GridApi, columnApi: ColumnApi) => {
        const focusCell = gridApi.getFocusedCell();
        const focusRow = focusCell ? focusCell.rowIndex : 0;
        const focusCol = focusCell ? focusCell.column : this.getFirstColumn(columnApi);

        if (focusCol) {
            gridApi.ensureIndexVisible(focusRow);
            gridApi.ensureColumnVisible(focusCol);

            setTimeout(() => {
                gridApi.setFocusedCell(focusRow, focusCol);
            }, 10);
        }
    };

    private getFirstColumn = (columnApi: ColumnApi) => {
        const columns = columnApi.getAllGridColumns();
        return columns?.length > 0 ? columns[0] : null;
    };

    private setSortForColumnId = (colId: string, sort: ColumnState['sort']) => {
        if (!this.columnApi) return;

        const columnState = this.columnApi.getColumnState();
        const targetIndex = columnState.findIndex((currentColumnState) => currentColumnState.colId === colId);

        if (targetIndex !== -1) {
            columnState[targetIndex] = { ...columnState[targetIndex], sort };
            this.columnApi.applyColumnState({ state: columnState });
        }
    };

    private clearSortForAllColumns = () => {
        if (!this.columnApi) return;

        const columnState = this.columnApi.getColumnState();

        const noSortColumns = columnState.map((currentColumn) => {
            return { ...currentColumn, sort: null };
        });
        this.columnApi.applyColumnState({ state: noSortColumns });
    };
}
