import React, { useMemo, useState } from 'react';
import { Subtitle1, Tooltip } from '@fluentui/react-components';
import { Textbox16Regular } from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';

import { ColumnList, ColumnTypeIcon, SearchBoxDebounce, type ColumnDetails } from '@kusto/ui-components';

import { useDataExplorationContext } from '../../context/DataExplorationContext';
import { type ColumnWithInsights } from '../../DataExplorationApi';
import { ColumnSummary } from './ColumnSummary/ColumnSummary';

import scrollableStyles from '../ScrollableContent.module.scss';
import styles from './SchemaColumnList.module.scss';

interface MapColumnOptions extends ColumnWithInsights {
    timezone: string;
    isLoading?: boolean;
}

function mapColumn({
    ColumnName,
    ColumnType,
    maxValue,
    minValue,
    cardinality,
    isHighCardinality,
    description,
    timezone,
    isLoading,
}: MapColumnOptions): ColumnDetails {
    return {
        icon: (
            <Tooltip content={ColumnType!} relationship="label">
                <div>
                    {/* extra div required for tooltip target */}
                    <ColumnTypeIcon type={ColumnType} size="small" />
                </div>
            </Tooltip>
        ),
        suffixIcon: description ? (
            <Tooltip content={description} relationship="description">
                <Textbox16Regular />
            </Tooltip>
        ) : undefined,
        title: ColumnName,
        id: ColumnName,
        columnInfo: (
            <ColumnSummary
                ColumnType={ColumnType}
                minValue={minValue}
                maxValue={maxValue}
                cardinality={cardinality}
                isHighCardinality={isHighCardinality}
                timezone={timezone}
                isLoading={isLoading}
            />
        ),
        type: 'column',
    };
}

export interface SchemaColumnListProps {
    /** list of columns to display */
    columns: ColumnWithInsights[];
    /** 'name' of selected column */
    selected?: string;
    /** callback that fire when selected column changes */
    onSelectedChange?: (columnName: string) => void;
    /** timezone for formatting data/time */
    timezone: string;
    appearance?: 'subtle' | 'pretty';
    isLoadingInsights?: boolean;
}

export const SchemaColumnList: React.FunctionComponent<SchemaColumnListProps> = observer(function SchemaColumnList({
    columns,
    selected,
    onSelectedChange,
    appearance,
    timezone,
    isLoadingInsights,
}) {
    const { t } = useDataExplorationContext();

    const [filterText, setFilterText] = useState<string>('');

    const filterValue = filterText.toLowerCase();
    const filteredColumns = useMemo(
        () =>
            columns
                .map((col) => mapColumn({ ...col, timezone, isLoading: isLoadingInsights }))
                .filter((col) => col.title.toLowerCase().includes(filterValue)),
        [columns, filterValue, isLoadingInsights, timezone]
    );

    return (
        <div className={styles.stylesWrapper}>
            <div className={styles.schemaColumnList}>
                <Subtitle1>{t.dataExploration.schemaInsights.columnListTitle}</Subtitle1>
                <SearchBoxDebounce
                    placeholder={t.dataExploration.schemaInsights.searchColumnsPlaceholder}
                    searchText={filterText}
                    onChange={setFilterText}
                    style={{ width: '100%' }}
                />
                <div className={scrollableStyles.scrollableContent}>
                    <ColumnList
                        columns={filteredColumns}
                        selectedId={selected}
                        onSelect={(col) => onSelectedChange?.(col)}
                        appearance={appearance}
                    />
                </div>
            </div>
        </div>
    );
});
