import { extractErrorDescriptionAndTrace, KustoDomains } from '@kusto/client';
import { Account, err, ok, Result } from '@kusto/utils';

import { QueryStoreEnv } from '../stores';
import { getClusterUrl } from '../stores/cluster';
import { getTelemetryClient, SeverityLevels } from './telemetryClient';

const { trackTrace } = getTelemetryClient({
    component: 'testConnection',
    flow: '',
});
const privateEndPointPrefix = 'private-';

interface ConnectionTestError {
    errorMessage: string;
    learnMoreLink?: string;
}

const testUrl = async (
    env: QueryStoreEnv,
    kustoDomains: KustoDomains,
    url: string,
    initialCatalog?: string,
    clusterName?: string,
    account?: Account
): Promise<Result<boolean, ConnectionTestError>> => {
    try {
        if (!kustoDomains.isAriaDomain(url)) {
            const kustoRequest = env.kustoClient.createRequest(url, { source: 'Query' });
            await kustoRequest.executeControlCommand_deprecated(initialCatalog, '.show version', { account });
        }
    } catch (e) {
        let description = extractErrorDescriptionAndTrace(e).errorMessage;
        if (description === 'Failed to fetch' || description === 'XHR error' || description === 'Network Error') {
            if (clusterName?.startsWith(privateEndPointPrefix)) {
                return err({
                    errorMessage: env.strings.query.badConnection$privateConnection,
                    learnMoreLink: 'https://docs.microsoft.com/azure/data-explorer/ingest-data-one-click#prerequisites',
                });
            }
            description = env.strings.query.badConnection;
        }

        return err({ errorMessage: description });
    }
    return ok(true);
};

const getConnectionString = (clusterName: string, domain: string) => `https://${clusterName}${domain}/;fed=true`;

/**
 * This method uses kusto client to see if the cluster responds to a simple .show version api call.
 * @param clusterName The cluster Name
 * @param connectionString The connection string
 * @param initialCatalog optional initial catalog
 */
export async function testConnection(
    env: QueryStoreEnv,
    clusterName: string,
    connectionString: string,
    initialCatalog?: string,
    account?: Account
): Promise<Result<string | undefined, ConnectionTestError>> {
    trackTrace('testConnection', SeverityLevels.Information, { flow: 'testConnection', clusterName });

    // TODO: once trident cluster format is stable, should try to detect trident cluster from name
    // and optimize the test connection to first try trident domain and then rest of domains

    const { kustoDomainResponse, url } = await testConnectionToDomainPostfix(
        env,
        connectionString,
        clusterName,
        env.kustoDomains.Default,
        env.kustoDomains,
        initialCatalog,
        account
    );

    const othersPostFixes = [
        env.kustoDomains.SynapseDefault,
        env.kustoDomains.TridentDefault,
        env.kustoDomains.TridentOldDefault,
    ];

    // is one of the known kusto domain postfixes specified in the connection string
    const specificPostFixedUsed = othersPostFixes.some((postFix) => url.indexOf(postFix) > 0);

    // in case default kusto domain doesn't work try to connect with kusto pool(synapse) default domain
    if (
        kustoDomainResponse.kind === 'err' &&
        clusterName &&
        url.indexOf(env.kustoDomains.Default) > 0 &&
        !specificPostFixedUsed
    ) {
        const otherDomainsResponses = await Promise.all(
            othersPostFixes.map((postFix) =>
                testConnectionToDomainPostfix(
                    env,
                    undefined,
                    clusterName,
                    postFix,
                    env.kustoDomains,
                    initialCatalog,
                    account
                )
            )
        );

        const successfulPostFix = otherDomainsResponses.find((response) => response.kustoDomainResponse.kind === 'ok');
        // in case of error return the original error response
        if (successfulPostFix) {
            return ok(successfulPostFix.connectionString);
        }
    }

    return kustoDomainResponse.kind === 'err' ? kustoDomainResponse : ok();
}

// Add domain postfix and test if it valid connection
// for example 'help' cluster name  & 'kusto.windows.net' post fix will be 'help.kusto.windows.net'
async function testConnectionToDomainPostfix(
    env: QueryStoreEnv,
    connectionString: string | undefined,
    clusterName: string,
    domainPostfix: string,
    kustoDomains: KustoDomains,
    initialCatalog: string | undefined,
    account: Account | undefined
) {
    connectionString = connectionString || getConnectionString(clusterName, domainPostfix);
    const url = getClusterUrl(connectionString);

    const kustoDomainResponse = await testUrl(env, kustoDomains, url, initialCatalog, clusterName, account);
    return { kustoDomainResponse, connectionString, url };
}
