import React from 'react';
import { Body1Strong } from '@fluentui/react-components';

import { useStrings } from '../../../../../hooks/useStrings';
import { pillToKql } from '../../../../../kqlParser/pillToKql';
import { Pill } from '../../../../../types/Pills.interfaces';
import { KQLViewer } from '../../../../KQLViewer/KQLViewer';

import styles from './KqlPreview.module.scss';

export interface KqlPreviewProps {
    pill?: Pill;
}

/** Container to display KQL code with 'preview' title */
export const KqlPreview: React.FunctionComponent<KqlPreviewProps> = ({ pill }) => {
    const { strings } = useStrings();
    const kqlText = pill ? pillToKql(pill) : strings.dataExplorationModal.pillsBar.invalidKql;

    return (
        <div className={styles.kqlPreview}>
            <Body1Strong className={styles.kqlPreviewTitle}>
                {strings.dataExplorationModal.pillsBar.kqlPreview}
            </Body1Strong>
            <KQLViewer kqlText={kqlText} hideLineNumbers className={styles.kqlPreviewViewer} />
        </div>
    );
};
