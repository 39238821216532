/**
 * Gets the difference between two Set<string>
 *
 * Minor improvement over lodash's `difference()`
 * since this works directly over Sets and we
 * can avoid needing to transform the
 * input Sets to an Array.
 */
export function setDifference(left: Set<string>, right: Set<string>): Set<string> {
    const seen: Record<string, boolean> = {};
    const diffSet = new Set<string>();

    for (const x of left) {
        if (!right.has(x)) {
            diffSet.add(x);
            seen[x] = true;
        }
    }

    for (const x of right) {
        if (!seen[x]) {
            if (!left.has(x)) {
                diffSet.add(x);
                seen[x] = true;
            }
        }
    }

    return diffSet;
}
