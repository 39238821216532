import React from 'react';
import { Button, Caption1, Link, Spinner } from '@fluentui/react-components';
import { ArrowLeft20Regular, Info16Regular } from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';

import { type DataTableColumn } from '@kusto/client';
import { ColumnTypeIcon } from '@kusto/ui-components';

import { useDataExplorationContext } from '../context/DataExplorationContext';
import {
    useFullRowCount,
    useSchemaWithInsights,
    useScopedRowCount,
    type ColumnWithInsights,
    type DataProfileTimeRange,
} from '../DataExplorationApi';
import { useStrings } from '../DataExplorationModal/hooks/useStrings';
import { SchemaColumnInfo } from './SchemaColumnInfo/SchemaColumnInfo';
import { SchemaColumnList } from './SchemaColumnList/SchemaColumnList';

import styles from './SchemaInsights.module.scss';

interface SchemaInsightsInternalProps {
    queryText: string;
    columns: ColumnWithInsights[];
    timeRange: DataProfileTimeRange;
    timezone: string;
    showLoader: boolean;
    hasColdData: boolean;
}

export interface ColumnWithDescription extends DataTableColumn {
    description?: string;
}

export interface SchemaInsightsProps
    extends Omit<SchemaInsightsInternalProps, 'columns' | 'showLoader' | 'hasColdData'> {
    columns: ColumnWithDescription[];
    viewMode: 'narrow' | 'wide';
}

function useHasColdData(queryText: string) {
    const { data: fullRowCount } = useFullRowCount(queryText);
    const { data: hotRowCount } = useScopedRowCount(queryText, { type: 'filterDisabled' });

    return !!(fullRowCount && hotRowCount && hotRowCount < fullRowCount);
}

const StatusBar: React.FC<{ isLoading: boolean; hasColdData: boolean }> = observer(function StatusBar({
    isLoading,
    hasColdData,
}) {
    const { strings } = useStrings();

    if (isLoading) {
        return (
            <div className={styles.statusBar}>
                <Spinner size="extra-tiny" />
            </div>
        );
    }

    if (hasColdData) {
        return (
            <div className={styles.statusBar}>
                <Info16Regular />
                <Caption1>
                    {strings.schemaInsights.statusBar.hasColdData}{' '}
                    <Link href="https://go.microsoft.com/fwlink/?linkid=2255777" target="_blank">
                        {strings.schemaInsights.statusBar.learnMore}
                    </Link>
                </Caption1>
            </div>
        );
    }

    return null;
});

export const SchemaInsights: React.FC<SchemaInsightsProps> = ({ viewMode, columns, ...props }) => {
    const { clusterUrl, databaseName } = useDataExplorationContext();
    const { data: columnsWithInsights, isValidating } = useSchemaWithInsights(
        props.queryText,
        columns,
        props.timeRange
    );

    const hasColdData = useHasColdData(props.queryText);

    if (!columnsWithInsights) {
        // TODO - Loading spinner or such
        return null;
    }

    return viewMode === 'wide' ? (
        <SchemaInsightsWide
            key={`${clusterUrl}/${databaseName}/${props.queryText}`}
            columns={columnsWithInsights}
            showLoader={isValidating}
            hasColdData={hasColdData}
            {...props}
        />
    ) : (
        <SchemaInsightsNarrow
            columns={columnsWithInsights}
            showLoader={isValidating}
            hasColdData={hasColdData}
            {...props}
        />
    );
};

export const SchemaInsightsWideContent: React.FC<Omit<SchemaInsightsInternalProps, 'hasColdData' | 'showLoader'>> = ({
    queryText,
    columns,
    timeRange,
    timezone,
}) => {
    const [selectedColumnName, setSelectedColumnName] = React.useState<string>(columns[0].ColumnName);

    const selectedColumn = React.useMemo(
        () => columns.find((col) => col.ColumnName === selectedColumnName) ?? columns[0],
        [selectedColumnName, columns]
    );

    return (
        <div className={`${styles.schemaInsightsWrapper} ${styles.fixedSize}`}>
            <div className={styles.content}>
                <div className={styles.sectionWrapper}>
                    <SchemaColumnList
                        columns={columns}
                        selected={selectedColumn.ColumnName}
                        onSelectedChange={(name) => setSelectedColumnName(name)}
                        timezone={timezone}
                        appearance="pretty"
                    />
                </div>
                {selectedColumn ? (
                    <div className={styles.sectionWrapper}>
                        <SchemaColumnInfo
                            selectedColumn={selectedColumn}
                            queryText={queryText}
                            timeRange={timeRange}
                            timezone={timezone}
                            title={
                                <div className={styles.selectedColumn}>
                                    <ColumnTypeIcon type={selectedColumn.ColumnType} size="small" />
                                    {selectedColumn.ColumnName}
                                </div>
                            }
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const SchemaInsightsWide: React.FC<SchemaInsightsInternalProps> = ({ showLoader, hasColdData, ...props }) => {
    return (
        <div className={`${styles.box}`}>
            <SchemaInsightsWideContent {...props} />
            <StatusBar isLoading={showLoader} hasColdData={hasColdData} />
        </div>
    );
};

const SchemaInsightsNarrow: React.FC<SchemaInsightsInternalProps> = ({
    queryText,
    columns,
    timeRange,
    timezone,
    showLoader,
    hasColdData,
}) => {
    const [selectedColumnName, setSelectedColumnName] = React.useState<string>();

    const selectedColumn = React.useMemo(
        () => (selectedColumnName ? columns.find((col) => col.ColumnName === selectedColumnName) : undefined),
        [selectedColumnName, columns]
    );

    return (
        <div className={styles.schemaInsightsWrapper}>
            <div className={styles.content}>
                <div className={styles.sectionWrapper}>
                    {selectedColumn ? (
                        <SchemaColumnInfo
                            selectedColumn={selectedColumn}
                            queryText={queryText}
                            timeRange={timeRange}
                            timezone={timezone}
                            title={
                                <Button
                                    className={styles.backButton}
                                    onClick={() => setSelectedColumnName(undefined)}
                                    appearance="transparent"
                                    icon={<ArrowLeft20Regular />}
                                    size="medium"
                                >
                                    {selectedColumn.ColumnName}
                                </Button>
                            }
                        />
                    ) : (
                        <SchemaColumnList
                            columns={columns}
                            onSelectedChange={(name) => setSelectedColumnName(name)}
                            timezone={timezone}
                            appearance="pretty"
                        />
                    )}
                </div>
            </div>
            <StatusBar isLoading={showLoader} hasColdData={hasColdData} />
        </div>
    );
};
