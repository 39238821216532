import { editor, Position, Range } from 'monaco-editor/esm/vs/editor/editor.api';

import { initWorker } from '@kusto/kusto-schema';

export function unescapeText(text: string) {
    const cleanStr = text
        .replace(/\r\n?/g, '\n') // normalize \r or \r\n to \n newline
        .split('\n')
        .map((line) =>
            // check if surrounded by quote - if it does - unescape
            line.replace(/^(\s*(['"]))(.*)((\2)\s*)$/, (_line, _prefix, quoteType, content) =>
                content
                    // change \' to '
                    .replace(quoteType === '"' ? /\\"/g : /\\'/g, quoteType)
                    // change double slash to slash
                    .replace(/\\\\/g, '\\')
                    // remove new lines \r or \n or \r\n
                    .replace(/(\\r)?(\\n)?$/, '')
            )
        )
        .join('\n');
    return cleanStr;
}

export function escapeText(text: string) {
    const escapedStr = text.replace(/\\/g, '\\\\').replace(/'/g, "\\'");
    const lines = escapedStr
        .replace(/\r\n?/g, '\n') // normalize \r or \r\n to \n newline
        .split('\n');
    const multilineEscapedStr = lines
        .map((line, index) =>
            // ignore empty last line
            index === lines.length - 1 && line === '' ? '' : `'${line}\\n'`
        )
        .join('\n');

    return multilineEscapedStr;
}

export interface SelectionDetails {
    value?: string;
    range?: Range;
    position?: Position;
}

export function getSelectionDetails(editor: editor.IStandaloneCodeEditor): SelectionDetails {
    const model = editor.getModel();
    if (!model) {
        return {};
    }

    const selection = editor.getSelection();
    const position = editor.getPosition();

    let range: Range;
    if (selection?.startLineNumber !== selection?.endLineNumber || selection?.startColumn !== selection?.endColumn) {
        range = selection!;
    } else if (position) {
        range = new Range(position.lineNumber, 1, position.lineNumber + 1, 1);
    } else {
        return {};
    }

    const value = model.getValueInRange(range) || '';

    return { value, position: position || undefined, range };
}

export async function getEndOfQuery(editor: editor.IStandaloneCodeEditor): Promise<Range | null> {
    const worker = await initWorker(editor);
    if (!worker) {
        return null;
    }

    const model = editor.getModel();

    if (!model) {
        return null;
    }

    const position = editor.getPosition();
    if (!position) {
        return null;
    }

    const offset = model.getOffsetAt(position);

    const result = await worker.getCommandAndLocationInContext(model.uri.toString(), offset);
    if (!result || !result.range) {
        return null;
    }

    let { endLineNumber } = result.range;
    // find the real last line of the command
    while (
        endLineNumber > result.range.startLineNumber &&
        // the range that we get might include the beginning of the next command, check current end line -1
        // till the end of the text - check current end line
        (model.getLineFirstNonWhitespaceColumn(endLineNumber) === 0 ||
            model.getLineFirstNonWhitespaceColumn(endLineNumber - 1) === 0)
    ) {
        endLineNumber--;
    }
    const endColumn = model.getLineLastNonWhitespaceColumn(endLineNumber);

    // This range starts and ends in the same position - after the last character of query.
    return new Range(endLineNumber, endColumn, endLineNumber, endColumn);
}
