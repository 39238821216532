import * as React from 'react';

import type { FeatureFlags } from '@kusto/app-common';
import type { IKweTelemetry } from '@kusto/utils';
import type * as Fwk from '@kusto/visual-fwk';
import type { ChartEvents, ChartProps, KustoDataProps } from '@kusto/visualizations';

import type { RtdProviderLocale } from './i18n';
import type { SupportedPlotlyVersion } from './visuals/Plotly';

/**
 * We only need the type `IOriginAllowlist` from `@kusto/query` inside
 * rtd-provider but importing it would create a cyclic dependency. So
 * creating a partial copy of the interface here to avoid that build issue.
 *
 * TODO: Extract the Settings model from `@kusto/query` into it's own package.
 */
export interface TO_BE_REMOVED_IOriginAllowlist {
    get allowlistParsed(): { valid: Set<string>; invalid: Set<string> };
    add(...domains: Array<string | URL | Location>): void;
}

export interface KweRtdVisualContext {
    readonly chartProps: Pick<ChartProps, 'useBoost' | 'showAllSeriesOnHover' | 'ignoreChartLimits'>;
    readonly chartEvents: Partial<Pick<ChartEvents, 'presentingError' | 'presentingChart' | 'disableChartLimits'>>;
    readonly strings: RtdProviderLocale;
    readonly azureMapSubscriptionKey: undefined | string;
    readonly telemetry: IKweTelemetry;
    readonly publicRtdPlotlyUrl: (version: SupportedPlotlyVersion) => string;
    readonly renderTable: (args: KustoDataProps) => React.ReactElement;
    // TODO: remove this once we get rid of the FF
    readonly securePlotlyIframe: boolean;
    readonly originAllowlist: TO_BE_REMOVED_IOriginAllowlist;
    readonly flags: FeatureFlags<Fwk.VisualFwkFeatureFlag>;
}
