import React from 'react';

import * as Charting from '@kusto/charting';
import * as client from '@kusto/client';
import { assertNever, err, formatLiterals, KweException, Ok, ok, Result } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';
import type { ChartEvents } from '@kusto/visualizations';

import {
    COLUMN_INTERACTION_ID,
    DRAG_END_PROPERTY_ID,
    DRAG_INTERACTION_ID,
    DRAG_START_PROPERTY_ID,
    DRAG_TIME_RANGE_PROPERTY_ID,
} from '../../constants';
import type { KweRtdVisualContext } from '../../context';
import { clientTaggedValueToKweTaggedValue } from '../../utils';
import type { Interaction } from './types';

/**
 *  (2 ** 31) - 1
 */
export const intMax = 2_147_483_647;
/**
 * -1 * 2 ** 31
 */
export const intMin = -2_147_483_648;

function clamp(value: number, min: number, max: number) {
    if (value > max) {
        return max;
    }
    if (value < min) {
        return min;
    }
    return value;
}

/**
 * Highest number representable in javascript number that is smaller than long max
 */
const nextNearestToLongMax = 9223372036854775000;

/**
 * Lowest number representable in javascript number that is larger than long min
 */
const nextNearestToLongMin = -9223372036854775000;

/**
 * No errors returns because converting is always best-effort once we know we're dealing with a numeric value
 */
function rtdValueFromXLocation(value: number, type: Fwk.TaggedBasicNumericType): Fwk.TaggedValue.UBasicScalar {
    switch (type) {
        case 'datetime':
            return Fwk.tagScalar('datetime', Math.round(value));
        case 'real':
            return Fwk.tagScalar('real', value);
        case 'int':
            return Fwk.tagScalar('int', clamp(Math.round(value), intMin, intMax));
        case 'long':
            // Using "toFixed" to ensure
            return Fwk.tagScalar('long', clamp(value, nextNearestToLongMin, nextNearestToLongMax).toFixed(0));
        case 'decimal':
            return Fwk.tagScalar('decimal', value.toString());
        default:
            assertNever(type);
    }
}

function extractRtdValue(
    column: string,
    columns: readonly client.KustoColumn[],
    row: client.KustoQueryResultRowObject,
    ctx: KweRtdVisualContext
): Fwk.MaybeParamValue {
    const columnDef = columns.find((c) => c.field === column);

    if (columnDef === undefined) {
        return err(
            formatLiterals(ctx.strings.rtdProvider.visuals.crossFiltering$configuredColumnNotFound, {
                columnName: column,
            })
        );
    }

    if (!(column in row)) {
        throw new KweException(`Unexpectedly missing column "${column}" in row`);
    }

    const value = row[column];

    const tagged = clientTaggedValueToKweTaggedValue({ value, dataType: columnDef.columnType });

    return ok(tagged.kind === 'null' ? Fwk.ALL_SELECTION : tagged);
}

/**
 * Convert min/match to match the type the heuristic inferred, or do the best we can with "numeric".
 *
 * TODO: Currently we're just leaving min/max as real if the column is "numeric", but I think we can/should do better
 */
function seriesFormattedStartEndValues(
    min: number,
    max: number,
    heuristicsOk: Interaction.HeuristicsOk,
    ctx: KweRtdVisualContext
): Record<'start' | 'end', Fwk.MaybeParamValue> {
    let start: Fwk.MaybeParamValue;
    let end: Fwk.MaybeParamValue;

    switch (heuristicsOk.kustoHeuristics.argumentType) {
        case undefined:
        case Charting.ArgumentColumnType.None:
            throw new KweException('Unexpectedly missing kusto heuristics argument type');
        case Charting.ArgumentColumnType.String:
        case Charting.ArgumentColumnType.Object:
        case Charting.ArgumentColumnType.Geospatial:
            start = err(ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$xisMustBeNumeric);
            end = start;
            break;
        case Charting.ArgumentColumnType.TimeSpan:
            start = err(
                ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$columnTimespanNotSupportedError
            );
            end = start;
            break;
        // TODO: Dashboards currently does not support casting real to all
        // numeric parameters in all cases. (Maybe are supported as "maybe")
        // We'll either need to update dashboards, or update the config to
        // "numeric" and cast to the parameters type here
        case Charting.ArgumentColumnType.Numeric:
            start = ok(Fwk.tagScalar('real', min));
            end = ok(Fwk.tagScalar('real', max));
            break;
        case Charting.ArgumentColumnType.DateTime:
            start = ok(rtdValueFromXLocation(min, 'datetime'));
            end = ok(rtdValueFromXLocation(max, 'datetime'));
            break;
        // As far as I can tell compound types
        case Charting.ArgumentColumnType.DateTimeOrTimeSpan:
        case Charting.ArgumentColumnType.StringOrDateTimeOrTimeSpan:
        case Charting.ArgumentColumnType.NumericOrDateTimeOrTimeSpan:
        case Charting.ArgumentColumnType.StringOrObject:
        case Charting.ArgumentColumnType.AllExceptGeospatial:
            throw new KweException(
                `Unexpected kusto heuristic argument type ${heuristicsOk.kustoHeuristics.argumentType}`
            );
        default:
            assertNever(heuristicsOk.kustoHeuristics.argumentType);
    }

    return { start, end };
}

/**
 * Convert min/max to match data set column type
 */
function startEndValuesFromXColumnType(
    min: number,
    max: number,
    heuristicsOk: Interaction.HeuristicsOk,
    queryResult: Fwk.OkQueryResult,
    ctx: KweRtdVisualContext
): Record<'start' | 'end', Fwk.MaybeParamValue> {
    if (heuristicsOk.xColumn === undefined) {
        const start = err(ctx.strings.rtdProvider.visuals.input.unableToInferXColumn);
        return { start, end: start };
    }
    const column = queryResult.dataFrame.fields.find((c) => c.name === heuristicsOk.xColumn);
    if (column === undefined) {
        const start = err(
            formatLiterals(ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$columnNotFoundError, {
                columnName: heuristicsOk.xColumn,
            })
        );
        return { start, end: start };
    }

    let start: Fwk.MaybeParamValue;
    let end: Fwk.MaybeParamValue;

    if (column.type === 'bool' || column.type === 'dynamic' || column.type === 'string' || column.type === 'guid') {
        start = err(ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$xColumnNotNumericError);
        end = start;
    } else if (column.type === 'timespan') {
        start = err(ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$columnTimespanNotSupportedError);
        end = start;
    } else {
        start = ok(rtdValueFromXLocation(min, column.type));
        end = ok(rtdValueFromXLocation(max, column.type));
    }

    return { start, end };
}

function rtdValuesFromDragEvent(
    min: number,
    max: number,
    heuristicsResult: Interaction.HeuristicsResult,
    queryResult: Fwk.OkQueryResult,
    ctx: KweRtdVisualContext
): Result<Record<'timeRange' | 'start' | 'end', Fwk.MaybeParamValue>, React.ReactNode> {
    if (heuristicsResult.kind === 'err') {
        return heuristicsResult;
    }

    let timeRange: Fwk.MaybeParamValue;

    if (heuristicsResult.value.kustoHeuristics.argumentType !== Charting.ArgumentColumnType.DateTime) {
        timeRange = err(ctx.strings.rtdProvider.visuals.highcharts.crossFilterDimensions$timeRangeNotAvailable);
    } else {
        timeRange = ok(Fwk.tagFixedDuration(new Date(min), new Date(max)));
    }

    const { start, end } = heuristicsResult.value.kustoHeuristics.metaData?.IsDataFormedAsSeries
        ? // Min/max value are real values, but we'll convert them to the type
          // of the matching column when possible to make things nicer for our
          // users
          seriesFormattedStartEndValues(min, max, heuristicsResult.value, ctx)
        : startEndValuesFromXColumnType(min, max, heuristicsResult.value, queryResult, ctx);

    return ok({ timeRange, start, end });
}
type Props = Fwk.IDataVisualProps<'crossFilter' | 'crossFilterDisabled'>;

export type CrossFilterHeuristics = null | Pick<Interaction.HeuristicsSuccess, 'columns' | 'result'>;

export function useCrossFilterEvents(
    ctx: KweRtdVisualContext,
    visualOptions: Props['visualOptions'],
    heuristics: CrossFilterHeuristics,
    dashboardApi: undefined | Fwk.DashboardVisualApi,
    queryResult: Props['queryResult']
) {
    const applyCrossFilter = dashboardApi?.crossFilter;
    const crossFilterConfigs = visualOptions.crossFilter;
    const crossFilterDisabled = visualOptions.crossFilterDisabled;
    const events:
        | undefined
        | Partial<Pick<ChartEvents, 'onPointClick' | 'onHighChartsDragXEnd' | 'onHighchartsPointMenuItems'>> =
        React.useMemo(() => {
            if (crossFilterDisabled || crossFilterConfigs.length === 0 || !applyCrossFilter || !heuristics) {
                return undefined;
            }

            const events: Partial<
                Pick<ChartEvents, 'onPointClick' | 'onHighChartsDragXEnd' | 'onHighchartsPointMenuItems'>
            > = {};

            const heuristicsResult = heuristics.result;

            const columnInteractions: Array<{ parameterId: string; column: string }> = [];
            for (const config of crossFilterConfigs) {
                if (Fwk.crossFilterIsReady(config) && config.interaction === COLUMN_INTERACTION_ID) {
                    columnInteractions.push({ parameterId: config.parameterId, column: config.property });
                }
            }

            if (columnInteractions.length !== 0) {
                const onPointClick = (row: undefined | client.KustoQueryResultRowObject) => {
                    if (!row) return;

                    const res = columnInteractions.map((c) => {
                        const maybeValue = extractRtdValue(c.column, heuristics.columns, row, ctx);

                        const okPairValue: Ok<Fwk.InteractionPair> = ok({
                            parameterId: c.parameterId,
                            value: maybeValue,
                        });

                        return okPairValue;
                    });

                    applyCrossFilter(ok(res));
                };

                events.onPointClick = onPointClick;
                events.onHighchartsPointMenuItems = (row: undefined | client.KustoQueryResultRowObject) => [
                    {
                        text: ctx.strings.rtdProvider.visuals.crossFilterContextMenuText,
                        key: 'cross-filter',
                        onClick: () => onPointClick(row),
                    },
                ];
            }

            const dragInteractions: ['timeRange' | 'start' | 'end', string][] = [];
            for (const config of crossFilterConfigs) {
                if (
                    Fwk.crossFilterIsReady(config) &&
                    config.interaction === DRAG_INTERACTION_ID &&
                    (config.property === DRAG_TIME_RANGE_PROPERTY_ID ||
                        config.property === DRAG_START_PROPERTY_ID ||
                        config.property === DRAG_END_PROPERTY_ID)
                ) {
                    dragInteractions.push([config.property, config.parameterId]);
                }
            }
            if (dragInteractions.length !== 0) {
                events.onHighChartsDragXEnd = (min: number, max: number) => {
                    const res = rtdValuesFromDragEvent(min, max, heuristicsResult, queryResult, ctx);
                    for (const [interaction, parameterId] of dragInteractions) {
                        if (res.kind === 'err') {
                            const okPairValue: Ok<Fwk.InteractionPair> = ok({
                                parameterId: parameterId,
                                value: res,
                            });

                            applyCrossFilter(ok([okPairValue]));
                        } else {
                            const okPairValue: Ok<Fwk.InteractionPair> = ok({
                                parameterId: parameterId,
                                value: res.value[interaction],
                            });

                            applyCrossFilter(ok([okPairValue]));
                        }
                    }
                    return undefined;
                };
            }

            return events;
        }, [crossFilterDisabled, crossFilterConfigs, applyCrossFilter, heuristics, queryResult, ctx]);

    return events;
}
