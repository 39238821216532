import { DATA_POSITIONING_ESCAPED, DATA_POSITIONING_HIDDEN, DATA_POSITIONING_INTERSECTING, DATA_POSITIONING_PLACEMENT } from '../constants';
/**
 * Writes all container element position updates after the position is computed
 */ export function writeContainerUpdates(options) {
    var _middlewareData_hide, _middlewareData_hide1, _container_ownerDocument_defaultView;
    const { container, placement, middlewareData, strategy, lowPPI, coordinates, useTransform = true } = options;
    if (!container) {
        return;
    }
    container.setAttribute(DATA_POSITIONING_PLACEMENT, placement);
    container.removeAttribute(DATA_POSITIONING_INTERSECTING);
    if (middlewareData.intersectionObserver.intersecting) {
        container.setAttribute(DATA_POSITIONING_INTERSECTING, '');
    }
    container.removeAttribute(DATA_POSITIONING_ESCAPED);
    if ((_middlewareData_hide = middlewareData.hide) === null || _middlewareData_hide === void 0 ? void 0 : _middlewareData_hide.escaped) {
        container.setAttribute(DATA_POSITIONING_ESCAPED, '');
    }
    container.removeAttribute(DATA_POSITIONING_HIDDEN);
    if ((_middlewareData_hide1 = middlewareData.hide) === null || _middlewareData_hide1 === void 0 ? void 0 : _middlewareData_hide1.referenceHidden) {
        container.setAttribute(DATA_POSITIONING_HIDDEN, '');
    }
    // Round so that the coordinates land on device pixels.
    // This prevents blurriness in cases where the browser doesn't apply pixel snapping, such as when other effects like
    // `backdrop-filter: blur()` are applied to the container, and the browser is zoomed in.
    // See https://github.com/microsoft/fluentui/issues/26764 for more info.
    const devicePixelRatio = ((_container_ownerDocument_defaultView = container.ownerDocument.defaultView) === null || _container_ownerDocument_defaultView === void 0 ? void 0 : _container_ownerDocument_defaultView.devicePixelRatio) || 1;
    const x = Math.round(coordinates.x * devicePixelRatio) / devicePixelRatio;
    const y = Math.round(coordinates.y * devicePixelRatio) / devicePixelRatio;
    Object.assign(container.style, {
        position: strategy
    });
    if (useTransform) {
        Object.assign(container.style, {
            transform: lowPPI ? `translate(${x}px, ${y}px)` : `translate3d(${x}px, ${y}px, 0)`
        });
        return;
    }
    Object.assign(container.style, {
        left: `${x}px`,
        top: `${y}px`
    });
}
