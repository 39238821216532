import React from 'react';

import { formatLiterals } from '@kusto/utils';

import { InteractionErrorMessage, interactionErrorMessage, InteractionTarget } from '../../interactions/target';
import type { KweVisualFwkLocale } from '../../types';
import type { CrossFilterConfig } from '../../visualOptions';
import type { InteractionsIndex } from './CrossFilter';
import type { CrossFilterVisual } from './VisualInteraction';

function localStrings(strings: KweVisualFwkLocale) {
    return strings.visualFwk.visualConfig.interactions.crossFilter;
}

export function crossFilterTypeWarning(
    t: KweVisualFwkLocale,
    { parameter, interaction, property }: ResolvedSelections
): undefined | InteractionErrorMessage {
    if (parameter === undefined || interaction === undefined || property?.dataTypes === undefined) {
        return undefined;
    }

    const res = parameter.canApply(property.dataTypes);

    switch (res.kind) {
        case 'error':
            return interactionErrorMessage(
                () =>
                    formatLiterals(localStrings(t).propertyCannotBeAssignedToParameter, {
                        propertiesName:
                            interaction.propertiesDisplayName ?? localStrings(t).property.defaultDropdownLabel,
                        propertyName: property.displayName ?? property.id,
                        parameterName: parameter.displayName,
                        messageList: res.errors.map((e) => e.text()).join(', '),
                    }),
                'error'
            );
        case 'warn':
            return interactionErrorMessage(
                () =>
                    formatLiterals(localStrings(t).propertyMayNotBeAssignableToParameter, {
                        propertiesName:
                            interaction.propertiesDisplayName ?? localStrings(t).property.defaultDropdownLabel,
                        propertyName: property.displayName ?? property.id,
                        parameterName: parameter.displayName,
                        messageList: res.errors.map((e) => e.text()).join(', '),
                    }),
                'warn'
            );
    }

    return undefined;
}

export interface ResolvedSelections {
    readonly parameter: InteractionTarget | undefined;
    readonly interaction?: undefined | CrossFilterVisual;
    readonly property?: undefined | CrossFilterVisual.Property;
}

export function useResolveSelections(
    value: CrossFilterConfig,
    interactionsIndex: InteractionsIndex,
    parametersRecord: ReadonlyMap<string, InteractionTarget> | undefined
): ResolvedSelections {
    const values = React.useMemo(() => {
        const maybeInteraction = value.interaction && interactionsIndex.get(value.interaction);
        if (!maybeInteraction) {
            return {};
        }
        let property: undefined | CrossFilterVisual.Property;

        if (maybeInteraction.properties && value.property !== undefined) {
            property = maybeInteraction.properties.get(value.property);
        }

        return { interaction: maybeInteraction.interaction, property };
    }, [interactionsIndex, value.interaction, value.property]);

    let parameter: undefined | InteractionTarget = undefined;

    if (value.parameterId) {
        parameter = parametersRecord?.get(value.parameterId);
    }

    return {
        ...values,
        parameter,
    };
}

export function formatUnavailable(strings: KweVisualFwkLocale, category: string) {
    return formatLiterals(localStrings(strings).unavailable, { category });
}
