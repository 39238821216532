import React, { useCallback, useEffect, useState } from 'react';
import type { IStyleFunctionOrObject } from '@fluentui/merge-styles';
import { Target, useId } from '@fluentui/react-hooks';
import {
    Callout,
    DirectionalHint,
    ICalloutContentStyleProps,
    ICalloutContentStyles,
} from '@fluentui/react/lib/Callout';
import { Icon, IIconProps } from '@fluentui/react/lib/Icon';
import type { IStyle, ITheme } from '@fluentui/style-utilities';
import { classNamesFunction, styled } from '@fluentui/utilities';

import { IconButtonWithTooltip } from './componentsWithTooltip';

export const NotificationType = {
    Success: 'Success',
    Error: 'Error',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type NotificationType = keyof typeof NotificationType;

export interface INotification {
    title: string;
    content: string;
    type: NotificationType;
}

type NotificationStyleProps = { transition: boolean } & Required<Pick<NotificationProps, 'theme'>>;

export interface NotificationSubComponentStyles {
    callout: IStyleFunctionOrObject<ICalloutContentStyleProps, ICalloutContentStyles>;
}

export interface NotificationStyles {
    content: IStyle;
    title: IStyle;
    close: IStyle;
    message: IStyle;
    subComponentStyles: NotificationSubComponentStyles;
}
export interface NotificationProps {
    notificationType?: NotificationType;
    iconProps?: IIconProps;
    title: string;
    target: Target;
    onDismiss: () => void;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<NotificationStyleProps, NotificationStyles>;
    /**
     * whether to fade-out the notification.
     * If set to false the user would have to dismiss it.
     * default: true
     */
    fadeOut?: boolean;
}
export const getClassNames: ReturnType<typeof classNamesFunction<NotificationStyleProps, NotificationStyles>> =
    classNamesFunction<NotificationStyleProps, NotificationStyles>();

export const notificationIconStyle: IStyle = {
    paddingRight: 12,
    fontSize: 24,
};

const notificationTypeToIconProps: { [key in NotificationType]: IIconProps } = {
    Success: {
        iconName: 'SkypeCircleCheck',
        styles: { root: { ...notificationIconStyle, color: 'green' } },
    },
    Error: {
        iconName: 'StatusErrorFull',
        styles: { root: { ...notificationIconStyle, color: 'red' } },
    },
};

/**
 * @deprecated Fluent 8
 */
export const NotificationBase: React.FC<NotificationProps> = ({
    fadeOut = true,
    iconProps,
    notificationType,
    title,
    target,
    onDismiss,
    theme,
    styles,
    children,
}) => {
    const [transition, setTransition] = useState(false);

    const timeouts = React.useRef<{ dismissTimeout?: number; delayTimeout?: number }>({});

    const restartTransition = useCallback(() => {
        // if no fade out, no need to do anything
        if (!fadeOut) {
            return;
        }
        // Since we don't currently know how to attach an animation end event to the fade out,
        // we'll use our own timer and dismiss when time is up.
        // Here's we're removing any previous timer that was going to dismiss the notification
        if (timeouts.current.delayTimeout) {
            clearTimeout(timeouts.current.delayTimeout);
            timeouts.current.delayTimeout = undefined;
        }

        if (timeouts.current.dismissTimeout) {
            clearTimeout(timeouts.current.dismissTimeout);
            timeouts.current.dismissTimeout = undefined;
        }

        // change the state to not transitioning. If we don't do this the CSS animation won't kick-in.
        setTransition(false);

        // show it for 2 few seconds then start transitioning
        timeouts.current.delayTimeout = window.setTimeout(() => setTransition(true), transitionDelay);

        // schedule to dismiss.
        timeouts.current.dismissTimeout = window.setTimeout(() => onDismiss(), transitionDelay + transitionDuration);
    }, [fadeOut, onDismiss]);

    useEffect(() => restartTransition(), [restartTransition]);

    const messageId = useId();

    const classNames = getClassNames(styles!, { theme: theme!, transition });
    const calcIconProps = iconProps ?? (notificationType ? notificationTypeToIconProps[notificationType] : undefined);

    return (
        <div>
            <Callout
                // only errors should interrupt reading flow aggressively using alert role
                role={notificationType === 'Error' ? 'alert' : 'alertdialog'}
                ariaLabel={title}
                ariaDescribedBy={messageId}
                isBeakVisible={false}
                target={target}
                directionalHint={DirectionalHint.bottomRightEdge}
                styles={classNames.subComponentStyles.callout}
                onMouseMove={() => restartTransition()}
                setInitialFocus={true}
            >
                <IconButtonWithTooltip
                    tooltipProps={{ content: 'Close' }}
                    ariaLabel="Close"
                    className={classNames.close}
                    iconProps={{ iconName: 'Cancel' }}
                    onClick={() => onDismiss()}
                />
                <div className={classNames.content}>
                    {calcIconProps && <Icon {...calcIconProps} />}
                    <div style={{ width: '90%', overflowWrap: 'break-word' }}>
                        {/* eslint-disable-next-line jsx-a11y/role-has-required-aria-props */}
                        <p className={classNames.title} role="heading">
                            {title}
                        </p>
                        <div className={classNames.message} id={messageId}>
                            {children}
                        </div>
                    </div>
                </div>
            </Callout>
        </div>
    );
};

const transitionDelay = 2000;
const transitionDuration = 5000;
const animationLength = `${transitionDuration}ms`;

const getStyles = (props: NotificationStyleProps): NotificationStyles => {
    const { theme } = props;

    return {
        content: {
            width: 400,
            display: 'flex',
            paddingTop: 24,
            paddingRight: 28,
            paddingLeft: 28,
            paddingBottom: 24,
        },
        title: [
            theme.fonts.xLarge,
            {
                color: theme.palette.neutralPrimary,
                // remove default paragraph styling
                marginTop: 0,
                // Align with icon size
                lineHeight: 24,
                // leave space for close button
                paddingRight: 12,
            },
        ],
        close: {
            position: 'absolute',
            top: 12,
            right: 12,
        },
        message: [theme.fonts.mediumPlus, { color: theme.palette.neutralPrimary }],
        subComponentStyles: {
            callout: props.transition
                ? {
                      calloutMain: {
                          opacity: 0,
                          transition: `opacity ${animationLength} ease-in`,
                      },
                      root: {
                          borderColor: 'rgba(0,0,0,0)',
                          boxShadow: 'none',
                          transition: `box-shadow ${animationLength} ease-in, border ${animationLength}`,
                      },
                  }
                : {},
        },
    };
};

/**
 * @deprecated Fluent 8
 */
export const Notification = styled(NotificationBase, getStyles);
