.distributionList {
    margin: 0;
    padding: 0;
    width: 100%;
}

.itemContainer {
    display: flex;
    flex-flow: row nowrap;
    padding: var(--spacingHorizontalXS) var(--spacingHorizontalS);
    align-items: center;
    justify-content: space-between;
    gap: var(--spacingHorizontalS);

    .title {
        flex-grow: 1;
        max-width: 60px;
        justify-content: flex-end;
    }

    .percentage {
        color: var(--colorNeutralForeground4);
    }

    .countContainer {
        display: flex;
        flex-flow: row nowrap;
        gap: var(--spacingHorizontalS);
        justify-content: flex-start;
        flex: 0 0 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .percentageBar {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-start;
        padding: 0;
        padding-inline: 0;
        padding-block: 0;
        height: 8px;
        .percentageBarFill {
            background-color: #118dff;
            padding: 0;
            height: 8px;
            border: none;
            min-width: 0;
            &:hover,
            &:focus {
                background-color: #33ceff;
            }
            &:active {
                background-color: #118dff;
            }
        }
    }
}
