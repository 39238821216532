import * as React from 'react';
import { useFocusedElementChange } from '@fluentui/react-tabster';
import { elementContains } from '@fluentui/react-utilities';
/**
 * https://www.w3.org/WAI/ARIA/apg/practices/keyboard-interface/#kbd_roving_tabindex
 */ export function useRovingTabIndex() {
    const currentElementRef = React.useRef();
    const walkerRef = React.useRef(null);
    useFocusedElementChange((element)=>{
        if ((element === null || element === void 0 ? void 0 : element.getAttribute('role')) === 'treeitem' && walkerRef.current && elementContains(walkerRef.current.root, element)) {
            rove(element);
        }
    });
    const initialize = React.useCallback((walker)=>{
        walkerRef.current = walker;
        walker.currentElement = walker.root;
        let tabbableChild = walker.firstChild((element)=>element.tabIndex === 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP);
        walker.currentElement = walker.root;
        tabbableChild !== null && tabbableChild !== void 0 ? tabbableChild : tabbableChild = walker.firstChild();
        if (!tabbableChild) {
            return;
        }
        tabbableChild.tabIndex = 0;
        currentElementRef.current = tabbableChild;
        let nextElement = null;
        while((nextElement = walker.nextElement()) && nextElement !== tabbableChild){
            nextElement.tabIndex = -1;
        }
    }, []);
    const rove = React.useCallback((nextElement)=>{
        if (!currentElementRef.current) {
            return;
        }
        currentElementRef.current.tabIndex = -1;
        nextElement.tabIndex = 0;
        nextElement.focus();
        currentElementRef.current = nextElement;
    }, []);
    return {
        rove,
        initialize
    };
}
