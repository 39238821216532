import React from 'react';

/**
 * An always updated ref that contains the latest value.
 * Used to reference a value without recalculating memoized (useEffect/useMemo/useCallback) functions
 */
export function useCurrent<T>(item: T): React.MutableRefObject<T> {
    const refItem = React.useRef(item);

    React.useEffect(() => {
        refItem.current = item;
    }, [item]);

    return refItem;
}
