import * as React from 'react';
import { CommandBarButton } from '@fluentui/react';
import { Text } from '@fluentui/react-components';
import classNames from 'classnames';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import { Slideout } from '@kusto/ui-components';

import type { KweVisualFwkLocale } from '../../types';
import type { VisualConfigLayout, VisualInput } from '../../visualConfig';
import type { VisualOptionKey } from '../../visualOptions';
import type { VisualConfigLayoutFiltered } from '../lib';
import type { VisualOptionsModel, VisualPluginModel } from '../model/model';
import { ResetButton } from './ResetButton';
import { SegmentToggle } from './SegmentToggle';

import * as styles from './Segment.module.scss';

const iconProps = { iconName: 'ChevronDown' };

export interface EmptyConfigSegmentProps<C extends VisualOptionKey, H> {
    paneModel: VisualOptionsModel;
    inputModel: VisualPluginModel<C, H>;
    title: string;
    segmentToggleInfo: VisualConfigLayout.SegmentToggle<C>;
}

/**
 * Section with an toggle, but no configuration inside of it
 */
export const EmptyConfigSegment = <C extends VisualOptionKey, H>({
    paneModel,
    inputModel,
    title,
    segmentToggleInfo,
}: EmptyConfigSegmentProps<C, H>) => {
    const titleTextId = `visual-options-${segmentToggleInfo.optionKey}-display-name`;

    return (
        <div className={styles.emptySection}>
            <Text className={styles.emptySectionTitle} id={titleTextId}>
                {title}
            </Text>
            <SegmentToggle
                paneModel={paneModel}
                inputModel={inputModel}
                {...segmentToggleInfo}
                aria-describedby={titleTextId}
            />
        </div>
    );
};

export interface ConfigSegmentProps<C extends VisualOptionKey, H> extends VisualConfigLayoutFiltered.Segment<C, H> {
    t: KweVisualFwkLocale;
    paneModel: VisualOptionsModel;
    inputModel: VisualPluginModel<C, H>;
    inputs: ReadonlyArray<VisualInput<C, H>>;
    closedSegments: ReadonlySet<string>;
    toggleSegmentOpen: (displayName: string) => void;
}

export const ConfigSegment = observer(function ConfigSegment<C extends VisualOptionKey, H>({
    t,
    paneModel,
    inputModel,
    displayName,
    inputs,
    toggle,
    closedSegments,
    toggleSegmentOpen,
    hideReset,
}: ConfigSegmentProps<C, H>) {
    const toggleOpen = React.useCallback(() => toggleSegmentOpen(displayName), [displayName, toggleSegmentOpen]);

    const disabled = React.useMemo(() => {
        if (!toggle) {
            return {
                get(): false {
                    return false;
                },
            };
        }
        return mobx.computed(() => {
            let enabled = inputModel.narrowedOptions[toggle.optionKey];
            if (toggle.invert) {
                enabled = !enabled;
            }
            return !enabled;
        });
    }, [inputModel, toggle]).get();

    const open = !closedSegments.has(displayName);

    const commandBarButtonId = `visual-options-${toggle?.optionKey}-display-name`;

    return (
        // This needs to be an element, and not a React.Fragment, to create a sticky
        // boundary
        <div>
            <div
                className={classNames(styles.head, {
                    [styles.open]: open,
                    [styles.hasToggle]: toggle,
                })}
            >
                <CommandBarButton
                    className={styles.commandBarButton}
                    iconProps={iconProps}
                    onClick={toggleOpen}
                    aria-expanded={open}
                    id={commandBarButtonId}
                >
                    {displayName}
                </CommandBarButton>
                {toggle && (
                    <SegmentToggle
                        paneModel={paneModel}
                        inputModel={inputModel}
                        {...toggle}
                        aria-describedby={commandBarButtonId}
                    />
                )}
            </div>
            <Slideout open={open} className={styles.slideoutOuter}>
                {/* Extra div for slideout sizing */}
                <div className={styles.slideoutInner}>
                    {inputs.map((input) => inputModel.getInputInstance(input).render(t, disabled))}
                    {!hideReset && (
                        <ResetButton
                            t={t}
                            inputs={inputs}
                            inputModel={inputModel}
                            paneModel={paneModel}
                            disabled={disabled}
                        />
                    )}
                </div>
            </Slideout>
        </div>
    );
});
