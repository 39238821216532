import { ApplicationInsights, ICustomProperties } from '@microsoft/applicationinsights-web';

import { initLegacyTelemetryClient } from '@kusto/query';
import { Account, createRedactPiiFn, KweTelemetry } from '@kusto/utils';

import type { IAuthProvider } from '../AuthenticationProviders/IAuthProvider';
import { MsalAuthenticationProvider } from '../AuthenticationProviders/MsalAuthProvider/MsalAuthenticationProvider';
import { domains, KWE_CONSTANTS, KWE_ENV } from '../common/constants';
import type { AppQueryParams, FeatureFlagMapAll } from '../core';

/**
 * Helper fn to create getter for auth accounts
 */
function createGetAllAccounts(getAuthProvider: () => IAuthProvider | undefined): () => Account[] {
    return () => {
        const authProvider = getAuthProvider();

        if (authProvider instanceof MsalAuthenticationProvider) {
            return authProvider.getAllAccounts();
        }

        const accounts: Account[] = [];
        const account = authProvider?.getAccount();

        if (account) {
            accounts.push(account);
        }

        return accounts;
    };
}

/**
 * @param getAuthProvider Used to retrieve the user's account info so we can redact its PII in telemetry calls
 * @param getFeatureFlags Passed as getter function so this can always use the latest
 */
export function initTelemetryClient(
    appSearch: AppQueryParams,
    sessionId: string,
    getAuthProvider: () => IAuthProvider | undefined,
    featureFlags: FeatureFlagMapAll
) {
    const hosting = appSearch.settings.hosting;
    const environment = window.location.hostname === domains.preview ? 'preview' : KWE_ENV.environment;

    const sessionContext: ICustomProperties = { environment };
    if (hosting) {
        // Undefined properties will be serialized to the sting
        // `"undefined"` by app insights, so we should avoid adding
        // them
        sessionContext.hosting = hosting;
    }
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: 'e90e244b-16cd-43c0-b0b1-15f395605458',
            enableUnhandledPromiseRejectionTracking: true,
            enableAutoRouteTracking: true,
        },
    });

    appInsights.loadAppInsights();
    appInsights.context.application.ver = KWE_ENV.appVersion;
    appInsights.context.session.id = sessionId;

    const telemetry = KweTelemetry.fromAppInsights(
        appInsights,
        {
            redactPii: createRedactPiiFn(createGetAllAccounts(getAuthProvider)),
            logLevelTelemetry: () => (featureFlags.verboseLogs ? 'verbose' : 'information'),
            logLevelConsole: () => {
                if (featureFlags.consoleLogs) {
                    return 'verbose';
                }
                if (process.env.NODE_ENV === 'development') {
                    return 'warning';
                }
                return 'error';
            },
            get isMicrosoftInternalAccount() {
                return getAuthProvider()?.getAccount()?.tenantId === KWE_CONSTANTS.tenants.microsoft;
            },
        },
        sessionContext
    ).bind(() => ({ disablePiiRedactor: featureFlags.DisableTelemetryPiiRedactor }));

    initLegacyTelemetryClient(telemetry, () => featureFlags.verboseLogs ?? false);

    return { appInsights, telemetry };
}
