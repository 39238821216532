import type { KustoTypeWithUnsupported } from '../types';
import type { KustoClientResult, KustoClientResultV2 } from './KustoClient';

export const dotnetTypeToKustoType: { readonly [dotNetType: string]: KustoTypeWithUnsupported } = {
    'System.SByte': 'bool',
    'System.Byte': 'uint8',
    'System.Int16': 'int16',
    'System.UInt16': 'uint16',
    'System.Int32': 'int',
    'System.UInt32': 'uint',
    'System.Int64': 'long',
    'System.UInt64': 'ulong',
    'System.String': 'string',
    'System.Single': 'float',
    'System.Double': 'real',
    'System.DateTime': 'datetime',
    'System.TimeSpan': 'timespan',
    'System.Guid': 'guid',
    'System.Boolean': 'bool',
    'Newtonsoft.Json.Linq.JArray': 'dynamic',
    'Newtonsoft.Json.Linq.JObject': 'dynamic',
    'Newtonsoft.Json.Linq.JToken': 'dynamic',
    'System.Object': 'dynamic',
    'System.Data.SqlTypes.SqlDecimal': 'decimal',
};

/**
 * api V1 to api V2 type conversion
 */
export const v1TypeToKustoType: { readonly [vType: string]: KustoTypeWithUnsupported } = {
    SByte: 'bool',
    Byte: 'uint8',
    Int16: 'int16',
    UInt16: 'uint16',
    Int32: 'int',
    UInt32: 'uint',
    Int64: 'long',
    UInt64: 'ulong',
    String: 'string',
    Single: 'float',
    Double: 'real',
    DateTime: 'datetime',
    TimeSpan: 'timespan',
    Guid: 'guid',
    Boolean: 'bool',
    JArray: 'dynamic',
    JObject: 'dynamic',
    JToken: 'dynamic',
    Object: 'dynamic',
};

export function isV1Response(arg: KustoClientResult | KustoClientResultV2): arg is KustoClientResult {
    return !Array.isArray(arg) && 'Tables' in arg;
}
