import React from 'react';

import { DropdownWithSearchOption } from '../types.ts';

const MAX_OPTIONS_WITHOUT_SEARCH = 8;
const INPUT_ELEM_HEIGHT = 46;
const COMBO_BOX_PADDING = 30;
const SEARCH_PADDING = 8;
const MIN_LIST_WIDTH = 200;

export function useListBoxResize(
    options: DropdownWithSearchOption[],
    filteredOptions: DropdownWithSearchOption[],
    listBoxRef: React.RefObject<HTMLDivElement>,
    popoverRef: React.RefObject<HTMLDivElement>,
    buttonRef: React.RefObject<HTMLButtonElement>,
    inputRef: React.RefObject<HTMLDivElement>,
    open: boolean,
    query: string,
    forceSearch?: boolean
) {
    const showSearch = forceSearch || options.length > MAX_OPTIONS_WITHOUT_SEARCH;

    React.useEffect(() => {
        if (open && popoverRef.current && listBoxRef.current) {
            popoverRef.current.style.height = `${
                listBoxRef.current.offsetHeight + (showSearch ? INPUT_ELEM_HEIGHT : 0)
            }px`;
        }
    }, [filteredOptions, showSearch, open, listBoxRef, popoverRef, query]);

    React.useEffect(() => {
        if (open && inputRef.current && buttonRef.current) {
            const maybeWidth = buttonRef.current.clientWidth - (COMBO_BOX_PADDING + (showSearch ? SEARCH_PADDING : 0));
            inputRef.current.style.width = `${maybeWidth > MIN_LIST_WIDTH ? maybeWidth : MIN_LIST_WIDTH}px`;
        }
    }, [open, inputRef, buttonRef, showSearch]);

    return showSearch;
}
