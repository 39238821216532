{
    "pageLang": "en",
    "pageLang.comment": "Please use lowercase. this should be changed to the localization code for the language: french=fr / portuguese-brazil=pt-br etc.  ",
    "cancel": "Cancel",
    "cancel.comment": "text on a button, pressing on it will lead to an action",
    "addGroup": "Add group",
    "addGroup.comment": "text on a button, pressing on it will lead to an action",
    "apply": "Apply",
    "apply.comment": "action on a button, applying changes",
    "close": "Close",
    "close.comment": "action on button to close a tab",
    "toggleNavigationMenu": "Toggle navigation menu",
    "toggleNavigationMenu.comment": "aria label for toggle navigation menu button",
    "pageTitle": "Azure Data Explorer",
    "addCluster": "Add connection",
    "addCluster.comment": "Text on a button, clicking it will lead to an action",
    "editCluster": "Edit connection",
    "editCluster.comment": "Text on a button, clicking it will lead to an action",
    "download": "Download",
    "download.comment": "Download",
    "save": "Save",
    "save.comment": "text on a button, pressing on it will lead to an action",
    "search": "Search",
    "search.comment": "text on a button, pressing on it will open a search box",
    "openSamples": "Open tutorials",
    "openSamples.comment": "Open tutorials menu button text",
    "saveToWorkspace": "Save to Workspace",
    "saveToWorkspace.comment": "text on a button, pressing on it will lead to an action",
    "edit": "Edit",
    "edit.comment": "text on a button, pressing on it will lead to an action",
    "loading": "Loading",
    "loading.comment": "label on spinner",
    "create": "Create",
    "create.comment": "text on a primary button",
    "connection": "Connection",
    "connection.comment": "text on a button, pressing on it will lead to an action",
    "add": "Add",
    "add.comment": "text on a button, pressing on it will lead to an action",
    "openWebUI": "Open in Web UI",
    "openWebUI.comment": "text on button that opens a window",
    "collapseAll": "Collapse all",
    "collapseAll.comment": "text on button",
    "favorites": "Filter by favorites",
    "favorites.comment": "text on a button to add an item to the user's favorites list",
    "connectionPaneMoreOption": "more options",
    "connectionPaneMoreOption.comment": "connection pane more options (... buttons) for search, etc. appear when there is not enough space for actual buttons",
    "connectionPane$search$navButtonText": "Focus on entity",
    "connectionPane$search$navButtonText.comment": "The text to show on a navigation button that is shown next to each entity in the connection pane. When user clicks this button it will clear the filter, and make sure the entity is visible.",
    "clusterAdded": "${clusterName} added",
    "clusterAdded.comment": "{Locked='${clusterName}'}",
    "clusterRenamed": "Cluster renamed to ${clusterAlias}",
    "clusterRenamed.comment": "{Locked='${clusterAlias}'}",
    "generate": "Generate",
    "generate.comment": "text on a contextual menu itm, pressing on it will lead to an action",
    "violations": "Violations",
    "violations.comment": "text on a button, pressing on it will lead to an action",
    "viewDatabaseViolations": "View database violations",
    "viewDatabaseViolations.comment": "text on a button, pressing on it will lead to an action",
    "viewHistory": "View history",
    "viewHistory.comment": "text on a button that causes a new tab with cluster commands history",
    "updateRetentionPolicy": "Retention policy",
    "updateRetentionPolicy.comment": "text on a button that opens retention policy wizard",
    "updateBatchingPolicy": "Batching policy",
    "updateBatchingPolicy.comment": "text on a button that opens batching policy wizard",
    "updatePolicy": "Policy",
    "updatePolicy.comment": "text on a button that opens policy wizard",
    "history": "History",
    "history.comment": "prefix for tab title viewing cluster history",
    "refresh": "Refresh",
    "refresh.comment": "text on a button that causes a refresh on a list of items",
    "refreshDatabase": "Refresh Database",
    "refreshDatabase.comment": "A message to show for refreshing the Database",
    "refreshing": "Refreshing",
    "refreshing.comment": "status message while refreshing a list of items",
    "addFavorites": "Add to Favorites",
    "addFavorites.comment": "Add database cluster to favorites",
    "addedFavorite": "Added to Favorite",
    "addedFavorite.comment": "status text after adding item to favorites",
    "removeFavorite": "Remove from Favorites",
    "removeFavorite.comment": "action on a button to remove an item from favorites",
    "removedFavorite": "Removed from Favorite",
    "removedFavorite.comment": "status text after removing a favorite item",
    "removeConnection": "Remove connection",
    "removeConnection.comment": "action on a button to remove a connection (url) from a list of connections",
    "removedConnection": "${clusterId} Connection removed",
    "removedConnection.comment": "{Locked='${clusterId}'} status text after removing a connection",
    "generateCreateScriptNewTab": "Create script in new tab",
    "generateCreateScriptNewTab.comment": "text on button to create a script in new tab",
    "generateCreateScript": "Create script",
    "generateCreateScript.comment": "text on button to create a script",
    "dataProfile": "Data profile",
    "dataProfile.comment": "text on a button to open data profile panel",
    "dataProfileMenuItem": "Data profile",
    "dataProfileMenuItem.comment": "Text on button to open data profile panel",
    "dataProfileMenuItemTooltip": "Select a table and then click this button for insights about the table data",
    "dataProfileMenuItemTooltip.comment": "Text for tooltip on button to open data profile panel",
    "deleteTable": "Delete table",
    "deleteTable.comment": "Delete table",
    "dropTable": "Drop table",
    "dropTable.comment": "text on button to drop (delete) the table",
    "dropTableTitle": "Do you want to delete ${tableName}?",
    "dropTableTitle.comment": "Do you want to delete the table",
    "dropTableAreYouSure": "The data will be permanently deleted.",
    "dropTableAreYouSure.comment": "message to confirm permanent deletion of the table, displayed in Browser alert, the user can click on OK or Cancel",
    "tableDropped": "table ${tableName} was successfully dropped",
    "tableDropped.comment": "{Locked='${tableName}'} notification on successful deleting a table",
    "removing": "Removing",
    "removing.comment": "status message while removing an item from a list",
    "tabSelected": "$(tab) selected",
    "tabSelected.comment": "{Locked='${tab}'}",
    "clickAddCluster": "Click the 'Add > Connection' button above to connect to a cluster",
    "clickAddCluster.comment": "displayed as text when no items (clusters) are found on the user's list",
    "scrollLeft": "Scroll left",
    "scrollLeft.comment": "action on button to scroll to the left",
    "scrollRight": "Scroll right",
    "scrollRight.comment": "action on button to scroll to the right",
    "sampleCode": "Sample code",
    "sampleCode.comment": "text on a button to open a sample code",
    "cancelQuery": "Cancel query",
    "cancelQuery.comment": "text on a button to cancel a running task",
    "run": "Run",
    "run.comment": "action on a button to run a task",
    "previewResults": "Preview results",
    "previewResults.comment": "Button label to run query with limited results",
    "previewResultsTooltip": "Show 50 records of the input record set",
    "previewResultsTooltip.comment": "Preview button tooltip",
    "recall": "Recall",
    "recall.comment": "text on a button to recall a previous result. (getting previous task results from memory, not from server)",
    "open": "Open",
    "open.comment": "text on a button to open a file",
    "file": "File",
    "file.comment": "computer file. menu title for list of actions related to a file",
    "connectionPane": {
        "error": "Error",
        "error.comment": "text on a label of cluster connection with error",
        "headerTitle": "Connections",
        "headerTitle.comment": "title of the connection pane",
        "group": "Group",
        "group.comment": "text on a label of cluster connection with group",
        "groupsTree": "Groups tree",
        "groupsTree.comment": "text on a connection pane component open the tree of groups",
        "favorites": "Favorites",
        "favorites.comment": "text on a connection pane component open the tree of favorite connections",
        "folders": {
            "functions": "Functions",
            "functions.comment": "text on a label of functions folder node",
            "tables": "Tables",
            "tables.comment": "text on a label of tables folder node",
            "externalTables": "External tables",
            "externalTables.comment": "text on a label of external tables folder node",
            "materializedViews": "Materialized views",
            "materializedViews.comment": "text on a label of materialized views folder node",
            "entityGroups": "Entity groups",
            "entityGroups.comment": "text on a label of entity groups folder node"
        },
        "showConnectionPane": "New connection pane",
        "showConnectionPane.comment": "text on a switch to show the new connections tree",
        "commandBar": {
            "collapseAllConnections": "Collapse all",
            "collapseAllConnections.comment": "text on a button to collapse all connections",
            "clearSearchAriaLabel": "Clear search",
            "clearSearchAriaLabel.comment": "aria label for clear search button",
            "closeSearchAriaLabel": "Close search",
            "closeSearchAriaLabel.comment": "aria label for close search button",
            "searchStringAriaLabel": "Search for entity containing ${searchText}",
            "searchStringAriaLabel.comment": "{Locked='${searchText}'} aria-label for the search box",
            "moreOptionsAriaLabel": "More options",
            "moreOptionsAriaLabel.comment": "aria label for more options button"
        },
        "actions": {
            "addToGroup": "Add to group",
            "addToGroup.comment": "text on a menu item to add a connection to a group",
            "addGroup": "New group",
            "addGroup.comment": "text on a menu item to add a new group of connections",
            "addToFavorites": "Add to favorites",
            "addToFavorites.comment": "text on a menu item to add a connection to favorites",
            "batchingPolicy": "Batching policy",
            "batchingPolicy.comment": "text on a menu item to update a batching policy",
            "createTable": "Create table",
            "createTable.comment": "text on a menu item to create a table",
            "createExternalTable": "Create external table",
            "createExternalTable.comment": "text on a menu item to create an external table",
            "lightIngest": "LightIngest",
            "lightIngest.comment": "Text on a menu item to ingest using LightIngest",
            "dataProfile": "Data profile",
            "dataProfile.comment": "text on a menu item to open data profile panel",
            "dropTable": "Drop table",
            "dropTable.comment": "text on a menu item to drop a table",
            "editConnection": "Edit connection",
            "editConnection.comment": "text on a menu item to edit a connection",
            "existingGroups": "Existing groups",
            "existingGroups.comment": "text on a menu header regarding adding connection to an existing group",
            "focusOnEntity": "Focus on entity",
            "focusOnEntity.comment": "text on a menu item to focus on an entity",
            "getData": "Get data",
            "getData.comment": "text on a menu item to open the get data wizard",
            "generate": {
                "label": "Generate",
                "label.comment": "text on a menu item to generate a script",
                "sampleApp": "Sample app",
                "sampleApp.comment": "text on a menu item to generate a sample app",
                "createScript": "Create script",
                "createScript.comment": "text on a menu item to generate a script",
                "createScriptNewTab": "Create script in new tab",
                "createScriptNewTab.comment": "text on a menu item to generate a script in a new tab",
                "createTemplateScript": "Create template script",
                "createTemplateScript.comment": "text on a menu item to generate a template script"
            },
            "groups": {
                "tooLong": "Group name is too long",
                "tooLong.comment": "error message when the user tries to create a group with a name that is too long",
                "groupNameInUse": "Group name already exists",
                "groupNameInUse.comment": "error message when the user tries to create a group with a name that already exists",
                "emptyString": "Group name cannot be empty",
                "emptyString.comment": "error message when the user tries to create a group without a name",
                "groupsPlaceholder": "Enter group name",
                "groupsPlaceholder.comment": "placeholder for the input field that accepts the group name"
            },
            "updatePolicy": "Policy",
            "updatePolicy.comment": "text on a menu item to update a policy",
            "queryTable": "Query table",
            "queryTable.comment": "text on a menu item to query a table",
            "retentionPolicy": "Retention policy",
            "retentionPolicy.comment": "text on a menu item to update a retention policy",
            "refreshSchema": "Refresh",
            "refreshSchema.comment": "text on a menu item to refresh the schema",
            "refreshDatabase": "Refresh database",
            "refreshDatabase.comment": "text on a menu item to refresh the database",
            "removeFromExistingGroup": "Remove from group",
            "removeFromExistingGroup.comment": "text on a menu item to remove a connection from a group",
            "removeFromFavorites": "Remove from favorites",
            "removeFromFavorites.comment": "text on a menu item to remove a connection from favorites",
            "removeConnection": "Remove connection",
            "removeConnection.comment": "text on a menu item to remove a connection",
            "groupRemoved": "Group ${groupId} removed successfully",
            "groupRemoved.comment": "{Locked='${groupId}'} text on a notification after removing a group",
            "renameGroup": "Rename group ",
            "renameGroup.comment": "text on a menu item to rename a group",
            "renameGroupAccessability": "Rename group ${groupName} to different name",
            "renameGroupAccessability.comment": "{Locked='${groupName}'}",
            "removeGroup": "Remove group, it will not remove the connections",
            "removeGroup.comment": "Text on a menu item to remove a group",
            "viewHistory": "View history",
            "viewHistory.comment": "text on a menu item to view the history of a connection",
            "viewViolations": "View database violations",
            "viewViolations.comment": "text on a menu item to view the database violations"
        },
        "collapsePaneButton": {
            "ariaLabel": "Collapse connection pane",
            "ariaLabel.comment": "aria label for collapse connection pane button"
        },
        "notification": {
            "tableDropped": "Table ${tableName} was successfully dropped",
            "tableDropped.comment": "{Locked='${tableName}'} notification on successful deleting a table"
        },
        "noResults": "No results",
        "noResults.comment": "text to show when no results are found"
    },
    "getData": {
        "contextualMenuLabel": "Get data",
        "contextualMenuLabel.comment": "text on a contextual menu item to get data",
        "menuTitle": "Get data",
        "menuTitle.comment": "text on a button to get data",
        "localFile": "Local file",
        "localFile.comment": "text on a button to ingest from a local file",
        "azureStorage": "Azure storage",
        "azureStorage.comment": "text on a button to ingest from Azure storage",
        "amazonS3": "Amazon S3",
        "amazonS3.comment": "text on a button to ingest from S3",
        "eventHub": "Event Hubs",
        "eventHub.comment": "text on a button to ingest from Event Hub"
    },
    "kqlEditor": {
        "title": "KQL tools",
        "title.comment": "KQL tool menu title",
        "duplicateQuery": "Duplicate query",
        "duplicateQuery.comment": "Duplicate query",
        "resources": "Resources",
        "resources.comment": "Resources",
        "sqkToKqlCheatSheet": "SQL to KQL Cheat sheet",
        "sqkToKqlCheatSheet.comment": "SQL to KQL Cheat sheet",
        "kqlQuickReference": "KQL quick reference",
        "kqlQuickReference.comment": "KQL quick reference",
        "keyboardShortcuts": "Keyboard shortcut doc",
        "keyboardShortcuts.comment": "Keyboard shortcuts",
        "commandPalette": "Open command palette",
        "commandPalette.comment": "command palette"
    },
    "kqlFile": "KQL file",
    "kqlFile.comment": "KQL file",
    "export": "Export",
    "export.comment": "Export",
    "copy": "Copy",
    "copy.comment": "Copy",
    "overflowMenu": "overflow menu",
    "overflowMenu.comment": "overflow menu aria label",
    "share": "Share",
    "share.comment": "menu title for list of actions related to sharing a task and its results",
    "linkToClipboard": "Link to clipboard",
    "linkToClipboard.comment": "text on button. copy a link to clipboard - should be short and same style as key:[linkQueryResultsToClipboard]",
    "linkQueryToClipboard": "Link, query to clipboard",
    "linkQueryToClipboard.comment": "text on button. copy both the link and the query (database query) to the clipboard",
    "pinToDashboard": "Pin to dashboard",
    "pinToDashboard.comment": "text on button. copy query and datasource to the dashboard of your choosing",
    "queryPowerBI": "Query to Power BI",
    "queryPowerBI.comment": "text on a button to open a query (database query) inside PowerBI (microsoft product)",
    "OpenInExcel": "Open in Excel",
    "OpenInExcel.comment": "text on a button to open a file in Excel",
    "closeDialog$aria": "close dialog",
    "closeDialog$aria.comment": "Close dialog label for screen reader",
    "feedbackError$invalidEmail": "Invalid email",
    "feedbackError$invalidEmail.comment": "Error to show when user gives consent to send him/her an email but enter an invalid email.",
    "sendFeedback": "We welcome your feedback",
    "sendFeedback.comment": "title for feedback screen",
    "giveFeedback": "Give feedback",
    "giveFeedback.comment": "title for feedback screen",
    "thankYouFeedbackTitle": "Thanks for your time!",
    "thankYouFeedbackTitle.comment": "message title after submitting a feedback",
    "thankYouFeedback": "Thanks for taking the time to give us feedback about your experience with this product",
    "thankYouFeedback.comment": "message after submitting a feedback",
    "table": "Table",
    "table.comment": "Table",
    "feedbackAreaDropdownTitle": "Category",
    "feedbackAreaDropdownTitle.comment": "Title from dropdown for relating feedback to a specific area",
    "feedbackAreaOption$Data": "Data",
    "feedbackAreaOption$Data.comment": "feedback for data page option",
    "feedbackAreaOption$Query": "Query",
    "feedbackAreaOption$Query.comment": "feedback for query page option",
    "feedbackAreaOption$Dashboards$final": "Dashboards",
    "feedbackAreaOption$Dashboards$final.comment": "feedback for dashboards option",
    "feedbackAreaOption$FreeCluster": "Free cluster",
    "feedbackAreaOption$FreeCluster.comment": "feedback for free cluster option",
    "feedbackAreaOption$Other": "Other",
    "feedbackAreaOption$Other.comment": "feedback not for a specific page",
    "thankYouFeedbackOneClick": "Your insights will help us improve the ingestion process for everyone.",
    "thankYouFeedbackOneClick.comment": "message after submitting a feedback",
    "feedback": "Feedback",
    "feedback.comment": "title for feedback screen",
    "feedbackConsentQuestion": "I agree to allow Microsoft to contact me about my responses and feedback",
    "feedbackConsentQuestion.comment": "A label on a radio button group. Checking the 'agree' radio button gives microsoft consent to reply to the user's feedback.",
    "feedbackConsentTitleAgree": "Agree",
    "feedbackConsentTitleDisagree": "Disagree",
    "feedbackUserEmail": "User email",
    "feedbackUserEmail.comment": "Label for user email input",
    "feedback$oneClick$description": "How would you rate your experience with the ingestion process?",
    "feedback$oneClick$description.comment": "Description for the feedback dialog",
    "feedback$oneClick$placeholder": "Describe your experience.",
    "feedback$oneClick$placeholder.comment": "Placeholder for the feedback input field",
    "feedback$oneClick$needHelp": "If you need help with Azure Data Explorer clusters,",
    "feedback$oneClick$needHelp.comment": "Feedback dialog contact support",
    "feedback$oneClick$contactSupport": "Contact support",
    "feedback$oneClick$contactSupport.comment": "Feedback dialog contact support button text",
    "feedback$oneClick$positiveExperienceLabel": "Happy to hear it went well. What else can we do?",
    "feedback$oneClick$positiveExperienceLabel.comment": "Text to show after user selected a positive rating",
    "feedback$oneClick$negativeExperienceLabel": "Sorry to hear that. What didn’t work?",
    "feedback$oneClick$negativeExperienceLabel.comment": "Text to show after user selected a negative rating",
    "feedback$oneClick$negativeExperience$optionA": "Finding the requested source.",
    "feedback$oneClick$negativeExperience$optionA.comment": "Possible reason for not completing wizard - the requested source was not found",
    "feedback$oneClick$negativeExperience$optionB": "Adding the right URL to the source.",
    "feedback$oneClick$negativeExperience$optionB.comment": "Possible reason for not completing wizard - the requested source is not known",
    "feedback$oneClick$negativeExperience$optionC": "I need to ingest more than 1000 files.",
    "feedback$oneClick$negativeExperience$optionC.comment": "Possible reason for not completing wizard - user need the capability to ingest more files",
    "feedback$oneClick$negativeExperience$optionD": "Just exploring the new feature. I will use it in the future.",
    "feedback$oneClick$negativeExperience$optionD.comment": "Possible reason for not completing wizard - exploration only, probably will use in the future",
    "feedback$oneClick$negativeExperience$optionE": "Just exploring the new feature. I don’t think I’ll be using it.",
    "feedback$oneClick$negativeExperience$optionE.comment": "Possible reason for not completing wizard - exploration only, won't use it",
    "feedback$oneClick$negativeExperience$optionF": "Other (please tell us).",
    "feedback$oneClick$negativeExperience$optionF.comment": "Possible reason for not completing OneClick wizard - other",
    "or": "or",
    "or.comment": "or",
    "ok": "Ok",
    "ok.comment": "answer to dialog with one button",
    "yes": "Yes",
    "yes.comment": "answer to a yes/no question",
    "no": "No",
    "no.comment": "answer to a yes/no question",
    "unknown": "Unknown",
    "answer": "Answer",
    "answer.comment": "provide information about your experience",
    "submit": "Submit",
    "submit.comment": "action on a button, submitting data",
    "executeQuery": "Execute query",
    "executeQuery.comment": "action on a button to execute a database query",
    "logOut": "Log out",
    "logOut.comment": "text on a button to log out",
    "privacyTerms": "Privacy statement",
    "privacyTerms.comment": "text on a link that leads the privacy and terms page",
    "termsAndConditions": "Legal terms and conditions",
    "termsAndConditions.comment": "text on a link that leads to Microsoft Azure Legal Information",
    "noticeFile": "NOTICE",
    "noticeFile.comment": "text on a link that leads to NOTICE file",
    "exportButtonTitle": "Export",
    "exportButtonTitle.comment": "The title on the export button.",
    "settings$advanced$localState$header": "Use these options to clear, export and import your query data (including connections, queries and settings)",
    "settings$advanced$localState$header.comment": "Header to show to buttons Clear Local State, Import and Export",
    "importButtonTitle": "Import",
    "importButtonTitle.comment": "The title on the import button.",
    "import$dialog$title": "Warning",
    "import$dialog$title.comment": "The title to show on the Are-You-Sure dialog before importing all the settings.",
    "import$dialog$body": "This will override your current Query data, with the imported connections, queries and settings.",
    "import$dialog$body.comment": "The body of the import's Are-You-Sure dialog",
    "import$dialog$buttonTitle": "Import",
    "import$dialog$buttonTitle.comment": "The title of the confirm button in the import's Are-You-Sure dialog",
    "import$error$jsonParse": "Failed to parse the imported file. trackingID: ${trackingID}",
    "import$error$jsonParse.comment": "The error to show when json.parse fails during import. {StrContains='${trackingID}'}",
    "import$error$apply": "Failed to apply the a snapshot of the imported file. trackingID: ${trackingID}",
    "import$error$apply.comment": "The error to show when applying snapshot during import fails. {StrContains='${trackingID}'}",
    "export$error": "Export has failed. Please send feedback and include this tracking ID ${trackingID}",
    "export$error.comment": "The error to show when export fails. {StrContains='${trackingID}'}",
    "dashboardVersion": "Dashboard version: ${dashboardVersion}",
    "dashboardVersion.comment": "{StrContains='${dashboardVersion}'}",
    "themesLabel": "Theme",
    "themesLabel.comment": "title for changing the theme",
    "themesAbout": "Select a theme",
    "themesAbout.comment": "hint when you choose a theme",
    "apiVersion": "ApiV2",
    "apiVersion.comment": "{Locked}",
    "formatResultData": "Format the result data",
    "formatResultDataHint": "Format data based on language and regional preferences",
    "formatResultData.comment": "an option in the settings (on/off), should we format (style) the data that came from the task",
    "alignRightResultNumbers": "Align numbers to the right ",
    "alignRightResultNumbers.comment": "an option in the settings (on/off), should number be align to right in the result grid",
    "settingsEagerIntelliSense": "Trigger IntelliSense right after previous IntelliSense option selected",
    "settingsEagerIntelliSense.comment": "an option in the settings (on/off), if on, app will trigger another IntelliSense dialog right after previous one is closed {Locked='IntelliSense'}",
    "settingsCodelens": "Codelens (experimental)",
    "settingsCodelens.comment": "an option in the settings (on/off), do we want to use Codelens {Locked='Codelens'}",
    "settingsEnhancedSyntaxCompletions": "Enhanced syntax completions",
    "breakPlotLine": "Break plot line on empty data (experimental)",
    "breakPlotLine.comment": "an option in the settings (on/off), do we want to break a plot line in a graph when we get empty data from the database",
    "displayGridLevelColoring": "Error level formatting",
    "displayGridLevelColoring.comment": "an option in the settings (on/off), change the background of row according the level cell (e.g red for error, yellow for warning, etc.",
    "hideEmptyCell": "Ignore blank cells in expanded view",
    "hideEmptyCell.comment": "an option in the settings (on/off), do we want to show empty cells in row expand view",
    "clearLocalState": "Clear local state",
    "clearLocalState.comment": "action on a button, to clear the user's local state. local state is also known as local storage, a place that we can save data on the user's browser",
    "settings$ClearLocalState$Dialog$Body": "This will clear your Query data, including connections, queries and settings",
    "settings$ClearLocalState$Dialog$Body.comment": "Body of the Clear Local State warning dialog {Locked='Azure Data Explorer'}",
    "settings$ClearLocalState$Dialog$Title": "Warning",
    "settings$ClearLocalState$Dialog$Title.comment": "Title of the Clear Local State warning dialog",
    "settings$ClearLocalState$Dialog$Button": "Clear",
    "settings$ClearLocalState$Dialog$Button.comment": "The text of the action button in the Clear Local State warning dialog",
    "settings$serverTimeout": "Query timeout (in minutes)",
    "settings$serverTimeout.comment": "slider control to set the query timeout (how long do we wait until we fail the query) in units of minutes",
    "settings$serverTimeoutAdmin": "Admin command timeout (in minutes)",
    "settings$serverTimeoutAdmin.comment": "slider control to set the admin command timeout (how long do we wait until we fail the admin command) in units of minutes",
    "settings$weakConsistency": "Weak consistency",
    "settings$weakConsistencyHint": "When set to ON, weak consistency will be used for queries.",
    "settings$lazySchemaExploration": "Lazy schema exploration",
    "settings$lazySchemaExplorationHint": "When turned ON, the connection panel will always use lazy database schema exploration method, which first lists databases and fetches schema only when a database is expanded. Default is OFF.",
    "settings$liteSchemaExploration": "Lite schema exploration",
    "settings$liteSchemaExplorationHint": "When turned ON, the connection panel will always use lite cluster schema exploration method, which fetches a light-weight representation of the cluster. Default is OFF.",
    "settings$weakConsistency.comment": "if true, query will execute in weak-consistency mode",
    "settingsMouseWheelZoom": "Zoom the editor using mouse scroll wheel",
    "settingsMouseWheelZoom.comment": "user can select whether to zoom the text only on mouse wheel (when the toggle is on), or zoom the entire page (off)",
    "settingEmptyWorkspaceOnDeepLink": "Open query links in a new browser tab",
    "settingEmptyWorkspaceOnDeepLinkHint": "Focus on the loaded query. Note that this experience is not persisting",
    "settingEmptyWorkspaceOnDeepLink.comment": "user can select whether to use empty workspace (on) or keep previous saved tabs/connection (off) when loading query link",
    "settingsCloseExpandViewOnClick": "Close expand view on grid click",
    "settingsCloseExpandViewOnClick.comment": "is clicking on any other cell will close the cell expand ",
    "settingsShowAllSeriesOnHover": "Show all series in chart tooltip",
    "settingsShowAllSeriesOnHover.comment": "by default, when hovering on a chart only the value of the current series is shown. if this setting is turned on, values of all series are shown",
    "settingsShowClickableLinks": "Show clickable links in table results",
    "settingsShowClickableLinks.comment": "when true, any URL shown in table results will be shown as a link and not just as text.",
    "documentation": "Documentation",
    "documentation.comment": "title text of link to app documentation",
    "themeDark": "Dark",
    "themeDark.comment": "Dropdown option for dark theme",
    "themeLight": "Light",
    "themeLight.comment": "Dropdown option for light dark theme",
    "appVersion": "App Version: $(appVersion)",
    "appVersion.comment": "{Locked='${appVersion}'}",
    "clientID": "Client ID: $(clientID)",
    "clientID.comment": "{Locked='${clientID}'}",
    "scope": "Scope:",
    "scope.comment": "after 'scope:' (colon included) we display the current context of database, i.e. if we are in scope: X, we can only see tables within X",
    "language": "Language (requires refresh)",
    "language.comment": "Title for changing the language. include colon",
    "browserDefault": "Browser default",
    "browserDefault.comment": "default option in a combo box for choosing a language. users can choose from a list of languages or use the browser's own language",
    "grid$filterOoo": "Filter...",
    "grid$filterOoo.comment": "hint written on a textbox for filtering the data",
    "share$PowerBI$notification$title": "Query added to clipboard",
    "share$PowerBI$notification$title.comment": "user clicked copy to power BI, and we copied the powerBI script to clipboard.",
    "share$PowerBI$notification$link": "Click here",
    "share$PowerBI$notification$link.comment": "Click here (this text appears in as a link to be clicked on",
    "share$PowerBI$notification$message": "to learn how to export this query into a Power BI dashboard",
    "share$PowerBI$notification$message.comment": "explains that the user needs to click the link to learn how to export the query to power BI",
    "share$dashboard$pinToDashboard": "Pin to dashboard",
    "share$dashboard$pinToDashboard.comment": "menu item that when selected pins a query result or a visualization to the dashboard",
    "share$dashboard$successNotification$title": "Successfully pinned to dashboard",
    "share$dashboard$successNotification$title.comment": "notification title shown when pin to dashboard operation was successful",
    "share$dashboard$successNotification$content": "View your dashboard",
    "share$dashboard$successNotification$content.comment": "link to view the dashboard shown in notification after pin to dashboard was successful",
    "share$dashboard$failureNotification$title": "Failed to pin to dashboard",
    "share$dashboard$failureNotification$title.comment": "notification title when pin to dashboard operation did not succeed",
    "setTenantPlaceholder": "Enter directory ID",
    "setTenantPlaceholder.comment": "placeholder for the input field that accepts the tenant ID",
    "setTenantErrorMessage": "Failed to switch directory",
    "setTenantErrorMessage.comment": "Prefix for error messages when setting tenant fails",
    "setTenantNotificationTitle": "Directory switched",
    "setTenantNotificationTitle.comment": "Notification title after setting the tenant",
    "setTenantSuccessContent": "Directory change successful",
    "setTenantSuccessContent.comment": "Notification content after setting the tenant was successful",
    "ingestion$source$fromBlob": "Blob",
    "ingestion$source$fromBlob.comment": "Ingestion option from blob, blob refer to Azure Storage Blob",
    "ingestion$source$fromFile": "File",
    "ingestion$source$fromFile.comment": "Ingestion option from file",
    "ingestion$source$fromContainer": "Container",
    "ingestion$source$fromContainer.comment": "Ingestion option from container, container refer to Azure Storage Blob Container",
    "switchDirectory": "Switch directory",
    "switchDirectory.comment": "Switch directory link in the Me Control, and label of the change directory button in settings",
    "resetToDefaultDirectory": "Reset to default",
    "resetToDefaultDirectory.comment": "Button label to reset to the default directory",
    "learnMore": "Learn more",
    "learnMore.comment": "the label of a link leading for more info.",
    "readMore": "Read more",
    "readMore.comment": "the label of a link leading for more info.",
    "ingestion$source$loading": "Loading...",
    "ingestion$source$loading.comment": "Placeholder for input component while state is validating",
    "navigation": {
        "data": "Data",
        "data.comment": "Left navigation name for data",
        "dashboards": "Dashboards",
        "dashboards.comment": "Left navigation name for dashboards",
        "home": "Home",
        "home.comment": "Left navigation name for homepage",
        "virtualCluster": "Virtual cluster",
        "virtualCluster.comment": "Left navigation name for virtual cluster",
        "myCluster": "My cluster",
        "myCluster.comment": "Left navigation name for free cluster",
        "query": "Query",
        "query.comment": "Left navigation name for query"
    },
    "navigationWrapper$navigationExpandButtonTitle": "Expand navigation",
    "navigationWrapper$navigationCollapseButtonTitle": "Collapse navigation",
    "navigationWrapper$data": "Data",
    "navigationWrapper$data.comment": "there are 3 main pages in the application - query page, dashboard page and data-landing page. when switching between them in the left navigation pane this is the text that is shown for data",
    "navigationWrapper$query": "Query",
    "navigationWrapper$query.comment": "there are 3 main pages in the application - query page, dashboard page and data-landing page. when switching between them in the left navigation pane this is the text that is shown for query",
    "navigationWrapper$dashboards$final": "Dashboards",
    "navigationWrapper$dashboards$final.comment": "there are 3 main pages in the application - query page, dashboard page and data-landing page. when switching between them in the left navigation pane this is the text that is shown for dashboards.",
    "navigationWrapper$homepage": "Home",
    "navigationWrapper$homepage.comment": "Left navigation name for homepage",
    "navigationWrapper$VirtualCluster": "My cluster",
    "navigationWrapper$VirtualCluster.comment": "The fifth main page in the application - Free cluster which is manage solely in KWE for now it call My Cluster  {Locked='${My Cluster}'}",
    "rtd$connectionLabel": "Cluster URI",
    "rtd$connectionLabel.comment": "The label to show about the connection textbox.",
    "rtd$connectionTextboxPlaceholder": "Enter cluster URI",
    "rtd$connectionTextboxPlaceholder.comment": "The placeholder to show in the connection textbox.",
    "scehma$columns$duplicateError": "Column names must be unique. Change these column names:",
    "scehma$columns$duplicateError.comment": "Error when renaming a column to an already in use name",
    "createTablePermissionsWarning": "You don't have the necessary permissions to create a new table. You need to be at least a Database User to complete this ingestion.",
    "createTablePermissionsWarning.comment": "warn user he needs more permissions to complete ingestion when creating a new table {Locked='${Database User}'}",
    "existingTablePermissionsWarning": "You don't have the necessary permissions to ingest to this table. You need to be at least a Table Admin to complete this ingestion.",
    "existingTablePermissionsWarning.comment": "warn user he needs more permissions to complete one click ingestion {Locked='${Table Admin}'}",
    "ingestion$schema$csv$duplicateSourceTargets": "Cannot insert two columns with the same source for delimited files.",
    "ingestion$schema$csv$duplicateSourceTargets.comment": "Error when trying to map source to several columns in CSV format.",
    "noClusterConnectionError": "There is no connection to the cluster. Please check your permissions and verify that the cluster is running.",
    "noClusterConnectionError.comment": "Error that appears on the source tab if no connection to the cluster.",
    "ingestNewData": "Ingest data",
    "ingestNewData.comment": "Ingest new data label.",
    "createExternalTable": "Create external table",
    "createExternalTable.comment": "Title from create external table tile",
    "createTable": "Create table",
    "createTableInDatabase": "Create new table in database",
    "createTable.comment": "Create table option when clicking on database",
    "createDatabase": "Create a database",
    "createDatabase.comment": "Create a new database database action label",
    "updateDatabase": "Update database",
    "updateDatabase.comment": "Update database an existing database action label",
    "wizardTab$previousButton": "Previous",
    "wizardTab$previousButton.comment": "label of previous tab button, in form wizard",
    "destinationTab$database$panel$nameErrorMsg": "Please enter a valid database name",
    "destinationTab$database$panel$nameErrorMsg.comment": "Error msg for database name",
    "destinationTab$database$panel$nameExistsErrorMsg": "This database name already exists",
    "destinationTab$database$panel$nameExistsErrorMsg.comment": "This database name already exists",
    "destinationTab$database$panel$headerText": "Destination",
    "destinationTab$database$panel$headerText.comment": "Destination header text",
    "destinationTab$next": "Next: Source",
    "destinationTab$next.comment": "next button label in destination tab",
    "sourceTab$headerText": "Source",
    "sourceTab$headerText.comment": "Source",
    "sourceTab$next": "Next: Schema",
    "sourceTab$next.comment": "Next: Schema",
    "updateTablePolicy$toggleIcon": "Info tooltip",
    "updateTablePolicy$toggleIcon.comment": "Toggle icon aria-label",
    "updateTableRetentionPolicy": {
        "retentionPeriod": {
            "UnlimitedCheckbox": {
                "label": "Unlimited retention period",
                "label.comment": "Unlimited retention period"
            }
        },
        "IndexCache": {
            "label": "Index (days)",
            "label.comment": "Index cache label",
            "arialLabel": "index cache period",
            "arialLabel.comment": "index cache period aria label"
        },
        "increaseIndexCachePeriod": "Increase index cache period",
        "increaseIndexCachePeriod.comment": "Aria label for increasing index cache",
        "decreaseIndexCachePeriod": "Decrease index cache period",
        "decreaseIndexCachePeriod.comment": "Aria label for decreasing index cache"
    },
    "ingestion$steps$ingestionPreparation$createDatabase": "Create database *%${databaseName}%*",
    "ingestion$steps$ingestionPreparation$createDatabase.comment": "{Locked='*%${databaseName}%*'} Default message for creating database step.",
    "ingestion$steps$ingestionPreparation$createDatabase$inProgress": "Creating database *%${databaseName}%*",
    "ingestion$steps$ingestionPreparation$createDatabase$inProgress.comment": "{Locked='*%${databaseName}%*'} Message for creating database step in progress.",
    "ingestion$steps$ingestionPreparation$createDatabase$success": "Database *%${databaseName}%* created",
    "ingestion$steps$ingestionPreparation$createDatabase$success.comment": "{Locked='*%${databaseName}%*'} Message for creating database step success.",
    "ingestion$steps$ingestionPreparation$updateDatabase": "Update database *%${databaseName}%*",
    "ingestion$steps$ingestionPreparation$updateDatabase.comment": "{Locked='*%${databaseName}%*'} Default message for updating database step.",
    "ingestion$steps$ingestionPreparation$updateDatabase$inProgress": "Updating database *%${databaseName}%*",
    "ingestion$steps$ingestionPreparation$updateDatabase$inProgress.comment": "{Locked='*%${databaseName}%*'} Message for updating database step in progress.",
    "ingestion$steps$ingestionPreparation$updateDatabase$success": "Database *%${databaseName}%* updated",
    "ingestion$steps$ingestionPreparation$updateDatabase$success.comment": "{Locked='*%${databaseName}%*'} Message for updating database step success.",
    "ingestion$steps$ingestionPreparation$updateDatabase$skipped": "Skip update database *%${databaseName}%*",
    "ingestion$steps$ingestionPreparation$updateDatabase$skipped.comment": "{Locked='*%${databaseName}%*'} Message for skip updating database step.",
    "ingestion$steps$dataConnectionResourceProvider$fail": "Resource provider '${provider}' is not registered to this subscription.",
    "ingestion$steps$dataConnectionResourceProvider$fail.comment": "{Locked='${provider}'} Label for resource provider registration failed.",
    "summaryTab$IngestHeader": "Ingest",
    "summaryTab$IngestHeader.comment": "Ingest",
    "summaryTab$tabHeader": "Summary",
    "summaryTab$closeButton.comment": "Title for the summary tab ",
    "summaryTab$closeButton": "Close",
    "summaryTab$tabHeader.comment": "label of close button after wizard execution is done",
    "sourceTab$source$tooltip$description": "The schema mapping will be based on the selected file.",
    "sourceTab$source$tooltip$description.comment": "tooltip callout body",
    "sourceTab$source$tooltip$title": "Defining file",
    "sourceTab$source$tooltip$title.comment": "tooltip callout title",
    "sourceTab$source$BrowseFiles": "Select up to ${maxNumOfFiles} files",
    "sourceTab$source$BrowseFiles.comment": "{Locked='${maxNumOfFiles}'} Label for files browsing and indication on maximum files per upload",
    "sourceTab$source$BrowseSingleFile": "Select a file",
    "sourceTab$source$BrowseSingleFile.comment": "Label for browsing a single file",
    "sourceTab$source$createExternalTableFlow$browseButton": "Select container",
    "sourceTab$source$createExternalTableFlow$browseButton.comment": "The text of the button which opens browse container panel",
    "sourceTab$source$addSource": "Add source",
    "sourceTab$source$addSource.comment": "Add source",
    "sourceTab$source$description$blob": "Add a blob URI with a SAS token or account key. Add up to 10 blobs with a max of 1 GB uncompressed each.",
    "sourceTab$source$description$blob.comment": "Info ballon for blob source field, which should contain URI with permission (SAS / Account key)",
    "sourceTab$ingestionType$description": "Select the ingestion method to use",
    "sourceTab$ingestionType$description.comment": "Ingestion type field description",
    "schemaTab$schemaNewMapping$title": "New mapping",
    "schemaTab$schemaExistingTable$title": "Existing table",
    "schemaTab$headerText": "Schema",
    "schemaTab$headerText.comment": "Schema",
    "schemaTab$nextStartIngestion": "Next: Start ingestion",
    "schemaTab$nextStartIngestion.comment": "schema tab next label",
    "schemaTab$next": "Next: Summary",
    "schemaTab$next.comment": "Next: Summary",
    "schemaTab$PreserveTableSchema": "Keep current table schema",
    "schemaTab$PreserveTableSchema.comment": "Label of checkbox which is used to map the data to the existing table columns only",
    "schemaTab$PreserveTableSchema$description": "Map columns by order according to the existing table schema",
    "schemaTab$PreserveTableSchema$description.comment": "Description for the Keep current table schema checkbox",
    "schemaTab$ingestionMappingErrorString": "No columns were mapped to source. Ingestion will be skipped.",
    "schemaTab$ingestionMappingErrorString.comment": "ingestion mapping error string",
    "oneClick$feedbackBubble$title": "We noticed you didn’t finish \ncreating that action.",
    "oneClick$feedbackBubble$title.comment": "The title of the notification that shown when the user didn't complete the action",
    "oneClick$feedbackBubble$content": "Can you tell us about your experience?",
    "oneClick$feedbackBubble$content.comment": "The content of the notification that shown when the user didn't complete the action",
    "schemaTab$newData$subtitle": "Ingest data into table:",
    "schemaTab$newData$subtitle.comment": "Ingest label",
    "eventHub$eventHubManager$getClusterLocationErrorMsg": "Could not get cluster location",
    "eventHub$eventHubManager$getClusterLocationErrorMsg.comment": "Message to show when getting cluster location failed.",
    "eventHub$clusterNoCI": "Cluster is not configured yet for continuous ingestion.",
    "eventHub$clusterNoCI.comment": "Error shown in a message bar if the clustered is not configured to have continuous ingestion",
    "eventHub$multipleListenersError": "The number of listeners on the specified consumer group has reached its limit. Please use another consumer group or try again later.",
    "eventHub$multipleListenersError.comment": "Appears when the users tries to listen to events, but there are too many listeners on that consumer group",
    "eventHub$timeoutOrUnknownError": "There was a problem connecting to this event hub. Try checking if there's IP filtering or try again in a couple of minutes.",
    "eventHub$timeoutOrUnknownError.comment": "This error appears because of timeout or because of IP filtering when trying to listen to events",
    "oneClickPage": {
        "subTitle": {
            "description": {
                "ingestFlow": "Ingestion",
                "ingestFlow.comment": "Title for first section in source tab, which includes general project details"
            }
        },
        "description": {
            "ingestFlow": "Quickly ingest data, create database tables, and map structures. Select data from different sources in different data formats, either as a one-time or continuous ingestion process.\nExplore your data as you like—you'll have the option to revert all changes later on. ",
            "ingestFlow.comment": "{Locked='\n'} Description for the wizard - ingestion flow. 'table' means database table."
        },
        "teachingBubble": {
            "next": "Next",
            "next.comment": "Next label",
            "previous": "Previous",
            "previous.comment": "Previous label",
            "dismiss": "Dismiss",
            "dismiss.comment": "Dismiss",
            "gotIt": "Got it",
            "gotIt.comment": "dismiss",
            "stepStatus": "${currentStep} of ${totalSteps}",
            "stepStatus.comment": "currentStep from total number of steps",
            "ingestNewData": {
                "title": "Ingesting data is easy",
                "title.comment": "Ingesting data title",
                "description": "Set the destination and data source - the automated process takes care of everything else, including generating the schema.",
                "description.comment": "Ingesting data description"
            },
            "createTable": {
                "title": "Prepare now, ingest later",
                "title.comment": "create table title",
                "description": "Define a table and ingest the data when convenient. Provide a sample data source to map the table schema ready for ingestion.",
                "description.comment": "create table description"
            }
        }
    },
    "landingPage$destination": "Destination",
    "landingPage$destination.comment": "tab label destination",
    "landingPage$title": "Data Management",
    "landingPage$title.comment": "This is the title of the landing page.",
    "landingPage$description": "Quickly ingest data, create database tables, and automatically map the table schema. The data can be ingested from different sources and data formats. Use the sample app generator to auto-generate code that queries your own data.",
    "landingPage$description.comment": "Landing page description",
    "landingPage$quickActions": "Quick actions",
    "landingPage$quickActions.comment": "Subtitle of the Landing page quick actions section.",
    "landingPage$allActions": "All",
    "landingPage$allActions.comment": "Subtitle of the Landing page all actions section.",
    "landingPage$oneTimeIngestion": "One-time ingestion",
    "landingPage$oneTimeIngestion.comment": "Tab label for one time ingestion tiles",
    "landingPage$continuousIngestion": "Continuous ingestion",
    "landingPage$continuousIngestion.comment": "Tab label for continuous ingestion tiles",
    "landingPage$backFill": "Backfill",
    "landingPage$backFill.comment": "Tab label for backfill tiles",
    "landingPage$create": "Create",
    "landingPage$create.comment": "Text on buttons for create resources flows",
    "landingPage$ingest": "Ingest",
    "landingPage$ingest.comment": "Text on buttons for ingest flows",
    "landingPage$manage": "Manage",
    "landingPage$manage.comment": "Tab label & text on buttons for manage resources flows",
    "landingPage$settings": "Settings",
    "landingPage$settings.comment": "Subtitle of the Landing page all settings section",
    "landingPage$sdks": "SDKs",
    "landingPage$sdks.comment": "Sub Tab label for SDKs tiles",
    "landingPage$connectors": "Connectors",
    "landingPage$connectors.comment": "Sub Tab label for Connector tiles",
    "landingPage$updatePolicy$updateButton": "Update",
    "landingPage$updatePolicy$updateButton.comment": "Tile button text",
    "landingPage$preview": "Preview",
    "landingPage$preview.comment": "Preview sub title to tile stating this feature in preview mode",
    "landingPage$updatePolicy$retentionPolicy$title": "Table retention policy",
    "landingPage$updatePolicy$retentionPolicy$title.comment": "retention policy title",
    "landingPage$updatePolicy$retentionPolicy$description": "Configure when to remove data from a specific table or materialized view.",
    "landingPage$updatePolicy$retentionPolicy$description.comment": "Tile description for update table retention policy",
    "landingPage$updatePolicy$batchingPolicy$description": "Optimize the ingestion throughput by aggregating data into batches.",
    "landingPage$updatePolicy$batchingPolicy$description.comment": "Tile description for update table retention policy",
    "landingPage$updatePolicy$batchingPolicy$title": "Table batching policy",
    "landingPage$updatePolicy$batchingPolicy$title.comment": "Table batching policy title",
    "landingPage$search$placeholder": "Search all actions",
    "landingPage$search$placeholder.comment": "Search placeholder text",
    "landingPage$documentation$oneClickIngestion": "What is ingestion?",
    "landingPage$documentation$oneClickIngestion.comment": "Link to documentation of ingestion",
    "landingPage$documentation$eventHub": "Use ingestion to create an Event Hubs data connection for Azure Data Explorer",
    "landingPage$documentation$eventHub.comment": "Link to Event Hubs documentation",
    "landingPage$documentation$containerAdls": "Ingest data from a container/Azure Data Lake Storage Gen2 into Azure Data Explorer",
    "landingPage$documentation$containerAdls.comment": "Link to container/ADLS documentation",
    "landingPage$documentation$seeAll": "See all",
    "landingPage$documentation$seeAll.comment": "Link to documentation",
    "landingPage$generate": "Generate",
    "landingPage$generate.comment": "Text on button for generate code flow",
    "landingPage$createExternalTableDescription": "Create an external table and schema mapping with the Azure Data Explorer web app.",
    "landingPage$createExternalTableDescription.comment": "Description of the external table flow",
    "landingPage$createTableDescription": "Create a table and schema mapping with the Azure Data Explorer web app.",
    "landingPage$createTableDescription.comment": "Description of the create table flow",
    "landingPage$manageDatabaseTitle": "Database",
    "landingPage$manageDatabaseTitle.comment": "Title for manage database (create or update)",
    "landingPage$manageDatabaseDescription": "Create and update databases with the Azure Data Explorer web app.",
    "landingPage$manageDatabaseDescription.comment": "Description for create database tile",
    "landingPage$ingestNewDataDescription": "Ingest new data, create database tables, and map structures.",
    "landingPage$ingestNewDataDescription.comment": "Description of the ingest data flow",
    "landingPage$ingestFromFile$description": "Ingest data from a local file and create database tables and map structures.",
    "landingPage$ingestFromFile$description.comment": "Description of the ingest from file flow",
    "landingPage$insightPage$title": "Insight page",
    "landingPage$insightPage$title.comment": "Title of insight page",
    "landingPage$manageData$title": "Manage data",
    "landingPage$manageData$title.comment": "Title if manage data",
    "landingPage$ingestFromFile$title": "Ingest data from local file",
    "landingPage$ingestFromFile$title.comment": "Title of the ingest from file card",
    "landingPage$ingestFromBlob$description": "Ingest data from a blob storage and create database tables and map structures.",
    "landingPage$ingestFromBlob$description.comment": "Description of the ingest from blob flow",
    "landingPage$ingestFromBlob$title": "Ingest data from blob",
    "landingPage$ingestFromBlob$title.comment": "Title of the ingest from blob card",
    "landingPage$ingestFromContainer$description": "Ingest data as a one-time or continuous ingestion.",
    "landingPage$ingestFromContainer$description.comment": "Description of the ingest from container flow",
    "landingPage$ingestFromContainer$title": "Ingest from blob container",
    "landingPage$ingestFromContainer$title.comment": "Title of the ingest from container card",
    "landingPage$ingestFromAdls2$description": "Ingest data from Azure Data Lake Storage Gen2 as a one-time or continuous ingestion process.",
    "landingPage$ingestFromAdls2$description.comment": "Description of the ingest from Adls gen2 flow",
    "landingPage$ingestFromAdls2$title": "Ingest from Azure Data Lake Storage Gen2",
    "landingPage$ingestFromAdls2$title.comment": "Title of the ingest from Adls gen2 card",
    "landingPage$ingestFromAmazonS3$title": "Ingest from Amazon S3",
    "landingPage$ingestFromAmazonS3$title.comment": "Title of the ingest from Amazon S3 card",
    "landingPage$ingestFromAmazonS3$description": "Ingest data from Amazon S3.",
    "landingPage$ingestFromAmazonS3$description.comment": "Description of the ingest from Amazon S3 flow",
    "landingPage$ingestFromEH$description": "Set up a data connection to an existing event hub for ongoing ingestion.",
    "landingPage$ingestFromEH$description.comment": "Description of the ingest from Event Hubs flow",
    "landingPage$ingestFromEH$title": "Ingest from Event Hubs",
    "landingPage$ingestFromEH$title.comment": "Title of the ingest from Event Hubs card",
    "landingPage$generateCode$description": "Create a working app to ingest and query your data with your preferred programming language.",
    "landingPage$generateCode$description.comment": "Description of the generate code flow",
    "landingPage$generateCode$title": "Generate sample app",
    "landingPage$generateCode$title.comment": "Title of the generate code card",
    "landingPage$adxKafka$title": "ADX Kafka",
    "landingPage$adxKafka$title.comment": "Title of ADX Kafka connector tile card",
    "landingPage$adxKafka$description": "The Azure Data Explorer Kafka Sink serves as the connector from Kafka and doesn't require using code.",
    "landingPage$adxKafka$description.comment": "Description of ADX Kafka connector tile card",
    "landingPage$apacheSpark$title": "Apache Spark",
    "landingPage$apacheSpark$title.comment": "Title of Apache Spark connector tile card",
    "landingPage$apacheSpark$description": "The Azure Data Explorer connector for Spark is an open source project that can run on any Spark cluster.",
    "landingPage$apacheSpark$description.comment": "Description of Apache Spark connector tile card",
    "landingPage$jupyter$title": "Jupyter Notebook",
    "landingPage$jupyter$title.comment": "Title of Jupyter Notebook and kqlmagic connector tile card",
    "landingPage$jupyter$description": "Jupyter Notebook and kqlmagic is an open-source web application that allows you to create and share documents containing live code, equations, visualizations, and narrative text.",
    "landingPage$jupyter$description.comment": "Description of Jupyter connector tile card",
    "landingPage$logstash$title": "Logstash",
    "landingPage$logstash$title.comment": "Title of Logstash connector tile card",
    "landingPage$logstash$description": "Logstash is an open source, server-side data processing pipeline that ingests data from many sources simultaneously, transforms the data, and then sends the data to your favorite \"stash\"",
    "landingPage$logstash$description.comment": "Description of Logstash connector tile card",
    "landingPage$adf$title": "Azure Data Factory",
    "landingPage$adf$title.comment": "Title of Azure Data Factory  connector tile card",
    "landingPage$adf$description": "ADF allows you to create data-driven workflows for orchestrating and automating data movement and data transformation.",
    "landingPage$adf$description.comment": "Description of Azure Data Factory  connector tile card",
    "landingPage$logicApp$title": "Logic App",
    "landingPage$logicApp$title.comment": "Title of Logic App connector tile card",
    "landingPage$logicApp$description": "The Azure Kusto Logic App connector enables you to run Kusto queries and commands automatically as part of a scheduled or triggered task, using the Microsoft Logic App connector.",
    "landingPage$logicApp$description.comment": "Description of Logic App connector tile card",
    "landingPage$sdkDotNet$title": ".NET SDK",
    "landingPage$sdkDotNet$title.comment": "Title of .NET SDK tile card",
    "landingPage$sdkDotNet$description": "A library that allows to query ADX clusters using C#.",
    "landingPage$sdkDotNet$description.comment": "Description of .NET SDK tile card",
    "landingPage$sdkJava$title": "Java SDK",
    "landingPage$sdkJava$title.comment": "Title of Java SDK tile card",
    "landingPage$sdkJava$description": "A library that allows to query ADX clusters using Java.",
    "landingPage$sdkJava$description.comment": "Description of Java SDK tile card",
    "landingPage$sdkNodeJS$title": "Node.js SDK",
    "landingPage$sdkNodeJS$title.comment": "Title of Node.js tile card",
    "landingPage$sdkNodeJS$description": "A library that allows to query ADX clusters using Typescript.",
    "landingPage$sdkNodeJS$description.comment": "Description of Node.js SDK tile card",
    "landingPage$sdkPython$title": "Python SDK",
    "landingPage$sdkPython$title.comment": "Title of Python SDK tile card",
    "landingPage$sdkPython$description": "A library that allows to query ADX clusters using Python.",
    "landingPage$sdkPython$description.comment": "Description of Python SDK tile card",
    "landingPage$powerAutomate$title": "Power Automate",
    "landingPage$powerAutomate$title.comment": "Title of Power Automate connector tile card",
    "landingPage$powerAutomate$description": "The Azure Data Explorer Power Automate (previously Microsoft Flow) connector allows Azure Data Explorer to use the flow capabilities of Microsoft Power Automate.",
    "landingPage$powerAutomate$description.comment": "Description of Power Automate connector tile card",
    "persistency$securityError": "Failed to save your data. Please check your browser settings and enable cookies.",
    "persistency$securityError.comment": "Error displayed if couldn't save to local storage due to browser settings.",
    "persistency$spaceError": "Failed to save your data. Please close unused query tabs or free up space in your browser storage.",
    "persistency$spaceError.comment": "Error displayed if couldn't save to local storage due to lack of space.",
    "persistency$unknownError": "Failed to save your data. An unknown error has occurred. Please try again later.",
    "persistency$unknownError.comment": "Error displayed if couldn't save to local storage due to unknown error.",
    "persistency$errorDetailsPrefix": "Exception details:",
    "persistency$errorDetailsPrefix.comment": "Prefix to show before the error we receive.",
    "kwe$loginErrorDialog$title": "Login Error",
    "kwe$loginErrorDialog$title.comment": "A title for a Login Error Dialog",
    "kwe$loginErrorDialog$message": "Failed to login using tenant '${tenantFromQuery}'. Click 'Login with home tenant' to login with the home tenant",
    "kwe$loginErrorDialog$message.comment": "A message for a Login Error Dialog {Locked='${tenantFromQuery}'}",
    "kwe$loginErrorDialog$buttonText": "Login with home tenant",
    "kwe$loginErrorDialog$buttonText.comment": "Button name for a login error dialog",
    "kwe$confirmLoginWithTenant$title": "Token expired",
    "kwe$confirmLoginWithTenant$title.comment": "A title for a dialog that asks the user to confirm the user of a persisted tenant. The user can choose to continue logging-in to the persisted tenant, or roll back to the home tenant.",
    "kwe$confirmLoginWithTenant$message": "Need to re-authenticate to tenant '${tenantId}'. Click 'Continue with the same tenant' to login again with tenant '${tenantId}', or 'Use home tenant' to login with the home tenant.",
    "kwe$confirmLoginWithTenant$message.comment": "A message for a dialog that asks the user to confirm the user of a persisted tenant. The user can choose to continue logging-in to the persisted tenant, or roll back to the home tenant.",
    "kwe$confirmLoginWithTenant$primaryButton": "Continue with the same tenant",
    "kwe$confirmLoginWithTenant$primaryButton.comment": "The continue button in the Confirm Login With Tenant dialog",
    "kwe$confirmLoginWithTenant$cancelButton": "Use home tenant",
    "kwe$confirmLoginWithTenant$cancelButton.comment": "The cancel button in the Confirm Login With Tenant dialog",
    "virtualCluster$ErrorBox$MoreDetails": "Show error details",
    "virtualCluster$ErrorBox$MoreDetails.comment": "Text of additional error details button with chevron down icon, expand the error message with more details ",
    "virtualCluster$info$defaultClusterAlias": "My Free Cluster",
    "virtualCluster$info$defaultClusterAlias.comment": "{Locked='Free Cluster'} Text of default Cluster name/page title if cluster name can't be retrieved, in My Cluster information page ",
    "virtualCluster$info$virtualClusterDescription": "Use the free cluster to try out Azure Data Explorer.\nLoad your data and quickly get answers to your questions for free.",
    "virtualCluster$info$virtualClusterDescription.comment": "Text of My Cluster description, in My Cluster information page",
    "virtualCluster$info$openInDesktopApp": "Open in Kusto Explorer",
    "virtualCluster$info$openInDesktopApp.comment": "Text of link start the desktop app, in My Cluster information page",
    "virtualCluster$info$descriptionSectionHeader": "Description",
    "virtualCluster$info$descriptionSectionHeader.comment": "text for My Cluster Description section header label, in My Cluster information page",
    "virtualCluster$info$propertiesSectionHeader": "Cluster details",
    "virtualCluster$info$propertiesSectionHeader.comment": "text for cluster Properties section header label, in My Cluster information page",
    "virtualCluster$info$actionsSectionHeader": "Actions",
    "virtualCluster$info$actionsSectionHeader.comment": "text for Actions (shortcuts to ingest/query) section header label, in My Cluster information page",
    "virtualCluster$info$createDatabase$title": "Create database",
    "virtualCluster$info$createDatabase$title.comment": "text for 'create database' shortcut card title , in My Cluster information page",
    "virtualCluster$info$createDatabase$description": "Create a database to manage data in tables, functions and materialized views.",
    "virtualCluster$info$createDatabase$description.comment": "text for 'create database' shortcut card description , in My Cluster information page",
    "virtualCluster$info$URI": "Cluster URI",
    "virtualCluster$info$URI.comment": "{Locked='URI'} text for cluster URI label, in My Cluster information page",
    "virtualCluster$info$dmURI": "Data ingestion URI",
    "virtualCluster$info$dmURI.comment": "{Locked='URI'} text for 'data ingestion' service URI label, in My Cluster information page ",
    "virtualCluster$info$location": "Cluster location",
    "virtualCluster$info$location.comment": "text for cluster location label (for example: North Europe, Europe, Asia), in My Cluster information page ",
    "virtualCluster$info$policies": "Policies",
    "virtualCluster$info$policies.comment": "text for policies label (term and condition), in My Cluster information page ",
    "virtualCluster$info$termsOfService": "Terms of service",
    "virtualCluster$info$termsOfService.comment": "text on a link that leads to legal terms of service, in My Cluster information page",
    "virtualCluster$info$privacyPolicy": "Microsoft privacy policy",
    "virtualCluster$info$privacyPolicy.comment": "text on a link that leads privacy policy, in My Cluster information page",
    "virtualCluster$info$andBetweenLinks": "and",
    "virtualCluster$info$andBetweenLinks.comment": "'and' label between 'Terms of service' and 'privacy policy' links, in My Cluster information page ",
    "virtualCluster$info$deleteButtonLabel": "Delete",
    "virtualCluster$info$deleteButtonLabel.comment": "text for delete button , in My Cluster information page ",
    "virtualCluster$info$upgradeButtonLabel": "Upgrade to Azure Cluster",
    "virtualCluster$info$upgradeButtonLabel.comment": "{Locked='Azure', 'Cluster'} text for upgrade to 'normal' Azure Data Explorer Cluster button , in My Cluster information page ",
    "virtualCluster$noCluster$header": "Bring data to Azure Data Explorer and analyze for free",
    "virtualCluster$noCluster$header.comment": "text for page header, in My Cluster landing page/ no Virtual Cluster ",
    "virtualCluster$noCluster$description": "Upload your data with just a few clicks, and quickly get answers to your questions",
    "virtualCluster$noCluster$description.comment": "{Locked='\n'} text for description of the service, in My Cluster landing page/ no Virtual Cluster ",
    "virtualCluster$noCluster$action": "Create cluster and database",
    "virtualCluster$noCluster$action.comment": "text for call to action button to create the cluster, in My Cluster landing page/ no Virtual Cluster ",
    "virtualCluster$createCluster$header": "Create a cluster and database",
    "virtualCluster$createCluster$header.comment": "text for popup header, in My Cluster create wizard ",
    "virtualCluster$creatingCluster$header": "Creating your free cluster ...",
    "virtualCluster$creatingCluster$header.comment": "text for popup header, in My Cluster create wizard during creation process, everything is disabled",
    "virtualCluster$createCluster$description": "Name your cluster and first database, then pick a location to deploy it.",
    "virtualCluster$createCluster$description.comment": "text for header description/sub title, in My Cluster create wizard ",
    "virtualCluster$createCluster$displayNameLabel": "Cluster display name",
    "virtualCluster$createCluster$displayNameLabel.comment": "text for display name field label, in My Cluster create wizard ",
    "virtualCluster$createCluster$displayNameDescription": "This cluster display name is to help you recognize the cluster easily within Azure Data Explorer.\nUse the cluster URI everywhere else",
    "virtualCluster$createCluster$displayNameDescription.comment": "{Locked= 'Azure data Explorer'} text for display name field description, in My Cluster create wizard ",
    "virtualCluster$createCluster$databaseNameLabel": "Database name",
    "virtualCluster$createCluster$databaseNameLabel.comment": "text for database name field label, in My Cluster create wizard ",
    "virtualCluster$createCluster$regionLabel": "Cluster location",
    "virtualCluster$createCluster$regionLabel.comment": "text for location drop down field label, in My Cluster create wizard ",
    "virtualCluster$createCluster$regionDescription": "Select a location to deploy the cluster",
    "virtualCluster$createCluster$regionDescription.comment": "text for location drop down field description, in My Cluster create wizard ",
    "virtualCluster$createCluster$regionLoadingLabel": "Loading locations",
    "virtualCluster$createCluster$regionLoadingLabel.comment": "text for loading message while fetching available cluster location information from the server, in My Cluster create wizard",
    "virtualCluster$createCluster$regionLoadingError": "Failed to load available locations. ${code}",
    "virtualCluster$createCluster$createError": "Failed to create cluster. ${code}",
    "virtualCluster$createCluster$createError.comment": "text for error title of cluster creation error, in My Cluster create wizard, {Locked='${code}'} error code",
    "virtualCluster$createCluster$regionLoadingError.comment": "text for error title of location loading fails, in My Cluster create wizard, {Locked='${code}'} error code",
    "virtualCluster$info$clusterListError": "Failed to load free cluster details. ${code}",
    "virtualCluster$info$clusterListError.comment": "text for error title of reading user Virtual cluster information, in My Cluster information page, {Locked='${code}'} error code",
    "virtualCluster$deleteCluster$deleteClusterError": "Failed to delete cluster. ${code}",
    "virtualCluster$deleteCluster$deleteClusterError.comment": "text for error title of delete cluster fails, in My Cluster information page, {Locked='${code}'} error code",
    "virtualCluster$createCluster$legalTermsLabel": "By clicking “Create”, you agree to the",
    "virtualCluster$createCluster$legalTermsLabel.comment": "text for legal terms checkbox label (will follow with links to Term of service & privacy policy), in My Cluster create wizard ",
    "virtualCluster$createCluster$minimumLengthError": "Name is required",
    "virtualCluster$createCluster$minimumLengthError.comment": "error message for field value too short (database name, display name), in My Cluster create wizard ",
    "virtualCluster$createCluster$DatabaseNameErrorMsg": "Can include alphanumeric chars, hyphens, underscores, and spaces, nothing else.",
    "virtualCluster$createCluster$DatabaseNameErrorMsg.comment": "Error message when the user tries to create a table with name not according to our format",
    "virtualCluster$createCluster$createAction": "Create",
    "virtualCluster$createCluster$createAction.comment": "text for create cluster action button, in My Cluster create wizard ",
    "virtualCluster$createCluster$cancelAction": "Cancel",
    "virtualCluster$createCluster$cancelAction.comment": "text for cancel creation/hide wizard action button, in My Cluster create wizard ",
    "virtualCluster$deleteCluster$Title": "Delete the cluster?",
    "virtualCluster$deleteCluster$Title.comment": "text for delete confirm popup header, in My Cluster confirm delete popup ",
    "virtualCluster$deleteCluster$deletingTitle": "Deleting the cluster...",
    "virtualCluster$deleteCluster$deletingTitle.comment": "text for delete confirm popup header, after approval, while deleting, in My Cluster confirm delete popup ",
    "virtualCluster$deleteCluster$description": "This action will permanently delete ‘${confirmationName}’ and its associated resources. ",
    "virtualCluster$deleteCluster$description.comment": "{Locked='${confirmationName}'} text for delete confirm description, in My Cluster confirm delete popup",
    "virtualCluster$deleteCluster$displayNameLabel": "Type the cluster display name to confirm deletion",
    "virtualCluster$deleteCluster$displayNameLabel.comment": "text for delete name confirm field label, in My Cluster confirm delete popup",
    "virtualCluster$deleteCluster$deleteButtonLabel": "Delete cluster",
    "virtualCluster$deleteCluster$deleteButtonLabel.comment": "text for delete button, on My Cluster information page ",
    "virtualCluster$deleteDatabase$Title": "Delete the database?",
    "virtualCluster$deleteDatabase$Title.comment": "text for delete confirm popup header, in My Cluster confirm delete database popup ",
    "virtualCluster$deleteDatabase$deletingTitle": "Deleting the database...",
    "virtualCluster$deleteDatabase$deletingTitle.comment": "text for delete confirm popup header, after approval, while deleting, in My Cluster confirm delete popup ",
    "virtualCluster$deleteDatabase$description": "This action will permanently delete ‘${confirmationName}’ and its associated resources. ",
    "virtualCluster$deleteDatabase$description.comment": "{Locked='${confirmationName}'} text for delete confirm description, in My Cluster confirm delete popup",
    "virtualCluster$deleteDatabase$displayNameLabel": "Type the database name to confirm deletion",
    "virtualCluster$deleteDatabase$displayNameLabel.comment": "text for delete name confirm field label, in My Cluster confirm delete popup",
    "virtualCluster$deleteDatabase$deleteClusterError": "Failed to delete database. ${code}",
    "virtualCluster$deleteDatabase$deleteClusterError.comment": "text for error title of delete cluster fails, in My Cluster information page, {Locked='${code}'} error code",
    "virtualCluster$deleteDatabase$deleteButtonLabel": "Delete database",
    "virtualCluster$deleteDatabase$deleteButtonLabel.comment": "text for delete button , in My Cluster information page ",
    "virtualCluster$info$databaseSectionHeader": "Databases",
    "virtualCluster$info$databaseSectionHeader.comment": "text for databases list section header label, in My Cluster information page",
    "virtualCluster$info$databaseTableNameColumn": "Name",
    "virtualCluster$info$databaseTableNameColumn.comment": "text for database name column header in databases list , in My Cluster information page",
    "virtualCluster$info$comingSoonTitle": "Coming Soon",
    "virtualCluster$info$comingSoonTitle.comment": "text for coming soon title - functionality that is still missing , in My Cluster info pane",
    "virtualCluster$info$upgradeMessageHighlighted": "Your free plan is almost used up.",
    "virtualCluster$info$upgradeMessageHighlighted.comment": "text for upgrade call to action message bar, text will be follow by non-highlighted text, learn more and upgrade button, in My Cluster info pane",
    "virtualCluster$info$upgradeMessageNormal": "Upgrade to the full plan and pay for what you use.",
    "virtualCluster$info$upgradeMessageNormal.comment": "text for upgrade call to action message bar, text after highlighted text (virtualCluster$info$upgradeMessageNormal), will be follow by learn more and upgrade button, in My Cluster info pane",
    "virtualCluster$info$comingSoonDescription": "",
    "virtualCluster$info$comingSoonDescription.comment": "text for coming soon description - functionality that is still missing , in My Cluster info pane",
    "virtualCluster$createDatabaseWizard$wizardTitle": "Create database",
    "virtualCluster$createDatabaseWizard$wizardTitle.comment": "text for wizard header/title , in My cluster create database wizard",
    "virtualCluster": {
        "upgradePane": {
            "title": "Upgrade cluster",
            "title.comment": "text for upgrade pane title, in My Cluster upgrade pane",
            "inProgressTitle": "Upgrading cluster ...",
            "inProgressTitle.comment": "text for upgrade pane title during upgrade, in My Cluster upgrade pane",
            "headerDescription": "Select an Azure subscription to manage the deployed resources. The Resource group organizes the cluster and resources used ",
            "headerDescription.comment": "text for description of upgrade, just below the pane header , in My Cluster upgrade pane",
            "upgradeError": "Failed to create Azure cluster. ${code}",
            "upgradeError.comment": "text for error title of creating the upgraded (Azure) cluster, in My Cluster upgrade pane, {Locked='${code}'} error code",
            "addUser": {
                "linkLabel": "Add user",
                "linkLabel.comment": "text for Link Button that will trigger user login popup, in My Cluster upgrade pane",
                "description": "You're currently logged in as ${userEmail}. Add a different user to view more subscriptions.",
                "description.comment": "description of add user/login with additional identity, will follow by link button to add user, in My Cluster upgrade pane, {Locked='${userEmail}'} current login user"
            },
            "subscriptions": {
                "label": "Subscription",
                "label.comment": "text for subscriptions dropdown field label, in My Cluster upgrade pane",
                "permissionError": "You don't have permission to create a cluster. Please contact the Azure subscription owner",
                "permissionError.comment": "error message when user doesn't have needed permission, in My Cluster upgrade pane",
                "infoBallon": "Select a subscription to manage and bill your cluster",
                "infoBallon.comment": "text for subscriptions dropdown info balloon, in My Cluster upgrade pane",
                "placeholder": "Select subscription",
                "placeholder.comment": "text for subscriptions dropdown placeholder (no value), in My Cluster upgrade pane",
                "description": "Upgrade as",
                "description.comment": "text for description line below subscriptions dropdown field, will be followed by bold user name and add user link eg (Upgrade as **noam@microsoft.com**. Add User ), in My Cluster upgrade pane",
                "optionLabelAccountWithoutSubscription": "No subscriptions",
                "optionLabelAccountWithoutSubscription.comment": "text for disabled option in subscription selection dropdown when account have no subscriptions, in My Cluster upgrade pane, {Locked='${code}'} error code",
                "optionLabelAccountError": "(Can't load subscription details - ${code)",
                "optionLabelAccountError.comment": "text for error message in users/subscription selection dropdown, will be append after the account email - e.g user@microsoft.com (error message), in My Cluster upgrade pane, {Locked='${code}'} error code"
            },
            "clusterName": {
                "label": "Cluster name",
                "label.comment": "text for cluster name field label, in My Cluster upgrade pane",
                "charsError": "Cluster name must begin with a letter and contain lowercase alphanumeric characters\nCluster name must be between 4 and 22 characters long",
                "charsError.comment": "text for cluster name field error, cluster name contain wrong chars, in My Cluster upgrade pane",
                "nameNotAvailableError": "Cluster name not available, please choose different name",
                "nameNotAvailableError.comment": "Error message when name to available (already used), in My Cluster upgrade pane",
                "nameVerificationError": "Can't verify the cluster name (${code})",
                "nameVerificationError.comment": "Error message when cluster name verification fails, in My Cluster upgrade pane, {Locked='${code}'} error code"
            },
            "resourceGroup": {
                "label": "Resource group",
                "label.comment": "text for resource group dropdown field label, in My Cluster upgrade pane",
                "infoBallon": "A resource group is where you organize the resources and services you consume",
                "infoBallon.comment": "text for resource group dropdown info balloon, in My Cluster upgrade pane",
                "placeholder": "Select resource group",
                "placeholder.comment": "text for resource group dropdown placeholder (no value), in My Cluster upgrade pane",
                "noAvailableGroups": "No available resource group",
                "noAvailableGroups.comment": "text for resource group dropdown placeholder (no value), in My Cluster upgrade pane",
                "createResourceGroupLinkLabel": "Create new resource group",
                "createResourceGroupLinkLabel.comment": "Text for link label that opens create resource group popup, in My Cluster upgrade pane"
            },
            "region": {
                "label": "Cluster region",
                "label.comment": "text for region dropdown field label, in My Cluster upgrade pane",
                "infoBallon": "Select in which region you want the cluster deployed. You'll get better performance if it's close to where the data is used",
                "infoBallon.comment": "text for region dropdown info balloon, in My Cluster upgrade pane",
                "placeholder": "Select region",
                "placeholder.comment": "text for region dropdown placeholder (no value), in My Cluster upgrade pane"
            },
            "workload": {
                "label": "Workload",
                "label.comment": "text for workload section header, in My Cluster upgrade pane",
                "infoBallon": "A workload is a collection of servers, VMs, and data. Your bill includes the storage, network, and computational costs.",
                "infoBallon.comment": "text for workload header info balloon, in My Cluster upgrade pane",
                "description": "An Azure Data Explorer production cluster will be created with a ${vmTypeName} VM. The pay as you go cost per month will be between ~$600 to $800 depending on the region and actual usage.",
                "description.comment": "text for workload description, in My Cluster upgrade pane"
            },
            "availabilityZone": {
                "label": "Availability zone",
                "label.comment": "text for availability zone dropdown field (yes,no), in My Cluster upgrade pane",
                "infoBallon": "Optionally, deploy the cluster resources in physically separate locations in the selected region",
                "infoBallon.comment": "text for availability zone info ballon, in My Cluster upgrade pane",
                "description": "",
                "description.comment": "removed for, text for availability zone description, in My Cluster upgrade pane"
            },
            "createResourceGroupPopup": {
                "nameLabel": "Resource group name",
                "nameLabel.comment": "text for field to enter new resource group name, in My Cluster upgrade pane",
                "emptyNameError": "Enter resource group name",
                "emptyNameError.comment": "error message when resource group name is empty, in My Cluster upgrade pane",
                "charsError": "Resource group name can only include alphanumeric, underscore, parentheses, hyphen, period (except at end).\nResource group name must be between 1 and 90 characters long",
                "charsError.comment": "error message when resource group name is empty, in My Cluster upgrade pane",
                "nameNotAvailableError": "A resource group with the same name already exists, please choose different name",
                "nameNotAvailableError.comment": "Error message when name to available (already used), in My Cluster upgrade pane",
                "nameNotAllowedError": "The resource group name or a part of the name is a trademarked or reserved word.",
                "nameNotAllowedError.comment": "Error message when resource group name is not allowed, in My Cluster upgrade pane",
                "nameVerificationError": "Can't verify the resource group name (${code})",
                "nameVerificationError.comment": "Error message when resource group name verification fails, in My Cluster upgrade pane, {Locked='${code}'} error code",
                "nameAvailabilityError": "Can't verify the resource group name availability(${code})",
                "nameAvailabilityError.comment": "Error message when resource group name availability check fails, in My Cluster upgrade pane, {Locked='${code}'} error code"
            },
            "dataConnectionValidation": {
                "errorTitle": "Can't validate Event Hubs permission",
                "noPermissionWarning": "The event hub's data connections ${EHNames} cannot be transferred to the full cluster because you don't have the Event Hubs owner permission.",
                "noPermissionWarning.comment": "Warning message after the user approve the lose of the event hubs , in My Cluster upgrade pane, {Locked='${EHNames}'} names of event hubs that will not be transferred",
                "noPermissionError": "The event hub's data connections ${EHNames} cannot be transferred to the full cluster because you don't have Event Hubs owner permission. If you upgrade the cluster, it will be without these data connections.",
                "noPermissionError.comment": "Error message, notify the user that EH will be lost, user can choose to continue , in My Cluster upgrade pane, {Locked='${EHNames}'} names of event hubs that will not be transferred"
            },
            "dataConnectionApprovalDialog": {
                "title": "Upgrade free cluster without its data connections",
                "title.comment": "title of dialog, user should approve the lost of data connections ",
                "actionDescription": "Because you don't have Event Hubs owner permission, data connections ${dataConnectionNames} will not be transferred to the full cluster ${clusterName}",
                "actionDescription.comment": "description text, {Locked='${dataConnectionNames}','${clusterName}' } names of event hubs that will not be transferred and cluster name",
                "confirmationLabel": "Type the cluster display name to confirm upgrade",
                "confirmationLabel.comment": "label of text field, where the user should type the cluster name to confirm the action",
                "upgradeButtonLabel": "Upgrade cluster",
                "cancelButtonLabel": "Stay with free cluster"
            }
        },
        "infoPane": {
            "dataConnectionList": {
                "loadingListError": "Failed to load data connection. ${code}",
                "loadingListError.comment": "error while loading data connections information, {Locked='${code}'} ",
                "deleteDataConnection": {
                    "buttonLabel": "Delete",
                    "successNotification": "Successfully deleted the data connection ${dataConnectionName}",
                    "successNotification.comment": "notification message when data connection deleted, {Locked='${dataConnectionName}'}",
                    "failNotification": "Failed to delete data connection. ${code}",
                    "failNotification.comment": "notification message when data connection deleted, {Locked='${dataConnectionName}'}",
                    "dialogTile": "Delete the data connection?",
                    "dialogDeletingTitle": "Deleting data connection ...",
                    "deleteDescriptionWithConfirmationName": "This action will delete the ‘${dataConnectionName}‘ data connection.",
                    "deleteDescriptionWithConfirmationName.connect": "description of the delete data connection, {Locked='${dataConnectionName}'}",
                    "confirmationLabel": "Type the data connection name to confirm deletion",
                    "confirmationLabel.comment": "description of are you sure call label, user need to type in the data connection name",
                    "approveDeleteButton": "Delete data connection"
                },
                "reconnectDataConnection": {
                    "buttonLabel": "Reconnect",
                    "inProgressNotification": "Reconnecting ${dataConnectionName}",
                    "inProgressNotification.comment": "notification message when data connection reconnecting, {Locked='${dataConnectionName}'}",
                    "failRetrieveKeysNotification": "Failed to retrieve new connection strings for data connection ${dataConnectionName}",
                    "failRetrieveKeysNotification.comment": "error notification when failing to retrieve connection keys , {Locked='${dataConnectionName}'}",
                    "failNotification": "Failed to reconnect to data connection ${dataConnectionName}",
                    "failNotification.comment": "error notification when updating/reconnecting data connection , {Locked='${dataConnectionName}'}",
                    "successNotification": "Successfully reconnected to data connection ${dataConnectionName}",
                    "successNotification.comment": "notification message when data connection reconnected, {Locked='${dataConnectionName}'}"
                },
                "columnHeaders": {
                    "dataConnectionName": "Name",
                    "databaseName": "Database",
                    "source": "Source",
                    "tableName": "Destination table",
                    "status": "Status"
                },
                "dataConnectionStatus": {
                    "connected": "Connected",
                    "connected.comment": "label value that will be display in status column when data connection is ok",
                    "disconnected": "Disconnected",
                    "disconnected.comment": "label value that will be display in status column when data connection have errors",
                    "disconnectedDescription": "There is a connectivity issue with the event hub. Either the event hub was deleted or its account keys were regenerated.\nIf the account keys were regenerated, click the reconnect icon.\nIf the event hub was deleted, you cannot reconnect to it",
                    "disconnectedDescription.comment": "description in info ballon of disconnected status"
                },
                "addDataConnectionButton": {
                    "label": "Ingest from Event Hubs",
                    "label.comment": "Add data connection button label",
                    "tooltip": "When you ingest from an event hub, a continuous data connection is created so that new events can be sent to the destination table."
                },
                "sectionHeader": {
                    "label": "Data connections",
                    "tooltip": "A data connection connects an event hub to the Azure Data Explorer cluster."
                },
                "dataConnectionTypes": {
                    "_.comments": "name of the data connection types that will appear in parentheses after the data connection name in data connection table for example- MyDataConnection(event hub)",
                    "eventHubType": "Event Hubs"
                }
            },
            "action": {
                "ingest": {
                    "title": "Ingest data",
                    "title.comment": "text for 'ingest data' shortcut card title , in My Cluster information page",
                    "description": "Ingest data from local files, Azure blob storage or Azure Event Hubs.",
                    "description.comment": "text for 'ingest data' shortcut card description , in My Cluster information page",
                    "button": "Ingest",
                    "button.comment": "text for 'ingest data' shortcut card call to action button , in My Cluster information page"
                },
                "query": {
                    "title": "Query data",
                    "title.comment": "text for 'query' shortcut card title , in My Cluster information page",
                    "description": "Explore data interactively to drive business insights at scale ",
                    "description.comment": "text for 'query' shortcut card description , in My Cluster information page",
                    "button": "Query",
                    "button.comment": "text for 'query' shortcut card call to action button , in My Cluster information page"
                },
                "createDatabase": {
                    "title": "Create database",
                    "title.comment": "text for 'create database' shortcut card title , in My Cluster information page",
                    "description": "Create a database to manage data in tables, functions and materialized views.",
                    "description.comment": "text for 'create database' shortcut card description , in My Cluster information page",
                    "button": "Create",
                    "button.comment": "text for 'create database' shortcut card call to action button , in My Cluster information page",
                    "disabledTooltip": "You can only create 10 databases when using Free Cluster. Please upgrade to a full Azure cluster or delete a database before creating a new one",
                    "disabledTooltip.comment": "text for tooltip of disabled 'create database' card, in My Cluster information page"
                }
            }
        },
        "NoLoginCreatePanel": {
            "formSectionHeader": "Create a free cluster to explore your data",
            "formSectionDescription": "If you have a Microsoft account you can try Azure Data Explorer for free!\nName your cluster and first database, then pick a location to deploy it",
            "panelFooter": {
                "loginAndCreateButtonLabel": "Sign in and create cluster",
                "loginAndCreateButtonLabel.comment": "button label that will load login popup and then create the free cluster",
                "skipAndSignInButtonLabel": "Skip and sign in",
                "skipAndSignInButtonLabel.comment": "button label that will redirect to login page, skipping the create process"
            },
            "panelHeader": {
                "panelHeader": "Welcome to Azure Data Explorer!",
                "bullet1Label": "Turn your data into insights within seconds",
                "bullet2Label": "Quickly identify anomalies and trends",
                "bullet3Label": "Derive insights from large volumes of telemetry"
            },
            "singInCanceledErrorMessage": "You can't create a cluster without signing in. Please sign in",
            "signInFailedErrorMessage": "Sign in failed. Please try again",
            "clusterCreationFailedErrorMessage": "Creation failed. Please try again",
            "alreadyHaveClusterNotification": {
                "title": "Use your existing free cluster",
                "details": "A new cluster was not created"
            }
        },
        "dataConnectionPanel": {
            "title": "Data connection details ${name}",
            "title.comment": "title of data connection details pane, {Locked='${name}'} data connection name",
            "targetTableSection": {
                "label": "Target table"
            },
            "tableName": {
                "label": "Table name"
            },
            "tableMapping": {
                "label": "Mapping name"
            },
            "dataFormat": {
                "label": "Data format"
            },
            "noneValue": "None",
            "noneValue.comment": "value to display in dropdown when no option is selected"
        }
    },
    "virtualCluster$upgradingVirtualCluster$text": "Your full Azure cluster is being created...",
    "virtualCluster$upgradingVirtualCluster$text.comment": "text below spinner while Virtual cluster is upgrading to ADX cluster, in My Cluster page ",
    "virtualCluster$upgradingVirtualClusterNotification$create$title": "Your full Azure cluster is being created...",
    "virtualCluster$upgradingVirtualClusterNotification$create$title.comment": "title of notification, create Azure cluster during upgrade of virtual cluster flow",
    "virtualCluster$upgradingVirtualClusterNotification$create$message": "It might take a few minutes to complete.",
    "virtualCluster$upgradingVirtualClusterNotification$create$message.comment": "description of notification, create Azure cluster during upgrade of virtual cluster flow",
    "virtualCluster$upgradingVirtualClusterNotification$copy$title": "Copying the data to your full Azure cluster...",
    "virtualCluster$upgradingVirtualClusterNotification$copy$title.comment": "title of notification, copy databases to Azure cluster during upgrade of virtual cluster flow",
    "virtualCluster$upgradingVirtualClusterNotification$copy$message": "",
    "virtualCluster$upgradingVirtualClusterNotification$copy$message.comment": "text of notification, create Azure cluster during upgrade of virtual cluster flow",
    "virtualCluster$upgradingVirtualClusterNotification$error$title": "Failed to upgrade to full Azure cluster",
    "virtualCluster$upgradingVirtualClusterNotification$error$title.comment": "notification title, upgrade to Azure cluster failed, during upgrade of virtual cluster flow ",
    "virtualCluster$upgradingVirtualClusterNotification$error$message": "Upgrade failure: ${code}",
    "virtualCluster$upgradingVirtualClusterNotification$error$message.comment": "text of notification, upgrade to Azure cluster failed, during upgrade of virtual cluster flow,  {Locked='${code}'} error code",
    "virtualCluster$upgradingVirtualClusterNotification$completed$title": "Your full Azure cluster is ready",
    "virtualCluster$upgradingVirtualClusterNotification$completed$title.comment": "title of notification, upgrade to Azure cluster completed",
    "virtualCluster$upgradingVirtualClusterNotification$completed$message": "Your free cluster was upgraded to a full Azure cluster and is ready to use",
    "virtualCluster$upgradingVirtualClusterNotification$completed$message.comment": "text of notification, upgrade to Azure cluster completed",
    "virtualCluster$upgradingVirtualClusterNotification$detailsLink": "See details",
    "virtualCluster$upgradingVirtualClusterNotification$detailsLink.comment": "text for link in notification message, to view cluster info in portal, during upgrade of virtual cluster flow",
    "virtualCluster$upgradingVirtualClusterNotification$documentationLink": "Go to documentation",
    "virtualCluster$upgradingVirtualClusterNotification$documentationLink.comment": "text for link in notification message, to view documentation, during upgrade of virtual cluster flow",
    "virtualCluster$upgradingVirtualClusterNotification$gotoPortalLink": "Manage the cluster in the Azure portal",
    "virtualCluster$upgradingVirtualClusterNotification$gotoPortalLink.comment": "text for link, to manage the cluster in portal, notification on upgrade to Azure cluster completed",
    "virtualCluster$regionName$northeurope": "Europe",
    "virtualCluster$regionName$northeurope.comment": "text for cluster location option of europe area, in My Cluster create wizard",
    "virtualCluster$regionName$eastus": "US",
    "virtualCluster$regionName$eastus.comment": "text for cluster location option of US area, in My Cluster create wizard",
    "virtualCluster$regionName$australiaeast": "Asia",
    "virtualCluster$regionName$australiaeast.comment": "text for cluster location option of Asia area, in My Cluster create wizard",
    "virtualCluster$regionName$southcentralus": "North America",
    "virtualCluster$regionName$southcentralus.comment": "text for cluster location option of North America area, in My Cluster create wizard",
    "virtualCluster$manageDatabaseTab$tabHeader": "Create database",
    "virtualCluster$manageDatabaseTab$tabHeader.comment": "label of database details tab, in manage database wizard virtual cluster",
    "virtualCluster$manageDatabaseTab$nextButton": "Next: Create Database",
    "virtualCluster$manageDatabaseTab$nextButton.comment": "label of next tab button which will execute database creation, in manage database wizard",
    "linkToAddNewFeature": "Suggest a new feature",
    "linkToAddNewFeature.comment": "Link's text. Clicking the link will take the user to a new page where they can give feedback.",
    "homePage$cover$title": "Welcome to Azure Data Explorer",
    "homePage$cover$title.comment": "Cover image title",
    "homePage$cover$shortButtonText": "Create a free cluster",
    "homePage$cover$shortButtonText.comment": "Cover image button text in small width screen",
    "homePage$cover$buttonText": "Create a free cluster to explore your data",
    "homePage$cover$buttonText.comment": "Cover image button text",
    "homePage$cover$description": "Discover valuable business insights and make data-driven decisions",
    "homePage$cover$description.comment": "Cover image description text about kusto web potential",
    "homePage$buttonText$learningModule": "Complete learning module",
    "homePage$buttonText$learningModule.comment": "Button label",
    "homePage$buttonText$getStarted": "Get started",
    "homePage$buttonText$getStarted.comment": "Button label",
    "homePage$learningAzure$subTitle": "Learn how the features of Azure Data Explorer work to turn your data into insights you can use.",
    "homePage$learningAzure$title": "Intro to Azure Data Explorer",
    "homePage$learningAzure$title.comment": "Azure module title",
    "homePage$learningAzure$subTitle.comment": "Learn Azure module subtitle",
    "homePage$learningKQL$title": "Write your first query with KQL",
    "homePage$learningKQL$title.comment": "KQL module title",
    "homePage$learningKQL$subTitle": "Write simple queries in Kusto Query Language (KQL)",
    "homePage$learningKQL$subTitle.comment": "Learn KQL module subtitle",
    "homePage$explorer$title": "Query data",
    "homePage$explorer$title.comment": "Explorer title",
    "homePage$explorer$subTitle": "Write, run, and share KQL commands and queries",
    "homePage$explorer$subTitle.comment": "Query data",
    "homePage$dashboards$title": "Visualize data",
    "homePage$dashboards$title.comment": "Dashboards title",
    "homePage$dashboards$subTitle": "Visualize data with Azure Data Explorer dashboards.",
    "homePage$dashboards$subTitle.comment": "dashboards subtitle",
    "homePage$oneClick$title": "Ingest data",
    "homePage$oneClick$title.comment": "Data management title",
    "homePage$oneClick$subTitle": "Ingest data, create tables and map structures.",
    "homePage$oneClick$subTitle.comment": "Data management subtitle",
    "homePage$exploreDashboardsSamples$subtitle": "Understand what you can add to the dashboard. \nClick \"Explore\" to visualize sample data of various types.",
    "homePage$exploreDashboardsSamples$subtitle.comment": "{Locked='\n'} Explore dashboards subtitle",
    "homePage$exploreDashboardsSamples$title": "Explore sample dashboards",
    "homePage$exploreDashboardsSamples$title.comment": "Explore sample dashboards",
    "homePage$exploreDatabaseSamples$title": "Explore data samples",
    "homePage$exploreDatabaseSamples$title.comment": "dialog title",
    "homePage$exploreSamplesDialog$subtitle": "Use common scenarios to get started with KQL. \nClick \"Explore\" to run a query tutorial or write your own queries using the sample data.",
    "homePage$exploreSamplesDialog$subtitle.comment": "{Locked='\n'} Explore data sample subtitle",
    "homePage$exploreSamplesDialog$title": "Explore data samples",
    "homePage$exploreSamplesDialog$title.comment": "dialog title",
    "homePage$exploreSamplesDialog$primaryBtn": "Explore",
    "homePage$exploreSamplesDialog$primaryBtn.comment": "Primary button text",
    "homePage$exploreSamplesDialog$dashboards$generalTitle": "Dashboard for basic data",
    "homePage$exploreSamplesDialog$dashboards$generalTitle.comment": "Dashboard for basic data",
    "homePage$exploreSamplesDialog$generalTitle": "Basic data",
    "homePage$exploreSamplesDialog$generalTitle.comment": "Sample card title",
    "homePage$exploreSamplesDialog$dashboards$generalDescription": "Visualize sample datasets to understand what you can put on your dashboard.",
    "homePage$exploreSamplesDialog$dashboards$generalDescription.comment": "general sample description, Dashboards",
    "homePage$exploreSamplesDialog$generalDescription": "Some sample datasets to help you familiarize yourself with Azure Data Explorer.",
    "homePage$exploreSamplesDialog$generalDescription.comment": "general sample database card description",
    "homePage$exploreSamplesDialog$dashboards$iotTitle": "IoT sample dashboard",
    "homePage$exploreSamplesDialog$dashboards$iotTitle.comment": "Dashboard for IoT data",
    "homePage$exploreSamplesDialog$iotTitle": "IoT data",
    "homePage$exploreSamplesDialog$iotTitle.comment": "IoT card title",
    "homePage$exploreSamplesDialog$dashboards$iotDescription": "Visualize sample telemetric data collected from various types of sensors",
    "homePage$exploreSamplesDialog$dashboards$iotDescription.comment": "iot card description, Dashboards",
    "homePage$exploreSamplesDialog$iotDescription": "A wide range of sample telemetric data collected from various types of sensors",
    "homePage$exploreSamplesDialog$iotDescription.comment": "iot card description",
    "homePage$exploreSamplesDialog$dashboards$logsTitle": "Logs sample dashboard",
    "homePage$exploreSamplesDialog$dashboards$logsTitle.comment": "Dashboard for log data",
    "homePage$exploreSamplesDialog$logsTitle": "Logs data",
    "homePage$exploreSamplesDialog$logsTitle.comment": "Logs card title",
    "homePage$exploreSamplesDialog$dashboards$metricsTitle": "Metrics sample dashboard ",
    "homePage$exploreSamplesDialog$dashboards$metricsTitle.comment": "Dashboard for metrics data",
    "homePage$exploreSamplesDialog$metricsTitle": "Metrics data",
    "homePage$exploreSamplesDialog$metricsTitle.comment": "Metrics sample data",
    "homePage$exploreSamplesDialog$dashboards$metricsDescription": "Visualize sample SQL server metrics generated by monitoring the servers ecosystem",
    "homePage$exploreSamplesDialog$dashboards$metricsDescription.comment": "Metrics card description, Dashboards",
    "homePage$exploreSamplesDialog$metricsDescription": "Sample SQL server metrics generated by monitoring the SQL servers ecosystem",
    "homePage$exploreSamplesDialog$metricsDescription.comment": "metrics sample tutorial description",
    "homePage$exploreSamplesDialog$dashboards$logsDescription": "Visualize sample logs that include server logs (syslog), security logs, and trace logs.",
    "homePage$exploreSamplesDialog$dashboards$logsDescription.comment": "Logs card description, Dashboards",
    "homePage$exploreSamplesDialog$logsDescription": "Sample logs that include server logs (syslog), security logs, and trace logs.",
    "homePage$exploreSamplesDialog$logsDescription.comment": "Logs card description",
    "homePage$tutorialsMenu$metricsText": "Metrics data",
    "homePage$tutorialsMenu$metricsText.comment": "Metrics data",
    "homePage$tutorialsMenu$advanceTutorial": "Advance",
    "homePage$tutorialsMenu$advanceTutorial.comment": "Advance Tutorial",
    "homePage$tutorialsMenu$metricsSqlTutorial": "SQL metrics",
    "homePage$tutorialsMenu$metricsSqlTutorial.comment": "SQL Metrics Tutorial",
    "homePage$tutorialsMenu$generalTutorial": "Basic data",
    "homePage$tutorialsMenu$generalTutorial.comment": "Basic data",
    "homePage$tutorialsMenu$logsTutorial": "Logs data",
    "homePage$tutorialsMenu$logsTutorial.comment": "Logs data",
    "homePage$tutorialsMenu$traceLogsTutorial": "Trace logs",
    "homePage$tutorialsMenu$traceLogsTutorial.comment": "Trace Logs Tutorial",
    "homePage$tutorialsMenu$sysLogsTutorial": "SysLogs",
    "homePage$tutorialsMenu$sysLogsTutorial.comment": "SysLogs Tutorial",
    "homePage$tutorialsMenu$iotTutorial": "IoT data",
    "homePage$tutorialsMenu$iotTutorial.comment": "IoT data",
    "homePage$tutorialsMenu$tutorialMenu": "tutorials menu",
    "homePage$tutorialsMenu$tutorialMenu.comment": "aria label",
    "pleaseUsePreviewPrompt$title": "Consider using our preview environment",
    "pleaseUsePreviewPrompt$title.comment": "Title of prompt asking user to use our preview environment",
    "pleaseUsePreviewPrompt$subText": "Our preview environment provides Microsoft employees with early access to new features, and helps us catch bugs early. Please note that queries saved here will not be available on preview.",
    "pleaseUsePreviewPrompt$subText.comment": "subtext of prompt asking the user to use our preview environment",
    "pleaseUsePreviewPrompt$RefreshButton": "Switch to preview",
    "pleaseUsePreviewPrompt$RefreshButton.comment": "Text of link to preview",
    "pleaseUsePreviewPrompt$DismissButton": "No thanks",
    "pleaseUsePreviewPrompt$DismissButton.comment": "Text of button to dismiss please use preview prompt",
    "actionBar": {
        "title": "Action bar",
        "title.comment": "title to describe a section on the screen where we have a few commands",
        "accountErrorMessage": "Couldn't connect with the listed account (${username}). Please reconnect.",
        "accountErrorMessage.comment": "{Locked='${username}'} Error message to show when token acquire for the cluster affiliated account fails",
        "loginButtonLabel": "Reconnect",
        "loginButtonLabel.comment": "Label for the login button"
    },
    "ingestHub": {
        "landingPageBanner": {
            "buttonText": "Switch to new",
            "buttonText.comment": "Text for the new ingest hub experience toggle",
            "bannerText": "Ingesting data made easy with Get Data tool",
            "bannerText.comment": "Text for the banner on the landing page"
        },
        "here": "here",
        "here.comment": "Link to the feedback form",
        "alertSecondaryButtonText": "Close",
        "alertSecondaryButtonText.comment": "Text for the alert secondary button"
    },
    "clusterNotInListDialog": {
        "title": "Adding connection",
        "title.comment": "Title of the dialog",
        "description": "The link uses a service that isn't in your connection list.",
        "description.comment": "Description of the dialog",
        "question": "Do you trust the link source and want to add the service to your connections?",
        "question.comment": "Question to the user",
        "clusterName": "Cluster: ${name}",
        "clusterName.comment": "{Locked='${name}'} Cluster label and name",
        "databaseName": "Database: ${name}",
        "databaseName.comment": "{Locked='${name}'} Database label and name",
        "trustButton": "Trust",
        "trustButton.comment": "Trust button"
    },
    "mainPage": {
        "teachingBubble": {
            "next": "Next",
            "next.comment": "Next label",
            "previous": "Previous",
            "previous.comment": "Previous label",
            "dismiss": "Dismiss",
            "dismiss.comment": "Dismiss",
            "gotIt": "Got it",
            "gotIt.comment": "dismiss",
            "stepStatus": "${currentStep} of ${totalSteps}",
            "stepStatus.comment": "currentStep from total number of steps",
            "contextualMenu": {
                "title": "New data management and Get Data Navigation options from Query pane",
                "title.comment": "title of the teaching bubble",
                "description": "Options are available at right click context menu from the query pane. ",
                "description.comment": "description of the teaching bubble"
            },
            "getStarted": {
                "title": "New! Get data hub",
                "title.comment": "title of the teaching bubble",
                "description": "Explore options to Get Data from different sources",
                "description.comment": "description of the teaching bubble"
            },
            "queryEditor": {
                "content": "Use this tutorial to familiarize yourself with KQL. You'll see hints and recommendations as you type your own queries.",
                "content.comment": "content",
                "title": "Query editor",
                "title.comment": "title"
            },
            "clusterPane": {
                "title": "Cluster pane",
                "title.comment": "title",
                "content": "See your clusters, databases, and the tables storing your data. Select a database to start querying the data.",
                "content.comment": "Cluster pane"
            },
            "queryResults": {
                "title": "Query results",
                "title.comment": "title",
                "content": "Use the column headers and right-click menu to explore the results. You can also see them as a graph.",
                "content.comment": "content"
            },
            "runAQuery": {
                "title": "Run a query",
                "title.comment": "title",
                "content": "Click the Run button to run the highlighted query (or use the Shift + Enter keys).",
                "content.comment": "content"
            },
            "whatsNext": {
                "title": "What next? Visualize the results",
                "title.comment": "title",
                "content": "Present visualized results on a dashboard. Click “Home” to check out sample dashboards, or click “Dashboards” to create your own.",
                "content.comment": "content"
            }
        }
    },
    "addClusterDialog": {
        "description": "Connection URI",
        "description.comment": "The description under the title in Add Cluster dialog {Locked='URI'}",
        "alias": "Display name",
        "alias.comment": "Text for the Display Name label that will be shown in the Add Cluster dialog below the Connection URI",
        "description$aria": "Connection U R I",
        "description$aria.comment": "This value will determine what will be read by the screen readers, when focus is set on the URL's text box in the Add Cluster dialog {Locked='U R I'}",
        "unknownUriText": "This is not a known Azure Data Explorer URI.",
        "unknownUriText.comment": "Text to show when the user tries to add cluster from not supported domain",
        "trustedHostLabel": "Add trusted host",
        "trustedHostLabel.comment": "Add trusted host dialog title",
        "addTrustedHostPreText": "Do you want to add",
        "addTrustedHostPreText.comment": "Pre subtext to prompt asking the user to add the unsupported cluster domain to his trusted hosts list",
        "addTrustedHostPostText": "to the list of trusted hosts?",
        "addTrustedHostPostText.comment": "Post subtext to prompt asking the user to add the unsupported cluster domain to his trusted hosts list",
        "addTrustedHostError": "Failed to add trusted host. Try to add it manually from the settings menu.",
        "addTrustedHostError.comment": "Text to show when trying to add a trusted host automatically was failed",
        "invalidConnectionUri": "Invalid connection URI",
        "invalidConnectionUri.comment": "Text to show when trying to add an invalid connection URI",
        "notAuthorizedErrorMessage": "You are not authorized to connect this cluster.",
        "notAuthorizedErrorMessage.comment": "Text to show when trying to add a cluster without the needed permissions",
        "tenantPicker": {
            "connectWith": "Connect as",
            "connectWith.comment": "Text to show before the logged in username",
            "onTenant": "on ${tenantName}",
            "onTenant.comment": "{Locked=${tenantName}} associated tenant name",
            "connectWithAnotherUser": "Connect as another user",
            "connectWithAnotherUser.comment": "Link text to connect as a different user",
            "switchDirectory": "Switch directory",
            "switchDirectory.comment": "Button text for displaying a list of available directories",
            "tenantName": "Name",
            "tenantName.comment": "Tenant name column name",
            "tenantDomain": "Domain",
            "tenantDomain.comment": "Tenant domain column name",
            "tenantId": "Directory ID",
            "tenantId.comment": "Tenant id column name",
            "notTenantsMessage": "Directory information is not available.",
            "notTenantsMessage.comment": "Message to show when there are no tenants",
            "errorMessage": "Failed to fetch tenants for the selected user. Please try to reconnect.",
            "errorMessage.comment": "Error message to show when fetching tenants failed",
            "rowSelectionAriaLabel": "Select row",
            "rowSelectionAriaLabel.comment": "Aria label for the row selection checkbox"
        }
    },
    "init": {
        "urlWarnings.comment": "Dialog shown if the url contains things that are deprecated",
        "urlWarnings": {
            "dialogTitle": "URL Warnings",
            "flags": {
                "notAFlag.comment": "Shown if the url contains a flag that does not exist",
                "notAFlag": "\"${text}\" is not a feature flag. Ignoring \"${urlPartial}\". Feature flags allowed the the URL include:\n${flags}. ",
                "cannotBeSet.comment": "Shown if the url contains a flag that cannot be set in the url",
                "cannotBeSet": "Feature flag \"${flag}\" cannot be set in the url. Ignoring \"${urlPartial}\".",
                "alreadySet": "Feature flag \"${flag}\" has already been set to \"${value}\", ignoring \"${urlPartial}\"",
                "invalidValue": "Feature flag \"${flag}\" is set to invalid value \"${value}\" by \"${urlPartial}\" in the url. Valid values are \"${trueValue}\", \"\" (empty string), or \"${falseValue}\". Ignoring."
            },
            "failedToParse.comment": "Catch-all error for url search parameters that we fail to parse",
            "failedToParse": "Failed to parse url search parameter ${urlPartial}",
            "query.comment": "Warnings related to the query area of the app",
            "query": {
                "autorunRequiresQuery.comment": "Shown if the user sets the \"autorun\" URL search parameter is set when there's no deep-link",
                "autorunRequiresQuery": "URL search parameter \"autorun\" only makes sense if \"q\" or \"query\" is also set"
            }
        }
    },
    "dataManagement": {
        "teachingBubble": {
            "next": "Next",
            "next.comment": "Next label",
            "previous": "Previous",
            "previous.comment": "Previous label",
            "dismiss": "Dismiss",
            "dismiss.comment": "Dismiss"
        }
    },
    "destinationTab": {
        "cluster": {
            "label": "Cluster",
            "placeholder": "Select cluster",
            "placeholder.comment": "placeholder",
            "connectionError": "Couldn't connect to the selected cluster. ${errorMessage}",
            "connectionError.comment": "{Locked='${errorMessage}'} Error message when connecting to the cluster failed"
        }
    },
    "sourceTab": {
        "noSource": {
            "notSupportedErrorMessage": "Empty source type is not supported for existing tables",
            "notSupportedErrorMessage.comment": "Error message to show when no source type option is selected while using an existing table"
        },
        "source": {
            "description": {
                "uploadLocalFileInfo": "Uploading data from a local file may take a few moments",
                "uploadLocalFileInfo.comment": "Information to user about upload local file time"
            },
            "tooltip": {
                "title": "Defining file",
                "title.comment": "tooltip callout title",
                "description": "The schema mapping will be based on the selected file.",
                "description.comment": "tooltip callout body"
            }
        }
    },
    "schemaTab": {
        "editColumn": {
            "ariaLabelText.correct": "aria label for edit column"
        },
        "schemaFields": {
            "createMapping": "Create mapping",
            "createMapping.comment": "Create mapping"
        }
    },
    "summaryTab": {
        "ingestionStatusTable": {
            "searchPlaceholder": "Search blobs",
            "searchPlaceholder.comment": "Search blobs"
        },
        "export": "Export",
        "export.comment": "Export",
        "titleStatus": {
            "ingestFlow": {
                "partiallyFailed": "Data Ingestion partially succeed",
                "partiallyFailed.comment": "Title for the summary tab partially failed",
                "default": "Data ingestion",
                "default.comment": "Title for the summary tab when there's no status",
                "started": "Data ingestion is in progress",
                "started.comment": "Title for the summary tab when ingestion is in progress",
                "succeeded": "Data ingestion completed",
                "succeeded.comment": "Title for the summary tab when ingestion succeeded",
                "failed": "Data ingestion failed",
                "failed.comment": "Title for the summary tab when ingestion failed"
            },
            "dataConnectionFlow": {
                "default": "Continuous ingestion from Event Hubs",
                "default.comment": "Title for the summary tab when there's no status",
                "started": "Continuous ingestion from Event Hubs is in progress",
                "started.comment": "Title for the summary tab when data connection creation is in progress",
                "succeeded": "Continuous ingestion from Event Hubs established",
                "succeeded.comment": "Title for the summary tab when creating data connection for event hub succeeded and established",
                "failed": "Continuous ingestion from Event Hubs failed",
                "failed.comment": "Title for the summary tab when data connection failed",
                "partiallyFailed": "Continuous ingestion from Event Hubs partially succeed",
                "partiallyFailed.comment": "Title for the summary tab partially failed"
            },
            "createTableFlow": {
                "default": "Create table",
                "default.comment": "Title for the summary tab when there's no status",
                "started": "Create table is in progress",
                "started.comment": "Title for the summary tab when creating table is in progress",
                "succeeded": "Create table completed",
                "succeeded.comment": "Title for the summary tab when creating table succeeded",
                "failed": "Create table failed",
                "failed.comment": "Title for the summary tab when creating table failed",
                "partiallyFailed": "Create table partially succeed",
                "partiallyFailed.comment": "Title for the summary tab partially failed"
            },
            "createDatabaseFlow": {
                "default": "Create database",
                "default.comment": "Title for the summary tab when there's no status",
                "started": "Create database is in progress",
                "started.comment": "Title for the summary tab when creating table is in progress",
                "succeeded": "Create database completed",
                "succeeded.comment": "Title for the summary tab when creating table succeeded",
                "failed": "Create database failed",
                "failed.comment": "Title for the summary tab when creating table failed",
                "partiallyFailed": "Create database partially succeed",
                "partiallyFailed.comment": "Title for the summary tab partially failed"
            },
            "updateDatabaseFlow": {
                "default": "Update database",
                "default.comment": "Title for the summary tab when there's no status",
                "started": "Update database is in progress",
                "started.comment": "Title for the summary tab when creating table is in progress",
                "succeeded": "Update database completed",
                "succeeded.comment": "Title for the summary tab when creating table succeeded",
                "failed": "Update database failed",
                "failed.comment": "Title for the summary tab when creating table failed",
                "partiallyFailed": "Update database partially succeed",
                "partiallyFailed.comment": "Title for the summary tab partially failed"
            },
            "externalTableFlow": {
                "default": "Create external table (preview)",
                "default.comment": "Title for the external table summary tab when there's no status",
                "started": "External table creation is in progress",
                "started.comment": "Title for the summary tab when creating external table is in progress",
                "succeeded": "External table successfully created",
                "succeeded.comment": "Title for the summary tab when creating external table succeeded",
                "failed": "External table creation failed",
                "failed.comment": "Title for the summary tab when creating external table failed",
                "partiallyFailed": "External table creation partially succeed",
                "partiallyFailed.comment": "Title for the summary tab partially failed"
            },
            "updateTablePolicy": {
                "default": "Update table policy",
                "default.comment": "Title for the Update table policy summary tab when there's no status",
                "started": "Update table policy is in progress",
                "started.comment": "Title for the summary tab when update table policy is in progress",
                "succeeded": "Update table policy successfully altered",
                "succeeded.comment": "Title for the summary tab when update table policy succeeded",
                "failed": "Table policy failed to update",
                "failed.comment": "Title for the summary tab when update table policy failed",
                "partiallyFailed": "Update table policy partially succeed",
                "partiallyFailed.comment": "Title for the summary tab partially failed"
            },
            "generateCodeFlow": {
                "default": "",
                "default.comment": "",
                "started": "Data creation is in progress",
                "started.comment": "Title for the summary tab when creating data for generate code flow is in progress",
                "succeeded": "Sample project data created successfully",
                "succeeded.comment": "Title for the summary tab when creating data for generate code flow succeeded",
                "failed": "Sample project data creation failed",
                "failed.comment": "Title for the summary tab when creating data for generate code flow failed",
                "partiallyFailed": "Sample project data creation partially succeed",
                "partiallyFailed.comment": "Title for the summary tab partially failed"
            }
        }
    },
    "eventHub": {
        "schema": {
            "formatFieldInfo": "Data format can be changed to JSON, CSV, etc.",
            "formatFieldInfo.comment": "Message box text to show next to the format field",
            "ignoreJsonFormatErrors": "Ignore data format errors",
            "ignoreJsonFormatErrors.comment": "Label for ignoring data format errors",
            "ignoreJsonFormatErrorsHint": "Not recommended, supports only JSONL (JSON Lines).",
            "ignoreJsonFormatErrorsHint.comment": "Tooltip message for Ignore data format errors field"
        },
        "source": {
            "noPermissionsErrorMessage": "You don't have permissions to create a data connection under the selected cluster",
            "noPermissionsErrorMessage.comment": "A message to show when the user has no sufficient permissions to continue",
            "createNamespace": "Create namespace (Azure portal)",
            "createNamespace.comment": "Label for create namespace external link",
            "manageNamespace": "Manage namespace (Azure portal)",
            "manageNamespace.comment": "Label for manage namespace external link",
            "dataConnection": {
                "label": "Data connection",
                "label.comment": "Shows in Source tab as a title for the configuration of the data connection properties",
                "subtitle": "Create a data connection to ingest data from Event Hubs. ",
                "subtitle.comment": "General explanation on data connection section in source tab, with a link to relevant documentation"
            },
            "dataConnectionName": {
                "label": "Data connection name",
                "label.comment": "Data connection name label",
                "alreadyExistsError": "A data connection with that name already exists. Please choose a different name and try again.",
                "alreadyExistsError.comment": "A data connection with that name already exists. Please choose a different name and try again.",
                "validation": "Data Connection name must be unique within your Azure Data Explorer cluster. It must be up to 40 characters long and contain only dashes, dots and alphanumeric characters.",
                "validation.comment": "Shown as error if the data connection name input doesn't follow the rules."
            },
            "subscription": "Subscription",
            "subscription.comment": "Label for the subscription dropdown where the user selects subscription for the event hub",
            "eventHubNamespace": {
                "label": "Event hub namespace",
                "label.comment": "Label for the event hub namespace dropdown where the user selects event hub namespace for the event hub",
                "defaultErrorMessage": "Select event hub namespace",
                "defaultErrorMessage.comment": "Default error message to select Event Hub namespace"
            },
            "eventHub": "Event Hubs",
            "eventHub.comment": "Label for the Event Hub dropdown where the user selects Event Hubs",
            "consumerGroup": {
                "label": "Consumer group",
                "label.comment": "Label for the Consumer group dropdown where the user selects Event Hubs consumer group",
                "description": "Name of consumer group to subscribe to an event Hhub",
                "description.comment": "Description of the consumer group field",
                "createLink": "Add consumer group",
                "createLink.comment": "Link text to add new consumer group"
            },
            "compression": {
                "label": "Compression",
                "label.comment": "Label for the Compression dropdown where the user selects Event Hub events compression",
                "description": "The compression type of the event hub messages payload",
                "description.comment": "Description of the compression field",
                "defaultErrorMessage": "Select compression",
                "defaultErrorMessage.comment": "Default error message for compression"
            },
            "systemProperties": {
                "label": "Event system properties",
                "label.comment": "Label for the Event system properties dropdown where the user selects Event Hub events system properties",
                "description": "System properties are supported for Json and tabular formats (Csv, Tsv etc.) and aren't supported on compressed data.",
                "description.comment": "Description of the system properties field"
            },
            "moreParameters": "More parameters",
            "moreParameters.comment": "collapsible title"
        }
    },
    "homePage": {
        "createNew": "Create new",
        "createNew.comment": "Create new title for tiles section",
        "getStarted": "Get started",
        "getStarted.comment": "Title above the quick actions",
        "tutorial": "tutorial",
        "tutorial.comment": "part of string <name> tutorial is currently unavailable.",
        "dashboard": "dashboard",
        "dashboard.comment": "part of string <name> dashboard is currently unavailable.",
        "exploreSampleCard": {
            "tooltip": "This corresponding database is currently not available in your environment",
            "tooltip.comment": "tooltip info",
            "notAvailable": "${database} ${type} is currently unavailable.",
            "notAvailable.comment": "tooltip message. {Locked='${database}'} {Locked='${type}'}"
        },
        "quickActions": {
            "dashboards": "Explore sample dashboards",
            "dashboards.comment": "Title for quick action card",
            "sample": "Explore sample data with KQL",
            "sample.comment": "Title for quick action card",
            "createCluster": "Free cluster",
            "createCluster.comment": "Title for quick action card",
            "findMyPartner": "Find my partner",
            "findMyPartner.comment": "Find my partner dashboard",
            "freeCluster": "Free cluster",
            "freeCluster.comment": "Tile title for the free cluster tile",
            "dashboard": "Dashboard",
            "dashboard.comment": "Tile title for the dashboard tile",
            "dataManagement": "Data management",
            "dataManagement.comment": "Homepage tiles section title for all data ingestion flows",
            "ingestData": "Get data",
            "ingestData.comment": "Tile title for the data ingestion wizard",
            "createTable": "Create table",
            "createTable.comment": "Tile title for Create table flow",
            "createExternalTable": "Create external table",
            "createExternalTable.comment": "Tile title for Create external table flow",
            "query": "Query",
            "query.comment": "Tile title for the query editor tile",
            "table": "Table",
            "table.comment": "Tile title for the table tile"
        },
        "teachingBubble": {
            "getStarted": {
                "title": "New! Get data hub",
                "title.comment": "Title for the teaching bubble",
                "description": "Explore options to Get Data from different sources",
                "description.comment": "Description for the teaching bubble"
            },
            "explorerNav": {
                "title": "New data management and Get Data Navigation options from Query pane",
                "title.comment": "Title for the teaching bubble",
                "description": "Options are available at right click context menu from the query pane.",
                "description.comment": "Description for the teaching bubble"
            },
            "stepStatus": "${currentStep} of ${totalSteps}",
            "stepStatus.comment": "currentStep from total number of steps",
            "next": "Next",
            "next.comment": "button label",
            "done": "Done",
            "done.comment": "primary button text",
            "previous": "Previous",
            "previous.comment": "secondary button text"
        },
        "featured": {
            "title": "Featured",
            "title.comment": "Featured section title",
            "partners": {
                "title": "Find the right Azure Data Explorer partner for you!",
                "title.comment": "Partner section title",
                "description": "Partners can help you with your Azure Data Explorer implementation, including consulting, training, and support.",
                "description.comment": "Partner section description",
                "callToActionText": "Click here for a list of ADX partners ",
                "callToActionText.comment": "Partner section call to action"
            },
            "kustoInADay": {
                "title": "Earn a Microsoft badge!",
                "title.comment": "Kusto in a day section title",
                "description": "Get skilled up on ADX through a hands-on learning experience starting with data ingestion and going all the way to visualization using KQL, Timeseries Analytics and creating Dashboards. ",
                "description.comment": "Kusto in a day section description",
                "callToActionText": "Click here to find out more",
                "callToActionText.comment": "Kusto in a day section call to action"
            },
            "kustoDetectiveAgencyV2": {
                "title": "Have you ever wanted to be a data detective?",
                "title.comment": "Kusto Detective Agency section title",
                "description": "Season 2 of Kusto Detective Agency is now available for you to play! Join this online escape room game, full of Big Data challenges, prizes, and learn KQL in an unforgettable experience.",
                "description.comment": "Kusto Detective Agency section description",
                "callToActionText": "Click here to play the game",
                "callToActionText.comment": "Kusto Detective Agency section call to action",
                "clickHere": "Click here",
                "clickHere.comment": "Click here text",
                "toPlay": "to play the game, and",
                "toPlay.comment": "to play the game text",
                "here": "here",
                "here.comment": "here text, part of Click here to play the game, and here to view season 2 trailer.",
                "toView": "to view season 2 trailer.",
                "toView.comment": "to view season 2 trailer text, part of Click here to play the game, and here to view season 2 trailer."
            }
        },
        "documents": {
            "title": "Documentation",
            "title.comment": "Documents section title",
            "tabBasic": "Basic documentation",
            "tabBasic.comment": "Tab name for basic docs",
            "tabAdvance": "Advanced documentation",
            "tabAdvance.comment": "Tab name for advance docs",
            "minutesInfo": "${number} min",
            "minutesInfo.comment": "{Locked='${number}'} info about how long doc reading should take",
            "whatIsADX": "What is Azure Data Explorer?",
            "whatIsADX.comment": "Azure Data Explorer doc",
            "whatIsNew": "What's new in Azure Data Explorer documentation",
            "whatIsNew.comment": "Document about what is new in Azure Data Explorer documentation",
            "ingestIntoAzure": "Quickstart: Ingest sample data into Azure Data Explorer",
            "ingestIntoAzure.comment": "Docs for ingestion in azure",
            "createDatabaseAndCluster": "Quickstart: Create an Azure Data Explorer cluster and database",
            "createDatabaseAndCluster.comment": "Docs for cluster and database",
            "azureArchitectures": "Azure Data Explorer solutions architectures",
            "azureArchitectures.comment": "Docs about Architectures",
            "kqlBestPractices": "KQL best practices",
            "kqlBestPractices.comment": "document title",
            "dataIngestionOverview": "Data ingestion overview",
            "dataIngestionOverview.comment": "document title",
            "dataVisualization": "Data visualization with Azure Data Explorer",
            "dataVisualization.comment": "document title"
        },
        "recommended": "Recommended",
        "recommended.comment": "Title above the big actions section."
    },
    "settings.comment": "Settings panel available app-wide",
    "settings": {
        "general$Advance": "Advanced",
        "general$Advance.comment": "Title for the advanced section under the General section in the settings panel",
        "sectionLabels.comment": "Text that appears on section tabs in panel",
        "sectionLabels": {
            "general": "General",
            "general.comment": "section in settings that's dedicated to general settings",
            "connection": "Connection",
            "connection.comment": "section in settings that's dedicated for server connection settings (example setting: query timeout settings",
            "editing": "Editor",
            "editing.comment": "section in settings that's dedicated to editing/editor settings (example settings: Semantic Colorization)",
            "appearance": "Appearance",
            "appearance.comment": "section in settings that's dedicated to the appearance (example settings: choosing a theme light/dark)",
            "about": "About",
            "about.comment": "Section contains information about the current user and the client"
        },
        "generalSection.comment": "Copy for inputs in the general section",
        "generalSection": {
            "originAllowlist.comment": "Text area used for configuring a list of trusted origins for content created by query results",
            "originAllowlist": {
                "label": "Origin allowlist for links and images",
                "description": "Use ';' to add more than a single origin. For example, \"${exampleText}\". Add \"file://\" to allowlist for file links.",
                "description.comment": "{Locked='${exampleText}'} Description text for user trusted hosts input",
                "infoTooltipContent.comment": "Content of tooltip that contains a long description",
                "infoTooltipContent": "Add here URLs of sites where you allow the opening of links or images based on the query"
            },
            "tenantFeatures": {
                "title": "Tenant Features",
                "description": "Enable or disable features in the tenant. This can only be changed by the tenant's global administrator.",
                "features": {
                    "crossTenantSharing": {
                        "name": "Share Dashboards Across Tenants",
                        "tooltip": "Allow sharing dashboards with users in different tenants using invitations."
                    }
                }
            }
        },
        "aboutSection.comment": "Labels for information in the about section",
        "aboutSection": {
            "userUPNLabel.comment": "Name of user in email format",
            "userUPNLabel": "User upn",
            "userIDLabel": "User ID",
            "userTenantLabel": "User tenant",
            "clientVersionLabel.comment": "Version of the website. For example: \"2.74.0\"",
            "clientVersionLabel": "Client version",
            "clientEnvironmentLabel.comment": "Website environment. For example: \"production\" or \"staging\"",
            "clientEnvironmentLabel": "Client environment",
            "sessionIdLabel": "Session ID",
            "isPpeLabel": "Is pre-production backend"
        },
        "title": "Settings",
        "title.comment": "title for the settings section",
        "queryRecommendations": {
            "label": "Query recommendations",
            "label.comment": "Label for the query recommendations section in the settings panel",
            "link": "Set query recommendations",
            "link.comment": "Link in the settings panel to open the query recommendations configuration",
            "enableWarnings": "Activate warnings",
            "enableWarnings.comment": "Label for the toggle of all warnings",
            "dialogTitle": "Set query recommendations",
            "dialogTitle.comment": "The title of the settings dialog of query recommendations",
            "subTitle": "Set what recommendations you see",
            "subTitle.comment": "The subtitle of the settings dialog of query recommendations",
            "suggestions": "Suggestions",
            "suggestions.comment": "Suggestions tab in the dialog",
            "warnings": "Warnings",
            "warnings.comment": "Warnings tab in the dialog",
            "enableSuggestions": "Activate suggestions",
            "enableSuggestions.comment": "Label for the toggle of all suggestions",
            "categories": {
                "general": "General",
                "general.comment": "Category label of General for a Query Recommendation",
                "correctness": "Correctness",
                "correctness.comment": "Category label of Correctness for a Query Recommendation",
                "performance": "Performance",
                "performance.comment": "Category label of Performance for a Query Recommendation",
                "unknown": "Unknown",
                "unknown.comment": "Category label of Unknown for a Query Recommendation"
            },
            "unsavedDataWarning": "Your unsaved edits will be discarded.",
            "unsavedDataWarning.comment": "Warning when dismissing the dialog without saving",
            "unsavedDataWarning$title": "Warning",
            "unsavedDataWarning$title.comment": "Title of warning when dismissing the dialog without saving",
            "KS500": "Avoid using the 'contains' operator as it has a high compute price. Use the 'has' operator in cases when full term match is desired.",
            "KS500.comment": "{Locked='contains', 'has'} Description of recommendation KS500",
            "KS501": "Avoid using 'isnull' on string arguments, use 'isempty()' instead.",
            "KS501.comment": "{Locked='isnull', 'isempty()'} Description of recommendation KS501",
            "KS502": "Avoid using 'isnotnull' on string arguments, use 'isnotempty()' instead.",
            "KS502.comment": "{Locked='isnotnull', 'isnotempty()'} Description of recommendation KS502",
            "KS503": "Avoid using short strings (less than 4 characters) for string comparison operations.",
            "KS503.comment": "Description of recommendation KS503",
            "KS504": "Avoid using 'tobool' on numeric arguments, use comparison operators instead.",
            "KS504.comment": "{Locked='tobool'} Description of recommendation KS504",
            "KS505": "Avoid using operations on possible null values inside sum aggregates.",
            "KS505.comment": "Description of recommendation KS505",
            "KS506": "Avoid using the 'format_datetime' function in a filter or predicate. Instead, use specific datetime functions like 'startofday' or 'bin'.",
            "KS506.comment": "{Locked='format_datetime', 'startofday', 'bin'} Description of recommendation KS506",
            "KS507": "Avoid using obsolete/deprecated functions.",
            "KS507.comment": "Description of recommendation KS507",
            "KS508": "Avoid using joins without specifying the join kind. The default join behavior may be unexpected.",
            "KS508.comment": "Description of recommendation KS508",
            "KS509": "This use of 'stdev()' results in a number. To get a timespan use 'totimespan()' on the result.",
            "KS509.comment": "{Locked='stdev()', 'totimespan()'} Description of recommendation KS509",
            "KS510": "A variable or parameter is ignored in favor of a column with the same name.",
            "KS510.comment": "Description of recommendation KS510",
            "KS511": "Consider using the 'materialized_view()' function to query the materialized part only.",
            "KS511.comment": "{Locked='materialized_view()'} Description of recommendation KS511",
            "KS512": "A called function has errors in its definition.",
            "KS512.comment": "Description of recommendation KS512",
            "KS513": "It is recommended to use the 'native' or 'shuffle' strategy of the partition operator. The legacy mode is limited to 64 partitions and is less efficient than the 'native' or 'shuffle' strategies.",
            "KS513.comment": "{Locked='native', 'shuffle'} Description of recommendation KS513"
        },
        "connectionSection": {
            "trustedHosts": {
                "label": "Additional trusted hosts",
                "label.comment": "Label for user defined additional trusted hosts input",
                "description": "Use ';' to add more than a single host",
                "description.comment": "Description text for user trusted hosts input",
                "hint": "List of additional trusted hosts considered safe to connect to. Use ';' to add more than a single host, and '*' to specify a multiple domain pattern, for example: www.some-domain.com;*.my-domain.com",
                "hint.comment": "Hint text for user trusted hosts input"
            }
        },
        "ignoreVisualLimitsToggleLabel.comment": "If toggle is enabled, limits which normal prevent charts from rendering when exceeded will be ignored",
        "ignoreVisualLimitsToggleLabel": "Ignore recommended visual series and point limits",
        "oldIngestHubExperienceText.comment": "If toggle is enabled, switch back to the deprecated Data management experience",
        "oldIngestHubExperienceText": "Enable data management (deprecated)",
        "timeZoneDropdown.comment": "Dropdown input for selecting the time zone",
        "timeZoneDropdown": {
            "browserTimezoneOption.comment": "{Locked='${timeZone}'} Dropdown option template for the users computers time zone",
            "browserTimezoneOption": "Browser default (${timeZone})",
            "label.comment": "Label to show on the Time Zone dropbox",
            "label": "Time zone"
        }
    },
    "switchDirectoryPanel": {
        "switch": "Switch",
        "switch.comment": "The button text on the bottom. Switching to the selected directory when clicked.",
        "descriptionTitle": "Directories",
        "descriptionTitle.comment": "The title for the switch directory description",
        "description": "Switching directories will reload Azure Data Explorer Web UI. You can either enter directory ID or choose from the list below.",
        "description.comment": "Information about switching directories"
    },
    "headerButtons": {
        "whatIsNew": "What's New in Azure Data Explorer Web",
        "documentation": "Documentation"
    },
    "ibizaOneClick": {
        "destinationTab": {
            "generalEventHubError": "Something went wrong, can't load the event hub details, please try again later",
            "subscriptionField": {
                "errorMessage": "Can't load subscriptions.",
                "errorMessage.comment": "Error message when the subscriptions can't be loaded",
                "label": "Cluster Subscription",
                "label.comment": "Label for the subscription field in the destination tab",
                "placeholder": "Select Subscription",
                "placeholder.comment": "Placeholder for the subscription field in the destination tab"
            },
            "clusterField": {
                "connectionError": "Can't connect to the cluster.",
                "connectionError.comment": "Error message when the cluster is not reachable"
            }
        }
    },
    "roamingProfile": {
        "syncSettingSectionTitle": "Sync mode",
        "syncSettingSectionTitle.comment": "Title for the sync section in the settings panel",
        "syncSettingSectionDescription": "When sync is on, your query data, connections, and settings, are saved and available everywhere.",
        "syncSettingSectionDescription.comment": "Description for the sync section in the settings panel",
        "deleteButton": "Delete synced data",
        "deleteButton.comment": "Button to delete synced data",
        "deleteButtonTooltip": "All your synced data will be deleted from the server, and you'll continue saving data locally.",
        "deleteButtonTooltip.comment": "Tooltip for the delete button",
        "toggleOnLabel": "On",
        "toggleOnLabel.comment": "Toggle button label when sync is on",
        "toggleOffLabel": "Off",
        "toggleOffLabel.comment": "Toggle button label when sync is off",

        "syncButtonOn": "Sync on",
        "syncButtonOn.comment": "Button label when sync is on",
        "syncButtonOff": "Sync off",
        "syncButtonOff.comment": "Button label when sync is off",
        "calloutErrorDescription": "You are currently offline. We’ll sync your data when the connection is restored.",
        "calloutErrorDescription.comment": "Description for the callout when there is an error syncing",
        "calloutDescription": "When sync is on, your query data, connections, and settings are synced across all your environments. To change the sync mode go to ",
        "calloutDescription.comment": "Description for the callout when sync is on",
        "calloutSettingsLink": "Settings",
        "calloutSettingsLink.comment": "Link to the settings panel",
        "calloutLastSynced": "Last synced on:",
        "calloutLastSynced.comment": "Last time synced successfully"
    },
    "dashboards.comment": "Copy related to dashboards",
    "dashboards": {
        "host.comment": "Strings used by dashboards that are host specific. This lets each host app customize the copy for their platform",
        "host": {
            "errorLoadingDashboardBtnLabel": "View all dashboards",
            "errorLoadingDashboardBtnLabel.comment": "Button label for the action button we show in a dialog when a dashboard fails to load"
        },
        "exploreDataMenuItemNotSupported": "Currently data exploration isn't supported for this type of visual",
        "exploreDataMenuItemNotSupported.comment": "Tile menu item that opens data exploration modal - disabled for unsupported visuals",
        "exploreDataMenuItem": "Explore data",
        "exploreDataMenuItem.comment": "Tile menu item that opens data exploration modal"
    }
}
