span.tabText {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

span.tabInput {
    width: 100%;
    min-height: unset;
    height: 22px;
    padding: 0;
}
